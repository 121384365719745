import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export type TypeCustomerManager = {
    customerManagerName: string;
    customerManagerPhone: string;
};

export const useRequestAuthCustomerManagerRegister = () => {
    const requestAxiosAuthCustomerManagerRegister = async (data: TypeCustomerManager) => {
        const response = await utilAxiosWithAuth().post(Request.CUSTOMER_MANAGER_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerManagerRegister,
        error: errorAuthCustomerManagerRegister,
        data: resultAuthCustomerManagerRegister,
        execute: requestAuthCustomerManagerRegister,
    } = useAsyncAxios(requestAxiosAuthCustomerManagerRegister);

    useEffect(() => {
        if (resultAuthCustomerManagerRegister === null) return;
        DEBUG && console.log("resultAuthCustomerManagerRegister", resultAuthCustomerManagerRegister);
    }, [resultAuthCustomerManagerRegister]);

    useEffect(() => {
        if (errorAuthCustomerManagerRegister === null) return;
        DEBUG && console.log("errorAuthCustomerManagerRegister", errorAuthCustomerManagerRegister);
    }, [errorAuthCustomerManagerRegister]);

    return {
        loadingAuthCustomerManagerRegister,
        errorAuthCustomerManagerRegister,
        resultAuthCustomerManagerRegister,
        requestAuthCustomerManagerRegister,
    };
};

export const useRequestAuthCustomerManagerList = () => {
    const requestAxiosAuthCustomerManagerList = async () => {
        const response = await utilAxiosWithAuth().get(Request.CUSTOMER_MANAGERS_URL);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerManagerList,
        error: errorAuthCustomerManagerList,
        data: resultAuthCustomerManagerList,
        execute: requestAuthCustomerManagerList,
    } = useAsyncAxios(requestAxiosAuthCustomerManagerList);

    useEffect(() => {
        if (resultAuthCustomerManagerList === null) return;
        DEBUG && console.log("resultAuthCustomerManagerList", resultAuthCustomerManagerList);
    }, [resultAuthCustomerManagerList]);

    useEffect(() => {
        if (errorAuthCustomerManagerList === null) return;
        DEBUG && console.log("errorAuthCustomerManagerList", errorAuthCustomerManagerList);
    }, [errorAuthCustomerManagerList]);

    return {
        loadingAuthCustomerManagerList,
        errorAuthCustomerManagerList,
        resultAuthCustomerManagerList,
        requestAuthCustomerManagerList,
    };
};

export const useRequestAuthCustomerManagerDeleteList = () => {
    const requestAxiosAuthCustomerManagerDeleteList = async (customerManagerIds: number[]) => {
        const response = await utilAxiosWithAuth().delete(Request.CUSTOMER_MANAGERS_URL, { data: customerManagerIds });

        return response.data;
    };

    const {
        loading: loadingAuthCustomerManagerDeleteList,
        error: errorAuthCustomerManagerDeleteList,
        data: resultAuthCustomerManagerDeleteList,
        execute: requestAuthCustomerManagerDeleteList,
    } = useAsyncAxios(requestAxiosAuthCustomerManagerDeleteList);

    useEffect(() => {
        if (resultAuthCustomerManagerDeleteList === null) return;
        DEBUG && console.log("resultAuthCustomerManagerDeleteList", resultAuthCustomerManagerDeleteList);
    }, [resultAuthCustomerManagerDeleteList]);

    useEffect(() => {
        if (errorAuthCustomerManagerDeleteList === null) return;
        DEBUG && console.log("errorAuthCustomerManagerDeleteList", errorAuthCustomerManagerDeleteList);
    }, [errorAuthCustomerManagerDeleteList]);

    return {
        loadingAuthCustomerManagerDeleteList,
        errorAuthCustomerManagerDeleteList,
        resultAuthCustomerManagerDeleteList,
        requestAuthCustomerManagerDeleteList,
    };
};

export const useRequestAuthCustomerManagerInfo = () => {
    const requestAxiosAuthCustomerManagerInfo = async (customerManagerId: number) => {
        const response = await utilAxiosWithAuth().get(Request.CUSTOMER_MANAGER_URL + customerManagerId);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerManagerInfo,
        error: errorAuthCustomerManagerInfo,
        data: resultAuthCustomerManagerInfo,
        execute: requestAuthCustomerManagerInfo,
    } = useAsyncAxios(requestAxiosAuthCustomerManagerInfo);

    useEffect(() => {
        if (resultAuthCustomerManagerInfo === null) return;
        DEBUG && console.log("resultAuthCustomerManagerInfo", resultAuthCustomerManagerInfo);
    }, [resultAuthCustomerManagerInfo]);

    useEffect(() => {
        if (errorAuthCustomerManagerInfo === null) return;
        DEBUG && console.log("errorAuthCustomerManagerInfo", errorAuthCustomerManagerInfo);
    }, [errorAuthCustomerManagerInfo]);

    return {
        loadingAuthCustomerManagerInfo,
        errorAuthCustomerManagerInfo,
        resultAuthCustomerManagerInfo,
        requestAuthCustomerManagerInfo,
    };
};

export const useRequestAuthCustomerManagerUpdate = () => {
    const requestAxiosAuthCustomerManagerUpdate = async (customerManagerId: number, data: TypeDTO.CustomerManagerDto) => {
        const response = await utilAxiosWithAuth().put(Request.CUSTOMER_MANAGER_URL + customerManagerId, data);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerManagerUpdate,
        error: errorAuthCustomerManagerUpdate,
        data: resultAuthCustomerManagerUpdate,
        execute: requestAuthCustomerManagerUpdate,
    } = useAsyncAxios(requestAxiosAuthCustomerManagerUpdate);

    useEffect(() => {
        if (resultAuthCustomerManagerUpdate === null) return;
        DEBUG && console.log("resultAuthCustomerManagerUpdate", resultAuthCustomerManagerUpdate);
    }, [resultAuthCustomerManagerUpdate]);

    useEffect(() => {
        if (errorAuthCustomerManagerUpdate === null) return;
        DEBUG && console.log("errorAuthCustomerManagerUpdate", errorAuthCustomerManagerUpdate);
    }, [errorAuthCustomerManagerUpdate]);

    return {
        loadingAuthCustomerManagerUpdate,
        errorAuthCustomerManagerUpdate,
        resultAuthCustomerManagerUpdate,
        requestAuthCustomerManagerUpdate,
    };
};

export const useRequestAuthCustomerManagerDelete = () => {
    const requestAxiosAuthCustomerManagerDelete = async (customerManagerId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.CUSTOMER_MANAGER_URL + customerManagerId);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerManagerDelete,
        error: errorAuthCustomerManagerDelete,
        data: resultAuthCustomerManagerDelete,
        execute: requestAuthCustomerManagerDelete,
    } = useAsyncAxios(requestAxiosAuthCustomerManagerDelete);

    useEffect(() => {
        if (resultAuthCustomerManagerDelete === null) return;
        DEBUG && console.log("resultAuthCustomerManagerDelete", resultAuthCustomerManagerDelete);
    }, [resultAuthCustomerManagerDelete]);

    useEffect(() => {
        if (errorAuthCustomerManagerDelete === null) return;
        DEBUG && console.log("errorAuthCustomerManagerDelete", errorAuthCustomerManagerDelete);
    }, [errorAuthCustomerManagerDelete]);

    return {
        loadingAuthCustomerManagerDelete,
        errorAuthCustomerManagerDelete,
        resultAuthCustomerManagerDelete,
        requestAuthCustomerManagerDelete,
    };
};
