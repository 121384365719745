import { useState, useEffect } from "react";
import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestService from "../../utils/requestAuthApplication";

import DatePicker from "antd/es/date-picker/index";
import Input from "antd/es/input/index";
import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";
import dayjs from "dayjs";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type SearchType = {
    applicationName: string;
    companyName: string;
    managerName: string;
};

const PageService = () => {
    const alert = useAlert();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [type, setType] = useState<viewType>("view");
    const [service, setService] = useState<TypeDTO.ApplicationDto>();
    const [services, setServices] = useState<Array<TypeDTO.ApplicationDto>>();
    const [filteredServices, setFilteredServices] = useState<Array<TypeDTO.ApplicationDto>>();
    const [isLicense, setLicense] = useState<boolean>(false);

    useEffect(() => {
        requestAuthApplicationList();
    }, []);

    const columns: ColumnsType<TypeDTO.ApplicationDto> = [
        {
            title: String.service,
            dataIndex: "applicationName",
            key: "service",
            width: "15%",
            align: "center",
        },
        {
            title: String.company,
            dataIndex: "companyName",
            key: "companyName",
            width: "10%",
            align: "center",
            render: (_, { companyName }) => companyName || String.dash,
        },
        {
            title: String.chargingManager,
            dataIndex: "managerName",
            key: "managerName",
            width: "10%",
            align: "center",
            render: (_, { managerName }) => managerName || String.dash,
        },
        {
            title: String.managerEmail,
            dataIndex: "managerEmail",
            key: "managerEmail",
            width: "15%",
            align: "center",
            render: (_, { managerEmail }) => managerEmail || String.dash,
        },
        {
            title: String.phone,
            dataIndex: "managerPhone",
            key: "managerPhone",
            width: "10%",
            align: "center",
            render: (_, { managerPhone }) => Utils.convertPhone(managerPhone) || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRow("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.applicationName);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "applicationName",
            span: 12,
            title: String.service,
            type: "select",
            options: services?.map((service) => ({ value: service.applicationName, label: service.applicationName })),
        },
        {
            id: "companyName",
            span: 6,
            title: String.company,
            type: "input",
        },
        {
            id: "managerName",
            span: 6,
            title: String.chargingManager,
            type: "input",
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        {
            name: "applicationName",
            label: String.serviceName,
            span: 12,
            required: true,
        },
        {
            name: "companyName",
            label: String.company,
            span: 12,
        },
        {
            name: "description",
            label: String.description,
            span: 24,
        },
        {
            name: "managerName",
            label: String.chargingManager,
            span: 12,
        },
        {
            name: "managerPhone",
            label: String.phone,
            span: 12,
            input: <Input className={type === "view" ? "disabled-input" : ""} disabled={type === "view"} />,
        },
        {
            name: "managerEmail",
            label: String.email,
            span: 24,
        },
        {
            name: "expiredDate",
            label: String.expiredDate,
            span: 24,
            input: (
                <DatePicker className={!isLicense && type !== "register" ? "disabled-datepicker" : ""} disabled={!isLicense && type !== "register"} />
            ),
            required: isLicense || type === "register",
        },
        {
            name: "applicationToken",
            label: String.license,
            span: 24,
            disabled: true,
            input: <Input.TextArea className="disabled-input" style={{ minHeight: "100px" }} disabled />,
        },
    ];

    const { loadingAuthApplicationList, requestAuthApplicationList, resultAuthApplicationList } = RequestService.useRequestAuthApplicationList();

    useEffect(() => {
        if (!resultAuthApplicationList) return;

        setServices(resultAuthApplicationList.applications);
    }, [resultAuthApplicationList]);

    const { requestAuthApplicationRegister, resultAuthApplicationRegister } = RequestService.useRequestAuthApplicationRegister();

    useEffect(() => {
        if (!resultAuthApplicationRegister) return;

        onResult("register");
    }, [resultAuthApplicationRegister]);

    const { requestAuthApplicationUpdate, resultAuthApplicationUpdate } = RequestService.useRequestAuthApplicationUpdate();

    useEffect(() => {
        if (!resultAuthApplicationUpdate) return;

        onResult("edit");
    }, [resultAuthApplicationUpdate]);

    const { requestAuthApplicationDelete, resultAuthApplicationDelete } = RequestService.useRequestAuthApplicationDelete();

    useEffect(() => {
        if (!resultAuthApplicationDelete) return;

        onResult("delete");
    }, [resultAuthApplicationDelete]);

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;
        setFilteredServices(
            services?.filter(
                (service) =>
                    searchFilter(service, value?.applicationName, "applicationName") &&
                    searchFilter(service, value?.companyName, "companyName") &&
                    searchFilter(service, value?.managerName, "managerName")
            )
        );
    };

    const onRow = (type: viewType, value?: TypeDTO.ApplicationDto) => {
        setService(value);
        setIsOpen(true);
        setType(type);
    };

    const onDelete = (name: string) => {
        Modal.confirm({
            title: String.service + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestAuthApplicationDelete(name);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinish = (type: viewType, value: TypeDTO.ApplicationDto) => {
        if (isLicense) {
            const searchValue = {
                managerPhone: value?.managerPhone?.replaceAll("-", ""),
                expiredDate: dayjs(value.expiredDate).format(Common.FORMAT_DATE_HOUR_TIME),
            };
            requestAuthApplicationUpdate(service?.applicationName, searchValue);
        } else {
            if (type === "register") {
                const searchValue = {
                    ...value,
                    managerPhone: value?.managerPhone?.replaceAll("-", ""),
                    expiredDate: dayjs(value.expiredDate).format(Common.FORMAT_DATE_HOUR_TIME),
                };
                requestAuthApplicationRegister(searchValue);
            } else {
                const { expiredDate, applicationToken, ...searchValue } = {
                    ...value,
                    managerPhone: value?.managerPhone?.replaceAll("-", ""),
                };
                requestAuthApplicationUpdate(service?.applicationName, searchValue);
            }
        }
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.service} ${typeText} 성공`, `${String.service} 정보를 ${typeText}하였습니다.`);
        requestAuthApplicationList();

        type !== "delete" && setIsOpen(false);
    };

    return (
        <div className="pageWrapper">
            <Search
                title={`${String.service} 목록`}
                dataLength={filteredServices?.length || services?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={() => onRow("register", undefined)}
            />
            <DataTable
                rowKey={(row: TypeDTO.ApplicationDto) => row.applicationName}
                disabledTitle
                loading={loadingAuthApplicationList}
                columns={columns}
                dataSource={filteredServices || services}
                onRow={(value) => onRow("view", value)}
            />
            <CustomModal
                title={String.service}
                open={isOpen}
                type={type}
                value={type === "view" ? { ...service, managerPhone: Utils.convertPhone(service?.managerPhone) } : service}
                hasButton="license"
                isLicense={isLicense}
                contents={contents}
                onChangedOpen={() => {
                    setIsOpen(false);
                    setLicense(false);
                }}
                onChangedType={(value) => setType(value)}
                onFinish={onFinish}
                onClick={(value) => (value === "license" ? setLicense(true) : setLicense(false))}
            />
        </div>
    );
};

export default PageService;
