import { useEffect, useState } from "react";
import Menu from "antd/es/menu/index";
import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthDispatch, useAuthState } from "../../provider/AuthProvider";
import type { MenuProps } from "antd/es/menu/menu";

import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import iconMonitor from "../../assets/images/icon/icon_monitor.png";
import iconTruck from "../../assets/images/icon/icon_truck.png";
import iconTool from "../../assets/images/icon/icon_tool.png";
import iconUser from "../../assets/images/icon/icon_user.png";
import MyPagePopup from "../../components/mobile/MyPagePopup";
import styles from "./PageMenu.module.css";

type MenuItem = Required<MenuProps>["items"][number];

function PageMenu() {
    const location = useLocation();
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const dispatch = useAuthDispatch();

    const [openKeys, setOpenKeys] = useState([String.menu_dashboard]);
    const [current, setCurrent] = useState<Array<string>>([Common.PAGE_DASHBOARD]);
    const [openMyPage, setOpenMyPage] = useState<boolean>(false);

    const menu = {
        Dashboard: String.menu_dashboard,
        RunningHistory: String.menu_running_history,
        CarInfo: String.menuCarInfo,
        Maintenance: String.menu_maintenance,
        MaintenanceHistoryByCar: String.menu_maintenance_history_by_car,
        MaintenanceHistory: String.menu_maintenance_history,
        SOC: String.menu_soc,
        Alarm: String.menu_alarm,
        MaintenanceGuide: String.menu_maintenance_guide,
        Stats: String.menu_stats,
        Device: String.menu_device,
        Terminal: String.menu_terminal,
        Doormodule: String.menu_doormodule,
        Modem: String.menu_modem,
        Setting: String.menu_system_setting,
        Category: String.menu_category,
        Maker: String.menu_maker,
        Customer: String.menu_customer,
        User: String.menu_user,
        Group: String.menu_group,
        Service: String.menu_service,
        Version: String.menu_version,
        MyPage: String.menu_mypage,
        Logout: String.logout,
    };

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLOSE_ALL_VIEW, handleCloseAllView);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_VIEW, handleCloseAllView);
        };
    }, []);

    useEffect(() => {
        if (openMyPage) {
            Utils.addViewStack();
        } else {
            Utils.popViewStack();
        }
    }, [openMyPage]);

    const handleCloseAllView = () => {
        setOpenMyPage(false);
    };

    const onOpenChange = (keys: string[]) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    const changeOpenKeys = (mainMenu: string, subMenu: string) => {
        setOpenKeys([mainMenu]);
        setCurrent([subMenu]);
    };

    const roleCheck = (permissionRole: string[]): boolean => {
        return permissionRole.includes(userDetails?.user?.authority);
    };

    const customerCheck = (customer: string[]): boolean => {
        return customer.includes(userDetails?.user?.customer?.customerName);
    };

    const getValueByKey = (key: string) => {
        if (key in Utils.convertAuthority) {
            return Utils.convertAuthority[key as keyof typeof Utils.convertAuthority];
        }
        return "";
    };

    useEffect(() => {
        const path = location.pathname;

        if (path === Common.PAGE_DASHBOARD) {
            changeOpenKeys(menu.Dashboard, menu.Dashboard);
        } else if (path.includes(Common.PAGE_RUNNING)) {
            changeOpenKeys(menu.RunningHistory, menu.RunningHistory);
        } else if (path.includes(Common.PAGE_MANAGE_CAR)) {
            changeOpenKeys(menu.CarInfo, menu.CarInfo);
        } else if (
            path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR) ||
            path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/")
        ) {
            changeOpenKeys(menu.Maintenance, menu.MaintenanceHistoryByCar);
        } else if (path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY)) {
            changeOpenKeys(menu.Maintenance, menu.MaintenanceHistory);
        } else if (path.includes(Common.PAGE_ISSUE_SOCS)) {
            changeOpenKeys(menu.Maintenance, menu.SOC);
        } else if (path.includes(Common.PAGE_ALARM)) {
            changeOpenKeys(menu.Maintenance, menu.Alarm);
        } else if (path.includes(Common.PAGE_MAINTENANCE_GUIDES)) {
            changeOpenKeys(menu.Maintenance, menu.MaintenanceGuide);
        }
    }, [location]);

    const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    };

    const handleLogout = () => {
        Modal.confirm({
            title: String.logout,
            content: "로그아웃 하시겠습니까?",
            okText: "확인",
            onOk() {
                if (dispatch !== null) dispatch({ type: "LOGOUT" });
                navigate(Common.PAGE_LOGIN);
            },
            cancelText: "취소",
            onCancel() {},
            centered: true,
        });
    };

    const menus: MenuItem[] = [
        getItem(
            <p>{String.menu_dashboard}</p>,
            String.menu_dashboard,
            <div className="menuIconWrapper">
                <img alt="" src={iconMonitor} width={16} />
            </div>
        ),
        getItem(
            <p>{String.carInfo}</p>,
            String.carInfo,
            <div className="menuIconWrapper">
                <img alt="" src={iconTruck} width={16} />
            </div>
        ),
        getItem(
            String.menu_maintenance,
            String.menu_maintenance,
            <div className="menuIconWrapper">
                <img alt="menuMaintenance" src={iconTool} width={16} />
            </div>,
            [
                getItem(<p>{String.menu_maintenance_history_by_car}</p>, String.menu_maintenance_history_by_car),
                getItem(<p>{String.menu_maintenance_history}</p>, String.menu_maintenance_history),

                !roleCheck([Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER, Utils.getAuthority.ROLE_USER]) &&
                !customerCheck(["RUTAS", "이엠지"])
                    ? null
                    : getItem(<p>{String.menu_soc}</p>, String.menu_soc),
                getItem(<p>{String.menu_alarm}</p>, String.menu_alarm),
                getItem(<p>{String.menu_maintenance_guide}</p>, String.menu_maintenance_guide),
            ]
        ),
        getItem(
            <Space className={styles.userWrapper} direction="vertical" size={4}>
                <span className="fw-rg fs-xs">{getValueByKey(userDetails?.user?.authority)}</span>
                <span>{userDetails?.user?.name}</span>
            </Space>,
            userDetails?.user?.name,
            <div className="menuIconWrapper">
                <img alt="" width={16} src={iconUser} />
            </div>,
            [
                getItem(<div>{String.menu_mypage}</div>, String.menu_mypage),
                getItem(<div className="fc-danger">{String.logout}</div>, String.logout),
            ]
        ),
    ];

    const onSelect = ({ item, key }: { item: any; key: React.Key }) => {
        switch (key) {
            case menu.Dashboard:
                navigate(Common.PAGE_DASHBOARD);
                break;

            case menu.RunningHistory:
                navigate(Common.PAGE_RUNNING);
                break;

            case menu.CarInfo:
                navigate(Common.PAGE_MANAGE_CAR);
                break;

            case menu.MaintenanceHistoryByCar:
                navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR);
                break;

            case menu.MaintenanceHistory:
                navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY);
                break;

            case menu.SOC:
                navigate(Common.PAGE_ISSUE_SOCS);
                break;

            case menu.Alarm:
                navigate(Common.PAGE_ALARM);
                break;

            case menu.MaintenanceGuide:
                navigate(Common.PAGE_MAINTENANCE_GUIDES);
                break;

            case menu.Stats:
                navigate(Common.PAGE_STATS);
                break;

            case menu.Terminal:
                navigate(Common.PAGE_MANAGE_TERMINAL);
                break;

            case menu.Doormodule:
                navigate(Common.PAGE_MANAGE_DOORMODULE);
                break;

            case menu.Modem:
                navigate(Common.PAGE_MANAGE_MODEM);
                break;

            case menu.Category:
                navigate(Common.PAGE_MANAGE_CATEGORY);
                break;

            case menu.Maker:
                navigate(Common.PAGE_MANAGE_MAKERS);
                break;

            case menu.Customer:
                navigate(Common.PAGE_MANAGE_CUSTOMER);
                break;

            case menu.User:
                navigate(Common.PAGE_MANAGE_USER);
                break;

            case menu.Group:
                navigate(Common.PAGE_MANAGE_GROUP);
                break;

            case menu.Service:
                navigate(Common.PAGE_MANAGE_SERVICE);
                break;

            case menu.Version:
                navigate(Common.PAGE_MANAGE_VERSION);
                break;

            case menu.MyPage:
                setOpenMyPage(true);
                break;

            case menu.Logout:
                handleLogout();
                break;

            default:
                break;
        }
    };

    return (
        <div className={styles.menuWrapper}>
            <Menu
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                defaultSelectedKeys={[String.menu_dashboard]}
                selectedKeys={current}
                mode="inline"
                theme="light"
                items={menus}
                onSelect={onSelect}
            />
            {openMyPage && <MyPagePopup type="view" onChangeOpen={(isOpen) => setOpenMyPage(isOpen)} />}
        </div>
    );
}

export default PageMenu;
