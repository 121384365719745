import { useEffect, useState } from "react";

import moment from "moment";

import * as CanDataNavya from "../../commons/CanDataNavya";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";

import CanDataWrapper, { CanTableType } from "./CanDataWrapper";
import Space from "antd/es/space/index";

function CanDataNavyaViewer({
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataNavya.CanData>();

    const convertRemarkWithUnit = (value: number, unit: string) => {
        return value + unit;
    };

    //#region Bms Command 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [speedPanelOpen, setVeh1PanelOpen] = useState(true);
    const [speedChartMounted, setVeh1ChartMounted] = useState(false);
    const [selectedVeh1, setSelectedVeh1] = useState<Array<React.Key>>([Common.NAVYA_SPEED_KEY]);

    const [seriesChartDatasVeh1, setSeriesChartDatasVeh1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVeh1BatteryPackCurrent, setChartVeh1BatteryPackCurrent] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_SPEED_NAME,
        data: [],
    });

    const getTabelTooltipVeh1 = (key: React.Key) => {
        switch (key) {
            case Common.NAVYA_SPEED_KEY:
                return (
                    <>
                        <p>
                            SPEED
                            <br />
                            CAN ID:
                            <br />
                            Name: Speed
                        </p>
                        <p>Remarks: km/h</p>
                    </>
                );
        }

        return null;
    };

    const getVeh1BatteryPackCurrentValue = () => {
        if (selectedVeh1.includes(Common.NAVYA_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData?.speed, Common.UNIT_SPEED);
        }
        return "";
    };

    const onChangeVeh1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVeh1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVeh1: Array<TypeUtils.ChartData> = [];

        if (selectedVeh1.includes(Common.NAVYA_SPEED_KEY)) chartDatasVeh1.push(chartVeh1BatteryPackCurrent);

        setSeriesChartDatasVeh1(chartDatasVeh1);
    }, [selectedVeh1, chartVeh1BatteryPackCurrent]);

    const tableDataVeh1: CanTableType[] = [
        {
            key: Common.NAVYA_SPEED_KEY,
            name: Common.NAVYA_SPEED_NAME,
            value: getVeh1BatteryPackCurrentValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ucveMGmp1State1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [ucveMGmp1State1PanelOpen, setUcveMGmp1State1PanelOpen] = useState(true);
    const [ucveMGmp1State1ChartMounted, setUcveMGmp1State1ChartMounted] = useState(false);
    const [selectedUcveMGmp1State1, setSelectedUcveMGmp1State1] = useState<Array<React.Key>>([
        Common.NAVYA_ENGINE_SPEED_KEY,
        Common.NAVYA_ACTUAL_ENGINE_TORQUE_KEY,
        Common.NAVYA_ENGINE_TEMPERATURE_KEY,
        Common.NAVYA_CONTROLLER_TEMPERATURE_KEY,
    ]);

    const [seriesChartDatasUcveMGmp1State1, setSeriesChartDatasUcveMGmp1State1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartUcveMGmp1State1EngineSpeed, setChartUcveMGmp1State1EngineSpeed] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_ENGINE_SPEED_NAME,
        data: [],
    });

    const [chartUcveMGmp1State1ActualEngineTorque, setChartUcveMGmp1State1ActualEngineTorque] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_ACTUAL_ENGINE_TORQUE_NAME,
        data: [],
    });

    const [chartUcveMGmp1State1EngineTemperature, setChartUcveMGmp1State1EngineTemperature] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_ENGINE_TEMPERATURE_NAME,
        data: [],
    });

    const [chartUcveMGmp1State1ControllerTemperature, setChartUcveMGmp1State1ControllerTemperature] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_CONTROLLER_TEMPERATURE_NAME,
        data: [],
    });

    const getTabelTooltipUcveMGmp1State1 = (key: React.Key) => {
        switch (key) {
            case Common.NAVYA_ENGINE_SPEED_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_GMP1_State1
                            <br />
                            CAN ID: 0x1D3
                            <br />
                            Name: Engine Speed
                        </p>
                        <p>Remarks: RPM (-10000 ~ 10000)</p>
                    </>
                );

            case Common.NAVYA_ACTUAL_ENGINE_TORQUE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_GMP1_State1
                            <br />
                            CAN ID: 0x1D3
                            <br />
                            Name: Actual Engine Percent Torque
                        </p>
                        <p>Remarks: % (-100 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_ENGINE_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_GMP1_State1
                            <br />
                            CAN ID: 0x1D3
                            <br />
                            Name: Engine Temperature
                        </p>
                        <p>Remarks: °C(-20 ~ 220)</p>
                    </>
                );

            case Common.NAVYA_CONTROLLER_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_GMP1_State1
                            <br />
                            CAN ID: 0x1D3
                            <br />
                            Name: Controller Temperature
                        </p>
                        <p>Remarks: °C(-20 ~ 220)</p>
                    </>
                );
        }

        return null;
    };

    const getUcveMGmp1State1EngineSpeedValue = () => {
        if (selectedUcveMGmp1State1.includes(Common.NAVYA_ENGINE_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMGmp1State1?.engineSpeed, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getUcveMGmp1State1ActualEngineTorqueValue = () => {
        if (selectedUcveMGmp1State1.includes(Common.NAVYA_ACTUAL_ENGINE_TORQUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMGmp1State1?.actualEnginePercentTorque, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getUcveMGmp1State1EngineTemperatureValue = () => {
        if (selectedUcveMGmp1State1.includes(Common.NAVYA_ENGINE_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMGmp1State1?.engineTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getUcveMGmp1State1ControllerTemperatureValue = () => {
        if (selectedUcveMGmp1State1.includes(Common.NAVYA_CONTROLLER_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMGmp1State1?.controllerTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const onChangeUcveMGmp1State1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedUcveMGmp1State1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasUcveMGmp1State1: Array<TypeUtils.ChartData> = [];

        if (selectedUcveMGmp1State1.includes(Common.NAVYA_ENGINE_SPEED_KEY)) chartDatasUcveMGmp1State1.push(chartUcveMGmp1State1EngineSpeed);
        if (selectedUcveMGmp1State1.includes(Common.NAVYA_ACTUAL_ENGINE_TORQUE_KEY))
            chartDatasUcveMGmp1State1.push(chartUcveMGmp1State1ActualEngineTorque);
        if (selectedUcveMGmp1State1.includes(Common.NAVYA_ENGINE_TEMPERATURE_KEY))
            chartDatasUcveMGmp1State1.push(chartUcveMGmp1State1EngineTemperature);
        if (selectedUcveMGmp1State1.includes(Common.NAVYA_CONTROLLER_TEMPERATURE_KEY))
            chartDatasUcveMGmp1State1.push(chartUcveMGmp1State1ControllerTemperature);

        setSeriesChartDatasUcveMGmp1State1(chartDatasUcveMGmp1State1);
    }, [
        selectedUcveMGmp1State1,
        chartUcveMGmp1State1EngineSpeed,
        chartUcveMGmp1State1ActualEngineTorque,
        chartUcveMGmp1State1EngineTemperature,
        chartUcveMGmp1State1ControllerTemperature,
    ]);

    const tableDataUcveMGmp1State1: CanTableType[] = [
        {
            key: Common.NAVYA_ENGINE_SPEED_KEY,
            name: Common.NAVYA_ENGINE_SPEED_NAME,
            value: getUcveMGmp1State1EngineSpeedValue(),
        },
        {
            key: Common.NAVYA_ACTUAL_ENGINE_TORQUE_KEY,
            name: Common.NAVYA_ACTUAL_ENGINE_TORQUE_NAME,
            value: getUcveMGmp1State1ActualEngineTorqueValue(),
        },
        {
            key: Common.NAVYA_ENGINE_TEMPERATURE_KEY,
            name: Common.NAVYA_ENGINE_TEMPERATURE_NAME,
            value: getUcveMGmp1State1EngineTemperatureValue(),
        },
        {
            key: Common.NAVYA_CONTROLLER_TEMPERATURE_KEY,
            name: Common.NAVYA_CONTROLLER_TEMPERATURE_NAME,
            value: getUcveMGmp1State1ControllerTemperatureValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ucveMGmp1State1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [ucveMSysBat1State1PanelOpen, setUcveMSysBat1State1PanelOpen] = useState(true);
    const [ucveMSysBat1State1ChartMounted, setUcveMSysBat1State1ChartMounted] = useState(false);
    const [selectedUcveMSysBat1State1, setSelectedUcveMSysBat1State1] = useState<Array<React.Key>>([
        Common.NAVYA_BATSYS1_MODE_KEY,
        Common.NAVYA_BATSYS1_SOC_KEY,
        Common.NAVYA_BATSYS1_SOH_KEY,
        Common.NAVYA_BATSYS1_VOLT_REAR_KEY,
        Common.NAVYA_BATSYS1_CURR_REAR_KEY,
        Common.NAVYA_BATSYS1_MAX_TEMP_REAR_KEY,
        Common.NAVYA_BATSYS3_MIN_TEMP_REAR_KEY,
    ]);

    const [seriesChartDatasUcveMSysBat1State1, setSeriesChartDatasUcveMSysBat1State1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartUcveMSysBat1State1Mode, setChartUcveMSysBat1State1Mode] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_BATSYS1_MODE_NAME,
        data: [],
    });

    const [chartUcveMSysBat1State1Soc, setChartUcveMSysBat1State1Soc] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_BATSYS1_SOC_NAME,
        data: [],
    });

    const [chartUcveMSysBat1State1Soh, setChartUcveMSysBat1State1Soh] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_BATSYS1_SOH_NAME,
        data: [],
    });

    const [chartUcveMSysBat1State1VoltRear, setChartUcveMSysBat1State1VoltRear] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_BATSYS1_VOLT_REAR_NAME,
        data: [],
    });

    const [chartUcveMSysBat1State1CurrRear, setChartUcveMSysBat1State1CurrRear] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_BATSYS1_CURR_REAR_NAME,
        data: [],
    });

    const [chartUcveMSysBat1State1MaxTemperatureRear, setChartUcveMSysBat1State1MaxTemperatureRear] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_BATSYS1_MAX_TEMP_REAR_NAME,
        data: [],
    });

    const [chartUcveMSysBat1State3MinTemperatureRear, setChartUcveMSysBat1State3MinTemperatureRear] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_BATSYS3_MIN_TEMP_REAR_NAME,
        data: [],
    });

    const getTabelTooltipUcveMSysBat1State1 = (key: React.Key) => {
        switch (key) {
            case Common.NAVYA_BATSYS1_MODE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_Sysbatt1_State1
                            <br />
                            CAN ID: 0x3A3
                            <br />
                            Name: Mode
                        </p>
                    </>
                );

            case Common.NAVYA_BATSYS1_SOC_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_Sysbatt1_State1
                            <br />
                            CAN ID: 0x3A3
                            <br />
                            Name: SOC
                        </p>
                        <p>Remarks: % (0 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_BATSYS1_SOH_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_Sysbatt1_State1
                            <br />
                            CAN ID: 0x3A3
                            <br />
                            Name: SOH
                        </p>
                        <p>Remarks: % (0 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_BATSYS1_VOLT_REAR_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_Sysbatt1_State1
                            <br />
                            CAN ID: 0x3A3
                            <br />
                            Name: Voltage Rear
                        </p>
                        <p>Remarks: V (0 ~ 105)</p>
                    </>
                );

            case Common.NAVYA_BATSYS1_CURR_REAR_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_Sysbatt1_State1
                            <br />
                            CAN ID: 0x3A3
                            <br />
                            Name: Current Rear
                        </p>
                        <p>Remarks: A (-300 ~ 150)</p>
                    </>
                );

            case Common.NAVYA_BATSYS1_MAX_TEMP_REAR_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_Sysbatt1_State1
                            <br />
                            CAN ID: 0x3A3
                            <br />
                            Name: Max Temperature Module Rear
                        </p>
                        <p>Remarks: °C(-20 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_BATSYS3_MIN_TEMP_REAR_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_Sysbatt1_State3
                            <br />
                            CAN ID: 0x3E3
                            <br />
                            Name: Min Temperature Module Rear
                        </p>
                        <p>Remarks: °C(-20 ~ 100)</p>
                    </>
                );
        }

        return null;
    };

    const getUcveMSysBat1State1ModeValue = () => {
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_MODE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.ucveMSysBat1State1?.batSys1Mode);
        }
        return "";
    };

    const getUcveMSysBat1State1SocValue = () => {
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMSysBat1State1?.batSys1Soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getUcveMSysBat1State1SohValue = () => {
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_SOH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMSysBat1State1?.batSys1Soh, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getUcveMSysBat1State1VoltRearValue = () => {
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_VOLT_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMSysBat1State1?.batVoltRear, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getUcveMSysBat1State1CurrRearValue = () => {
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_CURR_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMSysBat1State1?.batCurrRear, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getUcveMSysBat1State1MaxTemperatureRearValue = () => {
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_MAX_TEMP_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMSysBat1State1?.batMaxTempModuleRear, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getUcveMSysBat1State3MinTemperatureRearValue = () => {
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS3_MIN_TEMP_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMSysBat1State3?.batMinTempModuleRear, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const onChangeUcveMSysBat1State1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedUcveMSysBat1State1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasUcveMSysBat1State1: Array<TypeUtils.ChartData> = [];

        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_MODE_KEY)) chartDatasUcveMSysBat1State1.push(chartUcveMSysBat1State1Mode);
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_SOC_KEY)) chartDatasUcveMSysBat1State1.push(chartUcveMSysBat1State1Soc);
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_SOH_KEY)) chartDatasUcveMSysBat1State1.push(chartUcveMSysBat1State1Soh);
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_VOLT_REAR_KEY))
            chartDatasUcveMSysBat1State1.push(chartUcveMSysBat1State1VoltRear);
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_CURR_REAR_KEY))
            chartDatasUcveMSysBat1State1.push(chartUcveMSysBat1State1CurrRear);
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS1_MAX_TEMP_REAR_KEY))
            chartDatasUcveMSysBat1State1.push(chartUcveMSysBat1State1MaxTemperatureRear);
        if (selectedUcveMSysBat1State1.includes(Common.NAVYA_BATSYS3_MIN_TEMP_REAR_KEY))
            chartDatasUcveMSysBat1State1.push(chartUcveMSysBat1State3MinTemperatureRear);

        setSeriesChartDatasUcveMSysBat1State1(chartDatasUcveMSysBat1State1);
    }, [
        selectedUcveMSysBat1State1,
        chartUcveMSysBat1State1Mode,
        chartUcveMSysBat1State1Soc,
        chartUcveMSysBat1State1Soh,
        chartUcveMSysBat1State1VoltRear,
        chartUcveMSysBat1State1CurrRear,
        chartUcveMSysBat1State1MaxTemperatureRear,
        chartUcveMSysBat1State3MinTemperatureRear,
    ]);

    const tableDataUcveMSysBat1State1: CanTableType[] = [
        {
            key: Common.NAVYA_BATSYS1_MODE_KEY,
            name: Common.NAVYA_BATSYS1_MODE_NAME,
            value: getUcveMSysBat1State1ModeValue(),
        },
        {
            key: Common.NAVYA_BATSYS1_SOC_KEY,
            name: Common.NAVYA_BATSYS1_SOC_NAME,
            value: getUcveMSysBat1State1SocValue(),
        },
        {
            key: Common.NAVYA_BATSYS1_SOH_KEY,
            name: Common.NAVYA_BATSYS1_SOH_NAME,
            value: getUcveMSysBat1State1SohValue(),
        },
        {
            key: Common.NAVYA_BATSYS1_VOLT_REAR_KEY,
            name: Common.NAVYA_BATSYS1_VOLT_REAR_NAME,
            value: getUcveMSysBat1State1VoltRearValue(),
        },
        {
            key: Common.NAVYA_BATSYS1_CURR_REAR_KEY,
            name: Common.NAVYA_BATSYS1_CURR_REAR_NAME,
            value: getUcveMSysBat1State1CurrRearValue(),
        },
        {
            key: Common.NAVYA_BATSYS1_MAX_TEMP_REAR_KEY,
            name: Common.NAVYA_BATSYS1_MAX_TEMP_REAR_NAME,
            value: getUcveMSysBat1State1MaxTemperatureRearValue(),
        },
        {
            key: Common.NAVYA_BATSYS3_MIN_TEMP_REAR_KEY,
            name: Common.NAVYA_BATSYS3_MIN_TEMP_REAR_NAME,
            value: getUcveMSysBat1State3MinTemperatureRearValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ucveMModeState
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [ucveMModeStatePanelOpen, setUcveMModeStatePanelOpen] = useState(true);
    const [ucveMModeStateChartMounted, setUcveMModeStateChartMounted] = useState(false);
    const [selectedUcveMModeState, setSelectedUcveMModeState] = useState<Array<React.Key>>([
        Common.NAVYA_MODE_STATE_VEHICLE_MODE_KEY,
        Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_KEY,
        Common.NAVYA_MODE_STATE_EPROM_FORMATED_KEY,
        Common.NAVYA_MODE_STATE_DEFAULT_ACK_KEY,
        Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_KEY,
        Common.NAVYA_MODE_STATE_ALERT_PRESENCE_KEY,
        Common.NAVYA_MODE_STATE_ALARM_PRESENCE_KEY,
        Common.NAVYA_MODE_STATE_URDB_KEY,
        Common.NAVYA_MODE_STATE_IRDB_KEY,
    ]);

    const [seriesChartDatasUcveMModeState, setSeriesChartDatasUcveMModeState] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartUcveMModeStateVehicleMode, setChartUcveMModeStateVehicleMode] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_VEHICLE_MODE_NAME,
        data: [],
    });

    const [chartUcveMModeStateVehicleModeAck, setChartUcveMModeStateVehicleModeAck] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_NAME,
        data: [],
    });

    const [chartUcveMModeStateEpromFormated, setChartUcveMModeStateEpromFormated] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_EPROM_FORMATED_NAME,
        data: [],
    });

    const [chartUcveMModeStateDefaultAck, setChartUcveMModeStateDefaultAck] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_DEFAULT_ACK_NAME,
        data: [],
    });

    const [chartUcveMModeStateNotificationPresence, setChartUcveMModeStateNotificationPresence] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_NAME,
        data: [],
    });

    const [chartUcveMModeStateAlertPresence, setChartUcveMModeStateAlertPresence] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_ALERT_PRESENCE_NAME,
        data: [],
    });

    const [chartUcveMModeStateAlarmPresence, setChartUcveMModeStateAlarmPresence] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_ALARM_PRESENCE_NAME,
        data: [],
    });

    const [chartUcveMModeStateUrdb, setChartUcveMModeStateUrdb] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_URDB_NAME,
        data: [],
    });

    const [chartUcveMModeStateIrdb, setChartUcveMModeStateIrdb] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_MODE_STATE_IRDB_NAME,
        data: [],
    });

    const getTabelTooltipUcveMModeState = (key: React.Key) => {
        switch (key) {
            case Common.NAVYA_MODE_STATE_VEHICLE_MODE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: Vehicle Mode
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=off, 2=standby,
                            <br />
                            4=use, 7=safety
                        </p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: Vehicle Mode Acknowledged
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=off, 2=standby,
                            <br />
                            4=use, 7=safety
                        </p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_EPROM_FORMATED_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: EPROM formated
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=알림 없음, 1=알림발생
                        </p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_DEFAULT_ACK_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: Default Acknowledgable
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=알림 없음, 1=알림발생
                        </p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: Notification Presence
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=알림 없음, 1=알림발생
                        </p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_ALERT_PRESENCE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: Alert Presence
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=알림 없음, 1=알림발생
                        </p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_ALARM_PRESENCE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: Alarm Presence
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=알림 없음, 1=알림발생
                        </p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_URDB_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: URdB
                        </p>
                        <p>Remarks: V (7 ~ 19.7)</p>
                    </>
                );

            case Common.NAVYA_MODE_STATE_IRDB_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_ModeStates
                            <br />
                            CAN ID: 0x483
                            <br />
                            Name: IRdB
                        </p>
                        <p>Remarks: A (-200 ~ 204.7)</p>
                    </>
                );
        }

        return null;
    };

    const convertVehicleModeRemark = (value: number) => {
        let valueString = "Unknown";
        switch (value) {
            case 0:
                valueString = "Off";
                break;
            case 2:
                valueString = "Standby";
                break;
            case 4:
                valueString = "Use";
                break;
            case 7:
                valueString = "Safety";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertAlarmRemark = (value: number) => {
        let valueString = "Unknown";
        switch (value) {
            case 0:
                valueString = "알람 없음";
                break;
            case 1:
                valueString = "알람 발생";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getUcveMModeStateVehicleModeValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_VEHICLE_MODE_KEY) && currentCanData !== undefined) {
            return convertVehicleModeRemark(currentCanData.ucveMModeState?.vehicleMode);
        }
        return "";
    };

    const getUcveMModeStateVehicleModeAckValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_KEY) && currentCanData !== undefined) {
            return convertVehicleModeRemark(currentCanData.ucveMModeState?.vehicleModeAcknowledged);
        }
        return "";
    };

    const getUcveMModeStateEpromFormatedValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_EPROM_FORMATED_KEY) && currentCanData !== undefined) {
            return convertAlarmRemark(currentCanData.ucveMModeState?.epromFormated);
        }
        return "";
    };

    const getUcveMModeStateDefaultAckValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_DEFAULT_ACK_KEY) && currentCanData !== undefined) {
            return convertAlarmRemark(currentCanData.ucveMModeState?.defaultAcknowledgeable);
        }
        return "";
    };

    const getUcveMModeStateNotificationPresenceValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_KEY) && currentCanData !== undefined) {
            return convertAlarmRemark(currentCanData.ucveMModeState?.notificationPresence);
        }
        return "";
    };

    const getUcveMModeStateAlertPresenceValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_ALERT_PRESENCE_KEY) && currentCanData !== undefined) {
            return convertAlarmRemark(currentCanData.ucveMModeState?.alertPresence);
        }
        return "";
    };

    const getUcveMModeStateAlarmPresenceValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_ALARM_PRESENCE_KEY) && currentCanData !== undefined) {
            return convertAlarmRemark(currentCanData.ucveMModeState?.alarmPresence);
        }
        return "";
    };

    const getUcveMModeStateUrdbValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_URDB_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMModeState?.urdb, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getUcveMModeStateIrdbValue = () => {
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_IRDB_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMModeState?.irdb, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeUcveMModeState = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedUcveMModeState(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasUcveMModeState: Array<TypeUtils.ChartData> = [];

        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_VEHICLE_MODE_KEY)) chartDatasUcveMModeState.push(chartUcveMModeStateVehicleMode);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_KEY))
            chartDatasUcveMModeState.push(chartUcveMModeStateVehicleModeAck);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_EPROM_FORMATED_KEY))
            chartDatasUcveMModeState.push(chartUcveMModeStateEpromFormated);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_DEFAULT_ACK_KEY)) chartDatasUcveMModeState.push(chartUcveMModeStateDefaultAck);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_KEY))
            chartDatasUcveMModeState.push(chartUcveMModeStateNotificationPresence);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_ALERT_PRESENCE_KEY))
            chartDatasUcveMModeState.push(chartUcveMModeStateAlertPresence);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_ALARM_PRESENCE_KEY))
            chartDatasUcveMModeState.push(chartUcveMModeStateAlarmPresence);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_URDB_KEY)) chartDatasUcveMModeState.push(chartUcveMModeStateUrdb);
        if (selectedUcveMModeState.includes(Common.NAVYA_MODE_STATE_IRDB_KEY)) chartDatasUcveMModeState.push(chartUcveMModeStateIrdb);

        setSeriesChartDatasUcveMModeState(chartDatasUcveMModeState);
    }, [
        selectedUcveMModeState,
        chartUcveMModeStateVehicleMode,
        chartUcveMModeStateVehicleModeAck,
        chartUcveMModeStateEpromFormated,
        chartUcveMModeStateDefaultAck,
        chartUcveMModeStateNotificationPresence,
        chartUcveMModeStateAlertPresence,
        chartUcveMModeStateAlarmPresence,
        chartUcveMModeStateUrdb,
        chartUcveMModeStateIrdb,
    ]);

    const tableDataUcveMModeState: CanTableType[] = [
        {
            key: Common.NAVYA_MODE_STATE_VEHICLE_MODE_KEY,
            name: Common.NAVYA_MODE_STATE_VEHICLE_MODE_NAME,
            value: getUcveMModeStateVehicleModeValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_KEY,
            name: Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_NAME,
            value: getUcveMModeStateVehicleModeAckValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_EPROM_FORMATED_KEY,
            name: Common.NAVYA_MODE_STATE_EPROM_FORMATED_NAME,
            value: getUcveMModeStateEpromFormatedValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_DEFAULT_ACK_KEY,
            name: Common.NAVYA_MODE_STATE_DEFAULT_ACK_NAME,
            value: getUcveMModeStateDefaultAckValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_KEY,
            name: Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_NAME,
            value: getUcveMModeStateNotificationPresenceValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_ALERT_PRESENCE_KEY,
            name: Common.NAVYA_MODE_STATE_ALERT_PRESENCE_NAME,
            value: getUcveMModeStateAlertPresenceValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_ALARM_PRESENCE_KEY,
            name: Common.NAVYA_MODE_STATE_ALARM_PRESENCE_NAME,
            value: getUcveMModeStateAlarmPresenceValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_URDB_KEY,
            name: Common.NAVYA_MODE_STATE_URDB_NAME,
            value: getUcveMModeStateUrdbValue(),
        },
        {
            key: Common.NAVYA_MODE_STATE_IRDB_KEY,
            name: Common.NAVYA_MODE_STATE_IRDB_NAME,
            value: getUcveMModeStateIrdbValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ucveMTempVentil
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [ucveMTempVentilPanelOpen, setUcveMTempVentilPanelOpen] = useState(true);
    const [ucveMTempVentilChartMounted, setUcveMTempVentilChartMounted] = useState(false);
    const [selectedUcveMTempVentil, setSelectedUcveMTempVentil] = useState<Array<React.Key>>([
        Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_KEY,
        Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_KEY,
        Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_KEY,
        Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_KEY,
        Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_KEY,
        Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_KEY,
        Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_KEY,
        Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_KEY,
    ]);

    const [seriesChartDatasUcveMTempVentil, setSeriesChartDatasUcveMTempVentil] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartUcveMTempVentilTemperatureBoxNavya, setChartUcveMTempVentilTemperatureBoxNavya] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_NAME,
        data: [],
    });

    const [chartUcveMTempVentilTemperatureBoxEVE, setChartUcveMTempVentilTemperatureBoxEVE] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_NAME,
        data: [],
    });

    const [chartUcveMTempVentilTemperatureCabin, setChartUcveMTempVentilTemperatureCabin] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_NAME,
        data: [],
    });

    const [chartUcveMTempVentilTemperatureOutside, setChartUcveMTempVentilTemperatureOutside] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_NAME,
        data: [],
    });

    const [chartUcveMTempVentilTurbineLvlFront, setChartUcveMTempVentilTurbineLvlFront] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_NAME,
        data: [],
    });

    const [chartUcveMTempVentilTurbineLvlRear, setChartUcveMTempVentilTurbineLvlRear] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_NAME,
        data: [],
    });

    const [chartUcveMTempVentilVentilationLvlBoxNavya, setChartUcveMTempVentilVentilationLvlBoxNavya] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_NAME,
        data: [],
    });

    const [chartUcveMTempVentilVentilationLvlBoxEVE, setChartUcveMTempVentilVentilationLvlBoxEVE] = useState<TypeUtils.ChartData>({
        name: Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_NAME,
        data: [],
    });

    const getTabelTooltipUcveMTempVentil = (key: React.Key) => {
        switch (key) {
            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Temperature Box Navya
                        </p>
                        <p>Remarks: °C (-20 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Temperature Box EVE
                        </p>
                        <p>Remarks: °C (-20 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Temperature Cabin
                        </p>
                        <p>Remarks: °C (-20 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Temperature Outside
                        </p>
                        <p>Remarks: °C (-20 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Turbine Level Front
                        </p>
                        <p>Remarks: % (0 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Turbine Level Rear
                        </p>
                        <p>Remarks: % (0 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Ventilation Level Box Navya
                        </p>
                        <p>Remarks: % (0 ~ 100)</p>
                    </>
                );

            case Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_KEY:
                return (
                    <>
                        <p>
                            UCVE_M_TempVentil
                            <br />
                            CAN ID: 0x4F3
                            <br />
                            Name: Ventilation Level Box EVE
                        </p>
                        <p>Remarks: % (0 ~ 100)</p>
                    </>
                );
        }

        return null;
    };

    const getUcveMTempVentilTemperatureBoxNavyaValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.tempBoxNavya, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getUcveMTempVentilTemperatureBoxEVEValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.tempBoxEve, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getUcveMTempVentilTemperatureCabinValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.tempCabin, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getUcveMTempVentilTemperatureOutsideValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.tempOutside, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getUcveMTempVentilTurbineLvlFrontValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.turbineLvlFront, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getUcveMTempVentilTurbineLvlRearValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.turbineLvlRear, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getUcveMTempVentilVentilationLvlBoxNavyaValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.ventilLvlBoxNavya, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getUcveMTempVentilVentilationLvlBoxEVEValue = () => {
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ucveMTempVentil?.ventilLvlBoxEve, Common.UNIT_PERCENT);
        }
        return "";
    };

    const onChangeUcveMTempVentil = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedUcveMTempVentil(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasUcveMTempVentil: Array<TypeUtils.ChartData> = [];

        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilTemperatureBoxNavya);
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilTemperatureBoxEVE);
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilTemperatureCabin);
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilTemperatureOutside);
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilTurbineLvlFront);
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilTurbineLvlRear);
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilVentilationLvlBoxNavya);
        if (selectedUcveMTempVentil.includes(Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_KEY))
            chartDatasUcveMTempVentil.push(chartUcveMTempVentilVentilationLvlBoxEVE);

        setSeriesChartDatasUcveMTempVentil(chartDatasUcveMTempVentil);
    }, [
        selectedUcveMTempVentil,
        chartUcveMTempVentilTemperatureBoxNavya,
        chartUcveMTempVentilTemperatureBoxEVE,
        chartUcveMTempVentilTemperatureCabin,
        chartUcveMTempVentilTemperatureOutside,
        chartUcveMTempVentilTurbineLvlFront,
        chartUcveMTempVentilTurbineLvlRear,
        chartUcveMTempVentilVentilationLvlBoxNavya,
        chartUcveMTempVentilVentilationLvlBoxEVE,
    ]);

    const tableDataUcveMTempVentil: CanTableType[] = [
        {
            key: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_KEY,
            name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_NAME,
            value: getUcveMTempVentilTemperatureBoxNavyaValue(),
        },
        {
            key: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_KEY,
            name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_NAME,
            value: getUcveMTempVentilTemperatureBoxEVEValue(),
        },
        {
            key: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_KEY,
            name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_NAME,
            value: getUcveMTempVentilTemperatureCabinValue(),
        },
        {
            key: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_KEY,
            name: Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_NAME,
            value: getUcveMTempVentilTemperatureOutsideValue(),
        },
        {
            key: Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_KEY,
            name: Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_NAME,
            value: getUcveMTempVentilTurbineLvlFrontValue(),
        },
        {
            key: Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_KEY,
            name: Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_NAME,
            value: getUcveMTempVentilTurbineLvlRearValue(),
        },
        {
            key: Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_KEY,
            name: Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_NAME,
            value: getUcveMTempVentilVentilationLvlBoxNavyaValue(),
        },
        {
            key: Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_KEY,
            name: Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_NAME,
            value: getUcveMTempVentilVentilationLvlBoxEVEValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    useEffect(() => {
        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Navya) {
            //#region Bms Command 1
            const speedBatteryPackCurrentDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            const ucveMGmp1State1EngineSpeedDatas: TypeUtils.ChartElement[] = [];
            const ucveMGmp1State1ActualEngineTorqueDatas: TypeUtils.ChartElement[] = [];
            const ucveMGmp1State1EngineTemperatureDatas: TypeUtils.ChartElement[] = [];
            const ucveMGmp1State1ControllerTemperatureDatas: TypeUtils.ChartElement[] = [];

            const ucveMSysBat1State1ModeDatas: TypeUtils.ChartElement[] = [];
            const ucveMSysBat1State1SocDatas: TypeUtils.ChartElement[] = [];
            const ucveMSysBat1State1SohDatas: TypeUtils.ChartElement[] = [];
            const ucveMSysBat1State1VoltRearDatas: TypeUtils.ChartElement[] = [];
            const ucveMSysBat1State1CurrRearDatas: TypeUtils.ChartElement[] = [];
            const ucveMSysBat1State1MaxTemperatureRearDatas: TypeUtils.ChartElement[] = [];
            const ucveMSysBat1State3MinTemperatureRearDatas: TypeUtils.ChartElement[] = [];

            const ucveMModeStateVehicleModeDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateVehicleModeAckDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateEpromFormatedDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateDefaultAckDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateNotificationPresenceDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateAlertPresenceDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateAlarmPresenceDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateUrdbDatas: TypeUtils.ChartElement[] = [];
            const ucveMModeStateIrdbDatas: TypeUtils.ChartElement[] = [];

            const ucveMTempVentilTemperatureBoxNavyaDatas: TypeUtils.ChartElement[] = [];
            const ucveMTempVentilTemperatureBoxEVEDatas: TypeUtils.ChartElement[] = [];
            const ucveMTempVentilTemperatureCabinDatas: TypeUtils.ChartElement[] = [];
            const ucveMTempVentilTemperatureOutsideDatas: TypeUtils.ChartElement[] = [];
            const ucveMTempVentilTurbineLvlFrontDatas: TypeUtils.ChartElement[] = [];
            const ucveMTempVentilTurbineLvlRearDatas: TypeUtils.ChartElement[] = [];
            const ucveMTempVentilVentilationLvlBoxNavyaDatas: TypeUtils.ChartElement[] = [];
            const ucveMTempVentilVentilationLvlBoxEVEDatas: TypeUtils.ChartElement[] = [];

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataNavya.CanData;

                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region Bms Command 1
                speedBatteryPackCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData?.speed || 0,
                });
                //#endregion

                //#region ucveMGmp1State1
                ucveMGmp1State1EngineSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMGmp1State1?.engineSpeed || 0,
                });
                ucveMGmp1State1ActualEngineTorqueDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMGmp1State1?.actualEnginePercentTorque || 0,
                });
                ucveMGmp1State1EngineTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMGmp1State1?.engineTemp || 0,
                });
                ucveMGmp1State1ControllerTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMGmp1State1?.controllerTemp || 0,
                });
                //#endregion

                //#region ucveMSysBat1State1
                ucveMSysBat1State1ModeDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMSysBat1State1?.batSys1Mode || 0,
                });
                ucveMSysBat1State1SocDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMSysBat1State1?.batSys1Soc || 0,
                });
                ucveMSysBat1State1SohDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMSysBat1State1?.batSys1Soh || 0,
                });
                ucveMSysBat1State1VoltRearDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMSysBat1State1?.batVoltRear || 0,
                });
                ucveMSysBat1State1CurrRearDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMSysBat1State1?.batCurrRear || 0,
                });
                ucveMSysBat1State1MaxTemperatureRearDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMSysBat1State1?.batMaxTempModuleRear || 0,
                });

                ucveMSysBat1State3MinTemperatureRearDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMSysBat1State3?.batMinTempModuleRear || 0,
                });
                //#endregion

                //#region ucveMSysBat1State1
                ucveMModeStateVehicleModeDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.vehicleMode || 0,
                });
                ucveMModeStateVehicleModeAckDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.vehicleModeAcknowledged || 0,
                });
                ucveMModeStateEpromFormatedDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.epromFormated || 0,
                });
                ucveMModeStateDefaultAckDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.defaultAcknowledgeable || 0,
                });
                ucveMModeStateNotificationPresenceDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.notificationPresence || 0,
                });
                ucveMModeStateAlertPresenceDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.alertPresence || 0,
                });
                ucveMModeStateAlarmPresenceDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.alarmPresence || 0,
                });
                ucveMModeStateUrdbDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.urdb || 0,
                });
                ucveMModeStateIrdbDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMModeState?.irdb || 0,
                });
                //#endregion

                //#region ucveMTempVentil
                ucveMTempVentilTemperatureBoxNavyaDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.tempBoxNavya || 0,
                });
                ucveMTempVentilTemperatureBoxEVEDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.tempBoxEve || 0,
                });
                ucveMTempVentilTemperatureCabinDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.tempCabin || 0,
                });
                ucveMTempVentilTemperatureOutsideDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.tempOutside || 0,
                });
                ucveMTempVentilTurbineLvlFrontDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.turbineLvlFront || 0,
                });
                ucveMTempVentilTurbineLvlRearDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.turbineLvlRear || 0,
                });
                ucveMTempVentilVentilationLvlBoxNavyaDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.ventilLvlBoxNavya || 0,
                });
                ucveMTempVentilVentilationLvlBoxEVEDatas.push({
                    x: time.valueOf(),
                    y: canData.ucveMTempVentil?.ventilLvlBoxEve || 0,
                });
                //#endregion
            });

            //#region Bms Command 1
            setChartVeh1BatteryPackCurrent({
                ...chartVeh1BatteryPackCurrent,
                data: speedBatteryPackCurrentDatas,
            });
            //#endregion

            //#region ucveMGmp1State1
            setChartUcveMGmp1State1EngineSpeed({
                ...chartUcveMGmp1State1EngineSpeed,
                data: ucveMGmp1State1EngineSpeedDatas,
            });
            setChartUcveMGmp1State1ActualEngineTorque({
                ...chartUcveMGmp1State1ActualEngineTorque,
                data: ucveMGmp1State1ActualEngineTorqueDatas,
            });
            setChartUcveMGmp1State1EngineTemperature({
                ...chartUcveMGmp1State1EngineTemperature,
                data: ucveMGmp1State1EngineTemperatureDatas,
            });
            setChartUcveMGmp1State1ControllerTemperature({
                ...chartUcveMGmp1State1ControllerTemperature,
                data: ucveMGmp1State1ControllerTemperatureDatas,
            });
            //#endregion

            //#region ucveMSysBat1State1
            setChartUcveMSysBat1State1Mode({
                ...chartUcveMSysBat1State1Mode,
                data: ucveMSysBat1State1ModeDatas,
            });
            setChartUcveMSysBat1State1Soc({
                ...chartUcveMSysBat1State1Soc,
                data: ucveMSysBat1State1SocDatas,
            });
            setChartUcveMSysBat1State1Soh({
                ...chartUcveMSysBat1State1Soh,
                data: ucveMSysBat1State1SohDatas,
            });
            setChartUcveMSysBat1State1VoltRear({
                ...chartUcveMSysBat1State1VoltRear,
                data: ucveMSysBat1State1VoltRearDatas,
            });
            setChartUcveMSysBat1State1CurrRear({
                ...chartUcveMSysBat1State1CurrRear,
                data: ucveMSysBat1State1CurrRearDatas,
            });
            setChartUcveMSysBat1State1MaxTemperatureRear({
                ...chartUcveMSysBat1State1MaxTemperatureRear,
                data: ucveMSysBat1State1MaxTemperatureRearDatas,
            });
            setChartUcveMSysBat1State3MinTemperatureRear({
                ...chartUcveMSysBat1State3MinTemperatureRear,
                data: ucveMSysBat1State3MinTemperatureRearDatas,
            });
            //#endregion

            //#region ucveMModeState
            setChartUcveMModeStateVehicleMode({
                ...chartUcveMModeStateVehicleMode,
                data: ucveMModeStateVehicleModeDatas,
            });
            setChartUcveMModeStateVehicleModeAck({
                ...chartUcveMModeStateVehicleModeAck,
                data: ucveMModeStateVehicleModeAckDatas,
            });
            setChartUcveMModeStateEpromFormated({
                ...chartUcveMModeStateEpromFormated,
                data: ucveMModeStateEpromFormatedDatas,
            });
            setChartUcveMModeStateDefaultAck({
                ...chartUcveMModeStateDefaultAck,
                data: ucveMModeStateDefaultAckDatas,
            });
            setChartUcveMModeStateNotificationPresence({
                ...chartUcveMModeStateNotificationPresence,
                data: ucveMModeStateNotificationPresenceDatas,
            });
            setChartUcveMModeStateAlertPresence({
                ...chartUcveMModeStateAlertPresence,
                data: ucveMModeStateAlertPresenceDatas,
            });
            setChartUcveMModeStateAlarmPresence({
                ...chartUcveMModeStateAlarmPresence,
                data: ucveMModeStateAlarmPresenceDatas,
            });
            setChartUcveMModeStateUrdb({
                ...chartUcveMModeStateUrdb,
                data: ucveMModeStateUrdbDatas,
            });
            setChartUcveMModeStateIrdb({
                ...chartUcveMModeStateIrdb,
                data: ucveMModeStateIrdbDatas,
            });
            //#endregion

            //#region ucveMTempVentil
            setChartUcveMTempVentilTemperatureBoxNavya({
                ...chartUcveMTempVentilTemperatureBoxNavya,
                data: ucveMTempVentilTemperatureBoxNavyaDatas,
            });
            setChartUcveMTempVentilTemperatureBoxEVE({
                ...chartUcveMTempVentilTemperatureBoxEVE,
                data: ucveMTempVentilTemperatureBoxEVEDatas,
            });
            setChartUcveMTempVentilTemperatureCabin({
                ...chartUcveMTempVentilTemperatureCabin,
                data: ucveMTempVentilTemperatureCabinDatas,
            });
            setChartUcveMTempVentilTemperatureOutside({
                ...chartUcveMTempVentilTemperatureOutside,
                data: ucveMTempVentilTemperatureOutsideDatas,
            });
            setChartUcveMTempVentilTurbineLvlFront({
                ...chartUcveMTempVentilTurbineLvlFront,
                data: ucveMTempVentilTurbineLvlFrontDatas,
            });
            setChartUcveMTempVentilTurbineLvlRear({
                ...chartUcveMTempVentilTurbineLvlRear,
                data: ucveMTempVentilTurbineLvlRearDatas,
            });
            setChartUcveMTempVentilVentilationLvlBoxNavya({
                ...chartUcveMTempVentilVentilationLvlBoxNavya,
                data: ucveMTempVentilVentilationLvlBoxNavyaDatas,
            });
            setChartUcveMTempVentilVentilationLvlBoxEVE({
                ...chartUcveMTempVentilVentilationLvlBoxEVE,
                data: ucveMTempVentilVentilationLvlBoxEVEDatas,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Navya) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataNavya.CanData;

            console.log("Navya", canData);
            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (
            speedChartMounted === true ||
            ucveMGmp1State1ChartMounted === true ||
            ucveMSysBat1State1ChartMounted === true ||
            ucveMModeStateChartMounted === true ||
            ucveMTempVentilChartMounted === true
        ) {
            if (onMountedChart !== undefined) onMountedChart();
        }
    }, [speedChartMounted, ucveMGmp1State1ChartMounted, ucveMSysBat1State1ChartMounted, ucveMModeStateChartMounted, ucveMTempVentilChartMounted]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.NAVYA_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_SPEED);
                break;
            }

            case Common.NAVYA_ENGINE_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_MOTOR_SPEED);
                break;
            }

            case Common.NAVYA_ACTUAL_ENGINE_TORQUE_NAME:
            case Common.NAVYA_BATSYS1_SOC_NAME:
            case Common.NAVYA_BATSYS1_SOH_NAME:
            case Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_NAME:
            case Common.NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_NAME:
            case Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_NAME:
            case Common.NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PERCENT);
                break;
            }

            case Common.NAVYA_ENGINE_TEMPERATURE_NAME:
            case Common.NAVYA_CONTROLLER_TEMPERATURE_NAME:
            case Common.NAVYA_BATSYS1_MAX_TEMP_REAR_NAME:
            case Common.NAVYA_BATSYS3_MIN_TEMP_REAR_NAME:
            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_NAME:
            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_NAME:
            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_NAME:
            case Common.NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CELSIUS);
                break;
            }

            case Common.NAVYA_BATSYS1_VOLT_REAR_NAME:
            case Common.NAVYA_MODE_STATE_URDB_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }

            case Common.NAVYA_BATSYS1_CURR_REAR_NAME:
            case Common.NAVYA_MODE_STATE_IRDB_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }

            case Common.NAVYA_MODE_STATE_VEHICLE_MODE_NAME:
            case Common.NAVYA_MODE_STATE_VEHICLE_MODE_ACK_NAME: {
                convertTooltipString = convertVehicleModeRemark(value);
                break;
            }

            case Common.NAVYA_MODE_STATE_EPROM_FORMATED_NAME:
            case Common.NAVYA_MODE_STATE_DEFAULT_ACK_NAME:
            case Common.NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_NAME:
            case Common.NAVYA_MODE_STATE_ALERT_PRESENCE_NAME:
            case Common.NAVYA_MODE_STATE_ALARM_PRESENCE_NAME: {
                convertTooltipString = convertAlarmRemark(value);
                break;
            }

            default:
                break;
        }
        return convertTooltipString;
    };

    return (
        <div>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <div>
                    <CanDataWrapper
                        title="속도"
                        id="speed"
                        panelOpen={speedPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVeh1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVeh1}
                        tableSelectRowKeys={selectedVeh1}
                        onChangeTableSelect={onChangeVeh1}
                        tableGetTooltips={getTabelTooltipVeh1}
                        seriesChartDatas={seriesChartDatasVeh1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVeh1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="UCVE_M_GMP1_State1"
                        id="ucveMGmp1State1"
                        panelOpen={ucveMGmp1State1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setUcveMGmp1State1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataUcveMGmp1State1}
                        tableSelectRowKeys={selectedUcveMGmp1State1}
                        onChangeTableSelect={onChangeUcveMGmp1State1}
                        tableGetTooltips={getTabelTooltipUcveMGmp1State1}
                        seriesChartDatas={seriesChartDatasUcveMGmp1State1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setUcveMGmp1State1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="UCVE_M_Sysbatt1_State1"
                        id="ucveMSysBat1State1"
                        panelOpen={ucveMSysBat1State1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setUcveMSysBat1State1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataUcveMSysBat1State1}
                        tableSelectRowKeys={selectedUcveMSysBat1State1}
                        onChangeTableSelect={onChangeUcveMSysBat1State1}
                        tableGetTooltips={getTabelTooltipUcveMSysBat1State1}
                        seriesChartDatas={seriesChartDatasUcveMSysBat1State1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setUcveMSysBat1State1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="UCVE_M_ModeStates"
                        id="ucveMModeState"
                        panelOpen={ucveMModeStatePanelOpen}
                        onChangePanelOpen={(panelOpened) => setUcveMModeStatePanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataUcveMModeState}
                        tableSelectRowKeys={selectedUcveMModeState}
                        onChangeTableSelect={onChangeUcveMModeState}
                        tableGetTooltips={getTabelTooltipUcveMModeState}
                        seriesChartDatas={seriesChartDatasUcveMModeState}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setUcveMModeStateChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="UCVE_M_TempVentil"
                        id="ucveMTempVentil"
                        panelOpen={ucveMTempVentilPanelOpen}
                        onChangePanelOpen={(panelOpened) => setUcveMTempVentilPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataUcveMTempVentil}
                        tableSelectRowKeys={selectedUcveMTempVentil}
                        onChangeTableSelect={onChangeUcveMTempVentil}
                        tableGetTooltips={getTabelTooltipUcveMTempVentil}
                        seriesChartDatas={seriesChartDatasUcveMTempVentil}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setUcveMTempVentilChartMounted(true)}
                    />
                </div>
            </Space>
        </div>
    );
}

export default CanDataNavyaViewer;
