import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestAuthUserRegister = () => {
    const requestAxiosAuthUserRegister = async (data: TypeDTO.UserDto) => {
        const response = await utilAxiosWithAuth().post(Request.USER_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingAuthUserRegister,
        error: errorAuthUserRegister,
        data: resultAuthUserRegister,
        execute: requestAuthUserRegister,
    } = useAsyncAxios(requestAxiosAuthUserRegister);

    useEffect(() => {
        if (resultAuthUserRegister === null) return;
        DEBUG && console.log("resultAuthUserRegister", resultAuthUserRegister);
    }, [resultAuthUserRegister]);

    useEffect(() => {
        if (errorAuthUserRegister === null) return;
        DEBUG && console.log("errorAuthUserRegister", errorAuthUserRegister);
    }, [errorAuthUserRegister]);

    return {
        loadingAuthUserRegister,
        errorAuthUserRegister,
        resultAuthUserRegister,
        requestAuthUserRegister,
    };
};

export const useRequestAuthUserList = () => {
    const requestAxiosAuthUserList = async () => {
        const response = await utilAxiosWithAuth().get(Request.USERS_URL);

        return response.data;
    };

    const {
        loading: loadingAuthUserList,
        error: errorAuthUserList,
        data: resultAuthUserList,
        execute: requestAuthUserList,
    } = useAsyncAxios(requestAxiosAuthUserList);

    useEffect(() => {
        if (resultAuthUserList === null) return;
        DEBUG && console.log("resultAuthUserList", resultAuthUserList);
    }, [resultAuthUserList]);

    useEffect(() => {
        if (errorAuthUserList === null) return;
        DEBUG && console.log("errorAuthUserList", errorAuthUserList);
    }, [errorAuthUserList]);

    return {
        loadingAuthUserList,
        errorAuthUserList,
        resultAuthUserList,
        requestAuthUserList,
    };
};

export const useRequestAuthUserInfo = () => {
    const requestAxiosAuthUserInfo = async (username: string) => {
        const response = await utilAxiosWithAuth().get(Request.USER_URL + username);

        return response.data;
    };

    const {
        loading: loadingAuthUserInfo,
        error: errorAuthUserInfo,
        data: resultAuthUserInfo,
        execute: requestAuthUserInfo,
    } = useAsyncAxios(requestAxiosAuthUserInfo);

    useEffect(() => {
        if (resultAuthUserInfo === null) return;
        DEBUG && console.log("resultAuthUserInfo", resultAuthUserInfo);
    }, [resultAuthUserInfo]);

    useEffect(() => {
        if (errorAuthUserInfo === null) return;
        DEBUG && console.log("errorAuthUserInfo", errorAuthUserInfo);
    }, [errorAuthUserInfo]);

    return {
        loadingAuthUserInfo,
        errorAuthUserInfo,
        resultAuthUserInfo,
        requestAuthUserInfo,
    };
};

export const useRequestAuthUserUpdate = () => {
    const requestAxiosAuthUserUpdate = async (username: string, data: TypeDTO.UserDto) => {
        const response = await utilAxiosWithAuth().put(Request.USER_URL + username, data);

        return response.data;
    };

    const {
        loading: loadingAuthUserUpdate,
        error: errorAuthUserUpdate,
        data: resultAuthUserUpdate,
        execute: requestAuthUserUpdate,
    } = useAsyncAxios(requestAxiosAuthUserUpdate);

    useEffect(() => {
        if (resultAuthUserUpdate === null) return;
        DEBUG && console.log("resultAuthUserUpdate", resultAuthUserUpdate);
    }, [resultAuthUserUpdate]);

    useEffect(() => {
        if (errorAuthUserUpdate === null) return;
        DEBUG && console.log("errorAuthUserUpdate", errorAuthUserUpdate);
    }, [errorAuthUserUpdate]);

    return {
        loadingAuthUserUpdate,
        errorAuthUserUpdate,
        resultAuthUserUpdate,
        requestAuthUserUpdate,
    };
};

export const useRequestAuthUserDelete = () => {
    const requestAxiosAuthUserDelete = async (username: string) => {
        const response = await utilAxiosWithAuth().delete(Request.USER_URL + username);

        return response.data;
    };

    const {
        loading: loadingAuthUserDelete,
        error: errorAuthUserDelete,
        data: resultAuthUserDelete,
        execute: requestAuthUserDelete,
    } = useAsyncAxios(requestAxiosAuthUserDelete);

    useEffect(() => {
        if (resultAuthUserDelete === null) return;
        DEBUG && console.log("resultAuthUserDelete", resultAuthUserDelete);
    }, [resultAuthUserDelete]);

    useEffect(() => {
        if (errorAuthUserDelete === null) return;
        DEBUG && console.log("errorAuthUserDelete", errorAuthUserDelete);
    }, [errorAuthUserDelete]);

    return {
        loadingAuthUserDelete,
        errorAuthUserDelete,
        resultAuthUserDelete,
        requestAuthUserDelete,
    };
};
