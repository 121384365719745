import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiDoorModulesCloseDoor = () => {
    const useRequestApiDoorModulesCloseDoor = async (doorModuleId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_MODULE_CLOSE_DOOR_URL + doorModuleId);

        return response.data;
    };

    const {
        loading: loadingApiDoorModulesCloseDoor,
        error: errorApiDoorModulesCloseDoor,
        data: resultApiDoorModulesCloseDoor,
        execute: requestApiDoorModulesCloseDoor,
    } = useAsyncAxios(useRequestApiDoorModulesCloseDoor);

    useEffect(() => {
        if (resultApiDoorModulesCloseDoor === null) return;
        DEBUG && console.log("resultApiDoorModulesCloseDoor", resultApiDoorModulesCloseDoor);
    }, [resultApiDoorModulesCloseDoor]);

    useEffect(() => {
        if (errorApiDoorModulesCloseDoor === null) return;
        DEBUG && console.log("errorApiDoorModulesCloseDoor", errorApiDoorModulesCloseDoor);
    }, [errorApiDoorModulesCloseDoor]);

    return {
        loadingApiDoorModulesCloseDoor,
        errorApiDoorModulesCloseDoor,
        resultApiDoorModulesCloseDoor,
        requestApiDoorModulesCloseDoor,
    };
};

export const useRequestApiDoorModulesCloseSwingDoor = () => {
    const useRequestApiDoorModulesCloseSwingDoor = async (doorModuleId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_MODULE_CLOSE_SWINGDOOR_URL + doorModuleId);

        return response.data;
    };

    const {
        loading: loadingApiDoorModulesCloseSwingDoor,
        error: errorApiDoorModulesCloseSwingDoor,
        data: resultApiDoorModulesCloseSwingDoor,
        execute: requestApiDoorModulesCloseSwingDoor,
    } = useAsyncAxios(useRequestApiDoorModulesCloseSwingDoor);

    useEffect(() => {
        if (resultApiDoorModulesCloseSwingDoor === null) return;
        DEBUG && console.log("resultApiDoorModulesCloseSwingDoor", resultApiDoorModulesCloseSwingDoor);
    }, [resultApiDoorModulesCloseSwingDoor]);

    useEffect(() => {
        if (errorApiDoorModulesCloseSwingDoor === null) return;
        DEBUG && console.log("errorApiDoorModulesCloseSwingDoor", errorApiDoorModulesCloseSwingDoor);
    }, [errorApiDoorModulesCloseSwingDoor]);

    return {
        loadingApiDoorModulesCloseSwingDoor,
        errorApiDoorModulesCloseSwingDoor,
        resultApiDoorModulesCloseSwingDoor,
        requestApiDoorModulesCloseSwingDoor,
    };
};

export const useRequestApiDoorModulesOpenDoor = () => {
    const useRequestApiDoorModulesOpenDoor = async (doorModuleId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_MODULE_OPEN_DOOR_URL + doorModuleId);

        return response.data;
    };

    const {
        loading: loadingApiDoorModulesOpenDoor,
        error: errorApiDoorModulesOpenDoor,
        data: resultApiDoorModulesOpenDoor,
        execute: requestApiDoorModulesOpenDoor,
    } = useAsyncAxios(useRequestApiDoorModulesOpenDoor);

    useEffect(() => {
        if (resultApiDoorModulesOpenDoor === null) return;
        DEBUG && console.log("resultApiDoorModulesOpenDoor", resultApiDoorModulesOpenDoor);
    }, [resultApiDoorModulesOpenDoor]);

    useEffect(() => {
        if (errorApiDoorModulesOpenDoor === null) return;
        DEBUG && console.log("errorApiDoorModulesOpenDoor", errorApiDoorModulesOpenDoor);
    }, [errorApiDoorModulesOpenDoor]);

    return {
        loadingApiDoorModulesOpenDoor,
        errorApiDoorModulesOpenDoor,
        resultApiDoorModulesOpenDoor,
        requestApiDoorModulesOpenDoor,
    };
};

export const useRequestApiDoorModulesOpenSwingDoor = () => {
    const useRequestApiDoorModulesOpenSwingDoor = async (doorModuleId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_MODULE_OPEN_SWINGDOOR_URL + doorModuleId);

        return response.data;
    };

    const {
        loading: loadingApiDoorModulesOpenSwingDoor,
        error: errorApiDoorModulesOpenSwingDoor,
        data: resultApiDoorModulesOpenSwingDoor,
        execute: requestApiDoorModulesOpenSwingDoor,
    } = useAsyncAxios(useRequestApiDoorModulesOpenSwingDoor);

    useEffect(() => {
        if (resultApiDoorModulesOpenSwingDoor === null) return;
        DEBUG && console.log("resultApiDoorModulesOpenSwingDoor", resultApiDoorModulesOpenSwingDoor);
    }, [resultApiDoorModulesOpenSwingDoor]);

    useEffect(() => {
        if (errorApiDoorModulesOpenSwingDoor === null) return;
        DEBUG && console.log("errorApiDoorModulesOpenSwingDoor", errorApiDoorModulesOpenSwingDoor);
    }, [errorApiDoorModulesOpenSwingDoor]);

    return {
        loadingApiDoorModulesOpenSwingDoor,
        errorApiDoorModulesOpenSwingDoor,
        resultApiDoorModulesOpenSwingDoor,
        requestApiDoorModulesOpenSwingDoor,
    };
};

export const useRequestApiDoorModulesStatus = () => {
    const useRequestApiDoorModulesStatus = async (doorModuleId: number) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_MODULE_STATUS_URL + doorModuleId);

        return response.data;
    };

    const {
        loading: loadingApiDoorModulesStatus,
        error: errorApiDoorModulesStatus,
        data: resultApiDoorModulesStatus,
        execute: requestApiDoorModulesStatus,
    } = useAsyncAxios(useRequestApiDoorModulesStatus);

    useEffect(() => {
        if (resultApiDoorModulesStatus === null) return;
        DEBUG && console.log("resultApiDoorModulesStatus", resultApiDoorModulesStatus);
    }, [resultApiDoorModulesStatus]);

    useEffect(() => {
        if (errorApiDoorModulesStatus === null) return;
        DEBUG && console.log("errorApiDoorModulesStatus", errorApiDoorModulesStatus);
    }, [errorApiDoorModulesStatus]);

    return {
        loadingApiDoorModulesStatus,
        errorApiDoorModulesStatus,
        resultApiDoorModulesStatus,
        requestApiDoorModulesStatus,
    };
};
