import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiCarRegister = () => {
    const requestAxiosApiCarRegister = async (data: TypeDTO.CarDto) => {
        const response = await utilAxiosWithAuth().post(Request.CAR_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiCarRegister,
        error: errorApiCarRegister,
        data: resultApiCarRegister,
        execute: requestApiCarRegister,
    } = useAsyncAxios(requestAxiosApiCarRegister);

    useEffect(() => {
        if (resultApiCarRegister === null) return;
        DEBUG && console.log("resultApiCarRegister", resultApiCarRegister);
    }, [resultApiCarRegister]);

    useEffect(() => {
        if (errorApiCarRegister === null) return;
        DEBUG && console.log("errorApiCarRegister", errorApiCarRegister);
    }, [errorApiCarRegister]);

    return {
        loadingApiCarRegister,
        errorApiCarRegister,
        resultApiCarRegister,
        requestApiCarRegister,
    };
};

export const useRequestApiCarList = () => {
    const requestAxiosApiCarList = async (withRunningSummary?: boolean, withDevices?: boolean, withAlarm?: boolean) => {
        const response = await utilAxiosWithAuth().get(Request.CARS_URL, {
            params: { withRunningSummary, withDevices, withAlarm },
        });

        return response.data;
    };

    const {
        loading: loadingApiCarList,
        error: errorApiCarList,
        data: resultApiCarList,
        execute: requestApiCarList,
    } = useAsyncAxios(requestAxiosApiCarList);

    useEffect(() => {
        if (resultApiCarList === null) return;
        DEBUG && console.log("resultApiCarList", resultApiCarList);
    }, [resultApiCarList]);

    useEffect(() => {
        if (errorApiCarList === null) return;
        DEBUG && console.log("errorApiCarList", errorApiCarList);
    }, [errorApiCarList]);

    return {
        loadingApiCarList,
        errorApiCarList,
        resultApiCarList,
        requestApiCarList,
    };
};

export const useRequestApiCarDeleteList = () => {
    const requestAxiosApiCarDeleteList = async (carIds: number[]) => {
        const response = await utilAxiosWithAuth().delete(Request.CARS_URL, { data: carIds });

        return response.data;
    };

    const {
        loading: loadingApiCarDeleteList,
        error: errorApiCarDeleteList,
        data: resultApiCarDeleteList,
        execute: requestApiCarDeleteList,
    } = useAsyncAxios(requestAxiosApiCarDeleteList);

    useEffect(() => {
        if (resultApiCarDeleteList === null) return;
        DEBUG && console.log("resultApiCarDeleteList", resultApiCarDeleteList);
    }, [resultApiCarDeleteList]);

    useEffect(() => {
        if (errorApiCarDeleteList === null) return;
        DEBUG && console.log("errorApiCarDeleteList", errorApiCarDeleteList);
    }, [errorApiCarDeleteList]);

    return {
        loadingApiCarDeleteList,
        errorApiCarDeleteList,
        resultApiCarDeleteList,
        requestApiCarDeleteList,
    };
};

export const useRequestApiCarInfo = () => {
    const requestAxiosApiCarInfo = async (carId: number) => {
        const response = await utilAxiosWithAuth().get(Request.CAR_URL + carId);

        return response.data;
    };

    const {
        loading: loadingApiCarInfo,
        error: errorApiCarInfo,
        data: resultApiCarInfo,
        execute: requestApiCarInfo,
    } = useAsyncAxios(requestAxiosApiCarInfo);

    useEffect(() => {
        if (resultApiCarInfo === null) return;
        DEBUG && console.log("resultApiCarInfo", resultApiCarInfo);
    }, [resultApiCarInfo]);

    useEffect(() => {
        if (errorApiCarInfo === null) return;
        DEBUG && console.log("errorApiCarInfo", errorApiCarInfo);
    }, [errorApiCarInfo]);

    return {
        loadingApiCarInfo,
        errorApiCarInfo,
        resultApiCarInfo,
        requestApiCarInfo,
    };
};

export const useRequestApiCarUpdate = () => {
    const requestAxiosApiCarUpdate = async (carId: number, data: TypeDTO.CarDto, forced: boolean) => {
        const response = await utilAxiosWithAuth().put(Request.CAR_URL + carId, data, { params: { forced: forced } });

        return response.data;
    };

    const {
        loading: loadingApiCarUpdate,
        error: errorApiCarUpdate,
        data: resultApiCarUpdate,
        execute: requestApiCarUpdate,
    } = useAsyncAxios(requestAxiosApiCarUpdate);

    useEffect(() => {
        if (resultApiCarUpdate === null) return;
        DEBUG && console.log("resultApiCarUpdate", resultApiCarUpdate);
    }, [resultApiCarUpdate]);

    useEffect(() => {
        if (errorApiCarUpdate === null) return;
        DEBUG && console.log("errorApiCarUpdate", errorApiCarUpdate);
    }, [errorApiCarUpdate]);

    return {
        loadingApiCarUpdate,
        errorApiCarUpdate,
        resultApiCarUpdate,
        requestApiCarUpdate,
    };
};

export const useRequestApiCarDelete = () => {
    const requestAxiosApiCarDelete = async (carId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.CAR_URL + carId);

        return response.data;
    };

    const {
        loading: loadingApiCarDelete,
        error: errorApiCarDelete,
        data: resultApiCarDelete,
        execute: requestApiCarDelete,
    } = useAsyncAxios(requestAxiosApiCarDelete);

    useEffect(() => {
        if (resultApiCarDelete === null) return;
        DEBUG && console.log("resultApiCarDelete", resultApiCarDelete);
    }, [resultApiCarDelete]);

    useEffect(() => {
        if (errorApiCarDelete === null) return;
        DEBUG && console.log("errorApiCarDelete", errorApiCarDelete);
    }, [errorApiCarDelete]);

    return {
        loadingApiCarDelete,
        errorApiCarDelete,
        resultApiCarDelete,
        requestApiCarDelete,
    };
};
