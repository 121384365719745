import React, { useState, useEffect } from "react";
import { FormInstance } from "antd/es/form/hooks/useForm";
import { Store } from "antd/es/form/interface";
import { EditOutlined } from "@ant-design/icons";

import Button from "antd/es/button/button";
import Row from "antd/es/grid/row";
import Form from "antd/es/form/index";
import Input from "antd/es/input/index";
import Space from "antd/es/space/index";
import CustomSelector from "./CustomSelector";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import styles from "./CustomForm.module.css";

export type viewType = "register" | "edit" | "view";

const CustomForm = ({
    form,
    type,
    disabledBtn,
    initialValues,
    contents,
    onFinish,
    onValuesChange,
    onChangeType,
    onChangeSelectorValue,
}: {
    form: FormInstance<any>;
    type: viewType;
    disabledBtn?: boolean;
    initialValues?: Store;
    contents: Array<TypeUtils.contentsType>;
    onFinish?: (type: viewType, value: any) => void;
    onValuesChange?: (value: any, values: any) => void;
    onChangeType?: (type: viewType) => void;
    onChangeSelectorValue?: (key: any, value: any) => void;
}) => {
    useEffect(() => {
        console.log(initialValues);
    }, [initialValues]);
    const setButton = () => {
        if (type === "view") {
            return (
                <span className={`${styles.editBtn} fc-font3 fw-bd fs-sm btn-text`} onClick={() => onChangeType && onChangeType("edit")}>
                    <EditOutlined /> {String.update}
                </span>
            );
        }
    };

    return (
        <>
            <Form
                form={form}
                initialValues={initialValues}
                onFinish={(value) => {
                    onFinish && onFinish(type, value);
                }}
                onValuesChange={(value, values) => onValuesChange && onValuesChange(value, values)}
            >
                <Space direction="vertical" size={24} style={{ display: "flex" }}>
                    {contents?.map((content, index) => (
                        <Space key={index} direction="vertical" size={type === "view" ? 8 : 16} style={{ display: "flex" }}>
                            {content?.name && (
                                <Row className="titleWrapper" justify={"space-between"}>
                                    <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                                        {content?.name}
                                    </p>
                                    {!content?.disabledBtn && !disabledBtn && <Space size={16}>{setButton()}</Space>}
                                    {content?.buttonProps && content?.buttonProps}
                                </Row>
                            )}

                            {content.forms.map((form, index) => {
                                return (
                                    form.label && (
                                        <Form.Item
                                            key={index}
                                            className={type !== "view" ? `${styles.manageForm} ${styles.veticalManageForm}` : `${styles.manageForm}`}
                                            name={form.name}
                                            label={form.label}
                                            required={type !== "view" && form?.required}
                                            dependencies={form?.dependencies}
                                            hasFeedback={type !== "view" && form?.hasFeedback}
                                            rules={[
                                                { required: form?.required, message: Utils.addParticle(form.label, "입력해 주세요.") },
                                                ...(Array.isArray(form.rules) ? form.rules : []),
                                            ]}
                                        >
                                            {form?.customSelector ? (
                                                <CustomSelector
                                                    selectorType={form.customSelector}
                                                    onFinish={(value) => {
                                                        onChangeSelectorValue && onChangeSelectorValue(form.name, value);
                                                    }}
                                                />
                                            ) : form?.input ? (
                                                form.input
                                            ) : type === "view" ? (
                                                <Input className="disabled-input" disabled placeholder="-" />
                                            ) : (
                                                <Input placeholder={form?.placeholder || form?.label} disabled={form?.disabled} />
                                            )}
                                        </Form.Item>
                                    )
                                );
                            })}
                        </Space>
                    ))}
                </Space>
                {type !== "view" && onFinish && (
                    <div className={styles.btnSubmitWrapper}>
                        <Button className={`btn-primary ${styles.btnSubmit}`} onClick={() => form.submit()}>
                            {type === "register" ? String.register : String.save}하기
                        </Button>
                    </div>
                )}
            </Form>
        </>
    );
};

export default CustomForm;
