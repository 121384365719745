import { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import { type TableProps } from "antd/es/table/InternalTable";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestRunning from "../../utils/requestApiRunning";
import * as Common from "../../commons/common";

import Tag from "antd/es/tag/index";
import Space from "antd/es/space/index";
import Badge from "antd/es/badge/index";
import DataTable from "../common/DataTable";

import Search from "../browser/Search";
import moment from "moment";

import IconError from "../../assets/images/icon/icon_red_exclamation.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

type SearchType = {
    startDate: string;
    endDate: string;
    checkbox: string[];
};

const Socs = ({ carNumber }: { carNumber?: string }) => {
    const { pathname } = useLocation();
    const userDetails = useAuthState();

    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.RunningHistoryDto>>({});
    const [searchHistoryValue, setSearchHistoryValue] = useState<SearchType>();
    const [runnings, setRunnings] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [loading, setLoading] = useState(false);
    const [tempHistories, setTempHistories] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestHistoriesPage, setRequestHistoriesPage] = useState(0);

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "searchDate",
            span: 12,
            title: String.searchDate,
            type: "rangePicker",
            dateFormat: Common.FORMAT_DATE_TIME,
        },
        {
            id: "checkbox",
            span: 12,
            title: String.status,
            type: "checkbox",
            options: [
                { value: "batteryWarning", label: String.warning },
                { value: "batteryNormal", label: String.active },
            ],
            defaultValue: ["batteryWarning", "batteryNormal"],
        },
    ];

    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>(searchData);

    const columns: ColumnsType<TypeDTO.RunningHistoryDto> = [
        {
            title: String.status,
            dataIndex: "socCheck",
            key: "socCheck",
            align: "center",
            width: "8%",
            render: (_, { socCheck }) => {
                return socCheck ? (
                    <img alt="icon" src={IconError} width={20} height={20} />
                ) : (
                    <img alt="icon" src={IconCheck} width={20} height={20} />
                );
            },
        },
        {
            title: String.runningDate,
            dataIndex: "startTime",
            key: "startTime",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.endTime, b.endTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "time" ? sortedInfo.order : null,
            render: (_, { startTime }) => {
                return moment.utc(startTime, Common.FORMAT_DATE).format(Common.FORMAT_DATE);
            },
        },
        {
            title: String.startTime,
            dataIndex: "startTime",
            key: "startTime",
            align: "center",
            width: "10%",
            render: (_, { startTime }) => {
                return moment(startTime).format(Common.FORMAT_TIME);
            },
        },
        {
            title: String.endTime,
            dataIndex: "endTime",
            key: "endTime",
            align: "center",
            width: "12%",
            render: (_, { startTime, endTime, finish }) => {
                const checkDday = moment(endTime).startOf("day").diff(moment(startTime).startOf("day"), "days");
                const checkAbnormal = finish !== true && moment(endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));
                return (
                    <>
                        {checkDday > 0 && <Tag className="normalTag">D+{checkDday}</Tag>}
                        {checkAbnormal ? (
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                                <Badge style={{ right: "-4px" }} dot color="var(--danger)">
                                    <span className={"fs-md fc-point"}>
                                        {endTime ? moment(endTime).format(Common.FORMAT_TIME) : String.dash}
                                    </span>
                                </Badge>
                            ) : (
                                <span className={"fs-md"}>{endTime ? moment(endTime).format(Common.FORMAT_TIME) : String.dash}</span>
                            )
                        ) : finish === true ? (
                            <span className={"fs-md"}>{endTime ? moment(endTime).format(Common.FORMAT_TIME) : String.dash}</span>
                        ) : (
                            <Tag className="pointTag">운행중</Tag>
                        )}
                    </>
                );
            },
        },
        {
            title: (
                <>
                    {String.soc}
                    <span className="fs-xs"> {String.percentUnit}</span>
                </>
            ),
            dataIndex: "endSoc",
            key: "endSoc",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a?.endSoc, b?.endSoc, sortOrder),
            sortOrder: sortedInfo.columnKey === "endSoc" ? sortedInfo.order : null,
            render: (_, { endSoc }) => {
                return Utils.checkNumberData(endSoc) || String.dash;
            },
        },
        {
            title: (
                <>
                    {String.menu_soc}
                    <span className="fs-xs"> {String.percentUnit}</span>
                </>
            ),
            dataIndex: "tableSoc",
            key: "tableSoc",
            width: "12%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a?.tableSoc, b?.tableSoc, sortOrder),
            sortOrder: sortedInfo.columnKey === "tableSoc" ? sortedInfo.order : null,
            render: (_, { tableSoc }) => {
                return Utils.checkNumberData(tableSoc) || String.dash;
            },
        },
        {
            title: (
                <>
                    {String.maxTemp}
                    <span className="fs-xs"> {String.tempUnit}</span>
                </>
            ),
            dataIndex: "batMaxTemp",
            key: "batMaxTemp",
            align: "center",
            width: "10%",
            render: (_, { batMaxTemp }) => {
                return batMaxTemp || String.dash;
            },
        },
        {
            title: (
                <>
                    {String.minTemp}
                    <span className="fs-xs"> {String.tempUnit}</span>
                </>
            ),
            dataIndex: "batMinTemp",
            key: "batMinTemp",
            align: "center",
            width: "10%",
            render: (_, { batMinTemp }) => {
                return batMinTemp || String.dash;
            },
        },
        {
            title: (
                <>
                    {String.maxVolt}
                    <span className="fs-xs"> {String.voltUnit}</span>
                </>
            ),
            dataIndex: "batMaxVolt",
            key: "batMaxVolt",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a?.batMaxVolt, b?.batMaxVolt, sortOrder),
            sortOrder: sortedInfo.columnKey === "maxVolt" ? sortedInfo.order : null,
            render: (_, { batMaxVolt }) => {
                return Utils.checkNumberData(batMaxVolt) || String.dash;
            },
        },
        {
            title: (
                <>
                    {String.minVolt}
                    <span className="fs-xs"> {String.voltUnit}</span>
                </>
            ),
            dataIndex: "batMinVolt",
            key: "batMinVolt",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a?.batMinVolt, b?.batMinVolt, sortOrder),
            sortOrder: sortedInfo.columnKey === "minVolt" ? sortedInfo.order : null,
            render: (_, { batMinVolt }) => {
                return Utils.checkNumberData(batMinVolt) || String.dash;
            },
        },
    ];

    const onTableChange: TableProps<TypeDTO.RunningHistoryDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.RunningHistoryDto>);
    };

    const onSearchData = (value: any) => {
        const { checkbox, ...searchValue } = value;

        searchValue.carNumber = carNumber;
        searchValue.batteryWarning = value?.checkbox?.includes("batteryWarning");
        searchValue.batteryNormal = value?.checkbox?.includes("batteryNormal");

        if (carNumber) {
            setSearchHistoryValue(searchValue);
            setLoading(true);
            requestApiRunningHistoryList(searchValue, requestHistoriesPage);
        }
    };

    const { requestApiRunningHistoryList, resultApiRunningHistoryList } = RequestRunning.useRequestApiRunningHistoryList();

    useEffect(() => {
        if (resultApiRunningHistoryList === null) return;

        if (resultApiRunningHistoryList.runningHistories.totalPages > requestHistoriesPage + 1) {
            setTempHistories([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(requestHistoriesPage + 1);
        } else {
            setLoading(false);
            setRunnings([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(0);
            setTempHistories([]);
        }
    }, [resultApiRunningHistoryList]);

    useEffect(() => {
        if (requestHistoriesPage === 0) return;

        requestApiRunningHistoryList(searchHistoryValue, requestHistoriesPage);
    }, [requestHistoriesPage]);

    return (
        <Space direction="vertical" size={8} style={{ display: "flex" }}>
            <Search
                title={
                    carNumber
                        ? pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL)
                            ? String.menu_soc
                            : `${carNumber} ${String.menu_soc}`
                        : String.menu_soc
                }
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
            />
            <DataTable
                rowKey={(row: TypeDTO.RunningHistoryDto) => row?.bootTime}
                disabledTitle
                loading={loading}
                columns={columns}
                dataSource={runnings}
                onChange={onTableChange}
            />
        </Space>
    );
};

export default Socs;
