import { useEffect, useState } from "react";

import moment from "moment";

import * as CanDataSkywell from "../../commons/CanDataSkywell";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";

import CanDataWrapper, { CanTableType } from "./CanDataWrapper";
import Space from "antd/es/space/index";

function CanDataSkywellViewer({
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataSkywell.CanData>();

    const convertRemarkWithUnit = (value: number, unit: string) => {
        return value + unit;
    };

    //#region Battery Information
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryInformationPanelOpen, setBatteryInformationPanelOpen] = useState(false);
    const [batteryInformationChartMounted, setBatteryInformationChartMounted] = useState(false);
    const [selectedBatteryInformation, setSelectedBatteryInformation] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryInformation, setSeriesChartDatasBatteryInformation] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryInformationVoltage, setChartBatteryInformationVoltage] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_INFO_VOLTAGE_NAME,
        data: [],
    });

    const [chartBatteryInformationCurrent, setChartBatteryInformationCurrent] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_INFO_CURRENT_NAME,
        data: [],
    });

    const [chartBatteryInformationSoc, setChartBatteryInformationSoc] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_INFO_SOC_NAME,
        data: [],
    });

    const [chartBatteryInformationLifeSignal, setChartBatteryInformationLifeSignal] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_INFO_LIFE_SIGNAL_NAME,
        data: [],
    });

    const getTabelTooltipBatteryInformation = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_INFO_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            배터리 기본정보
                            <br />
                            CAN ID: 0x18F201F3
                            <br />
                            Name: Battery Voltage
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_INFO_CURRENT_KEY:
                return (
                    <>
                        <p>
                            배터리 기본정보
                            <br />
                            CAN ID: 0x18F201F3
                            <br />
                            Name: Battery Current
                        </p>
                        <p>Remarks: A</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_INFO_SOC_KEY:
                return (
                    <>
                        <p>
                            배터리 기본정보
                            <br />
                            CAN ID: 0x18F201F3
                            <br />
                            Name: Battery SOC
                        </p>
                        <p>Remarks: %</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_INFO_LIFE_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            배터리 기본정보
                            <br />
                            CAN ID: 0x18F201F3
                            <br />
                            Name: BMS Life 신호
                        </p>
                        <p>Remarks: 0 ~ 255</p>
                    </>
                );
        }

        return null;
    };

    const getBatteryInformationVoltageValue = () => {
        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bat?.volt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryInformationCurrentValue = () => {
        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bat?.curr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBatteryInformationSocValue = () => {
        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bat?.soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatteryInformationLifeSignalValue = () => {
        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_LIFE_SIGNAL_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bat?.lifeSignal);
        }
        return "";
    };

    const onChangeBatteryInformation = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryInformation(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryInformation: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_VOLTAGE_KEY))
            chartDatasBatteryInformation.push(chartBatteryInformationVoltage);
        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_CURRENT_KEY))
            chartDatasBatteryInformation.push(chartBatteryInformationCurrent);
        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_SOC_KEY)) chartDatasBatteryInformation.push(chartBatteryInformationSoc);
        if (selectedBatteryInformation.includes(Common.SKYWELL_BATTERY_INFO_LIFE_SIGNAL_KEY))
            chartDatasBatteryInformation.push(chartBatteryInformationLifeSignal);

        setSeriesChartDatasBatteryInformation(chartDatasBatteryInformation);
    }, [
        selectedBatteryInformation,
        chartBatteryInformationVoltage,
        chartBatteryInformationCurrent,
        chartBatteryInformationSoc,
        chartBatteryInformationLifeSignal,
    ]);

    const tableDataBatteryInformation: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_INFO_VOLTAGE_KEY,
            name: Common.SKYWELL_BATTERY_INFO_VOLTAGE_NAME,
            value: getBatteryInformationVoltageValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_INFO_CURRENT_KEY,
            name: Common.SKYWELL_BATTERY_INFO_CURRENT_NAME,
            value: getBatteryInformationCurrentValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_INFO_SOC_KEY,
            name: Common.SKYWELL_BATTERY_INFO_SOC_NAME,
            value: getBatteryInformationSocValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_INFO_LIFE_SIGNAL_KEY,
            name: Common.SKYWELL_BATTERY_INFO_LIFE_SIGNAL_NAME,
            value: getBatteryInformationLifeSignalValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Fault Alarm Information
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batFaultAlarmInfoPanelOpen, setBatFaultAlarmInfoPanelOpen] = useState(false);
    const [batFaultAlarmInfoChartMounted, setBatFaultAlarmInfoChartMounted] = useState(false);
    const [selectedBatFaultAlarmInfo, setSelectedBatFaultAlarmInfo] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatFaultAlarmInfo, setSeriesChartDatasBatFaultAlarmInfo] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatFaultAlarmInfoHighTempAlarm, setChartBatFaultAlarmInfoHighTempAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoCellOverVoltAlarm, setChartBatFaultAlarmInfoCellOverVoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoModuleOverVoltAlarm, setChartBatFaultAlarmInfoModuleOverVoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoModuleLowVoltAlarm, setChartBatFaultAlarmInfoModuleLowVoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmInfoCellLowVoltAlarm, setChartBatFaultAlarmInfoCellLowVoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoChargeGunHighTempAlarm, setChartBatFaultAlarmInfoChargeGunHighTempAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoBatFaultStatus, setChartBatFaultAlarmInfoBatFaultStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoDischargeCurrAlarm, setChartBatFaultAlarmInfoDischargeCurrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmInfoChargeCurrAlarm, setChartBatFaultAlarmInfoChargeCurrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoCellVotDiffAlarm, setChartBatFaultAlarmInfoCellVotDiffAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoTempDiffAlarm, setChartBatFaultAlarmInfoTempDiffAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoBmsFaultCode, setChartBatFaultAlarmInfoBmsFaultCode] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoChargeStatus, setChartBatFaultAlarmInfoChargeStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_NAME,
        data: [],
    });

    const [chartBatFaultAlarmInfoChargeNetworkStatus, setChartBatFaultAlarmInfoChargeNetworkStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipBatFaultAlarmInfo = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 배터리 고온 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 셀 과전압 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 모듈 과전압 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 모듈 전압부족 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 셀 전압부족 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 충전건 고온 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 동력 배터리 고장 상태
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 방전 전류 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 충전 전류 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 셀 전압 차 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 온도 차 경보
                        </p>
                        <p>Remarks: 1=3급 고장, 2=2급 고장, 3=1급 고장</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: BMS 고장코드
                        </p>
                        <p>Remarks: 0 ~ 254</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 충전상태
                        </p>
                        <p>Remarks: 0=미충전, 1=충전</p>
                    </>
                );

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장경보
                            <br />
                            CAN ID: 0x18F205F3
                            <br />
                            Name: 충전 인넷 연결 상태
                        </p>
                        <p>Remarks: 0=미연결, 1=연결</p>
                    </>
                );
        }

        return null;
    };

    const convertBatFaultAlarmRemark = (value: number) => {
        let valueString = "-";
        switch (value) {
            case 1:
                valueString = "3급 고장";
                break;
            case 2:
                valueString = "2급 고장";
                break;
            case 3:
                valueString = "1급 고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertChargeStatusRemark = (value: number) => {
        let valueString = "미충전";
        switch (value) {
            case 0:
                valueString = "미충전";
                break;
            case 1:
                valueString = "충전";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertChargeNetworkStatusRemark = (value: number) => {
        let valueString = "미연결";
        switch (value) {
            case 0:
                valueString = "미연결";
                break;
            case 1:
                valueString = "연결";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBatFaultAlarmInfoHighTempAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.highTempAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoCellOverVoltAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.cellOverVoltAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoModuleOverVoltAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.moduleOverVoltAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoModuleLowVoltAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.moduleLowVoltAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoCellLowVoltAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.cellLowVoltAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoChargeGunHighTempAlarmValue = () => {
        if (
            selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_KEY) &&
            currentCanData !== undefined
        ) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.chargeGunHighTempAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoBatFaultStatusalue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.batFaultStatus);
        }
        return "";
    };

    const getBatFaultAlarmInfoDischargeCurrAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.dischargeCurrAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoChargeCurrAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.chargeCurrAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoCellVotDiffAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.cellVotDiffAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoTempDiffAlarmValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_KEY) && currentCanData !== undefined) {
            return convertBatFaultAlarmRemark(currentCanData.batFaultAlarmInfo?.tempDiffAlarm);
        }
        return "";
    };

    const getBatFaultAlarmInfoBmsFaultCodeValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_KEY) && currentCanData !== undefined) {
            return currentCanData.batFaultAlarmInfo?.bmsFaultCode ? String(currentCanData.batFaultAlarmInfo?.bmsFaultCode) : "-";
        }
        return "";
    };

    const getBatFaultAlarmInfoChargeStatusValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_KEY) && currentCanData !== undefined) {
            return convertChargeStatusRemark(currentCanData.batFaultAlarmInfo?.chargeStatus);
        }
        return "";
    };

    const getBatFaultAlarmInfoChargeNetworkStatusValue = () => {
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_KEY) && currentCanData !== undefined) {
            return convertChargeNetworkStatusRemark(currentCanData.batFaultAlarmInfo?.chargeNetworkStatus);
        }
        return "";
    };

    const onChangeBatFaultAlarmInfo = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatFaultAlarmInfo(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatFaultAlarmInfo: Array<TypeUtils.ChartData> = [];

        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoHighTempAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoCellOverVoltAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoModuleOverVoltAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoModuleLowVoltAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoCellLowVoltAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoChargeGunHighTempAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoBatFaultStatus);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoDischargeCurrAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoChargeCurrAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoCellVotDiffAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoTempDiffAlarm);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoBmsFaultCode);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoChargeStatus);
        if (selectedBatFaultAlarmInfo.includes(Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_KEY))
            chartDatasBatFaultAlarmInfo.push(chartBatFaultAlarmInfoChargeNetworkStatus);

        setSeriesChartDatasBatFaultAlarmInfo(chartDatasBatFaultAlarmInfo);
    }, [
        selectedBatFaultAlarmInfo,
        chartBatFaultAlarmInfoHighTempAlarm,
        chartBatFaultAlarmInfoCellOverVoltAlarm,
        chartBatFaultAlarmInfoModuleOverVoltAlarm,
        chartBatFaultAlarmInfoModuleLowVoltAlarm,
        chartBatFaultAlarmInfoCellLowVoltAlarm,
        chartBatFaultAlarmInfoChargeGunHighTempAlarm,
        chartBatFaultAlarmInfoBatFaultStatus,
        chartBatFaultAlarmInfoDischargeCurrAlarm,
        chartBatFaultAlarmInfoChargeCurrAlarm,
        chartBatFaultAlarmInfoCellVotDiffAlarm,
        chartBatFaultAlarmInfoTempDiffAlarm,
        chartBatFaultAlarmInfoBmsFaultCode,
        chartBatFaultAlarmInfoChargeStatus,
        chartBatFaultAlarmInfoChargeNetworkStatus,
    ]);

    const tableDataBatFaultAlarmInfo: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_NAME,
            value: getBatFaultAlarmInfoHighTempAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_NAME,
            value: getBatFaultAlarmInfoCellOverVoltAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_NAME,
            value: getBatFaultAlarmInfoModuleOverVoltAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_NAME,
            value: getBatFaultAlarmInfoModuleLowVoltAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_NAME,
            value: getBatFaultAlarmInfoCellLowVoltAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_NAME,
            value: getBatFaultAlarmInfoChargeGunHighTempAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_NAME,
            value: getBatFaultAlarmInfoBatFaultStatusalue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_NAME,
            value: getBatFaultAlarmInfoDischargeCurrAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_NAME,
            value: getBatFaultAlarmInfoChargeCurrAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_NAME,
            value: getBatFaultAlarmInfoCellVotDiffAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_NAME,
            value: getBatFaultAlarmInfoTempDiffAlarmValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_NAME,
            value: getBatFaultAlarmInfoBmsFaultCodeValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_NAME,
            value: getBatFaultAlarmInfoChargeStatusValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_KEY,
            name: Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_NAME,
            value: getBatFaultAlarmInfoChargeNetworkStatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Cell Max Voltage 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryCellMaxVolt1PanelOpen, setBatteryCellMaxVolt1PanelOpen] = useState(false);
    const [batteryCellMaxVolt1ChartMounted, setBatteryCellMaxVolt1ChartMounted] = useState(false);
    const [selectedBatteryCellMaxVolt1, setSelectedBatteryCellMaxVolt1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryCellMaxVolt1, setSeriesChartDatasBatteryCellMaxVolt1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryCellMaxVolt1CellMaxVolt1, setChartBatteryCellMaxVolt1CellMaxVolt1] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt1CellMaxVolt1BoxNo, setChartBatteryCellMaxVolt1CellMaxVolt1BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt1CellMaxVolt1No, setChartBatteryCellMaxVolt1CellMaxVolt1No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt1CellMaxVolt2, setChartBatteryCellMaxVolt1CellMaxVolt2] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt1CellMaxVolt2BoxNo, setChartBatteryCellMaxVolt1CellMaxVolt2BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt1CellMaxVolt2No, setChartBatteryCellMaxVolt1CellMaxVolt2No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatteryCellMaxVolt1 = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 1
                            <br />
                            CAN ID: 0x18F206F3
                            <br />
                            Name: 셀 최고전압 1
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 1
                            <br />
                            CAN ID: 0x18F206F3
                            <br />
                            Name: 셀 최고전압 1 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 1
                            <br />
                            CAN ID: 0x18F206F3
                            <br />
                            Name: 셀 최고전압 1 No
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 2
                            <br />
                            CAN ID: 0x18F206F3
                            <br />
                            Name: 셀 최고전압 2
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 2
                            <br />
                            CAN ID: 0x18F206F3
                            <br />
                            Name: 셀 최고전압 2 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 2
                            <br />
                            CAN ID: 0x18F206F3
                            <br />
                            Name: 셀 최고전압 2 No
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryCellMaxVolt1CellMaxVolt1Value = () => {
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMaxVolt1?.cellMaxVolt1, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMaxVolt1CellMaxVolt1BoxNoValue = () => {
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt1?.cellMaxVolt1BoxNo);
        }
        return "";
    };

    const getBatteryCellMaxVolt1CellMaxVolt1NoValue = () => {
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt1?.cellMaxVolt1No);
        }
        return "";
    };

    const getBatteryCellMaxVolt1CellMaxVolt2Value = () => {
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMaxVolt1?.cellMaxVolt2, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMaxVolt1CellMaxVolt2BoxNoValue = () => {
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt1?.cellMaxVolt2BoxNo);
        }
        return "";
    };

    const getBatteryCellMaxVolt1CellMaxVolt2NoValue = () => {
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt1?.cellMaxVolt2No);
        }
        return "";
    };

    const onChangeBatteryCellMaxVolt1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryCellMaxVolt1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryCellMaxVolt1: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_KEY))
            chartDatasBatteryCellMaxVolt1.push(chartBatteryCellMaxVolt1CellMaxVolt1);
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_KEY))
            chartDatasBatteryCellMaxVolt1.push(chartBatteryCellMaxVolt1CellMaxVolt1BoxNo);
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_KEY))
            chartDatasBatteryCellMaxVolt1.push(chartBatteryCellMaxVolt1CellMaxVolt1No);
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_KEY))
            chartDatasBatteryCellMaxVolt1.push(chartBatteryCellMaxVolt1CellMaxVolt2);
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_KEY))
            chartDatasBatteryCellMaxVolt1.push(chartBatteryCellMaxVolt1CellMaxVolt2BoxNo);
        if (selectedBatteryCellMaxVolt1.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_KEY))
            chartDatasBatteryCellMaxVolt1.push(chartBatteryCellMaxVolt1CellMaxVolt2No);

        setSeriesChartDatasBatteryCellMaxVolt1(chartDatasBatteryCellMaxVolt1);
    }, [
        selectedBatteryCellMaxVolt1,
        chartBatteryCellMaxVolt1CellMaxVolt1,
        chartBatteryCellMaxVolt1CellMaxVolt1BoxNo,
        chartBatteryCellMaxVolt1CellMaxVolt1No,
        chartBatteryCellMaxVolt1CellMaxVolt2,
        chartBatteryCellMaxVolt1CellMaxVolt2BoxNo,
        chartBatteryCellMaxVolt1CellMaxVolt2No,
    ]);

    const tableDataBatteryCellMaxVolt1: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NAME,
            value: getBatteryCellMaxVolt1CellMaxVolt1Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_NAME,
            value: getBatteryCellMaxVolt1CellMaxVolt1BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_NAME,
            value: getBatteryCellMaxVolt1CellMaxVolt1NoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NAME,
            value: getBatteryCellMaxVolt1CellMaxVolt2Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_NAME,
            value: getBatteryCellMaxVolt1CellMaxVolt2BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_NAME,
            value: getBatteryCellMaxVolt1CellMaxVolt2NoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Cell Max Voltage 2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryCellMaxVolt2PanelOpen, setBatteryCellMaxVolt2PanelOpen] = useState(false);
    const [batteryCellMaxVolt2ChartMounted, setBatteryCellMaxVolt2ChartMounted] = useState(false);
    const [selectedBatteryCellMaxVolt2, setSelectedBatteryCellMaxVolt2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryCellMaxVolt2, setSeriesChartDatasBatteryCellMaxVolt2] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryCellMaxVolt2CellMaxVolt3, setChartBatteryCellMaxVolt2CellMaxVolt3] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt2CellMaxVolt3BoxNo, setChartBatteryCellMaxVolt2CellMaxVolt3BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt2CellMaxVolt3No, setChartBatteryCellMaxVolt2CellMaxVolt3No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt2CellMaxVolt4, setChartBatteryCellMaxVolt2CellMaxVolt4] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt2CellMaxVolt4BoxNo, setChartBatteryCellMaxVolt2CellMaxVolt4BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMaxVolt2CellMaxVolt4No, setChartBatteryCellMaxVolt2CellMaxVolt4No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatteryCellMaxVolt2 = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 3
                            <br />
                            CAN ID: 0x18F207F3
                            <br />
                            Name: 셀 최고전압 3
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 3
                            <br />
                            CAN ID: 0x18F207F3
                            <br />
                            Name: 셀 최고전압 3 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 3
                            <br />
                            CAN ID: 0x18F207F3
                            <br />
                            Name: 셀 최고전압 3 No
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 4
                            <br />
                            CAN ID: 0x18F207F3
                            <br />
                            Name: 셀 최고전압 4
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 4
                            <br />
                            CAN ID: 0x18F207F3
                            <br />
                            Name: 셀 최고전압 4 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최고전압 4
                            <br />
                            CAN ID: 0x18F207F3
                            <br />
                            Name: 셀 최고전압 4 No
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryCellMaxVolt2CellMaxVolt3Value = () => {
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMaxVolt2?.cellMaxVolt3, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMaxVolt2CellMaxVolt3BoxNoValue = () => {
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt2?.cellMaxVolt3BoxNo);
        }
        return "";
    };

    const getBatteryCellMaxVolt2CellMaxVolt3NoValue = () => {
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt2?.cellMaxVolt3No);
        }
        return "";
    };

    const getBatteryCellMaxVolt2CellMaxVolt4Value = () => {
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMaxVolt2?.cellMaxVolt4, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMaxVolt2CellMaxVolt4BoxNoValue = () => {
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt2?.cellMaxVolt4BoxNo);
        }
        return "";
    };

    const getBatteryCellMaxVolt2CellMaxVolt4NoValue = () => {
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMaxVolt2?.cellMaxVolt4No);
        }
        return "";
    };

    const onChangeBatteryCellMaxVolt2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryCellMaxVolt2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryCellMaxVolt2: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_KEY))
            chartDatasBatteryCellMaxVolt2.push(chartBatteryCellMaxVolt2CellMaxVolt3);
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_KEY))
            chartDatasBatteryCellMaxVolt2.push(chartBatteryCellMaxVolt2CellMaxVolt3BoxNo);
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_KEY))
            chartDatasBatteryCellMaxVolt2.push(chartBatteryCellMaxVolt2CellMaxVolt3No);
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_KEY))
            chartDatasBatteryCellMaxVolt2.push(chartBatteryCellMaxVolt2CellMaxVolt4);
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_KEY))
            chartDatasBatteryCellMaxVolt2.push(chartBatteryCellMaxVolt2CellMaxVolt4BoxNo);
        if (selectedBatteryCellMaxVolt2.includes(Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_KEY))
            chartDatasBatteryCellMaxVolt2.push(chartBatteryCellMaxVolt2CellMaxVolt4No);

        setSeriesChartDatasBatteryCellMaxVolt2(chartDatasBatteryCellMaxVolt2);
    }, [
        selectedBatteryCellMaxVolt2,
        chartBatteryCellMaxVolt2CellMaxVolt3,
        chartBatteryCellMaxVolt2CellMaxVolt3BoxNo,
        chartBatteryCellMaxVolt2CellMaxVolt3No,
        chartBatteryCellMaxVolt2CellMaxVolt4,
        chartBatteryCellMaxVolt2CellMaxVolt4BoxNo,
        chartBatteryCellMaxVolt2CellMaxVolt4No,
    ]);

    const tableDataBatteryCellMaxVolt2: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NAME,
            value: getBatteryCellMaxVolt2CellMaxVolt3Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_NAME,
            value: getBatteryCellMaxVolt2CellMaxVolt3BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_NAME,
            value: getBatteryCellMaxVolt2CellMaxVolt3NoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NAME,
            value: getBatteryCellMaxVolt2CellMaxVolt4Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_NAME,
            value: getBatteryCellMaxVolt2CellMaxVolt4BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_NAME,
            value: getBatteryCellMaxVolt2CellMaxVolt4NoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Cell Min Voltage 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryCellMinVolt1PanelOpen, setBatteryCellMinVolt1PanelOpen] = useState(false);
    const [batteryCellMinVolt1ChartMounted, setBatteryCellMinVolt1ChartMounted] = useState(false);
    const [selectedBatteryCellMinVolt1, setSelectedBatteryCellMinVolt1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryCellMinVolt1, setSeriesChartDatasBatteryCellMinVolt1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryCellMinVolt1CellMinVolt1, setChartBatteryCellMinVolt1CellMinVolt1] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt1CellMinVolt1BoxNo, setChartBatteryCellMinVolt1CellMinVolt1BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt1CellMinVolt1No, setChartBatteryCellMinVolt1CellMinVolt1No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt1CellMinVolt2, setChartBatteryCellMinVolt1CellMinVolt2] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt1CellMinVolt2BoxNo, setChartBatteryCellMinVolt1CellMinVolt2BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt1CellMinVolt2No, setChartBatteryCellMinVolt1CellMinVolt2No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatteryCellMinVolt1 = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 1
                            <br />
                            CAN ID: 0x18F208F3
                            <br />
                            Name: 셀 최저전압 1
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 1
                            <br />
                            CAN ID: 0x18F208F3
                            <br />
                            Name: 셀 최저전압 1 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 1
                            <br />
                            CAN ID: 0x18F208F3
                            <br />
                            Name: 셀 최저전압 1 No
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 2
                            <br />
                            CAN ID: 0x18F208F3
                            <br />
                            Name: 셀 최저전압 2
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 2
                            <br />
                            CAN ID: 0x18F208F3
                            <br />
                            Name: 셀 최저전압 2 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 1
                            <br />
                            CAN ID: 0x18F208F3
                            <br />
                            Name: 셀 최저전압 1 No
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryCellMinVolt1CellMinVolt1Value = () => {
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMinVolt1?.cellMinVolt1, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMinVolt1CellMinVolt1BoxNoValue = () => {
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt1?.cellMinVolt1BoxNo);
        }
        return "";
    };

    const getBatteryCellMinVolt1CellMinVolt1NoValue = () => {
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt1?.cellMinVolt1No);
        }
        return "";
    };

    const getBatteryCellMinVolt1CellMinVolt2Value = () => {
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMinVolt1?.cellMinVolt2, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMinVolt1CellMinVolt2BoxNoValue = () => {
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt1?.cellMinVolt2BoxNo);
        }
        return "";
    };

    const getBatteryCellMinVolt1CellMinVolt2NoValue = () => {
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt1?.cellMinVolt2No);
        }
        return "";
    };

    const onChangeBatteryCellMinVolt1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryCellMinVolt1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryCellMinVolt1: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_KEY))
            chartDatasBatteryCellMinVolt1.push(chartBatteryCellMinVolt1CellMinVolt1);
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_KEY))
            chartDatasBatteryCellMinVolt1.push(chartBatteryCellMinVolt1CellMinVolt1BoxNo);
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_KEY))
            chartDatasBatteryCellMinVolt1.push(chartBatteryCellMinVolt1CellMinVolt1No);
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_KEY))
            chartDatasBatteryCellMinVolt1.push(chartBatteryCellMinVolt1CellMinVolt2);
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_KEY))
            chartDatasBatteryCellMinVolt1.push(chartBatteryCellMinVolt1CellMinVolt2BoxNo);
        if (selectedBatteryCellMinVolt1.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_KEY))
            chartDatasBatteryCellMinVolt1.push(chartBatteryCellMinVolt1CellMinVolt2No);

        setSeriesChartDatasBatteryCellMinVolt1(chartDatasBatteryCellMinVolt1);
    }, [
        selectedBatteryCellMinVolt1,
        chartBatteryCellMinVolt1CellMinVolt1,
        chartBatteryCellMinVolt1CellMinVolt1BoxNo,
        chartBatteryCellMinVolt1CellMinVolt1No,
        chartBatteryCellMinVolt1CellMinVolt2,
        chartBatteryCellMinVolt1CellMinVolt2BoxNo,
        chartBatteryCellMinVolt1CellMinVolt2No,
    ]);

    const tableDataBatteryCellMinVolt1: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NAME,
            value: getBatteryCellMinVolt1CellMinVolt1Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_NAME,
            value: getBatteryCellMinVolt1CellMinVolt1BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_NAME,
            value: getBatteryCellMinVolt1CellMinVolt1NoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NAME,
            value: getBatteryCellMinVolt1CellMinVolt2Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_NAME,
            value: getBatteryCellMinVolt1CellMinVolt2BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_NAME,
            value: getBatteryCellMinVolt1CellMinVolt2NoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Cell Min Voltage 2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryCellMinVolt2PanelOpen, setBatteryCellMinVolt2PanelOpen] = useState(false);
    const [batteryCellMinVolt2ChartMounted, setBatteryCellMinVolt2ChartMounted] = useState(false);
    const [selectedBatteryCellMinVolt2, setSelectedBatteryCellMinVolt2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryCellMinVolt2, setSeriesChartDatasBatteryCellMinVolt2] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryCellMinVolt2CellMinVolt3, setChartBatteryCellMinVolt2CellMinVolt3] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt2CellMinVolt3BoxNo, setChartBatteryCellMinVolt2CellMinVolt3BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt2CellMinVolt3No, setChartBatteryCellMinVolt2CellMinVolt3No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt2CellMinVolt4, setChartBatteryCellMinVolt2CellMinVolt4] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt2CellMinVolt4BoxNo, setChartBatteryCellMinVolt2CellMinVolt4BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_NAME,
        data: [],
    });

    const [chartBatteryCellMinVolt2CellMinVolt4No, setChartBatteryCellMinVolt2CellMinVolt4No] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatteryCellMinVolt2 = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 3
                            <br />
                            CAN ID: 0x18F209F3
                            <br />
                            Name: 셀 최저전압 3
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 3
                            <br />
                            CAN ID: 0x18F209F3
                            <br />
                            Name: 셀 최저전압 3 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 3
                            <br />
                            CAN ID: 0x18F209F3
                            <br />
                            Name: 셀 최저전압 3 No
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 4
                            <br />
                            CAN ID: 0x18F209F3
                            <br />
                            Name: 셀 최저전압 4
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 4
                            <br />
                            CAN ID: 0x18F209F3
                            <br />
                            Name: 셀 최저전압 4 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 셀 최저전압 4
                            <br />
                            CAN ID: 0x18F209F3
                            <br />
                            Name: 셀 최저전압 4 No
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryCellMinVolt2CellMinVolt3Value = () => {
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMinVolt2?.cellMinVolt3, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMinVolt2CellMinVolt3BoxNoValue = () => {
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt2?.cellMinVolt3BoxNo);
        }
        return "";
    };

    const getBatteryCellMinVolt2CellMinVolt3NoValue = () => {
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt2?.cellMinVolt3No);
        }
        return "";
    };

    const getBatteryCellMinVolt2CellMinVolt4Value = () => {
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batCellMinVolt2?.cellMinVolt4, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryCellMinVolt2CellMinVolt4BoxNoValue = () => {
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt2?.cellMinVolt4BoxNo);
        }
        return "";
    };

    const getBatteryCellMinVolt2CellMinVolt4NoValue = () => {
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batCellMinVolt2?.cellMinVolt4No);
        }
        return "";
    };

    const onChangeBatteryCellMinVolt2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryCellMinVolt2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryCellMinVolt2: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_KEY))
            chartDatasBatteryCellMinVolt2.push(chartBatteryCellMinVolt2CellMinVolt3);
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_KEY))
            chartDatasBatteryCellMinVolt2.push(chartBatteryCellMinVolt2CellMinVolt3BoxNo);
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_KEY))
            chartDatasBatteryCellMinVolt2.push(chartBatteryCellMinVolt2CellMinVolt3No);
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_KEY))
            chartDatasBatteryCellMinVolt2.push(chartBatteryCellMinVolt2CellMinVolt4);
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_KEY))
            chartDatasBatteryCellMinVolt2.push(chartBatteryCellMinVolt2CellMinVolt4BoxNo);
        if (selectedBatteryCellMinVolt2.includes(Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_KEY))
            chartDatasBatteryCellMinVolt2.push(chartBatteryCellMinVolt2CellMinVolt4No);

        setSeriesChartDatasBatteryCellMinVolt2(chartDatasBatteryCellMinVolt2);
    }, [
        selectedBatteryCellMinVolt2,
        chartBatteryCellMinVolt2CellMinVolt3,
        chartBatteryCellMinVolt2CellMinVolt3BoxNo,
        chartBatteryCellMinVolt2CellMinVolt3No,
        chartBatteryCellMinVolt2CellMinVolt4,
        chartBatteryCellMinVolt2CellMinVolt4BoxNo,
        chartBatteryCellMinVolt2CellMinVolt4No,
    ]);

    const tableDataBatteryCellMinVolt2: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NAME,
            value: getBatteryCellMinVolt2CellMinVolt3Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_NAME,
            value: getBatteryCellMinVolt2CellMinVolt3BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_NAME,
            value: getBatteryCellMinVolt2CellMinVolt3NoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NAME,
            value: getBatteryCellMinVolt2CellMinVolt4Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_NAME,
            value: getBatteryCellMinVolt2CellMinVolt4BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_KEY,
            name: Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_NAME,
            value: getBatteryCellMinVolt2CellMinVolt4NoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Max Temperature
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryMaxTemperaturePanelOpen, setBatteryMaxTemperaturePanelOpen] = useState(false);
    const [batteryMaxTemperatureChartMounted, setBatteryMaxTemperatureChartMounted] = useState(false);
    const [selectedBatteryMaxTemperature, setSelectedBatteryMaxTemperature] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryMaxTemperature, setSeriesChartDatasBatteryMaxTemperature] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryMaxTemperaturePackMaxTemp1, setChartBatteryMaxTemperaturePackMaxTemp1] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_NAME,
        data: [],
    });
    const [chartBatteryMaxTemperaturePackMaxTemp1BoxNo, setChartBatteryMaxTemperaturePackMaxTemp1BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_NAME,
        data: [],
    });
    const [chartBatteryMaxTemperaturePackMaxTemp2, setChartBatteryMaxTemperaturePackMaxTemp2] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_NAME,
        data: [],
    });
    const [chartBatteryMaxTemperaturePackMaxTemp2BoxNo, setChartBatteryMaxTemperaturePackMaxTemp2BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_NAME,
        data: [],
    });
    const [chartBatteryMaxTemperaturePackMaxTemp3, setChartBatteryMaxTemperaturePackMaxTemp3] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_NAME,
        data: [],
    });
    const [chartBatteryMaxTemperaturePackMaxTemp3BoxNo, setChartBatteryMaxTemperaturePackMaxTemp3BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_NAME,
        data: [],
    });
    const [chartBatteryMaxTemperaturePackMaxTemp4, setChartBatteryMaxTemperaturePackMaxTemp4] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_NAME,
        data: [],
    });
    const [chartBatteryMaxTemperaturePackMaxTemp4BoxNo, setChartBatteryMaxTemperaturePackMaxTemp4BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatteryMaxTemperature = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 1
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 1
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 1
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 1 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 2
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 2
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 2
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 2 Box
                        </p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 3
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 3
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 3
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 3 Box
                        </p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 4
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 4
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최고온도 4
                            <br />
                            CAN ID: 0x18F20AF3
                            <br />
                            Name: 모듈 최고온도 4 Box
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryMaxTemperaturePackMaxTemp1Value = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMaxTemp?.batPackMaxTemp1, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMaxTemperaturePackMaxTemp1BoxNoValue = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMaxTemp?.batPackMaxTemp1BoxNo);
        }
        return "";
    };

    const getBatteryMaxTemperaturePackMaxTemp2Value = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMaxTemp?.batPackMaxTemp2, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMaxTemperaturePackMaxTemp2BoxNoValue = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMaxTemp?.batPackMaxTemp2BoxNo);
        }
        return "";
    };

    const getBatteryMaxTemperaturePackMaxTemp3Value = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMaxTemp?.batPackMaxTemp3, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMaxTemperaturePackMaxTemp3BoxNoValue = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMaxTemp?.batPackMaxTemp3BoxNo);
        }
        return "";
    };

    const getBatteryMaxTemperaturePackMaxTemp4Value = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMaxTemp?.batPackMaxTemp4, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMaxTemperaturePackMaxTemp4BoxNoValue = () => {
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMaxTemp?.batPackMaxTemp4BoxNo);
        }
        return "";
    };

    const onChangeBatteryMaxTemperature = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryMaxTemperature(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryMaxTemperature: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp1);
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp1BoxNo);
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp2);
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp2BoxNo);
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp3);
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp3BoxNo);
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp4);
        if (selectedBatteryMaxTemperature.includes(Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_KEY))
            chartDatasBatteryMaxTemperature.push(chartBatteryMaxTemperaturePackMaxTemp4BoxNo);

        setSeriesChartDatasBatteryMaxTemperature(chartDatasBatteryMaxTemperature);
    }, [
        selectedBatteryMaxTemperature,
        chartBatteryMaxTemperaturePackMaxTemp1,
        chartBatteryMaxTemperaturePackMaxTemp1BoxNo,
        chartBatteryMaxTemperaturePackMaxTemp2,
        chartBatteryMaxTemperaturePackMaxTemp2BoxNo,
        chartBatteryMaxTemperaturePackMaxTemp3,
        chartBatteryMaxTemperaturePackMaxTemp3BoxNo,
        chartBatteryMaxTemperaturePackMaxTemp4,
        chartBatteryMaxTemperaturePackMaxTemp4BoxNo,
    ]);

    const tableDataBatteryMaxTemperature: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp1Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp1BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp2Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp2BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp3Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp3BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp4Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_NAME,
            value: getBatteryMaxTemperaturePackMaxTemp4BoxNoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Min Temperature
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryMinTemperaturePanelOpen, setBatteryMinTemperaturePanelOpen] = useState(false);
    const [batteryMinTemperatureChartMounted, setBatteryMinTemperatureChartMounted] = useState(false);
    const [selectedBatteryMinTemperature, setSelectedBatteryMinTemperature] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryMinTemperature, setSeriesChartDatasBatteryMinTemperature] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryMinTemperaturePackMinTemp1, setChartBatteryMinTemperaturePackMinTemp1] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_NAME,
        data: [],
    });
    const [chartBatteryMinTemperaturePackMinTemp1BoxNo, setChartBatteryMinTemperaturePackMinTemp1BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_NAME,
        data: [],
    });
    const [chartBatteryMinTemperaturePackMinTemp2, setChartBatteryMinTemperaturePackMinTemp2] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_NAME,
        data: [],
    });
    const [chartBatteryMinTemperaturePackMinTemp2BoxNo, setChartBatteryMinTemperaturePackMinTemp2BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_NAME,
        data: [],
    });
    const [chartBatteryMinTemperaturePackMinTemp3, setChartBatteryMinTemperaturePackMinTemp3] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_NAME,
        data: [],
    });
    const [chartBatteryMinTemperaturePackMinTemp3BoxNo, setChartBatteryMinTemperaturePackMinTemp3BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_NAME,
        data: [],
    });
    const [chartBatteryMinTemperaturePackMinTemp4, setChartBatteryMinTemperaturePackMinTemp4] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_NAME,
        data: [],
    });
    const [chartBatteryMinTemperaturePackMinTemp4BoxNo, setChartBatteryMinTemperaturePackMinTemp4BoxNo] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatteryMinTemperature = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 1
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 1
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 1
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 1 Box
                        </p>
                    </>
                );

            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 2
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 2
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 2
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 2 Box
                        </p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 3
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 3
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 3
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 3 Box
                        </p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 4
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 4
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 최저온도 4
                            <br />
                            CAN ID: 0x18F20BF3
                            <br />
                            Name: 모듈 최저온도 4 Box
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryMinTemperaturePackMinTemp1Value = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMinTemp?.batPackMinTemp1, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMinTemperaturePackMinTemp1BoxNoValue = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMinTemp?.batPackMinTemp1BoxNo);
        }
        return "";
    };

    const getBatteryMinTemperaturePackMinTemp2Value = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMinTemp?.batPackMinTemp2, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMinTemperaturePackMinTemp2BoxNoValue = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMinTemp?.batPackMinTemp2BoxNo);
        }
        return "";
    };

    const getBatteryMinTemperaturePackMinTemp3Value = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMinTemp?.batPackMinTemp3, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMinTemperaturePackMinTemp3BoxNoValue = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMinTemp?.batPackMinTemp3BoxNo);
        }
        return "";
    };

    const getBatteryMinTemperaturePackMinTemp4Value = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batMinTemp?.batPackMinTemp4, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryMinTemperaturePackMinTemp4BoxNoValue = () => {
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batMinTemp?.batPackMinTemp4BoxNo);
        }
        return "";
    };

    const onChangeBatteryMinTemperature = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryMinTemperature(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryMinTemperature: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp1);
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp1BoxNo);
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp2);
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp2BoxNo);
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp3);
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp3BoxNo);
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp4);
        if (selectedBatteryMinTemperature.includes(Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_KEY))
            chartDatasBatteryMinTemperature.push(chartBatteryMinTemperaturePackMinTemp4BoxNo);

        setSeriesChartDatasBatteryMinTemperature(chartDatasBatteryMinTemperature);
    }, [
        selectedBatteryMinTemperature,
        chartBatteryMinTemperaturePackMinTemp1,
        chartBatteryMinTemperaturePackMinTemp1BoxNo,
        chartBatteryMinTemperaturePackMinTemp2,
        chartBatteryMinTemperaturePackMinTemp2BoxNo,
        chartBatteryMinTemperaturePackMinTemp3,
        chartBatteryMinTemperaturePackMinTemp3BoxNo,
        chartBatteryMinTemperaturePackMinTemp4,
        chartBatteryMinTemperaturePackMinTemp4BoxNo,
    ]);

    const tableDataBatteryMinTemperature: CanTableType[] = [
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_NAME,
            value: getBatteryMinTemperaturePackMinTemp1Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_NAME,
            value: getBatteryMinTemperaturePackMinTemp1BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_NAME,
            value: getBatteryMinTemperaturePackMinTemp2Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_NAME,
            value: getBatteryMinTemperaturePackMinTemp2BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_NAME,
            value: getBatteryMinTemperaturePackMinTemp3Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_NAME,
            value: getBatteryMinTemperaturePackMinTemp3BoxNoValue(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_NAME,
            value: getBatteryMinTemperaturePackMinTemp4Value(),
        },
        {
            key: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_KEY,
            name: Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_NAME,
            value: getBatteryMinTemperaturePackMinTemp4BoxNoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Isolation Monitor
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [isolationMonitorPanelOpen, setIsolationMonitorPanelOpen] = useState(false);
    const [isolationMonitorChartMounted, setIsolationMonitorChartMounted] = useState(false);
    const [selectedIsolationMonitor, setSelectedIsolationMonitor] = useState<Array<React.Key>>([]);

    const [seriesChartDatasIsolationMonitor, setSeriesChartDatasIsolationMonitor] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartIsolationMonitorAlarmStatus, setChartIsolationMonitorAlarmStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_NAME,
        data: [],
    });

    const [chartIsolationMonitorStatus, setChartIsolationMonitorStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_ISOLATION_MONITOR_STATUS_NAME,
        data: [],
    });

    const [chartIsolationMonitorResistance, setChartIsolationMonitorResistance] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_ISOLATION_MONITOR_RESISTANCE_NAME,
        data: [],
    });

    const [chartIsolationMonitorBatteryVoltage, setChartIsolationMonitorBatteryVoltage] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_NAME,
        data: [],
    });

    const [chartIsolationMonitorLifeSignal, setChartIsolationMonitorLifeSignal] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_ISOLATION_MONITOR_LIFE_SIGNAL_NAME,
        data: [],
    });

    const getTabelTooltipIsolationMonitor = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_KEY:
                return (
                    <>
                        <p>
                            절연 측정기
                            <br />
                            CAN ID: 0x18FF2B49
                            <br />
                            Name: 절연측정기 경보상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상
                            <br />
                            01-2급 경보 500&#937;/V
                            <br />
                            10-1급 경보 100&#937;/V
                        </p>
                    </>
                );
            case Common.SKYWELL_ISOLATION_MONITOR_STATUS_KEY:
                return (
                    <>
                        <p>
                            절연 측정기
                            <br />
                            CAN ID: 0x18FF2B49
                            <br />
                            Name: 절연측정기 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0001-자가검진 중
                            <br />
                            0010-정상작동
                            <br />
                            0100-접속고장
                            <br />
                            0101-고압+극과 접지절연정항 값이 비교적 낮음
                            <br />
                            0110-고압-극과 접지절연정항 값이 비교적 낮음
                            <br />
                            0111-설비고장
                        </p>
                    </>
                );

            case Common.SKYWELL_ISOLATION_MONITOR_RESISTANCE_KEY:
                return (
                    <>
                        <p>
                            절연 측정기
                            <br />
                            CAN ID: 0x18FF2B49
                            <br />
                            Name: 절연저항
                        </p>
                        <p>Remarks: k&#937;</p>
                    </>
                );

            case Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            절연 측정기
                            <br />
                            CAN ID: 0x18FF2B49
                            <br />
                            Name: 배터리 전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.SKYWELL_ISOLATION_MONITOR_LIFE_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            절연 측정기
                            <br />
                            CAN ID: 0x18FF2B49
                            <br />
                            Name: Life 신호
                        </p>
                        <p>Remarks: 0 ~ 255</p>
                    </>
                );
        }

        return null;
    };

    const convertIsolationMonitorAlarmStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "2급경보(500Ω/V)";
                break;
            case 2:
                valueString = "1급경보(100Ω/V)";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertIsolationMonitorStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 1:
                valueString = "자가검진 중";
                break;
            case 2:
                valueString = "정상작동";
                break;
            case 4:
                valueString = "접속고장";
                break;
            case 5:
                valueString = "고압+극 저항 낮음";
                break;
            case 6:
                valueString = "고압-극 저항 낮음";
                break;
            case 7:
                valueString = "설비고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getIsolationMonitorAlarmStatusValue = () => {
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_KEY) && currentCanData !== undefined) {
            return convertIsolationMonitorAlarmStatusRemark(currentCanData.isolationMonitor?.alarmStatus);
        }
        return "";
    };

    const getIsolationMonitorStatusValue = () => {
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_STATUS_KEY) && currentCanData !== undefined) {
            return convertIsolationMonitorStatusRemark(currentCanData.isolationMonitor?.status);
        }
        return "";
    };

    const getIsolationMonitorResistanceValue = () => {
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_RESISTANCE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.isolationMonitor?.resistance, Common.UNIT_K_OHM);
        }
        return "";
    };

    const getIsolationMonitorBatteryVoltageValue = () => {
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.isolationMonitor?.batVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getIsolationMonitorLifeSignalValue = () => {
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.isolationMonitor?.lifeSignal);
        }
        return "";
    };

    const onChangeIsolationMonitor = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedIsolationMonitor(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasIsolationMonitor: Array<TypeUtils.ChartData> = [];

        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_KEY))
            chartDatasIsolationMonitor.push(chartIsolationMonitorAlarmStatus);
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_STATUS_KEY))
            chartDatasIsolationMonitor.push(chartIsolationMonitorStatus);
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_RESISTANCE_KEY))
            chartDatasIsolationMonitor.push(chartIsolationMonitorResistance);
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_KEY))
            chartDatasIsolationMonitor.push(chartIsolationMonitorBatteryVoltage);
        if (selectedIsolationMonitor.includes(Common.SKYWELL_ISOLATION_MONITOR_LIFE_SIGNAL_KEY))
            chartDatasIsolationMonitor.push(chartIsolationMonitorLifeSignal);
        setSeriesChartDatasIsolationMonitor(chartDatasIsolationMonitor);
    }, [
        selectedIsolationMonitor,
        chartIsolationMonitorAlarmStatus,
        chartIsolationMonitorStatus,
        chartIsolationMonitorResistance,
        chartIsolationMonitorBatteryVoltage,
        chartIsolationMonitorLifeSignal,
    ]);

    const tableDataIsolationMonitor: CanTableType[] = [
        {
            key: Common.SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_KEY,
            name: Common.SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_NAME,
            value: getIsolationMonitorAlarmStatusValue(),
        },
        {
            key: Common.SKYWELL_ISOLATION_MONITOR_STATUS_KEY,
            name: Common.SKYWELL_ISOLATION_MONITOR_STATUS_NAME,
            value: getIsolationMonitorStatusValue(),
        },
        {
            key: Common.SKYWELL_ISOLATION_MONITOR_RESISTANCE_KEY,
            name: Common.SKYWELL_ISOLATION_MONITOR_RESISTANCE_NAME,
            value: getIsolationMonitorResistanceValue(),
        },
        {
            key: Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_KEY,
            name: Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_NAME,
            value: getIsolationMonitorBatteryVoltageValue(),
        },
        {
            key: Common.SKYWELL_ISOLATION_MONITOR_LIFE_SIGNAL_KEY,
            name: Common.SKYWELL_ISOLATION_MONITOR_LIFE_SIGNAL_NAME,
            value: getIsolationMonitorLifeSignalValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VCU Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuStatusPanelOpen, setVcuStatusPanelOpen] = useState(false);
    const [vcuStatusChartMounted, setVcuStatusChartMounted] = useState(false);
    const [selectedVcuStatus, setSelectedVcuStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuStatus, setSeriesChartDatasVcuStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVcuStatusVehicleStatus, setChartVcuStatusVehicleStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VCU_STATUS_VEHICLE_STATUS_NAME,
        data: [],
    });

    const [chartVcuStatusVehicleSpeed, setChartVcuStatusVehicleSpeed] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VCU_STATUS_VEHICLE_SPEED_NAME,
        data: [],
    });

    const [chartVcuStatusGearStatus, setChartVcuStatusGearStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VCU_STATUS_GEAR_STATUS_NAME,
        data: [],
    });

    const [chartVcuStatusVcuFail, setChartVcuStatusVcuFail] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VCU_STATUS_VCU_FAIL_NAME,
        data: [],
    });

    const [chartVcuStatusLifeSignal, setChartVcuStatusLifeSignal] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VCU_STATUS_LIFE_SIGNAL_NAME,
        data: [],
    });

    const getTabelTooltipVcuStatus = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_VCU_STATUS_VEHICLE_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU 상태정보
                            <br />
                            CAN ID: 0x181001D0
                            <br />
                            Name: 차량상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-WAIT
                            <br />
                            01-READY
                        </p>
                    </>
                );
            case Common.SKYWELL_VCU_STATUS_VEHICLE_SPEED_KEY:
                return (
                    <>
                        <p>
                            VCU 상태정보
                            <br />
                            CAN ID: 0x181001D0
                            <br />
                            Name: 차량속도
                        </p>
                        <p>Remarks: km/h</p>
                    </>
                );
            case Common.SKYWELL_VCU_STATUS_GEAR_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU 상태정보
                            <br />
                            CAN ID: 0x181001D0
                            <br />
                            Name: 기어상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            000-N, 001-R
                            <br />
                            010-D1, 011-D2
                            <br />
                            100-D3, 101-D4
                            <br />
                            110-D5, 111-D6
                        </p>
                    </>
                );
            case Common.SKYWELL_VCU_STATUS_VCU_FAIL_KEY:
                return (
                    <>
                        <p>
                            VCU 상태정보
                            <br />
                            CAN ID: 0x181001D0
                            <br />
                            Name: VCU 고장
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-고장없음
                            <br />
                            1-시스템 고장
                        </p>
                    </>
                );
            case Common.SKYWELL_VCU_STATUS_LIFE_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            VCU 상태정보
                            <br />
                            CAN ID: 0x181001D0
                            <br />
                            Name: Life 신호
                        </p>
                        <p>Remarks: 0 ~ 255</p>
                    </>
                );
        }

        return null;
    };

    const convertVcuStatusVehicleStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "WAIT";
                break;
            case 1:
                valueString = "READY";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertVcuStatusGearStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "N";
                break;
            case 1:
                valueString = "R";
                break;
            case 2:
                valueString = "D1";
                break;
            case 3:
                valueString = "D2";
                break;
            case 4:
                valueString = "D3";
                break;
            case 5:
                valueString = "D4";
                break;
            case 6:
                valueString = "D5";
                break;
            case 7:
                valueString = "D6";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertVcuStatusVcuFailRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "고장없음";
                break;
            case 1:
                valueString = "시스템 고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getVcuStatusVehicleStatusValue = () => {
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_VEHICLE_STATUS_KEY) && currentCanData !== undefined) {
            return convertVcuStatusVehicleStatusRemark(currentCanData.vcuStatus?.vehicleStatus);
        }
        return "";
    };

    const getVcuStatusVehicleSpeedValue = () => {
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_VEHICLE_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuStatus?.vehicleSpeed, Common.UNIT_SPEED);
        }
        return "";
    };

    const getVcuStatusGearStatusValue = () => {
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_GEAR_STATUS_KEY) && currentCanData !== undefined) {
            return convertVcuStatusGearStatusRemark(currentCanData.vcuStatus?.gearStatus);
        }
        return "";
    };

    const getVcuStatusVcuFailValue = () => {
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_VCU_FAIL_KEY) && currentCanData !== undefined) {
            return convertVcuStatusVcuFailRemark(currentCanData.vcuStatus?.vcuFail);
        }
        return "";
    };

    const getVcuStatusLifeSignalValue = () => {
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_LIFE_SIGNAL_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuStatus?.lifeSignal);
        }
        return "";
    };

    const onChangeVcuStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuStatus: Array<TypeUtils.ChartData> = [];

        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_VEHICLE_STATUS_KEY)) chartDatasVcuStatus.push(chartVcuStatusVehicleStatus);
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_VEHICLE_SPEED_KEY)) chartDatasVcuStatus.push(chartVcuStatusVehicleSpeed);
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_GEAR_STATUS_KEY)) chartDatasVcuStatus.push(chartVcuStatusGearStatus);
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_VCU_FAIL_KEY)) chartDatasVcuStatus.push(chartVcuStatusVcuFail);
        if (selectedVcuStatus.includes(Common.SKYWELL_VCU_STATUS_LIFE_SIGNAL_KEY)) chartDatasVcuStatus.push(chartVcuStatusLifeSignal);
        setSeriesChartDatasVcuStatus(chartDatasVcuStatus);
    }, [
        selectedVcuStatus,
        chartVcuStatusVehicleStatus,
        chartVcuStatusVehicleSpeed,
        chartVcuStatusGearStatus,
        chartVcuStatusVcuFail,
        chartVcuStatusLifeSignal,
    ]);

    const tableDataVcuStatus: CanTableType[] = [
        {
            key: Common.SKYWELL_VCU_STATUS_VEHICLE_STATUS_KEY,
            name: Common.SKYWELL_VCU_STATUS_VEHICLE_STATUS_NAME,
            value: getVcuStatusVehicleStatusValue(),
        },
        {
            key: Common.SKYWELL_VCU_STATUS_VEHICLE_SPEED_KEY,
            name: Common.SKYWELL_VCU_STATUS_VEHICLE_SPEED_NAME,
            value: getVcuStatusVehicleSpeedValue(),
        },
        {
            key: Common.SKYWELL_VCU_STATUS_GEAR_STATUS_KEY,
            name: Common.SKYWELL_VCU_STATUS_GEAR_STATUS_NAME,
            value: getVcuStatusGearStatusValue(),
        },
        {
            key: Common.SKYWELL_VCU_STATUS_VCU_FAIL_KEY,
            name: Common.SKYWELL_VCU_STATUS_VCU_FAIL_NAME,
            value: getVcuStatusVcuFailValue(),
        },
        {
            key: Common.SKYWELL_VCU_STATUS_LIFE_SIGNAL_KEY,
            name: Common.SKYWELL_VCU_STATUS_LIFE_SIGNAL_NAME,
            value: getVcuStatusLifeSignalValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region MCU Status 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [mcuStatus1PanelOpen, setMcuStatus1PanelOpen] = useState(false);
    const [mcuStatus1ChartMounted, setMcuStatus1ChartMounted] = useState(false);
    const [selectedMcuStatus1, setSelectedMcuStatus1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasMcuStatus1, setSeriesChartDatasMcuStatus1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartMcuStatus1MotorTemp, setChartMcuStatus1MotorTemp] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_MCU_STATUS_1_MOTOR_TEMP_NAME,
        data: [],
    });

    const [chartMcuStatus1MotorControllerTemp, setChartMcuStatus1MotorControllerTemp] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_MCU_STATUS_1_MCU_TEMP_NAME,
        data: [],
    });

    const [chartMcuStatus1ConverterVolt, setChartMcuStatus1ConverterVolt] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_NAME,
        data: [],
    });

    const getTabelTooltipMcuStatus1 = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_MCU_STATUS_1_MOTOR_TEMP_KEY:
                return (
                    <>
                        <p>
                            MCU 상태정보 1
                            <br />
                            CAN ID: 0x18F501F0
                            <br />
                            Name: 모터온도
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_MCU_STATUS_1_MCU_TEMP_KEY:
                return (
                    <>
                        <p>
                            MCU 상태정보 1
                            <br />
                            CAN ID: 0x18F501F0
                            <br />
                            Name: 모터컨트롤러 온도
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
            case Common.SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_KEY:
                return (
                    <>
                        <p>
                            MCU 상태정보 1
                            <br />
                            CAN ID: 0x18F501F0
                            <br />
                            Name: 컨버터 전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
        }

        return null;
    };

    const getMcuStatus1MotorTempValue = () => {
        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_1_MOTOR_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mcuStatus1?.motorTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getMcuStatus1MotorControllerTempValue = () => {
        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_1_MCU_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mcuStatus1?.motorControllerTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getMcuStatus1ConverterVoltValue = () => {
        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mcuStatus1?.converterVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeMcuStatus1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedMcuStatus1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasMcuStatus1: Array<TypeUtils.ChartData> = [];

        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_1_MOTOR_TEMP_KEY)) chartDatasMcuStatus1.push(chartMcuStatus1MotorTemp);
        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_1_MCU_TEMP_KEY)) chartDatasMcuStatus1.push(chartMcuStatus1MotorControllerTemp);
        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_KEY)) chartDatasMcuStatus1.push(chartMcuStatus1ConverterVolt);

        setSeriesChartDatasMcuStatus1(chartDatasMcuStatus1);
    }, [selectedMcuStatus1, chartMcuStatus1MotorTemp, chartMcuStatus1MotorControllerTemp, chartMcuStatus1ConverterVolt]);

    const tableDataMcuStatus1: CanTableType[] = [
        {
            key: Common.SKYWELL_MCU_STATUS_1_MOTOR_TEMP_KEY,
            name: Common.SKYWELL_MCU_STATUS_1_MOTOR_TEMP_NAME,
            value: getMcuStatus1MotorTempValue(),
        },
        {
            key: Common.SKYWELL_MCU_STATUS_1_MCU_TEMP_KEY,
            name: Common.SKYWELL_MCU_STATUS_1_MCU_TEMP_NAME,
            value: getMcuStatus1MotorControllerTempValue(),
        },
        {
            key: Common.SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_KEY,
            name: Common.SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_NAME,
            value: getMcuStatus1ConverterVoltValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region MCU Status 2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [mcuStatus2PanelOpen, setMcuStatus2PanelOpen] = useState(false);
    const [mcuStatus2ChartMounted, setMcuStatus2ChartMounted] = useState(false);
    const [selectedMcuStatus2, setSelectedMcuStatus2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasMcuStatus2, setSeriesChartDatasMcuStatus2] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartMcuStatus2MotorRpm, setChartMcuStatus2MotorRpm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_MCU_STATUS_2_MOTOR_RPM_NAME,
        data: [],
    });

    const [chartMcuStatus2MotorTorque, setChartMcuStatus2MotorTorque] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_NAME,
        data: [],
    });

    const getTabelTooltipMcuStatus2 = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_MCU_STATUS_2_MOTOR_RPM_KEY:
                return (
                    <>
                        <p>
                            MCU 상태정보 2
                            <br />
                            CAN ID: 0x18F502F0
                            <br />
                            Name: 모터 회전속도
                        </p>
                        <p>Remarks: rpm</p>
                    </>
                );
            case Common.SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_KEY:
                return (
                    <>
                        <p>
                            MCU 상태정보 2
                            <br />
                            CAN ID: 0x18F502F0
                            <br />
                            Name: 모터 출력 토크
                        </p>
                        <p>Remarks: rpm</p>
                    </>
                );
        }

        return null;
    };

    const getMcuStatus2MotorRpmValue = () => {
        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_2_MOTOR_RPM_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mcuStatus2?.motorRpm, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getMcuStatus2MotorTorqueValue = () => {
        if (selectedMcuStatus1.includes(Common.SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mcuStatus2?.motorTorque, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const onChangeMcuStatus2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedMcuStatus2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasMcuStatus2: Array<TypeUtils.ChartData> = [];

        if (selectedMcuStatus2.includes(Common.SKYWELL_MCU_STATUS_2_MOTOR_RPM_KEY)) chartDatasMcuStatus2.push(chartMcuStatus2MotorRpm);
        if (selectedMcuStatus2.includes(Common.SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_KEY)) chartDatasMcuStatus2.push(chartMcuStatus2MotorTorque);

        setSeriesChartDatasMcuStatus2(chartDatasMcuStatus2);
    }, [selectedMcuStatus2, chartMcuStatus2MotorRpm, chartMcuStatus2MotorTorque]);

    const tableDataMcuStatus2: CanTableType[] = [
        {
            key: Common.SKYWELL_MCU_STATUS_2_MOTOR_RPM_KEY,
            name: Common.SKYWELL_MCU_STATUS_2_MOTOR_RPM_NAME,
            value: getMcuStatus2MotorRpmValue(),
        },
        {
            key: Common.SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_KEY,
            name: Common.SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_NAME,
            value: getMcuStatus2MotorTorqueValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Vehicle Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vehicleStatusPanelOpen, setVehicleStatusPanelOpen] = useState(false);
    const [vehicleStatusChartMounted, setVehicleStatusChartMounted] = useState(false);
    const [selectedVehicleStatus, setSelectedVehicleStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVehicleStatus, setSeriesChartDatasVehicleStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVehicleStatusLowBatVolt, setChartVehicleStatusLowBatVolt] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_NAME,
        data: [],
    });

    const [chartVehicleStatusRightTurnStatus, setChartVehicleStatusRightTurnStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusLeftTurnStatus, setChartVehicleStatusLeftTurnStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusPositionLightStatus, setChartVehicleStatusPositionLightStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusStartSignal, setChartVehicleStatusStartSignal] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_START_SIGNAL_NAME,
        data: [],
    });

    const [chartVehicleStatusKeyOnSignal, setChartVehicleStatusKeyOnSignal] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_NAME,
        data: [],
    });

    const [chartVehicleStatusRearDoorStatus, setChartVehicleStatusRearDoorStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusFrontDoorStatus, setChartVehicleStatusFrontDoorStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusMiddleDoorStatus, setChartVehicleStatusMiddleDoorStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusMotorCoolingWaterAlarm, setChartVehicleStatusMotorCoolingWaterAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusDoorPumpAirAlarm, setChartVehicleStatusDoorPumpAirAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusDriverLeavingAlarm, setChartVehicleStatusDriverLeavingAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusStopStatus, setChartVehicleStatusStopStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_STOP_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusHandBrakeStatus, setChartVehicleStatusHandBrakeStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusFrontAirPressureAlarm, setChartVehicleStatusFrontAirPressureAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusRearAirPressureAlarm, setChartVehicleStatusRearAirPressureAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusFrontBrakeAirPressure, setChartVehicleStatusFrontBrakeAirPressure] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_NAME,
        data: [],
    });

    const [chartVehicleStatusRearBrakeAirPressure, setChartVehicleStatusRearBrakeAirPressure] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_NAME,
        data: [],
    });

    const [chartVehicleStatusHydrogenPortHatchStatus, setChartVehicleStatusHydrogenPortHatchStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusAccWakeupStatus, setChartVehicleStatusAccWakeupStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusSmartKeyStatus, setChartVehicleStatusSmartKeyStatus] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_NAME,
        data: [],
    });

    const [chartVehicleStatusRearFogLight, setChartVehicleStatusRearFogLight] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_NAME,
        data: [],
    });

    const [chartVehicleStatusFrontFogLight, setChartVehicleStatusFrontFogLight] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_NAME,
        data: [],
    });

    const [chartVehicleStatusLowBeam, setChartVehicleStatusLowBeam] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_LOW_BEAM_NAME,
        data: [],
    });

    const [chartVehicleStatusHighBeam, setChartVehicleStatusHighBeam] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_HIGH_BEAM_NAME,
        data: [],
    });

    const [chartVehicleStatusBrakeLight, setChartVehicleStatusBrakeLight] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_NAME,
        data: [],
    });

    const [chartVehicleStatusRightRearBrakeShoeAlarm, setChartVehicleStatusRightRearBrakeShoeAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusLeftRearBrakeShoeAlarm, setChartVehicleStatusLeftRearBrakeShoeAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusRightFrontBrakeShoeAlarm, setChartVehicleStatusRightFrontBrakeShoeAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_NAME,
        data: [],
    });

    const [chartVehicleStatusLeftFrontBrakeShoeAlarm, setChartVehicleStatusLeftFrontBrakeShoeAlarm] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_NAME,
        data: [],
    });

    const getTabelTooltipVehicleStatus = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 저압 배터리 전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 우회전 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );

            case Common.SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 좌회전 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );

            case Common.SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 포지션 램프 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_START_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: Start 신호
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: Key On 신호
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 후방 도어 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-Close, 1-Open
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 승차문 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-Close, 1-Open
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 하차문 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-Close, 1-Open
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 모터 냉각수 부족 경보
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 도어 펌프 에어 경보
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 기사 자리비움 경보
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_STOP_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: STOP 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 수동 브레이크 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 전방 에어 경보
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 후방 에어 경보
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 전방 브레이크 공기압
                        </p>
                        <p>Remarks: kPa</p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 후방 브레이크 공기압
                        </p>
                        <p>Remarks: kPa</p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: Hydrogen Port Hatch
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );

            case Common.SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: ACC 응답상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );

            case Common.SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 스마트키 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 후방 안개등
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 전방 안개등
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_LOW_BEAM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 하향등
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_HIGH_BEAM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 상향등
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 제동등
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-유효
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 우측후방 브레이크슈
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 좌측후방 브레이크슈
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 우측전방 브레이크슈
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_KEY:
                return (
                    <>
                        <p>
                            차량 상태정보
                            <br />
                            CAN ID: 0x18F40117
                            <br />
                            Name: 좌측전방 브레이크슈
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-무효, 1-경보
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertVehicleStatusStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "무효";
                break;
            case 1:
                valueString = "유효";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertVehicleStatusDoorRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Close";
                break;
            case 1:
                valueString = "Open";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertVehicleStatusAlarmRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "무효";
                break;
            case 1:
                valueString = "경보";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getVehicleStatusLowBatVoltValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleStatus?.lowBatVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getVehicleStatusRightTurnStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.rightTurnStatus);
        }
        return "";
    };

    const getVehicleStatusLeftTurnStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.leftTurnStatus);
        }
        return "";
    };

    const getVehicleStatusPositionLightStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.positionLightStatus);
        }
        return "";
    };

    const getVehicleStatusStartSignalValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_START_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.startSignal);
        }
        return "";
    };

    const getVehicleStatusKeyOnSignalValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.keyOnSignal);
        }
        return "";
    };

    const getVehicleStatusRearDoorStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusDoorRemark(currentCanData.vehicleStatus?.rearDoorStatus);
        }
        return "";
    };

    const getVehicleStatusFrontDoorStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusDoorRemark(currentCanData.vehicleStatus?.frontDoorStatus);
        }
        return "";
    };

    const getVehicleStatusMiddleDoorStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusDoorRemark(currentCanData.vehicleStatus?.middleDoorStatus);
        }
        return "";
    };

    const getVehicleStatusMotorCoolingWaterAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.motorCoolingWaterAlarm);
        }
        return "";
    };

    const getVehicleStatusDoorPumpAirAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.doorPumpAirAlarm);
        }
        return "";
    };

    const getVehicleStatusDriverLeavingAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.driverLeavingAlarm);
        }
        return "";
    };

    const getVehicleStatusStopStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_STOP_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.stopStatus);
        }
        return "";
    };

    const getVehicleStatusHandBrakeStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.handBrakeStatus);
        }
        return "";
    };

    const getVehicleStatusFrontAirPressureAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.frontAirPressureAlarm);
        }
        return "";
    };

    const getVehicleStatusRearAirPressureAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.rearAirPressureAlarm);
        }
        return "";
    };

    const getVehicleStatusFrontBrakeAirPressureValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleStatus?.frontBrakeAirPressure, Common.UNIT_K_PASCAL);
        }
        return "";
    };

    const getVehicleStatusRearBrakeAirPressureValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleStatus?.rearBrakeAirPressure, Common.UNIT_K_PASCAL);
        }
        return "";
    };

    const getVehicleStatusHydrogenPortHatchStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.hydrogenPortHatchStatus);
        }
        return "";
    };

    const getVehicleStatusAccWakeupStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.accWakeupStatus);
        }
        return "";
    };

    const getVehicleStatusSmartKeyStatusValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.smartKeyStatus);
        }
        return "";
    };

    const getVehicleStatusRearFogLightValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.rearFogLight);
        }
        return "";
    };

    const getVehicleStatusFrontFogLightValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.frontFogLight);
        }
        return "";
    };

    const getVehicleStatusLowBeamValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LOW_BEAM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.lowBeam);
        }
        return "";
    };

    const getVehicleStatusHighBeamValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_HIGH_BEAM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.highBeam);
        }
        return "";
    };

    const getVehicleStatusBrakeLightValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusStatusRemark(currentCanData.vehicleStatus?.brakeLight);
        }
        return "";
    };

    const getVehicleStatusRightRearBrakeShoeAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.rightRearBrakeShoeAlarm);
        }
        return "";
    };

    const getVehicleStatusLeftRearBrakeShoeAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.leftRearBrakeShoeAlarm);
        }
        return "";
    };

    const getVehicleStatusRightFrontBrakeShoeAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.rightFrontBrakeShoeAlarm);
        }
        return "";
    };

    const getVehicleStatusLeftFrontBrakeShoeAlarmValue = () => {
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusAlarmRemark(currentCanData.vehicleStatus?.leftFrontBrakeShoeAlarm);
        }
        return "";
    };

    const onChangeVehicleStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVehicleStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVehicleStatus: Array<TypeUtils.ChartData> = [];

        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusLowBatVolt);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusRightTurnStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusLeftTurnStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusPositionLightStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_START_SIGNAL_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusStartSignal);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusKeyOnSignal);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusRearDoorStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusFrontDoorStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusMiddleDoorStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusMotorCoolingWaterAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusDoorPumpAirAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusDriverLeavingAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_STOP_STATUS_KEY)) chartDatasVehicleStatus.push(chartVehicleStatusStopStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusHandBrakeStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusFrontAirPressureAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusRearAirPressureAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusFrontBrakeAirPressure);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusRearBrakeAirPressure);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusHydrogenPortHatchStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusAccWakeupStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusSmartKeyStatus);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusRearFogLight);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusFrontFogLight);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LOW_BEAM_KEY)) chartDatasVehicleStatus.push(chartVehicleStatusLowBeam);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_HIGH_BEAM_KEY)) chartDatasVehicleStatus.push(chartVehicleStatusHighBeam);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_KEY)) chartDatasVehicleStatus.push(chartVehicleStatusBrakeLight);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusRightRearBrakeShoeAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusLeftRearBrakeShoeAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusRightFrontBrakeShoeAlarm);
        if (selectedVehicleStatus.includes(Common.SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_KEY))
            chartDatasVehicleStatus.push(chartVehicleStatusLeftFrontBrakeShoeAlarm);

        setSeriesChartDatasVehicleStatus(chartDatasVehicleStatus);
    }, [
        selectedVehicleStatus,
        chartVehicleStatusLowBatVolt,
        chartVehicleStatusRightTurnStatus,
        chartVehicleStatusLeftTurnStatus,
        chartVehicleStatusPositionLightStatus,
        chartVehicleStatusStartSignal,
        chartVehicleStatusKeyOnSignal,
        chartVehicleStatusRearDoorStatus,
        chartVehicleStatusFrontDoorStatus,
        chartVehicleStatusMiddleDoorStatus,
        chartVehicleStatusMotorCoolingWaterAlarm,
        chartVehicleStatusDoorPumpAirAlarm,
        chartVehicleStatusDriverLeavingAlarm,
        chartVehicleStatusStopStatus,
        chartVehicleStatusHandBrakeStatus,
        chartVehicleStatusFrontAirPressureAlarm,
        chartVehicleStatusRearAirPressureAlarm,
        chartVehicleStatusFrontBrakeAirPressure,
        chartVehicleStatusRearBrakeAirPressure,
        chartVehicleStatusHydrogenPortHatchStatus,
        chartVehicleStatusAccWakeupStatus,
        chartVehicleStatusSmartKeyStatus,
        chartVehicleStatusRearFogLight,
        chartVehicleStatusFrontFogLight,
        chartVehicleStatusLowBeam,
        chartVehicleStatusHighBeam,
        chartVehicleStatusBrakeLight,
        chartVehicleStatusRightRearBrakeShoeAlarm,
        chartVehicleStatusLeftRearBrakeShoeAlarm,
        chartVehicleStatusRightFrontBrakeShoeAlarm,
        chartVehicleStatusLeftFrontBrakeShoeAlarm,
    ]);

    const tableDataVehicleStatus: CanTableType[] = [
        {
            key: Common.SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_NAME,
            value: getVehicleStatusLowBatVoltValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_NAME,
            value: getVehicleStatusRightTurnStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_NAME,
            value: getVehicleStatusLeftTurnStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_NAME,
            value: getVehicleStatusPositionLightStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_START_SIGNAL_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_START_SIGNAL_NAME,
            value: getVehicleStatusStartSignalValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_NAME,
            value: getVehicleStatusKeyOnSignalValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_NAME,
            value: getVehicleStatusRearDoorStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_NAME,
            value: getVehicleStatusFrontDoorStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_NAME,
            value: getVehicleStatusMiddleDoorStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_NAME,
            value: getVehicleStatusMotorCoolingWaterAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_NAME,
            value: getVehicleStatusDoorPumpAirAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_NAME,
            value: getVehicleStatusDriverLeavingAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_STOP_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_STOP_STATUS_NAME,
            value: getVehicleStatusStopStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_NAME,
            value: getVehicleStatusHandBrakeStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_NAME,
            value: getVehicleStatusFrontAirPressureAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_NAME,
            value: getVehicleStatusRearAirPressureAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_NAME,
            value: getVehicleStatusFrontBrakeAirPressureValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_NAME,
            value: getVehicleStatusRearBrakeAirPressureValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_NAME,
            value: getVehicleStatusHydrogenPortHatchStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_NAME,
            value: getVehicleStatusAccWakeupStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_NAME,
            value: getVehicleStatusSmartKeyStatusValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_NAME,
            value: getVehicleStatusRearFogLightValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_NAME,
            value: getVehicleStatusFrontFogLightValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_LOW_BEAM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_LOW_BEAM_NAME,
            value: getVehicleStatusLowBeamValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_HIGH_BEAM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_HIGH_BEAM_NAME,
            value: getVehicleStatusHighBeamValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_NAME,
            value: getVehicleStatusBrakeLightValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_NAME,
            value: getVehicleStatusRightRearBrakeShoeAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_NAME,
            value: getVehicleStatusLeftRearBrakeShoeAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_NAME,
            value: getVehicleStatusRightFrontBrakeShoeAlarmValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_KEY,
            name: Common.SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_NAME,
            value: getVehicleStatusLeftFrontBrakeShoeAlarmValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Vehicle Driving Info
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vehicleDrivingInfoPanelOpen, setVehicleDrivingInfoPanelOpen] = useState(false);
    const [vehicleDrivingInfoChartMounted, setVehicleDrivingInfoChartMounted] = useState(false);
    const [selectedVehicleDrivingInfo, setSelectedVehicleDrivingInfo] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVehicleDrivingInfo, setSeriesChartDatasVehicleDrivingInfo] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVehicleDrivingInfoTotalMileage, setChartVehicleDrivingInfoTotalMileage] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_NAME,
        data: [],
    });

    const [chartVehicleDrivingInfoProtocolVer, setChartVehicleDrivingInfoProtocolVer] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_NAME,
        data: [],
    });

    const [chartVehicleDrivingInfoProgramVer, setChartVehicleDrivingInfoProgramVer] = useState<TypeUtils.ChartData>({
        name: Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_NAME,
        data: [],
    });

    const getTabelTooltipVehicleDrivingInfo = (key: React.Key) => {
        switch (key) {
            case Common.SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_KEY:
                return (
                    <>
                        <p>
                            차량 주행정보
                            <br />
                            CAN ID: 0x18F40217
                            <br />
                            Name: 총 주행거리
                        </p>
                        <p>Remarks: km</p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_KEY:
                return (
                    <>
                        <p>
                            차량 주행정보
                            <br />
                            CAN ID: 0x18F40217
                            <br />
                            Name: 통신 프로토콜 버전
                        </p>
                    </>
                );
            case Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_KEY:
                return (
                    <>
                        <p>
                            차량 주행정보
                            <br />
                            CAN ID: 0x18F40217
                            <br />
                            Name: 계기판 프로그램 버전
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVehicleDrivingInfoTotalMileageValue = () => {
        if (selectedVehicleDrivingInfo.includes(Common.SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleDrivingInfo?.totalMileage, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const getVehicleDrivingInfoProtocolVerValue = () => {
        if (selectedVehicleDrivingInfo.includes(Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vehicleDrivingInfo?.protocolVer);
        }
        return "";
    };

    const getVehicleDrivingInfoProgramVerValue = () => {
        if (selectedVehicleDrivingInfo.includes(Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vehicleDrivingInfo?.programVer);
        }
        return "";
    };

    const onChangeVehicleDrivingInfo = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVehicleDrivingInfo(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVehicleDrivingInfo: Array<TypeUtils.ChartData> = [];

        if (selectedVehicleDrivingInfo.includes(Common.SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_KEY))
            chartDatasVehicleDrivingInfo.push(chartVehicleDrivingInfoTotalMileage);
        if (selectedVehicleDrivingInfo.includes(Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_KEY))
            chartDatasVehicleDrivingInfo.push(chartVehicleDrivingInfoProtocolVer);
        if (selectedVehicleDrivingInfo.includes(Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_KEY))
            chartDatasVehicleDrivingInfo.push(chartVehicleDrivingInfoProgramVer);

        setSeriesChartDatasVehicleDrivingInfo(chartDatasVehicleDrivingInfo);
    }, [selectedVehicleDrivingInfo, chartVehicleDrivingInfoTotalMileage, chartVehicleDrivingInfoProtocolVer, chartVehicleDrivingInfoProgramVer]);

    const tableDataVehicleDrivingInfo: CanTableType[] = [
        {
            key: Common.SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_KEY,
            name: Common.SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_NAME,
            value: getVehicleDrivingInfoTotalMileageValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_KEY,
            name: Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_NAME,
            value: getVehicleDrivingInfoProtocolVerValue(),
        },
        {
            key: Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_KEY,
            name: Common.SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_NAME,
            value: getVehicleDrivingInfoProgramVerValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion
    useEffect(() => {
        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Skywell) {
            //#region Datas
            const batteryInformationVoltageDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationCurrentDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationSocDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationLifeSignalDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Battery Fault Alarm Info
            const batFaultAlarmInfoHighTempAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoCellOverVoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoModuleOverVoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoModuleLowVoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoCellLowVoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoChargeGunHighTempAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoBatFaultStatusDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoDischargeCurrAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoChargeCurrAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoCellVotDiffAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoTempDiffAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoBmsFaultCodeDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoChargeStatusDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmInfoChargeNetworkStatusDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Battery Cell Max Voltage 1
            const batteryCellMaxVolt1CellMaxVolt1Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt1CellMaxVolt1BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt1CellMaxVolt1NoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt1CellMaxVolt2Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt1CellMaxVolt2BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt1CellMaxVolt2NoDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Battery Cell Max Voltage 2
            const batteryCellMaxVolt2CellMaxVolt3Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt2CellMaxVolt3BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt2CellMaxVolt3NoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt2CellMaxVolt4Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt2CellMaxVolt4BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMaxVolt2CellMaxVolt4NoDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Battery Cell Min Voltage 1
            const batteryCellMinVolt1CellMinVolt1Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt1CellMinVolt1BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt1CellMinVolt1NoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt1CellMinVolt2Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt1CellMinVolt2BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt1CellMinVolt2NoDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Battery Cell Min Voltage 2
            const batteryCellMinVolt2CellMinVolt3Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt2CellMinVolt3BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt2CellMinVolt3NoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt2CellMinVolt4Datas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt2CellMinVolt4BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryCellMinVolt2CellMinVolt4NoDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Battery Max Temperature
            const batteryMaxTemperaturePackMaxTemp1Datas: TypeUtils.ChartElement[] = [];
            const batteryMaxTemperaturePackMaxTemp1BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryMaxTemperaturePackMaxTemp2Datas: TypeUtils.ChartElement[] = [];
            const batteryMaxTemperaturePackMaxTemp2BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryMaxTemperaturePackMaxTemp3Datas: TypeUtils.ChartElement[] = [];
            const batteryMaxTemperaturePackMaxTemp3BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryMaxTemperaturePackMaxTemp4Datas: TypeUtils.ChartElement[] = [];
            const batteryMaxTemperaturePackMaxTemp4BoxNoDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Battery Min Temperature
            const batteryMinTemperaturePackMinTemp1Datas: TypeUtils.ChartElement[] = [];
            const batteryMinTemperaturePackMinTemp1BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryMinTemperaturePackMinTemp2Datas: TypeUtils.ChartElement[] = [];
            const batteryMinTemperaturePackMinTemp2BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryMinTemperaturePackMinTemp3Datas: TypeUtils.ChartElement[] = [];
            const batteryMinTemperaturePackMinTemp3BoxNoDatas: TypeUtils.ChartElement[] = [];
            const batteryMinTemperaturePackMinTemp4Datas: TypeUtils.ChartElement[] = [];
            const batteryMinTemperaturePackMinTemp4BoxNoDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Isolation Monitor
            const isolationMonitorAlarmStatusDatas: TypeUtils.ChartElement[] = [];
            const isolationMonitorStatusDatas: TypeUtils.ChartElement[] = [];
            const isolationMonitorResistanceDatas: TypeUtils.ChartElement[] = [];
            const isolationMonitorBatteryVoltageDatas: TypeUtils.ChartElement[] = [];
            const isolationMonitorLifeSignalDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region VCU Status
            const vcuStatusVehicleStatusDatas: TypeUtils.ChartElement[] = [];
            const vcuStatusVehicleSpeedDatas: TypeUtils.ChartElement[] = [];
            const vcuStatusGearStatusDatas: TypeUtils.ChartElement[] = [];
            const vcuStatusVcuFailDatas: TypeUtils.ChartElement[] = [];
            const vcuStatusLifeSignalDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region MCU Status 1
            const mcuStatus1MotorTempDatas: TypeUtils.ChartElement[] = [];
            const mcuStatus1MotorControllerTempDatas: TypeUtils.ChartElement[] = [];
            const mcuStatus1ConverterVoltDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region MCU Status 2
            const mcuStatus2MotorRpmDatas: TypeUtils.ChartElement[] = [];
            const mcuStatus2MotorTorqueDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Vehicle Status
            const vehicleStatusLowBatVoltDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusRightTurnStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusLeftTurnStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusPositionLightStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusStartSignalDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusKeyOnSignalDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusRearDoorStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusFrontDoorStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusMiddleDoorStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusMotorCoolingWaterAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusDoorPumpAirAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusDriverLeavingAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusStopStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusHandBrakeStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusFrontAirPressureAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusRearAirPressureAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusFrontBrakeAirPressureDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusRearBrakeAirPressureDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusHydrogenPortHatchStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusAccWakeupStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusSmartKeyStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusRearFogLightDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusFrontFogLightDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusLowBeamDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusHighBeamDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusBrakeLightDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusRightRearBrakeShoeAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusLeftRearBrakeShoeAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusRightFrontBrakeShoeAlarmDatas: TypeUtils.ChartElement[] = [];
            const vehicleStatusLeftFrontBrakeShoeAlarmDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Vehicle Driving Info
            const vehicleDrivingInfoTotalMileageDatas: TypeUtils.ChartElement[] = [];
            const vehicleDrivingInfoProtocolVerDatas: TypeUtils.ChartElement[] = [];
            const vehicleDrivingInfoProgramVerDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataSkywell.CanData;
                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region Battery Information
                batteryInformationVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.volt || 0,
                });

                batteryInformationCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.curr || 0,
                });

                batteryInformationSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.soc || 0,
                });

                batteryInformationLifeSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.lifeSignal || 0,
                });
                //#endregion

                //#region Battery Fault Alarm Info
                batFaultAlarmInfoHighTempAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.highTempAlarm || 0,
                });

                batFaultAlarmInfoCellOverVoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.cellOverVoltAlarm || 0,
                });

                batFaultAlarmInfoModuleOverVoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.moduleOverVoltAlarm || 0,
                });

                batFaultAlarmInfoModuleLowVoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.moduleLowVoltAlarm || 0,
                });
                batFaultAlarmInfoCellLowVoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.cellLowVoltAlarm || 0,
                });

                batFaultAlarmInfoChargeGunHighTempAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.chargeGunHighTempAlarm || 0,
                });

                batFaultAlarmInfoBatFaultStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.batFaultStatus || 0,
                });

                batFaultAlarmInfoDischargeCurrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.dischargeCurrAlarm || 0,
                });
                batFaultAlarmInfoChargeCurrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.chargeCurrAlarm || 0,
                });

                batFaultAlarmInfoCellVotDiffAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.cellVotDiffAlarm || 0,
                });

                batFaultAlarmInfoTempDiffAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.tempDiffAlarm || 0,
                });

                batFaultAlarmInfoBmsFaultCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.bmsFaultCode || 0,
                });
                batFaultAlarmInfoChargeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.chargeStatus || 0,
                });

                batFaultAlarmInfoChargeNetworkStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarmInfo?.chargeNetworkStatus || 0,
                });
                //#endregion

                //#region Battery Cell Max Voltage 1
                batteryCellMaxVolt1CellMaxVolt1Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt1?.cellMaxVolt1 || 0,
                });
                batteryCellMaxVolt1CellMaxVolt1BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt1?.cellMaxVolt1BoxNo || 0,
                });
                batteryCellMaxVolt1CellMaxVolt1NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt1?.cellMaxVolt1No || 0,
                });
                batteryCellMaxVolt1CellMaxVolt2Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt1?.cellMaxVolt2 || 0,
                });
                batteryCellMaxVolt1CellMaxVolt2BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt1?.cellMaxVolt2BoxNo || 0,
                });
                batteryCellMaxVolt1CellMaxVolt2NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt1?.cellMaxVolt2No || 0,
                });
                //#endregion

                //#region Battery Cell Max Voltage 2
                batteryCellMaxVolt2CellMaxVolt3Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt2?.cellMaxVolt3 || 0,
                });
                batteryCellMaxVolt2CellMaxVolt3BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt2?.cellMaxVolt3BoxNo || 0,
                });
                batteryCellMaxVolt2CellMaxVolt3NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt2?.cellMaxVolt3No || 0,
                });
                batteryCellMaxVolt2CellMaxVolt4Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt2?.cellMaxVolt4 || 0,
                });
                batteryCellMaxVolt2CellMaxVolt4BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt2?.cellMaxVolt4BoxNo || 0,
                });
                batteryCellMaxVolt2CellMaxVolt4NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMaxVolt2?.cellMaxVolt4No || 0,
                });
                //#endregion

                //#region Battery Cell Min Voltage 1
                batteryCellMinVolt1CellMinVolt1Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt1?.cellMinVolt1 || 0,
                });
                batteryCellMinVolt1CellMinVolt1BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt1?.cellMinVolt1BoxNo || 0,
                });
                batteryCellMinVolt1CellMinVolt1NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt1?.cellMinVolt1No || 0,
                });
                batteryCellMinVolt1CellMinVolt2Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt1?.cellMinVolt2 || 0,
                });
                batteryCellMinVolt1CellMinVolt2BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt1?.cellMinVolt2BoxNo || 0,
                });
                batteryCellMinVolt1CellMinVolt2NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt1?.cellMinVolt2No || 0,
                });
                //#endregion

                //#region Battery Cell Min Voltage 2
                batteryCellMinVolt2CellMinVolt3Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt2?.cellMinVolt3 || 0,
                });
                batteryCellMinVolt2CellMinVolt3BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt2?.cellMinVolt3BoxNo || 0,
                });
                batteryCellMinVolt2CellMinVolt3NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt2?.cellMinVolt3No || 0,
                });
                batteryCellMinVolt2CellMinVolt4Datas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt2?.cellMinVolt4 || 0,
                });
                batteryCellMinVolt2CellMinVolt4BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt2?.cellMinVolt4BoxNo || 0,
                });
                batteryCellMinVolt2CellMinVolt4NoDatas.push({
                    x: time.valueOf(),
                    y: canData.batCellMinVolt2?.cellMinVolt4No || 0,
                });
                //#endregion

                //#region Battery Max Temperature
                batteryMaxTemperaturePackMaxTemp1Datas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp1 || 0,
                });
                batteryMaxTemperaturePackMaxTemp1BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp1BoxNo || 0,
                });
                batteryMaxTemperaturePackMaxTemp2Datas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp2 || 0,
                });
                batteryMaxTemperaturePackMaxTemp2BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp2BoxNo || 0,
                });
                batteryMaxTemperaturePackMaxTemp3Datas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp3 || 0,
                });
                batteryMaxTemperaturePackMaxTemp3BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp3BoxNo || 0,
                });
                batteryMaxTemperaturePackMaxTemp4Datas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp4 || 0,
                });
                batteryMaxTemperaturePackMaxTemp4BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMaxTemp?.batPackMaxTemp4BoxNo || 0,
                });
                //#endregion

                //#region Battery Min Temperature
                batteryMinTemperaturePackMinTemp1Datas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp1 || 0,
                });
                batteryMinTemperaturePackMinTemp1BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp1BoxNo || 0,
                });
                batteryMinTemperaturePackMinTemp2Datas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp2 || 0,
                });
                batteryMinTemperaturePackMinTemp2BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp2BoxNo || 0,
                });
                batteryMinTemperaturePackMinTemp3Datas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp3 || 0,
                });
                batteryMinTemperaturePackMinTemp3BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp3BoxNo || 0,
                });
                batteryMinTemperaturePackMinTemp4Datas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp4 || 0,
                });
                batteryMinTemperaturePackMinTemp4BoxNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batMinTemp?.batPackMinTemp4BoxNo || 0,
                });
                //#endregion

                //#region Isolation Monitor
                isolationMonitorAlarmStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationMonitor?.alarmStatus || 0,
                });
                isolationMonitorStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationMonitor?.status || 0,
                });
                isolationMonitorResistanceDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationMonitor?.resistance || 0,
                });
                isolationMonitorBatteryVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationMonitor?.batVolt || 0,
                });
                isolationMonitorLifeSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationMonitor?.lifeSignal || 0,
                });
                //#endregion

                //#region VCU Status
                vcuStatusVehicleStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuStatus?.vehicleStatus || 0,
                });
                vcuStatusVehicleSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuStatus?.vehicleSpeed || 0,
                });
                vcuStatusGearStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuStatus?.gearStatus || 0,
                });
                vcuStatusVcuFailDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuStatus?.vcuFail || 0,
                });
                vcuStatusLifeSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuStatus?.lifeSignal || 0,
                });
                //#endregion

                //#region MCU Status 1
                mcuStatus1MotorTempDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus1?.motorTemp || 0,
                });
                mcuStatus1MotorControllerTempDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus1?.motorControllerTemp || 0,
                });
                mcuStatus1ConverterVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus1?.converterVolt || 0,
                });
                //#endregion

                //#region MCU Status 2
                mcuStatus2MotorRpmDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus2?.motorRpm || 0,
                });
                mcuStatus2MotorTorqueDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus2?.motorTorque || 0,
                });
                //#endregion

                //#region Vehicle Status
                vehicleStatusLowBatVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.lowBatVolt || 0,
                });
                vehicleStatusRightTurnStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.rightTurnStatus || 0,
                });
                vehicleStatusLeftTurnStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.leftTurnStatus || 0,
                });
                vehicleStatusPositionLightStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.positionLightStatus || 0,
                });
                vehicleStatusStartSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.startSignal || 0,
                });
                vehicleStatusKeyOnSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.keyOnSignal || 0,
                });
                vehicleStatusRearDoorStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.rearDoorStatus || 0,
                });
                vehicleStatusFrontDoorStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.frontDoorStatus || 0,
                });
                vehicleStatusMiddleDoorStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.middleDoorStatus || 0,
                });
                vehicleStatusMotorCoolingWaterAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.motorCoolingWaterAlarm || 0,
                });
                vehicleStatusDoorPumpAirAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.doorPumpAirAlarm || 0,
                });
                vehicleStatusDriverLeavingAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.driverLeavingAlarm || 0,
                });
                vehicleStatusStopStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.stopStatus || 0,
                });
                vehicleStatusHandBrakeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.handBrakeStatus || 0,
                });
                vehicleStatusFrontAirPressureAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.frontAirPressureAlarm || 0,
                });
                vehicleStatusRearAirPressureAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.rearAirPressureAlarm || 0,
                });
                vehicleStatusFrontBrakeAirPressureDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.frontBrakeAirPressure || 0,
                });
                vehicleStatusRearBrakeAirPressureDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.rearBrakeAirPressure || 0,
                });
                vehicleStatusHydrogenPortHatchStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.hydrogenPortHatchStatus || 0,
                });
                vehicleStatusAccWakeupStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.accWakeupStatus || 0,
                });
                vehicleStatusSmartKeyStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.smartKeyStatus || 0,
                });
                vehicleStatusRearFogLightDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.rearFogLight || 0,
                });
                vehicleStatusFrontFogLightDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.frontFogLight || 0,
                });
                vehicleStatusLowBeamDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.lowBeam || 0,
                });
                vehicleStatusHighBeamDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.highBeam || 0,
                });
                vehicleStatusBrakeLightDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.brakeLight || 0,
                });
                vehicleStatusRightRearBrakeShoeAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.rightRearBrakeShoeAlarm || 0,
                });
                vehicleStatusLeftRearBrakeShoeAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.leftRearBrakeShoeAlarm || 0,
                });
                vehicleStatusRightFrontBrakeShoeAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.rightFrontBrakeShoeAlarm || 0,
                });
                vehicleStatusLeftFrontBrakeShoeAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleStatus?.leftFrontBrakeShoeAlarm || 0,
                });
                //#endregion

                //#region Vehicle Driving Info
                vehicleDrivingInfoTotalMileageDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleDrivingInfo?.totalMileage || 0,
                });
                vehicleDrivingInfoProtocolVerDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleDrivingInfo?.protocolVer || 0,
                });
                vehicleDrivingInfoProgramVerDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleDrivingInfo?.programVer || 0,
                });
                //#endregion
            });

            //#region Battery Information
            setChartBatteryInformationVoltage({
                ...chartBatteryInformationVoltage,
                data: batteryInformationVoltageDatas,
            });

            setChartBatteryInformationCurrent({
                ...chartBatteryInformationCurrent,
                data: batteryInformationCurrentDatas,
            });

            setChartBatteryInformationSoc({
                ...chartBatteryInformationSoc,
                data: batteryInformationSocDatas,
            });

            setChartBatteryInformationLifeSignal({
                ...chartBatteryInformationLifeSignal,
                data: batteryInformationLifeSignalDatas,
            });
            //#endregion

            //#region Battery Fault Alarm Info
            setChartBatFaultAlarmInfoHighTempAlarm({
                ...chartBatFaultAlarmInfoHighTempAlarm,
                data: batFaultAlarmInfoHighTempAlarmDatas,
            });

            setChartBatFaultAlarmInfoCellOverVoltAlarm({
                ...chartBatFaultAlarmInfoCellOverVoltAlarm,
                data: batFaultAlarmInfoCellOverVoltAlarmDatas,
            });

            setChartBatFaultAlarmInfoModuleOverVoltAlarm({
                ...chartBatFaultAlarmInfoModuleOverVoltAlarm,
                data: batFaultAlarmInfoModuleOverVoltAlarmDatas,
            });

            setChartBatFaultAlarmInfoModuleLowVoltAlarm({
                ...chartBatFaultAlarmInfoModuleLowVoltAlarm,
                data: batFaultAlarmInfoModuleLowVoltAlarmDatas,
            });

            setChartBatFaultAlarmInfoCellLowVoltAlarm({
                ...chartBatFaultAlarmInfoCellLowVoltAlarm,
                data: batFaultAlarmInfoCellLowVoltAlarmDatas,
            });

            setChartBatFaultAlarmInfoChargeGunHighTempAlarm({
                ...chartBatFaultAlarmInfoChargeGunHighTempAlarm,
                data: batFaultAlarmInfoChargeGunHighTempAlarmDatas,
            });

            setChartBatFaultAlarmInfoBatFaultStatus({
                ...chartBatFaultAlarmInfoBatFaultStatus,
                data: batFaultAlarmInfoBatFaultStatusDatas,
            });

            setChartBatFaultAlarmInfoDischargeCurrAlarm({
                ...chartBatFaultAlarmInfoDischargeCurrAlarm,
                data: batFaultAlarmInfoDischargeCurrAlarmDatas,
            });

            setChartBatFaultAlarmInfoChargeCurrAlarm({
                ...chartBatFaultAlarmInfoChargeCurrAlarm,
                data: batFaultAlarmInfoChargeCurrAlarmDatas,
            });

            setChartBatFaultAlarmInfoCellVotDiffAlarm({
                ...chartBatFaultAlarmInfoCellVotDiffAlarm,
                data: batFaultAlarmInfoCellVotDiffAlarmDatas,
            });

            setChartBatFaultAlarmInfoTempDiffAlarm({
                ...chartBatFaultAlarmInfoTempDiffAlarm,
                data: batFaultAlarmInfoTempDiffAlarmDatas,
            });

            setChartBatFaultAlarmInfoBmsFaultCode({
                ...chartBatFaultAlarmInfoBmsFaultCode,
                data: batFaultAlarmInfoBmsFaultCodeDatas,
            });

            setChartBatFaultAlarmInfoChargeStatus({
                ...chartBatFaultAlarmInfoChargeStatus,
                data: batFaultAlarmInfoChargeStatusDatas,
            });

            setChartBatFaultAlarmInfoChargeNetworkStatus({
                ...chartBatFaultAlarmInfoChargeNetworkStatus,
                data: batFaultAlarmInfoChargeNetworkStatusDatas,
            });
            //#endregion

            //#region Battery Cell Max Voltage 1
            setChartBatteryCellMaxVolt1CellMaxVolt1({
                ...chartBatteryCellMaxVolt1CellMaxVolt1,
                data: batteryCellMaxVolt1CellMaxVolt1Datas,
            });
            setChartBatteryCellMaxVolt1CellMaxVolt1BoxNo({
                ...chartBatteryCellMaxVolt1CellMaxVolt1BoxNo,
                data: batteryCellMaxVolt1CellMaxVolt1BoxNoDatas,
            });
            setChartBatteryCellMaxVolt1CellMaxVolt1No({
                ...chartBatteryCellMaxVolt1CellMaxVolt1No,
                data: batteryCellMaxVolt1CellMaxVolt1NoDatas,
            });
            setChartBatteryCellMaxVolt1CellMaxVolt2({
                ...chartBatteryCellMaxVolt1CellMaxVolt2,
                data: batteryCellMaxVolt1CellMaxVolt2Datas,
            });
            setChartBatteryCellMaxVolt1CellMaxVolt2BoxNo({
                ...chartBatteryCellMaxVolt1CellMaxVolt2BoxNo,
                data: batteryCellMaxVolt1CellMaxVolt2BoxNoDatas,
            });
            setChartBatteryCellMaxVolt1CellMaxVolt2No({
                ...chartBatteryCellMaxVolt1CellMaxVolt2No,
                data: batteryCellMaxVolt1CellMaxVolt2NoDatas,
            });
            //#endregion

            //#region Battery Cell Max Voltage 2
            setChartBatteryCellMaxVolt2CellMaxVolt3({
                ...chartBatteryCellMaxVolt2CellMaxVolt3,
                data: batteryCellMaxVolt2CellMaxVolt3Datas,
            });
            setChartBatteryCellMaxVolt2CellMaxVolt3BoxNo({
                ...chartBatteryCellMaxVolt2CellMaxVolt3BoxNo,
                data: batteryCellMaxVolt2CellMaxVolt3BoxNoDatas,
            });
            setChartBatteryCellMaxVolt2CellMaxVolt3No({
                ...chartBatteryCellMaxVolt2CellMaxVolt3No,
                data: batteryCellMaxVolt2CellMaxVolt3NoDatas,
            });
            setChartBatteryCellMaxVolt2CellMaxVolt4({
                ...chartBatteryCellMaxVolt2CellMaxVolt4,
                data: batteryCellMaxVolt2CellMaxVolt4Datas,
            });
            setChartBatteryCellMaxVolt2CellMaxVolt4BoxNo({
                ...chartBatteryCellMaxVolt2CellMaxVolt4BoxNo,
                data: batteryCellMaxVolt2CellMaxVolt4BoxNoDatas,
            });
            setChartBatteryCellMaxVolt2CellMaxVolt4No({
                ...chartBatteryCellMaxVolt2CellMaxVolt4No,
                data: batteryCellMaxVolt2CellMaxVolt4NoDatas,
            });
            //#endregion

            //#region Battery Cell Min Voltage 1
            setChartBatteryCellMinVolt1CellMinVolt1({
                ...chartBatteryCellMinVolt1CellMinVolt1,
                data: batteryCellMinVolt1CellMinVolt1Datas,
            });
            setChartBatteryCellMinVolt1CellMinVolt1BoxNo({
                ...chartBatteryCellMinVolt1CellMinVolt1BoxNo,
                data: batteryCellMinVolt1CellMinVolt1BoxNoDatas,
            });
            setChartBatteryCellMinVolt1CellMinVolt1No({
                ...chartBatteryCellMinVolt1CellMinVolt1No,
                data: batteryCellMinVolt1CellMinVolt1NoDatas,
            });
            setChartBatteryCellMinVolt1CellMinVolt2({
                ...chartBatteryCellMinVolt1CellMinVolt2,
                data: batteryCellMinVolt1CellMinVolt2Datas,
            });
            setChartBatteryCellMinVolt1CellMinVolt2BoxNo({
                ...chartBatteryCellMinVolt1CellMinVolt2BoxNo,
                data: batteryCellMinVolt1CellMinVolt2BoxNoDatas,
            });
            setChartBatteryCellMinVolt1CellMinVolt2No({
                ...chartBatteryCellMinVolt1CellMinVolt2No,
                data: batteryCellMinVolt1CellMinVolt2NoDatas,
            });
            //#endregion

            //#region Battery Cell Min Voltage 2
            setChartBatteryCellMinVolt2CellMinVolt3({
                ...chartBatteryCellMinVolt2CellMinVolt3,
                data: batteryCellMinVolt2CellMinVolt3Datas,
            });
            setChartBatteryCellMinVolt2CellMinVolt3BoxNo({
                ...chartBatteryCellMinVolt2CellMinVolt3BoxNo,
                data: batteryCellMinVolt2CellMinVolt3BoxNoDatas,
            });
            setChartBatteryCellMinVolt2CellMinVolt3No({
                ...chartBatteryCellMinVolt2CellMinVolt3No,
                data: batteryCellMinVolt2CellMinVolt3NoDatas,
            });
            setChartBatteryCellMinVolt2CellMinVolt4({
                ...chartBatteryCellMinVolt2CellMinVolt4,
                data: batteryCellMinVolt2CellMinVolt4Datas,
            });
            setChartBatteryCellMinVolt2CellMinVolt4BoxNo({
                ...chartBatteryCellMinVolt2CellMinVolt4BoxNo,
                data: batteryCellMinVolt2CellMinVolt4BoxNoDatas,
            });
            setChartBatteryCellMinVolt2CellMinVolt4No({
                ...chartBatteryCellMinVolt2CellMinVolt4No,
                data: batteryCellMinVolt2CellMinVolt4NoDatas,
            });
            //#endregion

            //#region Battery Max Temperature
            setChartBatteryMaxTemperaturePackMaxTemp1({
                ...chartBatteryMaxTemperaturePackMaxTemp1,
                data: batteryMaxTemperaturePackMaxTemp1Datas,
            });
            setChartBatteryMaxTemperaturePackMaxTemp1BoxNo({
                ...chartBatteryMaxTemperaturePackMaxTemp1BoxNo,
                data: batteryMaxTemperaturePackMaxTemp1BoxNoDatas,
            });
            setChartBatteryMaxTemperaturePackMaxTemp2({
                ...chartBatteryMaxTemperaturePackMaxTemp2,
                data: batteryMaxTemperaturePackMaxTemp2Datas,
            });
            setChartBatteryMaxTemperaturePackMaxTemp2BoxNo({
                ...chartBatteryMaxTemperaturePackMaxTemp2BoxNo,
                data: batteryMaxTemperaturePackMaxTemp2BoxNoDatas,
            });
            setChartBatteryMaxTemperaturePackMaxTemp3({
                ...chartBatteryMaxTemperaturePackMaxTemp3,
                data: batteryMaxTemperaturePackMaxTemp3Datas,
            });
            setChartBatteryMaxTemperaturePackMaxTemp3BoxNo({
                ...chartBatteryMaxTemperaturePackMaxTemp3BoxNo,
                data: batteryMaxTemperaturePackMaxTemp3BoxNoDatas,
            });
            setChartBatteryMaxTemperaturePackMaxTemp4({
                ...chartBatteryMaxTemperaturePackMaxTemp4,
                data: batteryMaxTemperaturePackMaxTemp4Datas,
            });
            setChartBatteryMaxTemperaturePackMaxTemp4BoxNo({
                ...chartBatteryMaxTemperaturePackMaxTemp4BoxNo,
                data: batteryMaxTemperaturePackMaxTemp4BoxNoDatas,
            });
            //#endregion

            //#region Battery Min Temperature
            setChartBatteryMinTemperaturePackMinTemp1({
                ...chartBatteryMinTemperaturePackMinTemp1,
                data: batteryMinTemperaturePackMinTemp1Datas,
            });
            setChartBatteryMinTemperaturePackMinTemp1BoxNo({
                ...chartBatteryMinTemperaturePackMinTemp1BoxNo,
                data: batteryMinTemperaturePackMinTemp1BoxNoDatas,
            });
            setChartBatteryMinTemperaturePackMinTemp2({
                ...chartBatteryMinTemperaturePackMinTemp2,
                data: batteryMinTemperaturePackMinTemp2Datas,
            });
            setChartBatteryMinTemperaturePackMinTemp2BoxNo({
                ...chartBatteryMinTemperaturePackMinTemp2BoxNo,
                data: batteryMinTemperaturePackMinTemp2BoxNoDatas,
            });
            setChartBatteryMinTemperaturePackMinTemp3({
                ...chartBatteryMinTemperaturePackMinTemp3,
                data: batteryMinTemperaturePackMinTemp3Datas,
            });
            setChartBatteryMinTemperaturePackMinTemp3BoxNo({
                ...chartBatteryMinTemperaturePackMinTemp3BoxNo,
                data: batteryMinTemperaturePackMinTemp3BoxNoDatas,
            });
            setChartBatteryMinTemperaturePackMinTemp4({
                ...chartBatteryMinTemperaturePackMinTemp4,
                data: batteryMinTemperaturePackMinTemp4Datas,
            });
            setChartBatteryMinTemperaturePackMinTemp4BoxNo({
                ...chartBatteryMinTemperaturePackMinTemp4BoxNo,
                data: batteryMinTemperaturePackMinTemp4BoxNoDatas,
            });
            //#endregion

            //#region Isolation Monitor
            setChartIsolationMonitorAlarmStatus({
                ...chartIsolationMonitorAlarmStatus,
                data: isolationMonitorAlarmStatusDatas,
            });
            setChartIsolationMonitorStatus({
                ...chartIsolationMonitorStatus,
                data: isolationMonitorStatusDatas,
            });
            setChartIsolationMonitorResistance({
                ...chartIsolationMonitorResistance,
                data: isolationMonitorResistanceDatas,
            });
            setChartIsolationMonitorBatteryVoltage({
                ...chartIsolationMonitorBatteryVoltage,
                data: isolationMonitorBatteryVoltageDatas,
            });
            setChartIsolationMonitorLifeSignal({
                ...chartIsolationMonitorLifeSignal,
                data: isolationMonitorLifeSignalDatas,
            });
            //#endregion

            //#region VCU Status
            setChartVcuStatusVehicleStatus({
                ...chartVcuStatusVehicleStatus,
                data: vcuStatusVehicleStatusDatas,
            });
            setChartVcuStatusVehicleSpeed({
                ...chartVcuStatusVehicleSpeed,
                data: vcuStatusVehicleSpeedDatas,
            });
            setChartVcuStatusGearStatus({
                ...chartVcuStatusGearStatus,
                data: vcuStatusGearStatusDatas,
            });
            setChartVcuStatusVcuFail({
                ...chartVcuStatusVcuFail,
                data: vcuStatusVcuFailDatas,
            });
            setChartVcuStatusLifeSignal({
                ...chartVcuStatusLifeSignal,
                data: vcuStatusLifeSignalDatas,
            });
            //#endregion

            //#region MCU Status 1
            setChartMcuStatus1MotorTemp({
                ...chartMcuStatus1MotorTemp,
                data: mcuStatus1MotorTempDatas,
            });
            setChartMcuStatus1MotorControllerTemp({
                ...chartMcuStatus1MotorControllerTemp,
                data: mcuStatus1MotorControllerTempDatas,
            });
            setChartMcuStatus1ConverterVolt({
                ...chartMcuStatus1ConverterVolt,
                data: mcuStatus1ConverterVoltDatas,
            });
            //#endregion

            //#region MCU Status 2
            setChartMcuStatus2MotorRpm({
                ...chartMcuStatus2MotorRpm,
                data: mcuStatus2MotorRpmDatas,
            });
            setChartMcuStatus2MotorTorque({
                ...chartMcuStatus2MotorTorque,
                data: mcuStatus2MotorTorqueDatas,
            });
            //#endregion

            //#region Vehicle Status
            setChartVehicleStatusLowBatVolt({
                ...chartVehicleStatusLowBatVolt,
                data: vehicleStatusLowBatVoltDatas,
            });
            setChartVehicleStatusRightTurnStatus({
                ...chartVehicleStatusRightTurnStatus,
                data: vehicleStatusRightTurnStatusDatas,
            });
            setChartVehicleStatusLeftTurnStatus({
                ...chartVehicleStatusLeftTurnStatus,
                data: vehicleStatusLeftTurnStatusDatas,
            });
            setChartVehicleStatusPositionLightStatus({
                ...chartVehicleStatusPositionLightStatus,
                data: vehicleStatusPositionLightStatusDatas,
            });
            setChartVehicleStatusStartSignal({
                ...chartVehicleStatusStartSignal,
                data: vehicleStatusStartSignalDatas,
            });
            setChartVehicleStatusKeyOnSignal({
                ...chartVehicleStatusKeyOnSignal,
                data: vehicleStatusKeyOnSignalDatas,
            });
            setChartVehicleStatusRearDoorStatus({
                ...chartVehicleStatusRearDoorStatus,
                data: vehicleStatusRearDoorStatusDatas,
            });
            setChartVehicleStatusFrontDoorStatus({
                ...chartVehicleStatusFrontDoorStatus,
                data: vehicleStatusFrontDoorStatusDatas,
            });
            setChartVehicleStatusMiddleDoorStatus({
                ...chartVehicleStatusMiddleDoorStatus,
                data: vehicleStatusMiddleDoorStatusDatas,
            });
            setChartVehicleStatusMotorCoolingWaterAlarm({
                ...chartVehicleStatusMotorCoolingWaterAlarm,
                data: vehicleStatusMotorCoolingWaterAlarmDatas,
            });
            setChartVehicleStatusDoorPumpAirAlarm({
                ...chartVehicleStatusDoorPumpAirAlarm,
                data: vehicleStatusDoorPumpAirAlarmDatas,
            });
            setChartVehicleStatusDriverLeavingAlarm({
                ...chartVehicleStatusDriverLeavingAlarm,
                data: vehicleStatusDriverLeavingAlarmDatas,
            });
            setChartVehicleStatusStopStatus({
                ...chartVehicleStatusStopStatus,
                data: vehicleStatusStopStatusDatas,
            });
            setChartVehicleStatusHandBrakeStatus({
                ...chartVehicleStatusHandBrakeStatus,
                data: vehicleStatusHandBrakeStatusDatas,
            });
            setChartVehicleStatusFrontAirPressureAlarm({
                ...chartVehicleStatusFrontAirPressureAlarm,
                data: vehicleStatusFrontAirPressureAlarmDatas,
            });
            setChartVehicleStatusRearAirPressureAlarm({
                ...chartVehicleStatusRearAirPressureAlarm,
                data: vehicleStatusRearAirPressureAlarmDatas,
            });
            setChartVehicleStatusFrontBrakeAirPressure({
                ...chartVehicleStatusFrontBrakeAirPressure,
                data: vehicleStatusFrontBrakeAirPressureDatas,
            });
            setChartVehicleStatusRearBrakeAirPressure({
                ...chartVehicleStatusRearBrakeAirPressure,
                data: vehicleStatusRearBrakeAirPressureDatas,
            });
            setChartVehicleStatusHydrogenPortHatchStatus({
                ...chartVehicleStatusHydrogenPortHatchStatus,
                data: vehicleStatusHydrogenPortHatchStatusDatas,
            });
            setChartVehicleStatusAccWakeupStatus({
                ...chartVehicleStatusAccWakeupStatus,
                data: vehicleStatusAccWakeupStatusDatas,
            });
            setChartVehicleStatusSmartKeyStatus({
                ...chartVehicleStatusSmartKeyStatus,
                data: vehicleStatusSmartKeyStatusDatas,
            });
            setChartVehicleStatusRearFogLight({
                ...chartVehicleStatusRearFogLight,
                data: vehicleStatusRearFogLightDatas,
            });
            setChartVehicleStatusFrontFogLight({
                ...chartVehicleStatusFrontFogLight,
                data: vehicleStatusFrontFogLightDatas,
            });
            setChartVehicleStatusLowBeam({
                ...chartVehicleStatusLowBeam,
                data: vehicleStatusLowBeamDatas,
            });
            setChartVehicleStatusHighBeam({
                ...chartVehicleStatusHighBeam,
                data: vehicleStatusHighBeamDatas,
            });
            setChartVehicleStatusBrakeLight({
                ...chartVehicleStatusBrakeLight,
                data: vehicleStatusBrakeLightDatas,
            });
            setChartVehicleStatusRightRearBrakeShoeAlarm({
                ...chartVehicleStatusRightRearBrakeShoeAlarm,
                data: vehicleStatusRightRearBrakeShoeAlarmDatas,
            });
            setChartVehicleStatusLeftRearBrakeShoeAlarm({
                ...chartVehicleStatusLeftRearBrakeShoeAlarm,
                data: vehicleStatusLeftRearBrakeShoeAlarmDatas,
            });
            setChartVehicleStatusRightFrontBrakeShoeAlarm({
                ...chartVehicleStatusRightFrontBrakeShoeAlarm,
                data: vehicleStatusRightFrontBrakeShoeAlarmDatas,
            });
            setChartVehicleStatusLeftFrontBrakeShoeAlarm({
                ...chartVehicleStatusLeftFrontBrakeShoeAlarm,
                data: vehicleStatusLeftFrontBrakeShoeAlarmDatas,
            });
            //#endregion

            //#region Vehicle Driving Info
            setChartVehicleDrivingInfoTotalMileage({
                ...chartVehicleDrivingInfoTotalMileage,
                data: vehicleDrivingInfoTotalMileageDatas,
            });
            setChartVehicleDrivingInfoProtocolVer({
                ...chartVehicleDrivingInfoProtocolVer,
                data: vehicleDrivingInfoProtocolVerDatas,
            });
            setChartVehicleDrivingInfoProgramVer({
                ...chartVehicleDrivingInfoProgramVer,
                data: vehicleDrivingInfoProgramVerDatas,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Skywell) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataSkywell.CanData;

            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (
            batteryInformationChartMounted === true ||
            batFaultAlarmInfoChartMounted === true ||
            batteryCellMaxVolt1ChartMounted === true ||
            batteryCellMaxVolt2ChartMounted === true ||
            batteryCellMinVolt1ChartMounted === true ||
            batteryCellMinVolt2ChartMounted === true ||
            batteryMaxTemperatureChartMounted === true ||
            batteryMinTemperatureChartMounted === true ||
            isolationMonitorChartMounted === true ||
            vcuStatusChartMounted === true ||
            mcuStatus1ChartMounted === true ||
            mcuStatus2ChartMounted === true ||
            vehicleStatusChartMounted === true ||
            vehicleDrivingInfoChartMounted === true
        ) {
            if (onMountedChart !== undefined) onMountedChart();
        }
    }, [
        batteryInformationChartMounted,
        batFaultAlarmInfoChartMounted,
        batteryCellMaxVolt1ChartMounted,
        batteryCellMaxVolt2ChartMounted,
        batteryCellMinVolt1ChartMounted,
        batteryCellMinVolt2ChartMounted,
        batteryMaxTemperatureChartMounted,
        batteryMinTemperatureChartMounted,
        isolationMonitorChartMounted,
        vcuStatusChartMounted,
        mcuStatus1ChartMounted,
        mcuStatus2ChartMounted,
        vehicleStatusChartMounted,
        vehicleDrivingInfoChartMounted,
    ]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.SKYWELL_BATTERY_INFO_VOLTAGE_NAME:
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_1_NAME:
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_2_NAME:
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_3_NAME:
            case Common.SKYWELL_BATTERY_CELL_MAX_VOLT_4_NAME:
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_1_NAME:
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_2_NAME:
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_3_NAME:
            case Common.SKYWELL_BATTERY_CELL_MIN_VOLT_4_NAME:
            case Common.SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_NAME:
            case Common.SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }

            case Common.SKYWELL_BATTERY_INFO_CURRENT_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_NAME:
            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_NAME: {
                convertTooltipString = convertBatFaultAlarmRemark(value);
                break;
            }

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_NAME: {
                convertTooltipString = convertChargeStatusRemark(value);
                break;
            }

            case Common.SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_NAME: {
                convertTooltipString = convertChargeNetworkStatusRemark(value);
                break;
            }

            case Common.SKYWELL_BATTERY_INFO_SOC_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PERCENT);
                break;
            }

            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_1_NAME:
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_2_NAME:
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_3_NAME:
            case Common.SKYWELL_BATTERY_PACK_MAX_TEMP_4_NAME:
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_1_NAME:
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_2_NAME:
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_3_NAME:
            case Common.SKYWELL_BATTERY_PACK_MIN_TEMP_4_NAME:
            case Common.SKYWELL_MCU_STATUS_1_MOTOR_TEMP_NAME:
            case Common.SKYWELL_MCU_STATUS_1_MCU_TEMP_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CELSIUS);
                break;
            }

            case Common.SKYWELL_ISOLATION_MONITOR_RESISTANCE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_K_OHM);
                break;
            }

            case Common.SKYWELL_VCU_STATUS_VEHICLE_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_SPEED);
                break;
            }

            case Common.SKYWELL_MCU_STATUS_2_MOTOR_RPM_NAME:
            case Common.SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_MOTOR_SPEED);
                break;
            }

            case Common.SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_K_PASCAL);
                break;
            }

            case Common.SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_DISTANCE);
                break;
            }

            case Common.SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_NAME: {
                convertTooltipString = convertIsolationMonitorAlarmStatusRemark(value);
                break;
            }

            case Common.SKYWELL_ISOLATION_MONITOR_STATUS_NAME: {
                convertTooltipString = convertIsolationMonitorStatusRemark(value);
                break;
            }

            case Common.SKYWELL_VCU_STATUS_VEHICLE_STATUS_NAME: {
                convertTooltipString = convertVcuStatusVehicleStatusRemark(value);
                break;
            }

            case Common.SKYWELL_VCU_STATUS_GEAR_STATUS_NAME: {
                convertTooltipString = convertVcuStatusGearStatusRemark(value);
                break;
            }

            case Common.SKYWELL_VCU_STATUS_VCU_FAIL_NAME: {
                convertTooltipString = convertVcuStatusVcuFailRemark(value);
                break;
            }

            case Common.SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_START_SIGNAL_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_LOW_BEAM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_HIGH_BEAM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_NAME: {
                convertTooltipString = convertVehicleStatusStatusRemark(value);
                break;
            }

            case Common.SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_NAME: {
                convertTooltipString = convertVehicleStatusDoorRemark(value);
                break;
            }

            case Common.SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_STOP_STATUS_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_NAME:
            case Common.SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_NAME: {
                convertTooltipString = convertVehicleStatusAlarmRemark(value);
                break;
            }

            default:
                break;
        }
        return convertTooltipString;
    };

    return (
        <div>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <div>
                    <CanDataWrapper
                        title="배터리 기본 정보"
                        id="batteryInformation"
                        panelOpen={batteryInformationPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryInformationPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryInformation}
                        tableSelectRowKeys={selectedBatteryInformation}
                        onChangeTableSelect={onChangeBatteryInformation}
                        tableGetTooltips={getTabelTooltipBatteryInformation}
                        seriesChartDatas={seriesChartDatasBatteryInformation}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryInformationChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 고장 경보"
                        id="batFaultAlarmInfo"
                        panelOpen={batFaultAlarmInfoPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatFaultAlarmInfoPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatFaultAlarmInfo}
                        tableSelectRowKeys={selectedBatFaultAlarmInfo}
                        onChangeTableSelect={onChangeBatFaultAlarmInfo}
                        tableGetTooltips={getTabelTooltipBatFaultAlarmInfo}
                        seriesChartDatas={seriesChartDatasBatFaultAlarmInfo}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatFaultAlarmInfoChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 셀 최고 전압 1"
                        id="batteryCellMaxVolt1"
                        panelOpen={batteryCellMaxVolt1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryCellMaxVolt1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryCellMaxVolt1}
                        tableSelectRowKeys={selectedBatteryCellMaxVolt1}
                        onChangeTableSelect={onChangeBatteryCellMaxVolt1}
                        tableGetTooltips={getTabelTooltipBatteryCellMaxVolt1}
                        seriesChartDatas={seriesChartDatasBatteryCellMaxVolt1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryCellMaxVolt1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 셀 최고 전압 2"
                        id="batteryCellMaxVolt2"
                        panelOpen={batteryCellMaxVolt2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryCellMaxVolt2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryCellMaxVolt2}
                        tableSelectRowKeys={selectedBatteryCellMaxVolt2}
                        onChangeTableSelect={onChangeBatteryCellMaxVolt2}
                        tableGetTooltips={getTabelTooltipBatteryCellMaxVolt2}
                        seriesChartDatas={seriesChartDatasBatteryCellMaxVolt2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryCellMaxVolt2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 셀 최저 전압 1"
                        id="batteryCellMinVolt1"
                        panelOpen={batteryCellMinVolt1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryCellMinVolt1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryCellMinVolt1}
                        tableSelectRowKeys={selectedBatteryCellMinVolt1}
                        onChangeTableSelect={onChangeBatteryCellMinVolt1}
                        tableGetTooltips={getTabelTooltipBatteryCellMinVolt1}
                        seriesChartDatas={seriesChartDatasBatteryCellMinVolt1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryCellMinVolt1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 셀 최저 전압 2"
                        id="batteryCellMinVolt2"
                        panelOpen={batteryCellMinVolt2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryCellMinVolt2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryCellMinVolt2}
                        tableSelectRowKeys={selectedBatteryCellMinVolt2}
                        onChangeTableSelect={onChangeBatteryCellMinVolt2}
                        tableGetTooltips={getTabelTooltipBatteryCellMinVolt2}
                        seriesChartDatas={seriesChartDatasBatteryCellMinVolt2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryCellMinVolt2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 최고 온도"
                        id="batteryMaxTemperature"
                        panelOpen={batteryMaxTemperaturePanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryMaxTemperaturePanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryMaxTemperature}
                        tableSelectRowKeys={selectedBatteryMaxTemperature}
                        onChangeTableSelect={onChangeBatteryMaxTemperature}
                        tableGetTooltips={getTabelTooltipBatteryMaxTemperature}
                        seriesChartDatas={seriesChartDatasBatteryMaxTemperature}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryMaxTemperatureChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 최저 온도"
                        id="batteryMinTemperature"
                        panelOpen={batteryMinTemperaturePanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryMinTemperaturePanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryMinTemperature}
                        tableSelectRowKeys={selectedBatteryMinTemperature}
                        onChangeTableSelect={onChangeBatteryMinTemperature}
                        tableGetTooltips={getTabelTooltipBatteryMinTemperature}
                        seriesChartDatas={seriesChartDatasBatteryMinTemperature}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryMinTemperatureChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="절연측정기"
                        id="isolationMonitor"
                        panelOpen={isolationMonitorPanelOpen}
                        onChangePanelOpen={(panelOpened) => setIsolationMonitorPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataIsolationMonitor}
                        tableSelectRowKeys={selectedIsolationMonitor}
                        onChangeTableSelect={onChangeIsolationMonitor}
                        tableGetTooltips={getTabelTooltipIsolationMonitor}
                        seriesChartDatas={seriesChartDatasIsolationMonitor}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setIsolationMonitorChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU 상태"
                        id="vcuStatus"
                        panelOpen={vcuStatusPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuStatusPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuStatus}
                        tableSelectRowKeys={selectedVcuStatus}
                        onChangeTableSelect={onChangeVcuStatus}
                        tableGetTooltips={getTabelTooltipVcuStatus}
                        seriesChartDatas={seriesChartDatasVcuStatus}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuStatusChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="MCU 상태 1"
                        id="mcuStatus1"
                        panelOpen={mcuStatus1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setMcuStatus1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataMcuStatus1}
                        tableSelectRowKeys={selectedMcuStatus1}
                        onChangeTableSelect={onChangeMcuStatus1}
                        tableGetTooltips={getTabelTooltipMcuStatus1}
                        seriesChartDatas={seriesChartDatasMcuStatus1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setMcuStatus1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="MCU 상태 2"
                        id="mcuStatus2"
                        panelOpen={mcuStatus2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setMcuStatus2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataMcuStatus2}
                        tableSelectRowKeys={selectedMcuStatus2}
                        onChangeTableSelect={onChangeMcuStatus2}
                        tableGetTooltips={getTabelTooltipMcuStatus2}
                        seriesChartDatas={seriesChartDatasMcuStatus2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setMcuStatus2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="차량 상태"
                        id="vehicleStatus"
                        panelOpen={vehicleStatusPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVehicleStatusPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVehicleStatus}
                        tableSelectRowKeys={selectedVehicleStatus}
                        onChangeTableSelect={onChangeVehicleStatus}
                        tableGetTooltips={getTabelTooltipVehicleStatus}
                        seriesChartDatas={seriesChartDatasVehicleStatus}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVehicleStatusChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="차량 주행 정보"
                        id="vehicleDrivingInfo"
                        panelOpen={vehicleDrivingInfoPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVehicleDrivingInfoPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVehicleDrivingInfo}
                        tableSelectRowKeys={selectedVehicleDrivingInfo}
                        onChangeTableSelect={onChangeVehicleDrivingInfo}
                        tableGetTooltips={getTabelTooltipVehicleDrivingInfo}
                        seriesChartDatas={seriesChartDatasVehicleDrivingInfo}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVehicleDrivingInfoChartMounted(true)}
                    />
                </div>
            </Space>
        </div>
    );
}

export default CanDataSkywellViewer;
