import * as React from "react";
import * as Utils from "../../utils/utils";

import Space from "antd/es/space/index";

import Maintenances from "./Maintenances";
import Socs from "./Socs";

const TabMaintenance = ({ carId, carNumber, makerName }: { carId?: number; carNumber?: string; makerName?: string }) => {
    return (
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
            <Maintenances carNumber={carNumber} />
            {Utils.isBukgiOrYaxing(makerName) && <Socs carId={carId} carNumber={carNumber} />}
        </Space>
    );
};

export default TabMaintenance;
