import { useCallback, useEffect, useState } from "react";
import { DatePicker } from "antd-mobile";
import { CalendarOutlined, CloseCircleFilled } from "@ant-design/icons";

import moment from "moment";

import * as Common from "../../commons/common";
import styles from "./CustomCard.module.css";
import { Space } from "antd";

export type CardValue = {
    icon: JSX.Element;
    value?: string;
};

function CustomCard({ title, contents, icon, onClick }: { title?: string; contents: Array<CardValue>; icon?: JSX.Element; onClick?: () => void }) {
    return (
        <div className={styles.cardWrapper} onClick={() => onClick && onClick()}>
            <div className={styles.titleWrapper}>
                <span className="fw-rg fs-md fc-font1">{title}</span>
                {icon && icon}
            </div>
            <div className={styles.contentWrapper}>
                {contents.map((content, index) => {
                    return (
                        <div className={styles.contentSpace} key={index}>
                            {content.icon}
                            <span className="fw-rg fs-sm fc-font2">{content.value}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default CustomCard;
