import React from "react";
import Maintenances from "../../components/mobile/Maintenances";

import { useParams } from "react-router";

const PageMaintenance = () => {
    const params = useParams();

    return <Maintenances carNumber={params.id} />;
};

export default PageMaintenance;
