import { useReducer, useEffect, useCallback } from "react";
import axios from "axios";
import * as Common from "../commons/common";
import * as Utils from "../utils/utils";
import { useAuthDispatch } from "../provider/AuthProvider";
import { useNavigate } from "react-router";
import { AlertType, useAlert } from "../provider/AlertProvider";

type AxiosData = {
    [key: string]: any;
};

type AxiosState = {
    loading: boolean;
    data: AxiosData | null;
    error: AxiosData | null;
};

const initialAxiosState: AxiosState = {
    loading: false,
    data: null,
    error: null,
};

type AxiosAction = { type: "LOADING" } | { type: "SUCCESS"; data: AxiosData } | { type: "ERROR"; error: AxiosData } | { type: "CLEAR" };

const ayncAxiosReducer = (state: AxiosState, action: AxiosAction) => {
    switch (action.type) {
        case "LOADING":
            return {
                loading: true,
                data: null,
                error: null,
            };
        case "SUCCESS":
            return {
                loading: false,
                data: action.data,
                error: null,
            };
        case "ERROR":
            return {
                loading: false,
                data: null,
                error: action.error,
            };
        case "CLEAR":
            return initialAxiosState;
        default:
            throw new Error("Unhandled action type");
    }
};

export const useAsyncAxios = (callback: (...args: any[]) => AxiosData, immediate = false, disableAlert = false) => {
    const alert = useAlert();
    const [state, dispatch] = useReducer(ayncAxiosReducer, initialAxiosState);
    const dispatchAuth = useAuthDispatch();
    const navigate = useNavigate();

    const execute = useCallback(
        async (...args: any[]) => {
            dispatch({ type: "LOADING" });
            try {
                const data = await callback(...args);
                if (data.result === "ok") {
                    dispatch({ type: "SUCCESS", data });
                    return true;
                } else {
                    //alert.error(data.message);
                    dispatch({ type: "ERROR", error: data });
                }
            } catch (e: any) {
                if (e.code === AlertType.ERR_NETWORK || e.code === AlertType.ECONNABORTED) {
                    alert.setAlert(e.code, "ERROR", e.code);
                } else {
                    if (disableAlert === false) alert.setAlert(e.response?.data?.code, "ERROR", e.response.data.message);

                    if (Utils.checkUnAuthorizedToken(e)) {
                        if (dispatchAuth !== null) dispatchAuth({ type: "LOGOUT" });
                        navigate(Common.PAGE_LOGIN, { replace: true });
                    }
                }
                dispatch({ type: "ERROR", error: e });
            }
        },
        [callback]
    );

    useEffect(() => {
        immediate && execute();
        return () => dispatch({ type: "CLEAR" });
    }, []);

    return { ...state, execute };
};

export const useAsyncFileAxios = (callback: (...args: any[]) => AxiosData, immediate = false) => {
    const alert = useAlert();
    const [state, dispatch] = useReducer(ayncAxiosReducer, initialAxiosState);

    const execute = useCallback(
        async (...args: any[]) => {
            dispatch({ type: "LOADING" });
            try {
                const data = await callback(...args);
                if (data !== null) {
                    dispatch({ type: "SUCCESS", data });
                    return true;
                } else {
                    //alert.error(data.message);
                    dispatch({ type: "ERROR", error: data });
                }
            } catch (e: any) {
                // TODO: alert error with e message
                alert.setAlert(e.response.data.code, "ERROR", e.response.data.message);
                dispatch({ type: "ERROR", error: e });
            }
        },
        [callback]
    );

    useEffect(() => {
        immediate && execute();
        return () => dispatch({ type: "CLEAR" });
    }, []);

    return { ...state, execute };
};

export const utilAxios = () => {
    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
        },
        withCredentials: true,
    });

    return instance;
};

export const utilAxiosWithAuth = () => {
    const token = JSON.parse(Utils.getLocalStorage(Common.CONTEXT_AUTH)?.toString() || "{}").token;
    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    });

    return instance;
};

export const utilAxiosFormWithAuth = () => {
    const token = JSON.parse(Utils.getLocalStorage(Common.CONTEXT_AUTH)?.toString() || "{}").token;
    const instance = axios.create({
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    });

    return instance;
};

export const utilAxiosFileWithAuth = () => {
    const token = JSON.parse(Utils.getLocalStorage(Common.CONTEXT_AUTH)?.toString() || "{}").token;
    const instance = axios.create({
        headers: {
            Accept: "application/octet-stream",
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
        responseType: "arraybuffer",
    });

    return instance;
};

export const utilAxiosExcelWithAuth = () => {
    const token = JSON.parse(Utils.getLocalStorage(Common.CONTEXT_AUTH)?.toString() || "{}").token;
    const instance = axios.create({
        headers: {
            Accept: "application/vnd.ms-excel",
            "Content-Type": "application/vnd.ms-excel",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
        responseType: "arraybuffer",
    });

    return instance;
};

export const utilAxiosWithAppToken = () => {
    const token =
        "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJlek1vYmlsaXR5IiwiYXV0aCI6IlJPTEVfQVBQTElDQVRJT04iLCJpYXQiOjE3MDg5MDkzNzMsImV4cCI6MTc5ODc2MTU5OX0.n4hikxMkApzMAV_YHSYheGRUjwq0BsSeqNgTPkFGF1C4E2Wk48cWLYXTJx5BMHK82p7qqqxb22G7Fxgwy_q0ZA";
    const instance = axios.create({
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
    });

    return instance;
};
