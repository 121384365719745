import { useEffect, useState, useRef } from "react";
import { ConfigProvider, CalendarPicker, CalendarPickerRef } from "antd-mobile";
import {
    CalendarOutlined,
    CloseCircleFilled,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    LeftOutlined,
    RightOutlined,
} from "@ant-design/icons";

import Space from "antd/es/space/index";
import produce from "immer";
import moment from "moment";
import koKR from "antd-mobile/es/locales/ko-KR";

import * as Common from "../../commons/common";
import styles from "./Selector.module.css";

function CustomRangePicker({
    placeholder,
    isWhite,
    isSingle,
    value,
    maxDate,
    todayMax,
    isToday,
    onSearch,
    onStartDate,
    onEndDate,
}: {
    placeholder?: boolean;
    isWhite?: boolean;
    isSingle?: boolean;
    value?: { startDate: string; endDate: string };
    maxDate?: number;
    todayMax?: boolean;
    isToday?: boolean;
    onSearch?: (value: any) => void;
    onStartDate?: (value: any) => void;
    onEndDate?: (value: any) => void;
}) {
    const ref = useRef<CalendarPickerRef>(null);
    const [year, setYear] = useState<number>(Number(moment().format("YYYY")));
    const [month, setMonth] = useState<number>(Number(moment().format("M")));
    const [open, setOpen] = useState(false);
    const [datas, setDatas] = useState<any>({});
    const [disabled, setDisbled] = useState<boolean>(false);
    let initData = undefined;

    useEffect(() => {
        if (isToday) {
            setDatas({
                startDate: moment().startOf("day").format(Common.FORMAT_DATE_TIME),
                endDate: moment().format(Common.FORMAT_DATE_TIME),
            });
        }
    }, [isToday]);

    useEffect(() => {
        if (!initData) {
            initData = value;
        }

        setYear(Number(moment(value?.startDate).format("YYYY")));
        setMonth(Number(moment(value?.startDate).format("M")));
    }, [value]);

    const onChangeEvent = (type: string, value?: any) => {
        setDatas(
            produce((draft: any) => {
                if (value === undefined || value === "") {
                    delete draft[type as keyof any];
                } else {
                    draft[type as keyof any] = value;
                }
            })
        );
    };

    useEffect(() => {
        if (!onSearch) return;

        if (isSingle && datas.date) {
            onSearch(datas.date);
        } else if (datas.startDate && datas.endDate) {
            onSearch(datas);
        } else {
            onSearch(undefined);
        }
    }, [datas]);

    useEffect(() => {
        if (month < 1) {
            setYear(year - 1);
            setMonth(12);
        }
        if (month > 12) {
            setYear(year + 1);
            setMonth(1);
        }
    }, [month]);

    return (
        <ConfigProvider locale={koKR}>
            <div>
                <div
                    className={`customRangePicker ${styles.selectorTrigger}`}
                    style={!isWhite ? { background: "var(--background)", boxShadow: "none" } : {}}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    {placeholder ? (
                        <span className={`fw-rg fs-md fc-font3`}>검색 기간을 입력해 주세요.</span>
                    ) : isSingle ? (
                        <span className={`fw-rg fs-md ${!datas?.date ? "fc-font3" : "fc-font1"}`}>
                            {!datas?.date ? "검색 기간을 입력해 주세요." : datas?.date.slice(0, 10)}
                        </span>
                    ) : (
                        <span className={`fw-rg fs-md fc-font1`}>
                            {datas?.startDate ? datas?.startDate?.slice(0, 10) : value?.startDate?.slice(0, 10)}
                            {datas?.endDate ? ` ~ ${datas?.endDate?.slice(0, 10)}` : ` ~ ${value?.endDate?.slice(0, 10)}`}
                        </span>
                    )}
                    {initData !== datas.startDate || initData !== datas.endDate ? (
                        <CloseCircleFilled
                            onClick={(e) => {
                                e.stopPropagation();
                                console.log("CloseCircleFilled");
                                setDatas({ startDate: undefined, endDate: undefined });
                            }}
                        />
                    ) : (
                        <CalendarOutlined />
                    )}
                </div>
                <CalendarPicker
                    className={disabled ? "disabledRangePicker" : ""}
                    title={
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <span className="fw-bd fs-lg">검색 기간</span>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Space>
                                    <DoubleLeftOutlined
                                        onClick={() => {
                                            setYear(year - 1);
                                            ref.current?.jumpTo((page) => ({
                                                year: page.year - 1,
                                                month: page.month,
                                            }));
                                        }}
                                    />
                                    <LeftOutlined
                                        onClick={() => {
                                            setMonth(month - 1);
                                            ref.current?.jumpTo((page) => ({
                                                year: page.year,
                                                month: page.month - 1,
                                            }));
                                        }}
                                    />
                                </Space>
                                <span className="fw-rg fs-md fc-font1" style={{ minWidth: "30vw" }}>
                                    {year}년 {month}월
                                </span>
                                <Space>
                                    <RightOutlined
                                        style={{
                                            opacity:
                                                year < Number(moment().format("YYYY")) ||
                                                (year === Number(moment().format("YYYY")) && month < Number(moment().format("M")))
                                                    ? 1
                                                    : 0,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (
                                                year < Number(moment().format("YYYY")) ||
                                                (year === Number(moment().format("YYYY")) && month < Number(moment().format("M")))
                                            ) {
                                                setMonth(month + 1);
                                                ref.current?.jumpTo((page) => ({
                                                    year: page.year,
                                                    month: page.month + 1,
                                                }));
                                            }
                                        }}
                                    />
                                    <DoubleRightOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            if (
                                                year + 1 < Number(moment().format("YYYY")) ||
                                                (year + 1 === Number(moment().format("YYYY")) && month <= Number(moment().format("M")))
                                            ) {
                                                setYear(year + 1);
                                                ref.current?.jumpTo((page) => ({
                                                    year: page.year + 1,
                                                    month: page.month,
                                                }));
                                            } else {
                                                setYear(Number(moment().format("YYYY")));
                                                setMonth(Number(moment().format("M")));
                                                ref.current?.jumpTo(() => ({
                                                    year: Number(moment().format("YYYY")),
                                                    month: Number(moment().format("M")),
                                                }));
                                            }
                                        }}
                                    />
                                </Space>
                                {maxDate && disabled && (
                                    <span className={`fs-sm fc-danger disabledRangePickerText`}>최대 {maxDate}일까지 선택 가능합니다.</span>
                                )}
                            </div>
                        </Space>
                    }
                    ref={ref}
                    visible={open}
                    selectionMode={isSingle ? "single" : "range"}
                    defaultValue={
                        isSingle
                            ? moment().toDate()
                            : isToday
                            ? [moment().startOf("day").toDate(), moment().toDate()]
                            : [value.startDate, value.endDate]
                    }
                    max={todayMax === false ? undefined : moment().toDate()}
                    onClose={() => setOpen(false)}
                    onMaskClick={() => setOpen(false)}
                    confirmText="선택"
                    onChange={(value) => {
                        if (!value || isSingle) return;
                        if (maxDate) {
                            setDisbled(moment.duration(moment(value[1]).diff(moment(value[0]))).asDays() > maxDate);
                        }
                        if (value[0]) {
                            onStartDate && onStartDate(value[0]);
                        }
                        if (value[1]) {
                            onEndDate && onEndDate(value[1]);
                        }
                    }}
                    onConfirm={(value) => {
                        if (!value) return;
                        if (isSingle) {
                            onChangeEvent("date", moment(value).startOf("day").format(Common.FORMAT_DATE_TIME));
                        } else {
                            onChangeEvent(
                                "startDate",
                                value[0] ? moment(value[0]).startOf("day").format(Common.FORMAT_DATE_TIME) : undefined
                            );
                            onChangeEvent("endDate", value[1] ? moment(value[1]).format(Common.FORMAT_DATE_TIME) : undefined);
                        }
                    }}
                />
            </div>
        </ConfigProvider>
    );
}

export default CustomRangePicker;
