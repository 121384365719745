import React from "react";
import RightOutlined from "@ant-design/icons/lib/icons/RightOutlined";
import LeftOutlined from "@ant-design/icons/lib/icons/LeftOutlined";

interface CustomArrowProps {
    type: "next" | "prev";
    onClick?: () => void;
}

const CustomArrow: React.FC<CustomArrowProps> = ({ type, onClick }) => {
    return (
        <div className={`slick-${type} slick-arrow`} onClick={onClick}>
            {type === "next" ? <RightOutlined /> : <LeftOutlined />}
        </div>
    );
};

export default CustomArrow;
