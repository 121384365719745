import { useEffect, useState } from "react";
import { utilAxiosFileWithAuth } from "../../utils/customAxios";
import { useLocation, useNavigate, useParams } from "react-router";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import CustomForm, { viewType } from "../../components/mobile/CustomForm";
import type { UploadFile } from "antd/es/upload/interface";
import { CopyOutlined, HomeOutlined, NumberOutlined, TagOutlined } from "@ant-design/icons";

import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as TypeUtils from "../../commons/typeUtils";
import * as Request from "../../commons/request";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as RequestCar from "../../utils/requestApiCar";
import * as RequestIssue from "../../utils/requestApiIssue";

import Modal from "antd/es/modal/index";
import DatePicker from "antd/es/date-picker/index";
import Form from "antd/es/form/index";
import Row from "antd/es/grid/row";
import Space from "antd/es/space/index";
import Input from "antd/es/input/index";
import Upload from "antd/es/upload/index";
import dayjs from "dayjs";
import CustomCard from "../../components/mobile/CustomCard";
import CustomRangePicker from "../../components/mobile/CustomRangePicker";
import styles from "../../components/mobile/CustomForm.module.css";
import moment from "moment";

const PageMaintenanceDetail = () => {
    const navigate = useNavigate();
    const params = useParams();
    const alert = useAlert();
    const location = useLocation();
    const { pathname } = useLocation();

    const [carForm] = Form.useForm();
    const [issueForm] = Form.useForm();
    const [type, setType] = useState<viewType>("view");
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [alarm, setAlarm] = useState<TypeDTO.AlarmDto>();
    const [initIssue, setInitIssue] = useState<TypeDTO.IssueDto>();
    const [attachFiles, setAttachFiles] = useState<Array<any>>([]);
    const [defaultFiles, setDefaultFiles] = useState<Array<any>>([]);
    const [deletedFiles, setDeletedFiles] = useState<Array<TypeDTO.IssueFileDto>>([]);

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLICK_DELETE, handleDelete);

        return () => {
            Utils.offEvent(Common.EVENT_CLICK_DELETE, handleDelete);
        };
    }, []);

    useEffect(() => {
        if (!params) return;

        if (pathname.includes(Common.PAGE_MANAGE_MAINTENANCE_DETAIL)) {
            params.id2 && requestApiIssueInfo(params.id2);
            setType("view");
        } else if (pathname.includes(Common.PAGE_MANAGE_MAINTENANCE_REGISTER)) {
            setType("register");
        } else {
            params.id2 && requestApiIssueInfo(params.id2);
            setType("edit");
        }

        requestApiCarList();
    }, [params]);

    useEffect(() => {
        location.state && setAlarm(location.state);
    }, [location]);

    useEffect(() => {
        if (alarm === undefined) return;

        issueForm.setFieldsValue({
            issueTime: dayjs(alarm?.alarmTime),
            issueType: "SW",
            issueTitle: alarm?.alarmName,
            issueContent: alarm?.content?.replace(/<[^>]*>?/g, " "),
        });
    }, [alarm]);

    const file = (files: TypeDTO.IssueFileDto[]) => {
        const data: UploadFile[] = files.map((item: TypeDTO.IssueFileDto) => ({
            uid: item.issueFileId.toString(),
            name: item.fileName,
        }));

        setDefaultFiles(data);
        setAttachFiles(data);
    };

    const getValueByKey = (key: string) => {
        if (key in Utils.actionStatus) {
            return Utils.actionStatus[key as keyof typeof Utils.actionStatus];
        }
        return "";
    };

    const getIssueValueByKey = (key: string) => {
        if (key in Utils.issueType) {
            return Utils.issueType[key as keyof typeof Utils.issueType];
        }
        return "";
    };

    const propsAttachFiles = {
        onRemove: (file: UploadFile) => {
            const index = attachFiles.indexOf(file);

            if (index !== -1) {
                setAttachFiles(attachFiles.filter((_, idx) => idx !== index));

                if (defaultFiles.some((item) => item.uid === file.uid)) {
                    setDeletedFiles([...deletedFiles, { issueFileId: Number(file.uid), fileName: file.name, deleted: true }]);
                }
            }
        },
        beforeUpload: (file: UploadFile) => {
            setAttachFiles([...attachFiles, file]);
            return false;
        },
        onPreview: async (file: UploadFile) => {
            await utilAxiosFileWithAuth()
                .get(Request.ISSUE_FILE_DOWNLOAD_URL + file.uid)
                .then((response) => {
                    const blob = new Blob([response.data], { type: "application/octet-stream" });
                    const element = document.createElement("a");
                    element.href = window.URL.createObjectURL(blob);
                    element.download = decodeURIComponent(
                        response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "")
                    );
                    element.click();
                })
                .catch((error) => {
                    alert.setAlert(AlertType.FILE_NOT_FOUND, "파일 다운로드 실패", String.msg_download_fail);
                });
        },
        fileList: attachFiles,
    };

    const carContents: Array<TypeUtils.formType> = [
        { name: "carId", label: "", span: 0 },
        {
            name: "carNumber",
            label: String.carNumber,
            span: 12,
            customSelector: {
                title: String.carNumber,
                typeDetail: "car",
                placeholder: type === "view" ? "-" : "차량 번호",
                className: type === "view" ? "disabled-selector" : "",
                disabled: (type === "register" && !!params.id) || type === "view",
                defaultValue: car?.carNumber,
            },
            required: type !== "view",
        },
        { name: "idNumber", label: "", span: 0 },
        { name: ["customer", "customerName"], label: "", span: 0 },
        { name: ["customer", "customerPhone"], label: "", span: 0 },
        { name: ["makerModel", "maker", "makerName"], label: "", span: 0 },
        { name: ["category", "categoryName"], label: "", span: 0 },
    ];

    const issueContents: Array<TypeUtils.formType> = [
        {
            name: "issueTime",
            label: String.receiptDate,
            required: type !== "view",
            input:
                type !== "view" ? (
                    <CustomRangePicker
                        className={type === "view" ? "disabled-input" : ""}
                        value={{
                            startDate: moment().subtract(0, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
                            endDate: moment().endOf("day").format(Common.FORMAT_DATE_TIME),
                        }}
                        isSingle
                        maxDate={1}
                        onSearch={(value) => issueForm.setFieldValue("issueTime", value)}
                    />
                ) : (
                    <DatePicker
                        className={type === "view" ? "disabled-datepicker" : ""}
                        format={Common.FORMAT_SHORT_DATE}
                        disabled={type === "view"}
                    />
                ),
        },
        {
            name: "actionStatus",
            label: String.actionStatus,
            customSelector: {
                title: String.actionStatus,
                typeDetail: "actionStatus",
                placeholder: type === "view" ? "-" : "조치 상태",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                defaultValue: initIssue?.actionStatus && getValueByKey(initIssue?.actionStatus),
            },
        },
        {
            name: "issueType",
            label: String.issueType,
            customSelector: {
                title: String.issueType,
                typeDetail: "issueType",
                placeholder: type === "view" ? "-" : "정비 분류",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                defaultValue: initIssue?.issueType && getIssueValueByKey(initIssue?.issueType),
            },
        },
        {
            name: ["issueManager", "username"],
            label: String.chargingManager,
            customSelector: {
                title: String.chargingManager,
                typeDetail: "user",
                placeholder: type === "view" ? "-" : "담당자",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                defaultValue: initIssue?.issueManager?.name && initIssue?.issueManager?.name,
            },
        },
        {
            name: "issueTitle",
            label: String.title,
            required: type !== "view",
        },
        {
            name: "issueContent",
            label: String.issueContent,
            input: (
                <Input.TextArea
                    placeholder={type === "view" ? "" : "이슈 내용"}
                    autoSize={true}
                    className={type === "view" ? "disabled-input" : ""}
                    disabled={type === "view"}
                />
            ),
        },
        {
            name: "issueAction",
            label: String.issueAction,
            input: (
                <Input.TextArea
                    placeholder={type === "view" ? "" : "조치 내용"}
                    autoSize={true}
                    className={type === "view" ? "disabled-input" : ""}
                    disabled={type === "view"}
                />
            ),
        },
        {
            name: "attachment",
            label: String.attachment,
            input: (
                <Upload
                    className={type === "view" && styles.uploadWrapper}
                    {...propsAttachFiles}
                    showUploadList={{ showRemoveIcon: true }}
                    multiple={true}
                >
                    {type !== "view" && (
                        <span className="fc-font3 fw-bd fs-md btn-text">
                            <UploadOutlined /> {String.upload}
                        </span>
                    )}
                </Upload>
            ),
        },
    ];

    const { requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        const cars = resultApiCarList.cars;
        const car = cars.find((car: TypeDTO.CarDto) =>
            type === "register" ? car.carNumber === params.id : car.carId === Number(params.id1)
        );
        carForm.setFieldsValue({
            ...car,
            customer: {
                ...car?.customer,
                customerPhone: Utils.convertPhone(car?.customer?.customerPhone),
            },
        });

        setCar(car);
        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const { requestApiIssueInfo, resultApiIssueInfo } = RequestIssue.useRequestApiIssueInfo();

    useEffect(() => {
        if (!resultApiIssueInfo) return;

        const issue: TypeDTO.IssueDto = resultApiIssueInfo.issue;
        setInitIssue(issue);

        issueForm.setFieldsValue({
            ...issue,
            issueTime: dayjs(issue.issueTime),
            issueContent: issue?.issueContent?.replace(/<[^>]*>?/g, " "),
            issueAction: issue?.issueAction?.replace(/<[^>]*>?/g, " "),
            attachment: issue?.issueFiles?.map((file) => {
                return file.fileName?.replaceAll(/./gi, "");
            }),
        });

        file(issue.issueFiles);
    }, [resultApiIssueInfo]);

    const { requestApiIssueRegister, resultApiIssueRegister } = RequestIssue.useRequestApiIssueRegister();

    useEffect(() => {
        if (!resultApiIssueRegister) return;

        onResult("register");
    }, [resultApiIssueRegister]);

    const { requestApiIssueUpdate, resultApiIssueUpdate } = RequestIssue.useRequestApiIssueUpdate();

    useEffect(() => {
        if (!resultApiIssueUpdate) return;

        onResult("edit");
    }, [resultApiIssueUpdate]);

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.issue} ${typeText} 성공`, `${String.issue} 정보를 ${typeText}하였습니다.`);
        navigate(-1);
        // params.id1 ? navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR) : navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY);
    };

    const onValuesChange = (type: viewType, value: string) => {
        setCar(cars?.find((car) => car.carNumber === value));
    };

    const onFinishIssue = (type: viewType, value: TypeDTO.IssueDto) => {
        const formData = new FormData();

        const searchValue = {
            ...value,
            car: { carId: car?.carId },
            issueTime: dayjs(value?.issueTime).format(Common.FORMAT_DATE_HOUR_TIME),
            actionStatus: value?.actionStatus || "WAITING",
            issueType: value?.issueType || "HW",
        };

        if (deletedFiles) searchValue.issueFiles = deletedFiles;
        formData.append("issueDto", new Blob([JSON.stringify(searchValue)], { type: "application/json" }));

        attachFiles.forEach((file) => {
            formData.append("files", file);
        });

        type === "register" ? requestApiIssueRegister(formData) : requestApiIssueUpdate(params.id2, formData);
    };

    const cardValue = [
        {
            icon: <NumberOutlined />,
            value: car?.idNumber ? car.idNumber : "-",
        },
        {
            icon: <HomeOutlined />,
            value: car?.makerModel.maker.makerName ? car.makerModel.maker.makerName : "-",
        },
        {
            icon: <TagOutlined />,
            value: car?.makerModel.makerModelName ? car.makerModel.makerModelName : "-",
        },
    ];

    const handleDelete = () => {
        onDelete(params.id2 ? Number(params.id2) : initIssue?.issueId, initIssue?.issueTitle);
    };

    const { requestApiIssueDelete, resultApiIssueDelete } = RequestIssue.useRequestApiIssueDelete();

    useEffect(() => {
        if (!resultApiIssueDelete) return;

        onResult("delete");
    }, [resultApiIssueDelete]);

    const onDelete = (id: number, name: string) => {
        Modal.confirm({
            title: "정비 이력 " + String.remove,
            content: Utils.addParticle(name ? name : "해당 정비 이력", "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiIssueDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    return (
        <div className="pageWrapper" style={{ paddingBottom: type === "view" ? 60 : 68 }}>
            <Space direction="vertical" size={8} style={{ display: "flex" }}>
                {type === "view" && (
                    <Row className="titleWrapper" justify={"space-between"}>
                        <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                            정비 이력
                        </p>
                        <span
                            className={`${styles.editBtn} fc-font3 fw-bd fs-sm btn-text`}
                            onClick={() => {
                                setType("edit");
                                navigate(Common.PAGE_MANAGE_MAINTENANCE_UPDATE + "/" + car?.carId + "/" + initIssue?.issueId);
                            }}
                        >
                            <EditOutlined /> {String.update}
                        </span>
                    </Row>
                )}
                {type === "view" ? (
                    <CustomCard
                        title={car?.carNumber}
                        icon={<CopyOutlined />}
                        contents={cardValue}
                        onClick={() => {
                            if (car?.carNumber) {
                                navigator?.clipboard?.writeText(car.carNumber);
                                alert.setAlert(AlertType.SUCCESS, `복사 성공`, `차량 번호를 복사했습니다.`);
                            }
                        }}
                    />
                ) : (
                    <CustomForm
                        form={carForm}
                        type={type}
                        initialValues={car}
                        contents={[{ forms: carContents }]}
                        onChangeType={(value) => setType(value)}
                        onChangeSelectorValue={(key, value) => {
                            onValuesChange(type, value);
                            carForm.setFieldValue(key, value);
                        }}
                    />
                )}

                <CustomForm
                    form={issueForm}
                    type={type}
                    initialValues={initIssue}
                    contents={[{ forms: issueContents }]}
                    onClick={(value) => value === "back" && issueForm.resetFields()}
                    onFinish={onFinishIssue}
                    onChangeSelectorValue={(key, value) => {
                        issueForm.setFieldValue(key, value);
                    }}
                />
            </Space>
        </div>
    );
};

export default PageMaintenanceDetail;
