import { useState, useEffect } from "react";

import DatePicker from "antd/es/date-picker/index";
import Input from "antd/es/input/index";
import Modal from "antd/es/modal/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";

import { viewType } from "../../components/browser/CustomForm";
import { useAuthState } from "../../provider/AuthProvider";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestDoorModule from "../../utils/requestApiDoorModule";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";
import moment from "moment";
import dayjs from "dayjs";

type SearchType = {
    serialNumber: string;
    carNumber: string;
    swVersion: string;
};

const PageDoorModule = () => {
    const userDetails = useAuthState();
    const alert = useAlert();
    const { Option } = Select;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [type, setType] = useState<viewType>("view");
    const [doorModule, setDoorModule] = useState<TypeDTO.DoorModuleDto>();
    const [doorModules, setDoorModules] = useState<Array<TypeDTO.DoorModuleDto>>();
    const [filteredDoorModules, setFilteredDoorModules] = useState<Array<TypeDTO.DoorModuleDto>>();

    useEffect(() => {
        requestApiDoorModuleList();
    }, []);

    const getValueByKey = (key: string) => {
        if (key in Utils.carrier) {
            return Utils.carrier[key as keyof typeof Utils.carrier];
        }
        return "";
    };

    const columns: ColumnsType<TypeDTO.DoorModuleDto> = [
        {
            title: String.serialNumber,
            dataIndex: "serialNumber",
            key: "serialNumber",
            width: "10%",
            align: "center",
        },
        {
            title: String.swVersion,
            dataIndex: "sysVer",
            key: "sysVer",
            width: "10%",
            align: "center",
            render: (_, { swVersion }) => {
                return swVersion || String.dash;
            },
        },
        {
            title: String.equippedDate,
            dataIndex: "equippedDate",
            key: "equippedDate",
            width: "10%",
            align: "center",
            render: (_, { equippedDate }) => (equippedDate ? moment(equippedDate, Common.FORMAT_DATE_TIME).format(Common.FORMAT_DATE) : String.dash),
        },
        {
            title: String.carNumber,
            dataIndex: ["car", "carNumber"],
            key: "carNumber",
            width: "10%",
            align: "center",
            render: (_, { car }) => {
                return car?.carNumber || String.dash;
            },
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRow("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.doorModuleId, row.serialNumber);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "serialNumber",
            span: 12,
            title: String.serialNumber,
            type: "select",
            options: doorModules?.map((doorModule) => ({ value: doorModule.serialNumber, label: doorModule.serialNumber })),
        },
        {
            id: "swVersion",
            span: 6,
            title: String.swVersion,
            type: "select",
            options: Utils.removeDuplicateObjects(doorModules?.map((doorModule) => ({ value: doorModule.swVersion, label: doorModule.swVersion }))),
        },
        {
            id: "carNumber",
            span: 6,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        {
            name: "serialNumber",
            label: String.serialNumber,
            span: 24,
            required: true,
        },
        {
            name: "swVersion",
            label: String.swVersion,
            span: 12,
        },
        {
            name: "equippedDate",
            label: String.equippedDate,
            span: 12,
            input: <DatePicker className={type === "view" ? "disabled-datepicker" : ""} disabled={type === "view"} />,
        },
        {
            name: "imei",
            label: String.imei,
            span: 24,
        },
        {
            name: "carrier",
            label: String.carrier,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="통신사를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                >
                    {Object.keys(Utils.carrier)?.map((key) => (
                        <Option key={key} value={key}>
                            {getValueByKey(key)}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            name: "phone",
            label: String.phoneNumber,
            span: 12,
            rules: [
                {
                    pattern: Common.PHONE_REGEXP,
                    message: "유효한 전화번호 형식이 아닙니다",
                },
            ],
            input: <Input className={type === "view" ? "disabled-input" : ""} disabled={type === "view"} />,
        },
        {
            name: ["car", "carNumber"],
            label: String.carNumber,
            span: 24,
            disabled: true,
        },
        {
            name: ["car", "carId"],
            label: "carId",
            span: 0,
            disabled: true,
        },
    ];

    const { loadingApiDoorModuleList, requestApiDoorModuleList, resultApiDoorModuleList } = RequestDoorModule.useRequestApiDoorModuleList();

    useEffect(() => {
        if (!resultApiDoorModuleList) return;

        setDoorModules(resultApiDoorModuleList.doorModules);
    }, [resultApiDoorModuleList]);

    const { requestApiDoorModuleRegister, resultApiDoorModuleRegister } = RequestDoorModule.useRequestApiDoorModuleRegister();

    useEffect(() => {
        if (!resultApiDoorModuleRegister) return;

        onResult("register");
    }, [resultApiDoorModuleRegister]);

    const { requestApiDoorModuleUpdate, resultApiDoorModuleUpdate } = RequestDoorModule.useRequestApiDoorModuleUpdate();

    useEffect(() => {
        if (!resultApiDoorModuleUpdate) return;

        onResult("edit");
    }, [resultApiDoorModuleUpdate]);

    const { requestApiDoorModuleDelete, resultApiDoorModuleDelete } = RequestDoorModule.useRequestApiDoorModuleDelete();

    useEffect(() => {
        if (!resultApiDoorModuleDelete) return;

        onResult("delete");
    }, [resultApiDoorModuleDelete]);

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;
        setFilteredDoorModules(
            doorModules?.filter(
                (doorModule) =>
                    searchFilter(doorModule, value?.serialNumber, "serialNumber") &&
                    searchFilter(doorModule?.car, value?.carNumber, "carNumber") &&
                    searchFilter(doorModule, value?.swVersion, "swVersion")
            )
        );
    };

    const onRow = (type: viewType, value?: TypeDTO.DoorModuleDto) => {
        setDoorModule(value);
        setIsOpen(true);
        setType(type);
    };

    const onDelete = (id: number, serialNumber: string) => {
        Modal.confirm({
            title: String.doormodule + String.remove,
            content: Utils.addParticle(serialNumber, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiDoorModuleDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinish = (type: viewType, value: TypeDTO.DoorModuleDto) => {
        const searchValue = {
            ...value,
            phone: value?.phone?.replaceAll("-", ""),
            equippedDate: value.equippedDate && dayjs(value.equippedDate).format(Common.FORMAT_DATE_HOUR_TIME),
        };

        type === "register" ? requestApiDoorModuleRegister(searchValue) : requestApiDoorModuleUpdate(doorModule?.doorModuleId, searchValue);
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.doormodule} ${typeText} 성공`, `${String.doormodule} 정보를 ${typeText}하였습니다.`);
        requestApiDoorModuleList();

        type !== "delete" && setIsOpen(false);
    };

    return (
        <div className="pageWrapper">
            <Search
                title={String.doormodule}
                dataLength={filteredDoorModules?.length || doorModules?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? () => onRow("register", undefined) : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.DoorModuleDto) => row.doorModuleId}
                disabledTitle
                loading={loadingApiDoorModuleList}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={filteredDoorModules || doorModules}
                onRow={(value) => onRow("view", value)}
            />
            <CustomModal
                title={String.doormodule}
                open={isOpen}
                type={type}
                value={type === "view" ? { ...doorModule, phone: Utils.convertPhone(doorModule?.phone) } : doorModule}
                contents={contents}
                onChangedOpen={() => setIsOpen(false)}
                onChangedType={(value) => setType(value)}
                onFinish={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? onFinish : undefined}
            />
        </div>
    );
};

export default PageDoorModule;
