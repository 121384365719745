import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiStatsAlarm = () => {
    const requestAxiosApiStatsAlarm = async () => {
        const response = await utilAxiosWithAuth().get(Request.STATS_ALARM_URL);

        return response.data;
    };

    const {
        loading: loadingApiStatsAlarm,
        error: errorApiStatsAlarm,
        data: resultApiStatsAlarm,
        execute: requestApiStatsAlarm,
    } = useAsyncAxios(requestAxiosApiStatsAlarm);

    useEffect(() => {
        if (resultApiStatsAlarm === null) return;
        DEBUG && console.log("resultApiStatsAlarm", resultApiStatsAlarm);
    }, [resultApiStatsAlarm]);

    useEffect(() => {
        if (errorApiStatsAlarm === null) return;
        DEBUG && console.log("errorApiStatsAlarm", errorApiStatsAlarm);
    }, [errorApiStatsAlarm]);

    return {
        loadingApiStatsAlarm,
        errorApiStatsAlarm,
        resultApiStatsAlarm,
        requestApiStatsAlarm,
    };
};

export const useRequestApiStatsCarByCategory = () => {
    const requestAxiosApiStatsCarByCategory = async () => {
        const response = await utilAxiosWithAuth().get(Request.STATS_CAR_BY_CATEGORY_URL);

        return response.data;
    };

    const {
        loading: loadingApiStatsCarByCategory,
        error: errorApiStatsCarByCategory,
        data: resultApiStatsCarByCategory,
        execute: requestApiStatsCarByCategory,
    } = useAsyncAxios(requestAxiosApiStatsCarByCategory);

    useEffect(() => {
        if (resultApiStatsCarByCategory === null) return;
        DEBUG && console.log("resultApiStatsCarByCategory", resultApiStatsCarByCategory);
    }, [resultApiStatsCarByCategory]);

    useEffect(() => {
        if (errorApiStatsCarByCategory === null) return;
        DEBUG && console.log("errorApiStatsCarByCategory", errorApiStatsCarByCategory);
    }, [errorApiStatsCarByCategory]);

    return {
        loadingApiStatsCarByCategory,
        errorApiStatsCarByCategory,
        resultApiStatsCarByCategory,
        requestApiStatsCarByCategory,
    };
};

export const useRequestApiStatsCarByMaker = () => {
    const requestAxiosApiStatsCarByMaker = async () => {
        const response = await utilAxiosWithAuth().get(Request.STATS_CAR_BY_MAKER_URL);

        return response.data;
    };

    const {
        loading: loadingApiStatsCarByMaker,
        error: errorApiStatsCarByMaker,
        data: resultApiStatsCarByMaker,
        execute: requestApiStatsCarByMaker,
    } = useAsyncAxios(requestAxiosApiStatsCarByMaker);

    useEffect(() => {
        if (resultApiStatsCarByMaker === null) return;
        DEBUG && console.log("resultApiStatsCarByMaker", resultApiStatsCarByMaker);
    }, [resultApiStatsCarByMaker]);

    useEffect(() => {
        if (errorApiStatsCarByMaker === null) return;
        DEBUG && console.log("errorApiStatsCarByMaker", errorApiStatsCarByMaker);
    }, [errorApiStatsCarByMaker]);

    return {
        loadingApiStatsCarByMaker,
        errorApiStatsCarByMaker,
        resultApiStatsCarByMaker,
        requestApiStatsCarByMaker,
    };
};

export type StatsSearchTypeCellTemperature = {
    carId?: number;
    startDate?: string;
    endDate?: string;
    period?: "HOUR" | "MINUTE";
};

export const useRequestApiStatsCellTemperature = () => {
    const requestAxiosApiStatsCellTemperature = async (carId: number, data: StatsSearchTypeCellTemperature) => {
        const response = await utilAxiosWithAuth().get(Request.STATS_CELL_URL + carId, { params: data });

        return response.data;
    };

    const {
        loading: loadingApiStatsCellTemperature,
        error: errorApiStatsCellTemperature,
        data: resultApiStatsCellTemperature,
        execute: requestApiStatsCellTemperature,
    } = useAsyncAxios(requestAxiosApiStatsCellTemperature);

    useEffect(() => {
        if (resultApiStatsCellTemperature === null) return;
        DEBUG && console.log("resultApiStatsCellTemperature", resultApiStatsCellTemperature);
    }, [resultApiStatsCellTemperature]);

    useEffect(() => {
        if (errorApiStatsCellTemperature === null) return;
        DEBUG && console.log("errorApiStatsCellTemperature", errorApiStatsCellTemperature);
    }, [errorApiStatsCellTemperature]);

    return {
        loadingApiStatsCellTemperature,
        errorApiStatsCellTemperature,
        resultApiStatsCellTemperature,
        requestApiStatsCellTemperature,
    };
};

export type StatsSearchTypePeriod = {
    startDate?: string;
    endDate?: string;
    period?: "YEAR" | "MONTH" | "DAY";
};

export const useRequestApiStatsData = () => {
    const requestAxiosApiStatsData = async (data: StatsSearchTypePeriod) => {
        const response = await utilAxiosWithAuth().get(Request.STATS_DATA_URL, { params: data });

        return response.data;
    };

    const {
        loading: loadingApiStatsData,
        error: errorApiStatsData,
        data: resultApiStatsData,
        execute: requestApiStatsData,
    } = useAsyncAxios(requestAxiosApiStatsData);

    useEffect(() => {
        if (resultApiStatsData === null) return;
        DEBUG && console.log("resultApiStatsData", resultApiStatsData);
    }, [resultApiStatsData]);

    useEffect(() => {
        if (errorApiStatsData === null) return;
        DEBUG && console.log("errorApiStatsData", errorApiStatsData);
    }, [errorApiStatsData]);

    return {
        loadingApiStatsData,
        errorApiStatsData,
        resultApiStatsData,
        requestApiStatsData,
    };
};

export const useRequestApiStatsDataAccumulated = () => {
    const requestAxiosApiStatsDataAccumulated = async (accmulatedPeriod: "YEAR" | "MONTH") => {
        const response = await utilAxiosWithAuth().get(Request.STATS_DATA_ACCUMULATED_URL, { params: { period: accmulatedPeriod } });

        return response.data;
    };

    const {
        loading: loadingApiStatsDataAccumulated,
        error: errorApiStatsDataAccumulated,
        data: resultApiStatsDataAccumulated,
        execute: requestApiStatsDataAccumulated,
    } = useAsyncAxios(requestAxiosApiStatsDataAccumulated);

    useEffect(() => {
        if (resultApiStatsDataAccumulated === null) return;
        DEBUG && console.log("resultApiStatsDataAccumulated", resultApiStatsDataAccumulated);
    }, [resultApiStatsDataAccumulated]);

    useEffect(() => {
        if (errorApiStatsDataAccumulated === null) return;
        DEBUG && console.log("errorApiStatsDataAccumulated", errorApiStatsDataAccumulated);
    }, [errorApiStatsDataAccumulated]);

    return {
        loadingApiStatsDataAccumulated,
        errorApiStatsDataAccumulated,
        resultApiStatsDataAccumulated,
        requestApiStatsDataAccumulated,
    };
};

export const useRequestApiStatsRunning = () => {
    const requestAxiosApiStatsRunning = async (data: StatsSearchTypePeriod) => {
        const response = await utilAxiosWithAuth().get(Request.STATS_RUNNING_URL, { params: data });

        return response.data;
    };

    const {
        loading: loadingApiStatsRunning,
        error: errorApiStatsRunning,
        data: resultApiStatsRunning,
        execute: requestApiStatsRunning,
    } = useAsyncAxios(requestAxiosApiStatsRunning);

    useEffect(() => {
        if (resultApiStatsRunning === null) return;
        DEBUG && console.log("resultApiStatsRunning", resultApiStatsRunning);
    }, [resultApiStatsRunning]);

    useEffect(() => {
        if (errorApiStatsRunning === null) return;
        DEBUG && console.log("errorApiStatsRunning", errorApiStatsRunning);
    }, [errorApiStatsRunning]);

    return {
        loadingApiStatsRunning,
        errorApiStatsRunning,
        resultApiStatsRunning,
        requestApiStatsRunning,
    };
};

export type StatsSearchTypeRunningCar = {
    carId?: number;
    startDate?: string;
    endDate?: string;
};

export const useRequestApiStatsRunningCar = () => {
    const requestAxiosApiStatsRunningCar = async (carId: number, data: StatsSearchTypeRunningCar) => {
        const response = await utilAxiosWithAuth().get(Request.STATS_RUNNING_BY_CAR_URL + carId, { params: data });

        return response.data;
    };

    const {
        loading: loadingApiStatsRunningCar,
        error: errorApiStatsRunningCar,
        data: resultApiStatsRunningCar,
        execute: requestApiStatsRunningCar,
    } = useAsyncAxios(requestAxiosApiStatsRunningCar);

    useEffect(() => {
        if (resultApiStatsRunningCar === null) return;
        DEBUG && console.log("resultApiStatsRunningCar", resultApiStatsRunningCar);
    }, [resultApiStatsRunningCar]);

    useEffect(() => {
        if (errorApiStatsRunningCar === null) return;
        DEBUG && console.log("errorApiStatsRunningCar", errorApiStatsRunningCar);
    }, [errorApiStatsRunningCar]);

    return {
        loadingApiStatsRunningCar,
        errorApiStatsRunningCar,
        resultApiStatsRunningCar,
        requestApiStatsRunningCar,
    };
};

export const useRequestApiStatsRunningAccumulated = () => {
    const requestAxiosApiStatsRunningAccumulated = async (accmulatedPeriod: "YEAR" | "MONTH") => {
        const response = await utilAxiosWithAuth().get(Request.STATS_RUNNING_ACCUMULATED_URL, { params: { period: accmulatedPeriod } });

        return response.data;
    };

    const {
        loading: loadingApiStatsRunningAccumulated,
        error: errorApiStatsRunningAccumulated,
        data: resultApiStatsRunningAccumulated,
        execute: requestApiStatsRunningAccumulated,
    } = useAsyncAxios(requestAxiosApiStatsRunningAccumulated);

    useEffect(() => {
        if (resultApiStatsRunningAccumulated === null) return;
        DEBUG && console.log("resultApiStatsRunningAccumulated", resultApiStatsRunningAccumulated);
    }, [resultApiStatsRunningAccumulated]);

    useEffect(() => {
        if (errorApiStatsRunningAccumulated === null) return;
        DEBUG && console.log("errorApiStatsRunningAccumulated", errorApiStatsRunningAccumulated);
    }, [errorApiStatsRunningAccumulated]);

    return {
        loadingApiStatsRunningAccumulated,
        errorApiStatsRunningAccumulated,
        resultApiStatsRunningAccumulated,
        requestApiStatsRunningAccumulated,
    };
};
