import { useState, useEffect } from "react";

import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";

import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestCategory from "../../utils/requestApiCategory";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type SearchType = {
    categoryName: string;
};

const PageCategory = () => {
    const alert = useAlert();
    const userDetails = useAuthState();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [type, setType] = useState<viewType>("view");
    const [category, setCategory] = useState<TypeDTO.CategoryDto>();
    const [categories, setCategories] = useState<Array<TypeDTO.CategoryDto>>();
    const [filteredCategorys, setFilteredCategorys] = useState<Array<TypeDTO.CategoryDto>>();

    useEffect(() => {
        requestApiCategoryList();
    }, []);

    const columns: ColumnsType<TypeDTO.CategoryDto> = [
        {
            title: String.category,
            dataIndex: "categoryName",
            key: "categoryName",
            width: "10%",
            align: "center",
        },
        {
            title: String.manage,
            key: "detail",
            align: "center",
            width: "10%",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRow("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.categoryId, row.categoryName);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "categoryName",
            span: 24,
            title: String.category,
            type: "select",
            options: categories?.map((category) => ({ value: category.categoryName, label: category.categoryName })),
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        {
            name: "categoryName",
            label: String.type,
            span: 24,
            required: true,
        },
    ];

    const { loadingApiCategoryList, requestApiCategoryList, resultApiCategoryList } = RequestCategory.useRequestApiCategoryList();

    useEffect(() => {
        if (!resultApiCategoryList) return;

        setCategories(resultApiCategoryList.categories);
    }, [resultApiCategoryList]);

    const { requestApiCategoryRegister, resultApiCategoryRegister } = RequestCategory.useRequestApiCategoryRegister();

    useEffect(() => {
        if (!resultApiCategoryRegister) return;

        onResult("register");
    }, [resultApiCategoryRegister]);

    const { requestApiCategoryUpdate, resultApiCategoryUpdate } = RequestCategory.useRequestApiCategoryUpdate();

    useEffect(() => {
        if (!resultApiCategoryUpdate) return;

        onResult("edit");
    }, [resultApiCategoryUpdate]);

    const { requestApiCategoryDelete, resultApiCategoryDelete } = RequestCategory.useRequestApiCategoryDelete();

    useEffect(() => {
        if (!resultApiCategoryDelete) return;

        onResult("delete");
    }, [resultApiCategoryDelete]);

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;
        setFilteredCategorys(categories?.filter((category) => searchFilter(category, value?.categoryName, "categoryName")));
    };

    const onRow = (type: viewType, value?: TypeDTO.CategoryDto) => {
        setCategory(value);
        setIsOpen(true);
        setType(type);
    };

    const onDelete = (id: number, name: string) => {
        Modal.confirm({
            title: String.category + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiCategoryDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinish = (type: viewType, value: TypeDTO.CategoryDto) => {
        type === "register" ? requestApiCategoryRegister(value) : requestApiCategoryUpdate(category?.categoryId, value);
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.category} ${typeText} 성공`, `${String.category} 정보를 ${typeText}하였습니다.`);
        requestApiCategoryList();

        type !== "delete" && setIsOpen(false);
    };

    return (
        <div className="pageWrapper">
            <Search
                title={`${String.category} 목록`}
                dataLength={filteredCategorys?.length || categories?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? () => onRow("register", undefined) : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.CategoryDto) => row.categoryId}
                disabledTitle
                loading={loadingApiCategoryList}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={filteredCategorys || categories}
                onRow={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (value) => onRow("view", value) : undefined}
            />
            <CustomModal
                title={String.category}
                open={isOpen}
                type={type}
                value={category}
                contents={contents}
                onChangedOpen={() => setIsOpen(false)}
                onChangedType={(value) => setType(value)}
                onFinish={onFinish}
            />
        </div>
    );
};

export default PageCategory;
