import { useState } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import { useAuthState } from "../provider/AuthProvider";

import * as Common from "../commons/common";
import * as Utils from "../utils/utils";

// Pages
import LayoutDefault from "../layout/mobile/LayoutDefault";
import PageError403 from "../pages/common/PageError403";
import PageError404 from "../pages/common/PageError404";
import PageError5XX from "../pages/common/PageError5XX";
import AlertPopup from "../components/common/AlertPopup";
import Login from "../pages/common/Login";
import PageMenu from "../pages/mobile/PageMenu";
import Dashboard from "../pages/mobile/Dashboard";
import PageRunnings from "../pages/mobile/PageRunnings";
import PageRunningsDetails from "../pages/mobile/PageRunningsDetails";
import PageCars from "../pages/mobile/PageCars";
import PageCarDetail from "../pages/mobile/PageCarDetail";
import PageMaintenance from "../pages/mobile/PageMaintenance";
import PageMaintenanceByCar from "../pages/mobile/PageMaintenanceByCar";
import PageMaintenanceDetail from "../pages/mobile/PageMaintenanceDetail";
import PageSoc from "../pages/mobile/PageSoc";
import PageSocByCar from "../pages/mobile/PageSocByCar";
import PageSocDetail from "../pages/mobile/PageSocDetail";
import PageAlarm from "../pages/mobile/PageAlarm";
import PageMaintenanceGuide from "../pages/mobile/PageMaintenanceGuide";
import PageAlarmDetail from "../pages/mobile/PageAlarmDetail";
import Welcome from "../pages/mobile/Welcome";

const MobileRouter = () => {
    const [carNumber, setCarNumber] = useState<string>();
    const PrivateRoute = (props: any) => {
        const userDetails = useAuthState();

        if (userDetails?.user === null) {
            return <Navigate to={Common.PAGE_LOGIN} replace={true} />;
        }

        if (props.role !== undefined && props.role.length > 0) {
            const checkRole = userDetails?.user && props.role.includes(userDetails?.user.authority);
            return checkRole ? props.children : <PageError403 />;
        }

        return userDetails?.user ? props.children : <Navigate to={Common.PAGE_LOGIN} replace={true} />;
    };

    return (
        <BrowserRouter>
            <AlertPopup />
            <Routes>
                <Route path="*" element={<PageError404 />} />
                <Route path="/" element={<Navigate to={Common.PAGE_WELCOME} />} />
                <Route path={Common.PAGE_WELCOME} element={<Welcome />} />
                <Route
                    path={Common.PAGE_LOGIN}
                    element={
                        <LayoutDefault header={{ title: "로그인" }} theme={{ header: true, footer: false, scroll: false }}>
                            <Login />
                        </LayoutDefault>
                    }
                />
                <Route path={Common.PAGE_ERROR_404} element={<PageError404 />} />
                <Route path={Common.PAGE_ERROR_403} element={<PageError403 />} />
                <Route path={Common.PAGE_ERROR_5XX} element={<PageError5XX />} />
                <Route
                    path={Common.PAGE_MENU}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault header={{ title: "전체 메뉴" }} theme={{ header: true, footer: true }}>
                                <PageMenu />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_DASHBOARD}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault theme={{ footer: true, scroll: false }}>
                                <Dashboard />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ title: "차량 정보", right: "add" }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageCars />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR_DETAIL + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ left: true, title: "상세 차량 정보", right: "delete" }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageCarDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR_REGISTER}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN]}>
                            <LayoutDefault header={{ left: true, title: "차량 등록" }} theme={{ header: true, footer: false }}>
                                <PageCarDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR_UPDATE + "/:id"}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN]}>
                            <LayoutDefault header={{ left: true, title: "차량 수정" }} theme={{ header: true, footer: false }}>
                                <PageCarDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_CAR_DEVICE_UPDATE + "/:id1/:id2"}
                    element={
                        <PrivateRoute role={[Utils.getAuthority.ROLE_ADMIN]}>
                            <LayoutDefault header={{ left: true, title: "장치 수정" }} theme={{ header: true, footer: false }}>
                                <PageCarDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_RUNNING}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault header={{ left: true, title: "운행 이력" }} theme={{ header: true, footer: true }}>
                                <PageRunnings />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_RUNNING_DETAILS + "/:id1/:id2"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ left: true, title: "상세 운행 이력" }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageRunningsDetails />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_RUNNING_DETAILS + "/:id1/:id2/:id3"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ left: true, title: "상세 운행 이력" }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageRunningsDetails />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ title: "차량별 정비 이력", right: "add", isMaintenance: true }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageMaintenanceByCar />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCES_HISTORY}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ title: "전체 정비 이력", right: "add", isMaintenance: true }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageMaintenance />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ left: true, title: `${carNumber} 정비 이력`, right: "add", isMaintenance: false }}
                                theme={{ header: true, footer: true }}
                                onCarNumber={(number) => setCarNumber(number)}
                            >
                                <PageMaintenance />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_DETAIL + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ left: true, title: "상세 정비 이력", right: "delete" }}
                                theme={{ header: true, footer: true }}
                            >
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_DETAIL + "/:id1/:id2"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ left: true, title: "상세 정비 이력", right: "delete" }}
                                theme={{ header: true, footer: true }}
                            >
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_REGISTER}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                            ]}
                        >
                            <LayoutDefault
                                header={{
                                    title: "정비 이력 등록",
                                    left: true,
                                }}
                                theme={{ header: true }}
                            >
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_REGISTER + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                            ]}
                        >
                            <LayoutDefault
                                header={{
                                    title: "정비 이력 등록",
                                    left: true,
                                }}
                                theme={{ header: true }}
                            >
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MANAGE_MAINTENANCE_UPDATE + "/:id1/:id2"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                            ]}
                        >
                            <LayoutDefault
                                header={{
                                    title: "정비 이력 수정",
                                    left: true,
                                }}
                                theme={{ header: true }}
                            >
                                <PageMaintenanceDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ISSUE_SOCS}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ title: "전압 대비 SOC", isMaintenance: true }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageSoc />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ISSUE_SOCS + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{
                                    left: true,
                                    title: `${carNumber} 전압 대비 SOC`,
                                }}
                                theme={{ header: true, footer: true, scroll: false }}
                                onCarNumber={(number) => setCarNumber(number)}
                            >
                                <PageSocByCar />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ISSUE_SOC_DETAIL + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{
                                    left: true,
                                    title: `${carNumber} 배터리 정보`,
                                }}
                                theme={{ header: true, footer: true }}
                                onCarNumber={(number) => setCarNumber(number)}
                            >
                                <PageSocDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ALARM}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ title: "전체 알람", isMaintenance: true }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageAlarm />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ALARM + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault header={{ title: "전체 알람", isMaintenance: true }} theme={{ header: true, footer: true, scroll: false }}>
                                <PageAlarm />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_ALARM_DETAIL + "/:id"}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault header={{ left: true, title: "상세 알람 정보" }} theme={{ header: true, footer: true }}>
                                <PageAlarmDetail />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={Common.PAGE_MAINTENANCE_GUIDES}
                    element={
                        <PrivateRoute
                            role={[
                                Utils.getAuthority.ROLE_ADMIN,
                                Utils.getAuthority.ROLE_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                Utils.getAuthority.ROLE_CUSTOMER,
                                Utils.getAuthority.ROLE_USER,
                            ]}
                        >
                            <LayoutDefault
                                header={{ title: "정기점검 지침", isMaintenance: true }}
                                theme={{ header: true, footer: true, scroll: false }}
                            >
                                <PageMaintenanceGuide />
                            </LayoutDefault>
                        </PrivateRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
};

export default MobileRouter;
