import { useEffect, useState } from "react";
import { getCurrentDateTime, useInterval } from "../../utils/utils";
import Carousel from "antd/es/carousel/index";

import * as RequsetWeather from "../../utils/requestApiWeather";

import styles from "../../layout/browser/LayoutMenu.module.css";
import CustomArrow from "../common/CustomArrow";

import imgWeatherBrokenClouds from "../../assets/images/weather/broken_clouds.svg";
import imgWeatherClearSky from "../../assets/images/weather/clear_sky.svg";
import imgWeatherClearSkyNight from "../../assets/images/weather/clear_sky_night.svg";
import imgWeatherFewClouds from "../../assets/images/weather/few_clouds.svg";
import imgWeatherFewCloudsNight from "../../assets/images/weather/few_clouds_night.svg";
import imgWeatherMist from "../../assets/images/weather/mist.svg";
import imgWeatherRain from "../../assets/images/weather/rain.svg";
import imgWeatherShowerRain from "../../assets/images/weather/shower_rain.svg";
import imgWeatherSnow from "../../assets/images/weather/snow.svg";
import imgWeatherThunderstorm from "../../assets/images/weather/thunderstorm.svg";
import imgDayWeatherBg from "../../assets/images/weather/bg_day.png";
import imgNightWeatherBg from "../../assets/images/weather/bg_night.png";

type weatherType = {
    weatherId: number;
    base: string;
    clouds: any;
    cod: number;
    coord: { lat: number; lng: number };
    dt: number;
    id: number;
    main: any;
    name: string;
    sys: any;
    timezone: number;
    visibility: number;
    dataWeathers: Array<dataWeathersType>;
    wind: any;
    area: any;
    createDate: string;
};

type dataWeathersType = { dataWeatherId: number; description: string; icon: string; main: string; weather: any };

const Weather = () => {
    const [weathers, setWeathers] = useState<Array<weatherType>>();
    const [time, setTime] = useState<string>(getCurrentDateTime().time);
    const [date, setDate] = useState<string>(getCurrentDateTime().date);

    useEffect(() => {
        requestApiWeather();
    }, []);

    useInterval(() => {
        const t = getCurrentDateTime();
        setTime(t.time);
        setDate(t.date);
    }, 1000);

    const iconMap: Map<string, any> = new Map([
        ["01d", imgWeatherClearSky],
        ["02d", imgWeatherFewClouds],
        ["03d", imgWeatherBrokenClouds],
        ["04d", imgWeatherBrokenClouds],
        ["09d", imgWeatherShowerRain],
        ["10d", imgWeatherRain],
        ["11d", imgWeatherThunderstorm],
        ["13d", imgWeatherSnow],
        ["50d", imgWeatherMist],
        ["01n", imgWeatherClearSkyNight],
        ["02n", imgWeatherFewCloudsNight],
        ["03n", imgWeatherBrokenClouds],
        ["04n", imgWeatherBrokenClouds],
        ["09n", imgWeatherShowerRain],
        ["10n", imgWeatherRain],
        ["13n", imgWeatherSnow],
        ["50n", imgWeatherMist],
    ]);

    const nameMap: Map<string, any> = new Map([
        ["Seoul", "서울"],
        ["Daejeon", "대전"],
        ["Daegu", "대구"],
        ["Busan", "부산"],
        ["Gwangju", "광주"],
        ["Gangneung", "강릉"],
        ["Chuncheon", "춘천"],
        ["Jeju City", "제주"],
    ]);

    const locationCoordinatesMap: Map<string, { lat: number; lng: number }> = new Map([
        ["Seoul", { lat: 26, lng: 9 }],
        ["Daejeon", { lat: 32, lng: 33 }],
        ["Daegu", { lat: 47, lng: 40 }],
        ["Busan", { lat: 55, lng: 50 }],
        ["Gwangju", { lat: 25, lng: 50 }],
        ["Gangneung", { lat: 55, lng: 15 }],
        ["Chuncheon", { lat: 40, lng: 5 }],
        ["Jeju City", { lat: 20, lng: 75 }],
    ]);

    const getWeatherImageByKey = (key: string) => {
        return iconMap.get(key);
    };

    const getWeatherNameByKey = (key: string) => {
        return nameMap.get(key);
    };

    const getWeatherCoordinatesByKey = (key: string) => {
        return locationCoordinatesMap.get(key);
    };

    const { requestApiWeather, resultApiWeather } = RequsetWeather.useRequestApiWeather();

    useEffect(() => {
        if (!resultApiWeather) return;

        setWeathers(resultApiWeather?.weather);
    }, [resultApiWeather]);

    const settings = {
        nextArrow: <CustomArrow type="next" />,
        prevArrow: <CustomArrow type="prev" />,
    };

    return (
        <div
            className={styles.weatherCarouselWrapper}
            style={{ backgroundImage: Number(time.slice(0, 2)) >= 20 ? `url(${imgNightWeatherBg})` : `url(${imgDayWeatherBg})` }}
        >
            <Carousel draggable autoplay swipeToSlide autoplaySpeed={6500} dots={false} arrows={true} {...settings}>
                {weathers?.map((weather) => (
                    <div key={weather.id} className={styles.weatherId}>
                        <div className={styles.weatherCrop}>
                            <img alt="" key={weather?.dataWeathers[0].dataWeatherId} src={getWeatherImageByKey(weather?.dataWeathers[0]?.icon)} />
                        </div>
                        <div className={styles.weatherSpace}>
                            <span>{getWeatherNameByKey(weather?.name)}</span>
                            <h4>{weather?.main?.temp.toFixed(0)}℃</h4>
                        </div>
                    </div>
                ))}
            </Carousel>
            <span className={`${styles.weatherTime}`}>{date && time && date + " " + time}</span>
        </div>
    );
};

export default Weather;
