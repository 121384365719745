import { useState, useEffect, useCallback } from "react";
import { LockOutlined } from "@ant-design/icons";
import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestUser from "../../utils/requestAuthUser";
import * as RequestCustomer from "../../utils/requestAuthCustomer";

import Input from "antd/es/input/index";
import Modal from "antd/es/modal/index";
import Radio from "antd/es/radio/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type SearchType = {
    username: string;
    name: string;
    accountLocked: string;
    authority: string;
    email: string;
    phone: string;
};

const PageUser = () => {
    const alert = useAlert();
    const userDetails = useAuthState();
    const { Option } = Select;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [type, setType] = useState<viewType>("view");
    const [user, setUser] = useState<TypeDTO.UserDto>();
    const [users, setUsers] = useState<Array<TypeDTO.UserDto>>();
    const [customers, setCustomers] = useState<Array<TypeDTO.CustomerDto>>();
    const [filteredUsers, setFilteredUsers] = useState<Array<TypeDTO.UserDto>>();
    const [isCustomer, setIsCustomer] = useState<boolean>(false);
    const newPassword = "ez" + (user?.phone || "12345678") + "!";

    useEffect(() => {
        requestAuthUserList();
        requestAuthCustomerList();
    }, []);

    const columns: ColumnsType<TypeDTO.UserDto> = [
        {
            title: String.id,
            dataIndex: "username",
            key: "username",
            width: "10%",
            align: "center",
        },
        {
            title: String.name,
            dataIndex: "name",
            key: "name",
            width: "12%",
            align: "center",
            render: (_, { name }) => name || String.dash,
        },
        {
            title: String.phone,
            dataIndex: "phone",
            key: "phone",
            width: "13%",
            align: "center",
            render: (_, { phone }) => Utils.convertPhone(phone) || String.dash,
        },
        {
            title: String.email,
            dataIndex: "email",
            key: "email",
            width: "10%",
            align: "center",
            render: (_, { email }) => email || String.dash,
        },
        {
            title: String.authority,
            dataIndex: "authority",
            key: "authority",
            width: "10%",
            align: "center",
            render: (_, { authority }) =>
                Utils.convertAuthority[authority as "ROLE_ADMIN" | "ROLE_MANAGER" | "ROLE_CUSTOMER_MANAGER" | "ROLE_CUSTOMER" | "ROLE_USER"] ||
                String.dash,
        },
        {
            title: String.customer,
            dataIndex: ["customer", "customerName"],
            key: "customer",
            width: "13%",
            align: "center",
            render: (_, { customer }) => customer?.customerName || String.dash,
        },
        {
            title: String.accountLocked,
            dataIndex: "accountLocked",
            key: "accountLocked",
            width: "10%",
            align: "center",
            render: (_, { accountLocked }) => {
                return accountLocked ? "잠금" : String.active;
            },
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRow("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.username, row.name);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const getValueByKey = (key: string) => {
        if (key in Utils.convertAuthority) {
            return Utils.convertAuthority[key as keyof typeof Utils.convertAuthority];
        }
        return "";
    };

    const validatePwd = useCallback((_: any, value: string) => {
        const pwd_regExp = Common.PASSWORD_REGEXP;

        if (value && !pwd_regExp.test(value)) {
            return Promise.reject(new Error(String.msgPwdRule));
        }
        return Promise.resolve();
    }, []);

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "username",
            span: 6,
            title: String.id,
            type: "input",
        },
        {
            id: "name",
            span: 6,
            title: String.name,
            type: "input",
        },
        {
            id: "accountLocked",
            span: 6,
            title: String.accountStatus,
            type: "select",
            options: [
                { value: true, label: String.accountLocked },
                { value: false, label: String.active },
            ],
        },
        {
            id: "authority",
            span: 6,
            title: String.authority,
            type: "select",
            options: Object.keys(Utils.convertAuthority).map((key) => ({ value: key, label: getValueByKey(key) })),
        },
        {
            id: "email",
            span: 12,
            title: String.email,
            type: "input",
        },
        {
            id: "phone",
            span: 12,
            title: String.phone,
            type: "input",
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        {
            name: "username",
            label: String.id,
            span: 12,
            required: true,
        },
        {
            name: "name",
            label: String.name,
            span: 12,
            required: true,
        },
        {
            name: "password",
            label: String.password,
            span: 12,
            input: (
                <Input.Password
                    name="password"
                    className={`fw-rg fs-md fc-font2 ${type === "view" ? "disabled-password" : ""}`}
                    placeholder="비밀번호를 입력해 주세요."
                    disabled={type === "view"}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: type === "register",
            rules: [{ validator: validatePwd }, { max: 20, message: String.msgPwdMax }],
        },
        {
            name: "confirm",
            label: String.passwordConfirm,
            span: 12,
            input: (
                <Input.Password
                    name="confirm"
                    className={`fw-rg fs-md fc-font2 ${type === "view" ? "disabled-password" : ""}`}
                    placeholder="비밀번호를 한번 더 입력해 주세요."
                    disabled={type === "view"}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: type === "register",
            rules: [
                ({ getFieldValue }: any) => ({
                    validator(_: any, value: any) {
                        if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                        }
                        return Promise.reject(new Error("입력한 비밀번호가 일치하지 않습니다."));
                    },
                }),
            ],
            dependencies: ["password"],
            hasFeedback: true,
        },
        {
            name: "phone",
            label: String.phone,
            span: 12,
            required: true,
            rules: [
                {
                    pattern: Common.PHONE_REGEXP,
                    message: "유효한 전화번호 형식이 아닙니다",
                },
            ],
            input: <Input className={type === "view" ? "disabled-input" : ""} disabled={type === "view"} />,
        },
        {
            name: "email",
            label: String.email,
            span: 12,
            rules: [
                {
                    type: "email",
                    message: "이메일이 유효하지 않습니다.",
                },
            ],
        },
        {
            name: "authority",
            label: String.authority,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    className={type === "view" ? "disabled-selector" : ""}
                    placeholder="권한을 선택해 주세요."
                    optionFilterProp="children"
                    disabled={type === "view"}
                    onChange={(value) => setUser({ ...user, authority: value, customer: undefined })}
                >
                    {Object.keys(Utils.convertAuthority).map((key) => (
                        <Option key={key} value={key}>
                            {getValueByKey(key)}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: ["customer", "customerId"],
            label: String.customer,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    className={type === "view" ? "disabled-selector" : ""}
                    placeholder="고객을 선택해 주세요."
                    disabled={type === "view" || !isCustomer}
                    optionFilterProp="children"
                >
                    {customers?.map((customer) => (
                        <Option key={customer.customerId} value={customer.customerId}>
                            {customer.customerName}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            name: "accountLocked",
            label: String.accountLocked,
            span: 24,
            input: (
                <Radio.Group
                    disabled={type === "view"}
                    options={[
                        {
                            value: true,
                            label: "잠금",
                        },
                        {
                            value: false,
                            label: "정상",
                        },
                    ]}
                />
            ),
        },
    ];

    const { requestAuthCustomerList, resultAuthCustomerList } = RequestCustomer.useRequestAuthCustomerList();

    useEffect(() => {
        if (!resultAuthCustomerList) return;

        setCustomers(resultAuthCustomerList.customers);
    }, [resultAuthCustomerList]);

    const { loadingAuthUserList, requestAuthUserList, resultAuthUserList } = RequestUser.useRequestAuthUserList();

    useEffect(() => {
        if (!resultAuthUserList) return;

        setUsers(resultAuthUserList.users);
    }, [resultAuthUserList]);

    const { requestAuthUserRegister, resultAuthUserRegister } = RequestUser.useRequestAuthUserRegister();

    useEffect(() => {
        if (!resultAuthUserRegister) return;

        onResult("register");
    }, [resultAuthUserRegister]);

    const { requestAuthUserUpdate, resultAuthUserUpdate } = RequestUser.useRequestAuthUserUpdate();

    useEffect(() => {
        if (!resultAuthUserUpdate) return;

        onResult("edit");
    }, [resultAuthUserUpdate]);

    const { requestAuthUserDelete, resultAuthUserDelete } = RequestUser.useRequestAuthUserDelete();

    useEffect(() => {
        if (!resultAuthUserDelete) return;

        onResult("delete");
    }, [resultAuthUserDelete]);

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;
        setFilteredUsers(
            users?.filter(
                (user) =>
                    searchFilter(user, value?.username, "username") &&
                    searchFilter(user, value?.name, "name") &&
                    searchFilter(user, value?.accountLocked, "accountLocked") &&
                    searchFilter(user, value?.authority, "authority") &&
                    searchFilter(user, value?.email, "email") &&
                    searchFilter(user, value?.phone, "phone")
            )
        );
    };

    const getIsCustomer = (authority?: Utils.getAuthority) => {
        setIsCustomer(authority === Utils.getAuthority.ROLE_CUSTOMER || authority === Utils.getAuthority.ROLE_CUSTOMER_MANAGER);
    };

    const onRow = (type: viewType, value?: TypeDTO.UserDto) => {
        const user = { ...value, accountLocked: !!value?.accountLocked } as TypeDTO.UserDto;

        getIsCustomer(value?.authority as Utils.getAuthority);
        setUser(user);
        setIsOpen(true);
        setType(type);
    };

    const onDelete = (username: string, name: string) => {
        Modal.confirm({
            title: String.user + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestAuthUserDelete(username);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinish = (type: viewType, value: TypeDTO.UserDto) => {
        type === "register"
            ? requestAuthUserRegister({
                  ...value,
                  customer: value?.customer.customerId ? { customerId: value?.customer.customerId } : undefined,
                  phone: value?.phone?.replaceAll("-", ""),
              })
            : requestAuthUserUpdate(user?.username, {
                  ...value,
                  phone: value?.phone?.replaceAll("-", ""),
              });
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.user} ${typeText} 성공`, `${String.user} 정보를 ${typeText}하였습니다.`);
        requestAuthUserList();

        type !== "delete" && setIsOpen(false);
    };

    const onResetPassword = (user: TypeDTO.UserDto) => {
        Modal.confirm({
            title: "비밀번호 초기화",
            content: (
                <Space size={4} direction="vertical" style={{ textAlign: "center", width: "100%" }}>
                    <p className="fw-rg fs-md" style={{ margin: 0 }}>
                        비밀번호를 초기화 하시겠습니까?
                    </p>
                    <p className="fw-rg fs-md" style={{ margin: 0 }}>
                        초기화 비밀번호 : {newPassword}
                    </p>
                </Space>
            ),
            okText: String.confirm,
            onOk() {
                requestAuthUserUpdate(user.username, { password: newPassword });
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    return (
        <div className="pageWrapper">
            <Search
                title={`${String.user} 목록`}
                dataLength={filteredUsers?.length || users?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? () => onRow("register", undefined) : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.UserDto) => row.username}
                disabledTitle
                loading={loadingAuthUserList}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={filteredUsers || users}
                onRow={(value) => onRow("view", value)}
            />
            <CustomModal
                title={String.user}
                open={isOpen}
                type={type}
                value={type === "view" ? { ...user, phone: Utils.convertPhone(user?.phone) } : user}
                hasButton="password"
                contents={type !== "register" ? contents.filter((item) => item.name !== "password" && item.name !== "confirm") : contents}
                onChangedOpen={() => setIsOpen(false)}
                onChangedType={(value) => setType(value)}
                onFinish={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? onFinish : undefined}
                onValuesChange={(value: TypeDTO.UserDto) => getIsCustomer(value?.authority as Utils.getAuthority)}
                onClick={() => {
                    setIsOpen(false);
                    user && onResetPassword(user);
                }}
            />
        </div>
    );
};

export default PageUser;
