import { useState, useEffect } from "react";
import { CustomOverlayMap, Map, MapTypeControl } from "react-kakao-maps-sdk";
import Badge from "antd/es/badge/index";

import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import CircleBatteryLow from "../../assets/images/marker/circle_battery_low.png";
import CircleBatteryVeryLow from "../../assets/images/marker/circle_battery_very_low.png";

import styles from "./KakaoMap.module.css";
import moment from "moment";

export type runningHistoryMarkers = {
    runningPath?: runningPathType;
    markers?: mapMarker[];
    selected?: string;
};

export type runningPathType = {
    line: mapPolyline;
    markers: mapMarker[];
};

export type mapPolyline = {
    key: string;
    paths: gpsType[];
    strokeWeight: number;
    strokeColor: string;
    strokeOpacity: number;
};

export type mapMarker = {
    key: string;
    position: gpsType;
    imageSrc: string;
    zIndex?: number;
    overlayContent?: string;
    checkAbnormal?: boolean;
};

export type gpsType = {
    lat: number;
    lng: number;
};

const KakaoMap = ({
    className,
    children,
    mapStyle,
    mapBounds,
    mapCenter,
    mapZoom,
    maxZoom,
    cars,
    selectedCarId,
    mapTypeControl,
    onClick,
    onChange,
    onZoom,
}: {
    className?: string;
    children?: React.ReactNode;
    mapStyle?: React.CSSProperties;
    mapBounds?: kakao.maps.LatLngBounds;
    mapCenter?: gpsType;
    mapZoom?: number;
    maxZoom?: number;
    cars?: Array<any>;
    selectedCarId?: number;
    mapTypeControl?: boolean;
    onClick: (carId: number) => void;
    onChange?: (value: gpsType) => void;
    onZoom?: (value: number) => void;
}) => {
    const [center, setCenter] = useState<gpsType>({ lat: mapCenter?.lat || 35.85810060700929, lng: mapCenter?.lng || 128.55729938820272 });
    const [zoom, setZoom] = useState<number>(11);
    const [kakaoMap, setKakaoMap] = useState<kakao.maps.Map | undefined>(undefined);

    useEffect(() => {
        mapCenter && setCenter(mapCenter);
    }, [mapCenter]);

    useEffect(() => {
        mapZoom && setZoom(mapZoom);
    }, [mapZoom]);

    useEffect(() => {
        if (kakaoMap && mapBounds && mapBounds.isEmpty() === false) {
            kakaoMap.setBounds(mapBounds);
        }
    }, [kakaoMap, mapBounds]);

    const getIndex = (carId: number, lastRunningHistory: TypeDTO.RunningHistoryDto) => {
        if (carId === selectedCarId) return 999;
        if (lastRunningHistory?.errorCount) return 998;
        if (lastRunningHistory?.warningCount) return 997;
        if (lastRunningHistory?.endSoc <= 20) return 996;
        if (lastRunningHistory?.endSoc <= 40) return 995;
        if (lastRunningHistory?.finish === false) return 994;
        return 993;
    };

    const displayMarkers = () => {
        return cars?.map((car) => {
            const lastRunningHistory = car?.runningSummary?.lastRunningHistory;
            const errorCnt = car?.alarmCountByCar?.errorCount;
            const warningCnt = car?.alarmCountByCar?.warningCount;
            const checkAbnormal =
                car?.runningSummary?.lastRunningHistory?.finish !== true &&
                moment(car?.runningSummary?.lastRunningHistory?.endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));

            return (
                <CustomOverlayMap
                    key={car.carId}
                    position={{
                        lat: lastRunningHistory?.latitude,
                        lng: lastRunningHistory?.longitude,
                    }}
                    zIndex={getIndex(car.carId, lastRunningHistory)}
                >
                    <div className={styles.markerWrapper} onClick={() => onClick(car.carId)}>
                        <div
                            className={
                                checkAbnormal || car?.runningSummary?.lastRunningHistory?.finish === true
                                    ? `${styles.marker} ${styles.markerDriveEnd}`
                                    : `${styles.marker} ${styles.markerDrive}`
                            }
                        >
                            {lastRunningHistory?.endSoc <= 20 ? (
                                <img alt="" className={styles.markerBattery} src={CircleBatteryVeryLow} />
                            ) : lastRunningHistory?.endSoc <= 40 ? (
                                <img alt="" className={styles.markerBattery} src={CircleBatteryLow} />
                            ) : (
                                ""
                            )}
                            <div className={styles.badgeWrapper} style={{ display: !warningCnt && !errorCnt ? "none" : "" }}>
                                <Badge className="fw-bd fs-xs" style={{ display: !errorCnt ? "none" : "" }} color="var(--danger)" count={errorCnt} />

                                <Badge
                                    className="fw-bd fs-xs"
                                    style={{ display: !warningCnt ? "none" : "", color: "var(--font1)" }}
                                    color="var(--warning)"
                                    count={warningCnt}
                                />
                            </div>
                        </div>
                        {car.carId === selectedCarId && (
                            <div className={styles.wrap}>
                                <div className={styles.info}>
                                    <p className={styles.text}>{car.carNumber}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </CustomOverlayMap>
            );
        });
    };

    return (
        <Map
            className={className}
            center={center}
            isPanto={true}
            style={mapStyle}
            scrollwheel={true}
            level={zoom}
            maxLevel={maxZoom ? maxZoom : 12}
            onCreate={(map) => setKakaoMap(map)}
            onZoomChanged={(e) => onZoom && onZoom(e.getLevel())}
            onCenterChanged={(map) => onChange && onChange({ lat: map.getCenter().getLat(), lng: map.getCenter().getLng() })}
        >
            {mapTypeControl !== false && <MapTypeControl position="TOPRIGHT" />}
            {displayMarkers()}
            {children}
        </Map>
    );
};

export default KakaoMap;
