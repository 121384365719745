import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiModemRegister = () => {
    const requestAxiosApiModemRegister = async (data: TypeDTO.TerminalModemDto) => {
        const response = await utilAxiosWithAuth().post(Request.MODEM_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiModemRegister,
        error: errorApiModemRegister,
        data: resultApiModemRegister,
        execute: requestApiModemRegister,
    } = useAsyncAxios(requestAxiosApiModemRegister);

    useEffect(() => {
        if (resultApiModemRegister === null) return;
        DEBUG && console.log("resultApiModemRegister", resultApiModemRegister);
    }, [resultApiModemRegister]);

    useEffect(() => {
        if (errorApiModemRegister === null) return;
        DEBUG && console.log("errorApiModemRegister", errorApiModemRegister);
    }, [errorApiModemRegister]);

    return {
        loadingApiModemRegister,
        errorApiModemRegister,
        resultApiModemRegister,
        requestApiModemRegister,
    };
};

export const useRequestApiModemList = () => {
    const requestAxiosApiModemList = async () => {
        const response = await utilAxiosWithAuth().get(Request.MODEMS_URL);

        return response.data;
    };

    const {
        loading: loadingApiModemList,
        error: errorApiModemList,
        data: resultApiModemList,
        execute: requestApiModemList,
    } = useAsyncAxios(requestAxiosApiModemList);

    useEffect(() => {
        if (resultApiModemList === null) return;
        DEBUG && console.log("resultApiModemList", resultApiModemList);
    }, [resultApiModemList]);

    useEffect(() => {
        if (errorApiModemList === null) return;
        DEBUG && console.log("errorApiModemList", errorApiModemList);
    }, [errorApiModemList]);

    return {
        loadingApiModemList,
        errorApiModemList,
        resultApiModemList,
        requestApiModemList,
    };
};

export const useRequestApiModemDeleteList = () => {
    const requestAxiosApiModemDeleteList = async (modemIds: number[]) => {
        const response = await utilAxiosWithAuth().delete(Request.MODEMS_URL, { data: modemIds });

        return response.data;
    };

    const {
        loading: loadingApiModemDeleteList,
        error: errorApiModemDeleteList,
        data: resultApiModemDeleteList,
        execute: requestApiModemDeleteList,
    } = useAsyncAxios(requestAxiosApiModemDeleteList);

    useEffect(() => {
        if (resultApiModemDeleteList === null) return;
        DEBUG && console.log("resultApiModemDeleteList", resultApiModemDeleteList);
    }, [resultApiModemDeleteList]);

    useEffect(() => {
        if (errorApiModemDeleteList === null) return;
        DEBUG && console.log("errorApiModemDeleteList", errorApiModemDeleteList);
    }, [errorApiModemDeleteList]);

    return {
        loadingApiModemDeleteList,
        errorApiModemDeleteList,
        resultApiModemDeleteList,
        requestApiModemDeleteList,
    };
};

export const useRequestApiModemInfo = () => {
    const requestAxiosApiModemInfo = async (modemId: number) => {
        const response = await utilAxiosWithAuth().get(Request.MODEM_URL + modemId);

        return response.data;
    };

    const {
        loading: loadingApiModemInfo,
        error: errorApiModemInfo,
        data: resultApiModemInfo,
        execute: requestApiModemInfo,
    } = useAsyncAxios(requestAxiosApiModemInfo);

    useEffect(() => {
        if (resultApiModemInfo === null) return;
        DEBUG && console.log("resultApiModemInfo", resultApiModemInfo);
    }, [resultApiModemInfo]);

    useEffect(() => {
        if (errorApiModemInfo === null) return;
        DEBUG && console.log("errorApiModemInfo", errorApiModemInfo);
    }, [errorApiModemInfo]);

    return {
        loadingApiModemInfo,
        errorApiModemInfo,
        resultApiModemInfo,
        requestApiModemInfo,
    };
};

export const useRequestApiModemUpdate = () => {
    const requestAxiosApiModemUpdate = async (modemId: number, data: TypeDTO.TerminalModemDto) => {
        const response = await utilAxiosWithAuth().put(Request.MODEM_URL + modemId, data);

        return response.data;
    };

    const {
        loading: loadingApiModemUpdate,
        error: errorApiModemUpdate,
        data: resultApiModemUpdate,
        execute: requestApiModemUpdate,
    } = useAsyncAxios(requestAxiosApiModemUpdate);

    useEffect(() => {
        if (resultApiModemUpdate === null) return;
        DEBUG && console.log("resultApiModemUpdate", resultApiModemUpdate);
    }, [resultApiModemUpdate]);

    useEffect(() => {
        if (errorApiModemUpdate === null) return;
        DEBUG && console.log("errorApiModemUpdate", errorApiModemUpdate);
    }, [errorApiModemUpdate]);

    return {
        loadingApiModemUpdate,
        errorApiModemUpdate,
        resultApiModemUpdate,
        requestApiModemUpdate,
    };
};

export const useRequestApiModemDelete = () => {
    const requestAxiosApiModemDelete = async (modemId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.MODEM_URL + modemId);

        return response.data;
    };

    const {
        loading: loadingApiModemDelete,
        error: errorApiModemDelete,
        data: resultApiModemDelete,
        execute: requestApiModemDelete,
    } = useAsyncAxios(requestAxiosApiModemDelete);

    useEffect(() => {
        if (resultApiModemDelete === null) return;
        DEBUG && console.log("resultApiModemDelete", resultApiModemDelete);
    }, [resultApiModemDelete]);

    useEffect(() => {
        if (errorApiModemDelete === null) return;
        DEBUG && console.log("errorApiModemDelete", errorApiModemDelete);
    }, [errorApiModemDelete]);

    return {
        loadingApiModemDelete,
        errorApiModemDelete,
        resultApiModemDelete,
        requestApiModemDelete,
    };
};
