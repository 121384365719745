import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestAuthCustomerRegister = () => {
    const requestAxiosAuthCustomerRegister = async (data: TypeDTO.CustomerDto) => {
        const response = await utilAxiosWithAuth().post(Request.CUSTOMER_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerRegister,
        error: errorAuthCustomerRegister,
        data: resultAuthCustomerRegister,
        execute: requestAuthCustomerRegister,
    } = useAsyncAxios(requestAxiosAuthCustomerRegister);

    useEffect(() => {
        if (resultAuthCustomerRegister === null) return;
        DEBUG && console.log("resultAuthCustomerRegister", resultAuthCustomerRegister);
    }, [resultAuthCustomerRegister]);

    useEffect(() => {
        if (errorAuthCustomerRegister === null) return;
        DEBUG && console.log("errorAuthCustomerRegister", errorAuthCustomerRegister);
    }, [errorAuthCustomerRegister]);

    return {
        loadingAuthCustomerRegister,
        errorAuthCustomerRegister,
        resultAuthCustomerRegister,
        requestAuthCustomerRegister,
    };
};

export const useRequestAuthCustomerList = () => {
    const requestAxiosAuthCustomerList = async () => {
        const response = await utilAxiosWithAuth().get(Request.CUSTOMERS_URL);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerList,
        error: errorAuthCustomerList,
        data: resultAuthCustomerList,
        execute: requestAuthCustomerList,
    } = useAsyncAxios(requestAxiosAuthCustomerList);

    useEffect(() => {
        if (resultAuthCustomerList === null) return;
        DEBUG && console.log("resultAuthCustomerList", resultAuthCustomerList);
    }, [resultAuthCustomerList]);

    useEffect(() => {
        if (errorAuthCustomerList === null) return;
        DEBUG && console.log("errorAuthCustomerList", errorAuthCustomerList);
    }, [errorAuthCustomerList]);

    return {
        loadingAuthCustomerList,
        errorAuthCustomerList,
        resultAuthCustomerList,
        requestAuthCustomerList,
    };
};

export const useRequestAuthCustomerDeleteList = () => {
    const requestAxiosAuthCustomerDeleteList = async (customerIds: number[]) => {
        const response = await utilAxiosWithAuth().delete(Request.CUSTOMERS_URL, { data: customerIds });

        return response.data;
    };

    const {
        loading: loadingAuthCustomerDeleteList,
        error: errorAuthCustomerDeleteList,
        data: resultAuthCustomerDeleteList,
        execute: requestAuthCustomerDeleteList,
    } = useAsyncAxios(requestAxiosAuthCustomerDeleteList);

    useEffect(() => {
        if (resultAuthCustomerDeleteList === null) return;
        DEBUG && console.log("resultAuthCustomerDeleteList", resultAuthCustomerDeleteList);
    }, [resultAuthCustomerDeleteList]);

    useEffect(() => {
        if (errorAuthCustomerDeleteList === null) return;
        DEBUG && console.log("errorAuthCustomerDeleteList", errorAuthCustomerDeleteList);
    }, [errorAuthCustomerDeleteList]);

    return {
        loadingAuthCustomerDeleteList,
        errorAuthCustomerDeleteList,
        resultAuthCustomerDeleteList,
        requestAuthCustomerDeleteList,
    };
};

export const useRequestAuthCustomerInfo = () => {
    const requestAxiosAuthCustomerInfo = async (customerId: number) => {
        const response = await utilAxiosWithAuth().get(Request.CUSTOMER_URL + customerId);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerInfo,
        error: errorAuthCustomerInfo,
        data: resultAuthCustomerInfo,
        execute: requestAuthCustomerInfo,
    } = useAsyncAxios(requestAxiosAuthCustomerInfo);

    useEffect(() => {
        if (resultAuthCustomerInfo === null) return;
        DEBUG && console.log("resultAuthCustomerInfo", resultAuthCustomerInfo);
    }, [resultAuthCustomerInfo]);

    useEffect(() => {
        if (errorAuthCustomerInfo === null) return;
        DEBUG && console.log("errorAuthCustomerInfo", errorAuthCustomerInfo);
    }, [errorAuthCustomerInfo]);

    return {
        loadingAuthCustomerInfo,
        errorAuthCustomerInfo,
        resultAuthCustomerInfo,
        requestAuthCustomerInfo,
    };
};

export const useRequestAuthCustomerUpdate = () => {
    const requestAxiosAuthCustomerUpdate = async (customerId: number, data: TypeDTO.CustomerDto) => {
        const response = await utilAxiosWithAuth().put(Request.CUSTOMER_URL + customerId, data);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerUpdate,
        error: errorAuthCustomerUpdate,
        data: resultAuthCustomerUpdate,
        execute: requestAuthCustomerUpdate,
    } = useAsyncAxios(requestAxiosAuthCustomerUpdate);

    useEffect(() => {
        if (resultAuthCustomerUpdate === null) return;
        DEBUG && console.log("resultAuthCustomerUpdate", resultAuthCustomerUpdate);
    }, [resultAuthCustomerUpdate]);

    useEffect(() => {
        if (errorAuthCustomerUpdate === null) return;
        DEBUG && console.log("errorAuthCustomerUpdate", errorAuthCustomerUpdate);
    }, [errorAuthCustomerUpdate]);

    return {
        loadingAuthCustomerUpdate,
        errorAuthCustomerUpdate,
        resultAuthCustomerUpdate,
        requestAuthCustomerUpdate,
    };
};

export const useRequestAuthCustomerDelete = () => {
    const requestAxiosAuthCustomerDelete = async (customerId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.CUSTOMER_URL + customerId);

        return response.data;
    };

    const {
        loading: loadingAuthCustomerDelete,
        error: errorAuthCustomerDelete,
        data: resultAuthCustomerDelete,
        execute: requestAuthCustomerDelete,
    } = useAsyncAxios(requestAxiosAuthCustomerDelete);

    useEffect(() => {
        if (resultAuthCustomerDelete === null) return;
        DEBUG && console.log("resultAuthCustomerDelete", resultAuthCustomerDelete);
    }, [resultAuthCustomerDelete]);

    useEffect(() => {
        if (errorAuthCustomerDelete === null) return;
        DEBUG && console.log("errorAuthCustomerDelete", errorAuthCustomerDelete);
    }, [errorAuthCustomerDelete]);

    return {
        loadingAuthCustomerDelete,
        errorAuthCustomerDelete,
        resultAuthCustomerDelete,
        requestAuthCustomerDelete,
    };
};
