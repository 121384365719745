import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthDispatch } from "../../provider/AuthProvider";

import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as RequestLogin from "../../utils/requestAuthLogin";

declare const window: typeof globalThis & {
    Android: any;
};

function Welcome() {
    const navigate = useNavigate();
    const dispatch = useAuthDispatch();

    const [loginInfo, setLoginInfo] = useState<{ username: string; password: string }>();

    useEffect(() => {
        const result = Utils.isPossibleAutoLogin();
        if (result.isPossible && result.username && result.password) {
            setLoginInfo({ username: result.username, password: result.password });
        } else {
            setLoginInfo({ username: "", password: "" });
        }

        window.addEventListener("backPressEvent", onBackPressEvent);
        return () => {
            window.removeEventListener("backPressEvent", onBackPressEvent);
        };
    }, []);

    useEffect(() => {
        if (!loginInfo) return;

        loginInfo.username.length > 0 && loginInfo.password.length > 0 ? requestAuthLogin(loginInfo) : navigate(Common.PAGE_LOGIN);
    }, [loginInfo]);

    const { requestAuthLogin, resultAuthLogin, errorAuthLogin } = RequestLogin.useRequestAuthLogin();

    useEffect(() => {
        if (!resultAuthLogin) return;

        if (dispatch) {
            dispatch({
                type: "LOGIN_SUCCESS",
                payload: {
                    user: resultAuthLogin.user,
                    token: resultAuthLogin.token,
                },
            });
        }

        Utils.sendFCMToken({
            user: resultAuthLogin.user,
            token: resultAuthLogin.token,
        });

        navigate(Common.PAGE_DASHBOARD, { replace: true });
    }, [resultAuthLogin]);

    useEffect(() => {
        if (!errorAuthLogin) return;

        navigate(Common.PAGE_LOGIN, { replace: true });
    }, [errorAuthLogin]);

    const onBackPressEvent = () => {
        window.Android.closeAppOnBackPress();
    };

    return <div />;
}

export default Welcome;
