import { useState, useEffect } from "react";
import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestSwVer from "../../utils/requestApiSwVer";
import * as RequestMaker from "../../utils/requestApiMaker";

import Button from "antd/es/button/button";
import DatePicker from "antd/es/date-picker/index";
import Modal from "antd/es/modal/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import DataTable from "../../components/common/DataTable";
import CustomModal from "../../components/common/CustomModal";
import dayjs from "dayjs";
import Search from "../../components/browser/Search";
import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type SearchType = {
    makerName: string;
    makerModelId: string;
};

const PageSwVer = () => {
    const alert = useAlert();
    const userDetails = useAuthState();
    const BMS = "BMS";
    const VCU = "VCU";
    const { Option } = Select;

    const [type, setType] = useState<viewType>("view");
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>();
    const [selectedMakerId, setSelectedMakerId] = useState<number>();
    const [selectedMakerName, setSelectedMakerName] = useState<string>();

    const [isOpenBms, setIsOpenBms] = useState<boolean>(false);
    const [isOpenVcu, setIsOpenVcu] = useState<boolean>(false);
    const [bmsVer, setBmsVer] = useState<TypeDTO.SwVerDto>();
    const [bmsVers, setBmsVers] = useState<Array<TypeDTO.SwVerDto>>();
    const [filteredBmsVers, setFilteredBmsVers] = useState<Array<TypeDTO.SwVerDto>>();

    const [vcuVer, setVcuVer] = useState<TypeDTO.SwVerDto>();
    const [vcuVers, setVcuVers] = useState<Array<TypeDTO.SwVerDto>>();
    const [filteredVcuVers, setFilteredVcuVers] = useState<Array<TypeDTO.SwVerDto>>();

    useEffect(() => {
        requestApiSwVerList();
        requestApiMakerList();
    }, []);

    const onChange = (value: number) => {
        setSelectedMakerId(value);

        const updateVer = (ver: any) => ({
            ...ver,
            makerModel: {
                makerModelId: undefined,
                makerModelName: undefined,
                maker: { ...ver?.makerModel?.maker, makerId: value },
                deleted: undefined,
            },
        });

        if (isOpenBms) {
            setBmsVer((ver: any) => updateVer(ver));
        } else if (isOpenVcu) {
            setVcuVer((ver: any) => updateVer(ver));
        }
    };

    const bmsColumns: ColumnsType<TypeDTO.SwVerDto> = [
        {
            title: String.maker,
            dataIndex: ["makerModel", "maker", "makerName"],
            key: "maker",
            align: "center",
            width: "10%",
        },
        {
            title: String.model,
            dataIndex: ["makerModel", "makerModelName"],
            key: "model",
            align: "center",
            width: "10%",
        },
        {
            title: String.version,
            dataIndex: "swVer",
            key: "swVer",
            align: "center",
            width: "10%",
        },
        {
            title: String.versionDate,
            dataIndex: "releaseDate",
            key: "releaseDate",
            align: "center",
            width: "10%",
            render: (_, { releaseDate }) => releaseDate || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            align: "center",
            width: "10%",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowBms("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.swVerId, row.swVer);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const vcuColumns: ColumnsType<TypeDTO.SwVerDto> = [
        {
            title: String.maker,
            dataIndex: ["makerModel", "maker", "makerName"],
            key: "maker",
            align: "center",
            width: "10%",
        },
        {
            title: String.model,
            dataIndex: ["makerModel", "makerModelName"],
            key: "model",
            align: "center",
            width: "10%",
        },
        {
            title: String.version,
            dataIndex: "swVer",
            key: "swVer",
            width: "10%",
            align: "center",
        },
        {
            title: String.versionDate,
            dataIndex: "releaseDate",
            key: "releaseDate",
            width: "10%",
            align: "center",
            render: (_, { releaseDate }) => releaseDate || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={16}>
                        <img
                            alt=""
                            className="btn-icon"
                            src={IconEdit}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowVcu("edit", row);
                            }}
                            width={20}
                        />
                        <img
                            alt=""
                            className="btn-icon"
                            src={IconDelete}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.swVerId, row.swVer);
                            }}
                            width={20}
                        />
                    </Space>
                );
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "makerName",
            span: 12,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "makerModelId",
            span: 12,
            title: String.model,
            type: "select",
            typeDetail: "model",
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        {
            name: ["makerModel", "maker", "makerId"],
            label: String.maker,
            span: 24,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="제조사를 선택해 주세요."
                    onChange={onChange}
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                >
                    {makers?.map((maker) => (
                        <Option key={maker.makerId} value={maker.makerId}>
                            {maker.makerName}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: ["makerModel", "makerModelId"],
            label: String.carModel,
            span: 24,
            required: true,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="차량모델을 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view" || !selectedMakerId}
                    optionFilterProp="children"
                >
                    {makers
                        ?.find((maker) => maker.makerId === selectedMakerId)
                        ?.makerModels?.map((model) => (
                            <Option key={model.makerModelId} value={model.makerModelId}>
                                {model.makerModelName}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            name: "swVer",
            label: String.version,
            span: 24,
            required: true,
        },
        {
            name: "releaseDate",
            label: String.versionDate,
            span: 24,
            input: <DatePicker className={type === "view" ? "disabled-datepicker" : ""} disabled={type === "view"} />,
        },
        {
            name: "description",
            label: String.description,
            span: 24,
        },
    ];

    const { loadingApiSwVerList, requestApiSwVerList, resultApiSwVerList } = RequestSwVer.useRequestApiSwVerList();

    useEffect(() => {
        if (!resultApiSwVerList) return;

        const swVers = resultApiSwVerList.swVers;
        setBmsVers(swVers.filter((ver: TypeDTO.SwVerDto) => ver.swType === BMS));
        setVcuVers(swVers.filter((ver: TypeDTO.SwVerDto) => ver.swType === VCU));
    }, [resultApiSwVerList]);

    const { requestApiSwVerRegister, resultApiSwVerRegister } = RequestSwVer.useRequestApiSwVerRegister();

    useEffect(() => {
        if (!resultApiSwVerRegister) return;

        onResult("register");
    }, [resultApiSwVerRegister]);

    const { requestApiSwVerUpdate, resultApiSwVerUpdate } = RequestSwVer.useRequestApiSwVerUpdate();

    useEffect(() => {
        if (!resultApiSwVerUpdate) return;

        onResult("edit");
    }, [resultApiSwVerUpdate]);

    const { requestApiSwVerDelete, resultApiSwVerDelete } = RequestSwVer.useRequestApiSwVerDelete();

    useEffect(() => {
        if (!resultApiSwVerDelete) return;

        onResult("delete");
    }, [resultApiSwVerDelete]);

    const { requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    const onRowBms = (type: viewType, value?: TypeDTO.SwVerDto) => {
        setBmsVer(value);
        setSelectedMakerId(value?.makerModel?.maker?.makerId);

        setIsOpenBms(true);
        setType(type);
    };

    const onRowVcu = (type: viewType, value?: TypeDTO.SwVerDto) => {
        setVcuVer(value);
        setSelectedMakerId(value?.makerModel?.maker?.makerId);

        setIsOpenVcu(true);
        setType(type);
    };

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;

        setSelectedMakerName(value?.makerName);
        setFilteredBmsVers(
            bmsVers?.filter(
                (version) =>
                    searchFilter(version?.makerModel?.maker, value.makerName, "makerName") &&
                    searchFilter(version.makerModel, value?.makerModelId, "makerModelId")
            )
        );

        setFilteredVcuVers(
            vcuVers?.filter(
                (version) =>
                    searchFilter(version?.makerModel?.maker, value.makerName, "makerName") &&
                    searchFilter(version.makerModel, value?.makerModelId, "makerModelId")
            )
        );
    };

    const onDelete = (id: number, version: string) => {
        Modal.confirm({
            title: String.version + String.remove,
            content: Utils.addParticle(version, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiSwVerDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinishBms = (type: viewType, value: TypeDTO.SwVerDto) => {
        const searchValue = { ...value, swType: BMS, releaseDate: dayjs(value.releaseDate).format(Common.FORMAT_DATE) };
        type === "register" ? requestApiSwVerRegister(searchValue) : requestApiSwVerUpdate(bmsVer?.swVerId, searchValue);
    };

    const onFinishVcu = (type: viewType, value: TypeDTO.SwVerDto) => {
        const searchValue = { ...value, swType: VCU, releaseDate: dayjs(value.releaseDate).format(Common.FORMAT_DATE) };
        type === "register" ? requestApiSwVerRegister(searchValue) : requestApiSwVerUpdate(vcuVer?.swVerId, searchValue);
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.version} ${typeText} 성공`, `${String.version} 정보를 ${typeText}하였습니다.`);
        requestApiSwVerList();

        if (type !== "delete") {
            setIsOpenBms(false);
            setIsOpenVcu(false);
        }
    };

    return (
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
            <div className="pageWrapper">
                <Search
                    title={`${String.swVersion} 목록`}
                    values={search}
                    selectedMakerName={selectedMakerName}
                    onSearch={(value) => onSearchData(value)}
                    onClear={(value) => onSearchData(value)}
                />
            </div>
            <div style={{ width: "100%", display: "flex", gap: 24 }}>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <DataTable
                        title="BMS 버전"
                        rowKey={(row: TypeDTO.SwVerDto) => row.swVerId}
                        button={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                                <Button className="btn-primary" onClick={() => onRowBms("register", undefined)}>
                                    {String.register}
                                </Button>
                            ) : undefined
                        }
                        loading={loadingApiSwVerList}
                        columns={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? bmsColumns
                                : bmsColumns.filter((column) => column.key !== "detail")
                        }
                        dataSource={filteredBmsVers || bmsVers}
                        onRow={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? (value) => onRowBms("view", value)
                                : undefined
                        }
                    />
                </div>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <DataTable
                        title="VCU 버전"
                        rowKey={(row: TypeDTO.SwVerDto) => row.swVerId}
                        button={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                                <Button className="btn-primary" onClick={() => onRowVcu("register", undefined)}>
                                    {String.register}
                                </Button>
                            ) : undefined
                        }
                        loading={loadingApiSwVerList}
                        columns={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? vcuColumns
                                : vcuColumns.filter((column) => column.key !== "detail")
                        }
                        dataSource={filteredVcuVers || vcuVers}
                        onRow={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? (value) => onRowVcu("view", value)
                                : undefined
                        }
                    />
                </div>
                <CustomModal
                    title={String.carVerion}
                    open={isOpenBms}
                    type={type}
                    value={bmsVer}
                    contents={contents}
                    onChangedOpen={() => setIsOpenBms(false)}
                    onChangedType={(value) => setType(value)}
                    onFinish={onFinishBms}
                />
                <CustomModal
                    title={String.carVerion}
                    open={isOpenVcu}
                    type={type}
                    value={vcuVer}
                    contents={contents}
                    onChangedOpen={() => setIsOpenVcu(false)}
                    onChangedType={(value) => setType(value)}
                    onFinish={onFinishVcu}
                />
            </div>
        </Space>
    );
};

export default PageSwVer;
