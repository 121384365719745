import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiMakerRegister = () => {
    const requestAxiosApiMakerRegister = async (data: TypeDTO.MakerDto) => {
        const response = await utilAxiosWithAuth().post(Request.MAKER_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiMakerRegister,
        error: errorApiMakerRegister,
        data: resultApiMakerRegister,
        execute: requestApiMakerRegister,
    } = useAsyncAxios(requestAxiosApiMakerRegister);

    useEffect(() => {
        if (resultApiMakerRegister === null) return;
        DEBUG && console.log("resultApiMakerRegister", resultApiMakerRegister);
    }, [resultApiMakerRegister]);

    useEffect(() => {
        if (errorApiMakerRegister === null) return;
        DEBUG && console.log("errorApiMakerRegister", errorApiMakerRegister);
    }, [errorApiMakerRegister]);

    return {
        loadingApiMakerRegister,
        errorApiMakerRegister,
        resultApiMakerRegister,
        requestApiMakerRegister,
    };
};

export const useRequestApiMakerList = () => {
    const requestAxiosApiMakerList = async () => {
        const response = await utilAxiosWithAuth().get(Request.MAKERS_URL);

        return response.data;
    };

    const {
        loading: loadingApiMakerList,
        error: errorApiMakerList,
        data: resultApiMakerList,
        execute: requestApiMakerList,
    } = useAsyncAxios(requestAxiosApiMakerList);

    useEffect(() => {
        if (resultApiMakerList === null) return;
        DEBUG && console.log("resultApiMakerList", resultApiMakerList);
    }, [resultApiMakerList]);

    useEffect(() => {
        if (errorApiMakerList === null) return;
        DEBUG && console.log("errorApiMakerList", errorApiMakerList);
    }, [errorApiMakerList]);

    return {
        loadingApiMakerList,
        errorApiMakerList,
        resultApiMakerList,
        requestApiMakerList,
    };
};

export const useRequestApiMakerInfo = () => {
    const requestAxiosApiMakerInfo = async (makerId: number) => {
        const response = await utilAxiosWithAuth().get(Request.MAKER_URL + makerId);

        return response.data;
    };

    const {
        loading: loadingApiMakerInfo,
        error: errorApiMakerInfo,
        data: resultApiMakerInfo,
        execute: requestApiMakerInfo,
    } = useAsyncAxios(requestAxiosApiMakerInfo);

    useEffect(() => {
        if (resultApiMakerInfo === null) return;
        DEBUG && console.log("resultApiMakerInfo", resultApiMakerInfo);
    }, [resultApiMakerInfo]);

    useEffect(() => {
        if (errorApiMakerInfo === null) return;
        DEBUG && console.log("errorApiMakerInfo", errorApiMakerInfo);
    }, [errorApiMakerInfo]);

    return {
        loadingApiMakerInfo,
        errorApiMakerInfo,
        resultApiMakerInfo,
        requestApiMakerInfo,
    };
};

export const useRequestApiMakerUpdate = () => {
    const requestAxiosApiMakerUpdate = async (makerId: number, data: TypeDTO.MakerDto) => {
        const response = await utilAxiosWithAuth().put(Request.MAKER_URL + makerId, data);

        return response.data;
    };

    const {
        loading: loadingApiMakerUpdate,
        error: errorApiMakerUpdate,
        data: resultApiMakerUpdate,
        execute: requestApiMakerUpdate,
    } = useAsyncAxios(requestAxiosApiMakerUpdate);

    useEffect(() => {
        if (resultApiMakerUpdate === null) return;
        DEBUG && console.log("resultApiMakerUpdate", resultApiMakerUpdate);
    }, [resultApiMakerUpdate]);

    useEffect(() => {
        if (errorApiMakerUpdate === null) return;
        DEBUG && console.log("errorApiMakerUpdate", errorApiMakerUpdate);
    }, [errorApiMakerUpdate]);

    return {
        loadingApiMakerUpdate,
        errorApiMakerUpdate,
        resultApiMakerUpdate,
        requestApiMakerUpdate,
    };
};

export const useRequestApiMakerDelete = () => {
    const requestAxiosApiMakerDelete = async (makerId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.MAKER_URL + makerId);

        return response.data;
    };

    const {
        loading: loadingApiMakerDelete,
        error: errorApiMakerDelete,
        data: resultApiMakerDelete,
        execute: requestApiMakerDelete,
    } = useAsyncAxios(requestAxiosApiMakerDelete);

    useEffect(() => {
        if (resultApiMakerDelete === null) return;
        DEBUG && console.log("resultApiMakerDelete", resultApiMakerDelete);
    }, [resultApiMakerDelete]);

    useEffect(() => {
        if (errorApiMakerDelete === null) return;
        DEBUG && console.log("errorApiMakerDelete", errorApiMakerDelete);
    }, [errorApiMakerDelete]);

    return {
        loadingApiMakerDelete,
        errorApiMakerDelete,
        resultApiMakerDelete,
        requestApiMakerDelete,
    };
};
