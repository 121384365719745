import { useEffect, useState } from "react";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as RequestMaintenanceGuide from "../../utils/requestApiMaintenanceGuide";
import * as RequestMakerModel from "../../utils/requestApiMakerModel";

import Search from "../../components/browser/Search";
import MaintenanceGuide from "../../components/browser/MaintenanceGuide";
import Space from "antd/es/space/index";

type MaintenanceDetailByGuide = {
    [key: number]: Array<TypeDTO.MaintenanceDetailsDto>;
};

type SearchType = {
    makerName: string;
    makerModelId: string;
};

function PageMaintenanceGuide() {
    const [models, setModels] = useState<Array<TypeDTO.MakerModelDto>>([]);
    const [selectedMakerModelId, setSelectedMakerModelId] = useState<number>();
    const [maintenanceDetails, setMaintenanceDetails] = useState<Array<TypeDTO.MaintenanceDetailsDto>>([]);
    const [maintenanceDetailByGuide, setMaintenanceDetailByGuide] = useState<MaintenanceDetailByGuide>({});

    useEffect(() => {
        requestApiMakerModelList();
    }, []);

    useEffect(() => {
        const lionnes = models.filter((model) => model?.makerModelName === "LIONNE");

        if (lionnes.length > 0) {
            setSelectedMakerModelId(lionnes[0].makerModelId);
        }
    }, [models]);

    useEffect(() => {
        selectedMakerModelId ? requestApiMaintenanceDetailList(selectedMakerModelId) : setMaintenanceDetails([]);
    }, [selectedMakerModelId]);

    //model
    const { loadingApiMakerModelList, requestApiMakerModelList, resultApiMakerModelList } = RequestMakerModel.useRequestApiMakerModelList();

    useEffect(() => {
        if (!resultApiMakerModelList) return;

        setModels(resultApiMakerModelList.makerModels);
    }, [resultApiMakerModelList]);

    const { loadingApiMaintenanceDetailList, requestApiMaintenanceDetailList, resultApiMaintenanceDetailList } =
        RequestMaintenanceGuide.useRequestApiMaintenanceDetailList();

    useEffect(() => {
        if (!resultApiMaintenanceDetailList) return;

        setMaintenanceDetails(resultApiMaintenanceDetailList.maintenanceDetails);
    }, [resultApiMaintenanceDetailList]);

    useEffect(() => {
        const maintenanceDetailsByGuide: MaintenanceDetailByGuide = {};

        maintenanceDetails.forEach((detail) => {
            const guideId = detail.maintenanceItem.maintenanceGuide.maintenanceGuideId;
            if (!maintenanceDetailsByGuide[guideId]) maintenanceDetailsByGuide[guideId] = [];
            maintenanceDetailsByGuide[guideId].push(detail);
        });

        setMaintenanceDetailByGuide(maintenanceDetailsByGuide);
    }, [maintenanceDetails]);

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "makerName",
            span: 12,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
            defaultValue: "스카이웰",
        },
        {
            id: "makerModelId",
            span: 12,
            title: String.model,
            type: "select",
            typeDetail: "model",
            defaultValue: "LIONNE",
        },
    ];

    const onSearchData = (value: SearchType) => {
        setSelectedMakerModelId(value?.makerModelId ? Number(value?.makerModelId) : undefined);
    };

    return (
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
            <div className="pageWrapper">
                <Search title={String.menu_maintenance_guide} values={search} onSearch={(value) => onSearchData(value)} />
            </div>
            {Object.keys(maintenanceDetailByGuide).map((detail) => {
                const details = maintenanceDetailByGuide[Number(detail)];
                return <MaintenanceGuide key={detail} maintenanceDetails={details} />;
            })}
        </Space>
    );
}

export default PageMaintenanceGuide;
