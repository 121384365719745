import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router";
import { AlertType, useAlert } from "../../provider/AlertProvider";

import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as RequestCar from "../../utils/requestApiCar";
import * as Utils from "../../utils/utils";

import Modal from "antd/es/modal/index";
import Form from "antd/es/form/index";
import Tabs from "antd/es/tabs/index";
import TabCarInfo from "../../components/mobile/TabCarInfo";
import TabDevices from "../../components/mobile/TabDevices";
import TabRunnings from "../../components/mobile/TabRunnings";

const PageCarDetail = () => {
    const navigate = useNavigate();
    const param = useParams();
    const alert = useAlert();

    const { pathname } = useLocation();
    const [form] = Form.useForm();
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [type, setType] = useState<string>("view");

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLICK_DELETE, handleDelete);
        Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);

        return () => {
            Utils.offEvent(Common.EVENT_CLICK_DELETE, handleDelete);
            Utils.onEvent(Common.EVENT_CLOSE_ALL_MODAL, handleCloseAllModal);
        };
    }, []);

    useEffect(() => {
        form.resetFields();
        param.id && requestApiCarInfo(param.id);
    }, [param]);

    useEffect(() => {
        if (pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL)) {
            setType("view");
        } else if (pathname.includes(Common.PAGE_MANAGE_CAR_DEVICE_UPDATE)) {
            setType("device");
        } else {
            setType("update");
        }
    }, [pathname]);

    const { requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;
        const car = resultApiCarInfo.car;

        setCar(car);
        form.setFieldsValue(car);
    }, [resultApiCarInfo]);

    const items = [
        {
            label: String.basicInfo,
            key: String.basicInfo,
            children: <TabCarInfo carId={car?.carId} />,
        },
        {
            label: String.menu_car_device,
            key: String.menu_car_device,
            children: <TabDevices carId={car?.carId} />,
            disabled: !param.id,
        },
        {
            label: String.runningList,
            key: String.runningList,
            children: <TabRunnings carId={car?.carId} carNumber={car?.carNumber} />,
            disabled: type !== "view",
        },
    ];

    const onChange = (tab: string) => {
        localStorage.setItem("carDetailTab", tab);
    };

    const handleDelete = () => {
        Utils.addModalStack();
        onDelete(param.id ? Number(param.id) : car?.carId, car?.carNumber);
    };

    const onDelete = (id: number, name: string) => {
        Modal.confirm({
            title: String.car + String.remove,
            content: Utils.addParticle(name || "해당 차량", "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiCarDelete(id);
                Utils.popModalStack();
            },
            cancelText: String.cancel,
            onCancel() {
                Utils.popModalStack();
            },
            centered: true,
        });
    };

    const { requestApiCarDelete, resultApiCarDelete } = RequestCar.useRequestApiCarDelete();

    useEffect(() => {
        if (!resultApiCarDelete) return;

        onResult("delete");
    }, [resultApiCarDelete]);

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.car} ${typeText} 성공`, `${String.car} 정보를 ${typeText}하였습니다.`);
        navigate(Common.PAGE_MANAGE_CAR);
    };

    const handleCloseAllModal = () => {
        //TODO
    };

    return (
        <div className="pageWrapper" style={{ paddingBottom: type === "view" ? "" : 68 }}>
            {type === "view" ? (
                car && (
                    <Tabs
                        defaultActiveKey={localStorage.getItem("carDetailTab") || String.basicInfo}
                        type="card"
                        items={items}
                        onChange={(tab: string) => onChange(tab)}
                    />
                )
            ) : type === "device" ? (
                <TabDevices carId={car?.carId} />
            ) : (
                <TabCarInfo carId={car?.carId} />
            )}
        </div>
    );
};

export default PageCarDetail;
