import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { AuthProvider } from "./provider/AuthProvider";
import { AlertProvider } from "./provider/AlertProvider";
import { AliveScope } from "react-activation";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducers from "./utils/reducers";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const store = createStore(reducers);

root.render(
    <Provider store={store}>
        <AuthProvider>
            <AlertProvider>
                <AliveScope>
                    <App />
                </AliveScope>
            </AlertProvider>
        </AuthProvider>
    </Provider>
);

reportWebVitals();
