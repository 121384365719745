import { combineReducers } from "redux";

const dashboardReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_DASHBOARD":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const carsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_CARS":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const carDetailsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_CAR_DETAIL":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const runningsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_RUNNINGS":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const runningsByCarReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_RUNNINGS_BY_CAR":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const statsByCarReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_STATS_BY_CAR":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const issuesByCarReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_ISSUES_BY_CAR":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const socsByCarReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_SOCS_BY_CAR":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const issuesReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_ISSUES":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const socsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_SOCS":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const alarmsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_ALARMS":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const groupsReducer = (state = {}, action: any) => {
    switch (action.type) {
        case "SET_GROUPS":
            return { ...action.payload };
        case "RESET_STATE":
            return {};
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    dashboard: dashboardReducer,
    cars: carsReducer,
    carDetail: carDetailsReducer,
    runnings: runningsReducer,
    runningsByCar: runningsByCarReducer,
    statsByCar: statsByCarReducer,
    issuesByCar: issuesByCarReducer,
    socByCar: socsByCarReducer,
    issues: issuesReducer,
    socs: socsReducer,
    alarms: alarmsReducer,
    groups: groupsReducer,
});

const appReducer = (state: any, action: any) => {
    if (action.type === "RESET_STATE") {
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
};

export default appReducer;
