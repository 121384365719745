import { useEffect, useState } from "react";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { CopyOutlined } from "@ant-design/icons";

import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";

import Progress from "antd/es/progress/progress";
import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";
import moment from "moment";

import styles from "../../pages/mobile/Dashboard.module.css";

const DashboardCarInfo = ({ car }: { car: TypeDTO.CarDto }) => {
    const alert = useAlert();
    const [address, setAddress] = useState<string>();

    useEffect(() => {
        const runningHistory = car?.runningSummary?.lastRunningHistory;

        getAddress(runningHistory?.latitude, runningHistory?.longitude, (address, error) => {
            setAddress(address || error || undefined);
        });
    }, [car]);

    const getAddress = (lat: number, lng: number, callback: (address: string | null, error: string | null) => void) => {
        const geocoder = new kakao.maps.services.Geocoder();
        const coord = new kakao.maps.LatLng(lat, lng);

        geocoder.coord2Address(coord.getLng(), coord.getLat(), (result: any, status: any) => {
            if (status === kakao.maps.services.Status.OK) {
                callback(result[0].address.address_name, null);
            } else {
                callback(null, "위치를 확인할 수 없습니다.");
            }
        });
    };

    const ProgressColor = () => {
        const soc = car?.runningSummary?.lastRunningHistory?.endSoc;
        return soc <= 20 ? "var(--danger)" : soc <= 40 ? "var(--warning)" : "var(--point)";
    };

    const checkAbnormal =
        car?.runningSummary?.lastRunningHistory?.finish !== true &&
        moment(car?.runningSummary?.lastRunningHistory?.endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));

    return (
        <Space size={4} direction="vertical">
            <div className={styles.carInfoSpace}>
                <div className={styles.carInfo}>
                    <span className="fs-sm fc-font2">SOC</span>
                    {car?.runningSummary?.lastRunningHistory?.endSoc ? (
                        <div className={styles.batteryWrapper}>
                            <Progress
                                style={{ margin: 0 }}
                                percent={car?.runningSummary?.lastRunningHistory?.endSoc}
                                showInfo={false}
                                strokeColor={ProgressColor()}
                            />
                            <span className="fs-sm">{Number(car?.runningSummary?.lastRunningHistory?.endSoc.toFixed(1))}%</span>
                        </div>
                    ) : (
                        <span className="fs-sm">배터리 정보 없음</span>
                    )}
                </div>
            </div>
            <div className={styles.carInfoSpace}>
                <div className={styles.carInfo}>
                    <span className="fs-sm fc-font2">차량 정보</span>
                    <span className="fs-sm">{car?.category?.categoryName + " / " + car?.makerModel?.maker?.makerName}</span>
                </div>
            </div>
            <div className={styles.carInfoSpace}>
                <div
                    className={styles.carInfo}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (address) {
                            navigator?.clipboard?.writeText(address);
                            alert.setAlert(AlertType.SUCCESS, `복사 성공`, `현재 위치를 복사했습니다.`);
                        }
                    }}
                >
                    <span className="fs-sm fc-font2">현재 위치</span>
                    <span className="fs-sm">{address}</span>
                    {address && <CopyOutlined />}
                </div>
            </div>
            <div className={styles.carInfoSpace}>
                <div className={styles.carInfo}>
                    <span className="fs-sm fc-font2">최근 운행</span>
                    <div>
                        <span className="fs-sm">
                            {moment(car?.runningSummary?.lastRunningHistory?.startTime).format(Common.FORMAT_SHORT_DATE_TIME) + " ~ "}
                        </span>
                        {checkAbnormal || car?.runningSummary?.lastRunningHistory?.finish === true ? (
                            <span className={"fs-sm"}>{moment(car?.runningSummary?.lastRunningHistory?.endTime).format(Common.FORMAT_TIME)}</span>
                        ) : (
                            <Tag className="pointTag">운행중</Tag>
                        )}
                    </div>
                </div>
            </div>
        </Space>
    );
};

export default DashboardCarInfo;
