import { useEffect, useState, useCallback } from "react";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import CustomForm, { viewType } from "./CustomForm";
import Button from "antd/es/button/button";
import Form from "antd/es/form/index";
import Input from "antd/es/input/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import Modal from "antd/es/modal/index";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";

import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestMember from "../../utils/requestAuthMember";

function MyPagePopup({
    open,
    type,
    userInfo,
    onChangeOpen,
}: {
    open: boolean;
    type: viewType;
    userInfo: TypeDTO.UserDto | undefined;
    onChangeOpen?: () => void;
}) {
    const alert = useAlert();
    const userDetails = useAuthState();
    const { Option } = Select;

    const [form] = Form.useForm();
    const [formType, setFormType] = useState<viewType>("view");
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isPassword, setIsPassword] = useState<boolean>(false);

    useEffect(() => {
        setFormType(type);
    }, [type]);

    useEffect(() => {
        setIsOpen(open);
    }, [open]);

    useEffect(() => {
        userInfo
            ? form.setFieldsValue({ ...userInfo, phone: Utils.convertPhone(userInfo?.phone) })
            : form.setFieldsValue({ ...userDetails?.user, phone: Utils.convertPhone(userDetails?.user?.phone) });
    }, [userInfo]);

    const { requestAuthMemberUpdate, resultAuthMemberUpdate } = RequestMember.useRequestAuthMemberUpdate();

    useEffect(() => {
        if (!resultAuthMemberUpdate) return;
        onResult("edit");
    }, [resultAuthMemberUpdate]);

    const onFinish = (type: viewType, value: TypeDTO.UserDto) => {
        requestAuthMemberUpdate(value?.username, { ...value, phone: value?.phone?.replaceAll("-", "") });
        setFormType("view");
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);
        alert.setAlert(AlertType.SUCCESS, `${String.user} ${typeText} 성공`, `${String.user} 정보를 ${typeText}하였습니다.`);
        type !== "delete" && onChangeOpen && onChangeOpen();
    };

    const onChangedType = (type: viewType) => {
        setFormType(type);
    };

    const getValueByKey = (key: string) => {
        if (key in Utils.convertAuthority) {
            return Utils.convertAuthority[key as keyof typeof Utils.convertAuthority];
        }
        return "";
    };

    const validatePwd = useCallback((_: any, value: string) => {
        const pwd_regExp = Common.PASSWORD_REGEXP;

        if (value && !pwd_regExp.test(value)) {
            return Promise.reject(new Error(String.msgPwdRule));
        }
        return Promise.resolve();
    }, []);

    const contents: Array<TypeUtils.formType> = [
        {
            name: "username",
            label: String.id,
            span: 12,
            required: true,
        },
        {
            name: "name",
            label: String.name,
            span: 12,
        },
        {
            name: "phone",
            label: String.phone,
            span: 12,
            required: true,
            rules: [
                {
                    type: "phone",
                    message: "전화번호가 유효하지 않습니다.",
                },
            ],
            input: <Input className={formType === "view" ? "disabled-input" : ""} disabled={formType === "view"} />,
        },
        {
            name: "email",
            label: String.email,
            span: 12,
            rules: [
                {
                    type: "email",
                    message: "이메일이 유효하지 않습니다.",
                },
            ],
        },
        {
            name: "authority",
            label: String.authority,
            span: Utils.roleCheck(userInfo ? userInfo.authority : userDetails?.user?.authority, [
                Utils.getAuthority.ROLE_CUSTOMER,
                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
            ])
                ? 12
                : 24,
            input: (
                <Select
                    allowClear
                    showSearch
                    className={formType === "view" ? "disabled-selector" : ""}
                    placeholder="권한을 선택해 주세요."
                    disabled={
                        formType === "view" ||
                        !Utils.roleCheck(userInfo ? userInfo.authority : userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                    }
                >
                    {Object.keys(Utils.convertAuthority).map((key) => (
                        <Option key={key} value={key}>
                            {getValueByKey(key)}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: ["customer", "customerId"],
            label: String.customer,
            span: Utils.roleCheck(userInfo ? userInfo.authority : userDetails?.user?.authority, [
                Utils.getAuthority.ROLE_CUSTOMER,
                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
            ])
                ? 12
                : 0,
            disabled: true,
            input: (
                <Select
                    className={formType === "view" ? "disabled-selector" : ""}
                    disabled={
                        formType === "view" ||
                        !Utils.roleCheck(userInfo ? userInfo.authority : userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                    }
                >
                    {userInfo ? (
                        <Option key={userInfo?.customer?.customerId} value={userInfo?.customer?.customerId}>
                            {userInfo?.customer?.customerName}
                        </Option>
                    ) : (
                        <Option key={userDetails?.user?.customer?.customerId} value={userDetails?.user?.customer?.customerId}>
                            {userDetails?.user?.customer?.customerName}
                        </Option>
                    )}
                </Select>
            ),
        },
    ];

    const passwordContents: Array<TypeUtils.formType> = [
        {
            name: "username",
            label: String.id,
            span: 0,
        },
        {
            name: "oldPassword",
            label: String.currentPassword,
            span: 24,
            input: (
                <Input.Password
                    name="password"
                    className={`fw-rg fs-md fc-font2 `}
                    placeholder="비밀번호를 입력해 주세요."
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: true,
            rules: [{ validator: validatePwd }, { max: 20, message: String.msgPwdMax }],
        },
        {
            name: "newPassword",
            label: String.newPassword,
            span: 24,
            input: (
                <Input.Password
                    name="password"
                    className={`fw-rg fs-md fc-font2`}
                    placeholder="비밀번호를 입력해 주세요."
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: true,
            rules: [{ validator: validatePwd }, { max: 20, message: String.msgPwdMax }],
        },
        {
            name: "confirm",
            label: String.passwordConfirm,
            span: 24,
            input: (
                <Input.Password
                    name="confirm"
                    className={`fw-rg fs-md fc-font2`}
                    placeholder="비밀번호를 한번 더 입력해 주세요."
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: true,
            rules: [
                ({ getFieldValue }: any) => ({
                    validator(_: any, value: any) {
                        if (!value || getFieldValue("newPassword") === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error("입력한 비밀번호가 일치하지 않습니다."));
                    },
                }),
            ],
            dependencies: ["password"],
            hasFeedback: true,
        },
    ];

    return (
        <Modal
            centered
            forceRender
            closable={false}
            open={isOpen}
            title={
                <Space style={{ width: "100%", justifyContent: "space-between" }} align="center">
                    <h4 style={{ margin: 0 }}>{isPassword ? "비밀번호 재설정" : `내 정보 ${formType === "view" ? String.view : String.update}`}</h4>
                    {formType === "view" && !isPassword && (
                        <div
                            style={{ display: "flex", alignItems: "center", color: "var(--point)", cursor: "pointer", padding: 2 }}
                            onClick={() => setIsPassword(true)}
                        >
                            <LockOutlined style={{ marginRight: "8px" }} />
                            비밀번호 재설정
                        </div>
                    )}
                </Space>
            }
            footer={
                <Space style={{ width: "100%", justifyContent: "center" }} size={16} align="center">
                    <Button
                        key="back"
                        onClick={() => {
                            onChangeOpen && onChangeOpen();
                            setFormType("view");
                        }}
                    >
                        {String.cancel}
                    </Button>
                    <Button
                        className="btn-primary"
                        key="user"
                        onClick={() => {
                            if (isPassword) {
                                form.submit();
                            }
                            if (formType === "view") {
                                setFormType("edit");
                            } else {
                                form.submit();
                            }
                        }}
                    >
                        {formType === "view" ? String.update : String.save}
                    </Button>
                </Space>
            }
        >
            <CustomForm
                form={form}
                type={formType}
                disabledBtn
                contents={[{ forms: isPassword ? passwordContents : contents }]}
                onFinish={onFinish}
                onChangeType={onChangedType}
            />
        </Modal>
    );
}

export default MyPagePopup;
