export const CONTEXT_AUTH = "ezMobility-01";
export const CONTEXT_SAVED_ID = "ezMobility-02";
export const CONTEXT_OPEN_DRAWER = "ezMobility-03";
export const CONTEXT_CENTER = "ezMobility-04";
export const CONTEXT_ZOOM = "ezMobility-05";
export const CONTEXT_FILTER = "ezMobility-06";
export const CONTEXT_SAVED_PW = "ezMobility-07";
export const CONTEXT_NEXT_PATH = "ezMobility-08";
export const CONTEXT_SHOW_MAINTENANCE_MENU = "ezMobility-09";

export const EVENT_CLICK_DELETE = "clickDelete";
export const EVENT_RE_LOGIN = "reLogin";

export const SESSION_SHOW_MODAL = "showModal";
export const SESSION_SHOW_DRAWER = "showDrawer";
export const SESSION_SHOW_VIEW = "showView";
export const EVENT_CLOSE_ALL_MODAL = "closeAllModal";
export const EVENT_CLOSE_ALL_DRAWER = "closeAllDrawer";
export const EVENT_CLOSE_ALL_VIEW = "closeView";
export const EXTERNAL_EVENT_BACK = "backPressEvent";

export const PUSH_NOTIFICATION_ALARM = "alarmPushNotification";

// Page Link
export const PAGE_ERROR_404 = "/error404";
export const PAGE_ERROR_403 = "/error403";
export const PAGE_ERROR_5XX = "/error5XX";

export const PAGE_WELCOME = "/welcome";
export const PAGE_LOGIN = "/login";
export const PAGE_FIND_USER = "/login/find";
export const PAGE_RESET_PASSWORD = "/reset";

export const PAGE_MENU = "/menu";

export const PAGE_DASHBOARD = "/dashboard";

export const PAGE_RUNNING = "/running";
export const PAGE_RUNNINGS_BY_CAR = PAGE_RUNNING + "/car/list";
export const PAGE_RUNNING_STATS_BY_CAR = PAGE_RUNNING + "/car/stats";
export const PAGE_RUNNING_DETAILS = PAGE_RUNNING + "/details";

export const PAGE_MANAGE_CAR = "/car";
export const PAGE_MANAGE_CAR_DETAIL = PAGE_MANAGE_CAR + "/detail";
export const PAGE_MANAGE_CAR_REGISTER = "/car/register";
export const PAGE_MANAGE_CAR_UPDATE = "/car/update";
export const PAGE_MANAGE_CAR_DEVICE_UPDATE = "/car/update/device";

export const PAGE_MANAGE_MAINTENANCES_MENU = "/maintenance/menu";
export const PAGE_MANAGE_MAINTENANCE = "/maintenance";

export const PAGE_MANAGE_MAINTENANCES_HISTORY = PAGE_MANAGE_MAINTENANCE + "/history";
export const PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR = PAGE_MANAGE_MAINTENANCES_HISTORY + "/byCar";
export const PAGE_MANAGE_MAINTENANCE_DETAIL = PAGE_MANAGE_MAINTENANCE + "/detail";
export const PAGE_MANAGE_MAINTENANCE_REGISTER = PAGE_MANAGE_MAINTENANCE + "/register";
export const PAGE_MANAGE_MAINTENANCE_UPDATE = PAGE_MANAGE_MAINTENANCE + "/update";

export const PAGE_ISSUE_SOCS = "/soc";
export const PAGE_ISSUE_SOC_DETAIL = "/soc/detail";

export const PAGE_ALARM = "/alarm";
export const PAGE_ALARM_DETAIL = "/alarm/detail";

export const PAGE_MAINTENANCE_GUIDES = "/maintenance/guides";

export const PAGE_STATS = "/stats";

export const PAGE_MANAGE_MODEM = "/modem";
export const PAGE_MANAGE_MODEM_REGISTER = PAGE_MANAGE_MODEM + "/register";
export const PAGE_MANAGE_MODEM_UPDATE = PAGE_MANAGE_MODEM + "/update";

export const PAGE_MANAGE_TERMINAL = "/terminal";
export const PAGE_MANAGE_TERMINAL_REGISTER = PAGE_MANAGE_TERMINAL + "/register";
export const PAGE_MANAGE_TERMINAL_UPDATE = PAGE_MANAGE_TERMINAL + "/update";

export const PAGE_MANAGE_DOORMODULE = "/doormodule";
export const PAGE_MANAGE_DOORMODULE_REGISTER = PAGE_MANAGE_DOORMODULE + "/register";
export const PAGE_MANAGE_DOORMODULE_UPDATE = PAGE_MANAGE_DOORMODULE + "/update";

export const PAGE_MANAGE_CATEGORY = "/category";
export const PAGE_MANAGE_MAKERS = "/makers";
export const PAGE_MANAGE_CUSTOMER = "/customer";

export const PAGE_MANAGE_USER = "/user";
export const PAGE_MANAGE_USER_AGREE = PAGE_MANAGE_USER + "/agree";
export const PAGE_MANAGE_USER_RESULT = PAGE_MANAGE_USER + "/result";

export const PAGE_MANAGE_GROUP = "/group";
export const PAGE_MANAGE_GROUP_DETAIL = PAGE_MANAGE_GROUP + "/detail";
export const PAGE_MANAGE_GROUP_REGISTER = PAGE_MANAGE_GROUP + "/register";
export const PAGE_MANAGE_GROUP_UPDATE = PAGE_MANAGE_GROUP + "/update";

export const PAGE_MANAGE_SERVICE = "/service";
export const PAGE_MANAGE_SERVICE_REGISTER = PAGE_MANAGE_SERVICE + "/register";
export const PAGE_MANAGE_SERVICE_UPDATE = PAGE_MANAGE_SERVICE + "/update";

export const PAGE_MANAGE_VERSION = "/version";

export const FORMAT_DATE_TIME = "YYYY-MM-DD HH:mm:ss";
export const FORMAT_SHORT_DATE_TIME = "YY-MM-DD HH:mm:ss";
export const FORMAT_MOBILE_DATE_TIME = "YY-MM-DD HH:mm";
export const FORMAT_YEAR = "YYYY";
export const FORMAT_MONTH = "YYYY-MM";
export const FORMAT_DATE = "YYYY-MM-DD";
export const FORMAT_SHORT_DATE = "YY-MM-DD";
export const FORMAT_TIME = "HH:mm:ss";

export const FORMAT_DATE_HOUR_TIME_START = "YYYY-MM-DD 00:00:00";
export const FORMAT_DATE_HOUR_TIME_END = "YYYY-MM-DD 23:59:59";

export const FORMAT_DATE_HOUR_TIME = "YYYY-MM-DD HH:00:00";
export const FORMAT_DATE_HOUR2_TIME = "YYYY-MM-DD HH:59:59";

export const FORMAT_DATE_TIME_MILLISECOND = "YYYY-MM-DD HH:mm:ss.SSS";
export const FORMAT_TIME_MILLISECOND = "HH:mm:ss.SSS";

export const DEFAULT_SEARCH_DATE_RANGE = 6; // 6 days

export const CHART_HEIGHT = 300;
export const TABLE_SCROLL_Y = CHART_HEIGHT - 28;

export const UNIT_VOLTAGE = "V";
export const UNIT_PERCENT = "%";
export const UNIT_OHM = "Ω";
export const UNIT_K_OHM = "kΩ";
export const UNIT_M_OHM = "MΩ";
export const UNIT_CURRENT = "A";
export const UNIT_POWER = "kW";
export const UNIT_POWER_H = "kWh";
export const UNIT_CELSIUS = "°C";
export const UNIT_NUMBER = "개";
export const UNIT_SPEED = "km/h";
export const UNIT_MINUTE = "분";
export const UNIT_PRESSURE = "Kpa";
export const UNIT_POWER_DISTANCE = "kWh/km";
export const UNIT_MOTOR_SPEED = "rpm";
export const UNIT_DISTANCE = "km";
export const UNIT_DEGREE = "deg";
export const UNIT_DEGREE_SPEED = "deg/s";
export const UNIT_TORQUE = "N.m";
export const UNIT_K_PASCAL = "kPa";

export const CAN_DATA_PAGE_SIZE = 10000;
export const CAN_DATA_MAX_GPS_COUNT = 1000;

export const RUNNING_HISTORIES_PAGE_SIZE = 1000;
export const ALARM_LIST_PAGE_SIZE = 1000;

export const VehicleMaker = {
    Bukgi: "북기",
    Yaxing: "야싱",
    Skywell: "스카이웰",
    Kia: "기아",
    TSEco: "티에스에코에너지",
    Navya: "나브야",
};

export const PHONE_REGEXP = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}$/;
export const PASSWORD_REGEXP = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+=?{};':"|,.<>/~`[\]-])[a-zA-Z\d!@#$%^&*()_+=?{};':"|,.<>/~`[\]-]{8,}$/i;

//#region Bukgi
export const VCU_S_1_VEHICLE_FUNCTION_NAME = "Vehicle Function";
export const VCU_S_1_VEHICLE_FUNCTION_KEY = "Vehicle_Function";
export const VCU_S_1_SHIFT_LOCATION_NAME = "Shift Location";
export const VCU_S_1_SHIFT_LOCATION_KEY = "Shift_Location";
export const VCU_S_1_KEY_STATUS_NAME = "Key Status";
export const VCU_S_1_KEY_STATUS_KEY = "Key_Status";
export const VCU_S_1_VEHICLE_SPEED_NAME = "Vehicle Speed";
export const VCU_S_1_VEHICLE_SPEED_KEY = "Vehicle_Speed";
export const VCU_S_1_PEDAL_STATE_NAME = "Pedal State";
export const VCU_S_1_PEDAL_STATE_KEY = "Pedal_State";
export const VCU_S_1_BRAKE_STATE_NAME = "Brake State";
export const VCU_S_1_BRAKE_STATE_KEY = "Brake_State";
export const VCU_F_1_HANDBRAKE_STATE_NAME = "Handbrake State";
export const VCU_F_1_HANDBRAKE_STATE_KEY = "Handbrake_State";

export const VCU_TO_INS_C_1_SOC_INS_NAME = "Soc Ins";
export const VCU_TO_INS_C_1_SOC_INS_KEY = "Soc_Ins";
export const VCU_TO_INS_C_1_CURRENT_INS_NAME = "Current Ins";
export const VCU_TO_INS_C_1_CURRENT_INS_KEY = "Current_Ins";
export const VCU_TO_INS_C_1_VOLT_INS_NAME = "Volt Ins";
export const VCU_TO_INS_C_1_VOLT_INS_KEY = "Volt_Ins";
export const VCU_TO_INS_C_1_INSUL_RESISTANCE_NAME = "Insul Resistance";
export const VCU_TO_INS_C_1_INSUL_RESISTANCE_KEY = "Insul_Resistance";
export const VCU_TO_INS_C_1_CELL_MAX_TEMP_NAME = "Cell Max Temp";
export const VCU_TO_INS_C_1_CELL_MAX_TEMP_KEY = "I_Cell_Max_Temp";
export const VCU_TO_INS_C_1_LED_CHARGING_NAME = "LED 충전지시";
export const VCU_TO_INS_C_1_LED_CHARGING_KEY = "LED_Charging";
export const VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_NAME = "LED 충전연결지시";
export const VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_KEY = "LED_Charging_Connection";
export const VCU_TO_INS_C_1_LED_INSULATION_WARNING_NAME = "LED 차량절연경고";
export const VCU_TO_INS_C_1_LED_INSULATION_WARNING_KEY = "LED_Insulation_Warning";
export const VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_NAME = "LED 배터리저전압경고";
export const VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_KEY = "LED_Batt_Low_Volt_Warning";
export const VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_NAME = "LED 저soc온도경고";
export const VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_KEY = "LED_Low_Soc_Warning";
export const VCU_TO_INS_C_1_LED_BATT_FAILURE_NAME = "LED 배터리고장";
export const VCU_TO_INS_C_1_LED_BATT_FAILURE_KEY = "LED_Low_Batt_Failure";
export const VCU_TO_INS_C_1_LED_SOUND_SIGNAL_NAME = "LED 소리신호";
export const VCU_TO_INS_C_1_LED_SOUND_SIGNAL_KEY = "LED_Sound_Signal";
export const VCU_TO_INS_C_2_READY_SIGNAL_NAME = "Ready Signal";
export const VCU_TO_INS_C_2_READY_SIGNAL_KEY = "Ready_Signal";

export const VCU_COMMAND_1_CONTACTOR_REQUEST_NAME = "Contactor";
export const VCU_COMMAND_1_CONTACTOR_REQUEST_KEY = "Contactor_Request";
export const VCU_COMMAND_1_POWER_DOWN_REQUEST_NAME = "Power Down";
export const VCU_COMMAND_1_POWER_DOWN_REQUEST_KEY = "PowerDown_Request";
export const VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_NAME = "Fault Lockout";
export const VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_KEY = "FaultLockout_Request";
export const VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_NAME = "AC preCharge Voltage";
export const VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_KEY = "AC_preCharge_Voltage_Request";
export const VCU_COMMAND_1_MCU_INPUT_VOLTAGE_NAME = "MCU Input Voltage";
export const VCU_COMMAND_1_MCU_INPUT_VOLTAGE_KEY = "MCU_Input_Voltage";
export const VCU_COMMAND_1_MOTOR_AC_CURRENT_NAME = "Motor AC Current";
export const VCU_COMMAND_1_MOTOR_AC_CURRENT_KEY = "Motor_AC_Current";
export const VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_NAME = "AC preCharge Status";
export const VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_KEY = "AC_preCharge_Voltage_Statue";

export const BATTERY_BATTERY_STATUS_NAME = "Battery Status";
export const BATTERY_BATTERY_STATUS_KEY = "Battery_Status";
export const BATTERY_BATTERY_MODE_NAME = "Battery Mode";
export const BATTERY_BATTERY_MODE_KEY = "Battery_Mode";
export const BATTERY_FAULT_STATUS_NAME = "Fault Status";
export const BATTERY_FAULT_STATUS_KEY = "Fault_Status";
export const BATTERY_BALANCE_LOCKOUT_STATUS_NAME = "Balance Lockout";
export const BATTERY_BALANCE_LOCKOUT_STATUS_KEY = "Balance_Lockout_Status";
export const BATTERY_FAULT_LOCKOUT_STATUS_NAME = "Fault Lockout";
export const BATTERY_FAULT_LOCKOUT_STATUS_KEY = "Fault_Lockout_Status";
export const BATTERY_SOC_NAME = "SOC";
export const BATTERY_SOC_KEY = "SOC";
export const BATTERY_SOH_NAME = "SOH";
export const BATTERY_SOH_KEY = "SOH";
export const BATTERY_PACK_INTERNAL_RESISTANCE_NAME = "Internal Resistance";
export const BATTERY_PACK_INTERNAL_RESISTANCE_KEY = "Pack_Internal_Resistance";
export const BATTERY_PACK_RESIDUAL_CAPACITY_NAME = "Residual Capacity";
export const BATTERY_PACK_RESIDUAL_CAPACITY_KEY = "Pack_Residual_Capacity";
export const BATTERY_MESSAGE_COUNTER_NAME = "Message Counter";
export const BATTERY_MESSAGE_COUNTER_KEY = "Message_Counter";

export const BATTERY_PACK1_PACK_VBATT_NAME = "Pack Vbatt";
export const BATTERY_PACK1_PACK_VBATT_KEY = "Pack_Vbatt";
export const BATTERY_PACK1_PACK_VOUT_NAME = "Pack Vout";
export const BATTERY_PACK1_PACK_VOUT_KEY = "Pack_Vout";
export const BATTERY_PACK1_PACK_CURRENT_NAME = "Pack Current";
export const BATTERY_PACK1_PACK_CURRENT_KEY = "Pack_Current";
export const BATTERY_PACK1_PACK_POWER_NAME = "Pack Power";
export const BATTERY_PACK1_PACK_POWER_KEY = "Pack_Power";
export const BATTERY_PACK1_PACK_TEMP_NAME = "Pack Temp";
export const BATTERY_PACK1_PACK_TEMP_KEY = "Pack_Temp";

export const BATTERY_PACK2_SLAVE_CONTROL_1_NAME = "Slave Control(1~8)";
export const BATTERY_PACK2_SLAVE_CONTROL_1_KEY = "Slave_Control_01";
export const BATTERY_PACK2_SLAVE_CONTROL_9_NAME = "Slave Control(9~16)";
export const BATTERY_PACK2_SLAVE_CONTROL_9_KEY = "Slave_Control_09";
export const BATTERY_PACK2_SLAVE_CONTROL_17_NAME = "Slave Control(17~24)";
export const BATTERY_PACK2_SLAVE_CONTROL_17_KEY = "Slave_Control_17";
export const BATTERY_PACK2_SLAVE_CONTROL_25_NAME = "Slave Control(25~32)";
export const BATTERY_PACK2_SLAVE_CONTROL_25_KEY = "Slave_Control_25";
export const BATTERY_PACK2_BATTERY_BOX_NUMBER_NAME = "Battery Box No.";
export const BATTERY_PACK2_BATTERY_BOX_NUMBER_KEY = "Battery_Box_Number";
export const BATTERY_PACK2_SLAVE_CONTROL_NUMBER_NAME = "Slave Control No.";
export const BATTERY_PACK2_SLAVE_CONTROL_NUMBER_KEY = "Slave_Control_Number";

export const CELL_VOLTAGE_CELL_VOLTAGE_SUM_NAME = "Voltage Sum";
export const CELL_VOLTAGE_CELL_VOLTAGE_SUM_KEY = "Cell_Voltage_Sum";
export const CELL_VOLTAGE_CELL_MAX_V_NAME = "Max V";
export const CELL_VOLTAGE_CELL_MAX_V_KEY = "Cell_Max_V";
export const CELL_VOLTAGE_CELL_MIN_V_NAME = "Min V";
export const CELL_VOLTAGE_CELL_MIN_V_KEY = "Cell_Min_V";
export const CELL_VOLTAGE_CELL_MAX_V_NO_NAME = "Max V No";
export const CELL_VOLTAGE_CELL_MAX_V_NO_KEY = "Cell_Max_V_No";
export const CELL_VOLTAGE_CELL_MIN_V_NO_NAME = "Min V No";
export const CELL_VOLTAGE_CELL_MIN_V_NO_KEY = "Cell_Min_V_No";
export const CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_NAME = "Max V System No";
export const CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_KEY = "Cell_Max_V_System_No";
export const CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_NAME = "Min V System No";
export const CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_KEY = "Cell_Min_V_System_No";

export const CELL_TEMP_CELL_AVG_TEMP_NAME = "Avg Temp";
export const CELL_TEMP_CELL_AVG_TEMP_KEY = "Cell_Avg_Temp";
export const CELL_TEMP_CELL_MAX_TEMP_NAME = "Max Temp";
export const CELL_TEMP_CELL_MAX_TEMP_KEY = "Cell_Max_Temp";
export const CELL_TEMP_CELL_MIN_TEMP_NAME = "Min Temp";
export const CELL_TEMP_CELL_MIN_TEMP_KEY = "Cell_Min_Temp";
export const CELL_TEMP_CELL_MAX_TEMP_NO_NAME = "Max Temp No";
export const CELL_TEMP_CELL_MAX_TEMP_NO_KEY = "Cell_Max_Temp_No";
export const CELL_TEMP_CELL_MIN_TEMP_NO_NAME = "Min Temp No";
export const CELL_TEMP_CELL_MIN_TEMP_NO_KEY = "Cell_Min_Temp_No";
export const CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_NAME = "Max Temp System No";
export const CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_KEY = "Cell_Max_Temp_System_No";
export const CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_NAME = "Min Temp System No";
export const CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_KEY = "Cell_Min_Temp_System_No";

export const BMS_LIMIT_1_MAX_PACK_V_NAME = "Max Pack V";
export const BMS_LIMIT_1_MAX_PACK_V_KEY = "Max_Pack_V";
export const BMS_LIMIT_1_MIN_PACK_V_NAME = "Min Pack V";
export const BMS_LIMIT_1_MIN_PACK_V_KEY = "Min_Pack_V";
export const BMS_LIMIT_1_MAX_CELL_V_NAME = "Max Cell V";
export const BMS_LIMIT_1_MAX_CELL_V_KEY = "Max_Cell_V";
export const BMS_LIMIT_1_MIN_CELL_V_NAME = "Min Cell V";
export const BMS_LIMIT_1_MIN_CELL_V_KEY = "Min_Cell_V";
export const BMS_LIMIT_1_DISCHARGE_CURRENT_NAME = "Discharge Current";
export const BMS_LIMIT_1_DISCHARGE_CURRENT_KEY = "Discharge_Current";
export const BMS_LIMIT_1_MAX_CHARGE_CURRENT_NAME = "Max Charge Current";
export const BMS_LIMIT_1_MAX_CHARGE_CURRENT_KEY = "Max_Charge_Current";

export const BMS_LIMIT_2_MAX_SOC_NAME = "Max SOC";
export const BMS_LIMIT_2_MAX_SOC_KEY = "Max_SOC";
export const BMS_LIMIT_2_MIN_SOC_NAME = "Min SOC";
export const BMS_LIMIT_2_MIN_SOC_KEY = "MIN_SOC";
export const BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_NAME = "Max 10sec Discharge Power";
export const BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_KEY = "Max_10sec_Discharge_Power";
export const BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_NAME = "Max 10sec Charge Power";
export const BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_KEY = "Max_10sec_Charge_Power";
export const BMS_LIMIT_2_MAX_CELL_TEMP_NAME = "Max Cell Temp";
export const BMS_LIMIT_2_MAX_CELL_TEMP_KEY = "Max_Cell_Temp";
export const BMS_LIMIT_2_MIN_CELL_TEMP_NAME = "Min Cell Temp";
export const BMS_LIMIT_2_MIN_CELL_TEMP_KEY = "Min_Cell_Temp";

export const ISOLATION_R_ISOLATION_LIFE_SIGNAL_NAME = "Life Signal";
export const ISOLATION_R_ISOLATION_LIFE_SIGNAL_KEY = "Life_Signal";
export const ISOLATION_R_ISOLATION_RESISTANCE_STATUS_NAME = "Resistance Status";
export const ISOLATION_R_ISOLATION_RESISTANCE_STATUS_KEY = "Resistance_Status";
export const ISOLATION_R_FAULT_LEVEL_NAME = "Fault Level";
export const ISOLATION_R_FAULT_LEVEL_KEY = "Fault_Level";
export const ISOLATION_R_RGND_POS_NAME = "Rgnd Pos";
export const ISOLATION_R_RGND_POS_KEY = "Rgnd_Pos";
export const ISOLATION_R_RGND_NEG_NAME = "Rgnd Neg";
export const ISOLATION_R_RGND_NEG_KEY = "Rgnd_Neg";

export const BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_NAME = "Subsystem #";
export const BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_KEY = "Battery_Subsystem";
export const BATTERY_INFORMATION_1_TOTAL_CELL_NAME = "Total Cell #";
export const BATTERY_INFORMATION_1_TOTAL_CELL_KEY = "Total_Cell";
export const BATTERY_INFORMATION_1_TEMP_SENSOR_NAME = "Temp Sensor #";
export const BATTERY_INFORMATION_1_TEMP_SENSOR_KEY = "Temp_Sensor";
export const BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_NAME = "System Error #";
export const BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_KEY = "Battery_System_Error";
export const BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_NAME = "Charging Status";
export const BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_KEY = "Battery_Charging_Status";
export const BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_NAME = "Charging Gun Connect";
export const BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_KEY = "Battery_ChargingGun_Connect";

export const BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_NAME = "배터리 팩 누적 출력 에너지";
export const BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_KEY = "Battery_Pack_Accumulated_Output";
export const BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_NAME = "배터리 팩 누적 충전 에너지";
export const BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_KEY = "Battery_Pack_Accumulated_Charging";
export const BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_NAME = "배터리 팩 누적 회생제동 에너지";
export const BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_KEY = "Battery_Pack_Accumulated_Regen";

export const BCU_DTC_WARNING_CELL_UVP_NAME = "Warning Cell UVP";
export const BCU_DTC_WARNING_CELL_UVP_KEY = "Warning_Cell_UVP";
export const BCU_DTC_WARNING2_CELL_UVP_NAME = "Warning2 Cell UVP";
export const BCU_DTC_WARNING2_CELL_UVP_KEY = "Warning2_Cell_UVP";
export const BCU_DTC_FAULT_CELL_UVP_NAME = "Fault Cell UVP";
export const BCU_DTC_FAULT_CELL_UVP_KEY = "Fault_Cell_UVP";
export const BCU_DTC_WARNING_CELL_OVP_NAME = "Warning Cell OVP";
export const BCU_DTC_WARNING_CELL_OVP_KEY = "Warning_Cell_OVP";
export const BCU_DTC_WARNING2_CELL_OVP_NAME = "Warning2 Cell OVP";
export const BCU_DTC_WARNING2_CELL_OVP_KEY = "Warning2_Cell_OVP";
export const BCU_DTC_FAULT_CELL_OVP_NAME = "Fault Cell OVP";
export const BCU_DTC_FAULT_CELL_OVP_KEY = "Fault_Cell_OVP";
export const BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_NAME = "충전압수집고장2급 경고";
export const BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_KEY = "Warning_Total_Voltage_Collect_Fail_Level2";
export const BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_NAME = "셀전압수집고장3급 경고";
export const BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_KEY = "Warning_Cell_Voltage_Collect_Fail_Level3";
export const BCU_DTC_WARNING_CELL_UTP_NAME = "Warning Cell UTP";
export const BCU_DTC_WARNING_CELL_UTP_KEY = "Warning_Cell_UTP";
export const BCU_DTC_WARNING2_CELL_UTP_NAME = "Warning2 Cell UTP";
export const BCU_DTC_WARNING2_CELL_UTP_KEY = "Warning2_Cell_UTP";
export const BCU_DTC_FAULT_CELL_UTP_NAME = "Fault Cell UTP";
export const BCU_DTC_FAULT_CELL_UTP_KEY = "Fault_Cell_UTP";
export const BCU_DTC_WARNING_CELL_OTP_NAME = "Warning Cell OTP";
export const BCU_DTC_WARNING_CELL_OTP_KEY = "Warning_Cell_OTP";
export const BCU_DTC_WARNING2_CELL_OTP_NAME = "Warning2 Cell OTP";
export const BCU_DTC_WARNING2_CELL_OTP_KEY = "Warning2_Cell_OTP";
export const BCU_DTC_FAULT_CELL_OTP_NAME = "Fault Cell OTP";
export const BCU_DTC_FAULT_CELL_OTP_KEY = "Fault_Cell_OTP";
export const BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_NAME = "온도채널고장2급 경고";
export const BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_KEY = "Warning_Temp_Channel_Fail_Level2";
export const BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_NAME = "밸런싱채널고장1급 경고";
export const BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_KEY = "Warning_Balancing_Channel_Fail_Level1";
export const BCU_DTC_WARNING_PACK_UPV_NAME = "Warning Pack UPV";
export const BCU_DTC_WARNING_PACK_UPV_KEY = "Warning_Pack_UPV";
export const BCU_DTC_WARNING2_PACK_UPV_NAME = "Warning2 Pack UPV";
export const BCU_DTC_WARNING2_PACK_UPV_KEY = "Warning2_Pack_UPV";
export const BCU_DTC_FAULT_PACK_UPV_NAME = "Fault Pack UPV";
export const BCU_DTC_FAULT_PACK_UPV_KEY = "Fault_Pack_UPV";
export const BCU_DTC_WARNING_PACK_OPV_NAME = "Warning Pack OPV";
export const BCU_DTC_WARNING_PACK_OPV_KEY = "Warning_Pack_OPV";
export const BCU_DTC_WARNING2_PACK_OPV_NAME = "Warning2 Pack OPV";
export const BCU_DTC_WARNING2_PACK_OPV_KEY = "Warning2_Pack_OPV";
export const BCU_DTC_FAULT_PACK_OPV_NAME = "Fault Pack OPV";
export const BCU_DTC_FAULT_PACK_OPV_KEY = "Fault_Pack_OPV";
export const BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_NAME = "PreCharRelay 유착고장 3급 경고";
export const BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_KEY = "Warning_Precharrelay_Adhesion_Fail_Level3";
export const BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_NAME = "PreCharRelay 실패고장 3급 경고";
export const BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_KEY = "Warning_Precharrelay_Failure_Fail_Level3";
export const BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_NAME = "Warning Pack 전압차";
export const BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_KEY = "Warning_Pack_Voltage_Diff";
export const BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_NAME = "Warning2 Pack 전압차";
export const BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_KEY = "Warning2_Pack_Voltage_Diff";
export const BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_NAME = "Fault Pack 전압차";
export const BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_KEY = "Fault_Pack_Voltage_Diff";
export const BCU_DTC_WARNING_PACK_TEMP_DIFF_NAME = "Warning Pack 온도차";
export const BCU_DTC_WARNING_PACK_TEMP_DIFF_KEY = "Warning_Pack_Temp_Diff";
export const BCU_DTC_WARNING2_PACK_TEMP_DIFF_NAME = "Warning2 Pack 온도차";
export const BCU_DTC_WARNING2_PACK_TEMP_DIFF_KEY = "Warning2_Pack_Temp_Diff";
export const BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_NAME = "전류수집고장 경고";
export const BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_KEY = "Warning_Current_Collect_Fault";
export const BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_NAME = "MainRelay(+) 유착고장 3급 경고";
export const BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_KEY = "Warning_Mainrelay_Plus_Adhesion_Fail_Level3";
export const BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_NAME = "MainRelay(-) 유착고장 3급 경고";
export const BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_KEY = "Warning_Mainrelay_Minus_Adhesion_Fail_Level3";
export const BCU_DTC_WARNING_UNDER_SOC_NAME = "Warning Under SOC";
export const BCU_DTC_WARNING_UNDER_SOC_KEY = "Warning_Under_SOC";
export const BCU_DTC_WARNING2_UNDER_SOC_NAME = "Warning2 Under SOC";
export const BCU_DTC_WARNING2_UNDER_SOC_KEY = "Warning2_Under_SOC";
export const BCU_DTC_FAULT_UNDER_SOC_NAME = "Fault Under SOC";
export const BCU_DTC_FAULT_UNDER_SOC_KEY = "Fault_Under_SOC";
export const BCU_DTC_WARNING_OVER_SOC_NAME = "Warning Over SOC";
export const BCU_DTC_WARNING_OVER_SOC_KEY = "Warning_Over_SOC";
export const BCU_DTC_WARNING2_OVER_SOC_NAME = "Warning2 Over SOC";
export const BCU_DTC_WARNING2_OVER_SOC_KEY = "Warning2_Over_SOC";
export const BCU_DTC_FAULT_OVER_SOC_NAME = "Fault Over SOC";
export const BCU_DTC_FAULT_OVER_SOC_KEY = "Fault_Over_SOC";
export const BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_NAME = "BMS내부통신고장 3급 경고";
export const BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_KEY = "Warning_Bms_Internal_Comm_Fail_Level3";
export const BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_NAME = "BMS차량통신고장 3급 경고";
export const BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_KEY = "Warning_Bms_Vehicle_Comm_Fail_Level3";
export const BCU_DTC_WARNING_DISCHARGE_OCP_NAME = "Warning Discharge OCP";
export const BCU_DTC_WARNING_DISCHARGE_OCP_KEY = "Warning_Discharge_OCP";
export const BCU_DTC_WARNING2_DISCHARGE_OCP_NAME = "Warning2 Discharge OCP";
export const BCU_DTC_WARNING2_DISCHARGE_OCP_KEY = "Warning2_Discharge_OCP";
export const BCU_DTC_FAULT_DISCHARGE_OCP_NAME = "Fault Discharge OCP";
export const BCU_DTC_FAULT_DISCHARGE_OCP_KEY = "Fault_Discharge_OCP";
export const BCU_DTC_WARNING_CHARGE_OCP_NAME = "Warning Charge OCP";
export const BCU_DTC_WARNING_CHARGE_OCP_KEY = "Warning_Charge_OCP";
export const BCU_DTC_WARNING2_CHARGE_OCP_NAME = "Warning2 Charge OCP";
export const BCU_DTC_WARNING2_CHARGE_OCP_KEY = "Warning2_Charge_OCP";
export const BCU_DTC_FAULT_CHARGE_OCP_NAME = "Fault Charge OCP";
export const BCU_DTC_FAULT_CHARGE_OCP_KEY = "Fault_Charge_OCP";
export const BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_NAME = "절연고장 2급 경고";
export const BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_KEY = "Warning_Insulation_Fail_Level2";
export const BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_NAME = "절연고장 3급 경고";
export const BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_KEY = "Warning_Insulation_Fail_Level3";
export const BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_NAME = "Motor Relay 유착고장 3급 경고";
export const BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_KEY = "Warning_MotorRelay_Adhesion_Fail_Level3";
export const BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME = "방전 동결회로 차단고장 2급 경고";
export const BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY = "Warning_Discharge_Freezing_Circuit_Breaking_Fail_Level2";
export const BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME = "충전 동결회로 차단고장 2급 경고";
export const BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY = "Warning_Charge_Freezing_Circuit_Breaking_Fail_Level2";
export const BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME = "급속충전 Relay 유착고장 3급 경고";
export const BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY = "Warning_Fastcharging_Relay_Adhesion_Fail_Level3";
export const BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME = "완속충전 Relay 유착고장 3급 경고";
export const BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY = "Warning_Slowcharging_Relay_Adhesion_Fail_Level3";
export const BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_NAME = "가열 Relay 유착고장 3급경고";
export const BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_KEY = "Warning_Heating_Relay_Adhesion_Fail_Level3";
export const BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_NAME = "SOC Jump 이상 경고";
export const BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_KEY = "Warning_Soc_Jump_Abnormal";
export const BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_NAME = "배터리시스템 불일치 경고";
export const BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_KEY = "Warning_Battery_System_Mismatch";
export const BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_NAME = "급속충전 양극 고온 2급 경고";
export const BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_KEY = "Warning_Fastcharging_Anode_Hightemp_Class2";
export const BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_NAME = "급속충전 양극 고온 3급 경고";
export const BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_KEY = "Warning_Fastcharging_Anode_Hightemp_Class3";
export const BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME = "급속충전 음극 고온 2급 경고";
export const BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY = "Warning_Fastcharging_Cathode_Hightemp_Class3";
export const BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME = "급속충전 음극 고온 3급 경고";
export const BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY = "Warning_Fastcharging_Cathode_Hightemp_Class3";
export const BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_NAME = "완속충전 양극 고온 2급 경고";
export const BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_KEY = "Warning_Slowcharging_Anode_Hightemp_Class3";
export const BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_NAME = "완속충전 양극 고온 3급 경고";
export const BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_KEY = "Warning_Slowcharging_Anode_Hightemp_Class3";
export const BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME = "완속충전 음극 고온 2급 경고";
export const BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY = "Warning_Slowcharging_Cathode_Hightemp_Class3";
export const BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME = "완속충전 음극 고온 3급 경고";
export const BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY = "Warning_Slowcharging_Cathode_Hightemp_Class3";

export const VCU_MOTOR_CONTROL_MODE_NAME = "Motor Control Mode";
export const VCU_MOTOR_CONTROL_MODE_KEY = "Vcu_Motor_Control_Mode";
export const VCU_MOTOR_CONTROL_VCU_COUNTER_NAME = "Vcu Counter";
export const VCU_MOTOR_CONTROL_VCU_COUNTER_KEY = "Vcu_Motor_Control_Vcu_Counter";
export const VCU_MOTOR_CONTROL_TORQUE_NAME = "Motor Control Torque";
export const VCU_MOTOR_CONTROL_TORQUE_KEY = "Vcu_Motor_Control_Torque";
export const VCU_MOTOR_CONTROL_SPEED_NAME = "Motor Control Speed";
export const VCU_MOTOR_CONTROL_SPEED_KEY = "Vcu_Motor_Control_Speed";

export const MCU_STATUS_FEEDBACK_SPEED_NAME = "Feedback Speed";
export const MCU_STATUS_FEEDBACK_SPEED_KEY = "Mcu_Status_Feedback_Speed";
export const MCU_STATUS_FEEDBACK_TORQUE_NAME = "Feedback Torque";
export const MCU_STATUS_FEEDBACK_TORQUE_KEY = "Mcu_Status_Feedback_Torque";
export const MCU_STATUS_MCU_STATE_FLAG_NAME = "Mcu State Flag";
export const MCU_STATUS_MCU_STATE_FLAG_KEY = "Mcu_Status_Mcu_State_Flag";
export const MCU_STATUS_MCU_ALIVE_COUNT_NAME = "Mcu Alive Count";
export const MCU_STATUS_MCU_ALIVE_COUNT_KEY = "Mcu_Status_Mcu_Alive_Count";
//#endregion

//#region Yaxing
export const BATTERY_INFORMATION_VOLTAGE_NAME = "배터리 전압";
export const BATTERY_INFORMATION_VOLTAGE_KEY = "Battery_Voltage";
export const BATTERY_INFORMATION_CURRENT_NAME = "배터리 전류";
export const BATTERY_INFORMATION_CURRENT_KEY = "Battery_Current";
export const BATTERY_INFORMATION_SOC_NAME = "SOC";
export const BATTERY_INFORMATION_SOC_KEY = "Battery_Soc";
export const BATTERY_INFORMATION_SOH_NAME = "SOH";
export const BATTERY_INFORMATION_SOH_KEY = "Battery_Soh";
export const BATTERY_INFORMATION_OUTPUT_STATUS_NAME = "배터리 출력상태";
export const BATTERY_INFORMATION_OUTPUT_STATUS_KEY = "Battery_Output_Status";
export const BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_NAME = "충전연결상태";
export const BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_KEY = "Battery_Charging_Connect_Status";
export const BATTERY_INFORMATION_CHARGING_STATUS_NAME = "충전상태";
export const BATTERY_INFORMATION_CHARGING_STATUS_KEY = "Battery_Charging_Status";
export const BATTERY_INFORMATION_ERROR_LEVEL_NAME = "배터리 고장수준";
export const BATTERY_INFORMATION_ERROR_LEVEL_KEY = "Battery_Error_Level";
export const BATTERY_INFORMATION_ERROR_CODE_NAME = "배터리 고장코드";
export const BATTERY_INFORMATION_ERROR_CODE_KEY = "Battery_Error_Code";

export const CELL_VOLTAGE_BOX_NUMBER_NAME = "Box No.";
export const CELL_VOLTAGE_BOX_NUMBER_KEY = "Cell_Voltage_Box_No";
export const CELL_VOLTAGE_CELL_NUMBER_NAME = "Cell No.";
export const CELL_VOLTAGE_CELL_NUMBER_KEY = "Cell_Voltage_Cell_No";
export const CELL_VOLTAGE_CELL_1_VOLTAGE_NAME = "Cell1 Voltage";
export const CELL_VOLTAGE_CELL_1_VOLTAGE_KEY = "Cell_Voltage_Cell1_Voltage";
export const CELL_VOLTAGE_CELL_2_VOLTAGE_NAME = "Cell2 Voltage";
export const CELL_VOLTAGE_CELL_2_VOLTAGE_KEY = "Cell_Voltage_Cell2_Voltage";
export const CELL_VOLTAGE_CELL_3_VOLTAGE_NAME = "Cell3 Voltage";
export const CELL_VOLTAGE_CELL_3_VOLTAGE_KEY = "Cell_Voltage_Cell3_Voltage";

export const CELL_TEMPERATURE_BOX_NUMBER_NAME = "Box No.";
export const CELL_TEMPERATURE_BOX_NUMBER_KEY = "Cell_Temperature_Box_No";
export const CELL_TEMPERATURE_CELL_NUMBER_NAME = "Cell No.";
export const CELL_TEMPERATURE_CELL_NUMBER_KEY = "Cell_Temperature_Cell_No";
export const CELL_TEMPERATURE_CELL_1_TEMPERATURE_NAME = "Cell1 Temperature";
export const CELL_TEMPERATURE_CELL_1_TEMPERATURE_KEY = "Cell_Temperature_Cell1_Temperature";
export const CELL_TEMPERATURE_CELL_2_TEMPERATURE_NAME = "Cell2 Temperature";
export const CELL_TEMPERATURE_CELL_2_TEMPERATURE_KEY = "Cell_Temperature_Cell2_Temperature";
export const CELL_TEMPERATURE_CELL_3_TEMPERATURE_NAME = "Cell3 Temperature";
export const CELL_TEMPERATURE_CELL_3_TEMPERATURE_KEY = "Cell_Temperature_Cell3_Temperature";
export const CELL_TEMPERATURE_CELL_4_TEMPERATURE_NAME = "Cell4 Temperature";
export const CELL_TEMPERATURE_CELL_4_TEMPERATURE_KEY = "Cell_Temperature_Cell4_Temperature";
export const CELL_TEMPERATURE_CELL_5_TEMPERATURE_NAME = "Cell5 Temperature";
export const CELL_TEMPERATURE_CELL_5_TEMPERATURE_KEY = "Cell_Temperature_Cell5_Temperature";
export const CELL_TEMPERATURE_CELL_6_TEMPERATURE_NAME = "Cell6 Temperature";
export const CELL_TEMPERATURE_CELL_6_TEMPERATURE_KEY = "Cell_Temperature_Cell6_Temperature";

export const HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_NAME = "최고전압";
export const HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_KEY = "Hightest_Lowest_Cell_Voltage_Highest_Value";
export const HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_NAME = "최고전압 Cell No.";
export const HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_KEY = "Hightest_Lowest_Cell_Voltage_Cell_Number_With_HV";
export const HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_NAME = "최고전압 Box No.";
export const HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_KEY = "Hightest_Lowest_Cell_Voltage_Box_Number_With_HV";
export const HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_NAME = "최저전압";
export const HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_KEY = "Hightest_Lowest_Cell_Voltage_Lowest_Value";
export const HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_NAME = "최저전압 Cell No.";
export const HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_KEY = "Hightest_Lowest_Cell_Voltage_Cell_Number_With_LV";
export const HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_NAME = "최저전압 Box No.";
export const HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_KEY = "Hightest_Lowest_Cell_Voltage_Box_Number_With_LV";

export const HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_NAME = "최고온도";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_KEY = "Hightest_Lowest_Cell_Temperature_Highest_Value";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_NAME = "최고온도 Cell No.";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_KEY = "Hightest_Lowest_Cell_Temperature_Cell_Number_With_HV";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_NAME = "최고온도 Box No.";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_KEY = "Hightest_Lowest_Cell_Temperature_Box_Number_With_HV";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_NAME = "최저온도";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_KEY = "Hightest_Lowest_Cell_Temperature_Lowest_Value";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_NAME = "최저온도 Cell No.";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_KEY = "Hightest_Lowest_Cell_Temperature_Cell_Number_With_LV";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_NAME = "최저온도 Box No.";
export const HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_KEY = "Hightest_Lowest_Cell_Temperature_Box_Number_With_LV";

export const BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_NAME = "전압 상한";
export const BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_KEY = "Battery_Limit_1_Maximum_Cell_Voltage";
export const BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_NAME = "전압 하한";
export const BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_KEY = "Battery_Limit_1_Minimum_Cell_Voltage";
export const BATTERY_LIMIT_1_MAXIMUM_SOC_NAME = "SOC 상한";
export const BATTERY_LIMIT_1_MAXIMUM_SOC_KEY = "Battery_Limit_1_Maximum_Soc";
export const BATTERY_LIMIT_1_MINIMUM_SOC_NAME = "SOC 하한";
export const BATTERY_LIMIT_1_MINIMUM_SOC_KEY = "Battery_Limit_1_Minimum_Soc";
export const BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_NAME = "온도 상한";
export const BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_KEY = "Battery_Limit_1_Maximum_Cell_Temperature";
export const BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_NAME = "온도 하한";
export const BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_KEY = "Battery_Limit_1_Minimum_Cell_Temperature";

export const BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_NAME = "최대충전전류";
export const BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_KEY = "Battery_Limit_2_Maximum_Charging_Current";
export const BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_NAME = "최대방전전류";
export const BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_KEY = "Battery_Limit_2_Maximum_Discharging_Current";
export const BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_NAME = "최대충전전압";
export const BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_KEY = "Battery_Limit_2_Maximum_Charging_Voltage";
export const BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_NAME = "최저방전전압";
export const BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_KEY = "Battery_Limit_2_Minimum_Discharging_Voltage";

export const BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_NAME = "고압상하전";
export const BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_KEY = "Battery_Request_Contactor_Shutting_Request";
export const BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_NAME = "용량전압 기준치";
export const BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_KEY = "Battery_Request_Ultra_Capacitor_Reference_Voltage";

export const BATTERY_SUBSYSTEMS_NUMBER_NAME = "서브시스템 수";
export const BATTERY_SUBSYSTEMS_NUMBER_KEY = "Battery_Subsystems_Number";
export const BATTERY_SUBSYSTEMS_CELL_NUMBER_NAME = "Cell 수";
export const BATTERY_SUBSYSTEMS_CELL_NUMBER_KEY = "Battery_Subsystems_Cell_Number";
export const BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_NAME = "온도체크 수";
export const BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_KEY = "Battery_Subsystems_Temp_Check_Number";
export const BATTERY_SUBSYSTEMS_CHARGING_POWER_NAME = "충전 전력량";
export const BATTERY_SUBSYSTEMS_CHARGING_POWER_KEY = "Battery_Subsystems_Charging_Power";
export const BATTERY_SUBSYSTEMS_CHARGING_TIME_NAME = "잔여 충전시간";
export const BATTERY_SUBSYSTEMS_CHARGING_TIME_KEY = "Battery_Subsystems_Charging_Time";

export const INSULATION_STATUS_MONITOR_STATUS_NAME = "절연모니터 상태";
export const INSULATION_STATUS_MONITOR_STATUS_KEY = "Insulation_Status_Monitor_Status";
export const INSULATION_STATUS_RESISTANCE_NAME = "절연저항 값";
export const INSULATION_STATUS_RESISTANCE_KEY = "Insulation_Status_Resistance";
export const INSULATION_STATUS_ERROR_LEVEL_NAME = "절연모니터 고장수준";
export const INSULATION_STATUS_ERROR_LEVEL_KEY = "Insulation_Status_Error_Level";
export const INSULATION_STATUS_ERROR_CODE_NAME = "절연모니터 고장코드";
export const INSULATION_STATUS_ERROR_CODE_KEY = "Insulation_Status_Error_Code";

export const ACCUMULATED_CAPACITY_DISCHARGE_POWER_NAME = "누적 방전 전력량";
export const ACCUMULATED_CAPACITY_DISCHARGE_POWER_KEY = "Accumlated_Capacity_Discharge_Power";
export const ACCUMULATED_CAPACITY_CHARGE_POWER_NAME = "누적 충전 전력량";
export const ACCUMULATED_CAPACITY_CHARGE_POWER_KEY = "Accumlated_Capacity_Charge_Power";

export const DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_NAME = "출력 부족";
export const DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_KEY = "Dcdc_Status_Under_Output_Voltage";
export const DCDC_STATUS_OVER_OUTPUT_VOLTAGE_NAME = "출력 과압";
export const DCDC_STATUS_OVER_OUTPUT_VOLTAGE_KEY = "Dcdc_Status_Over_Output_Voltage";
export const DCDC_STATUS_UNDER_INPUT_VOLTAGE_NAME = "입력 부족";
export const DCDC_STATUS_UNDER_INPUT_VOLTAGE_KEY = "Dcdc_Status_Under_Input_Voltage";
export const DCDC_STATUS_OVER_INPUT_VOLTAGE_NAME = "입력 과압";
export const DCDC_STATUS_OVER_INPUT_VOLTAGE_KEY = "Dcdc_Status_Over_Input_Voltage";
export const DCDC_STATUS_HARDWARE_FAULT_FLAG_NAME = "하드웨어 오류";
export const DCDC_STATUS_HARDWARE_FAULT_FLAG_KEY = "Dcdc_Status_Hardware_Fault_Flag";
export const DCDC_STATUS_OPERATING_MODE_NAME = "작업모드";
export const DCDC_STATUS_OPERATING_MODE_KEY = "Dcdc_Status_Operating_Mode";
export const DCDC_STATUS_DERATING_NAME = "전력 제한상태";
export const DCDC_STATUS_DERATING_KEY = "Dcdc_Status_derating";
export const DCDC_STATUS_OVER_INPUT_CURRENT_NAME = "입력 오버플로";
export const DCDC_STATUS_OVER_INPUT_CURRENT_KEY = "Dcdc_Status_Over_Input_Current";
export const DCDC_STATUS_OVER_TEMPERATURE_NAME = "과온";
export const DCDC_STATUS_OVER_TEMPERATURE_KEY = "Dcdc_Status_Over_Temperature";
export const DCDC_STATUS_OVER_OUTPUT_CURRENT_NAME = "전류 제한보호";
export const DCDC_STATUS_OVER_OUTPUT_CURRENT_KEY = "Dcdc_Status_Over_Output_Current";
export const DCDC_STATUS_REAL_OUTPUT_CURRENT_NAME = "실시간 출력전류";
export const DCDC_STATUS_REAL_OUTPUT_CURRENT_KEY = "Dcdc_Status_Real_Output_Current";
export const DCDC_STATUS_REALITY_TEMPERATURE_NAME = "본체 온도";
export const DCDC_STATUS_REALITY_TEMPERATURE_KEY = "Dcdc_Status_Reality_Temperature";
export const DCDC_STATUS_REAL_OUTPUT_VOLTAGE_NAME = "실시간 출력전압";
export const DCDC_STATUS_REAL_OUTPUT_VOLTAGE_KEY = "Dcdc_Status_Real_Output_Voltage";
export const DCDC_STATUS_REAL_INPUT_VOLTAGE_NAME = "실시간 입력전압";
export const DCDC_STATUS_REAL_INPUT_VOLTAGE_KEY = "Dcdc_Status_Real_Input_Voltage";
export const DCDC_STATUS_VERSION_NAME = "버전";
export const DCDC_STATUS_VERSION_KEY = "Dcdc_Status_Version";

export const DCDC_WORK_COMMAND_ENABLE_NAME = "Enable";
export const DCDC_WORK_COMMAND_ENABLE_KEY = "Dcdc_Work_Command_Enable";
export const DCDC_WORK_COMMAND_VOLTAGE_REQUEST_NAME = "출력전압 설정";
export const DCDC_WORK_COMMAND_VOLTAGE_REQUEST_KEY = "Dcdc_Work_Command_Voltage_Request";

export const TMPS_PRESSURE_STATUS_LEFT_FRONT_NAME = "좌측전방 압력";
export const TMPS_PRESSURE_STATUS_LEFT_FRONT_KEY = "Tmps_Pressure_Status_Left_Front";
export const TMPS_PRESSURE_STATUS_RIGHT_FRONT_NAME = "우측전방 압력";
export const TMPS_PRESSURE_STATUS_RIGHT_FRONT_KEY = "Tmps_Pressure_Status_Right_Front";
export const TMPS_PRESSURE_STATUS_LEFT_REAR_NAME = "좌측후방 압력";
export const TMPS_PRESSURE_STATUS_LEFT_REAR_KEY = "Tmps_Pressure_Status_Left_Rear";
export const TMPS_PRESSURE_STATUS_RIGHT_REAR_NAME = "우측후방 압력";
export const TMPS_PRESSURE_STATUS_RIGHT_REAR_KEY = "Tmps_Pressure_Status_Right_Rear";
export const TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_NAME = "좌측전방 압력상태";
export const TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_KEY = "Tmps_Pressure_Status_Left_Front_Status";
export const TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_NAME = "우측전방 압력상태";
export const TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_KEY = "Tmps_Pressure_Status_Right_Front_Status";
export const TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_NAME = "좌측후방 압력상태";
export const TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_KEY = "Tmps_Pressure_Status_Left_Rear_Status";
export const TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_NAME = "우측후방 압력상태";
export const TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_KEY = "Tmps_Pressure_Status_Right_Rear_Status";
export const TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_NAME = "압력신호 수집상태";
export const TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_KEY = "Tmps_Pressure_Status_Acqisition_Status";
export const TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_NAME = "타이어 공기압 모니터링 시스템 상태";
export const TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_KEY = "Tmps_Pressure_Status_Tire_Monitor_System_Status";
export const TMPS_PRESSURE_STATUS_SEND_CYCLE_NAME = "전송주기";
export const TMPS_PRESSURE_STATUS_SEND_CYCLE_KEY = "Tmps_Pressure_Status_Send_Cycle";

export const TMPS_TEMPERATURE_STATUS_LEFT_FRONT_NAME = "좌측전방 온도";
export const TMPS_TEMPERATURE_STATUS_LEFT_FRONT_KEY = "Tmps_Temperature_Status_Left_Front";
export const TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_NAME = "우측전방 온도";
export const TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_KEY = "Tmps_Temperature_Status_Right_Front";
export const TMPS_TEMPERATURE_STATUS_LEFT_REAR_NAME = "좌측후방 온도";
export const TMPS_TEMPERATURE_STATUS_LEFT_REAR_KEY = "Tmps_Temperature_Status_Left_Rear";
export const TMPS_TEMPERATURE_STATUS_RIGHT_REAR_NAME = "우측후방 온도";
export const TMPS_TEMPERATURE_STATUS_RIGHT_REAR_KEY = "Tmps_Temperature_Status_Right_Rear";
export const TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_NAME = "좌측전방 온도상태";
export const TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_KEY = "Tmps_Temperature_Status_Left_Front_Status";
export const TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_NAME = "우측전방 온도상태";
export const TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_KEY = "Tmps_Temperature_Status_Right_Front_Status";
export const TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_NAME = "좌측후방 온도상태";
export const TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_KEY = "Tmps_Temperature_Status_Left_Rear_Status";
export const TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_NAME = "우측후방 온도상태";
export const TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_KEY = "Tmps_Temperature_Status_Right_Rear_Status";
export const TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_NAME = "온도신호 수집상태";
export const TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_KEY = "Tmps_Temperature_Status_Acqisition_Status";
export const TMPS_TEMPERATURE_STATUS_SEND_CYCLE_NAME = "전송주기";
export const TMPS_TEMPERATURE_STATUS_SEND_CYCLE_KEY = "Tmps_Temperature_Status_Send_Cycle";

export const LAMP_SIGNAL_HANDBRAKE_STATUS_NAME = "핸드브레이크 상태";
export const LAMP_SIGNAL_HANDBRAKE_STATUS_KEY = "Lamp_Signal_Handbrake_Status";
export const LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_NAME = "운전자 안전벨트";
export const LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_KEY = "Lamp_Signal_Driver_Seat_Belt_Switch";
export const LAMP_SIGNAL_LOW_BEAM_NAME = "하향등";
export const LAMP_SIGNAL_LOW_BEAM_KEY = "Lamp_Signal_Low_Beam";
export const LAMP_SIGNAL_HIGH_BEAM_NAME = "상향등";
export const LAMP_SIGNAL_HIGH_BEAM_KEY = "Lamp_Signal_High_Beam";
export const LAMP_SIGNAL_FRONT_FOG_NAME = "전방 안개등";
export const LAMP_SIGNAL_FRONT_FOG_KEY = "Lamp_Signal_Front_Fog";
export const LAMP_SIGNAL_REAR_FOG_NAME = "후방 안개등";
export const LAMP_SIGNAL_REAR_FOG_KEY = "Lamp_Signal_Rear_Fog";
export const LAMP_SIGNAL_RIGHT_TURN_NAME = "우측 방향지시등";
export const LAMP_SIGNAL_RIGHT_TURN_KEY = "Lamp_Signal_Right_Turn";
export const LAMP_SIGNAL_LEFT_TURN_NAME = "좌측 방향지시등";
export const LAMP_SIGNAL_LEFT_TURN_KEY = "Lamp_Signal_Left_Turn";
export const LAMP_SIGNAL_POSITION_NAME = "위치등";
export const LAMP_SIGNAL_POSITION_KEY = "Lamp_Signal_Position";
export const LAMP_SIGNAL_HAZARD_WARNING_NAME = "위험경고등";
export const LAMP_SIGNAL_HAZARD_WARNING_KEY = "Lamp_Signal_Hazard_Warning";
export const LAMP_SIGNAL_LEFT_FRONT_DOOR_NAME = "좌측전방도어";
export const LAMP_SIGNAL_LEFT_FRONT_DOOR_KEY = "Lamp_Signal_Left_Front_Door";
export const LAMP_SIGNAL_RIGHT_FRONT_DOOR_NAME = "우측전방도어";
export const LAMP_SIGNAL_RIGHT_FRONT_DOOR_KEY = "Lamp_Signal_Right_Front_Door";
export const LAMP_SIGNAL_LEFT_MIDDLE_DOOR_NAME = "좌측중간도어";
export const LAMP_SIGNAL_LEFT_MIDDLE_DOOR_KEY = "Lamp_Signal_Left_Middle_Door";
export const LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_NAME = "우측중간도어";
export const LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_KEY = "Lamp_Signal_Right_Middle_Door";
export const LAMP_SIGNAL_TAIL_GATE_NAME = "후방도어";
export const LAMP_SIGNAL_TAIL_GATE_KEY = "Lamp_Signal_Tail_Gate";

export const VEHICLE_SPEED_SPEED_NAME = "속도";
export const VEHICLE_SPEED_SPEED_KEY = "Vehicle_Speed_Speed";
export const VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_NAME = "순간 소모전력";
export const VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_KEY = "Vehicle_Speed_Instantanous_Power_Consumption";
export const VEHICLE_SPEED_DRIVE_MOTOR_SPEED_NAME = "구동모터회전수";
export const VEHICLE_SPEED_DRIVE_MOTOR_SPEED_KEY = "Vehicle_Speed_Drive_Motor_Speed";
export const VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_NAME = "고압 프리차지 전압";
export const VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_KEY = "Vehicle_Speed_High_Voltage_Precharge";
export const VEHICLE_SPEED_RECHARE_MILEAGE_NAME = "주행거리";
export const VEHICLE_SPEED_RECHARE_MILEAGE_KEY = "Vehicle_Speed_Recharge_Mileage";

export const ELECTRONIC_MESSAGE_DRIVING_MODE_NAME = "주행모드";
export const ELECTRONIC_MESSAGE_DRIVING_MODE_KEY = "Electronic_Message_Driving_Mode";
export const ELECTRONIC_MESSAGE_GEAR_POSITION_NAME = "기어";
export const ELECTRONIC_MESSAGE_GEAR_POSITION_KEY = "Electronic_Message_Gear_Position";
export const ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_NAME = "고압컨트롤러 상태";
export const ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_KEY = "Electronic_Message_High_Voltage_Controller_Status";
export const ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_NAME = "고압컨트롤러 고장코드";
export const ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_KEY = "Electronic_Message_High_Voltage_Controller_Fault_Code";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_NAME = "차량컨트롤러 상태";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_KEY = "Electronic_Message_Vehicle_Controller_Vcu_Status";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_NAME = "Defrost Status";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_KEY = "Electronic_Message_Vehicle_Controller_Defrost_Status";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_NAME = "가속페달 상태";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_KEY = "Electronic_Message_Vehicle_Controller_Accelerator_Pedal_Status";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_NAME = "브레이크페달 상태";
export const ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_KEY = "Electronic_Message_Vehicle_Controller_Brake_Pedal_Status";
export const ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_NAME = "전기시스템 고장";
export const ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_KEY = "Electronic_Message_Vehicle_Electronics_System_Failure";
export const ELECTRONIC_MESSAGE_DCDC_STATUS_NAME = "DCDC 상태";
export const ELECTRONIC_MESSAGE_DCDC_STATUS_KEY = "Electronic_Message_Dcdc_status";
export const ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_NAME = "구동모터 출력제한";
export const ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_KEY = "Electronic_Message_Drive_Motor_Power_Limit";
export const ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_NAME = "냉각수 펌프 상태";
export const ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_KEY = "Electronic_Message_Cooling_Water_Pump_Status";
export const ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_NAME = "오일 펌프 상태";
export const ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_KEY = "Electronic_Message_Steering_Oil_Pump_Status";
export const ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_NAME = "브레이크 진공 상태";
export const ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_KEY = "Electronic_Message_Air_Pump_Status";
export const ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_NAME = "에어컨 상태";
export const ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_KEY = "Electronic_Message_Air_Conditioner_Status";
export const ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_NAME = "작동 준비 표시등";
export const ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_KEY = "Electronic_Message_Operation_Ready_Indicator";

export const MOTOR_STATUS_RUNNING_STATUS_NAME = "모터 작동상태";
export const MOTOR_STATUS_RUNNING_STATUS_KEY = "Motor_Status_Running_Status";
export const MOTOR_STATUS_ROTATION_DIRECTION_NAME = "모터 조향";
export const MOTOR_STATUS_ROTATION_DIRECTION_KEY = "Motor_Status_Rotation_Direction";
export const MOTOR_STATUS_ERROR_LEVEL_NAME = "모터 고장레벨";
export const MOTOR_STATUS_ERROR_LEVEL_KEY = "Motor_Status_Error_Level";
export const MOTOR_STATUS_ERROR_CODE_NAME = "모터 고장코드";
export const MOTOR_STATUS_ERROR_CODE_KEY = "Motor_Status_Error_Code";
export const MOTOR_STATUS_COOLING_WATER_TEMPERATURE_NAME = "모터 냉각수 온도";
export const MOTOR_STATUS_COOLING_WATER_TEMPERATURE_KEY = "Motor_Status_Cooling_Water_Temperature";
export const MOTOR_STATUS_CONTROLLER_TEMPERATURE_NAME = "모터 제어기 온도";
export const MOTOR_STATUS_CONTROLLER_TEMPERATURE_KEY = "Motor_Status_Controller_Temperature";
export const MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_NAME = "모터 출력 백분율";
export const MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_KEY = "Motor_Status_Output_Power_Percentage";
export const MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_NAME = "회생제동 전력 백분율";
export const MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_KEY = "Motor_Status_Brake_Recovery_Power_Percentage";
export const MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_NAME = "회생제동 상태";
export const MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_KEY = "Motor_Status_Brake_Recovery_Working_Status";

export const VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_NAME = "누적 주행거리";
export const VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_KEY = "Vehicle_Mileage_Accumulated_Mileage";
export const VEHICLE_MILEAGE_CURRENT_MILEAGE_NAME = "현재 주행거리";
export const VEHICLE_MILEAGE_CURRENT_MILEAGE_KEY = "Vehicle_Mileage_Current_Mileage";

export const B2C_CHARGER_VOLTAGE_SET_NAME = "교류 충전 전압 설정";
export const B2C_CHARGER_VOLTAGE_SET_KEY = "B2C_Charger_Voltage_Set";
export const B2C_CHARGER_CURRENT_SET_NAME = "교류 충전 전류 설정";
export const B2C_CHARGER_CURRENT_SET_KEY = "B2C_Charger_Current_Set";
export const B2C_CHARGER_CONTROL_NAME = "교류 충전기 제어";
export const B2C_CHARGER_CONTROL_KEY = "B2C_Charger_Control";

export const WORKING_COMMAND_RUNNING_COMMAND_DCAC1_NAME = "DCAC1 실행지시";
export const WORKING_COMMAND_RUNNING_COMMAND_DCAC1_KEY = "Working_Command_Running_Command_Dcac1";
export const WORKING_COMMAND_RESET_COMMAND_EHPS_NAME = "EHPS 재설정 지시";
export const WORKING_COMMAND_RESET_COMMAND_EHPS_KEY = "Working_Command_Reset_Command_Ehps";
export const WORKING_COMMAND_SPEED_NAME = "속도";
export const WORKING_COMMAND_SPEED_KEY = "Working_Command_Speed";

export const EHPS_STATUS_ACTUAL_SPEED_NAME = "실회전수";
export const EHPS_STATUS_ACTUAL_SPEED_KEY = "Ehps_Status_Actual_Speed";
export const EHPS_STATUS_CONTROLLER_TEMPERATURE_NAME = "컨트롤러 온도";
export const EHPS_STATUS_CONTROLLER_TEMPERATURE_KEY = "Ehps_Status_Controller_Temperature";
export const EHPS_STATUS_STATUS_NAME = "상태";
export const EHPS_STATUS_STATUS_KEY = "Ehps_Status_Status";
export const EHPS_STATUS_ERROR_LEVEL_NAME = "고장 단계";
export const EHPS_STATUS_ERROR_LEVEL_KEY = "Ehps_Status_Error_Level";
export const EHPS_STATUS_ERROR_CODE_NAME = "고장 코드";
export const EHPS_STATUS_ERROR_CODE_KEY = "Ehps_Status_Code";
export const EHPS_STATUS2_VOLTAGE_NAME = "전압";
export const EHPS_STATUS2_VOLTAGE_KEY = "Ehps_Status2_Voltage";
export const EHPS_STATUS2_CURRENT_NAME = "전류";
export const EHPS_STATUS2_CURRENT_KEY = "Ehps_Status2_Voltage";

export const ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_NAME = "핸들 각도";
export const ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_KEY = "Angle_Sensor_Status_Steering_Wheel_Angle";
export const ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPEED_NAME = "핸들 속도";
export const ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPPED_KEY = "Angle_Sensor_Status_Steering_Wheel_Speed";
export const ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_NAME = "센서 고장 신호";
export const ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_KEY = "Angle_Sensor_Status_Sensor_Failure_Signal";
export const ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_NAME = "전압경고신호";
export const ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_KEY = "Angle_Sensor_Status_Voltage_Warning_Signal";
export const ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_NAME = "회전 속도 부호 비트";
export const ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_KEY = "Angle_Sensor_Status_Angular_Velocity_Sign_Bit";
export const ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_NAME = "교정 상태";
export const ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_KEY = "Angle_Sensor_Status_Calibration_Status";
export const ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_NAME = "회전속도유효신호";
export const ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_KEY = "Angle_Sensor_Status_Angular_Velocity_Valid_Signal";
export const ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_NAME = "스티어링 각도 유효 신호";
export const ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_KEY = "Angle_Sensor_Status_Steering_Angle_Valid_Signal";
export const ANGLE_SENSOR_STATUS_COUNT_SIGNAL_NAME = "계수 신호";
export const ANGLE_SENSOR_STATUS_COUNT_SIGNAL_KEY = "Angle_Sensor_Status_Count_Signal";
export const ANGLE_SENSOR_STATUS_CHECK_NAME = "체크";
export const ANGLE_SENSOR_STATUS_CHECK_KEY = "Angle_Sensor_Status_Check";
//#endregion

//#region CoCoKart
export const BMS_INFO_1_VOLT_NAME = "전압";
export const BMS_INFO_1_VOLT_KEY = "Bms_Info1_Volt";
export const BMS_INFO_1_CURR_NAME = "전류";
export const BMS_INFO_1_CURR_KEY = "Bms_Info1_Curr";
export const BMS_INFO_1_SOC_NAME = "SOC";
export const BMS_INFO_1_SOC_KEY = "Bms_Info1_Soc";
export const BMS_INFO_1_PROXIMITY_NAME = "충전연결";
export const BMS_INFO_1_PROXIMITY_KEY = "Bms_Info1_Proximity";
export const BMS_INFO_1_CHG_STAT_NAME = "충전상태";
export const BMS_INFO_1_CHG_STAT_KEY = "Bms_Info1_ChgStat";
export const BMS_INFO_1_LOW_SOC_NAME = "SOC상태";
export const BMS_INFO_1_LOW_SOC_KEY = "Bms_Info1_LowSoc";
export const BMS_INFO_1_READY_NAME = "BMS상태";
export const BMS_INFO_1_READY_KEY = "Bms_Info1_Ready";
export const BMS_INFO_1_RLY_DIS_CHG_NAME = "Dischage Relay상태";
export const BMS_INFO_1_RLY_DIS_CHG_KEY = "Bms_Info1_RlyDisChg";
export const BMS_INFO_1_RLY_CHG_NAME = "Chage Relay상태";
export const BMS_INFO_1_RLY_CHG_KEY = "Bms_Info1_RlyChg";
export const BMS_INFO_1_CHG_FINISH_NAME = "충전종료";
export const BMS_INFO_1_CHG_FINISH_KEY = "Bms_Info1_ChgFinish";
export const BMS_INFO_1_ERR_CODE_NAME = "에러코드";
export const BMS_INFO_1_ERR_CODE_KEY = "Bms_Info1_ErrCode";
export const BMS_INFO_1_ERR_LEVEL_NAME = "에러단계";
export const BMS_INFO_1_ERR_LEVEL_KEY = "Bms_Info1_ErrLevel";
export const BMS_INFO_1_ALIVE_CNT_NAME = "Alive Count";
export const BMS_INFO_1_ALIVE_CNT_KEY = "Bms_Info1_AliveCnt";

export const BMS_INFO_2_CELL_VOLT_MAX_NAME = "최고 전압";
export const BMS_INFO_2_CELL_VOLT_MAX_KEY = "Bms_Info2_CellVoltMax";
export const BMS_INFO_2_CELL_VOLT_MIN_NAME = "최저 전압";
export const BMS_INFO_2_CELL_VOLT_MIN_KEY = "Bms_Info2_CellVoltMin";
export const BMS_INFO_2_CELL_TEMP_MAX_NAME = "최고 온도";
export const BMS_INFO_2_CELL_TEMP_MAX_KEY = "Bms_Info2_CellTempMax";
export const BMS_INFO_2_CELL_TEMP_MIN_NAME = "최저 온도";
export const BMS_INFO_2_CELL_TEMP_MIN_KEY = "Bms_Info2_CellTempMin";
export const BMS_INFO_2_DISCHG_CURR_MAX_NAME = "최고 방전전류";
export const BMS_INFO_2_DISCHG_CURR_MAX_KEY = "Bms_Info2_DisChgCurrMax";

export const BMS_CHG_REQ_VOLT_MAX_NAME = "충전 최고전압";
export const BMS_CHG_REQ_VOLT_MAX_KEY = "Bms_Charge_Req_VoltMax";
export const BMS_CHG_REQ_CURR_MAX_NAME = "충전 최고전류";
export const BMS_CHG_REQ_CURR_MAX_KEY = "Bms_Charge_Req_CurrMax";
export const BMS_CHG_REQ_OBC_ON_NAME = "OBC On/Off";
export const BMS_CHG_REQ_OBC_ON_KEY = "Bms_Charge_Req_ObcOn";
export const BMS_CHG_REQ_OBC_MODE_NAME = "OBC Mode";
export const BMS_CHG_REQ_OBC_MODE_KEY = "Bms_Charge_Req_ObcMode";

export const BMS_CHG_TIME_DISCHG_CNT_NAME = "Discharge Count";
export const BMS_CHG_TIME_DISCHG_CNT_KEY = "Bms_Charge_Time_DisChg_Cnt";
export const BMS_CHG_TIME_CHG_CNT_NAME = "Charge Count";
export const BMS_CHG_TIME_CHG_CNT_KEY = "Bms_Charge_Time_Chg_Cnt";
export const BMS_CHG_TIME_SOH_NAME = "SOH";
export const BMS_CHG_TIME_SOH_KEY = "Bms_Charge_Time_Soh";
export const BMS_CHG_TIME_QCURR_NAME = "Capacity";
export const BMS_CHG_TIME_QCURR_KEY = "Bms_Charge_Time_QCurr";

export const OBC_CHG_INFO_OUT_VOLT_NAME = "Output Voltage";
export const OBC_CHG_INFO_OUT_VOLT_KEY = "Obc_Chg_Info_Out_Volt";
export const OBC_CHG_INFO_OUT_CURR_NAME = "Output Current";
export const OBC_CHG_INFO_OUT_CURR_KEY = "Obc_Chg_Info_Out_Curr";
export const OBC_CHG_INFO_STS_FAIL_HW_NAME = "HW Failure";
export const OBC_CHG_INFO_STS_FAIL_HW_KEY = "Obc_Chg_Info_Sts_Fail_Hw";
export const OBC_CHG_INFO_STS_OTP_NAME = "OTP";
export const OBC_CHG_INFO_STS_OTP_KEY = "Obc_Chg_Info_Sts_Otp";
export const OBC_CHG_INFO_STS_IN_VOLT_NAME = "Input Voltage";
export const OBC_CHG_INFO_STS_IN_VOLT_KEY = "Obc_Chg_Info_Sts_In_Volt";
export const OBC_CHG_INFO_STS_CHK_BAT_NAME = "Battery Connection";
export const OBC_CHG_INFO_STS_CHK_BAT_KEY = "Obc_Chg_Info_Sts_Chk_Bat";
export const OBC_CHG_INFO_STS_COMM_NAME = "CAN Communication";
export const OBC_CHG_INFO_STS_COMM_KEY = "Obc_Chg_Info_Sts_Comm";

export const MOT1_RPM_NAME = "rpm";
export const MOT1_RPM_KEY = "Mot1_Rpm";
export const MOT1_CURR_NAME = "전류";
export const MOT1_CURR_KEY = "Mot1_Curr";
export const MOT1_VOLT_NAME = "전압";
export const MOT1_VOLT_KEY = "Mot1_Volt";
export const MOT1_ERR0_NAME = "Error 0";
export const MOT1_ERR0_KEY = "Mot1_Err0";
export const MOT1_ERR1_NAME = "Error 1";
export const MOT1_ERR1_KEY = "Mot1_Err1";
export const MOT1_ERR2_NAME = "Error 2";
export const MOT1_ERR2_KEY = "Mot1_Err2";
export const MOT1_ERR4_NAME = "Error 4";
export const MOT1_ERR4_KEY = "Mot1_Err4";
export const MOT1_ERR5_NAME = "Error 5";
export const MOT1_ERR5_KEY = "Mot1_Err5";
export const MOT1_ERR6_NAME = "Error 6";
export const MOT1_ERR6_KEY = "Mot1_Err6";
export const MOT1_ERR7_NAME = "Error 7";
export const MOT1_ERR7_KEY = "Mot1_Err7";
export const MOT1_ERR9_NAME = "Error 9";
export const MOT1_ERR9_KEY = "Mot1_Err9";
export const MOT1_ERR10_NAME = "Error 10";
export const MOT1_ERR10_KEY = "Mot1_Err10";
export const MOT1_ERR11_NAME = "Error 11";
export const MOT1_ERR11_KEY = "Mot1_Err11";
export const MOT1_ERR14_NAME = "Error 14";
export const MOT1_ERR14_KEY = "Mot1_Err14";
export const MOT1_ERR15_NAME = "Error 15";
export const MOT1_ERR15_KEY = "Mot1_Err15";

export const MOT2_THROTTLE_NAME = "throttle";
export const MOT2_THROTTLE_KEY = "Mot2_Throttle";
export const MOT2_INVERTER_TEMP_NAME = "인버터 온도";
export const MOT2_INVERTER_TEMP_KEY = "Mot2_InverterTemp";
export const MOT2_MOTOR_TEMP_NAME = "모터 온도";
export const MOT2_MOTOR_TEMP_KEY = "Mot2_MotorTemp";
export const MOT2_STS_DNR_NAME = "MotSts_DNR";
export const MOT2_STS_DNR_KEY = "Mot2_MotSts_DNR";
export const MOT2_STS_DNR_FB_NAME = "MotSts_DNR_FB";
export const MOT2_STS_DNR_FB_KEY = "Mot2_MotSts_DNR_FB";
export const MOT2_DI_HALL_A_NAME = "DI HallA";
export const MOT2_DI_HALL_A_KEY = "Mot2_DI_HallA";
export const MOT2_DI_HALL_B_NAME = "DI HallB";
export const MOT2_DI_HALL_B_KEY = "Mot2_DI_HallB";
export const MOT2_DI_HALL_C_NAME = "DI HallC";
export const MOT2_DI_HALL_C_KEY = "Mot2_DI_HallC";
export const MOT2_BRAKE_SW_NAME = "Brake SW";
export const MOT2_BRAKE_SW_KEY = "Mot2_Brake_Sw";
export const MOT2_BACKWARD_NAME = "Backward";
export const MOT2_BACKWARD_KEY = "Mot2_Backward";
export const MOT2_FORWARD_NAME = "Forward";
export const MOT2_FORWARD_KEY = "Mot2_Forward";
export const MOT2_FOOT_SW_NAME = "Foot SW";
export const MOT2_FOOT_SW_KEY = "Mot2_Foot_Sw";
export const MOT2_BOOST_SW_NAME = "Boost SW";
export const MOT2_BOOST_SW_KEY = "Mot2_Boost_Sw";

export const VEH1_SOC_NAME = "SOC";
export const VEH1_SOC_KEY = "Veh1_Soc";
export const VEH1_STATE_SYS_NAME = "System State";
export const VEH1_STATE_SYS_KEY = "Veh1_stateSys";
export const VEH1_SPEED_NAME = "Speed";
export const VEH1_SPEED_KEY = "Veh1_speed";
export const VEH1_STATE_CHARGE_NAME = "충전여부";
export const VEH1_STATE_CHARGE_KEY = "Veh1_stateChg";
export const VEH1_STATE_FNR_NAME = "FNR";
export const VEH1_STATE_FNR_KEY = "Veh1_stateFnr";
export const VEH1_CLU_DISPLAY_NAME = "CLU Display";
export const VEH1_CLU_DISPLAY_KEY = "Veh1_cluDispOn";
export const VEH1_CLU_HIGH_BEAM_NAME = "CLU High Beam";
export const VEH1_CLU_HIGH_BEAM_KEY = "Veh1_cluHighBeam";
export const VEH1_CLU_LOW_BEAM_NAME = "CLU Low Beam";
export const VEH1_CLU_LOW_BEAM_KEY = "Veh1_cluLowBeam";
export const VEH1_CLU_LEFT_TURN_NAME = "CLU Left Turn";
export const VEH1_CLU_LEFT_TURN_KEY = "Veh1_cluLTurn";
export const VEH1_CLU_RIGHT_TURN_NAME = "CLU Right Turn";
export const VEH1_CLU_RIGHT_TURN_KEY = "Veh1_cluRTurn";
export const VEH1_KEY_FOB_CMD_NAME = "Key FOB Cmd";
export const VEH1_KEY_FOB_CMD_KEY = "Veh1_keyFobCmd";
export const VEH1_REGEN_NAME = "회생제동";
export const VEH1_REGEN_KEY = "Veh1_regen";
export const VEH1_SIDE_BRAKE_NAME = "Side Brake";
export const VEH1_SIDE_BRAKE_KEY = "Veh1_sideBrake";
export const VEH1_AUX_BATTERY_WARNING_NAME = "Aux. Battery low Warning";
export const VEH1_AUX_BATTERY_WARNING_KEY = "Veh1_auxBatLowWarn";
export const VEH1_DERATING_MODE_NAME = "Derating Mode";
export const VEH1_DERATING_MODE_KEY = "Veh1_derMod";
export const VEH1_LOW_SOH_ALARM_NAME = "Low SOH Alarm";
export const VEH1_LOW_SOH_ALARM_KEY = "Veh1_lowSohAlarm";
export const VEH1_OVER_TEMP_WARNING_NAME = "Over Temp Warning";
export const VEH1_OVER_TEMP_WARNING_KEY = "Veh1_overTempWarn";
export const VEH1_REPAIR_LAMP_NAME = "Repair Lamp";
export const VEH1_REPAIR_LAMP_KEY = "Veh1_repairLampOn";
export const VEH1_UNDER_TEMP_PROTECT_NAME = "Under Temp Protect";
export const VEH1_UNDER_TEMP_PROTECT_KEY = "Veh1_udrTempProtect";
export const VEH1_WARNING_LAMP_NAME = "Warning Lamp";
export const VEH1_WARNING_LAMP_KEY = "Veh1_warningLampOn";
export const VEH1_CHARGING_GUN_STATUS_NAME = "충전선 연결 여부";
export const VEH1_CHARGING_GUN_STATUS_KEY = "Veh1_chgGunStatus";

export const VEH2_RAW_SPEED_NAME = "Calculated Speed";
export const VEH2_RAW_SPEED_KEY = "Veh2_rawSpeed";
export const VEH2_I_IGN_NAME = "I_IGN";
export const VEH2_I_IGN_KEY = "Veh2_IIgn";
export const VEH2_I_CHARGE_NAME = "I_CHARGE";
export const VEH2_I_CHARGE_KEY = "Veh2_IAcc";
export const VEH2_DIH_BRAKE_NAME = "DIH_Brake";
export const VEH2_DIH_BRAKE_KEY = "Veh2_dihBrake";
export const VEH2_DIH_HAZZARD_NAME = "DIH_Hazard";
export const VEH2_DIH_HAZZARD_KEY = "Veh2_dihHazard";
export const VEH2_O_INV_ON_NAME = "O_INV_ON";
export const VEH2_O_INV_ON_KEY = "Veh2_OInvOn";
export const VEH2_O_MAIN_RLY_NAME = "O_MAIN_RLY";
export const VEH2_O_MAIN_RLY_KEY = "Veh2_OMainRly";
export const VEH2_O_BMS_ON_NAME = "O_BMS_ON";
export const VEH2_O_BMS_ON_KEY = "Veh2_OBmsOn";
export const VEH2_O_LP_REVERSE_NAME = "O_LP_Reverse";
export const VEH2_O_LP_REVERSE_KEY = "Veh2_OLpReverse";
export const VEH2_VOLT_BATT_NAME = "VOLT_BATT";
export const VEH2_VOLT_BATT_KEY = "Veh2_voltBat";
export const VEH2_AI_CHARGE_NAME = "AI_CHARGE";
export const VEH2_AI_CHARGE_KEY = "Veh2_aiCharge";
export const VEH2_DIH_TURN_L_NAME = "DIH_Turn_L";
export const VEH2_DIH_TURN_L_KEY = "Veh2_dihTurnL";
export const VEH2_DIH_TURN_R_NAME = "DIH_Turn_R";
export const VEH2_DIH_TURN_R_KEY = "Veh2_dihTurnR";
export const VEH2_O_LP_TURN_L_NAME = "O_LP_Turn_L";
export const VEH2_O_LP_TURN_L_KEY = "Veh2_OLpTurnL";
export const VEH2_O_LP_TURN_R_NAME = "O_LP_Turn_R";
export const VEH2_O_LP_TURN_R_KEY = "Veh2_OLpTurnR";
export const VEH2_DIH_HAZZARD_L_NAME = "DIH_Harzard_L";
export const VEH2_DIH_HAZZARD_L_KEY = "Veh2_dihHazardL";

export const OBD_BYTE_0_NAME = "OBD Byte 0";
export const OBD_BYTE_0_KEY = "Obd_Byte_0";
export const OBD_BYTE_1_NAME = "OBD Byte 1";
export const OBD_BYTE_1_KEY = "Obd_Byte_1";
export const OBD_BYTE_2_NAME = "OBD Byte 2";
export const OBD_BYTE_2_KEY = "Obd_Byte_2";
export const OBD_BYTE_3_NAME = "OBD Byte 3";
export const OBD_BYTE_3_KEY = "Obd_Byte_3";
export const OBD_BYTE_4_NAME = "OBD Byte 4";
export const OBD_BYTE_4_KEY = "Obd_Byte_4";
export const OBD_BYTE_5_NAME = "OBD Byte 5";
export const OBD_BYTE_5_KEY = "Obd_Byte_5";
export const OBD_BYTE_6_NAME = "OBD Byte 6";
export const OBD_BYTE_6_KEY = "Obd_Byte_6";
export const OBD_BYTE_7_NAME = "OBD Byte 7";
export const OBD_BYTE_7_KEY = "Obd_Byte_7";
export const OBD_BYTE_8_NAME = "OBD Byte 8";
export const OBD_BYTE_8_KEY = "Obd_Byte_8";
export const OBD_BYTE_9_NAME = "OBD Byte 9";
export const OBD_BYTE_9_KEY = "Obd_Byte_9";
export const OBD_BYTE_10_NAME = "OBD Byte 10";
export const OBD_BYTE_10_KEY = "Obd_Byte_10";
export const OBD_BYTE_11_NAME = "OBD Byte 11";
export const OBD_BYTE_11_KEY = "Obd_Byte_11";
export const OBD_BYTE_12_NAME = "OBD Byte 12";
export const OBD_BYTE_12_KEY = "Obd_Byte_12";
export const OBD_BYTE_13_NAME = "OBD Byte 13";
export const OBD_BYTE_13_KEY = "Obd_Byte_13";
export const OBD_BYTE_14_NAME = "OBD Byte 14";
export const OBD_BYTE_14_KEY = "Obd_Byte_14";
export const OBD_BYTE_15_NAME = "OBD Byte 15";
export const OBD_BYTE_15_KEY = "Obd_Byte_15";

export const ODO1_TRIP_2_EMPTY_NAME = "Trip2Empty";
export const ODO1_TRIP_2_EMPTY_KEY = "Odo1_trip2Empty";
export const ODO1_HV_PACK_VOLTAGE_NAME = "HvPackVoltage";
export const ODO1_HV_PACK_VOLTAGE_KEY = "Odo1_hvPackVoltage";
export const ODO1_HV_CURRENT_NAME = "HvCurrent";
export const ODO1_HV_CURRENT_KEY = "Odo1_hvCurrent";
export const ODO1_TOTAL_CHARGE_KWH_NAME = "TotalChgKwh";
export const ODO1_TOTAL_CHARGE_KWH_KEY = "Odo1_totalChgKwh";
export const ODO2_TRIP_TOT_NAME = "TripTot";
export const ODO2_TRIP_TOT_KEY = "Odo2_tripTot";
export const ODO3_TRIP_TOT_USER_NAME = "TripTotUser";
export const ODO3_TRIP_TOT_USER_KEY = "Odo3_tripTotUser";

//#endregion

//#region Skywell
export const SKYWELL_BATTERY_INFO_VOLTAGE_NAME = "배터리 전압";
export const SKYWELL_BATTERY_INFO_VOLTAGE_KEY = "Battery_Voltage";
export const SKYWELL_BATTERY_INFO_CURRENT_NAME = "배터리 전류";
export const SKYWELL_BATTERY_INFO_CURRENT_KEY = "Battery_Current";
export const SKYWELL_BATTERY_INFO_SOC_NAME = "SOC";
export const SKYWELL_BATTERY_INFO_SOC_KEY = "Battery_Soc";
export const SKYWELL_BATTERY_INFO_LIFE_SIGNAL_NAME = "Life Signal";
export const SKYWELL_BATTERY_INFO_LIFE_SIGNAL_KEY = "Life_Signal";

export const SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_NAME = "배터리 고온 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_HIGH_TEMP_ALARM_KEY = "bfa_high_temp_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_NAME = "셀 과전압 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_OVER_VOLT_ALARM_KEY = "bfa_cell_over_volt_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_NAME = "모듈 과전압 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_OVER_VOLT_ALARM_KEY = "bfa_module_over_volt_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_NAME = "모듈 전압부족 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_MODULE_LOW_VOLT_ALARM_KEY = "bfa_module_low_volt_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_NAME = "셀 전압부족 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_LOW_VOLT_ALARM_KEY = "bfa_cell_low_volt_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_NAME = "충전건 고온 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_GUN_HIGH_TEMP_ALARM_KEY = "bfa_charge_gun_high_temp_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_NAME = "동력 배터리 고장 상태";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_BAT_FALUT_STATUS_KEY = "bfa_bat_fault_status";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_NAME = "방전 전류 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_DISCHARGE_CURR_ALARM_KEY = "bfa_discharge_curr_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_NAME = "충전 전류 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_CURR_ALARM_KEY = "bfa_charge_curr_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_NAME = "셀 전압 차 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CELL_VOLT_DIFF_ALARM_KEY = "bfa_cell_volt_diff_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_NAME = "온도 차 경보";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_TEMP_VOLT_DIFF_ALARM_KEY = "bfa_temp_volt_diff_alarm";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_NAME = "BMS 고장코드";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_BMS_FAULT_CODE_KEY = "bfa_bms_fault_code";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_NAME = "충전상태";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_STATUS_KEY = "bfa_charge_status";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_NAME = "충전 인넷 연결 상태";
export const SKYWELL_BATTERY_FAULT_ALARM_INFO_CHARGE_NETWORK_STATUS_KEY = "bfa_charge_network_status";

export const SKYWELL_BATTERY_CELL_MAX_VOLT_1_NAME = "셀 최고전압 1";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_1_KEY = "Cell_Max_Volt_1";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_NAME = "셀 최고전압 1 Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_1_BOX_NO_KEY = "Cell_Max_Volt_1_Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_NAME = "셀 최고전압 1 No.";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_1_NO_KEY = "Cell_Max_Volt_1_No";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_2_NAME = "셀 최고전압 2";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_2_KEY = "Cell_Max_Volt_2";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_NAME = "셀 최고전압 2 Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_2_BOX_NO_KEY = "Cell_Max_Volt_2_Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_NAME = "셀 최고전압 2 No.";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_2_NO_KEY = "Cell_Max_Volt_2_No";

export const SKYWELL_BATTERY_CELL_MAX_VOLT_3_NAME = "셀 최고전압 3";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_3_KEY = "Cell_Max_Volt_3";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_NAME = "셀 최고전압 3 Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_3_BOX_NO_KEY = "Cell_Max_Volt_3_Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_NAME = "셀 최고전압 3 No.";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_3_NO_KEY = "Cell_Max_Volt_3_No";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_4_NAME = "셀 최고전압 4";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_4_KEY = "Cell_Max_Volt_4";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_NAME = "셀 최고전압 4 Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_4_BOX_NO_KEY = "Cell_Max_Volt_4_Box";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_NAME = "셀 최고전압 4 No.";
export const SKYWELL_BATTERY_CELL_MAX_VOLT_4_NO_KEY = "Cell_Max_Volt_4_No";

export const SKYWELL_BATTERY_CELL_MIN_VOLT_1_NAME = "셀 최저전압 1";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_1_KEY = "Cell_Min_Volt_1";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_NAME = "셀 최저전압 1 Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_1_BOX_NO_KEY = "Cell_Min_Volt_1_Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_NAME = "셀 최저전압 1 No.";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_1_NO_KEY = "Cell_Min_Volt_1_No";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_2_NAME = "셀 최저전압 2";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_2_KEY = "Cell_Min_Volt_2";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_NAME = "셀 최저전압 2 Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_2_BOX_NO_KEY = "Cell_Min_Volt_2_Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_NAME = "셀 최저전압 2 No.";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_2_NO_KEY = "Cell_Min_Volt_2_No";

export const SKYWELL_BATTERY_CELL_MIN_VOLT_3_NAME = "셀 최저전압 3";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_3_KEY = "Cell_Min_Volt_3";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_NAME = "셀 최저전압 3 Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_3_BOX_NO_KEY = "Cell_Min_Volt_3_Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_NAME = "셀 최저전압 3 No.";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_3_NO_KEY = "Cell_Min_Volt_3_No";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_4_NAME = "셀 최저전압 4";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_4_KEY = "Cell_Min_Volt_4";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_NAME = "셀 최저전압 4 Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_4_BOX_NO_KEY = "Cell_Min_Volt_4_Box";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_NAME = "셀 최저전압 4 No.";
export const SKYWELL_BATTERY_CELL_MIN_VOLT_4_NO_KEY = "Cell_Min_Volt_4_No";

export const SKYWELL_BATTERY_PACK_MAX_TEMP_1_NAME = "배터리 최고온도 1";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_1_KEY = "Pack_Max_Temp_1";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_NAME = "배터리 최고온도 1 Box";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_1_BOX_NO_KEY = "Pack_Max_Temp_1_Box";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_2_NAME = "배터리 최고온도 2";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_2_KEY = "Pack_Max_Temp_2";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_NAME = "배터리 최고온도 2 Box";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_2_BOX_NO_KEY = "Pack_Max_Temp_2_Box";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_3_NAME = "배터리 최고온도 3";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_3_KEY = "Pack_Max_Temp_3";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_NAME = "배터리 최고온도 3 Box";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_3_BOX_NO_KEY = "Pack_Max_Temp_3_Box";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_4_NAME = "배터리 최고온도 4";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_4_KEY = "Pack_Max_Temp_4";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_NAME = "배터리 최고온도 4 Box";
export const SKYWELL_BATTERY_PACK_MAX_TEMP_4_BOX_NO_KEY = "Pack_Max_Temp_4_Box";

export const SKYWELL_BATTERY_PACK_MIN_TEMP_1_NAME = "배터리 최저온도 1";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_1_KEY = "Pack_Min_Temp_1";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_NAME = "배터리 최저온도 1 Box";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_1_BOX_NO_KEY = "Pack_Min_Temp_1_Box";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_2_NAME = "배터리 최저온도 2";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_2_KEY = "Pack_Min_Temp_2";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_NAME = "배터리 최저온도 2 Box";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_2_BOX_NO_KEY = "Pack_Min_Temp_2_Box";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_3_NAME = "배터리 최저온도 3";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_3_KEY = "Pack_Min_Temp_3";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_NAME = "배터리 최저온도 3 Box";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_3_BOX_NO_KEY = "Pack_Min_Temp_3_Box";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_4_NAME = "배터리 최저온도 4";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_4_KEY = "Pack_Min_Temp_4";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_NAME = "배터리 최저온도 4 Box";
export const SKYWELL_BATTERY_PACK_MIN_TEMP_4_BOX_NO_KEY = "Pack_Min_Temp_4_Box";

export const SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_NAME = "절연측정기 경보상태";
export const SKYWELL_ISOLATION_MONITOR_ALARM_STATUS_KEY = "isolation_monitor_alarm_status";
export const SKYWELL_ISOLATION_MONITOR_STATUS_NAME = "절연측정기 상태";
export const SKYWELL_ISOLATION_MONITOR_STATUS_KEY = "isolation_monitor_status";
export const SKYWELL_ISOLATION_MONITOR_RESISTANCE_NAME = "절연저항";
export const SKYWELL_ISOLATION_MONITOR_RESISTANCE_KEY = "isolation_monitor_resistance";
export const SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_NAME = "배터리 전압";
export const SKYWELL_ISOLATION_MONITOR_BATTERY_VOLTAGE_KEY = "isolation_monitor_battery_voltage";
export const SKYWELL_ISOLATION_MONITOR_LIFE_SIGNAL_NAME = "Life Signal";
export const SKYWELL_ISOLATION_MONITOR_LIFE_SIGNAL_KEY = "isolation_monitor_life_signal";

export const SKYWELL_VCU_STATUS_VEHICLE_STATUS_NAME = "차량상태";
export const SKYWELL_VCU_STATUS_VEHICLE_STATUS_KEY = "vcu_status_vehicle_status";
export const SKYWELL_VCU_STATUS_VEHICLE_SPEED_NAME = "차량속도";
export const SKYWELL_VCU_STATUS_VEHICLE_SPEED_KEY = "vcu_status_vehicle_speed";
export const SKYWELL_VCU_STATUS_GEAR_STATUS_NAME = "기어상태";
export const SKYWELL_VCU_STATUS_GEAR_STATUS_KEY = "vcu_status_gear_status";
export const SKYWELL_VCU_STATUS_VCU_FAIL_NAME = "VCU고장";
export const SKYWELL_VCU_STATUS_VCU_FAIL_KEY = "vcu_status_vcu_fail";
export const SKYWELL_VCU_STATUS_LIFE_SIGNAL_NAME = "Life Signal";
export const SKYWELL_VCU_STATUS_LIFE_SIGNAL_KEY = "vcu_status_life_signal";

export const SKYWELL_MCU_STATUS_1_MOTOR_TEMP_NAME = "모터 온도";
export const SKYWELL_MCU_STATUS_1_MOTOR_TEMP_KEY = "mcu_status1_motor_temp";
export const SKYWELL_MCU_STATUS_1_MCU_TEMP_NAME = "모터컨트롤러 온도";
export const SKYWELL_MCU_STATUS_1_MCU_TEMP_KEY = "mcu_status1_motor_controller_temp";
export const SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_NAME = "컨버터 전압";
export const SKYWELL_MCU_STATUS_1_CONVERTER_VOLT_KEY = "mcu_status1_converter_temp";

export const SKYWELL_MCU_STATUS_2_MOTOR_RPM_NAME = "모터 회전속도";
export const SKYWELL_MCU_STATUS_2_MOTOR_RPM_KEY = "mcu_status2_motor_rpm";
export const SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_NAME = "모터 출력 토크";
export const SKYWELL_MCU_STATUS_2_MOTOR_TORQUE_KEY = "mcu_status2_motor_torque";

export const SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_NAME = "저압 배터리 전압";
export const SKYWELL_VEHICLE_STATUS_LOW_BAT_VOLT_KEY = "vehicle_status_low_bat_volt";
export const SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_NAME = "우회전 상태";
export const SKYWELL_VEHICLE_STATUS_RIGHT_TURN_STATUS_KEY = "vehicle_status_right_turn_status";
export const SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_NAME = "좌회전 상태";
export const SKYWELL_VEHICLE_STATUS_LEFT_TURN_STATUS_KEY = "vehicle_status_left_turn_status";
export const SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_NAME = "포지션 램프 상태";
export const SKYWELL_VEHICLE_STATUS_POSITION_LIGHT_STATUS_KEY = "vehicle_status_position_light_status";
export const SKYWELL_VEHICLE_STATUS_START_SIGNAL_NAME = "Start 신호";
export const SKYWELL_VEHICLE_STATUS_START_SIGNAL_KEY = "vehicle_status_start_signal";
export const SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_NAME = "Key On 신호";
export const SKYWELL_VEHICLE_STATUS_KEYON_SIGNAL_KEY = "vehicle_status_keyon_signal";
export const SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_NAME = "후방 도어 상태";
export const SKYWELL_VEHICLE_STATUS_REAR_DOOR_STATUS_KEY = "vehicle_status_rear_door_status";
export const SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_NAME = "승차문 상태";
export const SKYWELL_VEHICLE_STATUS_FRONT_DOOR_STATUS_KEY = "vehicle_status_front_door_status";
export const SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_NAME = "하차문 상태";
export const SKYWELL_VEHICLE_STATUS_MIDDLE_DOOR_STATUS_KEY = "vehicle_status_middle_door_status";
export const SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_NAME = "모터 냉각수 부족 경보";
export const SKYWELL_VEHICLE_STATUS_MOTOR_COOLING_WATER_ALARM_KEY = "vehicle_status_motor_cooling_water_alarm";
export const SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_NAME = "도어 펌프 에어 경보";
export const SKYWELL_VEHICLE_STATUS_DOOR_PUMP_AIR_ALARM_KEY = "vehicle_status_door_pump_air_alarm";
export const SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_NAME = "기사 자리비움 경보";
export const SKYWELL_VEHICLE_STATUS_DRIVER_LEAVING_ALARM_KEY = "vehicle_status_driver_leaving_alarm";
export const SKYWELL_VEHICLE_STATUS_STOP_STATUS_NAME = "STOP 상태";
export const SKYWELL_VEHICLE_STATUS_STOP_STATUS_KEY = "vehicle_status_stop_status";
export const SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_NAME = "수동 브레이크 상태";
export const SKYWELL_VEHICLE_STATUS_HANDBRAKE_STATUS_KEY = "vehicle_status_handbrake_status";
export const SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_NAME = "전방 에어 경보";
export const SKYWELL_VEHICLE_STATUS_FRONT_AIR_PRESSURE_ALARM_KEY = "vehicle_status_front_air_pressure_alarm";
export const SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_NAME = "후방 에어 경보";
export const SKYWELL_VEHICLE_STATUS_REAR_AIR_PRESSURE_ALARM_KEY = "vehicle_status_rear_air_pressure_alarm";
export const SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_NAME = "전방 브레이크 공기압";
export const SKYWELL_VEHICLE_STATUS_FRONT_BRAKE_AIR_PRESSURE_KEY = "vehicle_status_front_brake_air_pressure";
export const SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_NAME = "후방 브레이크 공기압";
export const SKYWELL_VEHICLE_STATUS_REAR_BRAKE_AIR_PRESSURE_KEY = "vehicle_status_rear_brake_air_pressure";
export const SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_NAME = "Hydrogen Port Hatch";
export const SKYWELL_VEHICLE_STATUS_HYDROGEN_PORT_HATCH_STATUS_KEY = "vehicle_status_hydrogen_port_hatch_status";
export const SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_NAME = "ACC 응답상태";
export const SKYWELL_VEHICLE_STATUS_ACC_WAKEUP_STATUS_KEY = "vehicle_status_acc_wakeup_status";
export const SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_NAME = "스마트키 상태";
export const SKYWELL_VEHICLE_STATUS_SMARTKEY_STATUS_KEY = "vehicle_status_smartkey_status";
export const SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_NAME = "후방 안개등";
export const SKYWELL_VEHICLE_STATUS_REAR_FOG_LIGHT_KEY = "vehicle_status_rear_fog_light";
export const SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_NAME = "전방 안개등";
export const SKYWELL_VEHICLE_STATUS_FRONT_FOG_LIGHT_KEY = "vehicle_status_front_fog_light";
export const SKYWELL_VEHICLE_STATUS_LOW_BEAM_NAME = "하향등";
export const SKYWELL_VEHICLE_STATUS_LOW_BEAM_KEY = "vehicle_status_low_beam";
export const SKYWELL_VEHICLE_STATUS_HIGH_BEAM_NAME = "상향등";
export const SKYWELL_VEHICLE_STATUS_HIGH_BEAM_KEY = "vehicle_status_high_beam";
export const SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_NAME = "제동등";
export const SKYWELL_VEHICLE_STATUS_BRAKE_LIGHT_KEY = "vehicle_status_brake_light";
export const SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_NAME = "우측후방 브레이크슈";
export const SKYWELL_VEHICLE_STATUS_RIGHT_REAR_BRAKE_SHOE_ALARM_KEY = "vehicle_status_right_rear_brake_shoe_alarm";
export const SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_NAME = "좌측후방 브레이크슈";
export const SKYWELL_VEHICLE_STATUS_LEFT_REAR_BRAKE_SHOE_ALARM_KEY = "vehicle_status_left_rear_brake_shoe_alarm";
export const SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_NAME = "우측전방 브레이크슈";
export const SKYWELL_VEHICLE_STATUS_RIGHT_FRONT_BRAKE_SHOE_ALARM_KEY = "vehicle_status_right_front_brake_shoe_alarm";
export const SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_NAME = "좌측전방 브레이크슈";
export const SKYWELL_VEHICLE_STATUS_LEFT_FRONT_BRAKE_SHOE_ALARM_KEY = "vehicle_status_left_front_brake_shoe_alarm";

export const SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_NAME = "총 주행거리";
export const SKYWELL_VEHICLE_DRIVIING_INFO_TOTAL_MILEAGE_KEY = "vehicle_driving_info_total_mileage";
export const SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_NAME = "통신프로토콜 버전";
export const SKYWELL_VEHICLE_DRIVIING_INFO_PROTOCOL_VER_KEY = "vehicle_driving_info_protocol_ver";
export const SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_NAME = "계기판 프로그램 버전";
export const SKYWELL_VEHICLE_DRIVIING_INFO_PROGRAM_VER_KEY = "vehicle_driving_info_program_ver";
//#endregion

//#region Bongo3
export const BONGO3_BATTERY_PACK_CURRENT_NAME = "배터리 전류";
export const BONGO3_BATTERY_PACK_CURRENT_KEY = "battery_pack_current";
export const BONGO3_BATTERY_PACK_VOLTAGE_NAME = "배터리 전압";
export const BONGO3_BATTERY_PACK_VOLTAGE_KEY = "battery_pack_voltage";
export const BONGO3_BATTERY_MAX_TEMPERATURE_NAME = "배터리 최고온도";
export const BONGO3_BATTERY_MAX_TEMPERATURE_KEY = "battery_max_temperature";
export const BONGO3_BATTERY_MIN_TEMPERATURE_NAME = "배터리 최저온도";
export const BONGO3_BATTERY_MIN_TEMPERATURE_KEY = "battery_min_temperature";
export const BONGO3_BATTERY_SOC_NAME = "SOC";
export const BONGO3_BATTERY_SOC_KEY = "battery_soc";
export const BONGO3_BATTERY_SOH_NAME = "SOH";
export const BONGO3_BATTERY_SOH_KEY = "battery_soh";

export const BONGO3_MAX_CELL_VOLTAGE_NAME = "Cell 최고전압";
export const BONGO3_MAX_CELL_VOLTAGE_KEY = "max_cell_voltage";
export const BONGO3_MAX_CELL_VOLTAGE_NUM_NAME = "Cell 최고전압 No.";
export const BONGO3_MAX_CELL_VOLTAGE_NUM_KEY = "max_cell_voltage_num";
export const BONGO3_MIN_CELL_VOLTAGE_NAME = "Cell 최저전압";
export const BONGO3_MIN_CELL_VOLTAGE_KEY = "min_cell_voltage";
export const BONGO3_MIN_CELL_VOLTAGE_NUM_NAME = "Cell 최저전압 No.";
export const BONGO3_MIN_CELL_VOLTAGE_NUM_KEY = "min_cell_voltage_num";
export const BONGO3_SUB_BATTERY_VOLTAGE_NAME = "보조배터리 전압";
export const BONGO3_SUB_BATTERY_VOLTAGE_KEY = "sub_battery_voltage";
export const BONGO3_ISOLATION_RESISTANCE_NAME = "절연저항";
export const BONGO3_ISOLATION_RESISTANCE_KEY = "isolation_resistance";

export const BONGO3_GEAR_STATUS_NAME = "기어";
export const BONGO3_GEAR_STATUS_KEY = "gear_status";
export const BONGO3_ACCEL_PEDAL_STATUS_NAME = "엑셀 페달";
export const BONGO3_ACCEL_PEDAL_STATUS_KEY = "accel_pedal_status";
export const BONGO3_VEHICLE_SPEED_NAME = "속도";
export const BONGO3_VEHICLE_SPEED_KEY = "vehicle_speed";
export const BONGO3_IGNITION_STATUS_NAME = "키 상태";
export const BONGO3_IGNITION_STATUS_KEY = "ignition_status";
/////////////////////////////////////////////////////////////////////////

//#region TSEco
export const TSECO_EVINFO_VOLT_NAME = "전압";
export const TSECO_EVINFO_VOLT_KEY = "evinfo_volt";
export const TSECO_EVINFO_SOC_NAME = "SOC";
export const TSECO_EVINFO_SOC_KEY = "evinfo_soc";
export const TSECO_EVINFO_MOTOR_RPM_NAME = "모터회전속도";
export const TSECO_EVINFO_MOTOR_RPM_KEY = "evinfo_motor_rpm";
export const TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_NAME = "모터제어기 입력전압";
export const TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_KEY = "evinfo_motor_controller_input_volt";
export const TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_NAME = "모터제어기 온도";
export const TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_KEY = "evinfo_motor_controller_temp";
export const TSECO_EVINFO_MOTOR_TEMP_NAME = "모터온도";
export const TSECO_EVINFO_MOTOR_TEMP_KEY = "evinfo_motor_temp";

export const TSECO_EVINFO1_SPEED_NAME = "차량 속도";
export const TSECO_EVINFO1_SPEED_KEY = "evinfo1_speed";
export const TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_NAME = "평균 에너지 소모";
export const TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_KEY = "evinfo1_avg_energy_consumption";
export const TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_NAME = "순간 에너지 소모";
export const TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_KEY = "evinfo1_instant_energy_consumption";
export const TSECO_EVINFO1_MILEAGE_NAME = "주행거리";
export const TSECO_EVINFO1_MILEAGE_KEY = "evinfo1_mileage";
export const TSECO_EVINFO1_REMAIN_ENERGY_NAME = "잔여 에너지";
export const TSECO_EVINFO1_REMAIN_ENERGY_KEY = "evinfo1_remaining_energy";
export const TSECO_EVINFO1_STORAGE_BAT_VOLT_NAME = "축전지 전압";
export const TSECO_EVINFO1_STORAGE_BAT_VOLT_KEY = "evinfo1_storage_bat_volt";
export const TSECO_EVINFO1_GEAR_NAME = "기어";
export const TSECO_EVINFO1_GEAR_KEY = "evinfo1_gear";
export const TSECO_EVINFO1_ACCEL_DEPTH_NAME = "악셀레이터 깊이";
export const TSECO_EVINFO1_ACCEL_DEPTH_KEY = "evinfo1_accel_depth";

export const TSECO_EVINFO2_CELL_MAX_VOLT_NAME = "셀 최고 전압";
export const TSECO_EVINFO2_CELL_MAX_VOLT_KEY = "evinfo2_cell_max_volt";
export const TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_NAME = "셀 최고 전압 번호";
export const TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_KEY = "evinfo2_cell_max_volt_no";
export const TSECO_EVINFO2_CELL_MIN_VOLT_NAME = "셀 최저 전압";
export const TSECO_EVINFO2_CELL_MIN_VOLT_KEY = "evinfo2_cell_min_volt";
export const TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_NAME = "셀 최저 전압 번호";
export const TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_KEY = "evinfo2_cell_min_volt_no";

export const TSECO_EVINFO3_CELL_MAX_TEMP_NAME = "셀 최고 온도";
export const TSECO_EVINFO3_CELL_MAX_TEMP_KEY = "evinfo3_cell_max_temp";
export const TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_NAME = "셀 최고 온도 번호";
export const TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_KEY = "evinfo3_cell_max_temp_no";
export const TSECO_EVINFO3_CELL_MIN_TEMP_NAME = "셀 최저 온도";
export const TSECO_EVINFO3_CELL_MIN_TEMP_KEY = "evinfo3_cell_min_temp";
export const TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_NAME = "셀 최저 온도 번호";
export const TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_KEY = "evinfo3_cell_min_temp_no";
export const TSECO_EVINFO3_BAT_CURR_NAME = "배터리 전류";
export const TSECO_EVINFO3_BAT_CURR_KEY = "evinfo3_bat_curr";

export const TSECO_EVDTC_SYS_READY_NAME = "시스템 준비 상태";
export const TSECO_EVDTC_SYS_READY_KEY = "evdtc_sys_ready";
export const TSECO_EVDTC_HVR_STATUS_NAME = "고압 릴레이 상태";
export const TSECO_EVDTC_HVR_STATUS_KEY = "evdtc_hvr_status";
export const TSECO_EVDTC_SYS_STATUS_NAME = "시스템 시동 상태";
export const TSECO_EVDTC_SYS_STATUS_KEY = "evdtc_sys_status";
export const TSECO_EVDTC_MCU_TEMP_ALARM_NAME = "구동 모터 제어기 온도 알람";
export const TSECO_EVDTC_MCU_TEMP_ALARM_KEY = "evdtc_mcu_temp_alarm";
export const TSECO_EVDTC_SOC_ALARM_NAME = "SOC 알람";
export const TSECO_EVDTC_SOC_ALARM_KEY = "evdtc_soc_alarm";
export const TSECO_EVDTC_INSULATION_FAULT_NAME = "절연 고장";
export const TSECO_EVDTC_INSULATION_FAULT_KEY = "evdtc_insulation_fault";
export const TSECO_EVDTC_BMS_FAULT_NAME = "BMS 고장";
export const TSECO_EVDTC_BMS_FAULT_KEY = "evdtc_bms_fault";
export const TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_NAME = "모터 온도 과열 알람";
export const TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_KEY = "evdtc_motor_overheat_alarm";
export const TSECO_EVDTC_MOTOR_FAULT_NAME = "모터 고장";
export const TSECO_EVDTC_MOTOR_FAULT_KEY = "evdtc_motor_fault";
export const TSECO_EVDTC_MCU_COMMUNICATION_FAULT_NAME = "MCU통신 고장";
export const TSECO_EVDTC_MCU_COMMUNICATION_FAULT_KEY = "evdtc_mcu_communication_fault";
export const TSECO_EVDTC_BATTERY_FAULT_NAME = "배터리 고장";
export const TSECO_EVDTC_BATTERY_FAULT_KEY = "evdtc_battery_fault";
export const TSECO_EVDTC_BMS_COMMUNICATION_FAULT_NAME = "BMS 통신 고장";
export const TSECO_EVDTC_BMS_COMMUNICATION_FAULT_KEY = "evdtc_bms_communication_fault";
export const TSECO_EVDTC_GEAR_FAULT_NAME = "변속기 고장";
export const TSECO_EVDTC_GEAR_FAULT_KEY = "evdtc_gear_fault";
export const TSECO_EVDTC_DCDC_FAULT_NAME = "DCDC 고장";
export const TSECO_EVDTC_DCDC_FAULT_KEY = "evdtc_dcdc_fault";
export const TSECO_EVDTC_STEERING_DCAC_FAULT_NAME = "스티어링 DCAC 고장";
export const TSECO_EVDTC_STEERING_DCAC_FAULT_KEY = "evdtc_steering_dcac_fault";
export const TSECO_EVDTC_BREAK_DCAC_FAULT_NAME = "브레이크 DCAC 고장";
export const TSECO_EVDTC_BREAK_DCAC_FAULT_KEY = "evdtc_brake_dcac_fault";
export const TSECO_EVDTC_ACCEL_PEDAL_FAULT_NAME = "가속 페달 고장";
export const TSECO_EVDTC_ACCEL_PEDAL_FAULT_KEY = "evdtc_accel_pedal_fault";
export const TSECO_EVDTC_BREAK_PEDAL_FAULT_NAME = "브레이크 페달 고장";
export const TSECO_EVDTC_BREAK_PEDAL_FAULT_KEY = "evdtc_brake_pedal_fault";
export const TSECO_EVDTC_SUB_BATTERY_STATUS_NAME = "저압 축전지 충전 상태";
export const TSECO_EVDTC_SUB_BATTERY_STATUS_KEY = "evdtc_subbattery_status";
export const TSECO_EVDTC_HVIL_STATUS_NAME = "고압 인터락 상태 알람";
export const TSECO_EVDTC_HVIL_STATUS_KEY = "evdtc_hvil_status";
export const TSECO_EVDTC_VEHICLE_STATUS_NAME = "차량 상태";
export const TSECO_EVDTC_VEHICLE_STATUS_KEY = "evdtc_vehicle_status";
export const TSECO_EVDTC_HVESTOP_STATUS_NAME = "고전압 비상 정지 상태";
export const TSECO_EVDTC_HVESTOP_STATUS_KEY = "evdtc_hvestop_status";
export const TSECO_EVDTC_SNOW_MODE_STATUS_NAME = "스노우 모드";
export const TSECO_EVDTC_SNOW_MODE_STATUS_KEY = "evdtc_snow_mode_status";
export const TSECO_EVDTC_OUTPUT_LIMIT_STATUS_NAME = "출력 제한 상태";
export const TSECO_EVDTC_OUTPUT_LIMIT_STATUS_KEY = "evdtc_output_limit_status";
export const TSECO_EVDTC_HYBRID_MODE_STATUS_NAME = "하이브리드 모드 선택 상태";
export const TSECO_EVDTC_HYBRID_MODE_STATUS_KEY = "evdtc_hybrid_mode_status";
export const TSECO_EVDTC_REGENERATION_STATUS_NAME = "강제 발전 상태";
export const TSECO_EVDTC_REGENERATION_STATUS_KEY = "evdtc_regeneration_status";
export const TSECO_EVDTC_ANTI_SLIP_STATUS_NAME = "언적 주차 미끄럼 방지 상태";
export const TSECO_EVDTC_ANTI_SLIP_STATUS_KEY = "evdtc_antislip_status";
export const TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_NAME = "내리막 어시스트 상태";
export const TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_KEY = "evdtc_downhill_assist_status";

export const TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_NAME = "메인 마이너스 접촉기 제어 명령";
export const TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_KEY = "ccm_main_minus_contactor_cmd";
export const TSECO_CCM_HEATER1_CONTACTOR_CMD_NAME = "히터 1 접촉기 제어 명령";
export const TSECO_CCM_HEATER1_CONTACTOR_CMD_KEY = "ccm_heater1_contactor_cmd";
export const TSECO_CCM_AIRCON_CONTACTOR_CMD_NAME = "에어컨 접촉기 제어 명령";
export const TSECO_CCM_AIRCON_CONTACTOR_CMD_KEY = "ccm_aircon_contactor_cmd";
export const TSECO_CCM_DEFROSTER_CONTACTOR_CMD_NAME = "디프로스터 명령";
export const TSECO_CCM_DEFROSTER_CONTACTOR_CMD_KEY = "ccm_defroster_contactor_cmd";
export const TSECO_CCM_DCDC_CONTACTOR_CMD_NAME = "DCDC접촉기 제어 명령";
export const TSECO_CCM_DCDC_CONTACTOR_CMD_KEY = "ccm_dcdc_contactor_cmd";
export const TSECO_CCM_HEATER2_CONTACTOR_CMD_NAME = "히터 2 접촉기 제어 명령";
export const TSECO_CCM_HEATER2_CONTACTOR_CMD_KEY = "ccm_heater2_contactor_cmd";

export const TSECO_BAT_INFO1_SOC_NAME = "축전지 충전 상태(SOC)";
export const TSECO_BAT_INFO1_SOC_KEY = "bi1_soc";
export const TSECO_BAT_INFO1_VOLT_NAME = "동력 배터리 총 전압";
export const TSECO_BAT_INFO1_VOLT_KEY = "bi1_volt";
export const TSECO_BAT_INFO1_CURR_NAME = "동력 배터리 총 전류";
export const TSECO_BAT_INFO1_CURR_KEY = "bi1_curr";
export const TSECO_BAT_INFO1_SOH_NAME = "전원 배터리 건강 상태(SOH)";
export const TSECO_BAT_INFO1_SOH_KEY = "bi1_soh";
export const TSECO_BAT_INFO1_CHARGE_STATUS_NAME = "충전 상태";
export const TSECO_BAT_INFO1_CHARGE_STATUS_KEY = "bi1_charge_status";
export const TSECO_BAT_INFO1_AC_CHARGE_CONNECT_NAME = "AC 충전 연결";
export const TSECO_BAT_INFO1_AC_CHARGE_CONNECT_KEY = "bi1_ac_charge_connect";
export const TSECO_BAT_INFO1_DC_CHARGE_CONNECT_NAME = "DC 충전 연결";
export const TSECO_BAT_INFO1_DC_CHARGE_CONNECT_KEY = "bi1_dc_charge_connect";
export const TSECO_BAT_INFO1_BAT_PACK_COUNT_NAME = "배터리 팩 병렬 수";
export const TSECO_BAT_INFO1_BAT_PACK_COUNT_KEY = "bi1_bat_pack_count";

export const TSECO_HVRS_MAIN_PLUS_HVR_STATUS_NAME = "메인 플러스 고압 릴레이 상태";
export const TSECO_HVRS_MAIN_PLUS_HVR_STATUS_KEY = "hvrs_main_plus_hvr_status";
export const TSECO_HVRS_MAIN_MINUS_HVR_STATUS_NAME = "메인 마이너스 릴레이 상태";
export const TSECO_HVRS_MAIN_MINUS_HVR_STATUS_KEY = "hvrs_main_minus_hvr_status";
export const TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_NAME = "충전 플러스 고압 릴레이 상태";
export const TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_KEY = "hvrs_charge_plus_hvr_status";
export const TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_NAME = "충전 마이너스 고압 릴레이 상태";
export const TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_KEY = "hvrs_charge_minus_hvr_status";
export const TSECO_HVRS_HEATING_PLUS_HVR_STATUS_NAME = "히팅 플러스 고압 릴레이 상태";
export const TSECO_HVRS_HEATING_PLUS_HVR_STATUS_KEY = "hvrs_heating_plus_hvr_status";
export const TSECO_HVRS_HEATING_MINUS_HVR_STATUS_NAME = "히팅 마이너스 고압 릴레이 상태";
export const TSECO_HVRS_HEATING_MINUS_HVR_STATUS_KEY = "hvrs_heating_minus_hvr_status";
export const TSECO_HVRS_PRE_CHARGE_HVR_STATUS_NAME = "프리차지 고압 릴레이 상태";
export const TSECO_HVRS_PRE_CHARGE_HVR_STATUS_KEY = "hvrs_precharge_hvr_status";
export const TSECO_HVRS_AIRCON_HVR_STATUS_NAME = "에어컨 고압 릴레이 상태";
export const TSECO_HVRS_AIRCON_HVR_STATUS_KEY = "hvrs_aircon_hvr_status";
export const TSECO_HVRS_DEFROSTER_HVR_STATUS_NAME = "디프로스터 고압 릴레이 상태";
export const TSECO_HVRS_DEFROSTER_HVR_STATUS_KEY = "hvrs_defroster_hvr_status";
export const TSECO_HVRS_HEATER1_HVR_STATUS_NAME = "히터1 고압 릴레이 상태";
export const TSECO_HVRS_HEATER1_HVR_STATUS_KEY = "hvrs_heater1_hvr_status";
export const TSECO_HVRS_HEATER2_HVR_STATUS_NAME = "히터2 고압 릴레이 상태";
export const TSECO_HVRS_HEATER2_HVR_STATUS_KEY = "hvrs_heater2_hvr_status";
export const TSECO_HVRS_MAIN_PLUS_HVR_ALARM_NAME = "메인 플러스 고압 릴레이 알람";
export const TSECO_HVRS_MAIN_PLUS_HVR_ALARM_KEY = "hvrs_main_plus_hvr_alarm";
export const TSECO_HVRS_MAIN_MINUS_HVR_ALARM_NAME = "메인 마이너스 고압 릴레이 알람";
export const TSECO_HVRS_MAIN_MINUS_HVR_ALARM_KEY = "hvrs_main_minus_hvr_alarm";
export const TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_NAME = "충전 플러스 고압 릴레이 알람";
export const TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_KEY = "hvrs_charge_plus_hvr_alarm";
export const TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_NAME = "충전 마이너스 고압 릴레이 알람";
export const TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_KEY = "hvrs_charge_minus_hvr_alarm";
export const TSECO_HVRS_HEATING_PLUS_HVR_ALARM_NAME = "히팅 플러스 고압 릴레이 알람";
export const TSECO_HVRS_HEATING_PLUS_HVR_ALARM_KEY = "hvrs_heating_plus_hvr_alarm";
export const TSECO_HVRS_HEATING_MINUS_HVR_ALARM_NAME = "히팅 마이너스 고압 릴레이 알람";
export const TSECO_HVRS_HEATING_MINUS_HVR_ALARM_KEY = "hvrs_heating_minus_hvr_alarm";
export const TSECO_HVRS_PRE_CHARGE_HVR_ALARM_NAME = "프리 차지 고압 릴레이 알람";
export const TSECO_HVRS_PRE_CHARGE_HVR_ALARM_KEY = "hvrs_precharge_hvr_alarm";
export const TSECO_HVRS_AIRCON_HVR_ALARM_NAME = "에어컨 고압 릴레이 알람";
export const TSECO_HVRS_AIRCON_HVR_ALARM_KEY = "hvrs_aircon_hvr_alarm";
export const TSECO_HVRS_DEFROSTER_HVR_ALARM_NAME = "디프로스터 고압 릴레이 알람";
export const TSECO_HVRS_DEFROSTER_HVR_ALARM_KEY = "hvrs_defroster_hvr_alarm";
export const TSECO_HVRS_HEATER_HVR_ALARM_NAME = "히터 고압 릴레이 알람";
export const TSECO_HVRS_HEATER_HVR_ALARM_KEY = "hvrs_heater_hvr_alarm";
export const TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_NAME = "充电2 플러스 고압 릴레이 알람";
export const TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_KEY = "hvrs_charge2_plus_hvr_alarm";
export const TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_NAME = "충전2 마이너스 고압 릴레이 알람";
export const TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_KEY = "hvrs_charge2_minus_hvr_alarm";
export const TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_NAME = "충전 프리차지 시간 초과 알람";
export const TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_KEY = "hvrs_charge_precharge_time_out_alarm";
export const TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_NAME = "충전2 프리차지 시간 초과 알람";
export const TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_KEY = "hvrs_charge2_precharge_time_out_alarm";
export const TSECO_HVRS_PDU_HVIL_NAME = "PDU고압 인터락（전달）";
export const TSECO_HVRS_PDU_HVIL_KEY = "hvrs_pdu_hvil";
export const TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_NAME = "HVCM 및 VCU 통신 고장（전달)";
export const TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_KEY = "hvrs_hvcm_vcu_communication_fault";

export const TSECO_BL_MIN_VOLT_NAME = "배터리 팩 최저 방전 전압";
export const TSECO_BL_MIN_VOLT_KEY = "bl_min_volt";
export const TSECO_BL_MAX_VOLT_NAME = "배터리 팩 최고 충전 전압";
export const TSECO_BL_MAX_VOLT_KEY = "bl_max_volt";
export const TSECO_BL_MIN_CURR_NAME = "배터리 팩 최대 방전 전류";
export const TSECO_BL_MIN_CURR_KEY = "bl_min_curr";
export const TSECO_BL_MAX_CURR_NAME = "배터리 팩 최대 충전 전류";
export const TSECO_BL_MAX_CURR_KEY = "bl_max_curr";

export const TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_NAME = "고전압 누전 알람";
export const TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_KEY = "bfa_hv_short_circuit_alarm";
export const TSECO_BFA_HIGH_TEMP_ALARM_NAME = "고온 알람";
export const TSECO_BFA_HIGH_TEMP_ALARM_KEY = "bfa_high_temp_alarm";
export const TSECO_BFA_TEMP_DIFF_ALARM_NAME = "온도 차이 알람";
export const TSECO_BFA_TEMP_DIFF_ALARM_KEY = "bfa_temp_diff_alarm";
export const TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_NAME = "분기 회로 차단 고장 경보";
export const TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_KEY = "bfa_branch_circuit_brake_fault_alarm";
export const TSECO_BFA_HVIL_FAULT_ALARM_NAME = "고압 배터리 인터락 고장 알람";
export const TSECO_BFA_HVIL_FAULT_ALARM_KEY = "bfa_hvil_fault_alarm";
export const TSECO_BFA_DISCHARGE_CURRENT_ALARM_NAME = "방전 전류 알람";
export const TSECO_BFA_DISCHARGE_CURRENT_ALARM_KEY = "bfa_discharge_current_alarm";
export const TSECO_BFA_CURR_SENSOR_FAULT_ALARM_NAME = "전류 센서 고장";
export const TSECO_BFA_CURR_SENSOR_FAULT_ALARM_KEY = "bfa_curr_sensor_fault_alarm";
export const TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_NAME = "회생 충전 전류 알람";
export const TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_KEY = "bfa_regeneration_charge_curr_alarm";
export const TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_NAME = "히팅막 히팅 회로 고장";
export const TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_KEY = "bfa_heating_branch_fault_alarm";
export const TSECO_BFA_CHARGING_CURR_ALARM_NAME = "충전 전류 알람";
export const TSECO_BFA_CHARGING_CURR_ALARM_KEY = "bfa_charging_curr_alarm";
export const TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_NAME = "배터리 팩 과전압 알람";
export const TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_KEY = "bfa_bat_pack_overvolt_alarm";
export const TSECO_BFA_CELL_OVERVOLT_ALARM_NAME = "셀 과전압 알람";
export const TSECO_BFA_CELL_OVERVOLT_ALARM_KEY = "bfa_cell_overvolt_alarm";
export const TSECO_BFA_LOW_SOC_ALARM_NAME = "SOC낮음 알람";
export const TSECO_BFA_LOW_SOC_ALARM_KEY = "bfa_low_soc_alarm";
export const TSECO_BFA_CHARGE_FAULT_ALARM_NAME = "충전 고장 알람";
export const TSECO_BFA_CHARGE_FAULT_ALARM_KEY = "bfa_charge_fault_alarm";
export const TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_NAME = "배터리팩 전압 부족 알람";
export const TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_KEY = "bfa_bat_pack_low_volt_alarm";
export const TSECO_BFA_CELL_LOW_VOLT_ALARM_NAME = "셀 전압 부족 알람";
export const TSECO_BFA_CELL_LOW_VOLT_ALARM_KEY = "bfa_cell_low_volt_alarm";
export const TSECO_BFA_BAT_PACK12_STATUS_NAME = "배터리 팩 12상태";
export const TSECO_BFA_BAT_PACK12_STATUS_KEY = "bfa_bat_pack_12_status";
export const TSECO_BFA_BAT_PACK11_STATUS_NAME = "배터리 팩 11상태";
export const TSECO_BFA_BAT_PACK11_STATUS_KEY = "bfa_bat_pack_11_status";
export const TSECO_BFA_BAT_LOW_TEMP_ALARM_NAME = "배터리 온도 부족 알람";
export const TSECO_BFA_BAT_LOW_TEMP_ALARM_KEY = "bfa_bat_low_temp_alarm";
export const TSECO_BFA_CELL_VOLT_DIFF_ALARM_NAME = "셀 전압 차이 알람";
export const TSECO_BFA_CELL_VOLT_DIFF_ALARM_KEY = "bfa_cell_volt_diff_alarm";
export const TSECO_BFA_BAT_PACK10_STATUS_NAME = "배터리 팩 10상태";
export const TSECO_BFA_BAT_PACK10_STATUS_KEY = "bfa_bat_pack_10_status";
export const TSECO_BFA_BAT_PACK9_STATUS_NAME = "배터리 팩9상태";
export const TSECO_BFA_BAT_PACK9_STATUS_KEY = "bfa_bat_pack_9_status";
export const TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_NAME = "통신 생명 주기 계산기";
export const TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_KEY = "bfa_communication_lifecycle_calculator";
export const TSECO_BFA_LECU_COMMUNICATION_ALARM_NAME = "LECU와 통신 알람";
export const TSECO_BFA_LECU_COMMUNICATION_ALARM_KEY = "bfa_lecu_communication_alarm";
export const TSECO_BFA_CHARGER_COMMUNICATION_ALARM_NAME = "충전기 통신 알람";
export const TSECO_BFA_CHARGER_COMMUNICATION_ALARM_KEY = "bfa_charger_communication_alarm";
export const TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_NAME = "차량 통신 알람";
export const TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_KEY = "bfa_vehicle_communication_alarm";
export const TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_NAME = "BMS저전압 전원 전압 이상 알람";
export const TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_KEY = "bfa_bms_low_voltage_power_fault_alarm";
export const TSECO_BFA_BAT_STATUS_NAME = "동력 배터리 상태";
export const TSECO_BFA_BAT_STATUS_KEY = "bfa_bat_status";
export const TSECO_BFA_BAT_DISCHARGE_STATUS_NAME = "동력 배터리 방전 상태";
export const TSECO_BFA_BAT_DISCHARGE_STATUS_KEY = "bfa_bat_discharge_status";
export const TSECO_BFA_CHARGE_SAVING_SYS_ALARM_NAME = "충전 저장 시스템 알람";
export const TSECO_BFA_CHARGE_SAVING_SYS_ALARM_KEY = "bfa_charge_saving_sys_alarm";
export const TSECO_BFA_BALANCING_STATUS_NAME = "밸런싱 상태";
export const TSECO_BFA_BALANCING_STATUS_KEY = "bfa_balancing_status";
export const TSECO_BFA_BALANCING_ALARM_STATUS_NAME = "밸런싱 알람 상태";
export const TSECO_BFA_BALANCING_ALARM_STATUS_KEY = "bfa_balancing_alarm_status";
export const TSECO_BFA_HIGH_SOC_ALARM_NAME = "SOC높음 알람";
export const TSECO_BFA_HIGH_SOC_ALARM_KEY = "bfa_high_soc_alarm";
export const TSECO_BFA_SOC_JUMP_ALARM_NAME = "SOC점프 알람";
export const TSECO_BFA_SOC_JUMP_ALARM_KEY = "bfa_soc_jump_alarm";
export const TSECO_BFA_BAT_FORECAST_NAME = "동력 배터리 예보";
export const TSECO_BFA_BAT_FORECAST_KEY = "bfa_bat_forecast";
export const TSECO_BFA_BAT_PACK1_STATUS_NAME = "배터리 팩 1 상태";
export const TSECO_BFA_BAT_PACK1_STATUS_KEY = "bfa_bat_pack1_status";
export const TSECO_BFA_BAT_PACK2_STATUS_NAME = "배터리 팩 2 상태";
export const TSECO_BFA_BAT_PACK2_STATUS_KEY = "bfa_bat_pack2_status";
export const TSECO_BFA_BAT_PACK3_STATUS_NAME = "배터리 팩 3 상태";
export const TSECO_BFA_BAT_PACK3_STATUS_KEY = "bfa_bat_pack3_status";
export const TSECO_BFA_BAT_PACK4_STATUS_NAME = "배터리 팩 4 상태";
export const TSECO_BFA_BAT_PACK4_STATUS_KEY = "bfa_bat_pack4_status";
export const TSECO_BFA_BAT_PACK5_STATUS_NAME = "배터리 팩 5 상태";
export const TSECO_BFA_BAT_PACK5_STATUS_KEY = "bfa_bat_pack5_status";
export const TSECO_BFA_BAT_PACK6_STATUS_NAME = "배터리 팩 6 상태";
export const TSECO_BFA_BAT_PACK6_STATUS_KEY = "bfa_bat_pack6_status";
export const TSECO_BFA_BAT_PACK7_STATUS_NAME = "배터리 팩 7 상태";
export const TSECO_BFA_BAT_PACK7_STATUS_KEY = "bfa_bat_pack7_status";
export const TSECO_BFA_BAT_PACK8_STATUS_NAME = "배터리 팩 8 상태";
export const TSECO_BFA_BAT_PACK8_STATUS_KEY = "bfa_bat_pack8_status";

export const TSECO_BI2_SYS_MIN_CELL_VOLT_NAME = "시스템 최저 셀 전압";
export const TSECO_BI2_SYS_MIN_CELL_VOLT_KEY = "bi2_sys_min_cell_volt";
export const TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_NAME = "시스템 최저 셀 전압 모듈 번호";
export const TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_KEY = "bi2_sys_min_cell_volt_module_no";
export const TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_NAME = "시스템 최저 전압 셀 번호";
export const TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_KEY = "bi2_sys_min_volt_cell_no";
export const TSECO_BI2_SYS_MAX_CELL_VOLT_NAME = "시스템 최고 셀 전압";
export const TSECO_BI2_SYS_MAX_CELL_VOLT_KEY = "bi2_sys_max_cell_volt";
export const TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_NAME = "시스템 최고 셀 전압 모듈 번호";
export const TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_KEY = "bi2_sys_max_cell_volt_module_no";
export const TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_NAME = "시스템 최고 전압 셀 번호";
export const TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_KEY = "bi2_sys_max_volt_cell_no";

export const TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_NAME = "시스템 최저 온도 샘플링 지점 온도";
export const TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_KEY = "bmi_sys_min_temp_sampling_spot_temp";
export const TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_NAME = "시스템 최저 온도 샘플링 지점 모듈 번호";
export const TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_KEY = "bmi_sys_min_temp_sampling_spot_module_no";
export const TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_NAME = "시스템 최저 온도 샘플링 지점 번호";
export const TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_KEY = "bmi_sys_min_temp_sampling_spot_no";
export const TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_NAME = "시스템 최고 온도 샘플링 지점 온도";
export const TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_KEY = "bmi_sys_max_temp_sampling_spot_temp";
export const TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_NAME = "시스템 최고 온도 샘플링 지점 모듈 번호";
export const TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_KEY = "bmi_sys_max_temp_sampling_spot_module_no";
export const TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_NAME = "시스템 최고 온도 샘플링 지점 번호";
export const TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_KEY = "bmi_sys_max_temp_sampling_spot_no";

export const TSECO_CV1_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_CV1_BAT_MODULE_NO_KEY = "cv1_bat_module_no";
export const TSECO_CV1_CELL_VOLT1_NAME = "1번 셀 전압";
export const TSECO_CV1_CELL_VOLT1_KEY = "cv1_cell_volt1";
export const TSECO_CV1_CELL_VOLT2_NAME = "2번 셀 전압";
export const TSECO_CV1_CELL_VOLT2_KEY = "cv1_cell_volt2";
export const TSECO_CV1_CELL_VOLT3_NAME = "3번 셀 전압";
export const TSECO_CV1_CELL_VOLT3_KEY = "cv1_cell_volt3";
export const TSECO_CV1_CELL_VOLT4_NAME = "4번 셀 전압";
export const TSECO_CV1_CELL_VOLT4_KEY = "cv1_cell_volt4";
export const TSECO_CV1_CELL_VOLT5_NAME = "5번 셀 전압";
export const TSECO_CV1_CELL_VOLT5_KEY = "cv1_cell_volt5";
export const TSECO_CV1_CELL_VOLT6_NAME = "6번 셀 전압";
export const TSECO_CV1_CELL_VOLT6_KEY = "cv1_cell_volt6";
export const TSECO_CV1_CELL_VOLT7_NAME = "7번 셀 전압";
export const TSECO_CV1_CELL_VOLT7_KEY = "cv1_cell_volt7";

export const TSECO_CV2_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_CV2_BAT_MODULE_NO_KEY = "cv2_bat_module_no";
export const TSECO_CV2_CELL_VOLT8_NAME = "8번 셀 전압";
export const TSECO_CV2_CELL_VOLT8_KEY = "cv2_cell_volt8";
export const TSECO_CV2_CELL_VOLT9_NAME = "9번 셀 전압";
export const TSECO_CV2_CELL_VOLT9_KEY = "cv2_cell_volt9";
export const TSECO_CV2_CELL_VOLT10_NAME = "10번 셀 전압";
export const TSECO_CV2_CELL_VOLT10_KEY = "cv2_cell_volt10";
export const TSECO_CV2_CELL_VOLT11_NAME = "11번 셀 전압";
export const TSECO_CV2_CELL_VOLT11_KEY = "cv2_cell_volt11";
export const TSECO_CV2_CELL_VOLT12_NAME = "12번 셀 전압";
export const TSECO_CV2_CELL_VOLT12_KEY = "cv2_cell_volt12";
export const TSECO_CV2_CELL_VOLT13_NAME = "13번 셀 전압";
export const TSECO_CV2_CELL_VOLT13_KEY = "cv2_cell_volt13";
export const TSECO_CV2_CELL_VOLT14_NAME = "14번 셀 전압";
export const TSECO_CV2_CELL_VOLT14_KEY = "cv2_cell_volt14";

export const TSECO_CV3_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_CV3_BAT_MODULE_NO_KEY = "cv3_bat_module_no";
export const TSECO_CV3_CELL_VOLT15_NAME = "15번 셀 전압";
export const TSECO_CV3_CELL_VOLT15_KEY = "cv3_cell_volt15";
export const TSECO_CV3_CELL_VOLT16_NAME = "16번 셀 전압";
export const TSECO_CV3_CELL_VOLT16_KEY = "cv3_cell_volt16";
export const TSECO_CV3_CELL_VOLT17_NAME = "17번 셀 전압";
export const TSECO_CV3_CELL_VOLT17_KEY = "cv3_cell_volt17";
export const TSECO_CV3_CELL_VOLT18_NAME = "18번 셀 전압";
export const TSECO_CV3_CELL_VOLT18_KEY = "cv3_cell_volt18";
export const TSECO_CV3_CELL_VOLT19_NAME = "19번 셀 전압";
export const TSECO_CV3_CELL_VOLT19_KEY = "cv3_cell_volt19";
export const TSECO_CV3_CELL_VOLT20_NAME = "20번 셀 전압";
export const TSECO_CV3_CELL_VOLT20_KEY = "cv3_cell_volt20";
export const TSECO_CV3_CELL_VOLT21_NAME = "21번 셀 전압";
export const TSECO_CV3_CELL_VOLT21_KEY = "cv3_cell_volt21";

export const TSECO_CV4_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_CV4_BAT_MODULE_NO_KEY = "cv4_bat_module_no";
export const TSECO_CV4_CELL_VOLT22_NAME = "22번 셀 전압";
export const TSECO_CV4_CELL_VOLT22_KEY = "cv4_cell_volt22";
export const TSECO_CV4_CELL_VOLT23_NAME = "23번 셀 전압";
export const TSECO_CV4_CELL_VOLT23_KEY = "cv4_cell_volt23";
export const TSECO_CV4_CELL_VOLT24_NAME = "24번 셀 전압";
export const TSECO_CV4_CELL_VOLT24_KEY = "cv4_cell_volt24";
export const TSECO_CV4_CELL_VOLT25_NAME = "25번 셀 전압";
export const TSECO_CV4_CELL_VOLT25_KEY = "cv4_cell_volt25";
export const TSECO_CV4_CELL_VOLT26_NAME = "26번 셀 전압";
export const TSECO_CV4_CELL_VOLT26_KEY = "cv4_cell_volt26";
export const TSECO_CV4_CELL_VOLT27_NAME = "27번 셀 전압";
export const TSECO_CV4_CELL_VOLT27_KEY = "cv4_cell_volt27";
export const TSECO_CV4_CELL_VOLT28_NAME = "28번 셀 전압";
export const TSECO_CV4_CELL_VOLT28_KEY = "cv4_cell_volt28";

export const TSECO_CV5_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_CV5_BAT_MODULE_NO_KEY = "cv5_bat_module_no";
export const TSECO_CV5_CELL_VOLT29_NAME = "29번 셀 전압";
export const TSECO_CV5_CELL_VOLT29_KEY = "cv5_cell_volt29";
export const TSECO_CV5_CELL_VOLT30_NAME = "30번 셀 전압";
export const TSECO_CV5_CELL_VOLT30_KEY = "cv5_cell_volt30";
export const TSECO_CV5_CELL_VOLT31_NAME = "31번 셀 전압";
export const TSECO_CV5_CELL_VOLT31_KEY = "cv5_cell_volt31";
export const TSECO_CV5_CELL_VOLT32_NAME = "32번 셀 전압";
export const TSECO_CV5_CELL_VOLT32_KEY = "cv5_cell_volt32";
export const TSECO_CV5_CELL_VOLT33_NAME = "33번 셀 전압";
export const TSECO_CV5_CELL_VOLT33_KEY = "cv5_cell_volt33";
export const TSECO_CV5_CELL_VOLT34_NAME = "34번 셀 전압";
export const TSECO_CV5_CELL_VOLT34_KEY = "cv5_cell_volt34";
export const TSECO_CV5_CELL_VOLT35_NAME = "35번 셀 전압";
export const TSECO_CV5_CELL_VOLT35_KEY = "cv5_cell_volt35";

export const TSECO_CV6_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_CV6_BAT_MODULE_NO_KEY = "cv6_bat_module_no";
export const TSECO_CV6_CELL_VOLT36_NAME = "36번 셀 전압";
export const TSECO_CV6_CELL_VOLT36_KEY = "cv6_cell_volt36";
export const TSECO_CV6_CELL_VOLT37_NAME = "37번 셀 전압";
export const TSECO_CV6_CELL_VOLT37_KEY = "cv6_cell_volt37";
export const TSECO_CV6_CELL_VOLT38_NAME = "38번 셀 전압";
export const TSECO_CV6_CELL_VOLT38_KEY = "cv6_cell_volt38";
export const TSECO_CV6_CELL_VOLT39_NAME = "39번 셀 전압";
export const TSECO_CV6_CELL_VOLT39_KEY = "cv6_cell_volt39";
export const TSECO_CV6_CELL_VOLT40_NAME = "40번 셀 전압";
export const TSECO_CV6_CELL_VOLT40_KEY = "cv6_cell_volt40";
export const TSECO_CV6_CELL_VOLT41_NAME = "41번 셀 전압";
export const TSECO_CV6_CELL_VOLT41_KEY = "cv6_cell_volt41";
export const TSECO_CV6_CELL_VOLT42_NAME = "42번 셀 전압";
export const TSECO_CV6_CELL_VOLT42_KEY = "cv6_cell_volt42";

export const TSECO_MBT1_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_MBT1_BAT_MODULE_NO_KEY = "mbt1_bat_module_no";
export const TSECO_MBT1_MODULE1_SAMPLING_TEMP_NAME = "모듈 1번째 샘플링 온도";
export const TSECO_MBT1_MODULE1_SAMPLING_TEMP_KEY = "mbt1_module1_sampling_temp";
export const TSECO_MBT1_MODULE2_SAMPLING_TEMP_NAME = "모듈 2번째 샘플링 온도";
export const TSECO_MBT1_MODULE2_SAMPLING_TEMP_KEY = "mbt1_module2_sampling_temp";
export const TSECO_MBT1_MODULE3_SAMPLING_TEMP_NAME = "모듈 3번째 샘플링 온도";
export const TSECO_MBT1_MODULE3_SAMPLING_TEMP_KEY = "mbt1_module3_sampling_temp";
export const TSECO_MBT1_MODULE4_SAMPLING_TEMP_NAME = "모듈 4번째 샘플링 온도";
export const TSECO_MBT1_MODULE4_SAMPLING_TEMP_KEY = "mbt1_module4_sampling_temp";
export const TSECO_MBT1_MODULE5_SAMPLING_TEMP_NAME = "모듈 5번째 샘플링 온도";
export const TSECO_MBT1_MODULE5_SAMPLING_TEMP_KEY = "mbt1_module5_sampling_temp";
export const TSECO_MBT1_MODULE6_SAMPLING_TEMP_NAME = "모듈 6번째 샘플링 온도";
export const TSECO_MBT1_MODULE6_SAMPLING_TEMP_KEY = "mbt1_module6_sampling_temp";
export const TSECO_MBT1_MODULE7_SAMPLING_TEMP_NAME = "모듈 7번째 샘플링 온도";
export const TSECO_MBT1_MODULE7_SAMPLING_TEMP_KEY = "mbt1_module7_sampling_temp";

export const TSECO_MBT2_BAT_MODULE_NO_NAME = "축전지 모듈 번호";
export const TSECO_MBT2_BAT_MODULE_NO_KEY = "mbt2_bat_module_no";
export const TSECO_MBT2_MODULE8_SAMPLING_TEMP_NAME = "모듈 8번째 샘플링 온도";
export const TSECO_MBT2_MODULE8_SAMPLING_TEMP_KEY = "mbt2_module8_sampling_temp";
export const TSECO_MBT2_MODULE9_SAMPLING_TEMP_NAME = "모듈 9번째 샘플링 온도";
export const TSECO_MBT2_MODULE9_SAMPLING_TEMP_KEY = "mbt2_module9_sampling_temp";
export const TSECO_MBT2_MODULE10_SAMPLING_TEMP_NAME = "모듈 10번째 샘플링 온도";
export const TSECO_MBT2_MODULE10_SAMPLING_TEMP_KEY = "mbt2_module10_sampling_temp";
export const TSECO_MBT2_MODULE11_SAMPLING_TEMP_NAME = "모듈 11번째 샘플링 온도";
export const TSECO_MBT2_MODULE11_SAMPLING_TEMP_KEY = "mbt2_module11_sampling_temp";
export const TSECO_MBT2_MODULE12_SAMPLING_TEMP_NAME = "모듈 12번째 샘플링 온도";
export const TSECO_MBT2_MODULE12_SAMPLING_TEMP_KEY = "mbt12_module5_sampling_temp";
export const TSECO_MBT2_MODULE13_SAMPLING_TEMP_NAME = "모듈 13번째 샘플링 온도";
export const TSECO_MBT2_MODULE13_SAMPLING_TEMP_KEY = "mbt2_module13_sampling_temp";
export const TSECO_MBT2_MODULE14_SAMPLING_TEMP_NAME = "모듈 14번째 샘플링 온도";
export const TSECO_MBT2_MODULE14_SAMPLING_TEMP_KEY = "mbt2_module14_sampling_temp";

export const TSECO_IM_INSUL_HIGH_NAME = "절연저항 하이바이트";
export const TSECO_IM_INSUL_HIGH_KEY = "im_insul_high";
export const TSECO_IM_INSUL_LOW_NAME = "절연저항 로우바이트";
export const TSECO_IM_INSUL_LOW_KEY = "im_insul_low";
export const TSECO_IM_BUS_VOLT_HIGH_NAME = "버스 전압 하이바이트";
export const TSECO_IM_BUS_VOLT_HIGH_KEY = "im_bus_volt_high";
export const TSECO_IM_BUS_VOLT_LOW_NAME = "버스 전압 로우바이트";
export const TSECO_IM_BUS_VOLT_LOW_KEY = "im_bus_volt_low";
export const TSECO_IM_FAULT_LEVEL_NAME = "고장 등급";
export const TSECO_IM_FAULT_LEVEL_KEY = "im_fault_level";
export const TSECO_IM_ALARM_STATUS_NAME = "알람 상태";
export const TSECO_IM_ALARM_STATUS_KEY = "im_alarm_status";
export const TSECO_IM_LIFE_VALUE_NAME = "Life 값";
export const TSECO_IM_LIFE_VALUE_KEY = "im_life_value";

export const TSECO_VM1_CHANNEL1_HIGH_NAME = "1번째 전압 채널의 하이 바이트";
export const TSECO_VM1_CHANNEL1_HIGH_KEY = "vm1_channel1_high";
export const TSECO_VM1_CHANNEL1_LOW_NAME = "1번째 전압 채널의 로우 바이트";
export const TSECO_VM1_CHANNEL1_LOW_KEY = "vm1_channel1_low";
export const TSECO_VM1_CHANNEL2_HIGH_NAME = "2번째 전압 채널의 하이 바이트";
export const TSECO_VM1_CHANNEL2_HIGH_KEY = "vm1_channel2_high";
export const TSECO_VM1_CHANNEL2_LOW_NAME = "2번째 전압 채널의 로우 바이트";
export const TSECO_VM1_CHANNEL2_LOW_KEY = "vm1_channel2_low";
export const TSECO_VM1_CHANNEL3_HIGH_NAME = "3번째 전압 채널의 하이 바이트";
export const TSECO_VM1_CHANNEL3_HIGH_KEY = "vm1_channel3_high";
export const TSECO_VM1_CHANNEL3_LOW_NAME = "3번째 전압 채널의 로우 바이트";
export const TSECO_VM1_CHANNEL3_LOW_KEY = "vm1_channel3_low";
export const TSECO_VM1_CHANNEL4_HIGH_NAME = "4번째 전압 채널의 하이 바이트";
export const TSECO_VM1_CHANNEL4_HIGH_KEY = "vm1_channel4_high";
export const TSECO_VM1_CHANNEL4_LOW_NAME = "4번째 전압 채널의 로우 바이트";
export const TSECO_VM1_CHANNEL4_LOW_KEY = "vm1_channel4_low";

export const TSECO_VM2_CHANNEL5_HIGH_NAME = "5번째 전압 채널의 하이 바이트";
export const TSECO_VM2_CHANNEL5_HIGH_KEY = "vm2_channel5_high";
export const TSECO_VM2_CHANNEL5_LOW_NAME = "5번째 전압 채널의 로우 바이트";
export const TSECO_VM2_CHANNEL5_LOW_KEY = "vm2_channel5_low";
export const TSECO_VM2_CHANNEL6_HIGH_NAME = "6번째 전압 채널의 하이 바이트";
export const TSECO_VM2_CHANNEL6_HIGH_KEY = "vm2_channel6_high";
export const TSECO_VM2_CHANNEL6_LOW_NAME = "6번째 전압 채널의 로우 바이트";
export const TSECO_VM2_CHANNEL6_LOW_KEY = "vm2_channel6_low";
export const TSECO_VM2_CHANNEL7_HIGH_NAME = "7번째 전압 채널의 하이 바이트";
export const TSECO_VM2_CHANNEL7_HIGH_KEY = "vm2_channel7_high";
export const TSECO_VM2_CHANNEL7_LOW_NAME = "7번째 전압 채널의 로우 바이트";
export const TSECO_VM2_CHANNEL7_LOW_KEY = "vm2_channel7_low";
export const TSECO_VM2_CHANNEL8_HIGH_NAME = "8번째 전압 채널의 하이 바이트";
export const TSECO_VM2_CHANNEL8_HIGH_KEY = "vm2_channel8_high";
export const TSECO_VM2_CHANNEL8_LOW_NAME = "8번째 전압 채널의 로우 바이트";
export const TSECO_VM2_CHANNEL8_LOW_KEY = "vm2_channel8_low";

export const TSECO_VM3_CHANNEL9_HIGH_NAME = "9번째 전압 채널의 하이 바이트";
export const TSECO_VM3_CHANNEL9_HIGH_KEY = "vm3_channel9_high";
export const TSECO_VM3_CHANNEL9_LOW_NAME = "9번째 전압 채널의 로우 바이트";
export const TSECO_VM3_CHANNEL9_LOW_KEY = "vm3_channel9_low";
export const TSECO_VM3_CHANNEL10_HIGH_NAME = "10번째 전압 채널의 하이 바이트";
export const TSECO_VM3_CHANNEL10_HIGH_KEY = "vm3_channel0_high";
export const TSECO_VM3_CHANNEL10_LOW_NAME = "10번째 전압 채널의 로우 바이트";
export const TSECO_VM3_CHANNEL10_LOW_KEY = "vm3_channel0_low";
export const TSECO_VM3_CHANNEL11_HIGH_NAME = "11번째 전압 채널의 하이 바이트";
export const TSECO_VM3_CHANNEL11_HIGH_KEY = "vm3_channel11_high";
export const TSECO_VM3_CHANNEL11_LOW_NAME = "11번째 전압 채널의 로우 바이트";
export const TSECO_VM3_CHANNEL11_LOW_KEY = "vm3_channel11_low";
export const TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_NAME = "인터락 신호 채널0 상태";
export const TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_KEY = "vm3_interlock_channel0_status";
export const TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_NAME = "인터락 신호 채널1 상태";
export const TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_KEY = "vm3_interlock_channel1_status";

export const TSECO_VI_PARKING_BRAKE_STATUS_NAME = "파킹 브레이크";
export const TSECO_VI_PARKING_BRAKE_STATUS_KEY = "vi_parking_brake_status";
export const TSECO_VI_SPEED_NAME = "속도";
export const TSECO_VI_SPEED_KEY = "vi_speed";
export const TSECO_VI_FOOT_BRAKE_STATUS_NAME = "브레이크";
export const TSECO_VI_FOOT_BRAKE_STATUS_KEY = "vi_foot_brake_status";
export const TSECO_VI_FRONT_DOOR_SWITCH_NAME = "앞문 스위치";
export const TSECO_VI_FRONT_DOOR_SWITCH_KEY = "vi_front_door_switch";
export const TSECO_VI_BACK_DOOR_SWITCH_NAME = "뒷문 스위치";
export const TSECO_VI_BACK_DOOR_SWITCH_KEY = "vi_back_door_switch";
export const TSECO_VI_BRAKE_AIR_PRESSURE1_NAME = "브레이크 에어 압력1";
export const TSECO_VI_BRAKE_AIR_PRESSURE1_KEY = "vi_brake_air_pressure1";
export const TSECO_VI_BRAKE_AIR_PRESSURE2_NAME = "브레이크 에어 압력2";
export const TSECO_VI_BRAKE_AIR_PRESSURE2_KEY = "vi_brake_air_pressure2";
export const TSECO_VI_BRAKE_AIR_PRESSURE3_NAME = "브레이크 에어 압력3";
export const TSECO_VI_BRAKE_AIR_PRESSURE3_KEY = "vi_brake_air_pressure3";

export const TSECO_DI2_AIR_PRESSURE2_NAME = "에어 압력2알람";
export const TSECO_DI2_AIR_PRESSURE2_KEY = "di2_air_pressure2";
export const TSECO_DI2_HYDRAULIC_NAME = "유압 알람";
export const TSECO_DI2_HYDRAULIC_KEY = "di2_hydraulic";
export const TSECO_DI2_COOLANT_LEVEL_NAME = "냉각수위 알람";
export const TSECO_DI2_COOLANT_LEVEL_KEY = "di2_coolant_level";
export const TSECO_DI2_AIR_CLEENER_FILTER_NAME = "공기 정화 필터 알람";
export const TSECO_DI2_AIR_CLEENER_FILTER_KEY = "di2_air_cleaner_filter";
export const TSECO_DI2_FUEL_FILTER_NAME = "연료 필터 경보";
export const TSECO_DI2_FUEL_FILTER_KEY = "di2_fuel_filter";
export const TSECO_DI2_OIL_FILTER_NAME = "오일 필터 경보";
export const TSECO_DI2_OIL_FILTER_KEY = "di2_oil_filter";
export const TSECO_DI2_WIPER_HIGH_SPEED_GEAR_NAME = "와이퍼 고속 기어";
export const TSECO_DI2_WIPER_HIGH_SPEED_GEAR_KEY = "di2_wiper_high_speed_gear";
export const TSECO_DI2_WIPER_LOW_SPEED_GEAR_NAME = "와이퍼 저속 기어";
export const TSECO_DI2_WIPER_LOW_SPEED_GEAR_KEY = "di2_wiper_low_speed_gear";
export const TSECO_DI2_ENGINE_NAME = "엔진 진단";
export const TSECO_DI2_ENGINE_KEY = "di2_engine";
export const TSECO_DI2_CHARGE_SIGN_NAME = "충전 표시(작동 신호)";
export const TSECO_DI2_CHARGE_SIGN_KEY = "di2_charge_sign";
export const TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_NAME = "좌측 앞 브레이크 패드 마모";
export const TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_KEY = "di2_left_front_brake_pad_wear";
export const TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_NAME = "좌측 중간 브레이크 패드 마모";
export const TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_KEY = "di2_left_middle_brake_pad_wear";
export const TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_NAME = "좌측 뒷 브레이크 패드 마모";
export const TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_KEY = "di2_left_back_brake_pad_wear";
export const TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_NAME = "우측 앞 브레이크 패드 마모";
export const TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_KEY = "di2_right_front_brake_pad_wear";
export const TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_NAME = "우측 중간 브레이크 패드 마모";
export const TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_KEY = "di2_right_middle_brake_pad_wear";
export const TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_NAME = "우측 뒷 브레이크 패드 마모";
export const TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_KEY = "di2_right_back_brake_pad_wear";
export const TSECO_DI2_WATER_TEMP_NAME = "수온 알람";
export const TSECO_DI2_WATER_TEMP_KEY = "di2_water_temp";
export const TSECO_DI2_VOLT_NAME = "전압 알람";
export const TSECO_DI2_VOLT_KEY = "di2_volt";
export const TSECO_DI2_CHECK_BYTE_NAME = "검증 바이트";
export const TSECO_DI2_CHECK_BYTE_KEY = "di2_check_byte";

export const TSECO_BCS1_BMS_MODE_NAME = "BMS 설정 모드";
export const TSECO_BCS1_BMS_MODE_KEY = "bcs1_bms_mode";
export const TSECO_BCS1_HIGH_VOLT_REQ_NAME = "고압 요청";
export const TSECO_BCS1_HIGH_VOLT_REQ_KEY = "bcs1_high_volt_req";
export const TSECO_BCS1_CHARGE_STATUS_NAME = "충전 상태";
export const TSECO_BCS1_CHARGE_STATUS_KEY = "bcs1_charge_status";
export const TSECO_BCS1_BMS_HVR_STATUS_NAME = "BMS 고압 릴레이 상태";
export const TSECO_BCS1_BMS_HVR_STATUS_KEY = "bcs1_bms_hvr_status";

export const TSECO_BCS2_PANEL_STATUS_NAME = "패널 상태";
export const TSECO_BCS2_PANEL_STATUS_KEY = "bcs2_panel_status";
export const TSECO_BCS2_WATER_PUMP_STATUS_NAME = "워터펌프 상태";
export const TSECO_BCS2_WATER_PUMP_STATUS_KEY = "bcs2_water_pump_status";
export const TSECO_BCS2_AIRCON_STATUS4_NAME = "에어컨 상태 4";
export const TSECO_BCS2_AIRCON_STATUS4_KEY = "bcs2_aircon_status4";
export const TSECO_BCS2_AIRCON_STATUS5_NAME = "에어컨 상태 5";
export const TSECO_BCS2_AIRCON_STATUS5_KEY = "bcs2_aircon_status5";
export const TSECO_BCS2_PLATE_WATER_IN_TEMP_NAME = "플레이트 입수 수온";
export const TSECO_BCS2_PLATE_WATER_IN_TEMP_KEY = "bcs2_plate_water_in_temp";
export const TSECO_BCS2_PLATE_WATER_OUT_TEMP_NAME = "플레이트 출수 수온";
export const TSECO_BCS2_PLATE_WATER_OUT_TEMP_KEY = "bcs2_plate_water_out_temp";
export const TSECO_BCS2_TMS_MODE_NAME = "TMS 작동모드";
export const TSECO_BCS2_TMS_MODE_KEY = "bcs2_tms_mode";
export const TSECO_BCS2_TMS_HVR_STATUS_NAME = "TMS 고전압 릴레이 상태";
export const TSECO_BCS2_TMS_HVR_STATUS_KEY = "bcs2_tms_hvr_status";
export const TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_NAME = "수로 밸브A 스위치상태";
export const TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_KEY = "bcs2_waterway_valve_a_switch";
export const TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_NAME = "수로 밸브B 스위치상태";
export const TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_KEY = "bcs2_waterway_valve_b_switch";
export const TSECO_BCS2_WATER_LEVEL_ALARM_NAME = "수위 알람";
export const TSECO_BCS2_WATER_LEVEL_ALARM_KEY = "bcs2_water_level_alarm";
export const TSECO_BCS2_TMS_FAULT_CODE_NAME = "TMS 고장코드";
export const TSECO_BCS2_TMS_FAULT_CODE_KEY = "bcs2_tms_fault_code";
export const TSECO_BCS2_TMS_FAULT_CODE_LEVEL_NAME = "TMS 고장코드 등급";
export const TSECO_BCS2_TMS_FAULT_CODE_LEVEL_KEY = "bcs2_tms_fault_code_level";

export const TSECO_IS1_MOTOR_SPEED_NAME = "모터 회전 속도";
export const TSECO_IS1_MOTOR_SPEED_KEY = "is1_motor_speed";
export const TSECO_IS1_MOTOR_TORQ_NAME = "모터 토크";
export const TSECO_IS1_MOTOR_TORQ_KEY = "is1_motor_torq";
export const TSECO_IS1_IPU_CURR_NAME = "BUS 전류";
export const TSECO_IS1_IPU_CURR_KEY = "is1_ipu_curr";
export const TSECO_IS1_FAIL_GRADE_NAME = "고장 등급";
export const TSECO_IS1_FAIL_GRADE_KEY = "is1_fail_grade";
export const TSECO_IS1_WORK_MODE_NAME = "작동 모드";
export const TSECO_IS1_WORK_MODE_KEY = "is1_work_mode";

export const TSECO_DTCI_SYS_FAULT_MODE_NAME = "시스템 고장 모드";
export const TSECO_DTCI_SYS_FAULT_MODE_KEY = "dtci_sys_fault_mode";
export const TSECO_DTCI_VCU_DTC_NAME = "VCU_DTC";
export const TSECO_DTCI_VCU_DTC_KEY = "dtci_vcu_dtc";
export const TSECO_DTCI_VCU_OCCURRENCE_CNT_NAME = "VCU_Occurrence Count";
export const TSECO_DTCI_VCU_OCCURRENCE_CNT_KEY = "dtci_vcu_occurrence_cnt";
/////////////////////////////////////////////////////////////////////////

//#region Navya
export const NAVYA_SPEED_NAME = "속도";
export const NAVYA_SPEED_KEY = "speed";

export const NAVYA_ENGINE_SPEED_NAME = "엔진 속도";
export const NAVYA_ENGINE_SPEED_KEY = "navya_engine_speed";
export const NAVYA_ACTUAL_ENGINE_TORQUE_NAME = "엔진 토크";
export const NAVYA_ACTUAL_ENGINE_TORQUE_KEY = "navya_actual_engine_torque";
export const NAVYA_ENGINE_TEMPERATURE_NAME = "엔진 온도";
export const NAVYA_ENGINE_TEMPERATURE_KEY = "navya_engine_temperature";
export const NAVYA_CONTROLLER_TEMPERATURE_NAME = "컨트롤러 온도";
export const NAVYA_CONTROLLER_TEMPERATURE_KEY = "navya_controller_temperature";

export const NAVYA_BATSYS1_MODE_NAME = "배터리 모드";
export const NAVYA_BATSYS1_MODE_KEY = "navya_batsys1_mode";
export const NAVYA_BATSYS1_SOC_NAME = "SOC";
export const NAVYA_BATSYS1_SOC_KEY = "navya_batsys1_soc";
export const NAVYA_BATSYS1_SOH_NAME = "SOH";
export const NAVYA_BATSYS1_SOH_KEY = "navya_batsys1_soh";
export const NAVYA_BATSYS1_VOLT_REAR_NAME = "전압";
export const NAVYA_BATSYS1_VOLT_REAR_KEY = "navya_batsys1_volt_rear";
export const NAVYA_BATSYS1_CURR_REAR_NAME = "전류";
export const NAVYA_BATSYS1_CURR_REAR_KEY = "navya_batsys1_curr_rear";
export const NAVYA_BATSYS1_MAX_TEMP_REAR_NAME = "최고온도";
export const NAVYA_BATSYS1_MAX_TEMP_REAR_KEY = "navya_batsys1_max_temp_rear";
export const NAVYA_BATSYS3_MIN_TEMP_REAR_NAME = "최저온도";
export const NAVYA_BATSYS3_MIN_TEMP_REAR_KEY = "navya_batsys3_min_temp_rear";

export const NAVYA_MODE_STATE_VEHICLE_MODE_NAME = "차량 모드";
export const NAVYA_MODE_STATE_VEHICLE_MODE_KEY = "navya_modestate_vehicle_mode";
export const NAVYA_MODE_STATE_VEHICLE_MODE_ACK_NAME = "차량 모드 응답";
export const NAVYA_MODE_STATE_VEHICLE_MODE_ACK_KEY = "navya_modestate_vehicle_mode_ack";
export const NAVYA_MODE_STATE_EPROM_FORMATED_NAME = "EPROM";
export const NAVYA_MODE_STATE_EPROM_FORMATED_KEY = "navya_modestate_eprom";
export const NAVYA_MODE_STATE_DEFAULT_ACK_NAME = "Default Ack";
export const NAVYA_MODE_STATE_DEFAULT_ACK_KEY = "navya_modestate_default_ack";
export const NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_NAME = "Notification";
export const NAVYA_MODE_STATE_NOTIFICATION_PRESENCE_KEY = "navya_modestate_notification";
export const NAVYA_MODE_STATE_ALERT_PRESENCE_NAME = "경고";
export const NAVYA_MODE_STATE_ALERT_PRESENCE_KEY = "navya_modestate_alert";
export const NAVYA_MODE_STATE_ALARM_PRESENCE_NAME = "알림";
export const NAVYA_MODE_STATE_ALARM_PRESENCE_KEY = "navya_modestate_alert";
export const NAVYA_MODE_STATE_URDB_NAME = "URdB";
export const NAVYA_MODE_STATE_URDB_KEY = "navya_modestate_urdb";
export const NAVYA_MODE_STATE_IRDB_NAME = "IRdB";
export const NAVYA_MODE_STATE_IRDB_KEY = "navya_modestate_irdb";

export const NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_NAME = "나브야 온도";
export const NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_NAVYA_KEY = "navya_tempventil_temperature_box_navya";
export const NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_NAME = "EVE 온도";
export const NAVYA_TEMP_VENTIL_TEMPERATURE_BOX_EVE_KEY = "navya_tempventil_temperature_box_eve";
export const NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_NAME = "Cabin 온도";
export const NAVYA_TEMP_VENTIL_TEMPERATURE_CABIN_KEY = "navya_tempventil_temperature_cabin";
export const NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_NAME = "외부 온도";
export const NAVYA_TEMP_VENTIL_TEMPERATURE_OUTSIDE_KEY = "navya_tempventil_temperature_outside";
export const NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_NAME = "Turbine Level Front";
export const NAVYA_TEMP_VENTIL_TURBINE_LVL_FRONT_KEY = "navya_tempventil_turbine_lvl_front";
export const NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_NAME = "Turbine Level Rear";
export const NAVYA_TEMP_VENTIL_TURBINE_LVL_REAR_KEY = "navya_tempventil_turbine_lvl_rear";
export const NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_NAME = "Ventilation Level Box Navya";
export const NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_NAVYA_KEY = "navya_tempventil_ventilation_lvl_box_navya";
export const NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_NAME = "Ventilation Level Box EVE";
export const NAVYA_TEMP_VENTIL_VENTILATION_LVL_BOX_EVE_KEY = "navya_tempventil_ventilation_lvl_box_eve";
/////////////////////////////////////////////////////////////////////////
