import { useEffect, useState } from "react";
import { utilAxiosExcelWithAuth } from "../../utils/customAxios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import { mapMarker } from "../../components/common/KakaoMap";

import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Request from "../../commons/request";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Utils from "../../utils/utils";
import * as RequestRunning from "../../utils/requestApiRunning";
import * as RequestCar from "../../utils/requestApiCar";

import Button from "antd/es/button/button";
import Form from "antd/es/form/index";
import Input from "antd/es/input/index";
import Row from "antd/es/grid/row";
import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";
import Skeleton from "antd/es/skeleton/index";
import RunningMap from "../../components/common/RunningMap";
import moment from "moment";

import CanDataBukgiViewer from "../../components/common/CanDataBukgiViewer";
import CanDataYaxingViewer from "../../components/common/CanDataYaxingViewer";
import CanDataSkywellViewer from "../../components/common/CanDataSkywellViewer";
import CanDataCoCoKartViewer from "../../components/common/CanDataCoCoKartViewer";
import CanDataBongo3Viewer from "../../components/common/CanDataBongo3Viewer";
import CanDataTSEcoViewer from "../../components/common/CanDataTSEcoViewer";

import MarkerLocation from "../../assets/images/marker/marker_car_location.png";
import CustomForm from "../../components/browser/CustomForm";
import styles from "../../components/common/KakaoMap.module.css";
import CanDataNavyaViewer from "../../components/common/CanDataNavyaViewer";

export type RunningCanChartState = {
    carId: number;
    bootTime?: string;
    startDate?: string;
    endDate?: string;
};

export type VehicleInfo = {
    startTime: string;
    endTime: string;
};

function PageRunningDetails() {
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const alert = useAlert();
    const param = useParams();
    const location = useLocation();
    const [carForm] = Form.useForm();
    const [runningsForm] = Form.useForm();

    const [canChartState, setCanChartState] = useState<RunningCanChartState | undefined>(undefined);
    const [car, setCar] = useState<TypeDTO.CarDto | undefined>(undefined);
    const [runningCanDataList, setRunningCanDataList] = useState<Array<TypeDTO.CanData> | undefined>(undefined);
    const [runningCanGpsDataList, setRunningCanGpsDataList] = useState<Array<TypeDTO.DataGpsDto> | undefined>(undefined);

    const [isFinish, setIsFinish] = useState<boolean>(false);
    const [chartMarker, setChartMarker] = useState<mapMarker | undefined>(undefined);
    const [selectedDataPointIndex, setSelectedDataPointIndex] = useState(-1);

    const [requestCanDataPage, setRequestCanDataPage] = useState(0);
    const [tempRunningCanDataList, setTempRunningCanDataList] = useState<Array<TypeDTO.CanData> | undefined>(undefined);
    const [alarmInfo, setAlarmInfo] = useState<TypeDTO.AlarmDto>();
    const [loading, setLoading] = useState<boolean>(false);
    const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
    const [endTime, setEndTime] = useState<string>();

    useEffect(() => {
        if (param.id1 && param.id2) {
            if (!param.id3) {
                setCanChartState({ carId: Number(param.id1), bootTime: param.id2 });
            } else {
                setCanChartState({ carId: Number(param.id1), startDate: param.id2, endDate: param.id3 });
            }

            document.querySelector(".ant-layout-content").scrollTo({ top: 0 });
        }
    }, [param]);

    useEffect(() => {
        location.state && setAlarmInfo(location.state as TypeDTO.AlarmDto);
    }, [location.state]);

    useEffect(() => {
        canChartState && requestApiCarInfo(canChartState.carId);
    }, [canChartState]);

    const { loadingApiCarInfo, requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;

        const car = resultApiCarInfo.car;
        setCar(car);
        carForm.setFieldsValue({
            ...car,
            customer: {
                ...car.customer,
                customerPhone: Utils.convertPhone(car?.customer?.customerPhone),
            },
        });
        setLoading(true);

        if (car) {
            getRunningHistory();
            requestApiRunningGpsData(canChartState);
            requestApiRunningData(canChartState, requestCanDataPage);
        }
    }, [resultApiCarInfo]);

    const { loadingApiRunningHistory, requestApiRunningHistory, resultApiRunningHistory } = RequestRunning.useRequestApiRunningHistory();

    useEffect(() => {
        const history = resultApiRunningHistory?.runningHistory;
        setIsFinish(history?.finish);

        if (param.id3) {
            runningsForm.setFieldsValue({
                runningDate: `${moment(param.id2).format(Common.FORMAT_DATE_TIME)} ~ ${moment(param.id3).format(Common.FORMAT_DATE_TIME)}`,
            });
        } else {
            if (history) {
                const startTime = moment(history.startTime).format(Common.FORMAT_DATE_TIME);
                const endTime = moment(history.endTime).format(Common.FORMAT_DATE_TIME);
                const drivingTime = Utils.secToTime(history.drivingTime);
                const drivingDistance = history.drivingDistance.toFixed(1);
                const avgSpeed = history.avgSpeed.toFixed(1);
                const electricMileage = history.electricMileage.toFixed(1);

                runningsForm.setFieldsValue({
                    ...history,
                    startTime,
                    endTime,
                    drivingTime,
                    drivingDistance: drivingDistance + String.kmDistanceUnit.replace("(", "").replace(")", ""),
                    avgSpeed: avgSpeed + String.kmSpeedUnit.replace("(", "").replace(")", ""),
                    electricMileage: electricMileage + String.kmElectricMileageUnit.replace("(", "").replace(")", ""),
                });
                setEndTime(endTime);
            }
        }
    }, [resultApiRunningHistory]);

    const { loadingApiRunningGpsData, requestApiRunningGpsData, resultApiRunningGpsData } = RequestRunning.useRequestApiRunningGpsData();

    useEffect(() => {
        setRunningCanGpsDataList(resultApiRunningGpsData?.runningGps);
    }, [resultApiRunningGpsData]);

    const { loadingApiRunningData, requestApiRunningData, resultApiRunningData } = RequestRunning.useRequestApiRunningData();

    const getRunningHistory = () => {
        if (canChartState?.bootTime) {
            requestApiRunningHistory(canChartState);
        } else if (canChartState?.startDate && canChartState?.endDate) {
            runningsForm.setFieldsValue({
                startTime: canChartState.startDate,
                endTime: canChartState.endDate,
            });
        }
    };

    useEffect(() => {
        if (!resultApiRunningData) {
            return;
        }

        if (!resultApiRunningData.runningData || !resultApiRunningData.runningData) {
            setLoading(false);
            return;
        }

        if (resultApiRunningData.runningData.totalPages > requestCanDataPage + 1) {
            if (tempRunningCanDataList) {
                setTempRunningCanDataList([...tempRunningCanDataList, ...resultApiRunningData.runningData.content]);
            } else {
                setTempRunningCanDataList(resultApiRunningData.runningData.content);
            }
            setRequestCanDataPage(requestCanDataPage + 1);
        } else {
            setLoading(false);
            if (tempRunningCanDataList) {
                setRunningCanDataList([...tempRunningCanDataList, ...resultApiRunningData.runningData.content]);
            } else {
                setRunningCanDataList(resultApiRunningData.runningData.content);
            }

            setRequestCanDataPage(0);
            setTempRunningCanDataList([]);
        }
    }, [resultApiRunningData]);

    useEffect(() => {
        if (requestCanDataPage !== 0) {
            requestApiRunningData(canChartState, requestCanDataPage);
        }
    }, [requestCanDataPage]);

    useEffect(() => {
        if (!runningCanDataList) return;

        const canData = runningCanDataList[selectedDataPointIndex] as TypeDTO.BasicCanData;
        const vehicleImage = MarkerLocation;

        setChartMarker({
            key: "",
            position: {
                lat: canData?.latitude,
                lng: canData?.longitude,
            },
            imageSrc: vehicleImage,
        });
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (alarmInfo && runningCanDataList) {
            const searchIndex = runningCanDataList.findIndex((item) => {
                const itemInfo = item as TypeDTO.BasicCanData;
                return itemInfo.dataTime.includes(alarmInfo.alarmTime);
            });

            setSelectedDataPointIndex(searchIndex);
        }
    }, [runningCanDataList]);

    const requestDownload = async () => {
        setDownloadLoading(true);

        await utilAxiosExcelWithAuth()
            .get(Request.RUNNING_DATA_DOWNLOAD_URL, {
                params: canChartState,
            })
            .then((response) => {
                const a = document.createElement("a");
                const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });

                console.log("response.headers", response);
                a.href = window.URL.createObjectURL(blob);
                a.target = "_blank";
                a.download = `${car?.carNumber}_${canChartState?.bootTime || canChartState?.startDate}_운행이력목록.xlsx`;
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(a.href);
                document.body.removeChild(a);

                setDownloadLoading(false);
            })
            .catch((error) => {
                alert.setAlert(AlertType.FILE_NOT_FOUND, "파일 다운로드 실패", String.msg_download_fail);
                setDownloadLoading(false);
            });
    };

    const handleSeletedDataPointIndex = (dataPointIndex: number) => {
        setSelectedDataPointIndex(dataPointIndex);
    };

    const displayEndTime = (endTime?: string) => {
        const checkAbnormal = isFinish !== true && moment(endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));
        return checkAbnormal ? (
            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                <Input className="disabled-input fc-danger" disabled />
            ) : (
                <Input className="disabled-input" disabled />
            )
        ) : isFinish === true ? (
            <Input className="disabled-input" disabled />
        ) : (
            <Tag className="pointTag" style={{ marginLeft: 11 }}>
                운행중
            </Tag>
        );
    };

    const carContents: Array<TypeUtils.formType> = [
        { name: "carNumber", label: String.carNumber, span: 12 },
        { name: "idNumber", label: String.identityNumber, span: 12 },
        { name: ["customer", "customerName"], label: String.customer, span: 6 },
        { name: ["customer", "customerPhone"], label: String.phone, span: 6 },
        { name: ["makerModel", "maker", "makerName"], label: String.maker, span: 6 },
        { name: ["category", "categoryName"], label: String.category, span: 6 },
    ];

    const contents: Array<TypeUtils.formType> = param.id3
        ? [{ name: "runningDate", label: String.runningDate, span: 24 }]
        : [
              { name: "startTime", label: String.startTime, span: 12 },
              {
                  name: "endTime",
                  label: String.endTime,
                  span: 12,
                  input: endTime ? displayEndTime(endTime) : <Input className="disabled-input" disabled />,
              },
              { name: "drivingTime", label: String.runningTime, span: 6 },
              { name: "drivingDistance", label: String.traveledDistance, span: 6 },
              { name: "avgSpeed", label: String.avgSpeed, span: 6 },
              { name: "electricMileage", label: String.electricMileage, span: 6 },
          ];

    return (
        <div className="pageWrapper" style={{ minHeight: "100vh" }}>
            <Space className={styles.space} direction="vertical" size={8} style={{ display: "flex" }}>
                <Row className="titleWrapper" justify={"space-between"}>
                    <h4 style={{ margin: 0 }}>상세 운행 이력</h4>
                    <Space size={16}>
                        <Button onClick={() => navigate(-1)}>이전</Button>
                        <Button className="btn-primary" onClick={() => requestDownload()} loading={downloadLoading}>
                            데이터 다운로드
                        </Button>
                        {/*<Button className="btn-primary">원본 파일 다운로드</Button>*/}
                    </Space>
                </Row>
                <CustomForm type="view" form={carForm} contents={[{ forms: carContents }]} disabledBtn />
                <CustomForm type="view" form={runningsForm} contents={[{ forms: contents }]} disabledBtn />

                <div style={{ height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {loadingApiRunningGpsData ? (
                        <Skeleton className="skeleton-block" active />
                    ) : (
                        <RunningMap isFinish={isFinish} runningPositions={runningCanGpsDataList} marker={chartMarker} />
                    )}
                </div>

                {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Bukgi && (
                    <CanDataBukgiViewer
                        loading={loading}
                        vehicleInfo={car}
                        runningCanDataList={runningCanDataList}
                        selectedDataPointIndex={selectedDataPointIndex}
                        onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                    />
                )}
                {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Yaxing && (
                    <CanDataYaxingViewer
                        loading={loading}
                        vehicleInfo={car}
                        runningCanDataList={runningCanDataList}
                        selectedDataPointIndex={selectedDataPointIndex}
                        onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                    />
                )}

                {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Skywell && (
                    <CanDataSkywellViewer
                        loading={loading}
                        vehicleInfo={car}
                        runningCanDataList={runningCanDataList}
                        selectedDataPointIndex={selectedDataPointIndex}
                        onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                    />
                )}

                {car?.category?.categoryName === String.CoCoKart && (
                    <CanDataCoCoKartViewer
                        loading={loading}
                        vehicleInfo={car}
                        runningCanDataList={runningCanDataList}
                        selectedDataPointIndex={selectedDataPointIndex}
                        onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                    />
                )}

                {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Kia && (
                    <CanDataBongo3Viewer
                        loading={loading}
                        vehicleInfo={car}
                        runningCanDataList={runningCanDataList}
                        selectedDataPointIndex={selectedDataPointIndex}
                        onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                    />
                )}

                {car?.makerModel?.maker?.makerName === Common.VehicleMaker.TSEco && (
                    <CanDataTSEcoViewer
                        loading={loading}
                        vehicleInfo={car}
                        runningCanDataList={runningCanDataList}
                        selectedDataPointIndex={selectedDataPointIndex}
                        onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                    />
                )}

                {car?.makerModel?.maker?.makerName === Common.VehicleMaker.Navya && (
                    <CanDataNavyaViewer
                        loading={loading}
                        vehicleInfo={car}
                        runningCanDataList={runningCanDataList}
                        selectedDataPointIndex={selectedDataPointIndex}
                        onChangedSelectDataPointIndex={handleSeletedDataPointIndex}
                    />
                )}
            </Space>
        </div>
    );
}

export default PageRunningDetails;
