import React from "react";
import { FormInstance } from "antd/es/form/hooks/useForm";
import { Store } from "antd/es/form/interface";
import { useNavigate } from "react-router";

import Button from "antd/es/button/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Form from "antd/es/form/index";
import Input from "antd/es/input/index";
import Space from "antd/es/space/index";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import styles from "../../pages/browser/Detail.module.css";

export type viewType = "register" | "edit" | "view";

const CustomForm = ({
    form,
    type,
    backBtn,
    disabledBtn,
    initialValues,
    contents,
    onFinish,
    onValuesChange,
    onChangeType,
    onClick,
}: {
    form: FormInstance<any>;
    type: viewType;
    backBtn?: boolean;
    disabledBtn?: boolean;
    initialValues?: Store;
    contents: Array<TypeUtils.contentsType>;
    onFinish?: (type: viewType, value: any) => void;
    onValuesChange?: (value: any, values: any) => void;
    onChangeType?: (type: viewType) => void;
    onClick?: (value: "back" | "cancel" | "save") => void;
}) => {
    const navigate = useNavigate();

    const setButton = () => {
        if (type === "view") {
            return (
                <Space>
                    {backBtn && <Button onClick={() => navigate(-1)}>{String.back}</Button>}
                    <Button className="btn-primary" onClick={() => onChangeType && onChangeType("edit")}>
                        {String.update}
                    </Button>
                </Space>
            );
        } else if (type === "edit") {
            return (
                <Space>
                    <Button
                        onClick={() => {
                            onClick && onClick("back");
                            onChangeType && onChangeType("view");
                            form.resetFields();
                        }}
                    >
                        {String.cancel}
                    </Button>
                    <Button className="btn-primary" onClick={() => form.submit()}>
                        {String.save}
                    </Button>
                </Space>
            );
        } else if (type === "register") {
            return (
                <Button className="btn-primary" onClick={() => form.submit()}>
                    {String.save}
                </Button>
            );
        }
    };

    return (
        <>
            <Form
                form={form}
                initialValues={initialValues}
                onFinish={(value) => {
                    onFinish && onFinish(type, value);
                }}
                onValuesChange={(value, values) => onValuesChange && onValuesChange(value, values)}
            >
                <Space direction="vertical" size={24} style={{ display: "flex" }}>
                    {contents?.map((content, index) => (
                        <Space key={index} direction="vertical" size={8} style={{ display: "flex" }}>
                            {content?.name && (
                                <Row className="titleWrapper" justify={"space-between"}>
                                    <h4 style={{ margin: 0 }}>{content?.name}</h4>
                                    {!content?.disabledBtn && !disabledBtn && <Space size={16}>{setButton()}</Space>}
                                    {content?.buttonProps && content?.buttonProps}
                                </Row>
                            )}
                            <Row className={styles.manageFormWrapper}>
                                {content.forms.map((form) => (
                                    <Col span={form.span} key={form?.key || form.label}>
                                        <Form.Item
                                            className={styles.manageForm}
                                            name={form.name}
                                            label={form.label}
                                            required={type !== "view" && form?.required}
                                            dependencies={form?.dependencies}
                                            hasFeedback={type !== "view" && form?.hasFeedback}
                                            rules={[
                                                { required: form?.required, message: Utils.addParticle(form.label, "입력해 주세요") },
                                                ...(Array.isArray(form.rules) ? form.rules : []),
                                            ]}
                                        >
                                            {form?.input ||
                                                (type === "view" ? (
                                                    <Input className="disabled-input" disabled />
                                                ) : (
                                                    <Input disabled={form?.disabled} />
                                                ))}
                                        </Form.Item>
                                    </Col>
                                ))}
                            </Row>
                        </Space>
                    ))}
                </Space>
            </Form>
        </>
    );
};

export default CustomForm;
