import { useEffect, useState } from "react";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Radio from "antd/es/radio/index";
import Card from "antd/es/card/index";
import Spin from "antd/es/spin/index";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as RequestStats from "../../utils/requestApiStats";
import * as Common from "../../commons/common";

import moment from "moment";
import CustomDatePicker from "../common/CustomDatePicker";
import produce from "immer";
import ReactApexChart from "react-apexcharts";
import styles from "./Stats.module.css";

export type dataPeriodType = {
    startDate?: string;
    endDate?: string;
    period?: "YEAR" | "MONTH" | "DAY";
};

type runningStatsType = {
    statsTime: string;
    count: number;
    drivingTime: number;
    errorCount: number;
    warningCount: number;
};

function StatsErrorByPeriod({ disabledSearch }: { disabledSearch?: boolean }) {
    const [requestStats, setRequestStats] = useState<dataPeriodType>({
        startDate: moment().add(-6, "days").format(Common.FORMAT_DATE_HOUR_TIME_START),
        endDate: moment().format(Common.FORMAT_DATE_HOUR_TIME_END),
        period: "DAY",
    });
    const [stats, setStats] = useState<Array<runningStatsType>>();
    const [errorOption, setErrorOption] = useState<Record<string, any> | undefined>();
    const [errorSeries, setErrorSeries] = useState<TypeUtils.ChartData[]>([]);
    const today = moment().format(Common.FORMAT_DATE);

    useEffect(() => {
        requestApiStatsRunning(requestStats);
    }, []);

    const errorChartOptions = {
        colors: ["var(--warning)", "var(--danger)"],
        chart: {
            height: "100%",
            stacked: true,
            width: "1000px",
            toolbar: {
                show: false,
            },
        },
        title: {
            text: "일별 알람 발생 추이",
            style: {
                fontFamily: "Pretendard-Bold",
                fontSize: "16px",
            },
        },
        xaxis: {
            type: "category",
            label: {
                style: { cssClass: "fw-rg fs-xs fc-point" },
                formatter: function (val: string, index: number) {
                    return <tspan style={{ color: moment(val).format(Common.FORMAT_TIME) === today ? "var(--point)" : "var(--font2)" }}>{val}</tspan>;
                },
            },
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    colors: "var(--font2)",
                },
                labels: {
                    formatter: function (val: number) {
                        return val?.toFixed(0);
                    },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val: number, { series, seriesIndex, dataPointIndex, w }: any) {
                    return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + String.count;
                },
            },
        },
        dataLabels: {
            formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex];
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "20%",
            },
        },
        stroke: {
            width: 1,
        },
        legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontFamily: "Pretendard-Regular",
            fontWeight: "normal",
            onItemClick: {
                toggleDataSeries: false,
            },
            onItemHover: {
                highlightDataSeries: false,
            },
            showForSingleSeries: false,
            fontSize: "14px",
        },
    };

    useEffect(() => {
        requestApiStatsRunning(requestStats);
    }, [requestStats]);

    useEffect(() => {
        const errorCounts: TypeUtils.ChartElement[] = [];
        const warningCounts: TypeUtils.ChartElement[] = [];

        let timeFormat = Common.FORMAT_DATE;
        switch (requestStats.period) {
            case "YEAR":
                timeFormat = Common.FORMAT_YEAR;
                break;
            case "MONTH":
                timeFormat = Common.FORMAT_MONTH;
                break;
            case "DAY":
                timeFormat = Common.FORMAT_DATE;
                break;
            default:
                break;
        }

        stats?.forEach((value, index) => {
            const formattedDate = moment(value.statsTime, Common.FORMAT_DATE).format(Common.FORMAT_DATE);
            const time = moment(formattedDate, Common.FORMAT_DATE_TIME_MILLISECOND).format(timeFormat);

            errorCounts.push({
                x: time,
                y: value?.errorCount || 0,
            });

            warningCounts.push({
                x: time,
                y: value?.warningCount || 0,
            });
        });

        setErrorSeries([
            {
                name: String.warning,
                type: "bar",
                data: warningCounts,
            },
            {
                name: String.error,
                type: "bar",
                data: errorCounts,
            },
        ]);

        errorOption === undefined && setErrorOption(errorChartOptions);
    }, [stats]);

    const { loadingApiStatsRunning, requestApiStatsRunning, resultApiStatsRunning } = RequestStats.useRequestApiStatsRunning();

    useEffect(() => {
        if (!resultApiStatsRunning) return;

        setStats(resultApiStatsRunning.runningStats);
    }, [resultApiStatsRunning]);

    const onChangeEvent = (type: string, value: any) => {
        setRequestStats(
            produce((draft) => {
                draft[type as keyof dataPeriodType] = value;
            })
        );
    };

    return (
        <>
            {!disabledSearch && (
                <Row className="searchLastRow" style={{ marginBottom: "10px" }}>
                    <Col xs={{ span: 6 }} sm={{ span: 3 }} xl={{ span: 2 }} className="searchHead">
                        {String.searchDate}
                    </Col>
                    <Col xs={{ span: 18 }} sm={{ span: 21 }} xl={{ span: 10 }} className="searchBody" style={{ display: "inline-flex" }}>
                        <CustomDatePicker
                            value={requestStats.startDate}
                            hasTime
                            dateFormat={Common.FORMAT_DATE_TIME}
                            onChange={(value) => onChangeEvent("startDate", value)}
                        />
                        <span style={{ margin: "0 7px" }}>~</span>
                        <CustomDatePicker
                            value={requestStats.endDate}
                            hasTime
                            dateFormat={Common.FORMAT_DATE_TIME}
                            onChange={(value) => onChangeEvent("endDate", value)}
                        />
                    </Col>
                    <Col xs={{ span: 6 }} sm={{ span: 3 }} xl={{ span: 2 }} className="searchHead">
                        {String.unit}
                    </Col>
                    <Col xs={{ span: 18 }} sm={{ span: 21 }} xl={{ span: 10 }} className="searchBody" style={{ display: "inline-flex" }}>
                        <Radio.Group onChange={(e) => onChangeEvent("period", e.target.value)} defaultValue={requestStats.period}>
                            <Radio.Button value="YEAR">{String.periodYear}</Radio.Button>
                            <Radio.Button value="MONTH">{String.periodMonth}</Radio.Button>
                            <Radio.Button value="DAY">{String.periodDay}</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
            )}

            {errorOption && (
                <Card className={styles.card}>
                    {loadingApiStatsRunning ? (
                        <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Spin />
                        </div>
                    ) : (
                        <ReactApexChart
                            style={{ backgroundColor: "white" }}
                            options={errorOption}
                            series={errorSeries}
                            width="100%"
                            height={errorOption.chart.height}
                        />
                    )}
                </Card>
            )}
        </>
    );
}

export default StatsErrorByPeriod;
