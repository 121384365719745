import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiSwVerRegister = () => {
    const requestAxiosApiSwVerRegister = async (data: TypeDTO.SwVerDto) => {
        const response = await utilAxiosWithAuth().post(Request.SWVER_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiSwVerRegister,
        error: errorApiSwVerRegister,
        data: resultApiSwVerRegister,
        execute: requestApiSwVerRegister,
    } = useAsyncAxios(requestAxiosApiSwVerRegister);

    useEffect(() => {
        if (resultApiSwVerRegister === null) return;
        DEBUG && console.log("resultApiSwVerRegister", resultApiSwVerRegister);
    }, [resultApiSwVerRegister]);

    useEffect(() => {
        if (errorApiSwVerRegister === null) return;
        DEBUG && console.log("errorApiSwVerRegister", errorApiSwVerRegister);
    }, [errorApiSwVerRegister]);

    return {
        loadingApiSwVerRegister,
        errorApiSwVerRegister,
        resultApiSwVerRegister,
        requestApiSwVerRegister,
    };
};

export const useRequestApiSwVerList = () => {
    const requestAxiosApiSwVerList = async () => {
        const response = await utilAxiosWithAuth().get(Request.SWVERS_URL);

        return response.data;
    };

    const {
        loading: loadingApiSwVerList,
        error: errorApiSwVerList,
        data: resultApiSwVerList,
        execute: requestApiSwVerList,
    } = useAsyncAxios(requestAxiosApiSwVerList);

    useEffect(() => {
        if (resultApiSwVerList === null) return;
        DEBUG && console.log("resultApiSwVerList", resultApiSwVerList);
    }, [resultApiSwVerList]);

    useEffect(() => {
        if (errorApiSwVerList === null) return;
        DEBUG && console.log("errorApiSwVerList", errorApiSwVerList);
    }, [errorApiSwVerList]);

    return {
        loadingApiSwVerList,
        errorApiSwVerList,
        resultApiSwVerList,
        requestApiSwVerList,
    };
};

export const useRequestApiSwVerInfo = () => {
    const requestAxiosApiSwVerInfo = async (swVerId: number) => {
        const response = await utilAxiosWithAuth().get(Request.SWVER_URL + swVerId);

        return response.data;
    };

    const {
        loading: loadingApiSwVerInfo,
        error: errorApiSwVerInfo,
        data: resultApiSwVerInfo,
        execute: requestApiSwVerInfo,
    } = useAsyncAxios(requestAxiosApiSwVerInfo);

    useEffect(() => {
        if (resultApiSwVerInfo === null) return;
        DEBUG && console.log("resultApiSwVerInfo", resultApiSwVerInfo);
    }, [resultApiSwVerInfo]);

    useEffect(() => {
        if (errorApiSwVerInfo === null) return;
        DEBUG && console.log("errorApiSwVerInfo", errorApiSwVerInfo);
    }, [errorApiSwVerInfo]);

    return {
        loadingApiSwVerInfo,
        errorApiSwVerInfo,
        resultApiSwVerInfo,
        requestApiSwVerInfo,
    };
};

export const useRequestApiSwVerUpdate = () => {
    const requestAxiosApiSwVerUpdate = async (swVerId: number, data: TypeDTO.SwVerDto) => {
        const response = await utilAxiosWithAuth().put(Request.SWVER_URL + swVerId, data);

        return response.data;
    };

    const {
        loading: loadingApiSwVerUpdate,
        error: errorApiSwVerUpdate,
        data: resultApiSwVerUpdate,
        execute: requestApiSwVerUpdate,
    } = useAsyncAxios(requestAxiosApiSwVerUpdate);

    useEffect(() => {
        if (resultApiSwVerUpdate === null) return;
        DEBUG && console.log("resultApiSwVerUpdate", resultApiSwVerUpdate);
    }, [resultApiSwVerUpdate]);

    useEffect(() => {
        if (errorApiSwVerUpdate === null) return;
        DEBUG && console.log("errorApiSwVerUpdate", errorApiSwVerUpdate);
    }, [errorApiSwVerUpdate]);

    return {
        loadingApiSwVerUpdate,
        errorApiSwVerUpdate,
        resultApiSwVerUpdate,
        requestApiSwVerUpdate,
    };
};

export const useRequestApiSwVerDelete = () => {
    const requestAxiosApiSwVerDelete = async (swVerId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.SWVER_URL + swVerId);

        return response.data;
    };

    const {
        loading: loadingApiSwVerDelete,
        error: errorApiSwVerDelete,
        data: resultApiSwVerDelete,
        execute: requestApiSwVerDelete,
    } = useAsyncAxios(requestAxiosApiSwVerDelete);

    useEffect(() => {
        if (resultApiSwVerDelete === null) return;
        DEBUG && console.log("resultApiSwVerDelete", resultApiSwVerDelete);
    }, [resultApiSwVerDelete]);

    useEffect(() => {
        if (errorApiSwVerDelete === null) return;
        DEBUG && console.log("errorApiSwVerDelete", errorApiSwVerDelete);
    }, [errorApiSwVerDelete]);

    return {
        loadingApiSwVerDelete,
        errorApiSwVerDelete,
        resultApiSwVerDelete,
        requestApiSwVerDelete,
    };
};
