import { useEffect, useState } from "react";

import moment from "moment";

import * as CanDataBongo3 from "../../commons/CanDataBongo3";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";

import CanDataWrapper, { CanTableType } from "./CanDataWrapper";
import Space from "antd/es/space/index";

function CanDataBongo3Viewer({
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataBongo3.CanData>();

    const convertRemarkWithUnit = (value: number, unit: string) => {
        return value + unit;
    };

    //#region Bms Command 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsCommand1PanelOpen, setBmsCommand1PanelOpen] = useState(false);
    const [bmsCommand1ChartMounted, setBmsCommand1ChartMounted] = useState(false);
    const [selectedBmsCommand1, setSelectedBmsCommand1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBmsCommand1, setSeriesChartDatasBmsCommand1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBmsCommand1BatteryPackCurrent, setChartBmsCommand1BatteryPackCurrent] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_BATTERY_PACK_CURRENT_NAME,
        data: [],
    });

    const [chartBmsCommand1BatteryPackVoltage, setChartBmsCommand1BatteryPackVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_BATTERY_PACK_VOLTAGE_NAME,
        data: [],
    });

    const [chartBmsCommand1BatteryMaxTemperature, setChartBmsCommand1BatteryMaxTemperature] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_BATTERY_MAX_TEMPERATURE_NAME,
        data: [],
    });

    const [chartBmsCommand1BatteryMinTemperature, setChartBmsCommand1BatteryMinTemperature] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_BATTERY_MIN_TEMPERATURE_NAME,
        data: [],
    });

    const [chartBmsCommand1BatterySoh, setChartBmsCommand1BatterySoh] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_BATTERY_SOH_NAME,
        data: [],
    });

    const getTabelTooltipBmsCommand1 = (key: React.Key) => {
        switch (key) {
            case Common.BONGO3_BATTERY_PACK_CURRENT_KEY:
                return (
                    <>
                        <p>
                            BMS Command 1
                            <br />
                            CAN ID: 0x20000001
                            <br />
                            Name: Battery Pack Current
                        </p>
                        <p>Remarks: A</p>
                    </>
                );

            case Common.BONGO3_BATTERY_PACK_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            BMS Command 1
                            <br />
                            CAN ID: 0x20000001
                            <br />
                            Name: Battery Pack Voltage
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.BONGO3_BATTERY_MAX_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            BMS Command 1
                            <br />
                            CAN ID: 0x20000001
                            <br />
                            Name: Battery Max Temperature
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );

            case Common.BONGO3_BATTERY_MIN_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            BMS Command 1
                            <br />
                            CAN ID: 0x20000001
                            <br />
                            Name: Battery Min Temperature
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );

            case Common.BONGO3_BATTERY_SOH_KEY:
                return (
                    <>
                        <p>
                            BMS Command 1
                            <br />
                            CAN ID: 0x20000001
                            <br />
                            Name: Battery SOH
                        </p>
                        <p>Remarks: %</p>
                    </>
                );
        }

        return null;
    };

    const getBmsCommand1BatteryPackCurrentValue = () => {
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_PACK_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand1?.batteryPackCurrent, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBmsCommand1BatteryPackVoltageValue = () => {
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_PACK_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand1?.batteryPackVoltage, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsCommand1BatteryMaxTemperatureValue = () => {
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_MAX_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand1?.batteryMaxTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBmsCommand1BatteryMinTemperatureValue = () => {
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_MIN_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand1?.batteryMinTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBmsCommand1BatterySohValue = () => {
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_SOH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand1?.batterySoh, Common.UNIT_PERCENT);
        }
        return "";
    };

    const onChangeBmsCommand1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsCommand1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsCommand1: Array<TypeUtils.ChartData> = [];

        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_PACK_CURRENT_KEY))
            chartDatasBmsCommand1.push(chartBmsCommand1BatteryPackCurrent);
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_PACK_VOLTAGE_KEY))
            chartDatasBmsCommand1.push(chartBmsCommand1BatteryPackVoltage);
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_MAX_TEMPERATURE_KEY))
            chartDatasBmsCommand1.push(chartBmsCommand1BatteryMaxTemperature);
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_MIN_TEMPERATURE_KEY))
            chartDatasBmsCommand1.push(chartBmsCommand1BatteryMinTemperature);
        if (selectedBmsCommand1.includes(Common.BONGO3_BATTERY_SOH_KEY)) chartDatasBmsCommand1.push(chartBmsCommand1BatterySoh);

        setSeriesChartDatasBmsCommand1(chartDatasBmsCommand1);
    }, [
        selectedBmsCommand1,
        chartBmsCommand1BatteryPackCurrent,
        chartBmsCommand1BatteryPackVoltage,
        chartBmsCommand1BatteryMaxTemperature,
        chartBmsCommand1BatteryMinTemperature,
        chartBmsCommand1BatterySoh,
    ]);

    const tableDataBmsCommand1: CanTableType[] = [
        {
            key: Common.BONGO3_BATTERY_PACK_CURRENT_KEY,
            name: Common.BONGO3_BATTERY_PACK_CURRENT_NAME,
            value: getBmsCommand1BatteryPackCurrentValue(),
        },
        {
            key: Common.BONGO3_BATTERY_PACK_VOLTAGE_KEY,
            name: Common.BONGO3_BATTERY_PACK_VOLTAGE_NAME,
            value: getBmsCommand1BatteryPackVoltageValue(),
        },
        {
            key: Common.BONGO3_BATTERY_MAX_TEMPERATURE_KEY,
            name: Common.BONGO3_BATTERY_MAX_TEMPERATURE_NAME,
            value: getBmsCommand1BatteryMaxTemperatureValue(),
        },
        {
            key: Common.BONGO3_BATTERY_MIN_TEMPERATURE_KEY,
            name: Common.BONGO3_BATTERY_MIN_TEMPERATURE_NAME,
            value: getBmsCommand1BatteryMinTemperatureValue(),
        },
        {
            key: Common.BONGO3_BATTERY_SOH_KEY,
            name: Common.BONGO3_BATTERY_SOH_NAME,
            value: getBmsCommand1BatterySohValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Bms Command 2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsCommand2PanelOpen, setBmsCommand2PanelOpen] = useState(false);
    const [bmsCommand2ChartMounted, setBmsCommand2ChartMounted] = useState(false);
    const [selectedBmsCommand2, setSelectedBmsCommand2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBmsCommand2, setSeriesChartDatasBmsCommand2] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBmsCommand2BatterySoc, setChartBmsCommand2BatterySoc] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_BATTERY_SOC_NAME,
        data: [],
    });

    const [chartBmsCommand2MaxCellVoltage, setChartBmsCommand2MaxCellVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_MAX_CELL_VOLTAGE_NAME,
        data: [],
    });

    const [chartBmsCommand2MaxCellVoltageNum, setChartBmsCommand2MaxCellVoltageNum] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_MAX_CELL_VOLTAGE_NUM_NAME,
        data: [],
    });

    const [chartBmsCommand2MinCellVoltage, setChartBmsCommand2MinCellVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_MIN_CELL_VOLTAGE_NAME,
        data: [],
    });

    const [chartBmsCommand2MinCellVoltageNum, setChartBmsCommand2MinCellVoltageNum] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_MIN_CELL_VOLTAGE_NUM_NAME,
        data: [],
    });

    const [chartBmsCommand2SubBatteryVoltage, setChartBmsCommand2SubBatteryVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_SUB_BATTERY_VOLTAGE_NAME,
        data: [],
    });

    const [chartBmsCommand2IsolationResistance, setChartBmsCommand2IsolationResistance] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_ISOLATION_RESISTANCE_NAME,
        data: [],
    });

    const getTabelTooltipBmsCommand2 = (key: React.Key) => {
        switch (key) {
            case Common.BONGO3_BATTERY_SOC_KEY:
                return (
                    <>
                        <p>
                            BMS Command 1
                            <br />
                            CAN ID: 0x20000001
                            <br />
                            Name: Battery SOC
                        </p>
                        <p>Remarks: %</p>
                    </>
                );
            case Common.BONGO3_MAX_CELL_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            BMS Command 2
                            <br />
                            CAN ID: 0x20000004
                            <br />
                            Name: Max Cell Voltage
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.BONGO3_MAX_CELL_VOLTAGE_NUM_KEY:
                return (
                    <>
                        <p>
                            BMS Command 2
                            <br />
                            CAN ID: 0x20000004
                            <br />
                            Name: Max Cell Voltage Num
                        </p>
                    </>
                );

            case Common.BONGO3_MIN_CELL_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            BMS Command 2
                            <br />
                            CAN ID: 0x20000004
                            <br />
                            Name: Min Cell Voltage
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.BONGO3_MIN_CELL_VOLTAGE_NUM_KEY:
                return (
                    <>
                        <p>
                            BMS Command 2
                            <br />
                            CAN ID: 0x20000004
                            <br />
                            Name: Min Cell Voltage Num
                        </p>
                    </>
                );

            case Common.BONGO3_SUB_BATTERY_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            BMS Command 2
                            <br />
                            CAN ID: 0x20000004
                            <br />
                            Name: Sub Battery Voltage
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.BONGO3_ISOLATION_RESISTANCE_KEY:
                return (
                    <>
                        <p>
                            BMS Command 2
                            <br />
                            CAN ID: 0x20000004
                            <br />
                            Name: 절연저항
                        </p>
                        <p>Remarks: kohm</p>
                    </>
                );
        }

        return null;
    };

    const getBmsCommand2BatterySocValue = () => {
        if (selectedBmsCommand2.includes(Common.BONGO3_BATTERY_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand2?.batterySoc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBmsCommand2MaxCellVoltageValue = () => {
        if (selectedBmsCommand2.includes(Common.BONGO3_MAX_CELL_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand2?.maxCellVoltage, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsCommand2MaxCellVoltageNumValue = () => {
        if (selectedBmsCommand2.includes(Common.BONGO3_MAX_CELL_VOLTAGE_NUM_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bmsCommand2?.maxCellVoltageNum);
        }
        return "";
    };

    const getBmsCommand2MinCellVoltageValue = () => {
        if (selectedBmsCommand2.includes(Common.BONGO3_MIN_CELL_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand2?.minCellVoltage, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsCommand2MinCellVoltageNumValue = () => {
        if (selectedBmsCommand2.includes(Common.BONGO3_MIN_CELL_VOLTAGE_NUM_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bmsCommand2?.minCellVoltageNum);
        }
        return "";
    };

    const getBmsCommand2SubBatteryVoltageValue = () => {
        if (selectedBmsCommand2.includes(Common.BONGO3_SUB_BATTERY_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand2?.subBatteryVoltage, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsCommand2IsolationResistanceValue = () => {
        if (selectedBmsCommand2.includes(Common.BONGO3_ISOLATION_RESISTANCE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsCommand2?.isolationResistance, Common.UNIT_K_OHM);
        }
        return "";
    };

    const onChangeBmsCommand2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsCommand2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsCommand2: Array<TypeUtils.ChartData> = [];

        if (selectedBmsCommand2.includes(Common.BONGO3_BATTERY_SOC_KEY)) chartDatasBmsCommand2.push(chartBmsCommand2BatterySoc);
        if (selectedBmsCommand2.includes(Common.BONGO3_MAX_CELL_VOLTAGE_KEY)) chartDatasBmsCommand2.push(chartBmsCommand2MaxCellVoltage);
        if (selectedBmsCommand2.includes(Common.BONGO3_MAX_CELL_VOLTAGE_NUM_KEY))
            chartDatasBmsCommand2.push(chartBmsCommand2MaxCellVoltageNum);
        if (selectedBmsCommand2.includes(Common.BONGO3_MIN_CELL_VOLTAGE_KEY)) chartDatasBmsCommand2.push(chartBmsCommand2MinCellVoltage);
        if (selectedBmsCommand2.includes(Common.BONGO3_MIN_CELL_VOLTAGE_NUM_KEY))
            chartDatasBmsCommand2.push(chartBmsCommand2MinCellVoltageNum);
        if (selectedBmsCommand2.includes(Common.BONGO3_SUB_BATTERY_VOLTAGE_KEY))
            chartDatasBmsCommand2.push(chartBmsCommand2SubBatteryVoltage);
        if (selectedBmsCommand2.includes(Common.BONGO3_ISOLATION_RESISTANCE_KEY))
            chartDatasBmsCommand2.push(chartBmsCommand2IsolationResistance);

        setSeriesChartDatasBmsCommand2(chartDatasBmsCommand2);
    }, [
        selectedBmsCommand2,
        chartBmsCommand2BatterySoc,
        chartBmsCommand2MaxCellVoltage,
        chartBmsCommand2MaxCellVoltageNum,
        chartBmsCommand2MinCellVoltage,
        chartBmsCommand2MinCellVoltageNum,
        chartBmsCommand2SubBatteryVoltage,
        chartBmsCommand2IsolationResistance,
    ]);

    const tableDataBmsCommand2: CanTableType[] = [
        {
            key: Common.BONGO3_BATTERY_SOC_KEY,
            name: Common.BONGO3_BATTERY_SOC_NAME,
            value: getBmsCommand2BatterySocValue(),
        },
        {
            key: Common.BONGO3_MAX_CELL_VOLTAGE_KEY,
            name: Common.BONGO3_MAX_CELL_VOLTAGE_NAME,
            value: getBmsCommand2MaxCellVoltageValue(),
        },
        {
            key: Common.BONGO3_MAX_CELL_VOLTAGE_NUM_KEY,
            name: Common.BONGO3_MAX_CELL_VOLTAGE_NUM_NAME,
            value: getBmsCommand2MaxCellVoltageNumValue(),
        },
        {
            key: Common.BONGO3_MIN_CELL_VOLTAGE_KEY,
            name: Common.BONGO3_MIN_CELL_VOLTAGE_NAME,
            value: getBmsCommand2MinCellVoltageValue(),
        },
        {
            key: Common.BONGO3_MIN_CELL_VOLTAGE_NUM_KEY,
            name: Common.BONGO3_MIN_CELL_VOLTAGE_NUM_NAME,
            value: getBmsCommand2MinCellVoltageNumValue(),
        },
        {
            key: Common.BONGO3_SUB_BATTERY_VOLTAGE_KEY,
            name: Common.BONGO3_SUB_BATTERY_VOLTAGE_NAME,
            value: getBmsCommand2SubBatteryVoltageValue(),
        },
        {
            key: Common.BONGO3_ISOLATION_RESISTANCE_KEY,
            name: Common.BONGO3_ISOLATION_RESISTANCE_NAME,
            value: getBmsCommand2IsolationResistanceValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Vcu Command
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuCommandPanelOpen, setVcuCommandPanelOpen] = useState(false);
    const [vcuCommandChartMounted, setVcuCommandChartMounted] = useState(false);
    const [selectedVcuCommand, setSelectedVcuCommand] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuCommand, setSeriesChartDatasVcuCommand] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVcuCommandGearStatus, setChartVcuCommandGearStatus] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_GEAR_STATUS_NAME,
        data: [],
    });

    const [chartVcuCommandAccelPedalStatus, setChartVcuCommandAccelPedalStatus] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_ACCEL_PEDAL_STATUS_NAME,
        data: [],
    });

    const [chartVcuCommandVehicleSpeed, setChartVcuCommandVehicleSpeed] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_VEHICLE_SPEED_NAME,
        data: [],
    });

    const [chartVcuCommandIgnitionStatus, setChartVcuCommandIgnitionStatus] = useState<TypeUtils.ChartData>({
        name: Common.BONGO3_IGNITION_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipVcuCommand = (key: React.Key) => {
        switch (key) {
            case Common.BONGO3_GEAR_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU Command
                            <br />
                            CAN ID: 0x30000001
                            <br />
                            Name: Gear Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0001-P, 0010-R
                            <br />
                            0100-N, 1000-D
                        </p>
                    </>
                );

            case Common.BONGO3_ACCEL_PEDAL_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU Command
                            <br />
                            CAN ID: 0x30000001
                            <br />
                            Name: Accel Pedal Status
                        </p>
                        <p>Remarks: %</p>
                    </>
                );

            case Common.BONGO3_VEHICLE_SPEED_KEY:
                return (
                    <>
                        <p>
                            VCU Command
                            <br />
                            CAN ID: 0x30000001
                            <br />
                            Name: Vehicle Speed
                        </p>
                        <p>Remarks: km/h</p>
                    </>
                );

            case Common.BONGO3_IGNITION_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU Command
                            <br />
                            CAN ID: 0x30000001
                            <br />
                            Name: 키 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-off, 1-on
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertVcuCommandGearStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 1:
                valueString = "P";
                break;
            case 2:
                valueString = "R";
                break;

            case 4:
                valueString = "N";
                break;

            case 8:
                valueString = "D";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertVcuCommandIgnitionStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "off";
                break;
            case 1:
                valueString = "on";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getVcuCommandGearStatusValue = () => {
        if (selectedVcuCommand.includes(Common.BONGO3_GEAR_STATUS_KEY) && currentCanData !== undefined) {
            return convertVcuCommandGearStatusRemark(currentCanData.vcuCommand?.gearStatus);
        }
        return "";
    };

    const getVcuCommandAccelPedalStatusValue = () => {
        if (selectedVcuCommand.includes(Common.BONGO3_ACCEL_PEDAL_STATUS_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuCommand?.accelPedalStatus, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getVcuCommandVehicleSpeedValue = () => {
        if (selectedVcuCommand.includes(Common.BONGO3_VEHICLE_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuCommand?.vehicleSpeed, Common.UNIT_SPEED);
        }
        return "";
    };

    const getVcuCommandIgnitionStatusValue = () => {
        if (selectedVcuCommand.includes(Common.BONGO3_IGNITION_STATUS_KEY) && currentCanData !== undefined) {
            return convertVcuCommandIgnitionStatusRemark(currentCanData.vcuCommand?.ignitionStatus);
        }
        return "";
    };

    const onChangeVcuCommand = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuCommand(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuCommand: Array<TypeUtils.ChartData> = [];

        if (selectedVcuCommand.includes(Common.BONGO3_GEAR_STATUS_KEY)) chartDatasVcuCommand.push(chartVcuCommandGearStatus);
        if (selectedVcuCommand.includes(Common.BONGO3_ACCEL_PEDAL_STATUS_KEY)) chartDatasVcuCommand.push(chartVcuCommandAccelPedalStatus);
        if (selectedVcuCommand.includes(Common.BONGO3_VEHICLE_SPEED_KEY)) chartDatasVcuCommand.push(chartVcuCommandVehicleSpeed);
        if (selectedVcuCommand.includes(Common.BONGO3_IGNITION_STATUS_KEY)) chartDatasVcuCommand.push(chartVcuCommandIgnitionStatus);

        setSeriesChartDatasVcuCommand(chartDatasVcuCommand);
    }, [
        selectedVcuCommand,
        chartVcuCommandGearStatus,
        chartVcuCommandAccelPedalStatus,
        chartVcuCommandVehicleSpeed,
        chartVcuCommandIgnitionStatus,
    ]);

    const tableDataVcuCommand: CanTableType[] = [
        {
            key: Common.BONGO3_GEAR_STATUS_KEY,
            name: Common.BONGO3_GEAR_STATUS_NAME,
            value: getVcuCommandGearStatusValue(),
        },
        {
            key: Common.BONGO3_ACCEL_PEDAL_STATUS_KEY,
            name: Common.BONGO3_ACCEL_PEDAL_STATUS_NAME,
            value: getVcuCommandAccelPedalStatusValue(),
        },
        {
            key: Common.BONGO3_VEHICLE_SPEED_KEY,
            name: Common.BONGO3_VEHICLE_SPEED_NAME,
            value: getVcuCommandVehicleSpeedValue(),
        },
        {
            key: Common.BONGO3_IGNITION_STATUS_KEY,
            name: Common.BONGO3_IGNITION_STATUS_NAME,
            value: getVcuCommandIgnitionStatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    useEffect(() => {
        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Kia) {
            //#region Bms Command 1
            const bmsCommand1BatteryPackCurrentDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand1BatteryPackVoltageDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand1BatteryMaxTemperatureDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand1BatteryMinTemperatureDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand1BatterySohDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Bms Command 2
            const bmsCommand2BatterySocDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand2MaxCellVoltageDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand2MaxCellVoltageNumDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand2MinCellVoltageDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand2MinCellVoltageNumDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand2SubBatteryVoltageDatas: TypeUtils.ChartElement[] = [];
            const bmsCommand2IsolationResistanceDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region Vcu Command
            const vcuCommandGearStatusDatas: TypeUtils.ChartElement[] = [];
            const vcuCommandAccelPedalStatusDatas: TypeUtils.ChartElement[] = [];
            const vcuCommandVehicleSpeedDatas: TypeUtils.ChartElement[] = [];
            const vcuCommandIgnitionStatusDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataBongo3.CanData;

                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region Bms Command 1
                bmsCommand1BatteryPackCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand1?.batteryPackCurrent || 0,
                });

                bmsCommand1BatteryPackVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand1?.batteryPackVoltage || 0,
                });

                bmsCommand1BatteryMaxTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand1?.batteryMaxTemp || 0,
                });

                bmsCommand1BatteryMinTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand1?.batteryMinTemp || 0,
                });

                bmsCommand1BatterySohDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand1?.batterySoh || 0,
                });
                //#endregion

                //#region Bms Command 2
                bmsCommand2BatterySocDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand2?.batterySoc || 0,
                });

                bmsCommand2MaxCellVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand2?.maxCellVoltage || 0,
                });

                bmsCommand2MaxCellVoltageNumDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand2?.maxCellVoltageNum || 0,
                });

                bmsCommand2MinCellVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand2?.minCellVoltage || 0,
                });

                bmsCommand2MinCellVoltageNumDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand2?.minCellVoltageNum || 0,
                });

                bmsCommand2SubBatteryVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand2?.subBatteryVoltage || 0,
                });

                bmsCommand2IsolationResistanceDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsCommand2?.isolationResistance || 0,
                });
                //#endregion

                //#region Vcu Command
                vcuCommandGearStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCommand?.gearStatus || 0,
                });

                vcuCommandAccelPedalStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCommand?.accelPedalStatus || 0,
                });

                vcuCommandVehicleSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCommand?.vehicleSpeed || 0,
                });

                vcuCommandIgnitionStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCommand?.ignitionStatus || 0,
                });
                //#endregion
            });

            //#region Bms Command 1
            setChartBmsCommand1BatteryPackCurrent({
                ...chartBmsCommand1BatteryPackCurrent,
                data: bmsCommand1BatteryPackCurrentDatas,
            });

            setChartBmsCommand1BatteryPackVoltage({
                ...chartBmsCommand1BatteryPackVoltage,
                data: bmsCommand1BatteryPackVoltageDatas,
            });

            setChartBmsCommand1BatteryMaxTemperature({
                ...chartBmsCommand1BatteryMaxTemperature,
                data: bmsCommand1BatteryMaxTemperatureDatas,
            });

            setChartBmsCommand1BatteryMinTemperature({
                ...chartBmsCommand1BatteryMinTemperature,
                data: bmsCommand1BatteryMinTemperatureDatas,
            });

            setChartBmsCommand1BatterySoh({
                ...chartBmsCommand1BatterySoh,
                data: bmsCommand1BatterySohDatas,
            });
            //#endregion

            //#region Bms Command 2
            setChartBmsCommand2BatterySoc({
                ...chartBmsCommand2BatterySoc,
                data: bmsCommand2BatterySocDatas,
            });

            setChartBmsCommand2MaxCellVoltage({
                ...chartBmsCommand2MaxCellVoltage,
                data: bmsCommand2MaxCellVoltageDatas,
            });

            setChartBmsCommand2MaxCellVoltageNum({
                ...chartBmsCommand2MaxCellVoltageNum,
                data: bmsCommand2MaxCellVoltageNumDatas,
            });

            setChartBmsCommand2MinCellVoltage({
                ...chartBmsCommand2MinCellVoltage,
                data: bmsCommand2MinCellVoltageDatas,
            });

            setChartBmsCommand2MinCellVoltageNum({
                ...chartBmsCommand2MinCellVoltageNum,
                data: bmsCommand2MinCellVoltageNumDatas,
            });

            setChartBmsCommand2SubBatteryVoltage({
                ...chartBmsCommand2SubBatteryVoltage,
                data: bmsCommand2SubBatteryVoltageDatas,
            });

            setChartBmsCommand2IsolationResistance({
                ...chartBmsCommand2IsolationResistance,
                data: bmsCommand2IsolationResistanceDatas,
            });
            //#endregion

            //#region Vcu Command
            setChartVcuCommandGearStatus({
                ...chartVcuCommandGearStatus,
                data: vcuCommandGearStatusDatas,
            });

            setChartVcuCommandAccelPedalStatus({
                ...chartVcuCommandAccelPedalStatus,
                data: vcuCommandAccelPedalStatusDatas,
            });

            setChartVcuCommandVehicleSpeed({
                ...chartVcuCommandVehicleSpeed,
                data: vcuCommandVehicleSpeedDatas,
            });

            setChartVcuCommandIgnitionStatus({
                ...chartVcuCommandIgnitionStatus,
                data: vcuCommandIgnitionStatusDatas,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Kia) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataBongo3.CanData;

            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (bmsCommand1ChartMounted === true || bmsCommand2ChartMounted === true || vcuCommandChartMounted === true) {
            if (onMountedChart !== undefined) onMountedChart();
        }
    }, [bmsCommand1ChartMounted, bmsCommand2ChartMounted, vcuCommandChartMounted]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.BONGO3_BATTERY_PACK_VOLTAGE_NAME:
            case Common.BONGO3_MAX_CELL_VOLTAGE_NAME:
            case Common.BONGO3_MIN_CELL_VOLTAGE_NAME:
            case Common.BONGO3_SUB_BATTERY_VOLTAGE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }

            case Common.BONGO3_BATTERY_PACK_CURRENT_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }

            case Common.BONGO3_BATTERY_SOC_NAME:
            case Common.BONGO3_BATTERY_SOH_NAME:
            case Common.BONGO3_ACCEL_PEDAL_STATUS_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PERCENT);
                break;
            }

            case Common.BONGO3_BATTERY_MAX_TEMPERATURE_NAME:
            case Common.BONGO3_BATTERY_MIN_TEMPERATURE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CELSIUS);
                break;
            }

            case Common.BONGO3_ISOLATION_RESISTANCE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_K_OHM);
                break;
            }

            case Common.BONGO3_VEHICLE_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_SPEED);
                break;
            }

            case Common.BONGO3_GEAR_STATUS_NAME: {
                convertTooltipString = convertVcuCommandGearStatusRemark(value);
                break;
            }

            case Common.BONGO3_IGNITION_STATUS_NAME: {
                convertTooltipString = convertVcuCommandIgnitionStatusRemark(value);
                break;
            }

            default:
                break;
        }
        return convertTooltipString;
    };

    return (
        <div>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <div>
                    <CanDataWrapper
                        title="Bms Command 1"
                        id="bmsCommand1"
                        panelOpen={bmsCommand1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsCommand1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsCommand1}
                        tableSelectRowKeys={selectedBmsCommand1}
                        onChangeTableSelect={onChangeBmsCommand1}
                        tableGetTooltips={getTabelTooltipBmsCommand1}
                        seriesChartDatas={seriesChartDatasBmsCommand1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsCommand1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Bms Command 2"
                        id="bmsCommand2"
                        panelOpen={bmsCommand2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsCommand2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsCommand2}
                        tableSelectRowKeys={selectedBmsCommand2}
                        onChangeTableSelect={onChangeBmsCommand2}
                        tableGetTooltips={getTabelTooltipBmsCommand2}
                        seriesChartDatas={seriesChartDatasBmsCommand2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsCommand2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Vcu Command"
                        id="vcuCommand"
                        panelOpen={vcuCommandPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuCommandPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuCommand}
                        tableSelectRowKeys={selectedVcuCommand}
                        onChangeTableSelect={onChangeVcuCommand}
                        tableGetTooltips={getTabelTooltipVcuCommand}
                        seriesChartDatas={seriesChartDatasVcuCommand}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuCommandChartMounted(true)}
                    />
                </div>
            </Space>
        </div>
    );
}

export default CanDataBongo3Viewer;
