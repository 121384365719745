import { useEffect, useState } from "react";
import Checkbox from "antd/es/checkbox/index";
import Space from "antd/es/space/index";

import styles from "../../pages/mobile/Dashboard.module.css";

import iconDriving from "../../assets/images/icon/icon_driving_dashboard.png";
import iconEnd from "../../assets/images/icon/icon_end_dashboard.png";

import iconError from "../../assets/images/icon/icon_error_dashboard.png";
import iconWarning from "../../assets/images/icon/icon_warning_dashboard.png";
import iconNormal from "../../assets/images/icon/icon_normal_dashboard.png";

import iconEmergency from "../../assets/images/icon/icon_emergency_dashboard.png";
import iconEncourage from "../../assets/images/icon/icon_encourage_dashboard.png";
import iconBatteryNormal from "../../assets/images/icon/icon_batteryNormal_dashboard.png";

const DashboardFilter = ({ filters, onChange }: { filters: string[]; onChange: (filters: string[]) => void }) => {
    const [selected, setSelected] = useState<string[]>(filters);

    useEffect(() => {
        setSelected(filters);
    }, [filters]);

    const dashboardFilters = [
        {
            label: "운행",
            value: "driving",
            icon: iconDriving,
        },
        {
            label: "종료",
            value: "end",
            icon: iconEnd,
            isEnd: true,
        },
        {
            label: "에러",
            value: "error",
            icon: iconError,
        },
        {
            label: "경고",
            value: "warning",
            icon: iconWarning,
        },
        {
            label: "정상",
            value: "normal",
            icon: iconNormal,
            isEnd: true,
        },
        {
            label: "긴급",
            value: "emergency",
            icon: iconEmergency,
        },
        {
            label: "권장",
            value: "encourage",
            icon: iconEncourage,
        },
        {
            label: "정상",
            value: "batteryNormal",
            icon: iconBatteryNormal,
        },
    ];

    const onSelect = (value: string, isChecked: boolean) => {
        const values = isChecked ? [...selected, value] : selected.filter((item) => item !== value);

        setSelected(values);
        onChange(values);
    };

    return (
        <div className={styles.filterOverlay}>
            <Space size={8}>
                {dashboardFilters.map((filter, index) => (
                    <Checkbox
                        key={index}
                        className={selected.includes(filter.value) ? `${styles.checkedFilter} ${styles.filter}` : `${styles.filter}`}
                        checked={selected.includes(filter.value) ? true : false}
                        onChange={(e) => onSelect(filter.value, e.target.checked)}
                    >
                        <img alt="" src={filter.icon} width={16} height={16} />
                        <span className={selected.includes(filter.value) ? "fc-font1" : "fc-font2"}>{filter.label}</span>
                    </Checkbox>
                ))}
            </Space>
        </div>
    );
};

export default DashboardFilter;
