import * as Request from "../commons/request";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAppToken } from "./customAxios";

const DEBUG = true;

export type TypeLogin = {
    username: string;
    password: string;
};

export const useRequestAuthLogin = () => {
    const requestAxiosAuthLogin = async (login: TypeLogin) => {
        const response = await utilAxiosWithAppToken().post(Request.LOGIN_URL, login);

        return response.data;
    };

    const {
        loading: loadingAuthLogin,
        error: errorAuthLogin,
        data: resultAuthLogin,
        execute: requestAuthLogin,
    } = useAsyncAxios(requestAxiosAuthLogin);

    useEffect(() => {
        if (resultAuthLogin === null) return;
        DEBUG && console.log("resultAuthLogin", resultAuthLogin);
    }, [resultAuthLogin]);

    useEffect(() => {
        if (errorAuthLogin === null) return;
        DEBUG && console.log("errorAuthLogin", errorAuthLogin);
    }, [errorAuthLogin]);

    return {
        loadingAuthLogin,
        errorAuthLogin,
        resultAuthLogin,
        requestAuthLogin,
    };
};
