import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiMakerModelRegister = () => {
    const requestAxiosApiMakerModelRegister = async (data: TypeDTO.MakerModelDto) => {
        const response = await utilAxiosWithAuth().post(Request.MAKER_MODEL_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiMakerModelRegister,
        error: errorApiMakerModelRegister,
        data: resultApiMakerModelRegister,
        execute: requestApiMakerModelRegister,
    } = useAsyncAxios(requestAxiosApiMakerModelRegister);

    useEffect(() => {
        if (resultApiMakerModelRegister === null) return;
        DEBUG && console.log("resultApiMakerModelRegister", resultApiMakerModelRegister);
    }, [resultApiMakerModelRegister]);

    useEffect(() => {
        if (errorApiMakerModelRegister === null) return;
        DEBUG && console.log("errorApiMakerModelRegister", errorApiMakerModelRegister);
    }, [errorApiMakerModelRegister]);

    return {
        loadingApiMakerModelRegister,
        errorApiMakerModelRegister,
        resultApiMakerModelRegister,
        requestApiMakerModelRegister,
    };
};

export const useRequestApiMakerModelList = () => {
    const requestAxiosApiMakerModelList = async () => {
        const response = await utilAxiosWithAuth().get(Request.MAKER_MODELS_URL);

        return response.data;
    };

    const {
        loading: loadingApiMakerModelList,
        error: errorApiMakerModelList,
        data: resultApiMakerModelList,
        execute: requestApiMakerModelList,
    } = useAsyncAxios(requestAxiosApiMakerModelList);

    useEffect(() => {
        if (resultApiMakerModelList === null) return;
        DEBUG && console.log("resultApiMakerModelList", resultApiMakerModelList);
    }, [resultApiMakerModelList]);

    useEffect(() => {
        if (errorApiMakerModelList === null) return;
        DEBUG && console.log("errorApiMakerModelList", errorApiMakerModelList);
    }, [errorApiMakerModelList]);

    return {
        loadingApiMakerModelList,
        errorApiMakerModelList,
        resultApiMakerModelList,
        requestApiMakerModelList,
    };
};

export const useRequestApiMakerModelInfo = () => {
    const requestAxiosApiMakerModelInfo = async (makerModelId: number) => {
        const response = await utilAxiosWithAuth().get(Request.MAKER_MODEL_URL + makerModelId);

        return response.data;
    };

    const {
        loading: loadingApiMakerModelInfo,
        error: errorApiMakerModelInfo,
        data: resultApiMakerModelInfo,
        execute: requestApiMakerModelInfo,
    } = useAsyncAxios(requestAxiosApiMakerModelInfo);

    useEffect(() => {
        if (resultApiMakerModelInfo === null) return;
        DEBUG && console.log("resultApiMakerModelInfo", resultApiMakerModelInfo);
    }, [resultApiMakerModelInfo]);

    useEffect(() => {
        if (errorApiMakerModelInfo === null) return;
        DEBUG && console.log("errorApiMakerModelInfo", errorApiMakerModelInfo);
    }, [errorApiMakerModelInfo]);

    return {
        loadingApiMakerModelInfo,
        errorApiMakerModelInfo,
        resultApiMakerModelInfo,
        requestApiMakerModelInfo,
    };
};

export const useRequestApiMakerModelUpdate = () => {
    const requestAxiosApiMakerModelUpdate = async (makerModelId: number, data: TypeDTO.MakerModelDto) => {
        const response = await utilAxiosWithAuth().put(Request.MAKER_MODEL_URL + makerModelId, data);

        return response.data;
    };

    const {
        loading: loadingApiMakerModelUpdate,
        error: errorApiMakerModelUpdate,
        data: resultApiMakerModelUpdate,
        execute: requestApiMakerModelUpdate,
    } = useAsyncAxios(requestAxiosApiMakerModelUpdate);

    useEffect(() => {
        if (resultApiMakerModelUpdate === null) return;
        DEBUG && console.log("resultApiMakerModelUpdate", resultApiMakerModelUpdate);
    }, [resultApiMakerModelUpdate]);

    useEffect(() => {
        if (errorApiMakerModelUpdate === null) return;
        DEBUG && console.log("errorApiMakerModelUpdate", errorApiMakerModelUpdate);
    }, [errorApiMakerModelUpdate]);

    return {
        loadingApiMakerModelUpdate,
        errorApiMakerModelUpdate,
        resultApiMakerModelUpdate,
        requestApiMakerModelUpdate,
    };
};

export const useRequestApiMakerModelDelete = () => {
    const requestAxiosApiMakerModelDelete = async (makerModelId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.MAKER_MODEL_URL + makerModelId);

        return response.data;
    };

    const {
        loading: loadingApiMakerModelDelete,
        error: errorApiMakerModelDelete,
        data: resultApiMakerModelDelete,
        execute: requestApiMakerModelDelete,
    } = useAsyncAxios(requestAxiosApiMakerModelDelete);

    useEffect(() => {
        if (resultApiMakerModelDelete === null) return;
        DEBUG && console.log("resultApiMakerModelDelete", resultApiMakerModelDelete);
    }, [resultApiMakerModelDelete]);

    useEffect(() => {
        if (errorApiMakerModelDelete === null) return;
        DEBUG && console.log("errorApiMakerModelDelete", errorApiMakerModelDelete);
    }, [errorApiMakerModelDelete]);

    return {
        loadingApiMakerModelDelete,
        errorApiMakerModelDelete,
        resultApiMakerModelDelete,
        requestApiMakerModelDelete,
    };
};
