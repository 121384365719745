import { useEffect } from "react";
import { useAuthState } from "../../provider/AuthProvider";
import Button from "antd/es/button/button";
import Form from "antd/es/form/index";
import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import LeftCircleOutlined from "@ant-design/icons/lib/icons/LeftCircleOutlined";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import CustomForm, { viewType } from "../browser/CustomForm";
import dayjs from "dayjs";

function CustomModal({
    title,
    open,
    type,
    value,
    hasButton,
    isLicense,
    contents,
    onChangedOpen,
    onChangedType,
    onFinish,
    onValuesChange,
    onClick,
}: {
    title: string;
    open: boolean;
    type: viewType;
    value?: any;
    hasButton?: string;
    isLicense?: boolean;
    contents: Array<TypeUtils.formType>;
    onChangedOpen: (open: boolean) => void;
    onChangedType?: (type: viewType) => void;
    onFinish?: (type: viewType, value: any) => void;
    onValuesChange?: (value: any) => void;
    onClick?: (value: string) => void;
}) {
    const userDetails = useAuthState();
    const [form] = Form.useForm();

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                ...value,
                equippedDate: value?.equippedDate && dayjs(value?.equippedDate),
                expiredDate: value?.expiredDate && dayjs(value?.expiredDate),
                releaseDate: value?.releaseDate && dayjs(value?.releaseDate),
            });
        } else {
            form.resetFields();
        }
    }, [open, value]);

    const handleClose = () => {
        onChangedOpen(false);
    };

    return (
        <Modal
            centered
            forceRender
            closable={false}
            open={open}
            title={
                <>
                    <Space style={{ width: "100%", justifyContent: "space-between" }} align="center">
                        <h4 style={{ margin: 0 }}>
                            {title} {type === "view" ? String.view : type === "edit" ? String.update : String.register}
                        </h4>
                        {hasButton === "license" &&
                            type === "view" &&
                            (!isLicense ? (
                                <div
                                    style={{ display: "flex", alignItems: "center", color: "var(--point)", cursor: "pointer", padding: 2 }}
                                    onClick={() => onClick && onClick("license")}
                                >
                                    <DownloadOutlined style={{ marginRight: "8px" }} />
                                    라이선스 재발급
                                </div>
                            ) : (
                                <div
                                    style={{ display: "flex", alignItems: "center", color: "var(--point)", cursor: "pointer", padding: 2 }}
                                    onClick={() => onClick && onClick("edit")}
                                >
                                    <LeftCircleOutlined style={{ marginRight: "8px" }} />
                                    서비스 조회
                                </div>
                            ))}
                        {Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) &&
                            hasButton === "password" &&
                            type === "view" && (
                                <div
                                    style={{ display: "flex", alignItems: "center", color: "var(--danger)", cursor: "pointer", padding: 2 }}
                                    onClick={() => onClick && onClick("password")}
                                >
                                    <LockOutlined style={{ marginRight: "8px" }} />
                                    비밀번호 초기화
                                </div>
                            )}
                    </Space>
                </>
            }
            onOk={handleClose}
            onCancel={handleClose}
            footer={
                <Space style={{ width: "100%", justifyContent: "center" }} size={16} align="center">
                    <Button key="back" onClick={handleClose}>
                        닫기
                    </Button>
                    {onFinish && (
                        <Button
                            className="btn-primary"
                            key="user"
                            onClick={() => (isLicense ? form.submit() : type === "view" ? onChangedType && onChangedType("edit") : form.submit())}
                        >
                            {isLicense ? "발급" : value ? (type === "view" ? String.update : String.save) : String.register}
                        </Button>
                    )}
                </Space>
            }
        >
            <CustomForm
                form={form}
                type={type}
                disabledBtn
                contents={[{ forms: contents }]}
                onFinish={onFinish}
                onChangeType={onChangedType}
                onValuesChange={onValuesChange}
            />
        </Modal>
    );
}

export default CustomModal;
