import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { TableProps } from "antd/es/table/InternalTable";
import { useSelector, useDispatch } from "react-redux";
import { actionSetSocs } from "../../utils/action";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as RequestCar from "../../utils/requestApiCar";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import IconError from "../../assets/images/icon/icon_red_exclamation.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

const PageSoc = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reducerSocs = useSelector((state) => state.socs);

    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.RunningHistoryDto>>({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<SearchType>();
    const [pageSize, setPageSize] = useState<number>(20);

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
        {
            id: "checkbox",
            span: 12,
            title: String.status,
            type: "checkbox",
            options: [
                { value: "batteryWarning", label: String.warning },
                { value: "batteryNormal", label: String.active },
            ],
            defaultValue: ["batteryWarning", "batteryNormal"],
        },
    ];

    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.CarDto> = [
        {
            title: String.status,
            dataIndex: ["runningSummary", "lastRunningHistory", "socCheck"],
            key: "socCheck",
            align: "center",
            width: "10%",
            render: (_, { runningSummary }) => {
                return runningSummary?.lastRunningHistory?.socCheck ? (
                    <img alt="icon" src={IconError} width={20} height={20} />
                ) : (
                    <img alt="icon" src={IconCheck} width={20} height={20} />
                );
            },
        },
        {
            title: String.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            width: "10%",
            align: "center",
        },
        {
            title: String.maker,
            dataIndex: ["makerModel", "maker", "makerName"],
            key: "makerName",
            width: "10%",
            align: "center",
        },
        {
            title: (
                <>
                    {String.soc}
                    <span className="fs-xs"> {String.percentUnit}</span>
                </>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "endSoc"],
            key: "endSoc",
            width: "10%",
            sorter: (a, b, sortOrder) =>
                Utils.sortNumber(a.runningSummary?.lastRunningHistory?.endSoc, b.runningSummary?.lastRunningHistory?.endSoc, sortOrder),
            sortOrder: sortedInfo.columnKey === "endSoc" ? sortedInfo.order : null,
            render: (_, { runningSummary }) => Utils.checkNumberData(runningSummary?.lastRunningHistory?.endSoc) || String.dash,
        },
        {
            title: (
                <>
                    {String.menu_soc}
                    <span className="fs-xs"> {String.percentUnit}</span>
                </>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "tableSoc"],
            key: "tableSoc",
            width: "10%",
            sorter: (a, b, sortOrder) =>
                Utils.sortNumber(a.runningSummary?.lastRunningHistory?.tableSoc, b.runningSummary?.lastRunningHistory?.tableSoc, sortOrder),
            sortOrder: sortedInfo.columnKey === "tableSoc" ? sortedInfo.order : null,
            render: (_, { runningSummary }) => Utils.checkNumberData(runningSummary?.lastRunningHistory?.tableSoc) || String.dash,
        },
        {
            title: (
                <>
                    {String.maxTemp}
                    <span className="fs-xs"> {String.tempUnit}</span>
                </>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "batMaxTemp"],
            key: "batMaxTemp",
            width: "10%",
            align: "center",
            render: (_, { runningSummary }) => runningSummary?.lastRunningHistory?.batMaxTemp || String.dash,
        },
        {
            title: (
                <>
                    {String.minTemp}
                    <span className="fs-xs"> {String.tempUnit}</span>
                </>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "batMinTemp"],
            key: "batMinTemp",
            width: "10%",
            align: "center",
            render: (_, { runningSummary }) => runningSummary?.lastRunningHistory?.batMinTemp || String.dash,
        },
        {
            title: (
                <>
                    {String.maxVolt}
                    <span className="fs-xs"> {String.voltUnit}</span>
                </>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "batMaxVolt"],
            key: "batMaxVolt",
            width: "10%",
            sorter: (a, b, sortOrder) =>
                Utils.sortNumber(
                    a.runningSummary?.lastRunningHistory?.batMaxVolt,
                    b.runningSummary?.lastRunningHistory?.batMaxVolt,
                    sortOrder
                ),
            sortOrder: sortedInfo.columnKey === "maxVolt" ? sortedInfo.order : null,
            render: (_, { runningSummary }) => Utils.checkNumberData(runningSummary?.lastRunningHistory?.batMaxVolt) || String.dash,
        },
        {
            title: (
                <>
                    {String.minVolt}
                    <span className="fs-xs"> {String.voltUnit}</span>
                </>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "batMinVolt"],
            key: "batMinVolt",
            width: "10%",
            sorter: (a, b, sortOrder) =>
                Utils.sortNumber(
                    a.runningSummary?.lastRunningHistory?.batMinVolt,
                    b.runningSummary?.lastRunningHistory?.batMinVolt,
                    sortOrder
                ),
            sortOrder: sortedInfo.columnKey === "minVolt" ? sortedInfo.order : null,
            render: (_, { runningSummary }) => Utils.checkNumberData(runningSummary?.lastRunningHistory?.batMinVolt) || String.dash,
        },
    ];

    useEffect(() => {
        requestApiCarList(true);
    }, []);

    useEffect(() => {
        searchValue && onSearchData(searchValue);

        setTimeout(() => {
            document.querySelector(".ant-layout-content").scrollTo({ top: reducerSocs.scrollPosition });
        }, 100);
    }, [cars]);

    useEffect(() => {
        if (Object.keys(reducerSocs).length > 0) {
            setCurrentPage(reducerSocs?.currentPage);

            setSearch(
                searchData?.map((item) => {
                    const { id, defaultValue } = item;

                    return {
                        ...item,
                        defaultValue: reducerSocs[id] ?? defaultValue,
                    };
                })
            );
        } else {
            setSearch(searchData);
        }
    }, [reducerSocs]);

    const dispatchSocs = (selectedId: number) => {
        console.log("dispatchSocs", searchValue);

        dispatch(
            actionSetSocs({
                ...searchValue,
                currentPage: currentPage,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
                pageSize: pageSize,
            })
        );
    };

    const onTableChange: TableProps<TypeDTO.IssueDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.RunningHistoryDto>);
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(
            resultApiCarList.cars.sort((a: TypeDTO.CarDto, b: TypeDTO.CarDto) =>
                !a?.runningSummary?.lastRunningHistory?.socCheck ? 1 : !b?.runningSummary?.lastRunningHistory?.socCheck ? -1 : 0
            )
        );
    }, [resultApiCarList]);

    const onSearchData = (value: any) => {
        setSearchValue(value);

        const { carNumber, checkbox } = value;
        const batteryWarning = checkbox?.includes("batteryWarning");
        const batteryNormal = checkbox?.includes("batteryNormal");

        setFilteredCars(
            cars?.filter((car) => {
                if (!Utils.searchFilter(car, carNumber, "carNumber")) return false;

                if (batteryWarning && batteryNormal) {
                    return true;
                }

                if (batteryWarning) {
                    return car?.runningSummary?.lastRunningHistory?.socCheck;
                }

                if (batteryNormal) {
                    return !car?.runningSummary?.lastRunningHistory?.socCheck;
                }

                return true;
            })
        );
    };

    return (
        <div className="pageWrapper">
            <Search
                title={String.menu_soc}
                dataLength={filteredCars?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
            />
            <DataTable
                rowKey={(row: TypeDTO.CarDto) => row?.carId}
                disabledTitle
                loading={loadingApiCarList}
                columns={columns}
                dataSource={filteredCars}
                currentPage={currentPage}
                rowClassName={(running: TypeDTO.CarDto) => {
                    return running?.socCheck ? "table-row-warning" : running.carId === reducerSocs.selectedId ? "table-row-selected" : "";
                }}
                tableSize={reducerSocs?.pageSize}
                onRow={(value: TypeDTO.CarDto) => {
                    dispatchSocs(value.carId);
                    navigate(Common.PAGE_ISSUE_SOCS + "/" + value.carNumber);
                }}
                onChange={onTableChange}
            />
        </div>
    );
};

export default PageSoc;
