import * as React from "react";
import { useParams } from "react-router";

import Socs from "../../components/browser/Socs";

const PageSocDetail = () => {
    const params = useParams();

    return (
        <div className="pageWrapper">
            <Socs carNumber={params.id} />
        </div>
    );
};

export default PageSocDetail;
