import { useState, useEffect } from "react";
import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import { TableProps } from "antd/es/table/InternalTable";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestTerminal from "../../utils/requestApiTerminal";

import DatePicker from "antd/es/date-picker/index";
import Modal from "antd/es/modal/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";

import moment from "moment";
import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";
import dayjs from "dayjs";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";
import { t } from "msw/lib/glossary-de6278a9";

type SearchType = {
    serialNumber: string;
    carNumber: string;
    sysVer: string;
    mcuVer: string;
};

const PageTerminal = () => {
    const userDetails = useAuthState();
    const alert = useAlert();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [type, setType] = useState<viewType>("view");
    const [terminal, setTerminal] = useState<TypeDTO.TerminalDto>();
    const [terminals, setTerminals] = useState<Array<TypeDTO.TerminalDto>>();
    const [filteredTerminals, setFilteredTerminals] = useState<Array<TypeDTO.TerminalDto>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.TerminalDto>>({});

    useEffect(() => {
        requestApiTerminalList();
    }, []);

    const columns: ColumnsType<TypeDTO.TerminalDto> = [
        {
            title: String.serialNumber,
            dataIndex: "serialNumber",
            key: "serialNumber",
            width: "20%",
            align: "center",
        },
        {
            title: String.systemVersion,
            dataIndex: "sysVer",
            key: "sysVer",
            width: "10%",
            align: "center",
            render: (_, { sysVer }) => sysVer || String.dash,
        },
        {
            title: String.mcuVersion,
            dataIndex: "mcuVer",
            key: "mcuVer",
            width: "10%",
            align: "center",
            render: (_, { mcuVer }) => mcuVer || String.dash,
        },
        {
            title: String.terminalType,
            dataIndex: "terminalType",
            key: "terminalType",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortString(a.terminalType, b.terminalType, sortOrder),
            sortOrder: sortedInfo.columnKey === "terminalType" ? sortedInfo.order : null,
            ellipsis: true,
            render: (_, { terminalType }) => terminalType || String.dash,
        },
        {
            title: String.equippedDate,
            dataIndex: "equippedDate",
            key: "equippedDate",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.equippedDate, b.equippedDate, sortOrder),
            sortOrder: sortedInfo.columnKey === "equipDate" ? sortedInfo.order : null,
            ellipsis: true,
            render: (_, { equippedDate }) => (equippedDate ? moment(equippedDate, Common.FORMAT_DATE_TIME).format(Common.FORMAT_DATE) : String.dash),
        },
        {
            title: String.carNumber,
            dataIndex: ["car", "carNumber"],
            key: "carNumber",
            width: "10%",
            align: "center",
            render: (_, { car }) => car?.carNumber || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRow("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.terminalId, row.serialNumber);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "serialNumber",
            span: 12,
            title: String.serialNumber,
            type: "select",
            options: terminals?.map((terminal) => ({ value: terminal.serialNumber, label: terminal.serialNumber })),
        },
        {
            id: "sysVer",
            span: 12,
            title: String.systemVersion,
            type: "select",
            options: Utils.removeDuplicateObjects(terminals?.map((terminal) => ({ value: terminal.sysVer, label: terminal.sysVer })))?.reverse(),
        },
        {
            id: "mcuVer",
            span: 12,
            title: String.mcuVersion,
            type: "select",
            options: Utils.removeDuplicateObjects(terminals?.map((terminal) => ({ value: terminal.mcuVer, label: terminal.mcuVer })))?.reverse(),
        },
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        {
            name: "serialNumber",
            label: String.serialNumber,
            span: 24,
            required: true,
        },

        {
            name: "sysVer",
            label: String.systemVersion,
            span: 12,
        },
        {
            name: "mcuVer",
            label: String.mcuVersion,
            span: 12,
        },
        {
            name: "terminalType",
            label: String.terminalType,
            span: 24,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="분류를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    options={[
                        {
                            value: "FMU",
                            label: "FMU",
                        },
                        {
                            value: "FMU_MINI",
                            label: "FMU_MINI",
                        },
                    ]}
                />
            ),
        },
        {
            name: "equippedDate",
            label: String.equippedDate,
            span: 24,
            input: <DatePicker className={type === "view" ? "disabled-datepicker" : ""} disabled={type === "view"} />,
        },
        {
            name: ["car", "carNumber"],
            label: String.carNumber,
            span: terminal?.car ? 12 : 24,
            disabled: true,
        },
        {
            name: ["car", "carId"],
            label: "carId",
            span: 0,
            disabled: true,
        },
        {
            name: ["car", "makerModel", "maker", "makerName"],
            label: String.maker,
            span: terminal?.car ? 12 : 0,
            disabled: true,
        },
        {
            name: ["car", "customer", "customerManager", "customerManagerName"],
            label: String.customerManager,
            span: terminal?.car ? 12 : 0,
            disabled: true,
        },
        {
            name: ["car", "customer", "customerName"],
            label: String.customer,
            span: terminal?.car ? 12 : 0,
            disabled: true,
        },
    ];

    //get terminals
    const { loadingApiTerminalList, requestApiTerminalList, resultApiTerminalList } = RequestTerminal.useRequestApiTerminalList();

    useEffect(() => {
        if (!resultApiTerminalList) return;

        setTerminals(resultApiTerminalList.terminals);
    }, [resultApiTerminalList]);

    const { requestApiTerminalRegister, resultApiTerminalRegister } = RequestTerminal.useRequestApiTerminalRegister();

    useEffect(() => {
        if (!resultApiTerminalRegister) return;

        onResult("register");
    }, [resultApiTerminalRegister]);

    const { requestApiTerminalUpdate, resultApiTerminalUpdate } = RequestTerminal.useRequestApiTerminalUpdate();

    useEffect(() => {
        if (!resultApiTerminalUpdate) return;

        onResult("edit");
    }, [resultApiTerminalUpdate]);

    const { requestApiTerminalDelete, resultApiTerminalDelete } = RequestTerminal.useRequestApiTerminalDelete();

    useEffect(() => {
        if (!resultApiTerminalDelete) return;

        onResult("delete");
    }, [resultApiTerminalDelete]);

    const onTableChange: TableProps<TypeDTO.TerminalDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.TerminalDto>);
    };

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;
        setFilteredTerminals(
            terminals?.filter(
                (terminal) =>
                    searchFilter(terminal, value?.serialNumber, "serialNumber") &&
                    searchFilter(terminal?.car, value?.carNumber, "carNumber") &&
                    searchFilter(terminal, value?.sysVer, "sysVer") &&
                    searchFilter(terminal, value?.mcuVer, "mcuVer")
            )
        );
    };

    const onRow = (type: viewType, value?: TypeDTO.TerminalDto) => {
        setTerminal(value);
        setIsOpen(true);
        setType(type);
    };

    const onDelete = (id: number, serialNumber: string) => {
        Modal.confirm({
            title: String.terminal + String.remove,
            content: Utils.addParticle(serialNumber, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiTerminalDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinish = (type: viewType, value: TypeDTO.TerminalDto) => {
        const searchValue = {
            ...value,
            equippedDate: value.equippedDate && dayjs(value.equippedDate).format(Common.FORMAT_DATE_HOUR_TIME),
        };
        type === "register" ? requestApiTerminalRegister(searchValue) : requestApiTerminalUpdate(terminal?.terminalId, searchValue);
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.terminal} ${typeText} 성공`, `${String.terminal} 정보를 ${typeText}하였습니다.`);
        requestApiTerminalList();

        type !== "delete" && setIsOpen(false);
    };

    return (
        <div className="pageWrapper">
            <Search
                title={String.terminal}
                dataLength={filteredTerminals?.length || terminals?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? () => onRow("register", undefined) : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.TerminalDto) => row.terminalId}
                disabledTitle
                loading={loadingApiTerminalList}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={filteredTerminals || terminals}
                onRow={(value) => onRow("view", value)}
                onChange={onTableChange}
            />
            <CustomModal
                title={String.terminal}
                open={isOpen}
                type={type}
                value={terminal}
                contents={contents}
                onChangedOpen={() => setIsOpen(false)}
                onChangedType={(value) => setType(value)}
                onFinish={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? onFinish : undefined}
            />
        </div>
    );
};

export default PageTerminal;
