import { useEffect, useState } from "react";

import CustomForm, { viewType } from "../mobile/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useParams, useLocation, useNavigate } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";

import * as Common from "../../commons/common";
import * as RequestCar from "../../utils/requestApiCar";
import * as RequestTerminal from "../../utils/requestApiTerminal";
import * as RequestModem from "../../utils/requestApiModem";
import * as RequestDoorModule from "../../utils/requestApiDoorModule";
import * as RequestDoorModules from "../../utils/requestApiDoorModules";
import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import Button from "antd/es/button/button";
import Modal from "antd/es/modal/index";
import Form from "antd/es/form/index";
import Divider from "antd/es/divider/index";
import SubBatteryHistory from "../common/SubBatteryHistory";
import DoorModuleHistory from "../common/DoorModuleHistory";
import dayjs from "dayjs";

import DoorModuleModal from "../common/DoorModuleModal";

const TabDevices = ({ carId }: { carId?: number }) => {
    const { pathname } = useLocation();
    const param = useParams();
    const alert = useAlert();
    const userDetails = useAuthState();
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [type, setType] = useState<viewType>("view");
    const [editedDevice, setEditedDevice] = useState<string>();
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [terminals, setTerminals] = useState<Array<TypeDTO.TerminalDto>>();
    const [modems, setModems] = useState<Array<TypeDTO.TerminalModemDto>>();
    const [doorModules, setDoorModules] = useState<Array<TypeDTO.DoorModuleDto>>();
    const [forced, setForced] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        form.resetFields();
        if (pathname.includes(Common.PAGE_MANAGE_CAR_DEVICE_UPDATE)) {
            setType("edit");
            param.id1 && setEditedDevice(param.id1);
            param.id2 && requestApiCarInfo(param.id2);
        }
    }, [param]);

    useEffect(() => {
        if (carId) {
            requestApiCarInfo(carId);
            requestApiTerminalList();
            requestApiModemList();
            requestApiDoorModuleList();
        }
    }, [carId]);

    const terminalContents: Array<TypeUtils.formType> = [
        {
            key: "terminalSerialNumber",
            name: ["terminal", "serialNumber"],
            label: String.serialNumber,
            customSelector: {
                title: String.serialNumber,
                typeDetail: "terminalSerialNumber",
                options: terminals
                    ?.sort((a, b) => (a?.car?.carNumber !== undefined ? 0 : b?.car?.carNumber === undefined ? 1 : -1))
                    ?.map((terminal) => {
                        return {
                            value: terminal.serialNumber,
                            label: `${terminal.serialNumber} ${type === "view" ? "" : terminal.car?.carNumber ? `(${terminal.car.carNumber})` : ""}`,
                        };
                    }),
                placeholder: type === "view" ? "-" : "단말기",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                defaultValue: car?.terminal?.serialNumber,
            },
        },
        {
            name: ["terminal", "terminalType"],
            label: String.modelName,
            disabled: true,
        },
        {
            name: ["terminal", "sysVer"],
            label: String.systemVersion,
            disabled: true,
        },
        {
            name: ["terminal", "mcuVer"],
            label: String.mcuVersion,
            disabled: true,
        },
        {
            key: "terminalEquippedDate",
            name: ["terminal", "equippedDate"],
            label: String.equippedDate,
            disabled: true,
        },
    ];

    const modemContents: Array<TypeUtils.formType> = [
        {
            key: "modemSerialNumber",
            name: ["terminalModem", "serialNumber"],
            label: String.serialNumber,
            customSelector: {
                title: String.serialNumber,
                typeDetail: "modemSerialNumber",
                options: modems
                    ?.sort((a, b) => (a?.car?.carNumber !== undefined ? 0 : b?.car?.carNumber === undefined ? 1 : -1))
                    ?.map((modem) => {
                        return {
                            value: modem.serialNumber,
                            label: `${modem.serialNumber} ${type === "view" ? "" : modem.car?.carNumber ? `(${modem.car.carNumber})` : ""}`,
                        };
                    }),
                placeholder: type === "view" ? "-" : "모뎀",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                defaultValue: car?.terminalModem?.serialNumber,
            },
        },
        {
            key: "modemCarrier",
            name: ["terminalModem", "carrier"],
            label: String.carrier,
            disabled: true,
        },
        {
            key: "modemPhone",
            name: ["terminalModem", "phone"],
            label: String.phoneNumber,
            disabled: true,
        },
        {
            key: "activatedDate",
            name: ["terminalModem", "activatedDate"],
            label: "개통일",
            disabled: true,
        },
        {
            key: "ratePlan",
            name: ["terminalModem", "ratePlan"],
            label: "요금제",
            disabled: true,
        },
        {
            key: "contractTerm",
            name: ["terminalModem", "contractTerm"],
            label: "약정 기간",
            disabled: true,
        },
        {
            key: "model",
            name: ["terminalModem", "model"],
            label: "모델",
            disabled: true,
        },
        {
            key: "modemEquippedDate",
            name: ["terminalModem", "equippedDate"],
            label: String.equippedDate,
            disabled: true,
        },
    ];

    const doorModuleContents: Array<TypeUtils.formType> = [
        {
            key: "doorModuleSerialNumber",
            name: ["doorModule", "serialNumber"],
            label: String.serialNumber,
            customSelector: {
                title: String.serialNumber,
                typeDetail: "doorModuleSerialNumber",
                options: doorModules
                    ?.sort((a, b) => (a?.car?.carNumber !== undefined ? 0 : b?.car?.carNumber === undefined ? 1 : -1))
                    ?.map((doorModule) => {
                        return {
                            value: doorModule.serialNumber,
                            label: `${doorModule.serialNumber} ${
                                type === "view" ? "" : doorModule.car?.carNumber ? `(${doorModule.car.carNumber})` : ""
                            }`,
                        };
                    }),
                placeholder: type === "view" ? "-" : "도어모듈",
                className: type === "view" ? "disabled-selector" : "",
                disabled: type === "view",
                defaultValue: car?.doorModule?.serialNumber,
            },
        },
        {
            key: "swVersion",
            name: ["doorModule", "swVersion"],
            label: String.swVersion,
            disabled: true,
        },
        {
            name: ["doorModule", "imei"],
            label: String.imei,
            disabled: true,
        },
        {
            key: "doorModuleModemCarrier",
            name: ["doorModule", "carrier"],
            label: String.carrier,
            disabled: true,
        },
        {
            key: "doorModuleModemPhone",
            name: ["doorModule", "phone"],
            label: String.phoneNumber,
            disabled: true,
        },
        {
            key: "equippedDate",
            name: ["doorModule", "equippedDate"],
            label: String.equippedDate,
            disabled: true,
        },
    ];

    const { loadingApiCarInfo, requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;

        const car = resultApiCarInfo.car;
        setCar(car);
        form.setFieldsValue({
            ...car,
            terminal: {
                ...car.terminal,
                equippedDate: car?.terminal?.equippedDate && dayjs(car?.terminal?.equippedDate).format(Common.FORMAT_SHORT_DATE),
            },
            terminalModem: {
                ...car.terminalModem,
                phone: Utils.convertPhone(car?.terminalModem?.phone),
                equippedDate: car?.terminalModem?.equippedDate && dayjs(car?.terminalModem?.equippedDate).format(Common.FORMAT_SHORT_DATE),
            },
            doorModule: {
                ...car.doorModule,
                phone: Utils.convertPhone(car?.doorModule?.phone),
                equippedDate: car?.doorModule?.equippedDate && dayjs(car?.doorModule?.equippedDate).format(Common.FORMAT_SHORT_DATE),
            },
        });
    }, [resultApiCarInfo]);

    const { requestApiCarRegister, resultApiCarRegister } = RequestCar.useRequestApiCarRegister();

    useEffect(() => {
        if (!resultApiCarRegister) return;

        onResult("register");
    }, [resultApiCarRegister]);

    const { requestApiCarUpdate, resultApiCarUpdate } = RequestCar.useRequestApiCarUpdate();

    useEffect(() => {
        if (!resultApiCarUpdate) return;

        onResult("edit");
    }, [resultApiCarUpdate]);

    const { requestApiTerminalList, resultApiTerminalList } = RequestTerminal.useRequestApiTerminalList();

    useEffect(() => {
        if (!resultApiTerminalList) return;

        setTerminals(resultApiTerminalList.terminals);
    }, [resultApiTerminalList]);

    const { requestApiModemList, resultApiModemList } = RequestModem.useRequestApiModemList();

    useEffect(() => {
        if (!resultApiModemList) return;

        setModems(resultApiModemList.terminalModems);
    }, [resultApiModemList]);

    const { requestApiDoorModuleList, resultApiDoorModuleList } = RequestDoorModule.useRequestApiDoorModuleList();

    useEffect(() => {
        if (!resultApiDoorModuleList) return;

        setDoorModules(resultApiDoorModuleList.doorModules);
    }, [resultApiDoorModuleList]);

    const { requestApiDoorModulesStatus, resultApiDoorModulesStatus } = RequestDoorModules.useRequestApiDoorModulesStatus();

    useEffect(() => {
        if (!resultApiDoorModulesStatus) return;

        alert.setAlert(AlertType.SUCCESS, `상태 확인 요청`, `도어모듈 상태 확인 요청을 보냈습니다.`);
    }, [resultApiDoorModulesStatus]);

    const onFinish = (type: viewType, value: TypeDTO.CarDto) => {
        const terminalId = terminals?.find((terminal) => terminal?.serialNumber === value?.terminal?.serialNumber)?.terminalId || null;
        const terminalModemId = modems?.find((modem) => modem?.serialNumber === value?.terminalModem?.serialNumber)?.terminalModemId || null;
        const doorModuleId = doorModules?.find((doorModule) => doorModule?.serialNumber === value?.doorModule?.serialNumber)?.doorModuleId || null;
        const body = terminalId
            ? {
                  terminal: { terminalId },
              }
            : terminalModemId
            ? {
                  terminalModem: { terminalModemId },
              }
            : doorModuleId
            ? {
                  doorModule: { doorModuleId },
              }
            : {};
        type === "register" ? requestApiCarRegister(body) : requestApiCarUpdate(carId, body, forced);
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.menu_car_device} ${typeText} 성공`, `${String.menu_car_device} 정보를 ${typeText}하였습니다.`);
        setType("view");
        navigate(-1);
    };

    return (
        <>
            {car?.doorModule && type === "view" && (
                <>
                    <SubBatteryHistory isMobile={true} doorModuleId={car?.doorModule?.doorModuleId} />
                    <Divider className="customDivider" />
                    <DoorModuleHistory isMobile={true} doorModuleId={car?.doorModule?.doorModuleId} />
                    <Divider className="customDivider" />
                </>
            )}
            {(editedDevice === "terminal" || type === "view") && (
                <CustomForm
                    form={form}
                    type={type}
                    initialValues={car}
                    contents={[{ name: "단말기", forms: terminalContents }]}
                    onFinish={onFinish}
                    onChangeType={(type) => {
                        setEditedDevice("단말기");
                        setType(type);
                        navigate(Common.PAGE_MANAGE_CAR_DEVICE_UPDATE + "/terminal" + "/" + carId);
                    }}
                    onChangeSelectorValue={(key, value) => {
                        form.setFieldValue(key, value);
                        const defaultTerminal = terminals?.find((terminal) => terminal.serialNumber === car?.terminal?.serialNumber);
                        const terminal = terminals?.find((terminal) => terminal.serialNumber === value);
                        if (terminal?.car) {
                            return Modal.confirm({
                                title: "등록 해제",
                                content: (
                                    <>
                                        <p className="fw-rg fs-md" style={{ margin: 0 }}>
                                            해당 단말기는 {terminal?.car?.carNumber} 차량에 등록되어 있습니다. 기존 등록 해제 후 등록하시겠습니까?
                                        </p>
                                    </>
                                ),
                                okText: String.confirm,
                                onOk() {
                                    setForced(true);
                                    form.setFieldValue("terminal", {
                                        ...terminal,
                                        equippedDate: terminal?.equippedDate && dayjs(terminal?.equippedDate).format(Common.FORMAT_SHORT_DATE),
                                    });
                                },
                                cancelText: String.cancel,
                                onCancel() {
                                    form.setFieldValue("terminal", {
                                        ...defaultTerminal,
                                        equippedDate:
                                            defaultTerminal?.equippedDate && dayjs(defaultTerminal?.equippedDate).format(Common.FORMAT_SHORT_DATE),
                                    });
                                },
                                centered: true,
                            });
                        } else {
                            form.setFieldValue("terminal", {
                                ...terminal,
                                equippedDate: terminal?.equippedDate && dayjs(terminal?.equippedDate).format(Common.FORMAT_SHORT_DATE),
                            });
                        }
                    }}
                    disabledBtn={!Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                />
            )}
            {type === "view" && <Divider className="customDivider" />}
            {(editedDevice === "modem" || type === "view") && (
                <CustomForm
                    form={form}
                    type={type}
                    initialValues={car}
                    contents={[{ name: "모뎀", forms: modemContents }]}
                    onFinish={onFinish}
                    onChangeType={(type) => {
                        setEditedDevice("모뎀");
                        setType(type);
                        navigate(Common.PAGE_MANAGE_CAR_DEVICE_UPDATE + "/modem" + "/" + carId);
                    }}
                    onChangeSelectorValue={(key, value) => {
                        form.setFieldValue(key, value);
                        const defaultTerminalModem = modems?.find((modem) => modem.serialNumber === car?.terminalModem?.serialNumber);
                        const terminalModem = modems?.find((modem) => modem.serialNumber === value);
                        if (terminalModem?.car) {
                            return Modal.confirm({
                                title: "등록 해제",
                                content: (
                                    <>
                                        <p className="fw-rg fs-md" style={{ margin: 0 }}>
                                            해당 모뎀은 {terminalModem?.car?.carNumber} 차량에 등록되어 있습니다.
                                        </p>
                                        <p style={{ margin: 0 }}>기존 등록 해제 후 등록하시겠습니까?</p>
                                    </>
                                ),
                                okText: String.confirm,
                                onOk() {
                                    setForced(true);
                                    form.setFieldValue("terminalModem", {
                                        ...terminalModem,
                                        equippedDate:
                                            terminalModem?.equippedDate && dayjs(terminalModem?.equippedDate).format(Common.FORMAT_SHORT_DATE),
                                        activatedDate:
                                            terminalModem?.activatedDate && dayjs(terminalModem?.activatedDate).format(Common.FORMAT_SHORT_DATE),
                                    });
                                },
                                cancelText: String.cancel,
                                onCancel() {
                                    form.setFieldValue("terminalModem", {
                                        ...defaultTerminalModem,
                                        equippedDate:
                                            defaultTerminalModem?.equippedDate &&
                                            dayjs(defaultTerminalModem?.equippedDate).format(Common.FORMAT_SHORT_DATE),
                                        activatedDate:
                                            defaultTerminalModem?.activatedDate &&
                                            dayjs(defaultTerminalModem?.activatedDate).format(Common.FORMAT_SHORT_DATE),
                                    });
                                },
                                centered: true,
                            });
                        } else {
                            form.setFieldValue("terminalModem", {
                                ...terminalModem,
                                equippedDate: terminalModem?.equippedDate && dayjs(terminalModem?.equippedDate).format(Common.FORMAT_SHORT_DATE),
                                activatedDate: terminalModem?.activatedDate && dayjs(terminalModem?.activatedDate).format(Common.FORMAT_SHORT_DATE),
                            });
                        }
                    }}
                    disabledBtn={!Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                />
            )}
            {(editedDevice === "doorModule" || type === "view") && Utils.isBukgiOrYaxing(car?.makerModel?.maker?.makerName) && (
                <>
                    {type === "view" && <Divider className="customDivider" />}
                    <CustomForm
                        form={form}
                        type={type}
                        initialValues={car}
                        contents={[
                            {
                                name: "도어모듈",
                                forms: doorModuleContents,
                            },
                        ]}
                        onFinish={onFinish}
                        onChangeType={(type) => {
                            setEditedDevice("도어모듈");
                            setType(type);
                            navigate(Common.PAGE_MANAGE_CAR_DEVICE_UPDATE + "/doorModule" + "/" + carId);
                        }}
                        onChangeSelectorValue={(key, value) => {
                            form.setFieldValue(key, value);
                            const doorModule = doorModules?.find((doorModule) => doorModule.serialNumber === value);
                            form.setFieldValue("doorModule", {
                                ...doorModule,
                                equippedDate: doorModule?.equippedDate && dayjs(doorModule?.equippedDate).format(Common.FORMAT_SHORT_DATE),
                            });
                        }}
                        disabledBtn={!Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                    />
                    {type === "view" && Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) && (
                        <div style={{ display: "flex", gap: 16, justifyContent: "center", marginTop: 16 }}>
                            <Button
                                className="btn-secondary"
                                style={{ minWidth: "calc((100vw - 48px) / 2)" }}
                                onClick={() => requestApiDoorModulesStatus(car?.doorModule?.doorModuleId)}
                            >
                                도어모듈 확인
                            </Button>
                            <Button className="btn-primary" style={{ minWidth: "calc((100vw - 48px) / 2)" }} onClick={() => setOpen(true)}>
                                차량 도어 작동
                            </Button>
                        </div>
                    )}
                </>
            )}
            {car?.doorModule?.doorModuleId && Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) && (
                <DoorModuleModal doorModuleId={car?.doorModule?.doorModuleId} open={open} onChangeOpen={(isOpen) => setOpen(isOpen)} />
            )}
        </>
    );
};

export default TabDevices;
