import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CopyOutlined, HomeOutlined, UserOutlined, PhoneOutlined } from "@ant-design/icons";
import { AlertType, useAlert } from "../../provider/AlertProvider";

import Input from "antd/es/input/index";
import Form from "antd/es/form/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import Button from "antd/es/button/button";
import CustomForm from "../../components/mobile/CustomForm";
import CustomCard from "../../components/mobile/CustomCard";
import moment from "moment";

import * as RequestAlarm from "../../utils/requestApiAlarm";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

const PageAlarmDetail = () => {
    const alert = useAlert();
    const param = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { Option } = Select;
    const [alarm, setAlarm] = useState<TypeDTO.AlarmDto>();

    useEffect(() => {
        requestApiAlarm(Number(param.id));
    }, []);

    useEffect(() => {
        form.setFieldsValue(alarm);
    }, [alarm]);

    const { requestApiAlarm, resultApiAlarm } = RequestAlarm.useRequestApiAlarm();

    useEffect(() => {
        if (!resultApiAlarm) return;
        setAlarm(resultApiAlarm.alarm);
    }, [resultApiAlarm]);

    const getAlarmTypeValueByKey = (key: string) => {
        if (key in Utils.alarmType) {
            return Utils.alarmType[key as keyof typeof Utils.alarmType];
        }
        return "";
    };

    const contents: Array<TypeUtils.formType> = [
        {
            name: "alarmType",
            label: String.alarmType,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="정비 분류"
                    className="disabled-selector"
                    disabled={true}
                    optionFilterProp="children"
                >
                    {Object.keys(Utils.alarmType).map((key) => (
                        <Option key={key} value={key}>
                            {getAlarmTypeValueByKey(key)}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            name: "content",
            label: "내용",
            input: <Input.TextArea placeholder="내용" autoSize={true} className="disabled-input" disabled={true} />,
        },
    ];

    const cardValue = [
        {
            icon: <HomeOutlined />,
            value: alarm?.runningHistory?.car?.makerModel?.maker?.makerName ? alarm.runningHistory.car.makerModel.maker.makerName : "-",
        },
        {
            icon: <UserOutlined />,
            value: alarm?.runningHistory?.car?.customer?.customerName ? alarm.runningHistory.car.customer.customerName : "-",
        },
        {
            icon: <PhoneOutlined />,
            value: alarm?.runningHistory?.car?.customer?.customerPhone ? alarm.runningHistory.car.customer.customerPhone : "-",
        },
    ];

    return (
        <div className="pageWrapper" style={{ paddingBottom: 64 }}>
            <Space direction="vertical" style={{ width: "100%" }}>
                <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                    알람 정보
                </p>
                <CustomCard
                    title={alarm?.runningHistory.carNumber}
                    icon={<CopyOutlined />}
                    contents={cardValue}
                    onClick={() => {
                        if (alarm?.runningHistory.carNumber) {
                            navigator?.clipboard?.writeText(alarm?.runningHistory.carNumber);
                            alert.setAlert(AlertType.SUCCESS, `복사 성공`, `차량 번호를 복사했습니다.`);
                        }
                    }}
                />
                <div style={{ display: "flex", gap: 16, justifyContent: "center" }}>
                    <Button
                        className="btn-secondary"
                        style={{ minWidth: "calc((100vw - 48px) / 2)" }}
                        onClick={() => {
                            const startTime = moment(alarm?.alarmTime).add(-5, "m").format(Common.FORMAT_DATE_TIME);
                            const endTime = moment(alarm?.alarmTime).add(5, "m").format(Common.FORMAT_DATE_TIME);
                            navigate(
                                Common.PAGE_RUNNING_DETAILS + "/" + alarm?.runningHistory?.car?.carId + "/" + startTime + "/" + endTime,
                                alarm
                            );
                        }}
                    >
                        상세 운행 이력
                    </Button>
                    <Button
                        className="btn-secondary"
                        style={{ minWidth: "calc((100vw - 48px) / 2)" }}
                        onClick={() => {
                            if (alarm?.issue?.issueId) {
                                navigate(
                                    Common.PAGE_MANAGE_MAINTENANCE_DETAIL +
                                        "/" +
                                        alarm?.runningHistory?.car?.carId +
                                        "/" +
                                        alarm?.issue?.issueId
                                );
                            } else {
                                navigate(Common.PAGE_MANAGE_MAINTENANCE_REGISTER + "/" + alarm?.runningHistory?.car?.carNumber, {
                                    state: alarm,
                                });
                            }
                        }}
                    >
                        정비 이력 {alarm?.issue?.issueId ? "수정" : "등록"}
                    </Button>
                </div>
                <CustomForm type="view" form={form} contents={[{ forms: contents }]} disabledBtn />
            </Space>
        </div>
    );
};

export default PageAlarmDetail;
