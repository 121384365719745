import { useEffect, useState } from "react";
import { mapMarker } from "../../components/common/KakaoMap";

import Skeleton from "antd/es/skeleton/index";
import RunningMap from "../common/RunningMap";

import * as TypeDTO from "../../commons/typeDTO";

const TabRunningMap = ({
    isFinish,
    loadingApiRunningGpsData,
    runningCanGpsDataList,
    chartMarker,
}: {
    isFinish?: boolean;
    loadingApiRunningGpsData?: boolean;
    runningCanGpsDataList?: Array<TypeDTO.DataGpsDto>;
    chartMarker?: mapMarker;
}) => {
    return (
        <>
            {loadingApiRunningGpsData ? (
                <Skeleton className="skeleton-block" active />
            ) : (
                <RunningMap isMobile isFinish={isFinish} runningPositions={runningCanGpsDataList} marker={chartMarker} />
            )}
        </>
    );
};

export default TabRunningMap;
