import { useEffect, useState } from "react";
import { runningHistoryMarkers, mapMarker, gpsType } from "./KakaoMap";
import { MapMarker, Polyline } from "react-kakao-maps-sdk";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";

import KakaoMap from "./KakaoMap";
import styles from "./KakaoMap.module.css";
import MarkerStart from "../../assets/images/marker/marker_start.png";
import MarkerArrive from "../../assets/images/marker/marker_arrive.png";
import MarkerDrive from "../../assets/images/marker/marker_car_drive.png";

function RunningMap({
    isMobile,
    isFinish,
    runningPositions,
    marker,
}: {
    isMobile?: boolean;
    isFinish?: boolean;
    runningPositions?: Array<TypeDTO.DataGpsDto>;
    marker?: mapMarker;
}) {
    const [runningHistoryMarkers, setRunningHistoryMarkers] = useState<runningHistoryMarkers | undefined>();
    const [selectedAddress, setSelectedAddress] = useState<string>("");
    const [mapBounds, setMapBounds] = useState<kakao.maps.LatLngBounds>(new kakao.maps.LatLngBounds());

    useEffect(() => {
        const newMarkers: Array<mapMarker> = [];
        const newPath: Array<gpsType> = [];

        if (runningPositions && runningPositions.length > 0) {
            const offset = Math.ceil(runningPositions.length / Common.CAN_DATA_MAX_GPS_COUNT);
            let newPathKey = "";

            runningPositions.forEach((canData: TypeDTO.DataGpsDto, index) => {
                const latitude = Number(canData.latitude.toFixed(5));
                const longitude = Number(canData.longitude.toFixed(5));

                if (index === 0) {
                    newMarkers.push({
                        key: canData.dataTime,
                        position: {
                            lat: latitude,
                            lng: longitude,
                        },
                        imageSrc: MarkerStart,
                    });

                    newPathKey = canData.dataTime;
                    newPath.push({
                        lat: latitude,
                        lng: longitude,
                    });
                } else if (index === runningPositions.length - 1) {
                    newMarkers.push({
                        key: canData.dataTime,
                        position: {
                            lat: latitude,
                            lng: longitude,
                        },
                        imageSrc: isFinish ? MarkerArrive : MarkerDrive,
                    });
                    newPath.push({
                        lat: latitude,
                        lng: longitude,
                    });
                } else {
                    const lastPosition = newPath[newPath.length - 1];
                    if (lastPosition.lat !== latitude || lastPosition.lng !== longitude) {
                        newPath.push({
                            lat: latitude,
                            lng: longitude,
                        });
                    }
                    // if (index % offset === 0) {
                    //     newPath.push({
                    //         lat: latitude,
                    //         lng: longitude,
                    //     });
                    // }
                }
            });

            const newPolyline = {
                key: newPathKey,
                paths: newPath,
                strokeColor: isFinish ? "var(--font2)" : "var(--point)",
                strokeWeight: 4,
                strokeOpacity: 0.7,
            };

            setRunningHistoryMarkers({
                ...runningHistoryMarkers,
                runningPath: { line: newPolyline, markers: newMarkers },
            });
        }
    }, [runningPositions]);

    useEffect(() => {
        if (!marker) return;

        const newMarkers: Array<mapMarker> = [];
        newMarkers.push(marker);

        const newRunningHistory = { ...runningHistoryMarkers };
        newRunningHistory.markers = newMarkers;

        setRunningHistoryMarkers(newRunningHistory);
    }, [marker]);

    useEffect(() => {
        if (!runningHistoryMarkers) return;

        const data = runningHistoryMarkers?.markers?.filter((item) => item.imageSrc !== MarkerStart && item.imageSrc !== MarkerArrive)[0];
        const geocoder = new kakao.maps.services.Geocoder();
        if (data?.position !== undefined) {
            const coord = new kakao.maps.LatLng(data?.position.lat, data?.position.lng);

            geocoder.coord2Address(coord.getLng(), coord.getLat(), callback);
        }
    }, [runningHistoryMarkers?.markers]);

    useEffect(() => {
        if (!runningHistoryMarkers) return;

        const bounds = new kakao.maps.LatLngBounds();

        runningHistoryMarkers?.runningPath?.line.paths.forEach((path) => {
            bounds.extend(new kakao.maps.LatLng(path.lat, path.lng));
        });

        setMapBounds(bounds);
    }, [runningHistoryMarkers?.runningPath?.line.paths]);

    const displayRunningHistory = (runningHistories: runningHistoryMarkers) => {
        if (!runningHistories) return;

        return (
            <>
                {runningHistories?.runningPath && (
                    <Polyline
                        key={runningHistories?.runningPath.line.key}
                        path={runningHistories?.runningPath.line.paths}
                        strokeWeight={runningHistories?.runningPath.line.strokeWeight}
                        strokeColor={runningHistories?.runningPath.line.strokeColor}
                        strokeOpacity={runningHistories?.runningPath.line.strokeOpacity}
                        strokeStyle="solid"
                    />
                )}
                {runningHistories?.runningPath?.markers.map((marker) => {
                    return (
                        <MapMarker
                            key={marker.key}
                            position={{
                                lat: marker.position.lat,
                                lng: marker.position.lng,
                            }}
                            clickable={true}
                            image={{
                                src: marker.imageSrc,
                                size: {
                                    width: 35,
                                    height: 43.17,
                                },
                            }}
                        />
                    );
                })}
                {runningHistories?.markers?.map((marker) => {
                    return (
                        <div key={marker.key}>
                            <MapMarker
                                key={marker.key}
                                position={{
                                    lat: marker.position.lat,
                                    lng: marker.position.lng,
                                }}
                                clickable={true}
                                image={{
                                    src: marker.imageSrc,
                                    size: {
                                        width: 24.32,
                                        height: 30,
                                    },
                                }}
                            />
                            <div className={styles.addressWrapper}>
                                <p className={styles.text}>{selectedAddress}</p>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const callback = function (result: any, status: any) {
        if (status === kakao.maps.services.Status.OK) {
            setSelectedAddress(result[0].address.address_name);
        } else {
            setSelectedAddress("주소를 확인할 수 없습니다.");
        }
    };

    return (
        <>
            {runningHistoryMarkers && (
                <KakaoMap
                    mapStyle={
                        isMobile
                            ? {
                                  width: "100vw",
                                  height: "calc(100vh - 162px)",
                              }
                            : {
                                  width: "100%",
                                  height: "50vh",
                              }
                    }
                    mapBounds={mapBounds}
                    onClick={() => console.log("click")}
                >
                    {runningHistoryMarkers && displayRunningHistory(runningHistoryMarkers)}
                </KakaoMap>
            )}
        </>
    );
}

export default RunningMap;
