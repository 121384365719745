import { useEffect, useState } from "react";
import Menu from "antd/es/menu/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../provider/AuthProvider";
import { FloatingPanel } from "antd-mobile";
import { Header } from "antd/es/layout/layout";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { actionReset } from "../../utils/action";
import type { MenuProps } from "antd/es/menu/menu";

import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import iconCarIssue from "../../assets/images/icon/icon_car_issue.png";
import iconIssueList from "../../assets/images/icon/icon_issue_list.png";
import iconBattery from "../../assets/images/icon/icon_battery.png";
import iconAlarm from "../../assets/images/icon/icon_bell.png";
import iconCheckList from "../../assets/images/icon/icon_check_list.png";
import styles from "./Selector.module.css";

type MenuItem = Required<MenuProps>["items"][number];

function MaintenaceMenu({ fromTabBar, onChangeOpen }: { fromTabBar?: boolean; onChangeOpen?: (isOpen: boolean) => void }) {
    const location = useLocation();
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [current, setCurrent] = useState<Array<string>>([]);

    const menu = {
        MaintenanceHistoryByCar: String.menu_maintenance_history_by_car,
        MaintenanceHistory: String.menu_maintenance_history,
        SOC: String.menu_soc,
        Alarm: String.menu_alarm,
        MaintenanceGuide: String.menu_maintenance_guide,
    };

    useEffect(() => {
        if (fromTabBar) return;
        const path = location.pathname;

        if (path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR) || path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/")) {
            setCurrent([menu.MaintenanceHistoryByCar]);
        } else if (path.includes(Common.PAGE_MANAGE_MAINTENANCES_HISTORY)) {
            setCurrent([menu.MaintenanceHistory]);
        } else if (path.includes(Common.PAGE_ISSUE_SOCS)) {
            setCurrent([menu.SOC]);
        } else if (path.includes(Common.PAGE_ALARM)) {
            setCurrent([menu.Alarm]);
        } else if (path.includes(Common.PAGE_MAINTENANCE_GUIDES)) {
            setCurrent([menu.MaintenanceGuide]);
        }
    }, [location]);

    const roleCheck = (permissionRole: string[]): boolean => {
        return permissionRole.includes(userDetails?.user?.authority);
    };

    const customerCheck = (customer: string[]): boolean => {
        return customer.includes(userDetails?.user?.customer?.customerName);
    };

    const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem => {
        return {
            key,
            icon,
            children,
            label,
            type,
        } as MenuItem;
    };

    const menus: MenuItem[] = [
        getItem(
            <p>{String.menu_maintenance_history_by_car}</p>,
            String.menu_maintenance_history_by_car,
            <div className="menuIconWrapper">
                <img alt="menuMaintenance" src={iconCarIssue} width={16} />
            </div>
        ),
        getItem(
            <p>{String.menu_maintenance_history}</p>,
            String.menu_maintenance_history,
            <div className="menuIconWrapper">
                <img alt="menuMaintenance" src={iconIssueList} width={16} />
            </div>
        ),
        !roleCheck([Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER, Utils.getAuthority.ROLE_USER]) &&
        !customerCheck(["RUTAS", "이엠지"])
            ? null
            : getItem(
                  <p>{String.menu_soc}</p>,
                  String.menu_soc,
                  <div className="menuIconWrapper">
                      <img alt="menuMaintenance" src={iconBattery} width={16} />
                  </div>
              ),
        getItem(
            <p>{String.menu_alarm}</p>,
            String.menu_alarm,
            <div className="menuIconWrapper">
                <img alt="menuMaintenance" src={iconAlarm} width={16} />
            </div>
        ),
        getItem(
            <p>{String.menu_maintenance_guide}</p>,
            String.menu_maintenance_guide,
            <div className="menuIconWrapper">
                <img alt="menuMaintenance" src={iconCheckList} width={16} />
            </div>
        ),
    ];

    const onSelect = ({ item, key }: { item: any; key: React.Key }) => {
        onChangeOpen && onChangeOpen(false);
        Utils.setLocalStorage(Common.CONTEXT_SHOW_MAINTENANCE_MENU, "true");
        dispatch(actionReset());

        switch (key) {
            case menu.MaintenanceHistoryByCar:
                navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY_BY_CAR);
                break;

            case menu.MaintenanceHistory:
                navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY);
                break;

            case menu.SOC:
                navigate(Common.PAGE_ISSUE_SOCS);
                break;

            case menu.Alarm:
                navigate(Common.PAGE_ALARM);
                break;

            case menu.MaintenanceGuide:
                navigate(Common.PAGE_MAINTENANCE_GUIDES);
                break;

            default:
                break;
        }
    };

    return (
        <FloatingPanel anchors={[window.innerHeight]} handleDraggingOfContent={false}>
            <div className={styles.selectorOverlay}>
                <Header className={styles.overlayHeader}>
                    {fromTabBar ? (
                        <LeftOutlined style={{ opacity: 0 }} />
                    ) : (
                        <LeftOutlined onClick={() => onChangeOpen && onChangeOpen(false)} />
                    )}
                    <h4>차량 정비</h4>
                    <LeftOutlined style={{ opacity: 0 }} />
                </Header>
                <div className={styles.overlayContent}>
                    <Menu selectedKeys={current} mode="inline" theme="light" items={menus} expandIcon={false} onClick={onSelect} />
                </div>
            </div>
        </FloatingPanel>
    );
}

export default MaintenaceMenu;
