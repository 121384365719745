//redux
export const SET_DASHBOARD = "SET_DASHBOARD";
export const SET_CARS = "SET_CARS";
export const SET_CAR_DETAIL = "SET_CAR_DETAIL";
export const SET_RUNNINGS = "SET_RUNNINGS";
export const SET_RUNNINGS_BY_CAR = "SET_RUNNINGS_BY_CAR";
export const SET_STATS_BY_CAR = "SET_STATS_BY_CAR";
export const SET_ISSUES_BY_CAR = "SET_ISSUES_BY_CAR";
export const SET_SOCS_BY_CAR = "SET_SOCS_BY_CAR";
export const SET_ISSUES = "SET_ISSUES";
export const SET_SOCS = "SET_SOCS";
export const SET_ALARMS = "SET_ALARMS";
export const SET_GROUPS = "SET_GROUPS";
export const RESET_STATE = "RESET_STATE";

export const actionSetDashboard = (value: any) => ({
    type: SET_DASHBOARD,
    payload: value,
});

export const actionSetCars = (value: any) => ({
    type: SET_CARS,
    payload: value,
});

export const actionSetCarDetail = (value: any) => ({
    type: SET_CAR_DETAIL,
    payload: value,
});

export const actionSetRunnings = (value: any) => ({
    type: SET_RUNNINGS,
    payload: value,
});

export const actionSetRunningsByCar = (value: any) => ({
    type: SET_RUNNINGS_BY_CAR,
    payload: value,
});

export const actionSetStatsByCar = (value: any) => ({
    type: SET_RUNNINGS_BY_CAR,
    payload: value,
});

export const actionSetIssuesByCar = (value: any) => ({
    type: SET_ISSUES_BY_CAR,
    payload: value,
});

export const actionSetSocsByCar = (value: any) => ({
    type: SET_SOCS_BY_CAR,
    payload: value,
});

export const actionSetIssues = (value: any) => ({
    type: SET_ISSUES,
    payload: value,
});

export const actionSetSocs = (value: any) => ({
    type: SET_SOCS,
    payload: value,
});

export const actionSetAlarms = (value: any) => ({
    type: SET_ALARMS,
    payload: value,
});

export const actionSetGroups = (value: any) => ({
    type: SET_GROUPS,
    payload: value,
});

export const actionReset = () => ({
    type: RESET_STATE,
});
