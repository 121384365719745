import { useEffect, useState } from "react";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Radio from "antd/es/radio/index";
import Card from "antd/es/card/index";
import Spin from "antd/es/spin/index";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as RequestStats from "../../utils/requestApiStats";
import * as Common from "../../commons/common";

import moment from "moment";
import CustomDatePicker from "../common/CustomDatePicker";
import produce from "immer";
import ReactApexChart from "react-apexcharts";
import styles from "./Stats.module.css";
import searchStyles from "./Search.module.css";

export type dataPeriodType = {
    startDate?: string;
    endDate?: string;
    period?: "YEAR" | "MONTH" | "DAY";
};

type dataStatsType = {
    statsTime: string;
    count: number;
};

type runningStatsType = {
    statsTime: string;
    count: number;
    drivingTime: number;
    errorCount: number;
    warningCount: number;
};

type statsType = {
    dataStats?: Array<dataStatsType>;
    runningStats?: Array<runningStatsType>;
};

function StatsDataByPeriod({
    initSearch,
    disabledSearch,
    disabledRunning,
    disabledError,
}: {
    initSearch?: dataPeriodType;
    disabledSearch?: boolean;
    disabledRunning?: boolean;
    disabledError?: boolean;
}) {
    const hour = 3600;
    const [requestStats, setRequestStats] = useState<dataPeriodType>({
        startDate: moment().add(-365, "days").format(Common.FORMAT_DATE_TIME),
        endDate: moment().format(Common.FORMAT_DATE_TIME),
        period: "MONTH",
    });
    const [stats, setStats] = useState<statsType>();
    const [series, setSeries] = useState<TypeUtils.ChartData[]>([]);
    const [option, setOption] = useState<Record<string, any> | undefined>();
    const [errorOption, setErrorOption] = useState<Record<string, any> | undefined>();
    const [errorSeries, setErrorSeries] = useState<TypeUtils.ChartData[]>([]);
    const [drivingTimeDisabled, setDrivingTimeDisabled] = useState<boolean>(false);

    useEffect(() => {
        initSearch && setRequestStats(initSearch);
    }, [initSearch]);

    const chartOptions = {
        colors: ["#9AC3FF", "var(--point)", "#002A6A", "#F63B3B", "#FAD200"],
        chart: {
            height: "350px",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    pan: false,
                    zoomin: true,
                    zoomout: true,
                },
            },
        },
        title: {
            text: "기간별 운행 통계",
            style: { fontSize: "16px", fontFamily: "Pretendard-Bold" },
        },
        xaxis: {
            type: "category",
        },
        yaxis: [
            {
                opposite: true,
                labels: {
                    formatter: function (val: number) {
                        return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
            },
            {
                // show: drivingTimeDisabled,
                labels: {
                    style: { colors: "var(--point)" },
                    formatter: function (val: number) {
                        return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
            },
            {
                labels: {
                    style: { colors: "#002A6A" },
                    formatter: function (val: number) {
                        return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val: number, { series, seriesIndex, dataPointIndex, w }: any) {
                    const unit = seriesIndex === 2 ? String.time : String.count;
                    return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + unit;
                },
            },
        },
        stroke: {
            width: 2,
        },
        legend: {
            position: "top",
            fontSize: "14px",
            horizontalAlign: "right",
            fontFamily: "Pretendard-Regular",
            fontWeight: "normal",
            showForSingleSeries: false,
        },
    };

    const errorChartOptions = {
        colors: ["var(--warning)", "var(--danger)"],
        chart: {
            height: "350px",
            stacked: true,
            width: "1000px",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    pan: false,
                    zoomin: true,
                    zoomout: true,
                },
            },
            zoom: {
                enabled: true,
                type: "x",
                autoScaleYaxis: false,
                zoomedArea: {
                    fill: {
                        color: "#90CAF9",
                        opacity: 0.4,
                    },
                    stroke: {
                        color: "#0D47A1",
                        opacity: 0.4,
                        width: 1,
                    },
                },
            },
        },
        title: {
            text: "기간별 에러 통계",
            style: {
                fontFamily: "Pretendard-Bold",
            },
        },
        xaxis: {
            type: "category",
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    colors: "var(--font2)",
                },
                labels: {
                    formatter: function (val: number) {
                        return val;
                    },
                    style: { colors: "var(--font2)" },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val: number, { series, seriesIndex, dataPointIndex, w }: any) {
                    return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + String.count;
                },
            },
        },
        dataLabels: {
            formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex];
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "20%",
            },
        },
        stroke: {
            width: 1,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            fontFamily: "Pretendard-Regular",
            fontWeight: "normal",
        },
    };

    useEffect(() => {
        setOption(chartOptions);
    }, [drivingTimeDisabled]);

    useEffect(() => {
        requestApiStatsData(requestStats);
        requestApiStatsRunning(requestStats);
    }, [requestStats]);

    useEffect(() => {
        const dataCounts: TypeUtils.ChartElement[] = [];
        const runningCounts: TypeUtils.ChartElement[] = [];
        const runningTimes: TypeUtils.ChartElement[] = [];
        const errorCounts: TypeUtils.ChartElement[] = [];
        const warningCounts: TypeUtils.ChartElement[] = [];

        let timeFormat = Common.FORMAT_DATE;
        switch (requestStats.period) {
            case "YEAR":
                timeFormat = Common.FORMAT_YEAR;
                break;
            case "MONTH":
                timeFormat = Common.FORMAT_MONTH;
                break;
            case "DAY":
                timeFormat = Common.FORMAT_DATE;
                break;
            default:
                break;
        }

        stats?.dataStats?.forEach((value) => {
            const time = moment(value.statsTime, Common.FORMAT_DATE_TIME_MILLISECOND).format(timeFormat);

            dataCounts.push({
                x: time,
                y: value.count,
            });
        });

        stats?.runningStats?.forEach((value, index) => {
            const time = moment(value.statsTime, Common.FORMAT_DATE_TIME_MILLISECOND).format(timeFormat);

            if (index === 0 && dataCounts.length > 0) {
                const valueIndex = dataCounts.findIndex((dataCount) => dataCount.x === time);

                if (valueIndex !== 0) {
                    dataCounts.forEach((element, index) => {
                        if (index >= valueIndex) return false;

                        runningCounts.push({
                            x: element.x,
                            y: 0,
                        });

                        runningTimes.push({
                            x: element.x,
                            y: 0,
                        });

                        errorCounts.push({
                            x: element.x,
                            y: 0,
                        });

                        warningCounts.push({
                            x: element.x,
                            y: 0,
                        });
                    });
                }
            }

            runningCounts.push({
                x: time,
                y: value.count,
            });

            runningTimes.push({
                x: time,
                y: Math.floor(value.drivingTime / hour),
            });

            errorCounts.push({
                x: time,
                y: value.errorCount,
            });

            warningCounts.push({
                x: time,
                y: value.warningCount,
            });
        });

        stats !== undefined &&
            setSeries([
                {
                    name: String.carDataCount,
                    type: "line",
                    data: dataCounts,
                },
                {
                    name: String.drivingCount,
                    type: "line",
                    data: runningCounts,
                },
                {
                    name: String.runningTime,
                    type: "line",
                    data: runningTimes,
                },
            ]);

        setErrorSeries([
            {
                name: String.warning,
                type: "bar",
                data: warningCounts,
            },
            {
                name: String.error,
                type: "bar",
                data: errorCounts,
            },
        ]);

        option === undefined && setOption(chartOptions);
        errorOption === undefined && setErrorOption(errorChartOptions);
    }, [stats]);

    const { loadingApiStatsData, requestApiStatsData, resultApiStatsData } = RequestStats.useRequestApiStatsData();

    useEffect(() => {
        if (!resultApiStatsData) return;

        setStats((prev) => ({
            ...prev,
            dataStats: resultApiStatsData.dataStats,
        }));
    }, [resultApiStatsData]);

    const { loadingApiStatsRunning, requestApiStatsRunning, resultApiStatsRunning } = RequestStats.useRequestApiStatsRunning();

    useEffect(() => {
        if (!resultApiStatsRunning) return;

        setStats((prev) => ({
            ...prev,
            runningStats: resultApiStatsRunning.runningStats,
        }));
    }, [resultApiStatsRunning]);

    const onChangeEvent = (type: string, value: any) => {
        setRequestStats(
            produce((draft) => {
                draft[type as keyof dataPeriodType] = value;
            })
        );
    };

    return (
        <>
            {!disabledSearch && (
                <div className={searchStyles.searchWrapper} style={{ marginBottom: 16 }}>
                    <Row className={searchStyles.searchFormWrapper}>
                        <Col className={searchStyles.searchForm} span={12}>
                            <span className="fw-bd fs-md fc-font2">{String.searchDate}</span>
                            <div className={searchStyles.searchCol}>
                                <CustomDatePicker
                                    value={requestStats.startDate}
                                    hasTime
                                    dateFormat={Common.FORMAT_SHORT_DATE_TIME}
                                    onChange={(value) => onChangeEvent("startDate", value)}
                                />
                                <span style={{ margin: "0 7px" }}>~</span>
                                <CustomDatePicker
                                    value={requestStats.endDate}
                                    hasTime
                                    dateFormat={Common.FORMAT_SHORT_DATE_TIME}
                                    onChange={(value) => onChangeEvent("endDate", value)}
                                />
                            </div>
                        </Col>
                        <Col className={searchStyles.searchForm} span={12}>
                            <span className="fw-bd fs-md fc-font2"> {String.unit}</span>
                            <div className={searchStyles.searchCol}>
                                <Radio.Group
                                    className={styles.checkbox}
                                    onChange={(e) => onChangeEvent("period", e.target.value)}
                                    defaultValue={requestStats.period}
                                >
                                    <Radio value="YEAR">{String.periodYear}</Radio>
                                    <Radio value="MONTH">{String.periodMonth}</Radio>
                                    <Radio value="DAY">{String.periodDay}</Radio>
                                </Radio.Group>
                            </div>
                        </Col>
                    </Row>
                </div>
            )}

            {!disabledRunning && option && (
                <Card className={styles.card}>
                    {loadingApiStatsData || loadingApiStatsRunning ? (
                        <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Spin />
                        </div>
                    ) : (
                        <ReactApexChart
                            style={{ backgroundColor: "white", marginBottom: "30px", minheight: "350px", overflowX: "auto", overflowY: "hidden" }}
                            options={option}
                            series={series}
                            type={option.chart.type}
                            width="100%"
                            height={option.chart.height}
                        />
                    )}
                </Card>
            )}
            {!disabledError && errorOption && (
                <Card className={styles.card}>
                    {loadingApiStatsData || loadingApiStatsRunning ? (
                        <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Spin />
                        </div>
                    ) : (
                        <ReactApexChart
                            style={{ backgroundColor: "white", minheight: "350px", overflowX: "auto", overflowY: "hidden" }}
                            options={errorOption}
                            series={errorSeries}
                            width="100%"
                            height={errorOption.chart.height}
                        />
                    )}
                </Card>
            )}
        </>
    );
}

export default StatsDataByPeriod;
