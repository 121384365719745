import { useEffect, useState } from "react";

import Button from "antd/es/button/button";
import Form from "antd/es/form/index";
import Row from "antd/es/grid/row";
import Space from "antd/es/space/index";
import Tabs from "antd/es/tabs/index";

import { useLocation, useParams } from "react-router";

import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as RequestCar from "../../utils/requestApiCar";
import * as Utils from "../../utils/utils";

import TabCarInfo from "../../components/browser/TabCarInfo";
import TabRunnings from "../../components/browser/TabRunnings";
import TabStats from "../../components/browser/TabStats";
import TabDevices from "../../components/browser/TabDevices";
import TabMaintenance from "../../components/browser/TabMaintenance";
import CustomForm from "../../components/browser/CustomForm";

const PageCarDetail = () => {
    const { navigateWithRefresh } = Utils.useNavigateWithRefresh();
    const param = useParams();
    const { pathname } = useLocation();
    const [form] = Form.useForm();
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [tabViewMode, setTabViewMode] = useState<any>({
        carInfo: pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL),
        device: pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL),
    });
    const [isView, setIsView] = useState<boolean>(pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL));

    useEffect(() => {
        form.resetFields();
        param.id && requestApiCarInfo(param.id);
    }, [param]);

    useEffect(() => {
        setIsView(tabViewMode.carInfo && tabViewMode.device);
    }, [tabViewMode]);

    const contents: Array<TypeUtils.formType> = [
        { name: "carNumber", label: String.carNumber, span: 6 },
        {
            name: ["category", "categoryName"],
            label: String.type,
            span: 6,
        },
        {
            name: ["makerModel", "maker", "makerName"],
            label: String.maker,
            span: 6,
        },
        {
            name: ["makerModel", "makerModelName"],
            label: String.model,
            span: 6,
        },
    ];

    const { requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;
        const car = resultApiCarInfo.car;

        setCar(car);
        form.setFieldsValue(car);
    }, [resultApiCarInfo]);

    const items = [
        {
            label: String.basicInfo,
            key: String.basicInfo,
            children: <TabCarInfo carId={car?.carId} onChange={(viewType) => setTabViewMode({ ...tabViewMode, carInfo: viewType })} />,
        },
        {
            label: String.menu_car_device,
            key: String.menu_car_device,
            children: <TabDevices carId={car?.carId} onChange={(viewType) => setTabViewMode({ ...tabViewMode, device: viewType })} />,
            disabled: !param.id,
        },
        {
            label: String.runningList,
            key: String.runningList,
            children: <TabRunnings carId={car?.carId} carNumber={car?.carNumber} />,
            disabled: !isView,
        },
        {
            label: String.runningStats,
            key: String.runningStats,
            children: <TabStats carId={car?.carId} />,
            disabled: !isView,
        },
        {
            label: String.menu_maintenance,
            key: String.menu_maintenance,
            children: <TabMaintenance carId={car?.carId} carNumber={car?.carNumber} makerName={car?.makerModel?.maker?.makerName} />,
            disabled: !isView,
        },
    ];

    const onChange = (tab: string) => {
        localStorage.setItem("carDetailTab", tab);
    };

    return (
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
            <div className="pageWrapper">
                <Space direction="vertical" size={8} style={{ display: "flex" }}>
                    <Row className="titleWrapper" justify={"space-between"}>
                        <h4 style={{ margin: 0 }}>차량 정보</h4>
                        <Space>
                            <Button onClick={() => navigateWithRefresh(Common.PAGE_MANAGE_CAR)}>이전</Button>
                        </Space>
                    </Row>
                    <CustomForm type="view" disabledBtn form={form} contents={[{ forms: contents }]} />
                </Space>
            </div>
            {isView ? (
                car && (
                    <Tabs
                        defaultActiveKey={!isView ? "1" : localStorage.getItem("carDetailTab") || "1"}
                        type="card"
                        items={items}
                        onChange={(tab: string) => onChange(tab)}
                    />
                )
            ) : (
                <Tabs defaultActiveKey="1" type="card" items={items} onChange={(tab: string) => onChange(tab)} />
            )}
        </Space>
    );
};

export default PageCarDetail;
