import { useEffect, useState } from "react";
import { utilAxiosFileWithAuth } from "../../utils/customAxios";
import { useLocation, useNavigate, useParams } from "react-router";
import { UploadOutlined } from "@ant-design/icons";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import CustomForm, { viewType } from "../../components/browser/CustomForm";
import { useAuthState } from "../../provider/AuthProvider";
import type { UploadFile } from "antd/es/upload/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as TypeUtils from "../../commons/typeUtils";
import * as Request from "../../commons/request";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as RequestCar from "../../utils/requestApiCar";
import * as RequestIssue from "../../utils/requestApiIssue";
import * as RequestUser from "../../utils/requestAuthUser";

import Button from "antd/es/button/button";
import DatePicker from "antd/es/date-picker/index";
import Form from "antd/es/form/index";
import Input from "antd/es/input/index";
import Radio from "antd/es/radio/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import Upload from "antd/es/upload/index";
import dayjs from "dayjs";

const PageMaintenanceDetail = () => {
    const userDetails = useAuthState();
    const params = useParams();
    const alert = useAlert();
    const location = useLocation();
    const { pathname } = useLocation();
    const { Option } = Select;
    const { navigateWithRefresh } = Utils.useNavigateWithRefresh();

    const [carForm] = Form.useForm();
    const [issueForm] = Form.useForm();
    const [type, setType] = useState<viewType>("view");
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [alarm, setAlarm] = useState<TypeDTO.AlarmDto>();
    const [users, setUsers] = useState<Array<TypeDTO.UserDto>>();
    const [initIssue, setInitIssue] = useState<TypeDTO.IssueDto>();
    const [attachFiles, setAttachFiles] = useState<Array<any>>([]);
    const [defaultFiles, setDefaultFiles] = useState<Array<any>>([]);
    const [deletedFiles, setDeletedFiles] = useState<Array<TypeDTO.IssueFileDto>>([]);

    useEffect(() => {
        if (!params) return;

        if (pathname.includes(Common.PAGE_MANAGE_MAINTENANCE_DETAIL)) {
            params.id2 && requestApiIssueInfo(params.id2);
            setType("view");
        } else if (pathname.includes(Common.PAGE_MANAGE_MAINTENANCE_REGISTER)) {
            setType("register");
        } else {
            params.id2 && requestApiIssueInfo(params.id2);
            setType("edit");
        }

        requestApiCarList();
        requestAuthUserList();
    }, [params]);

    useEffect(() => {
        location.state && setAlarm(location.state);
    }, [location]);

    useEffect(() => {
        if (alarm === undefined) return;

        issueForm.setFieldsValue({
            issueTime: dayjs(alarm?.alarmTime),
            issueType: "SW",
            issueTitle: alarm?.alarmName,
            issueContent: alarm?.content?.replace(/<[^>]*>?/g, " "),
        });
    }, [alarm]);

    const file = (files: TypeDTO.IssueFileDto[]) => {
        const data: UploadFile[] = files.map((item: TypeDTO.IssueFileDto) => ({
            uid: item.issueFileId.toString(),
            name: item.fileName,
        }));

        setDefaultFiles(data);
        setAttachFiles(data);
    };

    const getValueByKey = (key: string) => {
        if (key in Utils.actionStatus) {
            return Utils.actionStatus[key as keyof typeof Utils.actionStatus];
        }
        return "";
    };

    const getIssueValueByKey = (key: string) => {
        if (key in Utils.issueType) {
            return Utils.issueType[key as keyof typeof Utils.issueType];
        }
        return "";
    };

    const propsAttachFiles = {
        onRemove: (file: UploadFile) => {
            const index = attachFiles.indexOf(file);

            if (index !== -1) {
                setAttachFiles(attachFiles.filter((_, idx) => idx !== index));

                if (defaultFiles.some((item) => item.uid === file.uid)) {
                    setDeletedFiles([...deletedFiles, { issueFileId: Number(file.uid), fileName: file.name, deleted: true }]);
                }
            }
        },
        beforeUpload: (file: UploadFile) => {
            setAttachFiles([...attachFiles, file]);
            return false;
        },
        onPreview: async (file: UploadFile) => {
            await utilAxiosFileWithAuth()
                .get(Request.ISSUE_FILE_DOWNLOAD_URL + file.uid)
                .then((response) => {
                    const blob = new Blob([response.data], { type: "application/octet-stream" });
                    const element = document.createElement("a");
                    element.href = window.URL.createObjectURL(blob);
                    element.download = decodeURIComponent(response.headers["content-disposition"].split("filename=")[1].replaceAll('"', ""));
                    element.click();
                })
                .catch((error) => {
                    alert.setAlert(AlertType.FILE_NOT_FOUND, "파일 다운로드 실패", String.msg_download_fail);
                });
        },
        fileList: attachFiles,
    };

    const carContents: Array<TypeUtils.formType> = [
        { name: "carId", label: "", span: 0 },
        {
            name: "carNumber",
            label: String.carNumber,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="차량번호를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={(type === "register" && params.id && params.id !== "byCar") || type === "view"}
                    optionFilterProp="children"
                    onChange={(value) => carForm.setFieldsValue(cars?.find((car) => car.carId === value))}
                >
                    {cars?.map((car) => (
                        <Option key={car.carId} value={car.carId}>
                            {car.carNumber}
                        </Option>
                    ))}
                </Select>
            ),
            required: type !== "view",
        },
        { name: "idNumber", label: String.identityNumber, span: 12, disabled: true },
        { name: ["customer", "customerName"], label: String.customer, span: 6, disabled: true },
        { name: ["customer", "customerPhone"], label: String.phone, span: 6, disabled: true },
        { name: ["makerModel", "maker", "makerName"], label: String.maker, span: 6, disabled: true },
        { name: ["category", "categoryName"], label: String.category, span: 6, disabled: true },
    ];

    const issueContents: Array<TypeUtils.formType> = [
        {
            name: "issueTime",
            label: String.receiptDate,
            span: 12,
            required: type !== "view",
            input: <DatePicker className={type === "view" ? "disabled-datepicker" : ""} format={Common.FORMAT_DATE} disabled={type === "view"} />,
        },
        {
            name: "actionStatus",
            label: String.actionStatus,
            span: 12,
            input: (
                <Radio.Group defaultValue="WAITING" disabled={type === "view"}>
                    {Object.keys(Utils.actionStatus).map((key) => (
                        <Radio key={key} value={key}>
                            {getValueByKey(key)}
                        </Radio>
                    ))}
                </Radio.Group>
            ),
        },
        {
            name: "issueType",
            label: String.issueType,
            span: 12,
            input: (
                <Radio.Group defaultValue={alarm ? "SW" : "HW"} disabled={type === "view"}>
                    {Object.keys(Utils.issueType).map((key) => (
                        <Radio key={key} value={key}>
                            {getIssueValueByKey(key)}
                        </Radio>
                    ))}
                </Radio.Group>
            ),
        },
        {
            name: ["issueManager", "username"],
            label: String.chargingManager,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="담당자를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                >
                    {users?.map((user) => (
                        <Option key={user.username} value={user.username}>
                            {user.name}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            name: "issueTitle",
            label: String.title,
            span: 24,
            required: type !== "view",
        },
        {
            name: "issueContent",
            label: String.issueContent,
            span: 24,
            input: <Input.TextArea className={type === "view" ? "disabled-input" : ""} style={{ minHeight: "30vh" }} disabled={type === "view"} />,
        },
        {
            name: "issueAction",
            label: String.issueAction,
            span: 24,
            input: <Input.TextArea className={type === "view" ? "disabled-input" : ""} style={{ minHeight: "30vh" }} disabled={type === "view"} />,
        },
        {
            name: "attachment",
            label: String.attachment,
            span: 24,
            input: (
                <Upload {...propsAttachFiles} showUploadList={{ showRemoveIcon: false }} multiple={true}>
                    {type !== "view" && (
                        <Button className="btn-secondary" icon={<UploadOutlined />}>
                            {String.upload}
                        </Button>
                    )}
                </Upload>
            ),
        },
    ];

    const { requestAuthUserList, resultAuthUserList } = RequestUser.useRequestAuthUserList();

    useEffect(() => {
        if (!resultAuthUserList) return;

        setUsers(resultAuthUserList.users);
    }, [resultAuthUserList]);

    const { requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        const cars = resultApiCarList.cars;
        const car = cars.find((car: TypeDTO.CarDto) => (type === "register" ? car.carNumber === params.id : car.carId === Number(params.id1)));

        carForm.setFieldsValue({
            ...car,
            customer: {
                ...car?.customer,
                customerPhone: Utils.convertPhone(car?.customer?.customerPhone),
            },
        });

        setCar(car);
        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const { requestApiIssueInfo, resultApiIssueInfo } = RequestIssue.useRequestApiIssueInfo();

    useEffect(() => {
        if (!resultApiIssueInfo) return;

        const issue: TypeDTO.IssueDto = resultApiIssueInfo.issue;
        setInitIssue(issue);

        issueForm.setFieldsValue({
            ...issue,
            issueTime: dayjs(issue.issueTime),
            issueContent: issue?.issueContent?.replace(/<[^>]*>?/g, " "),
            issueAction: issue?.issueAction?.replace(/<[^>]*>?/g, " "),
            attachment: issue?.issueFiles?.map((file) => {
                return file.fileName?.replaceAll(/./gi, "");
            }),
        });

        file(issue.issueFiles);
    }, [resultApiIssueInfo]);

    const { requestApiIssueRegister, resultApiIssueRegister } = RequestIssue.useRequestApiIssueRegister();

    useEffect(() => {
        if (!resultApiIssueRegister) return;

        onResult("register");
    }, [resultApiIssueRegister]);

    const { requestApiIssueUpdate, resultApiIssueUpdate } = RequestIssue.useRequestApiIssueUpdate();

    useEffect(() => {
        if (!resultApiIssueUpdate) return;

        onResult("edit");
    }, [resultApiIssueUpdate]);

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.issue} ${typeText} 성공`, `${String.issue} 정보를 ${typeText}하였습니다.`);
        type === "register" &&
            (params.id
                ? navigateWithRefresh(Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/" + (car?.carNumber || params.id))
                : navigateWithRefresh(Common.PAGE_MANAGE_MAINTENANCES_HISTORY));
        type === "edit" &&
            navigateWithRefresh(
                Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/" + (car?.carNumber || cars?.find((car) => Number(params.id1) === car.carId)?.carNumber)
            );
    };

    const onFinish = (type: viewType, value: TypeDTO.CarDto) => {
        setCar(value);
        issueForm.submit();
    };

    const onFinishIssue = (type: viewType, value: TypeDTO.IssueDto) => {
        const formData = new FormData();

        const searchValue = {
            ...value,
            car: { carId: car?.carId },
            issueTime: dayjs(value?.issueTime).format(Common.FORMAT_DATE_HOUR_TIME),
            actionStatus: value?.actionStatus || "WAITING",
            issueType: value?.issueType || "HW",
        };

        if (deletedFiles) searchValue.issueFiles = deletedFiles;
        formData.append("issueDto", new Blob([JSON.stringify(searchValue)], { type: "application/json" }));

        attachFiles.forEach((file) => {
            formData.append("files", file);
        });

        type === "register" ? requestApiIssueRegister(formData) : requestApiIssueUpdate(params.id2, formData);
    };

    return (
        <div className="pageWrapper">
            <Space direction="vertical" size={8} style={{ display: "flex" }}>
                <CustomForm
                    form={carForm}
                    type={type}
                    initialValues={car}
                    contents={[{ name: "정비 이력", forms: carContents }]}
                    onChangeType={(value) => setType(value)}
                    onFinish={onFinish}
                    backBtn
                    disabledBtn={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_USER])}
                />
                <CustomForm
                    form={issueForm}
                    type={type}
                    initialValues={initIssue}
                    contents={[{ forms: issueContents }]}
                    onClick={(value) => value === "back" && issueForm.resetFields()}
                    onFinish={onFinishIssue}
                />
            </Space>
        </div>
    );
};

export default PageMaintenanceDetail;
