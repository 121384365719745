import { useEffect, useCallback, useState } from "react";

import Button from "antd/es/button/button";
import Checkbox from "antd/es/checkbox/index";
import Col from "antd/es/grid/col";
import Form from "antd/es/form/index";
import Input from "antd/es/input/index";
import Row from "antd/es/grid/row";
import Space from "antd/es/space/index";

import { useAuthDispatch } from "../../provider/AuthProvider";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as RequestLogin from "../../utils/requestAuthLogin";

import styles from "./Login.module.css";
import ezMobilityLogo from "../../assets/images/ezMobility_logo.svg";

function Login() {
    const dispatch = useAuthDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [checkSaveUsername, setCheckSaveUsername] = useState<boolean>(false);
    const [checkAutoLogin, setCheckAutoLogin] = useState<boolean>(isMobile);

    useEffect(() => {
        const savedUsername = Utils.getLocalStorage(Common.CONTEXT_SAVED_ID);
        if (savedUsername && savedUsername.length > 0) {
            setUsername(savedUsername);
            setCheckSaveUsername(true);
            form.setFieldValue("username", savedUsername);
        } else {
            setCheckSaveUsername(false);
        }
    }, []);

    const { requestAuthLogin, resultAuthLogin } = RequestLogin.useRequestAuthLogin();

    useEffect(() => {
        if (!resultAuthLogin) return;

        if (dispatch !== null)
            dispatch({
                type: "LOGIN_SUCCESS",
                payload: {
                    user: resultAuthLogin.user,
                    token: resultAuthLogin.token,
                },
            });

        const nextPath = Utils.getLocalStorage(Common.CONTEXT_NEXT_PATH);

        if (resultAuthLogin.result === "ok") {
            Utils.sendFCMToken({
                user: resultAuthLogin.user,
                token: resultAuthLogin.token,
            });

            if (checkAutoLogin) {
                Utils.setLocalStorage(Common.CONTEXT_SAVED_ID, username);
                Utils.setLocalStorage(Common.CONTEXT_SAVED_PW, password);
            } else {
                if (checkSaveUsername) {
                    Utils.setLocalStorage(Common.CONTEXT_SAVED_ID, resultAuthLogin?.user?.username);
                } else {
                    Utils.setLocalStorage(Common.CONTEXT_SAVED_ID, "");
                }
                Utils.setLocalStorage(Common.CONTEXT_SAVED_PW, "");
            }

            if (nextPath && nextPath?.length > 0) {
                Utils.setLocalStorage(Common.CONTEXT_NEXT_PATH, "");
                navigate(nextPath);
            } else {
                navigate(Common.PAGE_DASHBOARD);
            }
        }
    }, [resultAuthLogin]);

    const validateUser = useCallback((_: any, value: string) => {
        const user_regExp = /^[a-zA-Z0-9._@]*$/i;
        if (!value) {
            return Promise.reject(new Error(String.msgIdMust));
        }
        if (!user_regExp.test(value)) {
            return Promise.reject(new Error(String.msgIdRule));
        }
        return Promise.resolve();
    }, []);

    const validatePwd = useCallback((_: any, value: string) => {
        const pwd_regExp = /^[a-zA-Z0-9#?!@$%^&*-]*$/i;
        if (!value) {
            return Promise.reject(new Error(String.msgPwdMust));
        }
        if (!pwd_regExp.test(value)) {
            return Promise.reject(new Error(String.msgPwdRule));
        }
        return Promise.resolve();
    }, []);

    const onFinish = async () => {
        if (username && password) {
            await requestAuthLogin({ username: username, password: password });
        }
    };

    return (
        <>
            <Row className={`loginPage ${styles.loginWrapper}`} align="middle" justify="space-between">
                <Col className={styles.left} xs={24} md={12}>
                    <Form className={styles.loginCard} form={form} onFinish={onFinish} onKeyDown={(e) => e.key === "Enter" && form.submit()}>
                        <h2 className="fc-point" style={{ margin: 0 }}>
                            {String.login}
                        </h2>
                        <Form.Item name="username" rules={[{ validator: validateUser }]}>
                            <div>
                                <span className={`fw-bd fs-md fc-font2`}>{String.id}</span>
                                <Input
                                    name="username"
                                    className={`fw-rg fs-md fc-font2 ${styles.loginInput}`}
                                    value={username}
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="아이디를 입력해 주세요."
                                    maxLength={30}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                        </Form.Item>
                        <Form.Item name="password" rules={[{ validator: validatePwd }]}>
                            <div>
                                <span className={`fw-bd fs-md fc-font2`}>{String.password}</span>
                                <Input.Password
                                    name="password"
                                    className={`fw-rg fs-md fc-font2 ${styles.loginInput}`}
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    placeholder="비밀번호를 입력해 주세요."
                                    maxLength={20}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                        </Form.Item>
                        <Space>
                            <Form.Item>
                                <Checkbox
                                    className={`fw-rg fs-sm ${styles.loginCheckbox}`}
                                    checked={checkSaveUsername}
                                    onChange={(e) => {
                                        setCheckSaveUsername(e.target.checked);
                                    }}
                                >
                                    {String.saveId}
                                </Checkbox>
                            </Form.Item>
                            {isMobile && (
                                <Form.Item>
                                    <Checkbox
                                        className={`fw-rg fs-sm ${styles.loginCheckbox}`}
                                        checked={checkAutoLogin}
                                        onChange={(e) => {
                                            setCheckAutoLogin(e.target.checked);
                                        }}
                                    >
                                        자동 로그인
                                    </Checkbox>
                                </Form.Item>
                            )}
                        </Space>
                        <Form.Item>
                            <Button
                                className={`btn-primary ${styles.loginBtn}`}
                                block
                                disabled={username && password ? false : true}
                                type="primary"
                                size="large"
                                onClick={() => form.submit()}
                            >
                                {String.login}
                            </Button>
                        </Form.Item>
                    </Form>
                    <span className={styles.copyright}>Copyright© EMG Inc.</span>
                </Col>
                <Col xs={0} md={12} className={styles.right}>
                    <img alt="" className={styles.logo} src={ezMobilityLogo} width={"40%"} />
                </Col>
            </Row>
        </>
    );
}

export default Login;
