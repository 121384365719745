import React from "react";
import Row from "antd/es/grid/row";
import type { ColumnsType } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";

import DataTable from "../common/DataTable";

function MaintenanceGuide({ maintenanceDetails }: { maintenanceDetails: Array<TypeDTO.MaintenanceDetailsDto> }) {
    const columns: ColumnsType<TypeDTO.MaintenanceDetailsDto> = [
        {
            title: "작업목록",
            dataIndex: ["maintenanceItem", "workItem"],
            key: "maintenanceName",
            align: "center",
            width: "20%",
            onCell: (row) => {
                const sameMaintenanceItems = maintenanceDetails.filter(
                    (detail) => detail.maintenanceItem.maintenanceItemId === row.maintenanceItem.maintenanceItemId
                );

                if (sameMaintenanceItems.length === 1) {
                    return {};
                } else {
                    if (sameMaintenanceItems[0].maintenanceDetailsId === row.maintenanceDetailsId) {
                        return { rowSpan: sameMaintenanceItems.length, colSpan: 1 };
                    } else {
                        return { rowSpan: 0, colSpan: 0 };
                    }
                }
            },
        },
        {
            title: "작업내용",
            dataIndex: "workDetails",
            key: "workItem",
            align: "left",
            width: "30%",
            render: (_, { workDetails }) =>
                workDetails
                    ? workDetails.split(/\\r\\n|\\n|\\r/gm).map((line, index) => (
                          <li
                              style={{ whiteSpace: "pre-wrap", listStyle: "none" }}
                              key={index}
                              dangerouslySetInnerHTML={{
                                  __html: line.replaceAll(/\\r\\n|\\n|\\r/gm, ""),
                              }}
                          />
                      ))
                    : "-",
            // onCell: (row) => {
            //     const sameWorkDetails = maintenanceDetails.filter((detail) => detail.workDetails === row.workDetails);
            //     if (sameWorkDetails.length === 1) {
            //         return {};
            //     } else {
            //         if (sameWorkDetails[0].maintenanceDetailsId === row.maintenanceDetailsId) {
            //             return { rowSpan: sameWorkDetails.length };
            //         } else {
            //             return { rowSpan: 0 };
            //         }
            //     }
            // },
        },
        {
            title: "점검내용",
            dataIndex: "checklist",
            key: "checklist",
            align: "left",
            width: "50%",
            render: (_, { checklist }) =>
                checklist
                    ? checklist.split(/\\r\\n|\\n|\\r/gm).map((line, index) => (
                          <li
                              style={{ whiteSpace: "pre-wrap", listStyle: "none" }}
                              key={index}
                              dangerouslySetInnerHTML={{
                                  __html: line.replaceAll(/\\r\\n|\\n|\\r/gm, ""),
                              }}
                          />
                      ))
                    : "-",
        },
    ];

    return (
        <div className="pageWrapper">
            <Row className="titleWrapper" justify="space-between">
                <h4 style={{ margin: 0 }}>{maintenanceDetails[0].maintenanceItem.maintenanceGuide.maintenanceName}</h4>
            </Row>
            <DataTable
                rowKey={(row: TypeDTO.MaintenanceDetailsDto) => row.maintenanceDetailsId}
                disabledTitle
                columns={columns}
                dataSource={maintenanceDetails}
                disabledPagination={true}
            />
        </div>
    );
}

export default MaintenanceGuide;
