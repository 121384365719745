import { useEffect, useState } from "react";
import { useAuthState } from "../../provider/AuthProvider";
import { Popup, CheckList, SearchBar } from "antd-mobile";
import { CloseCircleFilled, DownOutlined } from "@ant-design/icons";
import { Store } from "antd/es/form/interface";

import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as RequestUser from "../../utils/requestAuthUser";
import * as RequestCar from "../../utils/requestApiCar";
import * as RequestCategory from "../../utils/requestApiCategory";
import * as RequestMaker from "../../utils/requestApiMaker";
import * as RequestCustomerManager from "../../utils/requestAuthCustomerManager";
import * as RequestCustomer from "../../utils/requestAuthCustomer";

import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import Empty from "antd/es/empty/index";
import IconCheck from "../../assets/images/icon/icon_check_circle.png";
import styles from "./Filter.module.css";

const CustomSelector = ({ selectorType, onFinish }: { selectorType: any; onFinish: (value: any) => void }) => {
    const userDetails = useAuthState();
    const [data, setData] = useState<any>();
    const [label, setLabel] = useState<any>();
    const [users, setUsers] = useState<Array<TypeDTO.UserDto>>();
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [categories, setCategories] = useState<Array<TypeDTO.CategoryDto>>();
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>();
    const [customers, setCustomers] = useState<Array<TypeDTO.CustomerDto>>();
    const [customerManagers, setCustomerManagers] = useState<Array<TypeDTO.CustomerManagerDto>>();

    const [visible, setVisible] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>();

    useEffect(() => {
        if (!visible) {
            setSearchText(undefined);
        }
    }, [visible]);

    useEffect(() => {
        Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]);

        const requests = [
            { typeDetail: "user", action: requestAuthUserList, isCheck: true },
            { typeDetail: "name", action: requestAuthUserList, isCheck: true },
            { typeDetail: "car", action: requestApiCarList, isCheck: true },
            { typeDetail: "idNumber", action: requestApiCarList, isCheck: true },
            { typeDetail: "category", action: requestApiCategoryList, isCheck: true },
            { typeDetail: "maker", action: requestApiMakerList, isCheck: true },
            { typeDetail: "model", action: requestApiMakerList, isCheck: true },
            {
                typeDetail: "customerManager",
                action: requestAuthCustomerManagerList,
                isCheck: Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]),
            },
            {
                typeDetail: "customer",
                action: requestAuthCustomerList,
                isCheck: Utils.roleCheck(userDetails?.user?.authority, [
                    Utils.getAuthority.ROLE_ADMIN,
                    Utils.getAuthority.ROLE_MANAGER,
                    Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                ]),
            },
        ];

        const request = requests.find((req) => req.typeDetail === selectorType?.typeDetail && req.isCheck);
        if (request) request.action();
    }, []);

    useEffect(() => {
        onFinish(data);
    }, [data]);

    const getType = (
        title: string,
        typeDetail: string,
        options?: any,
        required?: boolean,
        placeholder?: string,
        disabled?: boolean,
        className?: string,
        defaultValue?: string,
        onChange?: (value: any) => void
    ) => {
        const selectOptions = searchText
            ? (options || getSelect(typeDetail)).filter((option: { value: string; label: string }) => option.label.includes(searchText))
            : options || getSelect(typeDetail);

        return (
            <>
                <Select
                    className={className ? className : data ? `allowClearSelect ${styles.searchSelect}` : `${styles.searchSelect}`}
                    value={label || defaultValue || undefined}
                    defaultValue={defaultValue || undefined}
                    placeholder={placeholder ? placeholder : "-"}
                    onDropdownVisibleChange={(visible) => visible && setVisible(true)}
                    onClear={() => {
                        onChange && onChange(undefined);
                        setData(undefined);
                        setLabel(undefined);
                    }}
                    removeIcon={
                        <CloseCircleFilled
                            onClick={(e) => {
                                e.stopPropagation();
                                onChange && onChange(undefined);
                                setData(undefined);
                                setLabel(undefined);
                            }}
                        />
                    }
                    allowClear={required === true ? false : true}
                    dropdownStyle={{ display: "none" }}
                    disabled={
                        disabled
                            ? true
                            : (typeDetail === "customerManager" &&
                                  !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER])) ||
                              (typeDetail === "customer" &&
                                  !Utils.roleCheck(userDetails?.user?.authority, [
                                      Utils.getAuthority.ROLE_ADMIN,
                                      Utils.getAuthority.ROLE_MANAGER,
                                      Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                  ]))
                    }
                />
                <Popup
                    className={styles.selectorPopup}
                    visible={visible}
                    onMaskClick={() => {
                        setVisible(false);
                    }}
                    destroyOnClose
                    maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
                >
                    <div className={styles.selectorContainer}>
                        <Space align="center">
                            <span className="fw-bd fs-lg">{title}</span>
                            <span className="fw-rg fs-sm fc-font2">총 {selectOptions?.length}건</span>
                        </Space>
                        {((options && options) || getSelect(typeDetail))?.length > 10 && (
                            <SearchBar
                                className={styles.selectorSearch}
                                placeholder="검색어를 입력해 주세요."
                                value={searchText}
                                onChange={(value) => {
                                    setSearchText(value);
                                }}
                            />
                        )}
                        <CheckList
                            className={styles.myCheckList}
                            onChange={(value) => {
                                onChange && onChange(value[0]);
                                setData(value[0]);
                                setVisible(false);
                                selectOptions?.map((option: { value: string; label: string }) => {
                                    if (option.value === value[0]) {
                                        return setLabel(option.label);
                                    }
                                });
                            }}
                        >
                            {selectOptions?.length > 0 ? (
                                selectOptions?.map((option: { value: string; label: string }) => (
                                    <CheckList.Item key={option.value} value={option.value}>
                                        {option.label}
                                        {data === option.value && <img src={IconCheck} width={20} height={20} />}
                                    </CheckList.Item>
                                ))
                            ) : (
                                <Empty />
                            )}
                        </CheckList>
                    </div>
                </Popup>
            </>
        );
    };

    const getValueByKey = (key: string) => {
        if (key in Utils.issueType) {
            return Utils.issueType[key as keyof typeof Utils.issueType];
        }
        if (key in Utils.actionStatus) {
            return Utils.actionStatus[key as keyof typeof Utils.actionStatus];
        }
        return "";
    };

    const getSelect = (value: string) => {
        switch (value) {
            case "user":
                return users?.map((user) => ({ value: user.username, label: user.name }));
            case "name":
                return users?.map((user) => ({ value: user.name, label: user.name }));
            case "car":
                return cars?.map((car) => ({ value: car.carNumber, label: car.carNumber }));
            case "idNumber":
                return cars?.map((car) => ({ value: car.idNumber, label: car.idNumber }));
            case "maker":
                return makers?.map((maker) => ({ value: maker.makerId, label: maker.makerName }));
            case "model":
                return makers
                    ?.flatMap((maker) => maker.makerModels)
                    ?.map((model) => ({
                        value: model.makerModelId,
                        label: model.makerModelName,
                    }));
            case "category":
                return categories?.map((category) => ({ value: category.categoryId, label: category.categoryName }));
            case "customerManager":
                return customerManagers?.map((customerManager) => ({
                    value: customerManager.customerManagerName,
                    label: customerManager.customerManagerName,
                }));
            case "customer":
                return customers?.map((customer) => ({ value: customer.customerId, label: customer.customerName }));
            case "issueType":
                return Object.keys(Utils.issueType).map((key) => ({ value: key, label: getValueByKey(key) }));
            case "actionStatus":
                return Object.keys(Utils.actionStatus).map((key) => ({ value: key, label: getValueByKey(key) }));
            default:
                return undefined;
        }
    };

    const { requestAuthUserList, resultAuthUserList } = RequestUser.useRequestAuthUserList();

    useEffect(() => {
        if (!resultAuthUserList) return;

        setUsers(resultAuthUserList.users);
    }, [resultAuthUserList]);

    const { requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const { requestApiCategoryList, resultApiCategoryList } = RequestCategory.useRequestApiCategoryList();

    useEffect(() => {
        if (!resultApiCategoryList) return;

        setCategories(resultApiCategoryList.categories);
    }, [resultApiCategoryList]);

    const { requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    const { requestAuthCustomerList, resultAuthCustomerList } = RequestCustomer.useRequestAuthCustomerList();

    useEffect(() => {
        if (!resultAuthCustomerList) return;

        setCustomers(resultAuthCustomerList.customers);
    }, [resultAuthCustomerList]);

    const { requestAuthCustomerManagerList, resultAuthCustomerManagerList } = RequestCustomerManager.useRequestAuthCustomerManagerList();

    useEffect(() => {
        if (!resultAuthCustomerManagerList) return;

        setCustomerManagers(resultAuthCustomerManagerList.customerManagers);
    }, [resultAuthCustomerManagerList]);

    return (
        <>
            {getType(
                selectorType?.title,
                selectorType?.typeDetail,
                selectorType?.options,
                selectorType?.required,
                selectorType?.placeholder,
                selectorType?.disabled,
                selectorType?.className,
                selectorType?.defaultValue,
                selectorType?.onChange
            )}
        </>
    );
};

export default CustomSelector;
