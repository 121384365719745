import { useState, useEffect } from "react";
import Checkbox from "antd/es/checkbox/index";
import Space from "antd/es/space/index";

import styles from "../../pages/browser/Dashboard.module.css";

import iconDriving from "../../assets/images/icon/icon_driving_dashboard.png";
import iconEnd from "../../assets/images/icon/icon_end_dashboard.png";

import iconError from "../../assets/images/icon/icon_error_dashboard.png";
import iconWarning from "../../assets/images/icon/icon_warning_dashboard.png";
import iconNormal from "../../assets/images/icon/icon_normal_dashboard.png";

import iconEmergency from "../../assets/images/icon/icon_emergency_dashboard.png";
import iconEncourage from "../../assets/images/icon/icon_encourage_dashboard.png";
import iconBatteryNormal from "../../assets/images/icon/icon_batteryNormal_dashboard.png";

const DashboardFilter = ({ filters, onChange }: { filters: string[]; onChange: (filters: string[]) => void }) => {
    const [selected, setSelected] = useState<string[]>(filters);

    useEffect(() => {
        setSelected(filters);
    }, [filters]);

    const dashboardFilters = [
        {
            label: "운행",
            value: "driving",
            icon: iconDriving,
        },
        {
            label: "종료",
            value: "end",
            icon: iconEnd,
            isEnd: true,
        },
        {
            label: "에러",
            value: "error",
            icon: iconError,
        },
        {
            label: "경고",
            value: "warning",
            icon: iconWarning,
        },
        {
            label: "정상",
            value: "normal",
            icon: iconNormal,
            isEnd: true,
        },
        {
            label: "긴급",
            value: "emergency",
            icon: iconEmergency,
        },
        {
            label: "권장",
            value: "encourage",
            icon: iconEncourage,
        },
        {
            label: "정상",
            value: "batteryNormal",
            icon: iconBatteryNormal,
        },
    ];

    const onSelect = (value: string, isChecked: boolean) => {
        const values = isChecked ? [...selected, value] : selected.filter((item) => item !== value);

        setSelected(values);
        onChange(values);
    };

    return (
        <div className={styles.filterOverlay}>
            <Space className={styles.filterWrapper} direction="vertical" size={0}>
                {dashboardFilters.map((filter, index) => (
                    <Space key={index} className={styles.dividerWrapper} direction="vertical" size={0}>
                        <Checkbox
                            className={filters?.includes(filter.value) ? `${styles.checkedFilter} ${styles.filter}` : `${styles.filter}`}
                            checked={filters?.includes(filter.value) ? true : false}
                            onChange={(e) => onSelect(filter.value, e.target.checked)}
                        >
                            <img alt="" src={filter.icon} width={24} height={24} />
                            {filter.label}
                        </Checkbox>
                        {filter.isEnd && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="2" viewBox="0 0 66 2" fill="none" style={{ margin: "8px 0" }}>
                                <path d="M0 1H66" stroke="url(#paint0_linear_4315_122634)" />
                                <defs>
                                    <linearGradient id="paint0_linear_4315_122634" x1="0" y1="1" x2="65.3633" y2="1" gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#E0E1E2" stopOpacity="0" />
                                        <stop offset="0.5" stopColor="#E0E1E2" />
                                        <stop offset="1" stopColor="#E0E1E2" stopOpacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        )}
                    </Space>
                ))}
            </Space>
        </div>
    );
};

export default DashboardFilter;
