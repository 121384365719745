import { useState, useEffect } from "react";
import { isBrowser } from "react-device-detect";
import { isMobile } from "react-device-detect";

import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";

import ReactApexChart from "react-apexcharts";
import moment from "moment";

function CanDataChart({
    series,
    option,
    onClickChart,
    onMountedChart,
}: {
    series: TypeUtils.ChartData[];
    option: Record<string, any>;
    onClickChart?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [chartOption, setChartOption] = useState<Record<string, any> | undefined>();

    useEffect(() => {
        if (!option) return;

        let zoomed = false;

        const chart_option = {
            ...option,
            chart: {
                ...option.chart,
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                        selection: false,
                        pan: false,
                        zoomin: !isBrowser,
                        zoomout: !isBrowser,
                    },
                },
                zoom: {
                    enabled: true,
                    type: "x",
                },
                animations: {
                    enabled: false,
                    easing: "linear",
                    dynamicAnimation: {
                        speed: 500,
                    },
                },
                events: {
                    zoomed: function (chartContext: any, { xaxis, yaxis }: any) {
                        zoomed = true;
                    },
                    click: function (event: any, chartContext: any, config: any) {
                        if (zoomed) {
                            zoomed = false;
                            return;
                        }
                        if (config.seriesIndex === -1 || config.dataPointIndex === -1) return;
                        if (!isMobile && onClickChart !== undefined) onClickChart(config.dataPointIndex);
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            title: {
                align: "center",
            },
            xaxis: {
                type: "number",
                tickAmount: 30,
                labels: {
                    formatter: function (val: number, index: number) {
                        return moment(val).format(Common.FORMAT_TIME);
                    },
                },
                tooltip: {
                    formatter: function (val: number, index: number) {
                        return moment(val).format(Common.FORMAT_DATE_TIME);
                    },
                },
                ...option.xaxis,
            },
            yaxis: {
                labels: {
                    formatter: function (val: number, index: number) {
                        return val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
                ...option.yaxis,
            },
            tooltip: {
                ...option.tooltip,
                enable: true,
                shared: true,
                marker: {
                    show: false,
                },
                x: {
                    show: false,
                },
            },
            legend: {
                showForSingleSeries: true,
                fontFamily: "Pretendard-Regular",
                fontSize: "14px",
            },
            noData: {
                text: "No Data",
                align: "center",
                verticalAlign: "middle",
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#8b0000",
                    fontSize: "32px",
                    fontFamily: "Pretendard-Bold",
                },
            },
            plotOptions: {
                line: {
                    curve: "smooth",
                },
                bar: {
                    horizontal: false,
                    columnWidth: "10%",
                },
            },
            stroke: {
                width: 2,
            },
            colors: ["#0A84FF", "#5E5CE6", "#BF5AF2", "#FF2D55", "#FF453A", "#FF9F0A", "#FFD60A", "#32D74B", "#64D2FF"],

            axisBorder: {
                show: true,
                color: "#a9abb3",
                height: 1,
                width: "100%",
            },
            markers: {
                size: 0,
            },
        };

        setChartOption(chart_option);
    }, [option]);

    return (
        <>
            {chartOption && (
                <ReactApexChart
                    style={{ backgroundColor: "white", overflowX: "auto", overflowY: "hidden", position: "relative" }}
                    options={chartOption}
                    series={series}
                    type={chartOption.chart.type}
                    width={isBrowser ? "100%" : "2500px"}
                    height={chartOption.chart.height}
                />
            )}
        </>
    );
}

export default CanDataChart;
