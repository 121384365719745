import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestAuthMemberInfo = () => {
    const requestAxiosAuthMemberInfo = async (username: string) => {
        const response = await utilAxiosWithAuth().get(Request.MEMBER_URL + username);

        return response.data;
    };

    const {
        loading: loadingAuthMemberInfo,
        error: errorAuthMemberInfo,
        data: resultAuthMemberInfo,
        execute: requestAuthMemberInfo,
    } = useAsyncAxios(requestAxiosAuthMemberInfo);

    useEffect(() => {
        if (resultAuthMemberInfo === null) return;
        DEBUG && console.log("resultAuthMemberInfo", resultAuthMemberInfo);
    }, [resultAuthMemberInfo]);

    useEffect(() => {
        if (errorAuthMemberInfo === null) return;
        DEBUG && console.log("errorAuthMemberInfo", errorAuthMemberInfo);
    }, [errorAuthMemberInfo]);

    return {
        loadingAuthMemberInfo,
        errorAuthMemberInfo,
        resultAuthMemberInfo,
        requestAuthMemberInfo,
    };
};

export const useRequestAuthMemberUpdate = () => {
    const requestAxiosAuthMemberUpdate = async (username: string, data: TypeDTO.UserDto) => {
        const response = await utilAxiosWithAuth().put(Request.MEMBER_URL + username, data);

        return response.data;
    };

    const {
        loading: loadingAuthMemberUpdate,
        error: errorAuthMemberUpdate,
        data: resultAuthMemberUpdate,
        execute: requestAuthMemberUpdate,
    } = useAsyncAxios(requestAxiosAuthMemberUpdate);

    useEffect(() => {
        if (resultAuthMemberUpdate === null) return;
        DEBUG && console.log("resultAuthMemberUpdate", resultAuthMemberUpdate);
    }, [resultAuthMemberUpdate]);

    useEffect(() => {
        if (errorAuthMemberUpdate === null) return;
        DEBUG && console.log("errorAuthMemberUpdate", errorAuthMemberUpdate);
    }, [errorAuthMemberUpdate]);

    return {
        loadingAuthMemberUpdate,
        errorAuthMemberUpdate,
        resultAuthMemberUpdate,
        requestAuthMemberUpdate,
    };
};
