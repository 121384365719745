import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { actionSetIssuesByCar } from "../../utils/action";
import { type TableProps } from "antd/es/table/InternalTable";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as RequestIssue from "../../utils/requestApiIssue";
import * as RequestUser from "../../utils/requestAuthUser";
import * as Utils from "../../utils/utils";

import DataTable from "../common/DataTable";
import Search from "./Search";
import Space from "antd/es/space/index";
import Modal from "antd/es/modal/index";
import moment from "moment";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";
import IconRedExclamtion from "../../assets/images/icon/icon_red_exclamation.png";
import IconInAction from "../../assets/images/icon/icon_in_action.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

export type SearchType = {
    carNumber: string;
    customerName: string;
    issueManager: string;
    checkbox: string[];
    searchValue: string;
    startDate: string;
    endDate: string;
};

const Maintenances = ({ carNumber, hasSummary }: { carNumber?: string; hasSummary?: boolean }) => {
    const isLaptop = useMediaQuery({ maxWidth: 2100 });
    const alert = useAlert();
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerIssuesByCar = useSelector((state) => state.issuesByCar);
    const { pathname } = useLocation();
    const { navigateWithRefresh } = Utils.useNavigateWithRefresh();

    const [users, setUsers] = useState<Array<TypeDTO.UserDto>>();
    const [issues, setIssues] = useState<Array<TypeDTO.IssueDto>>([]);
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.IssueDto>>({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<SearchType>();
    const [loading, setLoading] = useState(false);
    const [tempIssues, setTempIssues] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestIssuesPage, setRequestIssuesPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(20);
    const [waitingCount, setWaitingCount] = useState<number>();
    const [inActionCount, setInActionCount] = useState<number>();

    const getIssueValueByKey = (key: string) => {
        if (key in Utils.issueType) {
            return Utils.issueType[key as keyof typeof Utils.issueType];
        }
        return "";
    };

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
        {
            id: "customerName",
            span: 12,
            title: String.customer,
            type: "select",
            typeDetail: "customer",
        },
        {
            id: "checkbox",
            span: isLaptop ? 12 : 6,
            title: String.actionStatus,
            type: "checkbox",
            options: [
                { value: "waiting", label: String.waiting },
                { value: "inAction", label: String.action },
                { value: "completed", label: String.completion },
            ],
            defaultValue: ["waiting", "inAction", "completed"],
        },
        {
            id: "issueType",
            span: isLaptop ? 12 : 6,
            title: String.issueType,
            type: "select",
            options: Object.keys(Utils.issueType).map((key) => ({ value: key, label: getIssueValueByKey(key) })),
        },
        {
            id: "searchValue",
            span: carNumber ? 12 : isLaptop ? 24 : 12,
            title: String.title + " / 내용",
            type: "input",
            typeDetail: "issueTitle",
        },
        {
            id: "issueManagerName",
            span: carNumber ? (isLaptop ? 12 : 6) : 12,
            title: String.chargingManager,
            type: "select",
            typeDetail: "name",
        },
        {
            id: "searchDate",
            span: carNumber ? (isLaptop ? 12 : 6) : 12,
            title: String.receiptDate,
            type: "rangePicker",
            dateFormat: Common.FORMAT_DATE_TIME,
        },
    ];
    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.IssueDto> = [
        {
            title: String.actionStatus,
            dataIndex: "actionStatus",
            key: "actionStatus",
            align: "center",
            width: "8%",
            render: (_, { actionStatus }) => {
                return actionStatus === "WAITING" ? (
                    <img alt="icon" src={IconRedExclamtion} width={20} height={20} />
                ) : actionStatus === "COMPLETED" ? (
                    <img alt="icon" src={IconCheck} width={20} height={20} />
                ) : actionStatus === "IN_ACTION" ? (
                    <img alt="icon" src={IconInAction} width={20} height={20} />
                ) : (
                    String.dash
                );
            },
        },
        {
            title: String.carNumber,
            dataIndex: ["car", "carNumber"],
            key: "carNumber",
            align: "center",
            width: "10%",
        },
        {
            title: String.title,
            dataIndex: "issueTitle",
            key: "issueTitle",
            align: "center",
            width: "15%",
        },
        {
            title: String.issueType,
            dataIndex: "issueType",
            key: "issueType",
            align: "center",
            width: "10%",
            render: (_, { issueType }) => Utils.issueType[issueType] || String.dash,
        },
        {
            title: String.chargingManager,
            dataIndex: ["issueManager", "username"],
            key: "issueManager",
            align: "center",
            width: "10%",
            render: (_, { issueManager }) => issueManager?.name || String.dash,
        },
        {
            title: String.receiptDate,
            dataIndex: "issueTime",
            key: "issueTime",
            align: "center",
            width: "15%",
        },
        {
            title: String.manage,
            key: "detail",
            align: "center",
            width: "10%",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(Common.PAGE_MANAGE_MAINTENANCE_UPDATE + "/" + row.car.carId + "/" + row.issueId);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.issueId, row.issueTitle);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    useEffect(() => {
        requestAuthUserList();
    }, []);

    useEffect(() => {
        if (Object.keys(reducerIssuesByCar).length > 0) {
            setCurrentPage(reducerIssuesByCar?.currentPage);

            setSearch(
                searchData?.map((item) => {
                    const { id, defaultStartDate, defaultEndDate, defaultValue } = item;
                    const isSearchDate = id === "searchDate";
                    const isCheckbox = id === "checkbox";

                    if (isSearchDate) {
                        return {
                            ...item,
                            defaultStartDate: reducerIssuesByCar["startDate"] ?? defaultStartDate,
                            defaultEndDate: reducerIssuesByCar["endDate"] ?? defaultEndDate,
                        };
                    } else if (isCheckbox) {
                        const { waiting = true, inAction = true, completed = true } = reducerIssuesByCar;

                        return {
                            ...item,
                            defaultValue: Object.entries({ waiting, inAction, completed })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else {
                        return {
                            ...item,
                            defaultValue: reducerIssuesByCar[id] ?? defaultValue,
                        };
                    }
                })
            );
        } else {
            setSearch(searchData);
        }
    }, [reducerIssuesByCar]);

    const dispatchIssuesByCar = (selectedId: number) => {
        dispatch(
            actionSetIssuesByCar({
                ...searchValue,
                currentPage: currentPage,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
                pageSize: pageSize,
            })
        );
    };

    const onTableChange: TableProps<TypeDTO.IssueDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.IssueDto>);
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const onSearchData = (value: any) => {
        const { checkbox, ...searchValue } = value;

        searchValue.carNumber = carNumber || value?.carNumber;
        searchValue.starDate = moment(value?.startDate).format(Common.FORMAT_DATE_HOUR_TIME_START);
        searchValue.endDate = moment(value?.endDate).format(Common.FORMAT_DATE_HOUR_TIME_END);
        searchValue.waiting = value?.checkbox?.includes("waiting");
        searchValue.inAction = value?.checkbox?.includes("inAction");
        searchValue.completed = value?.checkbox?.includes("completed");

        setSearchValue(searchValue);
        setLoading(true);
        requestApiIssueList(searchValue, requestIssuesPage);
    };

    const { requestAuthUserList, resultAuthUserList } = RequestUser.useRequestAuthUserList();

    useEffect(() => {
        if (!resultAuthUserList) return;

        setUsers(resultAuthUserList.users);
    }, [resultAuthUserList]);

    const { requestApiIssueList, resultApiIssueList } = RequestIssue.useRequestApiIssueList();

    useEffect(() => {
        if (!resultApiIssueList) return;

        if (resultApiIssueList.issues.totalPages > requestIssuesPage + 1) {
            setTempIssues([...(tempIssues || []), ...resultApiIssueList.issues.content]);
            setRequestIssuesPage(requestIssuesPage + 1);
        } else {
            setLoading(false);
            setIssues([...(tempIssues || []), ...resultApiIssueList.issues.content]);
            setRequestIssuesPage(0);
            setTempIssues([]);

            setTimeout(() => {
                document.querySelector(".ant-layout-content").scrollTo({ top: reducerIssuesByCar.scrollPosition });
            }, 100);
        }
    }, [resultApiIssueList]);

    useEffect(() => {
        setWaitingCount(issues?.filter((issue) => issue.actionStatus === "WAITING")?.length);
        setInActionCount(issues?.filter((issue) => issue.actionStatus === "IN_ACTION")?.length);
    }, [issues]);

    useEffect(() => {
        if (requestIssuesPage === 0) return;

        requestApiIssueList(searchValue, requestIssuesPage);
    }, [requestIssuesPage]);

    const { requestApiIssueDelete, resultApiIssueDelete } = RequestIssue.useRequestApiIssueDelete();

    useEffect(() => {
        if (!resultApiIssueDelete) return;

        onResult("delete");
    }, [resultApiIssueDelete]);

    const onDelete = (id: number, name: string) => {
        Modal.confirm({
            title: String.issue + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiIssueDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.issue} ${typeText} 성공`, `${String.issue} 정보를 ${typeText}하였습니다.`);
        requestApiIssueList(searchValue, requestIssuesPage);
    };

    const memoizedValues = useMemo(() => {
        return carNumber ? search?.filter((item) => item.id !== "carNumber") : search;
    }, [carNumber, search]);

    const summary = [
        {
            content: `${waitingCount}건 /`,
            icon: IconRedExclamtion,
        },
        {
            content: `${inActionCount}건`,
            icon: IconInAction,
        },
    ];

    return (
        <div className="pageWrapper" style={{ padding: hasSummary ? 24 : 0 }}>
            <Search
                title={
                    carNumber
                        ? pathname.includes(Common.PAGE_MANAGE_CAR_DETAIL)
                            ? "정비 이력"
                            : `${carNumber} 정비 이력`
                        : String.menu_maintenance_history
                }
                dataLength={issues?.length}
                summary={waitingCount || inActionCount ? summary : undefined}
                values={carNumber ? search?.filter((item) => item.id !== "carNumber") : search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_USER])
                        ? () => navigate(Common.PAGE_MANAGE_MAINTENANCE_REGISTER + (carNumber ? `/${carNumber}` : ""))
                        : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.IssueDto) => row.issueId}
                disabledTitle
                loading={loading}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [
                        Utils.getAuthority.ROLE_ADMIN,
                        Utils.getAuthority.ROLE_MANAGER,
                        Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                        Utils.getAuthority.ROLE_CUSTOMER,
                    ])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={issues}
                currentPage={currentPage}
                rowClassName={(issue: TypeDTO.IssueDto) => {
                    return issue?.issueId === reducerIssuesByCar?.selectedId ? "table-row-selected" : "";
                }}
                tableSize={reducerIssuesByCar?.pageSize}
                onRow={(value: TypeDTO.IssueDto) => {
                    dispatchIssuesByCar(value.issueId);
                    navigateWithRefresh(Common.PAGE_MANAGE_MAINTENANCE_DETAIL + "/" + value.car.carId + "/" + value.issueId);
                }}
                onChange={onTableChange}
            />
        </div>
    );
};

export default Maintenances;
