import { useEffect, useState } from "react";

import moment from "moment";

import * as CanDataTSEco from "../../commons/CanDataTSEco";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import Space from "antd/es/space/index";
import CanDataWrapper, { CanTableType } from "./CanDataWrapper";

function CanDataTSEcoViewer({
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataTSEco.CanData>();

    const convertRemarkWithUnit = (value: number, unit: string) => {
        if (value === undefined) {
            return "-";
        }
        return value + unit;
    };

    //#region EvInfo
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [evInfoPanelOpen, setEvInfoPanelOpen] = useState(true);
    const [evInfoChartMounted, setEvInfoChartMounted] = useState(false);
    const [selectedEvInfo, setSelectedEvInfo] = useState<Array<React.Key>>([
        Common.TSECO_EVINFO_VOLT_KEY,
        Common.TSECO_EVINFO_SOC_KEY,
        // Common.TSECO_EVINFO_MOTOR_RPM_KEY,
        // Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_KEY,
        // Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_KEY,
        // Common.TSECO_EVINFO_MOTOR_TEMP_KEY,
    ]);

    const [seriesChartDatasEvInfo, setSeriesChartDatasEvInfo] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartEvInfoVolt, setChartEvInfoVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO_VOLT_NAME,
        data: [],
    });

    const [chartEvInfoSoc, setChartEvInfoSoc] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO_SOC_NAME,
        data: [],
    });

    const [chartEvInfoMotorRpm, setChartEvInfoMotorRpm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO_MOTOR_RPM_NAME,
        data: [],
    });

    const [chartEvInfoMotorControllerInputVolt, setChartEvInfoMotorControllerInputVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_NAME,
        data: [],
    });

    const [chartEvInfoMotorControllerTemp, setChartEvInfoMotorControllerTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_NAME,
        data: [],
    });

    const [chartEvInfoMotorTemp, setChartEvInfoMotorTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO_MOTOR_TEMP_NAME,
        data: [],
    });

    const getTabelTooltipEvInfo = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_EVINFO_VOLT_KEY:
                return (
                    <>
                        <p>
                            EV Information
                            <br />
                            CAN ID: 0x18EF4AEF
                            <br />
                            Name: 배터리 전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.TSECO_EVINFO_SOC_KEY:
                return (
                    <>
                        <p>
                            EV Information
                            <br />
                            CAN ID: 0x18EF4AEF
                            <br />
                            Name: SOC
                        </p>
                        <p>Remarks: %</p>
                    </>
                );

            case Common.TSECO_EVINFO_MOTOR_RPM_KEY:
                return (
                    <>
                        <p>
                            EV Information
                            <br />
                            CAN ID: 0x18EF4AEF
                            <br />
                            Name: 모터회전속도
                        </p>
                        <p>Remarks: rpm</p>
                    </>
                );

            case Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_KEY:
                return (
                    <>
                        <p>
                            EV Information
                            <br />
                            CAN ID: 0x18EF4AEF
                            <br />
                            Name: 모터제어기 입력전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_KEY:
                return (
                    <>
                        <p>
                            EV Information
                            <br />
                            CAN ID: 0x18EF4AEF
                            <br />
                            Name: 모터제어기 온도
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );

            case Common.TSECO_EVINFO_MOTOR_TEMP_KEY:
                return (
                    <>
                        <p>
                            EV Information
                            <br />
                            CAN ID: 0x18EF4AEF
                            <br />
                            Name: 모터온도
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );
        }

        return null;
    };

    const getEvInfoVoltValue = () => {
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo?.volt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getEvInfoSocValue = () => {
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo?.soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getEvInfoMotorRpmValue = () => {
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_RPM_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo?.motorRpm, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getEvInfoMotorControllerInputVoltValue = () => {
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo?.motorControllerInputVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getEvInfoMotorControllerTempValue = () => {
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo?.motorControllerTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getEvInfoMotorTempValue = () => {
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo?.motorTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const onChangeEvInfo = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedEvInfo(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasEvInfo: Array<TypeUtils.ChartData> = [];

        if (selectedEvInfo.includes(Common.TSECO_EVINFO_VOLT_KEY)) chartDatasEvInfo.push(chartEvInfoVolt);
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_SOC_KEY)) chartDatasEvInfo.push(chartEvInfoSoc);
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_RPM_KEY)) chartDatasEvInfo.push(chartEvInfoMotorRpm);
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_KEY)) chartDatasEvInfo.push(chartEvInfoMotorControllerInputVolt);
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_KEY)) chartDatasEvInfo.push(chartEvInfoMotorControllerTemp);
        if (selectedEvInfo.includes(Common.TSECO_EVINFO_MOTOR_TEMP_KEY)) chartDatasEvInfo.push(chartEvInfoMotorTemp);

        setSeriesChartDatasEvInfo(chartDatasEvInfo);
    }, [
        selectedEvInfo,
        chartEvInfoVolt,
        chartEvInfoSoc,
        chartEvInfoMotorRpm,
        chartEvInfoMotorControllerInputVolt,
        chartEvInfoMotorControllerTemp,
        chartEvInfoMotorTemp,
    ]);

    const tableDataEvInfo: CanTableType[] = [
        {
            key: Common.TSECO_EVINFO_VOLT_KEY,
            name: Common.TSECO_EVINFO_VOLT_NAME,
            value: getEvInfoVoltValue(),
        },
        {
            key: Common.TSECO_EVINFO_SOC_KEY,
            name: Common.TSECO_EVINFO_SOC_NAME,
            value: getEvInfoSocValue(),
        },
        {
            key: Common.TSECO_EVINFO_MOTOR_RPM_KEY,
            name: Common.TSECO_EVINFO_MOTOR_RPM_NAME,
            value: getEvInfoMotorRpmValue(),
        },
        {
            key: Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_KEY,
            name: Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_NAME,
            value: getEvInfoMotorControllerInputVoltValue(),
        },
        {
            key: Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_KEY,
            name: Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_NAME,
            value: getEvInfoMotorControllerTempValue(),
        },
        {
            key: Common.TSECO_EVINFO_MOTOR_TEMP_KEY,
            name: Common.TSECO_EVINFO_MOTOR_TEMP_NAME,
            value: getEvInfoMotorTempValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region EvInfo1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [evInfo1PanelOpen, setEvInfo1PanelOpen] = useState(false);
    const [evInfo1ChartMounted, setEvInfo1ChartMounted] = useState(false);
    const [selectedEvInfo1, setSelectedEvInfo1] = useState<Array<React.Key>>([
        // Common.TSECO_EVINFO1_SPEED_KEY,
        // Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_KEY,
        // Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_KEY,
        // Common.TSECO_EVINFO1_MILEAGE_KEY,
        // Common.TSECO_EVINFO1_REMAIN_ENERGY_KEY,
        // Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_KEY,
        // Common.TSECO_EVINFO1_GEAR_KEY,
        // Common.TSECO_EVINFO1_ACCEL_DEPTH_KEY,
    ]);

    const [seriesChartDatasEvInfo1, setSeriesChartDatasEvInfo1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartEvInfo1Speed, setChartEvInfo1Speed] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_SPEED_NAME,
        data: [],
    });

    const [chartEvInfo1AvgEnergyConsumption, setChartEvInfo1AvgEnergyConsumption] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_NAME,
        data: [],
    });

    const [chartEvInfo1InstantEnergyConsumption, setChartEvInfo1InstantEnergyConsumption] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_NAME,
        data: [],
    });

    const [chartEvInfo1Mileage, setChartEvInfo1Mileage] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_MILEAGE_NAME,
        data: [],
    });

    const [chartEvInfo1RemainingEnergy, setChartEvInfo1RemainingEnergy] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_REMAIN_ENERGY_NAME,
        data: [],
    });

    const [chartEvInfo1StorageBatVolt, setChartEvInfo1StorageBatVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_NAME,
        data: [],
    });

    const [chartEvInfo1Gear, setChartEvInfo1Gear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_GEAR_NAME,
        data: [],
    });

    const [chartEvInfo1AccelDepth, setChartEvInfo1AccelDepth] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO1_ACCEL_DEPTH_NAME,
        data: [],
    });

    const getTabelTooltipEvInfo1 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_EVINFO1_SPEED_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 차량 속도
                        </p>
                        <p>Remarks: km/h</p>
                    </>
                );
            case Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 평균 에너지 소모
                        </p>
                        <p>Remarks: kwh/100km</p>
                    </>
                );

            case Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 순간 에너지 소모
                        </p>
                        <p>Remarks: kwh/100km</p>
                    </>
                );

            case Common.TSECO_EVINFO1_MILEAGE_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 주행거리
                        </p>
                        <p>Remarks: km</p>
                    </>
                );

            case Common.TSECO_EVINFO1_REMAIN_ENERGY_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 잔여 에너지
                        </p>
                        <p>Remarks: kwh</p>
                    </>
                );

            case Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 축전지 전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.TSECO_EVINFO1_GEAR_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 현재 기어
                        </p>
                        <p>
                            Remarks:
                            <br />
                            1=P, 2=R,
                            <br />
                            3=N, 4=D,
                        </p>
                    </>
                );

            case Common.TSECO_EVINFO1_ACCEL_DEPTH_KEY:
                return (
                    <>
                        <p>
                            EV Information 1
                            <br />
                            CAN ID: 0x18EF4BEF
                            <br />
                            Name: 악셀레이터 깊이
                        </p>
                        <p>Remarks: %</p>
                    </>
                );
        }

        return null;
    };

    const convertGearRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 1:
                valueString = "P";
                break;
            case 2:
                valueString = "R";
                break;
            case 3:
                valueString = "N";
                break;
            case 4:
                valueString = "D";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getEvInfo1SpeedValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo1?.speed, Common.UNIT_SPEED);
        }
        return "";
    };

    const getEvInfo1AvgEnergyConsumptionValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo1?.avgEnergyConsumption, Common.UNIT_POWER_DISTANCE);
        }
        return "";
    };

    const getEvInfo1InstantEnergyConsumptionValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo1?.instantEnergyConsumption, Common.UNIT_POWER_DISTANCE);
        }
        return "";
    };

    const getEvInfo1MileageValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_MILEAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo1?.mileage, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const getEvInfo1RemainingEnergyValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_REMAIN_ENERGY_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo1?.remainingEnergy, Common.UNIT_POWER_H);
        }
        return "";
    };

    const getEvInfo1StorageBatVoltValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo1?.storageBatVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getEvInfo1GearValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_GEAR_KEY) && currentCanData !== undefined) {
            return convertGearRemark(currentCanData.evInfo1?.gear);
        }
        return "";
    };

    const getEvInfo1AccelDepthValue = () => {
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_ACCEL_DEPTH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo1?.accelDepth, Common.UNIT_PERCENT);
        }
        return "";
    };

    const onChangeEvInfo1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedEvInfo1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasEvInfo1: Array<TypeUtils.ChartData> = [];

        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_SPEED_KEY)) chartDatasEvInfo1.push(chartEvInfo1Speed);
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_KEY)) chartDatasEvInfo1.push(chartEvInfo1AvgEnergyConsumption);
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_KEY))
            chartDatasEvInfo1.push(chartEvInfo1InstantEnergyConsumption);
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_MILEAGE_KEY)) chartDatasEvInfo1.push(chartEvInfo1Mileage);
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_REMAIN_ENERGY_KEY)) chartDatasEvInfo1.push(chartEvInfo1RemainingEnergy);
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_KEY)) chartDatasEvInfo1.push(chartEvInfo1StorageBatVolt);
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_GEAR_KEY)) chartDatasEvInfo1.push(chartEvInfo1Gear);
        if (selectedEvInfo1.includes(Common.TSECO_EVINFO1_ACCEL_DEPTH_KEY)) chartDatasEvInfo1.push(chartEvInfo1AccelDepth);

        setSeriesChartDatasEvInfo1(chartDatasEvInfo1);
    }, [
        selectedEvInfo1,
        chartEvInfo1Speed,
        chartEvInfo1AvgEnergyConsumption,
        chartEvInfo1InstantEnergyConsumption,
        chartEvInfo1Mileage,
        chartEvInfo1RemainingEnergy,
        chartEvInfo1StorageBatVolt,
        chartEvInfo1Gear,
        chartEvInfo1AccelDepth,
    ]);

    const tableDataEvInfo1: CanTableType[] = [
        {
            key: Common.TSECO_EVINFO1_SPEED_KEY,
            name: Common.TSECO_EVINFO1_SPEED_NAME,
            value: getEvInfo1SpeedValue(),
        },
        {
            key: Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_KEY,
            name: Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_NAME,
            value: getEvInfo1AvgEnergyConsumptionValue(),
        },
        {
            key: Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_KEY,
            name: Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_NAME,
            value: getEvInfo1InstantEnergyConsumptionValue(),
        },
        {
            key: Common.TSECO_EVINFO1_MILEAGE_KEY,
            name: Common.TSECO_EVINFO1_MILEAGE_NAME,
            value: getEvInfo1MileageValue(),
        },
        {
            key: Common.TSECO_EVINFO1_REMAIN_ENERGY_KEY,
            name: Common.TSECO_EVINFO1_REMAIN_ENERGY_NAME,
            value: getEvInfo1RemainingEnergyValue(),
        },
        {
            key: Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_KEY,
            name: Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_NAME,
            value: getEvInfo1StorageBatVoltValue(),
        },
        {
            key: Common.TSECO_EVINFO1_GEAR_KEY,
            name: Common.TSECO_EVINFO1_GEAR_NAME,
            value: getEvInfo1GearValue(),
        },
        {
            key: Common.TSECO_EVINFO1_ACCEL_DEPTH_KEY,
            name: Common.TSECO_EVINFO1_ACCEL_DEPTH_NAME,
            value: getEvInfo1AccelDepthValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region EvInfo2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [evInfo2PanelOpen, setEvInfo2PanelOpen] = useState(false);
    const [evInfo2ChartMounted, setEvInfo2ChartMounted] = useState(false);
    const [selectedEvInfo2, setSelectedEvInfo2] = useState<Array<React.Key>>([
        // Common.TSECO_EVINFO2_CELL_MAX_VOLT_KEY,
        // Common.TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_KEY,
        // Common.TSECO_EVINFO2_CELL_MIN_VOLT_KEY,
        // Common.TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_KEY,
    ]);

    const [seriesChartDatasEvInfo2, setSeriesChartDatasEvInfo2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartEvInfo2CellMaxVolt, setChartEvInfo2CellMaxVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO2_CELL_MAX_VOLT_NAME,
        data: [],
    });

    const [chartEvInfo2CellMaxVoltNo, setChartEvInfo2CellMaxVoltNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_NAME,
        data: [],
    });

    const [chartEvInfo2CellMinVolt, setChartEvInfo2CellMinVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO2_CELL_MIN_VOLT_NAME,
        data: [],
    });

    const [chartEvInfo2CellMinVoltNo, setChartEvInfo2CellMinVoltNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_NAME,
        data: [],
    });

    const getTabelTooltipEvInfo2 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_EVINFO2_CELL_MAX_VOLT_KEY:
                return (
                    <>
                        <p>
                            EV Information 2
                            <br />
                            CAN ID: 0x18EF4CEF
                            <br />
                            Name: 셀 최고 전압
                        </p>
                        <p>Remarks: V, 0~6</p>
                    </>
                );
            case Common.TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_KEY:
                return (
                    <>
                        <p>
                            EV Information 2
                            <br />
                            CAN ID: 0x18EF4CEF
                            <br />
                            Name: 셀 최고 전압 번호
                        </p>
                        <p>Remarks: 0~2000</p>
                    </>
                );

            case Common.TSECO_EVINFO2_CELL_MIN_VOLT_KEY:
                return (
                    <>
                        <p>
                            EV Information 2
                            <br />
                            CAN ID: 0x18EF4CEF
                            <br />
                            Name: 셀 최저 전압
                        </p>
                        <p>Remarks: V, 0~6</p>
                    </>
                );

            case Common.TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_KEY:
                return (
                    <>
                        <p>
                            EV Information 2
                            <br />
                            CAN ID: 0x18EF4CEF
                            <br />
                            Name: 셀 최저 전압 번호
                        </p>
                        <p>Remarks: 0~2000</p>
                    </>
                );
        }

        return null;
    };

    const getEvInfo2CellMaxVoltValue = () => {
        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MAX_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo2?.cellMaxVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getEvInfo2CellMaxVoltNoValue = () => {
        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo2?.cellMaxVoltNo, "");
        }
        return "";
    };

    const getEvInfo2CellMinVoltValue = () => {
        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MIN_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo2?.cellMinVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getEvInfo2CellMinVoltNoValue = () => {
        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo2?.cellMinVoltNo, "");
        }
        return "";
    };

    const onChangeEvInfo2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedEvInfo2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasEvInfo2: Array<TypeUtils.ChartData> = [];

        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MAX_VOLT_KEY)) chartDatasEvInfo2.push(chartEvInfo2CellMaxVolt);
        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_KEY)) chartDatasEvInfo2.push(chartEvInfo2CellMaxVoltNo);
        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MIN_VOLT_KEY)) chartDatasEvInfo2.push(chartEvInfo2CellMinVolt);
        if (selectedEvInfo2.includes(Common.TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_KEY)) chartDatasEvInfo2.push(chartEvInfo2CellMinVoltNo);

        setSeriesChartDatasEvInfo2(chartDatasEvInfo2);
    }, [selectedEvInfo2, chartEvInfo2CellMaxVolt, chartEvInfo2CellMaxVoltNo, chartEvInfo2CellMinVolt, chartEvInfo2CellMinVoltNo]);

    const tableDataEvInfo2: CanTableType[] = [
        {
            key: Common.TSECO_EVINFO2_CELL_MAX_VOLT_KEY,
            name: Common.TSECO_EVINFO2_CELL_MAX_VOLT_NAME,
            value: getEvInfo2CellMaxVoltValue(),
        },
        {
            key: Common.TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_KEY,
            name: Common.TSECO_EVINFO2_CELL_MAX_VOLT_NUMBER_NAME,
            value: getEvInfo2CellMaxVoltNoValue(),
        },
        {
            key: Common.TSECO_EVINFO2_CELL_MIN_VOLT_KEY,
            name: Common.TSECO_EVINFO2_CELL_MIN_VOLT_NAME,
            value: getEvInfo2CellMinVoltValue(),
        },
        {
            key: Common.TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_KEY,
            name: Common.TSECO_EVINFO2_CELL_MIN_VOLT_NUMBER_NAME,
            value: getEvInfo2CellMinVoltNoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region EvInfo3
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [evInfo3PanelOpen, setEvInfo3PanelOpen] = useState(false);
    const [evInfo3ChartMounted, setEvInfo3ChartMounted] = useState(false);
    const [selectedEvInfo3, setSelectedEvInfo3] = useState<Array<React.Key>>([
        // Common.TSECO_EVINFO3_CELL_MAX_TEMP_KEY,
        // Common.TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_KEY,
        // Common.TSECO_EVINFO3_CELL_MIN_TEMP_KEY,
        // Common.TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_KEY,
        // Common.TSECO_EVINFO3_BAT_CURR_KEY,
    ]);

    const [seriesChartDatasEvInfo3, setSeriesChartDatasEvInfo3] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartEvInfo3CellMaxTemp, setChartEvInfo3CellMaxTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO3_CELL_MAX_TEMP_NAME,
        data: [],
    });

    const [chartEvInfo3CellMaxTempNo, setChartEvInfo3CellMaxTempNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_NAME,
        data: [],
    });

    const [chartEvInfo3CellMinTemp, setChartEvInfo3CellMinTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO3_CELL_MIN_TEMP_NAME,
        data: [],
    });

    const [chartEvInfo3CellMinTempNo, setChartEvInfo3CellMinTempNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_NAME,
        data: [],
    });

    const [chartEvInfo3BatCurr, setChartEvInfo3BatCurr] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVINFO3_BAT_CURR_NAME,
        data: [],
    });

    const getTabelTooltipEvInfo3 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_EVINFO3_CELL_MAX_TEMP_KEY:
                return (
                    <>
                        <p>
                            EV Information 3
                            <br />
                            CAN ID: 0x18EF4DEF
                            <br />
                            Name: 셀 최고 온도
                        </p>
                        <p>Remarks: ℃, -40~-210</p>
                    </>
                );
            case Common.TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_KEY:
                return (
                    <>
                        <p>
                            EV Information 3
                            <br />
                            CAN ID: 0x18EF4DEF
                            <br />
                            Name: 셀 최고 온도 번호
                        </p>
                        <p>Remarks: 0~2000</p>
                    </>
                );

            case Common.TSECO_EVINFO3_CELL_MIN_TEMP_KEY:
                return (
                    <>
                        <p>
                            EV Information 3
                            <br />
                            CAN ID: 0x18EF4DEF
                            <br />
                            Name: 셀 최저 온도
                        </p>
                        <p>Remarks: ℃, -40~-210</p>
                    </>
                );

            case Common.TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_KEY:
                return (
                    <>
                        <p>
                            EV Information 3
                            <br />
                            CAN ID: 0x18EF4DEF
                            <br />
                            Name: 셀 최저 온도 번호
                        </p>
                        <p>Remarks: 0~2000</p>
                    </>
                );

            case Common.TSECO_EVINFO3_BAT_CURR_KEY:
                return (
                    <>
                        <p>
                            EV Information 3
                            <br />
                            CAN ID: 0x18EF4DEF
                            <br />
                            Name: 배터리 전류
                        </p>
                        <p>Remarks: A, -500~500</p>
                    </>
                );
        }

        return null;
    };

    const getEvInfo3CellMaxTempValue = () => {
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MAX_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo3?.cellMaxTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getEvInfo3CellMaxTempNoValue = () => {
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo3?.cellMaxTempNo, "");
        }
        return "";
    };

    const getEvInfo3CellMinTempValue = () => {
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MIN_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo3?.cellMinTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getEvInfo3CellMinTempNoValue = () => {
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo3?.cellMinTempNo, "");
        }
        return "";
    };

    const getEvInfo3BatCurrValue = () => {
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_BAT_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.evInfo3?.batCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeEvInfo3 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedEvInfo3(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasEvInfo3: Array<TypeUtils.ChartData> = [];

        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MAX_TEMP_KEY)) chartDatasEvInfo3.push(chartEvInfo3CellMaxTemp);
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_KEY)) chartDatasEvInfo3.push(chartEvInfo3CellMaxTempNo);
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MIN_TEMP_KEY)) chartDatasEvInfo3.push(chartEvInfo3CellMinTemp);
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_KEY)) chartDatasEvInfo3.push(chartEvInfo3CellMinTempNo);
        if (selectedEvInfo3.includes(Common.TSECO_EVINFO3_BAT_CURR_KEY)) chartDatasEvInfo3.push(chartEvInfo3BatCurr);

        setSeriesChartDatasEvInfo3(chartDatasEvInfo3);
    }, [
        selectedEvInfo3,
        chartEvInfo3CellMaxTemp,
        chartEvInfo3CellMaxTempNo,
        chartEvInfo3CellMinTemp,
        chartEvInfo3CellMinTempNo,
        chartEvInfo3BatCurr,
    ]);

    const tableDataEvInfo3: CanTableType[] = [
        {
            key: Common.TSECO_EVINFO3_CELL_MAX_TEMP_KEY,
            name: Common.TSECO_EVINFO3_CELL_MAX_TEMP_NAME,
            value: getEvInfo3CellMaxTempValue(),
        },
        {
            key: Common.TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_KEY,
            name: Common.TSECO_EVINFO3_CELL_MAX_TEMP_NUMBER_NAME,
            value: getEvInfo3CellMaxTempNoValue(),
        },
        {
            key: Common.TSECO_EVINFO3_CELL_MIN_TEMP_KEY,
            name: Common.TSECO_EVINFO3_CELL_MIN_TEMP_NAME,
            value: getEvInfo3CellMinTempValue(),
        },
        {
            key: Common.TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_KEY,
            name: Common.TSECO_EVINFO3_CELL_MIN_TEMP_NUMBER_NAME,
            value: getEvInfo3CellMinTempNoValue(),
        },
        {
            key: Common.TSECO_EVINFO3_BAT_CURR_KEY,
            name: Common.TSECO_EVINFO3_BAT_CURR_NAME,
            value: getEvInfo3BatCurrValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region EvDTC
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [evDtcPanelOpen, setEvDtcPanelOpen] = useState(false);
    const [evDtcChartMounted, setEvDtcChartMounted] = useState(false);
    const [selectedEvDtc, setSelectedEvDtc] = useState<Array<React.Key>>([
        // Common.TSECO_EVDTC_SYS_READY_KEY,
        // Common.TSECO_EVDTC_HVR_STATUS_KEY,
        // Common.TSECO_EVDTC_SYS_STATUS_KEY,
        // Common.TSECO_EVDTC_MCU_TEMP_ALARM_KEY,
        // Common.TSECO_EVDTC_SOC_ALARM_KEY,
        // Common.TSECO_EVDTC_INSULATION_FAULT_KEY,
        // Common.TSECO_EVDTC_BMS_FAULT_KEY,
        // Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_KEY,
        // Common.TSECO_EVDTC_MOTOR_FAULT_KEY,
        // Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_KEY,
        // Common.TSECO_EVDTC_BATTERY_FAULT_KEY,
        // Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_KEY,
        // Common.TSECO_EVDTC_GEAR_FAULT_KEY,
        // Common.TSECO_EVDTC_DCDC_FAULT_KEY,
        // Common.TSECO_EVDTC_STEERING_DCAC_FAULT_KEY,
        // Common.TSECO_EVDTC_BREAK_DCAC_FAULT_KEY,
        // Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_KEY,
        // Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_KEY,
        // Common.TSECO_EVDTC_SUB_BATTERY_STATUS_KEY,
        // Common.TSECO_EVDTC_HVIL_STATUS_KEY,
        // Common.TSECO_EVDTC_VEHICLE_STATUS_KEY,
        // Common.TSECO_EVDTC_HVESTOP_STATUS_KEY,
        // Common.TSECO_EVDTC_SNOW_MODE_STATUS_KEY,
        // Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_KEY,
        // Common.TSECO_EVDTC_HYBRID_MODE_STATUS_KEY,
        // Common.TSECO_EVDTC_REGENERATION_STATUS_KEY,
        // Common.TSECO_EVDTC_ANTI_SLIP_STATUS_KEY,
        // Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_KEY,
    ]);

    const [seriesChartDatasEvDtc, setSeriesChartDatasEvDtc] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartEvDtcSysReady, setChartEvDtcSysReady] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_SYS_READY_NAME,
        data: [],
    });

    const [chartEvDtcHvrStatus, setChartEvDtcHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_HVR_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcSysStatus, setChartEvDtcSysStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_SYS_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcMcuTempAlarm, setChartEvDtcMcuTempAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_MCU_TEMP_ALARM_NAME,
        data: [],
    });

    const [chartEvDtcSocAlarm, setChartEvDtcSocAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_SOC_ALARM_NAME,
        data: [],
    });

    const [chartEvDtcInsulationFault, setChartEvDtcInsulationFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_INSULATION_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcBmsFault, setChartEvDtcBmsFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_BMS_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcMotorOverheatAlarm, setChartEvDtcMotorOverheatAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_NAME,
        data: [],
    });

    const [chartEvDtcMotorFault, setChartEvDtcMotorFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_MOTOR_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcMcuCommunicationFault, setChartEvDtcMcuCommunicationFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcBatteryFault, setChartEvDtcBatteryFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_BATTERY_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcBmsCommunicationFault, setChartEvDtcBmsCommunicationFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcGearFault, setChartEvDtcGearFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_GEAR_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcDcdcFault, setChartEvDtcDcdcFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_DCDC_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcSteeringDcacFault, setChartEvDtcSteeringDcacFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_STEERING_DCAC_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcBreakDcacFault, setChartEvDtcBreakDcacFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_BREAK_DCAC_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcAccelPedalFault, setChartEvDtcAccelPedalFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcBreakPedalFault, setChartEvDtcBreakPedalFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_NAME,
        data: [],
    });

    const [chartEvDtcSubBatteryStatus, setChartEvDtcSubBatteryStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_SUB_BATTERY_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcHvilStatus, setChartEvDtcHvilStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_HVIL_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcVehicleStatus, setChartEvDtcVehicleStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_VEHICLE_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcHvestopStatus, setChartEvDtcHvestopStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_HVESTOP_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcSnowModeStatus, setChartEvDtcSnowModeStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_SNOW_MODE_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcOutputLimitStatus, setChartEvDtcOutputLimitStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcHybridModeStatus, setChartEvDtcHybridModeStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_HYBRID_MODE_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcRegenerationStatus, setChartEvDtcRegenerationStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_REGENERATION_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcAntiSlipStatus, setChartEvDtcAntiSlipStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_ANTI_SLIP_STATUS_NAME,
        data: [],
    });

    const [chartEvDtcDownhillAssistStatus, setChartEvDtcDownhillAssistStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipEvDtc = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_EVDTC_SYS_READY_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 시스템 준비 상태
                        </p>
                        <p>Remarks: 0=off, 1=on</p>
                    </>
                );
            case Common.TSECO_EVDTC_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_EVDTC_SYS_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 시스템 시동 상태
                        </p>
                        <p>Remarks: 0=off, 1=on</p>
                    </>
                );

            case Common.TSECO_EVDTC_MCU_TEMP_ALARM_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 구동 모터 제어기 온도 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_EVDTC_SOC_ALARM_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: SOC 알람
                        </p>
                        <p>Remarks: 0=알람없음, 1=1급 알람, 2=2급 알람, 3=3급 알람</p>
                    </>
                );

            case Common.TSECO_EVDTC_INSULATION_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 절연 고장
                        </p>
                        <p>Remarks: 0=알람없음, 1=1급 알람, 2=2급 알람, 3=3급 알람</p>
                    </>
                );
            case Common.TSECO_EVDTC_BMS_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: BMS 고장
                        </p>
                        <p>Remarks: 0=알람없음, 1=1급 알람, 2=2급 알람, 3=3급 알람</p>
                    </>
                );

            case Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 모터 온도 과열 알람
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_MOTOR_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 모터 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: MCU 통신 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_BATTERY_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 배터리 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );
            case Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: BMS 통신 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_GEAR_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 변속기 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_DCDC_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: DCDC 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_STEERING_DCAC_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 스티어링 DCAC 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_BREAK_DCAC_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 브레이크 DCAC 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );
            case Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 가속 페달 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 브레이크 페달 고장
                        </p>
                        <p>Remarks: 0=고장 없음, 1=고장 있음</p>
                    </>
                );

            case Common.TSECO_EVDTC_SUB_BATTERY_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 저압 축전지 충전 상태
                        </p>
                        <p>Remarks: 0=off, 1=on</p>
                    </>
                );

            case Common.TSECO_EVDTC_HVIL_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 고압 인터락 상태 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_EVDTC_VEHICLE_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 차량 상태
                        </p>
                        <p>Remarks: 1=전기, 2=하이브리드, 3=휘발유, 254=이상, 255=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_EVDTC_HVESTOP_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 고전압 비상 정지 상태
                        </p>
                        <p>Remarks: 0=차단, 1=작동</p>
                    </>
                );

            case Common.TSECO_EVDTC_SNOW_MODE_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 스노우 모드
                        </p>
                        <p>Remarks: 0=차단, 1=작동</p>
                    </>
                );

            case Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name:출력 제한 상태
                        </p>
                        <p>Remarks: 0=차단, 1=작동</p>
                    </>
                );

            case Common.TSECO_EVDTC_HYBRID_MODE_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 하이브리드 모드 선택 상태
                        </p>
                        <p>Remarks: 0=하이브리드, 1=전기 모드</p>
                    </>
                );

            case Common.TSECO_EVDTC_REGENERATION_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 강제 발전 상태
                        </p>
                        <p>Remarks: 0=차단, 1=작동</p>
                    </>
                );
            case Common.TSECO_EVDTC_ANTI_SLIP_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 언적 주차 미끄럼 방지 상태
                        </p>
                        <p>Remarks: 0=차단, 1=작동</p>
                    </>
                );

            case Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_KEY:
                return (
                    <>
                        <p>
                            EV DTC
                            <br />
                            CAN ID: 0x18EF4EEF
                            <br />
                            Name: 내리막 어시스트 상태
                        </p>
                        <p>Remarks: 0=차단, 1=작동</p>
                    </>
                );
        }

        return null;
    };

    const convertSysReadyRemark = (value: number) => {
        let valueString = "OFF";
        switch (value) {
            case 0:
                valueString = "OFF";
                break;
            case 1:
                valueString = "ON";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertHvrStatusRemark = (value: number) => {
        let valueString = "차단";
        switch (value) {
            case 0:
                valueString = "차단";
                break;
            case 1:
                valueString = "흡착";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertVehicleStatusRemark = (value: number) => {
        let valueString = "유효하지 않음";
        switch (value) {
            case 1:
                valueString = "전기";
                break;
            case 2:
                valueString = "하이브리드";
                break;
            case 3:
                valueString = "휘발유";
                break;
            case 254:
                valueString = "이상";
                break;
            case 255:
                valueString = "유효하지 않음";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertSocAlarmRemark = (value: number) => {
        let valueString = "알람 없음";
        switch (value) {
            case 0:
                valueString = "알람 없음";
                break;
            case 1:
                valueString = "1급 알람";
                break;
            case 2:
                valueString = "2급 알람";
                break;
            case 3:
                valueString = "3급 알람";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertFaultRemark = (value: number) => {
        let valueString = "고장 없음";
        switch (value) {
            case 0:
                valueString = "고장 없음";
                break;
            case 1:
                valueString = "고장 있음";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertTempAlarmRemark = (value: number) => {
        let valueString = "정상";
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "알람";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertHvestopStatusRemark = (value: number) => {
        let valueString = "차단";
        switch (value) {
            case 0:
                valueString = "차단";
                break;
            case 1:
                valueString = "작동";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertHybridModeStatusRemark = (value: number) => {
        let valueString = "하이브리드";
        switch (value) {
            case 0:
                valueString = "하이브리드";
                break;
            case 1:
                valueString = "전기 모드";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getEvDtcSysReadyValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SYS_READY_KEY) && currentCanData !== undefined) {
            return convertSysReadyRemark(currentCanData.evDtc?.sysReady);
        }
        return "";
    };

    const getEvDtcHvrStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.evDtc?.hvrStatus);
        }
        return "";
    };

    const getEvDtcSysStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SYS_STATUS_KEY) && currentCanData !== undefined) {
            return convertSysReadyRemark(currentCanData.evDtc?.sysStatus);
        }
        return "";
    };

    const getEvDtcMcuTempAlarmValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MCU_TEMP_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.evDtc?.mcuTempAlarm);
        }
        return "";
    };

    const getEvDtcSocAlarmValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SOC_ALARM_KEY) && currentCanData !== undefined) {
            return convertSocAlarmRemark(currentCanData.evDtc?.socAlarm);
        }
        return "";
    };

    const getEvDtcInsulationFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_INSULATION_FAULT_KEY) && currentCanData !== undefined) {
            return convertSocAlarmRemark(currentCanData.evDtc?.insulationFault);
        }
        return "";
    };

    const getEvDtcBmsFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BMS_FAULT_KEY) && currentCanData !== undefined) {
            return convertSocAlarmRemark(currentCanData.evDtc?.bmsFault);
        }
        return "";
    };

    const getEvDtcMotorOverheatAlarmValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.motorOverheatAlarm);
        }
        return "";
    };

    const getEvDtcMotorFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MOTOR_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.motorFault);
        }
        return "";
    };

    const getEvDtcMcuCommunicationFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.mcuCommunicationFault);
        }
        return "";
    };

    const getEvDtcBatteryFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BATTERY_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.batteryFault);
        }
        return "";
    };

    const getEvDtcBmsCommunicationFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.bmsCommunicationFault);
        }
        return "";
    };

    const getEvDtcGearFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_GEAR_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.gearFault);
        }
        return "";
    };

    const getEvDtcDcdcFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_DCDC_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.dcdcFault);
        }
        return "";
    };

    const getEvDtcSteeringDcacFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_STEERING_DCAC_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.steeringDcacFault);
        }
        return "";
    };

    const getEvDtcBreakDcacFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BREAK_DCAC_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.breakDcacFault);
        }
        return "";
    };

    const getEvDtcAccelPedalFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.accelPedalFault);
        }
        return "";
    };

    const getEvDtcBreakPedalFaultValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_KEY) && currentCanData !== undefined) {
            return convertFaultRemark(currentCanData.evDtc?.breakPedalFault);
        }
        return "";
    };

    const getEvDtcSubBatteryStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SUB_BATTERY_STATUS_KEY) && currentCanData !== undefined) {
            return convertSysReadyRemark(currentCanData.evDtc?.subBatteryStatus);
        }
        return "";
    };

    const getEvDtcHvilStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HVIL_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.evDtc?.hvilStatus);
        }
        return "";
    };

    const getEvDtcVehicleStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_VEHICLE_STATUS_KEY) && currentCanData !== undefined) {
            return convertVehicleStatusRemark(currentCanData.evDtc?.vehicleStatus);
        }
        return "";
    };

    const getEvDtcHvestopStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HVESTOP_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvestopStatusRemark(currentCanData.evDtc?.hvestopStatus);
        }
        return "";
    };

    const getEvDtcSnowModeStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SNOW_MODE_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvestopStatusRemark(currentCanData.evDtc?.snowModeStatus);
        }
        return "";
    };

    const getEvDtcOutputLimitStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvestopStatusRemark(currentCanData.evDtc?.outputLimitStatus);
        }
        return "";
    };

    const getEvDtcHybridModeStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HYBRID_MODE_STATUS_KEY) && currentCanData !== undefined) {
            return convertHybridModeStatusRemark(currentCanData.evDtc?.hybridModeStatus);
        }
        return "";
    };

    const getEvDtcRegenerationStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_REGENERATION_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvestopStatusRemark(currentCanData.evDtc?.regenerationStatus);
        }
        return "";
    };

    const getEvDtcAntiSlipStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_ANTI_SLIP_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvestopStatusRemark(currentCanData.evDtc?.antiSlipStatus);
        }
        return "";
    };

    const getEvDtcDownhillAssistStatusValue = () => {
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvestopStatusRemark(currentCanData.evDtc?.downhillAssistStatus);
        }
        return "";
    };

    const onChangeEvDtc = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedEvDtc(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasEvDtc: Array<TypeUtils.ChartData> = [];

        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SYS_READY_KEY)) chartDatasEvDtc.push(chartEvDtcSysReady);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HVR_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcHvrStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SYS_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcSysStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MCU_TEMP_ALARM_KEY)) chartDatasEvDtc.push(chartEvDtcMcuTempAlarm);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SOC_ALARM_KEY)) chartDatasEvDtc.push(chartEvDtcSocAlarm);

        if (selectedEvDtc.includes(Common.TSECO_EVDTC_INSULATION_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcInsulationFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BMS_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcBmsFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_KEY)) chartDatasEvDtc.push(chartEvDtcMotorOverheatAlarm);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MOTOR_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcMotorFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcMcuCommunicationFault);

        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BATTERY_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcBatteryFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcBmsCommunicationFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_GEAR_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcGearFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_DCDC_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcDcdcFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_STEERING_DCAC_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcSteeringDcacFault);

        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BREAK_DCAC_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcBreakDcacFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcAccelPedalFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_KEY)) chartDatasEvDtc.push(chartEvDtcBreakPedalFault);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SUB_BATTERY_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcSubBatteryStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HVIL_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcHvilStatus);

        if (selectedEvDtc.includes(Common.TSECO_EVDTC_VEHICLE_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcVehicleStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HVESTOP_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcHvestopStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_SNOW_MODE_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcSnowModeStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcOutputLimitStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_HYBRID_MODE_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcHybridModeStatus);

        if (selectedEvDtc.includes(Common.TSECO_EVDTC_REGENERATION_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcRegenerationStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_ANTI_SLIP_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcAntiSlipStatus);
        if (selectedEvDtc.includes(Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_KEY)) chartDatasEvDtc.push(chartEvDtcDownhillAssistStatus);

        setSeriesChartDatasEvDtc(chartDatasEvDtc);
    }, [
        selectedEvDtc,
        chartEvDtcSysReady,
        chartEvDtcHvrStatus,
        chartEvDtcSysStatus,
        chartEvDtcMcuTempAlarm,
        chartEvDtcSocAlarm,

        chartEvDtcInsulationFault,
        chartEvDtcBmsFault,
        chartEvDtcMotorOverheatAlarm,
        chartEvDtcMotorFault,
        chartEvDtcMcuCommunicationFault,

        chartEvDtcBatteryFault,
        chartEvDtcBmsCommunicationFault,
        chartEvDtcGearFault,
        chartEvDtcDcdcFault,
        chartEvDtcSteeringDcacFault,

        chartEvDtcBreakDcacFault,
        chartEvDtcAccelPedalFault,
        chartEvDtcBreakPedalFault,
        chartEvDtcSubBatteryStatus,
        chartEvDtcHvilStatus,

        chartEvDtcVehicleStatus,
        chartEvDtcHvestopStatus,
        chartEvDtcSnowModeStatus,
        chartEvDtcOutputLimitStatus,
        chartEvDtcHybridModeStatus,

        chartEvDtcRegenerationStatus,
        chartEvDtcAntiSlipStatus,
        chartEvDtcDownhillAssistStatus,
    ]);

    const tableDataEvDtc: CanTableType[] = [
        {
            key: Common.TSECO_EVDTC_SYS_READY_KEY,
            name: Common.TSECO_EVDTC_SYS_READY_NAME,
            value: getEvDtcSysReadyValue(),
        },
        {
            key: Common.TSECO_EVDTC_HVR_STATUS_KEY,
            name: Common.TSECO_EVDTC_HVR_STATUS_NAME,
            value: getEvDtcHvrStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_SYS_STATUS_KEY,
            name: Common.TSECO_EVDTC_SYS_STATUS_NAME,
            value: getEvDtcSysStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_MCU_TEMP_ALARM_KEY,
            name: Common.TSECO_EVDTC_MCU_TEMP_ALARM_NAME,
            value: getEvDtcMcuTempAlarmValue(),
        },
        {
            key: Common.TSECO_EVDTC_SOC_ALARM_KEY,
            name: Common.TSECO_EVDTC_SOC_ALARM_NAME,
            value: getEvDtcSocAlarmValue(),
        },

        {
            key: Common.TSECO_EVDTC_INSULATION_FAULT_KEY,
            name: Common.TSECO_EVDTC_INSULATION_FAULT_NAME,
            value: getEvDtcInsulationFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_BMS_FAULT_KEY,
            name: Common.TSECO_EVDTC_BMS_FAULT_NAME,
            value: getEvDtcBmsFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_KEY,
            name: Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_NAME,
            value: getEvDtcMotorOverheatAlarmValue(),
        },
        {
            key: Common.TSECO_EVDTC_MOTOR_FAULT_KEY,
            name: Common.TSECO_EVDTC_MOTOR_FAULT_NAME,
            value: getEvDtcMotorFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_KEY,
            name: Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_NAME,
            value: getEvDtcMcuCommunicationFaultValue(),
        },

        {
            key: Common.TSECO_EVDTC_BATTERY_FAULT_KEY,
            name: Common.TSECO_EVDTC_BATTERY_FAULT_NAME,
            value: getEvDtcBatteryFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_KEY,
            name: Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_NAME,
            value: getEvDtcBmsCommunicationFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_GEAR_FAULT_KEY,
            name: Common.TSECO_EVDTC_GEAR_FAULT_NAME,
            value: getEvDtcGearFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_DCDC_FAULT_KEY,
            name: Common.TSECO_EVDTC_DCDC_FAULT_NAME,
            value: getEvDtcDcdcFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_STEERING_DCAC_FAULT_KEY,
            name: Common.TSECO_EVDTC_STEERING_DCAC_FAULT_NAME,
            value: getEvDtcSteeringDcacFaultValue(),
        },

        {
            key: Common.TSECO_EVDTC_BREAK_DCAC_FAULT_KEY,
            name: Common.TSECO_EVDTC_BREAK_DCAC_FAULT_NAME,
            value: getEvDtcBreakDcacFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_KEY,
            name: Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_NAME,
            value: getEvDtcAccelPedalFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_KEY,
            name: Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_NAME,
            value: getEvDtcBreakPedalFaultValue(),
        },
        {
            key: Common.TSECO_EVDTC_SUB_BATTERY_STATUS_KEY,
            name: Common.TSECO_EVDTC_SUB_BATTERY_STATUS_NAME,
            value: getEvDtcSubBatteryStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_HVIL_STATUS_KEY,
            name: Common.TSECO_EVDTC_HVIL_STATUS_NAME,
            value: getEvDtcHvilStatusValue(),
        },

        {
            key: Common.TSECO_EVDTC_VEHICLE_STATUS_KEY,
            name: Common.TSECO_EVDTC_VEHICLE_STATUS_NAME,
            value: getEvDtcVehicleStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_HVESTOP_STATUS_KEY,
            name: Common.TSECO_EVDTC_HVESTOP_STATUS_NAME,
            value: getEvDtcHvestopStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_SNOW_MODE_STATUS_KEY,
            name: Common.TSECO_EVDTC_SNOW_MODE_STATUS_NAME,
            value: getEvDtcSnowModeStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_KEY,
            name: Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_NAME,
            value: getEvDtcOutputLimitStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_HYBRID_MODE_STATUS_KEY,
            name: Common.TSECO_EVDTC_HYBRID_MODE_STATUS_NAME,
            value: getEvDtcHybridModeStatusValue(),
        },

        {
            key: Common.TSECO_EVDTC_REGENERATION_STATUS_KEY,
            name: Common.TSECO_EVDTC_REGENERATION_STATUS_NAME,
            value: getEvDtcRegenerationStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_ANTI_SLIP_STATUS_KEY,
            name: Common.TSECO_EVDTC_ANTI_SLIP_STATUS_NAME,
            value: getEvDtcAntiSlipStatusValue(),
        },
        {
            key: Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_KEY,
            name: Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_NAME,
            value: getEvDtcDownhillAssistStatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ContactorCmd
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [ccmPanelOpen, setCcmPanelOpen] = useState(false);
    const [ccmChartMounted, setCcmChartMounted] = useState(false);
    const [selectedCcm, setSelectedCcm] = useState<Array<React.Key>>([
        // Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_KEY,
        // Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_KEY,
        // Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_KEY,
        // Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_KEY,
        // Common.TSECO_CCM_DCDC_CONTACTOR_CMD_KEY,
        // Common.TSECO_CCM_HEATER2_CONTACTOR_CMD_KEY,
    ]);

    const [seriesChartDatasCcm, setSeriesChartDatasCcm] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCcmMainMinusContactorCmd, setChartCcmMainMinusContactorCmd] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_NAME,
        data: [],
    });

    const [chartCcmHeater1ContactorCmd, setChartCcmHeater1ContactorCmd] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_NAME,
        data: [],
    });

    const [chartCcmAirconContactorCmd, setChartCcmAirconContactorCmd] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_NAME,
        data: [],
    });

    const [chartCcmDefrosterContactorCmd, setChartCcmDefrosterContactorCmd] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_NAME,
        data: [],
    });

    const [chartCcmDcdcContactorCmd, setChartCcmDcdcContactorCmd] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CCM_DCDC_CONTACTOR_CMD_NAME,
        data: [],
    });

    const [chartCcmHeater2ContactorCmd, setChartCcmHeater2ContactorCmd] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CCM_HEATER2_CONTACTOR_CMD_NAME,
        data: [],
    });

    const getTabelTooltipCcm = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_KEY:
                return (
                    <>
                        <p>
                            접촉기 제어 메시지
                            <br />
                            CAN ID: 0x18FFa1f3
                            <br />
                            Name: 메인 마이너스 접촉기 제어 명령
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );
            case Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_KEY:
                return (
                    <>
                        <p>
                            접촉기 제어 메시지
                            <br />
                            CAN ID: 0x18FFa1f3
                            <br />
                            Name: 히터 1 접촉기 제어 명령
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );

            case Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_KEY:
                return (
                    <>
                        <p>
                            접촉기 제어 메시지
                            <br />
                            CAN ID: 0x18FFa1f3
                            <br />
                            Name: 에어컨 접촉기 제어 명령
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );

            case Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_KEY:
                return (
                    <>
                        <p>
                            접촉기 제어 메시지
                            <br />
                            CAN ID: 0x18FFa1f3
                            <br />
                            Name: 디프로스터 명령
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );

            case Common.TSECO_CCM_DCDC_CONTACTOR_CMD_KEY:
                return (
                    <>
                        <p>
                            접촉기 제어 메시지
                            <br />
                            CAN ID: 0x18FFa1f3
                            <br />
                            Name: DCDC접촉기 제어 명령
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );

            case Common.TSECO_CCM_HEATER2_CONTACTOR_CMD_KEY:
                return (
                    <>
                        <p>
                            접촉기 제어 메시지
                            <br />
                            CAN ID: 0x18FFa1f3
                            <br />
                            Name: 히터 2 접촉기 제어 명령
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );
        }

        return null;
    };

    const convertCcmRemark = (value: number) => {
        let valueString = "열림";
        switch (value) {
            case 0:
                valueString = "열림";
                break;
            case 1:
                valueString = "닫힘";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertSohRemark = (value: number) => {
        let valueString = value ? value + Common.UNIT_PERCENT : "-";
        switch (value) {
            case 255:
                valueString = "무효";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getCcmMainMinusContactorCmdValue = () => {
        if (selectedCcm.includes(Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.contactorCmd?.mainMinusContactorCmd);
        }
        return "";
    };

    const getCcmHeater1ContactorCmdValue = () => {
        if (selectedCcm.includes(Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.contactorCmd?.heater1ContactorCmd);
        }
        return "";
    };

    const getCcmAirconContactorCmdValue = () => {
        if (selectedCcm.includes(Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.contactorCmd?.airconContactorCmd);
        }
        return "";
    };

    const getCcmDefrosterContactorCmdValue = () => {
        if (selectedCcm.includes(Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.contactorCmd?.defrosterContactorCmd);
        }
        return "";
    };

    const getCcmDcdcContactorCmdValue = () => {
        if (selectedCcm.includes(Common.TSECO_CCM_DCDC_CONTACTOR_CMD_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.contactorCmd?.dcdcContactorCmd);
        }
        return "";
    };

    const getCcmHeater2ContactorCmdValue = () => {
        if (selectedCcm.includes(Common.TSECO_CCM_HEATER2_CONTACTOR_CMD_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.contactorCmd?.heater2ContactorCmd);
        }
        return "";
    };

    const onChangeCcm = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCcm(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCcm: Array<TypeUtils.ChartData> = [];

        if (selectedCcm.includes(Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_KEY)) chartDatasCcm.push(chartCcmMainMinusContactorCmd);
        if (selectedCcm.includes(Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_KEY)) chartDatasCcm.push(chartCcmHeater1ContactorCmd);
        if (selectedCcm.includes(Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_KEY)) chartDatasCcm.push(chartCcmAirconContactorCmd);
        if (selectedCcm.includes(Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_KEY)) chartDatasCcm.push(chartCcmDefrosterContactorCmd);
        if (selectedCcm.includes(Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_KEY)) chartDatasCcm.push(chartCcmDcdcContactorCmd);
        if (selectedCcm.includes(Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_KEY)) chartDatasCcm.push(chartCcmHeater2ContactorCmd);

        setSeriesChartDatasCcm(chartDatasCcm);
    }, [
        selectedCcm,
        chartCcmMainMinusContactorCmd,
        chartCcmHeater1ContactorCmd,
        chartCcmAirconContactorCmd,
        chartCcmDefrosterContactorCmd,
        chartCcmDcdcContactorCmd,
        chartCcmHeater2ContactorCmd,
    ]);

    const tableDataCcm: CanTableType[] = [
        {
            key: Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_KEY,
            name: Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_NAME,
            value: getCcmMainMinusContactorCmdValue(),
        },
        {
            key: Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_KEY,
            name: Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_NAME,
            value: getCcmHeater1ContactorCmdValue(),
        },
        {
            key: Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_KEY,
            name: Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_NAME,
            value: getCcmAirconContactorCmdValue(),
        },
        {
            key: Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_KEY,
            name: Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_NAME,
            value: getCcmDefrosterContactorCmdValue(),
        },
        {
            key: Common.TSECO_CCM_DCDC_CONTACTOR_CMD_KEY,
            name: Common.TSECO_CCM_DCDC_CONTACTOR_CMD_NAME,
            value: getCcmDcdcContactorCmdValue(),
        },
        {
            key: Common.TSECO_CCM_HEATER2_CONTACTOR_CMD_KEY,
            name: Common.TSECO_CCM_HEATER2_CONTACTOR_CMD_NAME,
            value: getCcmHeater2ContactorCmdValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BatInfo1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batInfo1PanelOpen, setBatInfo1PanelOpen] = useState(false);
    const [batInfo1ChartMounted, setBatInfo1ChartMounted] = useState(false);
    const [selectedBatInfo1, setSelectedBatInfo1] = useState<Array<React.Key>>([
        // Common.TSECO_BAT_INFO1_SOC_KEY,
        // Common.TSECO_BAT_INFO1_VOLT_KEY,
        // Common.TSECO_BAT_INFO1_CURR_KEY,
        // Common.TSECO_BAT_INFO1_SOH_KEY,
        // Common.TSECO_BAT_INFO1_CHARGE_STATUS_KEY,
        // Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_KEY,
        // Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_KEY,
        // Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_KEY,
    ]);

    const [seriesChartDatasBatInfo1, setSeriesChartDatasBatInfo1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatInfo1Soc, setChartBatInfo1Soc] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_SOC_NAME,
        data: [],
    });

    const [chartBatInfo1Volt, setChartBatInfo1Volt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_VOLT_NAME,
        data: [],
    });

    const [chartBatInfo1Curr, setChartBatInfo1Curr] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_CURR_NAME,
        data: [],
    });

    const [chartBatInfo1Soh, setChartBatInfo1Soh] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_SOH_NAME,
        data: [],
    });

    const [chartBatInfo1ChargeStatus, setChartBatInfo1ChargeStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_CHARGE_STATUS_NAME,
        data: [],
    });

    const [chartBatInfo1AcChargeConnect, setChartBatInfo1AcChargeConnect] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_NAME,
        data: [],
    });

    const [chartBatInfo1DcChargeConnect, setChartBatInfo1DcChargeConnect] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_NAME,
        data: [],
    });

    const [chartBatInfo1BatPackCount, setChartBatInfo1BatPackCount] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_NAME,
        data: [],
    });

    const getTabelTooltipBatInfo1 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_BAT_INFO1_SOC_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: 축전지 충전 상태(SOC)
                        </p>
                        <p>Remarks: 0~250</p>
                    </>
                );
            case Common.TSECO_BAT_INFO1_VOLT_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: 동력 배터리 총 전압
                        </p>
                        <p>Remarks: 0~65534</p>
                    </>
                );

            case Common.TSECO_BAT_INFO1_CURR_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: 동력 배터리 총 전류
                        </p>
                        <p>Remarks: 0~64000</p>
                    </>
                );

            case Common.TSECO_BAT_INFO1_SOH_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: 전원 배터리 건강 상태(SOH)
                        </p>
                        <p>Remarks: 0~250, 255=무효</p>
                    </>
                );

            case Common.TSECO_BAT_INFO1_CHARGE_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: 충전 상태
                        </p>
                        <p>Remarks: 1=정차충전, 2=주행충전, 3=미충전, 4=충전완료, 254=이상, 255=무효</p>
                    </>
                );

            case Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: AC 충전 연결
                        </p>
                        <p>Remarks: 0=미연결, 1=연결</p>
                    </>
                );

            case Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: DC 충전 연결
                        </p>
                        <p>Remarks: 0=미연결, 1=연결</p>
                    </>
                );

            case Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보1
                            <br />
                            CAN ID: 0x18F212F3
                            <br />
                            Name: 배터리 팩 병렬 수
                        </p>
                        <p>Remarks: 0=1, 1=2, 2=3, 3=4, 4=5, 5=6, 6=8, 7=10</p>
                    </>
                );
        }

        return null;
    };

    const convertSocStatusRemark = (value: number) => {
        let valueString = value ? value + Common.UNIT_VOLTAGE : "-";
        switch (value) {
            case 255:
            case 65535:
                valueString = "무효";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertCurrRemark = (value: number) => {
        let valueString = value ? value + Common.UNIT_CURRENT : "-";
        switch (value) {
            case 65535:
                valueString = "무효";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertBatInfo1ChargeConnectRemark = (value: number) => {
        let valueString = "미연결";
        switch (value) {
            case 0:
                valueString = "미연결";
                break;
            case 1:
                valueString = "연결";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertBatInfo1ChargeStatusRemark = (value: number) => {
        let valueString = "무효";
        switch (value) {
            case 1:
                valueString = "정차충전";
                break;
            case 2:
                valueString = "주행충전";
                break;
            case 3:
                valueString = "미충전";
                break;
            case 4:
                valueString = "충전완료";
                break;
            case 254:
                valueString = "이상";
                break;
            case 255:
                valueString = "무효";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertBatInfo1BatPackCountRemark = (value: number) => {
        let valueString = "1 병렬";
        switch (value) {
            case 0:
                valueString = "1 병렬";
                break;
            case 1:
                valueString = "2 병렬";
                break;
            case 2:
                valueString = "3 병렬";
                break;
            case 3:
                valueString = "4 병렬";
                break;
            case 4:
                valueString = "5 병렬";
                break;
            case 5:
                valueString = "6 병렬";
                break;
            case 6:
                valueString = "8 병렬";
                break;
            case 7:
                valueString = "10 병렬";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBatInfo1SocValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo1?.soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatInfo1VoltValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_VOLT_KEY) && currentCanData !== undefined) {
            return convertSocStatusRemark(currentCanData.batInfo1?.volt);
        }
        return "";
    };

    const getBatInfo1CurrValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_CURR_KEY) && currentCanData !== undefined) {
            return convertCurrRemark(currentCanData.batInfo1?.curr);
        }
        return "";
    };

    const getBatInfo1SohValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_SOH_KEY) && currentCanData !== undefined) {
            return convertSohRemark(currentCanData.batInfo1?.soh);
        }
        return "";
    };

    const getBatInfo1ChargeStatusValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_CHARGE_STATUS_KEY) && currentCanData !== undefined) {
            return convertBatInfo1ChargeStatusRemark(currentCanData.batInfo1?.chargeStatus);
        }
        return "";
    };

    const getBatInfo1AcChargeConnectValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_KEY) && currentCanData !== undefined) {
            return convertBatInfo1ChargeConnectRemark(currentCanData.batInfo1?.acChargeConnect);
        }
        return "";
    };

    const getBatInfo1DcChargeConnectValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_KEY) && currentCanData !== undefined) {
            return convertBatInfo1ChargeConnectRemark(currentCanData.batInfo1?.dcChargeConnect);
        }
        return "";
    };

    const getBatInfo1BatPackCountValue = () => {
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_KEY) && currentCanData !== undefined) {
            return convertBatInfo1BatPackCountRemark(currentCanData.batInfo1?.batPackCount);
        }
        return "";
    };

    const onChangeBatInfo1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatInfo1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatInfo1: Array<TypeUtils.ChartData> = [];

        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_SOC_KEY)) chartDatasBatInfo1.push(chartBatInfo1Soc);
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_VOLT_KEY)) chartDatasBatInfo1.push(chartBatInfo1Volt);
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_CURR_KEY)) chartDatasBatInfo1.push(chartBatInfo1Curr);
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_SOH_KEY)) chartDatasBatInfo1.push(chartBatInfo1Soh);
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_CHARGE_STATUS_KEY)) chartDatasBatInfo1.push(chartBatInfo1ChargeStatus);
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_KEY)) chartDatasBatInfo1.push(chartBatInfo1AcChargeConnect);
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_KEY)) chartDatasBatInfo1.push(chartBatInfo1DcChargeConnect);
        if (selectedBatInfo1.includes(Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_KEY)) chartDatasBatInfo1.push(chartBatInfo1BatPackCount);

        setSeriesChartDatasBatInfo1(chartDatasBatInfo1);
    }, [
        selectedBatInfo1,
        chartBatInfo1Soc,
        chartBatInfo1Volt,
        chartBatInfo1Curr,
        chartBatInfo1Soh,
        chartBatInfo1ChargeStatus,
        chartBatInfo1AcChargeConnect,
        chartBatInfo1DcChargeConnect,
        chartBatInfo1BatPackCount,
    ]);

    const tableDataBatInfo1: CanTableType[] = [
        {
            key: Common.TSECO_BAT_INFO1_SOC_KEY,
            name: Common.TSECO_BAT_INFO1_SOC_NAME,
            value: getBatInfo1SocValue(),
        },
        {
            key: Common.TSECO_BAT_INFO1_VOLT_KEY,
            name: Common.TSECO_BAT_INFO1_VOLT_NAME,
            value: getBatInfo1VoltValue(),
        },
        {
            key: Common.TSECO_BAT_INFO1_CURR_KEY,
            name: Common.TSECO_BAT_INFO1_CURR_NAME,
            value: getBatInfo1CurrValue(),
        },
        {
            key: Common.TSECO_BAT_INFO1_SOH_KEY,
            name: Common.TSECO_BAT_INFO1_SOH_NAME,
            value: getBatInfo1SohValue(),
        },
        {
            key: Common.TSECO_BAT_INFO1_CHARGE_STATUS_KEY,
            name: Common.TSECO_BAT_INFO1_CHARGE_STATUS_NAME,
            value: getBatInfo1ChargeStatusValue(),
        },
        {
            key: Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_KEY,
            name: Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_NAME,
            value: getBatInfo1AcChargeConnectValue(),
        },
        {
            key: Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_KEY,
            name: Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_NAME,
            value: getBatInfo1DcChargeConnectValue(),
        },
        {
            key: Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_KEY,
            name: Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_NAME,
            value: getBatInfo1BatPackCountValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region HvrStatus
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [hvrStatusPanelOpen, setHvrStatusPanelOpen] = useState(false);
    const [hvrStatusChartMounted, setHvrStatusChartMounted] = useState(false);
    const [selectedHvrStatus, setSelectedHvrStatus] = useState<Array<React.Key>>([
        // Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_AIRCON_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_HEATER1_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_HEATER2_HVR_STATUS_KEY,
        // Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_AIRCON_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_HEATER_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_KEY,
        // Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_KEY,
        // Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_KEY,
        // Common.TSECO_HVRS_PDU_HVIL_KEY,
        // Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_KEY,
    ]);

    const [seriesChartDatasHvrStatus, setSeriesChartDatasHvrStatus] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartHvrMainPlusHvrStatus, setChartMainPlusHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_NAME,
        data: [],
    });

    const [chartMainMinusHvrStatus, setChartMainMinusHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_NAME,
        data: [],
    });

    const [chartChargePlusHvrStatus, setChartChargePlusHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_NAME,
        data: [],
    });

    const [chartChargeMinusHvrStatus, setChartChargeMinusHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_NAME,
        data: [],
    });

    const [chartHeatingPlusHvrStatus, setChartHeatingPlusHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_NAME,
        data: [],
    });

    const [chartHeatingMinusHvrStatus, setChartHeatingMinusHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_NAME,
        data: [],
    });

    const [chartPreChargeHvrStatus, setChartPreChargeHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_NAME,
        data: [],
    });

    const [chartAirconHvrStatus, setChartAirconHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_AIRCON_HVR_STATUS_NAME,
        data: [],
    });

    const [chartDefrosterHvrStatus, setChartDefrosterHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_NAME,
        data: [],
    });

    const [chartHeater1HvrStatus, setChartHeater1HvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HEATER1_HVR_STATUS_NAME,
        data: [],
    });

    const [chartHeater2HvrStatus, setChartHeater2HvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HEATER2_HVR_STATUS_NAME,
        data: [],
    });

    const [chartMainPlusHvrAlarm, setChartMainPlusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartMainMinusHvrAlarm, setChartMainMinusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartChargePlusHvrAlarm, setChartChargePlusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartChargeMinusHvrAlarm, setChartChargeMinusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartHeatingPlusHvrAlarm, setChartHeatingPlusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartHeatingMinusHvrAlarm, setChartHeatingMinusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartPreChargeHvrAlarm, setChartPreChargeHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_NAME,
        data: [],
    });

    const [chartAirconHvrAlarm, setChartAirconHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_AIRCON_HVR_ALARM_NAME,
        data: [],
    });

    const [chartDefrosterHvrAlarm, setChartDefrosterHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_NAME,
        data: [],
    });

    const [chartHeaterHvrAlarm, setChartHeaterHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HEATER_HVR_ALARM_NAME,
        data: [],
    });

    const [chartCharge2PlusHvrAlarm, setChartCharge2PlusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartCharge2MinusHvrAlarm, setChartCharge2MinusHvrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_NAME,
        data: [],
    });

    const [chartChargePreChargeTimeOutAlarm, setChartChargePreChargeTimeOutAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_NAME,
        data: [],
    });

    const [chartCharge2PreChargeTimeOutAlarm, setChartCharge2PreChargeTimeOutAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_NAME,
        data: [],
    });

    const [chartPduHvil, setChartPduHvil] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_PDU_HVIL_NAME,
        data: [],
    });

    const [chartHvcmVcuCommunicationFault, setChartHvcmVcuCommunicationFault] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_NAME,
        data: [],
    });

    const getTabelTooltipHvrStatus = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 메인 플러스 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );
            case Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 메인 마이너스 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 충전 플러스 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 충전 마이너스 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히팅 플러스 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히팅 마이너스 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 프리차지 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_AIRCON_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 에어컨 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 디프로스터 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );
            case Common.TSECO_HVRS_HEATER1_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히터1 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_HEATER2_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히터2 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=차단, 1=흡착</p>
                    </>
                );

            case Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 메인 플러스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 메인 마이너스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 충전 플러스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 충전 마이너스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히팅 플러스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히팅 마이너스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 프리 차지 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_AIRCON_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 에어컨 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 디프로스터 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_HEATER_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히터 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 充电2 플러스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 충전2 마이너스 고압 릴레이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 충전 프리차지 시간 초과 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 충전2 프리차지 시간 초과 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_PDU_HVIL_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: PDU고압 인터락（전달）
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_KEY:
                return (
                    <>
                        <p>
                            고압 릴레이 상태
                            <br />
                            CAN ID: 0x18F211F3
                            <br />
                            Name: 히HVCM및VCU통신 고장（전달）
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
        }

        return null;
    };

    const getMainPlusHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.mainPlusHvrStatus);
        }
        return "";
    };

    const getMainMinusHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.mainMinusHvrStatus);
        }
        return "";
    };

    const getCargePlusHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.chargePlusHvrStatus);
        }
        return "";
    };

    const getChargeMinusHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.chargeMinusHvrStatus);
        }
        return "";
    };

    const getHeatingPlusHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.heatingPlusHvrStatus);
        }
        return "";
    };

    const getHatingMinusHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.heatingMinusHvrStatus);
        }
        return "";
    };

    const getPreChargeHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.preChargeHvrStatus);
        }
        return "";
    };

    const getAirconHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_AIRCON_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.airconHvrStatus);
        }
        return "";
    };

    const getDefrosterHvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.defrosterHvrStatus);
        }
        return "";
    };

    const getHeater1HvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATER1_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.heater1HvrStatus);
        }
        return "";
    };

    const getHeater2HvrStatusValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATER2_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertHvrStatusRemark(currentCanData.hvrStatus?.heater2HvrStatus);
        }
        return "";
    };

    const getMainPlusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.mainPlusHvrAlarm);
        }
        return "";
    };

    const getMainMinusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.mainMinusHvrAlarm);
        }
        return "";
    };

    const getChargePlusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.chargePlusHvrAlarm);
        }
        return "";
    };

    const getChargeMinusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.chargeMinusHvrAlarm);
        }
        return "";
    };

    const getHeatingPlusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.heatingPlusHvrAlarm);
        }
        return "";
    };

    const getHeatingMinusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.heatingMinusHvrAlarm);
        }
        return "";
    };

    const getPreChargeHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.preChargeHvrAlarm);
        }
        return "";
    };

    const getAirconHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_AIRCON_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.airconHvrAlarm);
        }
        return "";
    };

    const getDefrosterHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.defrosterHvrAlarm);
        }
        return "";
    };

    const getHeaterHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATER_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.heaterHvrAlarm);
        }
        return "";
    };

    const getCharge2PlusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.charge2PlusHvrAlarm);
        }
        return "";
    };

    const getCharge2MinusHvrAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.charge2MinusHvrAlarm);
        }
        return "";
    };

    const getChargePreChargeTimeOutAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.chargePreChargeTimeOutAlarm);
        }
        return "";
    };

    const getCharge2PreChargeTimeOutAlarmValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.charge2PreChargeTimeOutAlarm);
        }
        return "";
    };

    const getPduHvilValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_PDU_HVIL_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.pduHvil);
        }
        return "";
    };

    const getHvcmVcuCommunicationFaultValue = () => {
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.hvrStatus?.hvcmVcuCommunicationFault);
        }
        return "";
    };

    const onChangeHvrStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedHvrStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasHvrStatus: Array<TypeUtils.ChartData> = [];

        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartHvrMainPlusHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartMainMinusHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartChargePlusHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartChargeMinusHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartHeatingPlusHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartHeatingMinusHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartPreChargeHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_AIRCON_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartAirconHvrStatus);

        if (selectedHvrStatus.includes(Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartDefrosterHvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATER1_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartHeater1HvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATER2_HVR_STATUS_KEY)) chartDatasHvrStatus.push(chartHeater2HvrStatus);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartMainPlusHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartMainMinusHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartChargePlusHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartChargeMinusHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartHeatingPlusHvrAlarm);

        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartHeatingMinusHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartPreChargeHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_AIRCON_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartAirconHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartDefrosterHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HEATER_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartHeaterHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartCharge2PlusHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_KEY)) chartDatasHvrStatus.push(chartCharge2MinusHvrAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_KEY))
            chartDatasHvrStatus.push(chartChargePreChargeTimeOutAlarm);

        if (selectedHvrStatus.includes(Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_KEY))
            chartDatasHvrStatus.push(chartCharge2PreChargeTimeOutAlarm);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_PDU_HVIL_KEY)) chartDatasHvrStatus.push(chartPduHvil);
        if (selectedHvrStatus.includes(Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_KEY)) chartDatasHvrStatus.push(chartHvcmVcuCommunicationFault);

        setSeriesChartDatasHvrStatus(chartDatasHvrStatus);
    }, [
        selectedHvrStatus,
        chartHvrMainPlusHvrStatus,
        chartMainMinusHvrStatus,
        chartChargePlusHvrStatus,
        chartChargeMinusHvrStatus,
        chartHeatingPlusHvrStatus,
        chartHeatingMinusHvrStatus,
        chartPreChargeHvrStatus,
        chartAirconHvrStatus,
        chartDefrosterHvrStatus,
        chartHeater1HvrStatus,
        chartHeater2HvrStatus,
        chartMainPlusHvrAlarm,
        chartMainMinusHvrAlarm,
        chartChargePlusHvrAlarm,
        chartChargeMinusHvrAlarm,
        chartHeatingPlusHvrAlarm,
        chartHeatingMinusHvrAlarm,
        chartPreChargeHvrAlarm,
        chartAirconHvrAlarm,
        chartDefrosterHvrAlarm,
        chartHeaterHvrAlarm,
        chartCharge2PlusHvrAlarm,
        chartCharge2MinusHvrAlarm,
        chartChargePreChargeTimeOutAlarm,
        chartCharge2PreChargeTimeOutAlarm,
        chartPduHvil,
        chartHvcmVcuCommunicationFault,
    ]);

    const tableDataHvrStatus: CanTableType[] = [
        {
            key: Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_NAME,
            value: getMainPlusHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_NAME,
            value: getMainMinusHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_NAME,
            value: getCargePlusHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_NAME,
            value: getChargeMinusHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_NAME,
            value: getHeatingPlusHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_NAME,
            value: getHatingMinusHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_NAME,
            value: getPreChargeHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_AIRCON_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_AIRCON_HVR_STATUS_NAME,
            value: getAirconHvrStatusValue(),
        },

        {
            key: Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_NAME,
            value: getDefrosterHvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_HEATER1_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_HEATER1_HVR_STATUS_NAME,
            value: getHeater1HvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_HEATER2_HVR_STATUS_KEY,
            name: Common.TSECO_HVRS_HEATER2_HVR_STATUS_NAME,
            value: getHeater2HvrStatusValue(),
        },
        {
            key: Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_NAME,
            value: getMainPlusHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_NAME,
            value: getMainMinusHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_NAME,
            value: getChargePlusHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_NAME,
            value: getChargeMinusHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_NAME,
            value: getHeatingPlusHvrAlarmValue(),
        },

        {
            key: Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_NAME,
            value: getHeatingMinusHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_NAME,
            value: getPreChargeHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_AIRCON_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_AIRCON_HVR_ALARM_NAME,
            value: getAirconHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_NAME,
            value: getDefrosterHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_HEATER_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_HEATER_HVR_ALARM_NAME,
            value: getHeaterHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_NAME,
            value: getCharge2PlusHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_KEY,
            name: Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_NAME,
            value: getCharge2MinusHvrAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_KEY,
            name: Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_NAME,
            value: getChargePreChargeTimeOutAlarmValue(),
        },

        {
            key: Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_KEY,
            name: Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_NAME,
            value: getCharge2PreChargeTimeOutAlarmValue(),
        },
        {
            key: Common.TSECO_HVRS_PDU_HVIL_KEY,
            name: Common.TSECO_HVRS_PDU_HVIL_NAME,
            value: getPduHvilValue(),
        },
        {
            key: Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_KEY,
            name: Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_NAME,
            value: getHvcmVcuCommunicationFaultValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BatLimit
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batLimitPanelOpen, setBatLimitPanelOpen] = useState(false);
    const [batLimitChartMounted, setBatLimitChartMounted] = useState(false);
    const [selectedBatLimit, setSelectedBatLimit] = useState<Array<React.Key>>([
        // Common.TSECO_BL_MIN_VOLT_KEY,
        // Common.TSECO_BL_MAX_VOLT_KEY,
        // Common.TSECO_BL_MIN_CURR_KEY,
        // Common.TSECO_BL_MAX_CURR_KEY,
    ]);

    const [seriesChartDatasBatLimit, setSeriesChartDatasBatLimit] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatLimitMinVolt, setChartBatLimitMinVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BL_MIN_VOLT_NAME,
        data: [],
    });

    const [chartBatLimitMaxVolt, setChartBatLimitMaxVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BL_MAX_VOLT_NAME,
        data: [],
    });

    const [chartBatLimitMinCurr, setChartBatLimitMinCurr] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BL_MIN_CURR_NAME,
        data: [],
    });

    const [chartBatLimitMaxCurr, setChartBatLimitMaxCurr] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BL_MAX_CURR_NAME,
        data: [],
    });

    const getTabelTooltipBatLimit = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_BL_MIN_VOLT_KEY:
                return (
                    <>
                        <p>
                            동력 배터리 기본 정보2-한계값
                            <br />
                            CAN ID: 0x18F213F3
                            <br />
                            Name: 배터리 팩 최저 방전 전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );
            case Common.TSECO_BL_MAX_VOLT_KEY:
                return (
                    <>
                        <p>
                            동력 배터리 기본 정보2-한계값
                            <br />
                            CAN ID: 0x18F213F3
                            <br />
                            Name: 배터리 팩 최고 충전 전압
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.TSECO_BL_MIN_CURR_KEY:
                return (
                    <>
                        <p>
                            동력 배터리 기본 정보2-한계값
                            <br />
                            CAN ID: 0x18F213F3
                            <br />
                            Name: 배터리 팩 최대 방전 전류
                        </p>
                        <p>Remarks: A</p>
                    </>
                );

            case Common.TSECO_BL_MAX_CURR_KEY:
                return (
                    <>
                        <p>
                            동력 배터리 기본 정보2-한계값
                            <br />
                            CAN ID: 0x18F213F3
                            <br />
                            Name: 배터리 팩 최대 충전 전류
                        </p>
                        <p>Remarks: A</p>
                    </>
                );
        }

        return null;
    };

    const getBatLimitBatLimitMinVoltValue = () => {
        if (selectedBatLimit.includes(Common.TSECO_BL_MIN_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit?.minVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatLimitBatLimitMaxVoltValue = () => {
        if (selectedBatLimit.includes(Common.TSECO_BL_MIN_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit?.maxVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatLimitBatLimitMinCurrValue = () => {
        if (selectedBatLimit.includes(Common.TSECO_BL_MIN_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit?.minCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBatLimitBatLimitMaxCurrValue = () => {
        if (selectedBatLimit.includes(Common.TSECO_BL_MAX_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit?.maxCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeBatLimit = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatLimit(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatLimit: Array<TypeUtils.ChartData> = [];

        if (selectedBatLimit.includes(Common.TSECO_BL_MIN_VOLT_KEY)) chartDatasBatLimit.push(chartBatLimitMinVolt);
        if (selectedBatLimit.includes(Common.TSECO_BL_MAX_VOLT_KEY)) chartDatasBatLimit.push(chartBatLimitMaxVolt);
        if (selectedBatLimit.includes(Common.TSECO_BL_MIN_CURR_KEY)) chartDatasBatLimit.push(chartBatLimitMinCurr);
        if (selectedBatLimit.includes(Common.TSECO_BL_MAX_CURR_KEY)) chartDatasBatLimit.push(chartBatLimitMaxCurr);

        setSeriesChartDatasBatLimit(chartDatasBatLimit);
    }, [selectedBatLimit, chartBatLimitMinVolt, chartBatLimitMaxVolt, chartBatLimitMinCurr, chartBatLimitMaxCurr]);

    const tableDataBatLimit: CanTableType[] = [
        {
            key: Common.TSECO_BL_MIN_VOLT_KEY,
            name: Common.TSECO_BL_MIN_VOLT_NAME,
            value: getBatLimitBatLimitMinVoltValue(),
        },
        {
            key: Common.TSECO_BL_MAX_VOLT_KEY,
            name: Common.TSECO_BL_MAX_VOLT_NAME,
            value: getBatLimitBatLimitMaxVoltValue(),
        },
        {
            key: Common.TSECO_BL_MIN_CURR_KEY,
            name: Common.TSECO_BL_MIN_CURR_NAME,
            value: getBatLimitBatLimitMinCurrValue(),
        },
        {
            key: Common.TSECO_BL_MAX_CURR_KEY,
            name: Common.TSECO_BL_MAX_CURR_NAME,
            value: getBatLimitBatLimitMaxCurrValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BatFaultAlarm
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batFaultAlarmPanelOpen, setBatFaultAlarmPanelOpen] = useState(false);
    const [batFaultAlarmChartMounted, setBatFaultAlarmChartMounted] = useState(false);
    const [selectedBatFaultAlarm, setSelectedBatFaultAlarm] = useState<Array<React.Key>>([
        // Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_KEY,
        // Common.TSECO_BFA_HIGH_TEMP_ALARM_KEY,
        // Common.TSECO_BFA_TEMP_DIFF_ALARM_KEY,
        // Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_KEY,
        // Common.TSECO_BFA_HVIL_FAULT_ALARM_KEY,
        // Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_KEY,
        // Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_KEY,
        // Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_KEY,
        // Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_KEY,
        // Common.TSECO_BFA_CHARGING_CURR_ALARM_KEY,
        // Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_KEY,
        // Common.TSECO_BFA_CELL_OVERVOLT_ALARM_KEY,
        // Common.TSECO_BFA_LOW_SOC_ALARM_KEY,
        // Common.TSECO_BFA_CHARGE_FAULT_ALARM_KEY,
        // Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_KEY,
        // Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_KEY,
        // Common.TSECO_BFA_BAT_PACK12_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK11_STATUS_KEY,
        // Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_KEY,
        // Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_KEY,
        // Common.TSECO_BFA_BAT_PACK10_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK9_STATUS_KEY,
        // Common.TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_KEY,
        // Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_KEY,
        // Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_KEY,
        // Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_KEY,
        // Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_KEY,
        // Common.TSECO_BFA_BAT_STATUS_KEY,
        // Common.TSECO_BFA_BAT_DISCHARGE_STATUS_KEY,
        // Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_KEY,
        // Common.TSECO_BFA_BALANCING_STATUS_KEY,
        // Common.TSECO_BFA_BALANCING_ALARM_STATUS_KEY,
        // Common.TSECO_BFA_HIGH_SOC_ALARM_KEY,
        // Common.TSECO_BFA_SOC_JUMP_ALARM_KEY,
        // Common.TSECO_BFA_BAT_FORECAST_KEY,
        // Common.TSECO_BFA_BAT_PACK1_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK2_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK3_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK4_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK5_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK6_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK7_STATUS_KEY,
        // Common.TSECO_BFA_BAT_PACK8_STATUS_KEY,
    ]);

    const [seriesChartDatasBatFaultAlarm, setSeriesChartDatasBatFaultAlarm] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatFaultAlarmHvShortCircuitAlarm, setChartBatFaultAlarmHvShortCircuitAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmHighTempAlarm, setChartBatFaultAlarmHighTempAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_HIGH_TEMP_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmTempDiffAlarm, setChartBatFaultAlarmTempDiffAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_TEMP_DIFF_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBranchCircuitBreakFaultAlarm, setChartBatFaultAlarmBranchCircuitBreakFaultAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmHvilFaultAlarm, setChartBatFaultAlarmHvilFaultAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_HVIL_FAULT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmDischargeCurrentAlarm, setChartBatFaultAlarmDischargeCurrentAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmCurrSensorFaultAlarm, setChartBatFaultAlarmCurrSensorFaultAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmRegenerationChargeCurrAlarm, setChartBatFaultAlarmRegenerationChargeCurrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmHeatingBranchFaultAlarm, setChartBatFaultAlarmHeatingBranchFaultAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmChargingCurrAlarm, setChartBatFaultAlarmChargingCurrAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CHARGING_CURR_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPackOvervoltAlarm, setChartBatFaultAlarmBatPackOvervoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmCellOvervoltAlarm, setChartBatFaultAlarmCellOvervoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CELL_OVERVOLT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmLowSocAlarm, setChartBatFaultAlarmLowSocAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_LOW_SOC_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmChargeFaultAlarm, setChartBatFaultAlarmChargeFaultAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CHARGE_FAULT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPackLowVoltAlarm, setChartBatFaultAlarmBatPackLowVoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmCellLowVoltAlarm, setChartBatFaultAlarmCellLowVoltAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack12Status, setChartBatFaultAlarmBatPack12Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK12_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack11Status, setChartBatFaultAlarmBatPack11Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK11_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatLowTempAlarm, setChartBatFaultAlarmBatLowTempAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmCellVoltDiffAlarm, setChartBatFaultAlarmCellVoltDiffAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack10Status, setChartBatFaultAlarmBatPack10Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK10_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack9Status, setChartBatFaultAlarmBatPack9Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK9_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmCommunicationLifecycleCalculator, setChartBatFaultAlarmCommunicationLifecycleCalculator] = useState<TypeUtils.ChartData>(
        {
            name: Common.TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_NAME,
            data: [],
        }
    );
    const [chartBatFaultAlarmLecuCommunicationAlarm, setChartBatFaultAlarmLecuCommunicationAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmChargerCommunicationAlarm, setChartBatFaultAlarmChargerCommunicationAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmVehicleCommunicationAlarm, setChartBatFaultAlarmVehicleCommunicationAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBmsLowVoltagePowerFaultAlarm, setChartBatFaultAlarmBmsLowVoltagePowerFaultAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatStatus, setChartBatFaultAlarmBatStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatDischargeStatus, setChartBatFaultAlarmBatDischargeStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_DISCHARGE_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmChargeSavingSysAlarm, setChartBatFaultAlarmChargeSavingSysAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBalancingStatus, setChartBatFaultAlarmBalancingStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BALANCING_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBalancingAlarmStatus, setChartBatFaultAlarmBalancingAlarmStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BALANCING_ALARM_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmHighSocAlarm, setChartBatFaultAlarmHighSocAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_HIGH_SOC_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmSocJumpAlarm, setChartBatFaultAlarmSocJumpAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_SOC_JUMP_ALARM_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatForecast, setChartBatFaultAlarmBatForecast] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_FORECAST_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack1Status, setChartBatFaultAlarmBatPack1Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK1_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack2Status, setChartBatFaultAlarmBatPack2Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK2_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack3Status, setChartBatFaultAlarmBatPack3Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK3_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack4Status, setChartBatFaultAlarmBatPack4Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK4_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack5Status, setChartBatFaultAlarmBatPack5Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK5_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack6Status, setChartBatFaultAlarmBatPack6Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK6_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack7Status, setChartBatFaultAlarmBatPack7Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK7_STATUS_NAME,
        data: [],
    });
    const [chartBatFaultAlarmBatPack8Status, setChartBatFaultAlarmBatPack8Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BFA_BAT_PACK8_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipBatFaultAlarm = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 고전압 누전 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );
            case Common.TSECO_BFA_HIGH_TEMP_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 고온 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_TEMP_DIFF_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 온도 차이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 분기 회로 차단 고장 경보
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_HVIL_FAULT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 고압 배터리 인터락 고장 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 방전 전류 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 전류 센서 고장
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 회생 충전 전류 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 히팅막 히팅 회로 고장
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_CHARGING_CURR_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 충전 전류 알람
                        </p>
                        <p>Remarks: 0=정상, 1=충전 전류 한계 초과 알람, 2=충전시 방전 전류 과다 , 3=미정의</p>
                    </>
                );

            case Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 과전압 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_CELL_OVERVOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 셀 과전압 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_LOW_SOC_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: SOC낮음 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_CHARGE_FAULT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 충전 고장 알람
                        </p>
                        <p>Remarks: 0=정상, 1=충전 마운트 과온 경미 고장, 2=충전 마운트 과온 일반 고장 알람 , 3=충전 마운트 NTC 고장</p>
                    </>
                );

            case Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리팩 전압 부족 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 셀 전압 부족 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_BAT_PACK12_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 12상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK11_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 11상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 온도 부족 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 셀 전압 차이 알람
                        </p>
                        <p>Remarks: 0=정상, 1=경미고장알람, 2=일반고장알람 , 3=심각고장알람</p>
                    </>
                );

            case Common.TSECO_BFA_BAT_PACK10_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 10상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_BAT_PACK9_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩9상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 통신 생명 주기 계산기
                        </p>
                        <p>Remarks: 0~15</p>
                    </>
                );

            case Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: LECU와 통신 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 충전기 통신 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 차량 통신 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: BMS저전압 전원 전압 이상 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 동력 배터리 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_DISCHARGE_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 동력 배터리 방전 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 충전 저장 시스템 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BALANCING_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 밸런싱 상태
                        </p>
                        <p>Remarks: 0=밸런싱 OFF, 1=밸런싱 ON</p>
                    </>
                );
            case Common.TSECO_BFA_BALANCING_ALARM_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 밸런싱 알람 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_HIGH_SOC_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: SOC높음 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_SOC_JUMP_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: SOC점프 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_FORECAST_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 동력 배터리 예보
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BFA_BAT_PACK1_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 1 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK2_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 2 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK3_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 3 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK4_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 4 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK5_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 5 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK6_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 6 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK7_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 7 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
            case Common.TSECO_BFA_BAT_PACK8_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 고장 알람정보
                            <br />
                            CAN ID: 0x18F214F3
                            <br />
                            Name: 배터리 팩 8 상태
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );
        }

        return null;
    };

    const convertHvShortCircuitAlarmRemark = (value: number) => {
        let valueString = "정상";
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "경미고장알람";
                break;
            case 2:
                valueString = "일반고장알람";
                break;
            case 3:
                valueString = "심각고장알람";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertChargeFaultAlarmRemark = (value: number) => {
        let valueString = "정상";
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "충전 마운트 과온 경미 고장 알람";
                break;
            case 2:
                valueString = "충전 마운트 과온 일반 고장 알람";
                break;
            case 3:
                valueString = "충전 마운트 NTC고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertBalancingStatusRemark = (value: number) => {
        let valueString = "밸런싱 off";
        switch (value) {
            case 0:
                valueString = "밸런싱 off";
                break;
            case 1:
                valueString = "밸런싱 on";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getHvShortCircuitAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.hvShortCircuitAlarm);
        }
        return "";
    };

    const getHighTempAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HIGH_TEMP_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.highTempAlarm);
        }
        return "";
    };

    const getTempDiffAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_TEMP_DIFF_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.tempDiffAlarm);
        }
        return "";
    };

    const getBranchCircuitBreakFaultAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.branchCircuitBreakFaultAlarm);
        }
        return "";
    };

    const getHvilFaultAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HVIL_FAULT_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.hvilFaultAlarm);
        }
        return "";
    };

    const getDischargeCurrentAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.dischargeCurrentAlarm);
        }
        return "";
    };

    const getCurrSensorFaultAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.currSensorFaultAlarm);
        }
        return "";
    };

    const getRegenerationChargeCurrAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.regenerationChargeCurrAlarm);
        }
        return "";
    };

    const getHeatingBranchFaultAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.heatingBranchFaultAlarm);
        }
        return "";
    };

    const getChargingCurrAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGING_CURR_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.chargingCurrAlarm);
        }
        return "";
    };

    const getBatPackOvervoltAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.batPackOvervoltAlarm);
        }
        return "";
    };

    const getCellOvervoltAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CELL_OVERVOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.cellOvervoltAlarm);
        }
        return "";
    };

    const getLowSocAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_LOW_SOC_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.lowSocAlarm);
        }
        return "";
    };

    const getChargeFaultAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGE_FAULT_ALARM_KEY) && currentCanData !== undefined) {
            return convertChargeFaultAlarmRemark(currentCanData.batFaultAlarm?.chargeFaultAlarm);
        }
        return "";
    };

    const getBatPackLowVoltAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.batPackLowVoltAlarm);
        }
        return "";
    };

    const getCellLowVoltAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.cellLowVoltAlarm);
        }
        return "";
    };

    const getBatPack12StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK12_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack12Status);
        }
        return "";
    };

    const getBatPack11StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK11_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack11Status);
        }
        return "";
    };

    const getBatLowTempAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.batLowTempAlarm);
        }
        return "";
    };

    const getCellVoltDiffAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_KEY) && currentCanData !== undefined) {
            return convertHvShortCircuitAlarmRemark(currentCanData.batFaultAlarm?.cellVoltDiffAlarm);
        }
        return "";
    };

    const getBatPack10StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK10_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack10Status);
        }
        return "";
    };

    const getBatPack9StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK9_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack9Status);
        }
        return "";
    };

    const getCommunicationLifecycleCalculatorValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batFaultAlarm?.communicationLifecycleCalculator, "");
        }
        return "";
    };

    const getLecuCommunicationAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.lecuCommunicationAlarm);
        }
        return "";
    };

    const getChargerCommunicationAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.chargerCommunicationAlarm);
        }
        return "";
    };

    const getVehicleCommunicationAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.vehicleCommunicationAlarm);
        }
        return "";
    };

    const getBmsLowVoltagePowerFaultAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.bmsLowVoltagePowerFaultAlarm);
        }
        return "";
    };

    const getBatStatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batStatus);
        }
        return "";
    };

    const getBatDischargeStatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_DISCHARGE_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batDischargeStatus);
        }
        return "";
    };

    const getChargeSavingSysAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.chargeSavingSysAlarm);
        }
        return "";
    };

    const getBalancingStatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BALANCING_STATUS_KEY) && currentCanData !== undefined) {
            return convertBalancingStatusRemark(currentCanData.batFaultAlarm?.balancingStatus);
        }
        return "";
    };

    const getBalancingAlarmStatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BALANCING_ALARM_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.balancingAlarmStatus);
        }
        return "";
    };

    const getHighSocAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HIGH_SOC_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.highSocAlarm);
        }
        return "";
    };

    const getSocJumpAlarmValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_SOC_JUMP_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.socJumpAlarm);
        }
        return "";
    };

    const getBatForecastValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_FORECAST_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batForecast);
        }
        return "";
    };

    const getBatPack1StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK1_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack1Status);
        }
        return "";
    };

    const getBatPack2StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK2_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack2Status);
        }
        return "";
    };

    const getBatPack3StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK3_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack3Status);
        }
        return "";
    };

    const getBatPack4StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK4_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack4Status);
        }
        return "";
    };

    const getBatPack5StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK5_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack5Status);
        }
        return "";
    };

    const getBatPack6StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK6_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack6Status);
        }
        return "";
    };

    const getBatPack7StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK7_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack7Status);
        }
        return "";
    };

    const getBatPack8StatusValue = () => {
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK8_STATUS_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batFaultAlarm?.batPack8Status);
        }
        return "";
    };

    const onChangeBatFaultAlarm = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatFaultAlarm(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatFaultAlarm: Array<TypeUtils.ChartData> = [];

        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmHvShortCircuitAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HIGH_TEMP_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmHighTempAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_TEMP_DIFF_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmTempDiffAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmBranchCircuitBreakFaultAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HVIL_FAULT_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmHvilFaultAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmDischargeCurrentAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmCurrSensorFaultAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmRegenerationChargeCurrAlarm);

        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmHeatingBranchFaultAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGING_CURR_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmChargingCurrAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPackOvervoltAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CELL_OVERVOLT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmCellOvervoltAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_LOW_SOC_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmLowSocAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGE_FAULT_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmChargeFaultAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPackLowVoltAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmCellLowVoltAlarm);

        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK12_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack12Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK11_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack11Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatLowTempAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmCellVoltDiffAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK10_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack10Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK9_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack9Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmCommunicationLifecycleCalculator);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmLecuCommunicationAlarm);

        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmChargerCommunicationAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmVehicleCommunicationAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmBmsLowVoltagePowerFaultAlarm);

        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatStatus);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_DISCHARGE_STATUS_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatDischargeStatus);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmChargeSavingSysAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BALANCING_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBalancingStatus);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BALANCING_ALARM_STATUS_KEY))
            chartDatasBatFaultAlarm.push(chartBatFaultAlarmBalancingAlarmStatus);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_HIGH_SOC_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmHighSocAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_SOC_JUMP_ALARM_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmSocJumpAlarm);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_FORECAST_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatForecast);

        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK1_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack1Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK2_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack2Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK3_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack3Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK4_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack4Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK5_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack5Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK6_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack6Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK7_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack7Status);
        if (selectedBatFaultAlarm.includes(Common.TSECO_BFA_BAT_PACK8_STATUS_KEY)) chartDatasBatFaultAlarm.push(chartBatFaultAlarmBatPack8Status);

        setSeriesChartDatasBatFaultAlarm(chartDatasBatFaultAlarm);
    }, [
        selectedBatFaultAlarm,
        chartBatFaultAlarmHvShortCircuitAlarm,
        chartBatFaultAlarmHighTempAlarm,
        chartBatFaultAlarmTempDiffAlarm,
        chartBatFaultAlarmBranchCircuitBreakFaultAlarm,
        chartBatFaultAlarmHvilFaultAlarm,
        chartBatFaultAlarmDischargeCurrentAlarm,
        chartBatFaultAlarmCurrSensorFaultAlarm,
        chartBatFaultAlarmRegenerationChargeCurrAlarm,
        chartBatFaultAlarmHeatingBranchFaultAlarm,
        chartBatFaultAlarmChargingCurrAlarm,
        chartBatFaultAlarmBatPackOvervoltAlarm,
        chartBatFaultAlarmCellOvervoltAlarm,
        chartBatFaultAlarmLowSocAlarm,
        chartBatFaultAlarmChargeFaultAlarm,
        chartBatFaultAlarmBatPackLowVoltAlarm,
        chartBatFaultAlarmCellLowVoltAlarm,
        chartBatFaultAlarmBatPack12Status,
        chartBatFaultAlarmBatPack11Status,
        chartBatFaultAlarmBatLowTempAlarm,
        chartBatFaultAlarmCellVoltDiffAlarm,
        chartBatFaultAlarmBatPack10Status,
        chartBatFaultAlarmBatPack9Status,
        chartBatFaultAlarmCommunicationLifecycleCalculator,
        chartBatFaultAlarmLecuCommunicationAlarm,
        chartBatFaultAlarmChargerCommunicationAlarm,
        chartBatFaultAlarmVehicleCommunicationAlarm,
        chartBatFaultAlarmBmsLowVoltagePowerFaultAlarm,
        chartBatFaultAlarmBatStatus,
        chartBatFaultAlarmBatDischargeStatus,
        chartBatFaultAlarmChargeSavingSysAlarm,
        chartBatFaultAlarmBalancingStatus,
        chartBatFaultAlarmBalancingAlarmStatus,
        chartBatFaultAlarmHighSocAlarm,
        chartBatFaultAlarmSocJumpAlarm,
        chartBatFaultAlarmBatForecast,
        chartBatFaultAlarmBatPack1Status,
        chartBatFaultAlarmBatPack2Status,
        chartBatFaultAlarmBatPack3Status,
        chartBatFaultAlarmBatPack4Status,
        chartBatFaultAlarmBatPack5Status,
        chartBatFaultAlarmBatPack6Status,
        chartBatFaultAlarmBatPack7Status,
        chartBatFaultAlarmBatPack8Status,
    ]);

    const tableDataBatFaultAlarm: CanTableType[] = [
        {
            key: Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_KEY,
            name: Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_NAME,
            value: getHvShortCircuitAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_HIGH_TEMP_ALARM_KEY,
            name: Common.TSECO_BFA_HIGH_TEMP_ALARM_NAME,
            value: getHighTempAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_TEMP_DIFF_ALARM_KEY,
            name: Common.TSECO_BFA_TEMP_DIFF_ALARM_NAME,
            value: getTempDiffAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_KEY,
            name: Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_NAME,
            value: getBranchCircuitBreakFaultAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_HVIL_FAULT_ALARM_KEY,
            name: Common.TSECO_BFA_HVIL_FAULT_ALARM_NAME,
            value: getHvilFaultAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_KEY,
            name: Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_NAME,
            value: getDischargeCurrentAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_KEY,
            name: Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_NAME,
            value: getCurrSensorFaultAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_KEY,
            name: Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_NAME,
            value: getRegenerationChargeCurrAlarmValue(),
        },

        {
            key: Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_KEY,
            name: Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_NAME,
            value: getHeatingBranchFaultAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_CHARGING_CURR_ALARM_KEY,
            name: Common.TSECO_BFA_CHARGING_CURR_ALARM_NAME,
            value: getChargingCurrAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_KEY,
            name: Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_NAME,
            value: getBatPackOvervoltAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_CELL_OVERVOLT_ALARM_KEY,
            name: Common.TSECO_BFA_CELL_OVERVOLT_ALARM_NAME,
            value: getCellOvervoltAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_LOW_SOC_ALARM_KEY,
            name: Common.TSECO_BFA_LOW_SOC_ALARM_NAME,
            value: getLowSocAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_CHARGE_FAULT_ALARM_KEY,
            name: Common.TSECO_BFA_CHARGE_FAULT_ALARM_NAME,
            value: getChargeFaultAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_KEY,
            name: Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_NAME,
            value: getBatPackLowVoltAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_KEY,
            name: Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_NAME,
            value: getCellLowVoltAlarmValue(),
        },

        {
            key: Common.TSECO_BFA_BAT_PACK12_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK12_STATUS_NAME,
            value: getBatPack12StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK11_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK11_STATUS_NAME,
            value: getBatPack11StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_KEY,
            name: Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_NAME,
            value: getBatLowTempAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_KEY,
            name: Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_NAME,
            value: getCellVoltDiffAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK10_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK10_STATUS_NAME,
            value: getBatPack10StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK9_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK9_STATUS_NAME,
            value: getBatPack9StatusValue(),
        },
        {
            key: Common.TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_KEY,
            name: Common.TSECO_BFA_COMMUNICATION_LIFECYCLE_CALCULATOR_NAME,
            value: getCommunicationLifecycleCalculatorValue(),
        },
        {
            key: Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_KEY,
            name: Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_NAME,
            value: getLecuCommunicationAlarmValue(),
        },

        {
            key: Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_KEY,
            name: Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_NAME,
            value: getChargerCommunicationAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_KEY,
            name: Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_NAME,
            value: getVehicleCommunicationAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_KEY,
            name: Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_NAME,
            value: getBmsLowVoltagePowerFaultAlarmValue(),
        },

        {
            key: Common.TSECO_BFA_BAT_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_STATUS_NAME,
            value: getBatStatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_DISCHARGE_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_DISCHARGE_STATUS_NAME,
            value: getBatDischargeStatusValue(),
        },
        {
            key: Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_KEY,
            name: Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_NAME,
            value: getChargeSavingSysAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_BALANCING_STATUS_KEY,
            name: Common.TSECO_BFA_BALANCING_STATUS_NAME,
            value: getBalancingStatusValue(),
        },
        {
            key: Common.TSECO_BFA_BALANCING_ALARM_STATUS_KEY,
            name: Common.TSECO_BFA_BALANCING_ALARM_STATUS_NAME,
            value: getBalancingAlarmStatusValue(),
        },
        {
            key: Common.TSECO_BFA_HIGH_SOC_ALARM_KEY,
            name: Common.TSECO_BFA_HIGH_SOC_ALARM_NAME,
            value: getHighSocAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_SOC_JUMP_ALARM_KEY,
            name: Common.TSECO_BFA_SOC_JUMP_ALARM_NAME,
            value: getSocJumpAlarmValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_FORECAST_KEY,
            name: Common.TSECO_BFA_BAT_FORECAST_NAME,
            value: getBatForecastValue(),
        },

        {
            key: Common.TSECO_BFA_BAT_PACK1_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK1_STATUS_NAME,
            value: getBatPack1StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK2_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK2_STATUS_NAME,
            value: getBatPack2StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK3_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK3_STATUS_NAME,
            value: getBatPack3StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK4_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK4_STATUS_NAME,
            value: getBatPack4StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK5_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK5_STATUS_NAME,
            value: getBatPack5StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK6_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK6_STATUS_NAME,
            value: getBatPack6StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK7_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK7_STATUS_NAME,
            value: getBatPack7StatusValue(),
        },
        {
            key: Common.TSECO_BFA_BAT_PACK8_STATUS_KEY,
            name: Common.TSECO_BFA_BAT_PACK8_STATUS_NAME,
            value: getBatPack8StatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BatInfo2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batInfo2PanelOpen, setBatInfo2PanelOpen] = useState(false);
    const [batInfo2ChartMounted, setBatInfo2ChartMounted] = useState(false);
    const [selectedBatInfo2, setSelectedBatInfo2] = useState<Array<React.Key>>([
        // Common.TSECO_BI2_SYS_MIN_CELL_VOLT_KEY,
        // Common.TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_KEY,
        // Common.TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_KEY,
        // Common.TSECO_BI2_SYS_MAX_CELL_VOLT_KEY,
        // Common.TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_KEY,
        // Common.TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_KEY,
    ]);

    const [seriesChartDatasBatInfo2, setSeriesChartDatasBatInfo2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatInfo2SysMinCellVolt, setChartBatInfo2SysMinCellVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_NAME,
        data: [],
    });

    const [chartBatInfo2SysMinCellVoltModuleNo, setChartBatInfo2SysMinCellVoltModuleNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_NAME,
        data: [],
    });

    const [chartBatInfo2SysMinVoltCellNo, setChartBatInfo2SysMinVoltCellNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_NAME,
        data: [],
    });

    const [chartBatInfo2SysMaxCellVolt, setChartBatInfo2SysMaxCellVolt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_NAME,
        data: [],
    });

    const [chartBatInfo2SysMaxCellVoltModuleNo, setChartBatInfo2SysMaxCellVoltModuleNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_NAME,
        data: [],
    });

    const [chartBatInfo2SysMaxVoltCellNo, setChartBatInfo2SysMaxVoltCellNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatInfo2 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_BI2_SYS_MIN_CELL_VOLT_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보2
                            <br />
                            CAN ID: 0x181817F3
                            <br />
                            Name: 시스템 최저 셀 전압
                        </p>
                        <p>Remarks: V, 0~65534</p>
                    </>
                );
            case Common.TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보2
                            <br />
                            CAN ID: 0x181817F3
                            <br />
                            Name: 시스템 최저 셀 전압 모듈 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );

            case Common.TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보2
                            <br />
                            CAN ID: 0x181817F3
                            <br />
                            Name: 시스템 최저 전압 셀 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );

            case Common.TSECO_BI2_SYS_MAX_CELL_VOLT_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보2
                            <br />
                            CAN ID: 0x181817F3
                            <br />
                            Name: 시스템 최고 셀 전압
                        </p>
                        <p>Remarks: V, 0~65534</p>
                    </>
                );

            case Common.TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보2
                            <br />
                            CAN ID: 0x181817F3
                            <br />
                            Name: 시스템 최고 셀 전압 모듈 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );

            case Common.TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 기본 정보2
                            <br />
                            CAN ID: 0x181817F3
                            <br />
                            Name: 시스템 최고 전압 셀 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );
        }

        return null;
    };

    const getBatInfo2SysMinCellVoltValue = () => {
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MIN_CELL_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo2?.sysMinCellVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatInfo2SysMinCellVoltModuleNoValue = () => {
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo2?.sysMinCellVoltModuleNo, "");
        }
        return "";
    };

    const getBatInfo2SysMinVoltCellNoValue = () => {
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo2?.sysMinVoltCellNo, "");
        }
        return "";
    };

    const getBatInfo2SysMaxCellVoltValue = () => {
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MAX_CELL_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo2?.sysMaxCellVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatInfo2SysMaxCellVoltModuleNoValue = () => {
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo2?.sysMaxCellVoltModuleNo, "");
        }
        return "";
    };

    const getBatInfo2SysMaxVoltCellNoValue = () => {
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo2?.sysMaxVoltCellNo, "");
        }
        return "";
    };

    const onChangeBatInfo2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatInfo2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatInfo2: Array<TypeUtils.ChartData> = [];

        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MIN_CELL_VOLT_KEY)) chartDatasBatInfo2.push(chartBatInfo2SysMinCellVolt);
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_KEY)) chartDatasBatInfo2.push(chartBatInfo2SysMinCellVoltModuleNo);
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_KEY)) chartDatasBatInfo2.push(chartBatInfo2SysMinVoltCellNo);
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MAX_CELL_VOLT_KEY)) chartDatasBatInfo2.push(chartBatInfo2SysMaxCellVolt);
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_KEY)) chartDatasBatInfo2.push(chartBatInfo2SysMaxCellVoltModuleNo);
        if (selectedBatInfo2.includes(Common.TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_KEY)) chartDatasBatInfo2.push(chartBatInfo2SysMaxVoltCellNo);

        setSeriesChartDatasBatInfo2(chartDatasBatInfo2);
    }, [
        selectedBatInfo2,
        chartBatInfo2SysMinCellVolt,
        chartBatInfo2SysMinCellVoltModuleNo,
        chartBatInfo2SysMinVoltCellNo,
        chartBatInfo2SysMaxCellVolt,
        chartBatInfo2SysMaxCellVoltModuleNo,
        chartBatInfo2SysMaxVoltCellNo,
    ]);

    const tableDataBatInfo2: CanTableType[] = [
        {
            key: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_KEY,
            name: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_NAME,
            value: getBatInfo2SysMinCellVoltValue(),
        },
        {
            key: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_KEY,
            name: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_MODULE_NAME,
            value: getBatInfo2SysMinCellVoltModuleNoValue(),
        },
        {
            key: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_KEY,
            name: Common.TSECO_BI2_SYS_MIN_CELL_VOLT_CELL_NO_NAME,
            value: getBatInfo2SysMinVoltCellNoValue(),
        },
        {
            key: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_KEY,
            name: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_NAME,
            value: getBatInfo2SysMaxCellVoltValue(),
        },
        {
            key: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_KEY,
            name: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_MODULE_NAME,
            value: getBatInfo2SysMaxCellVoltModuleNoValue(),
        },
        {
            key: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_KEY,
            name: Common.TSECO_BI2_SYS_MAX_CELL_VOLT_CELL_NO_NAME,
            value: getBatInfo2SysMaxVoltCellNoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BatModuleInfo
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batModuleInfoPanelOpen, setBatModuleInfoPanelOpen] = useState(false);
    const [batModuleInfoChartMounted, setBatModuleInfoChartMounted] = useState(false);
    const [selectedBatModuleInfo, setSelectedBatModuleInfo] = useState<Array<React.Key>>([
        // Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_KEY,
        // Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_KEY,
        // Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_KEY,
        // Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_KEY,
        // Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_KEY,
        // Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_KEY,
    ]);

    const [seriesChartDatasBatModuleInfo, setSeriesChartDatasBatModuleInfo] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatModuleInfoSysMinTempSamplingSpotTemp, setChartBatModuleInfoSysMinTempSamplingSpotTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_NAME,
        data: [],
    });

    const [chartBatModuleInfoSysMinTempSamplingSpotModuleNo, setChartBatModuleInfoSysMinTempSamplingSpotModuleNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_NAME,
        data: [],
    });

    const [chartBatModuleInfoSysMinTempSamplingSpotNo, setChartBatModuleInfoSysMinTempSamplingSpotNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_NAME,
        data: [],
    });

    const [chartBatModuleInfoSysMaxTempSamplingSpotTemp, setChartBatModuleInfoSysMaxTempSamplingSpotTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_NAME,
        data: [],
    });

    const [chartBatModuleInfoSysMaxTempSamplingSpotModuleNo, setChartBatModuleInfoSysMaxTempSamplingSpotModuleNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_NAME,
        data: [],
    });

    const [chartBatModuleInfoSysMaxTempSamplingSpotNo, setChartBatModuleInfoSysMaxTempSamplingSpotNo] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_NAME,
        data: [],
    });

    const getTabelTooltipBatModuleInfo = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 기본 정보
                            <br />
                            CAN ID: 0x181D17F3
                            <br />
                            Name: 시스템 최저 온도 샘플링 지점 온도
                        </p>
                        <p>Remarks: V, 0~65534</p>
                    </>
                );
            case Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 기본 정보
                            <br />
                            CAN ID: 0x181D17F3
                            <br />
                            Name: 시스템 최저 온도 샘플링 지점 모듈 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );

            case Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 기본 정보
                            <br />
                            CAN ID: 0x181D17F3
                            <br />
                            Name: 시스템 최저 온도 샘플링 지점 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );

            case Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 기본 정보
                            <br />
                            CAN ID: 0x181D17F3
                            <br />
                            Name: 시스템 최고 온도 샘플링 지점 온도
                        </p>
                        <p>Remarks: V, 0~65534</p>
                    </>
                );

            case Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 기본 정보
                            <br />
                            CAN ID: 0x181D17F3
                            <br />
                            Name: 시스템 최고 온도 샘플링 지점 모듈 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );

            case Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_KEY:
                return (
                    <>
                        <p>
                            배터리 모듈 기본 정보
                            <br />
                            CAN ID: 0x181D17F3
                            <br />
                            Name: 시스템 최고 온도 샘플링 지점 번호
                        </p>
                        <p>Remarks: 0~254</p>
                    </>
                );
        }

        return null;
    };

    const convertModuleBatTempRemark = (value: number) => {
        let valueString = value ? value + Common.UNIT_CELSIUS : "-";
        switch (value) {
            case 255:
                valueString = "무효";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getBatModuleInfoSysMinTempSamplingSpotTempValue = () => {
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.batModuleInfo?.sysMinTempSamplingSpotTemp);
        }
        return "";
    };

    const getBatModuleInfoSysMinTempSamplingSpotModuleNoValue = () => {
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batModuleInfo?.sysMinTempSamplingSpotModuleNo, "");
        }
        return "";
    };

    const getBatModuleInfoSysMinTempSamplingSpotNoValue = () => {
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batModuleInfo?.sysMinTempSamplingSpotNo, "");
        }
        return "";
    };

    const getBatModuleInfoSysMaxTempSamplingSpotTempValue = () => {
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.batModuleInfo?.sysMaxTempSamplingSpotTemp);
        }
        return "";
    };

    const getBatModuleInfoSysMaxTempSamplingSpotModuleValue = () => {
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batModuleInfo?.sysMaxTempSamplingSpotModuleNo, "");
        }
        return "";
    };

    const getBatModuleInfoSysMaxTempSamplingSpotNoValue = () => {
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batModuleInfo?.sysMaxTempSamplingSpotNo, "");
        }
        return "";
    };

    const onChangeBatModuleInfo = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatModuleInfo(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatModuleInfo: Array<TypeUtils.ChartData> = [];

        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_KEY))
            chartDatasBatModuleInfo.push(chartBatModuleInfoSysMinTempSamplingSpotTemp);
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_KEY))
            chartDatasBatModuleInfo.push(chartBatModuleInfoSysMinTempSamplingSpotModuleNo);
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_KEY))
            chartDatasBatModuleInfo.push(chartBatModuleInfoSysMinTempSamplingSpotNo);
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_KEY))
            chartDatasBatModuleInfo.push(chartBatModuleInfoSysMaxTempSamplingSpotTemp);
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_KEY))
            chartDatasBatModuleInfo.push(chartBatModuleInfoSysMaxTempSamplingSpotModuleNo);
        if (selectedBatModuleInfo.includes(Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_KEY))
            chartDatasBatModuleInfo.push(chartBatModuleInfoSysMaxTempSamplingSpotNo);

        setSeriesChartDatasBatModuleInfo(chartDatasBatModuleInfo);
    }, [
        selectedBatModuleInfo,
        chartBatModuleInfoSysMinTempSamplingSpotTemp,
        chartBatModuleInfoSysMinTempSamplingSpotModuleNo,
        chartBatModuleInfoSysMinTempSamplingSpotNo,
        chartBatModuleInfoSysMaxTempSamplingSpotTemp,
        chartBatModuleInfoSysMaxTempSamplingSpotModuleNo,
        chartBatModuleInfoSysMaxTempSamplingSpotNo,
    ]);

    const tableDataBatModuleInfo: CanTableType[] = [
        {
            key: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_KEY,
            name: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_NAME,
            value: getBatModuleInfoSysMinTempSamplingSpotTempValue(),
        },
        {
            key: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_KEY,
            name: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_MODULE_NAME,
            value: getBatModuleInfoSysMinTempSamplingSpotModuleNoValue(),
        },
        {
            key: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_KEY,
            name: Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_NO_NAME,
            value: getBatModuleInfoSysMinTempSamplingSpotNoValue(),
        },
        {
            key: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_KEY,
            name: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_NAME,
            value: getBatModuleInfoSysMaxTempSamplingSpotTempValue(),
        },
        {
            key: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_KEY,
            name: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_MODULE_NAME,
            value: getBatModuleInfoSysMaxTempSamplingSpotModuleValue(),
        },
        {
            key: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_KEY,
            name: Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_NO_NAME,
            value: getBatModuleInfoSysMaxTempSamplingSpotNoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region CellVolt1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVolt1PanelOpen, setCellVolt1PanelOpen] = useState(false);
    const [cellVolt1ChartMounted, setCellVolt1ChartMounted] = useState(false);
    const [selectedCellVolt1, setSelectedCellVolt1] = useState<Array<React.Key>>([
        // Common.TSECO_CV1_BAT_MODULE_NO_KEY,
        // Common.TSECO_CV1_CELL_VOLT1_KEY,
        // Common.TSECO_CV1_CELL_VOLT2_KEY,
        // Common.TSECO_CV1_CELL_VOLT3_KEY,
        // Common.TSECO_CV1_CELL_VOLT4_KEY,
        // Common.TSECO_CV1_CELL_VOLT5_KEY,
        // Common.TSECO_CV1_CELL_VOLT6_KEY,
        // Common.TSECO_CV1_CELL_VOLT7_KEY,
    ]);

    const [seriesChartDatasCellVolt1, setSeriesChartDatasCellVolt1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellVolt1BatModule, setChartCellVolt1BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartCellVolt1CellVolt1, setChartCellVolt1CellVolt1] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_CELL_VOLT1_NAME,
        data: [],
    });

    const [chartCellVolt1CellVolt2, setChartCellVolt1CellVolt2] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_CELL_VOLT2_NAME,
        data: [],
    });

    const [chartCellVolt1CellVolt3, setChartCellVolt1CellVolt3] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_CELL_VOLT3_NAME,
        data: [],
    });

    const [chartCellVolt1CellVolt4, setChartCellVolt1CellVolt4] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_CELL_VOLT4_NAME,
        data: [],
    });

    const [chartCellVolt1CellVolt5, setChartCellVolt1CellVolt5] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_CELL_VOLT5_NAME,
        data: [],
    });

    const [chartCellVolt1CellVolt6, setChartCellVolt1CellVolt6] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_CELL_VOLT6_NAME,
        data: [],
    });

    const [chartCellVolt1CellVolt7, setChartCellVolt1CellVolt7] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV1_CELL_VOLT7_NAME,
        data: [],
    });

    const getTabelTooltipCellVolt1 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_CV1_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 0~8</p>
                    </>
                );
            case Common.TSECO_CV1_CELL_VOLT1_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 1번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV1_CELL_VOLT2_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 2번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV1_CELL_VOLT3_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 3번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV1_CELL_VOLT4_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 4번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV1_CELL_VOLT5_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 5번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
            case Common.TSECO_CV1_CELL_VOLT6_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 6번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV1_CELL_VOLT7_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 1
                            <br />
                            CAN ID: 0x182417F3
                            <br />
                            Name: 7번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
        }

        return null;
    };

    const getCellVolt1BatModuleNoValue = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.batModuleNo, "");
        }
        return "";
    };
    const getCellVolt1CellVolt1Value = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT1_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.cellVolt1, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVolt1CellVolt2Value = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT2_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.cellVolt2, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt1CellVolt3Value = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT3_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.cellVolt3, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt1CellVolt4Value = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT4_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.cellVolt4, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt1CellVolt5Value = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT5_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.cellVolt5, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt1CellVolt6Value = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT6_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.cellVolt6, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt1CellVolt7Value = () => {
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT7_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt1?.cellVolt7, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeCellVolt1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVolt1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVolt1: Array<TypeUtils.ChartData> = [];

        if (selectedCellVolt1.includes(Common.TSECO_CV1_BAT_MODULE_NO_KEY)) chartDatasCellVolt1.push(chartCellVolt1BatModule);
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT1_KEY)) chartDatasCellVolt1.push(chartCellVolt1CellVolt1);
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT2_KEY)) chartDatasCellVolt1.push(chartCellVolt1CellVolt2);
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT3_KEY)) chartDatasCellVolt1.push(chartCellVolt1CellVolt3);
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT4_KEY)) chartDatasCellVolt1.push(chartCellVolt1CellVolt4);
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT5_KEY)) chartDatasCellVolt1.push(chartCellVolt1CellVolt5);
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT6_KEY)) chartDatasCellVolt1.push(chartCellVolt1CellVolt6);
        if (selectedCellVolt1.includes(Common.TSECO_CV1_CELL_VOLT7_KEY)) chartDatasCellVolt1.push(chartCellVolt1CellVolt7);

        setSeriesChartDatasCellVolt1(chartDatasCellVolt1);
    }, [
        selectedCellVolt1,
        chartCellVolt1BatModule,
        chartCellVolt1CellVolt1,
        chartCellVolt1CellVolt2,
        chartCellVolt1CellVolt3,
        chartCellVolt1CellVolt4,
        chartCellVolt1CellVolt5,
        chartCellVolt1CellVolt6,
        chartCellVolt1CellVolt7,
    ]);

    const tableDataCellVolt1: CanTableType[] = [
        {
            key: Common.TSECO_CV1_BAT_MODULE_NO_KEY,
            name: Common.TSECO_CV1_BAT_MODULE_NO_NAME,
            value: getCellVolt1BatModuleNoValue(),
        },
        {
            key: Common.TSECO_CV1_CELL_VOLT1_KEY,
            name: Common.TSECO_CV1_CELL_VOLT1_NAME,
            value: getCellVolt1CellVolt1Value(),
        },
        {
            key: Common.TSECO_CV1_CELL_VOLT2_KEY,
            name: Common.TSECO_CV1_CELL_VOLT2_NAME,
            value: getCellVolt1CellVolt2Value(),
        },
        {
            key: Common.TSECO_CV1_CELL_VOLT3_KEY,
            name: Common.TSECO_CV1_CELL_VOLT3_NAME,
            value: getCellVolt1CellVolt3Value(),
        },
        {
            key: Common.TSECO_CV1_CELL_VOLT4_KEY,
            name: Common.TSECO_CV1_CELL_VOLT4_NAME,
            value: getCellVolt1CellVolt4Value(),
        },
        {
            key: Common.TSECO_CV1_CELL_VOLT5_KEY,
            name: Common.TSECO_CV1_CELL_VOLT5_NAME,
            value: getCellVolt1CellVolt5Value(),
        },
        {
            key: Common.TSECO_CV1_CELL_VOLT6_KEY,
            name: Common.TSECO_CV1_CELL_VOLT6_NAME,
            value: getCellVolt1CellVolt6Value(),
        },
        {
            key: Common.TSECO_CV1_CELL_VOLT7_KEY,
            name: Common.TSECO_CV1_CELL_VOLT7_NAME,
            value: getCellVolt1CellVolt7Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region CellVolt2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVolt2PanelOpen, setCellVolt2PanelOpen] = useState(false);
    const [cellVolt2ChartMounted, setCellVolt2ChartMounted] = useState(false);
    const [selectedCellVolt2, setSelectedCellVolt2] = useState<Array<React.Key>>([
        // Common.TSECO_CV2_BAT_MODULE_NO_KEY,
        // Common.TSECO_CV2_CELL_VOLT8_KEY,
        // Common.TSECO_CV2_CELL_VOLT9_KEY,
        // Common.TSECO_CV2_CELL_VOLT10_KEY,
        // Common.TSECO_CV2_CELL_VOLT11_KEY,
        // Common.TSECO_CV2_CELL_VOLT12_KEY,
        // Common.TSECO_CV2_CELL_VOLT13_KEY,
        // Common.TSECO_CV2_CELL_VOLT14_KEY,
    ]);

    const [seriesChartDatasCellVolt2, setSeriesChartDatasCellVolt2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellVolt2BatModule, setChartCellVolt2BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartCellVolt2CellVolt8, setChartCellVolt2CellVolt8] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_CELL_VOLT8_NAME,
        data: [],
    });

    const [chartCellVolt2CellVolt9, setChartCellVolt2CellVolt9] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_CELL_VOLT9_NAME,
        data: [],
    });

    const [chartCellVolt2CellVolt10, setChartCellVolt2CellVolt10] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_CELL_VOLT10_NAME,
        data: [],
    });

    const [chartCellVolt2CellVolt11, setChartCellVolt2CellVolt11] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_CELL_VOLT11_NAME,
        data: [],
    });

    const [chartCellVolt2CellVolt12, setChartCellVolt2CellVolt12] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_CELL_VOLT12_NAME,
        data: [],
    });

    const [chartCellVolt2CellVolt13, setChartCellVolt2CellVolt13] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_CELL_VOLT13_NAME,
        data: [],
    });

    const [chartCellVolt2CellVolt14, setChartCellVolt2CellVolt14] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV2_CELL_VOLT14_NAME,
        data: [],
    });

    const getTabelTooltipCellVolt2 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_CV2_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 0~8</p>
                    </>
                );
            case Common.TSECO_CV2_CELL_VOLT8_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 8번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV2_CELL_VOLT9_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 9번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV2_CELL_VOLT10_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 10번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV2_CELL_VOLT11_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 11번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV2_CELL_VOLT12_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 12번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
            case Common.TSECO_CV2_CELL_VOLT13_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 13번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV2_CELL_VOLT14_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 2
                            <br />
                            CAN ID: 0x182517F3
                            <br />
                            Name: 14번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
        }

        return null;
    };

    const getCellVolt2BatModuleNoValue = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.batModuleNo, "");
        }
        return "";
    };
    const getCellVolt2CellVolt8Value = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT8_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.cellVolt8, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVolt2CellVolt9Value = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT9_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.cellVolt9, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt2CellVolt10Value = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT10_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.cellVolt10, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt2CellVolt11Value = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT11_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.cellVolt11, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt2CellVolt12Value = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT12_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.cellVolt12, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt2CellVolt13Value = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT13_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.cellVolt13, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt2CellVolt14Value = () => {
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT14_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt2?.cellVolt14, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeCellVolt2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVolt2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVolt2: Array<TypeUtils.ChartData> = [];

        if (selectedCellVolt2.includes(Common.TSECO_CV2_BAT_MODULE_NO_KEY)) chartDatasCellVolt2.push(chartCellVolt2BatModule);
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT8_KEY)) chartDatasCellVolt2.push(chartCellVolt2CellVolt8);
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT9_KEY)) chartDatasCellVolt2.push(chartCellVolt2CellVolt9);
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT10_KEY)) chartDatasCellVolt2.push(chartCellVolt2CellVolt10);
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT11_KEY)) chartDatasCellVolt2.push(chartCellVolt2CellVolt11);
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT12_KEY)) chartDatasCellVolt2.push(chartCellVolt2CellVolt12);
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT13_KEY)) chartDatasCellVolt2.push(chartCellVolt2CellVolt13);
        if (selectedCellVolt2.includes(Common.TSECO_CV2_CELL_VOLT14_KEY)) chartDatasCellVolt2.push(chartCellVolt2CellVolt14);

        setSeriesChartDatasCellVolt2(chartDatasCellVolt2);
    }, [
        selectedCellVolt2,
        chartCellVolt2BatModule,
        chartCellVolt2CellVolt8,
        chartCellVolt2CellVolt9,
        chartCellVolt2CellVolt10,
        chartCellVolt2CellVolt11,
        chartCellVolt2CellVolt12,
        chartCellVolt2CellVolt13,
        chartCellVolt2CellVolt14,
    ]);

    const tableDataCellVolt2: CanTableType[] = [
        {
            key: Common.TSECO_CV2_BAT_MODULE_NO_KEY,
            name: Common.TSECO_CV2_BAT_MODULE_NO_NAME,
            value: getCellVolt2BatModuleNoValue(),
        },
        {
            key: Common.TSECO_CV2_CELL_VOLT8_KEY,
            name: Common.TSECO_CV2_CELL_VOLT8_NAME,
            value: getCellVolt2CellVolt8Value(),
        },
        {
            key: Common.TSECO_CV2_CELL_VOLT9_KEY,
            name: Common.TSECO_CV2_CELL_VOLT9_NAME,
            value: getCellVolt2CellVolt9Value(),
        },
        {
            key: Common.TSECO_CV2_CELL_VOLT10_KEY,
            name: Common.TSECO_CV2_CELL_VOLT10_NAME,
            value: getCellVolt2CellVolt10Value(),
        },
        {
            key: Common.TSECO_CV2_CELL_VOLT11_KEY,
            name: Common.TSECO_CV2_CELL_VOLT11_NAME,
            value: getCellVolt2CellVolt11Value(),
        },
        {
            key: Common.TSECO_CV2_CELL_VOLT12_KEY,
            name: Common.TSECO_CV2_CELL_VOLT12_NAME,
            value: getCellVolt2CellVolt12Value(),
        },
        {
            key: Common.TSECO_CV2_CELL_VOLT13_KEY,
            name: Common.TSECO_CV2_CELL_VOLT13_NAME,
            value: getCellVolt2CellVolt13Value(),
        },
        {
            key: Common.TSECO_CV2_CELL_VOLT14_KEY,
            name: Common.TSECO_CV2_CELL_VOLT14_NAME,
            value: getCellVolt2CellVolt14Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region CellVolt3
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVolt3PanelOpen, setCellVolt3PanelOpen] = useState(false);
    const [cellVolt3ChartMounted, setCellVolt3ChartMounted] = useState(false);
    const [selectedCellVolt3, setSelectedCellVolt3] = useState<Array<React.Key>>([
        // Common.TSECO_CV3_BAT_MODULE_NO_KEY,
        // Common.TSECO_CV3_CELL_VOLT15_KEY,
        // Common.TSECO_CV3_CELL_VOLT16_KEY,
        // Common.TSECO_CV3_CELL_VOLT17_KEY,
        // Common.TSECO_CV3_CELL_VOLT18_KEY,
        // Common.TSECO_CV3_CELL_VOLT19_KEY,
        // Common.TSECO_CV3_CELL_VOLT20_KEY,
        // Common.TSECO_CV3_CELL_VOLT21_KEY,
    ]);

    const [seriesChartDatasCellVolt3, setSeriesChartDatasCellVolt3] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellVolt3BatModule, setChartCellVolt3BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartCellVolt3CellVolt15, setChartCellVolt3CellVolt15] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_CELL_VOLT15_NAME,
        data: [],
    });

    const [chartCellVolt3CellVolt16, setChartCellVolt3CellVolt16] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_CELL_VOLT16_NAME,
        data: [],
    });

    const [chartCellVolt3CellVolt17, setChartCellVolt3CellVolt17] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_CELL_VOLT17_NAME,
        data: [],
    });

    const [chartCellVolt3CellVolt18, setChartCellVolt3CellVolt18] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_CELL_VOLT18_NAME,
        data: [],
    });

    const [chartCellVolt3CellVolt19, setChartCellVolt3CellVolt19] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_CELL_VOLT19_NAME,
        data: [],
    });

    const [chartCellVolt3CellVolt20, setChartCellVolt3CellVolt20] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_CELL_VOLT20_NAME,
        data: [],
    });

    const [chartCellVolt3CellVolt21, setChartCellVolt3CellVolt21] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV3_CELL_VOLT21_NAME,
        data: [],
    });

    const getTabelTooltipCellVolt3 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_CV3_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 0~8</p>
                    </>
                );
            case Common.TSECO_CV3_CELL_VOLT15_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 15번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV3_CELL_VOLT16_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 16번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV3_CELL_VOLT17_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 17번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV3_CELL_VOLT18_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 18번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV3_CELL_VOLT19_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 19번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
            case Common.TSECO_CV3_CELL_VOLT20_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 20번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV3_CELL_VOLT21_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 3
                            <br />
                            CAN ID: 0x182617F3
                            <br />
                            Name: 21번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
        }

        return null;
    };

    const getCellVolt3BatModuleNoValue = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.batModuleNo, "");
        }
        return "";
    };
    const getCellVolt3CellVolt15Value = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT15_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.cellVolt15, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVolt3CellVolt16Value = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT16_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.cellVolt16, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt3CellVolt17Value = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT17_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.cellVolt17, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt3CellVolt18Value = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT18_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.cellVolt18, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt3CellVolt19Value = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT19_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.cellVolt19, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt3CellVolt20Value = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT20_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.cellVolt20, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt3CellVolt21Value = () => {
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT21_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt3?.cellVolt21, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeCellVolt3 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVolt3(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVolt3: Array<TypeUtils.ChartData> = [];

        if (selectedCellVolt3.includes(Common.TSECO_CV3_BAT_MODULE_NO_KEY)) chartDatasCellVolt3.push(chartCellVolt3BatModule);
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT15_KEY)) chartDatasCellVolt3.push(chartCellVolt3CellVolt15);
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT16_KEY)) chartDatasCellVolt3.push(chartCellVolt3CellVolt16);
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT17_KEY)) chartDatasCellVolt3.push(chartCellVolt3CellVolt17);
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT18_KEY)) chartDatasCellVolt3.push(chartCellVolt3CellVolt18);
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT19_KEY)) chartDatasCellVolt3.push(chartCellVolt3CellVolt19);
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT20_KEY)) chartDatasCellVolt3.push(chartCellVolt3CellVolt20);
        if (selectedCellVolt3.includes(Common.TSECO_CV3_CELL_VOLT21_KEY)) chartDatasCellVolt3.push(chartCellVolt3CellVolt21);

        setSeriesChartDatasCellVolt3(chartDatasCellVolt3);
    }, [
        selectedCellVolt3,
        chartCellVolt3BatModule,
        chartCellVolt3CellVolt15,
        chartCellVolt3CellVolt16,
        chartCellVolt3CellVolt17,
        chartCellVolt3CellVolt18,
        chartCellVolt3CellVolt19,
        chartCellVolt3CellVolt20,
        chartCellVolt3CellVolt21,
    ]);

    const tableDataCellVolt3: CanTableType[] = [
        {
            key: Common.TSECO_CV3_BAT_MODULE_NO_KEY,
            name: Common.TSECO_CV3_BAT_MODULE_NO_NAME,
            value: getCellVolt3BatModuleNoValue(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT15_KEY,
            name: Common.TSECO_CV3_CELL_VOLT15_NAME,
            value: getCellVolt3CellVolt15Value(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT16_KEY,
            name: Common.TSECO_CV3_CELL_VOLT16_NAME,
            value: getCellVolt3CellVolt16Value(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT17_KEY,
            name: Common.TSECO_CV3_CELL_VOLT17_NAME,
            value: getCellVolt3CellVolt17Value(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT18_KEY,
            name: Common.TSECO_CV3_CELL_VOLT18_NAME,
            value: getCellVolt3CellVolt18Value(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT19_KEY,
            name: Common.TSECO_CV3_CELL_VOLT19_NAME,
            value: getCellVolt3CellVolt19Value(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT20_KEY,
            name: Common.TSECO_CV3_CELL_VOLT20_NAME,
            value: getCellVolt3CellVolt20Value(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT21_KEY,
            name: Common.TSECO_CV3_CELL_VOLT21_NAME,
            value: getCellVolt3CellVolt21Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region CellVolt4
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVolt4PanelOpen, setCellVolt4PanelOpen] = useState(false);
    const [cellVolt4ChartMounted, setCellVolt4ChartMounted] = useState(false);
    const [selectedCellVolt4, setSelectedCellVolt4] = useState<Array<React.Key>>([
        // Common.TSECO_CV4_BAT_MODULE_NO_KEY,
        // Common.TSECO_CV4_CELL_VOLT22_KEY,
        // Common.TSECO_CV4_CELL_VOLT23_KEY,
        // Common.TSECO_CV4_CELL_VOLT24_KEY,
        // Common.TSECO_CV4_CELL_VOLT25_KEY,
        // Common.TSECO_CV4_CELL_VOLT26_KEY,
        // Common.TSECO_CV4_CELL_VOLT27_KEY,
        // Common.TSECO_CV4_CELL_VOLT28_KEY,
    ]);

    const [seriesChartDatasCellVolt4, setSeriesChartDatasCellVolt4] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellVolt4BatModule, setChartCellVolt4BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartCellVolt4CellVolt22, setChartCellVolt4CellVolt22] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_CELL_VOLT22_NAME,
        data: [],
    });

    const [chartCellVolt4CellVolt23, setChartCellVolt4CellVolt23] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_CELL_VOLT23_NAME,
        data: [],
    });

    const [chartCellVolt4CellVolt24, setChartCellVolt4CellVolt24] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_CELL_VOLT24_NAME,
        data: [],
    });

    const [chartCellVolt4CellVolt25, setChartCellVolt4CellVolt25] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_CELL_VOLT25_NAME,
        data: [],
    });

    const [chartCellVolt4CellVolt26, setChartCellVolt4CellVolt26] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_CELL_VOLT26_NAME,
        data: [],
    });

    const [chartCellVolt4CellVolt27, setChartCellVolt4CellVolt27] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_CELL_VOLT27_NAME,
        data: [],
    });

    const [chartCellVolt4CellVolt28, setChartCellVolt4CellVolt28] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV4_CELL_VOLT28_NAME,
        data: [],
    });

    const getTabelTooltipCellVolt4 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_CV4_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 0~8</p>
                    </>
                );
            case Common.TSECO_CV4_CELL_VOLT22_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 22번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV4_CELL_VOLT23_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 23번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV4_CELL_VOLT24_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 24번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV4_CELL_VOLT25_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 25번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV4_CELL_VOLT26_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 26번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
            case Common.TSECO_CV4_CELL_VOLT27_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 27번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV4_CELL_VOLT28_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 4
                            <br />
                            CAN ID: 0x182717F3
                            <br />
                            Name: 28번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
        }

        return null;
    };

    const getCellVolt4BatModuleNoValue = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.batModuleNo, "");
        }
        return "";
    };
    const getCellVolt4CellVolt22Value = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT22_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.cellVolt22, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVolt4CellVolt23Value = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT23_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.cellVolt23, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt4CellVolt24Value = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT24_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.cellVolt24, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt4CellVolt25Value = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT25_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.cellVolt25, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt4CellVolt26Value = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT26_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.cellVolt26, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt4CellVolt27Value = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT27_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.cellVolt27, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt4CellVolt28Value = () => {
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT28_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt4?.cellVolt28, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeCellVolt4 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVolt4(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVolt4: Array<TypeUtils.ChartData> = [];

        if (selectedCellVolt4.includes(Common.TSECO_CV4_BAT_MODULE_NO_KEY)) chartDatasCellVolt4.push(chartCellVolt4BatModule);
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT22_KEY)) chartDatasCellVolt4.push(chartCellVolt4CellVolt22);
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT23_KEY)) chartDatasCellVolt4.push(chartCellVolt4CellVolt23);
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT24_KEY)) chartDatasCellVolt4.push(chartCellVolt4CellVolt24);
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT25_KEY)) chartDatasCellVolt4.push(chartCellVolt4CellVolt25);
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT26_KEY)) chartDatasCellVolt4.push(chartCellVolt4CellVolt26);
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT27_KEY)) chartDatasCellVolt4.push(chartCellVolt4CellVolt27);
        if (selectedCellVolt4.includes(Common.TSECO_CV4_CELL_VOLT28_KEY)) chartDatasCellVolt4.push(chartCellVolt4CellVolt28);

        setSeriesChartDatasCellVolt4(chartDatasCellVolt4);
    }, [
        selectedCellVolt4,
        chartCellVolt4BatModule,
        chartCellVolt4CellVolt22,
        chartCellVolt4CellVolt23,
        chartCellVolt4CellVolt24,
        chartCellVolt4CellVolt25,
        chartCellVolt4CellVolt26,
        chartCellVolt4CellVolt27,
        chartCellVolt4CellVolt28,
    ]);

    const tableDataCellVolt4: CanTableType[] = [
        {
            key: Common.TSECO_CV4_BAT_MODULE_NO_KEY,
            name: Common.TSECO_CV4_BAT_MODULE_NO_NAME,
            value: getCellVolt4BatModuleNoValue(),
        },
        {
            key: Common.TSECO_CV4_CELL_VOLT22_KEY,
            name: Common.TSECO_CV4_CELL_VOLT22_NAME,
            value: getCellVolt4CellVolt22Value(),
        },
        {
            key: Common.TSECO_CV4_CELL_VOLT23_KEY,
            name: Common.TSECO_CV4_CELL_VOLT23_NAME,
            value: getCellVolt4CellVolt23Value(),
        },
        {
            key: Common.TSECO_CV4_CELL_VOLT24_KEY,
            name: Common.TSECO_CV4_CELL_VOLT24_NAME,
            value: getCellVolt4CellVolt24Value(),
        },
        {
            key: Common.TSECO_CV4_CELL_VOLT25_KEY,
            name: Common.TSECO_CV4_CELL_VOLT25_NAME,
            value: getCellVolt4CellVolt25Value(),
        },
        {
            key: Common.TSECO_CV4_CELL_VOLT26_KEY,
            name: Common.TSECO_CV4_CELL_VOLT26_NAME,
            value: getCellVolt4CellVolt26Value(),
        },
        {
            key: Common.TSECO_CV4_CELL_VOLT27_KEY,
            name: Common.TSECO_CV4_CELL_VOLT27_NAME,
            value: getCellVolt4CellVolt27Value(),
        },
        {
            key: Common.TSECO_CV4_CELL_VOLT28_KEY,
            name: Common.TSECO_CV4_CELL_VOLT28_NAME,
            value: getCellVolt4CellVolt28Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region CellVolt5
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVolt5PanelOpen, setCellVolt5PanelOpen] = useState(false);
    const [cellVolt5ChartMounted, setCellVolt5ChartMounted] = useState(false);
    const [selectedCellVolt5, setSelectedCellVolt5] = useState<Array<React.Key>>([
        // Common.TSECO_CV5_BAT_MODULE_NO_KEY,
        // Common.TSECO_CV5_CELL_VOLT29_KEY,
        // Common.TSECO_CV5_CELL_VOLT30_KEY,
        // Common.TSECO_CV5_CELL_VOLT31_KEY,
        // Common.TSECO_CV5_CELL_VOLT32_KEY,
        // Common.TSECO_CV5_CELL_VOLT33_KEY,
        // Common.TSECO_CV5_CELL_VOLT34_KEY,
        // Common.TSECO_CV5_CELL_VOLT35_KEY,
    ]);

    const [seriesChartDatasCellVolt5, setSeriesChartDatasCellVolt5] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellVolt5BatModule, setChartCellVolt5BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartCellVolt5CellVolt29, setChartCellVolt5CellVolt29] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_CELL_VOLT29_NAME,
        data: [],
    });

    const [chartCellVolt5CellVolt30, setChartCellVolt5CellVolt30] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_CELL_VOLT30_NAME,
        data: [],
    });

    const [chartCellVolt5CellVolt31, setChartCellVolt5CellVolt31] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_CELL_VOLT31_NAME,
        data: [],
    });

    const [chartCellVolt5CellVolt32, setChartCellVolt5CellVolt32] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_CELL_VOLT32_NAME,
        data: [],
    });

    const [chartCellVolt5CellVolt33, setChartCellVolt5CellVolt33] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_CELL_VOLT33_NAME,
        data: [],
    });

    const [chartCellVolt5CellVolt34, setChartCellVolt5CellVolt34] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_CELL_VOLT34_NAME,
        data: [],
    });

    const [chartCellVolt5CellVolt35, setChartCellVolt5CellVolt35] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV5_CELL_VOLT35_NAME,
        data: [],
    });

    const getTabelTooltipCellVolt5 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_CV5_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 0~8</p>
                    </>
                );
            case Common.TSECO_CV5_CELL_VOLT29_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 29번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV5_CELL_VOLT30_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 30번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV5_CELL_VOLT31_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 31번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV5_CELL_VOLT32_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 32번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV5_CELL_VOLT33_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 33번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
            case Common.TSECO_CV5_CELL_VOLT34_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 34번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV5_CELL_VOLT35_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 5
                            <br />
                            CAN ID: 0x182817F3
                            <br />
                            Name: 35번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
        }

        return null;
    };

    const getCellVolt5BatModuleNoValue = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.batModuleNo, "");
        }
        return "";
    };
    const getCellVolt5CellVolt29Value = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT29_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.cellVolt29, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVolt5CellVolt30Value = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT30_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.cellVolt30, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt5CellVolt31Value = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT31_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.cellVolt31, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt5CellVolt32Value = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT32_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.cellVolt32, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt5CellVolt33Value = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT33_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.cellVolt33, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt5CellVolt34Value = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT34_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.cellVolt34, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt5CellVolt35Value = () => {
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT35_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt5?.cellVolt35, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeCellVolt5 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVolt5(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVolt5: Array<TypeUtils.ChartData> = [];

        if (selectedCellVolt5.includes(Common.TSECO_CV5_BAT_MODULE_NO_KEY)) chartDatasCellVolt5.push(chartCellVolt5BatModule);
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT29_KEY)) chartDatasCellVolt5.push(chartCellVolt5CellVolt29);
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT30_KEY)) chartDatasCellVolt5.push(chartCellVolt5CellVolt30);
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT31_KEY)) chartDatasCellVolt5.push(chartCellVolt5CellVolt31);
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT32_KEY)) chartDatasCellVolt5.push(chartCellVolt5CellVolt32);
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT33_KEY)) chartDatasCellVolt5.push(chartCellVolt5CellVolt33);
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT34_KEY)) chartDatasCellVolt5.push(chartCellVolt5CellVolt34);
        if (selectedCellVolt5.includes(Common.TSECO_CV5_CELL_VOLT35_KEY)) chartDatasCellVolt5.push(chartCellVolt5CellVolt35);

        setSeriesChartDatasCellVolt5(chartDatasCellVolt5);
    }, [
        selectedCellVolt5,
        chartCellVolt5BatModule,
        chartCellVolt5CellVolt29,
        chartCellVolt5CellVolt30,
        chartCellVolt5CellVolt31,
        chartCellVolt5CellVolt32,
        chartCellVolt5CellVolt33,
        chartCellVolt5CellVolt34,
        chartCellVolt5CellVolt35,
    ]);

    const tableDataCellVolt5: CanTableType[] = [
        {
            key: Common.TSECO_CV5_BAT_MODULE_NO_KEY,
            name: Common.TSECO_CV5_BAT_MODULE_NO_NAME,
            value: getCellVolt5BatModuleNoValue(),
        },
        {
            key: Common.TSECO_CV5_CELL_VOLT29_KEY,
            name: Common.TSECO_CV5_CELL_VOLT29_NAME,
            value: getCellVolt5CellVolt29Value(),
        },
        {
            key: Common.TSECO_CV5_CELL_VOLT30_KEY,
            name: Common.TSECO_CV5_CELL_VOLT30_NAME,
            value: getCellVolt5CellVolt30Value(),
        },
        {
            key: Common.TSECO_CV5_CELL_VOLT31_KEY,
            name: Common.TSECO_CV5_CELL_VOLT31_NAME,
            value: getCellVolt5CellVolt31Value(),
        },
        {
            key: Common.TSECO_CV5_CELL_VOLT32_KEY,
            name: Common.TSECO_CV5_CELL_VOLT32_NAME,
            value: getCellVolt5CellVolt32Value(),
        },
        {
            key: Common.TSECO_CV5_CELL_VOLT33_KEY,
            name: Common.TSECO_CV5_CELL_VOLT33_NAME,
            value: getCellVolt5CellVolt33Value(),
        },
        {
            key: Common.TSECO_CV5_CELL_VOLT34_KEY,
            name: Common.TSECO_CV5_CELL_VOLT34_NAME,
            value: getCellVolt5CellVolt34Value(),
        },
        {
            key: Common.TSECO_CV3_CELL_VOLT21_KEY,
            name: Common.TSECO_CV3_CELL_VOLT21_NAME,
            value: getCellVolt5CellVolt35Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region CellVolt6
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVolt6PanelOpen, setCellVolt6PanelOpen] = useState(false);
    const [cellVolt6ChartMounted, setCellVolt6ChartMounted] = useState(false);
    const [selectedCellVolt6, setSelectedCellVolt6] = useState<Array<React.Key>>([
        // Common.TSECO_CV6_BAT_MODULE_NO_KEY,
        // Common.TSECO_CV6_CELL_VOLT36_KEY,
        // Common.TSECO_CV6_CELL_VOLT37_KEY,
        // Common.TSECO_CV6_CELL_VOLT38_KEY,
        // Common.TSECO_CV6_CELL_VOLT39_KEY,
        // Common.TSECO_CV6_CELL_VOLT40_KEY,
        // Common.TSECO_CV6_CELL_VOLT41_KEY,
        // Common.TSECO_CV6_CELL_VOLT42_KEY,
    ]);

    const [seriesChartDatasCellVolt6, setSeriesChartDatasCellVolt6] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellVolt6BatModule, setChartCellVolt6BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartCellVolt6CellVolt36, setChartCellVolt6CellVolt36] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_CELL_VOLT36_NAME,
        data: [],
    });

    const [chartCellVolt6CellVolt37, setChartCellVolt6CellVolt37] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_CELL_VOLT37_NAME,
        data: [],
    });

    const [chartCellVolt6CellVolt38, setChartCellVolt6CellVolt38] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_CELL_VOLT38_NAME,
        data: [],
    });

    const [chartCellVolt6CellVolt39, setChartCellVolt6CellVolt39] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_CELL_VOLT39_NAME,
        data: [],
    });

    const [chartCellVolt6CellVolt40, setChartCellVolt6CellVolt40] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_CELL_VOLT40_NAME,
        data: [],
    });

    const [chartCellVolt6CellVolt41, setChartCellVolt6CellVolt41] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_CELL_VOLT41_NAME,
        data: [],
    });

    const [chartCellVolt6CellVolt42, setChartCellVolt6CellVolt42] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_CV6_CELL_VOLT42_NAME,
        data: [],
    });

    const getTabelTooltipCellVolt6 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_CV6_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 0~8</p>
                    </>
                );
            case Common.TSECO_CV6_CELL_VOLT36_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 36번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV6_CELL_VOLT37_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 37번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV6_CELL_VOLT38_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 38번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV6_CELL_VOLT39_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 39번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV6_CELL_VOLT40_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 40번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
            case Common.TSECO_CV6_CELL_VOLT41_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 41번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );

            case Common.TSECO_CV6_CELL_VOLT42_KEY:
                return (
                    <>
                        <p>
                            셀 전압 정보 6
                            <br />
                            CAN ID: 0x182917F3
                            <br />
                            Name: 42번 셀 전압
                        </p>
                        <p>Remarks: V, 0~4.5</p>
                    </>
                );
        }

        return null;
    };

    const getCellVolt6BatModuleNoValue = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.batModuleNo, "");
        }
        return "";
    };
    const getCellVolt6CellVolt36Value = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT36_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.cellVolt36, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVolt6CellVolt37Value = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT37_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.cellVolt37, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt6CellVolt38Value = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT38_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.cellVolt38, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt6CellVolt39Value = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT39_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.cellVolt39, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt6CellVolt40Value = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT40_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.cellVolt40, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt6CellVolt41Value = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT41_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.cellVolt41, Common.UNIT_VOLTAGE);
        }
        return "";
    };
    const getCellVolt6CellVolt42Value = () => {
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT42_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt6?.cellVolt42, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeCellVolt6 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVolt6(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVolt6: Array<TypeUtils.ChartData> = [];

        if (selectedCellVolt6.includes(Common.TSECO_CV6_BAT_MODULE_NO_KEY)) chartDatasCellVolt6.push(chartCellVolt6BatModule);
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT36_KEY)) chartDatasCellVolt6.push(chartCellVolt6CellVolt36);
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT37_KEY)) chartDatasCellVolt6.push(chartCellVolt6CellVolt37);
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT38_KEY)) chartDatasCellVolt6.push(chartCellVolt6CellVolt38);
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT39_KEY)) chartDatasCellVolt6.push(chartCellVolt6CellVolt39);
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT40_KEY)) chartDatasCellVolt6.push(chartCellVolt6CellVolt40);
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT41_KEY)) chartDatasCellVolt6.push(chartCellVolt6CellVolt41);
        if (selectedCellVolt6.includes(Common.TSECO_CV6_CELL_VOLT42_KEY)) chartDatasCellVolt6.push(chartCellVolt6CellVolt42);

        setSeriesChartDatasCellVolt6(chartDatasCellVolt6);
    }, [
        selectedCellVolt6,
        chartCellVolt6BatModule,
        chartCellVolt6CellVolt36,
        chartCellVolt6CellVolt37,
        chartCellVolt6CellVolt38,
        chartCellVolt6CellVolt39,
        chartCellVolt6CellVolt40,
        chartCellVolt6CellVolt41,
        chartCellVolt6CellVolt42,
    ]);

    const tableDataCellVolt6: CanTableType[] = [
        {
            key: Common.TSECO_CV6_BAT_MODULE_NO_KEY,
            name: Common.TSECO_CV6_BAT_MODULE_NO_NAME,
            value: getCellVolt6BatModuleNoValue(),
        },
        {
            key: Common.TSECO_CV6_CELL_VOLT36_KEY,
            name: Common.TSECO_CV6_CELL_VOLT36_NAME,
            value: getCellVolt6CellVolt36Value(),
        },
        {
            key: Common.TSECO_CV6_CELL_VOLT37_KEY,
            name: Common.TSECO_CV6_CELL_VOLT37_NAME,
            value: getCellVolt6CellVolt37Value(),
        },
        {
            key: Common.TSECO_CV6_CELL_VOLT38_KEY,
            name: Common.TSECO_CV6_CELL_VOLT38_NAME,
            value: getCellVolt6CellVolt38Value(),
        },
        {
            key: Common.TSECO_CV6_CELL_VOLT39_KEY,
            name: Common.TSECO_CV6_CELL_VOLT39_NAME,
            value: getCellVolt6CellVolt39Value(),
        },
        {
            key: Common.TSECO_CV6_CELL_VOLT40_KEY,
            name: Common.TSECO_CV6_CELL_VOLT40_NAME,
            value: getCellVolt6CellVolt40Value(),
        },
        {
            key: Common.TSECO_CV6_CELL_VOLT41_KEY,
            name: Common.TSECO_CV6_CELL_VOLT41_NAME,
            value: getCellVolt6CellVolt41Value(),
        },
        {
            key: Common.TSECO_CV6_CELL_VOLT42_KEY,
            name: Common.TSECO_CV6_CELL_VOLT42_NAME,
            value: getCellVolt6CellVolt42Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ModulteBatTemp1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [moduleBatTemp1PanelOpen, setModuleBatTemp1PanelOpen] = useState(false);
    const [moduleBatTemp1ChartMounted, setModuleBatTemp1ChartMounted] = useState(false);
    const [selectedModuleBatTemp1, setSelectedModuleBatTemp1] = useState<Array<React.Key>>([
        // Common.TSECO_MBT1_BAT_MODULE_NO_KEY,
        // Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_KEY,
    ]);

    const [seriesChartDatasModuleBatTemp1, setSeriesChartDatasModuleBatTemp1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartModuleBatTemp1BatModule, setChartModuleBatTemp1BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartModuleBatTemp1Module1SamplingTemp, setChartModuleBatTemp1Module1SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp1Module2SamplingTemp, setChartModuleBatTemp1Module2SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp1Module3SamplingTemp, setChartModuleBatTemp1Module3SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp1Module4SamplingTemp, setChartModuleBatTemp1Module4SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp1Module5SamplingTemp, setChartModuleBatTemp1Module5SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp1Module6SamplingTemp, setChartModuleBatTemp1Module6SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp1Module7SamplingTemp, setChartModuleBatTemp1Module7SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_NAME,
        data: [],
    });

    const getTabelTooltipModuleBatTemp1 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_MBT1_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 1~254</p>
                    </>
                );
            case Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 모듈 1번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 모듈 2번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 모듈 3번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 모듈 4번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 모듈 5번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );
            case Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 모듈 6번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보1
                            <br />
                            CAN ID: 0x184f17F3
                            <br />
                            Name: 모듈 7번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );
        }

        return null;
    };

    const getModuleBatTemp1BatModuleNoValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.moduleBatTemp1?.batModuleNo, "");
        }
        return "";
    };
    const getModuleBatTemp1Module1SamplingTempValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp1?.module1SamplingTemp);
        }
        return "";
    };

    const getModuleBatTemp1Module2SamplingTempValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp1?.module2SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp1Module3SamplingTempValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp1?.module3SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp1Module4SamplingTempValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp1?.module4SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp1Module5SamplingTempValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp1?.module5SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp1Module6SamplingTempValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp1?.module6SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp1Module7SamplingTempValue = () => {
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp1?.module7SamplingTemp);
        }
        return "";
    };

    const onChangeModuleBatTemp1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedModuleBatTemp1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasModuleBatTemp1: Array<TypeUtils.ChartData> = [];

        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_BAT_MODULE_NO_KEY)) chartDatasModuleBatTemp1.push(chartModuleBatTemp1BatModule);
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp1.push(chartModuleBatTemp1Module1SamplingTemp);
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp1.push(chartModuleBatTemp1Module2SamplingTemp);
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp1.push(chartModuleBatTemp1Module3SamplingTemp);
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp1.push(chartModuleBatTemp1Module4SamplingTemp);
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp1.push(chartModuleBatTemp1Module5SamplingTemp);
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp1.push(chartModuleBatTemp1Module6SamplingTemp);
        if (selectedModuleBatTemp1.includes(Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp1.push(chartModuleBatTemp1Module7SamplingTemp);

        setSeriesChartDatasModuleBatTemp1(chartDatasModuleBatTemp1);
    }, [
        selectedModuleBatTemp1,
        chartModuleBatTemp1BatModule,
        chartModuleBatTemp1Module1SamplingTemp,
        chartModuleBatTemp1Module2SamplingTemp,
        chartModuleBatTemp1Module3SamplingTemp,
        chartModuleBatTemp1Module4SamplingTemp,
        chartModuleBatTemp1Module5SamplingTemp,
        chartModuleBatTemp1Module6SamplingTemp,
        chartModuleBatTemp1Module7SamplingTemp,
    ]);

    const tableDataModuleBatTemp1: CanTableType[] = [
        {
            key: Common.TSECO_MBT1_BAT_MODULE_NO_KEY,
            name: Common.TSECO_MBT1_BAT_MODULE_NO_NAME,
            value: getModuleBatTemp1BatModuleNoValue(),
        },
        {
            key: Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp1Module1SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp1Module2SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp1Module3SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp1Module4SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp1Module5SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp1Module6SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp1Module7SamplingTempValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ModulteBatTemp2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [moduleBatTemp2PanelOpen, setModuleBatTemp2PanelOpen] = useState(false);
    const [moduleBatTemp2ChartMounted, setModuleBatTemp2ChartMounted] = useState(false);
    const [selectedModuleBatTemp2, setSelectedModuleBatTemp2] = useState<Array<React.Key>>([
        // Common.TSECO_MBT2_BAT_MODULE_NO_KEY,
        // Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_KEY,
        // Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_KEY,
    ]);

    const [seriesChartDatasModuleBatTemp2, setSeriesChartDatasModuleBatTemp2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartModuleBatTemp2BatModule, setChartModuleBatTemp2BatModule] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_BAT_MODULE_NO_NAME,
        data: [],
    });

    const [chartModuleBatTemp2Module8SamplingTemp, setChartModuleBatTemp2Module8SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp2Module9SamplingTemp, setChartModuleBatTemp2Module9SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp2Module10SamplingTemp, setChartModuleBatTemp2Module10SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp2Module11SamplingTemp, setChartModuleBatTemp2Module11SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp2Module12SamplingTemp, setChartModuleBatTemp2Module12SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp2Module13SamplingTemp, setChartModuleBatTemp2Module13SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_NAME,
        data: [],
    });

    const [chartModuleBatTemp2Module14SamplingTemp, setChartModuleBatTemp2Module14SamplingTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_NAME,
        data: [],
    });

    const getTabelTooltipModuleBatTemp2 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_MBT2_BAT_MODULE_NO_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 축전지 모듈 번호
                        </p>
                        <p>Remarks: 1~254</p>
                    </>
                );
            case Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 모듈 8번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 모듈 9번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 모듈 10번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 모듈 11번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 모듈 12번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );
            case Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 모듈 13번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );

            case Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_KEY:
                return (
                    <>
                        <p>
                            모듈 내 배터리 온도 정보2
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 모듈 14번째 샘플링 온도
                        </p>
                        <p>Remarks: ℃, 0~254</p>
                    </>
                );
        }

        return null;
    };

    const getModuleBatTemp2BatModuleNoValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_BAT_MODULE_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.moduleBatTemp2?.batModuleNo, "");
        }
        return "";
    };
    const getModuleBatTemp2Module8SamplingTempValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp2?.module8SamplingTemp);
        }
        return "";
    };

    const getModuleBatTemp2Module9SamplingTempValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp2?.module9SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp2Module10SamplingTempValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp2?.module10SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp2Module11SamplingTempValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp2?.module11SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp2Module12SamplingTempValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp2?.module12SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp2Module13SamplingTempValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp2?.module13SamplingTemp);
        }
        return "";
    };
    const getModuleBatTemp2Module14SamplingTempValue = () => {
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_KEY) && currentCanData !== undefined) {
            return convertModuleBatTempRemark(currentCanData.moduleBatTemp2?.module14SamplingTemp);
        }
        return "";
    };

    const onChangeModuleBatTemp2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedModuleBatTemp2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasModuleBatTemp2: Array<TypeUtils.ChartData> = [];

        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_BAT_MODULE_NO_KEY)) chartDatasModuleBatTemp2.push(chartModuleBatTemp2BatModule);
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp2.push(chartModuleBatTemp2Module8SamplingTemp);
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp2.push(chartModuleBatTemp2Module9SamplingTemp);
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp2.push(chartModuleBatTemp2Module10SamplingTemp);
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp2.push(chartModuleBatTemp2Module11SamplingTemp);
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp2.push(chartModuleBatTemp2Module12SamplingTemp);
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp2.push(chartModuleBatTemp2Module13SamplingTemp);
        if (selectedModuleBatTemp2.includes(Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_KEY))
            chartDatasModuleBatTemp2.push(chartModuleBatTemp2Module14SamplingTemp);

        setSeriesChartDatasModuleBatTemp2(chartDatasModuleBatTemp2);
    }, [
        selectedModuleBatTemp2,
        chartModuleBatTemp2BatModule,
        chartModuleBatTemp2Module8SamplingTemp,
        chartModuleBatTemp2Module9SamplingTemp,
        chartModuleBatTemp2Module10SamplingTemp,
        chartModuleBatTemp2Module11SamplingTemp,
        chartModuleBatTemp2Module12SamplingTemp,
        chartModuleBatTemp2Module13SamplingTemp,
        chartModuleBatTemp2Module14SamplingTemp,
    ]);

    const tableDataModuleBatTemp2: CanTableType[] = [
        {
            key: Common.TSECO_MBT2_BAT_MODULE_NO_KEY,
            name: Common.TSECO_MBT2_BAT_MODULE_NO_NAME,
            value: getModuleBatTemp2BatModuleNoValue(),
        },
        {
            key: Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp2Module8SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp2Module9SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp2Module10SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp2Module11SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp2Module12SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp2Module13SamplingTempValue(),
        },
        {
            key: Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_KEY,
            name: Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_NAME,
            value: getModuleBatTemp2Module14SamplingTempValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region InsulMeasurement
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [insulMeasurementPanelOpen, setInsulMeasurementPanelOpen] = useState(false);
    const [insulMeasurementChartMounted, setInsulMeasurementChartMounted] = useState(false);
    const [selectedInsulMeasurement, setSelectedInsulMeasurement] = useState<Array<React.Key>>([
        // Common.TSECO_IM_INSUL_HIGH_KEY,
        // Common.TSECO_IM_INSUL_LOW_KEY,
        // Common.TSECO_IM_BUS_VOLT_HIGH_KEY,
        // Common.TSECO_IM_BUS_VOLT_LOW_KEY,
        // Common.TSECO_IM_FAULT_LEVEL_KEY,
        // Common.TSECO_IM_ALARM_STATUS_KEY,
        // Common.TSECO_IM_LIFE_VALUE_KEY,
    ]);

    const [seriesChartDatasInsulMeasurement, setSeriesChartDatasInsulMeasurement] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartInsulMeasurementInsulHigh, setChartInsulMeasurementInsulHigh] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IM_INSUL_HIGH_NAME,
        data: [],
    });

    const [chartInsulMeasurementInsulLow, setChartInsulMeasurementInsulLow] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IM_INSUL_LOW_NAME,
        data: [],
    });

    const [chartInsulMeasurementBusVoltHigh, setChartInsulMeasurementBusVoltHigh] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IM_BUS_VOLT_HIGH_NAME,
        data: [],
    });

    const [chartInsulMeasurementBusVoltLow, setChartInsulMeasurementBusVoltLow] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IM_BUS_VOLT_LOW_NAME,
        data: [],
    });

    const [chartInsulMeasurementFaultLevel, setChartInsulMeasurementFaultLevel] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IM_FAULT_LEVEL_NAME,
        data: [],
    });

    const [chartInsulMeasurementAlarmStatus, setChartInsulMeasurementAlarmStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IM_ALARM_STATUS_NAME,
        data: [],
    });

    const [chartInsulMeasurementLifeValue, setChartInsulMeasurementLifeValue] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IM_LIFE_VALUE_NAME,
        data: [],
    });

    const getTabelTooltipInsulMeasurement = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_IM_INSUL_HIGH_KEY:
                return (
                    <>
                        <p>
                            절연 측정
                            <br />
                            CAN ID: 0x1819a3a4
                            <br />
                            Name: 절연저항 하이바이트
                        </p>
                        <p>Remarks: Ω/bit</p>
                    </>
                );
            case Common.TSECO_IM_INSUL_LOW_KEY:
                return (
                    <>
                        <p>
                            절연 측정
                            <br />
                            CAN ID: 0x1819a3a4
                            <br />
                            Name: 절연저항 로우바이트
                        </p>
                        <p>Remarks: Ω/bit</p>
                    </>
                );

            case Common.TSECO_IM_BUS_VOLT_HIGH_KEY:
                return (
                    <>
                        <p>
                            절연 측정
                            <br />
                            CAN ID: 0x1819a3a4
                            <br />
                            Name: 버스 전압 하이바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_IM_BUS_VOLT_LOW_KEY:
                return (
                    <>
                        <p>
                            절연 측정
                            <br />
                            CAN ID: 0x1819a3a4
                            <br />
                            Name: 버스 전압 로우바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_IM_FAULT_LEVEL_KEY:
                return (
                    <>
                        <p>
                            절연 측정
                            <br />
                            CAN ID: 0x1819a3a4
                            <br />
                            Name: 고장 등급
                        </p>
                        <p>Remarks: 1=1급 절연 고장, 2=2급 절연 고장, 3=3급 절연 고장</p>
                    </>
                );

            case Common.TSECO_IM_ALARM_STATUS_KEY:
                return (
                    <>
                        <p>
                            절연 측정
                            <br />
                            CAN ID: 0x185017F3
                            <br />
                            Name: 알람 상태
                        </p>
                        <p>Remarks: 170=절연 고장 없음, 85=절연 고장 있음</p>
                    </>
                );
            case Common.TSECO_IM_LIFE_VALUE_KEY:
                return (
                    <>
                        <p>
                            절연 측정
                            <br />
                            CAN ID: 0x1819a3a4
                            <br />
                            Name: Life 값
                        </p>
                        <p>Remarks: 0~255</p>
                    </>
                );
        }

        return null;
    };

    const convertFaultLevelRemark = (value: number) => {
        let valueString = "-";
        switch (value) {
            case 1:
                valueString = "1급 절연 고장";
                break;
            case 2:
                valueString = "2급 절연 고장";
                break;
            case 3:
                valueString = "3급 절연 고장";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertAlarmStatusRemark = (value: number) => {
        let valueString = "-";
        switch (value) {
            case 170:
                valueString = "절연 고장 없음";
                break;
            case 85:
                valueString = "절연 고장 있음";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getInsulMeasurementInsulHighValue = () => {
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_INSUL_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.insulMeasurement?.insulHigh, "KΩ/bit");
        }
        return "";
    };
    const getInsulMeasurementInsulLowValue = () => {
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_INSUL_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.insulMeasurement?.insulLow, "KΩ/bit");
        }
        return "";
    };

    const getInsulMeasurementBusVoltHighValue = () => {
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_BUS_VOLT_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.insulMeasurement?.busVoltHigh, "V/bit");
        }
        return "";
    };

    const getInsulMeasurementBusVoltLowValue = () => {
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_BUS_VOLT_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.insulMeasurement?.busVoltLow, "V/bit");
        }
        return "";
    };

    const getInsulMeasurementFaultLevelValue = () => {
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_FAULT_LEVEL_KEY) && currentCanData !== undefined) {
            return convertFaultLevelRemark(currentCanData.insulMeasurement?.faultLevel);
        }
        return "";
    };

    const getInsulMeasurementAlarmStatusValue = () => {
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_ALARM_STATUS_KEY) && currentCanData !== undefined) {
            return convertAlarmStatusRemark(currentCanData.insulMeasurement?.alarmStatus);
        }
        return "";
    };

    const getInsulMeasurementLifeValueValue = () => {
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_LIFE_VALUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.insulMeasurement?.lifeValue, "");
        }
        return "";
    };

    const onChangeInsulMeasurement = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedInsulMeasurement(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasInsulMeasurement: Array<TypeUtils.ChartData> = [];

        if (selectedInsulMeasurement.includes(Common.TSECO_IM_INSUL_HIGH_KEY)) chartDatasInsulMeasurement.push(chartInsulMeasurementInsulHigh);
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_INSUL_LOW_KEY)) chartDatasInsulMeasurement.push(chartInsulMeasurementInsulLow);
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_BUS_VOLT_HIGH_KEY)) chartDatasInsulMeasurement.push(chartInsulMeasurementBusVoltHigh);
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_BUS_VOLT_LOW_KEY)) chartDatasInsulMeasurement.push(chartInsulMeasurementBusVoltLow);
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_FAULT_LEVEL_KEY)) chartDatasInsulMeasurement.push(chartInsulMeasurementFaultLevel);
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_ALARM_STATUS_KEY)) chartDatasInsulMeasurement.push(chartInsulMeasurementAlarmStatus);
        if (selectedInsulMeasurement.includes(Common.TSECO_IM_LIFE_VALUE_KEY)) chartDatasInsulMeasurement.push(chartInsulMeasurementLifeValue);

        setSeriesChartDatasInsulMeasurement(chartDatasInsulMeasurement);
    }, [
        selectedInsulMeasurement,
        chartInsulMeasurementInsulHigh,
        chartInsulMeasurementInsulLow,
        chartInsulMeasurementBusVoltHigh,
        chartInsulMeasurementBusVoltLow,
        chartInsulMeasurementFaultLevel,
        chartInsulMeasurementAlarmStatus,
        chartInsulMeasurementLifeValue,
    ]);

    const tableDataInsulMeasurement: CanTableType[] = [
        {
            key: Common.TSECO_IM_INSUL_HIGH_KEY,
            name: Common.TSECO_IM_INSUL_HIGH_NAME,
            value: getInsulMeasurementInsulHighValue(),
        },
        {
            key: Common.TSECO_IM_INSUL_LOW_KEY,
            name: Common.TSECO_IM_INSUL_LOW_NAME,
            value: getInsulMeasurementInsulLowValue(),
        },
        {
            key: Common.TSECO_IM_BUS_VOLT_HIGH_KEY,
            name: Common.TSECO_IM_BUS_VOLT_HIGH_NAME,
            value: getInsulMeasurementBusVoltHighValue(),
        },
        {
            key: Common.TSECO_IM_BUS_VOLT_LOW_KEY,
            name: Common.TSECO_IM_BUS_VOLT_LOW_NAME,
            value: getInsulMeasurementBusVoltLowValue(),
        },
        {
            key: Common.TSECO_IM_FAULT_LEVEL_KEY,
            name: Common.TSECO_IM_FAULT_LEVEL_NAME,
            value: getInsulMeasurementFaultLevelValue(),
        },
        {
            key: Common.TSECO_IM_ALARM_STATUS_KEY,
            name: Common.TSECO_IM_ALARM_STATUS_NAME,
            value: getInsulMeasurementAlarmStatusValue(),
        },
        {
            key: Common.TSECO_IM_LIFE_VALUE_KEY,
            name: Common.TSECO_IM_LIFE_VALUE_NAME,
            value: getInsulMeasurementLifeValueValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VoltMeasurement1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [voltMeasurement1PanelOpen, setVoltMeasurement1PanelOpen] = useState(false);
    const [voltMeasurement1ChartMounted, setVoltMeasurement1ChartMounted] = useState(false);
    const [selectedVoltMeasurement1, setSelectedVoltMeasurement1] = useState<Array<React.Key>>([
        // Common.TSECO_VM1_CHANNEL1_HIGH_KEY,
        // Common.TSECO_VM1_CHANNEL1_LOW_KEY,
        // Common.TSECO_VM1_CHANNEL2_HIGH_KEY,
        // Common.TSECO_VM1_CHANNEL2_LOW_KEY,
        // Common.TSECO_VM1_CHANNEL3_HIGH_KEY,
        // Common.TSECO_VM1_CHANNEL3_LOW_KEY,
        // Common.TSECO_VM1_CHANNEL4_HIGH_KEY,
        // Common.TSECO_VM1_CHANNEL4_LOW_KEY,
    ]);

    const [seriesChartDatasVoltMeasurement1, setSeriesChartDatasVoltMeasurement1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVoltMeasurement1Channel1High, setChartVoltMeasurement1Channel1High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL1_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement1Channel1Low, setChartVoltMeasurement1Channel1Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL1_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement1Channel2High, setChartVoltMeasurement1Channel2High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL2_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement1Channel2Low, setChartVoltMeasurement1Channel2Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL2_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement1Channel3High, setChartVoltMeasurement1Channel3High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL3_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement1Channel3Low, setChartVoltMeasurement1Channel3Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL3_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement1Channel4High, setChartVoltMeasurement1Channel4High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL4_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement1Channel4Low, setChartVoltMeasurement1Channel4Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM1_CHANNEL4_LOW_NAME,
        data: [],
    });

    const getTabelTooltipVoltMeasurement1 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_VM1_CHANNEL1_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 1번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM1_CHANNEL1_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 1번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM1_CHANNEL2_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 2번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM1_CHANNEL2_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 2번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_VM1_CHANNEL3_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 3번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM1_CHANNEL3_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 3번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_VM1_CHANNEL4_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 4번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM1_CHANNEL4_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정1
                            <br />
                            CAN ID: 0x1819b3b5
                            <br />
                            Name: 4번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
        }

        return null;
    };

    const getVoltMeasurement1Channel1HighValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL1_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel1High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement1Channel1LowValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL1_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel1Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement1Channel2HighValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL2_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel2High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement1Channel2LowValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL2_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel2Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement1Channel3HighValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL3_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel3High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement1Channel3LowValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL3_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel3Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement1Channel4HighValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL4_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel4High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement1Channel4LowValue = () => {
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL4_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement1?.channel4Low, "V/bit");
        }
        return "";
    };

    const onChangeVoltMeasurement1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVoltMeasurement1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVoltMeasurement1: Array<TypeUtils.ChartData> = [];

        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL1_HIGH_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel1High);
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL1_LOW_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel1Low);
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL2_HIGH_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel2High);
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL2_LOW_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel2Low);
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL3_HIGH_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel3High);
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL3_LOW_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel3Low);
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL4_HIGH_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel4High);
        if (selectedVoltMeasurement1.includes(Common.TSECO_VM1_CHANNEL4_LOW_KEY)) chartDatasVoltMeasurement1.push(chartVoltMeasurement1Channel4Low);

        setSeriesChartDatasVoltMeasurement1(chartDatasVoltMeasurement1);
    }, [
        selectedVoltMeasurement1,
        chartVoltMeasurement1Channel1High,
        chartVoltMeasurement1Channel1Low,
        chartVoltMeasurement1Channel2High,
        chartVoltMeasurement1Channel2Low,
        chartVoltMeasurement1Channel3High,
        chartVoltMeasurement1Channel3Low,
        chartVoltMeasurement1Channel4High,
        chartVoltMeasurement1Channel4Low,
    ]);

    const tableDataVoltMeasurement1: CanTableType[] = [
        {
            key: Common.TSECO_VM1_CHANNEL1_HIGH_KEY,
            name: Common.TSECO_VM1_CHANNEL1_HIGH_NAME,
            value: getVoltMeasurement1Channel1HighValue(),
        },
        {
            key: Common.TSECO_VM1_CHANNEL1_LOW_KEY,
            name: Common.TSECO_VM1_CHANNEL1_LOW_NAME,
            value: getVoltMeasurement1Channel1LowValue(),
        },
        {
            key: Common.TSECO_VM1_CHANNEL2_HIGH_KEY,
            name: Common.TSECO_VM1_CHANNEL2_HIGH_NAME,
            value: getVoltMeasurement1Channel2HighValue(),
        },
        {
            key: Common.TSECO_VM1_CHANNEL2_LOW_KEY,
            name: Common.TSECO_VM1_CHANNEL2_LOW_NAME,
            value: getVoltMeasurement1Channel2LowValue(),
        },
        {
            key: Common.TSECO_VM1_CHANNEL3_HIGH_KEY,
            name: Common.TSECO_VM1_CHANNEL3_HIGH_NAME,
            value: getVoltMeasurement1Channel3HighValue(),
        },
        {
            key: Common.TSECO_VM1_CHANNEL3_LOW_KEY,
            name: Common.TSECO_VM1_CHANNEL3_LOW_NAME,
            value: getVoltMeasurement1Channel3LowValue(),
        },
        {
            key: Common.TSECO_VM1_CHANNEL4_HIGH_KEY,
            name: Common.TSECO_VM1_CHANNEL4_HIGH_NAME,
            value: getVoltMeasurement1Channel4HighValue(),
        },
        {
            key: Common.TSECO_VM1_CHANNEL4_LOW_KEY,
            name: Common.TSECO_VM1_CHANNEL4_LOW_NAME,
            value: getVoltMeasurement1Channel4LowValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VoltMeasurement2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [voltMeasurement2PanelOpen, setVoltMeasurement2PanelOpen] = useState(false);
    const [voltMeasurement2ChartMounted, setVoltMeasurement2ChartMounted] = useState(false);
    const [selectedVoltMeasurement2, setSelectedVoltMeasurement2] = useState<Array<React.Key>>([
        // Common.TSECO_VM2_CHANNEL5_HIGH_KEY,
        // Common.TSECO_VM2_CHANNEL5_LOW_KEY,
        // Common.TSECO_VM2_CHANNEL6_HIGH_KEY,
        // Common.TSECO_VM2_CHANNEL6_LOW_KEY,
        // Common.TSECO_VM2_CHANNEL7_HIGH_KEY,
        // Common.TSECO_VM2_CHANNEL7_LOW_KEY,
        // Common.TSECO_VM2_CHANNEL8_HIGH_KEY,
        // Common.TSECO_VM2_CHANNEL8_LOW_KEY,
    ]);

    const [seriesChartDatasVoltMeasurement2, setSeriesChartDatasVoltMeasurement2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVoltMeasurement2Channel5High, setChartVoltMeasurement2Channel5High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL5_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement2Channel5Low, setChartVoltMeasurement2Channel5Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL5_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement2Channel6High, setChartVoltMeasurement2Channel6High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL6_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement2Channel6Low, setChartVoltMeasurement2Channel6Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL6_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement2Channel7High, setChartVoltMeasurement2Channel7High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL7_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement2Channel7Low, setChartVoltMeasurement2Channel7Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL7_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement2Channel8High, setChartVoltMeasurement2Channel8High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL8_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement2Channel8Low, setChartVoltMeasurement2Channel8Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM2_CHANNEL8_LOW_NAME,
        data: [],
    });

    const getTabelTooltipVoltMeasurement2 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_VM2_CHANNEL5_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 5번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM2_CHANNEL5_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 5번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM2_CHANNEL6_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 6번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM2_CHANNEL6_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 6번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_VM2_CHANNEL7_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 7번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM2_CHANNEL7_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 7번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_VM2_CHANNEL8_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 8번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM2_CHANNEL8_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정2
                            <br />
                            CAN ID: 0x1819b3b6
                            <br />
                            Name: 8번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
        }

        return null;
    };

    const getVoltMeasurement2Channel5HighValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL5_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel5High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement2Channel5LowValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL5_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel5Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement2Channel6HighValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL6_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel6High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement2Channel6LowValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL6_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel6Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement2Channel7HighValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL7_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel7High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement2Channel7LowValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL7_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel7Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement2Channel8HighValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL8_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel8High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement2Channel8LowValue = () => {
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL8_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement2?.channel8Low, "V/bit");
        }
        return "";
    };

    const onChangeVoltMeasurement2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVoltMeasurement2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVoltMeasurement2: Array<TypeUtils.ChartData> = [];

        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL5_HIGH_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel5High);
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL5_LOW_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel5Low);
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL6_HIGH_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel6High);
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL6_LOW_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel6Low);
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL7_HIGH_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel7High);
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL7_LOW_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel7Low);
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL8_HIGH_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel8High);
        if (selectedVoltMeasurement2.includes(Common.TSECO_VM2_CHANNEL8_LOW_KEY)) chartDatasVoltMeasurement2.push(chartVoltMeasurement2Channel8Low);

        setSeriesChartDatasVoltMeasurement2(chartDatasVoltMeasurement2);
    }, [
        selectedVoltMeasurement2,
        chartVoltMeasurement2Channel5High,
        chartVoltMeasurement2Channel5Low,
        chartVoltMeasurement2Channel6High,
        chartVoltMeasurement2Channel6Low,
        chartVoltMeasurement2Channel7High,
        chartVoltMeasurement2Channel7Low,
        chartVoltMeasurement2Channel8High,
        chartVoltMeasurement2Channel8Low,
    ]);

    const tableDataVoltMeasurement2: CanTableType[] = [
        {
            key: Common.TSECO_VM2_CHANNEL5_HIGH_KEY,
            name: Common.TSECO_VM2_CHANNEL5_HIGH_NAME,
            value: getVoltMeasurement2Channel5HighValue(),
        },
        {
            key: Common.TSECO_VM2_CHANNEL5_LOW_KEY,
            name: Common.TSECO_VM2_CHANNEL5_LOW_NAME,
            value: getVoltMeasurement2Channel5LowValue(),
        },
        {
            key: Common.TSECO_VM2_CHANNEL6_HIGH_KEY,
            name: Common.TSECO_VM2_CHANNEL6_HIGH_NAME,
            value: getVoltMeasurement2Channel6HighValue(),
        },
        {
            key: Common.TSECO_VM2_CHANNEL6_LOW_KEY,
            name: Common.TSECO_VM2_CHANNEL6_LOW_NAME,
            value: getVoltMeasurement2Channel6LowValue(),
        },
        {
            key: Common.TSECO_VM2_CHANNEL7_HIGH_KEY,
            name: Common.TSECO_VM2_CHANNEL7_HIGH_NAME,
            value: getVoltMeasurement2Channel7HighValue(),
        },
        {
            key: Common.TSECO_VM2_CHANNEL7_LOW_KEY,
            name: Common.TSECO_VM2_CHANNEL7_LOW_NAME,
            value: getVoltMeasurement2Channel7LowValue(),
        },
        {
            key: Common.TSECO_VM2_CHANNEL8_HIGH_KEY,
            name: Common.TSECO_VM2_CHANNEL8_HIGH_NAME,
            value: getVoltMeasurement2Channel8HighValue(),
        },
        {
            key: Common.TSECO_VM2_CHANNEL8_LOW_KEY,
            name: Common.TSECO_VM2_CHANNEL8_LOW_NAME,
            value: getVoltMeasurement2Channel8LowValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VoltMeasurement3
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [voltMeasurement3PanelOpen, setVoltMeasurement3PanelOpen] = useState(false);
    const [voltMeasurement3ChartMounted, setVoltMeasurement3ChartMounted] = useState(false);
    const [selectedVoltMeasurement3, setSelectedVoltMeasurement3] = useState<Array<React.Key>>([
        // Common.TSECO_VM3_CHANNEL9_HIGH_KEY,
        // Common.TSECO_VM3_CHANNEL9_LOW_KEY,
        // Common.TSECO_VM3_CHANNEL10_HIGH_KEY,
        // Common.TSECO_VM3_CHANNEL10_LOW_KEY,
        // Common.TSECO_VM3_CHANNEL11_HIGH_KEY,
        // Common.TSECO_VM3_CHANNEL11_LOW_KEY,
        // Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_KEY,
        // Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_KEY,
    ]);

    const [seriesChartDatasVoltMeasurement3, setSeriesChartDatasVoltMeasurement3] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVoltMeasurement3Channel9High, setChartVoltMeasurement3Channel9High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_CHANNEL9_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement3Channel9Low, setChartVoltMeasurement3Channel9Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_CHANNEL9_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement3Channel10High, setChartVoltMeasurement3Channel10High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_CHANNEL10_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement3Channel10Low, setChartVoltMeasurement3Channel10Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_CHANNEL10_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement3Channel11High, setChartVoltMeasurement3Channel11High] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_CHANNEL11_HIGH_NAME,
        data: [],
    });

    const [chartVoltMeasurement3Channel11Low, setChartVoltMeasurement3Channel11Low] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_CHANNEL11_LOW_NAME,
        data: [],
    });

    const [chartVoltMeasurement3InterlockChannel0Status, setChartVoltMeasurement3InterlockChannel0Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_NAME,
        data: [],
    });

    const [chartVoltMeasurement3InterlockChannel1Status, setChartVoltMeasurement3InterlockChannel1Status] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipVoltMeasurement3 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_VM3_CHANNEL9_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 9번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM3_CHANNEL9_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 9번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM3_CHANNEL10_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 10번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM3_CHANNEL10_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 10번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_VM3_CHANNEL11_HIGH_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 11번째 전압 채널의 하이 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );
            case Common.TSECO_VM3_CHANNEL11_LOW_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 11번째 전압 채널의 로우 바이트
                        </p>
                        <p>Remarks: V/bit</p>
                    </>
                );

            case Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 인터락 신호 채널0 상태
                        </p>
                        <p>Remarks: 85=알람, 170=정상</p>
                    </>
                );
            case Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_KEY:
                return (
                    <>
                        <p>
                            전압 측정3
                            <br />
                            CAN ID: 0x1819b3b7
                            <br />
                            Name: 인터락 신호 채널1 상태
                        </p>
                        <p>Remarks: 85=알람, 170=정상</p>
                    </>
                );
        }

        return null;
    };

    const convertInterlockChannelRemark = (value: number) => {
        let valueString = "정상";
        switch (value) {
            case 85:
                valueString = "알람";
                break;
            case 170:
                valueString = "정상";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getVoltMeasurement3Channel9HighValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL9_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement3?.channel9High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement3Channel9LowValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL9_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement3?.channel9Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement3Channel10HighValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL10_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement3?.channel10High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement3Channel10LowValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL10_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement3?.channel10Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement3Channel11HighValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL11_HIGH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement3?.channel11High, "V/bit");
        }
        return "";
    };
    const getVoltMeasurement3Channel11LowValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL11_LOW_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.voltMeasurement3?.channel11Low, "V/bit");
        }
        return "";
    };

    const getVoltMeasurement3InterlockChannel0StatusValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_KEY) && currentCanData !== undefined) {
            return convertInterlockChannelRemark(currentCanData.voltMeasurement3?.interlockChannel0Status);
        }
        return "";
    };
    const getVoltMeasurement3InterlockChannel1StatusValue = () => {
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_KEY) && currentCanData !== undefined) {
            return convertInterlockChannelRemark(currentCanData.voltMeasurement3?.interlockChannel1Status);
        }
        return "";
    };

    const onChangeVoltMeasurement3 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVoltMeasurement3(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVoltMeasurement3: Array<TypeUtils.ChartData> = [];

        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL9_HIGH_KEY)) chartDatasVoltMeasurement3.push(chartVoltMeasurement3Channel9High);
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL9_LOW_KEY)) chartDatasVoltMeasurement3.push(chartVoltMeasurement3Channel9Low);
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL10_HIGH_KEY))
            chartDatasVoltMeasurement3.push(chartVoltMeasurement3Channel10High);
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL10_LOW_KEY)) chartDatasVoltMeasurement3.push(chartVoltMeasurement3Channel10Low);
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL11_HIGH_KEY))
            chartDatasVoltMeasurement3.push(chartVoltMeasurement3Channel11High);
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_CHANNEL11_LOW_KEY)) chartDatasVoltMeasurement3.push(chartVoltMeasurement3Channel11Low);
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_KEY))
            chartDatasVoltMeasurement3.push(chartVoltMeasurement3InterlockChannel0Status);
        if (selectedVoltMeasurement3.includes(Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_KEY))
            chartDatasVoltMeasurement3.push(chartVoltMeasurement3InterlockChannel1Status);

        setSeriesChartDatasVoltMeasurement3(chartDatasVoltMeasurement3);
    }, [
        selectedVoltMeasurement3,
        chartVoltMeasurement3Channel9High,
        chartVoltMeasurement3Channel9Low,
        chartVoltMeasurement3Channel10High,
        chartVoltMeasurement3Channel10Low,
        chartVoltMeasurement3Channel11High,
        chartVoltMeasurement3Channel11Low,
        chartVoltMeasurement3InterlockChannel0Status,
        chartVoltMeasurement3InterlockChannel1Status,
    ]);

    const tableDataVoltMeasurement3: CanTableType[] = [
        {
            key: Common.TSECO_VM3_CHANNEL9_HIGH_KEY,
            name: Common.TSECO_VM3_CHANNEL9_HIGH_NAME,
            value: getVoltMeasurement3Channel9HighValue(),
        },
        {
            key: Common.TSECO_VM3_CHANNEL9_LOW_KEY,
            name: Common.TSECO_VM3_CHANNEL9_LOW_NAME,
            value: getVoltMeasurement3Channel9LowValue(),
        },
        {
            key: Common.TSECO_VM3_CHANNEL10_HIGH_KEY,
            name: Common.TSECO_VM3_CHANNEL10_HIGH_NAME,
            value: getVoltMeasurement3Channel10HighValue(),
        },
        {
            key: Common.TSECO_VM3_CHANNEL10_LOW_KEY,
            name: Common.TSECO_VM3_CHANNEL10_LOW_NAME,
            value: getVoltMeasurement3Channel10LowValue(),
        },
        {
            key: Common.TSECO_VM3_CHANNEL11_HIGH_KEY,
            name: Common.TSECO_VM3_CHANNEL11_HIGH_NAME,
            value: getVoltMeasurement3Channel11HighValue(),
        },
        {
            key: Common.TSECO_VM3_CHANNEL11_LOW_KEY,
            name: Common.TSECO_VM3_CHANNEL11_LOW_NAME,
            value: getVoltMeasurement3Channel11LowValue(),
        },
        {
            key: Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_KEY,
            name: Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_NAME,
            value: getVoltMeasurement3InterlockChannel0StatusValue(),
        },
        {
            key: Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_KEY,
            name: Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_NAME,
            value: getVoltMeasurement3InterlockChannel1StatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VehicleInfo
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vehicleInfoPanelOpen, setVehicleInfoPanelOpen] = useState(false);
    const [vehicleInfoChartMounted, setVehicleInfoChartMounted] = useState(false);
    const [selectedVehicleInfo, setSelectedVehicleInfo] = useState<Array<React.Key>>([
        // Common.TSECO_VI_PARKING_BRAKE_STATUS_KEY,
        // Common.TSECO_VI_SPEED_KEY,
        // Common.TSECO_VI_FOOT_BRAKE_STATUS_KEY,
        // Common.TSECO_VI_FRONT_DOOR_SWITCH_KEY,
        // Common.TSECO_VI_BACK_DOOR_SWITCH_KEY,
        // Common.TSECO_VI_BRAKE_AIR_PRESSURE1_KEY,
        // Common.TSECO_VI_BRAKE_AIR_PRESSURE2_KEY,
        // Common.TSECO_VI_BRAKE_AIR_PRESSURE3_KEY,
    ]);

    const [seriesChartDatasVehicleInfo, setSeriesChartDatasVehicleInfo] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVehicleInfoParkingBrakeStatus, setChartVehicleInfoParkingBrakeStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_PARKING_BRAKE_STATUS_NAME,
        data: [],
    });

    const [chartVehicleInfoSpeed, setChartVehicleInfoSpeed] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_SPEED_NAME,
        data: [],
    });

    const [chartVehicleInfoFootBrakeStatus, setChartVehicleInfoFootBrakeStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_FOOT_BRAKE_STATUS_NAME,
        data: [],
    });

    const [chartVehicleInfoFrontDoorSwitch, setChartVehicleInfoFrontDoorSwitch] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_FRONT_DOOR_SWITCH_NAME,
        data: [],
    });

    const [chartVehicleInfoBackDoorSwitch, setChartVehicleInfoFuelFilter] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_BACK_DOOR_SWITCH_NAME,
        data: [],
    });

    const [chartVehicleInfoBrakeAirPressure1, setChartVehicleInfoBrakeAirPressure1] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_BRAKE_AIR_PRESSURE1_NAME,
        data: [],
    });

    const [chartVehicleInfoBrakeAirPressure2, setChartVehicleInfoBrakeAirPressure2] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_BRAKE_AIR_PRESSURE2_NAME,
        data: [],
    });

    const [chartVehicleInfoBrakeAirPressure3, setChartVehicleInfoBrakeAirPressure3] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_VI_BRAKE_AIR_PRESSURE3_NAME,
        data: [],
    });

    const getTabelTooltipVehicleInfo = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_VI_PARKING_BRAKE_STATUS_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 파킹 브레이크
                        </p>
                        <p>Remarks: 0=OFF, 1=ON, 2=에러, 3=사용 불가 또는 미설치</p>
                    </>
                );
            case Common.TSECO_VI_SPEED_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 속도
                        </p>
                        <p>Remarks: km/h, 0~250.996</p>
                    </>
                );
            case Common.TSECO_VI_FOOT_BRAKE_STATUS_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 브레이크
                        </p>
                        <p>Remarks: 0=OFF, 1=ON, 2=에러, 3=사용 불가 또는 미설치</p>
                    </>
                );
            case Common.TSECO_VI_FRONT_DOOR_SWITCH_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 앞문 스위치
                        </p>
                        <p>Remarks: 0=OFF, 1=ON, 3=무효</p>
                    </>
                );

            case Common.TSECO_VI_BACK_DOOR_SWITCH_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 뒷문 스위치
                        </p>
                        <p>Remarks: 0=OFF, 1=ON, 3=무효</p>
                    </>
                );
            case Common.TSECO_VI_BRAKE_AIR_PRESSURE1_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 브레이크 에어 압력1
                        </p>
                        <p>Remarks: 0~2.50, 255=무효</p>
                    </>
                );

            case Common.TSECO_VI_BRAKE_AIR_PRESSURE2_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 브레이크 에어 압력2
                        </p>
                        <p>Remarks: 0~2.50, 255=무효</p>
                    </>
                );
            case Common.TSECO_VI_BRAKE_AIR_PRESSURE3_KEY:
                return (
                    <>
                        <p>
                            Vehicle Information Message (CCVS)
                            <br />
                            CAN ID: 0x18FEF117
                            <br />
                            Name: 브레이크 에어 압력3
                        </p>
                        <p>Remarks: 0~2.50, 255=무효</p>
                    </>
                );
        }

        return null;
    };

    const convertBrakeRemark = (value: number) => {
        let valueString = "OFF";
        switch (value) {
            case 0:
                valueString = "OFF";
                break;
            case 1:
                valueString = "ON";
                break;
            case 2:
                valueString = "에러";
                break;
            case 3:
                valueString = "사용 불가 또는 미설치";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertDoorSwitchRemark = (value: number) => {
        let valueString = "OFF";
        switch (value) {
            case 0:
                valueString = "OFF";
                break;
            case 1:
                valueString = "ON";
                break;
            case 3:
                valueString = "무효";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertBrakeAirPressRemark = (value: number) => {
        let valueString = value ? value?.toString() : "-";
        switch (value) {
            case 255:
                valueString = "무효";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getVehicleInfoParkingBrakeStatusValue = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_PARKING_BRAKE_STATUS_KEY) && currentCanData !== undefined) {
            return convertBrakeRemark(currentCanData.vehicleInfo?.parkingBrakeStatus);
        }
        return "";
    };
    const getVehicleInfoSpeedValue = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleInfo?.speed, "km/h");
        }
        return "";
    };

    const getVehicleInfoFootBrakeStatusValue = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_FOOT_BRAKE_STATUS_KEY) && currentCanData !== undefined) {
            return convertBrakeRemark(currentCanData.vehicleInfo?.footBrakeStatus);
        }
        return "";
    };
    const getVehicleInfoFrontDoorSwitchValue = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_FRONT_DOOR_SWITCH_KEY) && currentCanData !== undefined) {
            return convertDoorSwitchRemark(currentCanData.vehicleInfo?.frontDoorSwitch);
        }
        return "";
    };

    const getVehicleInfoBackDoorSwitchValue = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BACK_DOOR_SWITCH_KEY) && currentCanData !== undefined) {
            return convertDoorSwitchRemark(currentCanData.vehicleInfo?.backDoorSwitch);
        }
        return "";
    };
    const getVehicleInfoBrakeAirPressure1Value = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BRAKE_AIR_PRESSURE1_KEY) && currentCanData !== undefined) {
            return convertBrakeAirPressRemark(currentCanData.vehicleInfo?.brakeAirPressure1);
        }
        return "";
    };

    const getVehicleInfoBrakeAirPressure2Value = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BRAKE_AIR_PRESSURE2_KEY) && currentCanData !== undefined) {
            return convertBrakeAirPressRemark(currentCanData.vehicleInfo?.brakeAirPressure2);
        }
        return "";
    };
    const getVehicleInfoBrakeAirPressure3Value = () => {
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BRAKE_AIR_PRESSURE3_KEY) && currentCanData !== undefined) {
            return convertBrakeAirPressRemark(currentCanData.vehicleInfo?.brakeAirPressure3);
        }
        return "";
    };

    const onChangeVehicleInfo = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVehicleInfo(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVehicleInfo: Array<TypeUtils.ChartData> = [];

        if (selectedVehicleInfo.includes(Common.TSECO_VI_PARKING_BRAKE_STATUS_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoParkingBrakeStatus);
        if (selectedVehicleInfo.includes(Common.TSECO_VI_SPEED_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoSpeed);
        if (selectedVehicleInfo.includes(Common.TSECO_VI_FOOT_BRAKE_STATUS_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoFootBrakeStatus);
        if (selectedVehicleInfo.includes(Common.TSECO_VI_FRONT_DOOR_SWITCH_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoFrontDoorSwitch);
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BACK_DOOR_SWITCH_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoBackDoorSwitch);
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BRAKE_AIR_PRESSURE1_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoBrakeAirPressure1);
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BRAKE_AIR_PRESSURE2_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoBrakeAirPressure2);
        if (selectedVehicleInfo.includes(Common.TSECO_VI_BRAKE_AIR_PRESSURE3_KEY)) chartDatasVehicleInfo.push(chartVehicleInfoBrakeAirPressure3);

        setSeriesChartDatasVehicleInfo(chartDatasVehicleInfo);
    }, [
        selectedVehicleInfo,
        chartVehicleInfoParkingBrakeStatus,
        chartVehicleInfoSpeed,
        chartVehicleInfoFootBrakeStatus,
        chartVehicleInfoFrontDoorSwitch,
        chartVehicleInfoBackDoorSwitch,
        chartVehicleInfoBrakeAirPressure1,
        chartVehicleInfoBrakeAirPressure2,
        chartVehicleInfoBrakeAirPressure3,
    ]);

    const tableDataVehicleInfo: CanTableType[] = [
        {
            key: Common.TSECO_VI_PARKING_BRAKE_STATUS_KEY,
            name: Common.TSECO_VI_PARKING_BRAKE_STATUS_NAME,
            value: getVehicleInfoParkingBrakeStatusValue(),
        },
        {
            key: Common.TSECO_VI_SPEED_KEY,
            name: Common.TSECO_VI_SPEED_NAME,
            value: getVehicleInfoSpeedValue(),
        },
        {
            key: Common.TSECO_VI_FOOT_BRAKE_STATUS_KEY,
            name: Common.TSECO_VI_FOOT_BRAKE_STATUS_NAME,
            value: getVehicleInfoFootBrakeStatusValue(),
        },
        {
            key: Common.TSECO_VI_FRONT_DOOR_SWITCH_KEY,
            name: Common.TSECO_VI_FRONT_DOOR_SWITCH_NAME,
            value: getVehicleInfoFrontDoorSwitchValue(),
        },
        {
            key: Common.TSECO_VI_BACK_DOOR_SWITCH_KEY,
            name: Common.TSECO_VI_BACK_DOOR_SWITCH_NAME,
            value: getVehicleInfoBackDoorSwitchValue(),
        },
        {
            key: Common.TSECO_VI_BRAKE_AIR_PRESSURE1_KEY,
            name: Common.TSECO_VI_BRAKE_AIR_PRESSURE1_NAME,
            value: getVehicleInfoBrakeAirPressure1Value(),
        },
        {
            key: Common.TSECO_VI_BRAKE_AIR_PRESSURE2_KEY,
            name: Common.TSECO_VI_BRAKE_AIR_PRESSURE2_NAME,
            value: getVehicleInfoBrakeAirPressure2Value(),
        },
        {
            key: Common.TSECO_VI_BRAKE_AIR_PRESSURE3_KEY,
            name: Common.TSECO_VI_BRAKE_AIR_PRESSURE3_NAME,
            value: getVehicleInfoBrakeAirPressure3Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region DashboardInfo2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [dashboardInfo2PanelOpen, setDashboardInfo2PanelOpen] = useState(false);
    const [dashboardInfo2ChartMounted, setDashboardInfo2ChartMounted] = useState(false);
    const [selectedDashboardInfo2, setSelectedDashboardInfo2] = useState<Array<React.Key>>([
        // Common.TSECO_DI2_AIR_PRESSURE2_KEY,
        // Common.TSECO_DI2_HYDRAULIC_KEY,
        // Common.TSECO_DI2_COOLANT_LEVEL_KEY,
        // Common.TSECO_DI2_AIR_CLEENER_FILTER_KEY,
        // Common.TSECO_DI2_FUEL_FILTER_KEY,
        // Common.TSECO_DI2_OIL_FILTER_KEY,
        // Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_KEY,
        // Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_KEY,
        // Common.TSECO_DI2_ENGINE_KEY,
        // Common.TSECO_DI2_CHARGE_SIGN_KEY,
        // Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_KEY,
        // Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_KEY,
        // Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_KEY,
        // Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_KEY,
        // Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_KEY,
        // Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_KEY,
        // Common.TSECO_DI2_WATER_TEMP_KEY,
        // Common.TSECO_DI2_VOLT_KEY,
        // Common.TSECO_DI2_CHECK_BYTE_KEY,
    ]);

    const [seriesChartDatasDashboardInfo2, setSeriesChartDatasDashboardInfo2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartDashboardInfo2AirPressure2, setChartDashboardInfo2AirPressure2] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_AIR_PRESSURE2_NAME,
        data: [],
    });

    const [chartDashboardInfo2Hydraulic, setChartDashboardInfo2Hydraulic] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_HYDRAULIC_NAME,
        data: [],
    });

    const [chartDashboardInfo2CoolantLevel, setChartDashboardInfo2CoolantLevel] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_COOLANT_LEVEL_NAME,
        data: [],
    });

    const [chartDashboardInfo2AirCleanerFilter, setChartDashboardInfo2AirCleanerFilter] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_AIR_CLEENER_FILTER_NAME,
        data: [],
    });

    const [chartDashboardInfo2FuelFilter, setChartDashboardInfo2FuelFilter] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_FUEL_FILTER_NAME,
        data: [],
    });

    const [chartDashboardInfo2OilFilter, setChartDashboardInfo2OilFilter] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_OIL_FILTER_NAME,
        data: [],
    });

    const [chartDashboardInfo2WiperHighSpeedGear, setChartDashboardInfo2WiperHighSpeedGear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2WiperLowSpeedGear, setChartDashboardInfo2WiperLowSpeedGear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2Engine, setChartDashboardInfo2Engine] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_ENGINE_NAME,
        data: [],
    });

    const [chartDashboardInfo2ChargeSign, setChartDashboardInfo2ChargeSign] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_CHARGE_SIGN_NAME,
        data: [],
    });

    const [chartDashboardInfo2LeftFrontBrakePadWear, setChartDashboardInfo2LeftFrontBrakePadWear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2LeftMiddleBrakePadWear, setChartDashboardInfo2LeftMiddleBrakePadWear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2LeftBackBrakePadWear, setChartDashboardInfo2LeftBackBrakePadWear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2RightFrontBrakePadWear, setChartDashboardInfo2RightFrontBrakePadWear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2RightMiddleBrakePadWear, setChartDashboardInfo2RightMiddleBrakePadWear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2RightBackBrakePadWear, setChartDashboardInfo2RightBackBrakePadWear] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_NAME,
        data: [],
    });

    const [chartDashboardInfo2WaterTemp, setChartDashboardInfo2WaterTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_WATER_TEMP_NAME,
        data: [],
    });

    const [chartDashboardInfo2Volt, setChartDashboardInfo2Volt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_VOLT_NAME,
        data: [],
    });

    const [chartDashboardInfo2CheckByte, setChartDashboardInfo2CheckByte] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DI2_CHECK_BYTE_NAME,
        data: [],
    });

    const getTabelTooltipDashboardInfo2 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_DI2_AIR_PRESSURE2_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 에어 압력2 알람
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_HYDRAULIC_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 유압 알람
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_COOLANT_LEVEL_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 냉각수위 알람
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_AIR_CLEENER_FILTER_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 공기 정화 필터 알람
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_FUEL_FILTER_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 연료 필터 경보
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_OIL_FILTER_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 오일 필터 경보
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 와이퍼 고속 기어
                        </p>
                        <p>Remarks: 0=꺼짐, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 와이퍼 저속 기어
                        </p>
                        <p>Remarks: 0=꺼짐, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_ENGINE_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 엔진 진단
                        </p>
                        <p>Remarks: 0=꺼짐, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_CHARGE_SIGN_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 충전 표시(작동 신호)
                        </p>
                        <p>Remarks: 0=꺼짐, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 좌측 앞 브레이크 패드 마모
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 좌측 앞 브레이크 패드 마모
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 좌측 뒷 브레이크 패드 마모
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 우측 앞 브레이크 패드 마모
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 우측 중간 브레이크 패드 마모
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 우측 뒷 브레이크 패드 마모
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_WATER_TEMP_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 수온 알람
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );

            case Common.TSECO_DI2_VOLT_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 전압 알람
                        </p>
                        <p>Remarks: 0=정상, 1=켜짐, 2=알람, 3=유효하지 않음</p>
                    </>
                );
            case Common.TSECO_DI2_CHECK_BYTE_KEY:
                return (
                    <>
                        <p>
                            계기판 작동 2
                            <br />
                            CAN ID: 0x18FA0517
                            <br />
                            Name: 검증 바이트
                        </p>
                        <p>Remarks: 0~255</p>
                    </>
                );
        }

        return null;
    };

    const convertAirPressAlarmRemark = (value: number) => {
        let valueString = "정상";
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "켜짐";
                break;
            case 2:
                valueString = "알람";
                break;
            case 3:
                valueString = "유효하지 않음";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertWiperGearRemark = (value: number) => {
        let valueString = "꺼짐";
        switch (value) {
            case 0:
                valueString = "꺼짐";
                break;
            case 1:
                valueString = "켜짐";
                break;
            case 2:
                valueString = "알람";
                break;
            case 3:
                valueString = "유효하지 않음";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getDashboardInfo2AirPressure2Value = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_AIR_PRESSURE2_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.airPressure2);
        }
        return "";
    };
    const getDashboardInfo2HydraulicValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_HYDRAULIC_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.hydraulic);
        }
        return "";
    };

    const getDashboardInfo2CoolantLevelValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_COOLANT_LEVEL_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.coolantLevel);
        }
        return "";
    };
    const getDashboardInfo2AirCleanerFilterValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_AIR_CLEENER_FILTER_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.airCleanerFilter);
        }
        return "";
    };

    const getDashboardInfo2FuelFilterValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_FUEL_FILTER_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.fuelFilter);
        }
        return "";
    };
    const getDashboardInfo2OilFilterValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_OIL_FILTER_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.oilFilter);
        }
        return "";
    };

    const getDashboardInfo2WiperHighSpeedGearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_KEY) && currentCanData !== undefined) {
            return convertWiperGearRemark(currentCanData.dashboardInfo2?.wiperHighSpeedGear);
        }
        return "";
    };
    const getDashboardInfo2WiperLowSpeedGearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_KEY) && currentCanData !== undefined) {
            return convertWiperGearRemark(currentCanData.dashboardInfo2?.wiperLowSpeedGear);
        }
        return "";
    };

    const getDashboardInfo2EngineValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_ENGINE_KEY) && currentCanData !== undefined) {
            return convertWiperGearRemark(currentCanData.dashboardInfo2?.engine);
        }
        return "";
    };
    const getDashboardInfo2ChargeSignValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_CHARGE_SIGN_KEY) && currentCanData !== undefined) {
            return convertWiperGearRemark(currentCanData.dashboardInfo2?.chargeSign);
        }
        return "";
    };
    const getDashboardInfo2LeftFrontBrakePadWearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.leftFrontBrakePadWear);
        }
        return "";
    };
    const getDashboardInfo2LeftMiddleBrakePadWearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.leftMiddleBrakePadWear);
        }
        return "";
    };

    const getDashboardInfo2LeftBackBrakePadWearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.leftBackBrakePadWear);
        }
        return "";
    };
    const getDashboardInfo2RightFrontBrakePadWearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.rightFrontBrakePadWear);
        }
        return "";
    };

    const getDashboardInfo2RightMiddleBrakePadWearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.rightMiddleBrakePadWear);
        }
        return "";
    };
    const getDashboardInfo2RightBackBrakePadWearValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.rightBackBrakePadWear);
        }
        return "";
    };

    const getDashboardInfo2WaterTempValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_WATER_TEMP_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.waterTemp);
        }
        return "";
    };

    const getDashboardInfo2VoltValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_VOLT_KEY) && currentCanData !== undefined) {
            return convertAirPressAlarmRemark(currentCanData.dashboardInfo2?.volt);
        }
        return "";
    };

    const getDashboardInfo2CheckByteValue = () => {
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_CHECK_BYTE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dashboardInfo2?.checkByte, "");
        }
        return "";
    };

    const onChangeDashboardInfo2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedDashboardInfo2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasDashboardInfo2: Array<TypeUtils.ChartData> = [];

        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_AIR_PRESSURE2_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2AirPressure2);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_HYDRAULIC_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2Hydraulic);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_COOLANT_LEVEL_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2CoolantLevel);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_AIR_CLEENER_FILTER_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2AirCleanerFilter);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_FUEL_FILTER_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2FuelFilter);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_OIL_FILTER_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2OilFilter);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2WiperHighSpeedGear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2WiperLowSpeedGear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_ENGINE_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2Engine);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_CHARGE_SIGN_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2ChargeSign);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2LeftFrontBrakePadWear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2LeftMiddleBrakePadWear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2LeftBackBrakePadWear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2RightFrontBrakePadWear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2RightMiddleBrakePadWear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_KEY))
            chartDatasDashboardInfo2.push(chartDashboardInfo2RightBackBrakePadWear);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_WATER_TEMP_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2WaterTemp);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_VOLT_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2Volt);
        if (selectedDashboardInfo2.includes(Common.TSECO_DI2_CHECK_BYTE_KEY)) chartDatasDashboardInfo2.push(chartDashboardInfo2CheckByte);

        setSeriesChartDatasDashboardInfo2(chartDatasDashboardInfo2);
    }, [
        selectedDashboardInfo2,
        chartDashboardInfo2AirPressure2,
        chartDashboardInfo2Hydraulic,
        chartDashboardInfo2CoolantLevel,
        chartDashboardInfo2AirCleanerFilter,
        chartDashboardInfo2FuelFilter,
        chartDashboardInfo2OilFilter,
        chartDashboardInfo2WiperHighSpeedGear,
        chartDashboardInfo2WiperLowSpeedGear,
        chartDashboardInfo2Engine,
        chartDashboardInfo2ChargeSign,
        chartDashboardInfo2LeftFrontBrakePadWear,
        chartDashboardInfo2LeftMiddleBrakePadWear,
        chartDashboardInfo2LeftBackBrakePadWear,
        chartDashboardInfo2RightFrontBrakePadWear,
        chartDashboardInfo2RightMiddleBrakePadWear,
        chartDashboardInfo2RightBackBrakePadWear,
        chartDashboardInfo2WaterTemp,
        chartDashboardInfo2Volt,
        chartDashboardInfo2CheckByte,
    ]);

    const tableDataDashboardInfo2: CanTableType[] = [
        {
            key: Common.TSECO_DI2_AIR_PRESSURE2_KEY,
            name: Common.TSECO_DI2_AIR_PRESSURE2_NAME,
            value: getDashboardInfo2AirPressure2Value(),
        },
        {
            key: Common.TSECO_DI2_HYDRAULIC_KEY,
            name: Common.TSECO_DI2_HYDRAULIC_NAME,
            value: getDashboardInfo2HydraulicValue(),
        },
        {
            key: Common.TSECO_DI2_COOLANT_LEVEL_KEY,
            name: Common.TSECO_DI2_COOLANT_LEVEL_NAME,
            value: getDashboardInfo2CoolantLevelValue(),
        },
        {
            key: Common.TSECO_DI2_AIR_CLEENER_FILTER_KEY,
            name: Common.TSECO_DI2_AIR_CLEENER_FILTER_NAME,
            value: getDashboardInfo2AirCleanerFilterValue(),
        },
        {
            key: Common.TSECO_DI2_FUEL_FILTER_KEY,
            name: Common.TSECO_DI2_FUEL_FILTER_NAME,
            value: getDashboardInfo2FuelFilterValue(),
        },
        {
            key: Common.TSECO_DI2_OIL_FILTER_KEY,
            name: Common.TSECO_DI2_OIL_FILTER_NAME,
            value: getDashboardInfo2OilFilterValue(),
        },
        {
            key: Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_KEY,
            name: Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_NAME,
            value: getDashboardInfo2WiperHighSpeedGearValue(),
        },
        {
            key: Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_KEY,
            name: Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_NAME,
            value: getDashboardInfo2WiperLowSpeedGearValue(),
        },

        {
            key: Common.TSECO_DI2_ENGINE_KEY,
            name: Common.TSECO_DI2_ENGINE_NAME,
            value: getDashboardInfo2EngineValue(),
        },
        {
            key: Common.TSECO_DI2_CHARGE_SIGN_KEY,
            name: Common.TSECO_DI2_CHARGE_SIGN_NAME,
            value: getDashboardInfo2ChargeSignValue(),
        },
        {
            key: Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_KEY,
            name: Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_NAME,
            value: getDashboardInfo2LeftFrontBrakePadWearValue(),
        },
        {
            key: Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_KEY,
            name: Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_NAME,
            value: getDashboardInfo2LeftMiddleBrakePadWearValue(),
        },
        {
            key: Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_KEY,
            name: Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_NAME,
            value: getDashboardInfo2LeftBackBrakePadWearValue(),
        },
        {
            key: Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_KEY,
            name: Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_NAME,
            value: getDashboardInfo2RightFrontBrakePadWearValue(),
        },
        {
            key: Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_KEY,
            name: Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_NAME,
            value: getDashboardInfo2RightMiddleBrakePadWearValue(),
        },
        {
            key: Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_KEY,
            name: Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_NAME,
            value: getDashboardInfo2RightBackBrakePadWearValue(),
        },
        {
            key: Common.TSECO_DI2_WATER_TEMP_KEY,
            name: Common.TSECO_DI2_WATER_TEMP_NAME,
            value: getDashboardInfo2WaterTempValue(),
        },
        {
            key: Common.TSECO_DI2_VOLT_KEY,
            name: Common.TSECO_DI2_VOLT_NAME,
            value: getDashboardInfo2VoltValue(),
        },
        {
            key: Common.TSECO_DI2_CHECK_BYTE_KEY,
            name: Common.TSECO_DI2_CHECK_BYTE_NAME,
            value: getDashboardInfo2CheckByteValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BatCoolingSys1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batCoolingSys1PanelOpen, setBatCoolingSys1PanelOpen] = useState(false);
    const [batCoolingSys1ChartMounted, setBatCoolingSys1ChartMounted] = useState(false);
    const [selectedBatCoolingSys1, setSelectedBatCoolingSys1] = useState<Array<React.Key>>([
        // Common.TSECO_BCS1_BMS_MODE_KEY,
        // Common.TSECO_BCS1_HIGH_VOLT_REQ_KEY,
        // Common.TSECO_BCS1_CHARGE_STATUS_KEY,
        // Common.TSECO_BCS1_BMS_HVR_STATUS_KEY,
    ]);

    const [seriesChartDatasBatCoolingSys1, setSeriesChartDatasBatCoolingSys1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatCoolingSys1BmsMode, setChartBatCoolingSys1BmsMode] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS1_BMS_MODE_NAME,
        data: [],
    });

    const [chartBatCoolingSys1HighVoltReq, setChartBatCoolingSys1HighVoltReq] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS1_HIGH_VOLT_REQ_NAME,
        data: [],
    });

    const [chartBatCoolingSys1ChargeStatus, setChartBatCoolingSys1ChargeStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS1_CHARGE_STATUS_NAME,
        data: [],
    });

    const [chartBatCoolingSys1BmsHvrStatus, setChartBatCoolingSys1BmsHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS1_BMS_HVR_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipBatCoolingSys1 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_BCS1_BMS_MODE_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 1
                            <br />
                            CAN ID: 0x18F3C097
                            <br />
                            Name: BMS 설정 모드
                        </p>
                        <p>Remarks: 0=종료 모드, 1=냉각 모드, 2=히팅 모드, 3=자동 순환 모드</p>
                    </>
                );
            case Common.TSECO_BCS1_HIGH_VOLT_REQ_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 1
                            <br />
                            CAN ID: 0x18F3C097
                            <br />
                            Name: 고압 요청
                        </p>
                        <p>Remarks: 0=고전압 차단 요청, 1=고전압 요청, 2=무효 상태, 3=무효 상태</p>
                    </>
                );

            case Common.TSECO_BCS1_CHARGE_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 1
                            <br />
                            CAN ID: 0x18F3C097
                            <br />
                            Name: 충전 상태
                        </p>
                        <p>Remarks: 0=비충전 모드, 1=충전 모드, 2=무효 상태, 3=무효 상태</p>
                    </>
                );

            case Common.TSECO_BCS1_BMS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 1
                            <br />
                            CAN ID: 0x18F3C097
                            <br />
                            Name: BMS 고압 릴레이 상태
                        </p>
                        <p>Remarks: 0=떨어짐 상태, 1=붙음 상태, 2=무효 상태, 3=무효 상태</p>
                    </>
                );
        }

        return null;
    };

    const convertBmsModeRemark = (value: number) => {
        let valueString = "종료 모드";
        switch (value) {
            case 0:
                valueString = "종료 모드";
                break;
            case 1:
                valueString = "냉각 모드";
                break;
            case 2:
                valueString = "히팅 모드";
                break;
            case 3:
                valueString = "자동 순환 모드";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertHighVoltReqRemark = (value: number) => {
        let valueString = "무효 상태";
        switch (value) {
            case 0:
                valueString = "고전압 차단 요청";
                break;
            case 1:
                valueString = "고전압 요청";
                break;
            case 2:
                valueString = "무효 상태";
                break;
            case 3:
                valueString = "무효 상태";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertChargeStatusRemark = (value: number) => {
        let valueString = "무효 상태";
        switch (value) {
            case 0:
                valueString = "비충전 모드";
                break;
            case 1:
                valueString = "충전 모드";
                break;
            case 2:
                valueString = "무효 상태";
                break;
            case 3:
                valueString = "무효 상태";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertBmsHvrStatusRemark = (value: number) => {
        let valueString = "무효 상태";
        switch (value) {
            case 0:
                valueString = "떨어짐 상태";
                break;
            case 1:
                valueString = "붙음 상태";
                break;
            case 2:
                valueString = "무효 상태";
                break;
            case 3:
                valueString = "무효 상태";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getBatCoolingSys1BmsModeValue = () => {
        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_BMS_MODE_KEY) && currentCanData !== undefined) {
            return convertBmsModeRemark(currentCanData.batCoolingSys1?.bmsMode);
        }
        return "";
    };

    const getBatCoolingSys1HighVoltReqValue = () => {
        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_HIGH_VOLT_REQ_KEY) && currentCanData !== undefined) {
            return convertHighVoltReqRemark(currentCanData.batCoolingSys1?.highVoltReq);
        }
        return "";
    };

    const getBatCoolingSys1ChargeStatusValue = () => {
        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_CHARGE_STATUS_KEY) && currentCanData !== undefined) {
            return convertChargeStatusRemark(currentCanData.batCoolingSys1?.chargeStatus);
        }
        return "";
    };

    const getBatCoolingSys1BmsHvrStatusValue = () => {
        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_BMS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertBmsHvrStatusRemark(currentCanData.batCoolingSys1?.bmsHvrStatus);
        }
        return "";
    };

    const onChangeBatCoolingSys1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatCoolingSys1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatCoolingSys1: Array<TypeUtils.ChartData> = [];

        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_BMS_MODE_KEY)) chartDatasBatCoolingSys1.push(chartBatCoolingSys1BmsMode);
        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_HIGH_VOLT_REQ_KEY)) chartDatasBatCoolingSys1.push(chartBatCoolingSys1HighVoltReq);
        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_CHARGE_STATUS_KEY)) chartDatasBatCoolingSys1.push(chartBatCoolingSys1ChargeStatus);
        if (selectedBatCoolingSys1.includes(Common.TSECO_BCS1_BMS_HVR_STATUS_KEY)) chartDatasBatCoolingSys1.push(chartBatCoolingSys1BmsHvrStatus);

        setSeriesChartDatasBatCoolingSys1(chartDatasBatCoolingSys1);
    }, [
        selectedBatCoolingSys1,
        chartBatCoolingSys1BmsMode,
        chartBatCoolingSys1HighVoltReq,
        chartBatCoolingSys1ChargeStatus,
        chartBatCoolingSys1BmsHvrStatus,
    ]);

    const tableDataBatCoolingSys1: CanTableType[] = [
        {
            key: Common.TSECO_BCS1_BMS_MODE_KEY,
            name: Common.TSECO_BCS1_BMS_MODE_NAME,
            value: getBatCoolingSys1BmsModeValue(),
        },
        {
            key: Common.TSECO_BCS1_HIGH_VOLT_REQ_KEY,
            name: Common.TSECO_BCS1_HIGH_VOLT_REQ_NAME,
            value: getBatCoolingSys1HighVoltReqValue(),
        },
        {
            key: Common.TSECO_BCS1_CHARGE_STATUS_KEY,
            name: Common.TSECO_BCS1_CHARGE_STATUS_NAME,
            value: getBatCoolingSys1ChargeStatusValue(),
        },
        {
            key: Common.TSECO_BCS1_BMS_HVR_STATUS_KEY,
            name: Common.TSECO_BCS1_BMS_HVR_STATUS_NAME,
            value: getBatCoolingSys1BmsHvrStatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BatCoolingSys2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batCoolingSys2PanelOpen, setBatCoolingSys2PanelOpen] = useState(false);
    const [batCoolingSys2ChartMounted, setBatCoolingSys2ChartMounted] = useState(false);
    const [selectedBatCoolingSys2, setSelectedBatCoolingSys2] = useState<Array<React.Key>>([
        // Common.TSECO_BCS2_PANEL_STATUS_KEY,
        // Common.TSECO_BCS2_WATER_PUMP_STATUS_KEY,
        // Common.TSECO_BCS2_AIRCON_STATUS4_KEY,
        // Common.TSECO_BCS2_AIRCON_STATUS5_KEY,
        // Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_KEY,
        // Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_KEY,
        // Common.TSECO_BCS2_TMS_MODE_KEY,
        // Common.TSECO_BCS2_TMS_HVR_STATUS_KEY,
        // Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_KEY,
        // Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_KEY,
        // Common.TSECO_BCS2_WATER_LEVEL_ALARM_KEY,
        // Common.TSECO_BCS2_TMS_FAULT_CODE_KEY,
        // Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_KEY,
    ]);

    const [seriesChartDatasBatCoolingSys2, setSeriesChartDatasBatCoolingSys2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatCoolingSys2PanelStatus, setChartBatCoolingSys2PanelStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_PANEL_STATUS_NAME,
        data: [],
    });

    const [chartBatCoolingSys2WaterPumpStatus, setChartBatCoolingSys2WaterPumpStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_WATER_PUMP_STATUS_NAME,
        data: [],
    });

    const [chartBatCoolingSys2AirconStatus4, setChartBatCoolingSys2AirconStatus4] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_AIRCON_STATUS4_NAME,
        data: [],
    });

    const [chartBatCoolingSys2AirconStatus5, setChartBatCoolingSys2AirconStatus5] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_AIRCON_STATUS5_NAME,
        data: [],
    });

    const [chartBatCoolingSys2PlateWaterInTemp, setChartBatCoolingSys2PlateWaterInTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_NAME,
        data: [],
    });

    const [chartBatCoolingSys2PlateWaterOutTemp, setChartBatCoolingSys2PlateWaterOutTemp] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_NAME,
        data: [],
    });

    const [chartBatCoolingSys2TmsMode, setChartBatCoolingSys2TmsMode] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_TMS_MODE_NAME,
        data: [],
    });

    const [chartBatCoolingSys2TmsHvrStatus, setChartBatCoolingSys2TmsHvrStatus] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_TMS_HVR_STATUS_NAME,
        data: [],
    });

    const [chartBatCoolingSys2WaterwayValveASwitch, setChartBatCoolingSys2WaterwayValveASwitch] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_NAME,
        data: [],
    });

    const [chartBatCoolingSys2WaterwayValveBSwitch, setChartBatCoolingSys2WaterwayValveBSwitch] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_NAME,
        data: [],
    });

    const [chartBatCoolingSys2WaterLevelAlarm, setChartBatCoolingSys2WaterLevelAlarm] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_WATER_LEVEL_ALARM_NAME,
        data: [],
    });

    const [chartBatCoolingSys2TmsFaultCode, setChartBatCoolingSys2TmsFaultCode] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_TMS_FAULT_CODE_NAME,
        data: [],
    });

    const [chartBatCoolingSys2TmsFaultCodeLevel, setChartBatCoolingSys2TmsFaultCodeLevel] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_NAME,
        data: [],
    });

    const getTabelTooltipBatCoolingSys2 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_BCS2_PANEL_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 패널 상태
                        </p>
                        <p>Remarks: 0=ON, 1=OFF</p>
                    </>
                );
            case Common.TSECO_BCS2_WATER_PUMP_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 워터펌프 상태
                        </p>
                        <p>Remarks: 0=자동 순환 작동, 1=자동 순환 끄기</p>
                    </>
                );

            case Common.TSECO_BCS2_AIRCON_STATUS4_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 에어컨 상태 4
                        </p>
                        <p>Remarks: 0=보드 1 ON (배터리 냉각 진행), 1=보드 1 OFF (배터리 냉각 끄기)</p>
                    </>
                );

            case Common.TSECO_BCS2_AIRCON_STATUS5_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 에어컨 상태 5
                        </p>
                        <p>Remarks: 0=비보드 에어컨, 1=보드 에어컨</p>
                    </>
                );

            case Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 플레이트 입수 수온
                        </p>
                        <p>Remarks: ℃/bit, 255=무효</p>
                    </>
                );
            case Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 플레이트 출수 수온
                        </p>
                        <p>Remarks: ℃/bit, 255=무효</p>
                    </>
                );

            case Common.TSECO_BCS2_TMS_MODE_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: TMS 작동모드
                        </p>
                        <p>Remarks: 0=종료 모드, 1=냉각 모드, 2=히팅 모드, 3=자동 순환 모드</p>
                    </>
                );

            case Common.TSECO_BCS2_TMS_HVR_STATUS_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: TMS 고전압 릴레이 상태
                        </p>
                        <p>Remarks: 0=떨어짐 상태, 1=붙음 상태, 2=무효 상태, 3=무효 상태</p>
                    </>
                );

            case Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 수로 밸브A 스위치상태
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );
            case Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 수로 밸브B 스위치상태
                        </p>
                        <p>Remarks: 0=열림, 1=닫힘</p>
                    </>
                );

            case Common.TSECO_BCS2_WATER_LEVEL_ALARM_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: 수위 알람
                        </p>
                        <p>Remarks: 0=정상, 1=알람</p>
                    </>
                );

            case Common.TSECO_BCS2_TMS_FAULT_CODE_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: TMS 고장코드
                        </p>
                        <p>Remarks: 0=무효, 1=1급 고장, 2=2급 고장, 3=3급 고장</p>
                    </>
                );

            case Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_KEY:
                return (
                    <>
                        <p>
                            배터리 쿨링 시스템 2
                            <br />
                            CAN ID: 0x18FF0119
                            <br />
                            Name: TMS 고장코드 등급
                        </p>
                        <p>Remarks: 0=고장 없음</p>
                    </>
                );
        }

        return null;
    };

    const convertWaterPumpStatusRemark = (value: number) => {
        let valueString = "-";
        switch (value) {
            case 0:
                valueString = "워터펌프 자동 순환 작동";
                break;
            case 1:
                valueString = "워터펌프 자동 순환 끄기";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertAirconStatus4Remark = (value: number) => {
        let valueString = "-";
        switch (value) {
            case 0:
                valueString = "보드 1 ON (배터리 냉각 진행)";
                break;
            case 1:
                valueString = "보드 1 OFF (배터리 냉각 끄기)";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertAirconStatus5Remark = (value: number) => {
        let valueString = "-";
        switch (value) {
            case 0:
                valueString = "비보드 에어컨";
                break;
            case 1:
                valueString = "보드 에어컨";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertPlateWaterInTempRemark = (value: number) => {
        let valueString = value ? value + "℃/bit" : "-";
        switch (value) {
            case 255:
                valueString = "무효";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertTmsFaultCodeRemark = (value: number) => {
        let valueString = "무효";
        switch (value) {
            case 0:
                valueString = "무효";
                break;
            case 1:
                valueString = "1급 고장";
                break;
            case 2:
                valueString = "2급 고장";
                break;
            case 3:
                valueString = "3급 고장";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertTmsFaultCodeLevelRemark = (value: number) => {
        let valueString = value ? value.toString() : "-";
        switch (value) {
            case 0:
                valueString = "고장 없음";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getBatCoolingSys2PanelStatusValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_PANEL_STATUS_KEY) && currentCanData !== undefined) {
            return convertSysReadyRemark(currentCanData.batCoolingSys2?.panelStatus);
        }
        return "";
    };

    const getBatCoolingSys2WaterPumpStatusValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATER_PUMP_STATUS_KEY) && currentCanData !== undefined) {
            return convertWaterPumpStatusRemark(currentCanData.batCoolingSys2?.waterPumpStatus);
        }
        return "";
    };

    const getBatCoolingSys2AirconStatus4Value = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_AIRCON_STATUS4_KEY) && currentCanData !== undefined) {
            return convertAirconStatus4Remark(currentCanData.batCoolingSys2?.airconStatus4);
        }
        return "";
    };

    const getBatCoolingSys2AirconStatus5Value = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_AIRCON_STATUS5_KEY) && currentCanData !== undefined) {
            return convertAirconStatus5Remark(currentCanData.batCoolingSys2?.airconStatus5);
        }
        return "";
    };

    const getBatCoolingSys2PlateWaterInTempValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_KEY) && currentCanData !== undefined) {
            return convertPlateWaterInTempRemark(currentCanData.batCoolingSys2?.plateWaterInTemp);
        }
        return "";
    };

    const getBatCoolingSys2PlateWaterOutTempValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_KEY) && currentCanData !== undefined) {
            return convertPlateWaterInTempRemark(currentCanData.batCoolingSys2?.plateWaterOutTemp);
        }
        return "";
    };

    const getBatCoolingSys2TmsModeValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_MODE_KEY) && currentCanData !== undefined) {
            return convertBmsModeRemark(currentCanData.batCoolingSys2?.tmsMode);
        }
        return "";
    };

    const getBatCoolingSys2TmsHvrStatusValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_HVR_STATUS_KEY) && currentCanData !== undefined) {
            return convertBmsHvrStatusRemark(currentCanData.batCoolingSys2?.tmsHvrStatus);
        }
        return "";
    };

    const getBatCoolingSys2WaterwayValveASwitchValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.batCoolingSys2?.waterwayValveASwitch);
        }
        return "";
    };

    const getBatCoolingSys2WaterwayValveBSwitchValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_KEY) && currentCanData !== undefined) {
            return convertCcmRemark(currentCanData.batCoolingSys2?.waterwayValveBSwitch);
        }
        return "";
    };

    const getBatCoolingSys2WaterLevelAlarmValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATER_LEVEL_ALARM_KEY) && currentCanData !== undefined) {
            return convertTempAlarmRemark(currentCanData.batCoolingSys2?.waterLevelAlarm);
        }
        return "";
    };

    const getBatCoolingSys2TmsFaultCodeValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_FAULT_CODE_KEY) && currentCanData !== undefined) {
            return convertTmsFaultCodeRemark(currentCanData.batCoolingSys2?.tmsFaultCode);
        }
        return "";
    };

    const getBatCoolingSys2TmsFaultCodeLevelValue = () => {
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_KEY) && currentCanData !== undefined) {
            return convertTmsFaultCodeLevelRemark(currentCanData.batCoolingSys2?.tmsFaultCodeLevel);
        }
        return "";
    };

    const onChangeBatCoolingSys2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatCoolingSys2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatCoolingSys2: Array<TypeUtils.ChartData> = [];

        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_PANEL_STATUS_KEY)) chartDatasBatCoolingSys2.push(chartBatCoolingSys2PanelStatus);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATER_PUMP_STATUS_KEY))
            chartDatasBatCoolingSys2.push(chartBatCoolingSys2WaterPumpStatus);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_AIRCON_STATUS4_KEY)) chartDatasBatCoolingSys2.push(chartBatCoolingSys2AirconStatus4);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_AIRCON_STATUS5_KEY)) chartDatasBatCoolingSys2.push(chartBatCoolingSys2AirconStatus5);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_KEY))
            chartDatasBatCoolingSys2.push(chartBatCoolingSys2PlateWaterInTemp);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_KEY))
            chartDatasBatCoolingSys2.push(chartBatCoolingSys2PlateWaterOutTemp);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_MODE_KEY)) chartDatasBatCoolingSys2.push(chartBatCoolingSys2TmsMode);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_HVR_STATUS_KEY)) chartDatasBatCoolingSys2.push(chartBatCoolingSys2TmsHvrStatus);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_KEY))
            chartDatasBatCoolingSys2.push(chartBatCoolingSys2WaterwayValveASwitch);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_KEY))
            chartDatasBatCoolingSys2.push(chartBatCoolingSys2WaterwayValveBSwitch);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_WATER_LEVEL_ALARM_KEY))
            chartDatasBatCoolingSys2.push(chartBatCoolingSys2WaterLevelAlarm);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_FAULT_CODE_KEY)) chartDatasBatCoolingSys2.push(chartBatCoolingSys2TmsFaultCode);
        if (selectedBatCoolingSys2.includes(Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_KEY))
            chartDatasBatCoolingSys2.push(chartBatCoolingSys2TmsFaultCodeLevel);

        setSeriesChartDatasBatCoolingSys2(chartDatasBatCoolingSys2);
    }, [
        selectedBatCoolingSys2,
        chartBatCoolingSys2PanelStatus,
        chartBatCoolingSys2WaterPumpStatus,
        chartBatCoolingSys2AirconStatus4,
        chartBatCoolingSys2AirconStatus5,
        chartBatCoolingSys2PlateWaterInTemp,
        chartBatCoolingSys2PlateWaterOutTemp,
        chartBatCoolingSys2TmsMode,
        chartBatCoolingSys2TmsHvrStatus,
        chartBatCoolingSys2WaterwayValveASwitch,
        chartBatCoolingSys2WaterwayValveBSwitch,
        chartBatCoolingSys2WaterLevelAlarm,
        chartBatCoolingSys2TmsFaultCode,
        chartBatCoolingSys2TmsFaultCodeLevel,
    ]);

    const tableDataBatCoolingSys2: CanTableType[] = [
        {
            key: Common.TSECO_BCS2_PANEL_STATUS_KEY,
            name: Common.TSECO_BCS2_PANEL_STATUS_NAME,
            value: getBatCoolingSys2PanelStatusValue(),
        },
        {
            key: Common.TSECO_BCS2_WATER_PUMP_STATUS_KEY,
            name: Common.TSECO_BCS2_WATER_PUMP_STATUS_NAME,
            value: getBatCoolingSys2WaterPumpStatusValue(),
        },
        {
            key: Common.TSECO_BCS2_AIRCON_STATUS4_KEY,
            name: Common.TSECO_BCS2_AIRCON_STATUS4_NAME,
            value: getBatCoolingSys2AirconStatus4Value(),
        },
        {
            key: Common.TSECO_BCS2_AIRCON_STATUS5_KEY,
            name: Common.TSECO_BCS2_AIRCON_STATUS5_NAME,
            value: getBatCoolingSys2AirconStatus5Value(),
        },
        {
            key: Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_KEY,
            name: Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_NAME,
            value: getBatCoolingSys2PlateWaterInTempValue(),
        },
        {
            key: Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_KEY,
            name: Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_NAME,
            value: getBatCoolingSys2PlateWaterOutTempValue(),
        },
        {
            key: Common.TSECO_BCS2_TMS_MODE_KEY,
            name: Common.TSECO_BCS2_TMS_MODE_NAME,
            value: getBatCoolingSys2TmsModeValue(),
        },
        {
            key: Common.TSECO_BCS2_TMS_HVR_STATUS_KEY,
            name: Common.TSECO_BCS2_TMS_HVR_STATUS_NAME,
            value: getBatCoolingSys2TmsHvrStatusValue(),
        },
        {
            key: Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_KEY,
            name: Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_NAME,
            value: getBatCoolingSys2WaterwayValveASwitchValue(),
        },
        {
            key: Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_KEY,
            name: Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_NAME,
            value: getBatCoolingSys2WaterwayValveBSwitchValue(),
        },
        {
            key: Common.TSECO_BCS2_WATER_LEVEL_ALARM_KEY,
            name: Common.TSECO_BCS2_WATER_LEVEL_ALARM_NAME,
            value: getBatCoolingSys2WaterLevelAlarmValue(),
        },
        {
            key: Common.TSECO_BCS2_TMS_FAULT_CODE_KEY,
            name: Common.TSECO_BCS2_TMS_FAULT_CODE_NAME,
            value: getBatCoolingSys2TmsFaultCodeValue(),
        },
        {
            key: Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_KEY,
            name: Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_NAME,
            value: getBatCoolingSys2TmsFaultCodeLevelValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region IpuStatus1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [is1PanelOpen, setIs1PanelOpen] = useState(false);
    const [is1ChartMounted, setIs1ChartMounted] = useState(false);
    const [selectedIs1, setSelectedIs1] = useState<Array<React.Key>>([
        // Common.TSECO_IS1_MOTOR_SPEED_KEY,
        // Common.TSECO_IS1_MOTOR_TORQ_KEY,
        // Common.TSECO_IS1_IPU_CURR_KEY,
        // Common.TSECO_IS1_FAIL_GRADE_KEY,
        // Common.TSECO_IS1_WORK_MODE_KEY,
    ]);

    const [seriesChartDatasIs1, setSeriesChartDatasIs1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartIs1MotorSpeed, setChartIs1MotorSpeed] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IS1_MOTOR_SPEED_NAME,
        data: [],
    });

    const [chartIs1MotorTorq, setChartIs1MotorTorq] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IS1_MOTOR_TORQ_NAME,
        data: [],
    });

    const [chartIs1IpuCurr, setChartIs1IpuCurr] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IS1_IPU_CURR_NAME,
        data: [],
    });

    const [chartIs1FailGrade, setChartIs1FailGrade] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IS1_FAIL_GRADE_NAME,
        data: [],
    });

    const [chartIs1WorkMode, setChartIs1WorkMode] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_IS1_WORK_MODE_NAME,
        data: [],
    });

    const getTabelTooltipIs1 = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_IS1_MOTOR_SPEED_KEY:
                return (
                    <>
                        <p>
                            IPU STATUS 1
                            <br />
                            CAN ID: 0x18ff41f0
                            <br />
                            Name: 모터 회전 속도
                        </p>
                        <p>Remarks: rpm, -6000~6000</p>
                    </>
                );
            case Common.TSECO_IS1_MOTOR_TORQ_KEY:
                return (
                    <>
                        <p>
                            IPU STATUS 1
                            <br />
                            CAN ID: 0x18ff41f0
                            <br />
                            Name: 모터 토크
                        </p>
                        <p>Remarks: Nm, -3200~3200</p>
                    </>
                );

            case Common.TSECO_IS1_IPU_CURR_KEY:
                return (
                    <>
                        <p>
                            IPU STATUS 1
                            <br />
                            CAN ID: 0x18ff41f0
                            <br />
                            Name: BUS 전류
                        </p>
                        <p>Remarks: A, -1000~1000</p>
                    </>
                );

            case Common.TSECO_IS1_FAIL_GRADE_KEY:
                return (
                    <>
                        <p>
                            IPU STATUS 1
                            <br />
                            CAN ID: 0x18ff41f0
                            <br />
                            Name: 고장 등급
                        </p>
                        <p>Remarks: 0=고장 없음, 2=경고(출력 제한 필요), 3=심각(모터 차단 필요), 4=매우 심각(차량 정지 필요), 5~15=무효</p>
                    </>
                );

            case Common.TSECO_IS1_WORK_MODE_KEY:
                return (
                    <>
                        <p>
                            IPU STATUS 1
                            <br />
                            CAN ID: 0x18ff41f0
                            <br />
                            Name: 작동 모드
                        </p>
                        <p>Remarks: 0=Ready, 1=Enable, 2=Power Up, 3=Error, 4~15=무효</p>
                    </>
                );
        }

        return null;
    };

    const convertFailGradeRemark = (value: number) => {
        let valueString = "무효";
        switch (value) {
            case 0:
                valueString = "고장 없음";
                break;
            case 2:
                valueString = "경고(출력 제한 필요)";
                break;
            case 3:
                valueString = "심각(모터 차단 필요)";
                break;
            case 4:
                valueString = "매우 심각(차량 정지 필요)";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertWorkModeRemark = (value: number) => {
        let valueString = "무효";
        switch (value) {
            case 0:
                valueString = "Ready";
                break;
            case 1:
                valueString = "Enable";
                break;
            case 2:
                valueString = "Power Up";
                break;
            case 3:
                valueString = "Error";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getIs1MotorSpeedValue = () => {
        if (selectedIs1.includes(Common.TSECO_IS1_MOTOR_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ipuStatus1?.motorSpeed, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getIs1MotorTorqValue = () => {
        if (selectedIs1.includes(Common.TSECO_IS1_MOTOR_TORQ_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ipuStatus1?.motorTorq, Common.UNIT_TORQUE);
        }
        return "";
    };

    const getIs1IpuCurrValue = () => {
        if (selectedIs1.includes(Common.TSECO_IS1_IPU_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ipuStatus1?.ipuCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getIs1FailGradeValue = () => {
        if (selectedIs1.includes(Common.TSECO_IS1_FAIL_GRADE_KEY) && currentCanData !== undefined) {
            return convertFailGradeRemark(currentCanData.ipuStatus1?.failGrade);
        }
        return "";
    };

    const getIs1WorkModeValue = () => {
        if (selectedIs1.includes(Common.TSECO_IS1_WORK_MODE_KEY) && currentCanData !== undefined) {
            return convertWorkModeRemark(currentCanData.ipuStatus1?.workMode);
        }
        return "";
    };

    const onChangeIs1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedIs1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasIs1: Array<TypeUtils.ChartData> = [];

        if (selectedIs1.includes(Common.TSECO_IS1_MOTOR_SPEED_KEY)) chartDatasIs1.push(chartIs1MotorSpeed);
        if (selectedIs1.includes(Common.TSECO_IS1_MOTOR_TORQ_KEY)) chartDatasIs1.push(chartIs1MotorTorq);
        if (selectedIs1.includes(Common.TSECO_IS1_IPU_CURR_KEY)) chartDatasIs1.push(chartIs1IpuCurr);
        if (selectedIs1.includes(Common.TSECO_IS1_FAIL_GRADE_KEY)) chartDatasIs1.push(chartIs1FailGrade);
        if (selectedIs1.includes(Common.TSECO_IS1_WORK_MODE_KEY)) chartDatasIs1.push(chartIs1WorkMode);

        setSeriesChartDatasIs1(chartDatasIs1);
    }, [selectedIs1, chartIs1MotorSpeed, chartIs1MotorTorq, chartIs1IpuCurr, chartIs1FailGrade, chartIs1WorkMode]);

    const tableDataIs1: CanTableType[] = [
        {
            key: Common.TSECO_IS1_MOTOR_SPEED_KEY,
            name: Common.TSECO_IS1_MOTOR_SPEED_NAME,
            value: getIs1MotorSpeedValue(),
        },
        {
            key: Common.TSECO_IS1_MOTOR_TORQ_KEY,
            name: Common.TSECO_IS1_MOTOR_TORQ_NAME,
            value: getIs1MotorTorqValue(),
        },
        {
            key: Common.TSECO_IS1_IPU_CURR_KEY,
            name: Common.TSECO_IS1_IPU_CURR_NAME,
            value: getIs1IpuCurrValue(),
        },
        {
            key: Common.TSECO_IS1_FAIL_GRADE_KEY,
            name: Common.TSECO_IS1_FAIL_GRADE_NAME,
            value: getIs1FailGradeValue(),
        },
        {
            key: Common.TSECO_IS1_WORK_MODE_KEY,
            name: Common.TSECO_IS1_WORK_MODE_NAME,
            value: getIs1WorkModeValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region IpuStatus1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [dtciPanelOpen, setDtciPanelOpen] = useState(false);
    const [dtciChartMounted, setDtciChartMounted] = useState(false);
    const [selectedDtci, setSelectedDtci] = useState<Array<React.Key>>([
        // Common.TSECO_DTCI_SYS_FAULT_MODE_KEY,
        // Common.TSECO_DTCI_VCU_DTC_KEY,
        // Common.TSECO_DTCI_VCU_OCCURRENCE_CNT_KEY,
    ]);

    const [seriesChartDatasDtci, setSeriesChartDatasDtci] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartDtciSysFaultMode, setChartDtciSysFaultMode] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DTCI_SYS_FAULT_MODE_NAME,
        data: [],
    });

    const [chartDtciVcuDtc, setChartDtciVcuDtc] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DTCI_VCU_DTC_NAME,
        data: [],
    });

    const [chartDtciVcuOccurrenceCnt, setChartDtciVcuOccurrenceCnt] = useState<TypeUtils.ChartData>({
        name: Common.TSECO_DTCI_VCU_OCCURRENCE_CNT_NAME,
        data: [],
    });

    const getTabelTooltipDtci = (key: React.Key) => {
        switch (key) {
            case Common.TSECO_DTCI_SYS_FAULT_MODE_KEY:
                return (
                    <>
                        <p>
                            DTC
                            <br />
                            CAN ID: 0x18FECAEF
                            <br />
                            Name: 시스템 고장 모드
                        </p>
                        <p>Remarks: 0=고장 없음, 1=심각 고장, 2=경미 고장, 3=고장 알람</p>
                    </>
                );
            case Common.TSECO_DTCI_VCU_DTC_KEY:
                return (
                    <>
                        <p>
                            DTC
                            <br />
                            CAN ID: 0x18FECAEF
                            <br />
                            Name: VCU_DTC
                        </p>
                    </>
                );

            case Common.TSECO_DTCI_VCU_OCCURRENCE_CNT_KEY:
                return (
                    <>
                        <p>
                            DTC
                            <br />
                            CAN ID: 0x18FECAEF
                            <br />
                            Name: VCU_Occurrence Count
                        </p>
                        <p>Remarks: 0~126</p>
                    </>
                );
        }

        return null;
    };

    const convertSysFaultModeRemark = (value: number) => {
        let valueString = "고장 없음";
        switch (value) {
            case 0:
                valueString = "고장 없음";
                break;
            case 1:
                valueString = "심각 고장";
                break;
            case 2:
                valueString = "경미 고장";
                break;
            case 3:
                valueString = "고장 알람";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getDtciSysFaultModeValue = () => {
        if (selectedDtci.includes(Common.TSECO_DTCI_SYS_FAULT_MODE_KEY) && currentCanData !== undefined) {
            return convertSysFaultModeRemark(currentCanData.dtcInfo?.sysFaultMode);
        }
        return "";
    };

    const getDtciVcuDtcValue = () => {
        if (selectedDtci.includes(Common.TSECO_DTCI_VCU_DTC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dtcInfo?.vcuDtc, "");
        }
        return "";
    };

    const getDtciVcuOccurrenceCntValue = () => {
        if (selectedDtci.includes(Common.TSECO_DTCI_VCU_OCCURRENCE_CNT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dtcInfo?.vcuOccurrenceCnt, "");
        }
        return "";
    };

    const onChangeDtci = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedDtci(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasDtci: Array<TypeUtils.ChartData> = [];

        if (selectedDtci.includes(Common.TSECO_DTCI_SYS_FAULT_MODE_KEY)) chartDatasDtci.push(chartDtciSysFaultMode);
        if (selectedDtci.includes(Common.TSECO_DTCI_VCU_DTC_KEY)) chartDatasDtci.push(chartDtciVcuDtc);
        if (selectedDtci.includes(Common.TSECO_DTCI_VCU_OCCURRENCE_CNT_KEY)) chartDatasDtci.push(chartDtciVcuOccurrenceCnt);

        setSeriesChartDatasDtci(chartDatasDtci);
    }, [selectedDtci, chartDtciSysFaultMode, chartDtciVcuDtc, chartDtciVcuOccurrenceCnt]);

    const tableDataDtci: CanTableType[] = [
        {
            key: Common.TSECO_DTCI_SYS_FAULT_MODE_KEY,
            name: Common.TSECO_DTCI_SYS_FAULT_MODE_NAME,
            value: getDtciSysFaultModeValue(),
        },
        {
            key: Common.TSECO_DTCI_VCU_DTC_KEY,
            name: Common.TSECO_DTCI_VCU_DTC_NAME,
            value: getDtciVcuDtcValue(),
        },
        {
            key: Common.TSECO_DTCI_VCU_OCCURRENCE_CNT_KEY,
            name: Common.TSECO_DTCI_VCU_OCCURRENCE_CNT_NAME,
            value: getDtciVcuOccurrenceCntValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    useEffect(() => {
        console.log("runningCanDataList: ", runningCanDataList);
        if (vehicleInfo?.makerModel.maker?.makerName === Common.VehicleMaker.TSEco) {
            //#region Datas
            const evInfoVoltDatas: TypeUtils.ChartElement[] = [];
            const evInfoSocDatas: TypeUtils.ChartElement[] = [];
            const evInfoMotorRpmDatas: TypeUtils.ChartElement[] = [];
            const evInfoMotorControllerInputVoltDatas: TypeUtils.ChartElement[] = [];
            const evInfoMotorControllerTempDatas: TypeUtils.ChartElement[] = [];
            const evInfoMotorTempDatas: TypeUtils.ChartElement[] = [];

            const evInfo1SpeedDatas: TypeUtils.ChartElement[] = [];
            const evInfo1AvgEnergyConsumptionDatas: TypeUtils.ChartElement[] = [];
            const evInfo1InstantEnergyConsumptionDatas: TypeUtils.ChartElement[] = [];
            const evInfo1MileageDatas: TypeUtils.ChartElement[] = [];
            const evInfo1RemainingEnergyDatas: TypeUtils.ChartElement[] = [];
            const evInfo1StorageBatVoltDatas: TypeUtils.ChartElement[] = [];
            const evInfo1GearDatas: TypeUtils.ChartElement[] = [];
            const evInfo1AccelDepthDatas: TypeUtils.ChartElement[] = [];

            const evInfo2CellMaxVoltDatas: TypeUtils.ChartElement[] = [];
            const evInfo2CellMaxVoltNoDatas: TypeUtils.ChartElement[] = [];
            const evInfo2CellMinVoltDatas: TypeUtils.ChartElement[] = [];
            const evInfo2CellMinVoltNoDatas: TypeUtils.ChartElement[] = [];

            const evInfo3CellMaxTempDatas: TypeUtils.ChartElement[] = [];
            const evInfo3CellMaxTempNoDatas: TypeUtils.ChartElement[] = [];
            const evInfo3CellMinTempDatas: TypeUtils.ChartElement[] = [];
            const evInfo3CellMinTempNoDatas: TypeUtils.ChartElement[] = [];
            const evInfo3BatCurrDatas: TypeUtils.ChartElement[] = [];

            const evDtcSysReadyDatas: TypeUtils.ChartElement[] = [];
            const evDtcHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcSysStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcMcuTempAlarmDatas: TypeUtils.ChartElement[] = [];
            const evDtcSocAlarmDatas: TypeUtils.ChartElement[] = [];
            const evDtcInsulationFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcBmsFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcMotorOverheatAlarmDatas: TypeUtils.ChartElement[] = [];
            const evDtcMotorFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcMcuCommunicationFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcBatteryFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcBmsCommunicationFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcGearFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcDcdcFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcSteeringDcacFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcBreakDcacFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcAccelPedalFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcBreakPedalFaultDatas: TypeUtils.ChartElement[] = [];
            const evDtcSubBatteryStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcHvilStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcVehicleStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcHvestopStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcSnowModeStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcOutputLimitStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcHybridModeStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcRegenerationStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcAntiSlipStatusDatas: TypeUtils.ChartElement[] = [];
            const evDtcDownhillAssistStatusDatas: TypeUtils.ChartElement[] = [];

            const ccmMainMinusContactorCmdDatas: TypeUtils.ChartElement[] = [];
            const ccmHeater1ContactorCmdDatas: TypeUtils.ChartElement[] = [];
            const ccmAirconContactorCmdDatas: TypeUtils.ChartElement[] = [];
            const ccmDefrosterContactorCmdDatas: TypeUtils.ChartElement[] = [];
            const ccmCcmDcdcContactorCmdDatas: TypeUtils.ChartElement[] = [];
            const ccmHeater2ContactorCmdDatas: TypeUtils.ChartElement[] = [];

            const batInfo1SocDatas: TypeUtils.ChartElement[] = [];
            const batInfo1VoltDatas: TypeUtils.ChartElement[] = [];
            const batInfo1CurrDatas: TypeUtils.ChartElement[] = [];
            const batInfo1SohDatas: TypeUtils.ChartElement[] = [];
            const batInfo1ChargeStatusDatas: TypeUtils.ChartElement[] = [];
            const batInfo1AcChargeConnectDatas: TypeUtils.ChartElement[] = [];
            const batInfo1DcChargeConnectDatas: TypeUtils.ChartElement[] = [];
            const batInfo1BatPackCountDatas: TypeUtils.ChartElement[] = [];

            const hvrHvrMainPlusHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrMainMinusHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrChargePlusHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrChargeMinusHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrHeatingPlusHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrHeatingMinusHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrPreChargeHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrAirconHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrDefrosterHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrHeater1HvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrHeater2HvrStatusDatas: TypeUtils.ChartElement[] = [];
            const hvrMainPlusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrMainMinusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrChargePlusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrChargeMinusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrHeatingPlusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrHeatingMinusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrPreChargeHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrAirconHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrDefrosterHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrHeaterHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrCharge2PlusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrCharge2MinusHvrAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrChargePreChargeTimeOutAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrCharge2PreChargeTimeOutAlarmDatas: TypeUtils.ChartElement[] = [];
            const hvrPduHvilDatas: TypeUtils.ChartElement[] = [];
            const hvrHvcmVcuCommunicationFaultDatas: TypeUtils.ChartElement[] = [];

            const batLimitMinVoltDatas: TypeUtils.ChartElement[] = [];
            const batLimitMaxVoltDatas: TypeUtils.ChartElement[] = [];
            const batLimitMinCurrDatas: TypeUtils.ChartElement[] = [];
            const batLimitMaxCurrDatas: TypeUtils.ChartElement[] = [];

            const batFaultAlarmHvShortCircuitAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmHighTempAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmTempDiffAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBranchCircuitBreakFaultAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmHvilFaultAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmDischargeCurrentAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmCurrSensorFaultAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmRegenerationChargeCurrAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmHeatingBranchFaultAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmChargingCurrAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPackOvervoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmCellOvervoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmLowSocAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmChargeFaultAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPackLowVoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmCellLowVoltAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack12StatusDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack11StatusDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatLowTempAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmCellVoltDiffAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack10StatusDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack9StatusDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmCommunicationLifecycleCalculatorDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmLecuCommunicationAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmChargerCommunicationAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmVehicleCommunicationAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBmsLowVoltagePowerFaultAlarmDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatStatusDatas: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatDischargeStatus: TypeUtils.ChartElement[] = [];
            const batFaultAlarmChargeSavingSysAlarm: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBalancingStatus: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBalancingAlarmStatus: TypeUtils.ChartElement[] = [];
            const batFaultAlarmHighSocAlarm: TypeUtils.ChartElement[] = [];
            const batFaultAlarmSocJumpAlarm: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatForecast: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack1Status: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack2Status: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack3Status: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack4Status: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack5Status: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack6Status: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack7Status: TypeUtils.ChartElement[] = [];
            const batFaultAlarmBatPack8Status: TypeUtils.ChartElement[] = [];

            const batInfo2SysMinCellVoltDatas: TypeUtils.ChartElement[] = [];
            const batInfo2SysMinCellVoltModuleNoDatas: TypeUtils.ChartElement[] = [];
            const batInfo2SysMinVoltCellNoDatas: TypeUtils.ChartElement[] = [];
            const batInfo2SysMaxCellVoltDatas: TypeUtils.ChartElement[] = [];
            const batInfo2SysMaxCellVoltModuleNoDatas: TypeUtils.ChartElement[] = [];
            const batInfo2SysMaxVoltCellNoDatas: TypeUtils.ChartElement[] = [];

            const batModuleInfoSysMinTempSamplingSpotTempDatas: TypeUtils.ChartElement[] = [];
            const batModuleInfoSysMinTempSamplingSpotModuleNoDatas: TypeUtils.ChartElement[] = [];
            const batModuleInfoSysMinTempSamplingSpotNoDatas: TypeUtils.ChartElement[] = [];
            const batModuleInfoSysMaxTempSamplingSpotTempDatas: TypeUtils.ChartElement[] = [];
            const batModuleInfoSysMaxTempSamplingSpotModuleNoDatas: TypeUtils.ChartElement[] = [];
            const batModuleInfoSysMaxTempSamplingSpotNoDatas: TypeUtils.ChartElement[] = [];

            const cellVolt1BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const cellVolt1CellVolt1Datas: TypeUtils.ChartElement[] = [];
            const cellVolt1CellVolt2Datas: TypeUtils.ChartElement[] = [];
            const cellVolt1CellVolt3Datas: TypeUtils.ChartElement[] = [];
            const cellVolt1CellVolt4Datas: TypeUtils.ChartElement[] = [];
            const cellVolt1CellVolt5Datas: TypeUtils.ChartElement[] = [];
            const cellVolt1CellVolt6Datas: TypeUtils.ChartElement[] = [];
            const cellVolt1CellVolt7Datas: TypeUtils.ChartElement[] = [];
            const cellVolt2BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const cellVolt2CellVolt8Datas: TypeUtils.ChartElement[] = [];
            const cellVolt2CellVolt9Datas: TypeUtils.ChartElement[] = [];
            const cellVolt2CellVolt10Datas: TypeUtils.ChartElement[] = [];
            const cellVolt2CellVolt11Datas: TypeUtils.ChartElement[] = [];
            const cellVolt2CellVolt12Datas: TypeUtils.ChartElement[] = [];
            const cellVolt2CellVolt13Datas: TypeUtils.ChartElement[] = [];
            const cellVolt2CellVolt14Datas: TypeUtils.ChartElement[] = [];
            const cellVolt3BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const cellVolt3CellVolt15Datas: TypeUtils.ChartElement[] = [];
            const cellVolt3CellVolt16Datas: TypeUtils.ChartElement[] = [];
            const cellVolt3CellVolt17Datas: TypeUtils.ChartElement[] = [];
            const cellVolt3CellVolt18Datas: TypeUtils.ChartElement[] = [];
            const cellVolt3CellVolt19Datas: TypeUtils.ChartElement[] = [];
            const cellVolt3CellVolt20Datas: TypeUtils.ChartElement[] = [];
            const cellVolt3CellVolt21Datas: TypeUtils.ChartElement[] = [];
            const cellVolt4BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const cellVolt4CellVolt22Datas: TypeUtils.ChartElement[] = [];
            const cellVolt4CellVolt23Datas: TypeUtils.ChartElement[] = [];
            const cellVolt4CellVolt24Datas: TypeUtils.ChartElement[] = [];
            const cellVolt4CellVolt25Datas: TypeUtils.ChartElement[] = [];
            const cellVolt4CellVolt26Datas: TypeUtils.ChartElement[] = [];
            const cellVolt4CellVolt27Datas: TypeUtils.ChartElement[] = [];
            const cellVolt4CellVolt28Datas: TypeUtils.ChartElement[] = [];
            const cellVolt5BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const cellVolt5CellVolt29Datas: TypeUtils.ChartElement[] = [];
            const cellVolt5CellVolt30Datas: TypeUtils.ChartElement[] = [];
            const cellVolt5CellVolt31Datas: TypeUtils.ChartElement[] = [];
            const cellVolt5CellVolt32Datas: TypeUtils.ChartElement[] = [];
            const cellVolt5CellVolt33Datas: TypeUtils.ChartElement[] = [];
            const cellVolt5CellVolt34Datas: TypeUtils.ChartElement[] = [];
            const cellVolt5CellVolt35Datas: TypeUtils.ChartElement[] = [];
            const cellVolt6BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const cellVolt6CellVolt36Datas: TypeUtils.ChartElement[] = [];
            const cellVolt6CellVolt37Datas: TypeUtils.ChartElement[] = [];
            const cellVolt6CellVolt38Datas: TypeUtils.ChartElement[] = [];
            const cellVolt6CellVolt39Datas: TypeUtils.ChartElement[] = [];
            const cellVolt6CellVolt40Datas: TypeUtils.ChartElement[] = [];
            const cellVolt6CellVolt41Datas: TypeUtils.ChartElement[] = [];
            const cellVolt6CellVolt42Datas: TypeUtils.ChartElement[] = [];

            const moduleBatTemp1BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp1Module1SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp1Module2SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp1Module3SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp1Module4SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp1Module5SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp1Module6SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp1Module7SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2BatModuleNoDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2Module8SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2Module9SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2Module10SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2Module11SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2Module12SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2Module13SamplingTempDatas: TypeUtils.ChartElement[] = [];
            const moduleBatTemp2Module14SamplingTempDatas: TypeUtils.ChartElement[] = [];

            const insulMeasurementInsulHighDatas: TypeUtils.ChartElement[] = [];
            const insulMeasurementInsulLowDatas: TypeUtils.ChartElement[] = [];
            const insulMeasurementBusVoltHighDatas: TypeUtils.ChartElement[] = [];
            const insulMeasurementBusVoltLowDatas: TypeUtils.ChartElement[] = [];
            const insulMeasurementFaultLevelDatas: TypeUtils.ChartElement[] = [];
            const insulMeasurementAlarmStatusDatas: TypeUtils.ChartElement[] = [];
            const insulMeasurementLifeValueDatas: TypeUtils.ChartElement[] = [];

            const voltMeasurement1Channel1HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement1Channel1LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement1Channel2HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement1Channel2LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement1Channel3HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement1Channel3LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement1Channel4HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement1Channel4LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel5HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel5LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel6HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel6LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel7HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel7LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel8HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement2Channel8LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3Channel9HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3Channel9LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3Channel10HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3Channel10LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3Channel11HighDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3Channel11LowDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3InterlockChannel0StatusDatas: TypeUtils.ChartElement[] = [];
            const voltMeasurement3InterlockChannel1StatusDatas: TypeUtils.ChartElement[] = [];

            const vehicleInfoParkingBrakeStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleInfoSpeedDatas: TypeUtils.ChartElement[] = [];
            const vehicleInfoFootBrakeStatusDatas: TypeUtils.ChartElement[] = [];
            const vehicleInfoFrontDoorSwitchDatas: TypeUtils.ChartElement[] = [];
            const vehicleInfoBackDoorSwitchDatas: TypeUtils.ChartElement[] = [];
            const vehicleInfoBrakeAirPressure1Datas: TypeUtils.ChartElement[] = [];
            const vehicleInfoBrakeAirPressure2Datas: TypeUtils.ChartElement[] = [];
            const vehicleInfoBrakeAirPressure3Datas: TypeUtils.ChartElement[] = [];

            const dashboardInfo2AirPressure2Datas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2HydraulicDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2CoolantLevelDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2AirCleanerFilterDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2FuelFilterDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2OilFilterDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2WiperHighSpeedGearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2WiperLowSpeedGearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2EngineDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2ChargeSignDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2LeftFrontBrakePadWearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2LeftMiddleBrakePadWearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2LeftBackBrakePadWearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2RightFrontBrakePadWearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2RightMiddleBrakePadWearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2RightBackBrakePadWearDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2WaterTempDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2VoltDatas: TypeUtils.ChartElement[] = [];
            const dashboardInfo2CheckByteDatas: TypeUtils.ChartElement[] = [];

            const batCoolingSys1BmsModeDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys1HighVoltReqDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys1ChargeStatusDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys1BmsHvrStatusDatas: TypeUtils.ChartElement[] = [];

            const batCoolingSys2PanelStatusDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2WaterPumpStatusDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2AirconStatus4Datas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2AirconStatus5Datas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2PlateWaterInTempDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2PlateWaterOutTempDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2TmsModeDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2TmsHvrStatusDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2WaterwayValveASwitchDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2WaterwayValveBSwitchDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2WaterLevelAlarmDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2TmsFaultCodeDatas: TypeUtils.ChartElement[] = [];
            const batCoolingSys2TmsFaultCodeLevelDatas: TypeUtils.ChartElement[] = [];

            const is1MotorSpeedDatas: TypeUtils.ChartElement[] = [];
            const is1MotorTorqDatas: TypeUtils.ChartElement[] = [];
            const is1IpuCurrDatas: TypeUtils.ChartElement[] = [];
            const is1FailGradeDatas: TypeUtils.ChartElement[] = [];
            const is1WorkModeDatas: TypeUtils.ChartElement[] = [];

            const dtciSysFaultModeDatas: TypeUtils.ChartElement[] = [];
            const dtciVcuDtcDatas: TypeUtils.ChartElement[] = [];
            const dtciVcuOccurrenceCnt: TypeUtils.ChartElement[] = [];

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataTSEco.CanData;

                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region EV Info
                evInfoVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo?.volt || 0,
                });
                evInfoSocDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo?.soc || 0,
                });
                evInfoMotorRpmDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo?.motorRpm || 0,
                });
                evInfoMotorControllerInputVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo?.motorControllerInputVolt || 0,
                });
                evInfoMotorControllerTempDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo?.motorControllerTemp || 0,
                });
                evInfoMotorTempDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo?.motorTemp || 0,
                });
                //#endregion

                //#region EV Info 1
                evInfo1SpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.speed || 0,
                });
                evInfo1AvgEnergyConsumptionDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.avgEnergyConsumption || 0,
                });
                evInfo1InstantEnergyConsumptionDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.instantEnergyConsumption || 0,
                });
                evInfo1MileageDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.mileage || 0,
                });
                evInfo1RemainingEnergyDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.remainingEnergy || 0,
                });
                evInfo1StorageBatVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.storageBatVolt || 0,
                });
                evInfo1GearDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.gear || 0,
                });
                evInfo1AccelDepthDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo1?.accelDepth || 0,
                });
                //#endregion

                //#region EV Info 2
                evInfo2CellMaxVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo2?.cellMaxVolt || 0,
                });
                evInfo2CellMaxVoltNoDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo2?.cellMaxVoltNo || 0,
                });
                evInfo2CellMinVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo2?.cellMinVolt || 0,
                });
                evInfo2CellMinVoltNoDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo2?.cellMinVoltNo || 0,
                });
                //#endregion

                //#region EV Info 3
                evInfo3CellMaxTempDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo3?.cellMaxTemp || 0,
                });
                evInfo3CellMaxTempNoDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo3?.cellMaxTempNo || 0,
                });
                evInfo3CellMinTempDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo3?.cellMinTemp || 0,
                });
                evInfo3CellMinTempNoDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo3?.cellMinTempNo || 0,
                });
                evInfo3BatCurrDatas.push({
                    x: time.valueOf(),
                    y: canData.evInfo3?.batCurr || 0,
                });
                //#endregion

                //#region EV DTC
                evDtcSysReadyDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.sysReady || 0,
                });
                evDtcHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.hvrStatus || 0,
                });
                evDtcSysStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.sysStatus || 0,
                });
                evDtcMcuTempAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.mcuTempAlarm || 0,
                });
                evDtcSocAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.socAlarm || 0,
                });
                evDtcInsulationFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.insulationFault || 0,
                });
                evDtcBmsFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.bmsFault || 0,
                });
                evDtcMotorOverheatAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.motorOverheatAlarm || 0,
                });
                evDtcMotorFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.motorFault || 0,
                });
                evDtcMcuCommunicationFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.mcuCommunicationFault || 0,
                });
                evDtcBatteryFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.batteryFault || 0,
                });
                evDtcBmsCommunicationFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.bmsCommunicationFault || 0,
                });
                evDtcGearFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.gearFault || 0,
                });
                evDtcDcdcFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.dcdcFault || 0,
                });
                evDtcSteeringDcacFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.steeringDcacFault || 0,
                });
                evDtcBreakDcacFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.breakDcacFault || 0,
                });
                evDtcAccelPedalFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.accelPedalFault || 0,
                });
                evDtcBreakPedalFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.breakPedalFault || 0,
                });
                evDtcSubBatteryStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.subBatteryStatus || 0,
                });
                evDtcHvilStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.hvilStatus || 0,
                });
                evDtcVehicleStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.vehicleStatus || 0,
                });
                evDtcHvestopStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.hvestopStatus || 0,
                });
                evDtcSnowModeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.snowModeStatus || 0,
                });
                evDtcOutputLimitStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.outputLimitStatus || 0,
                });
                evDtcHybridModeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.hybridModeStatus || 0,
                });
                evDtcRegenerationStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.regenerationStatus || 0,
                });
                evDtcAntiSlipStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.antiSlipStatus || 0,
                });
                evDtcDownhillAssistStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.evDtc?.downhillAssistStatus || 0,
                });
                //#endregion

                //#region ContractorCmd
                ccmMainMinusContactorCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.contactorCmd?.mainMinusContactorCmd || 0,
                });
                ccmHeater1ContactorCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.contactorCmd?.heater1ContactorCmd || 0,
                });
                ccmAirconContactorCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.contactorCmd?.airconContactorCmd || 0,
                });
                ccmDefrosterContactorCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.contactorCmd?.defrosterContactorCmd || 0,
                });
                ccmCcmDcdcContactorCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.contactorCmd?.dcdcContactorCmd || 0,
                });
                ccmHeater2ContactorCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.contactorCmd?.heater2ContactorCmd || 0,
                });
                //#endregion

                //#region BatInfo1
                batInfo1SocDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.soc || 0,
                });
                batInfo1VoltDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.volt || 0,
                });
                batInfo1CurrDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.curr || 0,
                });
                batInfo1SohDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.soh || 0,
                });
                batInfo1ChargeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.chargeStatus || 0,
                });
                batInfo1AcChargeConnectDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.acChargeConnect || 0,
                });
                batInfo1DcChargeConnectDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.dcChargeConnect || 0,
                });
                batInfo1BatPackCountDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.batPackCount || 0,
                });
                //#endregion

                //#region HvrStatus
                hvrHvrMainPlusHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.mainPlusHvrStatus || 0,
                });
                hvrMainMinusHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.mainMinusHvrStatus || 0,
                });
                hvrChargePlusHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.chargePlusHvrStatus || 0,
                });
                hvrChargeMinusHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.chargeMinusHvrStatus || 0,
                });
                hvrHeatingPlusHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.heatingPlusHvrStatus || 0,
                });
                hvrHeatingMinusHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.heatingMinusHvrStatus || 0,
                });
                hvrPreChargeHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.preChargeHvrStatus || 0,
                });
                hvrAirconHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.airconHvrStatus || 0,
                });
                hvrDefrosterHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.defrosterHvrStatus || 0,
                });
                hvrHeater1HvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.heater1HvrStatus || 0,
                });
                hvrHeater2HvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.heater2HvrStatus || 0,
                });
                hvrMainPlusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.mainPlusHvrAlarm || 0,
                });
                hvrMainMinusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.mainMinusHvrAlarm || 0,
                });
                hvrChargePlusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.chargePlusHvrAlarm || 0,
                });
                hvrChargeMinusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.chargeMinusHvrAlarm || 0,
                });
                hvrHeatingPlusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.heatingPlusHvrAlarm || 0,
                });
                hvrHeatingMinusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.heatingMinusHvrAlarm || 0,
                });
                hvrPreChargeHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.preChargeHvrAlarm || 0,
                });
                hvrAirconHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.airconHvrAlarm || 0,
                });
                hvrDefrosterHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.defrosterHvrAlarm || 0,
                });
                hvrHeaterHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.heaterHvrAlarm || 0,
                });
                hvrCharge2PlusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.charge2PlusHvrAlarm || 0,
                });
                hvrCharge2MinusHvrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.charge2MinusHvrAlarm || 0,
                });
                hvrChargePreChargeTimeOutAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.chargePreChargeTimeOutAlarm || 0,
                });
                hvrCharge2PreChargeTimeOutAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.charge2PreChargeTimeOutAlarm || 0,
                });
                hvrPduHvilDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.pduHvil || 0,
                });
                hvrHvcmVcuCommunicationFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.hvrStatus?.hvcmVcuCommunicationFault || 0,
                });
                //#endregion

                //#region BatLimit
                batLimitMinVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit?.minVolt || 0,
                });
                batLimitMaxVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit?.maxVolt || 0,
                });
                batLimitMinCurrDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit?.minCurr || 0,
                });
                batLimitMaxCurrDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit?.maxCurr || 0,
                });
                //#endregion

                //#region BatFaultAlarm
                batFaultAlarmHvShortCircuitAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.hvShortCircuitAlarm || 0,
                });
                batFaultAlarmHighTempAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.highTempAlarm || 0,
                });
                batFaultAlarmTempDiffAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.tempDiffAlarm || 0,
                });
                batFaultAlarmBranchCircuitBreakFaultAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.branchCircuitBreakFaultAlarm || 0,
                });
                batFaultAlarmHvilFaultAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.hvilFaultAlarm || 0,
                });
                batFaultAlarmDischargeCurrentAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.dischargeCurrentAlarm || 0,
                });
                batFaultAlarmCurrSensorFaultAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.currSensorFaultAlarm || 0,
                });
                batFaultAlarmRegenerationChargeCurrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.regenerationChargeCurrAlarm || 0,
                });
                batFaultAlarmHeatingBranchFaultAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.heatingBranchFaultAlarm || 0,
                });
                batFaultAlarmChargingCurrAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.chargingCurrAlarm || 0,
                });
                batFaultAlarmBatPackOvervoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPackOvervoltAlarm || 0,
                });
                batFaultAlarmCellOvervoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.cellOvervoltAlarm || 0,
                });
                batFaultAlarmLowSocAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.lowSocAlarm || 0,
                });
                batFaultAlarmChargeFaultAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.chargeFaultAlarm || 0,
                });
                batFaultAlarmBatPackLowVoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPackLowVoltAlarm || 0,
                });
                batFaultAlarmCellLowVoltAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.cellLowVoltAlarm || 0,
                });
                batFaultAlarmBatPack12StatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack12Status || 0,
                });
                batFaultAlarmBatPack11StatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack11Status || 0,
                });
                batFaultAlarmBatLowTempAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batLowTempAlarm || 0,
                });
                batFaultAlarmCellVoltDiffAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.cellVoltDiffAlarm || 0,
                });
                batFaultAlarmBatPack10StatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack10Status || 0,
                });
                batFaultAlarmBatPack9StatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack9Status || 0,
                });
                batFaultAlarmCommunicationLifecycleCalculatorDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.communicationLifecycleCalculator || 0,
                });
                batFaultAlarmLecuCommunicationAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.lecuCommunicationAlarm || 0,
                });
                batFaultAlarmChargerCommunicationAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.chargerCommunicationAlarm || 0,
                });
                batFaultAlarmVehicleCommunicationAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.vehicleCommunicationAlarm || 0,
                });
                batFaultAlarmBmsLowVoltagePowerFaultAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.bmsLowVoltagePowerFaultAlarm || 0,
                });
                batFaultAlarmBatStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batStatus || 0,
                });
                batFaultAlarmBatDischargeStatus.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batDischargeStatus || 0,
                });
                batFaultAlarmChargeSavingSysAlarm.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.chargeSavingSysAlarm || 0,
                });
                batFaultAlarmBalancingStatus.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.balancingStatus || 0,
                });
                batFaultAlarmBalancingAlarmStatus.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.balancingAlarmStatus || 0,
                });
                batFaultAlarmHighSocAlarm.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.highSocAlarm || 0,
                });
                batFaultAlarmSocJumpAlarm.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.socJumpAlarm || 0,
                });
                batFaultAlarmBatForecast.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batForecast || 0,
                });
                batFaultAlarmBatPack1Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack1Status || 0,
                });
                batFaultAlarmBatPack2Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack2Status || 0,
                });
                batFaultAlarmBatPack3Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack3Status || 0,
                });
                batFaultAlarmBatPack4Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack4Status || 0,
                });
                batFaultAlarmBatPack5Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack5Status || 0,
                });
                batFaultAlarmBatPack6Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack6Status || 0,
                });
                batFaultAlarmBatPack7Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack7Status || 0,
                });
                batFaultAlarmBatPack8Status.push({
                    x: time.valueOf(),
                    y: canData.batFaultAlarm?.batPack8Status || 0,
                });
                //#endregion

                //#region BatInfo2
                batInfo2SysMinCellVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo2?.sysMinCellVolt || 0,
                });
                batInfo2SysMinCellVoltModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo2?.sysMinCellVoltModuleNo || 0,
                });
                batInfo2SysMinVoltCellNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo2?.sysMinVoltCellNo || 0,
                });
                batInfo2SysMaxCellVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo2?.sysMaxCellVolt || 0,
                });
                batInfo2SysMaxCellVoltModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo2?.sysMaxCellVoltModuleNo || 0,
                });
                batInfo2SysMaxVoltCellNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo2?.sysMaxVoltCellNo || 0,
                });
                //#endregion

                //#region BatModuleInfo
                batModuleInfoSysMinTempSamplingSpotTempDatas.push({
                    x: time.valueOf(),
                    y: canData.batModuleInfo?.sysMinTempSamplingSpotTemp || 0,
                });
                batModuleInfoSysMinTempSamplingSpotModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batModuleInfo?.sysMinTempSamplingSpotModuleNo || 0,
                });
                batModuleInfoSysMinTempSamplingSpotNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batModuleInfo?.sysMinTempSamplingSpotNo || 0,
                });
                batModuleInfoSysMaxTempSamplingSpotTempDatas.push({
                    x: time.valueOf(),
                    y: canData.batModuleInfo?.sysMaxTempSamplingSpotTemp || 0,
                });
                batModuleInfoSysMaxTempSamplingSpotModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batModuleInfo?.sysMaxTempSamplingSpotModuleNo || 0,
                });
                batModuleInfoSysMaxTempSamplingSpotNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batModuleInfo?.sysMaxTempSamplingSpotNo || 0,
                });
                //#endregion

                //#region CellVolt1
                cellVolt1BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.batModuleNo || 0,
                });
                cellVolt1CellVolt1Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.cellVolt1 || 0,
                });
                cellVolt1CellVolt2Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.cellVolt2 || 0,
                });
                cellVolt1CellVolt3Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.cellVolt3 || 0,
                });
                cellVolt1CellVolt4Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.cellVolt4 || 0,
                });
                cellVolt1CellVolt5Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.cellVolt5 || 0,
                });
                cellVolt1CellVolt6Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.cellVolt6 || 0,
                });
                cellVolt1CellVolt7Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt1?.cellVolt7 || 0,
                });
                //#endregion

                //#region CellVolt2
                cellVolt2BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.batModuleNo || 0,
                });
                cellVolt2CellVolt8Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.cellVolt8 || 0,
                });
                cellVolt2CellVolt9Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.cellVolt9 || 0,
                });
                cellVolt2CellVolt10Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.cellVolt10 || 0,
                });
                cellVolt2CellVolt11Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.cellVolt11 || 0,
                });
                cellVolt2CellVolt12Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.cellVolt12 || 0,
                });
                cellVolt2CellVolt13Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.cellVolt13 || 0,
                });
                cellVolt2CellVolt14Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt2?.cellVolt14 || 0,
                });
                //#endregion

                //#region CellVolt3
                cellVolt3BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.batModuleNo || 0,
                });
                cellVolt3CellVolt15Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.cellVolt15 || 0,
                });
                cellVolt3CellVolt16Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.cellVolt16 || 0,
                });
                cellVolt3CellVolt17Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.cellVolt17 || 0,
                });
                cellVolt3CellVolt18Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.cellVolt18 || 0,
                });
                cellVolt3CellVolt19Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.cellVolt19 || 0,
                });
                cellVolt3CellVolt20Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.cellVolt20 || 0,
                });
                cellVolt3CellVolt21Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt3?.cellVolt21 || 0,
                });
                //#endregion

                //#region CellVolt4
                cellVolt4BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.batModuleNo || 0,
                });
                cellVolt4CellVolt22Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.cellVolt22 || 0,
                });
                cellVolt4CellVolt23Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.cellVolt23 || 0,
                });
                cellVolt4CellVolt24Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.cellVolt24 || 0,
                });
                cellVolt4CellVolt25Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.cellVolt25 || 0,
                });
                cellVolt4CellVolt26Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.cellVolt26 || 0,
                });
                cellVolt4CellVolt27Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.cellVolt27 || 0,
                });
                cellVolt4CellVolt28Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt4?.cellVolt28 || 0,
                });
                //#endregion

                //#region CellVolt5
                cellVolt5BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.batModuleNo || 0,
                });
                cellVolt5CellVolt29Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.cellVolt29 || 0,
                });
                cellVolt5CellVolt30Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.cellVolt30 || 0,
                });
                cellVolt5CellVolt31Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.cellVolt31 || 0,
                });
                cellVolt5CellVolt32Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.cellVolt32 || 0,
                });
                cellVolt5CellVolt33Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.cellVolt33 || 0,
                });
                cellVolt5CellVolt34Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.cellVolt34 || 0,
                });
                cellVolt5CellVolt35Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt5?.cellVolt35 || 0,
                });
                //#endregion

                //#region CellVolt6
                cellVolt6BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.batModuleNo || 0,
                });
                cellVolt6CellVolt36Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.cellVolt36 || 0,
                });
                cellVolt6CellVolt37Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.cellVolt37 || 0,
                });
                cellVolt6CellVolt38Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.cellVolt38 || 0,
                });
                cellVolt6CellVolt39Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.cellVolt39 || 0,
                });
                cellVolt6CellVolt40Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.cellVolt40 || 0,
                });
                cellVolt6CellVolt41Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.cellVolt41 || 0,
                });
                cellVolt6CellVolt42Datas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt6?.cellVolt42 || 0,
                });
                //#endregion

                //#region ModuleBatTemp1
                moduleBatTemp1BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.batModuleNo || 0,
                });
                moduleBatTemp1Module1SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.module1SamplingTemp || 0,
                });
                moduleBatTemp1Module2SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.module2SamplingTemp || 0,
                });
                moduleBatTemp1Module3SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.module3SamplingTemp || 0,
                });
                moduleBatTemp1Module4SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.module4SamplingTemp || 0,
                });
                moduleBatTemp1Module5SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.module5SamplingTemp || 0,
                });
                moduleBatTemp1Module6SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.module6SamplingTemp || 0,
                });
                moduleBatTemp1Module7SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp1?.module7SamplingTemp || 0,
                });
                //#endregion

                //#region ModuleBatTemp2
                moduleBatTemp2BatModuleNoDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.batModuleNo || 0,
                });
                moduleBatTemp2Module8SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.module8SamplingTemp || 0,
                });
                moduleBatTemp2Module9SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.module9SamplingTemp || 0,
                });
                moduleBatTemp2Module10SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.module10SamplingTemp || 0,
                });
                moduleBatTemp2Module11SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.module11SamplingTemp || 0,
                });
                moduleBatTemp2Module12SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.module12SamplingTemp || 0,
                });
                moduleBatTemp2Module13SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.module13SamplingTemp || 0,
                });
                moduleBatTemp2Module14SamplingTempDatas.push({
                    x: time.valueOf(),
                    y: canData.moduleBatTemp2?.module14SamplingTemp || 0,
                });
                //#endregion

                //#region InsulMeasurement
                insulMeasurementInsulHighDatas.push({
                    x: time.valueOf(),
                    y: canData.insulMeasurement?.insulHigh || 0,
                });
                insulMeasurementInsulLowDatas.push({
                    x: time.valueOf(),
                    y: canData.insulMeasurement?.insulLow || 0,
                });
                insulMeasurementBusVoltHighDatas.push({
                    x: time.valueOf(),
                    y: canData.insulMeasurement?.busVoltHigh || 0,
                });
                insulMeasurementBusVoltLowDatas.push({
                    x: time.valueOf(),
                    y: canData.insulMeasurement?.busVoltLow || 0,
                });
                insulMeasurementFaultLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.insulMeasurement?.faultLevel || 0,
                });
                insulMeasurementAlarmStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.insulMeasurement?.alarmStatus || 0,
                });
                insulMeasurementLifeValueDatas.push({
                    x: time.valueOf(),
                    y: canData.insulMeasurement?.lifeValue || 0,
                });
                //#endregion

                //#region VoltMeasurement1
                voltMeasurement1Channel1HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel1High || 0,
                });
                voltMeasurement1Channel1LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel1Low || 0,
                });
                voltMeasurement1Channel2HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel2High || 0,
                });
                voltMeasurement1Channel2LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel2Low || 0,
                });
                voltMeasurement1Channel3HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel3High || 0,
                });
                voltMeasurement1Channel3LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel3Low || 0,
                });
                voltMeasurement1Channel4HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel4High || 0,
                });
                voltMeasurement1Channel4LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement1?.channel4Low || 0,
                });
                //#endregion

                //#region VoltMeasurement2
                voltMeasurement2Channel5HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel5High || 0,
                });
                voltMeasurement2Channel5LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel5Low || 0,
                });
                voltMeasurement2Channel6HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel6High || 0,
                });
                voltMeasurement2Channel6LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel6Low || 0,
                });
                voltMeasurement2Channel7HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel7High || 0,
                });
                voltMeasurement2Channel7LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel7Low || 0,
                });
                voltMeasurement2Channel8HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel8High || 0,
                });
                voltMeasurement2Channel8LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement2?.channel8Low || 0,
                });
                //#endregion

                //#region VoltMeasurement3
                voltMeasurement3Channel9HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.channel9High || 0,
                });
                voltMeasurement3Channel9LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.channel9Low || 0,
                });
                voltMeasurement3Channel10HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.channel10High || 0,
                });
                voltMeasurement3Channel10LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.channel10Low || 0,
                });
                voltMeasurement3Channel11HighDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.channel11High || 0,
                });
                voltMeasurement3Channel11LowDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.channel11Low || 0,
                });
                voltMeasurement3InterlockChannel0StatusDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.interlockChannel0Status || 0,
                });
                voltMeasurement3InterlockChannel1StatusDatas.push({
                    x: time.valueOf(),
                    y: canData.voltMeasurement3?.interlockChannel1Status || 0,
                });
                //#endregion

                //#region VehicleInfo
                vehicleInfoParkingBrakeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.parkingBrakeStatus || 0,
                });
                vehicleInfoSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.speed || 0,
                });
                vehicleInfoFootBrakeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.footBrakeStatus || 0,
                });
                vehicleInfoFrontDoorSwitchDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.frontDoorSwitch || 0,
                });
                vehicleInfoBackDoorSwitchDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.backDoorSwitch || 0,
                });
                vehicleInfoBrakeAirPressure1Datas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.brakeAirPressure1 || 0,
                });
                vehicleInfoBrakeAirPressure2Datas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.brakeAirPressure2 || 0,
                });
                vehicleInfoBrakeAirPressure3Datas.push({
                    x: time.valueOf(),
                    y: canData.vehicleInfo?.brakeAirPressure3 || 0,
                });
                //#endregion

                //#region DashboardInfo2
                dashboardInfo2AirPressure2Datas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.airPressure2 || 0,
                });
                dashboardInfo2HydraulicDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.hydraulic || 0,
                });
                dashboardInfo2CoolantLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.coolantLevel || 0,
                });
                dashboardInfo2AirCleanerFilterDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.airCleanerFilter || 0,
                });
                dashboardInfo2FuelFilterDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.fuelFilter || 0,
                });
                dashboardInfo2OilFilterDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.oilFilter || 0,
                });
                dashboardInfo2WiperHighSpeedGearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.wiperHighSpeedGear || 0,
                });
                dashboardInfo2WiperLowSpeedGearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.wiperLowSpeedGear || 0,
                });
                dashboardInfo2EngineDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.engine || 0,
                });
                dashboardInfo2ChargeSignDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.chargeSign || 0,
                });
                dashboardInfo2LeftFrontBrakePadWearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.leftFrontBrakePadWear || 0,
                });
                dashboardInfo2LeftMiddleBrakePadWearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.leftMiddleBrakePadWear || 0,
                });
                dashboardInfo2LeftBackBrakePadWearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.leftBackBrakePadWear || 0,
                });
                dashboardInfo2RightFrontBrakePadWearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.rightFrontBrakePadWear || 0,
                });
                dashboardInfo2RightMiddleBrakePadWearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.rightMiddleBrakePadWear || 0,
                });
                dashboardInfo2RightBackBrakePadWearDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.rightBackBrakePadWear || 0,
                });
                dashboardInfo2WaterTempDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.waterTemp || 0,
                });
                dashboardInfo2VoltDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.volt || 0,
                });
                dashboardInfo2CheckByteDatas.push({
                    x: time.valueOf(),
                    y: canData.dashboardInfo2?.checkByte || 0,
                });
                //#endregion

                //#region BatCoolingSys1
                batCoolingSys1BmsModeDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys1?.bmsMode || 0,
                });
                batCoolingSys1HighVoltReqDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys1?.highVoltReq || 0,
                });
                batCoolingSys1ChargeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys1?.chargeStatus || 0,
                });
                batCoolingSys1BmsHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys1?.bmsHvrStatus || 0,
                });
                //#endregion

                //#region BatCoolingSys2
                batCoolingSys2PanelStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.panelStatus || 0,
                });
                batCoolingSys2WaterPumpStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.waterPumpStatus || 0,
                });
                batCoolingSys2AirconStatus4Datas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.airconStatus4 || 0,
                });
                batCoolingSys2AirconStatus5Datas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.airconStatus5 || 0,
                });
                batCoolingSys2PlateWaterInTempDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.plateWaterInTemp || 0,
                });
                batCoolingSys2PlateWaterOutTempDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.plateWaterOutTemp || 0,
                });
                batCoolingSys2TmsModeDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.tmsMode || 0,
                });
                batCoolingSys2TmsHvrStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.tmsHvrStatus || 0,
                });
                batCoolingSys2WaterwayValveASwitchDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.waterwayValveASwitch || 0,
                });
                batCoolingSys2WaterwayValveBSwitchDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.waterwayValveBSwitch || 0,
                });
                batCoolingSys2WaterLevelAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.waterLevelAlarm || 0,
                });
                batCoolingSys2TmsFaultCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.tmsFaultCode || 0,
                });
                batCoolingSys2TmsFaultCodeLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.batCoolingSys2?.tmsFaultCodeLevel || 0,
                });
                //#endregion

                //#region IpuStatus1
                is1MotorSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.ipuStatus1?.motorSpeed || 0,
                });
                is1MotorTorqDatas.push({
                    x: time.valueOf(),
                    y: canData.ipuStatus1?.motorTorq || 0,
                });
                is1IpuCurrDatas.push({
                    x: time.valueOf(),
                    y: canData.ipuStatus1?.ipuCurr || 0,
                });
                is1FailGradeDatas.push({
                    x: time.valueOf(),
                    y: canData.ipuStatus1?.failGrade || 0,
                });
                is1WorkModeDatas.push({
                    x: time.valueOf(),
                    y: canData.ipuStatus1?.workMode || 0,
                });
                //#endregion

                //#region DtcInfo
                dtciSysFaultModeDatas.push({
                    x: time.valueOf(),
                    y: canData.dtcInfo?.sysFaultMode || 0,
                });
                dtciVcuDtcDatas.push({
                    x: time.valueOf(),
                    y: canData.dtcInfo?.vcuDtc || 0,
                });
                dtciVcuOccurrenceCnt.push({
                    x: time.valueOf(),
                    y: canData.dtcInfo?.vcuOccurrenceCnt || 0,
                });
                //#endregion
            });

            //#region setChartEvInfo
            setChartEvInfoVolt({
                ...chartEvInfoVolt,
                data: evInfoVoltDatas,
            });
            setChartEvInfoSoc({
                ...chartEvInfoSoc,
                data: evInfoSocDatas,
            });
            setChartEvInfoMotorRpm({
                ...chartEvInfoMotorRpm,
                data: evInfoMotorRpmDatas,
            });
            setChartEvInfoMotorControllerInputVolt({
                ...chartEvInfoMotorControllerInputVolt,
                data: evInfoMotorControllerInputVoltDatas,
            });
            setChartEvInfoMotorControllerTemp({
                ...chartEvInfoMotorControllerTemp,
                data: evInfoMotorControllerTempDatas,
            });
            setChartEvInfoMotorTemp({
                ...chartEvInfoMotorTemp,
                data: evInfoMotorTempDatas,
            });
            //#endregion

            //#region setChartEvInfo1
            setChartEvInfo1Speed({
                ...chartEvInfo1Speed,
                data: evInfo1SpeedDatas,
            });
            setChartEvInfo1AvgEnergyConsumption({
                ...chartEvInfo1AvgEnergyConsumption,
                data: evInfo1AvgEnergyConsumptionDatas,
            });
            setChartEvInfo1InstantEnergyConsumption({
                ...chartEvInfo1InstantEnergyConsumption,
                data: evInfo1InstantEnergyConsumptionDatas,
            });
            setChartEvInfo1Mileage({
                ...chartEvInfo1Mileage,
                data: evInfo1MileageDatas,
            });
            setChartEvInfo1RemainingEnergy({
                ...chartEvInfo1RemainingEnergy,
                data: evInfo1RemainingEnergyDatas,
            });
            setChartEvInfo1StorageBatVolt({
                ...chartEvInfo1StorageBatVolt,
                data: evInfo1StorageBatVoltDatas,
            });
            setChartEvInfo1Gear({
                ...chartEvInfo1Gear,
                data: evInfo1GearDatas,
            });
            setChartEvInfo1AccelDepth({
                ...chartEvInfo1AccelDepth,
                data: evInfo1AccelDepthDatas,
            });
            //#endregion

            //#region setChartEvInfo2
            setChartEvInfo2CellMaxVolt({
                ...chartEvInfo2CellMaxVolt,
                data: evInfo2CellMaxVoltDatas,
            });
            setChartEvInfo2CellMaxVoltNo({
                ...chartEvInfo2CellMaxVoltNo,
                data: evInfo2CellMaxVoltNoDatas,
            });
            setChartEvInfo2CellMinVolt({
                ...chartEvInfo2CellMinVolt,
                data: evInfo2CellMinVoltDatas,
            });
            setChartEvInfo2CellMinVoltNo({
                ...chartEvInfo2CellMinVoltNo,
                data: evInfo2CellMinVoltNoDatas,
            });
            //#endregion

            //#region setChartEvInfo3
            setChartEvInfo3CellMaxTemp({
                ...chartEvInfo3CellMaxTemp,
                data: evInfo3CellMaxTempDatas,
            });
            setChartEvInfo3CellMaxTempNo({
                ...chartEvInfo3CellMaxTempNo,
                data: evInfo3CellMaxTempNoDatas,
            });
            setChartEvInfo3CellMinTemp({
                ...chartEvInfo3CellMinTemp,
                data: evInfo3CellMinTempDatas,
            });
            setChartEvInfo3CellMinTempNo({
                ...chartEvInfo3CellMinTempNo,
                data: evInfo3CellMinTempNoDatas,
            });
            setChartEvInfo3BatCurr({
                ...chartEvInfo3BatCurr,
                data: evInfo3BatCurrDatas,
            });
            //#endregion

            //#region setChartEvDtc
            setChartEvDtcSysReady({
                ...chartEvDtcSysReady,
                data: evDtcSysReadyDatas,
            });
            setChartEvDtcHvrStatus({
                ...chartEvDtcHvrStatus,
                data: evDtcHvrStatusDatas,
            });
            setChartEvDtcSysStatus({
                ...chartEvDtcSysStatus,
                data: evDtcSysStatusDatas,
            });
            setChartEvDtcMcuTempAlarm({
                ...chartEvDtcMcuTempAlarm,
                data: evDtcMcuTempAlarmDatas,
            });
            setChartEvDtcSocAlarm({
                ...chartEvDtcSocAlarm,
                data: evDtcSocAlarmDatas,
            });
            setChartEvDtcInsulationFault({
                ...chartEvDtcInsulationFault,
                data: evDtcInsulationFaultDatas,
            });
            setChartEvDtcBmsFault({
                ...chartEvDtcBmsFault,
                data: evDtcBmsFaultDatas,
            });
            setChartEvDtcMotorOverheatAlarm({
                ...chartEvDtcMotorOverheatAlarm,
                data: evDtcMotorOverheatAlarmDatas,
            });
            setChartEvDtcMotorFault({
                ...chartEvDtcMotorFault,
                data: evDtcMotorFaultDatas,
            });
            setChartEvDtcMcuCommunicationFault({
                ...chartEvDtcMcuCommunicationFault,
                data: evDtcMcuCommunicationFaultDatas,
            });
            setChartEvDtcBatteryFault({
                ...chartEvDtcBatteryFault,
                data: evDtcBatteryFaultDatas,
            });
            setChartEvDtcBmsCommunicationFault({
                ...chartEvDtcBmsCommunicationFault,
                data: evDtcBmsCommunicationFaultDatas,
            });
            setChartEvDtcGearFault({
                ...chartEvDtcGearFault,
                data: evDtcGearFaultDatas,
            });
            setChartEvDtcDcdcFault({
                ...chartEvDtcDcdcFault,
                data: evDtcDcdcFaultDatas,
            });
            setChartEvDtcSteeringDcacFault({
                ...chartEvDtcSteeringDcacFault,
                data: evDtcSteeringDcacFaultDatas,
            });
            setChartEvDtcBreakDcacFault({
                ...chartEvDtcBreakDcacFault,
                data: evDtcBreakDcacFaultDatas,
            });
            setChartEvDtcAccelPedalFault({
                ...chartEvDtcAccelPedalFault,
                data: evDtcAccelPedalFaultDatas,
            });
            setChartEvDtcBreakPedalFault({
                ...chartEvDtcBreakPedalFault,
                data: evDtcBreakPedalFaultDatas,
            });
            setChartEvDtcSubBatteryStatus({
                ...chartEvDtcSubBatteryStatus,
                data: evDtcSubBatteryStatusDatas,
            });
            setChartEvDtcHvilStatus({
                ...chartEvDtcHvilStatus,
                data: evDtcHvilStatusDatas,
            });
            setChartEvDtcVehicleStatus({
                ...chartEvDtcVehicleStatus,
                data: evDtcVehicleStatusDatas,
            });
            setChartEvDtcHvestopStatus({
                ...chartEvDtcHvestopStatus,
                data: evDtcHvestopStatusDatas,
            });
            setChartEvDtcSnowModeStatus({
                ...chartEvDtcSnowModeStatus,
                data: evDtcSnowModeStatusDatas,
            });
            setChartEvDtcOutputLimitStatus({
                ...chartEvDtcOutputLimitStatus,
                data: evDtcOutputLimitStatusDatas,
            });
            setChartEvDtcHybridModeStatus({
                ...chartEvDtcHybridModeStatus,
                data: evDtcHybridModeStatusDatas,
            });
            setChartEvDtcRegenerationStatus({
                ...chartEvDtcRegenerationStatus,
                data: evDtcRegenerationStatusDatas,
            });
            setChartEvDtcAntiSlipStatus({
                ...chartEvDtcAntiSlipStatus,
                data: evDtcAntiSlipStatusDatas,
            });
            setChartEvDtcDownhillAssistStatus({
                ...chartEvDtcDownhillAssistStatus,
                data: evDtcDownhillAssistStatusDatas,
            });
            //#endregion

            //#region setChartContractorCmd
            setChartCcmMainMinusContactorCmd({
                ...chartCcmMainMinusContactorCmd,
                data: ccmMainMinusContactorCmdDatas,
            });
            setChartCcmHeater1ContactorCmd({
                ...chartCcmHeater1ContactorCmd,
                data: ccmHeater1ContactorCmdDatas,
            });
            setChartCcmAirconContactorCmd({
                ...chartCcmAirconContactorCmd,
                data: ccmAirconContactorCmdDatas,
            });
            setChartCcmDefrosterContactorCmd({
                ...chartCcmDefrosterContactorCmd,
                data: ccmDefrosterContactorCmdDatas,
            });
            setChartCcmDcdcContactorCmd({
                ...chartCcmDcdcContactorCmd,
                data: ccmCcmDcdcContactorCmdDatas,
            });
            setChartCcmHeater2ContactorCmd({
                ...chartCcmHeater2ContactorCmd,
                data: ccmHeater2ContactorCmdDatas,
            });
            //#endregion

            //#region setChartBatInfo1
            setChartBatInfo1Soc({
                ...chartBatInfo1Soc,
                data: batInfo1SocDatas,
            });
            setChartBatInfo1Volt({
                ...chartBatInfo1Volt,
                data: batInfo1VoltDatas,
            });
            setChartBatInfo1Curr({
                ...chartBatInfo1Curr,
                data: batInfo1CurrDatas,
            });
            setChartBatInfo1Soh({
                ...chartBatInfo1Soh,
                data: batInfo1SohDatas,
            });
            setChartBatInfo1ChargeStatus({
                ...chartBatInfo1ChargeStatus,
                data: batInfo1ChargeStatusDatas,
            });
            setChartBatInfo1AcChargeConnect({
                ...chartBatInfo1AcChargeConnect,
                data: batInfo1AcChargeConnectDatas,
            });
            setChartBatInfo1DcChargeConnect({
                ...chartBatInfo1DcChargeConnect,
                data: batInfo1DcChargeConnectDatas,
            });
            setChartBatInfo1BatPackCount({
                ...chartBatInfo1BatPackCount,
                data: batInfo1BatPackCountDatas,
            });
            //#endregion

            //#region setChartHvrStatus
            setChartMainPlusHvrStatus({
                ...chartHvrMainPlusHvrStatus,
                data: hvrHvrMainPlusHvrStatusDatas,
            });
            setChartMainMinusHvrStatus({
                ...chartMainMinusHvrStatus,
                data: hvrMainMinusHvrStatusDatas,
            });
            setChartChargePlusHvrStatus({
                ...chartChargePlusHvrStatus,
                data: hvrChargePlusHvrStatusDatas,
            });
            setChartChargeMinusHvrStatus({
                ...chartChargeMinusHvrStatus,
                data: hvrChargeMinusHvrStatusDatas,
            });
            setChartHeatingPlusHvrStatus({
                ...chartHeatingPlusHvrStatus,
                data: hvrHeatingPlusHvrStatusDatas,
            });
            setChartHeatingMinusHvrStatus({
                ...chartHeatingMinusHvrStatus,
                data: hvrHeatingMinusHvrStatusDatas,
            });
            setChartPreChargeHvrStatus({
                ...chartPreChargeHvrStatus,
                data: hvrPreChargeHvrStatusDatas,
            });
            setChartAirconHvrStatus({
                ...chartAirconHvrStatus,
                data: hvrAirconHvrStatusDatas,
            });
            setChartDefrosterHvrStatus({
                ...chartDefrosterHvrStatus,
                data: hvrDefrosterHvrStatusDatas,
            });
            setChartHeater1HvrStatus({
                ...chartHeater1HvrStatus,
                data: hvrHeater1HvrStatusDatas,
            });
            setChartHeater2HvrStatus({
                ...chartHeater2HvrStatus,
                data: hvrHeater2HvrStatusDatas,
            });
            setChartMainPlusHvrAlarm({
                ...chartMainPlusHvrAlarm,
                data: hvrMainPlusHvrAlarmDatas,
            });
            setChartMainMinusHvrAlarm({
                ...chartMainMinusHvrAlarm,
                data: hvrMainMinusHvrAlarmDatas,
            });
            setChartChargePlusHvrAlarm({
                ...chartChargePlusHvrAlarm,
                data: hvrChargePlusHvrAlarmDatas,
            });
            setChartChargeMinusHvrAlarm({
                ...chartChargeMinusHvrAlarm,
                data: hvrChargeMinusHvrAlarmDatas,
            });
            setChartHeatingPlusHvrAlarm({
                ...chartHeatingPlusHvrAlarm,
                data: hvrHeatingPlusHvrAlarmDatas,
            });
            setChartHeatingMinusHvrAlarm({
                ...chartHeatingMinusHvrAlarm,
                data: hvrHeatingMinusHvrAlarmDatas,
            });
            setChartPreChargeHvrAlarm({
                ...chartPreChargeHvrAlarm,
                data: hvrPreChargeHvrAlarmDatas,
            });
            setChartAirconHvrAlarm({
                ...chartAirconHvrAlarm,
                data: hvrAirconHvrAlarmDatas,
            });
            setChartDefrosterHvrAlarm({
                ...chartDefrosterHvrAlarm,
                data: hvrDefrosterHvrAlarmDatas,
            });
            setChartHeaterHvrAlarm({
                ...chartHeaterHvrAlarm,
                data: hvrHeaterHvrAlarmDatas,
            });
            setChartCharge2PlusHvrAlarm({
                ...chartCharge2PlusHvrAlarm,
                data: hvrCharge2PlusHvrAlarmDatas,
            });
            setChartCharge2MinusHvrAlarm({
                ...chartCharge2MinusHvrAlarm,
                data: hvrCharge2MinusHvrAlarmDatas,
            });
            setChartChargePreChargeTimeOutAlarm({
                ...chartChargePreChargeTimeOutAlarm,
                data: hvrChargePreChargeTimeOutAlarmDatas,
            });
            setChartCharge2PreChargeTimeOutAlarm({
                ...chartCharge2PreChargeTimeOutAlarm,
                data: hvrCharge2PreChargeTimeOutAlarmDatas,
            });
            setChartPduHvil({
                ...chartPduHvil,
                data: hvrPduHvilDatas,
            });
            setChartHvcmVcuCommunicationFault({
                ...chartHvcmVcuCommunicationFault,
                data: hvrHvcmVcuCommunicationFaultDatas,
            });
            //#endregion

            //#region setChartEvInfo2
            setChartBatLimitMinVolt({
                ...chartBatLimitMinVolt,
                data: batLimitMinVoltDatas,
            });
            setChartBatLimitMaxVolt({
                ...chartBatLimitMaxVolt,
                data: batLimitMaxVoltDatas,
            });
            setChartBatLimitMinCurr({
                ...chartBatLimitMinCurr,
                data: batLimitMinCurrDatas,
            });
            setChartBatLimitMaxCurr({
                ...chartBatLimitMaxCurr,
                data: batLimitMaxCurrDatas,
            });
            //#endregion

            //#region setChartBatFaultAlarm
            setChartBatFaultAlarmHvShortCircuitAlarm({
                ...chartBatFaultAlarmHvShortCircuitAlarm,
                data: batFaultAlarmHvShortCircuitAlarmDatas,
            });
            setChartBatFaultAlarmHighTempAlarm({
                ...chartBatFaultAlarmHighTempAlarm,
                data: batFaultAlarmHighTempAlarmDatas,
            });
            setChartBatFaultAlarmTempDiffAlarm({
                ...chartBatFaultAlarmTempDiffAlarm,
                data: batFaultAlarmTempDiffAlarmDatas,
            });
            setChartBatFaultAlarmBranchCircuitBreakFaultAlarm({
                ...chartBatFaultAlarmBranchCircuitBreakFaultAlarm,
                data: batFaultAlarmBranchCircuitBreakFaultAlarmDatas,
            });
            setChartBatFaultAlarmHvilFaultAlarm({
                ...chartBatFaultAlarmHvilFaultAlarm,
                data: batFaultAlarmHvilFaultAlarmDatas,
            });
            setChartBatFaultAlarmDischargeCurrentAlarm({
                ...chartBatFaultAlarmDischargeCurrentAlarm,
                data: batFaultAlarmDischargeCurrentAlarmDatas,
            });
            setChartBatFaultAlarmCurrSensorFaultAlarm({
                ...chartBatFaultAlarmCurrSensorFaultAlarm,
                data: batFaultAlarmCurrSensorFaultAlarmDatas,
            });
            setChartBatFaultAlarmRegenerationChargeCurrAlarm({
                ...chartBatFaultAlarmRegenerationChargeCurrAlarm,
                data: batFaultAlarmRegenerationChargeCurrAlarmDatas,
            });
            setChartBatFaultAlarmHeatingBranchFaultAlarm({
                ...chartBatFaultAlarmHeatingBranchFaultAlarm,
                data: batFaultAlarmHeatingBranchFaultAlarmDatas,
            });
            setChartBatFaultAlarmChargingCurrAlarm({
                ...chartBatFaultAlarmChargingCurrAlarm,
                data: batFaultAlarmChargingCurrAlarmDatas,
            });
            setChartBatFaultAlarmBatPackOvervoltAlarm({
                ...chartBatFaultAlarmBatPackOvervoltAlarm,
                data: batFaultAlarmBatPackOvervoltAlarmDatas,
            });
            setChartBatFaultAlarmCellOvervoltAlarm({
                ...chartBatFaultAlarmCellOvervoltAlarm,
                data: batFaultAlarmCellOvervoltAlarmDatas,
            });
            setChartBatFaultAlarmLowSocAlarm({
                ...chartBatFaultAlarmLowSocAlarm,
                data: batFaultAlarmLowSocAlarmDatas,
            });
            setChartBatFaultAlarmChargeFaultAlarm({
                ...chartBatFaultAlarmChargeFaultAlarm,
                data: batFaultAlarmChargeFaultAlarmDatas,
            });
            setChartBatFaultAlarmBatPackLowVoltAlarm({
                ...chartBatFaultAlarmBatPackLowVoltAlarm,
                data: batFaultAlarmBatPackLowVoltAlarmDatas,
            });
            setChartBatFaultAlarmCellLowVoltAlarm({
                ...chartBatFaultAlarmCellLowVoltAlarm,
                data: batFaultAlarmCellLowVoltAlarmDatas,
            });
            setChartBatFaultAlarmBatPack12Status({
                ...chartBatFaultAlarmBatPack12Status,
                data: batFaultAlarmBatPack12StatusDatas,
            });
            setChartBatFaultAlarmBatPack11Status({
                ...chartBatFaultAlarmBatPack11Status,
                data: batFaultAlarmBatPack11StatusDatas,
            });
            setChartBatFaultAlarmBatLowTempAlarm({
                ...chartBatFaultAlarmBatLowTempAlarm,
                data: batFaultAlarmBatLowTempAlarmDatas,
            });
            setChartBatFaultAlarmCellVoltDiffAlarm({
                ...chartBatFaultAlarmCellVoltDiffAlarm,
                data: batFaultAlarmCellVoltDiffAlarmDatas,
            });
            setChartBatFaultAlarmBatPack10Status({
                ...chartBatFaultAlarmBatPack10Status,
                data: batFaultAlarmBatPack10StatusDatas,
            });
            setChartBatFaultAlarmBatPack9Status({
                ...chartBatFaultAlarmBatPack9Status,
                data: batFaultAlarmBatPack9StatusDatas,
            });
            setChartBatFaultAlarmCommunicationLifecycleCalculator({
                ...chartBatFaultAlarmCommunicationLifecycleCalculator,
                data: batFaultAlarmCommunicationLifecycleCalculatorDatas,
            });
            setChartBatFaultAlarmLecuCommunicationAlarm({
                ...chartBatFaultAlarmLecuCommunicationAlarm,
                data: batFaultAlarmLecuCommunicationAlarmDatas,
            });
            setChartBatFaultAlarmChargerCommunicationAlarm({
                ...chartBatFaultAlarmChargerCommunicationAlarm,
                data: batFaultAlarmChargerCommunicationAlarmDatas,
            });
            setChartBatFaultAlarmVehicleCommunicationAlarm({
                ...chartBatFaultAlarmVehicleCommunicationAlarm,
                data: batFaultAlarmVehicleCommunicationAlarmDatas,
            });
            setChartBatFaultAlarmBmsLowVoltagePowerFaultAlarm({
                ...chartBatFaultAlarmBmsLowVoltagePowerFaultAlarm,
                data: batFaultAlarmBmsLowVoltagePowerFaultAlarmDatas,
            });
            setChartBatFaultAlarmBatStatus({
                ...chartBatFaultAlarmBatStatus,
                data: batFaultAlarmBatStatusDatas,
            });
            setChartBatFaultAlarmBatDischargeStatus({
                ...chartBatFaultAlarmBatDischargeStatus,
                data: batFaultAlarmBatDischargeStatus,
            });
            setChartBatFaultAlarmChargeSavingSysAlarm({
                ...chartBatFaultAlarmChargeSavingSysAlarm,
                data: batFaultAlarmChargeSavingSysAlarm,
            });
            setChartBatFaultAlarmBalancingStatus({
                ...chartBatFaultAlarmBalancingStatus,
                data: batFaultAlarmBalancingStatus,
            });
            setChartBatFaultAlarmBalancingAlarmStatus({
                ...chartBatFaultAlarmBalancingAlarmStatus,
                data: batFaultAlarmBalancingAlarmStatus,
            });
            setChartBatFaultAlarmHighSocAlarm({
                ...chartBatFaultAlarmHighSocAlarm,
                data: batFaultAlarmHighSocAlarm,
            });
            setChartBatFaultAlarmSocJumpAlarm({
                ...chartBatFaultAlarmSocJumpAlarm,
                data: batFaultAlarmSocJumpAlarm,
            });
            setChartBatFaultAlarmBatForecast({
                ...chartBatFaultAlarmBatForecast,
                data: batFaultAlarmBatForecast,
            });
            setChartBatFaultAlarmBatPack1Status({
                ...chartBatFaultAlarmBatPack1Status,
                data: batFaultAlarmBatPack1Status,
            });
            setChartBatFaultAlarmBatPack2Status({
                ...chartBatFaultAlarmBatPack2Status,
                data: batFaultAlarmBatPack2Status,
            });
            setChartBatFaultAlarmBatPack3Status({
                ...chartBatFaultAlarmBatPack3Status,
                data: batFaultAlarmBatPack3Status,
            });
            setChartBatFaultAlarmBatPack4Status({
                ...chartBatFaultAlarmBatPack4Status,
                data: batFaultAlarmBatPack4Status,
            });
            setChartBatFaultAlarmBatPack5Status({
                ...chartBatFaultAlarmBatPack5Status,
                data: batFaultAlarmBatPack5Status,
            });
            setChartBatFaultAlarmBatPack6Status({
                ...chartBatFaultAlarmBatPack6Status,
                data: batFaultAlarmBatPack6Status,
            });
            setChartBatFaultAlarmBatPack7Status({
                ...chartBatFaultAlarmBatPack7Status,
                data: batFaultAlarmBatPack7Status,
            });
            setChartBatFaultAlarmBatPack8Status({
                ...chartBatFaultAlarmBatPack8Status,
                data: batFaultAlarmBatPack8Status,
            });
            //#endregion

            //#region setChartBatInfo2
            setChartBatInfo2SysMinCellVolt({
                ...chartBatInfo2SysMinCellVolt,
                data: batInfo2SysMinCellVoltDatas,
            });
            setChartBatInfo2SysMinCellVoltModuleNo({
                ...chartBatInfo2SysMinCellVoltModuleNo,
                data: batInfo2SysMinCellVoltModuleNoDatas,
            });
            setChartBatInfo2SysMinVoltCellNo({
                ...chartBatInfo2SysMinVoltCellNo,
                data: batInfo2SysMinVoltCellNoDatas,
            });
            setChartBatInfo2SysMaxCellVolt({
                ...chartBatInfo2SysMaxCellVolt,
                data: batInfo2SysMaxCellVoltDatas,
            });
            setChartBatInfo2SysMaxCellVoltModuleNo({
                ...chartBatInfo2SysMaxCellVoltModuleNo,
                data: batInfo2SysMaxCellVoltModuleNoDatas,
            });
            setChartBatInfo2SysMaxVoltCellNo({
                ...chartBatInfo2SysMaxVoltCellNo,
                data: batInfo2SysMaxVoltCellNoDatas,
            });
            //#endregion

            //#region setChartBatModuleInfo
            setChartBatModuleInfoSysMinTempSamplingSpotTemp({
                ...chartBatModuleInfoSysMinTempSamplingSpotTemp,
                data: batModuleInfoSysMinTempSamplingSpotTempDatas,
            });
            setChartBatModuleInfoSysMinTempSamplingSpotModuleNo({
                ...chartBatModuleInfoSysMinTempSamplingSpotModuleNo,
                data: batModuleInfoSysMinTempSamplingSpotModuleNoDatas,
            });
            setChartBatModuleInfoSysMinTempSamplingSpotNo({
                ...chartBatModuleInfoSysMinTempSamplingSpotNo,
                data: batModuleInfoSysMinTempSamplingSpotNoDatas,
            });
            setChartBatModuleInfoSysMaxTempSamplingSpotTemp({
                ...chartBatModuleInfoSysMaxTempSamplingSpotTemp,
                data: batModuleInfoSysMaxTempSamplingSpotTempDatas,
            });
            setChartBatModuleInfoSysMaxTempSamplingSpotModuleNo({
                ...chartBatModuleInfoSysMaxTempSamplingSpotModuleNo,
                data: batModuleInfoSysMaxTempSamplingSpotModuleNoDatas,
            });
            setChartBatModuleInfoSysMaxTempSamplingSpotNo({
                ...chartBatModuleInfoSysMaxTempSamplingSpotNo,
                data: batModuleInfoSysMaxTempSamplingSpotNoDatas,
            });
            //#endregion

            //#region setChartCellVolt1
            setChartCellVolt1BatModule({
                ...chartCellVolt1BatModule,
                data: cellVolt1BatModuleNoDatas,
            });
            setChartCellVolt1CellVolt1({
                ...chartCellVolt1CellVolt1,
                data: cellVolt1CellVolt1Datas,
            });
            setChartCellVolt1CellVolt2({
                ...chartCellVolt1CellVolt2,
                data: cellVolt1CellVolt2Datas,
            });
            setChartCellVolt1CellVolt3({
                ...chartCellVolt1CellVolt3,
                data: cellVolt1CellVolt3Datas,
            });
            setChartCellVolt1CellVolt4({
                ...chartCellVolt1CellVolt4,
                data: cellVolt1CellVolt4Datas,
            });
            setChartCellVolt1CellVolt5({
                ...chartCellVolt1CellVolt5,
                data: cellVolt1CellVolt5Datas,
            });
            setChartCellVolt1CellVolt6({
                ...chartCellVolt1CellVolt6,
                data: cellVolt1CellVolt6Datas,
            });
            setChartCellVolt1CellVolt7({
                ...chartCellVolt1CellVolt7,
                data: cellVolt1CellVolt7Datas,
            });
            //#endregion

            //#region setChartCellVolt2
            setChartCellVolt2BatModule({
                ...chartCellVolt2BatModule,
                data: cellVolt2BatModuleNoDatas,
            });
            setChartCellVolt2CellVolt8({
                ...chartCellVolt2CellVolt8,
                data: cellVolt2CellVolt8Datas,
            });
            setChartCellVolt2CellVolt9({
                ...chartCellVolt2CellVolt9,
                data: cellVolt2CellVolt9Datas,
            });
            setChartCellVolt2CellVolt10({
                ...chartCellVolt2CellVolt10,
                data: cellVolt2CellVolt10Datas,
            });
            setChartCellVolt2CellVolt11({
                ...chartCellVolt2CellVolt11,
                data: cellVolt2CellVolt11Datas,
            });
            setChartCellVolt2CellVolt12({
                ...chartCellVolt2CellVolt12,
                data: cellVolt2CellVolt12Datas,
            });
            setChartCellVolt2CellVolt13({
                ...chartCellVolt2CellVolt13,
                data: cellVolt2CellVolt13Datas,
            });
            setChartCellVolt2CellVolt14({
                ...chartCellVolt2CellVolt14,
                data: cellVolt2CellVolt14Datas,
            });
            //#endregion

            //#region setChartCellVolt3
            setChartCellVolt3BatModule({
                ...chartCellVolt3BatModule,
                data: cellVolt3BatModuleNoDatas,
            });
            setChartCellVolt3CellVolt15({
                ...chartCellVolt3CellVolt15,
                data: cellVolt3CellVolt15Datas,
            });
            setChartCellVolt3CellVolt16({
                ...chartCellVolt3CellVolt16,
                data: cellVolt3CellVolt16Datas,
            });
            setChartCellVolt3CellVolt17({
                ...chartCellVolt3CellVolt17,
                data: cellVolt3CellVolt17Datas,
            });
            setChartCellVolt3CellVolt18({
                ...chartCellVolt3CellVolt18,
                data: cellVolt3CellVolt18Datas,
            });
            setChartCellVolt3CellVolt19({
                ...chartCellVolt3CellVolt19,
                data: cellVolt3CellVolt19Datas,
            });
            setChartCellVolt3CellVolt20({
                ...chartCellVolt3CellVolt20,
                data: cellVolt3CellVolt20Datas,
            });
            setChartCellVolt3CellVolt21({
                ...chartCellVolt3CellVolt21,
                data: cellVolt3CellVolt21Datas,
            });
            //#endregion

            //#region setChartCellVolt4
            setChartCellVolt4BatModule({
                ...chartCellVolt4BatModule,
                data: cellVolt4BatModuleNoDatas,
            });
            setChartCellVolt4CellVolt22({
                ...chartCellVolt4CellVolt22,
                data: cellVolt4CellVolt22Datas,
            });
            setChartCellVolt4CellVolt23({
                ...chartCellVolt4CellVolt23,
                data: cellVolt4CellVolt23Datas,
            });
            setChartCellVolt4CellVolt24({
                ...chartCellVolt4CellVolt24,
                data: cellVolt4CellVolt24Datas,
            });
            setChartCellVolt4CellVolt25({
                ...chartCellVolt4CellVolt25,
                data: cellVolt4CellVolt25Datas,
            });
            setChartCellVolt4CellVolt26({
                ...chartCellVolt4CellVolt26,
                data: cellVolt4CellVolt26Datas,
            });
            setChartCellVolt4CellVolt27({
                ...chartCellVolt4CellVolt27,
                data: cellVolt4CellVolt27Datas,
            });
            setChartCellVolt4CellVolt28({
                ...chartCellVolt4CellVolt28,
                data: cellVolt4CellVolt28Datas,
            });
            //#endregion

            //#region setChartCellVolt5
            setChartCellVolt5BatModule({
                ...chartCellVolt5BatModule,
                data: cellVolt5BatModuleNoDatas,
            });
            setChartCellVolt5CellVolt29({
                ...chartCellVolt5CellVolt29,
                data: cellVolt5CellVolt29Datas,
            });
            setChartCellVolt5CellVolt30({
                ...chartCellVolt5CellVolt30,
                data: cellVolt5CellVolt30Datas,
            });
            setChartCellVolt5CellVolt31({
                ...chartCellVolt5CellVolt31,
                data: cellVolt5CellVolt31Datas,
            });
            setChartCellVolt5CellVolt32({
                ...chartCellVolt5CellVolt32,
                data: cellVolt5CellVolt32Datas,
            });
            setChartCellVolt5CellVolt33({
                ...chartCellVolt5CellVolt33,
                data: cellVolt5CellVolt33Datas,
            });
            setChartCellVolt5CellVolt34({
                ...chartCellVolt5CellVolt34,
                data: cellVolt5CellVolt34Datas,
            });
            setChartCellVolt5CellVolt35({
                ...chartCellVolt5CellVolt35,
                data: cellVolt5CellVolt35Datas,
            });
            //#endregion

            //#region setChartCellVolt6
            setChartCellVolt6BatModule({
                ...chartCellVolt6BatModule,
                data: cellVolt6BatModuleNoDatas,
            });
            setChartCellVolt6CellVolt36({
                ...chartCellVolt6CellVolt36,
                data: cellVolt6CellVolt36Datas,
            });
            setChartCellVolt6CellVolt37({
                ...chartCellVolt6CellVolt37,
                data: cellVolt6CellVolt37Datas,
            });
            setChartCellVolt6CellVolt38({
                ...chartCellVolt6CellVolt38,
                data: cellVolt6CellVolt38Datas,
            });
            setChartCellVolt6CellVolt39({
                ...chartCellVolt6CellVolt39,
                data: cellVolt6CellVolt39Datas,
            });
            setChartCellVolt6CellVolt40({
                ...chartCellVolt6CellVolt40,
                data: cellVolt6CellVolt40Datas,
            });
            setChartCellVolt6CellVolt41({
                ...chartCellVolt6CellVolt41,
                data: cellVolt6CellVolt41Datas,
            });
            setChartCellVolt6CellVolt42({
                ...chartCellVolt6CellVolt42,
                data: cellVolt6CellVolt42Datas,
            });
            //#endregion

            //#region setChartModuleBatTemp1
            setChartModuleBatTemp1BatModule({
                ...chartModuleBatTemp1BatModule,
                data: moduleBatTemp1BatModuleNoDatas,
            });
            setChartModuleBatTemp1Module1SamplingTemp({
                ...chartModuleBatTemp1Module1SamplingTemp,
                data: moduleBatTemp1Module1SamplingTempDatas,
            });
            setChartModuleBatTemp1Module2SamplingTemp({
                ...chartModuleBatTemp1Module2SamplingTemp,
                data: moduleBatTemp1Module2SamplingTempDatas,
            });
            setChartModuleBatTemp1Module3SamplingTemp({
                ...chartModuleBatTemp1Module3SamplingTemp,
                data: moduleBatTemp1Module3SamplingTempDatas,
            });
            setChartModuleBatTemp1Module4SamplingTemp({
                ...chartModuleBatTemp1Module4SamplingTemp,
                data: moduleBatTemp1Module4SamplingTempDatas,
            });
            setChartModuleBatTemp1Module5SamplingTemp({
                ...chartModuleBatTemp1Module5SamplingTemp,
                data: moduleBatTemp1Module5SamplingTempDatas,
            });
            setChartModuleBatTemp1Module6SamplingTemp({
                ...chartModuleBatTemp1Module6SamplingTemp,
                data: moduleBatTemp1Module6SamplingTempDatas,
            });
            setChartModuleBatTemp1Module7SamplingTemp({
                ...chartModuleBatTemp1Module7SamplingTemp,
                data: moduleBatTemp1Module7SamplingTempDatas,
            });
            //#endregion

            //#region setChartModuleBatTemp2
            setChartModuleBatTemp2BatModule({
                ...chartModuleBatTemp2BatModule,
                data: moduleBatTemp2BatModuleNoDatas,
            });
            setChartModuleBatTemp2Module8SamplingTemp({
                ...chartModuleBatTemp2Module8SamplingTemp,
                data: moduleBatTemp2Module8SamplingTempDatas,
            });
            setChartModuleBatTemp2Module9SamplingTemp({
                ...chartModuleBatTemp2Module9SamplingTemp,
                data: moduleBatTemp2Module9SamplingTempDatas,
            });
            setChartModuleBatTemp2Module10SamplingTemp({
                ...chartModuleBatTemp2Module10SamplingTemp,
                data: moduleBatTemp2Module10SamplingTempDatas,
            });
            setChartModuleBatTemp2Module11SamplingTemp({
                ...chartModuleBatTemp2Module11SamplingTemp,
                data: moduleBatTemp2Module11SamplingTempDatas,
            });
            setChartModuleBatTemp2Module12SamplingTemp({
                ...chartModuleBatTemp2Module12SamplingTemp,
                data: moduleBatTemp2Module12SamplingTempDatas,
            });
            setChartModuleBatTemp2Module13SamplingTemp({
                ...chartModuleBatTemp2Module13SamplingTemp,
                data: moduleBatTemp2Module13SamplingTempDatas,
            });
            setChartModuleBatTemp2Module14SamplingTemp({
                ...chartModuleBatTemp2Module14SamplingTemp,
                data: moduleBatTemp2Module14SamplingTempDatas,
            });
            //#endregion

            //#region setChartInsulMeasurement
            setChartInsulMeasurementInsulHigh({
                ...chartInsulMeasurementInsulHigh,
                data: insulMeasurementInsulHighDatas,
            });
            setChartInsulMeasurementInsulLow({
                ...chartInsulMeasurementInsulLow,
                data: insulMeasurementInsulLowDatas,
            });
            setChartInsulMeasurementBusVoltHigh({
                ...chartInsulMeasurementBusVoltHigh,
                data: insulMeasurementBusVoltHighDatas,
            });
            setChartInsulMeasurementBusVoltLow({
                ...chartInsulMeasurementBusVoltLow,
                data: insulMeasurementBusVoltLowDatas,
            });
            setChartInsulMeasurementFaultLevel({
                ...chartInsulMeasurementFaultLevel,
                data: insulMeasurementFaultLevelDatas,
            });
            setChartInsulMeasurementAlarmStatus({
                ...chartInsulMeasurementAlarmStatus,
                data: insulMeasurementAlarmStatusDatas,
            });
            setChartInsulMeasurementLifeValue({
                ...chartInsulMeasurementLifeValue,
                data: insulMeasurementLifeValueDatas,
            });
            //#endregion

            //#region setChartVoltMeasurement1
            setChartVoltMeasurement1Channel1High({
                ...chartVoltMeasurement1Channel1High,
                data: voltMeasurement1Channel1HighDatas,
            });
            setChartVoltMeasurement1Channel1Low({
                ...chartVoltMeasurement1Channel1Low,
                data: voltMeasurement1Channel1LowDatas,
            });
            setChartVoltMeasurement1Channel2High({
                ...chartVoltMeasurement1Channel2High,
                data: voltMeasurement1Channel2HighDatas,
            });
            setChartVoltMeasurement1Channel2Low({
                ...chartVoltMeasurement1Channel2Low,
                data: voltMeasurement1Channel2LowDatas,
            });
            setChartVoltMeasurement1Channel3High({
                ...chartVoltMeasurement1Channel3High,
                data: voltMeasurement1Channel3HighDatas,
            });
            setChartVoltMeasurement1Channel3Low({
                ...chartVoltMeasurement1Channel3Low,
                data: voltMeasurement1Channel3LowDatas,
            });
            setChartVoltMeasurement1Channel4High({
                ...chartVoltMeasurement1Channel4High,
                data: voltMeasurement1Channel4HighDatas,
            });
            setChartVoltMeasurement1Channel4Low({
                ...chartVoltMeasurement1Channel4Low,
                data: voltMeasurement1Channel4LowDatas,
            });
            //#endregion

            //#region setChartVoltMeasurement2
            setChartVoltMeasurement2Channel5High({
                ...chartVoltMeasurement2Channel5High,
                data: voltMeasurement2Channel5HighDatas,
            });
            setChartVoltMeasurement2Channel5Low({
                ...chartVoltMeasurement2Channel5Low,
                data: voltMeasurement2Channel5LowDatas,
            });
            setChartVoltMeasurement2Channel6High({
                ...chartVoltMeasurement2Channel6High,
                data: voltMeasurement2Channel6HighDatas,
            });
            setChartVoltMeasurement2Channel6Low({
                ...chartVoltMeasurement2Channel6Low,
                data: voltMeasurement2Channel6LowDatas,
            });
            setChartVoltMeasurement2Channel7High({
                ...chartVoltMeasurement2Channel7High,
                data: voltMeasurement2Channel7HighDatas,
            });
            setChartVoltMeasurement2Channel7Low({
                ...chartVoltMeasurement2Channel7Low,
                data: voltMeasurement2Channel7LowDatas,
            });
            setChartVoltMeasurement2Channel8High({
                ...chartVoltMeasurement2Channel8High,
                data: voltMeasurement2Channel8HighDatas,
            });
            setChartVoltMeasurement2Channel8Low({
                ...chartVoltMeasurement2Channel8Low,
                data: voltMeasurement2Channel8LowDatas,
            });
            //#endregion

            //#region setChartVoltMeasurement3
            setChartVoltMeasurement3Channel9High({
                ...chartVoltMeasurement3Channel9High,
                data: voltMeasurement3Channel9HighDatas,
            });
            setChartVoltMeasurement3Channel9Low({
                ...chartVoltMeasurement3Channel9Low,
                data: voltMeasurement3Channel9LowDatas,
            });
            setChartVoltMeasurement3Channel10High({
                ...chartVoltMeasurement3Channel10High,
                data: voltMeasurement3Channel10HighDatas,
            });
            setChartVoltMeasurement3Channel10Low({
                ...chartVoltMeasurement3Channel10Low,
                data: voltMeasurement3Channel10LowDatas,
            });
            setChartVoltMeasurement3Channel11High({
                ...chartVoltMeasurement3Channel11High,
                data: voltMeasurement3Channel11HighDatas,
            });
            setChartVoltMeasurement3Channel11Low({
                ...chartVoltMeasurement3Channel11Low,
                data: voltMeasurement3Channel11LowDatas,
            });
            setChartVoltMeasurement3InterlockChannel0Status({
                ...chartVoltMeasurement3InterlockChannel0Status,
                data: voltMeasurement3InterlockChannel0StatusDatas,
            });
            setChartVoltMeasurement3InterlockChannel1Status({
                ...chartVoltMeasurement3InterlockChannel1Status,
                data: voltMeasurement3InterlockChannel1StatusDatas,
            });
            //#endregion

            //#region setChartVehicleInfo
            setChartVehicleInfoParkingBrakeStatus({
                ...chartVehicleInfoParkingBrakeStatus,
                data: vehicleInfoParkingBrakeStatusDatas,
            });
            setChartVehicleInfoSpeed({
                ...chartVehicleInfoSpeed,
                data: vehicleInfoSpeedDatas,
            });
            setChartVehicleInfoFootBrakeStatus({
                ...chartVehicleInfoFootBrakeStatus,
                data: vehicleInfoFootBrakeStatusDatas,
            });
            setChartVehicleInfoFrontDoorSwitch({
                ...chartVehicleInfoFrontDoorSwitch,
                data: vehicleInfoFrontDoorSwitchDatas,
            });
            setChartVehicleInfoFuelFilter({
                ...chartVehicleInfoBackDoorSwitch,
                data: vehicleInfoBackDoorSwitchDatas,
            });
            setChartVehicleInfoBrakeAirPressure1({
                ...chartVehicleInfoBrakeAirPressure1,
                data: vehicleInfoBrakeAirPressure1Datas,
            });
            setChartVehicleInfoBrakeAirPressure2({
                ...chartVehicleInfoBrakeAirPressure2,
                data: vehicleInfoBrakeAirPressure2Datas,
            });
            setChartVehicleInfoBrakeAirPressure3({
                ...chartVehicleInfoBrakeAirPressure3,
                data: vehicleInfoBrakeAirPressure3Datas,
            });
            //#endregion

            //#region setChartDashboardInfo2
            setChartDashboardInfo2AirPressure2({
                ...chartDashboardInfo2AirPressure2,
                data: dashboardInfo2AirPressure2Datas,
            });
            setChartDashboardInfo2Hydraulic({
                ...chartDashboardInfo2Hydraulic,
                data: dashboardInfo2HydraulicDatas,
            });
            setChartDashboardInfo2CoolantLevel({
                ...chartDashboardInfo2CoolantLevel,
                data: dashboardInfo2CoolantLevelDatas,
            });
            setChartDashboardInfo2AirCleanerFilter({
                ...chartDashboardInfo2AirCleanerFilter,
                data: dashboardInfo2AirCleanerFilterDatas,
            });
            setChartDashboardInfo2FuelFilter({
                ...chartDashboardInfo2FuelFilter,
                data: dashboardInfo2FuelFilterDatas,
            });
            setChartDashboardInfo2OilFilter({
                ...chartDashboardInfo2OilFilter,
                data: dashboardInfo2OilFilterDatas,
            });
            setChartDashboardInfo2WiperHighSpeedGear({
                ...chartDashboardInfo2WiperHighSpeedGear,
                data: dashboardInfo2WiperHighSpeedGearDatas,
            });
            setChartDashboardInfo2WiperLowSpeedGear({
                ...chartDashboardInfo2WiperLowSpeedGear,
                data: dashboardInfo2WiperLowSpeedGearDatas,
            });
            setChartDashboardInfo2Engine({
                ...chartDashboardInfo2Engine,
                data: dashboardInfo2EngineDatas,
            });
            setChartDashboardInfo2ChargeSign({
                ...chartDashboardInfo2ChargeSign,
                data: dashboardInfo2ChargeSignDatas,
            });
            setChartDashboardInfo2LeftFrontBrakePadWear({
                ...chartDashboardInfo2LeftFrontBrakePadWear,
                data: dashboardInfo2LeftFrontBrakePadWearDatas,
            });
            setChartDashboardInfo2LeftMiddleBrakePadWear({
                ...chartDashboardInfo2LeftMiddleBrakePadWear,
                data: dashboardInfo2LeftMiddleBrakePadWearDatas,
            });
            setChartDashboardInfo2LeftBackBrakePadWear({
                ...chartDashboardInfo2LeftBackBrakePadWear,
                data: dashboardInfo2LeftBackBrakePadWearDatas,
            });
            setChartDashboardInfo2RightFrontBrakePadWear({
                ...chartDashboardInfo2RightFrontBrakePadWear,
                data: dashboardInfo2RightFrontBrakePadWearDatas,
            });
            setChartDashboardInfo2RightMiddleBrakePadWear({
                ...chartDashboardInfo2RightMiddleBrakePadWear,
                data: dashboardInfo2RightMiddleBrakePadWearDatas,
            });
            setChartDashboardInfo2RightBackBrakePadWear({
                ...chartDashboardInfo2RightBackBrakePadWear,
                data: dashboardInfo2RightBackBrakePadWearDatas,
            });
            setChartDashboardInfo2WaterTemp({
                ...chartDashboardInfo2WaterTemp,
                data: dashboardInfo2WaterTempDatas,
            });
            setChartDashboardInfo2Volt({
                ...chartDashboardInfo2Volt,
                data: dashboardInfo2VoltDatas,
            });
            setChartDashboardInfo2CheckByte({
                ...chartDashboardInfo2CheckByte,
                data: dashboardInfo2CheckByteDatas,
            });
            //#endregion

            //#region setChartCellVolt1
            setChartBatCoolingSys1BmsMode({
                ...chartBatCoolingSys1BmsMode,
                data: batCoolingSys1BmsModeDatas,
            });
            setChartBatCoolingSys1HighVoltReq({
                ...chartBatCoolingSys1HighVoltReq,
                data: batCoolingSys1HighVoltReqDatas,
            });
            setChartBatCoolingSys1ChargeStatus({
                ...chartBatCoolingSys1ChargeStatus,
                data: batCoolingSys1ChargeStatusDatas,
            });
            setChartBatCoolingSys1BmsHvrStatus({
                ...chartBatCoolingSys1BmsHvrStatus,
                data: batCoolingSys1BmsHvrStatusDatas,
            });
            //#endregion

            //#region setChartBatCoolingSys2
            setChartBatCoolingSys2PanelStatus({
                ...chartBatCoolingSys2PanelStatus,
                data: batCoolingSys2PanelStatusDatas,
            });
            setChartBatCoolingSys2WaterPumpStatus({
                ...chartBatCoolingSys2WaterPumpStatus,
                data: batCoolingSys2WaterPumpStatusDatas,
            });
            setChartBatCoolingSys2AirconStatus4({
                ...chartBatCoolingSys2AirconStatus4,
                data: batCoolingSys2AirconStatus4Datas,
            });
            setChartBatCoolingSys2AirconStatus5({
                ...chartBatCoolingSys2AirconStatus5,
                data: batCoolingSys2AirconStatus5Datas,
            });
            setChartBatCoolingSys2PlateWaterInTemp({
                ...chartBatCoolingSys2PlateWaterInTemp,
                data: batCoolingSys2PlateWaterInTempDatas,
            });
            setChartBatCoolingSys2PlateWaterOutTemp({
                ...chartBatCoolingSys2PlateWaterOutTemp,
                data: batCoolingSys2PlateWaterOutTempDatas,
            });
            setChartBatCoolingSys2TmsMode({
                ...chartBatCoolingSys2TmsMode,
                data: batCoolingSys2TmsModeDatas,
            });
            setChartBatCoolingSys2TmsHvrStatus({
                ...chartBatCoolingSys2TmsHvrStatus,
                data: batCoolingSys2TmsHvrStatusDatas,
            });
            setChartBatCoolingSys2WaterwayValveASwitch({
                ...chartBatCoolingSys2WaterwayValveASwitch,
                data: batCoolingSys2WaterwayValveASwitchDatas,
            });
            setChartBatCoolingSys2WaterwayValveBSwitch({
                ...chartBatCoolingSys2WaterwayValveBSwitch,
                data: batCoolingSys2WaterwayValveBSwitchDatas,
            });
            setChartBatCoolingSys2WaterLevelAlarm({
                ...chartBatCoolingSys2WaterLevelAlarm,
                data: batCoolingSys2WaterLevelAlarmDatas,
            });
            setChartBatCoolingSys2TmsFaultCode({
                ...chartBatCoolingSys2TmsFaultCode,
                data: batCoolingSys2TmsFaultCodeDatas,
            });
            setChartBatCoolingSys2TmsFaultCodeLevel({
                ...chartBatCoolingSys2TmsFaultCodeLevel,
                data: batCoolingSys2TmsFaultCodeLevelDatas,
            });
            //#endregion

            //#region setIpuStatus1
            setChartIs1MotorSpeed({
                ...chartIs1MotorSpeed,
                data: is1MotorSpeedDatas,
            });
            setChartIs1MotorTorq({
                ...chartIs1MotorTorq,
                data: is1MotorTorqDatas,
            });
            setChartIs1IpuCurr({
                ...chartIs1IpuCurr,
                data: is1IpuCurrDatas,
            });
            setChartIs1FailGrade({
                ...chartIs1FailGrade,
                data: is1FailGradeDatas,
            });
            setChartIs1WorkMode({
                ...chartIs1WorkMode,
                data: is1WorkModeDatas,
            });
            //#endregion

            //#region setChartDtcInfo
            setChartDtciSysFaultMode({
                ...chartDtciSysFaultMode,
                data: dtciSysFaultModeDatas,
            });
            setChartDtciVcuDtc({
                ...chartDtciVcuDtc,
                data: dtciVcuDtcDatas,
            });
            setChartDtciVcuOccurrenceCnt({
                ...chartDtciVcuOccurrenceCnt,
                data: dtciVcuOccurrenceCnt,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.TSEco) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataTSEco.CanData;

            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (
            evInfoChartMounted === true ||
            evInfo1ChartMounted === true ||
            evInfo2ChartMounted === true ||
            evInfo3ChartMounted === true ||
            evDtcChartMounted === true ||
            ccmChartMounted === true ||
            batInfo1ChartMounted === true ||
            hvrStatusChartMounted === true ||
            batLimitChartMounted === true ||
            batFaultAlarmChartMounted === true ||
            batInfo2ChartMounted === true ||
            batModuleInfoChartMounted === true ||
            cellVolt1ChartMounted === true ||
            cellVolt2ChartMounted === true ||
            cellVolt3ChartMounted === true ||
            cellVolt4ChartMounted === true ||
            cellVolt5ChartMounted === true ||
            cellVolt6ChartMounted === true ||
            moduleBatTemp1ChartMounted === true ||
            moduleBatTemp2ChartMounted === true ||
            insulMeasurementChartMounted === true ||
            voltMeasurement1ChartMounted === true ||
            voltMeasurement2ChartMounted === true ||
            voltMeasurement3ChartMounted === true ||
            vehicleInfoChartMounted === true ||
            dashboardInfo2ChartMounted === true ||
            batCoolingSys1ChartMounted === true ||
            batCoolingSys2ChartMounted === true ||
            is1ChartMounted === true ||
            dtciChartMounted === true
        ) {
            if (onMountedChart !== undefined) onMountedChart();
        }
    }, [
        evInfoChartMounted,
        evInfo1ChartMounted,
        evInfo2ChartMounted,
        evInfo3ChartMounted,
        evDtcChartMounted,
        ccmChartMounted,
        batInfo1ChartMounted,
        hvrStatusChartMounted,
        batLimitChartMounted,
        batFaultAlarmChartMounted,
        batInfo2ChartMounted,
        batModuleInfoChartMounted,
        cellVolt1ChartMounted,
        cellVolt2ChartMounted,
        cellVolt4ChartMounted,
        cellVolt5ChartMounted,
        cellVolt6ChartMounted,
        moduleBatTemp1ChartMounted,
        moduleBatTemp2ChartMounted,
        insulMeasurementChartMounted,
        voltMeasurement1ChartMounted,
        voltMeasurement2ChartMounted,
        voltMeasurement3ChartMounted,
        vehicleInfoChartMounted,
        dashboardInfo2ChartMounted,
        batCoolingSys1ChartMounted,
        batCoolingSys2ChartMounted,
        is1ChartMounted,
        dtciChartMounted,
    ]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.TSECO_EVINFO_VOLT_NAME:
            case Common.TSECO_EVINFO_MOTOR_CONTROLLER_INPUT_VOLT_NAME:
            case Common.TSECO_EVINFO1_STORAGE_BAT_VOLT_NAME:
            case Common.TSECO_EVINFO2_CELL_MAX_VOLT_NAME:
            case Common.TSECO_EVINFO2_CELL_MIN_VOLT_NAME:
            case Common.TSECO_BAT_INFO1_VOLT_NAME:
            case Common.TSECO_BL_MIN_VOLT_NAME:
            case Common.TSECO_BL_MAX_VOLT_NAME:
            case Common.TSECO_BI2_SYS_MIN_CELL_VOLT_NAME:
            case Common.TSECO_BI2_SYS_MAX_CELL_VOLT_NAME:
            case Common.TSECO_CV1_CELL_VOLT1_NAME:
            case Common.TSECO_CV1_CELL_VOLT2_NAME:
            case Common.TSECO_CV1_CELL_VOLT3_NAME:
            case Common.TSECO_CV1_CELL_VOLT4_NAME:
            case Common.TSECO_CV1_CELL_VOLT5_NAME:
            case Common.TSECO_CV1_CELL_VOLT6_NAME:
            case Common.TSECO_CV1_CELL_VOLT7_NAME:
            case Common.TSECO_CV2_CELL_VOLT8_NAME:
            case Common.TSECO_CV2_CELL_VOLT9_NAME:
            case Common.TSECO_CV2_CELL_VOLT10_NAME:
            case Common.TSECO_CV2_CELL_VOLT11_NAME:
            case Common.TSECO_CV2_CELL_VOLT12_NAME:
            case Common.TSECO_CV2_CELL_VOLT13_NAME:
            case Common.TSECO_CV2_CELL_VOLT14_NAME:
            case Common.TSECO_CV3_CELL_VOLT15_NAME:
            case Common.TSECO_CV3_CELL_VOLT16_NAME:
            case Common.TSECO_CV3_CELL_VOLT17_NAME:
            case Common.TSECO_CV3_CELL_VOLT18_NAME:
            case Common.TSECO_CV3_CELL_VOLT19_NAME:
            case Common.TSECO_CV3_CELL_VOLT20_NAME:
            case Common.TSECO_CV3_CELL_VOLT21_NAME:
            case Common.TSECO_CV4_CELL_VOLT22_NAME:
            case Common.TSECO_CV4_CELL_VOLT23_NAME:
            case Common.TSECO_CV4_CELL_VOLT24_NAME:
            case Common.TSECO_CV4_CELL_VOLT25_NAME:
            case Common.TSECO_CV4_CELL_VOLT26_NAME:
            case Common.TSECO_CV4_CELL_VOLT27_NAME:
            case Common.TSECO_CV4_CELL_VOLT28_NAME:
            case Common.TSECO_CV5_CELL_VOLT29_NAME:
            case Common.TSECO_CV5_CELL_VOLT30_NAME:
            case Common.TSECO_CV5_CELL_VOLT31_NAME:
            case Common.TSECO_CV5_CELL_VOLT32_NAME:
            case Common.TSECO_CV5_CELL_VOLT33_NAME:
            case Common.TSECO_CV5_CELL_VOLT34_NAME:
            case Common.TSECO_CV5_CELL_VOLT35_NAME:
            case Common.TSECO_CV6_CELL_VOLT36_NAME:
            case Common.TSECO_CV6_CELL_VOLT37_NAME:
            case Common.TSECO_CV6_CELL_VOLT38_NAME:
            case Common.TSECO_CV6_CELL_VOLT39_NAME:
            case Common.TSECO_CV6_CELL_VOLT40_NAME:
            case Common.TSECO_CV6_CELL_VOLT41_NAME:
            case Common.TSECO_CV6_CELL_VOLT42_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }
            case Common.TSECO_BAT_INFO1_SOC_NAME:
            case Common.TSECO_EVINFO_SOC_NAME:
            case Common.TSECO_EVINFO1_ACCEL_DEPTH_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PERCENT);
                break;
            }

            case Common.TSECO_EVINFO_MOTOR_RPM_NAME:
            case Common.TSECO_IS1_MOTOR_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_MOTOR_SPEED);
                break;
            }

            case Common.TSECO_IS1_MOTOR_TORQ_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_TORQUE);
                break;
            }

            case Common.TSECO_EVINFO_MOTOR_CONTROLLER_TEMP_NAME:
            case Common.TSECO_EVINFO_MOTOR_TEMP_NAME:
            case Common.TSECO_EVINFO3_CELL_MAX_TEMP_NAME:
            case Common.TSECO_EVINFO3_CELL_MIN_TEMP_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CELSIUS);
                break;
            }

            case Common.TSECO_IM_INSUL_HIGH_NAME:
            case Common.TSECO_IM_INSUL_LOW_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, "KΩ/bit");
                break;
            }

            case Common.TSECO_IM_BUS_VOLT_HIGH_NAME:
            case Common.TSECO_IM_BUS_VOLT_LOW_NAME:
            case Common.TSECO_VM1_CHANNEL1_HIGH_NAME:
            case Common.TSECO_VM1_CHANNEL1_LOW_NAME:
            case Common.TSECO_VM1_CHANNEL2_HIGH_NAME:
            case Common.TSECO_VM1_CHANNEL2_LOW_NAME:
            case Common.TSECO_VM1_CHANNEL3_HIGH_NAME:
            case Common.TSECO_VM1_CHANNEL3_LOW_NAME:
            case Common.TSECO_VM1_CHANNEL4_HIGH_NAME:
            case Common.TSECO_VM1_CHANNEL4_LOW_NAME:
            case Common.TSECO_VM2_CHANNEL5_HIGH_NAME:
            case Common.TSECO_VM2_CHANNEL5_LOW_NAME:
            case Common.TSECO_VM2_CHANNEL6_HIGH_NAME:
            case Common.TSECO_VM2_CHANNEL6_LOW_NAME:
            case Common.TSECO_VM2_CHANNEL7_HIGH_NAME:
            case Common.TSECO_VM2_CHANNEL7_LOW_NAME:
            case Common.TSECO_VM2_CHANNEL8_HIGH_NAME:
            case Common.TSECO_VM2_CHANNEL8_LOW_NAME:
            case Common.TSECO_VM3_CHANNEL9_HIGH_NAME:
            case Common.TSECO_VM3_CHANNEL9_LOW_NAME:
            case Common.TSECO_VM3_CHANNEL10_HIGH_NAME:
            case Common.TSECO_VM3_CHANNEL10_LOW_NAME:
            case Common.TSECO_VM3_CHANNEL11_HIGH_NAME:
            case Common.TSECO_VM3_CHANNEL11_LOW_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, "V/bit");
                break;
            }

            case Common.TSECO_VM3_INTERLOCK_CHANNEL0_STATUS_NAME:
            case Common.TSECO_VM3_INTERLOCK_CHANNEL1_STATUS_NAME: {
                convertTooltipString = convertInterlockChannelRemark(value);
                break;
            }

            case Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_NAME:
            case Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_NAME: {
                convertTooltipString = convertModuleBatTempRemark(value);
                break;
            }

            case Common.TSECO_EVINFO3_BAT_CURR_NAME:
            case Common.TSECO_BAT_INFO1_CURR_NAME:
            case Common.TSECO_BL_MIN_CURR_NAME:
            case Common.TSECO_BL_MAX_CURR_NAME:
            case Common.TSECO_IS1_IPU_CURR_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }

            case Common.TSECO_EVINFO1_SPEED_NAME:
            case Common.TSECO_VI_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_SPEED);
                break;
            }

            case Common.TSECO_EVINFO1_AVG_ENERGY_CONSUMPTION_NAME:
            case Common.TSECO_EVINFO1_INSTANT_ENERGY_CONSUMPTION_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_POWER_DISTANCE);
                break;
            }

            case Common.TSECO_EVINFO1_MILEAGE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_DISTANCE);
                break;
            }

            case Common.TSECO_EVINFO1_REMAIN_ENERGY_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_POWER_H);
                break;
            }

            case Common.TSECO_EVINFO1_GEAR_NAME: {
                convertTooltipString = convertGearRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_MAIN_PLUS_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_MAIN_MINUS_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_CHARGE_PLUS_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_CHARGE_MINUS_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_HEATING_PLUS_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_HEATING_MINUS_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_PRE_CHARGE_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_AIRCON_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_DEFROSTER_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_HEATER1_HVR_STATUS_NAME:
            case Common.TSECO_HVRS_HEATER2_HVR_STATUS_NAME: {
                convertTooltipString = convertHvrStatusRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_HYBRID_MODE_STATUS_NAME: {
                convertTooltipString = convertHybridModeStatusRemark(value);
                break;
            }

            case Common.TSECO_BFA_CHARGE_FAULT_ALARM_NAME: {
                convertTooltipString = convertChargeFaultAlarmRemark(value);
                break;
            }

            case Common.TSECO_BFA_BALANCING_STATUS_NAME: {
                convertTooltipString = convertBalancingStatusRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_MCU_TEMP_ALARM_NAME:
            case Common.TSECO_EVDTC_HVIL_STATUS_NAME:
            case Common.TSECO_HVRS_MAIN_PLUS_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_MAIN_MINUS_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_CHARGE_PLUS_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_CHARGE_MINUS_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_HEATING_PLUS_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_HEATING_MINUS_HVR_ALARM_NAME:
            case Common.TSECO_EVDTC_MCU_TEMP_ALARM_NAME:
            case Common.TSECO_HVRS_PRE_CHARGE_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_AIRCON_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_DEFROSTER_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_HEATER_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_CHARGE2_PLUS_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_CHARGE2_MINUS_HVR_ALARM_NAME:
            case Common.TSECO_HVRS_CHARGE_PRE_CHARGE_TIME_OUT_ALARM_NAME:
            case Common.TSECO_HVRS_CHARGE2_PRE_CHARGE_TIME_OUT_ALARM_NAME:
            case Common.TSECO_HVRS_PDU_HVIL_NAME:
            case Common.TSECO_HVRS_HVCM_VCU_COMMUNICATION_FAULT_NAME:
            case Common.TSECO_BFA_BRANCH_CIRCUIT_BREAK_FAULT_ALARM_NAME:
            case Common.TSECO_BFA_HVIL_FAULT_ALARM_NAME:
            case Common.TSECO_BFA_CURR_SENSOR_FAULT_ALARM_NAME:
            case Common.TSECO_BFA_HEATING_BRANCH_FAULT_ALARM_NAME:
            case Common.TSECO_BFA_BAT_PACK12_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK11_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK10_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK9_STATUS_NAME:
            case Common.TSECO_BFA_LECU_COMMUNICATION_ALARM_NAME:
            case Common.TSECO_BFA_CHARGER_COMMUNICATION_ALARM_NAME:
            case Common.TSECO_BFA_VEHICLE_COMMUNICATION_ALARM_NAME:
            case Common.TSECO_BFA_BMS_LOW_VOLTAGE_POWER_FAULT_ALARM_NAME:
            case Common.TSECO_BFA_BAT_STATUS_NAME:
            case Common.TSECO_BFA_BAT_DISCHARGE_STATUS_NAME:
            case Common.TSECO_BFA_CHARGE_SAVING_SYS_ALARM_NAME:
            case Common.TSECO_BFA_BALANCING_ALARM_STATUS_NAME:
            case Common.TSECO_BFA_HIGH_SOC_ALARM_NAME:
            case Common.TSECO_BFA_SOC_JUMP_ALARM_NAME:
            case Common.TSECO_BFA_BAT_FORECAST_NAME:
            case Common.TSECO_BFA_BAT_PACK1_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK2_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK3_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK4_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK5_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK6_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK7_STATUS_NAME:
            case Common.TSECO_BFA_BAT_PACK8_STATUS_NAME:
            case Common.TSECO_BCS2_WATER_LEVEL_ALARM_NAME: {
                convertTooltipString = convertTempAlarmRemark(value);
                break;
            }

            case Common.TSECO_BAT_INFO1_AC_CHARGE_CONNECT_NAME:
            case Common.TSECO_BAT_INFO1_DC_CHARGE_CONNECT_NAME: {
                convertTooltipString = convertBatInfo1ChargeConnectRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_MOTOR_OVERHEAT_ALARM_NAME:
            case Common.TSECO_EVDTC_MOTOR_FAULT_NAME:
            case Common.TSECO_EVDTC_MCU_COMMUNICATION_FAULT_NAME:
            case Common.TSECO_EVDTC_BATTERY_FAULT_NAME:
            case Common.TSECO_EVDTC_BMS_COMMUNICATION_FAULT_NAME:
            case Common.TSECO_EVDTC_GEAR_FAULT_NAME:
            case Common.TSECO_EVDTC_DCDC_FAULT_NAME:
            case Common.TSECO_EVDTC_STEERING_DCAC_FAULT_NAME:
            case Common.TSECO_EVDTC_BREAK_DCAC_FAULT_NAME:
            case Common.TSECO_EVDTC_ACCEL_PEDAL_FAULT_NAME:
            case Common.TSECO_EVDTC_BREAK_PEDAL_FAULT_NAME: {
                convertTooltipString = convertFaultRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_SOC_ALARM_NAME:
            case Common.TSECO_EVDTC_INSULATION_FAULT_NAME:
            case Common.TSECO_EVDTC_BMS_FAULT_NAME: {
                convertTooltipString = convertSocAlarmRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_VEHICLE_STATUS_NAME: {
                convertTooltipString = convertVehicleStatusRemark(value);
                break;
            }

            case Common.TSECO_BAT_INFO1_CHARGE_STATUS_NAME: {
                convertTooltipString = convertBatInfo1ChargeStatusRemark(value);
                break;
            }

            case Common.TSECO_BAT_INFO1_BAT_PACK_COUNT_NAME: {
                convertTooltipString = convertBatInfo1BatPackCountRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_SYS_READY_NAME:
            case Common.TSECO_EVDTC_SYS_STATUS_NAME:
            case Common.TSECO_EVDTC_SUB_BATTERY_STATUS_NAME: {
                convertTooltipString = convertSysReadyRemark(value);
                break;
            }

            case Common.TSECO_EVDTC_HVESTOP_STATUS_NAME:
            case Common.TSECO_EVDTC_SNOW_MODE_STATUS_NAME:
            case Common.TSECO_EVDTC_OUTPUT_LIMIT_STATUS_NAME:
            case Common.TSECO_EVDTC_REGENERATION_STATUS_NAME:
            case Common.TSECO_EVDTC_ANTI_SLIP_STATUS_NAME:
            case Common.TSECO_EVDTC_DOWNHILL_ASSIST_STATUS_NAME: {
                convertTooltipString = convertHvestopStatusRemark(value);
                break;
            }

            case Common.TSECO_CCM_MAIN_MINUS_CONTACTOR_CMD_NAME:
            case Common.TSECO_CCM_HEATER1_CONTACTOR_CMD_NAME:
            case Common.TSECO_CCM_AIRCON_CONTACTOR_CMD_NAME:
            case Common.TSECO_CCM_DEFROSTER_CONTACTOR_CMD_NAME:
            case Common.TSECO_CCM_DCDC_CONTACTOR_CMD_NAME:
            case Common.TSECO_CCM_HEATER2_CONTACTOR_CMD_NAME:
            case Common.TSECO_BCS2_WATERWAY_VALVE_A_SWITCH_NAME:
            case Common.TSECO_BCS2_WATERWAY_VALVE_B_SWITCH_NAME: {
                convertTooltipString = convertCcmRemark(value);
                break;
            }

            case Common.TSECO_BFA_HV_SHORT_CIRCUIT_ALARM_NAME:
            case Common.TSECO_BFA_HIGH_TEMP_ALARM_NAME:
            case Common.TSECO_BFA_TEMP_DIFF_ALARM_NAME:
            case Common.TSECO_BFA_DISCHARGE_CURRENT_ALARM_NAME:
            case Common.TSECO_BFA_REGENERATION_CHARGE_CURR_ALARM_NAME:
            case Common.TSECO_BFA_CHARGING_CURR_ALARM_NAME:
            case Common.TSECO_BFA_BAT_PACK_OVERVOLT_ALARM_NAME:
            case Common.TSECO_BFA_CELL_OVERVOLT_ALARM_NAME:
            case Common.TSECO_BFA_LOW_SOC_ALARM_NAME:
            case Common.TSECO_BFA_BAT_PACK_LOW_VOLT_ALARM_NAME:
            case Common.TSECO_BFA_CELL_LOW_VOLT_ALARM_NAME:
            case Common.TSECO_BFA_BAT_LOW_TEMP_ALARM_NAME:
            case Common.TSECO_BFA_CELL_VOLT_DIFF_ALARM_NAME: {
                convertTooltipString = convertHvShortCircuitAlarmRemark(value);
                break;
            }

            case Common.TSECO_BMI_SYS_MIN_TEMP_SAMPLING_SPOT_TEMP_NAME:
            case Common.TSECO_BMI_SYS_MAX_TEMP_SAMPLING_SPOT_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE1_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE2_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE3_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE4_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE5_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE6_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT1_MODULE7_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE8_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE9_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE10_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE11_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE12_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE13_SAMPLING_TEMP_NAME:
            case Common.TSECO_MBT2_MODULE14_SAMPLING_TEMP_NAME: {
                convertTooltipString = convertModuleBatTempRemark(value);
                break;
            }

            case Common.TSECO_BAT_INFO1_CURR_NAME: {
                convertTooltipString = convertCurrRemark(value);
                break;
            }

            case Common.TSECO_IM_FAULT_LEVEL_NAME: {
                convertTooltipString = convertFaultLevelRemark(value);
                break;
            }

            case Common.TSECO_IM_ALARM_STATUS_NAME: {
                convertTooltipString = convertAlarmStatusRemark(value);
                break;
            }

            case Common.TSECO_VI_PARKING_BRAKE_STATUS_NAME:
            case Common.TSECO_VI_FOOT_BRAKE_STATUS_NAME: {
                convertTooltipString = convertBrakeRemark(value);
                break;
            }

            case Common.TSECO_VI_FRONT_DOOR_SWITCH_NAME:
            case Common.TSECO_VI_BACK_DOOR_SWITCH_NAME: {
                convertTooltipString = convertDoorSwitchRemark(value);
                break;
            }

            case Common.TSECO_VI_BRAKE_AIR_PRESSURE1_NAME:
            case Common.TSECO_VI_BRAKE_AIR_PRESSURE2_NAME:
            case Common.TSECO_VI_BRAKE_AIR_PRESSURE3_NAME: {
                convertTooltipString = convertBrakeAirPressRemark(value);
                break;
            }

            case Common.TSECO_BAT_INFO1_SOH_NAME: {
                convertTooltipString = convertSohRemark(value);
                break;
            }

            case Common.TSECO_DI2_WIPER_HIGH_SPEED_GEAR_NAME:
            case Common.TSECO_DI2_WIPER_LOW_SPEED_GEAR_NAME:
            case Common.TSECO_DI2_ENGINE_NAME:
            case Common.TSECO_DI2_CHARGE_SIGN_NAME: {
                convertTooltipString = convertWiperGearRemark(value);
                break;
            }

            case Common.TSECO_DI2_AIR_PRESSURE2_NAME:
            case Common.TSECO_DI2_HYDRAULIC_NAME:
            case Common.TSECO_DI2_COOLANT_LEVEL_NAME:
            case Common.TSECO_DI2_AIR_CLEENER_FILTER_NAME:
            case Common.TSECO_DI2_FUEL_FILTER_NAME:
            case Common.TSECO_DI2_OIL_FILTER_NAME:
            case Common.TSECO_DI2_LEFT_FRONT_BRAKE_PAD_WEAR_NAME:
            case Common.TSECO_DI2_LEFT_MIDDLE_BRAKE_PAD_WEAR_NAME:
            case Common.TSECO_DI2_LEFT_BACK_BRAKE_PAD_WEAR_NAME:
            case Common.TSECO_DI2_RIGHT_FRONT_BRAKE_PAD_WEAR_NAME:
            case Common.TSECO_DI2_RIGHT_MIDDLE_BRAKE_PAD_WEAR_NAME:
            case Common.TSECO_DI2_RIGHT_BACK_BRAKE_PAD_WEAR_NAME:
            case Common.TSECO_DI2_WATER_TEMP_NAME:
            case Common.TSECO_DI2_VOLT_NAME: {
                convertTooltipString = convertAirPressAlarmRemark(value);
                break;
            }

            case Common.TSECO_BCS1_BMS_MODE_NAME:
            case Common.TSECO_BCS2_TMS_MODE_NAME: {
                convertTooltipString = convertBmsModeRemark(value);
                break;
            }

            case Common.TSECO_BCS1_HIGH_VOLT_REQ_NAME: {
                convertTooltipString = convertHighVoltReqRemark(value);
                break;
            }

            case Common.TSECO_BCS1_CHARGE_STATUS_NAME: {
                convertTooltipString = convertChargeStatusRemark(value);
                break;
            }

            case Common.TSECO_BCS1_BMS_HVR_STATUS_NAME:
            case Common.TSECO_BCS2_TMS_HVR_STATUS_NAME: {
                convertTooltipString = convertBmsHvrStatusRemark(value);
                break;
            }

            case Common.TSECO_BCS2_PANEL_STATUS_NAME: {
                convertTooltipString = convertSysReadyRemark(value);
                break;
            }

            case Common.TSECO_BCS2_WATER_PUMP_STATUS_NAME: {
                convertTooltipString = convertWaterPumpStatusRemark(value);
                break;
            }

            case Common.TSECO_BCS2_AIRCON_STATUS4_NAME:
            case Common.TSECO_BCS2_AIRCON_STATUS5_NAME: {
                convertTooltipString = convertAirconStatus4Remark(value);
                break;
            }

            case Common.TSECO_BCS2_PLATE_WATER_IN_TEMP_NAME:
            case Common.TSECO_BCS2_PLATE_WATER_OUT_TEMP_NAME: {
                convertTooltipString = convertPlateWaterInTempRemark(value);
                break;
            }

            case Common.TSECO_BCS2_TMS_FAULT_CODE_NAME: {
                convertTooltipString = convertTmsFaultCodeRemark(value);
                break;
            }

            case Common.TSECO_BCS2_TMS_FAULT_CODE_LEVEL_NAME: {
                convertTooltipString = convertTmsFaultCodeLevelRemark(value);
                break;
            }

            case Common.TSECO_IS1_FAIL_GRADE_NAME: {
                convertTooltipString = convertFailGradeRemark(value);
                break;
            }

            case Common.TSECO_IS1_WORK_MODE_NAME: {
                convertTooltipString = convertWorkModeRemark(value);
                break;
            }

            case Common.TSECO_DTCI_SYS_FAULT_MODE_NAME: {
                convertTooltipString = convertSysFaultModeRemark(value);
                break;
            }

            default:
                break;
        }
        return convertTooltipString;
    };

    return (
        <div>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <div>
                    <CanDataWrapper
                        title="EV Information"
                        id="evInfo"
                        panelOpen={evInfoPanelOpen}
                        onChangePanelOpen={(panelOpened) => setEvInfoPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataEvInfo}
                        tableSelectRowKeys={selectedEvInfo}
                        onChangeTableSelect={onChangeEvInfo}
                        tableGetTooltips={getTabelTooltipEvInfo}
                        seriesChartDatas={seriesChartDatasEvInfo}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setEvInfoChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="EV Information 1"
                        id="evInfo1"
                        panelOpen={evInfo1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setEvInfo1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataEvInfo1}
                        tableSelectRowKeys={selectedEvInfo1}
                        onChangeTableSelect={onChangeEvInfo1}
                        tableGetTooltips={getTabelTooltipEvInfo1}
                        seriesChartDatas={seriesChartDatasEvInfo1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setEvInfo1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="EV Information 2"
                        id="evInfo2"
                        panelOpen={evInfo2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setEvInfo2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataEvInfo2}
                        tableSelectRowKeys={selectedEvInfo2}
                        onChangeTableSelect={onChangeEvInfo2}
                        tableGetTooltips={getTabelTooltipEvInfo2}
                        seriesChartDatas={seriesChartDatasEvInfo2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setEvInfo2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="EV Information 3"
                        id="evInfo3"
                        panelOpen={evInfo3PanelOpen}
                        onChangePanelOpen={(panelOpened) => setEvInfo3PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataEvInfo3}
                        tableSelectRowKeys={selectedEvInfo3}
                        onChangeTableSelect={onChangeEvInfo3}
                        tableGetTooltips={getTabelTooltipEvInfo3}
                        seriesChartDatas={seriesChartDatasEvInfo3}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setEvInfo3ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="EV DTC"
                        id="evDtc"
                        panelOpen={evDtcPanelOpen}
                        onChangePanelOpen={(panelOpened) => setEvDtcPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataEvDtc}
                        tableSelectRowKeys={selectedEvDtc}
                        onChangeTableSelect={onChangeEvDtc}
                        tableGetTooltips={getTabelTooltipEvDtc}
                        seriesChartDatas={seriesChartDatasEvDtc}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setEvDtcChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="접촉기 제어 메시지"
                        id="contactorCmd"
                        panelOpen={ccmPanelOpen}
                        onChangePanelOpen={(panelOpened) => setCcmPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCcm}
                        tableSelectRowKeys={selectedCcm}
                        onChangeTableSelect={onChangeCcm}
                        tableGetTooltips={getTabelTooltipCcm}
                        seriesChartDatas={seriesChartDatasCcm}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCcmChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 기본 정보 1"
                        id="batInfo1"
                        panelOpen={batInfo1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatInfo1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatInfo1}
                        tableSelectRowKeys={selectedBatInfo1}
                        onChangeTableSelect={onChangeBatInfo1}
                        tableGetTooltips={getTabelTooltipBatInfo1}
                        seriesChartDatas={seriesChartDatasBatInfo1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatInfo1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="고압 릴레이 상태"
                        id="hvrStatus"
                        panelOpen={hvrStatusPanelOpen}
                        onChangePanelOpen={(panelOpened) => setHvrStatusPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataHvrStatus}
                        tableSelectRowKeys={selectedHvrStatus}
                        onChangeTableSelect={onChangeHvrStatus}
                        tableGetTooltips={getTabelTooltipHvrStatus}
                        seriesChartDatas={seriesChartDatasHvrStatus}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setHvrStatusChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="동력 배터리 기본 정보2-한계값"
                        id="batLimit"
                        panelOpen={batLimitPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatLimitPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatLimit}
                        tableSelectRowKeys={selectedBatLimit}
                        onChangeTableSelect={onChangeBatLimit}
                        tableGetTooltips={getTabelTooltipBatLimit}
                        seriesChartDatas={seriesChartDatasBatLimit}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatLimitChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 고장 정보 알람"
                        id="batFaultAlarm"
                        panelOpen={batFaultAlarmPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatFaultAlarmPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatFaultAlarm}
                        tableSelectRowKeys={selectedBatFaultAlarm}
                        onChangeTableSelect={onChangeBatFaultAlarm}
                        tableGetTooltips={getTabelTooltipBatFaultAlarm}
                        seriesChartDatas={seriesChartDatasBatFaultAlarm}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatFaultAlarmChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 기본 정보 2"
                        id="batInfo2"
                        panelOpen={batInfo2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatInfo2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatInfo2}
                        tableSelectRowKeys={selectedBatInfo2}
                        onChangeTableSelect={onChangeBatInfo2}
                        tableGetTooltips={getTabelTooltipBatInfo2}
                        seriesChartDatas={seriesChartDatasBatInfo2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatInfo2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 모듈 기본 정보"
                        id="batModuleInfo"
                        panelOpen={batModuleInfoPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatModuleInfoPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatModuleInfo}
                        tableSelectRowKeys={selectedBatModuleInfo}
                        onChangeTableSelect={onChangeBatModuleInfo}
                        tableGetTooltips={getTabelTooltipBatModuleInfo}
                        seriesChartDatas={seriesChartDatasBatModuleInfo}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatModuleInfoChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="셀 전압 정보 1"
                        id="cellVolt1"
                        panelOpen={cellVolt1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellVolt1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellVolt1}
                        tableSelectRowKeys={selectedCellVolt1}
                        onChangeTableSelect={onChangeCellVolt1}
                        tableGetTooltips={getTabelTooltipCellVolt1}
                        seriesChartDatas={seriesChartDatasCellVolt1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellVolt1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="셀 전압 정보 2"
                        id="cellVolt2"
                        panelOpen={cellVolt2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellVolt2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellVolt2}
                        tableSelectRowKeys={selectedCellVolt2}
                        onChangeTableSelect={onChangeCellVolt2}
                        tableGetTooltips={getTabelTooltipCellVolt2}
                        seriesChartDatas={seriesChartDatasCellVolt2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellVolt2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="셀 전압 정보 3"
                        id="cellVolt3"
                        panelOpen={cellVolt3PanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellVolt3PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellVolt3}
                        tableSelectRowKeys={selectedCellVolt3}
                        onChangeTableSelect={onChangeCellVolt3}
                        tableGetTooltips={getTabelTooltipCellVolt3}
                        seriesChartDatas={seriesChartDatasCellVolt3}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellVolt3ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="셀 전압 정보 4"
                        id="cellVolt4"
                        panelOpen={cellVolt4PanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellVolt4PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellVolt4}
                        tableSelectRowKeys={selectedCellVolt4}
                        onChangeTableSelect={onChangeCellVolt4}
                        tableGetTooltips={getTabelTooltipCellVolt4}
                        seriesChartDatas={seriesChartDatasCellVolt4}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellVolt4ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="셀 전압 정보 5"
                        id="cellVolt5"
                        panelOpen={cellVolt5PanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellVolt5PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellVolt5}
                        tableSelectRowKeys={selectedCellVolt5}
                        onChangeTableSelect={onChangeCellVolt5}
                        tableGetTooltips={getTabelTooltipCellVolt5}
                        seriesChartDatas={seriesChartDatasCellVolt5}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellVolt5ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="셀 전압 정보 6"
                        id="cellVolt6"
                        panelOpen={cellVolt6PanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellVolt6PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellVolt6}
                        tableSelectRowKeys={selectedCellVolt6}
                        onChangeTableSelect={onChangeCellVolt6}
                        tableGetTooltips={getTabelTooltipCellVolt6}
                        seriesChartDatas={seriesChartDatasCellVolt6}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellVolt6ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="모듈 내 배터리 온도 정보 1"
                        id="moduleBatTemp1"
                        panelOpen={moduleBatTemp1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setModuleBatTemp1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataModuleBatTemp1}
                        tableSelectRowKeys={selectedModuleBatTemp1}
                        onChangeTableSelect={onChangeModuleBatTemp1}
                        tableGetTooltips={getTabelTooltipModuleBatTemp1}
                        seriesChartDatas={seriesChartDatasModuleBatTemp1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setModuleBatTemp1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="모듈 내 배터리 온도 정보 2"
                        id="moduleBatTemp2"
                        panelOpen={moduleBatTemp2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setModuleBatTemp2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataModuleBatTemp2}
                        tableSelectRowKeys={selectedModuleBatTemp2}
                        onChangeTableSelect={onChangeModuleBatTemp2}
                        tableGetTooltips={getTabelTooltipModuleBatTemp2}
                        seriesChartDatas={seriesChartDatasModuleBatTemp2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setModuleBatTemp2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="절연 측정"
                        id="insulMeasurement"
                        panelOpen={insulMeasurementPanelOpen}
                        onChangePanelOpen={(panelOpened) => setInsulMeasurementPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataInsulMeasurement}
                        tableSelectRowKeys={selectedInsulMeasurement}
                        onChangeTableSelect={onChangeInsulMeasurement}
                        tableGetTooltips={getTabelTooltipInsulMeasurement}
                        seriesChartDatas={seriesChartDatasInsulMeasurement}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setInsulMeasurementChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="전압 측정 1"
                        id="voltMeasurement1"
                        panelOpen={voltMeasurement1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVoltMeasurement1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVoltMeasurement1}
                        tableSelectRowKeys={selectedVoltMeasurement1}
                        onChangeTableSelect={onChangeVoltMeasurement1}
                        tableGetTooltips={getTabelTooltipVoltMeasurement1}
                        seriesChartDatas={seriesChartDatasVoltMeasurement1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVoltMeasurement1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="전압 측정 2"
                        id="voltMeasurement2"
                        panelOpen={voltMeasurement2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVoltMeasurement2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVoltMeasurement2}
                        tableSelectRowKeys={selectedVoltMeasurement2}
                        onChangeTableSelect={onChangeVoltMeasurement2}
                        tableGetTooltips={getTabelTooltipVoltMeasurement2}
                        seriesChartDatas={seriesChartDatasVoltMeasurement2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVoltMeasurement2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="전압 측정 3"
                        id="voltMeasurement3"
                        panelOpen={voltMeasurement3PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVoltMeasurement3PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVoltMeasurement3}
                        tableSelectRowKeys={selectedVoltMeasurement3}
                        onChangeTableSelect={onChangeVoltMeasurement3}
                        tableGetTooltips={getTabelTooltipVoltMeasurement3}
                        seriesChartDatas={seriesChartDatasVoltMeasurement3}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVoltMeasurement3ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Vehicle Info"
                        id="vehicleInfo"
                        panelOpen={vehicleInfoPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVehicleInfoPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVehicleInfo}
                        tableSelectRowKeys={selectedVehicleInfo}
                        onChangeTableSelect={onChangeVehicleInfo}
                        tableGetTooltips={getTabelTooltipVehicleInfo}
                        seriesChartDatas={seriesChartDatasVehicleInfo}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVehicleInfoChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="계기판 작동 2"
                        id="dashboardInfo2"
                        panelOpen={dashboardInfo2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setDashboardInfo2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataDashboardInfo2}
                        tableSelectRowKeys={selectedDashboardInfo2}
                        onChangeTableSelect={onChangeDashboardInfo2}
                        tableGetTooltips={getTabelTooltipDashboardInfo2}
                        seriesChartDatas={seriesChartDatasDashboardInfo2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setDashboardInfo2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 쿨링 시스템 1"
                        id="batCoolingSys1"
                        panelOpen={batCoolingSys1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatCoolingSys1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatCoolingSys1}
                        tableSelectRowKeys={selectedBatCoolingSys1}
                        onChangeTableSelect={onChangeBatCoolingSys1}
                        tableGetTooltips={getTabelTooltipBatCoolingSys1}
                        seriesChartDatas={seriesChartDatasBatCoolingSys1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatCoolingSys1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 쿨링 시스템 2"
                        id="batCoolingSys2"
                        panelOpen={batCoolingSys2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatCoolingSys2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatCoolingSys2}
                        tableSelectRowKeys={selectedBatCoolingSys2}
                        onChangeTableSelect={onChangeBatCoolingSys2}
                        tableGetTooltips={getTabelTooltipBatCoolingSys2}
                        seriesChartDatas={seriesChartDatasBatCoolingSys2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatCoolingSys2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="IPU STATUS 1"
                        id="is1"
                        panelOpen={is1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setIs1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataIs1}
                        tableSelectRowKeys={selectedIs1}
                        onChangeTableSelect={onChangeIs1}
                        tableGetTooltips={getTabelTooltipIs1}
                        seriesChartDatas={seriesChartDatasIs1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setIs1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="DTC"
                        id="dtci"
                        panelOpen={dtciPanelOpen}
                        onChangePanelOpen={(panelOpened) => setDtciPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataDtci}
                        tableSelectRowKeys={selectedDtci}
                        onChangeTableSelect={onChangeDtci}
                        tableGetTooltips={getTabelTooltipDtci}
                        seriesChartDatas={seriesChartDatasDtci}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setDtciChartMounted(true)}
                    />
                </div>
            </Space>
        </div>
    );
}

export default CanDataTSEcoViewer;
