import { useEffect, useState } from "react";

import moment from "moment";

import * as CanDataCoCoKart from "../../commons/CanDataCoCoKart";

import * as Common from "../../commons/common";
import * as Strings from "../../commons/string";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import Space from "antd/es/space/index";
import CanDataWrapper, { CanTableType } from "./CanDataWrapper";

function CanDataCoCoKartViewer({
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataCoCoKart.CanData>();

    const convertRemarkWithUnit = (value: number, unit: string) => {
        return value + unit;
    };

    // const convertRemarkWithUnitNException = (value: number, unit: string, bitLength: number) => {
    //     if (bitLength === 12) {
    //         if (value === 0x0ffe) return "이상";
    //         else if (value === 0x0fff) return "무효";
    //     } else if (bitLength === 8) {
    //         if (value === 0xfe) return "이상";
    //         else if (value === 0xff) return "무효";
    //     } else if (bitLength === 4) {
    //         if (value === 0xe) return "이상";
    //         else if (value === 0xf) return "무효";
    //     }

    //     return value + unit;
    // };

    // const convertRemarkToBinaryString = (value: number) => {
    //     return ("00000000" + value.toString(2)).slice(-8);
    // };

    //#region BmsInfo1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsInfo1PanelOpen, setBmsInfo1PanelOpen] = useState(true);
    const [bmsInfo1ChartMounted, setBmsInfo1ChartMounted] = useState(false);
    const [selectedBmsInfo1, setSelectedBmsInfo1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBmsInfo1, setSeriesChartDatasBmsInfo1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBmsInfo1Volt, setChartBmsInfo1Volt] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_VOLT_NAME,
        data: [],
    });
    const [chartBmsInfo1Curr, setChartBmsInfo1Curr] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_CURR_NAME,
        data: [],
    });
    const [chartBmsInfo1Soc, setChartBmsInfo1Soc] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_SOC_NAME,
        data: [],
    });
    const [chartBmsInfo1Proximity, setChartBmsInfo1Proximity] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_PROXIMITY_NAME,
        data: [],
    });
    const [chartBmsInfo1ChgStat, setChartBmsInfo1ChgStat] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_CHG_STAT_NAME,
        data: [],
    });
    const [chartBmsInfo1LowSoc, setChartBmsInfo1LowSoc] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_LOW_SOC_NAME,
        data: [],
    });
    const [chartBmsInfo1Ready, setChartBmsInfo1Ready] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_READY_NAME,
        data: [],
    });
    const [chartBmsInfo1RlyDisChg, setChartBmsInfo1RlyDisChg] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_RLY_DIS_CHG_NAME,
        data: [],
    });
    const [chartBmsInfo1RlyChg, setChartBmsInfo1RlyChg] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_RLY_CHG_NAME,
        data: [],
    });
    const [chartBmsInfo1ChgFinish, setChartBmsInfo1ChgFinish] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_CHG_FINISH_NAME,
        data: [],
    });
    const [chartBmsInfo1ErrCode, setChartBmsInfo1ErrCode] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_ERR_CODE_NAME,
        data: [],
    });
    const [chartBmsInfo1ErrLevel, setChartBmsInfo1ErrLevel] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_ERR_LEVEL_NAME,
        data: [],
    });
    const [chartBmsInfo1AliveCnt, setChartBmsInfo1AliveCnt] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_1_ALIVE_CNT_NAME,
        data: [],
    });

    const getTabelTooltipBmsInfo1 = (key: React.Key) => {
        switch (key) {
            case Common.BMS_INFO_1_VOLT_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsVolt
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Pack Voltage - Y=X*0.1
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_CURR_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsCurr
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Charge & Discharge Current
                            <br />
                            Y=X*0.1-500
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_SOC_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsSOC
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery SOC - Y=X
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_PROXIMITY_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsProximity
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Charge Proximity
                            <br />
                            0 : Not Connect
                            <br />1 : Connected
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_CHG_STAT_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsChgStat
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Charge Status
                            <br />
                            0 : Discharge
                            <br />1 : Charge
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_LOW_SOC_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsLowSOC
                        </p>
                        <p>
                            Remarks:
                            <br />
                            SOC Status
                            <br />
                            0 : Normal
                            <br />1 : Low SOC - SOC 30%
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_READY_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsReady
                        </p>
                        <p>
                            Remarks:
                            <br />
                            BMS Status
                            <br />
                            0 : Not Ready
                            <br />1 : Ready
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_RLY_DIS_CHG_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsRlyDischg
                        </p>
                        <p>
                            Remarks:
                            <br />
                            BMS Discharge Relay status
                            <br />
                            0 : Open
                            <br />1 : Closed
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_RLY_CHG_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsRlyChg
                        </p>
                        <p>
                            Remarks:
                            <br />
                            BMS Charge Relay status
                            <br />
                            0 : Open
                            <br />1 : Closed
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_CHG_FINISH_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsChgFinish
                        </p>
                        <p>
                            Remarks:
                            <br />
                            BMS Charge Finish
                            <br />
                            0 : Not Finish
                            <br />1 : Finish
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_ERR_CODE_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsErrCode
                        </p>
                        <p>
                            Remarks:
                            <br />
                            BMS Error Code
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_ERR_LEVEL_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsErrLevel
                        </p>
                        <p>
                            Remarks:
                            <br />
                            BMS Fault Level
                            <br />
                            0 : No Fault
                            <br />
                            1 : Level High - BMS stop
                            <br />
                            2 : Level Middle - BMS power limit
                            <br />3 : Level Warning
                        </p>
                    </>
                );

            case Common.BMS_INFO_1_ALIVE_CNT_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_1
                            <br />
                            CAN ID: 0x0CFF50F4
                            <br />
                            Name: BmsAliveCnt
                        </p>
                        <p>
                            Remarks:
                            <br />
                            BmsErrCode
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertProximityRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Not Connect";
                break;
            case 1:
                valueString = "Connected";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertChgStatRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Discharge";
                break;
            case 1:
                valueString = "Charge";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLowSocRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "Low SOC(30%)";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertReadyRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Not Ready";
                break;
            case 1:
                valueString = "Ready";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertRlyRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Open";
                break;
            case 1:
                valueString = "Closed";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertChgFinishRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Not Finish";
                break;
            case 1:
                valueString = "Finish";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertErrLevelRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "No Fault";
                break;
            case 1:
                valueString = "Level High - BMS stop";
                break;
            case 2:
                valueString = "Level Middle - BMS power limit";
                break;
            case 3:
                valueString = "Level Warning";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBmsInfo1VoltValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo1?.volt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsInfo1CurrValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo1?.curr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBmsInfo1SocValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo1?.soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBmsInfo1ProximityValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_PROXIMITY_KEY) && currentCanData !== undefined) {
            return convertProximityRemark(currentCanData.bmsInfo1?.proximity);
        }
        return "";
    };

    const getBmsInfo1ChgStatValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_CHG_STAT_KEY) && currentCanData !== undefined) {
            return convertChgStatRemark(currentCanData.bmsInfo1?.chgStat);
        }
        return "";
    };

    const getBmsInfo1LowSocValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_LOW_SOC_KEY) && currentCanData !== undefined) {
            return convertLowSocRemark(currentCanData.bmsInfo1?.lowSoc);
        }
        return "";
    };

    const getBmsInfo1ReadyValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_READY_KEY) && currentCanData !== undefined) {
            return convertReadyRemark(currentCanData.bmsInfo1?.ready);
        }
        return "";
    };

    const getBmsInfo1RlyDisChgValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_RLY_DIS_CHG_KEY) && currentCanData !== undefined) {
            return convertRlyRemark(currentCanData.bmsInfo1?.rlyDisChg);
        }
        return "";
    };

    const getBmsInfo1RlyChgValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_RLY_CHG_KEY) && currentCanData !== undefined) {
            return convertRlyRemark(currentCanData.bmsInfo1?.rlyChg);
        }
        return "";
    };

    const getBmsInfo1ChgFinishValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_CHG_FINISH_KEY) && currentCanData !== undefined) {
            return convertChgFinishRemark(currentCanData.bmsInfo1?.chgFinish);
        }
        return "";
    };

    const getBmsInfo1ErrCodeValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_ERR_CODE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bmsInfo1?.errCode);
        }
        return "";
    };

    const getBmsInfo1ErrLevelValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_ERR_LEVEL_KEY) && currentCanData !== undefined) {
            return convertErrLevelRemark(currentCanData.bmsInfo1?.errLevel);
        }
        return "";
    };

    const getBmsInfo1AliveCntValue = () => {
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_ALIVE_CNT_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bmsInfo1?.aliveCnt);
        }
        return "";
    };

    const onChangeBmsInfo1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsInfo1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsInfo1: Array<TypeUtils.ChartData> = [];

        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_VOLT_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1Volt);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_CURR_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1Curr);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_SOC_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1Soc);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_PROXIMITY_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1Proximity);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_CHG_STAT_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1ChgStat);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_LOW_SOC_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1LowSoc);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_READY_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1Ready);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_RLY_DIS_CHG_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1RlyDisChg);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_RLY_CHG_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1RlyChg);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_CHG_FINISH_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1ChgFinish);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_ERR_CODE_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1ErrCode);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_ERR_LEVEL_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1ErrLevel);
        if (selectedBmsInfo1.includes(Common.BMS_INFO_1_ALIVE_CNT_KEY)) chartDatasBmsInfo1.push(chartBmsInfo1AliveCnt);

        setSeriesChartDatasBmsInfo1(chartDatasBmsInfo1);
    }, [
        selectedBmsInfo1,
        chartBmsInfo1Volt,
        chartBmsInfo1Curr,
        chartBmsInfo1Soc,
        chartBmsInfo1Proximity,
        chartBmsInfo1ChgStat,
        chartBmsInfo1LowSoc,
        chartBmsInfo1Ready,
        chartBmsInfo1RlyDisChg,
        chartBmsInfo1RlyChg,
        chartBmsInfo1ChgFinish,
        chartBmsInfo1ErrCode,
        chartBmsInfo1ErrLevel,
        chartBmsInfo1AliveCnt,
    ]);

    const tableDataBmsInfo1: CanTableType[] = [
        {
            key: Common.BMS_INFO_1_VOLT_KEY,
            name: Common.BMS_INFO_1_VOLT_NAME,
            value: getBmsInfo1VoltValue(),
        },
        {
            key: Common.BMS_INFO_1_CURR_KEY,
            name: Common.BMS_INFO_1_CURR_NAME,
            value: getBmsInfo1CurrValue(),
        },
        {
            key: Common.BMS_INFO_1_SOC_KEY,
            name: Common.BMS_INFO_1_SOC_NAME,
            value: getBmsInfo1SocValue(),
        },
        {
            key: Common.BMS_INFO_1_PROXIMITY_KEY,
            name: Common.BMS_INFO_1_PROXIMITY_NAME,
            value: getBmsInfo1ProximityValue(),
        },
        {
            key: Common.BMS_INFO_1_CHG_STAT_KEY,
            name: Common.BMS_INFO_1_CHG_STAT_NAME,
            value: getBmsInfo1ChgStatValue(),
        },
        {
            key: Common.BMS_INFO_1_LOW_SOC_KEY,
            name: Common.BMS_INFO_1_LOW_SOC_NAME,
            value: getBmsInfo1LowSocValue(),
        },
        {
            key: Common.BMS_INFO_1_READY_KEY,
            name: Common.BMS_INFO_1_READY_NAME,
            value: getBmsInfo1ReadyValue(),
        },
        {
            key: Common.BMS_INFO_1_RLY_DIS_CHG_KEY,
            name: Common.BMS_INFO_1_RLY_DIS_CHG_NAME,
            value: getBmsInfo1RlyDisChgValue(),
        },
        {
            key: Common.BMS_INFO_1_RLY_CHG_KEY,
            name: Common.BMS_INFO_1_RLY_CHG_NAME,
            value: getBmsInfo1RlyChgValue(),
        },
        {
            key: Common.BMS_INFO_1_CHG_FINISH_KEY,
            name: Common.BMS_INFO_1_CHG_FINISH_NAME,
            value: getBmsInfo1ChgFinishValue(),
        },
        {
            key: Common.BMS_INFO_1_ERR_CODE_KEY,
            name: Common.BMS_INFO_1_ERR_CODE_NAME,
            value: getBmsInfo1ErrCodeValue(),
        },
        {
            key: Common.BMS_INFO_1_ERR_LEVEL_KEY,
            name: Common.BMS_INFO_1_ERR_LEVEL_NAME,
            value: getBmsInfo1ErrLevelValue(),
        },
        {
            key: Common.BMS_INFO_1_ALIVE_CNT_KEY,
            name: Common.BMS_INFO_1_ALIVE_CNT_NAME,
            value: getBmsInfo1AliveCntValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BmsInfo2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsInfo2PanelOpen, setBmsInfo2PanelOpen] = useState(true);
    const [bmsInfo2ChartMounted, setBmsInfo2ChartMounted] = useState(false);
    const [selectedBmsInfo2, setSelectedBmsInfo2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBmsInfo2, setSeriesChartDatasBmsInfo2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBmsInfo2CellVoltMax, setChartBmsInfo2CellVoltMax] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_2_CELL_VOLT_MAX_NAME,
        data: [],
    });

    const [chartBmsInfo2CellVoltMin, setChartBmsInfo2CellVoltMin] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_2_CELL_VOLT_MIN_NAME,
        data: [],
    });

    const [chartBmsInfo2CellTempMax, setChartBmsInfo2CellTempMax] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_2_CELL_TEMP_MAX_NAME,
        data: [],
    });

    const [chartBmsInfo2CellTempMin, setChartBmsInfo2CellTempMin] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_2_CELL_TEMP_MIN_NAME,
        data: [],
    });

    const [chartBmsInfo2DisChgCurrMax, setChartBmsInfo2DisChgCurrMax] = useState<TypeUtils.ChartData>({
        name: Common.BMS_INFO_2_DISCHG_CURR_MAX_NAME,
        data: [],
    });

    const getTabelTooltipBmsInfo2 = (key: React.Key) => {
        switch (key) {
            case Common.BMS_INFO_2_CELL_VOLT_MAX_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_2
                            <br />
                            CAN ID: 0x0CFE50F4
                            <br />
                            Name: BmsCellVoltMax
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Cell Max Voltage - Y=X*0.01
                        </p>
                    </>
                );

            case Common.BMS_INFO_2_CELL_VOLT_MIN_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_2
                            <br />
                            CAN ID: 0x0CFE50F4
                            <br />
                            Name: BmsCellVoltMin
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Cell Min Voltage - Y=X*0.01
                        </p>
                    </>
                );

            case Common.BMS_INFO_2_CELL_TEMP_MAX_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_2
                            <br />
                            CAN ID: 0x0CFE50F4
                            <br />
                            Name: BmsCellTempMax
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Cell Max Temp - Y=X-40
                        </p>
                    </>
                );

            case Common.BMS_INFO_2_CELL_TEMP_MIN_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_2
                            <br />
                            CAN ID: 0x0CFE50F4
                            <br />
                            Name: BmsCellTempMin
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Cell Min Temp - Y=X-40
                        </p>
                    </>
                );

            case Common.BMS_INFO_2_DISCHG_CURR_MAX_KEY:
                return (
                    <>
                        <p>
                            BMS_Base_Info_2
                            <br />
                            CAN ID: 0x0CFE50F4
                            <br />
                            Name: BmsDischgCurrMax
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Max Discharge Current - Y=X*0.1
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBmsInfo2CellVoltMaxValue = () => {
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_VOLT_MAX_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo2?.cellVoltMax, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsInfo2CellVoltMinValue = () => {
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_VOLT_MIN_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo2?.cellVoltMin, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsInfo2CellTempMaxValue = () => {
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_TEMP_MAX_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo2?.cellTempMax, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBmsInfo2CellTempMinValue = () => {
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_TEMP_MIN_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo2?.cellTempMin, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBmsInfo2DisChgCurrMaxValue = () => {
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_DISCHG_CURR_MAX_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsInfo2?.disChgCurrMax, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeBmsInfo2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsInfo2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsInfo2: Array<TypeUtils.ChartData> = [];

        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_VOLT_MAX_KEY)) chartDatasBmsInfo2.push(chartBmsInfo2CellVoltMax);
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_VOLT_MIN_KEY)) chartDatasBmsInfo2.push(chartBmsInfo2CellVoltMin);
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_TEMP_MAX_KEY)) chartDatasBmsInfo2.push(chartBmsInfo2CellTempMax);
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_CELL_TEMP_MIN_KEY)) chartDatasBmsInfo2.push(chartBmsInfo2CellTempMin);
        if (selectedBmsInfo2.includes(Common.BMS_INFO_2_DISCHG_CURR_MAX_KEY)) chartDatasBmsInfo2.push(chartBmsInfo2DisChgCurrMax);

        setSeriesChartDatasBmsInfo2(chartDatasBmsInfo2);
    }, [
        selectedBmsInfo2,
        chartBmsInfo2CellVoltMax,
        chartBmsInfo2CellVoltMin,
        chartBmsInfo2CellTempMax,
        chartBmsInfo2CellTempMin,
        chartBmsInfo2DisChgCurrMax,
    ]);

    const tableDataBmsInfo2: CanTableType[] = [
        {
            key: Common.BMS_INFO_2_CELL_VOLT_MAX_KEY,
            name: Common.BMS_INFO_2_CELL_VOLT_MAX_NAME,
            value: getBmsInfo2CellVoltMaxValue(),
        },
        {
            key: Common.BMS_INFO_2_CELL_VOLT_MIN_KEY,
            name: Common.BMS_INFO_2_CELL_VOLT_MIN_NAME,
            value: getBmsInfo2CellVoltMinValue(),
        },
        {
            key: Common.BMS_INFO_2_CELL_TEMP_MAX_KEY,
            name: Common.BMS_INFO_2_CELL_TEMP_MAX_NAME,
            value: getBmsInfo2CellTempMaxValue(),
        },
        {
            key: Common.BMS_INFO_2_CELL_TEMP_MIN_KEY,
            name: Common.BMS_INFO_2_CELL_TEMP_MIN_NAME,
            value: getBmsInfo2CellTempMinValue(),
        },
        {
            key: Common.BMS_INFO_2_DISCHG_CURR_MAX_KEY,
            name: Common.BMS_INFO_2_DISCHG_CURR_MAX_NAME,
            value: getBmsInfo2DisChgCurrMaxValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BmsChgReq
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsChgReqPanelOpen, setBmsChgReqPanelOpen] = useState(true);
    const [bmsChgReqChartMounted, setBmsChgReqChartMounted] = useState(false);
    const [selectedBmsChgReq, setSelectedBmsChgReq] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBmsChgReq, setSeriesChartDatasBmsChgReq] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBmsChgReqVoltMax, setChartBmsChgReqVoltMax] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_REQ_VOLT_MAX_NAME,
        data: [],
    });
    const [chartBmsChgReqCurrMax, setChartBmsChgReqCurrMax] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_REQ_CURR_MAX_NAME,
        data: [],
    });
    const [chartBmsChgReqObcOn, setChartBmsChgReqObcOn] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_REQ_OBC_ON_NAME,
        data: [],
    });
    const [chartBmsChgReqObcMode, setChartBmsChgReqObcMode] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_REQ_OBC_MODE_NAME,
        data: [],
    });

    const getTabelTooltipBmsChgReq = (key: React.Key) => {
        switch (key) {
            case Common.BMS_CHG_REQ_VOLT_MAX_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Req
                            <br />
                            CAN ID: 0x1806E5F4
                            <br />
                            Name: BmsChgVoltMax
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Pack Charge Voltage Max
                        </p>
                    </>
                );

            case Common.BMS_CHG_REQ_CURR_MAX_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Req
                            <br />
                            CAN ID: 0x1806E5F4
                            <br />
                            Name: BmsChgCurrMax
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Pack Charge Current Max
                        </p>
                    </>
                );

            case Common.BMS_CHG_REQ_OBC_ON_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Req
                            <br />
                            CAN ID: 0x1806E5F4
                            <br />
                            Name: BmsCtrl_ObcON
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC Turns ON/OFF Control
                            <br />
                            0 : Charger Turns on the charge
                            <br />1 : Charger Turns off ouput for Battery Protection
                        </p>
                    </>
                );

            case Common.BMS_CHG_REQ_OBC_MODE_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Req
                            <br />
                            CAN ID: 0x1806E5F4
                            <br />
                            Name: BmsCtrl_ObcMode
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC Mode Control
                            <br />
                            0 : Charging mode
                            <br />1 : Heating mode
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertObcOnRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Charger Turns on the charge";
                break;
            case 1:
                valueString = "Charger Turns off ouput for Battery Protection";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertObcModeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Charging mode";
                break;
            case 1:
                valueString = "Heating mode";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBmsChgReqVoltMaxValue = () => {
        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_VOLT_MAX_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsChgReq?.voltMax, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsChgReqCurrMaxValue = () => {
        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_CURR_MAX_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsChgReq?.currMax, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBmsChgReqObcOnValue = () => {
        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_OBC_ON_KEY) && currentCanData !== undefined) {
            return convertObcOnRemark(currentCanData.bmsChgReq?.obcOn);
        }
        return "";
    };

    const getBmsChgReqObcModeValue = () => {
        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_OBC_MODE_KEY) && currentCanData !== undefined) {
            return convertObcModeRemark(currentCanData.bmsChgReq?.obcMode);
        }
        return "";
    };

    const onChangeBmsChgReq = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsChgReq(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsChgReq: Array<TypeUtils.ChartData> = [];

        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_VOLT_MAX_KEY)) chartDatasBmsChgReq.push(chartBmsChgReqVoltMax);
        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_CURR_MAX_KEY)) chartDatasBmsChgReq.push(chartBmsChgReqCurrMax);
        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_OBC_ON_KEY)) chartDatasBmsChgReq.push(chartBmsChgReqObcOn);
        if (selectedBmsChgReq.includes(Common.BMS_CHG_REQ_OBC_MODE_KEY)) chartDatasBmsChgReq.push(chartBmsChgReqObcMode);

        setSeriesChartDatasBmsChgReq(chartDatasBmsChgReq);
    }, [selectedBmsChgReq, chartBmsChgReqVoltMax, chartBmsChgReqCurrMax, chartBmsChgReqObcOn, chartBmsChgReqObcMode]);

    const tableDataBmsChgReq: CanTableType[] = [
        {
            key: Common.BMS_CHG_REQ_VOLT_MAX_KEY,
            name: Common.BMS_CHG_REQ_VOLT_MAX_NAME,
            value: getBmsChgReqVoltMaxValue(),
        },
        {
            key: Common.BMS_CHG_REQ_CURR_MAX_KEY,
            name: Common.BMS_CHG_REQ_CURR_MAX_NAME,
            value: getBmsChgReqCurrMaxValue(),
        },
        {
            key: Common.BMS_CHG_REQ_OBC_ON_KEY,
            name: Common.BMS_CHG_REQ_OBC_ON_NAME,
            value: getBmsChgReqObcOnValue(),
        },
        {
            key: Common.BMS_CHG_REQ_OBC_MODE_KEY,
            name: Common.BMS_CHG_REQ_OBC_MODE_NAME,
            value: getBmsChgReqObcModeValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BmsChgTime
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsChgTimePanelOpen, setBmsChgTimePanelOpen] = useState(true);
    const [bmsChgTimeChartMounted, setBmsChgTimeChartMounted] = useState(false);
    const [selectedBmsChgTime, setSelectedBmsChgTime] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBmsChgTime, setSeriesChartDatasBmsChgTime] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBmsChgTimeDisChgCnt, setChartBmsChgTimeDisChgCnt] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_TIME_DISCHG_CNT_NAME,
        data: [],
    });

    const [chartBmsChgTimeChgCnt, setChartBmsChgTimeChgCnt] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_TIME_CHG_CNT_NAME,
        data: [],
    });

    const [chartBmsChgTimeSoh, setChartBmsChgTimeSoh] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_TIME_SOH_NAME,
        data: [],
    });

    const [chartBmsChgTimeQCurr, setChartBmsChgTimeQCurr] = useState<TypeUtils.ChartData>({
        name: Common.BMS_CHG_TIME_QCURR_NAME,
        data: [],
    });

    const getTabelTooltipBmsChgTime = (key: React.Key) => {
        switch (key) {
            case Common.BMS_CHG_TIME_DISCHG_CNT_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Time
                            <br />
                            CAN ID: 0x0C9650F4
                            <br />
                            Name: BmsDischgCnt
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Discharge Count Total - Y=X
                        </p>
                    </>
                );

            case Common.BMS_CHG_TIME_CHG_CNT_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Time
                            <br />
                            CAN ID: 0x0C9650F4
                            <br />
                            Name: BmsChgCnt
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Charge Count Total - Y=X
                        </p>
                    </>
                );

            case Common.BMS_CHG_TIME_SOH_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Time
                            <br />
                            CAN ID: 0x0C9650F4
                            <br />
                            Name: BmsSOH
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery SOH - Y=X*0.1
                        </p>
                    </>
                );

            case Common.BMS_CHG_TIME_QCURR_KEY:
                return (
                    <>
                        <p>
                            BMS_Charge_Time
                            <br />
                            CAN ID: 0x0C9650F4
                            <br />
                            Name: BmsQCurr
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Battery Capacity - Y=X*0.1
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBmsChgTimeDisChgCntValue = () => {
        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_DISCHG_CNT_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bmsChgTime?.disChgCnt);
        }
        return "";
    };

    const getBmsChgTimeChgCntValue = () => {
        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_CHG_CNT_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bmsChgTime?.chgCnt);
        }
        return "";
    };

    const getBmsChgTimeSohValue = () => {
        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_SOH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsChgTime?.soh, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBmsChgTimeQCurrValue = () => {
        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_QCURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsChgTime?.QCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeBmsChgTime = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsChgTime(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsChgTime: Array<TypeUtils.ChartData> = [];

        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_DISCHG_CNT_KEY)) chartDatasBmsChgTime.push(chartBmsChgTimeDisChgCnt);
        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_CHG_CNT_KEY)) chartDatasBmsChgTime.push(chartBmsChgTimeChgCnt);
        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_SOH_KEY)) chartDatasBmsChgTime.push(chartBmsChgTimeSoh);
        if (selectedBmsChgTime.includes(Common.BMS_CHG_TIME_QCURR_KEY)) chartDatasBmsChgTime.push(chartBmsChgTimeQCurr);

        setSeriesChartDatasBmsChgTime(chartDatasBmsChgTime);
    }, [selectedBmsChgTime, chartBmsChgTimeDisChgCnt, chartBmsChgTimeChgCnt, chartBmsChgTimeSoh, chartBmsChgTimeQCurr]);

    const tableDataBmsChgTime: CanTableType[] = [
        {
            key: Common.BMS_CHG_TIME_DISCHG_CNT_KEY,
            name: Common.BMS_CHG_TIME_DISCHG_CNT_NAME,
            value: getBmsChgTimeDisChgCntValue(),
        },
        {
            key: Common.BMS_CHG_TIME_CHG_CNT_KEY,
            name: Common.BMS_CHG_TIME_CHG_CNT_NAME,
            value: getBmsChgTimeChgCntValue(),
        },
        {
            key: Common.BMS_CHG_TIME_SOH_KEY,
            name: Common.BMS_CHG_TIME_SOH_NAME,
            value: getBmsChgTimeSohValue(),
        },
        {
            key: Common.BMS_CHG_TIME_QCURR_KEY,
            name: Common.BMS_CHG_TIME_QCURR_NAME,
            value: getBmsChgTimeQCurrValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region ObcChgInfo
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [obcChgInfoPanelOpen, setObcChgInfoPanelOpen] = useState(true);
    const [obcChgInfoChartMounted, setObcChgInfoChartMounted] = useState(false);
    const [selectedObcChgInfo, setSelectedObcChgInfo] = useState<Array<React.Key>>([]);

    const [seriesChartDatasObcChgInfo, setSeriesChartDatasObcChgInfo] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartObcChgInfoOutVolt, setChartObcChgInfoOutVolt] = useState<TypeUtils.ChartData>({
        name: Common.OBC_CHG_INFO_OUT_VOLT_NAME,
        data: [],
    });
    const [chartObcChgInfoOutCurr, setChartObcChgInfoOutCurr] = useState<TypeUtils.ChartData>({
        name: Common.OBC_CHG_INFO_OUT_CURR_NAME,
        data: [],
    });
    const [chartObcChgInfoStsFailHw, setChartObcChgInfoStsFailHw] = useState<TypeUtils.ChartData>({
        name: Common.OBC_CHG_INFO_STS_FAIL_HW_NAME,
        data: [],
    });
    const [chartObcChgInfoStsOTP, setChartObcChgInfoStsOTP] = useState<TypeUtils.ChartData>({
        name: Common.OBC_CHG_INFO_STS_OTP_NAME,
        data: [],
    });
    const [chartObcChgInfoStsInVolt, setChartObcChgInfoStsInVolt] = useState<TypeUtils.ChartData>({
        name: Common.OBC_CHG_INFO_STS_IN_VOLT_NAME,
        data: [],
    });
    const [chartObcChgInfoStsChkBat, setChartObcChgInfoStsChkBat] = useState<TypeUtils.ChartData>({
        name: Common.OBC_CHG_INFO_STS_CHK_BAT_NAME,
        data: [],
    });
    const [chartObcChgInfoStsComm, setChartObcChgInfoStsComm] = useState<TypeUtils.ChartData>({
        name: Common.OBC_CHG_INFO_STS_COMM_NAME,
        data: [],
    });

    const getTabelTooltipObcChgInfo = (key: React.Key) => {
        switch (key) {
            case Common.OBC_CHG_INFO_OUT_VOLT_KEY:
                return (
                    <>
                        <p>
                            OBC_Charge_Info
                            <br />
                            CAN ID: 0x18FF50E5
                            <br />
                            Name: ObcOutVolt
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC output voltage
                        </p>
                    </>
                );

            case Common.OBC_CHG_INFO_OUT_CURR_KEY:
                return (
                    <>
                        <p>
                            OBC_Charge_Info
                            <br />
                            CAN ID: 0x18FF50E5
                            <br />
                            Name: ObcOutCurr
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC output current
                        </p>
                    </>
                );

            case Common.OBC_CHG_INFO_STS_FAIL_HW_KEY:
                return (
                    <>
                        <p>
                            OBC_Charge_Info
                            <br />
                            CAN ID: 0x18FF50E5
                            <br />
                            Name: ObcStsFailHW
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC Hardware Failure
                            <br />
                            0 : OK
                            <br />1 : HW Fail
                        </p>
                    </>
                );

            case Common.OBC_CHG_INFO_STS_OTP_KEY:
                return (
                    <>
                        <p>
                            OBC_Charge_Info
                            <br />
                            CAN ID: 0x18FF50E5
                            <br />
                            Name: ObcStsOTP
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC Over Temperature Protection
                            <br />
                            0 : OK
                            <br />1 : Temp too High
                        </p>
                    </>
                );

            case Common.OBC_CHG_INFO_STS_IN_VOLT_KEY:
                return (
                    <>
                        <p>
                            OBC_Charge_Info
                            <br />
                            CAN ID: 0x18FF50E5
                            <br />
                            Name: ObcStsInVolt
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC Input Voltage
                            <br />
                            0 : Normal
                            <br />1 : Input voltage error, Charger Stop
                        </p>
                    </>
                );

            case Common.OBC_CHG_INFO_STS_CHK_BAT_KEY:
                return (
                    <>
                        <p>
                            OBC_Charge_Info
                            <br />
                            CAN ID: 0x18FF50E5
                            <br />
                            Name: ObcStsChkBat
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC Battery Connection Check
                            <br />
                            0 : Normal
                            <br />1 : Battery Connection error
                        </p>
                    </>
                );

            case Common.OBC_CHG_INFO_STS_COMM_KEY:
                return (
                    <>
                        <p>
                            OBC_Charge_Info
                            <br />
                            CAN ID: 0x18FF50E5
                            <br />
                            Name: ObcStsComm
                        </p>
                        <p>
                            Remarks:
                            <br />
                            OBC CAN Communication status
                            <br />
                            0 : OK
                            <br />1 : Communication reception timed out
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertStsFailHwRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "OK";
                break;
            case 1:
                valueString = "HW Fail";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertStsOTPRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "OK";
                break;
            case 1:
                valueString = "Temp too High";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertStsInVoltRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "Input voltage error, Charger Stop";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertStsChkBatRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "Battery Connection error";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertStsCommRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "OK";
                break;
            case 1:
                valueString = "Communication reception timed out";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getObcChgInfoOutVoltValue = () => {
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_OUT_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.obcChgInfo?.outVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getObcChgInfoOutCurrValue = () => {
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_OUT_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.obcChgInfo?.outCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getObcChgInfoStsFailHwValue = () => {
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_FAIL_HW_KEY) && currentCanData !== undefined) {
            return convertStsFailHwRemark(currentCanData.obcChgInfo?.stsFailHw);
        }
        return "";
    };

    const getObcChgInfoStsOTPValue = () => {
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_OTP_KEY) && currentCanData !== undefined) {
            return convertStsOTPRemark(currentCanData.obcChgInfo?.stsOTP);
        }
        return "";
    };

    const getObcChgInfoStsInVoltValue = () => {
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_IN_VOLT_KEY) && currentCanData !== undefined) {
            return convertStsInVoltRemark(currentCanData.obcChgInfo?.stsInVolt);
        }
        return "";
    };

    const getObcChgInfoStsChkBatValue = () => {
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_CHK_BAT_KEY) && currentCanData !== undefined) {
            return convertStsChkBatRemark(currentCanData.obcChgInfo?.stsChkBat);
        }
        return "";
    };

    const getObcChgInfoStsCommValue = () => {
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_COMM_KEY) && currentCanData !== undefined) {
            return convertStsCommRemark(currentCanData.obcChgInfo?.stsComm);
        }
        return "";
    };

    const onChangeObcChgInfo = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedObcChgInfo(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasObcChgInfo: Array<TypeUtils.ChartData> = [];

        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_OUT_VOLT_KEY)) chartDatasObcChgInfo.push(chartObcChgInfoOutVolt);
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_OUT_CURR_KEY)) chartDatasObcChgInfo.push(chartObcChgInfoOutCurr);
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_FAIL_HW_KEY)) chartDatasObcChgInfo.push(chartObcChgInfoStsFailHw);
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_OTP_KEY)) chartDatasObcChgInfo.push(chartObcChgInfoStsOTP);
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_IN_VOLT_KEY)) chartDatasObcChgInfo.push(chartObcChgInfoStsInVolt);
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_CHK_BAT_KEY)) chartDatasObcChgInfo.push(chartObcChgInfoStsChkBat);
        if (selectedObcChgInfo.includes(Common.OBC_CHG_INFO_STS_COMM_KEY)) chartDatasObcChgInfo.push(chartObcChgInfoStsComm);

        setSeriesChartDatasObcChgInfo(chartDatasObcChgInfo);
    }, [
        selectedObcChgInfo,
        chartObcChgInfoOutVolt,
        chartObcChgInfoOutCurr,
        chartObcChgInfoStsFailHw,
        chartObcChgInfoStsOTP,
        chartObcChgInfoStsInVolt,
        chartObcChgInfoStsChkBat,
        chartObcChgInfoStsComm,
    ]);

    const tableDataObcChgInfo: CanTableType[] = [
        {
            key: Common.OBC_CHG_INFO_OUT_VOLT_KEY,
            name: Common.OBC_CHG_INFO_OUT_VOLT_NAME,
            value: getObcChgInfoOutVoltValue(),
        },
        {
            key: Common.OBC_CHG_INFO_OUT_CURR_KEY,
            name: Common.OBC_CHG_INFO_OUT_CURR_NAME,
            value: getObcChgInfoOutCurrValue(),
        },
        {
            key: Common.OBC_CHG_INFO_STS_FAIL_HW_KEY,
            name: Common.OBC_CHG_INFO_STS_FAIL_HW_NAME,
            value: getObcChgInfoStsFailHwValue(),
        },
        {
            key: Common.OBC_CHG_INFO_STS_OTP_KEY,
            name: Common.OBC_CHG_INFO_STS_OTP_NAME,
            value: getObcChgInfoStsOTPValue(),
        },
        {
            key: Common.OBC_CHG_INFO_STS_IN_VOLT_KEY,
            name: Common.OBC_CHG_INFO_STS_IN_VOLT_NAME,
            value: getObcChgInfoStsInVoltValue(),
        },
        {
            key: Common.OBC_CHG_INFO_STS_CHK_BAT_KEY,
            name: Common.OBC_CHG_INFO_STS_CHK_BAT_NAME,
            value: getObcChgInfoStsChkBatValue(),
        },
        {
            key: Common.OBC_CHG_INFO_STS_COMM_KEY,
            name: Common.OBC_CHG_INFO_STS_COMM_NAME,
            value: getObcChgInfoStsCommValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Mot1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [mot1PanelOpen, setMot1PanelOpen] = useState(true);
    const [mot1ChartMounted, setMot1ChartMounted] = useState(false);
    const [selectedMot1, setSelectedMot1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasMot1, setSeriesChartDatasMot1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartMot1Rpm, setChartMot1Rpm] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_RPM_NAME,
        data: [],
    });
    const [chartMot1Curr, setChartMot1Curr] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_CURR_NAME,
        data: [],
    });
    const [chartMot1Volt, setChartMot1Volt] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_VOLT_NAME,
        data: [],
    });
    const [chartMot1Err0, setChartMot1Err0] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR0_NAME,
        data: [],
    });
    const [chartMot1Err1, setChartMot1Err1] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR1_NAME,
        data: [],
    });
    const [chartMot1Err2, setChartMot1Err2] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR2_NAME,
        data: [],
    });
    const [chartMot1Err4, setChartMot1Err4] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR4_NAME,
        data: [],
    });
    const [chartMot1Err5, setChartMot1Err5] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR5_NAME,
        data: [],
    });
    const [chartMot1Err6, setChartMot1Err6] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR6_NAME,
        data: [],
    });
    const [chartMot1Err7, setChartMot1Err7] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR7_NAME,
        data: [],
    });
    const [chartMot1Err9, setChartMot1Err9] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR9_NAME,
        data: [],
    });
    const [chartMot1Err10, setChartMot1Err10] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR10_NAME,
        data: [],
    });
    const [chartMot1Err11, setChartMot1Err11] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR11_NAME,
        data: [],
    });
    const [chartMot1Err14, setChartMot1Err14] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR14_NAME,
        data: [],
    });
    const [chartMot1Err15, setChartMot1Err15] = useState<TypeUtils.ChartData>({
        name: Common.MOT1_ERR15_NAME,
        data: [],
    });

    const getTabelTooltipMot1 = (key: React.Key) => {
        switch (key) {
            case Common.MOT1_RPM_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotRPM
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Motor RPM &#40;Byte 0 : LSB, Byte 1 : MSB&#41;
                            <br />
                            Actual speed&#40;RPM&#41; = &#40;&#40;MSB*256&#41;+LSB&#41;, 1rpm/bit
                        </p>
                    </>
                );

            case Common.MOT1_CURR_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotCurr
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Motor current &#40;Byte 0 : LSB, Byte 1 : MSB&#41;
                            <br />
                            Actual speed&#40;RPM&#41; = &#40;&#40;MSB*256&#41;+LSB&#41;/10, 0.1A/bit
                        </p>
                    </>
                );

            case Common.MOT1_VOLT_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotVolt
                        </p>
                        <p>
                            Remarks:
                            <br />
                            battery voltage for Motor &#40;Byte 0 : LSB, Byte 1 : MSB&#41;
                            <br />
                            Actual speed&#40;RPM&#41; = &#40;&#40;MSB*256&#41;+LSB&#41;/10, 0.1V/bit
                        </p>
                    </>
                );

            case Common.MOT1_ERR0_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr00
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Identification error
                        </p>
                    </>
                );

            case Common.MOT1_ERR1_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr01
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Over voltage
                        </p>
                    </>
                );

            case Common.MOT1_ERR2_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr02
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Low voltage
                        </p>
                    </>
                );

            case Common.MOT1_ERR4_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr04
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Stall
                        </p>
                    </>
                );

            case Common.MOT1_ERR5_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr05
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Internal volts fault
                        </p>
                    </>
                );

            case Common.MOT1_ERR6_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr06
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Over temperature
                        </p>
                    </>
                );

            case Common.MOT1_ERR7_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr07
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Throttle error at power-up
                        </p>
                    </>
                );

            case Common.MOT1_ERR9_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr09
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Internal reset
                        </p>
                    </>
                );

            case Common.MOT1_ERR10_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr10
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Hall throttle is open or short-circuit
                        </p>
                    </>
                );

            case Common.MOT1_ERR11_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr11
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Angle sensor error
                        </p>
                    </>
                );

            case Common.MOT1_ERR14_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr14
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Motor over-temperature
                        </p>
                    </>
                );

            case Common.MOT1_ERR15_KEY:
                return (
                    <>
                        <p>
                            MOT1
                            <br />
                            CAN ID: 0x0CF11E05
                            <br />
                            Name: MotErr15
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Hall Galvanometer sensor error
                        </p>
                    </>
                );
        }

        return null;
    };

    const getMot1RpmValue = () => {
        if (selectedMot1.includes(Common.MOT1_RPM_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mot1?.rpm, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getMot1CurrValue = () => {
        if (selectedMot1.includes(Common.MOT1_CURR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mot1?.curr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getMot1VoltValue = () => {
        if (selectedMot1.includes(Common.MOT1_VOLT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mot1?.volt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getMot1Err0Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR0_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err0);
        }
        return "";
    };

    const getMot1Err1Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR1_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err1);
        }
        return "";
    };

    const getMot1Err2Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR2_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err2);
        }
        return "";
    };

    const getMot1Err4Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR4_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err4);
        }
        return "";
    };

    const getMot1Err5Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR5_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err5);
        }
        return "";
    };

    const getMot1Err6Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR6_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err6);
        }
        return "";
    };

    const getMot1Err7Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR7_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err7);
        }
        return "";
    };

    const getMot1Err9Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR9_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err9);
        }
        return "";
    };

    const getMot1Err10Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR10_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err10);
        }
        return "";
    };

    const getMot1Err11Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR11_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err11);
        }
        return "";
    };

    const getMot1Err14Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR14_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err14);
        }
        return "";
    };

    const getMot1Err15Value = () => {
        if (selectedMot1.includes(Common.MOT1_ERR15_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot1?.err15);
        }
        return "";
    };

    const onChangeMot1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedMot1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasMot1: Array<TypeUtils.ChartData> = [];

        if (selectedMot1.includes(Common.MOT1_RPM_KEY)) chartDatasMot1.push(chartMot1Rpm);
        if (selectedMot1.includes(Common.MOT1_CURR_KEY)) chartDatasMot1.push(chartMot1Curr);
        if (selectedMot1.includes(Common.MOT1_VOLT_KEY)) chartDatasMot1.push(chartMot1Volt);
        if (selectedMot1.includes(Common.MOT1_ERR0_KEY)) chartDatasMot1.push(chartMot1Err0);
        if (selectedMot1.includes(Common.MOT1_ERR1_KEY)) chartDatasMot1.push(chartMot1Err1);
        if (selectedMot1.includes(Common.MOT1_ERR2_KEY)) chartDatasMot1.push(chartMot1Err2);
        if (selectedMot1.includes(Common.MOT1_ERR4_KEY)) chartDatasMot1.push(chartMot1Err4);
        if (selectedMot1.includes(Common.MOT1_ERR5_KEY)) chartDatasMot1.push(chartMot1Err5);
        if (selectedMot1.includes(Common.MOT1_ERR6_KEY)) chartDatasMot1.push(chartMot1Err6);
        if (selectedMot1.includes(Common.MOT1_ERR7_KEY)) chartDatasMot1.push(chartMot1Err7);
        if (selectedMot1.includes(Common.MOT1_ERR9_KEY)) chartDatasMot1.push(chartMot1Err9);
        if (selectedMot1.includes(Common.MOT1_ERR10_KEY)) chartDatasMot1.push(chartMot1Err10);
        if (selectedMot1.includes(Common.MOT1_ERR11_KEY)) chartDatasMot1.push(chartMot1Err11);
        if (selectedMot1.includes(Common.MOT1_ERR14_KEY)) chartDatasMot1.push(chartMot1Err14);
        if (selectedMot1.includes(Common.MOT1_ERR15_KEY)) chartDatasMot1.push(chartMot1Err15);

        setSeriesChartDatasMot1(chartDatasMot1);
    }, [
        selectedMot1,
        chartMot1Rpm,
        chartMot1Curr,
        chartMot1Volt,
        chartMot1Err0,
        chartMot1Err1,
        chartMot1Err2,
        chartMot1Err4,
        chartMot1Err5,
        chartMot1Err6,
        chartMot1Err7,
        chartMot1Err9,
        chartMot1Err10,
        chartMot1Err11,
        chartMot1Err14,
        chartMot1Err15,
    ]);

    const tableDataMot1: CanTableType[] = [
        {
            key: Common.MOT1_RPM_KEY,
            name: Common.MOT1_RPM_NAME,
            value: getMot1RpmValue(),
        },
        {
            key: Common.MOT1_CURR_KEY,
            name: Common.MOT1_CURR_NAME,
            value: getMot1CurrValue(),
        },
        {
            key: Common.MOT1_VOLT_KEY,
            name: Common.MOT1_VOLT_NAME,
            value: getMot1RpmValue(),
        },
        {
            key: Common.MOT1_ERR0_KEY,
            name: Common.MOT1_ERR0_NAME,
            value: getMot1Err0Value(),
        },
        {
            key: Common.MOT1_ERR1_KEY,
            name: Common.MOT1_ERR1_NAME,
            value: getMot1Err1Value(),
        },
        {
            key: Common.MOT1_ERR2_KEY,
            name: Common.MOT1_ERR2_NAME,
            value: getMot1Err2Value(),
        },
        {
            key: Common.MOT1_ERR4_KEY,
            name: Common.MOT1_ERR4_NAME,
            value: getMot1Err4Value(),
        },
        {
            key: Common.MOT1_ERR5_KEY,
            name: Common.MOT1_ERR5_NAME,
            value: getMot1Err5Value(),
        },
        {
            key: Common.MOT1_ERR6_KEY,
            name: Common.MOT1_ERR6_NAME,
            value: getMot1Err6Value(),
        },
        {
            key: Common.MOT1_ERR7_KEY,
            name: Common.MOT1_ERR7_NAME,
            value: getMot1Err7Value(),
        },
        {
            key: Common.MOT1_ERR9_KEY,
            name: Common.MOT1_ERR9_NAME,
            value: getMot1Err9Value(),
        },
        {
            key: Common.MOT1_ERR10_KEY,
            name: Common.MOT1_ERR10_NAME,
            value: getMot1Err10Value(),
        },
        {
            key: Common.MOT1_ERR11_KEY,
            name: Common.MOT1_ERR11_NAME,
            value: getMot1Err11Value(),
        },
        {
            key: Common.MOT1_ERR14_KEY,
            name: Common.MOT1_ERR14_NAME,
            value: getMot1Err14Value(),
        },
        {
            key: Common.MOT1_ERR15_KEY,
            name: Common.MOT1_ERR15_NAME,
            value: getMot1Err15Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Mot2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [mot2PanelOpen, setMot2PanelOpen] = useState(true);
    const [mot2ChartMounted, setMot2ChartMounted] = useState(false);
    const [selectedMot2, setSelectedMot2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasMot2, setSeriesChartDatasMot2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartMot2Throttle, setChartMot2Throttle] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_THROTTLE_NAME,
        data: [],
    });

    const [chartMot2InverterTemp, setChartMot2InverterTemp] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_INVERTER_TEMP_NAME,
        data: [],
    });

    const [chartMot2MotorTemp, setChartMot2MotorTemp] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_MOTOR_TEMP_NAME,
        data: [],
    });

    const [chartMot2StsDnr, setChartMot2StsDnr] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_STS_DNR_NAME,
        data: [],
    });

    const [chartMot2StsDnrFb, setChartMot2StsDnrFb] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_STS_DNR_FB_NAME,
        data: [],
    });

    const [chartMot2DiHallA, setChartMot2DiHallA] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_DI_HALL_A_NAME,
        data: [],
    });

    const [chartMot2DiHallB, setChartMot2DiHallB] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_DI_HALL_B_NAME,
        data: [],
    });

    const [chartMot2DiHallC, setChartMot2DiHallC] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_DI_HALL_C_NAME,
        data: [],
    });

    const [chartMot2BrakeSw, setChartMot2BrakeSw] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_BRAKE_SW_NAME,
        data: [],
    });

    const [chartMot2Backward, setChartMot2Backward] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_BACKWARD_NAME,
        data: [],
    });

    const [chartMot2Forward, setChartMot2Forward] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_FORWARD_NAME,
        data: [],
    });

    const [chartMot2FootSw, setChartMot2FootSw] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_FOOT_SW_NAME,
        data: [],
    });

    const [chartMot2BoostSw, setChartMot2BoostSw] = useState<TypeUtils.ChartData>({
        name: Common.MOT2_BOOST_SW_NAME,
        data: [],
    });

    const getTabelTooltipMot2 = (key: React.Key) => {
        switch (key) {
            case Common.MOT2_THROTTLE_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotThrottle
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.MOT2_INVERTER_TEMP_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotInverterTemp
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );

            case Common.MOT2_MOTOR_TEMP_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotMotorTemp
                        </p>
                        <p>Remarks: °C</p>
                    </>
                );

            case Common.MOT2_STS_DNR_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotSts_DNR
                        </p>
                    </>
                );

            case Common.MOT2_STS_DNR_FB_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotSts_DNR_FB
                        </p>
                    </>
                );

            case Common.MOT2_DI_HALL_A_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_HallA
                        </p>
                    </>
                );

            case Common.MOT2_DI_HALL_B_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_HallB
                        </p>
                    </>
                );

            case Common.MOT2_DI_HALL_C_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_HallC
                        </p>
                    </>
                );

            case Common.MOT2_BRAKE_SW_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_BrakeSW
                        </p>
                    </>
                );

            case Common.MOT2_BACKWARD_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_Backward
                        </p>
                    </>
                );

            case Common.MOT2_FORWARD_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_Forward
                        </p>
                    </>
                );

            case Common.MOT2_FOOT_SW_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_FootSW
                        </p>
                    </>
                );

            case Common.MOT2_BOOST_SW_KEY:
                return (
                    <>
                        <p>
                            MOT2
                            <br />
                            CAN ID: 0x0CF11F05
                            <br />
                            Name: MotDI_BoostSW
                        </p>
                    </>
                );
        }

        return null;
    };

    const getMot2ThrottleValue = () => {
        if (selectedMot2.includes(Common.MOT2_THROTTLE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mot2?.throttle, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getMot2InverterTempValue = () => {
        if (selectedMot2.includes(Common.MOT2_INVERTER_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mot2?.inverterTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getMot2MotorTempValue = () => {
        if (selectedMot2.includes(Common.MOT2_MOTOR_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mot2?.motorTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getMot2StsDnrValue = () => {
        if (selectedMot2.includes(Common.MOT2_STS_DNR_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.stsDnr);
        }
        return "";
    };

    const getMot2StsDnrFbValue = () => {
        if (selectedMot2.includes(Common.MOT2_STS_DNR_FB_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.stsDnrFb);
        }
        return "";
    };

    const getMot2DiHallAValue = () => {
        if (selectedMot2.includes(Common.MOT2_DI_HALL_A_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.diHallA);
        }
        return "";
    };

    const getMot2DiHallBValue = () => {
        if (selectedMot2.includes(Common.MOT2_DI_HALL_B_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.diHallB);
        }
        return "";
    };

    const getMot2DiHallCValue = () => {
        if (selectedMot2.includes(Common.MOT2_DI_HALL_C_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.diHallC);
        }
        return "";
    };

    const getMot2BrakeSwValue = () => {
        if (selectedMot2.includes(Common.MOT2_BRAKE_SW_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.brakeSw);
        }
        return "";
    };

    const getMot2BackwardValue = () => {
        if (selectedMot2.includes(Common.MOT2_BACKWARD_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.backward);
        }
        return "";
    };

    const getMot2ForwardValue = () => {
        if (selectedMot2.includes(Common.MOT2_FORWARD_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.forward);
        }
        return "";
    };

    const getMot2FootSwValue = () => {
        if (selectedMot2.includes(Common.MOT2_FOOT_SW_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.footSw);
        }
        return "";
    };

    const getMot2BoostSwValue = () => {
        if (selectedMot2.includes(Common.MOT2_BOOST_SW_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mot2?.boostSw);
        }
        return "";
    };

    const onChangeMot2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedMot2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasMot2: Array<TypeUtils.ChartData> = [];

        if (selectedMot2.includes(Common.MOT2_THROTTLE_KEY)) chartDatasMot2.push(chartMot2Throttle);
        if (selectedMot2.includes(Common.MOT2_INVERTER_TEMP_KEY)) chartDatasMot2.push(chartMot2InverterTemp);
        if (selectedMot2.includes(Common.MOT2_MOTOR_TEMP_KEY)) chartDatasMot2.push(chartMot2MotorTemp);
        if (selectedMot2.includes(Common.MOT2_STS_DNR_KEY)) chartDatasMot2.push(chartMot2StsDnr);
        if (selectedMot2.includes(Common.MOT2_STS_DNR_FB_KEY)) chartDatasMot2.push(chartMot2StsDnrFb);
        if (selectedMot2.includes(Common.MOT2_DI_HALL_A_KEY)) chartDatasMot2.push(chartMot2DiHallA);
        if (selectedMot2.includes(Common.MOT2_DI_HALL_B_KEY)) chartDatasMot2.push(chartMot2DiHallB);
        if (selectedMot2.includes(Common.MOT2_DI_HALL_C_KEY)) chartDatasMot2.push(chartMot2DiHallC);
        if (selectedMot2.includes(Common.MOT2_BRAKE_SW_KEY)) chartDatasMot2.push(chartMot2BrakeSw);
        if (selectedMot2.includes(Common.MOT2_BACKWARD_KEY)) chartDatasMot2.push(chartMot2Backward);
        if (selectedMot2.includes(Common.MOT2_FORWARD_KEY)) chartDatasMot2.push(chartMot2Forward);
        if (selectedMot2.includes(Common.MOT2_FOOT_SW_KEY)) chartDatasMot2.push(chartMot2FootSw);
        if (selectedMot2.includes(Common.MOT2_BOOST_SW_KEY)) chartDatasMot2.push(chartMot2BoostSw);

        setSeriesChartDatasMot2(chartDatasMot2);
    }, [
        selectedMot2,
        chartMot2Throttle,
        chartMot2InverterTemp,
        chartMot2MotorTemp,
        chartMot2StsDnr,
        chartMot2StsDnrFb,
        chartMot2DiHallA,
        chartMot2DiHallA,
        chartMot2DiHallC,
        chartMot2BrakeSw,
        chartMot2Backward,
        chartMot2Forward,
        chartMot2FootSw,
        chartMot2BoostSw,
    ]);

    const tableDataMot2: CanTableType[] = [
        {
            key: Common.MOT2_THROTTLE_KEY,
            name: Common.MOT2_THROTTLE_NAME,
            value: getMot2ThrottleValue(),
        },
        {
            key: Common.MOT2_INVERTER_TEMP_KEY,
            name: Common.MOT2_INVERTER_TEMP_NAME,
            value: getMot2InverterTempValue(),
        },
        {
            key: Common.MOT2_MOTOR_TEMP_KEY,
            name: Common.MOT2_MOTOR_TEMP_NAME,
            value: getMot2MotorTempValue(),
        },
        {
            key: Common.MOT2_STS_DNR_KEY,
            name: Common.MOT2_STS_DNR_NAME,
            value: getMot2StsDnrValue(),
        },
        {
            key: Common.MOT2_STS_DNR_FB_KEY,
            name: Common.MOT2_STS_DNR_FB_NAME,
            value: getMot2StsDnrFbValue(),
        },
        {
            key: Common.MOT2_DI_HALL_A_KEY,
            name: Common.MOT2_DI_HALL_A_NAME,
            value: getMot2DiHallAValue(),
        },
        {
            key: Common.MOT2_DI_HALL_B_KEY,
            name: Common.MOT2_DI_HALL_B_NAME,
            value: getMot2DiHallBValue(),
        },
        {
            key: Common.MOT2_DI_HALL_C_KEY,
            name: Common.MOT2_DI_HALL_C_NAME,
            value: getMot2DiHallCValue(),
        },
        {
            key: Common.MOT2_BRAKE_SW_KEY,
            name: Common.MOT2_BRAKE_SW_NAME,
            value: getMot2BrakeSwValue(),
        },
        {
            key: Common.MOT2_BACKWARD_KEY,
            name: Common.MOT2_BACKWARD_NAME,
            value: getMot2BackwardValue(),
        },
        {
            key: Common.MOT2_FORWARD_KEY,
            name: Common.MOT2_FORWARD_NAME,
            value: getMot2ForwardValue(),
        },
        {
            key: Common.MOT2_FOOT_SW_KEY,
            name: Common.MOT2_FOOT_SW_NAME,
            value: getMot2FootSwValue(),
        },
        {
            key: Common.MOT2_BOOST_SW_KEY,
            name: Common.MOT2_BOOST_SW_NAME,
            value: getMot2BoostSwValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region vcuVeh1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuVeh1PanelOpen, setVcuVeh1PanelOpen] = useState(true);
    const [vcuVeh1ChartMounted, setVcuVeh1ChartMounted] = useState(false);
    const [selectedVcuVeh1, setSelectedVcuVeh1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuVeh1, setSeriesChartDatasVcuVeh1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuVeh1Soc, setChartVcuVeh1Soc] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_SOC_NAME,
        data: [],
    });

    const [chartVcuVeh1StateSys, setChartVcuVeh1StateSys] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_STATE_SYS_NAME,
        data: [],
    });

    const [chartVcuVeh1Speed, setChartVcuVeh1Speed] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_SPEED_NAME,
        data: [],
    });

    const [chartVcuVeh1StateChg, setChartVcuVeh1StateChg] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_STATE_CHARGE_NAME,
        data: [],
    });

    const [chartVcuVeh1StateFnr, setChartVcuVeh1StateFnr] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_STATE_FNR_NAME,
        data: [],
    });

    const [chartVcuVeh1CluDispOn, setChartVcuVeh1CluDispOn] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_CLU_DISPLAY_NAME,
        data: [],
    });

    const [chartVcuVeh1CluHighBeam, setChartVcuVeh1CluHighBeam] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_CLU_HIGH_BEAM_NAME,
        data: [],
    });

    const [chartVcuVeh1CluLowBeam, setChartVcuVeh1CluLowBeam] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_CLU_LOW_BEAM_NAME,
        data: [],
    });

    const [chartVcuVeh1CluLTurn, setChartVcuVeh1CluLTurn] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_CLU_LEFT_TURN_NAME,
        data: [],
    });

    const [chartVcuVeh1CluRTurn, setChartVcuVeh1CluRTurn] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_CLU_RIGHT_TURN_NAME,
        data: [],
    });

    const [chartVcuVeh1KeyFobCmd, setChartVcuVeh1KeyFobCmd] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_KEY_FOB_CMD_NAME,
        data: [],
    });

    const [chartVcuVeh1Regen, setChartVcuVeh1Regen] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_REGEN_NAME,
        data: [],
    });

    const [chartVcuVeh1SideBrake, setChartVcuVeh1SideBrake] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_SIDE_BRAKE_NAME,
        data: [],
    });

    const [chartVcuVeh1AuxBatLowWarn, setChartVcuVeh1AuxBatLowWarn] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_AUX_BATTERY_WARNING_NAME,
        data: [],
    });

    const [chartVcuVeh1DerMod, setChartVcuVeh1DerMod] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_DERATING_MODE_NAME,
        data: [],
    });

    const [chartVcuVeh1LowSohAlarm, setChartVcuVeh1LowSohAlarm] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_LOW_SOH_ALARM_NAME,
        data: [],
    });

    const [chartVcuVeh1OverTempWarn, setChartVcuVeh1OverTempWarn] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_OVER_TEMP_WARNING_NAME,
        data: [],
    });

    const [chartVcuVeh1RepairLampOn, setChartVcuVeh1RepairLampOn] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_REPAIR_LAMP_NAME,
        data: [],
    });

    const [chartVcuVeh1UdrTempProtect, setChartVcuVeh1UdrTempProtect] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_UNDER_TEMP_PROTECT_NAME,
        data: [],
    });

    const [chartVcuVeh1WarningLampOn, setChartVcuVeh1WarningLampOn] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_WARNING_LAMP_NAME,
        data: [],
    });

    const [chartVcuVeh1ChgGunStatus, setChartVcuVeh1ChgGunStatus] = useState<TypeUtils.ChartData>({
        name: Common.VEH1_CHARGING_GUN_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipVcuVeh1 = (key: React.Key) => {
        switch (key) {
            case Common.VEH1_SOC_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_SOC
                        </p>
                        <p>Remarks: %</p>
                    </>
                );

            case Common.VEH1_STATE_SYS_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_StateSYS
                        </p>
                        <p>
                            Remarks:
                            <br />
                            주행가능상태 알림
                            <br />0 : Not ready
                            <br />1 : No brake start-up
                            <br />2 : Ready
                            <br />3 : Charge
                        </p>
                    </>
                );

            case Common.VEH1_SPEED_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_VehSpd
                        </p>
                        <p>Remarks: km/h</p>
                    </>
                );

            case Common.VEH1_STATE_CHARGE_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_StateChrg
                        </p>
                        <p>
                            Remarks:
                            <br />
                            충전 여부
                            <br />0 : No charge
                            <br />1 : Charge Start & Charing
                            <br />2 : Charge Complete
                            <br />3 : Charge Error
                        </p>
                    </>
                );

            case Common.VEH1_STATE_FNR_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_StateFNR
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Gear FNR status
                            <br />0 : Neutral
                            <br />1 : Forward
                            <br />2 : Reverse(Backward)
                            <br />3 : Error
                        </p>
                    </>
                );

            case Common.VEH1_CLU_DISPLAY_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_CluDispOn
                        </p>
                        <p>Remarks: CLU Display ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_CLU_HIGH_BEAM_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_CluHighBeam
                        </p>
                        <p>Remarks: high beam lamp on CLU ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_CLU_LOW_BEAM_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_CluLowBeam
                        </p>
                        <p>Remarks: low beam lamp on CLU ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_CLU_LEFT_TURN_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_CluLTurn
                        </p>
                        <p>Remarks: left turn signal lamp on CLU ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_CLU_RIGHT_TURN_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_CluRTurn
                        </p>
                        <p>Remarks: right turn signal lamp on CLU ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_KEY_FOB_CMD_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_KeyFobCmd
                        </p>
                        <p>Remarks: status of Key FOB command</p>
                    </>
                );

            case Common.VEH1_REGEN_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_Regen
                        </p>
                        <p>Remarks: Regeneration lamp ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_SIDE_BRAKE_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_SideBrake
                        </p>
                        <p>Remarks: Side Brake lamp ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_AUX_BATTERY_WARNING_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_AuxBattLowWarn
                        </p>
                        <p>Remarks: Aux. Battery low warning</p>
                    </>
                );

            case Common.VEH1_DERATING_MODE_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_DerMod
                        </p>
                        <p>Remarks: Derating mode lamp ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_LOW_SOH_ALARM_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_lowSOHAlarmBMS
                        </p>
                        <p>Remarks: low SOH Alarm</p>
                    </>
                );

            case Common.VEH1_OVER_TEMP_WARNING_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_OvrTempWarn
                        </p>
                        <p>Remarks: system over temperature warning bit</p>
                    </>
                );

            case Common.VEH1_REPAIR_LAMP_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_RepairLampON
                        </p>
                        <p>Remarks: Repair Lamp ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_UNDER_TEMP_PROTECT_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_UdrTempProtect
                        </p>
                        <p>Remarks: system under temperature protection alarm bit</p>
                    </>
                );

            case Common.VEH1_WARNING_LAMP_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_WarningLampON
                        </p>
                        <p>Remarks: Error warning lamp ON/OFF bit</p>
                    </>
                );

            case Common.VEH1_CHARGING_GUN_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH1
                            <br />
                            CAN ID: 0x00000331
                            <br />
                            Name: VCU_Chg_Gun_Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            충전선 연결 여부
                            <br />
                            0: Disconnected
                            <br />
                            1: Connected
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertStateSysRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Not Ready";
                break;
            case 1:
                valueString = "No brake start-up";
                break;
            case 2:
                valueString = "Ready";
                break;
            case 3:
                valueString = "Charge";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertStateChgRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "No charge";
                break;
            case 1:
                valueString = "Charge Start & Charing";
                break;
            case 2:
                valueString = "Charge Complete";
                break;
            case 3:
                valueString = "Charge Error";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertStateFnrRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Neutral";
                break;
            case 1:
                valueString = "Forward";
                break;
            case 2:
                valueString = "Reverse(Backward)";
                break;
            case 3:
                valueString = "Error";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertOnOffRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Off";
                break;
            case 1:
                valueString = "On";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertChgGunStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Disconnected";
                break;
            case 1:
                valueString = "Connected";
                break;
            default:
                break;
        }
        return valueString;
    };

    const getVcuVeh1SocValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuVeh1?.soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getVcuVeh1StateSysValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_STATE_SYS_KEY) && currentCanData !== undefined) {
            return convertStateSysRemark(currentCanData.vcuVeh1?.stateSys);
        }
        return "";
    };

    const getVcuVeh1SpeedValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuVeh1?.speed, Common.UNIT_SPEED);
        }
        return "";
    };

    const getVcuVeh1StateChgValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_STATE_CHARGE_KEY) && currentCanData !== undefined) {
            return convertStateChgRemark(currentCanData.vcuVeh1?.stateChg);
        }
        return "";
    };

    const getVcuVeh1StateFnrValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_STATE_FNR_KEY) && currentCanData !== undefined) {
            return convertStateFnrRemark(currentCanData.vcuVeh1?.stateFnr);
        }
        return "";
    };

    const getVcuVeh1CluDispOnValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_DISPLAY_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.cluDispOn);
        }
        return "";
    };

    const getVcuVeh1CluHighBeamValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_HIGH_BEAM_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.cluHighBeam);
        }
        return "";
    };

    const getVcuVeh1CluLowBeamValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_LOW_BEAM_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.cluLowBeam);
        }
        return "";
    };

    const getVcuVeh1CluLTurnValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_LEFT_TURN_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.cluLTurn);
        }
        return "";
    };

    const getVcuVeh1CluRTurnValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_RIGHT_TURN_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.cluRTurn);
        }
        return "";
    };

    const getVcuVeh1KeyFobCmdValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_KEY_FOB_CMD_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.keyFobCmd);
        }
        return "";
    };

    const getVcuVeh1RegenValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_REGEN_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.regen);
        }
        return "";
    };

    const getVcuVeh1SideBrakeValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_SIDE_BRAKE_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.sideBrake);
        }
        return "";
    };

    const getVcuVeh1AuxBatLowWarnValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_AUX_BATTERY_WARNING_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.auxBatLowWarn);
        }
        return "";
    };

    const getVcuVeh1DerModValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_DERATING_MODE_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.derMod);
        }
        return "";
    };

    const getVcuVeh1LowSohAlarmValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_LOW_SOH_ALARM_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.lowSohAlarm);
        }
        return "";
    };

    const getVcuVeh1OverTempWarnValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_OVER_TEMP_WARNING_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.overTempWarn);
        }
        return "";
    };

    const getVcuVeh1RepairLampOnValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_REPAIR_LAMP_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.repairLampOn);
        }
        return "";
    };

    const getVcuVeh1UdrTempProtectValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_UNDER_TEMP_PROTECT_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.udrTempProtect);
        }
        return "";
    };

    const getVcuVeh1WarningLampOnValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_WARNING_LAMP_KEY) && currentCanData !== undefined) {
            return convertOnOffRemark(currentCanData.vcuVeh1?.warningLampOn);
        }
        return "";
    };

    const getVcuVeh1ChgGunStatusValue = () => {
        if (selectedVcuVeh1.includes(Common.VEH1_CHARGING_GUN_STATUS_KEY) && currentCanData !== undefined) {
            return convertChgGunStatusRemark(currentCanData.vcuVeh1?.chgGunStatus);
        }
        return "";
    };

    const onChangeVcuVeh1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuVeh1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuVeh1: Array<TypeUtils.ChartData> = [];

        if (selectedVcuVeh1.includes(Common.VEH1_SOC_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1Soc);
        if (selectedVcuVeh1.includes(Common.VEH1_STATE_SYS_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1StateSys);
        if (selectedVcuVeh1.includes(Common.VEH1_SPEED_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1Speed);
        if (selectedVcuVeh1.includes(Common.VEH1_STATE_CHARGE_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1StateChg);
        if (selectedVcuVeh1.includes(Common.VEH1_STATE_FNR_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1StateFnr);
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_DISPLAY_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1CluDispOn);
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_HIGH_BEAM_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1CluHighBeam);
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_LOW_BEAM_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1CluLowBeam);
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_LEFT_TURN_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1CluLTurn);
        if (selectedVcuVeh1.includes(Common.VEH1_CLU_RIGHT_TURN_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1CluRTurn);
        if (selectedVcuVeh1.includes(Common.VEH1_KEY_FOB_CMD_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1KeyFobCmd);
        if (selectedVcuVeh1.includes(Common.VEH1_REGEN_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1Regen);
        if (selectedVcuVeh1.includes(Common.VEH1_SIDE_BRAKE_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1SideBrake);
        if (selectedVcuVeh1.includes(Common.VEH1_AUX_BATTERY_WARNING_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1AuxBatLowWarn);
        if (selectedVcuVeh1.includes(Common.VEH1_DERATING_MODE_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1DerMod);
        if (selectedVcuVeh1.includes(Common.VEH1_LOW_SOH_ALARM_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1LowSohAlarm);
        if (selectedVcuVeh1.includes(Common.VEH1_OVER_TEMP_WARNING_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1OverTempWarn);
        if (selectedVcuVeh1.includes(Common.VEH1_REPAIR_LAMP_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1RepairLampOn);
        if (selectedVcuVeh1.includes(Common.VEH1_UNDER_TEMP_PROTECT_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1UdrTempProtect);
        if (selectedVcuVeh1.includes(Common.VEH1_WARNING_LAMP_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1WarningLampOn);
        if (selectedVcuVeh1.includes(Common.VEH1_CHARGING_GUN_STATUS_KEY)) chartDatasVcuVeh1.push(chartVcuVeh1ChgGunStatus);

        setSeriesChartDatasVcuVeh1(chartDatasVcuVeh1);
    }, [
        selectedVcuVeh1,
        chartVcuVeh1Soc,
        chartVcuVeh1StateSys,
        chartVcuVeh1Speed,
        chartVcuVeh1StateChg,
        chartVcuVeh1StateFnr,
        chartVcuVeh1CluDispOn,
        chartVcuVeh1CluHighBeam,
        chartVcuVeh1CluLowBeam,
        chartVcuVeh1CluLTurn,
        chartVcuVeh1CluRTurn,
        chartVcuVeh1KeyFobCmd,
        chartVcuVeh1Regen,
        chartVcuVeh1SideBrake,
        chartVcuVeh1AuxBatLowWarn,
        chartVcuVeh1DerMod,
        chartVcuVeh1LowSohAlarm,
        chartVcuVeh1OverTempWarn,
        chartVcuVeh1RepairLampOn,
        chartVcuVeh1UdrTempProtect,
        chartVcuVeh1WarningLampOn,
        chartVcuVeh1ChgGunStatus,
    ]);

    const tableDataVcuVeh1: CanTableType[] = [
        {
            key: Common.VEH1_SOC_KEY,
            name: Common.VEH1_SOC_NAME,
            value: getVcuVeh1SocValue(),
        },
        {
            key: Common.VEH1_STATE_SYS_KEY,
            name: Common.VEH1_STATE_SYS_NAME,
            value: getVcuVeh1StateSysValue(),
        },
        {
            key: Common.VEH1_SPEED_KEY,
            name: Common.VEH1_SPEED_NAME,
            value: getVcuVeh1SpeedValue(),
        },
        {
            key: Common.VEH1_STATE_CHARGE_KEY,
            name: Common.VEH1_STATE_CHARGE_NAME,
            value: getVcuVeh1StateChgValue(),
        },
        {
            key: Common.VEH1_STATE_FNR_KEY,
            name: Common.VEH1_STATE_FNR_NAME,
            value: getVcuVeh1StateFnrValue(),
        },
        {
            key: Common.VEH1_CLU_DISPLAY_KEY,
            name: Common.VEH1_CLU_DISPLAY_NAME,
            value: getVcuVeh1CluDispOnValue(),
        },
        {
            key: Common.VEH1_CLU_HIGH_BEAM_KEY,
            name: Common.VEH1_CLU_HIGH_BEAM_NAME,
            value: getVcuVeh1CluHighBeamValue(),
        },
        {
            key: Common.VEH1_CLU_LOW_BEAM_KEY,
            name: Common.VEH1_CLU_LOW_BEAM_NAME,
            value: getVcuVeh1CluLowBeamValue(),
        },
        {
            key: Common.VEH1_CLU_LEFT_TURN_KEY,
            name: Common.VEH1_CLU_LEFT_TURN_NAME,
            value: getVcuVeh1CluLTurnValue(),
        },
        {
            key: Common.VEH1_CLU_RIGHT_TURN_KEY,
            name: Common.VEH1_CLU_RIGHT_TURN_NAME,
            value: getVcuVeh1CluRTurnValue(),
        },
        {
            key: Common.VEH1_KEY_FOB_CMD_KEY,
            name: Common.VEH1_KEY_FOB_CMD_NAME,
            value: getVcuVeh1KeyFobCmdValue(),
        },
        {
            key: Common.VEH1_REGEN_KEY,
            name: Common.VEH1_REGEN_NAME,
            value: getVcuVeh1RegenValue(),
        },
        {
            key: Common.VEH1_SIDE_BRAKE_KEY,
            name: Common.VEH1_SIDE_BRAKE_NAME,
            value: getVcuVeh1SideBrakeValue(),
        },
        {
            key: Common.VEH1_AUX_BATTERY_WARNING_KEY,
            name: Common.VEH1_AUX_BATTERY_WARNING_NAME,
            value: getVcuVeh1AuxBatLowWarnValue(),
        },
        {
            key: Common.VEH1_DERATING_MODE_KEY,
            name: Common.VEH1_DERATING_MODE_NAME,
            value: getVcuVeh1DerModValue(),
        },
        {
            key: Common.VEH1_LOW_SOH_ALARM_KEY,
            name: Common.VEH1_LOW_SOH_ALARM_NAME,
            value: getVcuVeh1LowSohAlarmValue(),
        },
        {
            key: Common.VEH1_OVER_TEMP_WARNING_KEY,
            name: Common.VEH1_OVER_TEMP_WARNING_NAME,
            value: getVcuVeh1OverTempWarnValue(),
        },
        {
            key: Common.VEH1_REPAIR_LAMP_KEY,
            name: Common.VEH1_REPAIR_LAMP_NAME,
            value: getVcuVeh1RepairLampOnValue(),
        },
        {
            key: Common.VEH1_UNDER_TEMP_PROTECT_KEY,
            name: Common.VEH1_UNDER_TEMP_PROTECT_NAME,
            value: getVcuVeh1UdrTempProtectValue(),
        },
        {
            key: Common.VEH1_WARNING_LAMP_KEY,
            name: Common.VEH1_WARNING_LAMP_NAME,
            value: getVcuVeh1WarningLampOnValue(),
        },
        {
            key: Common.VEH1_CHARGING_GUN_STATUS_KEY,
            name: Common.VEH1_CHARGING_GUN_STATUS_NAME,
            value: getVcuVeh1ChgGunStatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region vcuVeh2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuVeh2PanelOpen, setVcuVeh2PanelOpen] = useState(true);
    const [vcuVeh2ChartMounted, setVcuVeh2ChartMounted] = useState(false);
    const [selectedVcuVeh2, setSelectedVcuVeh2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuVeh2, setSeriesChartDatasVcuVeh2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuVeh2RawSpeed, setChartVcuVeh2RawSpeed] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_RAW_SPEED_NAME,
        data: [],
    });

    const [chartVcuVeh2IIgn, setChartVcuVeh2IIgn] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_I_IGN_NAME,
        data: [],
    });

    const [chartVcuVeh2IAcc, setChartVcuVeh2IAcc] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_I_CHARGE_NAME,
        data: [],
    });

    const [chartVcuVeh2DihBrake, setChartVcuVeh2DihBrake] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_DIH_BRAKE_NAME,
        data: [],
    });

    const [chartVcuVeh2DihHazard, setChartVcuVeh2DihHazard] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_DIH_HAZZARD_NAME,
        data: [],
    });

    const [chartVcuVeh2OInvOn, setChartVcuVeh2OInvOn] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_O_INV_ON_NAME,
        data: [],
    });

    const [chartVcuVeh2OMainRly, setChartVcuVeh2OMainRly] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_O_MAIN_RLY_NAME,
        data: [],
    });

    const [chartVcuVeh2OBmsOn, setChartVcuVeh2OBmsOn] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_O_BMS_ON_NAME,
        data: [],
    });

    const [chartVcuVeh2OLpReverse, setChartVcuVeh2OLpReverse] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_O_LP_REVERSE_NAME,
        data: [],
    });

    const [chartVcuVeh2VoltBat, setChartVcuVeh2VoltBat] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_VOLT_BATT_NAME,
        data: [],
    });

    const [chartVcuVeh2AiCharge, setChartVcuVeh2AiCharge] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_AI_CHARGE_NAME,
        data: [],
    });

    const [chartVcuVeh2DihTurnL, setChartVcuVeh2DihTurnL] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_DIH_TURN_L_NAME,
        data: [],
    });

    const [chartVcuVeh2DihTurnR, setChartVcuVeh2DihTurnR] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_DIH_TURN_R_NAME,
        data: [],
    });

    const [chartVcuVeh2OLpTurnL, setChartVcuVeh2OLpTurnL] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_O_LP_TURN_L_NAME,
        data: [],
    });

    const [chartVcuVeh2OLpTurnR, setChartVcuVeh2OLpTurnR] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_O_LP_TURN_R_NAME,
        data: [],
    });

    const [chartVcuVeh2DihHazardL, setChartVcuVeh2DihHazardL] = useState<TypeUtils.ChartData>({
        name: Common.VEH2_DIH_HAZZARD_L_NAME,
        data: [],
    });

    const getTabelTooltipVcuVeh2 = (key: React.Key) => {
        switch (key) {
            case Common.VEH2_RAW_SPEED_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_VehSpd_Raw
                        </p>
                        <p>Remarks: Calculated Vehicle Speed - km/h</p>
                    </>
                );

            case Common.VEH2_I_IGN_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_I_IGN
                        </p>
                    </>
                );

            case Common.VEH2_I_CHARGE_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_I_CHARGE
                        </p>
                    </>
                );

            case Common.VEH2_DIH_BRAKE_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_DIH_Brake
                        </p>
                    </>
                );

            case Common.VEH2_DIH_HAZZARD_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_DIH_Hazard
                        </p>
                    </>
                );

            case Common.VEH2_O_INV_ON_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_O_INV_ON
                        </p>
                    </>
                );

            case Common.VEH2_O_MAIN_RLY_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_O_MAIN_RLY
                        </p>
                    </>
                );

            case Common.VEH2_O_BMS_ON_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_O_BMS_ON
                        </p>
                    </>
                );

            case Common.VEH2_O_LP_REVERSE_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_O_LP_Reverse
                        </p>
                    </>
                );

            case Common.VEH2_VOLT_BATT_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_Volt_Batt
                        </p>
                        <p>Remarks: 12V Battery Protection Voltage - V</p>
                    </>
                );

            case Common.VEH2_AI_CHARGE_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_AI_CHARGE
                        </p>
                        <p>
                            Remarks: V
                            <br />
                            CHARGE Voltage
                            <br />
                            BMS to VCU WakeUp 12V Voltage
                        </p>
                    </>
                );

            case Common.VEH2_DIH_TURN_L_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_DIH_Turn_L
                        </p>
                    </>
                );

            case Common.VEH2_DIH_TURN_R_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_DIH_Turn_R
                        </p>
                    </>
                );

            case Common.VEH2_O_LP_TURN_L_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_O_LP_Turn_L
                        </p>
                    </>
                );

            case Common.VEH2_O_LP_TURN_R_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_O_LP_Turn_R
                        </p>
                    </>
                );

            case Common.VEH2_DIH_HAZZARD_L_KEY:
                return (
                    <>
                        <p>
                            VCU_VEH2
                            <br />
                            CAN ID: 0x00000335
                            <br />
                            Name: VCU_DIH_Harzard_L
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVcuVeh2RawSpeedValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_RAW_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuVeh2?.rawSpeed, Common.UNIT_SPEED);
        }
        return "";
    };

    const getVcuVeh2IIgnValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_I_IGN_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.IIgn);
        }
        return "";
    };

    const getVcuVeh2IAccValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_I_CHARGE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.IAcc);
        }
        return "";
    };

    const getVcuVeh2DihBrakeValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_BRAKE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.dihBrake);
        }
        return "";
    };

    const getVcuVeh2DihHazardValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_HAZZARD_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.dihHazard);
        }
        return "";
    };

    const getVcuVeh2OInvOnValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_O_INV_ON_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.OInvOn);
        }
        return "";
    };

    const getVcuVeh2OMainRlyValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_O_MAIN_RLY_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.OMainRly);
        }
        return "";
    };

    const getVcuVeh2OBmsOnValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_O_BMS_ON_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.OBmsOn);
        }
        return "";
    };

    const getVcuVeh2OLpReverseValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_O_LP_REVERSE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.OLpReverse);
        }
        return "";
    };

    const getVcuVeh2VoltBatValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_VOLT_BATT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuVeh2?.voltBat, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getVcuVeh2AiChargeValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_AI_CHARGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuVeh2?.aiCharge, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getVcuVeh2DihTurnLValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_TURN_L_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.dihTurnL);
        }
        return "";
    };

    const getVcuVeh2DihTurnRValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_TURN_R_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.dihTurnR);
        }
        return "";
    };

    const getVcuVeh2OLpTurnLValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_O_LP_TURN_L_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.OLpTurnL);
        }
        return "";
    };

    const getVcuVeh2OLpTurnRValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_O_LP_TURN_R_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.OLpTurnR);
        }
        return "";
    };

    const getVcuVeh2DihHazardLValue = () => {
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_HAZZARD_L_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuVeh2?.dihHazardL);
        }
        return "";
    };

    const onChangeVcuVeh2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuVeh2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuVeh2: Array<TypeUtils.ChartData> = [];

        if (selectedVcuVeh2.includes(Common.VEH2_RAW_SPEED_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2RawSpeed);
        if (selectedVcuVeh2.includes(Common.VEH2_I_IGN_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2IIgn);
        if (selectedVcuVeh2.includes(Common.VEH2_I_CHARGE_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2IAcc);
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_BRAKE_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2DihBrake);
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_HAZZARD_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2DihHazard);
        if (selectedVcuVeh2.includes(Common.VEH2_O_INV_ON_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2OInvOn);
        if (selectedVcuVeh2.includes(Common.VEH2_O_MAIN_RLY_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2OMainRly);
        if (selectedVcuVeh2.includes(Common.VEH2_O_BMS_ON_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2OBmsOn);
        if (selectedVcuVeh2.includes(Common.VEH2_O_LP_REVERSE_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2OLpReverse);
        if (selectedVcuVeh2.includes(Common.VEH2_VOLT_BATT_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2VoltBat);
        if (selectedVcuVeh2.includes(Common.VEH2_AI_CHARGE_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2AiCharge);
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_TURN_L_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2DihTurnL);
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_TURN_R_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2DihTurnR);
        if (selectedVcuVeh2.includes(Common.VEH2_O_LP_TURN_L_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2OLpTurnL);
        if (selectedVcuVeh2.includes(Common.VEH2_O_LP_TURN_R_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2OLpTurnR);
        if (selectedVcuVeh2.includes(Common.VEH2_DIH_HAZZARD_L_KEY)) chartDatasVcuVeh2.push(chartVcuVeh2DihHazardL);

        setSeriesChartDatasVcuVeh2(chartDatasVcuVeh2);
    }, [
        selectedVcuVeh2,
        chartVcuVeh2RawSpeed,
        chartVcuVeh2IIgn,
        chartVcuVeh2IAcc,
        chartVcuVeh2DihBrake,
        chartVcuVeh2DihHazard,
        chartVcuVeh2OInvOn,
        chartVcuVeh2OMainRly,
        chartVcuVeh2OBmsOn,
        chartVcuVeh2OLpReverse,
        chartVcuVeh2VoltBat,
        chartVcuVeh2AiCharge,
        chartVcuVeh2DihTurnL,
        chartVcuVeh2DihTurnR,
        chartVcuVeh2OLpTurnL,
        chartVcuVeh2OLpTurnR,
        chartVcuVeh2DihHazardL,
    ]);

    const tableDataVcuVeh2: CanTableType[] = [
        {
            key: Common.VEH2_RAW_SPEED_KEY,
            name: Common.VEH2_RAW_SPEED_NAME,
            value: getVcuVeh2RawSpeedValue(),
        },
        {
            key: Common.VEH2_I_IGN_KEY,
            name: Common.VEH2_I_IGN_NAME,
            value: getVcuVeh2IIgnValue(),
        },
        {
            key: Common.VEH2_I_CHARGE_KEY,
            name: Common.VEH2_I_CHARGE_NAME,
            value: getVcuVeh2IAccValue(),
        },
        {
            key: Common.VEH2_DIH_BRAKE_KEY,
            name: Common.VEH2_DIH_BRAKE_NAME,
            value: getVcuVeh2DihBrakeValue(),
        },
        {
            key: Common.VEH2_DIH_HAZZARD_KEY,
            name: Common.VEH2_DIH_HAZZARD_NAME,
            value: getVcuVeh2DihHazardValue(),
        },
        {
            key: Common.VEH2_O_INV_ON_KEY,
            name: Common.VEH2_O_INV_ON_NAME,
            value: getVcuVeh2OInvOnValue(),
        },
        {
            key: Common.VEH2_O_MAIN_RLY_KEY,
            name: Common.VEH2_O_MAIN_RLY_NAME,
            value: getVcuVeh2OMainRlyValue(),
        },
        {
            key: Common.VEH2_O_BMS_ON_KEY,
            name: Common.VEH2_O_BMS_ON_NAME,
            value: getVcuVeh2OBmsOnValue(),
        },
        {
            key: Common.VEH2_O_LP_REVERSE_KEY,
            name: Common.VEH2_O_LP_REVERSE_NAME,
            value: getVcuVeh2OLpReverseValue(),
        },
        {
            key: Common.VEH2_VOLT_BATT_KEY,
            name: Common.VEH2_VOLT_BATT_NAME,
            value: getVcuVeh2VoltBatValue(),
        },
        {
            key: Common.VEH2_AI_CHARGE_KEY,
            name: Common.VEH2_AI_CHARGE_NAME,
            value: getVcuVeh2AiChargeValue(),
        },
        {
            key: Common.VEH2_DIH_TURN_L_KEY,
            name: Common.VEH2_DIH_TURN_L_NAME,
            value: getVcuVeh2DihTurnLValue(),
        },
        {
            key: Common.VEH2_DIH_TURN_R_KEY,
            name: Common.VEH2_DIH_TURN_R_NAME,
            value: getVcuVeh2DihTurnRValue(),
        },
        {
            key: Common.VEH2_O_LP_TURN_L_KEY,
            name: Common.VEH2_O_LP_TURN_L_NAME,
            value: getVcuVeh2OLpTurnLValue(),
        },
        {
            key: Common.VEH2_O_LP_TURN_R_KEY,
            name: Common.VEH2_O_LP_TURN_R_NAME,
            value: getVcuVeh2OLpTurnRValue(),
        },
        {
            key: Common.VEH2_DIH_HAZZARD_L_KEY,
            name: Common.VEH2_DIH_HAZZARD_L_NAME,
            value: getVcuVeh2DihHazardLValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region vcuObd1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuObd1PanelOpen, setVcuObd1PanelOpen] = useState(true);
    const [vcuObd1ChartMounted, setVcuObd1ChartMounted] = useState(false);
    const [selectedVcuObd1, setSelectedVcuObd1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuObd1, setSeriesChartDatasVcuObd1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuObd1Byte00, setChartVcuObd1Byte00] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_0_NAME,
        data: [],
    });

    const [chartVcuObd1Byte01, setChartVcuObd1Byte01] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_1_NAME,
        data: [],
    });

    const [chartVcuObd1Byte02, setChartVcuObd1Byte02] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_2_NAME,
        data: [],
    });

    const [chartVcuObd1Byte03, setChartVcuObd1Byte03] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_3_NAME,
        data: [],
    });

    const [chartVcuObd1Byte04, setChartVcuObd1Byte04] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_4_NAME,
        data: [],
    });

    const [chartVcuObd1Byte05, setChartVcuObd1Byte05] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_5_NAME,
        data: [],
    });

    const [chartVcuObd1Byte06, setChartVcuObd1Byte06] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_6_NAME,
        data: [],
    });

    const [chartVcuObd1Byte07, setChartVcuObd1Byte07] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_7_NAME,
        data: [],
    });

    const getTabelTooltipVcuObd1 = (key: React.Key) => {
        switch (key) {
            case Common.OBD_BYTE_0_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte0
                        </p>
                    </>
                );

            case Common.OBD_BYTE_1_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte1
                        </p>
                    </>
                );

            case Common.OBD_BYTE_2_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte2
                        </p>
                    </>
                );

            case Common.OBD_BYTE_3_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte3
                        </p>
                    </>
                );

            case Common.OBD_BYTE_4_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte4
                        </p>
                    </>
                );

            case Common.OBD_BYTE_5_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte5
                        </p>
                    </>
                );

            case Common.OBD_BYTE_6_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte6
                        </p>
                    </>
                );
            case Common.OBD_BYTE_7_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD1
                            <br />
                            CAN ID: 0x000003E0
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte7
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVcuObd1Byte00Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_0_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte0);
        }
        return "";
    };

    const getVcuObd1Byte01Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_1_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte1);
        }
        return "";
    };

    const getVcuObd1Byte02Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_2_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte2);
        }
        return "";
    };

    const getVcuObd1Byte03Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_3_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte3);
        }
        return "";
    };

    const getVcuObd1Byte04Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_4_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte4);
        }
        return "";
    };

    const getVcuObd1Byte05Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_5_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte5);
        }
        return "";
    };

    const getVcuObd1Byte06Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_6_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte6);
        }
        return "";
    };

    const getVcuObd1Byte07Value = () => {
        if (selectedVcuObd1.includes(Common.OBD_BYTE_7_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd1?.byte7);
        }
        return "";
    };

    const onChangeVcuObd1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuObd1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuObd1: Array<TypeUtils.ChartData> = [];

        if (selectedVcuObd1.includes(Common.OBD_BYTE_0_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte00);
        if (selectedVcuObd1.includes(Common.OBD_BYTE_1_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte01);
        if (selectedVcuObd1.includes(Common.OBD_BYTE_2_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte02);
        if (selectedVcuObd1.includes(Common.OBD_BYTE_3_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte03);
        if (selectedVcuObd1.includes(Common.OBD_BYTE_4_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte04);
        if (selectedVcuObd1.includes(Common.OBD_BYTE_5_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte05);
        if (selectedVcuObd1.includes(Common.OBD_BYTE_6_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte06);
        if (selectedVcuObd1.includes(Common.OBD_BYTE_7_KEY)) chartDatasVcuObd1.push(chartVcuObd1Byte07);

        setSeriesChartDatasVcuObd1(chartDatasVcuObd1);
    }, [
        selectedVcuObd1,
        chartVcuObd1Byte00,
        chartVcuObd1Byte01,
        chartVcuObd1Byte02,
        chartVcuObd1Byte03,
        chartVcuObd1Byte04,
        chartVcuObd1Byte05,
        chartVcuObd1Byte06,
        chartVcuObd1Byte07,
    ]);

    const tableDataVcuObd1: CanTableType[] = [
        {
            key: Common.OBD_BYTE_0_KEY,
            name: Common.OBD_BYTE_0_NAME,
            value: getVcuObd1Byte00Value(),
        },
        {
            key: Common.OBD_BYTE_1_KEY,
            name: Common.OBD_BYTE_1_NAME,
            value: getVcuObd1Byte01Value(),
        },
        {
            key: Common.OBD_BYTE_2_KEY,
            name: Common.OBD_BYTE_2_NAME,
            value: getVcuObd1Byte02Value(),
        },
        {
            key: Common.OBD_BYTE_3_KEY,
            name: Common.OBD_BYTE_3_NAME,
            value: getVcuObd1Byte03Value(),
        },
        {
            key: Common.OBD_BYTE_4_KEY,
            name: Common.OBD_BYTE_4_NAME,
            value: getVcuObd1Byte04Value(),
        },
        {
            key: Common.OBD_BYTE_5_KEY,
            name: Common.OBD_BYTE_5_NAME,
            value: getVcuObd1Byte05Value(),
        },
        {
            key: Common.OBD_BYTE_6_KEY,
            name: Common.OBD_BYTE_6_NAME,
            value: getVcuObd1Byte06Value(),
        },
        {
            key: Common.OBD_BYTE_7_KEY,
            name: Common.OBD_BYTE_7_NAME,
            value: getVcuObd1Byte07Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region vcuObd2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuObd2PanelOpen, setVcuObd2PanelOpen] = useState(true);
    const [vcuObd2ChartMounted, setVcuObd2ChartMounted] = useState(false);
    const [selectedVcuObd2, setSelectedVcuObd2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuObd2, setSeriesChartDatasVcuObd2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuObd2Byte08, setChartVcuObd2Byte08] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_8_NAME,
        data: [],
    });

    const [chartVcuObd2Byte09, setChartVcuObd2Byte09] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_9_NAME,
        data: [],
    });

    const [chartVcuObd2Byte10, setChartVcuObd2Byte10] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_10_NAME,
        data: [],
    });

    const [chartVcuObd2Byte11, setChartVcuObd2Byte11] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_11_NAME,
        data: [],
    });

    const [chartVcuObd2Byte12, setChartVcuObd2Byte12] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_12_NAME,
        data: [],
    });

    const [chartVcuObd2Byte13, setChartVcuObd2Byte13] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_13_NAME,
        data: [],
    });

    const [chartVcuObd2Byte14, setChartVcuObd2Byte14] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_14_NAME,
        data: [],
    });

    const [chartVcuObd2Byte15, setChartVcuObd2Byte15] = useState<TypeUtils.ChartData>({
        name: Common.OBD_BYTE_15_NAME,
        data: [],
    });

    const getTabelTooltipVcuObd2 = (key: React.Key) => {
        switch (key) {
            case Common.OBD_BYTE_8_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte8
                        </p>
                    </>
                );

            case Common.OBD_BYTE_9_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte9
                        </p>
                    </>
                );

            case Common.OBD_BYTE_10_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte10
                        </p>
                    </>
                );

            case Common.OBD_BYTE_11_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte11
                        </p>
                    </>
                );

            case Common.OBD_BYTE_12_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte12
                        </p>
                    </>
                );

            case Common.OBD_BYTE_13_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte13
                        </p>
                    </>
                );

            case Common.OBD_BYTE_14_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte14
                        </p>
                    </>
                );

            case Common.OBD_BYTE_15_KEY:
                return (
                    <>
                        <p>
                            VCU_OBD2
                            <br />
                            CAN ID: 0x000003E1
                            <br />
                            Name: VCUOBD_Faultcurrent_Byte15
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVcuObd2Byte08Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_8_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte8);
        }
        return "";
    };

    const getVcuObd2Byte09Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_9_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte9);
        }
        return "";
    };

    const getVcuObd2Byte10Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_10_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte10);
        }
        return "";
    };

    const getVcuObd2Byte11Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_11_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte11);
        }
        return "";
    };

    const getVcuObd2Byte12Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_12_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte12);
        }
        return "";
    };

    const getVcuObd2Byte13Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_13_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte13);
        }
        return "";
    };

    const getVcuObd2Byte14Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_14_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte14);
        }
        return "";
    };

    const getVcuObd2Byte15Value = () => {
        if (selectedVcuObd2.includes(Common.OBD_BYTE_15_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuObd2?.byte15);
        }
        return "";
    };

    const onChangeVcuObd2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuObd2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuObd2: Array<TypeUtils.ChartData> = [];

        if (selectedVcuObd2.includes(Common.OBD_BYTE_8_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte08);
        if (selectedVcuObd2.includes(Common.OBD_BYTE_9_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte09);
        if (selectedVcuObd2.includes(Common.OBD_BYTE_10_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte10);
        if (selectedVcuObd2.includes(Common.OBD_BYTE_11_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte11);
        if (selectedVcuObd2.includes(Common.OBD_BYTE_12_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte12);
        if (selectedVcuObd2.includes(Common.OBD_BYTE_13_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte13);
        if (selectedVcuObd2.includes(Common.OBD_BYTE_14_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte14);
        if (selectedVcuObd2.includes(Common.OBD_BYTE_15_KEY)) chartDatasVcuObd2.push(chartVcuObd2Byte15);

        setSeriesChartDatasVcuObd2(chartDatasVcuObd2);
    }, [
        selectedVcuObd2,
        chartVcuObd2Byte08,
        chartVcuObd2Byte09,
        chartVcuObd2Byte10,
        chartVcuObd2Byte11,
        chartVcuObd2Byte12,
        chartVcuObd2Byte13,
        chartVcuObd2Byte14,
        chartVcuObd2Byte15,
    ]);

    const tableDataVcuObd2: CanTableType[] = [
        {
            key: Common.OBD_BYTE_8_KEY,
            name: Common.OBD_BYTE_8_NAME,
            value: getVcuObd2Byte08Value(),
        },
        {
            key: Common.OBD_BYTE_9_KEY,
            name: Common.OBD_BYTE_9_NAME,
            value: getVcuObd2Byte09Value(),
        },
        {
            key: Common.OBD_BYTE_10_KEY,
            name: Common.OBD_BYTE_10_NAME,
            value: getVcuObd2Byte10Value(),
        },
        {
            key: Common.OBD_BYTE_11_KEY,
            name: Common.OBD_BYTE_11_NAME,
            value: getVcuObd2Byte11Value(),
        },
        {
            key: Common.OBD_BYTE_12_KEY,
            name: Common.OBD_BYTE_12_NAME,
            value: getVcuObd2Byte12Value(),
        },
        {
            key: Common.OBD_BYTE_13_KEY,
            name: Common.OBD_BYTE_13_NAME,
            value: getVcuObd2Byte13Value(),
        },
        {
            key: Common.OBD_BYTE_14_KEY,
            name: Common.OBD_BYTE_14_NAME,
            value: getVcuObd2Byte14Value(),
        },
        {
            key: Common.OBD_BYTE_15_KEY,
            name: Common.OBD_BYTE_15_NAME,
            value: getVcuObd2Byte15Value(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region vcuOdo1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuOdo1PanelOpen, setVcuOdo1PanelOpen] = useState(true);
    const [vcuOdo1ChartMounted, setVcuOdo1ChartMounted] = useState(false);
    const [selectedVcuOdo1, setSelectedVcuOdo1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuOdo1, setSeriesChartDatasVcuOdo1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuOdo1Trip2Empty, setChartVcuOdo1Trip2Empty] = useState<TypeUtils.ChartData>({
        name: Common.ODO1_TRIP_2_EMPTY_NAME,
        data: [],
    });

    const [chartVcuOdo1HvPackVoltage, setChartVcuOdo1HvPackVoltage] = useState<TypeUtils.ChartData>({
        name: Common.ODO1_HV_PACK_VOLTAGE_NAME,
        data: [],
    });

    const [chartVcuOdo1HvCurrent, setChartVcuOdo1HvCurrent] = useState<TypeUtils.ChartData>({
        name: Common.ODO1_HV_CURRENT_NAME,
        data: [],
    });

    const [chartVcuOdo1TotalChgKwh, setChartVcuOdo1TotalChgKwh] = useState<TypeUtils.ChartData>({
        name: Common.ODO1_TOTAL_CHARGE_KWH_NAME,
        data: [],
    });

    const getTabelTooltipVcuOdo1 = (key: React.Key) => {
        switch (key) {
            case Common.ODO1_TRIP_2_EMPTY_KEY:
                return (
                    <>
                        <p>
                            VCU_ODO1
                            <br />
                            CAN ID: 0x00000332
                            <br />
                            Name: VCU_Trip2Empty
                        </p>
                        <p>
                            Remarks: km
                            <br />
                            Expected trip Distance based on Battery SOC
                        </p>
                    </>
                );

            case Common.ODO1_HV_PACK_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            VCU_ODO1
                            <br />
                            CAN ID: 0x00000332
                            <br />
                            Name: VCU_HV_Pack_Voltage
                        </p>
                        <p>
                            Remarks: V
                            <br />
                            Pack Voltage : Y=X*0.1
                        </p>
                    </>
                );

            case Common.ODO1_HV_CURRENT_KEY:
                return (
                    <>
                        <p>
                            VCU_ODO1
                            <br />
                            CAN ID: 0x00000332
                            <br />
                            Name: VCU_HV_Current
                        </p>
                        <p>
                            Remarks: A
                            <br />
                            Charge & Discharge Current : Y=X*0.1-500
                        </p>
                    </>
                );

            case Common.ODO1_TOTAL_CHARGE_KWH_KEY:
                return (
                    <>
                        <p>
                            VCU_ODO1
                            <br />
                            CAN ID: 0x00000332
                            <br />
                            Name: VCU_TotalChgkWh
                        </p>
                        <p>
                            Remarks: kWh
                            <br />
                            Accumulated charge power after charge start
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVcuOdo1Trip2EmptyValue = () => {
        if (selectedVcuOdo1.includes(Common.ODO1_TRIP_2_EMPTY_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuOdo1?.trip2Empty, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const getVcuOdo1HvPackVoltageValue = () => {
        if (selectedVcuOdo1.includes(Common.ODO1_HV_PACK_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuOdo1?.hvPackVoltage, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getVcuOdo1HvCurrentValue = () => {
        if (selectedVcuOdo1.includes(Common.ODO1_HV_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuOdo1?.hvCurrent, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getVcuOdo1TotalChgKwhValue = () => {
        if (selectedVcuOdo1.includes(Common.ODO1_TOTAL_CHARGE_KWH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuOdo1?.totalChgKwh, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeVcuOdo1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuOdo1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuOdo1: Array<TypeUtils.ChartData> = [];

        if (selectedVcuOdo1.includes(Common.ODO1_TRIP_2_EMPTY_KEY)) chartDatasVcuOdo1.push(chartVcuOdo1Trip2Empty);
        if (selectedVcuOdo1.includes(Common.ODO1_HV_PACK_VOLTAGE_KEY)) chartDatasVcuOdo1.push(chartVcuOdo1HvPackVoltage);
        if (selectedVcuOdo1.includes(Common.ODO1_HV_CURRENT_KEY)) chartDatasVcuOdo1.push(chartVcuOdo1HvCurrent);
        if (selectedVcuOdo1.includes(Common.ODO1_TOTAL_CHARGE_KWH_KEY)) chartDatasVcuOdo1.push(chartVcuOdo1TotalChgKwh);

        setSeriesChartDatasVcuOdo1(chartDatasVcuOdo1);
    }, [selectedVcuOdo1, chartVcuOdo1Trip2Empty, chartVcuOdo1HvPackVoltage, chartVcuOdo1HvCurrent, chartVcuOdo1TotalChgKwh]);

    const tableDataVcuOdo1: CanTableType[] = [
        {
            key: Common.ODO1_TRIP_2_EMPTY_KEY,
            name: Common.ODO1_TRIP_2_EMPTY_NAME,
            value: getVcuOdo1Trip2EmptyValue(),
        },
        {
            key: Common.ODO1_HV_PACK_VOLTAGE_KEY,
            name: Common.ODO1_HV_PACK_VOLTAGE_NAME,
            value: getVcuOdo1HvPackVoltageValue(),
        },
        {
            key: Common.ODO1_HV_CURRENT_KEY,
            name: Common.ODO1_HV_CURRENT_NAME,
            value: getVcuOdo1HvCurrentValue(),
        },
        {
            key: Common.ODO1_TOTAL_CHARGE_KWH_KEY,
            name: Common.ODO1_TOTAL_CHARGE_KWH_NAME,
            value: getVcuOdo1TotalChgKwhValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region vcuOdo2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuOdo2PanelOpen, setVcuOdo2PanelOpen] = useState(true);
    const [vcuOdo2ChartMounted, setVcuOdo2ChartMounted] = useState(false);
    const [selectedVcuOdo2, setSelectedVcuOdo2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuOdo2, setSeriesChartDatasVcuOdo2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuOdo2TripTot, setChartVcuOdo2TripTot] = useState<TypeUtils.ChartData>({
        name: Common.ODO2_TRIP_TOT_NAME,
        data: [],
    });

    const getTabelTooltipVcuOdo2 = (key: React.Key) => {
        switch (key) {
            case Common.ODO2_TRIP_TOT_KEY:
                return (
                    <>
                        <p>
                            VCU_ODO2
                            <br />
                            CAN ID: 0x00000333
                            <br />
                            Name: VCU_TripTot
                        </p>
                        <p>
                            Remarks: km
                            <br />
                            Total Trip Distance
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVcuOdo2TripTotValue = () => {
        if (selectedVcuOdo2.includes(Common.ODO2_TRIP_TOT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuOdo2?.tripTot, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const onChangeVcuOdo2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuOdo2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuOdo2: Array<TypeUtils.ChartData> = [];

        if (selectedVcuOdo2.includes(Common.ODO2_TRIP_TOT_KEY)) chartDatasVcuOdo2.push(chartVcuOdo2TripTot);

        setSeriesChartDatasVcuOdo2(chartDatasVcuOdo2);
    }, [selectedVcuOdo2, chartVcuOdo2TripTot]);

    const tableDataVcuOdo2: CanTableType[] = [
        {
            key: Common.ODO2_TRIP_TOT_KEY,
            name: Common.ODO2_TRIP_TOT_NAME,
            value: getVcuOdo2TripTotValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region vcuOdo3
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuOdo3PanelOpen, setVcuOdo3PanelOpen] = useState(true);
    const [vcuOdo3ChartMounted, setVcuOdo3ChartMounted] = useState(false);
    const [selectedVcuOdo3, setSelectedVcuOdo3] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVcuOdo3, setSeriesChartDatasVcuOdo3] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuOdo3TripTotUser, setChartVcuOdo3TripTotUser] = useState<TypeUtils.ChartData>({
        name: Common.ODO3_TRIP_TOT_USER_NAME,
        data: [],
    });

    const getTabelTooltipVcuOdo3 = (key: React.Key) => {
        switch (key) {
            case Common.ODO3_TRIP_TOT_USER_KEY:
                return (
                    <>
                        <p>
                            VCU_ODO3
                            <br />
                            CAN ID: 0x00000334
                            <br />
                            Name: VCU_TripTotUser
                        </p>
                        <p>
                            Remarks: km
                            <br />
                            Total Trip Distance reset by User
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVcuOdo3TripTotUserValue = () => {
        if (selectedVcuOdo3.includes(Common.ODO3_TRIP_TOT_USER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuOdo3?.tripTotUser, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const onChangeVcuOdo3 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuOdo3(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuOdo3: Array<TypeUtils.ChartData> = [];

        if (selectedVcuOdo3.includes(Common.ODO3_TRIP_TOT_USER_KEY)) chartDatasVcuOdo3.push(chartVcuOdo3TripTotUser);

        setSeriesChartDatasVcuOdo3(chartDatasVcuOdo3);
    }, [selectedVcuOdo3, chartVcuOdo3TripTotUser]);

    const tableDataVcuOdo3: CanTableType[] = [
        {
            key: Common.ODO3_TRIP_TOT_USER_KEY,
            name: Common.ODO3_TRIP_TOT_USER_NAME,
            value: getVcuOdo3TripTotUserValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    useEffect(() => {
        if (vehicleInfo?.category?.categoryName === Strings.CoCoKart) {
            //#region Datas
            const bmsInfo1VoltDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1CurrDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1SocDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1ProximityDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1ChgStatDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1LowSocDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1ReadyDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1RlyDisChgDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1RlyChgDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1ChgFinishDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1ErrCodeDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1ErrLevelDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo1AliveCntDatas: TypeUtils.ChartElement[] = [];

            const bmsInfo2CellVoltMaxDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo2CellVoltMinDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo2CellTempMaxDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo2CellTempMinDatas: TypeUtils.ChartElement[] = [];
            const bmsInfo2DisChgCurrMaxDatas: TypeUtils.ChartElement[] = [];

            const bmsChgReqVoltMaxDatas: TypeUtils.ChartElement[] = [];
            const bmsChgReqCurrMaxDatas: TypeUtils.ChartElement[] = [];
            const bmsChgReqObcOnDatas: TypeUtils.ChartElement[] = [];
            const bmsChgReqObcModeDatas: TypeUtils.ChartElement[] = [];

            const bmsChgTimeDisChgCntDatas: TypeUtils.ChartElement[] = [];
            const bmsChgTimeChgCntDatas: TypeUtils.ChartElement[] = [];
            const bmsChgTimeSohDatas: TypeUtils.ChartElement[] = [];
            const bmsChgTimeQCurrDatas: TypeUtils.ChartElement[] = [];

            const obcChgInfoOutVoltDatas: TypeUtils.ChartElement[] = [];
            const obcChgInfoOutCurrDatas: TypeUtils.ChartElement[] = [];
            const obcChgInfoStsFailHwDatas: TypeUtils.ChartElement[] = [];
            const obcChgInfoStsOTPDatas: TypeUtils.ChartElement[] = [];
            const obcChgInfoStsInVoltDatas: TypeUtils.ChartElement[] = [];
            const obcChgInfoStsChkBatDatas: TypeUtils.ChartElement[] = [];
            const obcChgInfoStsCommDatas: TypeUtils.ChartElement[] = [];

            const mot1RpmDatas: TypeUtils.ChartElement[] = [];
            const mot1CurrDatas: TypeUtils.ChartElement[] = [];
            const mot1VoltDatas: TypeUtils.ChartElement[] = [];
            const mot1Err0Datas: TypeUtils.ChartElement[] = [];
            const mot1Err1Datas: TypeUtils.ChartElement[] = [];
            const mot1Err2Datas: TypeUtils.ChartElement[] = [];
            const mot1Err4Datas: TypeUtils.ChartElement[] = [];
            const mot1Err5Datas: TypeUtils.ChartElement[] = [];
            const mot1Err6Datas: TypeUtils.ChartElement[] = [];
            const mot1Err7Datas: TypeUtils.ChartElement[] = [];
            const mot1Err9Datas: TypeUtils.ChartElement[] = [];
            const mot1Err10Datas: TypeUtils.ChartElement[] = [];
            const mot1Err11Datas: TypeUtils.ChartElement[] = [];
            const mot1Err14Datas: TypeUtils.ChartElement[] = [];
            const mot1Err15Datas: TypeUtils.ChartElement[] = [];

            const mot2ThrottleDatas: TypeUtils.ChartElement[] = [];
            const mot2InverterTempDatas: TypeUtils.ChartElement[] = [];
            const mot2MotorTempDatas: TypeUtils.ChartElement[] = [];
            const mot2StsDnrDatas: TypeUtils.ChartElement[] = [];
            const mot2StsDnrFbDatas: TypeUtils.ChartElement[] = [];
            const mot2DiHallADatas: TypeUtils.ChartElement[] = [];
            const mot2DiHallBDatas: TypeUtils.ChartElement[] = [];
            const mot2DiHallCDatas: TypeUtils.ChartElement[] = [];
            const mot2BrakeSwDatas: TypeUtils.ChartElement[] = [];
            const mot2BackwardDatas: TypeUtils.ChartElement[] = [];
            const mot2ForwardDatas: TypeUtils.ChartElement[] = [];
            const mot2FootSwDatas: TypeUtils.ChartElement[] = [];
            const mot2BoostSwDatas: TypeUtils.ChartElement[] = [];

            const vcuVeh1SocDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1StateSysDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1SpeedDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1StateChgDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1StateFnrDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1CluDispOnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1CluHighBeamDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1CluLowBeamDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1CluLTurnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1CluRTurnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1KeyFobCmdDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1RegenDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1SideBrakeDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1AuxBatLowWarnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1DerModDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1LowSohAlarmDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1OverTempWarnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1RepairLampOnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1UdrTempProtectDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1WarningLampOnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh1ChgGunStatusDatas: TypeUtils.ChartElement[] = [];

            const vcuVeh2RawSpeedDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2IIgnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2IAccDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2DihBrakeDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2DihHazardDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2OInvOnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2OMainRlyDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2OBmsOnDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2OLpReverseDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2VoltBatDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2AiChargeDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2DihTurnLDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2DihTurnRDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2OLpTurnLDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2OLpTurnRDatas: TypeUtils.ChartElement[] = [];
            const vcuVeh2DihHazardLDatas: TypeUtils.ChartElement[] = [];

            const vcuObd1Byte00Datas: TypeUtils.ChartElement[] = [];
            const vcuObd1Byte01Datas: TypeUtils.ChartElement[] = [];
            const vcuObd1Byte02Datas: TypeUtils.ChartElement[] = [];
            const vcuObd1Byte03Datas: TypeUtils.ChartElement[] = [];
            const vcuObd1Byte04Datas: TypeUtils.ChartElement[] = [];
            const vcuObd1Byte05Datas: TypeUtils.ChartElement[] = [];
            const vcuObd1Byte06Datas: TypeUtils.ChartElement[] = [];
            const vcuObd1Byte07Datas: TypeUtils.ChartElement[] = [];

            const vcuObd2Byte08Datas: TypeUtils.ChartElement[] = [];
            const vcuObd2Byte09Datas: TypeUtils.ChartElement[] = [];
            const vcuObd2Byte10Datas: TypeUtils.ChartElement[] = [];
            const vcuObd2Byte11Datas: TypeUtils.ChartElement[] = [];
            const vcuObd2Byte12Datas: TypeUtils.ChartElement[] = [];
            const vcuObd2Byte13Datas: TypeUtils.ChartElement[] = [];
            const vcuObd2Byte14Datas: TypeUtils.ChartElement[] = [];
            const vcuObd2Byte15Datas: TypeUtils.ChartElement[] = [];

            const vcuOdo1Trip2EmptyDatas: TypeUtils.ChartElement[] = [];
            const vcuOdo1HvPackVoltageDatas: TypeUtils.ChartElement[] = [];
            const vcuOdo1HvCurrentDatas: TypeUtils.ChartElement[] = [];
            const vcuOdo1TotalChgKwhDatas: TypeUtils.ChartElement[] = [];

            const vcuOdo2TripTotDatas: TypeUtils.ChartElement[] = [];
            const vcuOdo3TripTotUserDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataCoCoKart.CanData;

                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region BmsInfo1
                bmsInfo1VoltDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.volt || 0,
                });

                bmsInfo1CurrDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.curr || 0,
                });

                bmsInfo1SocDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.soc || 0,
                });

                bmsInfo1ProximityDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.proximity || 0,
                });

                bmsInfo1ChgStatDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.chgStat || 0,
                });

                bmsInfo1LowSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.lowSoc || 0,
                });

                bmsInfo1ReadyDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.ready || 0,
                });

                bmsInfo1RlyDisChgDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.rlyDisChg || 0,
                });

                bmsInfo1RlyChgDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.rlyChg || 0,
                });

                bmsInfo1ChgFinishDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.chgFinish || 0,
                });

                bmsInfo1ErrCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.errCode || 0,
                });

                bmsInfo1ErrLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.errLevel || 0,
                });

                bmsInfo1AliveCntDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo1?.aliveCnt || 0,
                });
                //#endregion

                //#region BmsInfo2
                bmsInfo2CellVoltMaxDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo2?.cellVoltMax || 0,
                });

                bmsInfo2CellVoltMinDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo2?.cellVoltMin || 0,
                });

                bmsInfo2CellTempMaxDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo2?.cellTempMax || 0,
                });

                bmsInfo2CellTempMinDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo2?.cellTempMin || 0,
                });

                bmsInfo2DisChgCurrMaxDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsInfo2?.disChgCurrMax || 0,
                });
                //#endregion

                //#region BmsChgReq
                bmsChgReqVoltMaxDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgReq?.voltMax || 0,
                });

                bmsChgReqCurrMaxDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgReq?.currMax || 0,
                });

                bmsChgReqObcOnDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgReq?.obcOn || 0,
                });

                bmsChgReqObcModeDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgReq?.obcMode || 0,
                });
                //#endregion

                //#region BmsChgTime
                bmsChgTimeDisChgCntDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgTime?.disChgCnt || 0,
                });

                bmsChgTimeChgCntDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgTime?.chgCnt || 0,
                });

                bmsChgTimeSohDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgTime?.soh || 0,
                });

                bmsChgTimeQCurrDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsChgTime?.QCurr || 0,
                });
                //#endregion

                //#region ObcChgInfo
                obcChgInfoOutVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.obcChgInfo?.outVolt || 0,
                });

                obcChgInfoOutCurrDatas.push({
                    x: time.valueOf(),
                    y: canData.obcChgInfo?.outCurr || 0,
                });

                obcChgInfoStsFailHwDatas.push({
                    x: time.valueOf(),
                    y: canData.obcChgInfo?.stsFailHw || 0,
                });

                obcChgInfoStsOTPDatas.push({
                    x: time.valueOf(),
                    y: canData.obcChgInfo?.stsOTP || 0,
                });

                obcChgInfoStsInVoltDatas.push({
                    x: time.valueOf(),
                    y: canData.obcChgInfo?.stsInVolt || 0,
                });

                obcChgInfoStsChkBatDatas.push({
                    x: time.valueOf(),
                    y: canData.obcChgInfo?.stsChkBat || 0,
                });

                obcChgInfoStsCommDatas.push({
                    x: time.valueOf(),
                    y: canData.obcChgInfo?.stsComm || 0,
                });
                //#endregion

                //#region Mot1
                mot1RpmDatas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.rpm || 0,
                });

                mot1CurrDatas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.curr || 0,
                });

                mot1VoltDatas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.volt || 0,
                });

                mot1Err0Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err0 || 0,
                });

                mot1Err1Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err1 || 0,
                });

                mot1Err2Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err2 || 0,
                });

                mot1Err4Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err4 || 0,
                });

                mot1Err5Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err5 || 0,
                });

                mot1Err6Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err6 || 0,
                });

                mot1Err7Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err7 || 0,
                });

                mot1Err9Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err9 || 0,
                });

                mot1Err10Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err10 || 0,
                });

                mot1Err11Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err11 || 0,
                });

                mot1Err14Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err14 || 0,
                });

                mot1Err15Datas.push({
                    x: time.valueOf(),
                    y: canData.mot1?.err15 || 0,
                });
                //#endregion

                //#region Mot1
                mot2ThrottleDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.throttle || 0,
                });

                mot2InverterTempDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.inverterTemp || 0,
                });

                mot2MotorTempDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.motorTemp || 0,
                });

                mot2StsDnrDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.stsDnr || 0,
                });

                mot2StsDnrFbDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.stsDnrFb || 0,
                });

                mot2DiHallADatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.diHallA || 0,
                });

                mot2DiHallBDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.diHallB || 0,
                });

                mot2DiHallCDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.diHallC || 0,
                });

                mot2BrakeSwDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.brakeSw || 0,
                });

                mot2BackwardDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.backward || 0,
                });

                mot2ForwardDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.forward || 0,
                });

                mot2FootSwDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.footSw || 0,
                });

                mot2BoostSwDatas.push({
                    x: time.valueOf(),
                    y: canData.mot2?.boostSw || 0,
                });
                //#endregion

                //#region VcuVeh1
                vcuVeh1SocDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.soc || 0,
                });

                vcuVeh1StateSysDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.stateSys || 0,
                });

                vcuVeh1SpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.speed || 0,
                });

                vcuVeh1StateChgDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.stateChg || 0,
                });

                vcuVeh1StateFnrDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.stateFnr || 0,
                });

                vcuVeh1CluDispOnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.cluDispOn || 0,
                });

                vcuVeh1CluHighBeamDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.cluHighBeam || 0,
                });

                vcuVeh1CluLowBeamDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.cluLowBeam || 0,
                });

                vcuVeh1CluLTurnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.cluLTurn || 0,
                });

                vcuVeh1CluRTurnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.cluRTurn || 0,
                });

                vcuVeh1KeyFobCmdDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.keyFobCmd || 0,
                });

                vcuVeh1RegenDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.regen || 0,
                });

                vcuVeh1SideBrakeDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.sideBrake || 0,
                });

                vcuVeh1AuxBatLowWarnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.auxBatLowWarn || 0,
                });

                vcuVeh1DerModDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.derMod || 0,
                });

                vcuVeh1LowSohAlarmDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.lowSohAlarm || 0,
                });

                vcuVeh1OverTempWarnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.overTempWarn || 0,
                });

                vcuVeh1RepairLampOnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.repairLampOn || 0,
                });

                vcuVeh1UdrTempProtectDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.udrTempProtect || 0,
                });

                vcuVeh1WarningLampOnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.warningLampOn || 0,
                });

                vcuVeh1ChgGunStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh1?.chgGunStatus || 0,
                });
                //#endregion

                //#region VcuVeh2
                vcuVeh2RawSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.rawSpeed || 0,
                });

                vcuVeh2IIgnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.IIgn || 0,
                });

                vcuVeh2IAccDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.IAcc || 0,
                });

                vcuVeh2DihBrakeDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.dihBrake || 0,
                });

                vcuVeh2DihHazardDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.dihHazard || 0,
                });

                vcuVeh2OInvOnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.OInvOn || 0,
                });

                vcuVeh2OMainRlyDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.OMainRly || 0,
                });

                vcuVeh2OBmsOnDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.OBmsOn || 0,
                });

                vcuVeh2OLpReverseDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.OLpReverse || 0,
                });

                vcuVeh2VoltBatDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.voltBat || 0,
                });

                vcuVeh2AiChargeDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.aiCharge || 0,
                });

                vcuVeh2DihTurnLDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.dihTurnL || 0,
                });

                vcuVeh2DihTurnRDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.dihTurnR || 0,
                });

                vcuVeh2OLpTurnLDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.OLpTurnL || 0,
                });

                vcuVeh2OLpTurnRDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.OLpTurnR || 0,
                });

                vcuVeh2DihHazardLDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuVeh2?.dihHazardL || 0,
                });
                //#endregion

                //#region VcuObd1
                vcuObd1Byte00Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte0 || 0,
                });

                vcuObd1Byte01Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte1 || 0,
                });

                vcuObd1Byte02Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte2 || 0,
                });

                vcuObd1Byte03Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte3 || 0,
                });

                vcuObd1Byte04Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte4 || 0,
                });

                vcuObd1Byte05Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte5 || 0,
                });

                vcuObd1Byte06Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte6 || 0,
                });

                vcuObd1Byte07Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd1?.byte7 || 0,
                });
                //#endregion

                //#region VcuObd2
                vcuObd2Byte08Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte8 || 0,
                });

                vcuObd2Byte09Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte9 || 0,
                });

                vcuObd2Byte10Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte10 || 0,
                });

                vcuObd2Byte11Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte11 || 0,
                });

                vcuObd2Byte12Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte12 || 0,
                });

                vcuObd2Byte13Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte13 || 0,
                });

                vcuObd2Byte14Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte14 || 0,
                });

                vcuObd2Byte15Datas.push({
                    x: time.valueOf(),
                    y: canData.vcuObd2?.byte15 || 0,
                });
                //#endregion

                //#region VcuOdo
                vcuOdo1Trip2EmptyDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuOdo1?.trip2Empty || 0,
                });

                vcuOdo1HvPackVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuOdo1?.hvPackVoltage || 0,
                });

                vcuOdo1HvCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuOdo1?.hvCurrent || 0,
                });

                vcuOdo1TotalChgKwhDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuOdo1?.totalChgKwh || 0,
                });

                vcuOdo2TripTotDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuOdo2?.tripTot || 0,
                });

                vcuOdo3TripTotUserDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuOdo3?.tripTotUser || 0,
                });
                //#endregion
            });

            //#region setChartBmsInfo1
            setChartBmsInfo1Volt({
                ...chartBmsInfo1Volt,
                data: bmsInfo1VoltDatas,
            });

            setChartBmsInfo1Curr({
                ...chartBmsInfo1Curr,
                data: bmsInfo1CurrDatas,
            });

            setChartBmsInfo1Soc({
                ...chartBmsInfo1Soc,
                data: bmsInfo1SocDatas,
            });

            setChartBmsInfo1Proximity({
                ...chartBmsInfo1Proximity,
                data: bmsInfo1ProximityDatas,
            });

            setChartBmsInfo1ChgStat({
                ...chartBmsInfo1ChgStat,
                data: bmsInfo1ChgStatDatas,
            });

            setChartBmsInfo1LowSoc({
                ...chartBmsInfo1LowSoc,
                data: bmsInfo1LowSocDatas,
            });

            setChartBmsInfo1Ready({
                ...chartBmsInfo1Ready,
                data: bmsInfo1ReadyDatas,
            });

            setChartBmsInfo1RlyDisChg({
                ...chartBmsInfo1RlyDisChg,
                data: bmsInfo1RlyDisChgDatas,
            });

            setChartBmsInfo1RlyChg({
                ...chartBmsInfo1RlyChg,
                data: bmsInfo1RlyChgDatas,
            });

            setChartBmsInfo1ChgFinish({
                ...chartBmsInfo1ChgFinish,
                data: bmsInfo1ChgFinishDatas,
            });

            setChartBmsInfo1ErrCode({
                ...chartBmsInfo1ErrCode,
                data: bmsInfo1ErrCodeDatas,
            });

            setChartBmsInfo1ErrLevel({
                ...chartBmsInfo1ErrLevel,
                data: bmsInfo1ErrLevelDatas,
            });

            setChartBmsInfo1AliveCnt({
                ...chartBmsInfo1AliveCnt,
                data: bmsInfo1AliveCntDatas,
            });
            //#endregion

            //#region setChartBmsInfo2
            setChartBmsInfo2CellVoltMax({
                ...chartBmsInfo2CellVoltMax,
                data: bmsInfo2CellVoltMaxDatas,
            });

            setChartBmsInfo2CellVoltMin({
                ...chartBmsInfo2CellVoltMin,
                data: bmsInfo2CellVoltMinDatas,
            });

            setChartBmsInfo2CellTempMax({
                ...chartBmsInfo2CellTempMax,
                data: bmsInfo2CellTempMaxDatas,
            });

            setChartBmsInfo2CellTempMin({
                ...chartBmsInfo2CellTempMin,
                data: bmsInfo2CellTempMinDatas,
            });

            setChartBmsInfo2DisChgCurrMax({
                ...chartBmsInfo2DisChgCurrMax,
                data: bmsInfo2DisChgCurrMaxDatas,
            });
            //#endregion

            //#region setChartBmsChgReq
            setChartBmsChgReqVoltMax({
                ...chartBmsChgReqVoltMax,
                data: bmsChgReqVoltMaxDatas,
            });

            setChartBmsChgReqCurrMax({
                ...chartBmsChgReqCurrMax,
                data: bmsChgReqCurrMaxDatas,
            });

            setChartBmsChgReqObcOn({
                ...chartBmsChgReqObcOn,
                data: bmsChgReqObcOnDatas,
            });

            setChartBmsChgReqObcMode({
                ...chartBmsChgReqObcMode,
                data: bmsChgReqObcModeDatas,
            });
            //#endregion

            //#region setChartBmsChgTime
            setChartBmsChgTimeDisChgCnt({
                ...chartBmsChgTimeDisChgCnt,
                data: bmsChgTimeDisChgCntDatas,
            });

            setChartBmsChgTimeChgCnt({
                ...chartBmsChgTimeChgCnt,
                data: bmsChgTimeChgCntDatas,
            });

            setChartBmsChgTimeSoh({
                ...chartBmsChgTimeSoh,
                data: bmsChgTimeSohDatas,
            });

            setChartBmsChgTimeQCurr({
                ...chartBmsChgTimeQCurr,
                data: bmsChgTimeQCurrDatas,
            });
            //#endregion

            //#region setChartObcChgInfo
            setChartObcChgInfoOutVolt({
                ...chartObcChgInfoOutVolt,
                data: obcChgInfoOutVoltDatas,
            });

            setChartObcChgInfoOutCurr({
                ...chartObcChgInfoOutCurr,
                data: obcChgInfoOutCurrDatas,
            });

            setChartObcChgInfoStsFailHw({
                ...chartObcChgInfoStsFailHw,
                data: obcChgInfoStsFailHwDatas,
            });

            setChartObcChgInfoStsOTP({
                ...chartObcChgInfoStsOTP,
                data: obcChgInfoStsOTPDatas,
            });

            setChartObcChgInfoStsInVolt({
                ...chartObcChgInfoStsInVolt,
                data: obcChgInfoStsInVoltDatas,
            });

            setChartObcChgInfoStsChkBat({
                ...chartObcChgInfoStsChkBat,
                data: obcChgInfoStsChkBatDatas,
            });

            setChartObcChgInfoStsComm({
                ...chartObcChgInfoStsComm,
                data: obcChgInfoStsCommDatas,
            });
            //#endregion

            //#region setChartMot1
            setChartMot1Rpm({
                ...chartMot1Rpm,
                data: mot1RpmDatas,
            });

            setChartMot1Curr({
                ...chartMot1Curr,
                data: mot1CurrDatas,
            });

            setChartMot1Volt({
                ...chartMot1Volt,
                data: mot1VoltDatas,
            });

            setChartMot1Err0({
                ...chartMot1Err0,
                data: mot1Err0Datas,
            });

            setChartMot1Err1({
                ...chartMot1Err1,
                data: mot1Err1Datas,
            });

            setChartMot1Err2({
                ...chartMot1Err2,
                data: mot1Err2Datas,
            });

            setChartMot1Err4({
                ...chartMot1Err4,
                data: mot1Err4Datas,
            });

            setChartMot1Err5({
                ...chartMot1Err5,
                data: mot1Err5Datas,
            });

            setChartMot1Err6({
                ...chartMot1Err6,
                data: mot1Err6Datas,
            });

            setChartMot1Err7({
                ...chartMot1Err7,
                data: mot1Err7Datas,
            });

            setChartMot1Err9({
                ...chartMot1Err9,
                data: mot1Err9Datas,
            });

            setChartMot1Err10({
                ...chartMot1Err10,
                data: mot1Err10Datas,
            });

            setChartMot1Err11({
                ...chartMot1Err11,
                data: mot1Err11Datas,
            });

            setChartMot1Err14({
                ...chartMot1Err14,
                data: mot1Err14Datas,
            });

            setChartMot1Err15({
                ...chartMot1Err15,
                data: mot1Err15Datas,
            });
            //#endregion

            //#region setChartMot2
            setChartMot2Throttle({
                ...chartMot2Throttle,
                data: mot2ThrottleDatas,
            });

            setChartMot2InverterTemp({
                ...chartMot2InverterTemp,
                data: mot2InverterTempDatas,
            });

            setChartMot2MotorTemp({
                ...chartMot2MotorTemp,
                data: mot2MotorTempDatas,
            });

            setChartMot2StsDnr({
                ...chartMot2StsDnr,
                data: mot2StsDnrDatas,
            });

            setChartMot2StsDnrFb({
                ...chartMot2StsDnrFb,
                data: mot2StsDnrFbDatas,
            });

            setChartMot2DiHallA({
                ...chartMot2DiHallA,
                data: mot2DiHallADatas,
            });

            setChartMot2DiHallB({
                ...chartMot2DiHallB,
                data: mot2DiHallBDatas,
            });

            setChartMot2DiHallC({
                ...chartMot2DiHallC,
                data: mot2DiHallCDatas,
            });

            setChartMot2BrakeSw({
                ...chartMot2BrakeSw,
                data: mot2BrakeSwDatas,
            });

            setChartMot2Backward({
                ...chartMot2Backward,
                data: mot2BackwardDatas,
            });

            setChartMot2Forward({
                ...chartMot2Forward,
                data: mot2ForwardDatas,
            });

            setChartMot2FootSw({
                ...chartMot2FootSw,
                data: mot2FootSwDatas,
            });

            setChartMot2BoostSw({
                ...chartMot2BoostSw,
                data: mot2BoostSwDatas,
            });
            //#endregion

            //#region setChartVcuVeh1
            setChartVcuVeh1Soc({
                ...chartVcuVeh1Soc,
                data: vcuVeh1SocDatas,
            });

            setChartVcuVeh1StateSys({
                ...chartVcuVeh1StateSys,
                data: vcuVeh1StateSysDatas,
            });

            setChartVcuVeh1Speed({
                ...chartVcuVeh1Speed,
                data: vcuVeh1SpeedDatas,
            });

            setChartVcuVeh1StateChg({
                ...chartVcuVeh1StateChg,
                data: vcuVeh1StateChgDatas,
            });

            setChartVcuVeh1StateFnr({
                ...chartVcuVeh1StateFnr,
                data: vcuVeh1StateFnrDatas,
            });

            setChartVcuVeh1CluDispOn({
                ...chartVcuVeh1CluDispOn,
                data: vcuVeh1CluDispOnDatas,
            });

            setChartVcuVeh1CluHighBeam({
                ...chartVcuVeh1CluHighBeam,
                data: vcuVeh1CluHighBeamDatas,
            });

            setChartVcuVeh1CluLowBeam({
                ...chartVcuVeh1CluLowBeam,
                data: vcuVeh1CluLowBeamDatas,
            });

            setChartVcuVeh1CluLTurn({
                ...chartVcuVeh1CluLTurn,
                data: vcuVeh1CluLTurnDatas,
            });

            setChartVcuVeh1CluRTurn({
                ...chartVcuVeh1CluRTurn,
                data: vcuVeh1CluRTurnDatas,
            });

            setChartVcuVeh1KeyFobCmd({
                ...chartVcuVeh1KeyFobCmd,
                data: vcuVeh1KeyFobCmdDatas,
            });

            setChartVcuVeh1Regen({
                ...chartVcuVeh1Regen,
                data: vcuVeh1RegenDatas,
            });

            setChartVcuVeh1SideBrake({
                ...chartVcuVeh1SideBrake,
                data: vcuVeh1SideBrakeDatas,
            });

            setChartVcuVeh1AuxBatLowWarn({
                ...chartVcuVeh1AuxBatLowWarn,
                data: vcuVeh1AuxBatLowWarnDatas,
            });

            setChartVcuVeh1DerMod({
                ...chartVcuVeh1DerMod,
                data: vcuVeh1DerModDatas,
            });

            setChartVcuVeh1LowSohAlarm({
                ...chartVcuVeh1LowSohAlarm,
                data: vcuVeh1LowSohAlarmDatas,
            });

            setChartVcuVeh1OverTempWarn({
                ...chartVcuVeh1OverTempWarn,
                data: vcuVeh1OverTempWarnDatas,
            });

            setChartVcuVeh1RepairLampOn({
                ...chartVcuVeh1RepairLampOn,
                data: vcuVeh1RepairLampOnDatas,
            });

            setChartVcuVeh1UdrTempProtect({
                ...chartVcuVeh1UdrTempProtect,
                data: vcuVeh1UdrTempProtectDatas,
            });

            setChartVcuVeh1WarningLampOn({
                ...chartVcuVeh1WarningLampOn,
                data: vcuVeh1WarningLampOnDatas,
            });

            setChartVcuVeh1ChgGunStatus({
                ...chartVcuVeh1ChgGunStatus,
                data: vcuVeh1ChgGunStatusDatas,
            });
            //#endregion

            //#region setChartVcuVeh2
            setChartVcuVeh2RawSpeed({
                ...chartVcuVeh2RawSpeed,
                data: vcuVeh2RawSpeedDatas,
            });

            setChartVcuVeh2IIgn({
                ...chartVcuVeh2IIgn,
                data: vcuVeh2IIgnDatas,
            });

            setChartVcuVeh2IAcc({
                ...chartVcuVeh2IAcc,
                data: vcuVeh2IAccDatas,
            });

            setChartVcuVeh2DihBrake({
                ...chartVcuVeh2DihBrake,
                data: vcuVeh2DihBrakeDatas,
            });

            setChartVcuVeh2DihHazard({
                ...chartVcuVeh2DihHazard,
                data: vcuVeh2DihHazardDatas,
            });

            setChartVcuVeh2OInvOn({
                ...chartVcuVeh2OInvOn,
                data: vcuVeh2OInvOnDatas,
            });

            setChartVcuVeh2OMainRly({
                ...chartVcuVeh2OMainRly,
                data: vcuVeh2OMainRlyDatas,
            });

            setChartVcuVeh2OBmsOn({
                ...chartVcuVeh2OBmsOn,
                data: vcuVeh2OBmsOnDatas,
            });

            setChartVcuVeh2OLpReverse({
                ...chartVcuVeh2OLpReverse,
                data: vcuVeh2OLpReverseDatas,
            });

            setChartVcuVeh2VoltBat({
                ...chartVcuVeh2VoltBat,
                data: vcuVeh2VoltBatDatas,
            });

            setChartVcuVeh2AiCharge({
                ...chartVcuVeh2AiCharge,
                data: vcuVeh2AiChargeDatas,
            });

            setChartVcuVeh2DihTurnL({
                ...chartVcuVeh2DihTurnL,
                data: vcuVeh2DihTurnLDatas,
            });

            setChartVcuVeh2DihTurnR({
                ...chartVcuVeh2DihTurnR,
                data: vcuVeh2DihTurnRDatas,
            });

            setChartVcuVeh2OLpTurnL({
                ...chartVcuVeh2OLpTurnL,
                data: vcuVeh2OLpTurnLDatas,
            });

            setChartVcuVeh2OLpTurnR({
                ...chartVcuVeh2OLpTurnR,
                data: vcuVeh2OLpTurnRDatas,
            });

            setChartVcuVeh2DihHazardL({
                ...chartVcuVeh2DihHazardL,
                data: vcuVeh2DihHazardLDatas,
            });
            //#endregion

            //#region setChartVcuObd1
            setChartVcuObd1Byte00({
                ...chartVcuObd1Byte00,
                data: vcuObd1Byte00Datas,
            });

            setChartVcuObd1Byte01({
                ...chartVcuObd1Byte01,
                data: vcuObd1Byte01Datas,
            });

            setChartVcuObd1Byte02({
                ...chartVcuObd1Byte02,
                data: vcuObd1Byte02Datas,
            });

            setChartVcuObd1Byte03({
                ...chartVcuObd1Byte03,
                data: vcuObd1Byte03Datas,
            });

            setChartVcuObd1Byte04({
                ...chartVcuObd1Byte04,
                data: vcuObd1Byte04Datas,
            });

            setChartVcuObd1Byte05({
                ...chartVcuObd1Byte05,
                data: vcuObd1Byte05Datas,
            });

            setChartVcuObd1Byte06({
                ...chartVcuObd1Byte06,
                data: vcuObd1Byte06Datas,
            });

            setChartVcuObd1Byte07({
                ...chartVcuObd1Byte07,
                data: vcuObd1Byte07Datas,
            });
            //#endregion

            //#region setChartVcuObd2
            setChartVcuObd2Byte08({
                ...chartVcuObd2Byte08,
                data: vcuObd2Byte08Datas,
            });

            setChartVcuObd2Byte09({
                ...chartVcuObd2Byte09,
                data: vcuObd2Byte09Datas,
            });

            setChartVcuObd2Byte10({
                ...chartVcuObd2Byte10,
                data: vcuObd2Byte10Datas,
            });

            setChartVcuObd2Byte11({
                ...chartVcuObd2Byte11,
                data: vcuObd2Byte11Datas,
            });

            setChartVcuObd2Byte12({
                ...chartVcuObd2Byte12,
                data: vcuObd2Byte12Datas,
            });

            setChartVcuObd2Byte13({
                ...chartVcuObd2Byte13,
                data: vcuObd2Byte13Datas,
            });

            setChartVcuObd2Byte14({
                ...chartVcuObd2Byte14,
                data: vcuObd2Byte14Datas,
            });

            setChartVcuObd2Byte15({
                ...chartVcuObd2Byte15,
                data: vcuObd2Byte15Datas,
            });
            //#endregion

            //#region setChartVcuOdo
            setChartVcuOdo1Trip2Empty({
                ...chartVcuOdo1Trip2Empty,
                data: vcuOdo1Trip2EmptyDatas,
            });

            setChartVcuOdo1HvPackVoltage({
                ...chartVcuOdo1HvPackVoltage,
                data: vcuOdo1HvPackVoltageDatas,
            });

            setChartVcuOdo1HvCurrent({
                ...chartVcuOdo1HvCurrent,
                data: vcuOdo1HvCurrentDatas,
            });

            setChartVcuOdo1TotalChgKwh({
                ...chartVcuOdo1TotalChgKwh,
                data: vcuOdo1TotalChgKwhDatas,
            });

            setChartVcuOdo2TripTot({
                ...chartVcuOdo2TripTot,
                data: vcuOdo2TripTotDatas,
            });

            setChartVcuOdo3TripTotUser({
                ...chartVcuOdo3TripTotUser,
                data: vcuOdo3TripTotUserDatas,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.category?.categoryName === Strings.CoCoKart) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataCoCoKart.CanData;

            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.BMS_INFO_1_VOLT_NAME:
            case Common.BMS_INFO_2_CELL_VOLT_MAX_NAME:
            case Common.BMS_INFO_2_CELL_VOLT_MIN_NAME:
            case Common.BMS_CHG_REQ_VOLT_MAX_NAME:
            case Common.OBC_CHG_INFO_OUT_VOLT_NAME:
            case Common.MOT1_VOLT_NAME:
            case Common.MOT2_THROTTLE_NAME:
            case Common.VEH2_VOLT_BATT_NAME:
            case Common.VEH2_AI_CHARGE_NAME:
            case Common.ODO1_HV_PACK_VOLTAGE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }

            case Common.BMS_INFO_1_CURR_NAME:
            case Common.BMS_INFO_2_DISCHG_CURR_MAX_NAME:
            case Common.BMS_CHG_REQ_CURR_MAX_NAME:
            case Common.BMS_CHG_TIME_QCURR_NAME:
            case Common.OBC_CHG_INFO_OUT_CURR_NAME:
            case Common.MOT1_CURR_NAME:
            case Common.ODO1_HV_CURRENT_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }

            case Common.BMS_INFO_1_SOC_NAME:
            case Common.BMS_CHG_TIME_SOH_NAME:
            case Common.VEH1_SOC_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PERCENT);
                break;
            }

            case Common.BMS_INFO_2_CELL_TEMP_MAX_NAME:
            case Common.BMS_INFO_2_CELL_TEMP_MIN_NAME:
            case Common.MOT2_INVERTER_TEMP_NAME:
            case Common.MOT2_MOTOR_TEMP_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CELSIUS);
                break;
            }

            case Common.MOT1_RPM_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_MOTOR_SPEED);
                break;
            }

            case Common.BMS_INFO_1_CHG_STAT_NAME: {
                convertTooltipString = convertChgStatRemark(value);
                break;
            }

            case Common.BMS_INFO_1_LOW_SOC_NAME: {
                convertTooltipString = convertLowSocRemark(value);
                break;
            }

            case Common.BMS_INFO_1_READY_NAME: {
                convertTooltipString = convertReadyRemark(value);
                break;
            }

            case Common.BMS_INFO_1_RLY_DIS_CHG_NAME:
            case Common.BMS_INFO_1_RLY_CHG_NAME: {
                convertTooltipString = convertRlyRemark(value);
                break;
            }

            case Common.BMS_INFO_1_CHG_FINISH_NAME: {
                convertTooltipString = convertChgFinishRemark(value);
                break;
            }

            case Common.BMS_INFO_1_ERR_LEVEL_NAME: {
                convertTooltipString = convertErrLevelRemark(value);
                break;
            }

            case Common.BMS_CHG_REQ_OBC_ON_NAME: {
                convertTooltipString = convertObcOnRemark(value);
                break;
            }

            case Common.BMS_CHG_REQ_OBC_MODE_NAME: {
                convertTooltipString = convertObcModeRemark(value);
                break;
            }

            case Common.OBC_CHG_INFO_STS_FAIL_HW_NAME: {
                convertTooltipString = convertStsFailHwRemark(value);
                break;
            }

            case Common.OBC_CHG_INFO_STS_OTP_NAME: {
                convertTooltipString = convertStsOTPRemark(value);
                break;
            }

            case Common.OBC_CHG_INFO_STS_IN_VOLT_NAME: {
                convertTooltipString = convertStsInVoltRemark(value);
                break;
            }

            case Common.OBC_CHG_INFO_STS_CHK_BAT_NAME: {
                convertTooltipString = convertStsChkBatRemark(value);
                break;
            }

            case Common.OBC_CHG_INFO_STS_COMM_NAME: {
                convertTooltipString = convertStsCommRemark(value);
                break;
            }

            case Common.VEH1_STATE_SYS_NAME: {
                convertTooltipString = convertStateSysRemark(value);
                break;
            }

            case Common.VEH1_SPEED_NAME:
            case Common.VEH2_RAW_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_SPEED);
                break;
            }

            case Common.VEH1_STATE_CHARGE_NAME: {
                convertTooltipString = convertStateChgRemark(value);
                break;
            }

            case Common.VEH1_STATE_FNR_NAME: {
                convertTooltipString = convertStateFnrRemark(value);
                break;
            }

            case Common.VEH1_CLU_DISPLAY_NAME:
            case Common.VEH1_CLU_HIGH_BEAM_NAME:
            case Common.VEH1_CLU_LOW_BEAM_NAME:
            case Common.VEH1_CLU_LEFT_TURN_NAME:
            case Common.VEH1_CLU_RIGHT_TURN_NAME:
            case Common.VEH1_KEY_FOB_CMD_NAME:
            case Common.VEH1_REGEN_NAME:
            case Common.VEH1_SIDE_BRAKE_NAME:
            case Common.VEH1_AUX_BATTERY_WARNING_NAME:
            case Common.VEH1_DERATING_MODE_NAME:
            case Common.VEH1_LOW_SOH_ALARM_NAME:
            case Common.VEH1_OVER_TEMP_WARNING_NAME:
            case Common.VEH1_REPAIR_LAMP_NAME:
            case Common.VEH1_UNDER_TEMP_PROTECT_NAME:
            case Common.VEH1_WARNING_LAMP_NAME: {
                convertTooltipString = convertOnOffRemark(value);
                break;
            }

            case Common.VEH1_CHARGING_GUN_STATUS_NAME: {
                convertTooltipString = convertChgGunStatusRemark(value);
                break;
            }

            case Common.ODO1_TRIP_2_EMPTY_NAME:
            case Common.ODO2_TRIP_TOT_NAME:
            case Common.ODO3_TRIP_TOT_USER_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_DISTANCE);
                break;
            }

            case Common.ODO1_TOTAL_CHARGE_KWH_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_POWER_H);
                break;
            }

            default:
                break;
        }
        return convertTooltipString;
    };

    return (
        <div>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <div>
                    <CanDataWrapper
                        title="BMS Info 1"
                        id="bmsInfo1"
                        panelOpen={bmsInfo1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsInfo1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsInfo1}
                        tableSelectRowKeys={selectedBmsInfo1}
                        onChangeTableSelect={onChangeBmsInfo1}
                        tableGetTooltips={getTabelTooltipBmsInfo1}
                        seriesChartDatas={seriesChartDatasBmsInfo1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsInfo1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="BMS Info 2"
                        id="bmsInfo2"
                        panelOpen={bmsInfo2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsInfo2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsInfo2}
                        tableSelectRowKeys={selectedBmsInfo2}
                        onChangeTableSelect={onChangeBmsInfo2}
                        tableGetTooltips={getTabelTooltipBmsInfo2}
                        seriesChartDatas={seriesChartDatasBmsInfo2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsInfo2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Bms Chg Req"
                        id="bmsChgReq"
                        panelOpen={bmsChgReqPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsChgReqPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsChgReq}
                        tableSelectRowKeys={selectedBmsChgReq}
                        onChangeTableSelect={onChangeBmsChgReq}
                        tableGetTooltips={getTabelTooltipBmsChgReq}
                        seriesChartDatas={seriesChartDatasBmsChgReq}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsChgReqChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Bms Chg Time"
                        id="bmsChgTime"
                        panelOpen={bmsChgTimePanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsChgTimePanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsChgTime}
                        tableSelectRowKeys={selectedBmsChgTime}
                        onChangeTableSelect={onChangeBmsChgTime}
                        tableGetTooltips={getTabelTooltipBmsChgTime}
                        seriesChartDatas={seriesChartDatasBmsChgTime}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsChgTimeChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Obc Chg Info"
                        id="obcChgInfo"
                        panelOpen={obcChgInfoPanelOpen}
                        onChangePanelOpen={(panelOpened) => setObcChgInfoPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataObcChgInfo}
                        tableSelectRowKeys={selectedObcChgInfo}
                        onChangeTableSelect={onChangeObcChgInfo}
                        tableGetTooltips={getTabelTooltipObcChgInfo}
                        seriesChartDatas={seriesChartDatasObcChgInfo}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setObcChgInfoChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Mot1"
                        id="mot1"
                        panelOpen={mot1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setMot1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataMot1}
                        tableSelectRowKeys={selectedMot1}
                        onChangeTableSelect={onChangeMot1}
                        tableGetTooltips={getTabelTooltipMot1}
                        seriesChartDatas={seriesChartDatasMot1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setMot1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Mot2"
                        id="mot2"
                        panelOpen={mot2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setMot2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataMot2}
                        tableSelectRowKeys={selectedMot2}
                        onChangeTableSelect={onChangeMot2}
                        tableGetTooltips={getTabelTooltipMot2}
                        seriesChartDatas={seriesChartDatasMot2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setMot2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU VEH 1"
                        id="vcuVeh1"
                        panelOpen={vcuVeh1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuVeh1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuVeh1}
                        tableSelectRowKeys={selectedVcuVeh1}
                        onChangeTableSelect={onChangeVcuVeh1}
                        tableGetTooltips={getTabelTooltipVcuVeh1}
                        seriesChartDatas={seriesChartDatasVcuVeh1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuVeh1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU VEH 2"
                        id="vcuVeh2"
                        panelOpen={vcuVeh1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuVeh2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuVeh2}
                        tableSelectRowKeys={selectedVcuVeh2}
                        onChangeTableSelect={onChangeVcuVeh2}
                        tableGetTooltips={getTabelTooltipVcuVeh2}
                        seriesChartDatas={seriesChartDatasVcuVeh2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuVeh2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU ODO 1"
                        id="vcuOdo1"
                        panelOpen={vcuOdo1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuOdo1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuOdo1}
                        tableSelectRowKeys={selectedVcuOdo1}
                        onChangeTableSelect={onChangeVcuOdo1}
                        tableGetTooltips={getTabelTooltipVcuOdo1}
                        seriesChartDatas={seriesChartDatasVcuOdo1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuOdo1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU ODO 2"
                        id="vcuOdo2"
                        panelOpen={vcuOdo2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuOdo2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuOdo2}
                        tableSelectRowKeys={selectedVcuOdo2}
                        onChangeTableSelect={onChangeVcuOdo2}
                        tableGetTooltips={getTabelTooltipVcuOdo2}
                        seriesChartDatas={seriesChartDatasVcuOdo2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuOdo2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU ODO 3"
                        id="vcuOdo3"
                        panelOpen={vcuOdo3PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuOdo3PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuOdo3}
                        tableSelectRowKeys={selectedVcuOdo3}
                        onChangeTableSelect={onChangeVcuOdo3}
                        tableGetTooltips={getTabelTooltipVcuOdo3}
                        seriesChartDatas={seriesChartDatasVcuOdo3}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuOdo3ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU OBD 1"
                        id="vcuObd1"
                        panelOpen={vcuObd1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuObd1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuObd1}
                        tableSelectRowKeys={selectedVcuObd1}
                        onChangeTableSelect={onChangeVcuObd1}
                        tableGetTooltips={getTabelTooltipVcuObd1}
                        seriesChartDatas={seriesChartDatasVcuObd1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuObd1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU OBD 2"
                        id="vcuObd2"
                        panelOpen={vcuObd2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuObd2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuObd2}
                        tableSelectRowKeys={selectedVcuObd2}
                        onChangeTableSelect={onChangeVcuObd2}
                        tableGetTooltips={getTabelTooltipVcuObd2}
                        seriesChartDatas={seriesChartDatasVcuObd2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuObd2ChartMounted(true)}
                    />
                </div>
            </Space>
        </div>
    );
}

export default CanDataCoCoKartViewer;
