import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiMaintenanceGuideList = () => {
    const requestAxiosApiMaintenanceGuideList = async (makerModelId: number) => {
        const response = await utilAxiosWithAuth().get(Request.MAINTENANCE_GUIDES_URL, { params: { makerModelId: makerModelId } });

        return response.data;
    };

    const {
        loading: loadingApiMaintenanceGuideList,
        error: errorApiMaintenanceGuideList,
        data: resultApiMaintenanceGuideList,
        execute: requestApiMaintenanceGuideList,
    } = useAsyncAxios(requestAxiosApiMaintenanceGuideList);

    useEffect(() => {
        if (resultApiMaintenanceGuideList === null) return;
        DEBUG && console.log("resultApiMaintenanceGuideList", resultApiMaintenanceGuideList);
    }, [resultApiMaintenanceGuideList]);

    useEffect(() => {
        if (errorApiMaintenanceGuideList === null) return;
        DEBUG && console.log("errorApiMaintenanceGuideList", errorApiMaintenanceGuideList);
    }, [errorApiMaintenanceGuideList]);

    return {
        loadingApiMaintenanceGuideList,
        errorApiMaintenanceGuideList,
        resultApiMaintenanceGuideList,
        requestApiMaintenanceGuideList,
    };
};

export const useRequestApiMaintenanceGuideRegister = () => {
    const requestAxiosApiMaintenanceGuideRegister = async (data: TypeDTO.MaintenanceGuideDto) => {
        const response = await utilAxiosWithAuth().post(Request.MAINTENANCE_GUIDE_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiMaintenanceGuideRegister,
        error: errorApiMaintenanceGuideRegister,
        data: resultApiMaintenanceGuideRegister,
        execute: requestApiMaintenanceGuideRegister,
    } = useAsyncAxios(requestAxiosApiMaintenanceGuideRegister);

    useEffect(() => {
        if (resultApiMaintenanceGuideRegister === null) return;
        DEBUG && console.log("resultApiMaintenanceGuideRegister", resultApiMaintenanceGuideRegister);
    }, [resultApiMaintenanceGuideRegister]);

    useEffect(() => {
        if (errorApiMaintenanceGuideRegister === null) return;
        DEBUG && console.log("errorApiMaintenancerGuideRegister", errorApiMaintenanceGuideRegister);
    }, [errorApiMaintenanceGuideRegister]);

    return {
        loadingApiMaintenanceGuideRegister,
        errorApiMaintenanceGuideRegister,
        resultApiMaintenanceGuideRegister,
        requestApiMaintenanceGuideRegister,
    };
};

export const useRequestApiMaintenanceGuideInfo = () => {
    const requestAxioApiMaintenanceGuideInfo = async (maintenanceGuideId: number) => {
        const response = await utilAxiosWithAuth().get(Request.MAINTENANCE_GUIDE_URL + maintenanceGuideId);

        return response.data;
    };

    const {
        loading: loadingApiMaintenanceGuideInfo,
        error: errorApiMaintenanceGuideInfo,
        data: resultApiMaintenanceGuideInfo,
        execute: requestApiMaintenanceGuideInfo,
    } = useAsyncAxios(requestAxioApiMaintenanceGuideInfo);

    useEffect(() => {
        if (resultApiMaintenanceGuideInfo === null) return;
        DEBUG && console.log("resultApiMaintenanceGuideInfo", resultApiMaintenanceGuideInfo);
    }, [resultApiMaintenanceGuideInfo]);

    useEffect(() => {
        if (errorApiMaintenanceGuideInfo === null) return;
        DEBUG && console.log("errorApiMaintenanceGuideInfo", errorApiMaintenanceGuideInfo);
    }, [errorApiMaintenanceGuideInfo]);

    return {
        loadingApiMaintenanceGuideInfo,
        errorApiMaintenanceGuideInfo,
        resultApiMaintenanceGuideInfo,
        requestApiMaintenanceGuideInfo,
    };
};

export const useRequestApiMaintenanceGuideUpdate = () => {
    const requestAxioApiMaintenanceGuideUpdate = async (maintenanceGuideId: number, data: TypeDTO.MaintenanceGuideDto) => {
        const response = await utilAxiosWithAuth().put(Request.MAINTENANCE_GUIDE_URL + maintenanceGuideId, data);

        return response.data;
    };

    const {
        loading: loadingApiMaintenanceGuideUpdate,
        error: errorApiMaintenanceGuideUpdate,
        data: resultApiMaintenanceGuideUpdate,
        execute: requestApiMaintenanceGuideUpdate,
    } = useAsyncAxios(requestAxioApiMaintenanceGuideUpdate);

    useEffect(() => {
        if (resultApiMaintenanceGuideUpdate === null) return;
        DEBUG && console.log("resultApiMaintenanceGuideUpdate", resultApiMaintenanceGuideUpdate);
    }, [resultApiMaintenanceGuideUpdate]);

    useEffect(() => {
        if (errorApiMaintenanceGuideUpdate === null) return;
        DEBUG && console.log("errorApiMaintenanceGuideUpdate", errorApiMaintenanceGuideUpdate);
    }, [errorApiMaintenanceGuideUpdate]);

    return {
        loadingApiMaintenanceGuideUpdate,
        errorApiMaintenanceGuideUpdate,
        resultApiMaintenanceGuideUpdate,
        requestApiMaintenanceGuideUpdate,
    };
};

export const useRequestApiMaintenanceGuideDelete = () => {
    const requestAxioApiMaintenanceGuideDelete = async (maintenanceGuideId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.MAINTENANCE_GUIDE_URL + maintenanceGuideId);

        return response.data;
    };

    const {
        loading: loadingApiMaintenanceGuideDelete,
        error: errorApiMaintenanceGuideDelete,
        data: resultApiMaintenanceGuideDelete,
        execute: requestApiMaintenanceGuideDelete,
    } = useAsyncAxios(requestAxioApiMaintenanceGuideDelete);

    useEffect(() => {
        if (resultApiMaintenanceGuideDelete === null) return;
        DEBUG && console.log("resultApiMaintenanceGuideDelete", resultApiMaintenanceGuideDelete);
    }, [resultApiMaintenanceGuideDelete]);

    useEffect(() => {
        if (errorApiMaintenanceGuideDelete === null) return;
        DEBUG && console.log("errorApiMaintenanceGuideDelete", errorApiMaintenanceGuideDelete);
    }, [errorApiMaintenanceGuideDelete]);

    return {
        loadingApiMaintenanceGuideDelete,
        errorApiMaintenanceGuideDelete,
        resultApiMaintenanceGuideDelete,
        requestApiMaintenanceGuideDelete,
    };
};

export const useRequestApiMaintenanceDetailList = () => {
    const requestAxiosApiMaintenanceDetailList = async (makerModelId: number) => {
        const response = await utilAxiosWithAuth().get(Request.MAINTENANCE_DETAILS_URL, { params: { makerModelId: makerModelId } });

        return response.data;
    };

    const {
        loading: loadingApiMaintenanceDetailList,
        error: errorApiMaintenanceDetailList,
        data: resultApiMaintenanceDetailList,
        execute: requestApiMaintenanceDetailList,
    } = useAsyncAxios(requestAxiosApiMaintenanceDetailList);

    useEffect(() => {
        if (resultApiMaintenanceDetailList === null) return;
        DEBUG && console.log("resultApiMaintenanceDetailList", resultApiMaintenanceDetailList);
    }, [resultApiMaintenanceDetailList]);

    useEffect(() => {
        if (errorApiMaintenanceDetailList === null) return;
        DEBUG && console.log("errorApiMaintenanceDetailList", errorApiMaintenanceDetailList);
    }, [errorApiMaintenanceDetailList]);

    return {
        loadingApiMaintenanceDetailList,
        errorApiMaintenanceDetailList,
        resultApiMaintenanceDetailList,
        requestApiMaintenanceDetailList,
    };
};
