import React, { useEffect, useState } from "react";
import { Input, Row, Space } from "antd";
import type { ColumnsType } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";

import styles from "./Filter.module.css";

function MaintenanceGuide({ maintenanceDetails }: { maintenanceDetails: Array<TypeDTO.MaintenanceDetailsDto> }) {
    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Row className="titleWrapper" justify={"space-between"}>
                <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                    {maintenanceDetails[0].maintenanceItem.maintenanceGuide.maintenanceName}
                </p>
            </Row>
            <div className={styles.searchFormWrapper} style={{ paddingBottom: 0 }}>
                {maintenanceDetails.map((detail) => {
                    return (
                        <div className={styles.searchForm} key={detail.maintenanceDetailsId}>
                            <span className="fs-md fc-font2">{detail.maintenanceItem.workItem}</span>
                            <Input.TextArea
                                className="disabled-input"
                                autoSize
                                disabled={true}
                                value={
                                    (detail.workDetails ? "[" + detail.workDetails + "]" : "-") +
                                    `\n` +
                                    (detail.checklist
                                        ? detail.checklist?.padStart(detail.checklist.length + 2, "· ")?.replace(/\\r\\n|\\n|\\r/gm, `\n` + "· ")
                                        : "-")
                                }
                            />
                        </div>
                    );
                })}
            </div>
        </Space>
    );
}

export default MaintenanceGuide;
