import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiWeather = () => {
    const requestAxiosApiWeather = async () => {
        const areaIds = [1, 3, 4, 6, 7, 8, 9, 10].join(",");
        const response = await utilAxiosWithAuth().get(Request.WEATHER_URL, { params: { areaIds } });

        return response.data;
    };

    const {
        loading: loadingApiWeather,
        error: errorApiWeather,
        data: resultApiWeather,
        execute: requestApiWeather,
    } = useAsyncAxios(requestAxiosApiWeather);

    useEffect(() => {
        if (!resultApiWeather) return;
        DEBUG && console.log("resultApiWeather", resultApiWeather);
    }, [resultApiWeather]);

    useEffect(() => {
        if (!errorApiWeather) return;
        DEBUG && console.log("errorApiWeather", errorApiWeather);
    }, [errorApiWeather]);

    return {
        loadingApiWeather,
        errorApiWeather,
        resultApiWeather,
        requestApiWeather,
    };
};
