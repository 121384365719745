import React from "react";
import { useNavigate } from "react-router";
import * as Common from "../../commons/common";
import styles from "./Error.module.css";

import Button from "antd/es/button/button";
import Result from "antd/es/result/index";

function PageError5XX() {
    const navigate = useNavigate();
    return (
        <div className={styles.pageWrapper}>
            <Result
                className={styles.result}
                status="500"
                title={<h2 className="fc-point">500</h2>}
                subTitle={
                    <span className="fw-rg fs-md fc-font1">
                        서버와 웹페이지 간에 통신이 불안정하여 접속이 불가합니다.
                        <br />
                        잠시 후 다시 시도해 주시기 바랍니다.
                    </span>
                }
                extra={
                    <Button className="btn-primary" onClick={() => navigate(Common.PAGE_DASHBOARD)}>
                        홈으로 이동
                    </Button>
                }
            />
        </div>
    );
}

export default PageError5XX;
