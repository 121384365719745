import { useEffect, useState } from "react";
import Spin from "antd/es/spin/index";
import Space from "antd/es/space/index";

import moment from "moment";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as RequestStat from "../../utils/requestApiStats";

import CanDataChart from "./CanDataChart";
import Search from "../browser/Search";

type CellTempStatsDto = {
    statsTime: string;
    maxTempMax: number;
    maxTempAvg: number;
    minTempMin: number;
    minTempAvg: number;
};

function ChartBatteryTemp({ carId }: { carId?: number }) {
    const exceptionMinTemp = -40;
    const [stats, setStats] = useState<Array<CellTempStatsDto>>([]);
    const [series, setSeries] = useState<TypeUtils.ChartData[]>([]);
    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>([
        {
            id: "searchDate",
            span: 12,
            title: String.searchDate,
            type: "rangePicker",
            dateFormat: Common.FORMAT_DATE_TIME,
        },
        {
            id: "radio",
            span: 12,
            title: String.unit,
            type: "radio",
            options: [
                { value: "HOUR", label: "시간" },
                { value: "MINUTE", label: "분" },
            ],
            defaultValue: "HOUR",
        },
    ]);

    const options = {
        colors: ["#0A84FF", "#5E5CE6", "#BF5AF2", "#FF2D55", "#FF453A", "#FF9F0A", "#FFD60A", "#32D74B", "#64D2FF"],
        chart: {
            height: "350px",
            type: "line",
            group: "batteryTemperature",
            toolbar: {
                show: true,
                tools: {
                    download: false,
                    selection: false,
                    pan: false,
                    zoomin: true,
                    zoomout: true,
                },
                offsetY: -4,
            },
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    colors: "var(--font2)",
                },
                labels: {
                    style: {
                        colors: "var(--font2)",
                    },
                    formatter: function (val: number) {
                        return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (value: number) {
                    return value?.toFixed(2) + Common.UNIT_CELSIUS;
                },
            },
        },
        stroke: {
            width: 2,
        },
        legend: {
            showForSingleSeries: false,
            fontFamily: "Pretendard-Regular",
            fontSize: "14px",
        },
    };

    const { loadingApiStatsCellTemperature, requestApiStatsCellTemperature, resultApiStatsCellTemperature } =
        RequestStat.useRequestApiStatsCellTemperature();

    useEffect(() => {
        if (!resultApiStatsCellTemperature) return;

        setStats(resultApiStatsCellTemperature.cellTempStats);
    }, [resultApiStatsCellTemperature]);

    useEffect(() => {
        if (!stats) return;

        const maxTempMaxList: TypeUtils.ChartElement[] = [];
        const maxTempAvgList: TypeUtils.ChartElement[] = [];
        const minTempAvgList: TypeUtils.ChartElement[] = [];
        const minTempMinList: TypeUtils.ChartElement[] = [];

        stats.forEach((element) => {
            const time = moment(element.statsTime, Common.FORMAT_DATE_TIME_MILLISECOND);

            maxTempMaxList.push({
                x: time.valueOf(),
                y: element.maxTempMax,
            });
            maxTempAvgList.push({
                x: time.valueOf(),
                y: element.maxTempAvg,
            });
            minTempAvgList.push({
                x: time.valueOf(),
                y: element.minTempAvg,
            });
            element.minTempMin !== exceptionMinTemp &&
                minTempMinList.push({
                    x: time.valueOf(),
                    y: element.minTempMin,
                });
        });

        setSeries([
            {
                name: "maxTempMax",
                data: maxTempMaxList,
            },
            {
                name: "maxTempAvg",
                data: maxTempAvgList,
            },
            {
                name: "minTempAvg",
                data: minTempAvgList,
            },
            {
                name: "minTempMin",
                data: minTempMinList,
            },
        ]);
    }, [stats]);

    const onSearch = (value: any) => {
        const searchValue = { ...value, period: value.radio };
        delete searchValue.radio;

        carId && searchValue?.period && requestApiStatsCellTemperature(carId, searchValue);
    };

    return (
        <Space size={16} direction="vertical" style={{ width: "100%" }}>
            <Search title="배터리 온도" values={search} onSearch={(value) => onSearch(value)} onClear={(value) => onSearch(value)} />
            {loadingApiStatsCellTemperature ? (
                <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin />
                </div>
            ) : (
                <CanDataChart series={series} option={options} />
            )}
        </Space>
    );
}

export default ChartBatteryTemp;
