import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestAuthApplicationRegister = () => {
    const requestAxiosAuthApplicationRegister = async (data: TypeDTO.ApplicationDto) => {
        const response = await utilAxiosWithAuth().post(Request.APPLICATION_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingAuthApplicationRegister,
        error: errorAuthApplicationRegister,
        data: resultAuthApplicationRegister,
        execute: requestAuthApplicationRegister,
    } = useAsyncAxios(requestAxiosAuthApplicationRegister);

    useEffect(() => {
        if (resultAuthApplicationRegister === null) return;
        DEBUG && console.log("resultAuthApplicationRegister", resultAuthApplicationRegister);
    }, [resultAuthApplicationRegister]);

    useEffect(() => {
        if (errorAuthApplicationRegister === null) return;
        DEBUG && console.log("errorAuthApplicationRegister", errorAuthApplicationRegister);
    }, [errorAuthApplicationRegister]);

    return {
        loadingAuthApplicationRegister,
        errorAuthApplicationRegister,
        resultAuthApplicationRegister,
        requestAuthApplicationRegister,
    };
};

export const useRequestAuthApplicationList = () => {
    const requestAxiosAuthApplicationList = async () => {
        const response = await utilAxiosWithAuth().get(Request.APPLICATIONS_URL);

        return response.data;
    };

    const {
        loading: loadingAuthApplicationList,
        error: errorAuthApplicationList,
        data: resultAuthApplicationList,
        execute: requestAuthApplicationList,
    } = useAsyncAxios(requestAxiosAuthApplicationList);

    useEffect(() => {
        if (resultAuthApplicationList === null) return;
        DEBUG && console.log("resultAuthApplicationList", resultAuthApplicationList);
    }, [resultAuthApplicationList]);

    useEffect(() => {
        if (errorAuthApplicationList === null) return;
        DEBUG && console.log("errorAuthApplicationList", errorAuthApplicationList);
    }, [errorAuthApplicationList]);

    return {
        loadingAuthApplicationList,
        errorAuthApplicationList,
        resultAuthApplicationList,
        requestAuthApplicationList,
    };
};

export const useRequestAuthApplicationInfo = () => {
    const requestAxiosAuthApplicationInfo = async (applicationName: string) => {
        const response = await utilAxiosWithAuth().get(Request.APPLICATION_URL + applicationName);

        return response.data;
    };

    const {
        loading: loadingAuthApplicationInfo,
        error: errorAuthApplicationInfo,
        data: resultAuthApplicationInfo,
        execute: requestAuthApplicationInfo,
    } = useAsyncAxios(requestAxiosAuthApplicationInfo);

    useEffect(() => {
        if (resultAuthApplicationInfo === null) return;
        DEBUG && console.log("resultAuthApplicationInfo", resultAuthApplicationInfo);
    }, [resultAuthApplicationInfo]);

    useEffect(() => {
        if (errorAuthApplicationInfo === null) return;
        DEBUG && console.log("errorAuthApplicationInfo", errorAuthApplicationInfo);
    }, [errorAuthApplicationInfo]);

    return {
        loadingAuthApplicationInfo,
        errorAuthApplicationInfo,
        resultAuthApplicationInfo,
        requestAuthApplicationInfo,
    };
};

export const useRequestAuthApplicationUpdate = () => {
    const requestAxiosAuthApplicationUpdate = async (applicationName: string, data: TypeDTO.ApplicationDto) => {
        const response = await utilAxiosWithAuth().put(Request.APPLICATION_URL + applicationName, data);

        return response.data;
    };

    const {
        loading: loadingAuthApplicationUpdate,
        error: errorAuthApplicationUpdate,
        data: resultAuthApplicationUpdate,
        execute: requestAuthApplicationUpdate,
    } = useAsyncAxios(requestAxiosAuthApplicationUpdate);

    useEffect(() => {
        if (resultAuthApplicationUpdate === null) return;
        DEBUG && console.log("resultAuthApplicationUpdate", resultAuthApplicationUpdate);
    }, [resultAuthApplicationUpdate]);

    useEffect(() => {
        if (errorAuthApplicationUpdate === null) return;
        DEBUG && console.log("errorAuthApplicationUpdate", errorAuthApplicationUpdate);
    }, [errorAuthApplicationUpdate]);

    return {
        loadingAuthApplicationUpdate,
        errorAuthApplicationUpdate,
        resultAuthApplicationUpdate,
        requestAuthApplicationUpdate,
    };
};

export const useRequestAuthApplicationDelete = () => {
    const requestAxiosAuthApplicationDelete = async (applicationName: string) => {
        const response = await utilAxiosWithAuth().delete(Request.APPLICATION_URL + applicationName);

        return response.data;
    };

    const {
        loading: loadingAuthApplicationDelete,
        error: errorAuthApplicationDelete,
        data: resultAuthApplicationDelete,
        execute: requestAuthApplicationDelete,
    } = useAsyncAxios(requestAxiosAuthApplicationDelete);

    useEffect(() => {
        if (resultAuthApplicationDelete === null) return;
        DEBUG && console.log("resultAuthApplicationDelete", resultAuthApplicationDelete);
    }, [resultAuthApplicationDelete]);

    useEffect(() => {
        if (errorAuthApplicationDelete === null) return;
        DEBUG && console.log("errorAuthApplicationDelete", errorAuthApplicationDelete);
    }, [errorAuthApplicationDelete]);

    return {
        loadingAuthApplicationDelete,
        errorAuthApplicationDelete,
        resultAuthApplicationDelete,
        requestAuthApplicationDelete,
    };
};
