import * as React from "react";
import Space from "antd/es/space/index";
import Statistic from "antd/es/statistic/index";
import CountUp from "react-countup";

import styles from "../../pages/browser/Dashboard.module.css";

type summaryType = {
    title: string;
    firstText?: number;
    lastText?: number;
    unit: string;
    icon?: { first: any; second: any };
    box: any;
};

const DashboardSummary = ({
    summaries,
    isStats,
    height,
    onClick,
}: {
    summaries: Array<summaryType>;
    isStats?: boolean;
    height?: string;
    onClick?: (summary: string) => void;
}) => {
    const formatter = (value: any) => <CountUp end={value} separator="," />;

    return (
        <div className={styles.summaryWrapper}>
            {summaries.map((summary) => (
                <div
                    key={summary.title}
                    className={styles.summary}
                    style={{ height: height, cursor: onClick ? "pointer" : "normal" }}
                    onClick={() => onClick && onClick(summary.title)}
                >
                    <Space direction="vertical" size={4}>
                        <span className="fw-bd fs-sm fc-font2" style={{ wordBreak: "keep-all" }}>
                            {summary.title}
                        </span>
                        {isStats ? (
                            <Space className={styles.summaryTextWrapper} size={4}>
                                <Statistic className={styles.statistic} suffix={summary.unit} value={summary.firstText} formatter={formatter} />
                            </Space>
                        ) : (
                            <div className={styles.summaryTextWrapper}>
                                <div>
                                    {summary.icon?.first}
                                    <h3>{summary.firstText || 0}</h3> <p className="fw-rg fs-md">{summary.unit}</p>
                                </div>
                                {summary.lastText !== undefined && (
                                    <>
                                        <p className="fw-rg fs-md"> / </p>
                                        <div>
                                            {summary.icon?.second}
                                            <h3>{summary.lastText || 0}</h3>
                                            <p className="fw-rg fs-md">{summary.unit}</p>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </Space>
                    {summary.box}
                </div>
            ))}
        </div>
    );
};

export default DashboardSummary;
