import { PropsWithChildren, useState } from "react";
import Layout from "antd/es/layout/index";
import LayoutMenu from "./LayoutMenu";
import styles from "./LayoutDefault.module.css";

const { Sider, Content } = Layout;

function LayoutDefault(props: PropsWithChildren) {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Layout className={styles.layout}>
            <Sider
                className={styles.sider}
                width="clamp(289px, 16vw, 313px)"
                breakpoint="xs"
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <LayoutMenu />
            </Sider>

            <Layout>
                <Content className={styles.content}>{props.children}</Content>
            </Layout>
        </Layout>
    );
}

export default LayoutDefault;
