import { createContext, useState, useContext } from "react";

export const AlertType = {
    NONE: "NONE",

    SUCCESS: "SUCCESS",
    ERROR: "ERROR",

    NONEXISTENT_MEMBER: "NONEXISTENT_MEMBER",
    ALREADY_EXISTENT_MEMBER: "ALREADY_EXISTENT_MEMBER",
    ACCOUNT_DISABLED: "ACCOUNT_DISABLED",
    ACCOUNT_LOCKED: "ACCOUNT_LOCKED",
    ACCOUNT_REJECTED: "ACCOUNT_REJECTED",
    ACCOUNT_WAITING: "ACCOUNT_WAITING",
    ALREADY_ASSIGNED_ALARM: "ALREADY_ASSIGNED_ALARM",
    NONEXISTENT_ALARM: "NONEXISTENT_ALARM",
    NONEXISTENT_ALARM_RECEIVING_GROUP: "NONEXISTENT_ALARM_RECEIVING_GROUP",
    ALREADY_EXISTENT_APPLICATION: "ALREADY_EXISTENT_APPLICATION",
    NONEXISTENT_APPLICATION: "NONEXISTENT_APPLICATION",
    ALREADY_EXISTENT_AREA: "ALREADY_EXISTENT_AREA",
    NONEXISTENT_AREA: "NONEXISTENT_AREA",
    CANNOT_DELETE_CAR: "CANNOT_DELETE_CAR",
    ALREADY_EXISTENT_CAR: "ALREADY_EXISTENT_CAR",
    NONEXISTENT_CAR: "NONEXISTENT_CAR",
    ALREADY_EXISTENT_CAR_NUMBER_HISTORY: "ALREADY_EXISTENT_CAR_NUMBER_HISTORY",
    NONEXISTENT_CAR_NUMBER_HISTORY: "NONEXISTENT_CAR_NUMBER_HISTORY",
    ALREADY_EXISTENT_CATEGORY: "ALREADY_EXISTENT_CATEGORY",
    NONEXISTENT_CATEGORY: "NONEXISTENT_CATEGORY",
    UNDELETABLE_CUSTOMER_MANAGER: "UNDELETABLE_CUSTOMER_MANAGER",
    ALREADY_EXISTENT_CUSTOMER: "ALREADY_EXISTENT_CUSTOMER",
    ALREADY_EXISTENT_CUSTOMER_MANAGER: "ALREADY_EXISTENT_CUSTOMER_MANAGER",
    NONEXISTENT_CUSTOMER_MANAGER: "NONEXISTENT_CUSTOMER_MANAGER",
    NONEXISTENT_CUSTOMER: "NONEXISTENT_CUSTOMER",
    NONEXISTENT_DATA_BUKGI: "NONEXISTENT_DATA_BUKGI",
    CANNOT_DELETE_DATA: "CANNOT_DELETE_DATA",
    UPLOAD_FAILURE: "UPLOAD_FAILURE",
    NONEXISTENT_DATA_YAXING: "NONEXISTENT_DATA_YAXING",
    ALREADY_EXISTENT_DOOR_MODULE: "ALREADY_EXISTENT_DOOR_MODULE",
    NONEXISTENT_DOOR_MODULE: "NONEXISTENT_DOOR_MODULE",
    NONEXISTENT_DOOR_STATUS_HISTORY: "NONEXISTENT_DOOR_STATUS_HISTORY",
    NONEXISTENT_FILE: "NONEXISTENT_FILE",
    INACCESSIBLE_DATA: "INACCESSIBLE_DATA",
    INVALID_FILE: "INVALID_FILE",
    INVALID_PARAM: "INVALID_PARAM",
    NONEXISTENT_ISSUE_ACTION: "NONEXISTENT_ISSUE_ACTION",
    NONEXISTENT_ISSUE: "NONEXISTENT_ISSUE",
    ALREADY_EXISTENT_MAINTENANCE_GUIDE: "ALREADY_EXISTENT_MAINTENANCE_GUIDE",
    NONEXISTENT_MAINTENANCE_GUIDE: "NONEXISTENT_MAINTENANCE_GUIDE",
    ALREADY_EXISTENT_MAKER: "ALREADY_EXISTENT_MAKER",
    UNDELETABLE_MAKER_MODEL: "UNDELETABLE_MAKER_MODEL",
    ALREADY_EXISTENT_MAKER_MODEL: "ALREADY_EXISTENT_MAKER_MODEL",
    NONEXISTENT_MAKER_MODEL: "NONEXISTENT_MAKER_MODEL",
    NONEXISTENT_MAKER: "NONEXISTENT_MAKER",
    OPEN_WEATHER_ERROR: "OPEN_WEATHER_ERROR",
    ALREADY_EXISTENT_SUB_BATTERY_HISTORY: "ALREADY_EXISTENT_SUB_BATTERY_HISTORY",
    NONEXISTENT_SUB_BATTERY_HISTORY: "NONEXISTENT_SUB_BATTERY_HISTORY",
    CANT_DELETE_SW_VER: "CANT_DELETE_SW_VER",
    ALREADY_EXISTENT_SW_VER: "ALREADY_EXISTENT_SW_VER",
    NONEXISTENT_SW_VER: "NONEXISTENT_SW_VER",
    ALREADY_EXISTENT_TERMINAL: "ALREADY_EXISTENT_TERMINAL",
    ALREADY_EXISTENT_TERMINAL_MODEM: "ALREADY_EXISTENT_TERMINAL_MODEM",
    NONEXISTENT_TERMINAL_MODEM: "NONEXISTENT_TERMINAL_MODEM",
    NONEXISTENT_TERMINAL: "NONEXISTENT_TERMINAL",
    UNAUTHORIZED: "UNAUTHORIZED",
    ALREADY_EXISTENT_USER: "ALREADY_EXISTENT_USER",
    NONEXISTENT_USER: "NONEXISTENT_USER",
    FILE_NOT_FOUND: "FILE_NOT_FOUND",
    ERR_NETWORK: "ERR_NETWORK",
    ECONNABORTED: "ECONNABORTED",
};

const AlertContext = createContext({
    type: AlertType.NONE,
    title: "",
    message: "",
    setAlert: (type: string, title: string, message: string) => {
        return;
    },
});

export const AlertProvider = (props: any) => {
    const [type, setType] = useState(AlertType.NONE);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");

    const setAlert = (type: string, title: string, message: string) => {
        setType(type);
        setTitle(title);
        setMessage(message);
    };

    return (
        <AlertContext.Provider
            value={{
                type,
                title,
                message,
                setAlert,
            }}
        >
            {props.children}
        </AlertContext.Provider>
    );
};

export function useAlert() {
    const context = useContext(AlertContext);
    if (context === undefined) {
        throw new Error("useAlert must be used within a AlertProvider");
    }

    return context;
}
