import { useEffect, useState } from "react";
import { Form, Row, Select, Space, Button, Tabs } from "antd";
import { FloatingPanel, SideBar } from "antd-mobile";
import { Header } from "antd/es/layout/layout";
import { EditOutlined, HomeOutlined, LeftOutlined, RedoOutlined } from "@ant-design/icons";
import CustomForm, { viewType } from "../../components/mobile/CustomForm";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as RequestMaintenanceGuide from "../../utils/requestApiMaintenanceGuide";
import * as RequestMaker from "../../utils/requestApiMaker";
import * as RequestMakerModel from "../../utils/requestApiMakerModel";

import Divider from "antd/es/divider/index";
import MaintenanceGuide from "../../components/mobile/MaintenanceGuide";
import CustomCard from "../../components/mobile/CustomCard";
import styles from "../../components/mobile/Filter.module.css";

type MaintenanceDetailByGuide = {
    [key: number]: Array<TypeDTO.MaintenanceDetailsDto>;
};

function PageMaintenanceGuide() {
    const { Option } = Select;
    const [form] = Form.useForm();

    const [type, setType] = useState<viewType>("view");
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>([]);
    const [models, setModels] = useState<Array<TypeDTO.MakerModelDto>>([]);
    const [selectedMakerId, setSelectedMakerId] = useState<number>();
    const [selectedMakerModelId, setSelectedMakerModelId] = useState<number>();
    const [selectedMakerModel, setSelectedMakerModel] = useState<TypeDTO.MakerModelDto>();

    const [formMakerId, setFormMakerId] = useState<number>();
    const [formMakerModelId, setFormMakerModelId] = useState<number>();

    const [maintenanceDetails, setMaintenanceDetails] = useState<Array<TypeDTO.MaintenanceDetailsDto>>([]);
    const [maintenanceDetailByGuide, setMaintenanceDetailByGuide] = useState<MaintenanceDetailByGuide>({});

    const [details, setDetails] = useState<any>();
    const [tabs, setTabs] = useState<any>();

    useEffect(() => {
        requestApiMakerList();
        requestApiMakerModelList();
    }, []);

    useEffect(() => {
        selectedMakerModelId ? requestApiMaintenanceDetailList(selectedMakerModelId) : setMaintenanceDetails([]);
        setSelectedMakerModel(models.find((model) => model.makerModelId === selectedMakerModelId));
    }, [selectedMakerId]);

    useEffect(() => {
        selectedMakerModelId ? requestApiMaintenanceDetailList(selectedMakerModelId) : setMaintenanceDetails([]);
        setSelectedMakerModel(models.find((model) => model.makerModelId === selectedMakerModelId));
    }, [selectedMakerModelId]);

    //maker
    const { requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    useEffect(() => {
        const skywell = makers.find((maker) => maker?.makerModels.find((model) => model.makerModelName === "LIONNE"));

        if (skywell) {
            setSelectedMakerId(skywell.makerId);
        }
    }, [makers]);

    //model
    const { loadingApiMakerModelList, requestApiMakerModelList, resultApiMakerModelList } = RequestMakerModel.useRequestApiMakerModelList();

    useEffect(() => {
        if (!resultApiMakerModelList) return;

        setModels(resultApiMakerModelList.makerModels);
    }, [resultApiMakerModelList]);

    useEffect(() => {
        const lionnes = models.filter((model) => model?.makerModelName === "LIONNE");

        if (lionnes.length > 0) {
            setSelectedMakerModelId(lionnes[0].makerModelId);
        }
    }, [models]);

    //guide list
    const { loadingApiMaintenanceDetailList, requestApiMaintenanceDetailList, resultApiMaintenanceDetailList } =
        RequestMaintenanceGuide.useRequestApiMaintenanceDetailList();

    useEffect(() => {
        if (!resultApiMaintenanceDetailList) return;
        setMaintenanceDetails(resultApiMaintenanceDetailList.maintenanceDetails);
    }, [resultApiMaintenanceDetailList]);

    useEffect(() => {
        const maintenanceDetailsByGuide: MaintenanceDetailByGuide = {};

        maintenanceDetails.forEach((detail) => {
            const guideId = detail.maintenanceItem.maintenanceGuide.maintenanceGuideId;
            if (!maintenanceDetailsByGuide[guideId]) maintenanceDetailsByGuide[guideId] = [];
            maintenanceDetailsByGuide[guideId].push(detail);
        });

        setMaintenanceDetailByGuide(maintenanceDetailsByGuide);
    }, [maintenanceDetails]);

    const contents: Array<TypeUtils.formType> = [
        {
            name: ["makerModel", "maker", "makerId"],
            label: String.maker,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="제조사를 선택해 주세요."
                    onChange={(value) => {
                        setFormMakerId(value);
                        !value && setSelectedMakerModelId(undefined);
                        form.setFieldValue(["makerModel", "makerModelId"], undefined);
                    }}
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                    defaultValue={selectedMakerId}
                >
                    {makers?.map((maker) => (
                        <Option key={maker.makerId} value={maker.makerId}>
                            {maker.makerName}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: ["makerModel", "makerModelId"],
            label: String.carModel,
            required: true,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="제조사 입력 후 선택 가능"
                    optionFilterProp="children"
                    onChange={(value) => {
                        setFormMakerModelId(value);
                    }}
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view" || !selectedMakerId}
                    defaultValue={selectedMakerModelId}
                >
                    {makers
                        ?.find((maker) => (formMakerId ? maker.makerId === formMakerId : maker.makerId === selectedMakerId))
                        ?.makerModels?.map((model) => (
                            <Option key={model.makerModelId} value={model.makerModelId}>
                                {model.makerModelName}
                            </Option>
                        ))}
                </Select>
            ),
        },
    ];

    const cardValue = [
        {
            icon: <HomeOutlined />,
            value: selectedMakerModel?.maker?.makerName ? selectedMakerModel.maker.makerName : "-",
        },
    ];

    useEffect(() => {
        if (maintenanceDetailByGuide === undefined) return;

        const detailArray: any = [];
        Object.keys(maintenanceDetailByGuide).map((detail) => {
            detailArray.push(maintenanceDetailByGuide[Number(detail)]);
        });

        setTabs(
            detailArray.map((detail: any, index: number) => {
                const label = detail[0].maintenanceItem.maintenanceGuide.maintenanceName;
                return {
                    key: index,
                    label: label.replace("정비", ""),
                    children: <MaintenanceGuide key={index} maintenanceDetails={detail} />,
                };
            })
        );
    }, [maintenanceDetailByGuide]);

    return (
        <div className="pageWrapper">
            {type === "edit" && (
                <FloatingPanel anchors={[window.innerHeight]} className="fullPanel" handleDraggingOfContent={false}>
                    <div className={styles.selectorOverlay}>
                        <Header className={styles.overlayHeader}>
                            <LeftOutlined
                                className={styles.menuIcon}
                                onClick={() => {
                                    setType("view");
                                }}
                            />
                            <p className="fw-bd fs-lg fc-font1">기준 모델 변경</p> <LeftOutlined style={{ opacity: 0 }} />
                        </Header>
                        <div className={styles.overlayContent}>
                            <CustomForm form={form} type={type} initialValues={selectedMakerModel} contents={[{ forms: contents }]} />
                        </div>
                        <div className={styles.overlayFooter}>
                            <span
                                className="fw-bd fs-md fc-font2 btn-text"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    form.setFieldValue(["makerModel", "maker", "makerId"], undefined);
                                    form.setFieldValue(["makerModel", "makerModelId"], undefined);
                                }}
                            >
                                <RedoOutlined />
                                초기화
                            </span>
                            <Button
                                className="btn-primary"
                                onClick={() => {
                                    setType("view");
                                    setSelectedMakerId(formMakerId);
                                    setSelectedMakerModelId(formMakerModelId);
                                }}
                            >
                                저장하기
                            </Button>
                        </div>
                    </div>
                </FloatingPanel>
            )}

            <Space direction="vertical">
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Row className="titleWrapper" justify={"space-between"}>
                        <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                            기준 모델
                        </p>
                        <span
                            className={`${styles.filterBtn} fc-font3 fw-bd fs-sm btn-text`}
                            onClick={() => {
                                setType("edit");
                            }}
                        >
                            <EditOutlined /> {String.update}
                        </span>
                    </Row>
                    <CustomCard title={selectedMakerModel?.makerModelName} contents={cardValue} />
                </Space>
                <Tabs className="maintenanceTab" items={tabs} />
            </Space>
        </div>
    );
}

export default PageMaintenanceGuide;
