import { useEffect, useState } from "react";
import Button from "antd/es/button/button";
import Modal from "antd/es/modal/index";
import Form from "antd/es/form/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";

import * as Common from "../../commons/common";
import * as RequestCar from "../../utils/requestApiCar";
import * as RequestTerminal from "../../utils/requestApiTerminal";
import * as RequestModem from "../../utils/requestApiModem";
import * as RequestDoorModule from "../../utils/requestApiDoorModule";
import * as RequestDoorModules from "../../utils/requestApiDoorModules";

import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";

import CustomForm, { viewType } from "./CustomForm";
import SubBatteryHistory from "../common/SubBatteryHistory";
import DoorModuleHistory from "../common/DoorModuleHistory";
import dayjs from "dayjs";

import DoorModuleModal from "../common/DoorModuleModal";

const TabDevices = ({ carId, onChange }: { carId?: number; onChange?: (viewType: boolean) => void }) => {
    const userDetails = useAuthState();
    const alert = useAlert();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [type, setType] = useState<viewType>("view");
    const [car, setCar] = useState<TypeDTO.CarDto>();
    const [terminals, setTerminals] = useState<Array<TypeDTO.TerminalDto>>();
    const [modems, setModems] = useState<Array<TypeDTO.TerminalModemDto>>();
    const [doorModules, setDoorModules] = useState<Array<TypeDTO.DoorModuleDto>>();
    const [forced, setForced] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
        onChange && onChange(type === "view");
    }, [type]);

    useEffect(() => {
        if (carId) {
            requestApiCarInfo(carId);
            requestApiTerminalList();
            requestApiModemList();
            requestApiDoorModuleList();
        }
    }, [carId]);

    const getValueByKey = (key: string) => {
        //TODO: 합치기
        if (key in Utils.carrier) {
            return Utils.carrier[key as keyof typeof Utils.carrier];
        }
        return "";
    };

    const terminalContents: Array<TypeUtils.formType> = [
        {
            key: "terminalSerialNumber",
            name: ["terminal", "serialNumber"],
            label: String.serialNumber,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="단말기를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                    onChange={(value) => {
                        const defaultTerminal = terminals?.find((terminal) => terminal.serialNumber === car?.terminal?.serialNumber);
                        const terminal = terminals?.find((terminal) => terminal.serialNumber === value);
                        if (terminal?.car) {
                            return Modal.confirm({
                                title: "등록 해제",
                                content: (
                                    <>
                                        <p className="fw-rg fs-md" style={{ margin: 0 }}>
                                            해당 단말기는 {terminal?.car?.carNumber} 차량에 등록되어 있습니다.
                                        </p>
                                        <p style={{ margin: 0 }}>기존 등록 해제 후 등록하시겠습니까?</p>
                                    </>
                                ),
                                okText: String.confirm,
                                onOk() {
                                    setForced(true);
                                    form.setFieldValue("terminal", {
                                        ...terminal,
                                        equippedDate: terminal?.equippedDate && dayjs(terminal?.equippedDate).format(Common.FORMAT_DATE),
                                    });
                                },
                                cancelText: String.cancel,
                                onCancel() {
                                    form.setFieldValue("terminal", {
                                        ...defaultTerminal,
                                        equippedDate:
                                            defaultTerminal?.equippedDate && dayjs(defaultTerminal?.equippedDate).format(Common.FORMAT_DATE),
                                    });
                                },
                                centered: true,
                            });
                        } else {
                            form.setFieldValue("terminal", {
                                ...terminal,
                                equippedDate: terminal?.equippedDate && dayjs(terminal?.equippedDate).format(Common.FORMAT_DATE),
                            });
                        }
                    }}
                >
                    {terminals
                        ?.sort((a, b) => (a?.car?.carNumber !== undefined ? 0 : b?.car?.carNumber === undefined ? 1 : -1))
                        ?.map((terminal) => (
                            <Option key={terminal.serialNumber} value={terminal.serialNumber}>
                                {`${terminal.serialNumber} ${type === "view" ? "" : terminal.car?.carNumber ? `(${terminal.car.carNumber})` : ""}`}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            name: ["terminal", "terminalType"],
            label: String.modelName,
            span: 12,
            disabled: true,
        },
        {
            name: ["terminal", "sysVer"],
            label: String.systemVersion,
            span: 6,
            disabled: true,
        },
        {
            name: ["terminal", "mcuVer"],
            label: String.mcuVersion,
            span: 6,
            disabled: true,
        },
        {
            key: "terminalEquippedDate",
            name: ["terminal", "equippedDate"],
            label: String.equippedDate,
            span: 12,
            disabled: true,
        },
    ];

    const modemContents: Array<TypeUtils.formType> = [
        {
            key: "modemSerialNumber",
            name: ["terminalModem", "serialNumber"],
            label: String.serialNumber,
            span: 12,
            disabled: true,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="모뎀 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                    onChange={(value) => {
                        const defaultTerminalModem = modems?.find((modem) => modem.serialNumber === car?.terminalModem?.serialNumber);
                        const terminalModem = modems?.find((modem) => modem.serialNumber === value);
                        if (terminalModem?.car) {
                            return Modal.confirm({
                                title: "등록 해제",
                                content: (
                                    <>
                                        <p className="fw-rg fs-md" style={{ margin: 0 }}>
                                            해당 모뎀은 {terminalModem?.car?.carNumber} 차량에 등록되어 있습니다.
                                        </p>
                                        <p style={{ margin: 0 }}>기존 등록 해제 후 등록하시겠습니까?</p>
                                    </>
                                ),
                                okText: String.confirm,
                                onOk() {
                                    setForced(true);
                                    form.setFieldValue("terminalModem", {
                                        ...terminalModem,
                                        equippedDate: terminalModem?.equippedDate && dayjs(terminalModem?.equippedDate).format(Common.FORMAT_DATE),
                                        activatedDate: terminalModem?.activatedDate && dayjs(terminalModem?.activatedDate).format(Common.FORMAT_DATE),
                                    });
                                },
                                cancelText: String.cancel,
                                onCancel() {
                                    form.setFieldValue("terminalModem", {
                                        ...defaultTerminalModem,
                                        equippedDate:
                                            defaultTerminalModem?.equippedDate &&
                                            dayjs(defaultTerminalModem?.equippedDate).format(Common.FORMAT_DATE),
                                        activatedDate:
                                            defaultTerminalModem?.activatedDate &&
                                            dayjs(defaultTerminalModem?.activatedDate).format(Common.FORMAT_DATE),
                                    });
                                },
                                centered: true,
                            });
                        } else {
                            form.setFieldValue("terminalModem", {
                                ...terminalModem,
                                equippedDate: terminalModem?.equippedDate && dayjs(terminalModem?.equippedDate).format(Common.FORMAT_DATE),
                                activatedDate: terminalModem?.activatedDate && dayjs(terminalModem?.activatedDate).format(Common.FORMAT_DATE),
                            });
                        }
                    }}
                >
                    {modems
                        ?.sort((a, b) => (a?.car?.carNumber !== undefined ? 0 : b?.car?.carNumber === undefined ? 1 : -1))
                        ?.map((modem) => (
                            <Option key={modem.serialNumber} value={modem.serialNumber}>
                                {`${modem.serialNumber} ${type === "view" ? "" : modem.car?.carNumber ? `(${modem.car.carNumber})` : ""}`}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            key: "modemCarrier",
            name: ["terminalModem", "carrier"],
            label: String.carrier,
            span: 6,
            disabled: true,
            input: (
                <Select allowClear showSearch disabled className={type === "view" ? "disabled-selector" : ""} optionFilterProp="children">
                    {Object.keys(Utils.carrier)?.map((key) => (
                        <Option key={key} value={key}>
                            {getValueByKey(key)}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            key: "modemPhone",
            name: ["terminalModem", "phone"],
            label: String.phoneNumber,
            span: 6,
            disabled: true,
        },
        {
            key: "activatedDate",
            name: ["terminalModem", "activatedDate"],
            label: "개통일",
            span: 12,
            disabled: true,
        },
        {
            key: "ratePlan",
            name: ["terminalModem", "ratePlan"],
            label: "요금제",
            span: 12,
            disabled: true,
        },
        {
            key: "contractTerm",
            name: ["terminalModem", "contractTerm"],
            label: "약정 기간",
            span: 6,
            disabled: true,
        },
        {
            key: "model",
            name: ["terminalModem", "model"],
            label: "모델",
            span: 6,
            disabled: true,
        },
        {
            key: "modemEquippedDate",
            name: ["terminalModem", "equippedDate"],
            label: String.equippedDate,
            span: 12,
            disabled: true,
        },
    ];

    const doorModuleContents: Array<TypeUtils.formType> = [
        {
            key: "doorModuleSerialNumber",
            name: ["doorModule", "serialNumber"],
            label: String.serialNumber,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="도어모듈을 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                    onChange={(value) => {
                        const doorModule = doorModules?.find((doorModule) => doorModule.serialNumber === value);
                        form.setFieldValue("doorModule", {
                            ...doorModule,
                            equippedDate: doorModule?.equippedDate && dayjs(doorModule?.equippedDate).format(Common.FORMAT_DATE),
                        });
                    }}
                >
                    {doorModules
                        ?.sort((a, b) => (a?.car?.carNumber !== undefined ? 0 : b?.car?.carNumber === undefined ? 1 : -1))
                        ?.map((doorModule) => (
                            <Option key={doorModule.serialNumber} value={doorModule.serialNumber}>
                                {`${doorModule.serialNumber} ${
                                    type === "view" ? "" : doorModule.car?.carNumber ? `(${doorModule.car.carNumber})` : ""
                                }`}
                            </Option>
                        ))}
                </Select>
            ),
        },
        {
            key: "swVersion",
            name: ["doorModule", "swVersion"],
            label: String.swVersion,
            span: 12,
            disabled: true,
        },
        {
            name: ["doorModule", "imei"],
            label: String.imei,
            span: 12,
            disabled: true,
        },
        {
            key: "doorModuleModemCarrier",
            name: ["doorModule", "carrier"],
            label: String.carrier,
            span: 6,
            disabled: true,

            input: (
                <Select
                    allowClear
                    showSearch
                    disabled
                    className={type === "view" ? "disabled-selector" : ""}
                    options={Object.keys(Utils.carrier).map((key) => ({ value: key, label: getValueByKey(key) }))}
                />
            ),
        },
        {
            key: "doorModuleModemPhone",
            name: ["doorModule", "phone"],
            label: String.phoneNumber,
            span: 6,
            disabled: true,
        },
        {
            key: "equippedDate",
            name: ["doorModule", "equippedDate"],
            label: String.equippedDate,
            span: 24,
            disabled: true,
        },
    ];

    const { loadingApiCarInfo, requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;

        const car = resultApiCarInfo.car;
        setCar(car);
        form.setFieldsValue({
            ...car,
            terminal: {
                ...car.terminal,
                equippedDate: car?.terminal?.equippedDate && dayjs(car?.terminal?.equippedDate).format(Common.FORMAT_DATE),
            },
            terminalModem: {
                ...car.terminalModem,
                phone: Utils.convertPhone(car?.terminalModem?.phone),
                equippedDate: car?.terminalModem?.equippedDate && dayjs(car?.terminalModem?.equippedDate).format(Common.FORMAT_DATE),
            },
            doorModule: {
                ...car.doorModule,
                phone: Utils.convertPhone(car?.doorModule?.phone),
                equippedDate: car?.doorModule?.equippedDate && dayjs(car?.doorModule?.equippedDate).format(Common.FORMAT_DATE),
            },
        });
    }, [resultApiCarInfo]);

    const { requestApiCarRegister, resultApiCarRegister } = RequestCar.useRequestApiCarRegister();

    useEffect(() => {
        if (!resultApiCarRegister) return;

        onResult("register");
    }, [resultApiCarRegister]);

    const { requestApiCarUpdate, resultApiCarUpdate } = RequestCar.useRequestApiCarUpdate();

    useEffect(() => {
        if (!resultApiCarUpdate) return;

        onResult("edit");
    }, [resultApiCarUpdate]);

    const { requestApiTerminalList, resultApiTerminalList } = RequestTerminal.useRequestApiTerminalList();

    useEffect(() => {
        if (!resultApiTerminalList) return;

        setTerminals(resultApiTerminalList.terminals);
    }, [resultApiTerminalList]);

    const { requestApiModemList, resultApiModemList } = RequestModem.useRequestApiModemList();

    useEffect(() => {
        if (!resultApiModemList) return;

        setModems(resultApiModemList.terminalModems);
    }, [resultApiModemList]);

    const { requestApiDoorModuleList, resultApiDoorModuleList } = RequestDoorModule.useRequestApiDoorModuleList();

    useEffect(() => {
        if (!resultApiDoorModuleList) return;

        setDoorModules(resultApiDoorModuleList.doorModules);
    }, [resultApiDoorModuleList]);

    const { requestApiDoorModulesStatus, resultApiDoorModulesStatus } = RequestDoorModules.useRequestApiDoorModulesStatus();

    useEffect(() => {
        if (!resultApiDoorModulesStatus) return;

        alert.setAlert(AlertType.SUCCESS, `상태 확인 요청`, `도어모듈 상태 확인 요청을 보냈습니다.`);
    }, [resultApiDoorModulesStatus]);

    const onFinish = (type: viewType, value: TypeDTO.CarDto) => {
        const terminalId = terminals?.find((terminal) => terminal?.serialNumber === value?.terminal?.serialNumber)?.terminalId || null;
        const terminalModemId = modems?.find((modem) => modem?.serialNumber === value?.terminalModem?.serialNumber)?.terminalModemId || null;
        const doorModuleId = doorModules?.find((doorModule) => doorModule?.serialNumber === value?.doorModule?.serialNumber)?.doorModuleId || null;
        const body = {
            terminal: { terminalId },
            terminalModem: { terminalModemId },
            doorModule: { doorModuleId },
        };
        type === "register" ? requestApiCarRegister(body) : requestApiCarUpdate(carId, body, forced);
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.menu_car_device} ${typeText} 성공`, `${String.menu_car_device} 정보를 ${typeText}하였습니다.`);
        setType("view");

        requestApiCarInfo(carId);
    };

    const getContent = () => {
        const contents = [
            { name: "단말기", forms: terminalContents },
            { name: "모뎀", forms: modemContents, disabledBtn: true },
        ];

        if (Utils.isBukgiOrYaxing(car?.makerModel?.maker?.makerName)) {
            contents.push({
                name: "도어모듈",
                forms: doorModuleContents,
                disabledBtn: true,
                buttonProps:
                    type === "view" && Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                        <Space>
                            <Button className="btn-secondary" onClick={() => requestApiDoorModulesStatus(car?.doorModule?.doorModuleId)}>
                                도어모듈 상태 확인
                            </Button>
                            <Button className="btn-primary" onClick={() => setOpen(true)}>
                                차량 도어 작동
                            </Button>
                        </Space>
                    ) : undefined,
            });
        }

        return contents;
    };

    return (
        <Space direction="vertical" size={24} style={{ display: "flex" }}>
            <CustomForm
                form={form}
                type={type}
                initialValues={car}
                contents={getContent()}
                onFinish={onFinish}
                onChangeType={(type) => setType(type)}
                disabledBtn={!Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
            />
            {car?.doorModule && type === "view" && (
                <div style={{ width: "100%", display: "flex", gap: 24 }}>
                    <SubBatteryHistory doorModuleId={car?.doorModule?.doorModuleId} />
                    <DoorModuleHistory doorModuleId={car?.doorModule?.doorModuleId} />
                </div>
            )}
            {car?.doorModule?.doorModuleId && Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) && (
                <DoorModuleModal doorModuleId={car?.doorModule?.doorModuleId} open={open} onChangeOpen={(isOpen) => setOpen(isOpen)} />
            )}
        </Space>
    );
};

export default TabDevices;
