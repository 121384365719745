import { useState, useEffect } from "react";
import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestMaker from "../../utils/requestApiMaker";
import * as RequestMakerModel from "../../utils/requestApiMakerModel";

import Modal from "antd/es/modal/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type modelSearchType = { makerModelName: string };

const PageMaker = () => {
    const alert = useAlert();
    const userDetails = useAuthState();
    const { Option } = Select;

    const [type, setType] = useState<viewType>("view");
    const [selectedMakerId, setSelectedMakerId] = useState<number>();
    const [searchValue, setSearchValue] = useState<modelSearchType>();

    const [isOpenMaker, setIsOpenMaker] = useState<boolean>(false);
    const [maker, setMaker] = useState<TypeDTO.MakerDto>();
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>();
    const [filteredMakers, setFilteredMakers] = useState<Array<TypeDTO.MakerDto>>();

    const [isOpenModel, setIsOpenModel] = useState<boolean>(false);
    const [model, setModel] = useState<TypeDTO.MakerModelDto>();
    const [models, setModels] = useState<Array<TypeDTO.MakerModelDto>>();
    const [filteredModels, setFilteredModels] = useState<Array<TypeDTO.MakerModelDto>>();

    useEffect(() => {
        requestApiMakerList();
        requestApiMakerModelList();
    }, []);

    useEffect(() => {
        setFilteredModels(
            models?.filter(
                (model: TypeDTO.MakerModelDto) =>
                    (!selectedMakerId || model.maker?.makerId === selectedMakerId) &&
                    (!searchValue?.makerModelName || model.makerModelName.includes(searchValue.makerModelName))
            )
        );
    }, [selectedMakerId]);

    const makerColumns: ColumnsType<TypeDTO.MakerDto> = [
        {
            title: String.maker,
            dataIndex: "makerName",
            key: "makerName",
            width: "10%",
            align: "center",
        },
        {
            title: String.modelCount,
            dataIndex: "makerModels",
            key: "makerModels",
            width: "10%",
            align: "center",
            render: (_, { makerModels }) => makerModels?.length || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={16}>
                        <img
                            alt=""
                            className="btn-icon"
                            src={IconEdit}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowMaker("edit", row);
                            }}
                            width={20}
                        />
                        <img
                            alt=""
                            className="btn-icon"
                            src={IconDelete}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete("maker", row.makerId, row.makerName);
                            }}
                            width={20}
                        />
                    </Space>
                );
            },
        },
    ];

    const modelColumns: ColumnsType<TypeDTO.MakerModelDto> = [
        {
            title: String.maker,
            dataIndex: ["maker", "makerName"],
            key: "makerName",
            width: "10%",
            align: "center",
        },
        {
            title: String.model,
            dataIndex: "makerModelName",
            key: "makerModelName",
            width: "10%",
            align: "center",
            render: (_, { makerModelName }) => {
                return makerModelName || String.dash;
            },
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowModel("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete("model", row.makerModelId, row.makerModelName);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const makerSearch: TypeUtils.SearchItemType[] = [
        {
            id: "makerName",
            span: 24,
            title: String.maker,
            type: "select",
            options: makers?.map((maker) => ({ value: maker.makerName, label: maker.makerName })),
        },
    ];

    const modelSearch: TypeUtils.SearchItemType[] = [
        {
            id: "makerModelName",
            span: 24,
            title: String.model,
            type: "input",
        },
    ];

    const makerContents: Array<TypeUtils.formType> = [
        {
            name: "makerName",
            label: String.maker,
            span: 24,
            required: true,
        },
    ];

    const modelContents: Array<TypeUtils.formType> = [
        {
            name: ["maker", "makerId"],
            label: String.maker,
            span: 24,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="제조사를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                >
                    {makers?.map((maker) => (
                        <Option key={maker.makerId} value={maker.makerId}>
                            {maker.makerName}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: "makerModelName",
            label: String.model,
            span: 24,
            required: true,
        },
    ];

    //maker
    const { loadingApiMakerList, requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    const { requestApiMakerRegister, resultApiMakerRegister } = RequestMaker.useRequestApiMakerRegister();

    useEffect(() => {
        if (!resultApiMakerRegister) return;

        onResultMaker("register");
    }, [resultApiMakerRegister]);

    const { requestApiMakerUpdate, resultApiMakerUpdate } = RequestMaker.useRequestApiMakerUpdate();

    useEffect(() => {
        if (!resultApiMakerUpdate) return;

        onResultMaker("edit");
    }, [resultApiMakerUpdate]);

    const { requestApiMakerDelete, resultApiMakerDelete } = RequestMaker.useRequestApiMakerDelete();

    useEffect(() => {
        if (!resultApiMakerDelete) return;

        onResultMaker("delete");
    }, [resultApiMakerDelete]);

    //model
    const { loadingApiMakerModelList, requestApiMakerModelList, resultApiMakerModelList } = RequestMakerModel.useRequestApiMakerModelList();

    useEffect(() => {
        if (!resultApiMakerModelList) return;

        setModels(resultApiMakerModelList.makerModels);
    }, [resultApiMakerModelList]);

    const { requestApiMakerModelRegister, resultApiMakerModelRegister } = RequestMakerModel.useRequestApiMakerModelRegister();

    useEffect(() => {
        if (!resultApiMakerModelRegister) return;

        onResultModel("register");
    }, [resultApiMakerModelRegister]);

    const { requestApiMakerModelUpdate, resultApiMakerModelUpdate } = RequestMakerModel.useRequestApiMakerModelUpdate();

    useEffect(() => {
        if (!resultApiMakerModelUpdate) return;

        onResultModel("edit");
    }, [resultApiMakerModelUpdate]);

    const { requestApiMakerModelDelete, resultApiMakerModelDelete } = RequestMakerModel.useRequestApiMakerModelDelete();

    useEffect(() => {
        if (!resultApiMakerModelDelete) return;

        onResultModel("delete");
    }, [resultApiMakerModelDelete]);

    const onSearchMaker = (value: { makerName: string }) => {
        const searchFilter = Utils.searchFilter;
        setFilteredMakers(makers?.filter((maker) => searchFilter(maker, value?.makerName, "makerName")));
    };

    const onSearchModel = (value: modelSearchType) => {
        const searchFilter = Utils.searchFilter;

        setSearchValue(value);
        setFilteredModels(
            models?.filter(
                (model) =>
                    searchFilter(model, value?.makerModelName, "makerModelName") &&
                    (selectedMakerId ? searchFilter(model.maker, selectedMakerId, "makerId") : true)
            )
        );
    };

    const onRowMaker = (type: viewType, value?: TypeDTO.MakerDto) => {
        setMaker(value);
        setIsOpenMaker(true);
        setType(type);
    };

    const onRowModel = (type: viewType, value?: TypeDTO.MakerModelDto) => {
        setModel(value);
        setIsOpenModel(true);
        setType(type);
    };

    const onDelete = (category: "maker" | "model", id: number, name: string) => {
        Modal.confirm({
            title: (category === "maker" ? String.maker : String.model) + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                category === "maker" ? requestApiMakerDelete(id) : requestApiMakerModelDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinishMaker = (type: viewType, value: TypeDTO.MakerDto) => {
        type === "register" ? requestApiMakerRegister(value) : requestApiMakerUpdate(maker?.makerId, value);
    };

    const onFinishModel = (type: viewType, value: TypeDTO.MakerModelDto) => {
        type === "register" ? requestApiMakerModelRegister(value) : requestApiMakerModelUpdate(model?.makerModelId, value);
    };

    const onResultMaker = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.maker} ${typeText} 성공`, `${String.maker} 정보를 ${typeText}하였습니다.`);
        requestApiMakerList();
        requestApiMakerModelList();

        type !== "delete" && setIsOpenMaker(false);
    };

    const onResultModel = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.model} ${typeText} 성공`, `${String.model} 정보를 ${typeText}하였습니다.`);
        requestApiMakerModelList();

        setIsOpenModel(false);
    };

    return (
        <>
            <div style={{ width: "100%", display: "flex", gap: 24 }}>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <Search
                        title={String.maker}
                        dataLength={filteredMakers?.length || makers?.length}
                        values={makerSearch}
                        onSearch={(value) => onSearchMaker(value)}
                        onClear={(value) => onSearchMaker(value)}
                        onClick={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? () => onRowMaker("register", undefined)
                                : undefined
                        }
                    />
                    <DataTable
                        rowKey={(row: TypeDTO.MakerDto) => row.makerId}
                        disabledTitle
                        loading={loadingApiMakerList}
                        columns={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? makerColumns
                                : makerColumns.filter((column) => column.key !== "detail")
                        }
                        dataSource={filteredMakers || makers}
                        rowClassName={(running: TypeDTO.MakerDto) => {
                            return running?.makerId === selectedMakerId ? "table-row-selected" : "";
                        }}
                        onRow={(value) => {
                            setSelectedMakerId((prevSelectedMakerId) => (value.makerId === prevSelectedMakerId ? undefined : value.makerId));
                        }}
                    />
                </div>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <Search
                        title={String.model}
                        dataLength={filteredModels?.length || models?.length}
                        values={modelSearch}
                        onSearch={(value) => onSearchModel(value)}
                        onClear={(value) => onSearchModel(value)}
                        onClick={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? () => onRowModel("register", undefined)
                                : undefined
                        }
                    />
                    <DataTable
                        rowKey={(row: TypeDTO.MakerModelDto) => row.makerModelId}
                        disabledTitle
                        loading={loadingApiMakerModelList}
                        columns={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? modelColumns
                                : modelColumns.filter((column) => column.key !== "detail")
                        }
                        dataSource={filteredModels || models}
                        onRow={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? (value) => onRowModel("view", value)
                                : undefined
                        }
                    />
                </div>
            </div>
            <CustomModal
                title={String.maker}
                open={isOpenMaker}
                type={type}
                value={maker}
                contents={makerContents}
                onChangedOpen={() => setIsOpenMaker(false)}
                onChangedType={(value) => setType(value)}
                onFinish={onFinishMaker}
            />
            <CustomModal
                title={String.model}
                open={isOpenModel}
                type={type}
                value={model}
                contents={modelContents}
                onChangedOpen={() => setIsOpenModel(false)}
                onChangedType={(value) => setType(value)}
                onFinish={onFinishModel}
            />
        </>
    );
};

export default PageMaker;
