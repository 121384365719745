import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { actionSetSocs } from "../../utils/action";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";
import * as RequestCar from "../../utils/requestApiCar";

import Space from "antd/es/space/index";
import DataTable from "../../components/common/DataTable";
import Filter from "../../components/mobile/Filter";
import CarSelector from "../../components/mobile/CarSelector";
import IconError from "../../assets/images/icon/icon_red_exclamation.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

type SearchType = {
    batteryWarning: boolean;
    batteryNormal: boolean;
};

const PageSoc = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reducerSocs = useSelector((state) => state.socs);

    const [searchValue, setSearchValue] = useState<SearchType>();
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [selectedCarId, setSelectedCarId] = useState<number>();
    const [selectedCarNumber, setSelectedCarNumber] = useState<string>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto>>();

    const filterData: TypeUtils.SearchItemType[] = [
        {
            id: "checkbox",
            span: 12,
            title: String.status,
            type: "checkbox",
            options: [
                { value: "batteryWarning", label: String.warning },
                { value: "batteryNormal", label: String.active },
            ],
            defaultValue: ["batteryWarning", "batteryNormal"],
        },
    ];

    const [filter, setFilter] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.CarDto> = [
        {
            title: String.status,
            dataIndex: ["runningSummary", "lastRunningHistory", "socCheck"],
            key: "socCheck",
            align: "center",
            width: "7%",
            render: (_, { runningSummary }) => {
                return runningSummary?.lastRunningHistory?.socCheck ? (
                    <img alt="icon" src={IconError} width={20} height={20} />
                ) : (
                    <img alt="icon" src={IconCheck} width={20} height={20} />
                );
            },
        },
        {
            title: String.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            width: "17%",
            align: "center",
        },

        {
            title: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ lineHeight: "16px" }}>{String.soc}</span>
                    <span style={{ lineHeight: "16px" }} className="fs-xs">
                        {String.percentUnit}
                    </span>
                </div>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "endSoc"],
            key: "endSoc",
            width: "8%",
            render: (_, { runningSummary }) => Utils.checkNumberData(runningSummary?.lastRunningHistory?.endSoc) || String.dash,
        },
        {
            title: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ margin: 0, lineHeight: "18px" }}>전압 대비</p>
                    <p style={{ margin: 0, lineHeight: "18px" }}>{String.soc}</p>
                </div>
            ),
            dataIndex: ["runningSummary", "lastRunningHistory", "tableSoc"],
            key: "tableSoc",
            width: "12%",
            render: (_, { runningSummary }) => Utils.checkNumberData(runningSummary?.lastRunningHistory?.tableSoc) || String.dash,
        },
    ];

    useEffect(() => {
        requestApiCarList(true);
    }, []);

    useEffect(() => {
        searchValue && onSearchData(searchValue);

        setTimeout(() => {
            document.querySelector(".ant-table-body").scrollTo({ top: reducerSocs.scrollPosition });
        }, 100);
    }, [cars]);

    useEffect(() => {
        if (Object.keys(reducerSocs).length > 0) {
            setSelectedCarId(reducerSocs?.selectedCarId);

            setFilter(
                filterData?.map((item) => {
                    const { id, defaultValue } = item;
                    const isCheckbox = id === "checkbox";

                    if (isCheckbox) {
                        const { batteryWarning = true, batteryNormal = true } = reducerSocs;

                        return {
                            ...item,
                            defaultValue: Object.entries({ batteryWarning, batteryNormal })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else {
                        return {
                            ...item,
                            defaultValue: reducerSocs[id] ?? defaultValue,
                        };
                    }
                })
            );
        } else {
            setFilter(filterData);
        }
    }, [reducerSocs]);

    const dispatchSocs = (selectedId: number) => {
        dispatch(
            actionSetSocs({
                ...searchValue,
                selectedCarId: selectedCarId,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
            })
        );
    };

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(
            resultApiCarList.cars.sort((a: TypeDTO.CarDto, b: TypeDTO.CarDto) =>
                !a?.runningSummary?.lastRunningHistory?.socCheck ? 1 : !b?.runningSummary?.lastRunningHistory?.socCheck ? -1 : 0
            )
        );
    }, [resultApiCarList]);

    useEffect(() => {
        setSearchValue(searchValue);
        setFilteredCars(
            cars?.filter((car) => {
                if (selectedCarNumber) {
                    if (Utils.searchFilter(car, selectedCarNumber, "carNumber")) {
                        if (searchValue?.batteryWarning && searchValue?.batteryNormal) {
                            return true;
                        }
                        if (searchValue?.batteryWarning) {
                            return car?.runningSummary?.lastRunningHistory?.socCheck;
                        }

                        if (searchValue?.batteryNormal) {
                            return !car?.runningSummary?.lastRunningHistory?.socCheck;
                        }
                    } else {
                        return false;
                    }
                } else {
                    if (searchValue?.batteryWarning && searchValue?.batteryNormal) {
                        return true;
                    }
                    if (searchValue?.batteryWarning) {
                        return car?.runningSummary?.lastRunningHistory?.socCheck;
                    }
                    if (searchValue?.batteryNormal) {
                        return !car?.runningSummary?.lastRunningHistory?.socCheck;
                    }
                    return false;
                }
            })
        );
    }, [selectedCarNumber]);

    const onSearchData = (value: SearchType) => {
        const { checkbox, ...searchValue } = value;
        const batteryWarning = value.batteryWarning ?? value?.checkbox?.includes("batteryWarning") ?? false;
        const batteryNormal = value.batteryNormal ?? value?.checkbox?.includes("batteryNormal") ?? false;

        searchValue.batteryWarning = batteryWarning;
        searchValue.batteryNormal = batteryNormal;

        setFilteredCars(
            cars?.filter((car) => {
                if (selectedCarNumber) {
                    if (Utils.searchFilter(car, selectedCarNumber, "carNumber")) {
                        if (batteryWarning && batteryNormal) {
                            return true;
                        }
                        if (batteryWarning) {
                            return car?.runningSummary?.lastRunningHistory?.socCheck;
                        }
                        if (batteryNormal) {
                            return !car?.runningSummary?.lastRunningHistory?.socCheck;
                        }
                    } else {
                        return false;
                    }
                } else {
                    if (batteryWarning && batteryNormal) {
                        return true;
                    }
                    if (batteryWarning) {
                        return car?.runningSummary?.lastRunningHistory?.socCheck;
                    }
                    if (batteryNormal) {
                        return !car?.runningSummary?.lastRunningHistory?.socCheck;
                    }

                    return false;
                }
            })
        );
        setSearchValue(searchValue);
    };

    return (
        <div className="pageWrapper">
            <CarSelector
                selectedCarId={selectedCarId}
                onSelect={(id?: number) => {
                    setSelectedCarId(id);
                }}
                onSelectCarNumber={(carNumber?: string) => {
                    setSelectedCarNumber(carNumber);
                }}
            />
            <Space direction="vertical">
                <Filter dataLength={filteredCars?.length} values={filter} onSearch={(value) => onSearchData(value)} />
                <DataTable
                    isMobile
                    rowKey={(row: TypeDTO.CarDto) => row?.carId}
                    disabledTitle
                    disabledPagination
                    loading={loadingApiCarList}
                    columns={columns}
                    dataSource={filteredCars}
                    rowClassName={(running: TypeDTO.CarDto) => {
                        return running?.socCheck
                            ? "table-row-warning"
                            : running.carId === reducerSocs.selectedId
                            ? "table-row-selected"
                            : "";
                    }}
                    onRow={(row: TypeDTO.CarDto) => {
                        dispatchSocs(row?.carId);
                        navigate(Common.PAGE_ISSUE_SOCS + "/" + row.carNumber);
                    }}
                    scroll="calc(var(--vh, 1vh) * 100 - 292px)"
                />
            </Space>
        </div>
    );
};

export default PageSoc;
