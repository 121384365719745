import { useEffect, useState } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "../../utils/customAxios";
import { useInterval } from "../../utils/utils";
import * as Request from "../../commons/request";

import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Space from "antd/es/space/index";

import StatsDataByPeriod from "../../components/browser/StatsDataByPeriod";
import StatsCarByCategory from "../../components/browser/StatsCarByCategory";
import StatsCarByMaker from "../../components/browser/StatsCarByMaker";
import StatsAccumulated from "../../components/browser/StatsAccumulated";
import moment from "moment";
import DashboardSummary from "../../components/browser/DashboardSummary";

import iconCircleCarData from "../../assets/images/icon/icon_circle_carData.png";
import iconCircleRunningCount from "../../assets/images/icon/icon_circle_drivingCount.png";
import iconCircleRunningTime from "../../assets/images/icon/icon_circle_runningTime.png";

type summaryType = {
    totalDataCount?: number;
    totalRunningCount?: number;
    totalDrivingTime?: number;
};

type updateDate = {
    dataDate?: string;
    runningDate?: string;
};

type dataStatsType = {
    statsTime: string;
    count: number;
};

type runningStatsType = {
    statsTime: string;
    count: number;
    drivingTime: number;
};

type statsType = {
    dataStats?: Array<dataStatsType>;
    runningStats?: Array<runningStatsType>;
};

function PageStats() {
    const hour = 3600;
    const dateFormat = "YYYY-MM-DD a h:mm";
    const [summary, setSummary] = useState<summaryType>({
        totalDataCount: 0,
        totalRunningCount: 0,
        totalDrivingTime: 0,
    });
    const [stats, setStats] = useState<statsType>({
        dataStats: undefined,
        runningStats: undefined,
    });
    const [savedDate, setSavedDate] = useState<string>(moment().format(dateFormat));
    const [updateDate, setUpdateDate] = useState<updateDate>({
        dataDate: "",
        runningDate: "",
    });
    const [accmulatedPeriod, setAccumulatedPeriod] = useState<"YEAR" | "MONTH">();
    const [loading, setLoading] = useState<boolean>(false);

    useInterval(
        () => {
            if (window.location.href.includes(window.location.host + "/stats") && accmulatedPeriod !== undefined) {
                requestGetStatsData();
                requestGetStatsRunning();
            }
        },
        window.location.href.includes(window.location.host + "/stats") ? 1000 * 60 : null
    );

    useEffect(() => {
        if (accmulatedPeriod) {
            requestGetStatsData();
            requestGetStatsRunning();
        }
    }, []);

    useEffect(() => {
        if (accmulatedPeriod) {
            requestGetStatsData();
            requestGetStatsRunning();
        }

        setLoading(true);
    }, [accmulatedPeriod]);

    const summaries = [
        {
            title: "누적 차량 데이터",
            firstText: summary.totalDataCount,
            unit: "건",
            box: <img alt="" width={60} height={60} src={iconCircleCarData} />,
        },
        {
            title: "누적 운행 건수",
            firstText: summary.totalRunningCount,
            unit: "건",
            box: <img alt="" width={60} height={60} src={iconCircleRunningCount} />,
        },
        {
            title: "누적 운행 시간",
            firstText: summary?.totalDrivingTime === undefined ? 0 : Math.floor(summary.totalDrivingTime / hour),
            unit: "시간",
            box: <img alt="" width={60} height={60} src={iconCircleRunningTime} />,
        },
    ];

    //get data
    const requestAxiosGetStatsData = async () => {
        const response = await utilAxiosWithAuth().get(Request.STATS_DATA_ACCUMULATED_URL, { params: { period: accmulatedPeriod } });
        return response.data;
    };

    const {
        loading: loadingGetStatsData,
        error: errorGetStatsData,
        data: resultGetStatsData,
        execute: requestGetStatsData,
    } = useAsyncAxios(requestAxiosGetStatsData);

    useEffect(() => {
        if (resultGetStatsData === null) return;

        setSummary((prev) => ({
            ...prev,
            totalDataCount: resultGetStatsData.dataStats[resultGetStatsData.dataStats.length - 1].count,
        }));

        if (stats?.dataStats === undefined || stats.dataStats.length !== resultGetStatsData.dataStats.length) {
            setStats((prev) => ({
                ...prev,
                dataStats: resultGetStatsData.dataStats,
            }));
        }

        setUpdateDate((prev) => ({
            ...prev,
            dataDate: moment().format(dateFormat),
        }));
        setLoading(false);
    }, [resultGetStatsData]);

    useEffect(() => {
        if (errorGetStatsData === null) return;

        console.log("errorStatsData", errorGetStatsData);
    }, [errorGetStatsData]);

    //get running
    const requestAxiosGetStatsRunning = async () => {
        const response = await utilAxiosWithAuth().get(Request.STATS_RUNNING_ACCUMULATED_URL, { params: { period: accmulatedPeriod } });
        return response.data;
    };

    const {
        loading: loadingGetStatsRunning,
        error: errorGetStatsRunning,
        data: resultGetStatsRunning,
        execute: requestGetStatsRunning,
    } = useAsyncAxios(requestAxiosGetStatsRunning);

    useEffect(() => {
        if (resultGetStatsRunning === null) return;
        console.log("resultGetStatsRunning", resultGetStatsRunning);

        setSummary((prev) => ({
            ...prev,
            totalRunningCount: resultGetStatsRunning.runningStats[resultGetStatsRunning.runningStats.length - 1].count,
            totalDrivingTime: resultGetStatsRunning.runningStats[resultGetStatsRunning.runningStats.length - 1].drivingTime,
        }));

        if (stats?.runningStats === undefined || stats.runningStats.length !== resultGetStatsRunning.runningStats.length) {
            setStats((prev) => ({
                ...prev,
                runningStats: resultGetStatsRunning.runningStats,
            }));
        }

        setUpdateDate((prev) => ({
            ...prev,
            runningDate: moment().format(dateFormat),
        }));
    }, [resultGetStatsRunning]);

    useEffect(() => {
        if (errorGetStatsRunning === null) return;

        console.log("errorStatsRunning", errorGetStatsRunning);
    }, [errorGetStatsRunning]);

    useEffect(() => {
        updateDate.dataDate !== undefined && updateDate.dataDate === updateDate.runningDate && setSavedDate(updateDate.dataDate);
    }, [updateDate]);

    return (
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
            <DashboardSummary summaries={summaries} isStats height="auto" />
            <div className="pageWrapper">
                <StatsAccumulated stats={stats} loading={loading} period={(value: "YEAR" | "MONTH") => setAccumulatedPeriod(value)} />
            </div>
            <div className="pageWrapper">
                <Row gutter={20}>
                    <Col xs={24} md={12} className="gutter-row">
                        <StatsCarByCategory />
                    </Col>
                    <Col xs={24} md={12} className="gutter-row">
                        <StatsCarByMaker />
                    </Col>
                </Row>
            </div>
            <div className="pageWrapper">
                <StatsDataByPeriod />
            </div>
        </Space>
    );
}

export default PageStats;
