import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { type TableProps } from "antd/es/table/InternalTable";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestRunning from "../../utils/requestApiRunning";
import * as Common from "../../commons/common";

import Space from "antd/es/space/index";
import DataTable from "../common/DataTable";

import Filter from "./Filter";
import moment from "moment";

import IconError from "../../assets/images/icon/icon_red_exclamation.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

type SearchType = {
    startDate: string;
    endDate: string;
    checkbox: string[];
};

const Socs = ({ carNumber }: { carId?: number; carNumber?: string }) => {
    const navigate = useNavigate();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.RunningHistoryDto>>({});
    const [searchHistoryValue, setSearchHistoryValue] = useState<SearchType>();
    const [runnings, setRunnings] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [loading, setLoading] = useState(false);
    const [tempHistories, setTempHistories] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestHistoriesPage, setRequestHistoriesPage] = useState(0);

    const columns: ColumnsType<TypeDTO.RunningHistoryDto> = [
        {
            title: String.status,
            dataIndex: "socCheck",
            key: "socCheck",
            align: "center",
            width: "7%",
            render: (_, { socCheck }) => {
                return socCheck ? (
                    <img alt="icon" src={IconError} width={20} height={20} />
                ) : (
                    <img alt="icon" src={IconCheck} width={20} height={20} />
                );
            },
        },
        {
            title: String.runningDate,
            dataIndex: "startTime",
            key: "startTime",
            width: "15%",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.endTime, b.endTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "time" ? sortedInfo.order : null,
            render: (_, { startTime }) => {
                return moment.utc(startTime, Common.FORMAT_DATE).format(Common.FORMAT_SHORT_DATE);
            },
        },
        {
            title: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ lineHeight: "16px" }}>{String.soc}</span>
                    <span style={{ lineHeight: "16px" }} className="fs-xs">
                        {String.percentUnit}
                    </span>
                </div>
            ),
            dataIndex: "endSoc",
            key: "endSoc",
            width: "8%",
            render: (_, { endSoc }) => {
                return Utils.checkNumberData(endSoc) || String.dash;
            },
        },
        {
            title: (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ margin: 0, lineHeight: "18px" }}>전압 대비</p>
                    <p style={{ margin: 0, lineHeight: "18px" }}>{String.soc}</p>
                </div>
            ),
            dataIndex: "tableSoc",
            key: "tableSoc",
            width: "12%",
            render: (_, { tableSoc }) => {
                return Utils.checkNumberData(tableSoc) || String.dash;
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "searchDate",
            span: 12,
            title: String.searchDate,
            type: "rangePicker",
            dateFormat: Common.FORMAT_DATE_TIME,
        },
        {
            id: "checkbox",
            span: 12,
            title: String.status,
            type: "checkbox",
            options: [
                { value: "batteryWarning", label: String.warning },
                { value: "batteryNormal", label: String.active },
            ],
            defaultValue: ["batteryWarning", "batteryNormal"],
        },
    ];

    const onTableChange: TableProps<TypeDTO.RunningHistoryDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.RunningHistoryDto>);
    };

    const onSearchData = (value: any) => {
        const { checkbox, ...searchValue } = value;

        searchValue.carNumber = carNumber;
        searchValue.batteryWarning = value?.batteryWarning ?? value?.checkbox?.includes("batteryWarning") ?? false;
        searchValue.batteryNormal = value.batteryNormal ?? value?.checkbox?.includes("batteryNormal") ?? false;

        if (carNumber) {
            setSearchHistoryValue(searchValue);
            setLoading(true);
            requestApiRunningHistoryList(searchValue, requestHistoriesPage);
        }
    };

    const { requestApiRunningHistoryList, resultApiRunningHistoryList } = RequestRunning.useRequestApiRunningHistoryList();

    useEffect(() => {
        if (resultApiRunningHistoryList === null) return;
        if (resultApiRunningHistoryList.runningHistories.totalPages > requestHistoriesPage + 1) {
            setTempHistories([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(requestHistoriesPage + 1);
        } else {
            setLoading(false);
            setRunnings([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(0);
            setTempHistories([]);
        }
    }, [resultApiRunningHistoryList]);

    useEffect(() => {
        if (requestHistoriesPage === 0) return;

        requestApiRunningHistoryList(searchHistoryValue, requestHistoriesPage);
    }, [requestHistoriesPage]);

    return (
        <Space direction="vertical">
            <Filter dataLength={runnings?.length} values={search} onSearch={(value) => onSearchData(value)} />
            <DataTable
                isMobile
                rowKey={(row: TypeDTO.RunningHistoryDto) => row?.bootTime}
                disabledTitle
                disabledPagination
                loading={loading}
                columns={columns}
                dataSource={runnings}
                onRow={(row: TypeDTO.CarDto) => navigate(Common.PAGE_ISSUE_SOC_DETAIL + "/" + row.carNumber)}
                onChange={onTableChange}
                scroll="calc(var(--vh, 1vh) * 100 - 226px)"
            />
        </Space>
    );
};

export default Socs;
