import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiDoorModuleRegister = () => {
    const requestAxiosApiDoorModuleRegister = async (data: TypeDTO.DoorModuleDto) => {
        const response = await utilAxiosWithAuth().post(Request.DOOR_MODULE_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiDoorModuleRegister,
        error: errorApiDoorModuleRegister,
        data: resultApiDoorModuleRegister,
        execute: requestApiDoorModuleRegister,
    } = useAsyncAxios(requestAxiosApiDoorModuleRegister);

    useEffect(() => {
        if (resultApiDoorModuleRegister === null) return;
        DEBUG && console.log("resultApiDoorModuleRegister", resultApiDoorModuleRegister);
    }, [resultApiDoorModuleRegister]);

    useEffect(() => {
        if (errorApiDoorModuleRegister === null) return;
        DEBUG && console.log("errorApiDoorModuleRegister", errorApiDoorModuleRegister);
    }, [errorApiDoorModuleRegister]);

    return {
        loadingApiDoorModuleRegister,
        errorApiDoorModuleRegister,
        resultApiDoorModuleRegister,
        requestApiDoorModuleRegister,
    };
};

export const useRequestApiDoorModuleList = () => {
    const requestAxiosApiDoorModuleList = async () => {
        const response = await utilAxiosWithAuth().get(Request.DOOR_MODULES_URL);

        return response.data;
    };

    const {
        loading: loadingApiDoorModuleList,
        error: errorApiDoorModuleList,
        data: resultApiDoorModuleList,
        execute: requestApiDoorModuleList,
    } = useAsyncAxios(requestAxiosApiDoorModuleList);

    useEffect(() => {
        if (resultApiDoorModuleList === null) return;
        DEBUG && console.log("resultApiDoorModuleList", resultApiDoorModuleList);
    }, [resultApiDoorModuleList]);

    useEffect(() => {
        if (errorApiDoorModuleList === null) return;
        DEBUG && console.log("errorApiDoorModuleList", errorApiDoorModuleList);
    }, [errorApiDoorModuleList]);

    return {
        loadingApiDoorModuleList,
        errorApiDoorModuleList,
        resultApiDoorModuleList,
        requestApiDoorModuleList,
    };
};

export const useRequestApiDoorModuleDeleteList = () => {
    const requestAxiosApiDoorModuleDeleteList = async (doorModuleIds: number[]) => {
        const response = await utilAxiosWithAuth().delete(Request.DOOR_MODULES_URL, { data: doorModuleIds });

        return response.data;
    };

    const {
        loading: loadingApiDoorModuleDeleteList,
        error: errorApiDoorModuleDeleteList,
        data: resultApiDoorModuleDeleteList,
        execute: requestApiDoorModuleDeleteList,
    } = useAsyncAxios(requestAxiosApiDoorModuleDeleteList);

    useEffect(() => {
        if (resultApiDoorModuleDeleteList === null) return;
        DEBUG && console.log("resultApiDoorModuleDeleteList", resultApiDoorModuleDeleteList);
    }, [resultApiDoorModuleDeleteList]);

    useEffect(() => {
        if (errorApiDoorModuleDeleteList === null) return;
        DEBUG && console.log("errorApiDoorModuleDeleteList", errorApiDoorModuleDeleteList);
    }, [errorApiDoorModuleDeleteList]);

    return {
        loadingApiDoorModuleDeleteList,
        errorApiDoorModuleDeleteList,
        resultApiDoorModuleDeleteList,
        requestApiDoorModuleDeleteList,
    };
};

export const useRequestApiDoorModuleInfo = () => {
    const requestAxiosApiDoorModuleInfo = async (doorModuleId: number) => {
        const response = await utilAxiosWithAuth().get(Request.DOOR_MODULE_URL + doorModuleId);

        return response.data;
    };

    const {
        loading: loadingApiDoorModuleInfo,
        error: errorApiDoorModuleInfo,
        data: resultApiDoorModuleInfo,
        execute: requestApiDoorModuleInfo,
    } = useAsyncAxios(requestAxiosApiDoorModuleInfo);

    useEffect(() => {
        if (resultApiDoorModuleInfo === null) return;
        DEBUG && console.log("resultApiDoorModuleInfo", resultApiDoorModuleInfo);
    }, [resultApiDoorModuleInfo]);

    useEffect(() => {
        if (errorApiDoorModuleInfo === null) return;
        DEBUG && console.log("errorApiDoorModuleInfo", errorApiDoorModuleInfo);
    }, [errorApiDoorModuleInfo]);

    return {
        loadingApiDoorModuleInfo,
        errorApiDoorModuleInfo,
        resultApiDoorModuleInfo,
        requestApiDoorModuleInfo,
    };
};

export const useRequestApiDoorModuleUpdate = () => {
    const requestAxiosApiDoorModuleUpdate = async (doorModuleId: number, data: TypeDTO.DoorModuleDto) => {
        const response = await utilAxiosWithAuth().put(Request.DOOR_MODULE_URL + doorModuleId, data);

        return response.data;
    };

    const {
        loading: loadingApiDoorModuleUpdate,
        error: errorApiDoorModuleUpdate,
        data: resultApiDoorModuleUpdate,
        execute: requestApiDoorModuleUpdate,
    } = useAsyncAxios(requestAxiosApiDoorModuleUpdate);

    useEffect(() => {
        if (resultApiDoorModuleUpdate === null) return;
        DEBUG && console.log("resultApiDoorModuleUpdate", resultApiDoorModuleUpdate);
    }, [resultApiDoorModuleUpdate]);

    useEffect(() => {
        if (errorApiDoorModuleUpdate === null) return;
        DEBUG && console.log("errorApiDoorModuleUpdate", errorApiDoorModuleUpdate);
    }, [errorApiDoorModuleUpdate]);

    return {
        loadingApiDoorModuleUpdate,
        errorApiDoorModuleUpdate,
        resultApiDoorModuleUpdate,
        requestApiDoorModuleUpdate,
    };
};

export const useRequestApiDoorModuleDelete = () => {
    const requestAxiosApiDoorModuleDelete = async (doorModuleId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.DOOR_MODULE_URL + doorModuleId);

        return response.data;
    };

    const {
        loading: loadingApiDoorModuleDelete,
        error: errorApiDoorModuleDelete,
        data: resultApiDoorModuleDelete,
        execute: requestApiDoorModuleDelete,
    } = useAsyncAxios(requestAxiosApiDoorModuleDelete);

    useEffect(() => {
        if (resultApiDoorModuleDelete === null) return;
        DEBUG && console.log("resultApiDoorModuleDelete", resultApiDoorModuleDelete);
    }, [resultApiDoorModuleDelete]);

    useEffect(() => {
        if (errorApiDoorModuleDelete === null) return;
        DEBUG && console.log("errorApiDoorModuleDelete", errorApiDoorModuleDelete);
    }, [errorApiDoorModuleDelete]);

    return {
        loadingApiDoorModuleDelete,
        errorApiDoorModuleDelete,
        resultApiDoorModuleDelete,
        requestApiDoorModuleDelete,
    };
};

export const useRequestApiDoorStatusHistoryList = () => {
    const requestAxiosApiDoorStatusHistoryList = async (doorModuleId: number, page: number) => {
        const response = await utilAxiosWithAuth().get(Request.DOOR_STATUS_HISTORIES_URL, { params: { doorModuleId, page, size: 100 } });

        return response.data;
    };

    const {
        loading: loadingApiDoorStatusHistoryList,
        error: errorApiDoorStatusHistoryList,
        data: resultApiDoorStatusHistoryList,
        execute: requestApiDoorStatusHistoryList,
    } = useAsyncAxios(requestAxiosApiDoorStatusHistoryList);

    useEffect(() => {
        if (resultApiDoorStatusHistoryList === null) return;
        DEBUG && console.log("resultApiDoorStatusHistoryList", resultApiDoorStatusHistoryList);
    }, [resultApiDoorStatusHistoryList]);

    useEffect(() => {
        if (errorApiDoorStatusHistoryList === null) return;
        DEBUG && console.log("errorApiDoorStatusHistoryList", errorApiDoorStatusHistoryList);
    }, [errorApiDoorStatusHistoryList]);

    return {
        loadingApiDoorStatusHistoryList,
        errorApiDoorStatusHistoryList,
        resultApiDoorStatusHistoryList,
        requestApiDoorStatusHistoryList,
    };
};

export const useRequestApiSubBatteryHistoryList = () => {
    const requestAxiosApiSubBatteryHistoryList = async (doorModuleId: number, page: number) => {
        const response = await utilAxiosWithAuth().get(Request.SUB_BATTERY_HISTORIES_URL, { params: { doorModuleId, page, size: 100 } });

        return response.data;
    };

    const {
        loading: loadingApiSubBatteryHistoryList,
        error: errorApiSubBatteryHistoryList,
        data: resultApiSubBatteryHistoryList,
        execute: requestApiSubBatteryHistoryList,
    } = useAsyncAxios(requestAxiosApiSubBatteryHistoryList);

    useEffect(() => {
        if (resultApiSubBatteryHistoryList === null) return;
        DEBUG && console.log("resultApiSubBatteryHistoryList", resultApiSubBatteryHistoryList);
    }, [resultApiSubBatteryHistoryList]);

    useEffect(() => {
        if (errorApiSubBatteryHistoryList === null) return;
        DEBUG && console.log("errorApiSubBatteryHistoryList", errorApiSubBatteryHistoryList);
    }, [errorApiSubBatteryHistoryList]);

    return {
        loadingApiSubBatteryHistoryList,
        errorApiSubBatteryHistoryList,
        resultApiSubBatteryHistoryList,
        requestApiSubBatteryHistoryList,
    };
};
