//common
export const register = "등록";
export const fullRegister = "일괄등록";
export const save = "저장";
export const update = "수정";
export const back = "이전";
export const cancel = "취소";
export const remove = "삭제";
export const search = "검색";
export const reset = "검색 초기화";
export const refresh = "새로고침";
export const result = "결과";
export const download = "다운로드";
export const excelDownload = "엑셀 다운로드";
export const date = "날짜";
export const time = "시간";
export const addFile = "파일 등록";
export const msg_add_file = "파일이 등록되었습니다.";
export const msg_add = "등록되었습니다.";
export const msg_update = "수정되었습니다.";
export const msg_remove = "삭제되었습니다.";
export const msg_reissue = "재발급 되었습니다.";
export const msg_download_fail = "다운로드에 실패했습니다. 다운 받을 목록을 다시 한 번 확인해 주세요.";
export const total = "전체 ";
export const all = "총";
export const count = "건";
export const clear = "해제";
export const logout = "로그아웃";
export const editMyInfo = "내 정보 수정";
export const currentLocation = "현위치";
export const campingCar = "캠핑카";
export const bus = "통학버스";
export const droneStation = "드론 스테이션";
export const shuttle = "셔틀";
export const refrigeratedTruck = "냉장차";
export const CoCoKart = "사이클 카트";
export const printing = "인쇄";
export const dash = "-";
export const etc = "기타";
export const unit = "단위";
export const view = "조회";

//login
export const id = "아이디";
export const password = "비밀번호";
export const saveId = "아이디 저장";
export const findUser = "아이디 찾기 / 비밀번호 재설정";
export const email = "이메일";
export const login = "로그인";
export const signUp = "회원가입";
export const name = "이름";
export const authority = "권한 등급";

export const msgIdMust = "아이디를 입력해 주세요";
export const msgIdMin = "5자 이상 입력하셔야 합니다";
export const msgIdRule = "허용하지 않는 문자를 입력하셨습니다";
export const msgPwdMust = "비밀번호를 입력해 주세요";
export const msgPwdMin = "8자 이상 입력하셔야 합니다";
export const msgPwdMax = "20자까지만 입력할 수 있습니다.";
export const msgPwdRule = "8~20자 영문 대 소문자, 숫자, 특수문자를 입력해 주세요.";

//member
export const listMember = "사용자 목록";
export const infoMember = "사용자 정보";
export const registerMember = "사용자 정보 등록";
export const updateMember = "사용자 정보 수정";

export const companyName = "소속 회사명";
export const status = "상태";
export const approveId = "승인자 ID";
export const companyRank = "직급";
export const passwordConfirm = "비밀번호 확인";
export const active = "정상";
export const inactive = "비활성화";
export const accountStatus = "계정 상태";
export const accountLocked = "계정 잠금";
export const admin = "최고관리자";
export const manager = "운영자";
export const customerManager = "고객 관리자";
export const customer = "고객";
export const partner = "파트너사";
export const user = "사용자";
export const application = "애플리케이션";
export const changePassword = "비밀번호 변경";
export const currentPassword = "현재 비밀번호";
export const newPassword = "새 비밀번호";
export const newPasswordConfirm = "새 비밀번호 확인";
export const confirm = "확인";
export const manage = "관리";

// Menu
export const menu_dashboard = "대시보드";
export const menu_running_history = "운행 이력";
export const menuCarInfo = "차량 정보";
export const menuCarList = "차량";
export const menu_stats = "통계";

export const menu_device = "장치 관리";
export const menu_car_device = "주변 장치";
export const menu_terminal = "단말기";
export const menu_doormodule = "도어모듈";
export const menu_modem = "모뎀";

export const menu_maintenance = "차량 정비";
export const menu_maintenance_history_by_car = "차량별 정비 이력";
export const menu_maintenance_history = "전체 정비 이력";
export const menu_soc = "전압 대비 SOC";
export const menu_alarm = "전체 알람";
export const menu_maintenance_guide = "정기점검 지침";

export const menu_issue = "유지보수";
export const menu_issue_history = "유지보수 이력";
export const menu_issue_soc = "차량 SOC 점검";

export const menu_system_setting = "환경 설정";
export const menu_category = "차량 분류 관리";
export const menu_maker = "제조사/모델 관리";
export const menu_customer = "고객 관리자/고객 관리";
export const menu_user = "사용자 관리";
export const menu_group = "알림 수신 그룹 관리";
export const menu_service = "서비스 관리";
export const menu_version = "차량 SW 버전 관리";
export const menu_mypage = "내 정보";

//dashboard
export const mainBattery = "메인 배터리";
export const subBattery = "보조 배터리";
export const vehicleStatus = "차량 현황";
export const alarmInfo = "금일 알람 정보";
export const more = "더보기";
export const runInfo = "최근 운행 정보";

//car
export const car = "차량";
export const carList = "차량 목록";
export const carInfo = "차량 정보";
export const registerCarInfo = "차량 정보 등록";
export const updateCarInfo = "차량 정보 수정";

export const carSocList = "차량 SOC 목록";

export const basicInfo = "기본 정보";
export const registerBasicInfo = "기본 정보 등록";
export const updateBasicInfo = "기본 정보 수정";
export const bmsVersion = "BMS 버전";
export const vcuVersion = "VCU 버전";
export const description = "설명";
export const optionalInfo = "추가 정보";
export const registerOptionalInfo = "추가 정보 등록";
export const updateOptionalInfo = "추가 정보 수정";
export const detailInfo = "상세 정보";
export const carNumber = "차량 번호";
export const identityNumber = "차대 번호";
export const phone = "연락처";
export const maker = "제조사";
export const type = "차량 분류";
export const totalRunningDistance = "총 주행 거리";
export const createdDate = "등록일";
export const quickMenu = "퀵메뉴";
export const carModel = "차량 모델";
export const carImage = "차량 이미지";
export const terminalSystemVersion = "단말기 시스템";
export const terminalMCUVersion = "단말기 MCU";
export const terminalModemSerialNumber = "단말기 모뎀 S/N";
export const doorModuleSWVersion = "도어모듈 SW";
export const run = "운행";
export const stat = "통계";
export const runningStatus = "운행 상태";
export const runningEndDate = "운행 종료";
export const running = "운행중";
export const runningEnd = "운행종료";
export const batteryTemperature = "배터리 온도 그래프";
export const periodHour = "시간";
export const periodMinute = "분";
export const periodYear = "연별";
export const periodMonth = "월별";
export const periodDay = "일별";
export const history = "변경 이력";
export const carHistory = "차량 정보 변경 이력";
export const doorModuleHistory = "도어모듈 이력";
export const changeTime = "변경일";
export const noHistoryMsg = "차량번호 변경 이력이 없습니다.";
export const duplicateMsg = "차량 이미지 파일 이름이 중복입니다.";

//running history
export const runningvehicleList = "전체 운행 이력";
export const vehicleRunningHistory = "차량별 운행 이력";
export const vehicleRunningStats = "차량별 운행 통계";

export const runningDate = "운행일";
export const startTime = "운행 시작";
export const endTime = "운행 종료";
export const runningTime = "운행 시간";
export const traveledDistance = "운행 거리";
export const avgSpeed = "평균 속도";
export const electricMileage = "전비";

export const searchOption = "검색 조건";
export const searchDate = "검색 기간";
export const eventType = "이벤트 분류";
export const displayItem = "표시 항목";

export const runningStats = "운행 통계";
export const runningList = "운행 이력";
export const error = "에러";
export const warning = "경고";

export const kmDistanceUnit = "(km)";
export const kmSpeedUnit = "(km/h)";
export const kmElectricMileageUnit = "(km/kw)";
export const percentUnit = "(%)";
export const tempUnit = "(°C)";
export const voltUnit = "(V)";

//issue
export const issue = "정비";
export const issueInfo = "정비 정보";
export const registerIssueInfo = "정비 정보 등록";
export const updateIssueInfo = "정비 정보 수정";
export const occurrenceDate = "발생 일시";
export const issueAction = "조치 내용";
export const attachment = "첨부파일";
export const issueCount = "정비 건수";
export const lastIssueTime = "최근 이슈 발생 일시";

export const listByCar = "차량별 목록";
export const issueList = "이슈별 목록";
export const vehicleIssueList = "차량별 이슈 목록";

export const actionStatus = "조치 상태";
export const actionTime = "조치 일시";
export const actionInfo = "조치 정보";

export const issueDate = "발생 일시";
export const receiptDate = "접수일";
export const issueType = "정비 분류";
export const issueContent = "이슈 내용";
export const waiting = "대기";
export const action = "조치중";
export const completion = "완료";
export const upload = "업로드";

//delivery
export const delivery = "출고";
export const deliveryStatus = "출고 현황";
export const infoDelivery = "출고 정보";
export const listDelivery = "출고 목록";
export const registerDelivery = "출고 현황 등록";
export const updateDelivery = "출고 현황 수정";
export const infoDeliveryStatus = "출고 현황 정보";
export const updateContract = "계약 정보 수정";
export const infoContract = "계약 정보";
export const updateModify = "정비 정보 수정";
export const infoModify = "정비 정보";

export const rentalRegisterNumber = "렌트 번호";
export const newRegisterNumber = "신규 번호";
export const tuningType = "튜닝 타입";
export const uniqueKey = "고유키";
export const buyerName = "구매자";
export const deliveryRegion = "출하 지역";
export const deliveryDate = "출하 일자";
export const vehicleType = "차량 분류";
export const taxInvoiceIssueDate = "세금계산서";
export const vehicleColor = "차량 색상";
export const consignmentMethod = "탁송";
export const facilityInformation = "설비 정보";
export const equippedMonitor = "모니터";
export const equippedChargerReleaseLever = "충전기 해제레버";
export const equippedGroundSeparation = "접지 분리";
export const equippedBlowerPan = "블로워팬 사상";
export const equippedAirConditioner = "에어컨 매꾸라(신규)";
export const equippedDoorModule = "폰키 설치";
export const filledRefrigerant = "에어컨 작동(냉매)";
export const waterproofBathroom = "욕실 방수";
export const note = "비고";

export const startDate = "정비입고";
export const startDescription = "정비입고 부가설명";
export const endDate = "정비출고";
export const endDescription = "정비출고 부가설명";
export const dcdcConDate = "DC DC Con";
export const dcdcConDescription = "DC DC Con 부가설명";
export const waterPumpDate = "Water Pump";
export const waterPumpDescription = "Water Pump 부가설명";
export const battDate = "BAT";
export const battDescription = "BAT 부가설명";
export const dcdcBattDate = "접지 보강";
export const dcdcBattDescription = "접지 보강 부가설명";
export const bmsJunctionBoxDate = "BMS 정션박스";
export const bmsJunctionBoxDescription = "BMS 정션박스 부가설명";

export const downPayment = "계약금";
export const balance = "잔금";
export const moneyBack = "환수금";
export const ownershipChangeDate = "명의이전승인";
export const ownershipChangeDescription = "명의이전 승인 부가설명";
export const insuranceCancelDate = "보험해지";
export const insuranceCancelDescription = "보험해지 부가설명";
export const registerDescription = "부가설명 작성";

export const selectCity = "시/도 선택";
export const selectDistrict = "구/군 선택";
export const noDeliveryMsg = "출고 현황이 없습니다.";

//terminal
export const terminal = "단말기";
export const listTerminal = "단말기 목록";
export const infoTerminal = "단말기 정보";
export const registerTerminal = "단말기 정보 등록";
export const updateTerminal = "단말기 정보 수정";

export const serialNumber = "S/N";
export const terminalModel = "단말기 모델";
export const systemVersion = "시스템 버전";
export const mcuVersion = "MCU 버전";
export const terminalType = "단말기 분류";
export const equippedDate = "장착일";
export const modem = "모뎀";
export const fmu = "FMU";
export const fmuMini = "FMU_MINI";

//modem
export const listModem = "모뎀 목록";
export const infoModem = "모뎀 정보";
export const regitsterModem = "모뎀 정보 등록";
export const updateModem = "모뎀 정보 수정";

export const phoneNumber = "전화번호";
export const modelName = "모델명";
export const carrier = "통신사";
export const SKT = "SKT";
export const KT = "KT";
export const Uplus = "LG U+";
export const UplusValue = "LGU_PLUS";

//doorModule
export const doormodule = "도어 모듈";
export const listDoorModule = "도어모듈 목록";
export const infoDoorModule = "도어모듈 정보";
export const registerDoorModule = "도어모듈 정보 등록";
export const updateDoorModule = "도어모듈 정보 수정";
export const doorModuleStatusHistory = "도어 상태 이력";
export const subBatteryHistory = "보조배터리 이력";

export const swVersion = "S/W 버전";
export const doorStatus = "도어 상태";
export const swingDoorStatus = "스윙도어 상태";
export const subBatteryVoltage = "보조 배터리 전압";
export const historyTime = "일시";
export const voltage = "보조배터리 전압";
export const subBatteryStatus = "상태";
export const normal = "정상";
export const low = "낮음";
export const veryLow = "매우 낮음";
export const imei = "IMEI";

//alarm
export const listAlarm = "전체 알람 목록";

export const alarmType = "알람 분류";
export const alarmName = "알람 이름";
export const alarmValue = "알람 값";
export const alarmTime = "발생 시간";
export const alarmStatus = "알람 상태";
export const analysis = "분석 결과";
export const analysisLevel = "분석 레벨";
export const level = "레벨";

//service
export const listService = "서비스 목록";
export const infoService = "서비스 정보";
export const registerService = "서비스 정보 등록";
export const updateService = "서비스 정보 수정";
export const license = "라이선스";

export const service = "서비스";
export const serviceName = "서비스명";
export const company = "사업자";
export const serviceManager = "서비스 담당자";
export const serviceDescription = "서비스 설명";
export const managerName = "담당자 이름";
export const managerEmail = "담당자 이메일";
export const managerMobileNumber = "담당자 연락처";
export const token = "라이선스 키";
export const expiredDate = "유효 기간";
export const reissue = "재발급";

//code
export const category = "차량 분류";

//suggest
export const listSuggest = "건의 목록";
export const infoSuggest = "건의 정보";
export const registerSuggest = "건의 등록";
export const updateSuggest = "건의 정보 수정";

export const title = "제목";
export const writer = "작성자";
export const chargingManager = "담당자";
export const suggestState = "상태";
export const answerDate = "완료 일시";

//group
export const listGroup = "알림 수신 그룹 목록";
export const infoGroup = "알림 수신 그룹 정보";
export const registerGroup = "알림 수신 그룹 등록";
export const updateGroup = "알림 수신 그룹 수정";

export const alarmGroup = "알림 수신 그룹";
export const group = "수신 그룹";
export const groupName = "수신 그룹 이름";
export const receiver = "수신자";
export const receiverName = "수신자 이름";
export const groupCount = "수신자 인원";
export const receiverStatus = "수신 상태";
export const registerId = "등록자 ID";
export const sms = "문자";
export const receiveSettings = "수신 설정";
export const receiverSettings = "수신인 설정";
export const vehicleSettings = "차량 설정";

//customer
export const listCustomer = "고객 목록";
export const infoCustomer = "고객 정보";
export const registerCustomer = "고객 정보 등록";
export const updateCustomer = "고객 정보 수정";

export const customerName = "고객 이름";
export const customerPhone = "고객 연락처";

//category
export const listCategory = "차량 분류 목록";
export const infoCategory = "차량 분류 정보";
export const registerCategory = "차량 분류 정보 등록";
export const updateCategory = "차량 분류 정보 수정";

//category
export const listMaker = "제조사 목록";
export const infoMaker = "제조사 정보";
export const registerMaker = "제조사 정보 등록";
export const updateMaker = "제조사 정보 수정";

//stats
export const summary = "개요";
export const canDataAccrue = "누적 차량 데이터";
export const totalDrivingAccrue = "누적 운행 건수";
export const totalDrivingTimeAccrue = "누적 운행 시간";
export const carDataCount = "차량 데이터";
export const drivingCount = "운행 건수";
export const statsCarByCategory = "분류별 차량수";
export const statsCarByMaker = "제조사별 차량수";

//makerModel
export const makerModel = "제조사 모델";
export const addModel = "모델 추가";
export const modelCount = "모델 ";

//part
export const listPartAll = "전체 부품 목록";
export const listPart = "부품 목록";
export const listPartByCar = "차량별 부품 목록";
export const partInfo = "부품 정보";
export const partInfoHistory = "부품 정보 변경 이력";
export const registerPart = "부품 정보 등록";
export const updatePart = "부품 정보 수정";
export const partName = "부품명";
export const model = "모델";
export const inverter = "인버터";
export const battery = "배터리";
export const OBC = "OBC";
export const LDC = "LDC";

export const maxTemp = "최고 온도";
export const minTemp = "최저 온도";
export const maxVolt = "최고 전압";
export const minVolt = "최저 전압";
export const soc = "SOC";

// title
export const title_name = "ezMobility";
export const title_emg = "EMG";

// swVer
export const version = "버전";
export const versionDate = "버전 일자";
export const carVerion = "차량 S/W 버전";
