import * as React from "react";
import ChartRunningsByCar from "../common/ChartRunningsByCar";
import ChartBatteryTemp from "../common/ChartBatteryTemp";
import Space from "antd/es/space/index";

const TabStats = ({ carId }: { carId?: number }) => {
    return (
        <Space direction="vertical" size={24} style={{ display: "flex" }}>
            <ChartRunningsByCar carId={carId} />
            <ChartBatteryTemp carId={carId} />
        </Space>
    );
};

export default TabStats;
