import { useState, useEffect } from "react";
import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestModem from "../../utils/requestApiModem";

import DatePicker from "antd/es/date-picker/index";
import Input from "antd/es/input/index";
import Modal from "antd/es/modal/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";
import CustomDatePicker from "../../components/common/CustomDatePicker";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";
import moment from "moment";
import dayjs from "dayjs";

type SearchType = {
    serialNumber: string;
    phone: string;
    carNumber: string;
    carrier: string;
};

const PageModem = () => {
    const userDetails = useAuthState();
    const alert = useAlert();
    const { Option } = Select;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [type, setType] = useState<viewType>("view");
    const [modem, setModem] = useState<TypeDTO.TerminalModemDto>();
    const [modems, setModems] = useState<Array<TypeDTO.TerminalModemDto>>();
    const [filteredModems, setFilteredModems] = useState<Array<TypeDTO.TerminalModemDto>>();
    const [activatedDate, setActivatedDate] = useState<string>();

    useEffect(() => {
        requestApiModemList();
    }, []);

    const getValueByKey = (key: string) => {
        if (key in Utils.carrier) {
            return Utils.carrier[key as keyof typeof Utils.carrier];
        }
        return "";
    };

    const columns: ColumnsType<TypeDTO.TerminalModemDto> = [
        {
            title: String.serialNumber,
            dataIndex: "serialNumber",
            key: "serialNumber",
            width: "10%",
            align: "center",
        },
        {
            title: String.carrier,
            dataIndex: "carrier",
            key: "carrier",
            width: "10%",
            align: "center",
            render: (_, { carrier }) => Utils.carrier[carrier] || String.dash,
        },
        {
            title: String.phoneNumber,
            dataIndex: "phone",
            key: "phone",
            width: "10%",
            align: "center",
            render: (_, { phone }) => Utils.convertPhone(phone) || String.dash,
        },
        {
            title: String.equippedDate,
            dataIndex: "equippedDate",
            key: "equippedDate",
            width: "10%",
            align: "center",
            render: (_, { equippedDate }) => (equippedDate ? moment(equippedDate, Common.FORMAT_DATE_TIME).format(Common.FORMAT_DATE) : String.dash),
        },
        {
            title: String.carNumber,
            dataIndex: ["car", "carNumber"],
            key: "carNumber",
            width: "10%",
            align: "center",
            render: (_, { car }) => car?.carNumber || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRow("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(row.terminalModemId, row.serialNumber);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "serialNumber",
            span: 12,
            title: String.serialNumber,
            type: "select",
            options: modems?.map((modem) => ({ value: modem.serialNumber, label: modem.serialNumber })),
        },
        {
            id: "phone",
            span: 12,
            title: String.phoneNumber,
            type: "select",
            options: modems?.filter((modem) => modem?.phone).map((modem) => ({ value: modem.phone, label: Utils.convertPhone(modem.phone) })),
        },
        {
            id: "carrier",
            span: 12,
            title: String.carrier,
            type: "select",
            options: Object.keys(Utils.carrier).map((key) => ({ value: key, label: getValueByKey(key) })),
        },
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
    ];

    const contents: Array<TypeUtils.formType> = [
        {
            name: "serialNumber",
            label: String.serialNumber,
            span: 24,
            required: true,
        },
        {
            name: "carrier",
            label: String.carrier,
            span: 12,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="통신사를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                    options={Object.keys(Utils.carrier).map((key) => ({ value: key, label: getValueByKey(key) }))}
                >
                    {Object.keys(Utils.carrier)?.map((key) => (
                        <Option key={key} value={key}>
                            {getValueByKey(key)}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            name: "phone",
            label: String.phoneNumber,
            span: 12,
            rules: [
                {
                    pattern: Common.PHONE_REGEXP,
                    message: "유효한 전화번호 형식이 아닙니다",
                },
            ],
            input: <Input className={type === "view" ? "disabled-input" : ""} disabled={type === "view"} />,
        },
        {
            name: "activatedDate",
            label: "개통일",
            span: 12,
            input: (
                <CustomDatePicker
                    hasTime={false}
                    dateFormat={Common.FORMAT_DATE_TIME}
                    allowClear={true}
                    className={type === "view" ? "disabled-datepicker" : ""}
                    disabled={type === "view"}
                    onChange={(value: string | undefined) => {
                        setActivatedDate(value);
                    }}
                />
            ),
        },
        {
            name: "ratePlan",
            label: "요금제",
            span: 12,
        },
        {
            name: "contractTerm",
            label: "약정 기간",
            span: 12,
        },
        {
            name: "model",
            label: "모델",
            span: 12,
        },
        {
            name: "equippedDate",
            label: String.equippedDate,
            span: 24,
            input: <DatePicker className={type === "view" ? "disabled-datepicker" : ""} disabled={type === "view"} />,
        },
        {
            name: ["car", "carNumber"],
            label: String.carNumber,
            span: modem?.car ? 12 : 24,
            disabled: true,
        },
        {
            name: ["car", "carId"],
            label: "carId",
            span: 0,
            disabled: true,
        },
        {
            name: ["car", "makerModel", "maker", "makerName"],
            label: String.maker,
            span: modem?.car ? 12 : 0,
            disabled: true,
        },
        {
            name: ["car", "customer", "customerManager", "customerManagerName"],
            label: String.customerManager,
            span: modem?.car ? 12 : 0,
            disabled: true,
        },
        {
            name: ["car", "customer", "customerName"],
            label: String.customer,
            span: modem?.car ? 12 : 0,
            disabled: true,
        },
    ];

    const { loadingApiModemList, requestApiModemList, resultApiModemList } = RequestModem.useRequestApiModemList();

    useEffect(() => {
        if (!resultApiModemList) return;

        setModems(resultApiModemList.terminalModems);
    }, [resultApiModemList]);

    const { requestApiModemRegister, resultApiModemRegister } = RequestModem.useRequestApiModemRegister();

    useEffect(() => {
        if (!resultApiModemRegister) return;

        onResult("register");
    }, [resultApiModemRegister]);

    const { requestApiModemUpdate, resultApiModemUpdate } = RequestModem.useRequestApiModemUpdate();

    useEffect(() => {
        if (!resultApiModemUpdate) return;

        onResult("edit");
    }, [resultApiModemUpdate]);

    const { requestApiModemDelete, resultApiModemDelete } = RequestModem.useRequestApiModemDelete();

    useEffect(() => {
        if (!resultApiModemDelete) return;

        onResult("delete");
    }, [resultApiModemDelete]);

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;
        setFilteredModems(
            modems?.filter(
                (modem) =>
                    searchFilter(modem, value?.serialNumber, "serialNumber") &&
                    searchFilter(modem, value?.phone, "phone") &&
                    searchFilter(modem?.car, value?.carNumber, "carNumber") &&
                    searchFilter(modem, value?.carrier, "carrier")
            )
        );
    };

    const onRow = (type: viewType, value?: TypeDTO.TerminalModemDto) => {
        setModem(value);
        setIsOpen(true);
        setType(type);
    };

    const onDelete = (id: number, serialNumber: string) => {
        Modal.confirm({
            title: String.modem + String.remove,
            content: Utils.addParticle(serialNumber, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                requestApiModemDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinish = (type: viewType, value: TypeDTO.TerminalModemDto) => {
        const searchValue = {
            ...value,
            phone: value?.phone?.replaceAll("-", ""),
            equippedDate: value.equippedDate && dayjs(value.equippedDate).format(Common.FORMAT_DATE_TIME),
            activatedDate: activatedDate && dayjs(activatedDate).format(Common.FORMAT_DATE_TIME),
        };
        type === "register" ? requestApiModemRegister(searchValue) : requestApiModemUpdate(modem?.terminalModemId, searchValue);
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.modem} ${typeText} 성공`, `${String.modem} 정보를 ${typeText}하였습니다.`);
        requestApiModemList();

        type !== "delete" && setIsOpen(false);
    };

    return (
        <div className="pageWrapper">
            <Search
                title={String.listModem}
                dataLength={filteredModems?.length || modems?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
                onClick={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? () => onRow("register", undefined) : undefined
                }
            />
            <DataTable
                rowKey={(row: TypeDTO.TerminalModemDto) => row.terminalModemId}
                disabledTitle
                loading={loadingApiModemList}
                columns={
                    Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                        ? columns
                        : columns.filter((column) => column.key !== "detail")
                }
                dataSource={filteredModems || modems}
                onRow={(value) => onRow("view", value)}
            />
            <CustomModal
                title={String.modem}
                open={isOpen}
                type={type}
                value={type === "view" ? { ...modem, phone: Utils.convertPhone(modem?.phone) } : modem}
                contents={contents}
                onChangedOpen={() => setIsOpen(false)}
                onChangedType={(value) => setType(value)}
                onFinish={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? onFinish : undefined}
            />
        </div>
    );
};

export default PageModem;
