import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { actionSetIssues } from "../../utils/action";
import type { ColumnsType } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as RequestIssue from "../../utils/requestApiIssue";

import moment from "moment";
import Space from "antd/es/space/index";
import DataTable from "../../components/common/DataTable";
import Filter from "../../components/mobile/Filter";
import CarSelector from "../../components/mobile/CarSelector";
import IconRedExclamtion from "../../assets/images/icon/icon_red_exclamation.png";
import IconInAction from "../../assets/images/icon/icon_in_action.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

export type SearchType = {
    carNumber: string;
    makerName: string;
    categoryName: string;
    idNumber: string;
    customerName: string;
    customerManagerName: string;
};

export type CarIssueDto = {
    car: TypeDTO.CarDto;
    issueLevel: "RED" | "YELLOW" | "BLUE";
    actionStatus: "WAITING" | "IN_ACTION" | "COMPLETED";
    lastIssueTime: string;
    count: number;
    issues: Array<TypeDTO.IssueDto>;
};

const PageMaintenanceByIssue = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const reducerIssues = useSelector((state) => state.issues);

    const [searchValue, setSearchValue] = useState<SearchType>();
    const [selectedManagerName, setSelectedManagerName] = useState<string>();
    const [issues, setIssues] = useState<Array<CarIssueDto>>();

    const columns: ColumnsType<CarIssueDto> = [
        {
            title: String.status,
            dataIndex: "actionStatus",
            key: "actionStatus",
            align: "center",
            width: "5%",
            render: (_, { actionStatus }) => {
                return actionStatus === "WAITING" ? (
                    <img alt="icon" src={IconRedExclamtion} width={20} height={20} />
                ) : actionStatus === "COMPLETED" ? (
                    <img alt="icon" src={IconInAction} width={20} height={20} />
                ) : actionStatus === "IN_ACTION" ? (
                    <img alt="icon" src={IconCheck} width={20} height={20} />
                ) : (
                    String.dash
                );
            },
        },
        {
            title: String.carNumber,
            dataIndex: ["car", "carNumber"],
            key: "carNumber",
            align: "center",
            width: "15%",
        },
        {
            title: "건수",
            dataIndex: "count",
            key: "count",
            align: "center",
            width: "9%",
            render: (_, row) => row?.count || 0,
        },
        {
            title: String.receiptDate,
            dataIndex: "lastIssueTime",
            key: "lastIssueTime",
            align: "center",
            width: "15%",
            render: (_, { lastIssueTime }) => (lastIssueTime ? moment(lastIssueTime).format(Common.FORMAT_SHORT_DATE) : "-"),
        },
    ];

    const filterData: TypeUtils.SearchItemType[] = [
        {
            id: "makerName",
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "idNumber",
            title: String.identityNumber,
            type: "select",
            typeDetail: "idNumber",
        },
        {
            id: "customerManagerName",
            title: String.customerManager,
            type: "select",
            typeDetail: "customerManager",
        },
        {
            id: "customerName",
            title: String.customer,
            type: "select",
            typeDetail: "customer",
        },
    ];

    const [filter, setFilter] = useState<TypeUtils.SearchItemType[]>();

    useEffect(() => {
        setTimeout(() => {
            document.querySelector(".ant-table-body").scrollTo({ top: reducerIssues.scrollPosition });
        }, 100);
    }, [issues]);

    useEffect(() => {
        if (Object.keys(reducerIssues).length > 2) {
            setFilter(
                filterData?.map((item) => {
                    const { id, defaultValue } = item;

                    return {
                        ...item,
                        defaultValue: reducerIssues[id] ?? defaultValue,
                    };
                })
            );

            setSearchValue(reducerIssues);
        } else {
            setFilter(filterData);
            requestApiIssueByCar();
        }
    }, [reducerIssues]);

    const dispatchIssues = (selectedId: number) => {
        dispatch(
            actionSetIssues({
                ...searchValue,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-table-body").scrollTop,
            })
        );
    };

    const onSearchData = (value?: SearchType) => {
        setSelectedManagerName(value?.customerManagerName);
        setSearchValue(value);
        requestApiIssueByCar(value);
    };

    const { loadingApiIssueByCar, requestApiIssueByCar, resultApiIssueByCar } = RequestIssue.useRequestApiIssueByCar();

    useEffect(() => {
        if (!resultApiIssueByCar) return;

        setIssues(resultApiIssueByCar.carIssues);
    }, [resultApiIssueByCar]);

    return (
        <div className="pageWrapper">
            <CarSelector
                selectedCarNumber={searchValue?.carNumber}
                onSelect={(id?: number) => {}}
                onSelectCarNumber={(carNumber?: string) => {
                    onSearchData({ ...searchValue, carNumber: carNumber });
                }}
            />
            <Space direction="vertical">
                <Filter
                    dataLength={issues?.length}
                    values={filter}
                    selectedName={selectedManagerName}
                    onSearch={(value) => {
                        onSearchData({ ...value, carNumber: searchValue?.carNumber });
                    }}
                />
                <DataTable
                    rowKey={(row: CarIssueDto) => row.car?.carId}
                    disabledTitle
                    disabledPagination
                    loading={loadingApiIssueByCar}
                    isMobile
                    columns={columns}
                    dataSource={issues}
                    rowClassName={(value: CarIssueDto) => {
                        return value?.car?.carId === reducerIssues?.selectedId ? "table-row-selected" : "";
                    }}
                    onRow={(value: CarIssueDto) => {
                        dispatchIssues(value.car?.carId);
                        navigate(Common.PAGE_MANAGE_MAINTENANCES_HISTORY + "/" + value.car?.carNumber);
                    }}
                    scroll="calc(var(--vh, 1vh) * 100 - 292px)"
                />
            </Space>
        </div>
    );
};

export default PageMaintenanceByIssue;
