import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiTerminalRegister = () => {
    const requestAxiosApiTerminalRegister = async (data: TypeDTO.TerminalDto) => {
        const response = await utilAxiosWithAuth().post(Request.TERMINAL_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiTerminalRegister,
        error: errorApiTerminalRegister,
        data: resultApiTerminalRegister,
        execute: requestApiTerminalRegister,
    } = useAsyncAxios(requestAxiosApiTerminalRegister);

    useEffect(() => {
        if (resultApiTerminalRegister === null) return;
        DEBUG && console.log("resultApiTerminalRegister", resultApiTerminalRegister);
    }, [resultApiTerminalRegister]);

    useEffect(() => {
        if (errorApiTerminalRegister === null) return;
        DEBUG && console.log("errorApiTerminalRegister", errorApiTerminalRegister);
    }, [errorApiTerminalRegister]);

    return {
        loadingApiTerminalRegister,
        errorApiTerminalRegister,
        resultApiTerminalRegister,
        requestApiTerminalRegister,
    };
};

export const useRequestApiTerminalList = () => {
    const requestAxiosApiTerminalList = async () => {
        const response = await utilAxiosWithAuth().get(Request.TERMINALS_URL);

        return response.data;
    };

    const {
        loading: loadingApiTerminalList,
        error: errorApiTerminalList,
        data: resultApiTerminalList,
        execute: requestApiTerminalList,
    } = useAsyncAxios(requestAxiosApiTerminalList);

    useEffect(() => {
        if (resultApiTerminalList === null) return;
        DEBUG && console.log("resultApiTerminalList", resultApiTerminalList);
    }, [resultApiTerminalList]);

    useEffect(() => {
        if (errorApiTerminalList === null) return;
        DEBUG && console.log("errorApiTerminalList", errorApiTerminalList);
    }, [errorApiTerminalList]);

    return {
        loadingApiTerminalList,
        errorApiTerminalList,
        resultApiTerminalList,
        requestApiTerminalList,
    };
};

export const useRequestApiTerminalDeleteList = () => {
    const requestAxiosApiTerminalDeleteList = async (terminalIds: number[]) => {
        const response = await utilAxiosWithAuth().delete(Request.TERMINALS_URL, { data: terminalIds });

        return response.data;
    };

    const {
        loading: loadingApiTerminalDeleteList,
        error: errorApiTerminalDeleteList,
        data: resultApiTerminalDeleteList,
        execute: requestApiTerminalDeleteList,
    } = useAsyncAxios(requestAxiosApiTerminalDeleteList);

    useEffect(() => {
        if (resultApiTerminalDeleteList === null) return;
        DEBUG && console.log("resultApiTerminalDeleteList", resultApiTerminalDeleteList);
    }, [resultApiTerminalDeleteList]);

    useEffect(() => {
        if (errorApiTerminalDeleteList === null) return;
        DEBUG && console.log("errorApiTerminalDeleteList", errorApiTerminalDeleteList);
    }, [errorApiTerminalDeleteList]);

    return {
        loadingApiTerminalDeleteList,
        errorApiTerminalDeleteList,
        resultApiTerminalDeleteList,
        requestApiTerminalDeleteList,
    };
};

export const useRequestApiTerminalInfo = () => {
    const requestAxiosApiTerminalInfo = async (terminalId: number) => {
        const response = await utilAxiosWithAuth().get(Request.TERMINAL_URL + terminalId);

        return response.data;
    };

    const {
        loading: loadingApiTerminalInfo,
        error: errorApiTerminalInfo,
        data: resultApiTerminalInfo,
        execute: requestApiTerminalInfo,
    } = useAsyncAxios(requestAxiosApiTerminalInfo);

    useEffect(() => {
        if (resultApiTerminalInfo === null) return;
        DEBUG && console.log("resultApiTerminalInfo", resultApiTerminalInfo);
    }, [resultApiTerminalInfo]);

    useEffect(() => {
        if (errorApiTerminalInfo === null) return;
        DEBUG && console.log("errorApiTerminalInfo", errorApiTerminalInfo);
    }, [errorApiTerminalInfo]);

    return {
        loadingApiTerminalInfo,
        errorApiTerminalInfo,
        resultApiTerminalInfo,
        requestApiTerminalInfo,
    };
};

export const useRequestApiTerminalUpdate = () => {
    const requestAxiosApiTerminalUpdate = async (terminalId: number, data: TypeDTO.TerminalDto) => {
        const response = await utilAxiosWithAuth().put(Request.TERMINAL_URL + terminalId, data);

        return response.data;
    };

    const {
        loading: loadingApiTerminalUpdate,
        error: errorApiTerminalUpdate,
        data: resultApiTerminalUpdate,
        execute: requestApiTerminalUpdate,
    } = useAsyncAxios(requestAxiosApiTerminalUpdate);

    useEffect(() => {
        if (resultApiTerminalUpdate === null) return;
        DEBUG && console.log("resultApiTerminalUpdate", resultApiTerminalUpdate);
    }, [resultApiTerminalUpdate]);

    useEffect(() => {
        if (errorApiTerminalUpdate === null) return;
        DEBUG && console.log("errorApiTerminalUpdate", errorApiTerminalUpdate);
    }, [errorApiTerminalUpdate]);

    return {
        loadingApiTerminalUpdate,
        errorApiTerminalUpdate,
        resultApiTerminalUpdate,
        requestApiTerminalUpdate,
    };
};

export const useRequestApiTerminalDelete = () => {
    const requestAxiosApiTerminalDelete = async (terminalId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.TERMINAL_URL + terminalId);

        return response.data;
    };

    const {
        loading: loadingApiTerminalDelete,
        error: errorApiTerminalDelete,
        data: resultApiTerminalDelete,
        execute: requestApiTerminalDelete,
    } = useAsyncAxios(requestAxiosApiTerminalDelete);

    useEffect(() => {
        if (resultApiTerminalDelete === null) return;
        DEBUG && console.log("resultApiTerminalDelete", resultApiTerminalDelete);
    }, [resultApiTerminalDelete]);

    useEffect(() => {
        if (errorApiTerminalDelete === null) return;
        DEBUG && console.log("errorApiTerminalDelete", errorApiTerminalDelete);
    }, [errorApiTerminalDelete]);

    return {
        loadingApiTerminalDelete,
        errorApiTerminalDelete,
        resultApiTerminalDelete,
        requestApiTerminalDelete,
    };
};
