import { useEffect, useState, useCallback } from "react";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import CustomForm, { viewType } from "./CustomForm";
import { Header } from "antd/es/layout/layout";
import { FloatingPanel } from "antd-mobile";
import { LeftOutlined } from "@ant-design/icons";
import Button from "antd/es/button/button";
import Form from "antd/es/form/index";
import Input from "antd/es/input/index";
import Select from "antd/es/select/index";
import LockOutlined from "@ant-design/icons/lib/icons/LockOutlined";

import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestMember from "../../utils/requestAuthMember";

import styles from "./Filter.module.css";

function MyPagePopup({ type, onChangeOpen }: { type: viewType; onChangeOpen?: (isOpen: boolean) => void }) {
    const alert = useAlert();
    const userDetails = useAuthState();
    const { Option } = Select;

    const [form] = Form.useForm();
    const [formType, setFormType] = useState<viewType>("view");
    const [isPassword, setIsPassword] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<TypeDTO.UserDto>();

    useEffect(() => {
        requestAuthMemberInfo(userDetails?.user?.username);
    }, []);

    useEffect(() => {
        isPassword && setFormType("edit");
    }, [isPassword]);

    useEffect(() => {
        setFormType(type);
    }, [type]);

    const { requestAuthMemberInfo, resultAuthMemberInfo } = RequestMember.useRequestAuthMemberInfo();

    useEffect(() => {
        if (!resultAuthMemberInfo) return;
        setUserInfo(resultAuthMemberInfo?.user);
        form.setFieldsValue({ ...resultAuthMemberInfo?.user, phone: Utils.convertPhone(resultAuthMemberInfo?.user?.phone) });
    }, [resultAuthMemberInfo]);

    const { requestAuthMemberUpdate, resultAuthMemberUpdate } = RequestMember.useRequestAuthMemberUpdate();

    useEffect(() => {
        if (!resultAuthMemberUpdate) return;
        onResult("edit");
    }, [resultAuthMemberUpdate]);

    const onFinish = (type: viewType, value: TypeDTO.UserDto) => {
        requestAuthMemberUpdate(value?.username, { ...value, phone: value?.phone?.replaceAll("-", "") });
        setFormType("view");
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);
        alert.setAlert(AlertType.SUCCESS, `${String.user} ${typeText} 성공`, `${String.user} 정보를 ${typeText}하였습니다.`);
        type !== "delete" && onChangeOpen && onChangeOpen(false);
    };

    const onChangedType = (type: viewType) => {
        setFormType(type);
    };

    const getValueByKey = (key: string) => {
        if (key in Utils.convertAuthority) {
            return Utils.convertAuthority[key as keyof typeof Utils.convertAuthority];
        }
        return "";
    };

    const validatePwd = useCallback((_: any, value: string) => {
        const pwd_regExp = Common.PASSWORD_REGEXP;

        if (value && !pwd_regExp.test(value)) {
            return Promise.reject(new Error(String.msgPwdRule));
        }
        return Promise.resolve();
    }, []);

    const contents: Array<TypeUtils.formType> = [
        {
            name: "username",
            label: String.id,
            required: true,
        },
        {
            name: "name",
            label: String.name,
        },
        {
            name: "phone",
            label: String.phone,
            required: true,
            rules: [
                {
                    type: "phone",
                    message: "전화번호가 유효하지 않습니다.",
                },
            ],
            input: (
                <Input
                    className={formType === "view" ? "disabled-input" : ""}
                    disabled={formType === "view"}
                    placeholder={formType === "view" ? "-" : "연락처"}
                />
            ),
        },
        {
            name: "email",
            label: String.email,
            rules: [
                {
                    type: "email",
                    message: "이메일이 유효하지 않습니다.",
                },
            ],
        },
        {
            name: "authority",
            label: String.authority,
            input: (
                <Select
                    allowClear
                    showSearch
                    className={formType === "view" ? "disabled-selector" : ""}
                    placeholder="권한을 선택해 주세요."
                    disabled={formType === "view" || !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                >
                    {Object.keys(Utils.convertAuthority).map((key) => (
                        <Option key={key} value={key}>
                            {getValueByKey(key)}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: ["customer", "customerId"],
            label: Utils.roleCheck(userDetails?.user?.authority, [
                Utils.getAuthority.ROLE_CUSTOMER,
                Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
            ])
                ? String.customer
                : undefined,
            disabled: true,
            input: (
                <Select
                    className={formType === "view" ? "disabled-selector" : ""}
                    disabled={formType === "view" || !Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                >
                    <Option key={userInfo?.customer?.customerId} value={userInfo?.customer?.customerId}>
                        {userInfo?.customer?.customerName}
                    </Option>
                </Select>
            ),
        },
    ];

    const passwordContents: Array<TypeUtils.formType> = [
        {
            name: "username",
            label: String.id,
            span: 0,
            disabled: true,
        },
        {
            name: "oldPassword",
            label: String.currentPassword,
            span: 24,
            input: (
                <Input.Password
                    name="password"
                    className={`fw-rg fs-md fc-font2 `}
                    placeholder="비밀번호를 입력해 주세요."
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: true,
            rules: [{ validator: validatePwd }, { max: 20, message: String.msgPwdMax }],
        },
        {
            name: "newPassword",
            label: String.newPassword,
            span: 24,
            input: (
                <Input.Password
                    name="password"
                    className={`fw-rg fs-md fc-font2`}
                    placeholder="비밀번호를 입력해 주세요."
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: true,
            rules: [{ validator: validatePwd }, { max: 20, message: String.msgPwdMax }],
        },
        {
            name: "confirm",
            label: String.passwordConfirm,
            span: 24,
            input: (
                <Input.Password
                    name="confirm"
                    className={`fw-rg fs-md fc-font2`}
                    placeholder="비밀번호를 한번 더 입력해 주세요."
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    maxLength={20}
                />
            ),
            required: true,
            rules: [
                ({ getFieldValue }: any) => ({
                    validator(_: any, value: any) {
                        if (!value || getFieldValue("newPassword") === value) {
                            return Promise.resolve();
                        }

                        return Promise.reject(new Error("입력한 비밀번호가 일치하지 않습니다."));
                    },
                }),
            ],
            dependencies: ["password"],
            hasFeedback: true,
        },
    ];

    return (
        <FloatingPanel anchors={[window.innerHeight]} className="fullPanel" handleDraggingOfContent={false}>
            <div className={styles.selectorOverlay}>
                <Header className={styles.overlayHeader}>
                    <LeftOutlined
                        onClick={() => {
                            if (isPassword) {
                                setFormType("view");
                                setIsPassword(false);
                            } else if (formType === "view") {
                                onChangeOpen && onChangeOpen(false);
                            } else {
                                setFormType("view");
                            }
                        }}
                    />
                    <p className="fw-bd fs-lg fc-font1">
                        {isPassword ? "비밀번호 재설정" : `내 정보 ${formType === "view" ? String.view : String.update}`}
                    </p>
                    <LeftOutlined style={{ opacity: 0 }} />
                </Header>
                <div className={styles.overlayContent}>
                    <div style={{ paddingBottom: 64 }}>
                        <CustomForm
                            form={form}
                            type={formType}
                            disabledBtn
                            contents={[{ forms: isPassword ? passwordContents : contents }]}
                            onFinish={onFinish}
                            onChangeType={onChangedType}
                        />
                    </div>
                </div>
                {formType === "view" && (
                    <div className={styles.overlayFooter}>
                        <Button className="btn-secondary" key="user" onClick={() => setIsPassword(true)}>
                            비밀번호 재설정
                        </Button>
                        <Button
                            className="btn-primary"
                            key="user"
                            onClick={() => {
                                setFormType("edit");
                            }}
                        >
                            {String.update}
                        </Button>
                    </div>
                )}
            </div>
        </FloatingPanel>
    );
}

export default MyPagePopup;
