import { useEffect, useState } from "react";
import { useAuthState } from "../../provider/AuthProvider";
import { RadioChangeEvent } from "antd/es/radio/interface";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { Header } from "antd/es/layout/layout";
import { FloatingPanel, Popup, CheckList, SearchBar } from "antd-mobile";
import { CloseCircleFilled, LeftOutlined, RedoOutlined } from "@ant-design/icons";
import { FilterOutlined } from "@ant-design/icons";
import { Button } from "antd";

import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";
import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as RequestUser from "../../utils/requestAuthUser";
import * as RequestCar from "../../utils/requestApiCar";
import * as RequestCategory from "../../utils/requestApiCategory";
import * as RequestMaker from "../../utils/requestApiMaker";
import * as RequestCustomerManager from "../../utils/requestAuthCustomerManager";
import * as RequestCustomer from "../../utils/requestAuthCustomer";

import Input from "antd/es/input/index";
import Checkbox from "antd/es/checkbox/index";
import Radio from "antd/es/radio/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";
import Empty from "antd/es/empty/index";

import produce from "immer";
import CustomRangePicker from "./CustomRangePicker";
import styles from "./Filter.module.css";

import IconError from "../../assets/images/icon/icon_red_exclamation.png";
import IconYellowExclamtion from "../../assets/images/icon/icon_yellow_exclamation.png";
import IconBlueExclamtion from "../../assets/images/icon/icon_blue_exclamation.png";
import IconNormal from "../../assets/images/icon/icon_gray_check.png";
import IconRedExclamtion from "../../assets/images/icon/icon_red_exclamation.png";
import IconInAction from "../../assets/images/icon/icon_in_action.png";
import IconCheck from "../../assets/images/icon/icon_check_circle.png";

import moment from "moment";

const Filter = ({
    title,
    dataLength,
    values,
    onSearch,
}: {
    title?: string;
    dataLength?: number;
    values: Array<TypeUtils.SearchItemType>;
    onSearch: (value: any) => void;
}) => {
    const userDetails = useAuthState();
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [datas, setDatas] = useState<any>({});
    const [users, setUsers] = useState<Array<TypeDTO.UserDto>>();
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [categories, setCategories] = useState<Array<TypeDTO.CategoryDto>>();
    const [makers, setMakers] = useState<Array<TypeDTO.MakerDto>>();
    const [customers, setCustomers] = useState<Array<TypeDTO.CustomerDto>>();
    const [customerManagers, setCustomerManagers] = useState<Array<TypeDTO.CustomerManagerDto>>();
    const [selectedCustomerManagerName, setSelectedCustomerManagerName] = useState<string>();
    const [selectedMakerName, setSelectedMakerName] = useState<string>();

    const [visible, setVisible] = useState<string>();
    const [searchText, setSearchText] = useState<string>();

    useEffect(() => {
        Utils.onEvent(Common.EVENT_CLOSE_ALL_VIEW, handleCloseAllView);
        Utils.onEvent(Common.EVENT_CLOSE_ALL_DRAWER, handleCloseAllDrawer);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_VIEW, handleCloseAllView);
            Utils.offEvent(Common.EVENT_CLOSE_ALL_DRAWER, handleCloseAllDrawer);
        };
    }, []);

    useEffect(() => {
        if (open) {
            Utils.addViewStack();
        } else {
            Utils.popViewStack();
        }
    }, [open]);

    useEffect(() => {
        if (visible) {
            Utils.addDrawerStack();
        } else {
            Utils.popDrawerStack();
            setSearchText(undefined);
        }
    }, [visible]);

    const initData = () => {
        const carNumber = values.find((value) => value.id === "carNumber")?.defaultValue as string;
        const idNumber = values.find((value) => value.id === "idNumber")?.defaultValue as string;
        const manager = values.find((value) => value.id === "customerManagerName")?.defaultValue as string;
        const customer = values.find((value) => value.id === "customerName")?.defaultValue as string;
        const maker = values.find((value) => value.id === "makerName")?.defaultValue as string;
        const model = values.find((value) => value.id === "makerModelId")?.defaultValue as number;
        const category = values.find((value) => value.id === "categoryName")?.defaultValue as string;
        const searchValue = values.find((value) => value.id === "searchValue")?.defaultValue as string;
        const issueManagerName = values.find((value) => value.id === "issueManagerName")?.defaultValue as string;
        const issueType = values.find((value) => value.id === "issueType")?.defaultValue as string;

        setSelectedCustomerManagerName(manager);
        setSelectedMakerName(maker);

        setDatas(
            produce((draft: any) => {
                if (idNumber) draft.idNumber = idNumber;
                if (manager) draft.customerManagerName = manager;
                if (customer) draft.customerName = customer;
                if (maker) draft.makerName = maker;
                if (model) draft.makerModelId = model;
                if (category) draft.categoryName = category;
                if (searchValue) draft.searchValue = searchValue;
                if (issueManagerName) draft.issueManagerName = issueManagerName;
                if (issueType) draft.issueType = issueType;
                Object.assign(draft, onChangeDefaultData());
            })
        );
    };

    useEffect(() => {
        if (!values) return;

        initData();

        Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]);

        const requests = [
            { typeDetail: "user", action: requestAuthUserList, isCheck: true },
            { typeDetail: "name", action: requestAuthUserList, isCheck: true },
            { typeDetail: "car", action: requestApiCarList, isCheck: true },
            { typeDetail: "idNumber", action: requestApiCarList, isCheck: true },
            { typeDetail: "category", action: requestApiCategoryList, isCheck: true },
            { typeDetail: "maker", action: requestApiMakerList, isCheck: true },
            { typeDetail: "model", action: requestApiMakerList, isCheck: true },
            {
                typeDetail: "customerManager",
                action: requestAuthCustomerManagerList,
                isCheck: Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN, Utils.getAuthority.ROLE_MANAGER]),
            },
            {
                typeDetail: "customer",
                action: requestAuthCustomerList,
                isCheck: Utils.roleCheck(userDetails?.user?.authority, [
                    Utils.getAuthority.ROLE_ADMIN,
                    Utils.getAuthority.ROLE_MANAGER,
                    Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                ]),
            },
        ];

        values.forEach(({ typeDetail }) => {
            const request = requests.find((req) => req.typeDetail === typeDetail && req.isCheck);
            if (request) request.action();
        });
    }, [JSON.stringify(values)]);

    useEffect(() => {
        console.log("datas", datas);

        if (Object.keys(datas).length > 0) {
            onSearch(datas);
            setIsFiltered(true);
        } else {
            setIsFiltered(false);
        }
    }, [datas]);

    const onChangeDefaultData = () => {
        const filtedSelectData = values.find((value) => value.type === "select");
        const filtedData = values.find((value) => value.type === "rangePicker");
        const filtedCheckboxData = values.find((value) => value.type === "checkbox");
        const filtedCheckbox2Data = values.find((value) => value.type === "checkbox2");
        const filtedRadioData = values.find((value) => value.type === "radio");
        let defaultData = {};

        if (filtedSelectData && filtedSelectData.defaultValue) {
            if (filtedSelectData.id === "carNumber") {
                defaultData = { ...defaultData, carNumber: filtedSelectData.defaultValue };
            }
        }

        if (filtedData) {
            defaultData = {
                startDate: filtedData.defaultRange
                    ? moment()
                          .add(filtedData.defaultRange, "days")
                          .format(filtedData.dateFormat || Common.FORMAT_DATE)
                    : moment()
                          .add(-7, "days")
                          .format(filtedData.dateFormat || Common.FORMAT_DATE),
                endDate: moment().format(filtedData.dateFormat || Common.FORMAT_DATE),
            };
        }

        if (filtedCheckboxData) {
            defaultData = {
                ...defaultData,
                checkbox: filtedCheckboxData.defaultValue,
            };
        }

        if (filtedCheckbox2Data) {
            defaultData = {
                ...defaultData,
                checkbox2: filtedCheckbox2Data.defaultValue,
            };
        }

        if (filtedRadioData) {
            defaultData = {
                ...defaultData,
                radio: filtedRadioData.defaultValue,
            };
        }

        return defaultData;
    };

    const displayCheckbox = (value: string) => {
        switch (value) {
            case "error":
                return IconError;
            case "warning":
                return IconYellowExclamtion;
            case "normal":
                return IconNormal;

            case "batteryWarning":
                return IconRedExclamtion;
            case "batteryNormal":
                return IconNormal;

            case "waiting":
                return IconRedExclamtion;
            case "inAction":
                return IconInAction;
            case "completed":
                return IconNormal;

            case "red":
                return IconRedExclamtion;
            case "yellow":
                return IconYellowExclamtion;
            case "blue":
                return IconBlueExclamtion;

            default:
                break;
        }
    };

    const getType = (
        id: string,
        title: string,
        type: string,
        typeDetail?: string,
        options?: any,
        defaultValue?: any,
        dateFormat?: string,
        required?: boolean
    ) => {
        const selectOptions = searchText
            ? ((typeDetail && getSelect(typeDetail)) || options)?.filter((option) => option?.label?.includes(searchText))
            : (typeDetail && getSelect(typeDetail)) || options;
        switch (type) {
            case "input":
                return (
                    <Input
                        className={styles.searchInput}
                        value={datas[id]}
                        placeholder={title}
                        onChange={(e) => onChangeEvent(id, e.target.value)}
                    />
                );
            case "select":
                return (
                    <>
                        <Select
                            className={datas[id] ? `allowClearSelect ${styles.searchSelect}` : `${styles.searchSelect}`}
                            value={datas[id]}
                            placeholder={title}
                            onDropdownVisibleChange={(visible) => visible && setVisible(typeDetail)}
                            onClear={() => {
                                onChangeEvent(id, undefined);
                            }}
                            removeIcon={
                                <CloseCircleFilled
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        onChangeEvent(id, undefined);
                                    }}
                                />
                            }
                            allowClear={required === true ? false : true}
                            dropdownStyle={{ display: "none" }}
                            disabled={
                                (typeDetail === "customerManager" &&
                                    !Utils.roleCheck(userDetails?.user?.authority, [
                                        Utils.getAuthority.ROLE_ADMIN,
                                        Utils.getAuthority.ROLE_MANAGER,
                                    ])) ||
                                (typeDetail === "customer" &&
                                    !Utils.roleCheck(userDetails?.user?.authority, [
                                        Utils.getAuthority.ROLE_ADMIN,
                                        Utils.getAuthority.ROLE_MANAGER,
                                        Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                                    ]))
                            }
                        />
                        <Popup
                            className={styles.selectorPopup}
                            visible={visible !== undefined && visible === typeDetail}
                            onMaskClick={() => {
                                setVisible(undefined);
                            }}
                            destroyOnClose
                            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
                        >
                            <div className={styles.selectorContainer}>
                                <Space align="center">
                                    <span className="fw-bd fs-lg">{title}</span>
                                    <span className="fw-rg fs-sm fc-font2">총 {selectOptions?.length}건</span>
                                </Space>
                                {((typeDetail && getSelect(typeDetail)) || options)?.length > 10 && (
                                    <SearchBar
                                        className={styles.selectorSearch}
                                        placeholder="검색어를 입력해 주세요."
                                        value={searchText}
                                        onChange={(value) => {
                                            setSearchText(value);
                                        }}
                                    />
                                )}
                                <CheckList
                                    className={styles.myCheckList}
                                    onChange={(value) => {
                                        onChangeEvent(id, value[0]);
                                        setVisible(undefined);
                                    }}
                                >
                                    {selectOptions?.length > 0 ? (
                                        selectOptions?.map((option: { value: string; label: string }) => (
                                            <CheckList.Item key={option.value} value={option.value}>
                                                {option.label}
                                                {datas[id]?.toString() === option.label && <img src={IconCheck} width={20} height={20} />}
                                            </CheckList.Item>
                                        ))
                                    ) : (
                                        <Empty />
                                    )}
                                </CheckList>
                            </div>
                        </Popup>
                    </>
                );
            case "rangePicker":
                return (
                    <CustomRangePicker
                        onStartDate={(value) => {
                            onChangeEvent(
                                "startDate",
                                value ? moment(value).format(dateFormat || Common.FORMAT_DATE_HOUR_TIME_START) : undefined
                            );
                        }}
                        onEndDate={(value) => {
                            onChangeEvent(
                                "endDate",
                                value ? moment(value).format(dateFormat || Common.FORMAT_DATE_HOUR_TIME_END) : undefined
                            );
                        }}
                    />
                );
            case "checkbox":
            case "checkbox2":
                return (
                    <Checkbox.Group
                        className={styles.checkboxWrapper}
                        onChange={(value: CheckboxValueType[]) => onChangeEvent(id, value)}
                        defaultValue={defaultValue}
                        value={datas[id]}
                    >
                        {options.map((option: any) => {
                            return (
                                <Checkbox
                                    key={option.value}
                                    className={displayCheckbox(option.value) ? "iconCheckbox" : ""}
                                    value={option.value}
                                >
                                    <img alt="icon" src={displayCheckbox(option.value)} width={20} height={20} />
                                    <span className="fw-bd fs-md fc-font1">{option.label}</span>
                                </Checkbox>
                            );
                        })}
                    </Checkbox.Group>
                );
            case "radio":
                return (
                    <Radio.Group
                        className={styles.checkboxWrapper}
                        options={options}
                        onChange={(e: RadioChangeEvent) => onChangeEvent(id, e.target.value)}
                        defaultValue={defaultValue}
                        value={datas[id]}
                    />
                );
            default:
                return;
        }
    };

    const getIssueValueByKey = (key: string) => {
        if (key in Utils.issueType) {
            return Utils.issueType[key as keyof typeof Utils.issueType];
        }
        return "";
    };

    const getSelect = (value: string) => {
        switch (value) {
            case "user":
                return users?.map((user) => ({ value: user.username, label: user.name }));
            case "name":
                return users?.map((user) => ({ value: user.name, label: user.name }));
            case "car":
                return cars?.map((car) => ({ value: car.carNumber, label: car.carNumber }));
            case "idNumber":
                return cars?.map((car) => ({ value: car.idNumber, label: car.idNumber }));
            case "maker":
                return makers?.map((maker) => ({ value: maker.makerName, label: maker.makerName }));
            case "model":
                return (
                    selectedMakerName
                        ? makers?.find((maker) => maker.makerName === selectedMakerName)?.makerModels
                        : makers?.flatMap((maker) => maker.makerModels)
                )?.map((model) => ({
                    value: model.makerModelId,
                    label: model.makerModelName,
                }));
            case "category":
                return categories?.map((category) => ({ value: category.categoryName, label: category.categoryName }));
            case "customerManager":
                return customerManagers?.map((customerManager) => ({
                    value: customerManager.customerManagerName,
                    label: customerManager.customerManagerName,
                }));
            case "customer":
                return (
                    selectedCustomerManagerName
                        ? customers?.filter((customer) => customer?.customerManager?.customerManagerName === selectedCustomerManagerName)
                        : customers
                )?.map((customer) => ({ value: customer.customerName, label: customer.customerName }));
            case "issueType":
                return Object.keys(Utils.issueType).map((key) => ({ value: key, label: getIssueValueByKey(key) }));

            default:
                return undefined;
        }
    };

    const onChangeEvent = (type: string, value?: any) => {
        setDatas(
            produce((draft: any) => {
                if (type === "makerName" && value !== selectedMakerName) {
                    setSelectedMakerName(value);
                    draft.makerModelId = undefined;
                } else if (type === "customerManagerName" && value !== selectedCustomerManagerName) {
                    setSelectedCustomerManagerName(value);
                    draft.customerName = undefined;
                }

                if (value === undefined || value === "") {
                    delete draft[type as keyof any];
                } else {
                    draft[type as keyof any] = value;
                }
            })
        );
    };

    const { requestAuthUserList, resultAuthUserList } = RequestUser.useRequestAuthUserList();

    useEffect(() => {
        if (!resultAuthUserList) return;

        setUsers(resultAuthUserList.users);
    }, [resultAuthUserList]);

    const { requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const { requestApiCategoryList, resultApiCategoryList } = RequestCategory.useRequestApiCategoryList();

    useEffect(() => {
        if (!resultApiCategoryList) return;

        setCategories(resultApiCategoryList.categories);
    }, [resultApiCategoryList]);

    const { requestApiMakerList, resultApiMakerList } = RequestMaker.useRequestApiMakerList();

    useEffect(() => {
        if (!resultApiMakerList) return;

        setMakers(resultApiMakerList.makers);
    }, [resultApiMakerList]);

    const { requestAuthCustomerList, resultAuthCustomerList } = RequestCustomer.useRequestAuthCustomerList();

    useEffect(() => {
        if (!resultAuthCustomerList) return;

        setCustomers(resultAuthCustomerList.customers);
    }, [resultAuthCustomerList]);

    const { requestAuthCustomerManagerList, resultAuthCustomerManagerList } = RequestCustomerManager.useRequestAuthCustomerManagerList();

    useEffect(() => {
        if (!resultAuthCustomerManagerList) return;

        setCustomerManagers(resultAuthCustomerManagerList.customerManagers);
    }, [resultAuthCustomerManagerList]);

    const handleCloseAllDrawer = () => {
        setVisible(undefined);
    };

    const handleCloseAllView = () => {
        setOpen(false);
    };

    return (
        <>
            <div className={styles.searchWrapper}>
                <Space align="center">
                    {title && (
                        <p className="fw-bd fs-lg fc-font1" style={{ margin: 0 }}>
                            {title}
                        </p>
                    )}
                    <span className="fw-rg fs-md fc-font2">총 {dataLength ? dataLength : "0"}건</span>
                </Space>
                <span
                    className={`${isFiltered ? styles.filteredFilterBtn : styles.filterBtn} ${
                        isFiltered ? "fc-point" : "fc-font3"
                    } fw-bd fs-sm btn-text`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <FilterOutlined /> 필터
                </span>
            </div>
            {open && (
                <FloatingPanel anchors={[window.innerHeight]} className="fullPanel" handleDraggingOfContent={false}>
                    <div className={styles.selectorOverlay}>
                        <Header className={styles.overlayHeader}>
                            <LeftOutlined
                                className={styles.menuIcon}
                                onClick={() => {
                                    initData();
                                    setOpen(false);
                                    setVisible(undefined);
                                }}
                            />
                            <p className="fw-bd fs-lg fc-font1">필터</p> <LeftOutlined style={{ opacity: 0 }} />
                        </Header>
                        <div className={styles.overlayContent}>
                            <div className={styles.searchFormWrapper}>
                                {values?.map((value) => (
                                    <div className={styles.searchForm} key={value.id}>
                                        <span className="fs-md fc-font2">{value.title}</span>
                                        {getType(
                                            value.id,
                                            value.title,
                                            value.type,
                                            value.typeDetail,
                                            value.options,
                                            value.defaultValue,
                                            value.dateFormat,
                                            value.required
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.overlayFooter}>
                            <span
                                className="fw-bd fs-md fc-font2 btn-text"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setDatas(onChangeDefaultData());
                                }}
                            >
                                <RedoOutlined />
                                초기화
                            </span>
                            <Button
                                className="btn-primary"
                                onClick={() => {
                                    setOpen(false);
                                    setVisible(undefined);
                                }}
                            >
                                {dataLength}건 보기
                            </Button>
                        </div>
                    </div>
                </FloatingPanel>
            )}
        </>
    );
};

export default Filter;
