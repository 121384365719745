import { useState, useEffect } from "react";

import Input from "antd/es/input/index";
import Modal from "antd/es/modal/index";
import Select from "antd/es/select/index";
import Space from "antd/es/space/index";

import { viewType } from "../../components/browser/CustomForm";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestCustsomerManager from "../../utils/requestAuthCustomerManager";
import * as RequestCustomer from "../../utils/requestAuthCustomer";

import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";
import CustomModal from "../../components/common/CustomModal";

import IconEdit from "../../assets/images/icon/icon_edit.png";
import IconDelete from "../../assets/images/icon/icon_delete.png";

type customerSearchType = { customerName: string };

const PageMaker = () => {
    const alert = useAlert();
    const userDetails = useAuthState();
    const { Option } = Select;

    const [type, setType] = useState<viewType>("view");
    const [selectedManagetId, setSelectedManagerId] = useState<number>();
    const [searchValue, setSearchValue] = useState<customerSearchType>();

    const [isOpenManager, setIsOpenManager] = useState<boolean>(false);
    const [customerManager, setCustomerManager] = useState<TypeDTO.CustomerManagerDto>();
    const [customerManagers, setCustomerManagers] = useState<Array<TypeDTO.CustomerManagerDto>>();
    const [filteredCustomerManagers, setFilteredCustomerManagers] = useState<Array<TypeDTO.CustomerManagerDto>>();

    const [isOpenCustomer, setIsOpenCustomer] = useState<boolean>(false);
    const [customer, setCustomer] = useState<TypeDTO.CustomerDto>();
    const [customers, setCustomers] = useState<Array<TypeDTO.CustomerDto>>();
    const [filteredCustomers, setFilteredCustomers] = useState<Array<TypeDTO.CustomerDto>>();

    useEffect(() => {
        requestAuthCustomerManagerList();
        requestAuthCustomerList();
    }, []);

    useEffect(() => {
        setFilteredCustomers(
            customers?.filter(
                (customer: TypeDTO.CustomerDto) =>
                    (!selectedManagetId || customer.customerManager?.customerManagerId === selectedManagetId) &&
                    (!searchValue?.customerName || customer.customerName.includes(searchValue.customerName))
            )
        );
    }, [selectedManagetId]);

    const customerManagerColumns: ColumnsType<TypeDTO.CustomerManagerDto> = [
        {
            title: String.name,
            dataIndex: "customerManagerName",
            key: "customerManagerName",
            width: "10%",
            align: "center",
        },
        {
            title: String.phone,
            dataIndex: "customerManagerPhone",
            key: "customerManagerPhone",
            width: "10%",
            align: "center",
            render: (_, { customerManagerPhone }) => Utils.convertPhone(customerManagerPhone) || String.dash,
        },
        {
            title: String.customer,
            dataIndex: "customers",
            key: "customers",
            width: "10%",
            align: "center",
            render: (_, { customers }) => customers?.length || String.dash,
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={10}>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowCustomerManager("edit", row);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconEdit} width={20} />
                        </div>
                        <div
                            style={{ width: 24, height: 24 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete("customerManager", row.customerManagerId, row.customerManagerName);
                            }}
                        >
                            <img alt="" className="btn-icon" src={IconDelete} width={20} />
                        </div>
                    </Space>
                );
            },
        },
    ];

    const customerColumns: ColumnsType<TypeDTO.CustomerDto> = [
        {
            title: String.customerManager,
            dataIndex: ["customerManager", "customerManagerName"],
            key: "name",
            width: "10%",
            align: "center",
        },
        {
            title: String.name,
            dataIndex: "customerName",
            key: "name",
            width: "10%",
            align: "center",
        },
        {
            title: String.phone,
            dataIndex: "customerPhone",
            key: "customerPhone",
            width: "20%",
            align: "center",
            render: (_, { customerPhone }) => {
                return Utils.convertPhone(customerPhone) || String.dash;
            },
        },
        {
            title: String.manage,
            key: "detail",
            width: "10%",
            align: "center",
            render: (_, row) => {
                return (
                    <Space size={16}>
                        <img
                            alt=""
                            className="btn-icon"
                            src={IconEdit}
                            onClick={(e) => {
                                e.stopPropagation();
                                onRowCustomer("edit", row);
                            }}
                            width={20}
                        />
                        <img
                            alt=""
                            className="btn-icon"
                            src={IconDelete}
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete("customer", row.customerId, row.customerName);
                            }}
                            width={20}
                        />
                    </Space>
                );
            },
        },
    ];

    const managerSearch: TypeUtils.SearchItemType[] = [
        {
            id: "customerManagerName",
            span: 24,
            title: String.customerManager,
            type: "input",
        },
    ];

    const customerSearch: TypeUtils.SearchItemType[] = [
        {
            id: "customerName",
            span: 24,
            title: String.customer,
            type: "input",
        },
    ];

    const customerManagerContents: Array<TypeUtils.formType> = [
        {
            name: "customerManagerName",
            label: String.name,
            span: 24,
            required: true,
        },
        {
            name: "customerManagerPhone",
            label: String.phone,
            span: 24,
            input: <Input className={type === "view" ? "disabled-input" : ""} disabled={type === "view"} />,
        },
    ];

    const customerContents: Array<TypeUtils.formType> = [
        {
            name: ["customerManager", "customerManagerId"],
            label: String.customerManager,
            span: 24,
            input: (
                <Select
                    allowClear
                    showSearch
                    placeholder="고객 관리자를 선택해 주세요."
                    className={type === "view" ? "disabled-selector" : ""}
                    disabled={type === "view"}
                    optionFilterProp="children"
                >
                    {customerManagers?.map((manager) => (
                        <Option key={manager.customerManagerId} value={manager.customerManagerId}>
                            {manager.customerManagerName}
                        </Option>
                    ))}
                </Select>
            ),
            required: true,
        },
        {
            name: "customerName",
            label: String.name,
            span: 24,
            required: true,
        },
        {
            name: "customerPhone",
            label: String.phone,
            span: 24,
            required: true,
            input: <Input className={type === "view" ? "disabled-input" : ""} disabled={type === "view"} />,
        },
    ];

    //customerManager
    const { loadingAuthCustomerManagerList, requestAuthCustomerManagerList, resultAuthCustomerManagerList } =
        RequestCustsomerManager.useRequestAuthCustomerManagerList();

    useEffect(() => {
        if (!resultAuthCustomerManagerList) return;

        setCustomerManagers(resultAuthCustomerManagerList.customerManagers);
    }, [resultAuthCustomerManagerList]);

    const { requestAuthCustomerManagerRegister, resultAuthCustomerManagerRegister } = RequestCustsomerManager.useRequestAuthCustomerManagerRegister();

    useEffect(() => {
        if (!resultAuthCustomerManagerRegister) return;

        onResultCustomerManager("register");
    }, [resultAuthCustomerManagerRegister]);

    const { requestAuthCustomerManagerUpdate, resultAuthCustomerManagerUpdate } = RequestCustsomerManager.useRequestAuthCustomerManagerUpdate();

    useEffect(() => {
        if (!resultAuthCustomerManagerUpdate) return;

        onResultCustomerManager("edit");
    }, [resultAuthCustomerManagerUpdate]);

    const { requestAuthCustomerManagerDelete, resultAuthCustomerManagerDelete } = RequestCustsomerManager.useRequestAuthCustomerManagerDelete();

    useEffect(() => {
        if (!resultAuthCustomerManagerDelete) return;

        onResultCustomerManager("delete");
    }, [resultAuthCustomerManagerDelete]);

    //customer
    const { loadingAuthCustomerList, requestAuthCustomerList, resultAuthCustomerList } = RequestCustomer.useRequestAuthCustomerList();

    useEffect(() => {
        if (!resultAuthCustomerList) return;

        setCustomers(resultAuthCustomerList.customers);
    }, [resultAuthCustomerList]);

    const { requestAuthCustomerRegister, resultAuthCustomerRegister } = RequestCustomer.useRequestAuthCustomerRegister();

    useEffect(() => {
        if (!resultAuthCustomerRegister) return;

        onResultCustomer("register");
    }, [resultAuthCustomerRegister]);

    const { requestAuthCustomerUpdate, resultAuthCustomerUpdate } = RequestCustomer.useRequestAuthCustomerUpdate();

    useEffect(() => {
        if (!resultAuthCustomerUpdate) return;

        onResultCustomer("edit");
    }, [resultAuthCustomerUpdate]);

    const { requestAuthCustomerDelete, resultAuthCustomerDelete } = RequestCustomer.useRequestAuthCustomerDelete();

    useEffect(() => {
        if (!resultAuthCustomerDelete) return;

        onResultCustomer("delete");
    }, [resultAuthCustomerDelete]);

    const onSearchManager = (value: { customerManagerName: string }) => {
        const searchFilter = Utils.searchFilter;
        setFilteredCustomerManagers(customerManagers?.filter((manager) => searchFilter(manager, value?.customerManagerName, "customerManagerName")));
    };

    const onSearchCustomer = (value: customerSearchType) => {
        const searchFilter = Utils.searchFilter;

        setSearchValue(value);
        setFilteredCustomers(
            customers?.filter(
                (customer) =>
                    searchFilter(customer, value?.customerName, "customerName") &&
                    (selectedManagetId ? searchFilter(customer.customerManager, selectedManagetId, "customerManagerId") : true)
            )
        );
    };

    const onRowCustomerManager = (type: viewType, value?: TypeDTO.CustomerManagerDto) => {
        setCustomerManager(value);
        setIsOpenManager(true);
        setType(type);
    };

    const onRowCustomer = (type: viewType, value?: TypeDTO.CustomerDto) => {
        setCustomer(value);
        setIsOpenCustomer(true);
        setType(type);
    };

    const onDelete = (category: "customerManager" | "customer", id: number, name: string) => {
        Modal.confirm({
            title: (category === "customerManager" ? String.customerManager : String.customer) + String.remove,
            content: Utils.addParticle(name, "삭제하시겠습니까?"),
            okText: String.confirm,
            onOk() {
                category === "customerManager" ? requestAuthCustomerManagerDelete(id) : requestAuthCustomerDelete(id);
            },
            cancelText: String.cancel,
            onCancel() {},
            centered: true,
        });
    };

    const onFinishCustomerManager = (type: viewType, value: TypeDTO.CustomerManagerDto) => {
        type === "register"
            ? requestAuthCustomerManagerRegister({ ...value, customerManagerPhone: value?.customerManagerPhone?.replaceAll("-", "") })
            : requestAuthCustomerManagerUpdate(customerManager?.customerManagerId, {
                  ...value,
                  customerManagerPhone: value?.customerManagerPhone?.replaceAll("-", ""),
              });
    };

    const onFinishCustomer = (type: viewType, value: TypeDTO.CustomerDto) => {
        type === "register"
            ? requestAuthCustomerRegister({ ...value, customerPhone: value?.customerPhone?.replaceAll("-", "") })
            : requestAuthCustomerUpdate(customer?.customerId, { ...value, customerPhone: value?.customerPhone?.replaceAll("-", "") });
    };

    const onResultCustomerManager = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);
        alert.setAlert(AlertType.SUCCESS, `${String.customerManager} ${typeText} 성공`, `${String.customerManager} 정보를 ${typeText}하였습니다.`);

        setIsOpenManager(false);
        requestAuthCustomerManagerList();
        requestAuthCustomerList();
    };

    const onResultCustomer = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);
        alert.setAlert(AlertType.SUCCESS, `${String.customer} ${typeText} 성공`, `${String.customer} 정보를 ${typeText}하였습니다.`);

        setIsOpenCustomer(false);
        requestAuthCustomerManagerList();
        requestAuthCustomerList();
    };

    return (
        <>
            <div style={{ width: "100%", display: "flex", gap: 24 }}>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <Search
                        title={String.customerManager}
                        dataLength={filteredCustomerManagers?.length || customerManagers?.length}
                        values={managerSearch}
                        onSearch={(value) => onSearchManager(value)}
                        onClear={(value) => onSearchManager(value)}
                        onClick={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? () => onRowCustomerManager("register", undefined)
                                : undefined
                        }
                    />
                    <DataTable
                        rowKey={(row: TypeDTO.CustomerManagerDto) => row.customerManagerId}
                        disabledTitle
                        loading={loadingAuthCustomerManagerList}
                        columns={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? customerManagerColumns
                                : customerManagerColumns.filter((column) => column.key !== "detail")
                        }
                        dataSource={filteredCustomerManagers || customerManagers}
                        onRow={(value) => setSelectedManagerId((prev) => (value.customerManagerId === prev ? undefined : value.customerManagerId))}
                    />
                </div>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <Search
                        title={String.customer}
                        dataLength={filteredCustomers?.length || customers?.length}
                        values={customerSearch}
                        onSearch={(value) => onSearchCustomer(value)}
                        onClear={(value) => onSearchCustomer(value)}
                        onClick={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? () => onRowCustomer("register", undefined)
                                : undefined
                        }
                    />
                    <DataTable
                        rowKey={(row: TypeDTO.CustomerDto) => row.customerId}
                        disabledTitle
                        loading={loadingAuthCustomerList}
                        columns={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? customerColumns
                                : customerColumns.filter((column) => column.key !== "detail")
                        }
                        dataSource={filteredCustomers || customers}
                        onRow={
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])
                                ? (value) => onRowCustomer("view", value)
                                : undefined
                        }
                    />
                </div>
                <CustomModal
                    title={String.customerManager}
                    open={isOpenManager}
                    type={type}
                    value={
                        type === "view"
                            ? { ...customerManager, customerManagerPhone: Utils.convertPhone(customerManager?.customerManagerPhone) }
                            : customerManager
                    }
                    contents={customerManagerContents}
                    onChangedOpen={() => setIsOpenManager(false)}
                    onChangedType={(value) => setType(value)}
                    onFinish={onFinishCustomerManager}
                />
                <CustomModal
                    title={String.customer}
                    open={isOpenCustomer}
                    type={type}
                    value={type === "view" ? { ...customer, customerPhone: Utils.convertPhone(customer?.customerPhone) } : customer}
                    contents={customerContents}
                    onChangedOpen={() => setIsOpenCustomer(false)}
                    onChangedType={(value) => setType(value)}
                    onFinish={onFinishCustomer}
                />
            </div>
        </>
    );
};

export default PageMaker;
