import { useEffect, useState } from "react";
import CanDataWrapper, { CanTableType } from "./CanDataWrapper";
import Space from "antd/es/space/index";

import moment from "moment";

import * as CanDataBukgi from "../../commons/CanDataBukgi";
import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";

function CanDataBukgiViewer({
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataBukgi.CanData>();

    const convertRemarkWithUnit = (value: number, unit: string) => {
        return value + unit;
    };

    const convertRemarkWithUnitNException = (value: number, unit: string, bitLength: number) => {
        if (bitLength === 12) {
            if (value === 0x0ffe) return "이상";
            else if (value === 0x0fff) return "무효";
        } else if (bitLength === 8) {
            if (value === 0xfe) return "이상";
            else if (value === 0xff) return "무효";
        } else if (bitLength === 4) {
            if (value === 0xe) return "이상";
            else if (value === 0xf) return "무효";
        }

        return value + unit;
    };

    const convertRemarkToBinaryString = (value: number) => {
        return ("00000000" + value.toString(2)).slice(-8);
    };

    //#region VCU
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuPanelOpen, setVcuPanelOpen] = useState(true);
    const [vcuChartMounted, setVcuChartMounted] = useState(false);
    const [selectedVcu, setSelectedVcu] = useState<Array<React.Key>>([
        Common.VCU_S_1_VEHICLE_FUNCTION_KEY,
        Common.VCU_S_1_SHIFT_LOCATION_KEY,
        Common.VCU_S_1_KEY_STATUS_KEY,
        Common.VCU_S_1_VEHICLE_SPEED_KEY,
    ]);

    const [seriesChartDatasVcu, setSeriesChartDatasVcu] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuVehicleFunction, setChartVcuVehicleFunction] = useState<TypeUtils.ChartData>({
        name: Common.VCU_S_1_VEHICLE_FUNCTION_NAME,
        data: [],
    });
    const [chartVcuShiftLocation, setChartVcuShiftLocation] = useState<TypeUtils.ChartData>({
        name: Common.VCU_S_1_SHIFT_LOCATION_NAME,
        data: [],
    });
    const [chartVcuKeyStatus, setChartVcuKeyStatus] = useState<TypeUtils.ChartData>({
        name: Common.VCU_S_1_KEY_STATUS_NAME,
        data: [],
    });
    const [chartVcuVehicleSpeed, setChartVcuVehicleSpeed] = useState<TypeUtils.ChartData>({
        name: Common.VCU_S_1_VEHICLE_SPEED_NAME,
        data: [],
    });
    const [chartVcuPedalState, setChartVcuPedalState] = useState<TypeUtils.ChartData>({
        name: Common.VCU_S_1_PEDAL_STATE_NAME,
        data: [],
    });
    const [chartVcuBrakeState, setChartVcuBrakeState] = useState<TypeUtils.ChartData>({
        name: Common.VCU_S_1_BRAKE_STATE_NAME,
        data: [],
    });
    const [chartVcuHandbrakeState, setChartVcuHandbrakeState] = useState<TypeUtils.ChartData>({
        name: Common.VCU_F_1_HANDBRAKE_STATE_NAME,
        data: [],
    });

    const getTabelTooltipVcu = (key: React.Key) => {
        switch (key) {
            case Common.VCU_S_1_VEHICLE_FUNCTION_KEY:
                return (
                    <>
                        <p>
                            VCU_S_1
                            <br />
                            CAN ID: 0x08F200A0
                            <br />
                            Name: vehicle function
                        </p>
                        <p>
                            Remarks:
                            <br />
                            1=standby, 2=driving,
                            <br />
                            010=charging, 111=emergency
                        </p>
                    </>
                );

            case Common.VCU_S_1_SHIFT_LOCATION_KEY:
                return (
                    <>
                        <p>
                            VCU_S_1
                            <br />
                            CAN ID: 0x08F200A0
                            <br />
                            Name: shift location
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0000=N, 0001=P,
                            <br />
                            0101=R, 1010=D
                        </p>
                    </>
                );

            case Common.VCU_S_1_KEY_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU_S_1
                            <br />
                            CAN ID: 0x08F200A0
                            <br />
                            Name: key status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0000=Lock, 0001=ACC,
                            <br />
                            0010=ON, 0011=Start
                        </p>
                    </>
                );

            case Common.VCU_S_1_VEHICLE_SPEED_KEY:
                return (
                    <>
                        <p>
                            VCU_S_1
                            <br />
                            CAN ID: 0x08F200A0
                            <br />
                            Name: vehicle speed
                        </p>
                    </>
                );

            case Common.VCU_S_1_PEDAL_STATE_KEY:
                return (
                    <>
                        <p>
                            VCU_S_1
                            <br />
                            CAN ID: 0x08F200A0
                            <br />
                            Name: pedal state
                        </p>
                    </>
                );

            case Common.VCU_S_1_BRAKE_STATE_KEY:
                return (
                    <>
                        <p>
                            VCU_S_1
                            <br />
                            CAN ID: 0x08F200A0
                            <br />
                            Name: brake state
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=brake switch not activated,
                            <br />
                            1=brake switch activated
                        </p>
                    </>
                );

            case Common.VCU_F_1_HANDBRAKE_STATE_KEY:
                return (
                    <>
                        <p>
                            VCU_F_1
                            <br />
                            CAN ID: 0x08F200A0
                            <br />
                            Name: handbrake state
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=handbrake not started,
                            <br />
                            1=handbrake started
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertVehicleFuntionRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 1:
                valueString = "standby";
                break;
            // case 2:
            //     valueString = "driving";
            //     break;
            // case 5:
            //     valueString = "charging";
            //     break;
            case 7:
                valueString = "emergency";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertShiftLocationRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "N";
                break;
            case 1:
                valueString = "P";
                break;
            case 5:
                valueString = "R";
                break;
            case 10:
                valueString = "D";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertKeyStatusRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "Lock";
                break;
            case 1:
                valueString = "ACC";
                break;
            case 2:
                valueString = "ON";
                break;
            case 3:
                valueString = "Start";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertBrakeStateRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "not activated";
                break;
            case 1:
                valueString = "activated";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertHandbrakeStateRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "not started";
                break;
            case 1:
                valueString = "started";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getVcuVehicleFunctionValue = () => {
        if (selectedVcu.includes(Common.VCU_S_1_VEHICLE_FUNCTION_KEY) && currentCanData !== undefined) {
            return convertVehicleFuntionRemark(currentCanData.vcuS1?.vehicleFunction);
        }
        return "";
    };

    const getVcuShiftLocationValue = () => {
        if (selectedVcu.includes(Common.VCU_S_1_SHIFT_LOCATION_KEY) && currentCanData !== undefined) {
            return convertShiftLocationRemark(currentCanData.vcuS1?.shiftLocation);
        }
        return "";
    };

    const getVcuKeyStatusValue = () => {
        if (selectedVcu.includes(Common.VCU_S_1_KEY_STATUS_KEY) && currentCanData !== undefined) {
            return convertKeyStatusRemark(currentCanData.vcuS1?.keyStatus);
        }
        return "";
    };

    const getVcuVehicleSpeedValue = () => {
        if (selectedVcu.includes(Common.VCU_S_1_VEHICLE_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuS1?.vehicleSpeed, Common.UNIT_SPEED);
        }
        return "";
    };

    const getVcuPedalStateValue = () => {
        if (selectedVcu.includes(Common.VCU_S_1_PEDAL_STATE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuS1?.pedalState);
        }
        return "";
    };

    const getVcuBrakeStateValue = () => {
        if (selectedVcu.includes(Common.VCU_S_1_BRAKE_STATE_KEY) && currentCanData !== undefined) {
            return convertBrakeStateRemark(currentCanData.vcuS1?.brakeState);
        }
        return "";
    };

    const getVcuHandbrakeStateValue = () => {
        if (selectedVcu.includes(Common.VCU_F_1_HANDBRAKE_STATE_KEY) && currentCanData !== undefined) {
            return convertHandbrakeStateRemark(currentCanData.vcuF1?.handbrakeStatus);
        }
        return "";
    };

    const onChangeVcu = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcu(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcu: Array<TypeUtils.ChartData> = [];

        if (selectedVcu.includes(Common.VCU_S_1_VEHICLE_FUNCTION_KEY)) chartDatasVcu.push(chartVcuVehicleFunction);
        if (selectedVcu.includes(Common.VCU_S_1_SHIFT_LOCATION_KEY)) chartDatasVcu.push(chartVcuShiftLocation);
        if (selectedVcu.includes(Common.VCU_S_1_KEY_STATUS_KEY)) chartDatasVcu.push(chartVcuKeyStatus);
        if (selectedVcu.includes(Common.VCU_S_1_VEHICLE_SPEED_KEY)) chartDatasVcu.push(chartVcuVehicleSpeed);
        if (selectedVcu.includes(Common.VCU_S_1_PEDAL_STATE_KEY)) chartDatasVcu.push(chartVcuPedalState);
        if (selectedVcu.includes(Common.VCU_S_1_BRAKE_STATE_KEY)) chartDatasVcu.push(chartVcuBrakeState);
        if (selectedVcu.includes(Common.VCU_F_1_HANDBRAKE_STATE_KEY)) chartDatasVcu.push(chartVcuHandbrakeState);

        setSeriesChartDatasVcu(chartDatasVcu);
    }, [
        selectedVcu,
        chartVcuVehicleSpeed,
        chartVcuShiftLocation,
        chartVcuKeyStatus,
        chartVcuVehicleSpeed,
        chartVcuPedalState,
        chartVcuBrakeState,
        chartVcuHandbrakeState,
    ]);

    const tableDataVcu: CanTableType[] = [
        {
            key: Common.VCU_S_1_VEHICLE_FUNCTION_KEY,
            name: Common.VCU_S_1_VEHICLE_FUNCTION_NAME,
            value: getVcuVehicleFunctionValue(),
        },
        {
            key: Common.VCU_S_1_SHIFT_LOCATION_KEY,
            name: Common.VCU_S_1_SHIFT_LOCATION_NAME,
            value: getVcuShiftLocationValue(),
        },
        {
            key: Common.VCU_S_1_KEY_STATUS_KEY,
            name: Common.VCU_S_1_KEY_STATUS_NAME,
            value: getVcuKeyStatusValue(),
        },
        {
            key: Common.VCU_S_1_VEHICLE_SPEED_KEY,
            name: Common.VCU_S_1_VEHICLE_SPEED_NAME,
            value: getVcuVehicleSpeedValue(),
        },
        {
            key: Common.VCU_S_1_PEDAL_STATE_KEY,
            name: Common.VCU_S_1_PEDAL_STATE_NAME,
            value: getVcuPedalStateValue(),
        },
        {
            key: Common.VCU_S_1_BRAKE_STATE_KEY,
            name: Common.VCU_S_1_BRAKE_STATE_NAME,
            value: getVcuBrakeStateValue(),
        },
        {
            key: Common.VCU_F_1_HANDBRAKE_STATE_KEY,
            name: Common.VCU_F_1_HANDBRAKE_STATE_NAME,
            value: getVcuHandbrakeStateValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VCU to INS C
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcu2InsCPanelOpen, setVcu2InsCPanelOpen] = useState(true);
    const [vcu2InsCChartMounted, setVcu2InsCChartMounted] = useState(false);
    const [selectedVcu2InsC, setSelectedVcu2InsC] = useState<Array<React.Key>>([
        Common.VCU_TO_INS_C_1_LED_CHARGING_KEY,
        Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_KEY,
        Common.VCU_TO_INS_C_2_READY_SIGNAL_KEY,
    ]);
    const [seriesChartDatasVcu2InsC, setSeriesChartDatasVcu2InsC] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcu2InsCSocIns, setChartVcu2InsCSocIns] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_SOC_INS_NAME,
        data: [],
    });
    const [chartVcu2InsCCurrentIns, setChartVcu2InsCCurrentIns] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_CURRENT_INS_NAME,
        data: [],
    });
    const [chartVcu2InsCVoltIns, setChartVcu2InsCVoltIns] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_VOLT_INS_NAME,
        data: [],
    });
    const [chartVcu2InsCInsulationResistance, setChartVcu2InsCInsulationResistance] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_INSUL_RESISTANCE_NAME,
        data: [],
    });
    const [chartVcu2InsCCellMaxTemp, setChartVcu2InsCCellMaxTemp] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_CELL_MAX_TEMP_NAME,
        data: [],
    });
    const [chartVcu2InsCLedCharging, setChartVcu2InsCLedCharging] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_LED_CHARGING_NAME,
        data: [],
    });
    const [chartVcu2InsCLedChargingConnection, setChartVcu2InsCLedChargingConnection] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_NAME,
        data: [],
    });
    const [chartVcu2InsCLedInsulationWarning, setChartVcu2InsCLedInsulationWarning] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_LED_INSULATION_WARNING_NAME,
        data: [],
    });
    const [chartVcu2InsCLedBattLowVoltWarning, setChartVcu2InsCLedBattLowVoltWarning] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_NAME,
        data: [],
    });
    const [chartVcu2InsCLedLowSocWarning, setChartVcu2InsCLedLowSocWarning] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_NAME,
        data: [],
    });
    const [chartVcu2InsCLedBattFailure, setChartVcu2InsCLedBattFailure] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_LED_BATT_FAILURE_NAME,
        data: [],
    });
    const [chartVcu2InsCLedSoundSignal, setChartVcu2InsCLedSoundSignal] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_1_LED_SOUND_SIGNAL_NAME,
        data: [],
    });
    const [chartVcu2InsCReadySignal, setChartVcu2InsCReadySignal] = useState<TypeUtils.ChartData>({
        name: Common.VCU_TO_INS_C_2_READY_SIGNAL_NAME,
        data: [],
    });

    const getTabelTooltipVcu2InsC = (key: React.Key) => {
        switch (key) {
            case Common.VCU_TO_INS_C_1_SOC_INS_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: Batt SOC INS
                        </p>
                        <p>Remarks: %, 0~256</p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_CURRENT_INS_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: Batt Current INS
                        </p>
                        <p>Remarks: A, -400~1238.4</p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_VOLT_INS_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: Batt Volt INS
                        </p>
                        <p>Remarks: V, 0~819.2</p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_INSUL_RESISTANCE_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: Insul Resistance
                        </p>
                        <p>Remarks: MΩ, 0~25.6</p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_CELL_MAX_TEMP_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: Cell max Temp
                        </p>
                        <p>Remarks: °C, -50 ~ +206</p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_LED_CHARGING_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: LEDs
                        </p>
                        <p>
                            Remarks:
                            <br />
                            충전지시 bit0-1
                            <br />
                            11:전기량낮음, 10:충전시번쩍임,
                            <br />
                            01:보류, 00:상시
                        </p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: LEDs
                        </p>
                        <p>
                            Remarks:
                            <br />
                            충전연결지시 bit2
                            <br />
                            1:충전케이블 충전단자 삽입시 켜짐
                            <br />
                            0:상시멸등
                        </p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_LED_INSULATION_WARNING_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: LEDs
                        </p>
                        <p>
                            Remarks:
                            <br />
                            차량절연경고 bit3
                            <br />
                            1:절연저항떨어질때 지시등 켜지고 음성경고
                            <br />
                            0:상시멸등
                        </p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: LEDs
                        </p>
                        <p>
                            Remarks:
                            <br />
                            배터리저전압경고 bit4
                            <br />
                            1:배터리전압낮을때 지시등 켜지고 음성경고
                            <br />
                            0:상시멸등
                        </p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: LEDs
                        </p>
                        <p>
                            Remarks:
                            <br />
                            저SOC.온도경고 bit5
                            <br />
                            1:SOC낮을때 지시등 켜지고 음성경고
                            <br />
                            0:상시멸등
                        </p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_LED_BATT_FAILURE_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: LEDs
                        </p>
                        <p>
                            Remarks:
                            <br />
                            배터리고장 bit6
                            <br />
                            1:배터리고장시 지시등 켜지고 음성경고
                            <br />
                            0:상시멸등
                        </p>
                    </>
                );

            case Common.VCU_TO_INS_C_1_LED_SOUND_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1810A6A0
                            <br />
                            Name: LEDs
                        </p>
                        <p>
                            Remarks:
                            <br />
                            소리신호 bit7
                            <br />
                            1:beep울림
                            <br />
                            0:beep멈춤
                        </p>
                    </>
                );

            case Common.VCU_TO_INS_C_2_READY_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            VCU To INS C
                            <br />
                            CAN ID: 0x1811A6A0
                            <br />
                            Name: Ready Signal
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=invalid, 1=effective
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertLedChargingRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "상시";
                break;
            case 1:
                valueString = "보류";
                break;
            case 2:
                valueString = "충전시번쩍임";
                break;
            case 3:
                valueString = "전기량낮음";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLedChargingConnectionRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "상시멸등";
                break;
            case 1:
                valueString = "충전단자 삽입";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLedInsulationWarningRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "상시멸등";
                break;
            case 1:
                valueString = "절연저항 떨어짐";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLedBattLowVoltWarningRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "상시멸등";
                break;
            case 1:
                valueString = "배터리 전압 낮음";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLedLowSocWarningRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "상시멸등";
                break;
            case 1:
                valueString = "SOC 낮음";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLedBattFailureRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "상시멸등";
                break;
            case 1:
                valueString = "배터리 고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLedSoundSignalRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "beep 멈춤";
                break;
            case 1:
                valueString = "beep 울림";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertReadySignalRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "invalid";
                break;
            case 1:
                valueString = "effective";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getVcu2InsCSocInsValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_SOC_INS_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuToInsC1?.batSocIns, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getVcu2InsCCurrentInsValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_CURRENT_INS_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuToInsC1?.batCurrIns, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getVcu2InsCVoltInsValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_VOLT_INS_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuToInsC1?.batVoltIns, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getVcu2InsCInsulResistanceValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_INSUL_RESISTANCE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuToInsC1?.insulResistance, Common.UNIT_M_OHM);
        }
        return "";
    };

    const getVcu2InsCCellMaxTempValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_CELL_MAX_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuToInsC1?.cellMaxTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getVcu2InsCLedChargingValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_CHARGING_KEY) && currentCanData !== undefined) {
            return convertLedChargingRemark(currentCanData.vcuToInsC1?.ledChargeCmd);
        }
        return "";
    };

    const getVcu2InsCLedChargingConnectionValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_KEY) && currentCanData !== undefined) {
            return convertLedChargingConnectionRemark(currentCanData.vcuToInsC1?.ledChargeConnectCmd);
        }
        return "";
    };

    const getVcu2InsCLedInsulationWarningValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_INSULATION_WARNING_KEY) && currentCanData !== undefined) {
            return convertLedInsulationWarningRemark(currentCanData.vcuToInsC1?.ledInsulWarn);
        }
        return "";
    };

    const getVcu2InsCLedBattLowVoltWarningValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_KEY) && currentCanData !== undefined) {
            return convertLedBattLowVoltWarningRemark(currentCanData.vcuToInsC1?.ledBatLowVoltWarn);
        }
        return "";
    };

    const getVcu2InsCLedLowSocWarningValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_KEY) && currentCanData !== undefined) {
            return convertLedLowSocWarningRemark(currentCanData.vcuToInsC1?.ledLowSocWarn);
        }
        return "";
    };

    const getVcu2InsCLedBattFailureValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_BATT_FAILURE_KEY) && currentCanData !== undefined) {
            return convertLedBattFailureRemark(currentCanData.vcuToInsC1?.ledBatFail);
        }
        return "";
    };

    const getVcu2InsCLedSoundSignalValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_SOUND_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertLedSoundSignalRemark(currentCanData.vcuToInsC1?.ledSound);
        }
        return "";
    };

    const getVcu2InsCReadySignalValue = () => {
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_2_READY_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertReadySignalRemark(currentCanData.vcuToInsC2?.readySignal);
        }
        return "";
    };

    const onChangeVcu2InsC = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcu2InsC(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcu2InsC: Array<TypeUtils.ChartData> = [];

        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_SOC_INS_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCSocIns);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_CURRENT_INS_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCCurrentIns);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_VOLT_INS_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCVoltIns);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_INSUL_RESISTANCE_KEY))
            chartDatasVcu2InsC.push(chartVcu2InsCInsulationResistance);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_CELL_MAX_TEMP_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCCellMaxTemp);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_CHARGING_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCLedCharging);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_KEY))
            chartDatasVcu2InsC.push(chartVcu2InsCLedChargingConnection);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_INSULATION_WARNING_KEY))
            chartDatasVcu2InsC.push(chartVcu2InsCLedInsulationWarning);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_KEY))
            chartDatasVcu2InsC.push(chartVcu2InsCLedBattLowVoltWarning);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_KEY))
            chartDatasVcu2InsC.push(chartVcu2InsCLedLowSocWarning);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_BATT_FAILURE_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCLedBattFailure);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_1_LED_SOUND_SIGNAL_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCLedSoundSignal);
        if (selectedVcu2InsC.includes(Common.VCU_TO_INS_C_2_READY_SIGNAL_KEY)) chartDatasVcu2InsC.push(chartVcu2InsCReadySignal);

        setSeriesChartDatasVcu2InsC(chartDatasVcu2InsC);
    }, [
        selectedVcu2InsC,
        chartVcu2InsCSocIns,
        chartVcu2InsCCurrentIns,
        chartVcu2InsCVoltIns,
        chartVcu2InsCInsulationResistance,
        chartVcu2InsCCellMaxTemp,
        chartVcu2InsCLedCharging,
        chartVcu2InsCLedChargingConnection,
        chartVcu2InsCLedInsulationWarning,
        chartVcu2InsCLedBattLowVoltWarning,
        chartVcu2InsCLedLowSocWarning,
        chartVcu2InsCLedBattFailure,
        chartVcu2InsCLedSoundSignal,
        chartVcu2InsCReadySignal,
    ]);

    const tableDataVcu2InsC: CanTableType[] = [
        {
            key: Common.VCU_TO_INS_C_2_READY_SIGNAL_KEY,
            name: Common.VCU_TO_INS_C_2_READY_SIGNAL_NAME,
            value: getVcu2InsCReadySignalValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_LED_CHARGING_KEY,
            name: Common.VCU_TO_INS_C_1_LED_CHARGING_NAME,
            value: getVcu2InsCLedChargingValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_KEY,
            name: Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_NAME,
            value: getVcu2InsCLedChargingConnectionValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_SOC_INS_KEY,
            name: Common.VCU_TO_INS_C_1_SOC_INS_NAME,
            value: getVcu2InsCSocInsValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_CURRENT_INS_KEY,
            name: Common.VCU_TO_INS_C_1_CURRENT_INS_NAME,
            value: getVcu2InsCCurrentInsValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_VOLT_INS_KEY,
            name: Common.VCU_TO_INS_C_1_VOLT_INS_NAME,
            value: getVcu2InsCVoltInsValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_INSUL_RESISTANCE_KEY,
            name: Common.VCU_TO_INS_C_1_INSUL_RESISTANCE_NAME,
            value: getVcu2InsCInsulResistanceValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_CELL_MAX_TEMP_KEY,
            name: Common.VCU_TO_INS_C_1_CELL_MAX_TEMP_NAME,
            value: getVcu2InsCCellMaxTempValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_LED_INSULATION_WARNING_KEY,
            name: Common.VCU_TO_INS_C_1_LED_INSULATION_WARNING_NAME,
            value: getVcu2InsCLedInsulationWarningValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_KEY,
            name: Common.VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_NAME,
            value: getVcu2InsCLedBattLowVoltWarningValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_KEY,
            name: Common.VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_NAME,
            value: getVcu2InsCLedLowSocWarningValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_LED_BATT_FAILURE_KEY,
            name: Common.VCU_TO_INS_C_1_LED_BATT_FAILURE_NAME,
            value: getVcu2InsCLedBattFailureValue(),
        },
        {
            key: Common.VCU_TO_INS_C_1_LED_SOUND_SIGNAL_KEY,
            name: Common.VCU_TO_INS_C_1_LED_SOUND_SIGNAL_NAME,
            value: getVcu2InsCLedSoundSignalValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VCU Command
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuCommandPanelOpen, setVcuCommandPanelOpen] = useState(true);
    const [vcuCommandChartMounted, setVcuCommandChartMounted] = useState(false);
    const [selectedVcuCommand, setSelectedVcuCommand] = useState<Array<React.Key>>([
        Common.VCU_COMMAND_1_CONTACTOR_REQUEST_KEY,
        Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_KEY,
        Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_KEY,
        Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_KEY,
    ]);
    const [seriesChartDatasVcuCommand, setSeriesChartDatasVcuCommand] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuCommandContactorRequest, setChartVcuCommandContactorRequest] = useState<TypeUtils.ChartData>({
        name: Common.VCU_COMMAND_1_CONTACTOR_REQUEST_NAME,
        data: [],
    });
    const [chartVcuCommandPowerDownRequest, setChartVcuCommandPowerDownRequest] = useState<TypeUtils.ChartData>({
        name: Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_NAME,
        data: [],
    });
    const [chartVcuCommandFaultLockoutRequest, setChartVcuCommandFaultLockoutRequest] = useState<TypeUtils.ChartData>({
        name: Common.VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_NAME,
        data: [],
    });
    const [chartVcuCommandAcPreChargeVoltageRequest, setChartVcuCommandAcPreChargeVoltageRequest] = useState<TypeUtils.ChartData>({
        name: Common.VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_NAME,
        data: [],
    });
    const [chartVcuCommandMcuInputVoltage, setChartVcuCommandMcuInputVoltage] = useState<TypeUtils.ChartData>({
        name: Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_NAME,
        data: [],
    });
    const [chartVcuCommandMotorAcCurrent, setChartVcuCommandMotorAcCurrent] = useState<TypeUtils.ChartData>({
        name: Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_NAME,
        data: [],
    });
    const [chartVcuCommandAcPreChargeVoltageStatus, setChartVcuCommandAcPreChargeVoltageStatus] = useState<TypeUtils.ChartData>({
        name: Common.VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipVcuCommand = (key: React.Key) => {
        switch (key) {
            case Common.VCU_COMMAND_1_CONTACTOR_REQUEST_KEY:
                return (
                    <>
                        <p>
                            VCU command 1
                            <br />
                            CAN ID: 0x18FFA0F3
                            <br />
                            Name: Contactor Request
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=주접속기차단(고전압차단)
                            <br />
                            1=주접속기접속(고전압접속)
                            <br />
                            2=보류, 3=보류
                        </p>
                    </>
                );

            case Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_KEY:
                return (
                    <>
                        <p>
                            VCU command 1
                            <br />
                            CAN ID: 0x18FFA0F3
                            <br />
                            Name: Power Down Request
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=전원차단, 1=전원연결
                            <br />
                            2=보류, 3=보류
                        </p>
                    </>
                );

            case Common.VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_KEY:
                return (
                    <>
                        <p>
                            VCU command 1
                            <br />
                            CAN ID: 0x18FFA0F3
                            <br />
                            Name: Fault Lockout Request
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=전원차단, 1=전원연결
                            <br />
                            2=보류, 3=보류
                        </p>
                    </>
                );

            case Common.VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_KEY:
                return (
                    <>
                        <p>
                            VCU command 1
                            <br />
                            CAN ID: 0x18FFA0F3
                            <br />
                            Name: AC pre-charge Voltage Request
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=미측정, 1=측정
                        </p>
                    </>
                );

            case Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            VCU command 1
                            <br />
                            CAN ID: 0x18FFA0F3
                            <br />
                            Name: MCU Input Voltage
                        </p>
                        <p>Remarks: V, 0 ~ 1000</p>
                    </>
                );

            case Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_KEY:
                return (
                    <>
                        <p>
                            VCU command 1
                            <br />
                            CAN ID: 0x18FFA0F3
                            <br />
                            Name: Motor AC Current
                        </p>
                        <p>Remarks: V, 0 ~ 1000</p>
                    </>
                );

            case Common.VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_KEY:
                return (
                    <>
                        <p>
                            VCU command 2
                            <br />
                            CAN ID: 0x18FFB0F3
                            <br />
                            Name: AC pre-charge Voltage Status
                        </p>
                        <p>
                            Remarks: <br />
                            00=미완료, 01=완료
                            <br />
                            10=시간초과, 11=예비
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertContactRequestRemark = (value: number) => {
        let valueString = "보류";
        switch (value) {
            case 0:
                valueString = "주접속기차단(고전압차단)";
                break;
            case 1:
                valueString = "주접속기접속(고전압접속)";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertPowerDownRequestRemark = (value: number) => {
        let valueString = "보류";
        switch (value) {
            case 0:
                valueString = "전원차단";
                break;
            case 1:
                valueString = "전원연결";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertFaultLockoutRequestRemark = (value: number) => {
        let valueString = "보류";
        switch (value) {
            case 0:
                valueString = "전원차단";
                break;
            case 1:
                valueString = "전원연결";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertAcPreChargeVoltageRequestRemark = (value: number) => {
        let valueString = "";
        switch (value) {
            case 0:
                valueString = "미측정";
                break;
            case 1:
                valueString = "측정";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertAcPreChargeVoltageStatusRemark = (value: number) => {
        let valueString = "";
        switch (value) {
            case 0:
                valueString = "미완료";
                break;
            case 1:
                valueString = "완료";
                break;
            case 2:
                valueString = "시간초과";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getVcuCommandContactorRequestValue = () => {
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_CONTACTOR_REQUEST_KEY) && currentCanData !== undefined) {
            return convertContactRequestRemark(currentCanData.vcuCmd1?.contactorReq);
        }
        return "";
    };

    const getVcuCommandPowerDownRequestValue = () => {
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_KEY) && currentCanData !== undefined) {
            return convertPowerDownRequestRemark(currentCanData.vcuCmd1?.powerDownReq);
        }
        return "";
    };

    const getVcuCommandFaultLockoutRequestValue = () => {
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_KEY) && currentCanData !== undefined) {
            return convertFaultLockoutRequestRemark(currentCanData.vcuCmd1?.faultLockoutReq);
        }
        return "";
    };

    const getVcuCommandAcPreChargeVoltageRequestValue = () => {
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_KEY) && currentCanData !== undefined) {
            return convertAcPreChargeVoltageRequestRemark(currentCanData.vcuCmd1?.acPrechargeVoltReq);
        }
        return "";
    };

    const getVcuCommandMcuInputVoltageValue = () => {
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuCmd1?.mcuInputVolt, "V");
        }
        return "";
    };

    const getVcuCommandMotorAcCurrentValue = () => {
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuCmd1?.motorAcCurr, "V");
        }
        return "";
    };

    const getVcuCommandAcPreChargeVoltageStatusValue = () => {
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_KEY) && currentCanData !== undefined) {
            return convertAcPreChargeVoltageRequestRemark(currentCanData.vcuCmd2?.acPrechargeVoltStatus);
        }
        return "";
    };

    const onChangeVcuCommand = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuCommand(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuCommand: Array<TypeUtils.ChartData> = [];

        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_CONTACTOR_REQUEST_KEY))
            chartDatasVcuCommand.push(chartVcuCommandContactorRequest);
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_KEY))
            chartDatasVcuCommand.push(chartVcuCommandPowerDownRequest);
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_KEY))
            chartDatasVcuCommand.push(chartVcuCommandFaultLockoutRequest);
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_KEY))
            chartDatasVcuCommand.push(chartVcuCommandAcPreChargeVoltageRequest);
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_KEY))
            chartDatasVcuCommand.push(chartVcuCommandMcuInputVoltage);
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_KEY))
            chartDatasVcuCommand.push(chartVcuCommandMotorAcCurrent);
        if (selectedVcuCommand.includes(Common.VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_KEY))
            chartDatasVcuCommand.push(chartVcuCommandAcPreChargeVoltageStatus);

        setSeriesChartDatasVcuCommand(chartDatasVcuCommand);
    }, [
        selectedVcuCommand,
        chartVcuCommandContactorRequest,
        chartVcuCommandPowerDownRequest,
        chartVcuCommandFaultLockoutRequest,
        chartVcuCommandAcPreChargeVoltageRequest,
        chartVcuCommandMcuInputVoltage,
        chartVcuCommandMotorAcCurrent,
        chartVcuCommandAcPreChargeVoltageStatus,
    ]);

    const tableDataVcuCommand: CanTableType[] = [
        {
            key: Common.VCU_COMMAND_1_CONTACTOR_REQUEST_KEY,
            name: Common.VCU_COMMAND_1_CONTACTOR_REQUEST_NAME,
            value: getVcuCommandContactorRequestValue(),
        },
        {
            key: Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_KEY,
            name: Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_NAME,
            value: getVcuCommandPowerDownRequestValue(),
        },
        {
            key: Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_KEY,
            name: Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_NAME,
            value: getVcuCommandMcuInputVoltageValue(),
        },
        {
            key: Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_KEY,
            name: Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_NAME,
            value: getVcuCommandMotorAcCurrentValue(),
        },
        {
            key: Common.VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_KEY,
            name: Common.VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_NAME,
            value: getVcuCommandFaultLockoutRequestValue(),
        },
        {
            key: Common.VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_KEY,
            name: Common.VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_NAME,
            value: getVcuCommandAcPreChargeVoltageRequestValue(),
        },
        {
            key: Common.VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_KEY,
            name: Common.VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_NAME,
            value: getVcuCommandAcPreChargeVoltageStatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryPanelOpen, setBatteryPanelOpen] = useState(true);
    const [batteryChartMounted, setBatteryChartMounted] = useState(false);
    const [selectedBattery, setSelectedBattery] = useState<Array<React.Key>>([
        Common.BATTERY_BATTERY_STATUS_KEY,
        Common.BATTERY_SOC_KEY,
        Common.BATTERY_PACK_RESIDUAL_CAPACITY_KEY,
    ]);
    const [seriesChartDatasBattery, setSeriesChartDatasBattery] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatteryBatteryStatus, setChartBatteryBatteryStatus] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_BATTERY_STATUS_NAME,
        data: [],
    });
    const [chartBatteryBatteryMode, setChartBatteryBatteryMode] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_BATTERY_MODE_NAME,
        data: [],
    });
    const [chartBatteryFaultStatus, setChartBatteryFaultStatus] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_FAULT_STATUS_NAME,
        data: [],
    });
    const [chartBatteryBalanceLockoutStatus, setChartBatteryBalanceLockoutStatus] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_BALANCE_LOCKOUT_STATUS_NAME,
        data: [],
    });
    const [chartBatteryFaultLockoutStatus, setChartBatteryFaultLockoutStatus] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_FAULT_LOCKOUT_STATUS_NAME,
        data: [],
    });
    const [chartBatterySoc, setChartBatterySoc] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_SOC_NAME,
        data: [],
    });
    const [chartBatterySoh, setChartBatterySoh] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_SOH_NAME,
        data: [],
    });
    const [chartBatteryPackInternalResistance, setChartBatteryPackInternalResistance] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK_INTERNAL_RESISTANCE_NAME,
        data: [],
    });
    const [chartBatteryPackResidualCapacity, setChartBatteryPackResidualCapacity] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK_RESIDUAL_CAPACITY_NAME,
        data: [],
    });
    const [chartBatteryMessageCounter, setChartBatteryMessageCounter] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_MESSAGE_COUNTER_NAME,
        data: [],
    });

    const getTabelTooltipBattery = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_BATTERY_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Battery Status
                        </p>
                        <p>
                            Remarks: <br />
                            0=initialization, 1=disconnet
                            <br />
                            2=precharge over-process
                            <br />
                            3=closed main contactor
                            <br />
                            4=contactor open, 5=boot down
                            <br />
                            6~15=keep
                        </p>
                    </>
                );
            case Common.BATTERY_BATTERY_MODE_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Battery Mode
                        </p>
                        <p>
                            Remarks: <br />
                            0=boot up, 1=self-diagnosis
                            <br />
                            2=access mode, 3=discharge
                            <br />
                            4=boot down
                            <br />
                            5~15=keep
                        </p>
                    </>
                );
            case Common.BATTERY_FAULT_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Fault Status
                        </p>
                        <p>
                            Remarks: <br />
                            0=no fault
                            <br />
                            1=1 level alert
                            <br />
                            2=2 level alert
                            <br />
                            3=restart shutdown
                            <br />
                            4=hardware shutdown
                            <br />
                            5~15=keep
                        </p>
                    </>
                );

            case Common.BATTERY_BALANCE_LOCKOUT_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Balance Lockout Status
                        </p>
                        <p>
                            Remarks: <br />
                            0=balance prohibit
                            <br />
                            1=balance enable
                            <br />
                            2~3=keep
                        </p>
                    </>
                );

            case Common.BATTERY_FAULT_LOCKOUT_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Fault Lockout Status
                        </p>
                        <p>
                            Remarks: <br />
                            0=lockout prohibit
                            <br />
                            1=lockout enable
                            <br />
                            2=keey, 3=n/a
                        </p>
                    </>
                );

            case Common.BATTERY_SOC_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: SOC
                        </p>
                        <p>Remarks: %, 0 ~ 125</p>
                    </>
                );

            case Common.BATTERY_SOH_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: SOH
                        </p>
                        <p>Remarks: %, 0 ~ 125</p>
                    </>
                );

            case Common.BATTERY_PACK_INTERNAL_RESISTANCE_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Pack Internal Resistance
                        </p>
                        <p>Remarks: ohm, 0 ~ 65,535</p>
                    </>
                );

            case Common.BATTERY_PACK_RESIDUAL_CAPACITY_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Pack Residual Capacity
                        </p>
                        <p>Remarks: A, 0 ~ 409.5</p>
                    </>
                );

            case Common.BATTERY_MESSAGE_COUNTER_KEY:
                return (
                    <>
                        <p>
                            Battery
                            <br />
                            CAN ID: 0x18FFA1F3
                            <br />
                            Name: Message Counter
                        </p>
                        <p>Remarks: 1/100ms, 0 ~ 14, 15=보류</p>
                    </>
                );
        }

        return null;
    };

    const convertBatteryStatusRemark = (value: number) => {
        let valueString = "keep";
        switch (value) {
            case 0:
                valueString = "initialization";
                break;
            case 1:
                valueString = "disconnet";
                break;
            case 2:
                valueString = "precharge over-process";
                break;
            case 3:
                valueString = "closed main contactor";
                break;
            case 4:
                valueString = "contactor open";
                break;
            case 5:
                valueString = "boot down";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertBatteryModeRemark = (value: number) => {
        let valueString = "keep";
        switch (value) {
            case 0:
                valueString = "boot up";
                break;
            case 1:
                valueString = "self-diagnosis";
                break;
            case 2:
                valueString = "access mode";
                break;
            case 3:
                valueString = "discharge";
                break;
            case 4:
                valueString = "boot down";
                break;

            default:
                break;
        }
        return valueString;
    };
    const convertFaultStatusRemark = (value: number) => {
        let valueString = "keep";
        switch (value) {
            case 0:
                valueString = "no fault";
                break;
            case 1:
                valueString = "1 level alert";
                break;
            case 2:
                valueString = "2 level alert";
                break;
            case 3:
                valueString = "restart shutdown";
                break;
            case 4:
                valueString = "hardware shutdown";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertBalanceLockoutStatusRemark = (value: number) => {
        let valueString = "keep";
        switch (value) {
            case 0:
                valueString = "balance prohibit";
                break;
            case 1:
                valueString = "balance enable";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertFaultLockoutStatusRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "lockout prohibit";
                break;
            case 1:
                valueString = "lockout enable";
                break;
            case 2:
                valueString = "keep";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBatteryBatteryStatusValue = () => {
        if (selectedBattery.includes(Common.BATTERY_BATTERY_STATUS_KEY) && currentCanData !== undefined) {
            return convertBatteryStatusRemark(currentCanData.bat?.status);
        }
        return "";
    };

    const getBatteryBatteryModeValue = () => {
        if (selectedBattery.includes(Common.BATTERY_BATTERY_MODE_KEY) && currentCanData !== undefined) {
            return convertBatteryModeRemark(currentCanData.bat?.mode);
        }
        return "";
    };

    const getBatteryFaultStatusValue = () => {
        if (selectedBattery.includes(Common.BATTERY_FAULT_STATUS_KEY) && currentCanData !== undefined) {
            return convertFaultStatusRemark(currentCanData.bat?.faultStatus);
        }
        return "";
    };

    const getBatteryBalanceLockoutStatusValue = () => {
        if (selectedBattery.includes(Common.BATTERY_BALANCE_LOCKOUT_STATUS_KEY) && currentCanData !== undefined) {
            return convertBalanceLockoutStatusRemark(currentCanData.bat?.balanceLockoutStatus);
        }
        return "";
    };

    const getBatteryFaultLockoutStatusValue = () => {
        if (selectedBattery.includes(Common.BATTERY_FAULT_LOCKOUT_STATUS_KEY) && currentCanData !== undefined) {
            return convertFaultLockoutStatusRemark(currentCanData.bat?.faultLockoutStatus);
        }
        return "";
    };

    const getBatterySocValue = () => {
        if (selectedBattery.includes(Common.BATTERY_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bat?.soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatterySohValue = () => {
        if (selectedBattery.includes(Common.BATTERY_SOH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bat?.soh, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatteryPackInternalResistanceValue = () => {
        if (selectedBattery.includes(Common.BATTERY_PACK_INTERNAL_RESISTANCE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bat?.packInterResistance, Common.UNIT_OHM);
        }
        return "";
    };

    const getBatteryPackResidualCapacityValue = () => {
        if (selectedBattery.includes(Common.BATTERY_PACK_RESIDUAL_CAPACITY_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bat?.packResidualCapa, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBatteryMessageCounterValue = () => {
        if (selectedBattery.includes(Common.BATTERY_MESSAGE_COUNTER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.bat?.msgCounter);
        }
        return "";
    };

    const onChangeBattery = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBattery(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBattery: Array<TypeUtils.ChartData> = [];

        if (selectedBattery.includes(Common.BATTERY_BATTERY_STATUS_KEY)) chartDatasBattery.push(chartBatteryBatteryStatus);
        if (selectedBattery.includes(Common.BATTERY_BATTERY_MODE_KEY)) chartDatasBattery.push(chartBatteryBatteryMode);
        if (selectedBattery.includes(Common.BATTERY_FAULT_STATUS_KEY)) chartDatasBattery.push(chartBatteryFaultStatus);
        if (selectedBattery.includes(Common.BATTERY_BALANCE_LOCKOUT_STATUS_KEY)) chartDatasBattery.push(chartBatteryBalanceLockoutStatus);
        if (selectedBattery.includes(Common.BATTERY_FAULT_LOCKOUT_STATUS_KEY)) chartDatasBattery.push(chartBatteryFaultLockoutStatus);
        if (selectedBattery.includes(Common.BATTERY_SOC_KEY)) chartDatasBattery.push(chartBatterySoc);
        if (selectedBattery.includes(Common.BATTERY_SOH_KEY)) chartDatasBattery.push(chartBatterySoh);
        if (selectedBattery.includes(Common.BATTERY_PACK_INTERNAL_RESISTANCE_KEY))
            chartDatasBattery.push(chartBatteryPackInternalResistance);
        if (selectedBattery.includes(Common.BATTERY_PACK_RESIDUAL_CAPACITY_KEY)) chartDatasBattery.push(chartBatteryPackResidualCapacity);
        if (selectedBattery.includes(Common.BATTERY_MESSAGE_COUNTER_KEY)) chartDatasBattery.push(chartBatteryMessageCounter);

        setSeriesChartDatasBattery(chartDatasBattery);
    }, [
        selectedBattery,
        chartBatteryBatteryStatus,
        chartBatteryBatteryMode,
        chartBatteryFaultStatus,
        chartBatteryBalanceLockoutStatus,
        chartBatteryFaultLockoutStatus,
        chartBatterySoc,
        chartBatterySoh,
        chartBatteryPackInternalResistance,
        chartBatteryPackResidualCapacity,
        chartBatteryMessageCounter,
    ]);

    const tableDataBattery: CanTableType[] = [
        {
            key: Common.BATTERY_SOC_KEY,
            name: Common.BATTERY_SOC_NAME,
            value: getBatterySocValue(),
        },
        {
            key: Common.BATTERY_BATTERY_STATUS_KEY,
            name: Common.BATTERY_BATTERY_STATUS_NAME,
            value: getBatteryBatteryStatusValue(),
        },
        {
            key: Common.BATTERY_PACK_RESIDUAL_CAPACITY_KEY,
            name: Common.BATTERY_PACK_RESIDUAL_CAPACITY_NAME,
            value: getBatteryPackResidualCapacityValue(),
        },
        {
            key: Common.BATTERY_BATTERY_MODE_KEY,
            name: Common.BATTERY_BATTERY_MODE_NAME,
            value: getBatteryBatteryModeValue(),
        },
        {
            key: Common.BATTERY_FAULT_STATUS_KEY,
            name: Common.BATTERY_FAULT_STATUS_NAME,
            value: getBatteryFaultStatusValue(),
        },
        {
            key: Common.BATTERY_BALANCE_LOCKOUT_STATUS_KEY,
            name: Common.BATTERY_BALANCE_LOCKOUT_STATUS_NAME,
            value: getBatteryBalanceLockoutStatusValue(),
        },
        {
            key: Common.BATTERY_FAULT_LOCKOUT_STATUS_KEY,
            name: Common.BATTERY_FAULT_LOCKOUT_STATUS_NAME,
            value: getBatteryFaultLockoutStatusValue(),
        },
        {
            key: Common.BATTERY_SOH_KEY,
            name: Common.BATTERY_SOH_NAME,
            value: getBatterySohValue(),
        },
        {
            key: Common.BATTERY_PACK_INTERNAL_RESISTANCE_KEY,
            name: Common.BATTERY_PACK_INTERNAL_RESISTANCE_NAME,
            value: getBatteryPackInternalResistanceValue(),
        },
        {
            key: Common.BATTERY_MESSAGE_COUNTER_KEY,
            name: Common.BATTERY_MESSAGE_COUNTER_NAME,
            value: getBatteryMessageCounterValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Pack 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryPack1PanelOpen, setBatteryPack1PanelOpen] = useState(false);
    const [batteryPack1ChartMounted, setBatteryPack1ChartMounted] = useState(false);
    const [selectedBatteryPack1, setSelectedBatteryPack1] = useState<Array<React.Key>>([]);
    const [seriesChartDatasBatteryPack1, setSeriesChartDatasBatteryPack1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatteryPack1PackVbatt, setChartBatteryPack1PackVbatt] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK1_PACK_VBATT_NAME,
        data: [],
    });
    const [chartBatteryPack1PackVout, setChartBatteryPack1PackVout] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK1_PACK_VOUT_NAME,
        data: [],
    });
    const [chartBatteryPack1PackCurrent, setChartBatteryPack1PackCurrent] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK1_PACK_CURRENT_NAME,
        data: [],
    });
    const [chartBatteryPack1PackPower, setChartBatteryPack1PackPower] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK1_PACK_POWER_NAME,
        data: [],
    });
    const [chartBatteryPack1PackTemp, setChartBatteryPack1PackTemp] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK1_PACK_TEMP_NAME,
        data: [],
    });

    const getTabelTooltipBatteryPack1 = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_PACK1_PACK_VBATT_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 1
                            <br />
                            CAN ID: 0x18FFA2F3
                            <br />
                            Name: Pack Vbatt
                        </p>
                        <p>Remarks: V, 0 ~ 800</p>
                    </>
                );

            case Common.BATTERY_PACK1_PACK_VOUT_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 1
                            <br />
                            CAN ID: 0x18FFA2F3
                            <br />
                            Name: Pack Vout
                        </p>
                        <p>Remarks: V, 0 ~ 800</p>
                    </>
                );

            case Common.BATTERY_PACK1_PACK_CURRENT_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 1
                            <br />
                            CAN ID: 0x18FFA2F3
                            <br />
                            Name: Pack I(Current)
                        </p>
                        <p>Remarks: A, -500 ~ 500</p>
                    </>
                );

            case Common.BATTERY_PACK1_PACK_POWER_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 1
                            <br />
                            CAN ID: 0x18FFA2F3
                            <br />
                            Name: Pack Power
                        </p>
                        <p>Remarks: kW, -325 ~ 325</p>
                    </>
                );

            case Common.BATTERY_PACK1_PACK_TEMP_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 1
                            <br />
                            CAN ID: 0x18FFA2F3
                            <br />
                            Name: Pack Temp
                        </p>
                        <p>Remarks: °C, -50 ~ +200</p>
                    </>
                );
        }

        return null;
    };

    const getBatteryPack1PackVbattValue = () => {
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_VBATT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batPack1?.packVbat, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryPack1PackVoutValue = () => {
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_VOUT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batPack1?.packVout, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryPack1PackCurrentValue = () => {
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batPack1?.packI, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBatteryPack1PackPowerValue = () => {
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_POWER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batPack1?.packPower, Common.UNIT_POWER);
        }
        return "";
    };

    const getBatteryPack1PackTempValue = () => {
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batPack1?.packTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const onChangeBatteryPack1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryPack1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryPack1: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_VBATT_KEY)) chartDatasBatteryPack1.push(chartBatteryPack1PackVbatt);
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_VOUT_KEY)) chartDatasBatteryPack1.push(chartBatteryPack1PackVout);
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_CURRENT_KEY)) chartDatasBatteryPack1.push(chartBatteryPack1PackCurrent);
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_POWER_KEY)) chartDatasBatteryPack1.push(chartBatteryPack1PackPower);
        if (selectedBatteryPack1.includes(Common.BATTERY_PACK1_PACK_TEMP_KEY)) chartDatasBatteryPack1.push(chartBatteryPack1PackTemp);

        setSeriesChartDatasBatteryPack1(chartDatasBatteryPack1);
    }, [
        selectedBatteryPack1,
        chartBatteryPack1PackVbatt,
        chartBatteryPack1PackVout,
        chartBatteryPack1PackCurrent,
        chartBatteryPack1PackPower,
        chartBatteryPack1PackTemp,
    ]);

    const tableDataBatteryPack1: CanTableType[] = [
        {
            key: Common.BATTERY_PACK1_PACK_VBATT_KEY,
            name: Common.BATTERY_PACK1_PACK_VBATT_NAME,
            value: getBatteryPack1PackVbattValue(),
        },
        {
            key: Common.BATTERY_PACK1_PACK_VOUT_KEY,
            name: Common.BATTERY_PACK1_PACK_VOUT_NAME,
            value: getBatteryPack1PackVoutValue(),
        },
        {
            key: Common.BATTERY_PACK1_PACK_CURRENT_KEY,
            name: Common.BATTERY_PACK1_PACK_CURRENT_NAME,
            value: getBatteryPack1PackCurrentValue(),
        },
        {
            key: Common.BATTERY_PACK1_PACK_POWER_KEY,
            name: Common.BATTERY_PACK1_PACK_POWER_NAME,
            value: getBatteryPack1PackPowerValue(),
        },
        {
            key: Common.BATTERY_PACK1_PACK_TEMP_KEY,
            name: Common.BATTERY_PACK1_PACK_TEMP_NAME,
            value: getBatteryPack1PackTempValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Pack 2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryPack2PanelOpen, setBatteryPack2PanelOpen] = useState(false);
    const [batteryPack2ChartMounted, setBatteryPack2ChartMounted] = useState(false);
    const [selectedBatteryPack2, setSelectedBatteryPack2] = useState<Array<React.Key>>([]);
    const [seriesChartDatasBatteryPack2, setSeriesChartDatasBatteryPack2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatteryPack2SlaveControl01, setChartBatteryPack2SlaveControl01] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK2_SLAVE_CONTROL_1_NAME,
        data: [],
    });
    const [chartBatteryPack2SlaveControl09, setChartBatteryPack2SlaveControl09] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK2_SLAVE_CONTROL_9_NAME,
        data: [],
    });
    const [chartBatteryPack2SlaveControl17, setChartBatteryPack2SlaveControl17] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK2_SLAVE_CONTROL_17_NAME,
        data: [],
    });
    const [chartBatteryPack2SlaveControl25, setChartBatteryPack2SlaveControl25] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK2_SLAVE_CONTROL_25_NAME,
        data: [],
    });
    const [chartBatteryPack2BatteryBoxNumber, setChartBatteryPack2BatteryBoxNumber] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK2_BATTERY_BOX_NUMBER_NAME,
        data: [],
    });
    const [chartBatteryPack2SlaveControlNumber, setChartBatteryPack2SlaveControlNumber] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_PACK2_SLAVE_CONTROL_NUMBER_NAME,
        data: [],
    });

    const getTabelTooltipBatteryPack2 = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_PACK2_SLAVE_CONTROL_1_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 2
                            <br />
                            CAN ID: 0x18FFA3F3
                            <br />
                            Name: 1 ~ 8 slave control online
                        </p>
                        <p>
                            Remarks: 개, 0=off, 1=on
                            <br />
                            bit0=1번, bit1=2번, bit2=3번 … bit7=8번
                        </p>
                    </>
                );

            case Common.BATTERY_PACK2_SLAVE_CONTROL_9_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 2
                            <br />
                            CAN ID: 0x18FFA3F3
                            <br />
                            Name: 9 ~ 16 slave control online
                        </p>
                        <p>
                            Remarks: 개, 0=off, 1=on
                            <br />
                            bit0=9번, bit1=10번, bit2=11번 … bit7=16번
                        </p>
                    </>
                );

            case Common.BATTERY_PACK2_SLAVE_CONTROL_17_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 2
                            <br />
                            CAN ID: 0x18FFA3F3
                            <br />
                            Name: 17 ~ 24 slave control online
                        </p>
                        <p>
                            Remarks: 개, 0=off, 1=on
                            <br />
                            bit0=17번, bit1=18번, bit2=19번 … bit7=24번
                        </p>
                    </>
                );

            case Common.BATTERY_PACK2_SLAVE_CONTROL_25_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 2
                            <br />
                            CAN ID: 0x18FFA3F3
                            <br />
                            Name: 25 ~ 32 slave control online
                        </p>
                        <p>
                            Remarks: 개, 0=off, 1=on
                            <br />
                            bit0=25번, bit1=26번, bit2=27번 … bit7=32번
                        </p>
                    </>
                );

            case Common.BATTERY_PACK2_BATTERY_BOX_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 2
                            <br />
                            CAN ID: 0x18FFA3F3
                            <br />
                            Name: battery box number
                        </p>
                        <p>Remarks: 개, 1 ~ 16</p>
                    </>
                );

            case Common.BATTERY_PACK2_SLAVE_CONTROL_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Battery Pack 2
                            <br />
                            CAN ID: 0x18FFA3F3
                            <br />
                            Name: slave control number
                        </p>
                        <p>Remarks: 개, 1 ~ 32</p>
                    </>
                );
        }

        return null;
    };

    const getBatteryPack2SlaveControl01Value = () => {
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_1_KEY) && currentCanData !== undefined) {
            return convertRemarkToBinaryString(currentCanData.batPack2?.slaveControlOnline1_8);
        }
        return "";
    };

    const getBatteryPack2SlaveControl09Value = () => {
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_9_KEY) && currentCanData !== undefined) {
            return convertRemarkToBinaryString(currentCanData.batPack2?.slaveControlOnline9_16);
        }
        return "";
    };

    const getBatteryPack2SlaveControl17Value = () => {
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_17_KEY) && currentCanData !== undefined) {
            return convertRemarkToBinaryString(currentCanData.batPack2?.slaveControlOnline17_24);
        }
        return "";
    };

    const getBatteryPack2SlaveControl25Value = () => {
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_25_KEY) && currentCanData !== undefined) {
            return convertRemarkToBinaryString(currentCanData.batPack2?.slaveControlOnline25_32);
        }
        return "";
    };

    const getBatteryPack2BatteryBoxNumberValue = () => {
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_BATTERY_BOX_NUMBER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batPack2?.batBoxNo, Common.UNIT_NUMBER);
        }
        return "";
    };

    const getBatteryPack2SlaveControlNumberValue = () => {
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_NUMBER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batPack2?.slaveControlNo, Common.UNIT_NUMBER);
        }
        return "";
    };

    const onChangeBatteryPack2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryPack2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryPack2: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_1_KEY))
            chartDatasBatteryPack2.push(chartBatteryPack2SlaveControl01);
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_9_KEY))
            chartDatasBatteryPack2.push(chartBatteryPack2SlaveControl09);
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_17_KEY))
            chartDatasBatteryPack2.push(chartBatteryPack2SlaveControl17);
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_25_KEY))
            chartDatasBatteryPack2.push(chartBatteryPack2SlaveControl25);
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_BATTERY_BOX_NUMBER_KEY))
            chartDatasBatteryPack2.push(chartBatteryPack2BatteryBoxNumber);
        if (selectedBatteryPack2.includes(Common.BATTERY_PACK2_SLAVE_CONTROL_NUMBER_KEY))
            chartDatasBatteryPack2.push(chartBatteryPack2SlaveControlNumber);

        setSeriesChartDatasBatteryPack2(chartDatasBatteryPack2);
    }, [
        selectedBatteryPack2,
        chartBatteryPack2SlaveControl01,
        chartBatteryPack2SlaveControl09,
        chartBatteryPack2SlaveControl17,
        chartBatteryPack2SlaveControl25,
        chartBatteryPack2BatteryBoxNumber,
        chartBatteryPack2SlaveControlNumber,
    ]);

    const tableDataBatteryPack2: CanTableType[] = [
        {
            key: Common.BATTERY_PACK2_SLAVE_CONTROL_1_KEY,
            name: Common.BATTERY_PACK2_SLAVE_CONTROL_1_NAME,
            value: getBatteryPack2SlaveControl01Value(),
        },
        {
            key: Common.BATTERY_PACK2_SLAVE_CONTROL_9_KEY,
            name: Common.BATTERY_PACK2_SLAVE_CONTROL_9_NAME,
            value: getBatteryPack2SlaveControl09Value(),
        },
        {
            key: Common.BATTERY_PACK2_SLAVE_CONTROL_17_KEY,
            name: Common.BATTERY_PACK2_SLAVE_CONTROL_17_NAME,
            value: getBatteryPack2SlaveControl17Value(),
        },
        {
            key: Common.BATTERY_PACK2_SLAVE_CONTROL_25_KEY,
            name: Common.BATTERY_PACK2_SLAVE_CONTROL_25_NAME,
            value: getBatteryPack2SlaveControl25Value(),
        },
        {
            key: Common.BATTERY_PACK2_BATTERY_BOX_NUMBER_KEY,
            name: Common.BATTERY_PACK2_BATTERY_BOX_NUMBER_NAME,
            value: getBatteryPack2BatteryBoxNumberValue(),
        },
        {
            key: Common.BATTERY_PACK2_SLAVE_CONTROL_NUMBER_KEY,
            name: Common.BATTERY_PACK2_SLAVE_CONTROL_NUMBER_NAME,
            value: getBatteryPack2SlaveControlNumberValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Cell Voltage
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVoltagePanelOpen, setCellVoltagePanelOpen] = useState(true);
    const [cellVoltageChartMounted, setCellVoltageChartMounted] = useState(false);
    const [selectedCellVoltage, setSelectedCellVoltage] = useState<Array<React.Key>>([
        Common.CELL_VOLTAGE_CELL_MAX_V_KEY,
        Common.CELL_VOLTAGE_CELL_MIN_V_KEY,
    ]);
    const [seriesChartDatasCellVoltage, setSeriesChartDatasCellVoltage] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellVoltageCellVoltageSum, setChartCellVoltageCellVoltageSum] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_VOLTAGE_SUM_NAME,
        data: [],
    });
    const [chartCellVoltageCellMaxV, setChartCellVoltageCellMaxV] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_MAX_V_NAME,
        data: [],
    });
    const [chartCellVoltageCellMinV, setChartCellVoltageCellMinV] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_MIN_V_NAME,
        data: [],
    });
    const [chartCellVoltageCellMaxVNo, setChartCellVoltageCellMaxVNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_MAX_V_NO_NAME,
        data: [],
    });
    const [chartCellVoltageCellMinVNo, setChartCellVoltageCellMinVNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_MIN_V_NO_NAME,
        data: [],
    });
    const [chartCellVoltageCellMaxVSystemNo, setChartCellVoltageCellMaxVSystemNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_NAME,
        data: [],
    });
    const [chartCellVoltageCellMinVSystemNo, setChartCellVoltageCellMinVSystemNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_NAME,
        data: [],
    });

    const getTabelTooltipCellVoltage = (key: React.Key) => {
        switch (key) {
            case Common.CELL_VOLTAGE_CELL_VOLTAGE_SUM_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x18FFA4F3
                            <br />
                            Name: Cell Voltage Sum
                        </p>
                        <p>Remarks: V, 0 ~ 650</p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_MAX_V_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x18FFA4F3
                            <br />
                            Name: Cell Max V
                        </p>
                        <p>
                            Remarks: V, 0 ~ 6
                            <br />
                            0x0F, 0xFE=이상, 0x0F, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_MIN_V_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x18FFA4F3
                            <br />
                            Name: Cell Min V
                        </p>
                        <p>
                            Remarks: V, 0 ~ 6
                            <br />
                            0x0F, 0xFE=이상, 0x0F, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_MAX_V_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x18FFA4F3
                            <br />
                            Name: Cell Max V no
                        </p>
                        <p>
                            Remarks: 0 ~ 250
                            <br />
                            0xFE=이상, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_MIN_V_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x18FFA4F3
                            <br />
                            Name: Cell Min V no
                        </p>
                        <p>
                            Remarks: 0 ~ 250
                            <br />
                            0xFE=이상, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x18FFA4F3
                            <br />
                            Name: Cell Max V System no
                        </p>
                        <p>
                            Remarks: 0 ~ 15
                            <br />
                            0xE=이상, 0xF=무효
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x18FFA4F3
                            <br />
                            Name: Cell Min V System no
                        </p>
                        <p>
                            Remarks: 0 ~ 15
                            <br />
                            0xE=이상, 0xF=무효
                        </p>
                    </>
                );
        }

        return null;
    };

    const getCellVoltageCellVoltageSumValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_VOLTAGE_SUM_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt?.sum, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVoltageCellMaxVValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MAX_V_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellVolt?.maxV, Common.UNIT_VOLTAGE, 12);
        }
        return "";
    };

    const getCellVoltageCellMinVValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MIN_V_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellVolt?.minV, Common.UNIT_VOLTAGE, 12);
        }
        return "";
    };

    const getCellVoltageCellMaxVNoValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MAX_V_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellVolt?.maxVno, "", 8);
        }
        return "";
    };

    const getCellVoltageCellMinVNoValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MIN_V_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellVolt?.minVno, "", 8);
        }
        return "";
    };

    const getCellVoltageCellMaxVSystemNoValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellVolt?.maxVSysNo, "", 4);
        }
        return "";
    };

    const getCellVoltageCellMinVSystemNoValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellVolt?.minVSysNo, "", 4);
        }
        return "";
    };

    const onChangeCellVoltage = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVoltage(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVoltage: Array<TypeUtils.ChartData> = [];

        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_VOLTAGE_SUM_KEY))
            chartDatasCellVoltage.push(chartCellVoltageCellVoltageSum);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MAX_V_KEY)) chartDatasCellVoltage.push(chartCellVoltageCellMaxV);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MIN_V_KEY)) chartDatasCellVoltage.push(chartCellVoltageCellMinV);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MAX_V_NO_KEY)) chartDatasCellVoltage.push(chartCellVoltageCellMaxVNo);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MIN_V_NO_KEY)) chartDatasCellVoltage.push(chartCellVoltageCellMinVNo);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_KEY))
            chartDatasCellVoltage.push(chartCellVoltageCellMaxVSystemNo);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_KEY))
            chartDatasCellVoltage.push(chartCellVoltageCellMinVSystemNo);

        setSeriesChartDatasCellVoltage(chartDatasCellVoltage);
    }, [
        selectedCellVoltage,
        chartCellVoltageCellVoltageSum,
        chartCellVoltageCellMaxV,
        chartCellVoltageCellMinV,
        chartCellVoltageCellMaxVNo,
        chartCellVoltageCellMinVNo,
        chartCellVoltageCellMaxVSystemNo,
        chartCellVoltageCellMinVSystemNo,
    ]);

    const tableDataCellVoltage: CanTableType[] = [
        {
            key: Common.CELL_VOLTAGE_CELL_MAX_V_KEY,
            name: Common.CELL_VOLTAGE_CELL_MAX_V_NAME,
            value: getCellVoltageCellMaxVValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_MIN_V_KEY,
            name: Common.CELL_VOLTAGE_CELL_MIN_V_NAME,
            value: getCellVoltageCellMinVValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_VOLTAGE_SUM_KEY,
            name: Common.CELL_VOLTAGE_CELL_VOLTAGE_SUM_NAME,
            value: getCellVoltageCellVoltageSumValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_MAX_V_NO_KEY,
            name: Common.CELL_VOLTAGE_CELL_MAX_V_NO_NAME,
            value: getCellVoltageCellMaxVNoValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_MIN_V_NO_KEY,
            name: Common.CELL_VOLTAGE_CELL_MIN_V_NO_NAME,
            value: getCellVoltageCellMinVNoValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_KEY,
            name: Common.CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_NAME,
            value: getCellVoltageCellMaxVSystemNoValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_KEY,
            name: Common.CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_NAME,
            value: getCellVoltageCellMinVSystemNoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Cell Temp
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellTempPanelOpen, setCellTempPanelOpen] = useState(true);
    const [cellTempChartMounted, setCellTempChartMounted] = useState(false);
    const [selectedCellTemp, setSelectedCellTemp] = useState<Array<React.Key>>([
        Common.CELL_TEMP_CELL_MAX_TEMP_KEY,
        Common.CELL_TEMP_CELL_MIN_TEMP_KEY,
    ]);
    const [seriesChartDatasCellTemp, setSeriesChartDatasCellTemp] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartCellTempCellAvgTemp, setChartCellTempCellAvgTemp] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMP_CELL_AVG_TEMP_NAME,
        data: [],
    });
    const [chartCellTempCellMaxTemp, setChartCellTempCellMaxTemp] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMP_CELL_MAX_TEMP_NAME,
        data: [],
    });
    const [chartCellTempCellMinTemp, setChartCellTempCellMinTemp] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMP_CELL_MIN_TEMP_NAME,
        data: [],
    });
    const [chartCellTempCellMaxTempNo, setChartCellTempCellMaxTempNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMP_CELL_MAX_TEMP_NO_NAME,
        data: [],
    });
    const [chartCellTempCellMinTempNo, setChartCellTempCellMinTempNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMP_CELL_MIN_TEMP_NO_NAME,
        data: [],
    });
    const [chartCellTempCellMaxTempSystemNo, setChartCellTempCellMaxTempSystemNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_NAME,
        data: [],
    });
    const [chartCellTempCellMinTempSystemNo, setChartCellTempCellMinTempSystemNo] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_NAME,
        data: [],
    });

    const getTabelTooltipCellTemp = (key: React.Key) => {
        switch (key) {
            case Common.CELL_TEMP_CELL_AVG_TEMP_KEY:
                return (
                    <>
                        <p>
                            Cell Temp
                            <br />
                            CAN ID: 0x18FFA5F3
                            <br />
                            Name: Cell Avg Temp
                        </p>
                        <p>Remarks: °C, -50 ~ +154</p>
                    </>
                );

            case Common.CELL_TEMP_CELL_MAX_TEMP_KEY:
                return (
                    <>
                        <p>
                            Cell Temp
                            <br />
                            CAN ID: 0x18FFA5F3
                            <br />
                            Name: Cell Max Temp
                        </p>
                        <p>
                            Remarks: °C, -50 ~ +154
                            <br />
                            0x0F, 0xFE=이상, 0x0F, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_TEMP_CELL_MIN_TEMP_KEY:
                return (
                    <>
                        <p>
                            Cell Temp
                            <br />
                            CAN ID: 0x18FFA5F3
                            <br />
                            Name: Cell Min Temp
                        </p>
                        <p>
                            Remarks: °C, -50 ~ +154
                            <br />
                            0x0F, 0xFE=이상, 0x0F, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_TEMP_CELL_MAX_TEMP_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Temp
                            <br />
                            CAN ID: 0x18FFA5F3
                            <br />
                            Name: Cell Max Temp no
                        </p>
                        <p>
                            Remarks: 0 ~ 250
                            <br />
                            0xFE=이상, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_TEMP_CELL_MIN_TEMP_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Temp
                            <br />
                            CAN ID: 0x18FFA5F3
                            <br />
                            Name: Cell Min Temp no
                        </p>
                        <p>
                            Remarks: 0 ~ 250
                            <br />
                            0xFE=이상, 0xFF=무효
                        </p>
                    </>
                );

            case Common.CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Temp
                            <br />
                            CAN ID: 0x18FFA5F3
                            <br />
                            Name: Cell Max Temp System no
                        </p>
                        <p>
                            Remarks: 0 ~ 15
                            <br />
                            0xE=이상, 0xF=무효
                        </p>
                    </>
                );

            case Common.CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_KEY:
                return (
                    <>
                        <p>
                            Cell Temp
                            <br />
                            CAN ID: 0x18FFA5F3
                            <br />
                            Name: Cell Min Temp System no
                        </p>
                        <p>
                            Remarks: 0 ~ 15
                            <br />
                            0xE=이상, 0xF=무효
                        </p>
                    </>
                );
        }

        return null;
    };

    const getCellTempCellAvgTempValue = () => {
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_AVG_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellTemp?.avgTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getCellTempCellMaxTempValue = () => {
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MAX_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellTemp?.maxTemp, Common.UNIT_CELSIUS, 12);
        }
        return "";
    };

    const getCellTempCellMinTempValue = () => {
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MIN_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellTemp?.minTemp, Common.UNIT_CELSIUS, 12);
        }
        return "";
    };

    const getCellTempCellMaxTempNoValue = () => {
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MAX_TEMP_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellTemp?.maxTempNo, "", 8);
        }
        return "";
    };

    const getCellTempCellMinTempNoValue = () => {
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MIN_TEMP_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellTemp?.minTempNo, "", 8);
        }
        return "";
    };

    const getCellTempCellMaxTempSystemNoValue = () => {
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellTemp?.maxTempSysNo, "", 4);
        }
        return "";
    };

    const getCellTempCellMinTempSystemNoValue = () => {
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnitNException(currentCanData.cellTemp?.minTempSysNo, "", 4);
        }
        return "";
    };

    const onChangeCellTemp = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellTemp(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellTemp: Array<TypeUtils.ChartData> = [];

        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_AVG_TEMP_KEY)) chartDatasCellTemp.push(chartCellTempCellAvgTemp);
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MAX_TEMP_KEY)) chartDatasCellTemp.push(chartCellTempCellMaxTemp);
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MIN_TEMP_KEY)) chartDatasCellTemp.push(chartCellTempCellMinTemp);
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MAX_TEMP_NO_KEY)) chartDatasCellTemp.push(chartCellTempCellMaxTempNo);
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MIN_TEMP_NO_KEY)) chartDatasCellTemp.push(chartCellTempCellMinTempNo);
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_KEY))
            chartDatasCellTemp.push(chartCellTempCellMaxTempSystemNo);
        if (selectedCellTemp.includes(Common.CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_KEY))
            chartDatasCellTemp.push(chartCellTempCellMinTempSystemNo);

        setSeriesChartDatasCellTemp(chartDatasCellTemp);
    }, [
        selectedCellTemp,
        chartCellTempCellAvgTemp,
        chartCellTempCellMaxTemp,
        chartCellTempCellMinTemp,
        chartCellTempCellMaxTempNo,
        chartCellTempCellMinTempNo,
        chartCellTempCellMaxTempSystemNo,
        chartCellTempCellMinTempSystemNo,
    ]);

    const tableDataCellTemp: CanTableType[] = [
        {
            key: Common.CELL_TEMP_CELL_MAX_TEMP_KEY,
            name: Common.CELL_TEMP_CELL_MAX_TEMP_NAME,
            value: getCellTempCellMaxTempValue(),
        },
        {
            key: Common.CELL_TEMP_CELL_MIN_TEMP_KEY,
            name: Common.CELL_TEMP_CELL_MIN_TEMP_NAME,
            value: getCellTempCellMinTempValue(),
        },
        {
            key: Common.CELL_TEMP_CELL_AVG_TEMP_KEY,
            name: Common.CELL_TEMP_CELL_AVG_TEMP_NAME,
            value: getCellTempCellAvgTempValue(),
        },
        {
            key: Common.CELL_TEMP_CELL_MAX_TEMP_NO_KEY,
            name: Common.CELL_TEMP_CELL_MAX_TEMP_NO_NAME,
            value: getCellTempCellMaxTempNoValue(),
        },
        {
            key: Common.CELL_TEMP_CELL_MIN_TEMP_NO_KEY,
            name: Common.CELL_TEMP_CELL_MIN_TEMP_NO_NAME,
            value: getCellTempCellMinTempNoValue(),
        },
        {
            key: Common.CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_KEY,
            name: Common.CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_NAME,
            value: getCellTempCellMaxTempSystemNoValue(),
        },
        {
            key: Common.CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_KEY,
            name: Common.CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_NAME,
            value: getCellTempCellMinTempSystemNoValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BMS Limit 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsLimit1PanelOpen, setBmsLimit1PanelOpen] = useState(false);
    const [bmsLimit1ChartMounted, setBmsLimit1ChartMounted] = useState(false);
    const [selectedBmsLimit1, setSelectedBmsLimit1] = useState<Array<React.Key>>([]);
    const [seriesChartDatasBmsLimit1, setSeriesChartDatasBmsLimit1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBmsLimit1MaxPackVLimit, setChartBmsLimit1MaxPackVLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_1_MAX_PACK_V_NAME,
        data: [],
    });
    const [chartBmsLimit1MinPackVLimit, setChartBmsLimit1MinPackVLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_1_MIN_PACK_V_NAME,
        data: [],
    });
    const [chartBmsLimit1MaxCellVLimit, setChartBmsLimit1MaxCellVLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_1_MAX_CELL_V_NAME,
        data: [],
    });
    const [chartBmsLimit1MinCellVLimit, setChartBmsLimit1MinCellVLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_1_MIN_CELL_V_NAME,
        data: [],
    });
    const [chartBmsLimit1DischargeCurrentLimit, setChartBmsLimit1DischargeCurrentLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_1_DISCHARGE_CURRENT_NAME,
        data: [],
    });
    const [chartBmsLimit1MaxChargeCurrentLimit, setChartBmsLimit1MaxChargeCurrentLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_1_MAX_CHARGE_CURRENT_NAME,
        data: [],
    });

    const getTabelTooltipBmsLimit1 = (key: React.Key) => {
        switch (key) {
            case Common.BMS_LIMIT_1_MAX_PACK_V_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 1
                            <br />
                            CAN ID: 0x18FFA6F3
                            <br />
                            Name: Max Pack V Limit
                        </p>
                        <p>Remarks: V, 0 ~ 818</p>
                    </>
                );

            case Common.BMS_LIMIT_1_MIN_PACK_V_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 1
                            <br />
                            CAN ID: 0x18FFA6F3
                            <br />
                            Name: Min Pack V Limit
                        </p>
                        <p>Remarks: V, 0 ~ 818</p>
                    </>
                );

            case Common.BMS_LIMIT_1_MAX_CELL_V_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 1
                            <br />
                            CAN ID: 0x18FFA6F3
                            <br />
                            Name: Max Cell V Limit
                        </p>
                        <p>Remarks: V, 0 ~ 6</p>
                    </>
                );

            case Common.BMS_LIMIT_1_MIN_CELL_V_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 1
                            <br />
                            CAN ID: 0x18FFA6F3
                            <br />
                            Name: Min Cell V Limit
                        </p>
                        <p>Remarks: V, 0 ~ 6</p>
                    </>
                );

            case Common.BMS_LIMIT_1_DISCHARGE_CURRENT_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 1
                            <br />
                            CAN ID: 0x18FFA6F3
                            <br />
                            Name: Discharge Current Limit
                        </p>
                        <p>Remarks: A, 0 ~ 500</p>
                    </>
                );

            case Common.BMS_LIMIT_1_MAX_CHARGE_CURRENT_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 1
                            <br />
                            CAN ID: 0x18FFA6F3
                            <br />
                            Name: Max Charge Current Limit
                        </p>
                        <p>Remarks: A, 0 ~ 500</p>
                    </>
                );
        }

        return null;
    };

    const getBmsLimit1MaxPackVLimitValue = () => {
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MAX_PACK_V_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit1?.maxPackV, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsLimit1MinPackVLimitValue = () => {
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MIN_PACK_V_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit1?.minPackV, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsLimit1MaxCellVLimitValue = () => {
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MAX_CELL_V_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit1?.maxCellV, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsLimit1MinCellVLimitValue = () => {
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MIN_CELL_V_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit1?.minCellV, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBmsLimit1DischargeCurrentLimitValue = () => {
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_DISCHARGE_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit1?.dischargeCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBmsLimit1MaxChargeCurrentLimitValue = () => {
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MAX_CHARGE_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit1?.maxChargeCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeBmsLimit1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsLimit1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsLimit1: Array<TypeUtils.ChartData> = [];

        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MAX_PACK_V_KEY)) chartDatasBmsLimit1.push(chartBmsLimit1MaxPackVLimit);
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MIN_PACK_V_KEY)) chartDatasBmsLimit1.push(chartBmsLimit1MinPackVLimit);
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MAX_CELL_V_KEY)) chartDatasBmsLimit1.push(chartBmsLimit1MaxCellVLimit);
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MIN_CELL_V_KEY)) chartDatasBmsLimit1.push(chartBmsLimit1MinCellVLimit);
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_DISCHARGE_CURRENT_KEY))
            chartDatasBmsLimit1.push(chartBmsLimit1DischargeCurrentLimit);
        if (selectedBmsLimit1.includes(Common.BMS_LIMIT_1_MAX_CHARGE_CURRENT_KEY))
            chartDatasBmsLimit1.push(chartBmsLimit1MaxChargeCurrentLimit);

        setSeriesChartDatasBmsLimit1(chartDatasBmsLimit1);
    }, [
        selectedBmsLimit1,
        chartBmsLimit1MaxPackVLimit,
        chartBmsLimit1MinPackVLimit,
        chartBmsLimit1MaxCellVLimit,
        chartBmsLimit1MinCellVLimit,
        chartBmsLimit1DischargeCurrentLimit,
        chartBmsLimit1MaxChargeCurrentLimit,
    ]);

    const tableDataBmsLimit1: CanTableType[] = [
        {
            key: Common.BMS_LIMIT_1_MAX_PACK_V_KEY,
            name: Common.BMS_LIMIT_1_MAX_PACK_V_NAME,
            value: getBmsLimit1MaxPackVLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_1_MIN_PACK_V_KEY,
            name: Common.BMS_LIMIT_1_MIN_PACK_V_NAME,
            value: getBmsLimit1MinPackVLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_1_MAX_CELL_V_KEY,
            name: Common.BMS_LIMIT_1_MAX_CELL_V_NAME,
            value: getBmsLimit1MaxCellVLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_1_MIN_CELL_V_KEY,
            name: Common.BMS_LIMIT_1_MIN_CELL_V_NAME,
            value: getBmsLimit1MinCellVLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_1_DISCHARGE_CURRENT_KEY,
            name: Common.BMS_LIMIT_1_DISCHARGE_CURRENT_NAME,
            value: getBmsLimit1DischargeCurrentLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_1_MAX_CHARGE_CURRENT_KEY,
            name: Common.BMS_LIMIT_1_MAX_CHARGE_CURRENT_NAME,
            value: getBmsLimit1MaxChargeCurrentLimitValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region BMS Limit 2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bmsLimit2PanelOpen, setBmsLimit2PanelOpen] = useState(false);
    const [bmsLimit2ChartMounted, setBmsLimit2ChartMounted] = useState(false);
    const [selectedBmsLimit2, setSelectedBmsLimit2] = useState<Array<React.Key>>([]);
    const [seriesChartDatasBmsLimit2, setSeriesChartDatasBmsLimit2] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBmsLimit2MaxSocLimit, setChartBmsLimit2MaxSocLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_2_MAX_SOC_NAME,
        data: [],
    });
    const [chartBmsLimit2MinSocLimit, setChartBmsLimit2MinSocLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_2_MIN_SOC_NAME,
        data: [],
    });
    const [chartBmsLimit2Max10secDischargePowerLimit, setChartBmsLimit2Max10secDischargePowerLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_NAME,
        data: [],
    });
    const [chartBmsLimit2Max10secChargePowerLimit, setChartBmsLimit2Max10secChargePowerLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_NAME,
        data: [],
    });
    const [chartBmsLimit2MaxCellTempLimit, setChartBmsLimit2MaxCellTempLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_2_MAX_CELL_TEMP_NAME,
        data: [],
    });
    const [chartBmsLimit2MinCellTempLimit, setChartBmsLimit2MinCellTempLimit] = useState<TypeUtils.ChartData>({
        name: Common.BMS_LIMIT_2_MIN_CELL_TEMP_NAME,
        data: [],
    });

    const getTabelTooltipBmsLimit2 = (key: React.Key) => {
        switch (key) {
            case Common.BMS_LIMIT_2_MAX_SOC_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 2
                            <br />
                            CAN ID: 0x18FFA7F3
                            <br />
                            Name: Max SOC Limit
                        </p>
                        <p>Remarks: %, 0 ~ 125</p>
                    </>
                );

            case Common.BMS_LIMIT_2_MIN_SOC_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 2
                            <br />
                            CAN ID: 0x18FFA7F3
                            <br />
                            Name: Min SOC Limit
                        </p>
                        <p>Remarks: %, 0 ~ 125</p>
                    </>
                );

            case Common.BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 2
                            <br />
                            CAN ID: 0x18FFA7F3
                            <br />
                            Name: Max 10sec Discharge Power Limit
                        </p>
                        <p>Remarks: kW, 0 ~ 250</p>
                    </>
                );

            case Common.BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 2
                            <br />
                            CAN ID: 0x18FFA7F3
                            <br />
                            Name: Max 10sec Charge Power Limit
                        </p>
                        <p>Remarks: kW, 0 ~ 250</p>
                    </>
                );

            case Common.BMS_LIMIT_2_MAX_CELL_TEMP_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 2
                            <br />
                            CAN ID: 0x18FFA7F3
                            <br />
                            Name: Max Cell Temp Limit
                        </p>
                        <p>Remarks: °C, -50 ~ +200</p>
                    </>
                );

            case Common.BMS_LIMIT_2_MIN_CELL_TEMP_KEY:
                return (
                    <>
                        <p>
                            BMS Limit 2
                            <br />
                            CAN ID: 0x18FFA7F3
                            <br />
                            Name: Min Cell Temp Limit
                        </p>
                        <p>Remarks: °C, -50 ~ +200</p>
                    </>
                );
        }

        return null;
    };

    const getBmsLimit2MaxSocLimitValue = () => {
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit2?.maxSoc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBmsLimit2MinSocLimitValue = () => {
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MIN_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit2?.minSoc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBmsLimit2Max10secDischargePowerLimitValue = () => {
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit2?.max10SecDischargePower, Common.UNIT_POWER);
        }
        return "";
    };

    const getBmsLimit2Max10secChargePowerLimitValue = () => {
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit2?.max10SecChargePower, Common.UNIT_POWER);
        }
        return "";
    };

    const getBmsLimit2MaxCellTempLimitValue = () => {
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_CELL_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit2?.maxCellTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBmsLimit2MinCellTempLimitValue = () => {
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MIN_CELL_TEMP_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.bmsLimit2?.minCellTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const onChangeBmsLimit2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBmsLimit2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBmsLimit2: Array<TypeUtils.ChartData> = [];

        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_SOC_KEY)) chartDatasBmsLimit2.push(chartBmsLimit2MaxSocLimit);
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MIN_SOC_KEY)) chartDatasBmsLimit2.push(chartBmsLimit2MinSocLimit);
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_KEY))
            chartDatasBmsLimit2.push(chartBmsLimit2Max10secDischargePowerLimit);
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_KEY))
            chartDatasBmsLimit2.push(chartBmsLimit2Max10secChargePowerLimit);
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MAX_CELL_TEMP_KEY)) chartDatasBmsLimit2.push(chartBmsLimit2MaxCellTempLimit);
        if (selectedBmsLimit2.includes(Common.BMS_LIMIT_2_MIN_CELL_TEMP_KEY)) chartDatasBmsLimit2.push(chartBmsLimit2MinCellTempLimit);

        setSeriesChartDatasBmsLimit2(chartDatasBmsLimit2);
    }, [
        selectedBmsLimit2,
        chartBmsLimit2MaxSocLimit,
        chartBmsLimit2MinSocLimit,
        chartBmsLimit2Max10secDischargePowerLimit,
        chartBmsLimit2Max10secChargePowerLimit,
        chartBmsLimit2MaxCellTempLimit,
        chartBmsLimit2MinCellTempLimit,
    ]);

    const tableDataBmsLimit2: CanTableType[] = [
        {
            key: Common.BMS_LIMIT_2_MAX_SOC_KEY,
            name: Common.BMS_LIMIT_2_MAX_SOC_NAME,
            value: getBmsLimit2MaxSocLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_2_MIN_SOC_KEY,
            name: Common.BMS_LIMIT_2_MIN_SOC_NAME,
            value: getBmsLimit2MinSocLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_KEY,
            name: Common.BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_NAME,
            value: getBmsLimit2Max10secDischargePowerLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_KEY,
            name: Common.BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_NAME,
            value: getBmsLimit2Max10secChargePowerLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_2_MAX_CELL_TEMP_KEY,
            name: Common.BMS_LIMIT_2_MAX_CELL_TEMP_NAME,
            value: getBmsLimit2MaxCellTempLimitValue(),
        },
        {
            key: Common.BMS_LIMIT_2_MIN_CELL_TEMP_KEY,
            name: Common.BMS_LIMIT_2_MIN_CELL_TEMP_NAME,
            value: getBmsLimit2MinCellTempLimitValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Isolation R
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [isolationRPanelOpen, setIsolationRPanelOpen] = useState(false);
    const [isolationRChartMounted, setIsolationRChartMounted] = useState(false);
    const [selectedIsolationR, setSelectedIsolationR] = useState<Array<React.Key>>([]);
    const [seriesChartDatasIsolationR, setSeriesChartDatasIsolationR] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartIsolationRIsolationLifeSignal, setChartIsolationRIsolationLifeSignal] = useState<TypeUtils.ChartData>({
        name: Common.ISOLATION_R_ISOLATION_LIFE_SIGNAL_NAME,
        data: [],
    });
    const [chartIsolationRIsolationResistanceStatus, setChartIsolationRIsolationResistanceStatus] = useState<TypeUtils.ChartData>({
        name: Common.ISOLATION_R_ISOLATION_RESISTANCE_STATUS_NAME,
        data: [],
    });
    const [chartIsolationRFaultLevel, setChartIsolationRFaultLevel] = useState<TypeUtils.ChartData>({
        name: Common.ISOLATION_R_FAULT_LEVEL_NAME,
        data: [],
    });
    const [chartIsolationRRgndPos, setChartIsolationRRgndPos] = useState<TypeUtils.ChartData>({
        name: Common.ISOLATION_R_RGND_POS_NAME,
        data: [],
    });
    const [chartIsolationRRgndNeg, setChartIsolationRRgndNeg] = useState<TypeUtils.ChartData>({
        name: Common.ISOLATION_R_RGND_NEG_NAME,
        data: [],
    });

    const getTabelTooltipIsolationR = (key: React.Key) => {
        switch (key) {
            case Common.ISOLATION_R_ISOLATION_LIFE_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            Isolation R
                            <br />
                            CAN ID: 0x18FFA8F3
                            <br />
                            Name: Isolation Life Signal
                        </p>
                        <p>
                            Remarks: 0 ~ 255
                            <br />
                            자동1추가 @1프레임 발송시
                        </p>
                    </>
                );

            case Common.ISOLATION_R_ISOLATION_RESISTANCE_STATUS_KEY:
                return (
                    <>
                        <p>
                            Isolation R
                            <br />
                            CAN ID: 0x18FFA8F3
                            <br />
                            Name: Isolation Resistance Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=작동정상, 1=작동 비정상
                        </p>
                    </>
                );

            case Common.ISOLATION_R_FAULT_LEVEL_KEY:
                return (
                    <>
                        <p>
                            Isolation R
                            <br />
                            CAN ID: 0x18FFA8F3
                            <br />
                            Name: Fault Levle
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00=if, R&gt;500&#8486;/V 고장없는 것으로 정상,
                            <br />
                            01=if 100&#8486;/V≤R≤500&#8486;/V 2급고장,
                            <br />
                            10=if, R&lt;100&#8486;/V 2급고장 Relay차단 후 즉시 검사
                        </p>
                    </>
                );

            case Common.ISOLATION_R_RGND_POS_KEY:
                return (
                    <>
                        <p>
                            Isolation R
                            <br />
                            CAN ID: 0x18FFA8F3
                            <br />
                            Name: Rgnd Pos
                        </p>
                        <p>
                            Remarks: k&#8486;,
                            <br />
                            하위바이트는 앞에,
                            <br />
                            상위바이트는 뒤에
                        </p>
                    </>
                );

            case Common.ISOLATION_R_RGND_NEG_KEY:
                return (
                    <>
                        <p>
                            Isolation R
                            <br />
                            CAN ID: 0x18FFA8F3
                            <br />
                            Name: Rgnd Neg
                        </p>
                        <p>
                            Remarks: k&#8486;,
                            <br />
                            하위바이트는 앞에,
                            <br />
                            상위바이트는 뒤에
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertIsolationResistanceStatusRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "작동정상";
                break;
            case 1:
                valueString = "작동 비정상";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getIsolationRIsolationLifeSignalValue = () => {
        if (selectedIsolationR.includes(Common.ISOLATION_R_ISOLATION_LIFE_SIGNAL_KEY) && currentCanData !== undefined) {
            return String(currentCanData.isolationR?.lifeSignal);
        }
        return "";
    };

    const getIsolationRIsolationResistanceStatusValue = () => {
        if (selectedIsolationR.includes(Common.ISOLATION_R_ISOLATION_RESISTANCE_STATUS_KEY) && currentCanData !== undefined) {
            return convertIsolationResistanceStatusRemark(currentCanData.isolationR?.resistanceStatus);
        }
        return "";
    };

    const getIsolationRFaultLevelValue = () => {
        if (selectedIsolationR.includes(Common.ISOLATION_R_FAULT_LEVEL_KEY) && currentCanData !== undefined) {
            return String(currentCanData.isolationR?.faultLevel);
        }
        return "";
    };

    const getIsolationRRgndPosValue = () => {
        if (selectedIsolationR.includes(Common.ISOLATION_R_RGND_POS_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.isolationR?.rgndPos, Common.UNIT_K_OHM);
        }
        return "";
    };

    const getIsolationRRgndNegValue = () => {
        if (selectedIsolationR.includes(Common.ISOLATION_R_RGND_NEG_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.isolationR?.rgndNeg, Common.UNIT_K_OHM);
        }
        return "";
    };

    const onChangeIsolationR = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedIsolationR(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasIsolationR: Array<TypeUtils.ChartData> = [];

        if (selectedIsolationR.includes(Common.ISOLATION_R_ISOLATION_LIFE_SIGNAL_KEY))
            chartDatasIsolationR.push(chartIsolationRIsolationLifeSignal);
        if (selectedIsolationR.includes(Common.ISOLATION_R_ISOLATION_RESISTANCE_STATUS_KEY))
            chartDatasIsolationR.push(chartIsolationRIsolationResistanceStatus);
        if (selectedIsolationR.includes(Common.ISOLATION_R_FAULT_LEVEL_KEY)) chartDatasIsolationR.push(chartIsolationRFaultLevel);
        if (selectedIsolationR.includes(Common.ISOLATION_R_RGND_POS_KEY)) chartDatasIsolationR.push(chartIsolationRRgndPos);
        if (selectedIsolationR.includes(Common.ISOLATION_R_RGND_NEG_KEY)) chartDatasIsolationR.push(chartIsolationRRgndNeg);

        setSeriesChartDatasIsolationR(chartDatasIsolationR);
    }, [
        selectedIsolationR,
        chartIsolationRIsolationLifeSignal,
        chartIsolationRIsolationResistanceStatus,
        chartIsolationRFaultLevel,
        chartIsolationRRgndPos,
        chartIsolationRRgndNeg,
    ]);

    const tableDataIsolationR: CanTableType[] = [
        {
            key: Common.ISOLATION_R_ISOLATION_LIFE_SIGNAL_KEY,
            name: Common.ISOLATION_R_ISOLATION_LIFE_SIGNAL_NAME,
            value: getIsolationRIsolationLifeSignalValue(),
        },
        {
            key: Common.ISOLATION_R_ISOLATION_RESISTANCE_STATUS_KEY,
            name: Common.ISOLATION_R_ISOLATION_RESISTANCE_STATUS_NAME,
            value: getIsolationRIsolationResistanceStatusValue(),
        },
        {
            key: Common.ISOLATION_R_FAULT_LEVEL_KEY,
            name: Common.ISOLATION_R_FAULT_LEVEL_NAME,
            value: getIsolationRFaultLevelValue(),
        },
        {
            key: Common.ISOLATION_R_RGND_POS_KEY,
            name: Common.ISOLATION_R_RGND_POS_NAME,
            value: getIsolationRRgndPosValue(),
        },
        {
            key: Common.ISOLATION_R_RGND_NEG_KEY,
            name: Common.ISOLATION_R_RGND_NEG_NAME,
            value: getIsolationRRgndNegValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Information 1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryInformation1PanelOpen, setBatteryInformation1PanelOpen] = useState(false);
    const [batteryInformation1ChartMounted, setBatteryInformation1ChartMounted] = useState(false);
    const [selectedBatteryInformation1, setSelectedBatteryInformation1] = useState<Array<React.Key>>([]);
    const [seriesChartDatasBatteryInformation1, setSeriesChartDatasBatteryInformation1] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatteryInformation1BatterySubsystemNo, setChartBatteryInformation1BatterySubsystemNo] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_NAME,
        data: [],
    });
    const [chartBatteryInformation1TotalCellNo, setChartBatteryInformation1TotalCellNo] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_1_TOTAL_CELL_NAME,
        data: [],
    });
    const [chartBatteryInformation1TempSensorNo, setChartBatteryInformation1TempSensorNo] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_1_TEMP_SENSOR_NAME,
        data: [],
    });
    const [chartBatteryInformation1BatterySystemErrorNo, setChartBatteryInformation1BatterySystemErrorNo] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_NAME,
        data: [],
    });
    const [chartBatteryInformation1BatteryChargingStatus, setChartBatteryInformation1BatteryChargingStatus] = useState<TypeUtils.ChartData>(
        {
            name: Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_NAME,
            data: [],
        }
    );
    const [chartBatteryInformation1BatteryChargingGunConnect, setChartBatteryInformation1BatteryChargingGunConnect] =
        useState<TypeUtils.ChartData>({
            name: Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_NAME,
            data: [],
        });

    const getTabelTooltipBatteryInformation1 = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_KEY:
                return (
                    <>
                        <p>
                            Battery Information 1
                            <br />
                            CAN ID: 0x18FFA9F3
                            <br />
                            Name: Battery Sub system #
                        </p>
                        <p>Remarks: 0 ~ 255</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_1_TOTAL_CELL_KEY:
                return (
                    <>
                        <p>
                            Battery Information 1
                            <br />
                            CAN ID: 0x18FFA9F3
                            <br />
                            Name: Total Cell #
                        </p>
                        <p>Remarks: 0 ~ 65535</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_1_TEMP_SENSOR_KEY:
                return (
                    <>
                        <p>
                            Battery Information 1
                            <br />
                            CAN ID: 0x18FFA9F3
                            <br />
                            Name: Temp Sensor #
                        </p>
                        <p>Remarks: 0 ~ 255</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_KEY:
                return (
                    <>
                        <p>
                            Battery Information 1
                            <br />
                            CAN ID: 0x18FFA9F3
                            <br />
                            Name: Battery System Error #
                        </p>
                        <p>Remarks: 0 ~ 255</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery Information 1
                            <br />
                            CAN ID: 0x18FFA9F3
                            <br />
                            Name: Battery Charging Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            1=정차충전, 2=운전중충전,
                            <br />
                            3=미충전상태, 4=충전완료,
                            <br />
                            0xFE=이상, 0xFF=무효
                        </p>
                    </>
                );

            case Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_KEY:
                return (
                    <>
                        <p>
                            Battery Information 1
                            <br />
                            CAN ID: 0x18FFA9F3
                            <br />
                            Name: Battery Charging Gun Connect
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0=미연결, 1=연결
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertBatteryInformation1BatteryChargingStatusRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 1:
                valueString = "정차충전";
                break;
            case 2:
                valueString = "운전중충전";
                break;
            case 3:
                valueString = "미충전상태";
                break;
            case 4:
                valueString = "충전완료";
                break;
            case 0xfe:
                valueString = "이상";
                break;
            case 0xff:
                valueString = "무효";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertBatteryInformation1BatteryChargingGunConnectRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "미연결";
                break;
            case 1:
                valueString = "연결";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBatteryInformation1BatterySubsystemNo = () => {
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batInfo1?.subsysNo);
        }
        return "";
    };

    const getBatteryInformation1TotalCellNo = () => {
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_TOTAL_CELL_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batInfo1?.totalCellNo);
        }
        return "";
    };

    const getBatteryInformation1TempSensorNo = () => {
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_TEMP_SENSOR_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batInfo1?.tempSensorNo);
        }
        return "";
    };

    const getBatteryInformation1BatterySystemErrorNo = () => {
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batInfo1?.sysErrNo);
        }
        return "";
    };

    const getBatteryInformation1BatteryChargingStatus = () => {
        if (
            selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_KEY) &&
            currentCanData !== undefined
        ) {
            return convertBatteryInformation1BatteryChargingStatusRemark(currentCanData.batInfo1?.chargingStatus);
        }
        return "";
    };

    const getBatteryInformation1BatteryChargingGunConnect = () => {
        if (
            selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_KEY) &&
            currentCanData !== undefined
        ) {
            return convertBatteryInformation1BatteryChargingGunConnectRemark(currentCanData.batInfo1?.chargingGunConnect);
        }
        return "";
    };

    const onChangeBatteryInformation1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryInformation1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryInformation1: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_KEY))
            chartDatasBatteryInformation1.push(chartBatteryInformation1BatterySubsystemNo);
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_TOTAL_CELL_KEY))
            chartDatasBatteryInformation1.push(chartBatteryInformation1TotalCellNo);
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_TEMP_SENSOR_KEY))
            chartDatasBatteryInformation1.push(chartBatteryInformation1TempSensorNo);
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_KEY))
            chartDatasBatteryInformation1.push(chartBatteryInformation1BatterySystemErrorNo);
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_KEY))
            chartDatasBatteryInformation1.push(chartBatteryInformation1BatteryChargingStatus);
        if (selectedBatteryInformation1.includes(Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_KEY))
            chartDatasBatteryInformation1.push(chartBatteryInformation1BatteryChargingGunConnect);

        setSeriesChartDatasBatteryInformation1(chartDatasBatteryInformation1);
    }, [
        selectedBatteryInformation1,
        chartBatteryInformation1BatterySubsystemNo,
        chartBatteryInformation1TotalCellNo,
        chartBatteryInformation1TempSensorNo,
        chartBatteryInformation1BatterySystemErrorNo,
        chartBatteryInformation1BatteryChargingStatus,
        chartBatteryInformation1BatteryChargingGunConnect,
    ]);

    const tableDataBatteryInformation1: CanTableType[] = [
        {
            key: Common.BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_KEY,
            name: Common.BATTERY_INFORMATION_1_BATTERY_SUB_SYSTEM_NAME,
            value: getBatteryInformation1BatterySubsystemNo(),
        },
        {
            key: Common.BATTERY_INFORMATION_1_TOTAL_CELL_KEY,
            name: Common.BATTERY_INFORMATION_1_TOTAL_CELL_NAME,
            value: getBatteryInformation1TotalCellNo(),
        },
        {
            key: Common.BATTERY_INFORMATION_1_TEMP_SENSOR_KEY,
            name: Common.BATTERY_INFORMATION_1_TEMP_SENSOR_NAME,
            value: getBatteryInformation1TempSensorNo(),
        },
        {
            key: Common.BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_KEY,
            name: Common.BATTERY_INFORMATION_1_BATTERY_SYSTEM_ERROR_NAME,
            value: getBatteryInformation1BatterySystemErrorNo(),
        },
        {
            key: Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_KEY,
            name: Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_NAME,
            value: getBatteryInformation1BatteryChargingStatus(),
        },
        {
            key: Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_KEY,
            name: Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_NAME,
            value: getBatteryInformation1BatteryChargingGunConnect(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Information 2
    // UNUSED
    //#endregion

    //#region Battery Information 3
    // UNUSED
    //#endregion

    //#region Battery Information 4
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryInformation4PanelOpen, setBatteryInformation4PanelOpen] = useState(false);
    const [batteryInformation4ChartMounted, setBatteryInformation4ChartMounted] = useState(false);
    const [selectedBatteryInformation4, setSelectedBatteryInformation4] = useState<Array<React.Key>>([]);
    const [seriesChartDatasBatteryInformation4, setSeriesChartDatasBatteryInformation4] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartBatteryInformation4BatteryPackAccumulatedOutput, setChartBatteryInformation4BatteryPackAccumulatedOutput] =
        useState<TypeUtils.ChartData>({
            name: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_NAME,
            data: [],
        });
    const [chartBatteryInformation4BatteryPackAccumulatedCharging, setChartBatteryInformation4BatteryPackAccumulatedCharging] =
        useState<TypeUtils.ChartData>({
            name: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_NAME,
            data: [],
        });
    const [chartBatteryInformation4BatteryPackAccumulatedRegen, setChartBatteryInformation4BatteryPackAccumulatedRegen] =
        useState<TypeUtils.ChartData>({
            name: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_NAME,
            data: [],
        });

    const getTabelTooltipBatteryInformation4 = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_KEY:
                return (
                    <>
                        <p>
                            Battery Information 4
                            <br />
                            CAN ID: 0x18FFAFF3
                            <br />
                            Name: 배터리 팩 누적 출력 에너지
                        </p>
                        <p>Remarks: kWh, 1~1000000</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_KEY:
                return (
                    <>
                        <p>
                            Battery Information 4
                            <br />
                            CAN ID: 0x18FFAFF3
                            <br />
                            Name: 배터리 팩 누적 충전 에너지
                        </p>
                        <p>Remarks: kWh, 1~1000000</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_KEY:
                return (
                    <>
                        <p>
                            Battery Information 4
                            <br />
                            CAN ID: 0x18FFAFF3
                            <br />
                            Name: 배터리 팩 누적 회생제동 에너지
                        </p>
                        <p>Remarks: kWh, 1~1000000</p>
                    </>
                );
        }

        return null;
    };

    const getBatteryInformation4BatteryPackAccumulatedOutput = () => {
        if (
            selectedBatteryInformation4.includes(Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_KEY) &&
            currentCanData !== undefined
        ) {
            return convertRemarkWithUnit(currentCanData.batInfo4?.packOutputEnergy, Common.UNIT_POWER_H);
        }
        return "";
    };

    const getBatteryInformation4BatteryPackAccumulatedCharging = () => {
        if (
            selectedBatteryInformation4.includes(Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_KEY) &&
            currentCanData !== undefined
        ) {
            return convertRemarkWithUnit(currentCanData.batInfo4?.packChargeEnergy, Common.UNIT_POWER_H);
        }
        return "";
    };

    const getBatteryInformation4BatteryPackAccumulatedRegen = () => {
        if (
            selectedBatteryInformation4.includes(Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_KEY) &&
            currentCanData !== undefined
        ) {
            return convertRemarkWithUnit(currentCanData.batInfo4?.packRegenEnergy, Common.UNIT_POWER_H);
        }
        return "";
    };

    const onChangeBatteryInformation4 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryInformation4(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryInformation4: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryInformation4.includes(Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_KEY))
            chartDatasBatteryInformation4.push(chartBatteryInformation4BatteryPackAccumulatedOutput);
        if (selectedBatteryInformation4.includes(Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_KEY))
            chartDatasBatteryInformation4.push(chartBatteryInformation4BatteryPackAccumulatedCharging);
        if (selectedBatteryInformation4.includes(Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_KEY))
            chartDatasBatteryInformation4.push(chartBatteryInformation4BatteryPackAccumulatedRegen);

        setSeriesChartDatasBatteryInformation4(chartDatasBatteryInformation4);
    }, [
        selectedBatteryInformation4,
        chartBatteryInformation4BatteryPackAccumulatedOutput,
        chartBatteryInformation4BatteryPackAccumulatedCharging,
        chartBatteryInformation4BatteryPackAccumulatedRegen,
    ]);

    const tableDataBatteryInformation4: CanTableType[] = [
        {
            key: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_KEY,
            name: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_NAME,
            value: getBatteryInformation4BatteryPackAccumulatedOutput(),
        },
        {
            key: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_KEY,
            name: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_NAME,
            value: getBatteryInformation4BatteryPackAccumulatedCharging(),
        },
        {
            key: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_KEY,
            name: Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_NAME,
            value: getBatteryInformation4BatteryPackAccumulatedRegen(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Cell Voltage Status
    // UNUSED
    //#endregion

    //#region Cell Temp Status
    // UNUSED
    //#endregion

    //#region Heat Plate Temp Status
    // UNUSED
    //#endregion

    //#region Charging Status
    // UNUSED
    //#endregion

    //#region Charging Socket Temp
    // UNUSED
    //#endregion

    //#region BCU DTC
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [bcuDtcPanelOpen, setBcuDtcPanelOpen] = useState(false);
    const [bcuDtcChartMounted, setBcuDtcChartMounted] = useState(false);
    const [selectedBcuDtc, setSelectedBcuDtc] = useState<Array<React.Key>>([]);
    const [seriesChartDatasBcuDtc, setSeriesChartDatasBcuDtc] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartWarningCellUvp, setChartWarningCellUvp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_CELL_UVP_NAME,
        data: [],
    });
    const [chartWarning2CellUvp, setChartWarning2CellUvp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_CELL_UVP_NAME,
        data: [],
    });
    const [chartFaultCellUvp, setChartFaultCellUvp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_CELL_UVP_NAME,
        data: [],
    });

    const [chartWarningCellOvp, setChartWarningCellOvp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_CELL_OVP_NAME,
        data: [],
    });
    const [chartWarning2CellOvp, setChartWarning2CellOvp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_CELL_OVP_NAME,
        data: [],
    });
    const [chartFaultCellOvp, setChartFaultCellOvp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_CELL_OVP_NAME,
        data: [],
    });

    const [chartWarningTotalVoltageCollectFailLevel2, setChartWarningTotalVoltageCollectFailLevel2] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_NAME,
        data: [],
    });
    const [chartWarningCellVoltageCollectFailLevel3, setChartWarningCellVoltageCollectFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_NAME,
        data: [],
    });

    const [chartWarningCellUtp, setChartWarningCellUtp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_CELL_UTP_NAME,
        data: [],
    });
    const [chartWarning2CellUtp, setChartWarning2CellUtp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_CELL_UTP_NAME,
        data: [],
    });
    const [chartFaultCellUtp, setChartFaultCellUtp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_CELL_UTP_NAME,
        data: [],
    });

    const [chartWarningCellOtp, setChartWarningCellOtp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_CELL_OTP_NAME,
        data: [],
    });
    const [chartWarning2CellOtp, setChartWarning2CellOtp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_CELL_OTP_NAME,
        data: [],
    });
    const [chartFaultCellOtp, setChartFaultCellOtp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_CELL_OTP_NAME,
        data: [],
    });

    const [chartWarningTempChannelFailLevel2, setChartWarningTempChannelFailLevel2] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_NAME,
        data: [],
    });
    const [chartWarningBalancingChannelFailLevel1, setChartWarningBalancingChannelFailLevel1] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_NAME,
        data: [],
    });

    const [chartWarningPackUpv, setChartWarningPackUpv] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_PACK_UPV_NAME,
        data: [],
    });
    const [chartWarning2PackUpv, setChartWarning2PackUpv] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_PACK_UPV_NAME,
        data: [],
    });
    const [chartFaultPackUpv, setChartFaultPackUpv] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_PACK_UPV_NAME,
        data: [],
    });

    const [chartWarningPackOpv, setChartWarningPackOpv] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_PACK_OPV_NAME,
        data: [],
    });
    const [chartWarning2PackOpv, setChartWarning2PackOpv] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_PACK_OPV_NAME,
        data: [],
    });
    const [chartFaultPackOpv, setChartFaultPackOpv] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_PACK_OPV_NAME,
        data: [],
    });

    const [chartWarningPreCharRelayAdhesionFailLevel3, setChartWarningPreCharRelayAdhesionFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_NAME,
        data: [],
    });
    const [chartWarningPreCharRelayFailureFailLevel3, setChartWarningPreCharRelayFailureFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_NAME,
        data: [],
    });

    const [chartWarningPackVoltageDiff, setChartWarningPackVoltageDiff] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_NAME,
        data: [],
    });
    const [chartWarning2PackVoltageDiff, setChartWarning2PackVoltageDiff] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_NAME,
        data: [],
    });
    const [chartFaultPackVoltageDiff, setChartFaultPackVoltageDiff] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_NAME,
        data: [],
    });

    const [chartWarningPackTempDiff, setChartWarningPackTempDiff] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_NAME,
        data: [],
    });
    const [chartWarning2PackTempDiff, setChartWarning2PackTempDiff] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_NAME,
        data: [],
    });

    const [chartWarningCurrentCollectFault, setChartWarningCurrentCollectFault] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_NAME,
        data: [],
    });
    const [chartWarningMainRelayPlusAdhesionFailLevel3, setChartWarningMainRelayPlusAdhesionFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_NAME,
        data: [],
    });
    const [chartWarningMainRelayMinusAdhesionFailLevel3, setChartWarningMainRelayMinusAdhesionFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_NAME,
        data: [],
    });

    const [chartWarningUnderSoc, setChartWarningUnderSoc] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_UNDER_SOC_NAME,
        data: [],
    });
    const [chartWarning2UnderSoc, setChartWarning2UnderSoc] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_UNDER_SOC_NAME,
        data: [],
    });
    const [chartFaultUnderSoc, setChartFaultUnderSoc] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_UNDER_SOC_NAME,
        data: [],
    });

    const [chartWarningOverSoc, setChartWarningOverSoc] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_OVER_SOC_NAME,
        data: [],
    });
    const [chartWarning2OverSoc, setChartWarning2OverSoc] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_OVER_SOC_NAME,
        data: [],
    });
    const [chartFaultOverSoc, setChartFaultOverSoc] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_OVER_SOC_NAME,
        data: [],
    });

    const [chartWarningBmsInternalCommFailLevel3, setChartWarningBmsInternalCommFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_NAME,
        data: [],
    });
    const [chartWarningBmsVehicleCommFailLevel3, setChartWarningBmsVehicleCommFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_NAME,
        data: [],
    });

    const [chartWarningDischargeOcp, setChartWarningDischargeOcp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_DISCHARGE_OCP_NAME,
        data: [],
    });
    const [chartWarning2DischargeOcp, setChartWarning2DischargeOcp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_DISCHARGE_OCP_NAME,
        data: [],
    });
    const [chartFaultDischargeOcp, setChartFaultDischargeOcp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_DISCHARGE_OCP_NAME,
        data: [],
    });

    const [chartWarningChargeOcp, setChartWarningChargeOcp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_CHARGE_OCP_NAME,
        data: [],
    });
    const [chartWarning2ChargeOcp, setChartWarning2ChargeOcp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING2_CHARGE_OCP_NAME,
        data: [],
    });
    const [chartFaultChargeOcp, setChartFaultChargeOcp] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_FAULT_CHARGE_OCP_NAME,
        data: [],
    });

    const [chartWarningInsulationFailLevel2, setChartWarningInsulationFailLevel2] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_NAME,
        data: [],
    });

    const [chartWarningInsulationFailLevel3, setChartWarningInsulationFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_NAME,
        data: [],
    });

    const [chartWarningMotorRelayAdhesionFailLevel3, setChartWarningMotorRelayAdhesionFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_NAME,
        data: [],
    });

    const [chartWarningDischargeFreezingCircuitBreakingFailLevel2, setChartWarningDischargeFreezingCircuitBreakingFailLevel2] =
        useState<TypeUtils.ChartData>({
            name: Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME,
            data: [],
        });
    const [chartWarningChargeFreezeCircuitBreakingFailLevel2, setChartWarningChargeFreezeCircuitBreakingFailLevel2] =
        useState<TypeUtils.ChartData>({
            name: Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME,
            data: [],
        });
    const [chartWarningFastChargingRelayAdhesionFailLevel3, setChartWarningFastChargingRelayAdhesionFailLevel3] =
        useState<TypeUtils.ChartData>({
            name: Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME,
            data: [],
        });
    const [chartWarningSlowChargingRelayAdhesionFailLevel3, setChartWarningSlowChargingRelayAdhesionFailLevel3] =
        useState<TypeUtils.ChartData>({
            name: Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME,
            data: [],
        });

    const [chartWarningHeatingRelayAdhesionFailLevel3, setChartWarningHeatingRelayAdhesionFailLevel3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_NAME,
        data: [],
    });
    const [chartWarningSocJumpAbnormal, setChartWarningSocJumpAbnormal] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_NAME,
        data: [],
    });

    const [chartWarningBatterySystemMismatch, setChartWarningBatterySystemMismatch] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_NAME,
        data: [],
    });

    const [chartWarningFastChargingAnodeHighTempClass2, setChartWarningFastChargingAnodeHighTempClass2] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_NAME,
        data: [],
    });
    const [chartWarningFastChargingAnodeHighTempClass3, setChartWarningFastChargingAnodeHighTempClass3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_NAME,
        data: [],
    });
    const [chartWarningFastChargingCathodeHighTempClass2, setChartWarningFastChargingCathodeHighTempClass2] = useState<TypeUtils.ChartData>(
        {
            name: Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME,
            data: [],
        }
    );
    const [chartWarningFastChargingCathodeHighTempClass3, setChartWarningFastChargingCathodeHighTempClass3] = useState<TypeUtils.ChartData>(
        {
            name: Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME,
            data: [],
        }
    );

    const [chartWarningSlowChargingAnodeHighTempClass2, setChartWarningSlowChargingAnodeHighTempClass2] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_NAME,
        data: [],
    });
    const [chartWarningSlowChargingAnodeHighTempClass3, setChartWarningSlowChargingAnodeHighTempClass3] = useState<TypeUtils.ChartData>({
        name: Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_NAME,
        data: [],
    });
    const [chartWarningSlowChargingCathodeHighTempClass2, setChartWarningSlowChargingCathodeHighTempClass2] = useState<TypeUtils.ChartData>(
        {
            name: Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME,
            data: [],
        }
    );
    const [chartWarningSlowChargingCathodeHighTempClass3, setChartWarningSlowChargingCathodeHighTempClass3] = useState<TypeUtils.ChartData>(
        {
            name: Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME,
            data: [],
        }
    );

    const getTabelTooltipBcuDtc = (key: React.Key) => {
        return null;
    };

    const convertBcuDtcRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "Fault";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBcuDtcWarningCellUvpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_UVP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnCellUvp);
        }
        return "";
    };
    const getBcuDtcWarning2CellUvpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_UVP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2CellUvp);
        }
        return "";
    };
    const getBcuDtcFaultCellUvpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_UVP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultCellUvp);
        }
        return "";
    };

    const getBcuDtcWarningCellOvpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_OVP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnCellOvp);
        }
        return "";
    };
    const getBcuDtcWarning2CellOvpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_OVP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2CellOvp);
        }
        return "";
    };
    const getBcuDtcFaultCellOvpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_OVP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultCellOvp);
        }
        return "";
    };

    const getBcuDtcWarningTotalVoltageCollectFailLevel2Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2TotalVoltCollect);
        }
        return "";
    };
    const getBcuDtcWarningCellVoltageCollectFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_OVP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3CellVoltCollect);
        }
        return "";
    };

    const getBcuDtcWarningCellUtpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_UTP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnCellUtp);
        }
        return "";
    };
    const getBcuDtcWarning2CellUtpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_UTP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2CellUtp);
        }
        return "";
    };
    const getBcuDtcFaultCellUtpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_UTP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultCellUtp);
        }
        return "";
    };

    const getBcuDtcWarningCellOtpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_OTP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnCellOtp);
        }
        return "";
    };
    const getBcuDtcWarning2CellOtpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_OTP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2CellOtp);
        }
        return "";
    };
    const getBcuDtcFaultCellOtpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_OTP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultCellOtp);
        }
        return "";
    };

    const getBcuDtcWarningTempChannelFailLevel2Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2TempChannel);
        }
        return "";
    };
    const getBcuDtcWarningBalancingChannelFailLevel1Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn1BalancingChannel);
        }
        return "";
    };

    const getBcuDtcWarningPackUpvValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_UPV_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnPackUpv);
        }
        return "";
    };
    const getBcuDtcWarning2PackUpvValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_UPV_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2PackUpv);
        }
        return "";
    };
    const getBcuDtcFaultPackUpvValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_PACK_UPV_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultPackUpv);
        }
        return "";
    };

    const getBcuDtcWarningPackOpvValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_OPV_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnPackOpv);
        }
        return "";
    };
    const getBcuDtcWarning2PackOpvValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_OPV_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2PackOpv);
        }
        return "";
    };
    const getBcuDtcFaultPackOpvValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_PACK_OPV_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultPackOpv);
        }
        return "";
    };

    const getBcuDtcWarningPrecharrelayAdhesionFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3PrechargeRelayAdhesion);
        }
        return "";
    };
    const getBcuDtcWarningPrecharrelayFailureFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3PrechargeRelayFail);
        }
        return "";
    };

    const getBcuDtcWarningPackVoltageDiffValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnPackVoltDiff);
        }
        return "";
    };
    const getBcuDtcWarning2PackVoltageDiffValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2PackVoltDiff);
        }
        return "";
    };
    const getBcuDtcFaultPackVoltageDiffValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultPackVoltDiff);
        }
        return "";
    };

    const getBcuDtcWarningPackTempDiffValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnPackTempDiff);
        }
        return "";
    };
    const getBcuDtcWarning2PackTempDiffValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2PackTempDiff);
        }
        return "";
    };

    const getBcuDtcWarningCurrentCollectFaultValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnCurrCollect);
        }
        return "";
    };

    const getBcuDtcWarningMainrelayPlusAdhesionFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3MainRelayPlusAdhesion);
        }
        return "";
    };
    const getBcuDtcWarningMainrelayMinusAdhesionFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3MainRelayMinusAdhesion);
        }
        return "";
    };

    const getBcuDtcWarningUnderSocValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_UNDER_SOC_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnUnderSoc);
        }
        return "";
    };
    const getBcuDtcWarning2UnderSocValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_UNDER_SOC_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2UnderSoc);
        }
        return "";
    };
    const getBcuDtcFaultUnderSocValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_UNDER_SOC_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultUnderSoc);
        }
        return "";
    };

    const getBcuDtcWarningOverSocValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_OVER_SOC_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnOverSoc);
        }
        return "";
    };
    const getBcuDtcWarning2OverSocValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_OVER_SOC_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2OverSoc);
        }
        return "";
    };
    const getBcuDtcFaultOverSocValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_OVER_SOC_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultOverSoc);
        }
        return "";
    };

    const getBcuDtcWarningBmsInternalCommFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3BmsInterNetwork);
        }
        return "";
    };
    const getBcuDtcWarningBmsVehicleCommFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3BmsVehicleNetwork);
        }
        return "";
    };

    const getBcuDtcWarningDischargeOcpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_DISCHARGE_OCP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnDischargeOcp);
        }
        return "";
    };
    const getBcuDtcWarning2DischargeOcpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_DISCHARGE_OCP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2DischargeOcp);
        }
        return "";
    };
    const getBcuDtcFaultDischargeOcpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_DISCHARGE_OCP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultDischargeOcp);
        }
        return "";
    };

    const getBcuDtcWarningChargeOcpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CHARGE_OCP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnChargeOcp);
        }
        return "";
    };
    const getBcuDtcWarning2ChargeOcpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CHARGE_OCP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2ChargeOcp);
        }
        return "";
    };
    const getBcuDtcFaultChargeOcpValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CHARGE_OCP_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.faultChargeOcp);
        }
        return "";
    };

    const getBcuDtcWarningInsulationFailLevel2Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2Insul);
        }
        return "";
    };
    const getBcuDtcWarningInsulationFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3Insul);
        }
        return "";
    };

    const getBcuDtcWarningMotorRelayAdhesionFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3MotorRelayAdhesion);
        }
        return "";
    };

    const getBcuDtcWarningDischargeFreezingCircuitBreakingFailLevel2Value = () => {
        if (
            selectedBcuDtc.includes(Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY) &&
            currentCanData !== undefined
        ) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2DischargeFreezeCircuitBlock);
        }
        return "";
    };

    const getBcuDtcWarningChargeFreezingCircuitBreakingFailLevel2Value = () => {
        if (
            selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY) &&
            currentCanData !== undefined
        ) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2ChargeFreezeCircuitBlock);
        }
        return "";
    };

    const getBcuDtcWarningFastchargingRelayAdhesionFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3QuickChargeRelayAdhesion);
        }
        return "";
    };

    const getBcuDtcWarningSlowchargingRelayAdhesionFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3SlowChargeRelayAdhesion);
        }
        return "";
    };

    const getBcuDtcWarningHeatingRelayAdhesionFailLevel3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3HeatRelayAdhesion);
        }
        return "";
    };

    const getBcuDtcWarningSocJumpAbnormalValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnSocJump);
        }
        return "";
    };

    const getBcuDtcWarningBatterySystemMismatchValue = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warnBatSysMismatch);
        }
        return "";
    };

    const getBcuDtcWarningFastchargingAnodeHightempClass2Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2QuickChargeAnodeHighTemp);
        }
        return "";
    };
    const getBcuDtcWarningFastchargingAnodeHightempClass3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3QuickChargeAnodeHighTemp);
        }
        return "";
    };
    const getBcuDtcWarningFastchargingCathodeHightempClass2Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2QuickChargeCathodeHighTemp);
        }
        return "";
    };
    const getBcuDtcWarningFastchargingCathodeHightempClass3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3QuickChargeCathodeHighTemp);
        }
        return "";
    };

    const getBcuDtcWarningSlowchargingAnodeHightempClass2Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2SlowChargeAnodeHighTemp);
        }
        return "";
    };
    const getBcuDtcWarningSlowchargingAnodeHightempClass3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3SlowChargeAnodeHighTemp);
        }
        return "";
    };
    const getBcuDtcWarningSlowchargingCathodeHightempClass2Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn2SlowChargeCathodeHighTemp);
        }
        return "";
    };
    const getBcuDtcWarningSlowchargingCathodeHightempClass3Value = () => {
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY) && currentCanData !== undefined) {
            return convertBcuDtcRemark(currentCanData.bcuDtc?.warn3SlowChargeCathodeHighTemp);
        }
        return "";
    };

    const onChangeBcuDtc = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBcuDtc(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBctDtc: Array<TypeUtils.ChartData> = [];

        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_UVP_KEY)) chartDatasBctDtc.push(chartWarningCellUvp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_UVP_KEY)) chartDatasBctDtc.push(chartWarning2CellUvp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_UVP_KEY)) chartDatasBctDtc.push(chartFaultCellUvp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_OVP_KEY)) chartDatasBctDtc.push(chartWarningCellOvp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_OVP_KEY)) chartDatasBctDtc.push(chartWarning2CellOvp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_OVP_KEY)) chartDatasBctDtc.push(chartFaultCellOvp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_KEY))
            chartDatasBctDtc.push(chartWarningTotalVoltageCollectFailLevel2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningCellVoltageCollectFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_UTP_KEY)) chartDatasBctDtc.push(chartWarningCellUtp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_UTP_KEY)) chartDatasBctDtc.push(chartWarning2CellUtp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_UTP_KEY)) chartDatasBctDtc.push(chartFaultCellUtp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CELL_OTP_KEY)) chartDatasBctDtc.push(chartWarningCellOtp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CELL_OTP_KEY)) chartDatasBctDtc.push(chartWarning2CellOtp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CELL_OTP_KEY)) chartDatasBctDtc.push(chartFaultCellOtp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_KEY))
            chartDatasBctDtc.push(chartWarningTempChannelFailLevel2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_KEY))
            chartDatasBctDtc.push(chartWarningBalancingChannelFailLevel1);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_UPV_KEY)) chartDatasBctDtc.push(chartWarningPackUpv);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_UPV_KEY)) chartDatasBctDtc.push(chartWarning2PackUpv);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_PACK_UPV_KEY)) chartDatasBctDtc.push(chartFaultPackUpv);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_OPV_KEY)) chartDatasBctDtc.push(chartWarningPackOpv);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_OPV_KEY)) chartDatasBctDtc.push(chartWarning2PackOpv);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_PACK_OPV_KEY)) chartDatasBctDtc.push(chartFaultPackOpv);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningPreCharRelayAdhesionFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningPreCharRelayFailureFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_KEY)) chartDatasBctDtc.push(chartWarningPackVoltageDiff);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_KEY)) chartDatasBctDtc.push(chartWarning2PackVoltageDiff);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_KEY)) chartDatasBctDtc.push(chartFaultPackVoltageDiff);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_KEY)) chartDatasBctDtc.push(chartWarningPackTempDiff);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_KEY)) chartDatasBctDtc.push(chartWarning2PackTempDiff);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_KEY))
            chartDatasBctDtc.push(chartWarningCurrentCollectFault);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningMainRelayPlusAdhesionFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningMainRelayMinusAdhesionFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_UNDER_SOC_KEY)) chartDatasBctDtc.push(chartWarningUnderSoc);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_UNDER_SOC_KEY)) chartDatasBctDtc.push(chartWarning2UnderSoc);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_UNDER_SOC_KEY)) chartDatasBctDtc.push(chartFaultUnderSoc);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_OVER_SOC_KEY)) chartDatasBctDtc.push(chartWarningOverSoc);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_OVER_SOC_KEY)) chartDatasBctDtc.push(chartWarning2OverSoc);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_OVER_SOC_KEY)) chartDatasBctDtc.push(chartFaultOverSoc);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningBmsInternalCommFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningBmsVehicleCommFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_DISCHARGE_OCP_KEY)) chartDatasBctDtc.push(chartWarningDischargeOcp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_DISCHARGE_OCP_KEY)) chartDatasBctDtc.push(chartWarning2DischargeOcp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_DISCHARGE_OCP_KEY)) chartDatasBctDtc.push(chartFaultDischargeOcp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CHARGE_OCP_KEY)) chartDatasBctDtc.push(chartWarningChargeOcp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING2_CHARGE_OCP_KEY)) chartDatasBctDtc.push(chartWarning2ChargeOcp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_FAULT_CHARGE_OCP_KEY)) chartDatasBctDtc.push(chartFaultChargeOcp);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_KEY))
            chartDatasBctDtc.push(chartWarningInsulationFailLevel2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningInsulationFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningMotorRelayAdhesionFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY))
            chartDatasBctDtc.push(chartWarningDischargeFreezingCircuitBreakingFailLevel2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY))
            chartDatasBctDtc.push(chartWarningChargeFreezeCircuitBreakingFailLevel2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningFastChargingRelayAdhesionFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningSlowChargingRelayAdhesionFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_KEY))
            chartDatasBctDtc.push(chartWarningHeatingRelayAdhesionFailLevel3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_KEY)) chartDatasBctDtc.push(chartWarningSocJumpAbnormal);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_KEY))
            chartDatasBctDtc.push(chartWarningBatterySystemMismatch);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_KEY))
            chartDatasBctDtc.push(chartWarningFastChargingAnodeHighTempClass2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_KEY))
            chartDatasBctDtc.push(chartWarningFastChargingAnodeHighTempClass3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY))
            chartDatasBctDtc.push(chartWarningFastChargingCathodeHighTempClass2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY))
            chartDatasBctDtc.push(chartWarningFastChargingCathodeHighTempClass3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_KEY))
            chartDatasBctDtc.push(chartWarningSlowChargingAnodeHighTempClass2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_KEY))
            chartDatasBctDtc.push(chartWarningSlowChargingAnodeHighTempClass3);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY))
            chartDatasBctDtc.push(chartWarningSlowChargingCathodeHighTempClass2);
        if (selectedBcuDtc.includes(Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY))
            chartDatasBctDtc.push(chartWarningSlowChargingCathodeHighTempClass3);

        setSeriesChartDatasBcuDtc(chartDatasBctDtc);
    }, [
        selectedBcuDtc,
        chartWarningCellUvp,
        chartWarning2CellUvp,
        chartFaultCellUvp,
        chartWarningCellOvp,
        chartWarning2CellOvp,
        chartFaultCellOvp,
        chartWarningTotalVoltageCollectFailLevel2,
        chartWarningCellVoltageCollectFailLevel3,
        chartWarningCellUtp,
        chartWarning2CellUtp,
        chartFaultCellUtp,
        chartWarningCellOtp,
        chartWarning2CellOtp,
        chartFaultCellOtp,
        chartWarningTempChannelFailLevel2,
        chartWarningBalancingChannelFailLevel1,
        chartWarningPackUpv,
        chartWarning2PackUpv,
        chartFaultPackUpv,
        chartWarningPackOpv,
        chartWarning2PackOpv,
        chartFaultPackOpv,
        chartWarningPreCharRelayAdhesionFailLevel3,
        chartWarningPreCharRelayFailureFailLevel3,
        chartWarningPackVoltageDiff,
        chartWarning2PackVoltageDiff,
        chartFaultPackVoltageDiff,
        chartWarningPackTempDiff,
        chartWarning2PackTempDiff,
        chartWarningCurrentCollectFault,
        chartWarningMainRelayPlusAdhesionFailLevel3,
        chartWarningMainRelayMinusAdhesionFailLevel3,
        chartWarningUnderSoc,
        chartWarning2UnderSoc,
        chartFaultUnderSoc,
        chartWarningOverSoc,
        chartWarning2OverSoc,
        chartFaultOverSoc,
        chartWarningBmsInternalCommFailLevel3,
        chartWarningBmsVehicleCommFailLevel3,
        chartWarningDischargeOcp,
        chartWarning2DischargeOcp,
        chartFaultDischargeOcp,
        chartWarningChargeOcp,
        chartWarning2ChargeOcp,
        chartFaultChargeOcp,
        chartWarningInsulationFailLevel2,
        chartWarningInsulationFailLevel3,
        chartWarningMotorRelayAdhesionFailLevel3,
        chartWarningDischargeFreezingCircuitBreakingFailLevel2,
        chartWarningChargeFreezeCircuitBreakingFailLevel2,
        chartWarningFastChargingRelayAdhesionFailLevel3,
        chartWarningSlowChargingRelayAdhesionFailLevel3,
        chartWarningHeatingRelayAdhesionFailLevel3,
        chartWarningSocJumpAbnormal,
        chartWarningBatterySystemMismatch,
        chartWarningFastChargingAnodeHighTempClass2,
        chartWarningFastChargingAnodeHighTempClass3,
        chartWarningFastChargingCathodeHighTempClass2,
        chartWarningFastChargingCathodeHighTempClass3,
        chartWarningSlowChargingAnodeHighTempClass2,
        chartWarningSlowChargingAnodeHighTempClass3,
        chartWarningSlowChargingCathodeHighTempClass2,
        chartWarningSlowChargingCathodeHighTempClass3,
    ]);

    const [tableDataBcuDtc, setTableDataBcuDtc] = useState<Array<CanTableType>>([]);
    const [existFaultDataBcuDtc, setexistFaultDataBcuDtc] = useState<Array<string>>([]);

    const checkExistFaultDataBcuDtc = (key: string, value: number) => {
        if (value === 1) {
            if (!existFaultDataBcuDtc.includes(key)) {
                const newExistFaultDataBcuDtc = existFaultDataBcuDtc.slice();
                newExistFaultDataBcuDtc.push(key);

                setexistFaultDataBcuDtc(newExistFaultDataBcuDtc);
            }
        }
    };

    const getTableDataBcuDtc = (key: string) => {
        let tabelData = undefined;
        switch (key) {
            case Common.BCU_DTC_WARNING_CELL_UVP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CELL_UVP_KEY,
                    name: Common.BCU_DTC_WARNING_CELL_UVP_NAME,
                    value: getBcuDtcWarningCellUvpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_CELL_UVP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_CELL_UVP_KEY,
                    name: Common.BCU_DTC_WARNING2_CELL_UVP_NAME,
                    value: getBcuDtcWarning2CellUvpValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_CELL_UVP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_CELL_UVP_KEY,
                    name: Common.BCU_DTC_FAULT_CELL_UVP_NAME,
                    value: getBcuDtcFaultCellUvpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_CELL_OVP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CELL_OVP_KEY,
                    name: Common.BCU_DTC_WARNING_CELL_OVP_NAME,
                    value: getBcuDtcWarningCellOvpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_CELL_OVP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_CELL_OVP_KEY,
                    name: Common.BCU_DTC_WARNING2_CELL_OVP_NAME,
                    value: getBcuDtcWarning2CellOvpValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_CELL_OVP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_CELL_OVP_KEY,
                    name: Common.BCU_DTC_FAULT_CELL_OVP_NAME,
                    value: getBcuDtcFaultCellOvpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_KEY,
                    name: Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_NAME,
                    value: getBcuDtcWarningTotalVoltageCollectFailLevel2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningCellVoltageCollectFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_CELL_UTP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CELL_UTP_KEY,
                    name: Common.BCU_DTC_WARNING_CELL_UTP_NAME,
                    value: getBcuDtcWarningCellUtpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_CELL_UTP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_CELL_UTP_KEY,
                    name: Common.BCU_DTC_WARNING2_CELL_UTP_NAME,
                    value: getBcuDtcWarning2CellUtpValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_CELL_UTP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_CELL_UTP_KEY,
                    name: Common.BCU_DTC_FAULT_CELL_UTP_NAME,
                    value: getBcuDtcFaultCellUtpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_CELL_OTP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CELL_OTP_KEY,
                    name: Common.BCU_DTC_WARNING_CELL_OTP_NAME,
                    value: getBcuDtcWarningCellOtpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_CELL_OTP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_CELL_OTP_KEY,
                    name: Common.BCU_DTC_WARNING2_CELL_OTP_NAME,
                    value: getBcuDtcWarning2CellOtpValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_CELL_OTP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_CELL_OTP_KEY,
                    name: Common.BCU_DTC_FAULT_CELL_OTP_NAME,
                    value: getBcuDtcFaultCellOtpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_KEY,
                    name: Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_NAME,
                    value: getBcuDtcWarningTempChannelFailLevel2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_KEY,
                    name: Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_NAME,
                    value: getBcuDtcWarningBalancingChannelFailLevel1Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_PACK_UPV_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_PACK_UPV_KEY,
                    name: Common.BCU_DTC_WARNING_PACK_UPV_NAME,
                    value: getBcuDtcWarningPackUpvValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_PACK_UPV_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_PACK_UPV_KEY,
                    name: Common.BCU_DTC_WARNING2_PACK_UPV_NAME,
                    value: getBcuDtcWarning2PackUpvValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_PACK_UPV_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_PACK_UPV_KEY,
                    name: Common.BCU_DTC_FAULT_PACK_UPV_NAME,
                    value: getBcuDtcFaultPackUpvValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_PACK_OPV_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_PACK_OPV_KEY,
                    name: Common.BCU_DTC_WARNING_PACK_OPV_NAME,
                    value: getBcuDtcWarningPackOpvValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_PACK_OPV_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_PACK_OPV_KEY,
                    name: Common.BCU_DTC_WARNING2_PACK_OPV_NAME,
                    value: getBcuDtcWarning2PackOpvValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_PACK_OPV_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_PACK_OPV_KEY,
                    name: Common.BCU_DTC_FAULT_PACK_OPV_NAME,
                    value: getBcuDtcFaultPackOpvValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningPrecharrelayAdhesionFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningPrecharrelayFailureFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_KEY,
                    name: Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_NAME,
                    value: getBcuDtcWarningPackVoltageDiffValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_KEY,
                    name: Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_NAME,
                    value: getBcuDtcWarning2PackVoltageDiffValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_KEY,
                    name: Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_NAME,
                    value: getBcuDtcFaultPackVoltageDiffValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_KEY,
                    name: Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_NAME,
                    value: getBcuDtcWarningPackTempDiffValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_KEY,
                    name: Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_NAME,
                    value: getBcuDtcWarning2PackTempDiffValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_KEY,
                    name: Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_NAME,
                    value: getBcuDtcWarningCurrentCollectFaultValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningMainrelayPlusAdhesionFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningMainrelayMinusAdhesionFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_UNDER_SOC_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_UNDER_SOC_KEY,
                    name: Common.BCU_DTC_WARNING_UNDER_SOC_NAME,
                    value: getBcuDtcWarningUnderSocValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_UNDER_SOC_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_UNDER_SOC_KEY,
                    name: Common.BCU_DTC_WARNING2_UNDER_SOC_NAME,
                    value: getBcuDtcWarning2UnderSocValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_UNDER_SOC_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_UNDER_SOC_KEY,
                    name: Common.BCU_DTC_FAULT_UNDER_SOC_NAME,
                    value: getBcuDtcFaultUnderSocValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_OVER_SOC_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_OVER_SOC_KEY,
                    name: Common.BCU_DTC_WARNING_OVER_SOC_NAME,
                    value: getBcuDtcWarningOverSocValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_OVER_SOC_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_OVER_SOC_KEY,
                    name: Common.BCU_DTC_WARNING2_OVER_SOC_NAME,
                    value: getBcuDtcWarning2OverSocValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_OVER_SOC_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_OVER_SOC_KEY,
                    name: Common.BCU_DTC_FAULT_OVER_SOC_NAME,
                    value: getBcuDtcFaultOverSocValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningBmsInternalCommFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningBmsVehicleCommFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_DISCHARGE_OCP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_DISCHARGE_OCP_KEY,
                    name: Common.BCU_DTC_WARNING_DISCHARGE_OCP_NAME,
                    value: getBcuDtcWarningDischargeOcpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_DISCHARGE_OCP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_DISCHARGE_OCP_KEY,
                    name: Common.BCU_DTC_WARNING2_DISCHARGE_OCP_NAME,
                    value: getBcuDtcWarning2DischargeOcpValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_DISCHARGE_OCP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_DISCHARGE_OCP_KEY,
                    name: Common.BCU_DTC_FAULT_DISCHARGE_OCP_NAME,
                    value: getBcuDtcFaultDischargeOcpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_CHARGE_OCP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CHARGE_OCP_KEY,
                    name: Common.BCU_DTC_WARNING_CHARGE_OCP_NAME,
                    value: getBcuDtcWarningChargeOcpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING2_CHARGE_OCP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING2_CHARGE_OCP_KEY,
                    name: Common.BCU_DTC_WARNING2_CHARGE_OCP_NAME,
                    value: getBcuDtcWarning2ChargeOcpValue(),
                };
                break;

            case Common.BCU_DTC_FAULT_CHARGE_OCP_KEY:
                tabelData = {
                    key: Common.BCU_DTC_FAULT_CHARGE_OCP_KEY,
                    name: Common.BCU_DTC_FAULT_CHARGE_OCP_NAME,
                    value: getBcuDtcFaultChargeOcpValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_KEY,
                    name: Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_NAME,
                    value: getBcuDtcWarningInsulationFailLevel2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningInsulationFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningMotorRelayAdhesionFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY,
                    name: Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME,
                    value: getBcuDtcWarningDischargeFreezingCircuitBreakingFailLevel2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY,
                    name: Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME,
                    value: getBcuDtcWarningChargeFreezingCircuitBreakingFailLevel2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningFastchargingRelayAdhesionFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningSlowchargingRelayAdhesionFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_KEY,
                    name: Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_NAME,
                    value: getBcuDtcWarningHeatingRelayAdhesionFailLevel3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_KEY,
                    name: Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_NAME,
                    value: getBcuDtcWarningSocJumpAbnormalValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_KEY,
                    name: Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_NAME,
                    value: getBcuDtcWarningBatterySystemMismatchValue(),
                };
                break;

            case Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_KEY,
                    name: Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_NAME,
                    value: getBcuDtcWarningFastchargingAnodeHightempClass2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_KEY,
                    name: Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_NAME,
                    value: getBcuDtcWarningFastchargingAnodeHightempClass3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY,
                    name: Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME,
                    value: getBcuDtcWarningFastchargingCathodeHightempClass2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY,
                    name: Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME,
                    value: getBcuDtcWarningFastchargingCathodeHightempClass3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_KEY,
                    name: Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_NAME,
                    value: getBcuDtcWarningSlowchargingAnodeHightempClass2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_KEY,
                    name: Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_NAME,
                    value: getBcuDtcWarningSlowchargingAnodeHightempClass3Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY,
                    name: Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME,
                    value: getBcuDtcWarningSlowchargingCathodeHightempClass2Value(),
                };
                break;

            case Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY:
                tabelData = {
                    key: Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY,
                    name: Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME,
                    value: getBcuDtcWarningSlowchargingCathodeHightempClass3Value(),
                };
                break;

            default:
                break;
        }

        return tabelData;
    };

    useEffect(() => {
        const newTableDataBcuDtc: CanTableType[] = [];
        existFaultDataBcuDtc.forEach((key) => {
            const data = getTableDataBcuDtc(key);
            if (data !== undefined) {
                newTableDataBcuDtc.push(data);
            }
        });

        setTableDataBcuDtc(newTableDataBcuDtc);
    }, [currentCanData, existFaultDataBcuDtc]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region VCU Moter Control
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vcuMotorControlPanelOpen, setVcuMotorControlPanelOpen] = useState(true);
    const [vcuMotorControlChartMounted, setVcuMotorControlChartMounted] = useState(false);
    const [selectedVcuMotorControl, setSelectedVcuMotorControl] = useState<Array<React.Key>>([
        Common.VCU_MOTOR_CONTROL_MODE_KEY,
        Common.VCU_MOTOR_CONTROL_VCU_COUNTER_KEY,
        Common.VCU_MOTOR_CONTROL_TORQUE_KEY,
        Common.VCU_MOTOR_CONTROL_SPEED_KEY,
    ]);

    const [seriesChartDatasVcuMotorControl, setSeriesChartDatasVcuMotorControl] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartVcuMotorControlMode, setChartVcuMotorControlMode] = useState<TypeUtils.ChartData>({
        name: Common.VCU_MOTOR_CONTROL_MODE_NAME,
        data: [],
    });
    const [chartVcuMotorControlVcuCounter, setChartVcuMotorControlVcuCounter] = useState<TypeUtils.ChartData>({
        name: Common.VCU_MOTOR_CONTROL_VCU_COUNTER_NAME,
        data: [],
    });
    const [chartVcuMotorControlTorque, setChartVcuMotorControlTorque] = useState<TypeUtils.ChartData>({
        name: Common.VCU_MOTOR_CONTROL_TORQUE_NAME,
        data: [],
    });
    const [chartVcuMotorControlSpeed, setChartVcuMotorControlSpeed] = useState<TypeUtils.ChartData>({
        name: Common.VCU_MOTOR_CONTROL_SPEED_NAME,
        data: [],
    });

    const getTabelTooltipVcuMotorControl = (key: React.Key) => {
        switch (key) {
            case Common.VCU_MOTOR_CONTROL_MODE_KEY:
                return (
                    <>
                        <p>
                            VCU_MOTOR_CONTROL_MODE_KEY
                            <br />
                            CAN ID: 0x0CFF08EF
                            <br />
                            Name: Motor Control Mode
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x08=not ready, 0x09=ready
                        </p>
                    </>
                );

            case Common.VCU_MOTOR_CONTROL_VCU_COUNTER_KEY:
                return (
                    <>
                        <p>
                            VCU_MOTOR_CONTROL_VCU_COUNTER_KEY
                            <br />
                            CAN ID: 0x0CFF08EF
                            <br />
                            Name: Vcu Counter
                        </p>
                    </>
                );

            case Common.VCU_MOTOR_CONTROL_TORQUE_KEY:
                return (
                    <>
                        <p>
                            VCU_MOTOR_CONTROL_TORQUE_KEY
                            <br />
                            CAN ID: 0x0CFF08EF
                            <br />
                            Name: Motor Control Torque
                        </p>
                        <p>Remarks: N.m</p>
                    </>
                );

            case Common.VCU_MOTOR_CONTROL_SPEED_KEY:
                return (
                    <>
                        <p>
                            VCU_MOTOR_CONTROL_SPEED_KEY
                            <br />
                            CAN ID: 0x0CFF08EF
                            <br />
                            Name: Motor Control Speed
                        </p>
                        <p>Remarks: rpm</p>
                    </>
                );
        }

        return null;
    };

    const convertVcuMotorControlModeRemark = (value: number) => {
        let valueString = "N/A";
        switch (value) {
            case 0x08:
                valueString = "not ready";
                break;
            case 0x09:
                valueString = "ready";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getVcuMotorControlModeValue = () => {
        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_MODE_KEY) && currentCanData !== undefined) {
            return convertVcuMotorControlModeRemark(currentCanData.vcuMotorControl?.motorControlMode);
        }
        return "";
    };

    const getVcuMotorControlVcuConterValue = () => {
        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_VCU_COUNTER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.vcuMotorControl?.vcuCounter);
        }
        return "";
    };

    const getVcuMotorControlTorqueValue = () => {
        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_TORQUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuMotorControl?.motorControlTorque, Common.UNIT_TORQUE);
        }
        return "";
    };

    const getVcuMotorControlSpeedValue = () => {
        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vcuMotorControl?.motorControlSpeed, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const onChangeVcuMotorControl = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVcuMotorControl(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcuMotorControl: Array<TypeUtils.ChartData> = [];

        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_MODE_KEY)) chartDatasVcuMotorControl.push(chartVcuMotorControlMode);
        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_VCU_COUNTER_KEY))
            chartDatasVcuMotorControl.push(chartVcuMotorControlVcuCounter);
        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_TORQUE_KEY))
            chartDatasVcuMotorControl.push(chartVcuMotorControlTorque);
        if (selectedVcuMotorControl.includes(Common.VCU_MOTOR_CONTROL_SPEED_KEY)) chartDatasVcuMotorControl.push(chartVcuMotorControlSpeed);

        setSeriesChartDatasVcuMotorControl(chartDatasVcuMotorControl);
    }, [
        selectedVcuMotorControl,
        chartVcuMotorControlMode,
        chartVcuMotorControlVcuCounter,
        chartVcuMotorControlTorque,
        chartVcuMotorControlSpeed,
    ]);

    const tableDataVcuMotorControl: CanTableType[] = [
        {
            key: Common.VCU_MOTOR_CONTROL_MODE_KEY,
            name: Common.VCU_MOTOR_CONTROL_MODE_NAME,
            value: getVcuMotorControlModeValue(),
        },
        {
            key: Common.VCU_MOTOR_CONTROL_VCU_COUNTER_KEY,
            name: Common.VCU_MOTOR_CONTROL_VCU_COUNTER_NAME,
            value: getVcuMotorControlVcuConterValue(),
        },
        {
            key: Common.VCU_MOTOR_CONTROL_TORQUE_KEY,
            name: Common.VCU_MOTOR_CONTROL_TORQUE_NAME,
            value: getVcuMotorControlTorqueValue(),
        },
        {
            key: Common.VCU_MOTOR_CONTROL_SPEED_KEY,
            name: Common.VCU_MOTOR_CONTROL_SPEED_NAME,
            value: getVcuMotorControlSpeedValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region MCU Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [mcuStatusPanelOpen, setMcuStatusPanelOpen] = useState(true);
    const [mcuStatusChartMounted, setMcuStatusChartMounted] = useState(false);
    const [selectedMcuStatus, setSelectedMcuStatus] = useState<Array<React.Key>>([
        Common.MCU_STATUS_FEEDBACK_SPEED_KEY,
        Common.MCU_STATUS_FEEDBACK_TORQUE_KEY,
        Common.MCU_STATUS_MCU_STATE_FLAG_KEY,
        Common.MCU_STATUS_MCU_ALIVE_COUNT_KEY,
    ]);

    const [seriesChartDatasMcuStatus, setSeriesChartDatasMcuStatus] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartMcuStatusFeedbackSpeed, setChartMcuStatusFeedbackSpeed] = useState<TypeUtils.ChartData>({
        name: Common.MCU_STATUS_FEEDBACK_SPEED_NAME,
        data: [],
    });
    const [chartMcuStatusFeedbackTorque, setChartMcuStatusFeedbackTorque] = useState<TypeUtils.ChartData>({
        name: Common.MCU_STATUS_FEEDBACK_TORQUE_NAME,
        data: [],
    });
    const [chartMcuStatusMcuStateFlag, setChartMcuStatusMcuStateFlag] = useState<TypeUtils.ChartData>({
        name: Common.MCU_STATUS_MCU_STATE_FLAG_NAME,
        data: [],
    });
    const [chartMcuStatusMcuAliveCount, setChartMcuStatusMcuAliveCount] = useState<TypeUtils.ChartData>({
        name: Common.MCU_STATUS_MCU_ALIVE_COUNT_NAME,
        data: [],
    });

    const getTabelTooltipMcuStatus = (key: React.Key) => {
        switch (key) {
            case Common.MCU_STATUS_FEEDBACK_SPEED_KEY:
                return (
                    <>
                        <p>
                            MCU_STATUS_FEEDBACK_SPEED_KEY
                            <br />
                            CAN ID: 0x0CFF0008
                            <br />
                            Name: Feedback Speed
                        </p>
                        <p>Remarks: rpm</p>
                    </>
                );

            case Common.MCU_STATUS_FEEDBACK_TORQUE_KEY:
                return (
                    <>
                        <p>
                            MCU_STATUS_FEEDBACK_TORQUE_KEY
                            <br />
                            CAN ID: 0x0CFF0008
                            <br />
                            Name: Feedback Torque
                        </p>
                        <p>Remarks: N.m</p>
                    </>
                );

            case Common.MCU_STATUS_MCU_STATE_FLAG_KEY:
                return (
                    <>
                        <p>
                            MCU_STATUS_MCU_STATE_FLAG_KEY
                            <br />
                            CAN ID: 0x0CFF0008
                            <br />
                            Name: MCU State Flag
                        </p>
                    </>
                );

            case Common.MCU_STATUS_MCU_ALIVE_COUNT_KEY:
                return (
                    <>
                        <p>
                            MCU_STATUS_MCU_ALIVE_COUNT_KEY
                            <br />
                            CAN ID: 0x0CFF0008
                            <br />
                            Name: MCU Alive Count
                        </p>
                    </>
                );
        }

        return null;
    };

    const getMcuStatusFeedbackSpeedValue = () => {
        if (selectedMcuStatus.includes(Common.MCU_STATUS_FEEDBACK_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mcuStatus?.feedbackSpeed, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getMcuStatusFeedbackTorqueValue = () => {
        if (selectedMcuStatus.includes(Common.MCU_STATUS_FEEDBACK_TORQUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.mcuStatus?.feedbackTorque, Common.UNIT_TORQUE);
        }
        return "";
    };

    const getMcuStatusMcuStateFlagValue = () => {
        if (selectedMcuStatus.includes(Common.MCU_STATUS_MCU_STATE_FLAG_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mcuStatus?.mcuStateFlag);
        }
        return "";
    };

    const getMcuStatusMcuAliveCountValue = () => {
        if (selectedMcuStatus.includes(Common.MCU_STATUS_MCU_ALIVE_COUNT_KEY) && currentCanData !== undefined) {
            return String(currentCanData.mcuStatus?.mcuAliveCount);
        }
        return "";
    };

    const onChangeMcuStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedMcuStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasMcuStatus: Array<TypeUtils.ChartData> = [];

        if (selectedMcuStatus.includes(Common.MCU_STATUS_FEEDBACK_SPEED_KEY)) chartDatasMcuStatus.push(chartMcuStatusFeedbackSpeed);
        if (selectedMcuStatus.includes(Common.MCU_STATUS_FEEDBACK_TORQUE_KEY)) chartDatasMcuStatus.push(chartMcuStatusFeedbackTorque);
        if (selectedMcuStatus.includes(Common.MCU_STATUS_MCU_STATE_FLAG_KEY)) chartDatasMcuStatus.push(chartMcuStatusMcuStateFlag);
        if (selectedMcuStatus.includes(Common.MCU_STATUS_MCU_ALIVE_COUNT_KEY)) chartDatasMcuStatus.push(chartMcuStatusMcuAliveCount);

        setSeriesChartDatasMcuStatus(chartDatasMcuStatus);
    }, [
        selectedMcuStatus,
        chartMcuStatusFeedbackSpeed,
        chartMcuStatusFeedbackTorque,
        chartMcuStatusMcuStateFlag,
        chartMcuStatusMcuAliveCount,
    ]);

    const tableDataMcuStatus: CanTableType[] = [
        {
            key: Common.MCU_STATUS_FEEDBACK_SPEED_KEY,
            name: Common.MCU_STATUS_FEEDBACK_SPEED_NAME,
            value: getMcuStatusFeedbackSpeedValue(),
        },
        {
            key: Common.MCU_STATUS_FEEDBACK_TORQUE_KEY,
            name: Common.MCU_STATUS_FEEDBACK_TORQUE_NAME,
            value: getMcuStatusFeedbackTorqueValue(),
        },
        {
            key: Common.MCU_STATUS_MCU_STATE_FLAG_KEY,
            name: Common.MCU_STATUS_MCU_STATE_FLAG_NAME,
            value: getMcuStatusMcuStateFlagValue(),
        },
        {
            key: Common.MCU_STATUS_MCU_ALIVE_COUNT_KEY,
            name: Common.MCU_STATUS_MCU_ALIVE_COUNT_NAME,
            value: getMcuStatusMcuAliveCountValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    useEffect(() => {
        if (vehicleInfo?.makerModel.maker?.makerName === Common.VehicleMaker.Bukgi) {
            //#region Datas
            const vcuVehicleFunctionDatas: TypeUtils.ChartElement[] = [];
            const vcuShiftLocationDatas: TypeUtils.ChartElement[] = [];
            const vcuKeyStatusDatas: TypeUtils.ChartElement[] = [];
            const vcuVehicleSpeedDatas: TypeUtils.ChartElement[] = [];
            const vcuPedalStateDatas: TypeUtils.ChartElement[] = [];
            const vcuBrakeStateDatas: TypeUtils.ChartElement[] = [];
            const vcuHandbrakeStateDatas: TypeUtils.ChartElement[] = [];

            const vcu2InsCSocInsDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCCurrentInsDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCVoltInsDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCInsulationResistanceDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCCellMaxTempDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCLedChargingDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCLedChargingConnectionDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCLedInsulationWarningDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCLedBattLowVoltWarningDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCLedLowSocWarningDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCLedBattFailureDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCLedSoundSignalDatas: TypeUtils.ChartElement[] = [];
            const vcu2InsCReadySignalDatas: TypeUtils.ChartElement[] = [];

            const vcuCommand1ContactorRequestDatas: TypeUtils.ChartElement[] = [];
            const vcuCommand1PowerDownRequestDatas: TypeUtils.ChartElement[] = [];
            const vcuCommand1FaultLockoutRequestDatas: TypeUtils.ChartElement[] = [];
            const vcuCommand1AcPreChargeVoltageRequestDatas: TypeUtils.ChartElement[] = [];
            const vcuCommand1McuInputVoltageDatas: TypeUtils.ChartElement[] = [];
            const vcuCommand1MototAcCurrentDatas: TypeUtils.ChartElement[] = [];
            const vcuCommand2AcPreChargeVoltageStatusDatas: TypeUtils.ChartElement[] = [];

            const batteryBatteryStatusDatas: TypeUtils.ChartElement[] = [];
            const batteryBatteryModeDatas: TypeUtils.ChartElement[] = [];
            const batteryFaultStatusDatas: TypeUtils.ChartElement[] = [];
            const batteryBalanceLockoutStatusDatas: TypeUtils.ChartElement[] = [];
            const batteryFaultLockoutStatusDatas: TypeUtils.ChartElement[] = [];
            const batterySocDatas: TypeUtils.ChartElement[] = [];
            const batterySohDatas: TypeUtils.ChartElement[] = [];
            const batteryPackInternalResistanceDatas: TypeUtils.ChartElement[] = [];
            const batteryPackResidualCapacityDatas: TypeUtils.ChartElement[] = [];
            const batteryMessageCounterDatas: TypeUtils.ChartElement[] = [];

            const batteryPack1PackVbattDatas: TypeUtils.ChartElement[] = [];
            const batteryPack1PackVoutDatas: TypeUtils.ChartElement[] = [];
            const batteryPack1PackCurrentDatas: TypeUtils.ChartElement[] = [];
            const batteryPack1PackPowerDatas: TypeUtils.ChartElement[] = [];
            const batteryPack1PackTempDatas: TypeUtils.ChartElement[] = [];

            const batteryPack2SlaveControl01Datas: TypeUtils.ChartElement[] = [];
            const batteryPack2SlaveControl09Datas: TypeUtils.ChartElement[] = [];
            const batteryPack2SlaveControl17Datas: TypeUtils.ChartElement[] = [];
            const batteryPack2SlaveControl25Datas: TypeUtils.ChartElement[] = [];
            const batteryPack2BatteryBoxNumberDatas: TypeUtils.ChartElement[] = [];
            const batteryPack2SlaveControlNumberDatas: TypeUtils.ChartElement[] = [];

            const cellVoltageCellVoltageSumDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCellMaxVDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCellMinVDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCellMaxVNoDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCellMinVNoDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCellMaxVSystemNoDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCellMinVSystemNoDatas: TypeUtils.ChartElement[] = [];

            const cellTempCellAvgTempDatas: TypeUtils.ChartElement[] = [];
            const cellTempCellMaxTempDatas: TypeUtils.ChartElement[] = [];
            const cellTempCellMinTempDatas: TypeUtils.ChartElement[] = [];
            const cellTempCellMaxTempNoDatas: TypeUtils.ChartElement[] = [];
            const cellTempCellMinTempNoDatas: TypeUtils.ChartElement[] = [];
            const cellTempCellMaxTempSystemNoDatas: TypeUtils.ChartElement[] = [];
            const cellTempCellMinTempSystemNoDatas: TypeUtils.ChartElement[] = [];

            const bmsLimit1MaxPackVLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit1MinPackVLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit1MaxCellVLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit1MinCellVLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit1DischargeCurrentLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit1MaxChargeCurrentLimitDatas: TypeUtils.ChartElement[] = [];

            const bmsLimit2MaxSocLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit2MinSocLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit2Max10secDischargePowerLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit2Max10secChargePowerLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit2MaxCellTempLimitDatas: TypeUtils.ChartElement[] = [];
            const bmsLimit2MinCellTempLimitDatas: TypeUtils.ChartElement[] = [];

            const isolationRIsolationLifeSignalDatas: TypeUtils.ChartElement[] = [];
            const isolationRIsolationResistanceStatusDatas: TypeUtils.ChartElement[] = [];
            const isolationRFaultLevelDatas: TypeUtils.ChartElement[] = [];
            const isolationRRgndPosDatas: TypeUtils.ChartElement[] = [];
            const isolationRRgndNegDatas: TypeUtils.ChartElement[] = [];

            const batteryInformation1BatterySubsystemNoDatas: TypeUtils.ChartElement[] = [];
            const batteryInformation1TotalCellNoDatas: TypeUtils.ChartElement[] = [];
            const batteryInformation1TempSensorNoDatas: TypeUtils.ChartElement[] = [];
            const batteryInformation1BatterySystemErrorNoDatas: TypeUtils.ChartElement[] = [];
            const batteryInformation1BatteryChargingStatusDatas: TypeUtils.ChartElement[] = [];
            const batteryInformation1BatteryChargingGunConnectDatas: TypeUtils.ChartElement[] = [];

            const batteryInformation4BatteryPackAccumulatedOutputDatas: TypeUtils.ChartElement[] = [];
            const batteryInformation4BatteryPackAccumulatedChargingDatas: TypeUtils.ChartElement[] = [];
            const batteryInformation4BatteryPackAccumulatedRegenDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region BCU DTC
            const warningCellUvpDatas: TypeUtils.ChartElement[] = [];
            const warning2CellUvpDatas: TypeUtils.ChartElement[] = [];
            const faultCellUvpDatas: TypeUtils.ChartElement[] = [];
            const warningCellOvpDatas: TypeUtils.ChartElement[] = [];
            const warning2CellOvpDatas: TypeUtils.ChartElement[] = [];
            const faultCellOvpDatas: TypeUtils.ChartElement[] = [];
            const warningTotalVoltageCollectFailLevel2Datas: TypeUtils.ChartElement[] = [];
            const warningCellVoltageCollectFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningCellUtpDatas: TypeUtils.ChartElement[] = [];
            const warning2CellUtpDatas: TypeUtils.ChartElement[] = [];
            const faultCellUtpDatas: TypeUtils.ChartElement[] = [];
            const warningCellOtpDatas: TypeUtils.ChartElement[] = [];
            const warning2CellOtpDatas: TypeUtils.ChartElement[] = [];
            const faultCellOtpDatas: TypeUtils.ChartElement[] = [];
            const warningTempChannelFailLevel2Datas: TypeUtils.ChartElement[] = [];
            const warningBalancingChannelFailLevel1Datas: TypeUtils.ChartElement[] = [];
            const warningPackUpvDatas: TypeUtils.ChartElement[] = [];
            const warning2PackUpvDatas: TypeUtils.ChartElement[] = [];
            const faultPackUpvDatas: TypeUtils.ChartElement[] = [];
            const warningPackOpvDatas: TypeUtils.ChartElement[] = [];
            const warning2PackOpvDatas: TypeUtils.ChartElement[] = [];
            const faultPackOpvDatas: TypeUtils.ChartElement[] = [];
            const warningPreCharRelayAdhesionFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningPreCharRelayFailureFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningPackVoltageDiffDatas: TypeUtils.ChartElement[] = [];
            const warning2PackVoltageDiffDatas: TypeUtils.ChartElement[] = [];
            const faultPackVoltageDiffDatas: TypeUtils.ChartElement[] = [];
            const warningPackTempDiffDatas: TypeUtils.ChartElement[] = [];
            const warning2PackTempDiffDatas: TypeUtils.ChartElement[] = [];
            const warningCurrentCollectFaultDatas: TypeUtils.ChartElement[] = [];
            const warningMainRelayPlusAdhesionFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningMainRelayMinusAdhesionFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningUnderSocDatas: TypeUtils.ChartElement[] = [];
            const warning2UnderSocDatas: TypeUtils.ChartElement[] = [];
            const faultUnderSocDatas: TypeUtils.ChartElement[] = [];
            const warningOverSocDatas: TypeUtils.ChartElement[] = [];
            const warning2OverSocDatas: TypeUtils.ChartElement[] = [];
            const faultOverSocDatas: TypeUtils.ChartElement[] = [];
            const warningBmsInternalCommFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningBmsVehicleCommFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningDischargeOcpDatas: TypeUtils.ChartElement[] = [];
            const warning2DischargeOcpDatas: TypeUtils.ChartElement[] = [];
            const faultDischargeOcpDatas: TypeUtils.ChartElement[] = [];
            const warningChargeOcpDatas: TypeUtils.ChartElement[] = [];
            const warning2ChargeOcpDatas: TypeUtils.ChartElement[] = [];
            const faultChargeOcpDatas: TypeUtils.ChartElement[] = [];
            const warningInsulationFailLevel2Datas: TypeUtils.ChartElement[] = [];
            const warningInsulationFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningMotorRelayAdhesionFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningDischargeFreezingCircuitBreakingFailLevel2Datas: TypeUtils.ChartElement[] = [];
            const warningChargeFreezeCircuitBreakingFailLevel2Datas: TypeUtils.ChartElement[] = [];
            const warningFastChargingRelayAdhesionFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningSlowChargingRelayAdhesionFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningHeatingRelayAdhesionFailLevel3Datas: TypeUtils.ChartElement[] = [];
            const warningSocJumpAbnormalDatas: TypeUtils.ChartElement[] = [];
            const warningBatterySystemMismatchDatas: TypeUtils.ChartElement[] = [];
            const warningFastChargingAnodeHighTempClass2Datas: TypeUtils.ChartElement[] = [];
            const warningFastChargingAnodeHighTempClass3Datas: TypeUtils.ChartElement[] = [];
            const warningFastChargingCathodeHighTempClass2Datas: TypeUtils.ChartElement[] = [];
            const warningFastChargingCathodeHighTempClass3Datas: TypeUtils.ChartElement[] = [];
            const warningSlowChargingAnodeHighTempClass2Datas: TypeUtils.ChartElement[] = [];
            const warningSlowChargingAnodeHighTempClass3Datas: TypeUtils.ChartElement[] = [];
            const warningSlowChargingCathodeHighTempClass2Datas: TypeUtils.ChartElement[] = [];
            const warningSlowChargingCathodeHighTempClass3Datas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region VCU Motor Control
            const vcuMotorControlModeDatas: TypeUtils.ChartElement[] = [];
            const vcuMotorControlVcuCounterDatas: TypeUtils.ChartElement[] = [];
            const vcuMotorControlTorqueDatas: TypeUtils.ChartElement[] = [];
            const vcuMotorControlSpeedDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            //#region MCU Status
            const mcuStatusFeedbackSpeedDatas: TypeUtils.ChartElement[] = [];
            const mcuStatusFeedbackTorqueDatas: TypeUtils.ChartElement[] = [];
            const mcuStatusMcuStateFlagDatas: TypeUtils.ChartElement[] = [];
            const mcuStatusMcuAliveCountDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataBukgi.CanData;

                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region VCU
                vcuVehicleFunctionDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuS1?.vehicleFunction || 0,
                });

                vcuShiftLocationDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuS1?.shiftLocation || 0,
                });

                vcuKeyStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuS1?.keyStatus || 0,
                });

                vcuVehicleSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuS1?.vehicleSpeed || 0,
                });

                vcuPedalStateDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuS1?.pedalState || 0,
                });

                vcuBrakeStateDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuS1?.brakeState || 0,
                });

                vcuHandbrakeStateDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuF1?.handbrakeStatus || 0,
                });
                //#endregion

                //#region VCU To INS C
                vcu2InsCSocInsDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.batSocIns || 0,
                });

                vcu2InsCCurrentInsDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.batCurrIns || 0,
                });

                vcu2InsCVoltInsDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.batVoltIns || 0,
                });

                vcu2InsCInsulationResistanceDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.insulResistance || 0,
                });

                vcu2InsCCellMaxTempDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.cellMaxTemp || 0,
                });

                vcu2InsCLedChargingDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.ledChargeCmd || 0,
                });

                vcu2InsCLedChargingConnectionDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.ledChargeConnectCmd || 0,
                });

                vcu2InsCLedInsulationWarningDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.ledInsulWarn || 0,
                });

                vcu2InsCLedBattLowVoltWarningDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.ledBatLowVoltWarn || 0,
                });

                vcu2InsCLedLowSocWarningDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.ledLowSocWarn || 0,
                });

                vcu2InsCLedBattFailureDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.ledBatFail || 0,
                });

                vcu2InsCLedSoundSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC1?.ledSound || 0,
                });

                vcu2InsCReadySignalDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuToInsC2?.readySignal || 0,
                });
                //#endregion

                //#region VCU Command
                vcuCommand1ContactorRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCmd1?.contactorReq || 0,
                });

                vcuCommand1PowerDownRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCmd1?.powerDownReq || 0,
                });

                vcuCommand1FaultLockoutRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCmd1?.faultLockoutReq || 0,
                });

                vcuCommand1AcPreChargeVoltageRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCmd1?.acPrechargeVoltReq || 0,
                });

                vcuCommand1McuInputVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCmd1?.mcuInputVolt || 0,
                });

                vcuCommand1MototAcCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCmd1?.motorAcCurr || 0,
                });

                vcuCommand2AcPreChargeVoltageStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuCmd2?.acPrechargeVoltStatus || 0,
                });
                //#endregion

                //#region Battery
                batteryBatteryStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.status || 0,
                });

                batteryBatteryModeDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.mode || 0,
                });

                batteryFaultStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.faultStatus || 0,
                });

                batteryBalanceLockoutStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.balanceLockoutStatus || 0,
                });

                batteryFaultLockoutStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.faultLockoutStatus || 0,
                });

                batterySocDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.soc || 0,
                });

                batterySohDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.soh || 0,
                });

                batteryPackInternalResistanceDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.packInterResistance || 0,
                });

                batteryPackResidualCapacityDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.packResidualCapa || 0,
                });

                batteryMessageCounterDatas.push({
                    x: time.valueOf(),
                    y: canData.bat?.msgCounter || 0,
                });
                //#endregion

                //#region Battery Pack 1
                batteryPack1PackVbattDatas.push({
                    x: time.valueOf(),
                    y: canData.batPack1?.packVbat || 0,
                });

                batteryPack1PackVoutDatas.push({
                    x: time.valueOf(),
                    y: canData.batPack1?.packVout || 0,
                });

                batteryPack1PackCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.batPack1?.packI || 0,
                });

                batteryPack1PackPowerDatas.push({
                    x: time.valueOf(),
                    y: canData.batPack1?.packPower || 0,
                });

                batteryPack1PackTempDatas.push({
                    x: time.valueOf(),
                    y: canData.batPack1?.packTemp || 0,
                });
                // #endregion

                //#region Battery Pack 2
                batteryPack2SlaveControl01Datas.push({
                    x: time.valueOf(),
                    y: canData.batPack2?.slaveControlOnline1_8 || 0,
                });

                batteryPack2SlaveControl09Datas.push({
                    x: time.valueOf(),
                    y: canData.batPack2?.slaveControlOnline9_16 || 0,
                });

                batteryPack2SlaveControl17Datas.push({
                    x: time.valueOf(),
                    y: canData.batPack2?.slaveControlOnline17_24 || 0,
                });

                batteryPack2SlaveControl25Datas.push({
                    x: time.valueOf(),
                    y: canData.batPack2?.slaveControlOnline25_32 || 0,
                });

                batteryPack2BatteryBoxNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.batPack2?.batBoxNo || 0,
                });

                batteryPack2SlaveControlNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.batPack2?.slaveControlNo || 0,
                });
                //#endregion

                //#region Cell Voltage
                cellVoltageCellVoltageSumDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.sum || 0,
                });

                cellVoltageCellMaxVDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.maxV || 0,
                });

                cellVoltageCellMinVDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.minV || 0,
                });

                cellVoltageCellMaxVNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.maxVno || 0,
                });

                cellVoltageCellMinVNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.minVno || 0,
                });

                cellVoltageCellMaxVSystemNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.maxVSysNo || 0,
                });

                cellVoltageCellMinVSystemNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.minVSysNo || 0,
                });
                //#endregion

                //#region Cell Temp
                cellTempCellAvgTempDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.avgTemp || 0,
                });

                cellTempCellMaxTempDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.maxTemp || 0,
                });

                cellTempCellMinTempDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.minTemp || 0,
                });

                cellTempCellMaxTempNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.maxTempNo || 0,
                });

                cellTempCellMinTempNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.minTempNo || 0,
                });

                cellTempCellMaxTempSystemNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.maxTempSysNo || 0,
                });

                cellTempCellMinTempSystemNoDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.minTempSysNo || 0,
                });
                //#endregion

                //#region BMS Limit 1
                bmsLimit1MaxPackVLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit1?.maxPackV || 0,
                });

                bmsLimit1MinPackVLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit1?.minPackV || 0,
                });

                bmsLimit1MaxCellVLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit1?.maxCellV || 0,
                });

                bmsLimit1MinCellVLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit1?.minCellV || 0,
                });

                bmsLimit1DischargeCurrentLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit1?.dischargeCurr || 0,
                });

                bmsLimit1MaxChargeCurrentLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit1?.maxChargeCurr || 0,
                });
                //#endregion

                //#region BMS Limit 2
                bmsLimit2MaxSocLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit2?.maxSoc || 0,
                });

                bmsLimit2MinSocLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit2?.minSoc || 0,
                });

                bmsLimit2Max10secDischargePowerLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit2?.max10SecDischargePower || 0,
                });

                bmsLimit2Max10secChargePowerLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit2?.max10SecChargePower || 0,
                });

                bmsLimit2MaxCellTempLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit2?.maxCellTemp || 0,
                });

                bmsLimit2MinCellTempLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.bmsLimit2?.minCellTemp || 0,
                });
                //#endregion

                //#region Isolation R
                isolationRIsolationLifeSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationR?.lifeSignal || 0,
                });

                isolationRIsolationResistanceStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationR?.resistanceStatus || 0,
                });

                isolationRFaultLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationR?.faultLevel || 0,
                });

                isolationRRgndPosDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationR?.rgndPos || 0,
                });

                isolationRRgndNegDatas.push({
                    x: time.valueOf(),
                    y: canData.isolationR?.rgndNeg || 0,
                });
                //#endregion

                //#region Battery Information 1
                batteryInformation1BatterySubsystemNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.subsysNo || 0,
                });

                batteryInformation1TotalCellNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.totalCellNo || 0,
                });

                batteryInformation1TempSensorNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.tempSensorNo || 0,
                });

                batteryInformation1BatterySystemErrorNoDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.sysErrNo || 0,
                });

                batteryInformation1BatteryChargingStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.chargingStatus || 0,
                });

                batteryInformation1BatteryChargingGunConnectDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo1?.chargingGunConnect || 0,
                });
                //#endregion

                //#region Battery Information 4
                batteryInformation4BatteryPackAccumulatedOutputDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo4?.packOutputEnergy || 0,
                });

                batteryInformation4BatteryPackAccumulatedChargingDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo4?.packChargeEnergy || 0,
                });

                batteryInformation4BatteryPackAccumulatedRegenDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo4?.packRegenEnergy || 0,
                });
                //#endregion

                //#region BCU DTC
                warningCellUvpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnCellUvp ? canData.bcuDtc?.warnCellUvp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_CELL_UVP_KEY, canData.bcuDtc?.warnCellUvp);

                warning2CellUvpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2CellUvp ? canData.bcuDtc?.warn2CellUvp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_CELL_UVP_KEY, canData.bcuDtc?.warn2CellUvp);

                faultCellUvpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultCellUvp ? canData.bcuDtc?.faultCellUvp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_CELL_UVP_KEY, canData.bcuDtc?.faultCellUvp);

                warningCellOvpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnCellOvp ? canData.bcuDtc?.warnCellOvp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_CELL_OVP_KEY, canData.bcuDtc?.warnCellOvp);

                warning2CellOvpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2CellOvp ? canData.bcuDtc?.warn2CellOvp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_CELL_OVP_KEY, canData.bcuDtc?.warn2CellOvp);

                faultCellOvpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultCellOvp ? canData.bcuDtc?.faultCellOvp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_CELL_OVP_KEY, canData.bcuDtc?.faultCellOvp);

                warningTotalVoltageCollectFailLevel2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2TotalVoltCollect ? canData.bcuDtc?.warn2TotalVoltCollect : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_KEY,
                    canData.bcuDtc?.warn2TotalVoltCollect
                );

                warningCellVoltageCollectFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3CellVoltCollect ? canData.bcuDtc?.warn3CellVoltCollect : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3CellVoltCollect
                );

                warningCellUtpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnCellUtp ? canData.bcuDtc?.warnCellUtp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_CELL_UTP_KEY, canData.bcuDtc?.warnCellUtp);

                warning2CellUtpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2CellUtp ? canData.bcuDtc?.warn2CellUtp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_CELL_UTP_KEY, canData.bcuDtc?.warn2CellUtp);

                faultCellUtpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultCellUtp ? canData.bcuDtc?.faultCellUtp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_CELL_UTP_KEY, canData.bcuDtc?.faultCellUtp);

                warningCellOtpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnCellOtp ? canData.bcuDtc?.warnCellOtp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_CELL_OTP_KEY, canData.bcuDtc?.warnCellOtp);

                warning2CellOtpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2CellOtp ? canData.bcuDtc?.warn2CellOtp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_CELL_OTP_KEY, canData.bcuDtc?.warn2CellOtp);

                faultCellOtpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultCellOtp ? canData.bcuDtc?.faultCellOtp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_CELL_OTP_KEY, canData.bcuDtc?.faultCellOtp);

                warningTempChannelFailLevel2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2TempChannel ? canData.bcuDtc?.warn2TempChannel : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_KEY, canData.bcuDtc?.warn2TempChannel);

                warningBalancingChannelFailLevel1Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn1BalancingChannel ? canData.bcuDtc?.warn1BalancingChannel : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_KEY, canData.bcuDtc?.warn1BalancingChannel);

                warningPackUpvDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnPackUpv ? canData.bcuDtc?.warnPackUpv : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_PACK_UPV_KEY, canData.bcuDtc?.warnPackUpv);

                warning2PackUpvDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2PackUpv ? canData.bcuDtc?.warn2PackUpv : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_PACK_UPV_KEY, canData.bcuDtc?.warn2PackUpv);

                faultPackUpvDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultPackUpv ? canData.bcuDtc?.faultPackUpv : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_PACK_UPV_KEY, canData.bcuDtc?.faultPackUpv);

                warningPackOpvDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnPackOpv ? canData.bcuDtc?.warnPackOpv : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_PACK_OPV_KEY, canData.bcuDtc?.warnPackOpv);

                warning2PackOpvDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2PackOpv ? canData.bcuDtc?.warn2PackOpv : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_PACK_OPV_KEY, canData.bcuDtc?.warn2PackOpv);

                faultPackOpvDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultPackOpv ? canData.bcuDtc?.faultPackOpv : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_PACK_OPV_KEY, canData.bcuDtc?.faultPackOpv);

                warningPreCharRelayAdhesionFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3PrechargeRelayAdhesion ? canData.bcuDtc?.warn3PrechargeRelayAdhesion : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3PrechargeRelayAdhesion
                );

                warningPreCharRelayFailureFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3PrechargeRelayFail ? canData.bcuDtc?.warn3PrechargeRelayFail : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3PrechargeRelayFail
                );

                warningPackVoltageDiffDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnPackVoltDiff ? canData.bcuDtc?.warnPackVoltDiff : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_KEY, canData.bcuDtc?.warnPackVoltDiff);

                warning2PackVoltageDiffDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2PackVoltDiff ? canData.bcuDtc?.warn2PackVoltDiff : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_KEY, canData.bcuDtc?.warn2PackVoltDiff);

                faultPackVoltageDiffDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultPackVoltDiff ? canData.bcuDtc?.faultPackVoltDiff : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_KEY, canData.bcuDtc?.faultPackVoltDiff);

                warningPackTempDiffDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnPackTempDiff ? canData.bcuDtc?.warnPackTempDiff : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_KEY, canData.bcuDtc?.warnPackTempDiff);

                warning2PackTempDiffDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2PackTempDiff ? canData.bcuDtc?.warn2PackTempDiff : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_KEY, canData.bcuDtc?.warn2PackTempDiff);

                warningCurrentCollectFaultDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnCurrCollect ? canData.bcuDtc?.warnCurrCollect : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_KEY, canData.bcuDtc?.warnCurrCollect);

                warningMainRelayPlusAdhesionFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3MainRelayPlusAdhesion ? canData.bcuDtc?.warn3MainRelayPlusAdhesion : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3MainRelayPlusAdhesion
                );

                warningMainRelayMinusAdhesionFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3MainRelayMinusAdhesion ? canData.bcuDtc?.warn3MainRelayMinusAdhesion : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3MainRelayMinusAdhesion
                );

                warningUnderSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnUnderSoc ? canData.bcuDtc?.warnUnderSoc : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_UNDER_SOC_KEY, canData.bcuDtc?.warnUnderSoc);

                warning2UnderSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2UnderSoc ? canData.bcuDtc?.warn2UnderSoc : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_UNDER_SOC_KEY, canData.bcuDtc?.warn2UnderSoc);

                faultUnderSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultUnderSoc ? canData.bcuDtc?.faultUnderSoc : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_UNDER_SOC_KEY, canData.bcuDtc?.faultUnderSoc);

                warningOverSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnOverSoc ? canData.bcuDtc?.warnOverSoc : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_OVER_SOC_KEY, canData.bcuDtc?.warnOverSoc);

                warning2OverSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2OverSoc ? canData.bcuDtc?.warn2OverSoc : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_OVER_SOC_KEY, canData.bcuDtc?.warn2OverSoc);

                faultOverSocDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultOverSoc ? canData.bcuDtc?.faultOverSoc : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_OVER_SOC_KEY, canData.bcuDtc?.faultOverSoc);

                warningBmsInternalCommFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3BmsInterNetwork ? canData.bcuDtc?.warn3BmsInterNetwork : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_KEY, canData.bcuDtc?.warn3BmsInterNetwork);

                warningBmsVehicleCommFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3BmsVehicleNetwork ? canData.bcuDtc?.warn3BmsVehicleNetwork : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_KEY, canData.bcuDtc?.warn3BmsVehicleNetwork);

                warningDischargeOcpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnDischargeOcp ? canData.bcuDtc?.warnDischargeOcp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_DISCHARGE_OCP_KEY, canData.bcuDtc?.warnDischargeOcp);

                warning2DischargeOcpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2DischargeOcp ? canData.bcuDtc?.warn2DischargeOcp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_DISCHARGE_OCP_KEY, canData.bcuDtc?.warn2DischargeOcp);

                faultDischargeOcpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultDischargeOcp ? canData.bcuDtc?.faultDischargeOcp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_DISCHARGE_OCP_KEY, canData.bcuDtc?.faultDischargeOcp);

                warningChargeOcpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnChargeOcp ? canData.bcuDtc?.warnChargeOcp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_CHARGE_OCP_KEY, canData.bcuDtc?.warnChargeOcp);

                warning2ChargeOcpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2ChargeOcp ? canData.bcuDtc?.warn2ChargeOcp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING2_CHARGE_OCP_KEY, canData.bcuDtc?.warn2ChargeOcp);

                faultChargeOcpDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.faultChargeOcp ? canData.bcuDtc?.faultChargeOcp : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_FAULT_CHARGE_OCP_KEY, canData.bcuDtc?.faultChargeOcp);

                warningInsulationFailLevel2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2Insul ? canData.bcuDtc?.warn2Insul : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_KEY, canData.bcuDtc?.warn2Insul);

                warningInsulationFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3Insul ? canData.bcuDtc?.warn3Insul : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_KEY, canData.bcuDtc?.warn3Insul);

                warningMotorRelayAdhesionFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3MotorRelayAdhesion ? canData.bcuDtc?.warn3MotorRelayAdhesion : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3MotorRelayAdhesion
                );

                warningDischargeFreezingCircuitBreakingFailLevel2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2DischargeFreezeCircuitBlock ? canData.bcuDtc?.warn2DischargeFreezeCircuitBlock : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY,
                    canData.bcuDtc?.warn2DischargeFreezeCircuitBlock
                );

                warningChargeFreezeCircuitBreakingFailLevel2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2ChargeFreezeCircuitBlock ? canData.bcuDtc?.warn2ChargeFreezeCircuitBlock : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_KEY,
                    canData.bcuDtc?.warn2ChargeFreezeCircuitBlock
                );

                warningFastChargingRelayAdhesionFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3QuickChargeRelayAdhesion ? canData.bcuDtc?.warn3QuickChargeRelayAdhesion : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3QuickChargeRelayAdhesion
                );

                warningSlowChargingRelayAdhesionFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3SlowChargeRelayAdhesion ? canData.bcuDtc?.warn3SlowChargeRelayAdhesion : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3SlowChargeRelayAdhesion
                );

                warningHeatingRelayAdhesionFailLevel3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3HeatRelayAdhesion ? canData.bcuDtc?.warn3HeatRelayAdhesion : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_KEY,
                    canData.bcuDtc?.warn3HeatRelayAdhesion
                );

                warningSocJumpAbnormalDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnSocJump ? canData.bcuDtc?.warnSocJump : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_KEY, canData.bcuDtc?.warnSocJump);

                warningBatterySystemMismatchDatas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warnBatSysMismatch ? canData.bcuDtc?.warnBatSysMismatch : 0,
                });
                checkExistFaultDataBcuDtc(Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_KEY, canData.bcuDtc?.warnBatSysMismatch);

                warningFastChargingAnodeHighTempClass2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2QuickChargeAnodeHighTemp ? canData.bcuDtc?.warn2QuickChargeAnodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_KEY,
                    canData.bcuDtc?.warn2QuickChargeAnodeHighTemp
                );

                warningFastChargingAnodeHighTempClass3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3QuickChargeAnodeHighTemp ? canData.bcuDtc?.warn3QuickChargeAnodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_KEY,
                    canData.bcuDtc?.warn3QuickChargeAnodeHighTemp
                );

                warningFastChargingCathodeHighTempClass2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2QuickChargeCathodeHighTemp ? canData.bcuDtc?.warn2QuickChargeCathodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY,
                    canData.bcuDtc?.warn2QuickChargeCathodeHighTemp
                );

                warningFastChargingCathodeHighTempClass3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3QuickChargeCathodeHighTemp ? canData.bcuDtc?.warn3QuickChargeCathodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY,
                    canData.bcuDtc?.warn3QuickChargeCathodeHighTemp
                );

                warningSlowChargingAnodeHighTempClass2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2SlowChargeAnodeHighTemp ? canData.bcuDtc?.warn2SlowChargeAnodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_KEY,
                    canData.bcuDtc?.warn2SlowChargeAnodeHighTemp
                );

                warningSlowChargingAnodeHighTempClass3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3SlowChargeAnodeHighTemp ? canData.bcuDtc?.warn3SlowChargeAnodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_KEY,
                    canData.bcuDtc?.warn3SlowChargeAnodeHighTemp
                );

                warningSlowChargingCathodeHighTempClass2Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn2SlowChargeCathodeHighTemp ? canData.bcuDtc?.warn2SlowChargeCathodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_KEY,
                    canData.bcuDtc?.warn2SlowChargeCathodeHighTemp
                );

                warningSlowChargingCathodeHighTempClass3Datas.push({
                    x: time.valueOf(),
                    y: canData.bcuDtc?.warn3SlowChargeCathodeHighTemp ? canData.bcuDtc?.warn3SlowChargeCathodeHighTemp : 0,
                });
                checkExistFaultDataBcuDtc(
                    Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_KEY,
                    canData.bcuDtc?.warn3SlowChargeCathodeHighTemp
                );
                //#endregion

                //#region VCU Motor Control
                vcuMotorControlModeDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuMotorControl?.motorControlMode || 0,
                });

                vcuMotorControlVcuCounterDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuMotorControl?.vcuCounter || 0,
                });

                vcuMotorControlTorqueDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuMotorControl?.motorControlTorque || 0,
                });

                vcuMotorControlSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vcuMotorControl?.motorControlSpeed || 0,
                });
                //#endregion

                //#region MCU Status
                mcuStatusFeedbackSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus?.feedbackSpeed || 0,
                });

                mcuStatusFeedbackTorqueDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus?.feedbackTorque || 0,
                });

                mcuStatusMcuStateFlagDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus?.mcuStateFlag || 0,
                });

                mcuStatusMcuAliveCountDatas.push({
                    x: time.valueOf(),
                    y: canData.mcuStatus?.mcuAliveCount || 0,
                });
                //#endregion
            });

            //#region setChartVcu
            setChartVcuVehicleFunction({
                ...chartVcuVehicleFunction,
                data: vcuVehicleFunctionDatas,
            });

            setChartVcuShiftLocation({
                ...chartVcuShiftLocation,
                data: vcuShiftLocationDatas,
            });

            setChartVcuKeyStatus({
                ...chartVcuKeyStatus,
                data: vcuKeyStatusDatas,
            });

            setChartVcuVehicleSpeed({
                ...chartVcuVehicleSpeed,
                data: vcuVehicleSpeedDatas,
            });

            setChartVcuPedalState({
                ...chartVcuPedalState,
                data: vcuPedalStateDatas,
            });

            setChartVcuBrakeState({
                ...chartVcuBrakeState,
                data: vcuBrakeStateDatas,
            });

            setChartVcuHandbrakeState({
                ...chartVcuHandbrakeState,
                data: vcuHandbrakeStateDatas,
            });
            //#endregion

            //#region setChartVcu2InsC
            setChartVcu2InsCSocIns({
                ...chartVcu2InsCSocIns,
                data: vcu2InsCSocInsDatas,
            });

            setChartVcu2InsCCurrentIns({
                ...chartVcu2InsCCurrentIns,
                data: vcu2InsCCurrentInsDatas,
            });

            setChartVcu2InsCVoltIns({
                ...chartVcu2InsCVoltIns,
                data: vcu2InsCVoltInsDatas,
            });

            setChartVcu2InsCInsulationResistance({
                ...chartVcu2InsCInsulationResistance,
                data: vcu2InsCInsulationResistanceDatas,
            });

            setChartVcu2InsCCellMaxTemp({
                ...chartVcu2InsCCellMaxTemp,
                data: vcu2InsCCellMaxTempDatas,
            });

            setChartVcu2InsCLedCharging({
                ...chartVcu2InsCLedCharging,
                data: vcu2InsCLedChargingDatas,
            });

            setChartVcu2InsCLedChargingConnection({
                ...chartVcu2InsCLedChargingConnection,
                data: vcu2InsCLedChargingConnectionDatas,
            });

            setChartVcu2InsCLedInsulationWarning({
                ...chartVcu2InsCLedInsulationWarning,
                data: vcu2InsCLedInsulationWarningDatas,
            });

            setChartVcu2InsCLedBattLowVoltWarning({
                ...chartVcu2InsCLedBattLowVoltWarning,
                data: vcu2InsCLedBattLowVoltWarningDatas,
            });

            setChartVcu2InsCLedLowSocWarning({
                ...chartVcu2InsCLedLowSocWarning,
                data: vcu2InsCLedLowSocWarningDatas,
            });

            setChartVcu2InsCLedBattFailure({
                ...chartVcu2InsCLedBattFailure,
                data: vcu2InsCLedBattFailureDatas,
            });

            setChartVcu2InsCLedSoundSignal({
                ...chartVcu2InsCLedSoundSignal,
                data: vcu2InsCLedSoundSignalDatas,
            });

            setChartVcu2InsCReadySignal({
                ...chartVcu2InsCReadySignal,
                data: vcu2InsCReadySignalDatas,
            });
            //#endregion

            //#region setChartVcuCommand
            setChartVcuCommandContactorRequest({
                ...chartVcuCommandContactorRequest,
                data: vcuCommand1ContactorRequestDatas,
            });

            setChartVcuCommandPowerDownRequest({
                ...chartVcuCommandPowerDownRequest,
                data: vcuCommand1PowerDownRequestDatas,
            });

            setChartVcuCommandFaultLockoutRequest({
                ...chartVcuCommandFaultLockoutRequest,
                data: vcuCommand1FaultLockoutRequestDatas,
            });

            setChartVcuCommandAcPreChargeVoltageRequest({
                ...chartVcuCommandAcPreChargeVoltageRequest,
                data: vcuCommand1AcPreChargeVoltageRequestDatas,
            });

            setChartVcuCommandMcuInputVoltage({
                ...chartVcuCommandMcuInputVoltage,
                data: vcuCommand1McuInputVoltageDatas,
            });

            setChartVcuCommandMotorAcCurrent({
                ...chartVcuCommandMotorAcCurrent,
                data: vcuCommand1MototAcCurrentDatas,
            });

            setChartVcuCommandAcPreChargeVoltageStatus({
                ...chartVcuCommandAcPreChargeVoltageStatus,
                data: vcuCommand2AcPreChargeVoltageStatusDatas,
            });
            //#endregion

            //#region setChartBattery
            setChartBatteryBatteryStatus({
                ...chartBatteryBatteryStatus,
                data: batteryBatteryStatusDatas,
            });

            setChartBatteryBatteryMode({
                ...chartBatteryBatteryMode,
                data: batteryBatteryModeDatas,
            });

            setChartBatteryFaultStatus({
                ...chartBatteryFaultStatus,
                data: batteryFaultStatusDatas,
            });

            setChartBatteryBalanceLockoutStatus({
                ...chartBatteryBalanceLockoutStatus,
                data: batteryBalanceLockoutStatusDatas,
            });

            setChartBatteryFaultLockoutStatus({
                ...chartBatteryFaultLockoutStatus,
                data: batteryFaultLockoutStatusDatas,
            });

            setChartBatterySoc({
                ...chartBatterySoc,
                data: batterySocDatas,
            });

            setChartBatterySoh({
                ...chartBatterySoh,
                data: batterySohDatas,
            });

            setChartBatteryPackInternalResistance({
                ...chartBatteryPackInternalResistance,
                data: batteryPackInternalResistanceDatas,
            });

            setChartBatteryPackResidualCapacity({
                ...chartBatteryPackResidualCapacity,
                data: batteryPackResidualCapacityDatas,
            });

            setChartBatteryMessageCounter({
                ...chartBatteryMessageCounter,
                data: batteryMessageCounterDatas,
            });
            //#endregion

            //#region setChartBatteryPack1
            setChartBatteryPack1PackVbatt({
                ...chartBatteryPack1PackVbatt,
                data: batteryPack1PackVbattDatas,
            });

            setChartBatteryPack1PackVout({
                ...chartBatteryPack1PackVout,
                data: batteryPack1PackVoutDatas,
            });

            setChartBatteryPack1PackCurrent({
                ...chartBatteryPack1PackCurrent,
                data: batteryPack1PackCurrentDatas,
            });

            setChartBatteryPack1PackPower({
                ...chartBatteryPack1PackPower,
                data: batteryPack1PackPowerDatas,
            });

            setChartBatteryPack1PackTemp({
                ...chartBatteryPack1PackTemp,
                data: batteryPack1PackTempDatas,
            });
            //#endregion

            //#region setChartBatteryPack2
            setChartBatteryPack2SlaveControl01({
                ...chartBatteryPack2SlaveControl01,
                data: batteryPack2SlaveControl01Datas,
            });

            setChartBatteryPack2SlaveControl09({
                ...chartBatteryPack2SlaveControl09,
                data: batteryPack2SlaveControl09Datas,
            });

            setChartBatteryPack2SlaveControl17({
                ...chartBatteryPack2SlaveControl17,
                data: batteryPack2SlaveControl17Datas,
            });

            setChartBatteryPack2SlaveControl25({
                ...chartBatteryPack2SlaveControl25,
                data: batteryPack2SlaveControl25Datas,
            });

            setChartBatteryPack2BatteryBoxNumber({
                ...chartBatteryPack2BatteryBoxNumber,
                data: batteryPack2BatteryBoxNumberDatas,
            });

            setChartBatteryPack2SlaveControlNumber({
                ...chartBatteryPack2SlaveControlNumber,
                data: batteryPack2SlaveControlNumberDatas,
            });
            //#endregion

            //#region setChartCellVoltage
            setChartCellVoltageCellVoltageSum({
                ...chartCellVoltageCellVoltageSum,
                data: cellVoltageCellVoltageSumDatas,
            });

            setChartCellVoltageCellMaxV({
                ...chartCellVoltageCellMaxV,
                data: cellVoltageCellMaxVDatas,
            });

            setChartCellVoltageCellMinV({
                ...chartCellVoltageCellMinV,
                data: cellVoltageCellMinVDatas,
            });

            setChartCellVoltageCellMaxVNo({
                ...chartCellVoltageCellMaxVNo,
                data: cellVoltageCellMaxVNoDatas,
            });

            setChartCellVoltageCellMinVNo({
                ...chartCellVoltageCellMinVNo,
                data: cellVoltageCellMinVNoDatas,
            });

            setChartCellVoltageCellMaxVSystemNo({
                ...chartCellVoltageCellMaxVSystemNo,
                data: cellVoltageCellMaxVSystemNoDatas,
            });

            setChartCellVoltageCellMinVSystemNo({
                ...chartCellVoltageCellMinVSystemNo,
                data: cellVoltageCellMinVSystemNoDatas,
            });
            //#endregion

            //#region setChartCellTemp
            setChartCellTempCellAvgTemp({
                ...chartCellTempCellAvgTemp,
                data: cellTempCellAvgTempDatas,
            });

            setChartCellTempCellMaxTemp({
                ...chartCellTempCellMaxTemp,
                data: cellTempCellMaxTempDatas,
            });

            setChartCellTempCellMinTemp({
                ...chartCellTempCellMinTemp,
                data: cellTempCellMinTempDatas,
            });

            setChartCellTempCellMaxTempNo({
                ...chartCellTempCellMaxTempNo,
                data: cellTempCellMaxTempNoDatas,
            });

            setChartCellTempCellMinTempNo({
                ...chartCellTempCellMinTempNo,
                data: cellTempCellMinTempNoDatas,
            });

            setChartCellTempCellMaxTempSystemNo({
                ...chartCellTempCellMaxTempSystemNo,
                data: cellTempCellMaxTempSystemNoDatas,
            });

            setChartCellTempCellMinTempSystemNo({
                ...chartCellTempCellMinTempSystemNo,
                data: cellTempCellMinTempSystemNoDatas,
            });
            //#endregion

            //#region setChartBmsLimit1
            setChartBmsLimit1MaxPackVLimit({
                ...chartBmsLimit1MaxPackVLimit,
                data: bmsLimit1MaxPackVLimitDatas,
            });

            setChartBmsLimit1MinPackVLimit({
                ...chartBmsLimit1MinPackVLimit,
                data: bmsLimit1MinPackVLimitDatas,
            });

            setChartBmsLimit1MaxCellVLimit({
                ...chartBmsLimit1MaxCellVLimit,
                data: bmsLimit1MaxCellVLimitDatas,
            });

            setChartBmsLimit1MinCellVLimit({
                ...chartBmsLimit1MinCellVLimit,
                data: bmsLimit1MinCellVLimitDatas,
            });

            setChartBmsLimit1DischargeCurrentLimit({
                ...chartBmsLimit1DischargeCurrentLimit,
                data: bmsLimit1DischargeCurrentLimitDatas,
            });

            setChartBmsLimit1MaxChargeCurrentLimit({
                ...chartBmsLimit1MaxChargeCurrentLimit,
                data: bmsLimit1MaxChargeCurrentLimitDatas,
            });
            //#endregion

            //#region setChartBmsLimit2
            setChartBmsLimit2MaxSocLimit({
                ...chartBmsLimit2MaxSocLimit,
                data: bmsLimit2MaxSocLimitDatas,
            });

            setChartBmsLimit2MinSocLimit({
                ...chartBmsLimit2MinSocLimit,
                data: bmsLimit2MinSocLimitDatas,
            });

            setChartBmsLimit2Max10secDischargePowerLimit({
                ...chartBmsLimit2Max10secDischargePowerLimit,
                data: bmsLimit2Max10secDischargePowerLimitDatas,
            });

            setChartBmsLimit2Max10secChargePowerLimit({
                ...chartBmsLimit2Max10secChargePowerLimit,
                data: bmsLimit2Max10secChargePowerLimitDatas,
            });

            setChartBmsLimit2MaxCellTempLimit({
                ...chartBmsLimit2MaxCellTempLimit,
                data: bmsLimit2MaxCellTempLimitDatas,
            });

            setChartBmsLimit2MinCellTempLimit({
                ...chartBmsLimit2MinCellTempLimit,
                data: bmsLimit2MinCellTempLimitDatas,
            });
            //#endregion

            //#region setChartIsolationR
            setChartIsolationRIsolationLifeSignal({
                ...chartIsolationRIsolationLifeSignal,
                data: isolationRIsolationLifeSignalDatas,
            });

            setChartIsolationRIsolationResistanceStatus({
                ...chartIsolationRIsolationResistanceStatus,
                data: isolationRIsolationResistanceStatusDatas,
            });

            setChartIsolationRFaultLevel({
                ...chartIsolationRFaultLevel,
                data: isolationRFaultLevelDatas,
            });

            setChartIsolationRRgndPos({
                ...chartIsolationRRgndPos,
                data: isolationRRgndPosDatas,
            });

            setChartIsolationRRgndNeg({
                ...chartIsolationRRgndNeg,
                data: isolationRRgndNegDatas,
            });
            //#endregion

            //#region setChartBatteryInformation1
            setChartBatteryInformation1BatterySubsystemNo({
                ...chartBatteryInformation1BatterySubsystemNo,
                data: batteryInformation1BatterySubsystemNoDatas,
            });

            setChartBatteryInformation1TotalCellNo({
                ...chartBatteryInformation1TotalCellNo,
                data: batteryInformation1TotalCellNoDatas,
            });

            setChartBatteryInformation1TempSensorNo({
                ...chartBatteryInformation1TempSensorNo,
                data: batteryInformation1TempSensorNoDatas,
            });

            setChartBatteryInformation1BatterySystemErrorNo({
                ...chartBatteryInformation1BatterySystemErrorNo,
                data: batteryInformation1BatterySystemErrorNoDatas,
            });

            setChartBatteryInformation1BatteryChargingStatus({
                ...chartBatteryInformation1BatteryChargingStatus,
                data: batteryInformation1BatteryChargingStatusDatas,
            });

            setChartBatteryInformation1BatteryChargingGunConnect({
                ...chartBatteryInformation1BatteryChargingGunConnect,
                data: batteryInformation1BatteryChargingGunConnectDatas,
            });
            //#endregion

            //#region setChartBatteryInformation4
            setChartBatteryInformation4BatteryPackAccumulatedOutput({
                ...chartBatteryInformation4BatteryPackAccumulatedOutput,
                data: batteryInformation4BatteryPackAccumulatedOutputDatas,
            });

            setChartBatteryInformation4BatteryPackAccumulatedCharging({
                ...chartBatteryInformation4BatteryPackAccumulatedCharging,
                data: batteryInformation4BatteryPackAccumulatedChargingDatas,
            });

            setChartBatteryInformation4BatteryPackAccumulatedRegen({
                ...chartBatteryInformation4BatteryPackAccumulatedRegen,
                data: batteryInformation4BatteryPackAccumulatedRegenDatas,
            });
            //#endregion

            //#region BCU DTC
            setChartWarningCellUvp({
                ...chartWarningCellUvp,
                data: warningCellUvpDatas,
            });

            setChartWarning2CellUvp({
                ...chartWarning2CellUvp,
                data: warning2CellUvpDatas,
            });

            setChartFaultCellUvp({
                ...chartFaultCellUvp,
                data: faultCellUvpDatas,
            });

            setChartWarningCellOvp({
                ...chartWarningCellOvp,
                data: warningCellOvpDatas,
            });

            setChartWarning2CellOvp({
                ...chartWarning2CellOvp,
                data: warning2CellOvpDatas,
            });

            setChartFaultCellOvp({
                ...chartFaultCellOvp,
                data: faultCellOvpDatas,
            });

            setChartWarningTotalVoltageCollectFailLevel2({
                ...chartWarningTotalVoltageCollectFailLevel2,
                data: warningTotalVoltageCollectFailLevel2Datas,
            });

            setChartWarningCellVoltageCollectFailLevel3({
                ...chartWarningCellVoltageCollectFailLevel3,
                data: warningCellVoltageCollectFailLevel3Datas,
            });

            setChartWarningCellUtp({
                ...chartWarningCellUtp,
                data: warningCellUtpDatas,
            });

            setChartWarning2CellUtp({
                ...chartWarning2CellUtp,
                data: warning2CellUtpDatas,
            });

            setChartFaultCellUtp({
                ...chartFaultCellUtp,
                data: faultCellUtpDatas,
            });

            setChartWarningCellOtp({
                ...chartWarningCellOtp,
                data: warningCellOtpDatas,
            });

            setChartWarning2CellOtp({
                ...chartWarning2CellOtp,
                data: warning2CellOtpDatas,
            });

            setChartFaultCellOtp({
                ...chartFaultCellOtp,
                data: faultCellOtpDatas,
            });

            setChartWarningTempChannelFailLevel2({
                ...chartWarningTempChannelFailLevel2,
                data: warningTempChannelFailLevel2Datas,
            });

            setChartWarningBalancingChannelFailLevel1({
                ...chartWarningBalancingChannelFailLevel1,
                data: warningBalancingChannelFailLevel1Datas,
            });

            setChartWarningPackUpv({
                ...chartWarningPackUpv,
                data: warningPackUpvDatas,
            });

            setChartWarning2PackUpv({
                ...chartWarning2PackUpv,
                data: warning2PackUpvDatas,
            });

            setChartFaultPackUpv({
                ...chartFaultPackUpv,
                data: faultPackUpvDatas,
            });

            setChartWarningPackOpv({
                ...chartWarningPackOpv,
                data: warningPackOpvDatas,
            });

            setChartWarning2PackOpv({
                ...chartWarning2PackOpv,
                data: warning2PackOpvDatas,
            });

            setChartFaultPackOpv({
                ...chartFaultPackOpv,
                data: faultPackOpvDatas,
            });

            setChartWarningPreCharRelayAdhesionFailLevel3({
                ...chartWarningPreCharRelayAdhesionFailLevel3,
                data: warningPreCharRelayAdhesionFailLevel3Datas,
            });

            setChartWarningPreCharRelayFailureFailLevel3({
                ...chartWarningPreCharRelayFailureFailLevel3,
                data: warningPreCharRelayFailureFailLevel3Datas,
            });

            setChartWarningPackVoltageDiff({
                ...chartWarningPackVoltageDiff,
                data: warningPackVoltageDiffDatas,
            });

            setChartWarning2PackVoltageDiff({
                ...chartWarning2PackVoltageDiff,
                data: warning2PackVoltageDiffDatas,
            });

            setChartFaultPackVoltageDiff({
                ...chartFaultPackVoltageDiff,
                data: faultPackVoltageDiffDatas,
            });

            setChartWarningPackTempDiff({
                ...chartWarningPackTempDiff,
                data: warningPackTempDiffDatas,
            });

            setChartWarning2PackTempDiff({
                ...chartWarning2PackTempDiff,
                data: warning2PackTempDiffDatas,
            });

            setChartWarningCurrentCollectFault({
                ...chartWarningCurrentCollectFault,
                data: warningCurrentCollectFaultDatas,
            });

            setChartWarningMainRelayPlusAdhesionFailLevel3({
                ...chartWarningMainRelayPlusAdhesionFailLevel3,
                data: warningMainRelayPlusAdhesionFailLevel3Datas,
            });

            setChartWarningMainRelayMinusAdhesionFailLevel3({
                ...chartWarningMainRelayMinusAdhesionFailLevel3,
                data: warningMainRelayMinusAdhesionFailLevel3Datas,
            });

            setChartWarningUnderSoc({
                ...chartWarningUnderSoc,
                data: warningUnderSocDatas,
            });

            setChartWarning2UnderSoc({
                ...chartWarning2UnderSoc,
                data: warning2UnderSocDatas,
            });

            setChartFaultUnderSoc({
                ...chartFaultUnderSoc,
                data: faultUnderSocDatas,
            });

            setChartWarningOverSoc({
                ...chartWarningOverSoc,
                data: warningOverSocDatas,
            });

            setChartWarning2OverSoc({
                ...chartWarning2OverSoc,
                data: warning2OverSocDatas,
            });

            setChartFaultOverSoc({
                ...chartFaultOverSoc,
                data: faultOverSocDatas,
            });

            setChartWarningBmsInternalCommFailLevel3({
                ...chartWarningBmsInternalCommFailLevel3,
                data: warningBmsInternalCommFailLevel3Datas,
            });

            setChartWarningBmsVehicleCommFailLevel3({
                ...chartWarningBmsVehicleCommFailLevel3,
                data: warningBmsVehicleCommFailLevel3Datas,
            });

            setChartWarningDischargeOcp({
                ...chartWarningDischargeOcp,
                data: warningDischargeOcpDatas,
            });

            setChartWarning2DischargeOcp({
                ...chartWarning2DischargeOcp,
                data: warning2DischargeOcpDatas,
            });

            setChartFaultDischargeOcp({
                ...chartFaultDischargeOcp,
                data: faultDischargeOcpDatas,
            });

            setChartWarningChargeOcp({
                ...chartWarningChargeOcp,
                data: warningChargeOcpDatas,
            });

            setChartWarning2ChargeOcp({
                ...chartWarning2ChargeOcp,
                data: warning2ChargeOcpDatas,
            });

            setChartFaultChargeOcp({
                ...chartFaultChargeOcp,
                data: faultChargeOcpDatas,
            });

            setChartWarningInsulationFailLevel2({
                ...chartWarningInsulationFailLevel2,
                data: warningInsulationFailLevel2Datas,
            });

            setChartWarningInsulationFailLevel3({
                ...chartWarningInsulationFailLevel3,
                data: warningInsulationFailLevel3Datas,
            });

            setChartWarningMotorRelayAdhesionFailLevel3({
                ...chartWarningMotorRelayAdhesionFailLevel3,
                data: warningMotorRelayAdhesionFailLevel3Datas,
            });

            setChartWarningDischargeFreezingCircuitBreakingFailLevel2({
                ...chartWarningDischargeFreezingCircuitBreakingFailLevel2,
                data: warningDischargeFreezingCircuitBreakingFailLevel2Datas,
            });

            setChartWarningChargeFreezeCircuitBreakingFailLevel2({
                ...chartWarningChargeFreezeCircuitBreakingFailLevel2,
                data: warningChargeFreezeCircuitBreakingFailLevel2Datas,
            });

            setChartWarningFastChargingRelayAdhesionFailLevel3({
                ...chartWarningFastChargingRelayAdhesionFailLevel3,
                data: warningFastChargingRelayAdhesionFailLevel3Datas,
            });

            setChartWarningSlowChargingRelayAdhesionFailLevel3({
                ...chartWarningSlowChargingRelayAdhesionFailLevel3,
                data: warningSlowChargingRelayAdhesionFailLevel3Datas,
            });

            setChartWarningHeatingRelayAdhesionFailLevel3({
                ...chartWarningHeatingRelayAdhesionFailLevel3,
                data: warningHeatingRelayAdhesionFailLevel3Datas,
            });

            setChartWarningSocJumpAbnormal({
                ...chartWarningSocJumpAbnormal,
                data: warningSocJumpAbnormalDatas,
            });

            setChartWarningBatterySystemMismatch({
                ...chartWarningBatterySystemMismatch,
                data: warningBatterySystemMismatchDatas,
            });

            setChartWarningFastChargingAnodeHighTempClass2({
                ...chartWarningFastChargingAnodeHighTempClass2,
                data: warningFastChargingAnodeHighTempClass2Datas,
            });

            setChartWarningFastChargingAnodeHighTempClass3({
                ...chartWarningFastChargingAnodeHighTempClass3,
                data: warningFastChargingAnodeHighTempClass3Datas,
            });

            setChartWarningFastChargingCathodeHighTempClass2({
                ...chartWarningFastChargingCathodeHighTempClass2,
                data: warningFastChargingCathodeHighTempClass2Datas,
            });

            setChartWarningFastChargingCathodeHighTempClass3({
                ...chartWarningFastChargingCathodeHighTempClass3,
                data: warningFastChargingCathodeHighTempClass3Datas,
            });

            setChartWarningSlowChargingAnodeHighTempClass2({
                ...chartWarningSlowChargingAnodeHighTempClass2,
                data: warningSlowChargingAnodeHighTempClass2Datas,
            });

            setChartWarningSlowChargingAnodeHighTempClass3({
                ...chartWarningSlowChargingAnodeHighTempClass3,
                data: warningSlowChargingAnodeHighTempClass3Datas,
            });

            setChartWarningSlowChargingCathodeHighTempClass2({
                ...chartWarningSlowChargingCathodeHighTempClass2,
                data: warningSlowChargingCathodeHighTempClass2Datas,
            });

            setChartWarningSlowChargingCathodeHighTempClass3({
                ...chartWarningSlowChargingCathodeHighTempClass3,
                data: warningSlowChargingCathodeHighTempClass3Datas,
            });
            //#endregion

            //#region setChartVcuMotorControl
            setChartVcuMotorControlMode({
                ...chartVcuMotorControlMode,
                data: vcuMotorControlModeDatas,
            });

            setChartVcuMotorControlVcuCounter({
                ...chartVcuMotorControlVcuCounter,
                data: vcuMotorControlVcuCounterDatas,
            });

            setChartVcuMotorControlTorque({
                ...chartVcuMotorControlTorque,
                data: vcuMotorControlTorqueDatas,
            });

            setChartVcuMotorControlSpeed({
                ...chartVcuMotorControlSpeed,
                data: vcuMotorControlSpeedDatas,
            });
            //#endregion

            //#region setChartVcuMotorControl
            setChartMcuStatusFeedbackSpeed({
                ...chartMcuStatusFeedbackSpeed,
                data: mcuStatusFeedbackSpeedDatas,
            });

            setChartMcuStatusFeedbackTorque({
                ...chartMcuStatusFeedbackTorque,
                data: mcuStatusFeedbackTorqueDatas,
            });

            setChartMcuStatusMcuStateFlag({
                ...chartMcuStatusMcuStateFlag,
                data: mcuStatusMcuStateFlagDatas,
            });

            setChartMcuStatusMcuAliveCount({
                ...chartMcuStatusMcuAliveCount,
                data: mcuStatusMcuAliveCountDatas,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Bukgi) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataBukgi.CanData;

            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (
            vcuChartMounted === true ||
            batteryChartMounted === true ||
            cellVoltageChartMounted === true ||
            cellTempChartMounted === true ||
            vcuCommandChartMounted === true ||
            vcu2InsCChartMounted === true ||
            (tableDataBcuDtc.length > 0 ? bcuDtcChartMounted === true : false) ||
            isolationRChartMounted === true ||
            batteryInformation4ChartMounted === true ||
            batteryPack1ChartMounted === true ||
            batteryPack2ChartMounted === true ||
            bmsLimit1ChartMounted === true ||
            bmsLimit2ChartMounted === true ||
            batteryInformation1ChartMounted === true ||
            vcuMotorControlChartMounted === true ||
            mcuStatusChartMounted === true
        ) {
            if (onMountedChart !== undefined) onMountedChart();
        }
    }, [
        vcuChartMounted,
        batteryChartMounted,
        cellVoltageChartMounted,
        cellTempChartMounted,
        vcuCommandChartMounted,
        vcu2InsCChartMounted,
        bcuDtcChartMounted,
        isolationRChartMounted,
        batteryInformation4ChartMounted,
        batteryPack1ChartMounted,
        batteryPack2ChartMounted,
        bmsLimit1ChartMounted,
        bmsLimit2ChartMounted,
        batteryInformation1ChartMounted,
        vcuMotorControlChartMounted,
        mcuStatusChartMounted,
    ]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.VCU_S_1_VEHICLE_FUNCTION_NAME: {
                convertTooltipString = convertVehicleFuntionRemark(value);
                break;
            }

            case Common.VCU_S_1_SHIFT_LOCATION_NAME: {
                convertTooltipString = convertShiftLocationRemark(value);
                break;
            }

            case Common.VCU_S_1_KEY_STATUS_NAME: {
                convertTooltipString = convertKeyStatusRemark(value);
                break;
            }

            case Common.VCU_S_1_VEHICLE_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_SPEED);
                break;
            }

            case Common.VCU_S_1_BRAKE_STATE_NAME: {
                convertTooltipString = convertBrakeStateRemark(value);
                break;
            }

            case Common.VCU_F_1_HANDBRAKE_STATE_NAME: {
                convertTooltipString = convertHandbrakeStateRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_1_INSUL_RESISTANCE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_M_OHM);
                break;
            }

            case Common.VCU_TO_INS_C_1_LED_CHARGING_NAME: {
                convertTooltipString = convertLedChargingRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_1_LED_CHARGING_CONNECTION_NAME: {
                convertTooltipString = convertLedChargingConnectionRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_1_LED_INSULATION_WARNING_NAME: {
                convertTooltipString = convertLedInsulationWarningRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_1_LED_BATT_LOW_VOLT_WARNING_NAME: {
                convertTooltipString = convertLedBattLowVoltWarningRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_1_LED_LOW_SOC_WARNING_NAME: {
                convertTooltipString = convertLedLowSocWarningRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_1_LED_BATT_FAILURE_NAME: {
                convertTooltipString = convertLedBattFailureRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_1_LED_SOUND_SIGNAL_NAME: {
                convertTooltipString = convertLedSoundSignalRemark(value);
                break;
            }

            case Common.VCU_TO_INS_C_2_READY_SIGNAL_NAME: {
                convertTooltipString = convertReadySignalRemark(value);
                break;
            }

            case Common.VCU_COMMAND_1_CONTACTOR_REQUEST_NAME: {
                convertTooltipString = convertContactRequestRemark(value);
                break;
            }

            case Common.VCU_COMMAND_1_POWER_DOWN_REQUEST_NAME: {
                convertTooltipString = convertPowerDownRequestRemark(value);
                break;
            }

            case Common.VCU_COMMAND_1_FAULT_LOCKOUT_REQUEST_NAME: {
                convertTooltipString = convertFaultLockoutRequestRemark(value);
                break;
            }

            case Common.VCU_COMMAND_1_AC_PRE_CHARGE_VOLTAGE_REQUEST_NAME: {
                convertTooltipString = convertAcPreChargeVoltageRequestRemark(value);
                break;
            }

            case Common.VCU_COMMAND_1_MCU_INPUT_VOLTAGE_NAME:
            case Common.VCU_COMMAND_1_MOTOR_AC_CURRENT_NAME:
            case Common.BATTERY_PACK1_PACK_VBATT_NAME:
            case Common.BATTERY_PACK1_PACK_VOUT_NAME:
            case Common.CELL_VOLTAGE_CELL_VOLTAGE_SUM_NAME:
            case Common.BMS_LIMIT_1_MAX_PACK_V_NAME:
            case Common.BMS_LIMIT_1_MIN_PACK_V_NAME:
            case Common.BMS_LIMIT_1_MAX_CELL_V_NAME:
            case Common.BMS_LIMIT_1_MIN_CELL_V_NAME:
            case Common.VCU_TO_INS_C_1_VOLT_INS_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }

            case Common.VCU_COMMAND_2_AC_PRE_CHARGE_VOLTAGE_STATUS_NAME: {
                convertTooltipString = convertAcPreChargeVoltageStatusRemark(value);
                break;
            }

            case Common.BATTERY_BATTERY_STATUS_NAME: {
                convertTooltipString = convertBatteryStatusRemark(value);
                break;
            }

            case Common.BATTERY_BATTERY_MODE_NAME: {
                convertTooltipString = convertBatteryModeRemark(value);
                break;
            }

            case Common.BATTERY_FAULT_STATUS_NAME: {
                convertTooltipString = convertFaultStatusRemark(value);
                break;
            }

            case Common.BATTERY_BALANCE_LOCKOUT_STATUS_NAME: {
                convertTooltipString = convertBalanceLockoutStatusRemark(value);
                break;
            }

            case Common.BATTERY_FAULT_LOCKOUT_STATUS_NAME: {
                convertTooltipString = convertFaultLockoutStatusRemark(value);
                break;
            }

            case Common.BATTERY_SOC_NAME:
            case Common.BATTERY_SOH_NAME:
            case Common.BMS_LIMIT_2_MAX_SOC_NAME:
            case Common.BMS_LIMIT_2_MIN_SOC_NAME:
            case Common.VCU_TO_INS_C_1_SOC_INS_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PERCENT);
                break;
            }

            case Common.BATTERY_PACK_INTERNAL_RESISTANCE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_OHM);
                break;
            }

            case Common.BATTERY_PACK_RESIDUAL_CAPACITY_NAME:
            case Common.BATTERY_PACK1_PACK_CURRENT_NAME:
            case Common.BMS_LIMIT_1_DISCHARGE_CURRENT_NAME:
            case Common.BMS_LIMIT_1_MAX_CHARGE_CURRENT_NAME:
            case Common.VCU_TO_INS_C_1_CURRENT_INS_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }

            case Common.BATTERY_PACK1_PACK_POWER_NAME:
            case Common.BMS_LIMIT_2_MAX_10SEC_DISCHARGE_POWER_NAME:
            case Common.BMS_LIMIT_2_MAX_10SEC_CHARGE_POWER_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_POWER);
                break;
            }

            case Common.BATTERY_PACK1_PACK_TEMP_NAME:
            case Common.CELL_TEMP_CELL_AVG_TEMP_NAME:
            case Common.BMS_LIMIT_2_MAX_CELL_TEMP_NAME:
            case Common.BMS_LIMIT_2_MIN_CELL_TEMP_NAME:
            case Common.VCU_TO_INS_C_1_CELL_MAX_TEMP_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CELSIUS);
                break;
            }

            case Common.BATTERY_PACK2_SLAVE_CONTROL_1_NAME:
            case Common.BATTERY_PACK2_SLAVE_CONTROL_9_NAME:
            case Common.BATTERY_PACK2_SLAVE_CONTROL_17_NAME:
            case Common.BATTERY_PACK2_SLAVE_CONTROL_25_NAME: {
                convertTooltipString = convertRemarkToBinaryString(value);
                break;
            }

            case Common.BATTERY_PACK2_BATTERY_BOX_NUMBER_NAME:
            case Common.BATTERY_PACK2_SLAVE_CONTROL_NUMBER_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_NUMBER);
                break;
            }

            case Common.CELL_VOLTAGE_CELL_MAX_V_NAME:
            case Common.CELL_VOLTAGE_CELL_MIN_V_NAME: {
                convertTooltipString = convertRemarkWithUnitNException(value, Common.UNIT_VOLTAGE, 12);
                break;
            }

            case Common.CELL_VOLTAGE_CELL_MAX_V_NO_NAME:
            case Common.CELL_VOLTAGE_CELL_MIN_V_NO_NAME:
            case Common.CELL_TEMP_CELL_MAX_TEMP_NO_NAME:
            case Common.CELL_TEMP_CELL_MIN_TEMP_NO_NAME: {
                convertTooltipString = convertRemarkWithUnitNException(value, "", 8);
                break;
            }

            case Common.CELL_VOLTAGE_CELL_MAX_V_SYSTEM_NO_NAME:
            case Common.CELL_VOLTAGE_CELL_MIN_V_SYSTEM_NO_NAME:
            case Common.CELL_TEMP_CELL_MAX_TEMP_SYSTEM_NO_NAME:
            case Common.CELL_TEMP_CELL_MIN_TEMP_SYSTEM_NO_NAME: {
                convertTooltipString = convertRemarkWithUnitNException(value, "", 4);
                break;
            }

            case Common.CELL_TEMP_CELL_MAX_TEMP_NAME:
            case Common.CELL_TEMP_CELL_MIN_TEMP_NAME: {
                convertTooltipString = convertRemarkWithUnitNException(value, Common.UNIT_CELSIUS, 12);
                break;
            }

            case Common.ISOLATION_R_ISOLATION_RESISTANCE_STATUS_NAME: {
                convertTooltipString = convertIsolationResistanceStatusRemark(value);
                break;
            }

            case Common.ISOLATION_R_RGND_POS_NAME:
            case Common.ISOLATION_R_RGND_NEG_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_K_OHM);
                break;
            }

            case Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_STATUS_NAME:
                convertTooltipString = convertBatteryInformation1BatteryChargingStatusRemark(value);
                break;

            case Common.BATTERY_INFORMATION_1_BATTERY_CHARGING_GUN_CONNECT_NAME:
                convertTooltipString = convertBatteryInformation1BatteryChargingGunConnectRemark(value);
                break;

            case Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_OUTPUT_NAME:
            case Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_CHARGING_NAME:
            case Common.BATTERY_INFORMATION_4_BATTERY_PACK_ACCUMULATED_REGEN_NAME:
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_POWER_H);
                break;

            case Common.BCU_DTC_WARNING_CELL_UVP_NAME:
            case Common.BCU_DTC_WARNING2_CELL_UVP_NAME:
            case Common.BCU_DTC_FAULT_CELL_UVP_NAME:
            case Common.BCU_DTC_WARNING_CELL_OVP_NAME:
            case Common.BCU_DTC_WARNING2_CELL_OVP_NAME:
            case Common.BCU_DTC_FAULT_CELL_OVP_NAME:
            case Common.BCU_DTC_WARNING_TOTAL_VOLTAGE_COLLECT_FAIL_LEVEL2_NAME:
            case Common.BCU_DTC_WARNING_CELL_VOLTAGE_COLLECT_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_CELL_UTP_NAME:
            case Common.BCU_DTC_WARNING2_CELL_UTP_NAME:
            case Common.BCU_DTC_FAULT_CELL_UTP_NAME:
            case Common.BCU_DTC_WARNING_CELL_OTP_NAME:
            case Common.BCU_DTC_WARNING2_CELL_OTP_NAME:
            case Common.BCU_DTC_FAULT_CELL_OTP_NAME:
            case Common.BCU_DTC_WARNING_TEMP_CHANNEL_FAIL_LEVEL2_NAME:
            case Common.BCU_DTC_WARNING_BALANCING_CHANNEL_FAIL_LEVEL1_NAME:
            case Common.BCU_DTC_WARNING_PACK_UPV_NAME:
            case Common.BCU_DTC_WARNING2_PACK_UPV_NAME:
            case Common.BCU_DTC_FAULT_PACK_UPV_NAME:
            case Common.BCU_DTC_WARNING_PACK_OPV_NAME:
            case Common.BCU_DTC_WARNING2_PACK_OPV_NAME:
            case Common.BCU_DTC_FAULT_PACK_OPV_NAME:
            case Common.BCU_DTC_WARNING_PRECHARRELAY_ADHESION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_PRECHARRELAY_FAILURE_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_PACK_VOLTAGE_DIFF_NAME:
            case Common.BCU_DTC_WARNING2_PACK_VOLTAGE_DIFF_NAME:
            case Common.BCU_DTC_FAULT_PACK_VOLTAGE_DIFF_NAME:
            case Common.BCU_DTC_WARNING_PACK_TEMP_DIFF_NAME:
            case Common.BCU_DTC_WARNING2_PACK_TEMP_DIFF_NAME:
            case Common.BCU_DTC_WARNING_CURRENT_COLLECT_FAULT_NAME:
            case Common.BCU_DTC_WARNING_MAIN_RELAY_PLUS_ADHESION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_MAIN_RELAY_MINUS_ADHESION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_UNDER_SOC_NAME:
            case Common.BCU_DTC_WARNING2_UNDER_SOC_NAME:
            case Common.BCU_DTC_FAULT_UNDER_SOC_NAME:
            case Common.BCU_DTC_WARNING_OVER_SOC_NAME:
            case Common.BCU_DTC_WARNING2_OVER_SOC_NAME:
            case Common.BCU_DTC_FAULT_OVER_SOC_NAME:
            case Common.BCU_DTC_WARNING_BMS_INTERNAL_COMM_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_BMS_VEHICLE_COMM_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_DISCHARGE_OCP_NAME:
            case Common.BCU_DTC_WARNING2_DISCHARGE_OCP_NAME:
            case Common.BCU_DTC_FAULT_DISCHARGE_OCP_NAME:
            case Common.BCU_DTC_WARNING_CHARGE_OCP_NAME:
            case Common.BCU_DTC_WARNING2_CHARGE_OCP_NAME:
            case Common.BCU_DTC_FAULT_CHARGE_OCP_NAME:
            case Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL2_NAME:
            case Common.BCU_DTC_WARNING_ISULATION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_MOTORRELAY_ADHESION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_DISCHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME:
            case Common.BCU_DTC_WARNING_CHARGE_FREEZING_CIRCUIT_BREAKING_FAIL_LEVEL2_NAME:
            case Common.BCU_DTC_WARNING_FASTCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_SLOWCHARGING_RELAY_ADHESION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_HEATING_RELAY_ADHESION_FAIL_LEVEL3_NAME:
            case Common.BCU_DTC_WARNING_SOC_JUMP_ABNORMAL_NAME:
            case Common.BCU_DTC_WARNING_BATTERY_SYSTEM_MISMATCH_NAME:
            case Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS2_NAME:
            case Common.BCU_DTC_WARNING_FASTCHARGING_ANODE_HIGHTEMP_CLASS3_NAME:
            case Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME:
            case Common.BCU_DTC_WARNING_FASTCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME:
            case Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS2_NAME:
            case Common.BCU_DTC_WARNING_SLOWCHARGING_ANODE_HIGHTEMP_CLASS3_NAME:
            case Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS2_NAME:
            case Common.BCU_DTC_WARNING_SLOWCHARGING_CATHODE_HIGHTEMP_CLASS3_NAME:
                convertTooltipString = convertBcuDtcRemark(value);
                break;

            case Common.VCU_MOTOR_CONTROL_MODE_NAME:
                convertTooltipString = convertVcuMotorControlModeRemark(value);
                break;

            case Common.VCU_MOTOR_CONTROL_TORQUE_NAME:
            case Common.MCU_STATUS_FEEDBACK_TORQUE_NAME:
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_TORQUE);
                break;

            case Common.VCU_MOTOR_CONTROL_SPEED_NAME:
            case Common.MCU_STATUS_FEEDBACK_SPEED_NAME:
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_MOTOR_SPEED);
                break;

            default:
                break;
        }
        return convertTooltipString;
    };

    return (
        <div>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
                <div>
                    <CanDataWrapper
                        title="VCU"
                        id="vcu"
                        panelOpen={vcuPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcu}
                        tableSelectRowKeys={selectedVcu}
                        onChangeTableSelect={onChangeVcu}
                        tableGetTooltips={getTabelTooltipVcu}
                        seriesChartDatas={seriesChartDatasVcu}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Battery"
                        id="battery"
                        panelOpen={batteryPanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBattery}
                        tableSelectRowKeys={selectedBattery}
                        onChangeTableSelect={onChangeBattery}
                        tableGetTooltips={getTabelTooltipBattery}
                        seriesChartDatas={seriesChartDatasBattery}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Cell Voltage"
                        id="cellVoltage"
                        panelOpen={cellVoltagePanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellVoltagePanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellVoltage}
                        tableSelectRowKeys={selectedCellVoltage}
                        onChangeTableSelect={onChangeCellVoltage}
                        tableGetTooltips={getTabelTooltipCellVoltage}
                        seriesChartDatas={seriesChartDatasCellVoltage}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellVoltageChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Cell Temp"
                        id="cellTemp"
                        panelOpen={cellTempPanelOpen}
                        onChangePanelOpen={(panelOpened) => setCellTempPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataCellTemp}
                        tableSelectRowKeys={selectedCellTemp}
                        onChangeTableSelect={onChangeCellTemp}
                        tableGetTooltips={getTabelTooltipCellTemp}
                        seriesChartDatas={seriesChartDatasCellTemp}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setCellTempChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU Command"
                        id="vcuCommand"
                        panelOpen={vcuCommandPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuCommandPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuCommand}
                        tableSelectRowKeys={selectedVcuCommand}
                        onChangeTableSelect={onChangeVcuCommand}
                        tableGetTooltips={getTabelTooltipVcuCommand}
                        seriesChartDatas={seriesChartDatasVcuCommand}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuCommandChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="계기판 표시 정보(VCU To INS C)"
                        id="vcu2InsC"
                        panelOpen={vcu2InsCPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcu2InsCPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcu2InsC}
                        tableSelectRowKeys={selectedVcu2InsC}
                        onChangeTableSelect={onChangeVcu2InsC}
                        tableGetTooltips={getTabelTooltipVcu2InsC}
                        seriesChartDatas={seriesChartDatasVcu2InsC}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcu2InsCChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="VCU Motor Control"
                        id="vcuMotorControl"
                        panelOpen={vcuMotorControlPanelOpen}
                        onChangePanelOpen={(panelOpened) => setVcuMotorControlPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataVcuMotorControl}
                        tableSelectRowKeys={selectedVcuMotorControl}
                        onChangeTableSelect={onChangeVcuMotorControl}
                        tableGetTooltips={getTabelTooltipVcuMotorControl}
                        seriesChartDatas={seriesChartDatasVcuMotorControl}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setVcuMotorControlChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="MCU Status"
                        id="mcuStatus"
                        panelOpen={mcuStatusPanelOpen}
                        onChangePanelOpen={(panelOpened) => setMcuStatusPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataMcuStatus}
                        tableSelectRowKeys={selectedMcuStatus}
                        onChangeTableSelect={onChangeMcuStatus}
                        tableGetTooltips={getTabelTooltipMcuStatus}
                        seriesChartDatas={seriesChartDatasMcuStatus}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setMcuStatusChartMounted(true)}
                    />

                    {tableDataBcuDtc.length > 0 ? (
                        <CanDataWrapper
                            title="BTU DTC"
                            id="bcuDtc"
                            panelOpen={bcuDtcPanelOpen}
                            onChangePanelOpen={(panelOpened) => setBcuDtcPanelOpen(panelOpened)}
                            dataLoading={loading}
                            tableDataSource={tableDataBcuDtc}
                            tableSelectRowKeys={selectedBcuDtc}
                            onChangeTableSelect={onChangeBcuDtc}
                            tableGetTooltips={getTabelTooltipBcuDtc}
                            seriesChartDatas={seriesChartDatasBcuDtc}
                            convertChartTooltips={convertChartTooltip}
                            selectedChartDataPointIndex={selectedDataPointIndex}
                            onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                            onMountedChart={() => setBcuDtcChartMounted(true)}
                        />
                    ) : null}

                    <CanDataWrapper
                        title="절연저항(Isolation R)"
                        id="isolationR"
                        panelOpen={isolationRPanelOpen}
                        onChangePanelOpen={(panelOpened) => setIsolationRPanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataIsolationR}
                        tableSelectRowKeys={selectedIsolationR}
                        onChangeTableSelect={onChangeIsolationR}
                        tableGetTooltips={getTabelTooltipIsolationR}
                        seriesChartDatas={seriesChartDatasIsolationR}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setIsolationRChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="배터리 누적 에너지"
                        id="batteryInformation4"
                        panelOpen={batteryInformation4PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryInformation4PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryInformation4}
                        tableSelectRowKeys={selectedBatteryInformation4}
                        onChangeTableSelect={onChangeBatteryInformation4}
                        tableGetTooltips={getTabelTooltipBatteryInformation4}
                        seriesChartDatas={seriesChartDatasBatteryInformation4}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryInformation4ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Battery Pack 1"
                        id="batteryPack1"
                        panelOpen={batteryPack1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryPack1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryPack1}
                        tableSelectRowKeys={selectedBatteryPack1}
                        onChangeTableSelect={onChangeBatteryPack1}
                        tableGetTooltips={getTabelTooltipBatteryPack1}
                        seriesChartDatas={seriesChartDatasBatteryPack1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryPack1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Battery Pack 2"
                        id="batteryPack2"
                        panelOpen={batteryPack2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryPack2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryPack2}
                        tableSelectRowKeys={selectedBatteryPack2}
                        onChangeTableSelect={onChangeBatteryPack2}
                        tableGetTooltips={getTabelTooltipBatteryPack2}
                        seriesChartDatas={seriesChartDatasBatteryPack2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryPack2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="BMS Limit 1"
                        id="bmsLimit1"
                        panelOpen={bmsLimit1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsLimit1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsLimit1}
                        tableSelectRowKeys={selectedBmsLimit1}
                        onChangeTableSelect={onChangeBmsLimit1}
                        tableGetTooltips={getTabelTooltipBmsLimit1}
                        seriesChartDatas={seriesChartDatasBmsLimit1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsLimit1ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="BMS Limit 2"
                        id="bmsLimit2"
                        panelOpen={bmsLimit2PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBmsLimit2PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBmsLimit2}
                        tableSelectRowKeys={selectedBmsLimit2}
                        onChangeTableSelect={onChangeBmsLimit2}
                        tableGetTooltips={getTabelTooltipBmsLimit2}
                        seriesChartDatas={seriesChartDatasBmsLimit2}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBmsLimit2ChartMounted(true)}
                    />

                    <CanDataWrapper
                        title="Battery Information 1"
                        id="batteryInformation1"
                        panelOpen={batteryInformation1PanelOpen}
                        onChangePanelOpen={(panelOpened) => setBatteryInformation1PanelOpen(panelOpened)}
                        dataLoading={loading}
                        tableDataSource={tableDataBatteryInformation1}
                        tableSelectRowKeys={selectedBatteryInformation1}
                        onChangeTableSelect={onChangeBatteryInformation1}
                        tableGetTooltips={getTabelTooltipBatteryInformation1}
                        seriesChartDatas={seriesChartDatasBatteryInformation1}
                        convertChartTooltips={convertChartTooltip}
                        selectedChartDataPointIndex={selectedDataPointIndex}
                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                        onMountedChart={() => setBatteryInformation1ChartMounted(true)}
                    />
                </div>
            </Space>
        </div>
    );
}

export default CanDataBukgiViewer;
