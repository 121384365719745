import { useEffect, useState } from "react";
import Button from "antd/es/button/button";
import Modal from "antd/es/modal/index";
import Space from "antd/es/space/index";
import { AlertType, useAlert } from "../../provider/AlertProvider";

import * as RequestDoorModules from "../../utils/requestApiDoorModules";
import styles from "../../pages/browser/Dashboard.module.css";

import IconDoorClose from "../../assets/images/icon/icon_lock.png";
import IconDoorOpen from "../../assets/images/icon/icon_unlock.png";
import IconSwingDoorClose from "../../assets/images/icon/icon_swing_door_close.png";
import IconSwingDoorOpen from "../../assets/images/icon/icon_swing_door_open.png";

const DoorModuleModal = ({
    doorModuleId,
    open,
    onChangeOpen,
}: {
    doorModuleId: number;
    open?: boolean;
    onChangeOpen?: (open: boolean) => void;
}) => {
    const alert = useAlert();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (open === undefined) return;
        setIsOpen(open);
    }, [open]);

    const { requestApiDoorModulesOpenDoor, resultApiDoorModulesOpenDoor } = RequestDoorModules.useRequestApiDoorModulesOpenDoor();
    useEffect(() => {
        if (!resultApiDoorModulesOpenDoor) return;

        alert.setAlert(
            AlertType.SUCCESS,
            `차량도어 열기 요청`,
            `차량도어 열기 요청을 보냈습니다.\n이력을 새로고침 하여 결과를 확인해 주세요.`
        );
    }, [resultApiDoorModulesOpenDoor]);

    const { requestApiDoorModulesOpenSwingDoor, resultApiDoorModulesOpenSwingDoor } =
        RequestDoorModules.useRequestApiDoorModulesOpenSwingDoor();
    useEffect(() => {
        if (!resultApiDoorModulesOpenSwingDoor) return;

        alert.setAlert(
            AlertType.SUCCESS,
            `스윙도어 열기 요청`,
            `스윙도어 열기 요청을 보냈습니다.\n이력을 새로고침 하여 결과를 확인해 주세요.`
        );
    }, [resultApiDoorModulesOpenSwingDoor]);

    const { requestApiDoorModulesCloseDoor, resultApiDoorModulesCloseDoor } = RequestDoorModules.useRequestApiDoorModulesCloseDoor();
    useEffect(() => {
        if (!resultApiDoorModulesCloseDoor) return;

        alert.setAlert(
            AlertType.SUCCESS,
            `차량도어 닫기 요청`,
            `차량도어 잠금 요청을 보냈습니다.\n이력을 새로고침 하여 결과를 확인해 주세요.`
        );
    }, [resultApiDoorModulesCloseDoor]);

    const { requestApiDoorModulesCloseSwingDoor, resultApiDoorModulesCloseSwingDoor } =
        RequestDoorModules.useRequestApiDoorModulesCloseSwingDoor();
    useEffect(() => {
        if (!resultApiDoorModulesCloseSwingDoor) return;

        alert.setAlert(
            AlertType.SUCCESS,
            `스윙도어 닫기 요청`,
            `스윙도어 닫기 요청을 보냈습니다.\n이력을 새로고침 하여 결과를 확인해 주세요.`
        );
    }, [resultApiDoorModulesCloseSwingDoor]);

    return (
        <Modal
            centered
            forceRender
            closable={false}
            open={open}
            title={
                <Space style={{ width: "100%", justifyContent: "space-between" }} align="center">
                    <h4 style={{ margin: 0 }}>도어모듈 작동</h4>
                </Space>
            }
            footer={
                <Space style={{ width: "100%", justifyContent: "center" }} size={16} align="center">
                    <Button
                        key="back"
                        onClick={() => {
                            onChangeOpen && onChangeOpen(false);
                        }}
                    >
                        닫기
                    </Button>
                </Space>
            }
        >
            <Space className={styles.carInfoWrapper} size={8} direction="vertical">
                <Space className={styles.doorButton} size={0}>
                    <Button onClick={() => requestApiDoorModulesCloseDoor(doorModuleId)}>
                        <img src={IconDoorClose} height={27} />
                        <span>차량도어 잠금</span>
                    </Button>
                    <Button onClick={() => requestApiDoorModulesOpenDoor(doorModuleId)}>
                        <img src={IconDoorOpen} height={27} />
                        <span>차량도어 열기</span>
                    </Button>
                </Space>

                <Space className={styles.doorButton} size={0}>
                    <Button onClick={() => requestApiDoorModulesCloseSwingDoor(doorModuleId)}>
                        <img src={IconSwingDoorClose} width={25} />
                        <span>스윙도어 닫기</span>
                    </Button>
                    <Button onClick={() => requestApiDoorModulesOpenSwingDoor(doorModuleId)}>
                        <img src={IconSwingDoorOpen} width={25} />
                        <span>스윙도어 열기</span>
                    </Button>
                </Space>
            </Space>
        </Modal>
    );
};

export default DoorModuleModal;
