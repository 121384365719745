import { useEffect, useState } from "react";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Radio from "antd/es/radio/index";
import Spin from "antd/es/spin/index";
import Card from "antd/es/card/index";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Common from "../../commons/common";

import moment from "moment";
import ReactApexChart from "react-apexcharts";
import styles from "./Stats.module.css";
import searchStyles from "../browser/Search.module.css";

type dataStatsType = {
    statsTime: string;
    count: number;
};

type runningStatsType = {
    statsTime: string;
    count: number;
    drivingTime: number;
};

type statsType = {
    dataStats?: Array<dataStatsType>;
    runningStats?: Array<runningStatsType>;
};

function StatsAccumulated({ period, stats, loading }: { period: (value: "YEAR" | "MONTH") => void; stats?: statsType; loading?: boolean }) {
    const hour = 3600;
    const [series, setSeries] = useState<TypeUtils.ChartData[]>([]);
    const [accmulatedPeriod, setAccmulatedPeriod] = useState<"YEAR" | "MONTH">("MONTH");
    const [option, setOption] = useState<Record<string, any> | undefined>();
    const [drivingTimeDisabled, setDrivingTimeDisabled] = useState<boolean>(false);

    const chartOptions = {
        colors: ["#9AC3FF", "var(--point)", "#002A6A", "#F63B3B", "#FAD200"],
        chart: {
            height: "350px",
            width: "1000px",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    pan: false,
                    zoomin: true,
                    zoomout: true,
                },
            },
            zoom: {
                enabled: true,
                type: "x",
                autoScaleYaxis: false,
                zoomedArea: {
                    fill: {
                        color: "#90CAF9",
                        opacity: 0.4,
                    },
                    stroke: {
                        color: "#0D47A1",
                        opacity: 0.4,
                        width: 1,
                    },
                },
            },
        },
        title: {
            text: "누적 데이터 추이",
            style: {
                fontFamily: "Pretendard-Bold",
                fontSize: "16px",
            },
        },
        xaxis: {
            type: "category",
        },
        showAlways: true,
        showForNullSeries: true,
        yaxis: [
            {
                opposite: true,
                labels: {
                    formatter: function (val: number) {
                        return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
            },
            {
                labels: {
                    formatter: function (val: number) {
                        return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                    style: { colors: "var(--point)" },
                },
            },
            {
                labels: {
                    formatter: function (val: number) {
                        return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    },
                },
                style: { colors: "#002A6A" },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val: number, { series, seriesIndex, dataPointIndex, w }: any) {
                    const unit = seriesIndex === 2 ? String.time : String.count;
                    return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + unit;
                },
            },
        },
        stroke: {
            width: 2,
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            fontFamily: "Pretendard-Regular",
            fontWeight: "normal",
            fontSize: "14px",
        },
    };

    useEffect(() => {
        period(accmulatedPeriod);
    }, []);

    useEffect(() => {
        setOption(chartOptions);
    }, [drivingTimeDisabled]);

    useEffect(() => {
        const dataCounts: TypeUtils.ChartElement[] = [];
        const drivingCounts: TypeUtils.ChartElement[] = [];
        const drivingTimes: TypeUtils.ChartElement[] = [];

        let timeFormat = Common.FORMAT_DATE;
        switch (accmulatedPeriod) {
            case "YEAR":
                timeFormat = Common.FORMAT_YEAR;
                break;
            case "MONTH":
                timeFormat = Common.FORMAT_MONTH;
                break;
            default:
                break;
        }

        stats?.dataStats?.forEach((value) => {
            const time = moment(value.statsTime, Common.FORMAT_DATE_TIME_MILLISECOND).format(timeFormat);
            dataCounts.push({
                x: time,
                y: value.count,
            });
        });

        stats?.runningStats?.forEach((value, index) => {
            const time = moment(value.statsTime, Common.FORMAT_DATE_TIME_MILLISECOND).format(timeFormat);

            if (index === 0 && dataCounts.length > 0) {
                const valueIndex = dataCounts.findIndex((dataCount) => dataCount.x === time);

                if (valueIndex !== 0) {
                    dataCounts.forEach((element, index) => {
                        if (index >= valueIndex) return false;

                        drivingCounts.push({
                            x: element.x,
                            y: 0,
                        });

                        drivingTimes.push({
                            x: element.x,
                            y: 0,
                        });
                    });
                }
            }

            drivingCounts.push({
                x: time,
                y: value.count,
            });

            drivingTimes.push({
                x: time,
                y: Math.floor(value.drivingTime / hour),
            });
        });

        stats !== undefined &&
            setSeries([
                {
                    name: String.carDataCount,
                    type: "line",
                    data: dataCounts,
                },
                {
                    name: String.drivingCount,
                    type: "line",
                    data: drivingCounts,
                },
                {
                    name: String.runningTime,
                    type: "line",
                    data: drivingTimes,
                },
            ]);

        option === undefined && setOption(chartOptions);
    }, [stats]);

    const onChangeEvent = (value: "YEAR" | "MONTH") => {
        setAccmulatedPeriod(value);
        period(value);
    };

    return (
        <>
            <div className={searchStyles.searchWrapper} style={{ marginBottom: 16 }}>
                <Row className={searchStyles.searchFormWrapper}>
                    <Col className={searchStyles.searchForm} span={24}>
                        <span className="fw-bd fs-md fc-font2">단위</span>
                        <div className={searchStyles.searchCol}>
                            <Radio.Group className={styles.checkbox} onChange={(e) => onChangeEvent(e.target.value)} defaultValue="MONTH">
                                <Radio value="YEAR">{String.periodYear}</Radio>
                                <Radio value="MONTH">{String.periodMonth}</Radio>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>
            </div>
            <Card className={styles.card}>
                {loading ? (
                    <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Spin />
                    </div>
                ) : (
                    option && (
                        <ReactApexChart
                            style={{ backgroundColor: "white", minheight: "350px", overflowX: "auto", overflowY: "hidden" }}
                            options={option}
                            series={series}
                            type={option.chart.type}
                            width="100%"
                            height={option.chart.height}
                        />
                    )
                )}
            </Card>
        </>
    );
}

export default StatsAccumulated;
