import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

import moment from "moment";

import * as CanDataYaxing from "../../commons/CanDataYaxing";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";

import CanDataWrapper, { CanTableType } from "./CanDataWrapper";
import Space from "antd/es/space/index";

function CanDataYaxingViewer({
    isMobile,
    loading,
    vehicleInfo,
    runningCanDataList,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    isMobile?: boolean;
    loading: boolean;
    vehicleInfo?: TypeDTO.CarDto | undefined;
    runningCanDataList?: Array<TypeDTO.CanData> | undefined;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [currentCanData, setCurrentCanData] = useState<CanDataYaxing.CanData>();
    const convertRemarkWithUnit = (value: number, unit: string) => {
        return value + unit;
    };

    //#region Battery Information
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryInformationPanelOpen, setBatteryInformationPanelOpen] = useState(false);
    const [batteryInformationChartMounted, setBatteryInformationChartMounted] = useState(false);
    const [selectedBatteryInformation, setSelectedBatteryInformation] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryInformation, setSeriesChartDatasBatteryInformation] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryInformationVoltage, setChartBatteryInformationVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_VOLTAGE_NAME,
        data: [],
    });

    const [chartBatteryInformationCurrent, setChartBatteryInformationCurrent] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_CURRENT_NAME,
        data: [],
    });

    const [chartBatteryInformationSoc, setChartBatteryInformationSoc] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_SOC_NAME,
        data: [],
    });

    const [chartBatteryInformationSoh, setChartBatteryInformationSoh] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_SOH_NAME,
        data: [],
    });

    const [chartBatteryInformationOutputStatus, setChartBatteryInformationOutputStatus] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_OUTPUT_STATUS_NAME,
        data: [],
    });

    const [chartBatteryInformationChargingConnectStatus, setChartBatteryInformationChargingConnectStatus] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_NAME,
        data: [],
    });

    const [chartBatteryInformationChargingStatus, setChartBatteryInformationChargingStatus] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_CHARGING_STATUS_NAME,
        data: [],
    });

    const [chartBatteryInformationErrorLevel, setChartBatteryInformationErrorLevel] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_ERROR_LEVEL_NAME,
        data: [],
    });

    const [chartBatteryInformationErrorCode, setChartBatteryInformationErrorCode] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_INFORMATION_ERROR_CODE_NAME,
        data: [],
    });

    const getTabelTooltipBatteryInformation = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_INFORMATION_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Battery Voltage
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_CURRENT_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Battery Current
                        </p>
                        <p>Remarks: A</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_SOC_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Battery SOC
                        </p>
                        <p>Remarks: %</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_SOH_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Battery SOH
                        </p>
                        <p>Remarks: %</p>
                    </>
                );

            case Common.BATTERY_INFORMATION_OUTPUT_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Battery Output Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-배터리 분리
                            <br />
                            01 - 예충폐중(배터리+단일배전함만 해당)
                            <br />
                            10 - 전원공급 완료(주접촉기 결합)
                            <br />
                            11-고장
                        </p>
                    </>
                );

            case Common.BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Charging Connect Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-연결되지 않음
                            <br />
                            10-연결됨
                        </p>
                    </>
                );

            case Common.BATTERY_INFORMATION_CHARGING_STATUS_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Charging Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-미충전, 01-충전중
                            <br />
                            10-충전 종료, 11-가열 중
                        </p>
                    </>
                );

            case Common.BATTERY_INFORMATION_ERROR_LEVEL_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Battery Error Level
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상
                            <br />
                            01-1급 고장(경고, 알람)
                            <br />
                            10-2차 고장(경미한 고장) 배터리 충방전 제한전력(정격전력 이하)
                            <br />
                            11-3단계 고장(심각한 고장) 배터리 충방전 금지
                        </p>
                    </>
                );

            case Common.BATTERY_INFORMATION_ERROR_CODE_KEY:
                return (
                    <>
                        <p>
                            Battery Information
                            <br />
                            CAN ID: 0x0C71D0D4
                            <br />
                            Name: Battery Error Code
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x00-정상
                            <br />
                            0x01-자체 테스트 고장
                            <br />
                            0x02- 고압 과전류 고장
                            <br />
                            0x03-배터리 냉각 시스템 고장
                            <br />
                            0x05- 저전압 전원 공급기가 너무 낮음
                            <br />
                            0x06- 저전압 전원 공급기가 너무 높음
                            <br />
                            0x07-SOC 과소고장
                            <br />
                            0x08-단체 전압이 너무 높음
                            <br />
                            0x09-단체 전압이 너무 낮음
                            <br />
                            0x0A-단량체 온도가 너무 높음
                            <br />
                            0x0B-단량체 온도가 너무 낮음
                            <br />
                            0x0C-절연 저항값이 너무 낮음
                            <br />
                            0x0D-통신 장애
                            <br />
                            0x12-배터리 셀 일관성 불량 고장
                            <br />
                            0x13-내부 고압 차단 고장
                            <br />
                            0x14-배터리 과방전 고장
                            <br />
                            0x15-전류 센서 고장
                            <br />
                            0x16-SOC 불안정 고장
                            <br />
                            0x17-배터리 난방 시스템 고장
                            <br />
                            0x18-온도차이가 크다
                            <br />
                            0x19-배터리 팩 발화
                            <br />
                            0x1A-충전 고장
                            <br />
                            0x1B-총 압력이 너무 높음
                            <br />
                            0x1C-총 압력이 너무 낮음
                            <br />
                            0x1D-시스템 일치 오류
                            <br />
                            0x1E-인터락 고장
                            <br />
                            0x1F-절연 검출기 고장
                            <br />
                            0x20-사전 충전 고장
                            <br />
                            0x21-메인 포지티브 릴레이 고장
                            <br />
                            0x22-메인 및 마이너스 릴레이 고장
                            <br />
                            0x23-충전 양 릴레이 고장
                            <br />
                            0x24-충전 음의 릴레이 고장
                            <br />
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertOutputStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "배터리 분리";
                break;
            case 1:
                valueString = "예충폐중";
                break;
            case 2:
                valueString = "전원공급 완료";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertChargingConnectStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "연결되지 않음";
                break;
            case 2:
                valueString = "연결됨";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertChargingStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "미충전";
                break;
            case 1:
                valueString = "충전중";
                break;
            case 2:
                valueString = "충전종료";
                break;
            case 3:
                valueString = "가열중";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertErrorLevelRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "1급고장";
                break;
            case 2:
                valueString = "2급고장";
                break;
            case 3:
                valueString = "3급고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertErrorCodeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 0x01:
                valueString = "자체 테스트 고장";
                break;
            case 0x02:
                valueString = "고압 과전류 고장";
                break;
            case 0x03:
                valueString = "배터리 냉각 시스템 고장";
                break;
            case 0x05:
                valueString = "저전압 전원 공급기가 너무 낮음";
                break;
            case 0x06:
                valueString = "저전압 전원 공급기가 너무 높음";
                break;
            case 0x07:
                valueString = "SOC 과소고장";
                break;
            case 0x08:
                valueString = "단체 전압이 너무 높음";
                break;
            case 0x09:
                valueString = "단체 전압이 너무 낮음";
                break;
            case 0x0a:
                valueString = "단량체 온도가 너무 높음";
                break;
            case 0x0b:
                valueString = "단량체 온도가 너무 낮음";
                break;
            case 0x0c:
                valueString = "절연 저항값이 너무 낮음";
                break;
            case 0x0d:
                valueString = "통신 장애";
                break;
            case 0x12:
                valueString = "배터리 셀 일관성 불량 고장";
                break;
            case 0x13:
                valueString = "내부 고압 차단 고장";
                break;
            case 0x14:
                valueString = "배터리 과방전 고장";
                break;
            case 0x15:
                valueString = "전류 센서 고장";
                break;
            case 0x16:
                valueString = "SOC 불안정 고장";
                break;
            case 0x17:
                valueString = "배터리 난방 시스템 고장";
                break;
            case 0x18:
                valueString = "온도차이가 크다";
                break;
            case 0x19:
                valueString = "배터리 팩 발화";
                break;
            case 0x1a:
                valueString = "충전 고장";
                break;
            case 0x1b:
                valueString = "총 압력이 너무 높음";
                break;
            case 0x1c:
                valueString = "총 압력이 너무 낮음";
                break;
            case 0x1d:
                valueString = "시스템 일치 오류";
                break;
            case 0x1e:
                valueString = "인터락 고장";
                break;
            case 0x1f:
                valueString = "절연 검출기 고장";
                break;
            case 0x20:
                valueString = "사전 충전 고장";
                break;
            case 0x21:
                valueString = "메인 포지티브 릴레이 고장";
                break;
            case 0x22:
                valueString = "메인 및 마이너스 릴레이 고장";
                break;
            case 0x23:
                valueString = "충전 양 릴레이 고장";
                break;
            case 0x24:
                valueString = "충전 음의 릴레이 고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBatteryInformationVoltageValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo?.volt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryInformationCurrentValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo?.curr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBatteryInformationSocValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo?.soc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatteryInformationSohValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_SOH_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batInfo?.soh, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatteryInformationOutputStatusValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_OUTPUT_STATUS_KEY) && currentCanData !== undefined) {
            return convertOutputStatusRemark(currentCanData.batInfo?.outputStatus);
        }
        return "";
    };

    const getBatteryInformationChargingConnectStatusValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_KEY) && currentCanData !== undefined) {
            return convertChargingConnectStatusRemark(currentCanData.batInfo?.chargeConnectStatus);
        }
        return "";
    };

    const getBatteryInformationChargingStatusValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_CHARGING_STATUS_KEY) && currentCanData !== undefined) {
            return convertChargingStatusRemark(currentCanData.batInfo?.chargeStatus);
        }
        return "";
    };

    const getBatteryInformationErrorLevelValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_ERROR_LEVEL_KEY) && currentCanData !== undefined) {
            return convertErrorLevelRemark(currentCanData.batInfo?.errLevel);
        }
        return "";
    };

    const getBatteryInformationErrorCodeValue = () => {
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_ERROR_CODE_KEY) && currentCanData !== undefined) {
            return convertErrorCodeRemark(currentCanData.batInfo?.errCode);
        }
        return "";
    };

    const onChangeBatteryInformation = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryInformation(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVcu: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_VOLTAGE_KEY)) chartDatasVcu.push(chartBatteryInformationVoltage);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_CURRENT_KEY)) chartDatasVcu.push(chartBatteryInformationCurrent);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_SOC_KEY)) chartDatasVcu.push(chartBatteryInformationSoc);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_SOH_KEY)) chartDatasVcu.push(chartBatteryInformationSoh);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_OUTPUT_STATUS_KEY))
            chartDatasVcu.push(chartBatteryInformationOutputStatus);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_KEY))
            chartDatasVcu.push(chartBatteryInformationChargingConnectStatus);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_CHARGING_STATUS_KEY))
            chartDatasVcu.push(chartBatteryInformationChargingStatus);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_ERROR_LEVEL_KEY)) chartDatasVcu.push(chartBatteryInformationErrorLevel);
        if (selectedBatteryInformation.includes(Common.BATTERY_INFORMATION_ERROR_CODE_KEY)) chartDatasVcu.push(chartBatteryInformationErrorCode);

        setSeriesChartDatasBatteryInformation(chartDatasVcu);
    }, [
        selectedBatteryInformation,
        chartBatteryInformationVoltage,
        chartBatteryInformationCurrent,
        chartBatteryInformationSoc,
        chartBatteryInformationSoh,
        chartBatteryInformationOutputStatus,
        chartBatteryInformationChargingConnectStatus,
        chartBatteryInformationChargingStatus,
        chartBatteryInformationErrorLevel,
        chartBatteryInformationErrorCode,
    ]);

    const tableDataBatteryInformation: CanTableType[] = [
        {
            key: Common.BATTERY_INFORMATION_VOLTAGE_KEY,
            name: Common.BATTERY_INFORMATION_VOLTAGE_NAME,
            value: getBatteryInformationVoltageValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_CURRENT_KEY,
            name: Common.BATTERY_INFORMATION_CURRENT_NAME,
            value: getBatteryInformationCurrentValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_SOC_KEY,
            name: Common.BATTERY_INFORMATION_SOC_NAME,
            value: getBatteryInformationSocValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_SOH_KEY,
            name: Common.BATTERY_INFORMATION_SOH_NAME,
            value: getBatteryInformationSohValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_OUTPUT_STATUS_KEY,
            name: Common.BATTERY_INFORMATION_OUTPUT_STATUS_NAME,
            value: getBatteryInformationOutputStatusValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_KEY,
            name: Common.BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_NAME,
            value: getBatteryInformationChargingConnectStatusValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_CHARGING_STATUS_KEY,
            name: Common.BATTERY_INFORMATION_CHARGING_STATUS_NAME,
            value: getBatteryInformationChargingStatusValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_ERROR_LEVEL_KEY,
            name: Common.BATTERY_INFORMATION_ERROR_LEVEL_NAME,
            value: getBatteryInformationErrorLevelValue(),
        },
        {
            key: Common.BATTERY_INFORMATION_ERROR_CODE_KEY,
            name: Common.BATTERY_INFORMATION_ERROR_CODE_NAME,
            value: getBatteryInformationErrorCodeValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Cell Voltage
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellVoltagePanelOpen, setCellVoltagePanelOpen] = useState(false);
    const [cellVoltageChartMounted, setCellVoltageChartMounted] = useState(false);
    const [selectedCellVoltage, setSelectedCellVoltage] = useState<Array<React.Key>>([]);

    const [seriesChartDatasCellVoltage, setSeriesChartDatasCellVoltage] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartCellVoltageBoxNumber, setChartCellVoltageBoxNumber] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_BOX_NUMBER_NAME,
        data: [],
    });

    const [chartCellVoltageMonomerStartingNumber, setChartCellVoltageMonomerStartingNumber] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_NUMBER_NAME,
        data: [],
    });

    const [chartCellVoltageCell1Voltage, setChartCellVoltageCell1Voltage] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_1_VOLTAGE_NAME,
        data: [],
    });

    const [chartCellVoltageCell2Voltage, setChartCellVoltageCell2Voltage] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_2_VOLTAGE_NAME,
        data: [],
    });

    const [chartCellVoltageCell3Voltage, setChartCellVoltageCell3Voltage] = useState<TypeUtils.ChartData>({
        name: Common.CELL_VOLTAGE_CELL_3_VOLTAGE_NAME,
        data: [],
    });

    const getTabelTooltipCellVoltage = (key: React.Key) => {
        switch (key) {
            case Common.CELL_VOLTAGE_BOX_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x1872D7D4
                            <br />
                            Name: Box No. of Cell Voltage
                        </p>
                        <p>
                            Remarks:
                            <br />
                            배터리 셀이 위치한 상자의 번호를 말하며,
                            <br />
                            시작 번호는 1입니다.
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x1872D7D4
                            <br />
                            Name: Cell No. of Cell Voltage
                        </p>
                        <p>
                            Remarks:
                            <br />
                            배터리 셀이 위치한 박스 내의 셀의 시작 번호를 말하며,
                            <br />
                            시작 번호는 0이고, 그 이후의 전압 데이터는 연속이다.
                            <br />
                            3개 셀의 전압값; 시작 번호가 4인 경우,
                            <br />
                            이후 전압 데이터는 4-6번 셀의 전압값입니다.
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_1_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x1872D7D4
                            <br />
                            Name: Cell1 Voltage
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작 번호에 표시된 첫 번째 단체 전압 값을 가리킵니다.
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_2_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x1872D7D4
                            <br />
                            Name: Cell2 Voltage
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작 번호에 표시된 두 번째 셀 전압 값을 가리킵니다.
                        </p>
                    </>
                );

            case Common.CELL_VOLTAGE_CELL_3_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Cell Voltage
                            <br />
                            CAN ID: 0x1872D7D4
                            <br />
                            Name: Cell3 Voltage
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작 번호에 표시된 세 번째 단체 전압 값을 가리킵니다.
                        </p>
                    </>
                );
        }

        return null;
    };

    const getCellVoltageBoxNumberValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_BOX_NUMBER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.cellVolt?.boxNo);
        }
        return "";
    };

    const getCellVoltageMonomerStartingNumberValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_NUMBER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.cellVolt?.cellNo);
        }
        return "";
    };

    const getCellVoltageCell1VoltageValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_1_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt?.cell1, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVoltageCell2VoltageValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_2_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt?.cell2, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getCellVoltageCell3VoltageValue = () => {
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_3_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellVolt?.cell3, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeCellVoltage = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellVoltage(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellVoltage: Array<TypeUtils.ChartData> = [];

        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_BOX_NUMBER_KEY)) chartDatasCellVoltage.push(chartCellVoltageBoxNumber);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_NUMBER_KEY)) chartDatasCellVoltage.push(chartCellVoltageMonomerStartingNumber);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_1_VOLTAGE_KEY)) chartDatasCellVoltage.push(chartCellVoltageCell1Voltage);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_2_VOLTAGE_KEY)) chartDatasCellVoltage.push(chartCellVoltageCell2Voltage);
        if (selectedCellVoltage.includes(Common.CELL_VOLTAGE_CELL_3_VOLTAGE_KEY)) chartDatasCellVoltage.push(chartCellVoltageCell3Voltage);

        setSeriesChartDatasCellVoltage(chartDatasCellVoltage);
    }, [
        selectedCellVoltage,
        chartCellVoltageBoxNumber,
        chartCellVoltageMonomerStartingNumber,
        chartCellVoltageCell1Voltage,
        chartCellVoltageCell2Voltage,
        chartCellVoltageCell3Voltage,
    ]);

    const tableDataCellVoltage: CanTableType[] = [
        {
            key: Common.CELL_VOLTAGE_BOX_NUMBER_KEY,
            name: Common.CELL_VOLTAGE_BOX_NUMBER_NAME,
            value: getCellVoltageBoxNumberValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_NUMBER_KEY,
            name: Common.CELL_VOLTAGE_CELL_NUMBER_NAME,
            value: getCellVoltageMonomerStartingNumberValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_1_VOLTAGE_KEY,
            name: Common.CELL_VOLTAGE_CELL_1_VOLTAGE_NAME,
            value: getCellVoltageCell1VoltageValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_2_VOLTAGE_KEY,
            name: Common.CELL_VOLTAGE_CELL_2_VOLTAGE_NAME,
            value: getCellVoltageCell2VoltageValue(),
        },
        {
            key: Common.CELL_VOLTAGE_CELL_3_VOLTAGE_KEY,
            name: Common.CELL_VOLTAGE_CELL_3_VOLTAGE_NAME,
            value: getCellVoltageCell3VoltageValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Cell Temperature
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [cellTemperaturePanelOpen, setCellTemperaturePanelOpen] = useState(false);
    const [cellTemperatureChartMounted, setCellTemperatureChartMounted] = useState(false);
    const [selectedCellTemperature, setSelectedCellTemperature] = useState<Array<React.Key>>([]);

    const [seriesChartDatasCellTemperature, setSeriesChartDatasCellTemperature] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartCellTemperatureBoxNumber, setChartCellTemperatureBoxNumber] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_BOX_NUMBER_NAME,
        data: [],
    });

    const [chartCellTemperatureCellNumber, setChartCellTemperatureCellNumber] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_CELL_NUMBER_NAME,
        data: [],
    });

    const [chartCellTemperatureCell1Temperature, setChartCellTemperatureCell1Temperature] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_CELL_1_TEMPERATURE_NAME,
        data: [],
    });

    const [chartCellTemperatureCell2Temperature, setChartCellTemperatureCell2Temperature] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_CELL_2_TEMPERATURE_NAME,
        data: [],
    });

    const [chartCellTemperatureCell3Temperature, setChartCellTemperatureCell3Temperature] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_CELL_3_TEMPERATURE_NAME,
        data: [],
    });

    const [chartCellTemperatureCell4Temperature, setChartCellTemperatureCell4Temperature] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_CELL_4_TEMPERATURE_NAME,
        data: [],
    });

    const [chartCellTemperatureCell5Temperature, setChartCellTemperatureCell5Temperature] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_CELL_5_TEMPERATURE_NAME,
        data: [],
    });

    const [chartCellTemperatureCell6Temperature, setChartCellTemperatureCell6Temperature] = useState<TypeUtils.ChartData>({
        name: Common.CELL_TEMPERATURE_CELL_6_TEMPERATURE_NAME,
        data: [],
    });

    const getTabelTooltipCellTemperature = (key: React.Key) => {
        switch (key) {
            case Common.CELL_TEMPERATURE_BOX_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Box No. of Cell Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            배터리 온도 모니터링 지점의 박스 번호를
                            <br />
                            말하며, 시작 번호는 1입니다.
                        </p>
                    </>
                );

            case Common.CELL_TEMPERATURE_CELL_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Cell No. of Cell Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            배터리 온도 모니터링 지점이 있는 상자
                            <br />
                            안의 모니터링 지점의 시작 번호를 말하며,
                            <br />
                            시작 번호는 0이며, 그 이후의 온도 수는
                            <br />
                            6개의 연속 측정 지점의 온도 값에 따르면,
                            <br />
                            시작 번호가 7인 경우 그 이후의 온도
                            <br />
                            데이터는 7-12 모니터링입니다.
                        </p>
                    </>
                );

            case Common.CELL_TEMPERATURE_CELL_1_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Cell1 Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작번호에 표시된 첫 번째 온도
                            <br />
                            측정 지점의 온도 값을 가리킨다.
                        </p>
                    </>
                );

            case Common.CELL_TEMPERATURE_CELL_2_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Cell2 Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작번호에 표시된 2번째 온도
                            <br />
                            측정 지점의 온도 값을 가리킨다.
                        </p>
                    </>
                );

            case Common.CELL_TEMPERATURE_CELL_3_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Cell3 Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작번호에 표시된 3번째 온도
                            <br />
                            측정 지점의 온도 값을 가리킨다.
                        </p>
                    </>
                );

            case Common.CELL_TEMPERATURE_CELL_4_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Cell4 Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작번호에 표시된 4번째 온도
                            <br />
                            측정 지점의 온도 값을 가리킨다.
                        </p>
                    </>
                );

            case Common.CELL_TEMPERATURE_CELL_5_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Cell5 Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작번호에 표시된 5번째 온도
                            <br />
                            측정 지점의 온도 값을 가리킨다.
                        </p>
                    </>
                );

            case Common.CELL_TEMPERATURE_CELL_6_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Cell Temperature
                            <br />
                            CAN ID: 0x1873D7D4
                            <br />
                            Name: Cell6 Temperature
                        </p>
                        <p>
                            Remarks:
                            <br />
                            시작번호에 표시된 6번째 온도
                            <br />
                            측정 지점의 온도 값을 가리킨다.
                        </p>
                    </>
                );
        }

        return null;
    };

    const getCellTemperatureBoxNumberValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_VOLTAGE_BOX_NUMBER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.cellTemp?.boxNo);
        }
        return "";
    };

    const getCellTemperatureCellNumberValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_NUMBER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.cellTemp?.cellNo);
        }
        return "";
    };

    const getCellTemperatureCell1TemperatureValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_1_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellTemp?.cell1, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getCellTemperatureCell2TemperatureValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_2_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellTemp?.cell2, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getCellTemperatureCell3TemperatureValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_3_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellTemp?.cell3, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getCellTemperatureCell4TemperatureValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_4_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellTemp?.cell4, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getCellTemperatureCell5TemperatureValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_5_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellTemp?.cell5, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getCellTemperatureCell6TemperatureValue = () => {
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_6_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.cellTemp?.cell6, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const onChangeCellTemperature = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedCellTemperature(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasCellTemperature: Array<TypeUtils.ChartData> = [];

        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_BOX_NUMBER_KEY)) chartDatasCellTemperature.push(chartCellTemperatureBoxNumber);
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_NUMBER_KEY)) chartDatasCellTemperature.push(chartCellTemperatureCellNumber);
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_1_TEMPERATURE_KEY))
            chartDatasCellTemperature.push(chartCellTemperatureCell1Temperature);
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_2_TEMPERATURE_KEY))
            chartDatasCellTemperature.push(chartCellTemperatureCell2Temperature);
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_3_TEMPERATURE_KEY))
            chartDatasCellTemperature.push(chartCellTemperatureCell3Temperature);
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_4_TEMPERATURE_KEY))
            chartDatasCellTemperature.push(chartCellTemperatureCell4Temperature);
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_5_TEMPERATURE_KEY))
            chartDatasCellTemperature.push(chartCellTemperatureCell5Temperature);
        if (selectedCellTemperature.includes(Common.CELL_TEMPERATURE_CELL_6_TEMPERATURE_KEY))
            chartDatasCellTemperature.push(chartCellTemperatureCell6Temperature);

        setSeriesChartDatasCellTemperature(chartDatasCellTemperature);
    }, [
        selectedCellTemperature,
        chartCellTemperatureBoxNumber,
        chartCellTemperatureCellNumber,
        chartCellTemperatureCell1Temperature,
        chartCellTemperatureCell2Temperature,
        chartCellTemperatureCell3Temperature,
        chartCellTemperatureCell4Temperature,
        chartCellTemperatureCell5Temperature,
        chartCellTemperatureCell6Temperature,
    ]);

    const tableDataCellTemperature: CanTableType[] = [
        {
            key: Common.CELL_TEMPERATURE_BOX_NUMBER_KEY,
            name: Common.CELL_TEMPERATURE_BOX_NUMBER_NAME,
            value: getCellTemperatureBoxNumberValue(),
        },
        {
            key: Common.CELL_TEMPERATURE_CELL_NUMBER_KEY,
            name: Common.CELL_TEMPERATURE_CELL_NUMBER_NAME,
            value: getCellTemperatureCellNumberValue(),
        },
        {
            key: Common.CELL_TEMPERATURE_CELL_1_TEMPERATURE_KEY,
            name: Common.CELL_TEMPERATURE_CELL_1_TEMPERATURE_NAME,
            value: getCellTemperatureCell1TemperatureValue(),
        },
        {
            key: Common.CELL_TEMPERATURE_CELL_2_TEMPERATURE_KEY,
            name: Common.CELL_TEMPERATURE_CELL_2_TEMPERATURE_NAME,
            value: getCellTemperatureCell2TemperatureValue(),
        },
        {
            key: Common.CELL_TEMPERATURE_CELL_3_TEMPERATURE_KEY,
            name: Common.CELL_TEMPERATURE_CELL_3_TEMPERATURE_NAME,
            value: getCellTemperatureCell3TemperatureValue(),
        },
        {
            key: Common.CELL_TEMPERATURE_CELL_4_TEMPERATURE_KEY,
            name: Common.CELL_TEMPERATURE_CELL_4_TEMPERATURE_NAME,
            value: getCellTemperatureCell4TemperatureValue(),
        },
        {
            key: Common.CELL_TEMPERATURE_CELL_5_TEMPERATURE_KEY,
            name: Common.CELL_TEMPERATURE_CELL_5_TEMPERATURE_NAME,
            value: getCellTemperatureCell5TemperatureValue(),
        },
        {
            key: Common.CELL_TEMPERATURE_CELL_6_TEMPERATURE_KEY,
            name: Common.CELL_TEMPERATURE_CELL_6_TEMPERATURE_NAME,
            value: getCellTemperatureCell6TemperatureValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Highest and Lowest Cell Voltage
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [highestLowestCellVoltagePanelOpen, setHighestLowestCellVoltagePanelOpen] = useState(false);
    const [highestLowestCellVoltageChartMounted, setHighestLowestCellVoltageChartMounted] = useState(false);
    const [selectedHighestLowestCellVoltage, setSelectedHighestLowestCellVoltage] = useState<Array<React.Key>>([]);

    const [seriesChartDatasHighestLowestCellVoltage, setSeriesChartDatasHighestLowestCellVoltage] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartHighestLowestCellVoltageHighestValue, setChartHighestLowestCellVoltageHighestValue] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_NAME,
        data: [],
    });

    const [chartHighestLowestCellVoltageCellNumberWithHV, setChartHighestLowestCellVoltageCellNumberWithHV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_NAME,
        data: [],
    });

    const [chartHighestLowestCellVoltageBoxNumberWithHV, setChartHighestLowestCellVoltageBoxNumberWithHV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_NAME,
        data: [],
    });

    const [chartHighestLowestCellVoltageLowestValue, setChartHighestLowestCellVoltageLowestValue] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_NAME,
        data: [],
    });

    const [chartHighestLowestCellVoltageCellNumberWithLV, setChartHighestLowestCellVoltageCellNumberWithLV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_NAME,
        data: [],
    });

    const [chartHighestLowestCellVoltageBoxNumberWithLV, setChartHighestLowestCellVoltageBoxNumberWithLV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_NAME,
        data: [],
    });

    const getTabelTooltipHighestLowestCellVoltage = (key: React.Key) => {
        switch (key) {
            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Voltage
                            <br />
                            CAN ID: 0x1874D0D4
                            <br />
                            Name: Highest Cell Voltage
                        </p>
                        <p>Remarks: V</p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Voltage
                            <br />
                            CAN ID: 0x1874D0D4
                            <br />
                            Name: Cell No. of Highest Cell Voltage
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Voltage
                            <br />
                            CAN ID: 0x1874D0D4
                            <br />
                            Name: Cell No. of Highest Box Voltage
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Voltage
                            <br />
                            CAN ID: 0x1874D0D4
                            <br />
                            Name: Lowest Cell Voltage
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Voltage
                            <br />
                            CAN ID: 0x1874D0D4
                            <br />
                            Name: Cell No. of Lowest Cell Voltage
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Voltage
                            <br />
                            CAN ID: 0x1874D0D4
                            <br />
                            Name: Cell No. of Lowest Box Voltage
                        </p>
                    </>
                );
        }

        return null;
    };

    const getHighestLowestCellVoltageHighestValueValue = () => {
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.highLowCellVolt?.highest, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getHighestLowestCellVoltageCellNumberWithHVValue = () => {
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_KEY) && currentCanData !== undefined) {
            return String(currentCanData.highLowCellVolt?.highestCellNo);
        }
        return "";
    };

    const getHighestLowestCellVoltageBoxNumberWithHVValue = () => {
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_KEY) && currentCanData !== undefined) {
            return String(currentCanData.highLowCellVolt?.highestBoxNo);
        }
        return "";
    };

    const getHighestLowestCellVoltageLowestValueValue = () => {
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.highLowCellVolt?.lowest, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getHighestLowestCellVoltageCellNumberWithLVValue = () => {
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_KEY) && currentCanData !== undefined) {
            return String(currentCanData.highLowCellVolt?.lowestCellNo);
        }
        return "";
    };

    const getHighestLowestCellVoltageBoxNumberWithLVValue = () => {
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_KEY) && currentCanData !== undefined) {
            return String(currentCanData.highLowCellVolt?.lowestBoxNo);
        }
        return "";
    };

    const onChangeHighestLowestCellVoltage = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedHighestLowestCellVoltage(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasHighestLowestCellVoltage: Array<TypeUtils.ChartData> = [];

        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_KEY))
            chartDatasHighestLowestCellVoltage.push(chartHighestLowestCellVoltageHighestValue);
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_KEY))
            chartDatasHighestLowestCellVoltage.push(chartHighestLowestCellVoltageCellNumberWithHV);
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_KEY))
            chartDatasHighestLowestCellVoltage.push(chartHighestLowestCellVoltageBoxNumberWithHV);
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_KEY))
            chartDatasHighestLowestCellVoltage.push(chartHighestLowestCellVoltageLowestValue);
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_KEY))
            chartDatasHighestLowestCellVoltage.push(chartHighestLowestCellVoltageCellNumberWithLV);
        if (selectedHighestLowestCellVoltage.includes(Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_KEY))
            chartDatasHighestLowestCellVoltage.push(chartHighestLowestCellVoltageBoxNumberWithLV);

        setSeriesChartDatasHighestLowestCellVoltage(chartDatasHighestLowestCellVoltage);
    }, [
        selectedHighestLowestCellVoltage,
        chartHighestLowestCellVoltageHighestValue,
        chartHighestLowestCellVoltageCellNumberWithHV,
        chartHighestLowestCellVoltageBoxNumberWithHV,
        chartHighestLowestCellVoltageLowestValue,
        chartHighestLowestCellVoltageCellNumberWithLV,
        chartHighestLowestCellVoltageBoxNumberWithLV,
    ]);

    const tableDataHighestLowestCellVoltage: CanTableType[] = [
        {
            key: Common.HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_KEY,
            name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_NAME,
            value: getHighestLowestCellVoltageHighestValueValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_HV_NAME,
            value: getHighestLowestCellVoltageCellNumberWithHVValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_HV_NAME,
            value: getHighestLowestCellVoltageBoxNumberWithHVValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_KEY,
            name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_NAME,
            value: getHighestLowestCellVoltageLowestValueValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_CELL_NUMBER_WITH_LV_NAME,
            value: getHighestLowestCellVoltageCellNumberWithLVValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_VOLTAGE_BOX_NUMBER_WITH_LV_NAME,
            value: getHighestLowestCellVoltageBoxNumberWithLVValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Highest and Lowest Cell Temperature
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [highestLowestCellTemperaturePanelOpen, setHighestLowestCellTemperaturePanelOpen] = useState(false);
    const [highestLowestCellTemperatureChartMounted, setHighestLowestCellTemperatureChartMounted] = useState(false);
    const [selectedHighestLowestCellTemperature, setSelectedHighestLowestCellTemperature] = useState<Array<React.Key>>([]);

    const [seriesChartDatasHighestLowestCellTemperature, setSeriesChartDatasHighestLowestCellTemperature] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartHighestLowestCellTemperatureHighestValue, setChartHighestLowestCellTemperatureHighestValue] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_NAME,
        data: [],
    });

    const [chartHighestLowestCellTemperatureCellNumberWithHV, setChartHighestLowestCellTemperatureCellNumberWithHV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_NAME,
        data: [],
    });

    const [chartHighestLowestCellTemperatureBoxNumberWithHV, setChartHighestLowestCellTemperatureBoxNumberWithHV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_NAME,
        data: [],
    });

    const [chartHighestLowestCellTemperatureLowestValue, setChartHighestLowestCellTemperatureLowestValue] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_NAME,
        data: [],
    });

    const [chartHighestLowestCellTemperatureCellNumberWithLV, setChartHighestLowestCellTemperatureCellNumberWithLV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_NAME,
        data: [],
    });

    const [chartHighestLowestCellTemperatureBoxNumberWithLV, setChartHighestLowestCellTemperatureBoxNumberWithLV] = useState<TypeUtils.ChartData>({
        name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_NAME,
        data: [],
    });

    // const [chartHighestLowestCellTemperatureHighestPoleTemperature, setChartHighestLowestCellTemperatureHighestPoleTemperature] =
    //     useState<TypeUtils.ChartData>({
    //         name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_POLE_TEMPERATURE_NAME,
    //         data: [],
    //     });

    // const [chartHighestLowestCellTemperatureLocation, setChartHighestLowestCellTemperatureLocation] = useState<TypeUtils.ChartData>({
    //     name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOCATION_NAME,
    //     data: [],
    // });

    const getTabelTooltipHighestLowestCellTemperature = (key: React.Key) => {
        switch (key) {
            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Temperature
                            <br />
                            CAN ID: 0x1875D0D4
                            <br />
                            Name: Highest Cell Temperature
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Temperature
                            <br />
                            CAN ID: 0x1875D0D4
                            <br />
                            Name: Cell No. of Highest Cell Temperature
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Temperature
                            <br />
                            CAN ID: 0x1875D0D4
                            <br />
                            Name: Cell No. of Highest Box Temperature
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Temperature
                            <br />
                            CAN ID: 0x1875D0D4
                            <br />
                            Name: Lowest Cell Temperature
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Temperature
                            <br />
                            CAN ID: 0x1875D0D4
                            <br />
                            Name: Cell No. of Lowest Cell Temperature
                        </p>
                    </>
                );

            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_KEY:
                return (
                    <>
                        <p>
                            Highest and Lowest Cell Temperature
                            <br />
                            CAN ID: 0x1875D0D4
                            <br />
                            Name: Cell No. of Lowest Box Temperature
                        </p>
                    </>
                );

            // case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_POLE_TEMPERATURE_KEY:
            //     return (
            //         <>
            //             <p>
            //                 Highest and Lowest Cell Temperature
            //                 <br />
            //                 CAN ID: 0x1875D0D4
            //                 <br />
            //                 Name: Highest Pole Temperature
            //             </p>
            //         </>
            //     );

            // case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOCATION_KEY:
            //     return (
            //         <>
            //             <p>
            //                 Highest and Lowest Cell Temperature
            //                 <br />
            //                 CAN ID: 0x1875D0D4
            //                 <br />
            //                 Name: Location for Highest Pole Temperature
            //             </p>
            //         </>
            //     );
        }

        return null;
    };

    const getHighestLowestCellTemperatureHighestValueValue = () => {
        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.highLowCellTemp?.highest, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getHighestLowestCellTemperatureCellNumberWithHVValue = () => {
        if (
            selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_KEY) &&
            currentCanData !== undefined
        ) {
            return String(currentCanData.highLowCellTemp?.highestCellNo);
        }
        return "";
    };

    const getHighestLowestCellTemperatureBoxNumberWithHVValue = () => {
        if (
            selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_KEY) &&
            currentCanData !== undefined
        ) {
            return String(currentCanData.highLowCellTemp?.highestBoxNo);
        }
        return "";
    };

    const getHighestLowestCellTemperatureLowestValueValue = () => {
        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.highLowCellTemp?.lowest, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getHighestLowestCellTemperatureCellNumberWithLVValue = () => {
        if (
            selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_KEY) &&
            currentCanData !== undefined
        ) {
            return String(currentCanData.highLowCellTemp?.lowestCellNo);
        }
        return "";
    };

    const getHighestLowestCellTemperatureBoxNumberWithLVValue = () => {
        if (
            selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_KEY) &&
            currentCanData !== undefined
        ) {
            return String(currentCanData.highLowCellTemp?.lowestBoxNo);
        }
        return "";
    };

    // const getHighestLowestCellTemperatureHighestPoleTemperatureValue = () => {
    //     if (
    //         selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_POLE_TEMPERATURE_KEY) &&
    //         currentCanData !== undefined
    //     ) {
    //         return convertRemarkWithUnit(currentCanData.highLowCellTemp?.highestPoleTemperature, Common.UNIT_CELSIUS);
    //     }
    //     return "";
    // };

    // const getHighestLowestCellTemperatureLocationValue = () => {
    //     if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOCATION_KEY) && currentCanData !== undefined) {
    //         return String(currentCanData.highLowCellTemp?.location);
    //     }
    //     return "";
    // };

    const onChangeHighestLowestCellTemperature = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedHighestLowestCellTemperature(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasHighestLowestCellTemperature: Array<TypeUtils.ChartData> = [];

        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_KEY))
            chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureHighestValue);
        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_KEY))
            chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureCellNumberWithHV);
        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_KEY))
            chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureBoxNumberWithHV);
        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_KEY))
            chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureLowestValue);
        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_KEY))
            chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureCellNumberWithLV);
        if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_KEY))
            chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureBoxNumberWithLV);
        // if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_POLE_TEMPERATURE_KEY))
        //     chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureHighestPoleTemperature);
        // if (selectedHighestLowestCellTemperature.includes(Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOCATION_KEY))
        //     chartDatasHighestLowestCellTemperature.push(chartHighestLowestCellTemperatureLocation);

        setSeriesChartDatasHighestLowestCellTemperature(chartDatasHighestLowestCellTemperature);
    }, [
        selectedHighestLowestCellTemperature,
        chartHighestLowestCellTemperatureHighestValue,
        chartHighestLowestCellTemperatureCellNumberWithHV,
        chartHighestLowestCellTemperatureBoxNumberWithHV,
        chartHighestLowestCellTemperatureLowestValue,
        chartHighestLowestCellTemperatureCellNumberWithLV,
        chartHighestLowestCellTemperatureBoxNumberWithLV,
        // chartHighestLowestCellTemperatureHighestPoleTemperature,
        // chartHighestLowestCellTemperatureLocation,
    ]);

    const tableDataHighestLowestCellTemperature: CanTableType[] = [
        {
            key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_KEY,
            name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_NAME,
            value: getHighestLowestCellTemperatureHighestValueValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_HV_NAME,
            value: getHighestLowestCellTemperatureCellNumberWithHVValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_HV_NAME,
            value: getHighestLowestCellTemperatureBoxNumberWithHVValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_KEY,
            name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_NAME,
            value: getHighestLowestCellTemperatureLowestValueValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_CELL_NUMBER_WITH_LV_NAME,
            value: getHighestLowestCellTemperatureCellNumberWithLVValue(),
        },
        {
            key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_KEY,
            name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_BOX_NUMBER_WITH_LV_NAME,
            value: getHighestLowestCellTemperatureBoxNumberWithLVValue(),
        },
        // {
        //     key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_POLE_TEMPERATURE_KEY,
        //     name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_POLE_TEMPERATURE_NAME,
        //     value: getHighestLowestCellTemperatureBoxNumberWithLVValue(),
        // },
        // {
        //     key: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOCATION_KEY,
        //     name: Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOCATION_NAME,
        //     value: getHighestLowestCellTemperatureBoxNumberWithLVValue(),
        // },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Limit1
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryLimit1PanelOpen, setBatteryLimit1PanelOpen] = useState(false);
    const [batteryLimit1ChartMounted, setBatteryLimit1ChartMounted] = useState(false);
    const [selectedBatteryLimit1, setSelectedBatteryLimit1] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryLimit1, setSeriesChartDatasBatteryLimit1] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryLimit1MaximumCellVoltage, setChartBatteryLimit1MaximumCellVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_NAME,
        data: [],
    });

    const [chartBatteryLimit1MinimumCellVoltage, setChartBatteryLimit1MinimumCellVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_NAME,
        data: [],
    });

    const [chartBatteryLimit1MaximumSoc, setChartBatteryLimit1MaximumSoc] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_1_MAXIMUM_SOC_NAME,
        data: [],
    });

    const [chartBatteryLimit1MinimumSoc, setChartBatteryLimit1MinimumSoc] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_1_MINIMUM_SOC_NAME,
        data: [],
    });

    const [chartBatteryLimit1MaximumCellTemperature, setChartBatteryLimit1MaximumCellTemperature] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_NAME,
        data: [],
    });

    const [chartBatteryLimit1MinimumCellTemperature, setChartBatteryLimit1MinimumCellTemperature] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_NAME,
        data: [],
    });

    const getTabelTooltipBatteryLimit1 = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 1
                            <br />
                            CAN ID: 0x1876D0D4
                            <br />
                            Name: Maximum of Cell Voltage
                        </p>
                    </>
                );
            case Common.BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 1
                            <br />
                            CAN ID: 0x1876D0D4
                            <br />
                            Name: Minimum of Cell Voltage
                        </p>
                    </>
                );
            case Common.BATTERY_LIMIT_1_MAXIMUM_SOC_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 1
                            <br />
                            CAN ID: 0x1876D0D4
                            <br />
                            Name: Maximum of SOC
                        </p>
                    </>
                );

            case Common.BATTERY_LIMIT_1_MINIMUM_SOC_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 1
                            <br />
                            CAN ID: 0x1876D0D4
                            <br />
                            Name: Minimum of SOC
                        </p>
                    </>
                );

            case Common.BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 1
                            <br />
                            CAN ID: 0x1876D0D4
                            <br />
                            Name: Maximum of Cell Temperature
                        </p>
                    </>
                );

            case Common.BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 1
                            <br />
                            CAN ID: 0x1876D0D4
                            <br />
                            Name: Minimum of Cell Temperature
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryLimit1MaximumCellVoltageValue = () => {
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit1?.maxCellVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryLimit1MinimumCellVoltageValue = () => {
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit1?.minCellVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryLimit1MaximumSocValue = () => {
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MAXIMUM_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit1?.maxSoc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatteryLimit1MinimumSocValue = () => {
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MINIMUM_SOC_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit1?.minSoc, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getBatteryLimit1MaximumCellTemperatureValue = () => {
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit1?.maxCellTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getBatteryLimit1MinimumCellTemperatureValue = () => {
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit1?.minCellTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const onChangeBatteryLimit1 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryLimit1(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryLimit1: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_KEY))
            chartDatasBatteryLimit1.push(chartBatteryLimit1MaximumCellVoltage);
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_KEY))
            chartDatasBatteryLimit1.push(chartBatteryLimit1MinimumCellVoltage);
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MAXIMUM_SOC_KEY)) chartDatasBatteryLimit1.push(chartBatteryLimit1MaximumSoc);
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MINIMUM_SOC_KEY)) chartDatasBatteryLimit1.push(chartBatteryLimit1MinimumSoc);
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_KEY))
            chartDatasBatteryLimit1.push(chartBatteryLimit1MaximumCellTemperature);
        if (selectedBatteryLimit1.includes(Common.BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_KEY))
            chartDatasBatteryLimit1.push(chartBatteryLimit1MinimumCellTemperature);

        setSeriesChartDatasBatteryLimit1(chartDatasBatteryLimit1);
    }, [
        selectedBatteryLimit1,
        chartBatteryLimit1MaximumCellVoltage,
        chartBatteryLimit1MinimumCellVoltage,
        chartBatteryLimit1MaximumSoc,
        chartBatteryLimit1MinimumSoc,
        chartBatteryLimit1MaximumCellTemperature,
        chartBatteryLimit1MinimumCellTemperature,
    ]);

    const tableDataBatteryLimit1: CanTableType[] = [
        {
            key: Common.BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_KEY,
            name: Common.BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_NAME,
            value: getBatteryLimit1MaximumCellVoltageValue(),
        },
        {
            key: Common.BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_KEY,
            name: Common.BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_NAME,
            value: getBatteryLimit1MinimumCellVoltageValue(),
        },
        {
            key: Common.BATTERY_LIMIT_1_MAXIMUM_SOC_KEY,
            name: Common.BATTERY_LIMIT_1_MAXIMUM_SOC_NAME,
            value: getBatteryLimit1MaximumSocValue(),
        },
        {
            key: Common.BATTERY_LIMIT_1_MINIMUM_SOC_KEY,
            name: Common.BATTERY_LIMIT_1_MINIMUM_SOC_NAME,
            value: getBatteryLimit1MinimumSocValue(),
        },
        {
            key: Common.BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_KEY,
            name: Common.BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_NAME,
            value: getBatteryLimit1MaximumCellTemperatureValue(),
        },
        {
            key: Common.BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_KEY,
            name: Common.BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_NAME,
            value: getBatteryLimit1MinimumCellTemperatureValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Limit2
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryLimit2PanelOpen, setBatteryLimit2PanelOpen] = useState(false);
    const [batteryLimit2ChartMounted, setBatteryLimit2ChartMounted] = useState(false);
    const [selectedBatteryLimit2, setSelectedBatteryLimit2] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryLimit2, setSeriesChartDatasBatteryLimit2] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryLimit2MaximumChargingCurrent, setChartBatteryLimit2MaximumChargingCurrent] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_NAME,
        data: [],
    });

    const [chartBatteryLimit2MaximumDischargingCurrent, setChartBatteryLimit2MaximumDischargingCurrent] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_NAME,
        data: [],
    });

    const [chartBatteryLimit2MaximumChargingVoltage, setChartBatteryLimit2MaximumChargingVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_NAME,
        data: [],
    });

    const [chartBatteryLimit2MinimumDischargingVoltage, setChartBatteryLimit2MinimumDischargingVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_NAME,
        data: [],
    });

    const getTabelTooltipBatteryLimit2 = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 2
                            <br />
                            CAN ID: 0x1877D0D4
                            <br />
                            Name: Maximum Charging Current
                        </p>
                        <p>
                            Remarks:
                            <br />
                            양의 값이며, VCU에서 전체 차량의
                            <br />
                            최대 사용 전력 한계치를 계산하는 데
                            <br />
                            사용됩니다
                            <br />
                            (고장 보고를 피하기 위해 배터리
                            <br />
                            공장에서 부분 보류를 권장합니다).
                        </p>
                    </>
                );

            case Common.BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 2
                            <br />
                            CAN ID: 0x1877D0D4
                            <br />
                            Name: Maximum Discharging Current
                        </p>
                        <p>
                            Remarks:
                            <br />
                            양의 값이며, VCU에서 차량 전체의
                            <br />
                            최대 회수 전력 한계치를 계산하는 데
                            <br />
                            사용됩니다
                            <br />
                            (고장 보고를 피하기 위해 배터리
                            <br />
                            공장에서 부분 보존을 권장합니다).
                        </p>
                    </>
                );

            case Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 2
                            <br />
                            CAN ID: 0x1877D0D4
                            <br />
                            Name: Maximum Charging Voltage
                        </p>
                    </>
                );

            case Common.BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Battery Limit 2
                            <br />
                            CAN ID: 0x1877D0D4
                            <br />
                            Name: Minimum Discharging Voltage
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatteryLimit2MaximumChargingCurrentValue = () => {
        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit2?.maxChargeCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBatteryLimit2MaximumDischargingCurrentValue = () => {
        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit2?.maxDischargeCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getBatteryLimit2MaximumChargingVoltageValue = () => {
        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit2?.maxChargeVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getBatteryLimit2MinimumDischargingVoltageValue = () => {
        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batLimit2?.minDischargeVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeBatteryLimit2 = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryLimit2(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryLimit2: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_KEY))
            chartDatasBatteryLimit2.push(chartBatteryLimit2MaximumChargingCurrent);
        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_KEY))
            chartDatasBatteryLimit2.push(chartBatteryLimit2MaximumDischargingCurrent);
        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_KEY))
            chartDatasBatteryLimit2.push(chartBatteryLimit2MaximumChargingVoltage);
        if (selectedBatteryLimit2.includes(Common.BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_KEY))
            chartDatasBatteryLimit2.push(chartBatteryLimit2MinimumDischargingVoltage);

        setSeriesChartDatasBatteryLimit2(chartDatasBatteryLimit2);
    }, [
        selectedBatteryLimit2,
        chartBatteryLimit2MaximumChargingCurrent,
        chartBatteryLimit2MaximumDischargingCurrent,
        chartBatteryLimit2MaximumChargingVoltage,
        chartBatteryLimit2MinimumDischargingVoltage,
    ]);

    const tableDataBatteryLimit2: CanTableType[] = [
        {
            key: Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_KEY,
            name: Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_NAME,
            value: getBatteryLimit2MaximumChargingCurrentValue(),
        },
        {
            key: Common.BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_KEY,
            name: Common.BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_NAME,
            value: getBatteryLimit2MaximumDischargingCurrentValue(),
        },
        {
            key: Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_KEY,
            name: Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_NAME,
            value: getBatteryLimit2MaximumChargingVoltageValue(),
        },
        {
            key: Common.BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_KEY,
            name: Common.BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_NAME,
            value: getBatteryLimit2MinimumDischargingVoltageValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Request
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batteryRequestPanelOpen, setBatteryRequestPanelOpen] = useState(false);
    const [batteryRequestChartMounted, setBatteryRequestChartMounted] = useState(false);
    const [selectedBatteryRequest, setSelectedBatteryRequest] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatteryRequest, setSeriesChartDatasBatteryRequest] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatteryRequestContactorShuttingRequest, setChartBatteryRequestContactorShuttingRequest] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_NAME,
        data: [],
    });

    const [chartBatteryRequestUltraCapacitorReferenceVoltage, setChartBatteryRequestUltraCapacitorReferenceVoltage] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_NAME,
        data: [],
    });

    const getTabelTooltipBatteryRequest = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_KEY:
                return (
                    <>
                        <p>
                            Battery Request
                            <br />
                            CAN ID: 0x0C78D4D0
                            <br />
                            Name: Contactor Shutting Request
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-분리(하압)
                            <br />
                            01-닫힘 요청(상고압)
                        </p>
                    </>
                );

            case Common.BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            Battery Request
                            <br />
                            CAN ID: 0x0C78D4D0
                            <br />
                            Name: Ultracapacitor Reference Voltage
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertBatteryRequestContactorShuttingRequestRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "분리(하압)";
                break;
            case 1:
                valueString = "닫힘 요청(상고압)";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getBatteryRequestContactorShuttingRequestValue = () => {
        if (selectedBatteryRequest.includes(Common.BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_KEY) && currentCanData !== undefined) {
            return convertBatteryRequestContactorShuttingRequestRemark(currentCanData.batReq?.contactorShutting);
        }
        return "";
    };

    const getBatteryRequestUltraCapacitorReferenceVoltageValue = () => {
        if (selectedBatteryRequest.includes(Common.BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batReq?.ultraCapaRefVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeBatteryRequest = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatteryRequest(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatteryRequest: Array<TypeUtils.ChartData> = [];

        if (selectedBatteryRequest.includes(Common.BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_KEY))
            chartDatasBatteryRequest.push(chartBatteryRequestContactorShuttingRequest);
        if (selectedBatteryRequest.includes(Common.BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_KEY))
            chartDatasBatteryRequest.push(chartBatteryRequestUltraCapacitorReferenceVoltage);

        setSeriesChartDatasBatteryRequest(chartDatasBatteryRequest);
    }, [selectedBatteryRequest, chartBatteryRequestContactorShuttingRequest, chartBatteryRequestUltraCapacitorReferenceVoltage]);

    const tableDataBatteryRequest: CanTableType[] = [
        {
            key: Common.BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_KEY,
            name: Common.BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_NAME,
            value: getBatteryRequestContactorShuttingRequestValue(),
        },
        {
            key: Common.BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_KEY,
            name: Common.BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_NAME,
            value: getBatteryRequestUltraCapacitorReferenceVoltageValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Battery Subsystems
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [batterySubsystemsPanelOpen, setBatterySubsystemsPanelOpen] = useState(false);
    const [batterySubsystemsChartMounted, setBatterySubsystemsChartMounted] = useState(false);
    const [selectedBatterySubsystems, setSelectedBatterySubsystems] = useState<Array<React.Key>>([]);

    const [seriesChartDatasBatterySubsystems, setSeriesChartDatasBatterySubsystems] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartBatterySubsystemsNumber, setChartBatterySubsystemsNumber] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_SUBSYSTEMS_NUMBER_NAME,
        data: [],
    });

    const [chartBatterySubsystemsCellNumber, setChartBatterySubsystemsCellNumber] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_SUBSYSTEMS_CELL_NUMBER_NAME,
        data: [],
    });

    const [chartBatterySubsystemsTempCheckNumber, setChartBatterySubsystemsTempCheckNumber] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_NAME,
        data: [],
    });

    const [chartBatterySubsystemsChargingPower, setChartBatterySubsystemsChargingPower] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_SUBSYSTEMS_CHARGING_POWER_NAME,
        data: [],
    });

    const [chartBatterySubsystemsChargingTime, setChartBatterySubsystemsChargingTime] = useState<TypeUtils.ChartData>({
        name: Common.BATTERY_SUBSYSTEMS_CHARGING_TIME_NAME,
        data: [],
    });

    const getTabelTooltipBatterySubsystems = (key: React.Key) => {
        switch (key) {
            case Common.BATTERY_SUBSYSTEMS_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Battery Subsystems
                            <br />
                            CAN ID: 0x1879D0D4
                            <br />
                            Name: Battery SubSystems Number
                        </p>
                    </>
                );

            case Common.BATTERY_SUBSYSTEMS_CELL_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Battery Subsystems
                            <br />
                            CAN ID: 0x1879D0D4
                            <br />
                            Name: Battery Cells Number
                        </p>
                    </>
                );

            case Common.BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_KEY:
                return (
                    <>
                        <p>
                            Battery Subsystems
                            <br />
                            CAN ID: 0x1879D0D4
                            <br />
                            Name: Battery TempCheck Number
                        </p>
                    </>
                );

            case Common.BATTERY_SUBSYSTEMS_CHARGING_POWER_KEY:
                return (
                    <>
                        <p>
                            Battery Subsystems
                            <br />
                            CAN ID: 0x1879D0D4
                            <br />
                            Name: Charging Power
                        </p>
                    </>
                );

            case Common.BATTERY_SUBSYSTEMS_CHARGING_TIME_KEY:
                return (
                    <>
                        <p>
                            Battery Subsystems
                            <br />
                            CAN ID: 0x1879D0D4
                            <br />
                            Name: Charging Time
                        </p>
                    </>
                );
        }

        return null;
    };

    const getBatterySubsystemsNumberValue = () => {
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_NUMBER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batSubsys?.no);
        }
        return "";
    };

    const getBatterySubsystemsCellNumberValue = () => {
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_CELL_NUMBER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batSubsys?.cellNo);
        }
        return "";
    };

    const getBatterySubsystemsTempCheckNumberValue = () => {
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_KEY) && currentCanData !== undefined) {
            return String(currentCanData.batSubsys?.tempCheckNo);
        }
        return "";
    };

    const getBatterySubsystemsChargingPowerValue = () => {
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_CHARGING_POWER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batSubsys?.chargePower, Common.UNIT_POWER_H);
        }
        return "";
    };

    const getBatterySubsystemsChargingTimeValue = () => {
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_CHARGING_TIME_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.batSubsys?.chargeTime, Common.UNIT_MINUTE);
        }
        return "";
    };

    const onChangeBatterySubsystems = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedBatterySubsystems(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasBatterySubsystems: Array<TypeUtils.ChartData> = [];

        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_NUMBER_KEY)) chartDatasBatterySubsystems.push(chartBatterySubsystemsNumber);
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_CELL_NUMBER_KEY))
            chartDatasBatterySubsystems.push(chartBatterySubsystemsCellNumber);
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_KEY))
            chartDatasBatterySubsystems.push(chartBatterySubsystemsTempCheckNumber);
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_CHARGING_POWER_KEY))
            chartDatasBatterySubsystems.push(chartBatterySubsystemsChargingPower);
        if (selectedBatterySubsystems.includes(Common.BATTERY_SUBSYSTEMS_CHARGING_TIME_KEY))
            chartDatasBatterySubsystems.push(chartBatterySubsystemsChargingTime);

        setSeriesChartDatasBatterySubsystems(chartDatasBatterySubsystems);
    }, [
        selectedBatterySubsystems,
        chartBatterySubsystemsNumber,
        chartBatterySubsystemsCellNumber,
        chartBatterySubsystemsTempCheckNumber,
        chartBatterySubsystemsChargingPower,
        chartBatterySubsystemsChargingTime,
    ]);

    const tableDataBatterySubsystems: CanTableType[] = [
        {
            key: Common.BATTERY_SUBSYSTEMS_NUMBER_KEY,
            name: Common.BATTERY_SUBSYSTEMS_NUMBER_NAME,
            value: getBatterySubsystemsNumberValue(),
        },
        {
            key: Common.BATTERY_SUBSYSTEMS_CELL_NUMBER_KEY,
            name: Common.BATTERY_SUBSYSTEMS_CELL_NUMBER_NAME,
            value: getBatterySubsystemsCellNumberValue(),
        },
        {
            key: Common.BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_KEY,
            name: Common.BATTERY_SUBSYSTEMS_TEMP_CHECK_NUMBER_NAME,
            value: getBatterySubsystemsTempCheckNumberValue(),
        },
        {
            key: Common.BATTERY_SUBSYSTEMS_CHARGING_POWER_KEY,
            name: Common.BATTERY_SUBSYSTEMS_CHARGING_POWER_NAME,
            value: getBatterySubsystemsChargingPowerValue(),
        },
        {
            key: Common.BATTERY_SUBSYSTEMS_CHARGING_TIME_KEY,
            name: Common.BATTERY_SUBSYSTEMS_CHARGING_TIME_NAME,
            value: getBatterySubsystemsChargingTimeValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Insulation Stauts
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [insulationStatusPanelOpen, setInsulationStatusPanelOpen] = useState(false);
    const [insulationStatusChartMounted, setInsulationStatusChartMounted] = useState(false);
    const [selectedInsulationStatus, setSelectedInsulationStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasInsulationStatus, setSeriesChartDatasInsulationStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartInsulationStatusMonitorStatus, setChartInsulationStatusMonitorStatus] = useState<TypeUtils.ChartData>({
        name: Common.INSULATION_STATUS_MONITOR_STATUS_NAME,
        data: [],
    });

    const [chartInsulationStatusResistance, setChartInsulationStatusResistance] = useState<TypeUtils.ChartData>({
        name: Common.INSULATION_STATUS_RESISTANCE_NAME,
        data: [],
    });

    const [chartInsulationStatusErrorLevel, setChartInsulationStatusErrorLevel] = useState<TypeUtils.ChartData>({
        name: Common.INSULATION_STATUS_ERROR_LEVEL_NAME,
        data: [],
    });

    const [chartInsulationStatusErrorCode, setChartInsulationStatusErrorCode] = useState<TypeUtils.ChartData>({
        name: Common.INSULATION_STATUS_ERROR_CODE_NAME,
        data: [],
    });

    const getTabelTooltipInsulationStatus = (key: React.Key) => {
        switch (key) {
            case Common.INSULATION_STATUS_MONITOR_STATUS_KEY:
                return (
                    <>
                        <p>
                            Insulation Status
                            <br />
                            CAN ID: 0x1822D4D9
                            <br />
                            Name: Insulation Monitor Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-꺼짐, 01-자가진단 중
                            <br />
                            10-정상,11-고장
                        </p>
                    </>
                );

            case Common.INSULATION_STATUS_RESISTANCE_KEY:
                return (
                    <>
                        <p>
                            Insulation Status
                            <br />
                            CAN ID: 0x1822D4D9
                            <br />
                            Name: Insulation Resistance
                        </p>
                    </>
                );

            case Common.INSULATION_STATUS_ERROR_LEVEL_KEY:
                return (
                    <>
                        <p>
                            Insulation Status
                            <br />
                            CAN ID: 0x1822D4D9
                            <br />
                            Name: Insulation Monitor Error
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상
                            <br />
                            01-1급 고장(경고, 알람)
                            <br />
                            10-2차 고장(경미한 고장) <br />
                            제한전력(정격전력 이하)
                            <br />
                            11-3단계 고장(심각한 고장) 정지
                        </p>
                    </>
                );

            case Common.INSULATION_STATUS_ERROR_CODE_KEY:
                return (
                    <>
                        <p>
                            Insulation Status
                            <br />
                            CAN ID: 0x1822D4D9
                            <br />
                            Name: Insulation Monitor Error
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x00-정상
                            <br />
                            0x11-절연 저항값이 너무 낮음
                            <br />
                            0x12-배선 고장
                            <br />
                            0x13-고전압 양극 대 차체 절연 고장
                            <br />
                            0x14-고압 음극 대 차체 절연 고장
                            <br />
                            0x15-장비 고장
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertMonitorStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "꺼짐";
                break;
            case 0x01:
                valueString = "자가진단 중";
                break;
            case 0x02:
                valueString = "정상";
                break;
            case 0x03:
                valueString = "고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertInsulationStatusErrorCodeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 0x11:
                valueString = "절연 저항값이 너무 낮음";
                break;
            case 0x12:
                valueString = "배선 고장";
                break;
            case 0x13:
                valueString = "고전압 양극 대차체 절연고장";
                break;
            case 0x14:
                valueString = "고전압 음극 대차체 절연고장";
                break;
            case 0x15:
                valueString = "장비 고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getInsulationStatusMonitorStatusValue = () => {
        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_MONITOR_STATUS_KEY) && currentCanData !== undefined) {
            return convertMonitorStatusRemark(currentCanData.insulStatus?.monitorStatus);
        }
        return "";
    };

    const getInsulationStatusResistanceValue = () => {
        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_RESISTANCE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.insulStatus?.resistance, Common.UNIT_K_OHM);
        }
        return "";
    };

    const getInsulationStatusErrorLevelValue = () => {
        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_ERROR_LEVEL_KEY) && currentCanData !== undefined) {
            return convertErrorLevelRemark(currentCanData.insulStatus?.monitorErrLevel);
        }
        return "";
    };

    const getInsulationStatusErrorCodeValue = () => {
        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_ERROR_CODE_KEY) && currentCanData !== undefined) {
            return convertInsulationStatusErrorCodeRemark(currentCanData.insulStatus?.monitorErrCode);
        }
        return "";
    };

    const onChangeInsulationStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedInsulationStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasInsulationStatus: Array<TypeUtils.ChartData> = [];

        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_MONITOR_STATUS_KEY))
            chartDatasInsulationStatus.push(chartInsulationStatusMonitorStatus);
        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_RESISTANCE_KEY))
            chartDatasInsulationStatus.push(chartInsulationStatusResistance);
        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_ERROR_LEVEL_KEY))
            chartDatasInsulationStatus.push(chartInsulationStatusErrorLevel);
        if (selectedInsulationStatus.includes(Common.INSULATION_STATUS_ERROR_CODE_KEY))
            chartDatasInsulationStatus.push(chartInsulationStatusErrorCode);

        setSeriesChartDatasInsulationStatus(chartDatasInsulationStatus);
    }, [
        selectedInsulationStatus,
        chartInsulationStatusMonitorStatus,
        chartInsulationStatusResistance,
        chartInsulationStatusErrorLevel,
        chartInsulationStatusErrorCode,
    ]);

    const tableDataInsulationStatus: CanTableType[] = [
        {
            key: Common.INSULATION_STATUS_MONITOR_STATUS_KEY,
            name: Common.INSULATION_STATUS_MONITOR_STATUS_NAME,
            value: getInsulationStatusMonitorStatusValue(),
        },
        {
            key: Common.INSULATION_STATUS_RESISTANCE_KEY,
            name: Common.INSULATION_STATUS_RESISTANCE_NAME,
            value: getInsulationStatusResistanceValue(),
        },
        {
            key: Common.INSULATION_STATUS_ERROR_LEVEL_KEY,
            name: Common.INSULATION_STATUS_ERROR_LEVEL_NAME,
            value: getInsulationStatusErrorLevelValue(),
        },
        {
            key: Common.INSULATION_STATUS_ERROR_CODE_KEY,
            name: Common.INSULATION_STATUS_ERROR_CODE_NAME,
            value: getInsulationStatusErrorCodeValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Accumulated Capacity
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [accumulatedCapacityPanelOpen, setAccumulatedCapacityPanelOpen] = useState(false);
    const [accumulatedCapacityChartMounted, setAccumulatedCapacityChartMounted] = useState(false);
    const [selectedAccumulatedCapacity, setSelectedAccumulatedCapacity] = useState<Array<React.Key>>([]);

    const [seriesChartDatasAccumulatedCapacity, setSeriesChartDatasAccumulatedCapacity] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartAccumulatedCapacityDischargePower, setChartAccumulatedCapacityDischargePower] = useState<TypeUtils.ChartData>({
        name: Common.ACCUMULATED_CAPACITY_DISCHARGE_POWER_NAME,
        data: [],
    });

    const [chartAccumulatedCapacityChargePower, setChartAccumulatedCapacityChargePower] = useState<TypeUtils.ChartData>({
        name: Common.ACCUMULATED_CAPACITY_CHARGE_POWER_NAME,
        data: [],
    });

    const getTabelTooltipAccumulatedCapacity = (key: React.Key) => {
        switch (key) {
            case Common.ACCUMULATED_CAPACITY_DISCHARGE_POWER_KEY:
                return (
                    <>
                        <p>
                            Accumlated Capacity
                            <br />
                            CAN ID: 0x187CD7D4
                            <br />
                            Name: Accumulated Discharging
                        </p>
                    </>
                );

            case Common.ACCUMULATED_CAPACITY_CHARGE_POWER_KEY:
                return (
                    <>
                        <p>
                            Accumlated Capacity
                            <br />
                            CAN ID: 0x187CD7D4
                            <br />
                            Name: Accumulated Charging
                        </p>
                    </>
                );
        }

        return null;
    };

    const getAccumulatedCapacityDischargePowerValue = () => {
        if (selectedAccumulatedCapacity.includes(Common.ACCUMULATED_CAPACITY_DISCHARGE_POWER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.accumulatedCapa?.discharge, Common.UNIT_POWER_H);
        }
        return "";
    };

    const getAccumulatedCapacityChargePowerValue = () => {
        if (selectedAccumulatedCapacity.includes(Common.ACCUMULATED_CAPACITY_CHARGE_POWER_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.accumulatedCapa?.charge, Common.UNIT_POWER_H);
        }
        return "";
    };

    const onChangeAccumulatedCapacity = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedAccumulatedCapacity(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasAccumulatedCapacity: Array<TypeUtils.ChartData> = [];

        if (selectedAccumulatedCapacity.includes(Common.ACCUMULATED_CAPACITY_DISCHARGE_POWER_KEY))
            chartDatasAccumulatedCapacity.push(chartAccumulatedCapacityDischargePower);
        if (selectedAccumulatedCapacity.includes(Common.ACCUMULATED_CAPACITY_CHARGE_POWER_KEY))
            chartDatasAccumulatedCapacity.push(chartAccumulatedCapacityChargePower);

        setSeriesChartDatasAccumulatedCapacity(chartDatasAccumulatedCapacity);
    }, [selectedAccumulatedCapacity, chartAccumulatedCapacityDischargePower, chartAccumulatedCapacityChargePower]);

    const tableDataAccumulatedCapacity: CanTableType[] = [
        {
            key: Common.ACCUMULATED_CAPACITY_DISCHARGE_POWER_KEY,
            name: Common.ACCUMULATED_CAPACITY_DISCHARGE_POWER_NAME,
            value: getAccumulatedCapacityDischargePowerValue(),
        },
        {
            key: Common.ACCUMULATED_CAPACITY_CHARGE_POWER_KEY,
            name: Common.ACCUMULATED_CAPACITY_CHARGE_POWER_NAME,
            value: getAccumulatedCapacityChargePowerValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region DC/DC Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [dcdcStatusPanelOpen, setDcdcStatusPanelOpen] = useState(false);
    const [dcdcStatusChartMounted, setDcdcStatusChartMounted] = useState(false);
    const [selectedDcdcStatus, setSelectedDcdcStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasDcdcStatus, setSeriesChartDatasDcdcStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartDcdcStatusUnderOutputVoltage, setChartDcdcStatusUnderOutputVoltage] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_NAME,
        data: [],
    });

    const [chartDcdcStatusOverOutputVoltage, setChartDcdcStatusOverOutputVoltage] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_OVER_OUTPUT_VOLTAGE_NAME,
        data: [],
    });

    const [chartDcdcStatusUnderInputVoltage, setChartDcdcStatusUnderInputVoltage] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_UNDER_INPUT_VOLTAGE_NAME,
        data: [],
    });

    const [chartDcdcStatusOverInputVoltage, setChartDcdcStatusOverInputVoltage] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_OVER_INPUT_VOLTAGE_NAME,
        data: [],
    });

    const [chartDcdcStatusHardwareFaultFlag, setChartDcdcStatusHardwareFaultFlag] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_HARDWARE_FAULT_FLAG_NAME,
        data: [],
    });

    const [chartDcdcStatusOperatingMode, setChartDcdcStatusOperatingMode] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_OPERATING_MODE_NAME,
        data: [],
    });

    const [chartDcdcStatusDerating, setChartDcdcStatusDerating] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_DERATING_NAME,
        data: [],
    });

    const [chartDcdcStatusOverInputCurrent, setChartDcdcStatusOverInputCurrent] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_OVER_INPUT_CURRENT_NAME,
        data: [],
    });

    const [chartDcdcStatusOverTemperature, setChartDcdcStatusOverTemperature] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_OVER_TEMPERATURE_NAME,
        data: [],
    });

    const [chartDcdcStatusOverOutputCurrent, setChartDcdcStatusOverOutputCurrent] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_OVER_OUTPUT_CURRENT_NAME,
        data: [],
    });

    const [chartDcdcStatusRealOutputCurrent, setChartDcdcStatusRealOutputCurrent] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_REAL_OUTPUT_CURRENT_NAME,
        data: [],
    });

    const [chartDcdcStatusRealityTemperature, setChartDcdcStatusRealityTemperature] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_REALITY_TEMPERATURE_NAME,
        data: [],
    });

    const [chartDcdcStatusRealOutputVoltage, setChartDcdcStatusRealOutputVoltage] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_REAL_OUTPUT_VOLTAGE_NAME,
        data: [],
    });

    const [chartDcdcStatusRealInputVoltage, setChartDcdcStatusRealInputVoltage] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_REAL_INPUT_VOLTAGE_NAME,
        data: [],
    });

    const [chartDcdcStatusVersion, setChartDcdcStatusVersion] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_STATUS_VERSION_NAME,
        data: [],
    });

    const getTabelTooltipDcdcStatus = (key: React.Key) => {
        switch (key) {
            case Common.DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC Voltage status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDCUnder_Output_Voltage 출력 부족
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_OVER_OUTPUT_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC Voltage status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDCover_Output_Voltage 출력 과압
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_UNDER_INPUT_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC Voltage status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDC_Under_Input_Voltage 입력 부족 전압
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_OVER_INPUT_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC Voltage status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDC_over_Input_Voltage 입력 과압
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_HARDWARE_FAULT_FLAG_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC Voltage status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DC/DC_Hardware_Fault_Flag 하드웨어 오류
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_OPERATING_MODE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC Voltage status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDC_Operating_Mode 작업 모드
                            <br />
                            00-준비, 01-작업
                            <br />
                            10-고장, 11-보류
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_DERATING_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC limited
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDC_Derating 전력 제한 상태
                            <br />
                            0-제한 없음, 1-제한
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_OVER_INPUT_CURRENT_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC limited
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDC_Over_Input_Current 입력 오버플로
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_OVER_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC limited
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDC_Over_Temperature 과온
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_OVER_OUTPUT_CURRENT_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DC/DC limited
                        </p>
                        <p>
                            Remarks:
                            <br />
                            DCDC_Over_Output_Current 전류 제한 보호
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_REAL_OUTPUT_CURRENT_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DCDC_Real_Output_Current
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_REALITY_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DCDC_Reality_Temperature
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_REAL_OUTPUT_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DCDC_Real_Output_Voltage
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_REAL_INPUT_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DCDC_Real_Input_Voltage
                        </p>
                    </>
                );

            case Common.DCDC_STATUS_VERSION_KEY:
                return (
                    <>
                        <p>
                            DC/DC status
                            <br />
                            CAN ID: 0x18A3D02B
                            <br />
                            Name: DCDC_Ver
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertDcdcStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertDcdcStatusOperatingModeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "준비";
                break;
            case 1:
                valueString = "작업";
                break;
            case 2:
                valueString = "고장";
                break;
            case 3:
                valueString = "보류";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertDcdcStatusDeratingRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "제한 없음";
                break;
            case 1:
                valueString = "제한";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getDcdcStatusUnderOutputVoltageValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.underOutputVolt);
        }
        return "";
    };

    const getDcdcStatusOverOutputVoltageValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_OUTPUT_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.overOutputVolt);
        }
        return "";
    };

    const getDcdcStatusUnderInputVoltageValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_UNDER_INPUT_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.underInputVolt);
        }
        return "";
    };

    const getDcdcStatusOverInputVoltageValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_INPUT_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.overInputVolt);
        }
        return "";
    };

    const getDcdcStatusHardwareFaultFlagValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_HARDWARE_FAULT_FLAG_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.hwFaultFlag);
        }
        return "";
    };

    const getDcdcStatusOperatingModeValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OPERATING_MODE_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusOperatingModeRemark(currentCanData.dcdcStatus?.opMode);
        }
        return "";
    };

    const getDcdcStatusDeratingValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_DERATING_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusDeratingRemark(currentCanData.dcdcStatus?.derating);
        }
        return "";
    };

    const onChangeDcdcStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedDcdcStatus(selectedRowKeys);
    };

    const getDcdcStatusOverInputCurrentValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_INPUT_CURRENT_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.overInputCurr);
        }
        return "";
    };

    const getDcdcStatusOverTemperatureValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.overTemp);
        }
        return "";
    };

    const getDcdcStatusOverOutputCurrentValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_OUTPUT_CURRENT_KEY) && currentCanData !== undefined) {
            return convertDcdcStatusRemark(currentCanData.dcdcStatus?.overOutputCurr);
        }
        return "";
    };

    const getDcdcStatusRealOutputCurrentValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REAL_OUTPUT_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dcdcStatus?.realOutputCurr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getDcdcStatusRealityTemperatureValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REALITY_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dcdcStatus?.realityTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getDcdcStatusRealOutputVoltageValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REAL_OUTPUT_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dcdcStatus?.realOutputVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getDcdcStatusRealInputVoltageValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REAL_INPUT_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dcdcStatus?.realInputVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getDcdcStatusVersionValue = () => {
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_VERSION_KEY) && currentCanData !== undefined) {
            return String(currentCanData.dcdcStatus?.ver);
        }
        return "";
    };

    useEffect(() => {
        const chartDatasDcdcStatus: Array<TypeUtils.ChartData> = [];

        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusUnderOutputVoltage);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_OUTPUT_VOLTAGE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusOverOutputVoltage);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_UNDER_INPUT_VOLTAGE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusUnderInputVoltage);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_INPUT_VOLTAGE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusOverInputVoltage);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_HARDWARE_FAULT_FLAG_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusHardwareFaultFlag);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OPERATING_MODE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusOperatingMode);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_DERATING_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusDerating);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_INPUT_CURRENT_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusOverInputCurrent);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_TEMPERATURE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusOverTemperature);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_OVER_OUTPUT_CURRENT_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusOverOutputCurrent);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REAL_OUTPUT_CURRENT_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusRealOutputCurrent);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REALITY_TEMPERATURE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusRealityTemperature);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REAL_OUTPUT_VOLTAGE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusRealOutputVoltage);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_REAL_INPUT_VOLTAGE_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusRealInputVoltage);
        if (selectedDcdcStatus.includes(Common.DCDC_STATUS_VERSION_KEY)) chartDatasDcdcStatus.push(chartDcdcStatusVersion);

        setSeriesChartDatasDcdcStatus(chartDatasDcdcStatus);
    }, [
        selectedDcdcStatus,
        chartDcdcStatusUnderOutputVoltage,
        chartDcdcStatusOverOutputVoltage,
        chartDcdcStatusUnderInputVoltage,
        chartDcdcStatusOverInputVoltage,
        chartDcdcStatusHardwareFaultFlag,
        chartDcdcStatusOperatingMode,
        chartDcdcStatusDerating,
        chartDcdcStatusOverInputCurrent,
        chartDcdcStatusOverTemperature,
        chartDcdcStatusOverOutputCurrent,
        chartDcdcStatusRealOutputCurrent,
        chartDcdcStatusRealityTemperature,
        chartDcdcStatusRealOutputVoltage,
        chartDcdcStatusRealInputVoltage,
        chartDcdcStatusVersion,
    ]);

    const tableDataDcdcStatus: CanTableType[] = [
        {
            key: Common.DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_KEY,
            name: Common.DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_NAME,
            value: getDcdcStatusUnderOutputVoltageValue(),
        },
        {
            key: Common.DCDC_STATUS_OVER_OUTPUT_VOLTAGE_KEY,
            name: Common.DCDC_STATUS_OVER_OUTPUT_VOLTAGE_NAME,
            value: getDcdcStatusOverOutputVoltageValue(),
        },
        {
            key: Common.DCDC_STATUS_UNDER_INPUT_VOLTAGE_KEY,
            name: Common.DCDC_STATUS_UNDER_INPUT_VOLTAGE_NAME,
            value: getDcdcStatusUnderInputVoltageValue(),
        },
        {
            key: Common.DCDC_STATUS_OVER_INPUT_VOLTAGE_KEY,
            name: Common.DCDC_STATUS_OVER_INPUT_VOLTAGE_NAME,
            value: getDcdcStatusOverInputVoltageValue(),
        },
        {
            key: Common.DCDC_STATUS_HARDWARE_FAULT_FLAG_KEY,
            name: Common.DCDC_STATUS_HARDWARE_FAULT_FLAG_NAME,
            value: getDcdcStatusHardwareFaultFlagValue(),
        },
        {
            key: Common.DCDC_STATUS_OPERATING_MODE_KEY,
            name: Common.DCDC_STATUS_OPERATING_MODE_NAME,
            value: getDcdcStatusOperatingModeValue(),
        },
        {
            key: Common.DCDC_STATUS_DERATING_KEY,
            name: Common.DCDC_STATUS_DERATING_NAME,
            value: getDcdcStatusDeratingValue(),
        },
        {
            key: Common.DCDC_STATUS_OVER_INPUT_CURRENT_KEY,
            name: Common.DCDC_STATUS_OVER_INPUT_CURRENT_NAME,
            value: getDcdcStatusOverInputCurrentValue(),
        },
        {
            key: Common.DCDC_STATUS_OVER_TEMPERATURE_KEY,
            name: Common.DCDC_STATUS_OVER_TEMPERATURE_NAME,
            value: getDcdcStatusOverTemperatureValue(),
        },
        {
            key: Common.DCDC_STATUS_OVER_OUTPUT_CURRENT_KEY,
            name: Common.DCDC_STATUS_OVER_OUTPUT_CURRENT_NAME,
            value: getDcdcStatusOverOutputCurrentValue(),
        },
        {
            key: Common.DCDC_STATUS_REAL_OUTPUT_CURRENT_KEY,
            name: Common.DCDC_STATUS_REAL_OUTPUT_CURRENT_NAME,
            value: getDcdcStatusRealOutputCurrentValue(),
        },
        {
            key: Common.DCDC_STATUS_REALITY_TEMPERATURE_KEY,
            name: Common.DCDC_STATUS_REALITY_TEMPERATURE_NAME,
            value: getDcdcStatusRealityTemperatureValue(),
        },
        {
            key: Common.DCDC_STATUS_REAL_OUTPUT_VOLTAGE_KEY,
            name: Common.DCDC_STATUS_REAL_OUTPUT_VOLTAGE_NAME,
            value: getDcdcStatusRealOutputVoltageValue(),
        },
        {
            key: Common.DCDC_STATUS_REAL_INPUT_VOLTAGE_KEY,
            name: Common.DCDC_STATUS_REAL_INPUT_VOLTAGE_NAME,
            value: getDcdcStatusRealInputVoltageValue(),
        },
        {
            key: Common.DCDC_STATUS_VERSION_KEY,
            name: Common.DCDC_STATUS_VERSION_NAME,
            value: getDcdcStatusVersionValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region DC/DC Work Command
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [dcdcWorkCommandPanelOpen, setDcdcWorkCommandPanelOpen] = useState(false);
    const [dcdcWorkCommandChartMounted, setDcdcWorkCommandChartMounted] = useState(false);
    const [selectedDcdcWorkCommand, setSelectedDcdcWorkCommand] = useState<Array<React.Key>>([]);

    const [seriesChartDatasDcdcWorkCommand, setSeriesChartDatasDcdcWorkCommand] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartDcdcWorkCommandEnable, setChartDcdcWorkCommandEnable] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_WORK_COMMAND_ENABLE_NAME,
        data: [],
    });

    const [chartDcdcWorkCommandVoltageRequest, setChartDcdcWorkCommandVoltageRequest] = useState<TypeUtils.ChartData>({
        name: Common.DCDC_WORK_COMMAND_VOLTAGE_REQUEST_NAME,
        data: [],
    });

    const convertDcdcWorkCommandEnableRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "준비";
                break;
            case 1:
                valueString = "작업";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getTabelTooltipDcdcWorkCommand = (key: React.Key) => {
        switch (key) {
            case Common.DCDC_WORK_COMMAND_ENABLE_KEY:
                return (
                    <>
                        <p>
                            DC/DC Work command
                            <br />
                            CAN ID: 0x18A42BD0
                            <br />
                            Name: DCDC Enable
                        </p>
                        <p>Remarks: 0-준비, 1-작업</p>
                    </>
                );

            case Common.DCDC_WORK_COMMAND_VOLTAGE_REQUEST_KEY:
                return (
                    <>
                        <p>
                            DC/DC Work command
                            <br />
                            CAN ID: 0x18A42BD0
                            <br />
                            Name: HCU DCDC Voltage req
                        </p>
                    </>
                );
        }

        return null;
    };

    const getDcdcWorkCommandEnableValue = () => {
        if (selectedDcdcWorkCommand.includes(Common.DCDC_WORK_COMMAND_ENABLE_KEY) && currentCanData !== undefined) {
            return convertDcdcWorkCommandEnableRemark(currentCanData.dcdcWorkCmd?.enable);
        }
        return "";
    };

    const getDcdcWorkCommandVoltageRequestValue = () => {
        if (selectedDcdcWorkCommand.includes(Common.DCDC_WORK_COMMAND_VOLTAGE_REQUEST_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.dcdcWorkCmd?.voltReq, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const onChangeDcdcWorkCommand = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedDcdcWorkCommand(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasDcdcWorkCommand: Array<TypeUtils.ChartData> = [];

        if (selectedDcdcWorkCommand.includes(Common.DCDC_WORK_COMMAND_ENABLE_KEY)) chartDatasDcdcWorkCommand.push(chartDcdcWorkCommandEnable);
        if (selectedDcdcWorkCommand.includes(Common.DCDC_WORK_COMMAND_VOLTAGE_REQUEST_KEY))
            chartDatasDcdcWorkCommand.push(chartDcdcWorkCommandVoltageRequest);

        setSeriesChartDatasDcdcWorkCommand(chartDatasDcdcWorkCommand);
    }, [selectedDcdcWorkCommand, chartDcdcWorkCommandEnable, chartDcdcWorkCommandVoltageRequest]);

    const tableDataDcdcWorkCommand: CanTableType[] = [
        {
            key: Common.DCDC_WORK_COMMAND_ENABLE_KEY,
            name: Common.DCDC_WORK_COMMAND_ENABLE_NAME,
            value: getDcdcWorkCommandEnableValue(),
        },
        {
            key: Common.DCDC_WORK_COMMAND_VOLTAGE_REQUEST_KEY,
            name: Common.DCDC_WORK_COMMAND_VOLTAGE_REQUEST_NAME,
            value: getDcdcWorkCommandVoltageRequestValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region TMPS Pressure Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [tmpsPressureStatusPanelOpen, setTmpsPressureStatusPanelOpen] = useState(false);
    const [tmpsPressureStatusChartMounted, setTmpsPressureStatusChartMounted] = useState(false);
    const [selectedTmpsPressureStatus, setSelectedTmpsPressureStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasTmpsPressureStatus, setSeriesChartDatasTmpsPressureStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartTmpsPressureStatusLeftFront, setChartTmpsPressureStatusLeftFront] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusRightFront, setChartTmpsPressureStatusRightFront] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusLeftRear, setChartTmpsPressureStatusLeftRear] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_LEFT_REAR_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusRightRear, setChartTmpsPressureStatusRightRear] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusLeftFrontStatus, setChartTmpsPressureStatusLeftFrontStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusRightFrontStatus, setChartTmpsPressureStatusRightFrontStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusLeftRearStatus, setChartTmpsPressureStatusLeftRearStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusRightRearStatus, setChartTmpsPressureStatusRightRearStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusAcquisitionStatus, setChartTmpsPressureStatusAcquisitionStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusTireMonitorSystemStatus, setChartTmpsPressureStatusTireMonitorSystemStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_NAME,
        data: [],
    });

    const [chartTmpsPressureStatusSendCycle, setChartTmpsPressureStatusSendCycle] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_PRESSURE_STATUS_SEND_CYCLE_NAME,
        data: [],
    });

    const convertTmpsPressureStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "Over-Pressure";
                break;
            case 2:
                valueString = "Under-Pressure";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertTmpsPressureStatusAcquisitionStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "lost";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertTmpsPressureStatusTireMonitorSystemStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "System is ok";
                break;
            case 1:
                valueString = "System is abnormal";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getTabelTooltipTmpsPressureStatus = (key: React.Key) => {
        switch (key) {
            case Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 좌측전방 압력
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 우측전방 압력
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_LEFT_REAR_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 좌측후방 압력
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 우측후방 압력
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 좌측전방 압력상태
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 우측전방 압력상태
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 좌측후방 압력상태
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 우측후방 압력상태
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 압력신호 수집상태
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 타이어 공기압 모니터링 시스템 상태
                        </p>
                    </>
                );

            case Common.TMPS_PRESSURE_STATUS_SEND_CYCLE_KEY:
                return (
                    <>
                        <p>
                            TMPS Pressure Status
                            <br />
                            CAN ID: 0x1860D751
                            <br />
                            Name: 전송주기
                        </p>
                    </>
                );
        }

        return null;
    };

    const getTmpsPressureStatusLeftFrontValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsPressureStatus?.leftFront, Common.UNIT_PRESSURE);
        }
        return "";
    };

    const getTmpsPressureStatusRightFrontValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsPressureStatus?.rightFront, Common.UNIT_PRESSURE);
        }
        return "";
    };

    const getTmpsPressureStatusLeftRearValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsPressureStatus?.leftRear, Common.UNIT_PRESSURE);
        }
        return "";
    };

    const getTmpsPressureStatusRightRearValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsPressureStatus?.rightRear, Common.UNIT_PRESSURE);
        }
        return "";
    };

    const getTmpsPressureStatusLeftFrontStatusValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsPressureStatusRemark(currentCanData.tmpsPressureStatus?.leftFrontStatus);
        }
        return "";
    };

    const getTmpsPressureStatusRightFrontStatusValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsPressureStatusRemark(currentCanData.tmpsPressureStatus?.rightFrontStatus);
        }
        return "";
    };

    const getTmpsPressureStatusLeftRearStatusValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsPressureStatusRemark(currentCanData.tmpsPressureStatus?.leftRearStatus);
        }
        return "";
    };

    const getTmpsPressureStatusRightRearStatusValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsPressureStatusRemark(currentCanData.tmpsPressureStatus?.rightRearStatus);
        }
        return "";
    };

    const getTmpsPressureStatusAcquisitionStatusValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsPressureStatusAcquisitionStatusRemark(currentCanData.tmpsPressureStatus?.collectStatus);
        }
        return "";
    };

    const getTmpsPressureStatusTireMonitorSystemStatusValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsPressureStatusTireMonitorSystemStatusRemark(currentCanData.tmpsPressureStatus?.monitoringStatus);
        }
        return "";
    };

    const getTmpsPressureStatusSendCycleValue = () => {
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_SEND_CYCLE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.tmpsPressureStatus?.heartBeat);
        }
        return "";
    };

    const onChangeTmpsPressureStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedTmpsPressureStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasTmpsPressureStatus: Array<TypeUtils.ChartData> = [];

        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusLeftFront);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusRightFront);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_REAR_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusLeftRear);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusRightRear);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusLeftFrontStatus);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusRightFrontStatus);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusLeftRearStatus);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusRightRearStatus);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusAcquisitionStatus);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusTireMonitorSystemStatus);
        if (selectedTmpsPressureStatus.includes(Common.TMPS_PRESSURE_STATUS_SEND_CYCLE_KEY))
            chartDatasTmpsPressureStatus.push(chartTmpsPressureStatusSendCycle);

        setSeriesChartDatasTmpsPressureStatus(chartDatasTmpsPressureStatus);
    }, [
        selectedTmpsPressureStatus,
        chartTmpsPressureStatusLeftFront,
        chartTmpsPressureStatusRightFront,
        chartTmpsPressureStatusLeftRear,
        chartTmpsPressureStatusRightRear,
        chartTmpsPressureStatusLeftFrontStatus,
        chartTmpsPressureStatusRightFrontStatus,
        chartTmpsPressureStatusLeftRearStatus,
        chartTmpsPressureStatusRightRearStatus,
        chartTmpsPressureStatusAcquisitionStatus,
        chartTmpsPressureStatusTireMonitorSystemStatus,
        chartTmpsPressureStatusSendCycle,
    ]);

    const tableDataTmpsPressureStatus: CanTableType[] = [
        {
            key: Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_KEY,
            name: Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_NAME,
            value: getTmpsPressureStatusLeftFrontValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_KEY,
            name: Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_NAME,
            value: getTmpsPressureStatusRightFrontValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_LEFT_REAR_KEY,
            name: Common.TMPS_PRESSURE_STATUS_LEFT_REAR_NAME,
            value: getTmpsPressureStatusLeftRearValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_KEY,
            name: Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_NAME,
            value: getTmpsPressureStatusRightRearValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_KEY,
            name: Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_NAME,
            value: getTmpsPressureStatusLeftFrontStatusValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_KEY,
            name: Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_NAME,
            value: getTmpsPressureStatusRightFrontStatusValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_KEY,
            name: Common.TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_NAME,
            value: getTmpsPressureStatusLeftRearStatusValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_KEY,
            name: Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_NAME,
            value: getTmpsPressureStatusRightRearStatusValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_KEY,
            name: Common.TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_NAME,
            value: getTmpsPressureStatusAcquisitionStatusValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_KEY,
            name: Common.TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_NAME,
            value: getTmpsPressureStatusTireMonitorSystemStatusValue(),
        },
        {
            key: Common.TMPS_PRESSURE_STATUS_SEND_CYCLE_KEY,
            name: Common.TMPS_PRESSURE_STATUS_SEND_CYCLE_NAME,
            value: getTmpsPressureStatusSendCycleValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region TMPS Temperature Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [tmpsTemperatureStatusPanelOpen, setTmpsTemperatureStatusPanelOpen] = useState(false);
    const [tmpsTemperatureStatusChartMounted, setTmpsTemperatureStatusChartMounted] = useState(false);
    const [selectedTmpsTemperatureStatus, setSelectedTmpsTemperatureStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasTmpsTemperatureStatus, setSeriesChartDatasTmpsTemperatureStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartTmpsTemperatureStatusLeftFront, setChartTmpsTemperatureStatusLeftFront] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusRightFront, setChartTmpsTemperatureStatusRightFront] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusLeftRear, setChartTmpsTemperatureStatusLeftRear] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusRightRear, setChartTmpsTemperatureStatusRightRear] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusLeftFrontStatus, setChartTmpsTemperatureStatusLeftFrontStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusRightFrontStatus, setChartTmpsTemperatureStatusRightFrontStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusLeftRearStatus, setChartTmpsTemperatureStatusLeftRearStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusRightRearStatus, setChartTmpsTemperatureStatusRightRearStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusAcquisitionStatus, setChartTmpsTemperatureStatusAcquisitionStatus] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_NAME,
        data: [],
    });

    const [chartTmpsTemperatureStatusSendCycle, setChartTmpsTemperatureStatusSendCycle] = useState<TypeUtils.ChartData>({
        name: Common.TMPS_TEMPERATURE_STATUS_SEND_CYCLE_NAME,
        data: [],
    });

    const convertTmpsTemperatureStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "Over-Temp";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertTmpsTemperatureStatusAcquisitionStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Normal";
                break;
            case 1:
                valueString = "lost";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getTabelTooltipTmpsTemperatureStatus = (key: React.Key) => {
        switch (key) {
            case Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 좌측전방 온도
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 우측전방 온도
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 좌측후방 온도
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 우측후방 온도
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 좌측전방 온도상태
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 우측전방 온도상태
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 좌측후방 온도상태
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 우측후방 온도상태
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 온도신호 수집상태
                        </p>
                    </>
                );

            case Common.TMPS_TEMPERATURE_STATUS_SEND_CYCLE_KEY:
                return (
                    <>
                        <p>
                            TMPS Temperature Status
                            <br />
                            CAN ID: 0x1861D751
                            <br />
                            Name: 전송주기
                        </p>
                    </>
                );
        }

        return null;
    };

    const getTmpsTemperatureStatusLeftFrontValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsTempStatus?.leftFront, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getTmpsTemperatureStatusRightFrontValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsTempStatus?.rightFront, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getTmpsTemperatureStatusLeftRearValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsTempStatus?.leftRear, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getTmpsTemperatureStatusRightRearValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.tmpsTempStatus?.rightRear, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getTmpsTemperatureStatusLeftFrontStatusValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsTemperatureStatusRemark(currentCanData.tmpsTempStatus?.leftFrontStatus);
        }
        return "";
    };

    const getTmpsTemperatureStatusRightFrontStatusValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsTemperatureStatusRemark(currentCanData.tmpsTempStatus?.rightFrontStatus);
        }
        return "";
    };

    const getTmpsTemperatureStatusLeftRearStatusValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsTemperatureStatusRemark(currentCanData.tmpsTempStatus?.leftRearStatus);
        }
        return "";
    };

    const getTmpsTemperatureStatusRightRearStatusValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsTemperatureStatusRemark(currentCanData.tmpsTempStatus?.rightRearStatus);
        }
        return "";
    };

    const getTmpsTemperatureStatusAcquisitionStatusValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_KEY) && currentCanData !== undefined) {
            return convertTmpsTemperatureStatusAcquisitionStatusRemark(currentCanData.tmpsTempStatus?.collectStatus);
        }
        return "";
    };

    const getTmpsTemperatureStatusSendCycleValue = () => {
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_SEND_CYCLE_KEY) && currentCanData !== undefined) {
            return String(currentCanData.tmpsTempStatus?.heartBeat);
        }
        return "";
    };

    const onChangeTmpsTemperatureStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedTmpsTemperatureStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasTmpsTemperatureStatus: Array<TypeUtils.ChartData> = [];

        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusLeftFront);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusRightFront);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusLeftRear);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusRightRear);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusLeftFrontStatus);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusRightFrontStatus);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusLeftRearStatus);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusRightRearStatus);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusAcquisitionStatus);
        if (selectedTmpsTemperatureStatus.includes(Common.TMPS_TEMPERATURE_STATUS_SEND_CYCLE_KEY))
            chartDatasTmpsTemperatureStatus.push(chartTmpsTemperatureStatusSendCycle);

        setSeriesChartDatasTmpsTemperatureStatus(chartDatasTmpsTemperatureStatus);
    }, [
        selectedTmpsTemperatureStatus,
        chartTmpsTemperatureStatusLeftFront,
        chartTmpsTemperatureStatusRightFront,
        chartTmpsTemperatureStatusLeftRear,
        chartTmpsTemperatureStatusRightRear,
        chartTmpsTemperatureStatusLeftFrontStatus,
        chartTmpsTemperatureStatusRightFrontStatus,
        chartTmpsTemperatureStatusLeftRearStatus,
        chartTmpsTemperatureStatusRightRearStatus,
        chartTmpsTemperatureStatusAcquisitionStatus,
        chartTmpsTemperatureStatusSendCycle,
    ]);

    const tableDataTmpsTemperatureStatus: CanTableType[] = [
        {
            key: Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_NAME,
            value: getTmpsTemperatureStatusLeftFrontValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_NAME,
            value: getTmpsTemperatureStatusRightFrontValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_NAME,
            value: getTmpsTemperatureStatusLeftRearValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_NAME,
            value: getTmpsTemperatureStatusRightRearValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_NAME,
            value: getTmpsTemperatureStatusLeftFrontStatusValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_NAME,
            value: getTmpsTemperatureStatusRightFrontStatusValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_NAME,
            value: getTmpsTemperatureStatusLeftRearStatusValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_NAME,
            value: getTmpsTemperatureStatusRightRearStatusValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_NAME,
            value: getTmpsTemperatureStatusAcquisitionStatusValue(),
        },
        {
            key: Common.TMPS_TEMPERATURE_STATUS_SEND_CYCLE_KEY,
            name: Common.TMPS_TEMPERATURE_STATUS_SEND_CYCLE_NAME,
            value: getTmpsTemperatureStatusSendCycleValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Lamp Signal
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [lampSignalPanelOpen, setLampSignalPanelOpen] = useState(false);
    const [lampSignalChartMounted, setLampSignalChartMounted] = useState(false);
    const [selectedLampSignal, setSelectedLampSignal] = useState<Array<React.Key>>([]);

    const [seriesChartDatasLampSignal, setSeriesChartDatasLampSignal] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartLampSignalHandbrakeStatus, setChartLampSignalHandbrakeStatus] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_HANDBRAKE_STATUS_NAME,
        data: [],
    });

    const [chartLampSignalDriverSeatBeltSwitch, setChartLampSignalDriverSeatBeltSwitch] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_NAME,
        data: [],
    });

    const [chartLampSignalLowBeam, setChartLampSignalLowBeam] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_LOW_BEAM_NAME,
        data: [],
    });

    const [chartLampSignalHighBeam, setChartLampSignalHighBeam] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_HIGH_BEAM_NAME,
        data: [],
    });

    const [chartLampSignalFrontFog, setChartLampSignalFrontFog] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_FRONT_FOG_NAME,
        data: [],
    });

    const [chartLampSignalRearFog, setChartLampSignalRearFog] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_REAR_FOG_NAME,
        data: [],
    });

    const [chartLampSignalRightTurn, setChartLampSignalRightTurn] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_RIGHT_TURN_NAME,
        data: [],
    });

    const [chartLampSignalLeftTurn, setChartLampSignalLeftTurn] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_LEFT_TURN_NAME,
        data: [],
    });

    const [chartLampSignalPosition, setChartLampSignalPosition] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_POSITION_NAME,
        data: [],
    });

    const [chartLampSignalHazardWarning, setChartLampSignalHazardWarning] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_HAZARD_WARNING_NAME,
        data: [],
    });

    const [chartLampSignalLeftFrontDoor, setChartLampSignalLeftFrontDoor] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_LEFT_FRONT_DOOR_NAME,
        data: [],
    });

    const [chartLampSignalRightFrontDoor, setChartLampSignalRightFrontDoor] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_RIGHT_FRONT_DOOR_NAME,
        data: [],
    });

    const [chartLampSignalLeftMiddleDoor, setChartLampSignalLeftMiddleDoor] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_LEFT_MIDDLE_DOOR_NAME,
        data: [],
    });

    const [chartLampSignalRightMiddleDoor, setChartLampSignalRightMiddleDoor] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_NAME,
        data: [],
    });

    const [chartLampSignalTailGate, setChartLampSignalTailGate] = useState<TypeUtils.ChartData>({
        name: Common.LAMP_SIGNAL_TAIL_GATE_NAME,
        data: [],
    });

    const convertLampSignalRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "꺼짐";
                break;
            case 1:
                valueString = "켜짐";
                break;
            case 2:
                valueString = "고장";
                break;
            case 3:
                valueString = "예약";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertLampSignalDriverSeatBeltSwitchRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "안전벨트 미착용";
                break;
            case 1:
                valueString = "안전벨트 착용";
                break;
            case 2:
                valueString = "고장";
                break;
            case 3:
                valueString = "예약";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getTabelTooltipLampSignal = (key: React.Key) => {
        switch (key) {
            case Common.LAMP_SIGNAL_HANDBRAKE_STATUS_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 핸드브레이크 상태
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 운전자 안전벨트
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_LOW_BEAM_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 하향등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_HIGH_BEAM_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 상향등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_FRONT_FOG_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 전방 안개등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_REAR_FOG_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 후방 안개등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_RIGHT_TURN_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 우측 방향지시등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_LEFT_TURN_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 좌측 방향지시등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_POSITION_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 위치등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_HAZARD_WARNING_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 위험경고등
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_LEFT_FRONT_DOOR_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 좌측전방도어
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_RIGHT_FRONT_DOOR_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 우측전방도어
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_LEFT_MIDDLE_DOOR_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 좌측중간도어
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 우측중간도어
                        </p>
                    </>
                );

            case Common.LAMP_SIGNAL_TAIL_GATE_KEY:
                return (
                    <>
                        <p>
                            Lamp Signal
                            <br />
                            CAN ID: 0x10FF2021
                            <br />
                            Name: 후방도어
                        </p>
                    </>
                );
        }

        return null;
    };

    const getLampSignalHandbrakeStatusValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_HANDBRAKE_STATUS_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.handbrake);
        }
        return "";
    };

    const getLampSignalDriverSeatBeltSwitchValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_KEY) && currentCanData !== undefined) {
            return convertLampSignalDriverSeatBeltSwitchRemark(currentCanData.lampSignal?.seatbeltSwitch);
        }
        return "";
    };

    const getLampSignalLowBeamValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LOW_BEAM_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.lowBeam);
        }
        return "";
    };

    const getLampSignalHighBeamValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_HIGH_BEAM_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.highBeam);
        }
        return "";
    };

    const getLampSignalFrontFogValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_FRONT_FOG_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.frontFog);
        }
        return "";
    };

    const getLampSignalRearFogValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_REAR_FOG_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.rearFog);
        }
        return "";
    };

    const getLampSignalRightTurnValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_RIGHT_TURN_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.rightTurn);
        }
        return "";
    };

    const getLampSignalLeftTurnValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LEFT_TURN_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.leftTurn);
        }
        return "";
    };

    const getLampSignalPositionValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_POSITION_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.position);
        }
        return "";
    };

    const getLampSignalHazardWarningValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_HAZARD_WARNING_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.hazardWarn);
        }
        return "";
    };

    const getLampSignalLeftFrontDoorValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LEFT_FRONT_DOOR_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.leftDoor);
        }
        return "";
    };

    const getLampSignalRightFrontDoorValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_RIGHT_FRONT_DOOR_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.rightDoor);
        }
        return "";
    };

    const getLampSignalLeftMiddleDoorValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LEFT_MIDDLE_DOOR_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.middleDoor);
        }
        return "";
    };

    const getLampSignalRightMiddleDoorValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.rightMiddleDoor);
        }
        return "";
    };

    const getLampSignalTailGateValue = () => {
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_TAIL_GATE_KEY) && currentCanData !== undefined) {
            return convertLampSignalRemark(currentCanData.lampSignal?.backDoor);
        }
        return "";
    };

    const onChangeLampSignal = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedLampSignal(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasLampSignal: Array<TypeUtils.ChartData> = [];

        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_HANDBRAKE_STATUS_KEY)) chartDatasLampSignal.push(chartLampSignalHandbrakeStatus);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_KEY))
            chartDatasLampSignal.push(chartLampSignalDriverSeatBeltSwitch);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LOW_BEAM_KEY)) chartDatasLampSignal.push(chartLampSignalLowBeam);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_HIGH_BEAM_KEY)) chartDatasLampSignal.push(chartLampSignalHighBeam);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_FRONT_FOG_KEY)) chartDatasLampSignal.push(chartLampSignalFrontFog);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_REAR_FOG_KEY)) chartDatasLampSignal.push(chartLampSignalRearFog);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_RIGHT_TURN_KEY)) chartDatasLampSignal.push(chartLampSignalRightTurn);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LEFT_TURN_KEY)) chartDatasLampSignal.push(chartLampSignalLeftTurn);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_POSITION_KEY)) chartDatasLampSignal.push(chartLampSignalPosition);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_HAZARD_WARNING_KEY)) chartDatasLampSignal.push(chartLampSignalHazardWarning);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LEFT_FRONT_DOOR_KEY)) chartDatasLampSignal.push(chartLampSignalLeftFrontDoor);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_RIGHT_FRONT_DOOR_KEY)) chartDatasLampSignal.push(chartLampSignalRightFrontDoor);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_LEFT_MIDDLE_DOOR_KEY)) chartDatasLampSignal.push(chartLampSignalLeftMiddleDoor);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_KEY)) chartDatasLampSignal.push(chartLampSignalRightMiddleDoor);
        if (selectedLampSignal.includes(Common.LAMP_SIGNAL_TAIL_GATE_KEY)) chartDatasLampSignal.push(chartLampSignalTailGate);

        setSeriesChartDatasLampSignal(chartDatasLampSignal);
    }, [
        selectedLampSignal,
        chartLampSignalHandbrakeStatus,
        chartLampSignalDriverSeatBeltSwitch,
        chartLampSignalLowBeam,
        chartLampSignalHighBeam,
        chartLampSignalFrontFog,
        chartLampSignalRearFog,
        chartLampSignalRightTurn,
        chartLampSignalLeftTurn,
        chartLampSignalPosition,
        chartLampSignalHazardWarning,
        chartLampSignalLeftFrontDoor,
        chartLampSignalRightFrontDoor,
        chartLampSignalLeftMiddleDoor,
        chartLampSignalRightMiddleDoor,
        chartLampSignalTailGate,
    ]);

    const tableDataLampSignal: CanTableType[] = [
        {
            key: Common.LAMP_SIGNAL_HANDBRAKE_STATUS_KEY,
            name: Common.LAMP_SIGNAL_HANDBRAKE_STATUS_NAME,
            value: getLampSignalHandbrakeStatusValue(),
        },
        {
            key: Common.LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_KEY,
            name: Common.LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_NAME,
            value: getLampSignalDriverSeatBeltSwitchValue(),
        },
        {
            key: Common.LAMP_SIGNAL_LOW_BEAM_KEY,
            name: Common.LAMP_SIGNAL_LOW_BEAM_NAME,
            value: getLampSignalLowBeamValue(),
        },
        {
            key: Common.LAMP_SIGNAL_HIGH_BEAM_KEY,
            name: Common.LAMP_SIGNAL_HIGH_BEAM_NAME,
            value: getLampSignalHighBeamValue(),
        },
        {
            key: Common.LAMP_SIGNAL_FRONT_FOG_KEY,
            name: Common.LAMP_SIGNAL_FRONT_FOG_NAME,
            value: getLampSignalFrontFogValue(),
        },
        {
            key: Common.LAMP_SIGNAL_REAR_FOG_KEY,
            name: Common.LAMP_SIGNAL_REAR_FOG_NAME,
            value: getLampSignalRearFogValue(),
        },
        {
            key: Common.LAMP_SIGNAL_RIGHT_TURN_KEY,
            name: Common.LAMP_SIGNAL_RIGHT_TURN_NAME,
            value: getLampSignalRightTurnValue(),
        },
        {
            key: Common.LAMP_SIGNAL_LEFT_TURN_KEY,
            name: Common.LAMP_SIGNAL_LEFT_TURN_NAME,
            value: getLampSignalLeftTurnValue(),
        },
        {
            key: Common.LAMP_SIGNAL_POSITION_KEY,
            name: Common.LAMP_SIGNAL_POSITION_NAME,
            value: getLampSignalPositionValue(),
        },
        {
            key: Common.LAMP_SIGNAL_HAZARD_WARNING_KEY,
            name: Common.LAMP_SIGNAL_HAZARD_WARNING_NAME,
            value: getLampSignalHazardWarningValue(),
        },
        {
            key: Common.LAMP_SIGNAL_LEFT_FRONT_DOOR_KEY,
            name: Common.LAMP_SIGNAL_LEFT_FRONT_DOOR_NAME,
            value: getLampSignalLeftFrontDoorValue(),
        },
        {
            key: Common.LAMP_SIGNAL_RIGHT_FRONT_DOOR_KEY,
            name: Common.LAMP_SIGNAL_RIGHT_FRONT_DOOR_NAME,
            value: getLampSignalRightFrontDoorValue(),
        },
        {
            key: Common.LAMP_SIGNAL_LEFT_MIDDLE_DOOR_KEY,
            name: Common.LAMP_SIGNAL_LEFT_MIDDLE_DOOR_NAME,
            value: getLampSignalLeftMiddleDoorValue(),
        },
        {
            key: Common.LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_KEY,
            name: Common.LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_NAME,
            value: getLampSignalRightMiddleDoorValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Vehicle Speed
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vehicleSpeedPanelOpen, setVehicleSpeedPanelOpen] = useState(false);
    const [vehicleSpeedChartMounted, setVehicleSpeedChartMounted] = useState(false);
    const [selectedVehicleSpeed, setSelectedVehicleSpeed] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVehicleSpeed, setSeriesChartDatasVehicleSpeed] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVehicleSpeedSpeed, setChartVehicleSpeedSpeed] = useState<TypeUtils.ChartData>({
        name: Common.VEHICLE_SPEED_SPEED_NAME,
        data: [],
    });

    const [chartVehicleSpeedInstantaneousPowerConsumption, setChartVehicleSpeedInstantaneousPowerConsumption] = useState<TypeUtils.ChartData>({
        name: Common.VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_NAME,
        data: [],
    });

    const [chartVehicleSpeedDriveMotorSpeed, setChartVehicleSpeedDriveMotorSpeed] = useState<TypeUtils.ChartData>({
        name: Common.VEHICLE_SPEED_DRIVE_MOTOR_SPEED_NAME,
        data: [],
    });

    const [chartVehicleSpeedHighVoltagePrecharge, setChartVehicleSpeedHighVoltagePrecharge] = useState<TypeUtils.ChartData>({
        name: Common.VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_NAME,
        data: [],
    });

    const [chartVehicleSpeedRechargeMileage, setChartVehicleSpeedRechargeMileage] = useState<TypeUtils.ChartData>({
        name: Common.VEHICLE_SPEED_RECHARE_MILEAGE_NAME,
        data: [],
    });

    const getTabelTooltipVehicleSpeed = (key: React.Key) => {
        switch (key) {
            case Common.VEHICLE_SPEED_SPEED_KEY:
                return (
                    <>
                        <p>
                            Vehicle Speed
                            <br />
                            CAN ID: 0x18E2D7D0
                            <br />
                            Name: 속도
                        </p>
                    </>
                );

            case Common.VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_KEY:
                return (
                    <>
                        <p>
                            Vehicle Speed
                            <br />
                            CAN ID: 0x18E2D7D0
                            <br />
                            Name: 순간 소모전력
                        </p>
                    </>
                );

            case Common.VEHICLE_SPEED_DRIVE_MOTOR_SPEED_KEY:
                return (
                    <>
                        <p>
                            Vehicle Speed
                            <br />
                            CAN ID: 0x18E2D7D0
                            <br />
                            Name: 구동모터회전수
                        </p>
                    </>
                );

            case Common.VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_KEY:
                return (
                    <>
                        <p>
                            Vehicle Speed
                            <br />
                            CAN ID: 0x18E2D7D0
                            <br />
                            Name: 고압 프리차지 전압
                        </p>
                    </>
                );

            case Common.VEHICLE_SPEED_RECHARE_MILEAGE_KEY:
                return (
                    <>
                        <p>
                            Vehicle Speed
                            <br />
                            CAN ID: 0x18E2D7D0
                            <br />
                            Name: 주행거리
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVehicleSpeedSpeedValue = () => {
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleSpeed?.speed, Common.UNIT_SPEED);
        }
        return "";
    };

    const getVehicleSpeedInstantaneousPowerConsumptionValue = () => {
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleSpeed?.powerUsage, Common.UNIT_POWER_DISTANCE);
        }
        return "";
    };

    const getVehicleSpeedDriveMotorSpeedValue = () => {
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_DRIVE_MOTOR_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleSpeed?.motorRpm, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getVehicleSpeedHighVoltagePrechargeValue = () => {
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleSpeed?.prechargeVolt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getVehicleSpeedRechargeMileageValue = () => {
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_RECHARE_MILEAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleSpeed?.cruisingDistance, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const onChangeVehicleSpeed = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVehicleSpeed(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVehicleSpeed: Array<TypeUtils.ChartData> = [];

        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_SPEED_KEY)) chartDatasVehicleSpeed.push(chartVehicleSpeedSpeed);
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_KEY))
            chartDatasVehicleSpeed.push(chartVehicleSpeedInstantaneousPowerConsumption);
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_DRIVE_MOTOR_SPEED_KEY)) chartDatasVehicleSpeed.push(chartVehicleSpeedDriveMotorSpeed);
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_KEY))
            chartDatasVehicleSpeed.push(chartVehicleSpeedHighVoltagePrecharge);
        if (selectedVehicleSpeed.includes(Common.VEHICLE_SPEED_RECHARE_MILEAGE_KEY)) chartDatasVehicleSpeed.push(chartVehicleSpeedRechargeMileage);

        setSeriesChartDatasVehicleSpeed(chartDatasVehicleSpeed);
    }, [
        selectedVehicleSpeed,
        chartVehicleSpeedSpeed,
        chartVehicleSpeedInstantaneousPowerConsumption,
        chartVehicleSpeedDriveMotorSpeed,
        chartVehicleSpeedHighVoltagePrecharge,
        chartVehicleSpeedRechargeMileage,
    ]);

    const tableDataVehicleSpeed: CanTableType[] = [
        {
            key: Common.VEHICLE_SPEED_SPEED_KEY,
            name: Common.VEHICLE_SPEED_SPEED_NAME,
            value: getVehicleSpeedSpeedValue(),
        },
        {
            key: Common.VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_KEY,
            name: Common.VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_NAME,
            value: getVehicleSpeedInstantaneousPowerConsumptionValue(),
        },
        {
            key: Common.VEHICLE_SPEED_DRIVE_MOTOR_SPEED_KEY,
            name: Common.VEHICLE_SPEED_DRIVE_MOTOR_SPEED_NAME,
            value: getVehicleSpeedDriveMotorSpeedValue(),
        },
        {
            key: Common.VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_KEY,
            name: Common.VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_NAME,
            value: getVehicleSpeedHighVoltagePrechargeValue(),
        },
        {
            key: Common.VEHICLE_SPEED_RECHARE_MILEAGE_KEY,
            name: Common.VEHICLE_SPEED_RECHARE_MILEAGE_NAME,
            value: getVehicleSpeedRechargeMileageValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Electronic Message
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [electronicMessagePanelOpen, setElectronicMessagePanelOpen] = useState(false);
    const [electronicMessageChartMounted, setElectronicMessageChartMounted] = useState(false);
    const [selectedElectronicMessage, setSelectedElectronicMessage] = useState<Array<React.Key>>([]);

    const [seriesChartDatasElectronicMessage, setSeriesChartDatasElectronicMessage] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartElectronicMessageDrivingMode, setChartElectronicMessageDrivingMode] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_DRIVING_MODE_NAME,
        data: [],
    });

    const [chartElectronicMessageGearPosition, setChartElectronicMessageGearPosition] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_GEAR_POSITION_NAME,
        data: [],
    });

    const [chartElectronicMessageHighVoltageControllerStatus, setChartElectronicMessageHighVoltageControllerStatus] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_NAME,
        data: [],
    });

    const [chartElectronicMessageHighVoltageControllerFaultCode, setChartElectronicMessageHighVoltageControllerFaultCode] =
        useState<TypeUtils.ChartData>({
            name: Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_NAME,
            data: [],
        });

    const [chartElectronicMessageVehicleControllerVcuStatus, setChartElectronicMessageVehicleControllerVcuStatus] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_NAME,
        data: [],
    });

    const [chartElectronicMessageVehicleControllerDefrostStatus, setChartElectronicMessageVehicleControllerDefrostStatus] =
        useState<TypeUtils.ChartData>({
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_NAME,
            data: [],
        });

    const [chartElectronicMessageVehicleControllerAcceleratorPedalStatus, setChartElectronicMessageVehicleControllerAcceleratorPedalStatus] =
        useState<TypeUtils.ChartData>({
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_NAME,
            data: [],
        });

    const [chartElectronicMessageVehicleControllerBrakePedalStatus, setChartElectronicMessageVehicleControllerBrakePedalStatus] =
        useState<TypeUtils.ChartData>({
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_NAME,
            data: [],
        });

    const [chartElectronicMessageVehicleElectronicSystemFailure, setChartElectronicMessageVehicleElectronicSystemFailure] =
        useState<TypeUtils.ChartData>({
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_NAME,
            data: [],
        });

    const [chartElectronicMessageDcDcStatus, setChartElectronicMessageDcDcStatus] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_DCDC_STATUS_NAME,
        data: [],
    });

    const [chartElectronicMessageDriveMotorPowerLimit, setChartElectronicMessageDriveMotorPowerLimit] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_NAME,
        data: [],
    });

    const [chartElectronicMessageCoolingWaterPumpStatus, setChartElectronicMessageCoolingWaterPumpStatus] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_NAME,
        data: [],
    });

    const [chartElectronicMessageSteeringOilPumpStatus, setChartElectronicMessageSteeringOilPumpStatus] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_NAME,
        data: [],
    });

    const [chartElectronicMessageAirPumpStatus, setChartElectronicMessageAirPumpStatus] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_NAME,
        data: [],
    });

    const [chartElectronicMessageAirConditionerStatus, setChartElectronicMessageAirConditionerStatus] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_NAME,
        data: [],
    });

    const [chartElectronicMessageOperationReadyIndicator, setChartElectronicMessageOperationReadyIndicator] = useState<TypeUtils.ChartData>({
        name: Common.ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_NAME,
        data: [],
    });

    const getTabelTooltipElectronicMessage = (key: React.Key) => {
        switch (key) {
            case Common.ELECTRONIC_MESSAGE_DRIVING_MODE_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 주행모드
                        </p>
                        <p>
                            Remarks: 00-수동 모드
                            <br />
                            01-자동모드, 10-수동 개입 모드
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_GEAR_POSITION_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 기어
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0000-빈칸(N)
                            <br />
                            0001-전진(D)
                            <br />
                            0010-뒤로(R)
                            <br />
                            0011-주차( P)
                            <br />
                            1111-기어단고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 고압컨트롤러 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상, 11-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 고압컨트롤러 고장코드
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상
                            <br />
                            01-양극 접촉기 고장
                            <br />
                            02-프리차지 접촉기 고장
                            <br />
                            03-음극접촉기 고장
                            <br />
                            04-급속 충전 접촉기 고장
                            <br />
                            05-완충 접촉기 고장
                            <br />
                            06-부속품 접촉기 고장
                            <br />
                            07-DC/DC 고장
                            <br />
                            08-IMMS 검출기 장비 고장
                            <br />
                            09-기타
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 차량컨트롤러 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상, 11-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: Defrost Status
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-닫기, 01-운행, 11-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 가속 페달 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-방출, 01-밟기, 11-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 브레이크 페달 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-방출, 01-밟기, 11-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 차량 전체 전기 시스템 고장
                            <br />
                            (모든 고장 정보는 전기 고장 표시에 해당)
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_DCDC_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: DCDC 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 구동모터 출력제한
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-정상, 1-출력제한
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 냉각수 펌프 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 오일 펌프 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 브레이크 진공 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-정상, 1-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 에어컨 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-닫기, 01-운행, 11-고장
                        </p>
                    </>
                );

            case Common.ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_KEY:
                return (
                    <>
                        <p>
                            Electronic Message
                            <br />
                            CAN ID: 0x18E3D7D0
                            <br />
                            Name: 작동 준비 표시등
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-옐로우 READY
                            <br />
                            01-그린READY
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertElectronicMessageStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertElectronicMessageStatus2Remark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "미운행";
                break;
            case 1:
                valueString = "운행";
                break;
            case 2:
                valueString = "고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertElectronicMessagePedalStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "방출";
                break;
            case 1:
                valueString = "밟기";
                break;
            case 2:
                valueString = "고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertElectronicMessageDrivingModeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "수동모드";
                break;
            case 1:
                valueString = "자동모드";
                break;
            case 2:
                valueString = "수동개입모드";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertElectronicMessageGearPositionRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "N";
                break;
            case 1:
                valueString = "D";
                break;
            case 2:
                valueString = "R";
                break;
            case 3:
                valueString = "P";
                break;
            case 0x0f:
                valueString = "기어고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertElectronicMessageHighVoltageControllerFaultCodeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "양극 접촉기 고장";
                break;
            case 2:
                valueString = "프리차지 접촉기 고장";
                break;
            case 3:
                valueString = "음극접촉기 고장";
                break;
            case 4:
                valueString = "급속 충전 접촉기 고장";
                break;
            case 5:
                valueString = "완충 접촉기 고장";
                break;
            case 6:
                valueString = "부속품 접촉기 고장";
                break;
            case 7:
                valueString = "DC/DC 고장";
                break;
            case 8:
                valueString = "IMMS 검출기 장비 고장";
                break;
            case 9:
                valueString = "기타";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertElectronicMessageDriveMotorPowerLimitRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "출력제한";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertElectronicMessageOperationReadyIndicatorRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "옐로우 READY";
                break;
            case 1:
                valueString = "그린 READY";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getElectronicMessageDrivingModeValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_DRIVING_MODE_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageDrivingModeRemark(currentCanData.electricalMsg?.drivingMode);
        }
        return "";
    };

    const getElectronicMessageGearPositionValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_GEAR_POSITION_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageGearPositionRemark(currentCanData.electricalMsg?.gearPosition);
        }
        return "";
    };

    const getElectronicMessageHighVoltageControllerStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatusRemark(currentCanData.electricalMsg?.hiacStatus);
        }
        return "";
    };

    const getElectronicMessageHighVoltageControllerFaultCodeValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageHighVoltageControllerFaultCodeRemark(currentCanData.electricalMsg?.hiacErrCode);
        }
        return "";
    };

    const getElectronicMessageVehicleControllerVcuStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatusRemark(currentCanData.electricalMsg?.vcuStatus);
        }
        return "";
    };

    const getElectronicMessageVehicleControllerDefrostStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatus2Remark(currentCanData.electricalMsg?.defrostStatus);
        }
        return "";
    };

    const getElectronicMessageVehicleControllerAcceleratorPedalStatusValue = () => {
        if (
            selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_KEY) &&
            currentCanData !== undefined
        ) {
            return convertElectronicMessagePedalStatusRemark(currentCanData.electricalMsg?.acceleratorPedalStatus);
        }
        return "";
    };

    const getElectronicMessageVehicleControllerBrakePedalStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessagePedalStatusRemark(currentCanData.electricalMsg?.brakePedalStatus);
        }
        return "";
    };

    const getElectronicMessageVehicleElectronicSystemFailureValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatusRemark(currentCanData.electricalMsg?.electricSysFail);
        }
        return "";
    };

    const getElectronicMessageDcDcStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_DCDC_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatusRemark(currentCanData.electricalMsg?.dcDcStatus);
        }
        return "";
    };

    const getElectronicMessageDriveMotorPowerLimitValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageDriveMotorPowerLimitRemark(currentCanData.electricalMsg?.motorPowerLimit);
        }
        return "";
    };

    const getElectronicMessageCoolingWaterPumpStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatusRemark(currentCanData.electricalMsg?.coolingWaterPumpStatus);
        }
        return "";
    };

    const getElectronicMessageSteeringOilPumpStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatusRemark(currentCanData.electricalMsg?.steeringOilPumpStatus);
        }
        return "";
    };

    const getElectronicMessageAirPumpStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatusRemark(currentCanData.electricalMsg?.airPumpStatus);
        }
        return "";
    };

    const getElectronicMessageAirConditionerStatusValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageStatus2Remark(currentCanData.electricalMsg?.airConditionerStatus);
        }
        return "";
    };

    const getElectronicMessageOperationReadyIndicatorValue = () => {
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_KEY) && currentCanData !== undefined) {
            return convertElectronicMessageOperationReadyIndicatorRemark(currentCanData.electricalMsg?.readyIndicator);
        }
        return "";
    };

    const onChangeElectronicMessage = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedElectronicMessage(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasElectronicMessage: Array<TypeUtils.ChartData> = [];

        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_DRIVING_MODE_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageDrivingMode);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_GEAR_POSITION_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageGearPosition);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageHighVoltageControllerStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageHighVoltageControllerFaultCode);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageVehicleControllerVcuStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageVehicleControllerDefrostStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageVehicleControllerAcceleratorPedalStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageVehicleControllerBrakePedalStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageVehicleElectronicSystemFailure);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_DCDC_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageDcDcStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageDriveMotorPowerLimit);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageCoolingWaterPumpStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageSteeringOilPumpStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageAirPumpStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageAirConditionerStatus);
        if (selectedElectronicMessage.includes(Common.ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_KEY))
            chartDatasElectronicMessage.push(chartElectronicMessageOperationReadyIndicator);

        setSeriesChartDatasElectronicMessage(chartDatasElectronicMessage);
    }, [
        selectedElectronicMessage,
        chartElectronicMessageDrivingMode,
        chartElectronicMessageGearPosition,
        chartElectronicMessageHighVoltageControllerStatus,
        chartElectronicMessageHighVoltageControllerFaultCode,
        chartElectronicMessageVehicleControllerVcuStatus,
        chartElectronicMessageVehicleControllerDefrostStatus,
        chartElectronicMessageVehicleControllerAcceleratorPedalStatus,
        chartElectronicMessageVehicleControllerBrakePedalStatus,
        chartElectronicMessageVehicleElectronicSystemFailure,
        chartElectronicMessageDcDcStatus,
        chartElectronicMessageDriveMotorPowerLimit,
        chartElectronicMessageCoolingWaterPumpStatus,
        chartElectronicMessageSteeringOilPumpStatus,
        chartElectronicMessageAirPumpStatus,
        chartElectronicMessageAirConditionerStatus,
        chartElectronicMessageOperationReadyIndicator,
    ]);

    const tableDataElectronicMessage: CanTableType[] = [
        {
            key: Common.ELECTRONIC_MESSAGE_DRIVING_MODE_KEY,
            name: Common.ELECTRONIC_MESSAGE_DRIVING_MODE_NAME,
            value: getElectronicMessageDrivingModeValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_GEAR_POSITION_KEY,
            name: Common.ELECTRONIC_MESSAGE_GEAR_POSITION_NAME,
            value: getElectronicMessageGearPositionValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_NAME,
            value: getElectronicMessageHighVoltageControllerStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_KEY,
            name: Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_NAME,
            value: getElectronicMessageHighVoltageControllerFaultCodeValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_NAME,
            value: getElectronicMessageVehicleControllerVcuStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_NAME,
            value: getElectronicMessageVehicleControllerDefrostStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_NAME,
            value: getElectronicMessageVehicleControllerAcceleratorPedalStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_NAME,
            value: getElectronicMessageVehicleControllerBrakePedalStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_KEY,
            name: Common.ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_NAME,
            value: getElectronicMessageVehicleElectronicSystemFailureValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_DCDC_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_DCDC_STATUS_NAME,
            value: getElectronicMessageDcDcStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_KEY,
            name: Common.ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_NAME,
            value: getElectronicMessageDriveMotorPowerLimitValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_NAME,
            value: getElectronicMessageCoolingWaterPumpStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_NAME,
            value: getElectronicMessageSteeringOilPumpStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_NAME,
            value: getElectronicMessageAirPumpStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_KEY,
            name: Common.ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_NAME,
            value: getElectronicMessageAirConditionerStatusValue(),
        },
        {
            key: Common.ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_KEY,
            name: Common.ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_NAME,
            value: getElectronicMessageOperationReadyIndicatorValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Motor Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [motorStatusPanelOpen, setMotorStatusPanelOpen] = useState(false);
    const [motorStatusChartMounted, setMotorStatusChartMounted] = useState(false);
    const [selectedMotorStatus, setSelectedMotorStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasMotorStatus, setSeriesChartDatasMotorStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartMotorStatusRunningStatus, setChartMotorStatusRunningStatus] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_RUNNING_STATUS_NAME,
        data: [],
    });

    const [chartMotorStatusRotationDirection, setChartMotorStatusRotationDirection] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_ROTATION_DIRECTION_NAME,
        data: [],
    });

    const [chartMotorStatusErrorLevel, setChartMotorStatusErrorLevel] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_ERROR_LEVEL_NAME,
        data: [],
    });

    const [chartMotorStatusErrorCode, setChartMotorStatusErrorCode] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_ERROR_CODE_NAME,
        data: [],
    });

    const [chartMotorStatusCoolingWaterTemperature, setChartMotorStatusCoolingWaterTemperature] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_COOLING_WATER_TEMPERATURE_NAME,
        data: [],
    });

    const [chartMotorStatusControllerTemperature, setChartMotorStatusControllerTemperature] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_CONTROLLER_TEMPERATURE_NAME,
        data: [],
    });

    const [chartMotorStatusOutputPowerPercentage, setChartMotorStatusOutputPowerPercentage] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_NAME,
        data: [],
    });

    const [chartMotorStatusBrakeRecoveryPowerPercentage, setChartMotorStatusBrakeRecoveryPowerPercentage] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_NAME,
        data: [],
    });

    const [chartMotorStatusBrakeRecoveryWorkingStatus, setChartMotorStatusBrakeRecoveryWorkingStatus] = useState<TypeUtils.ChartData>({
        name: Common.MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_NAME,
        data: [],
    });

    const getTabelTooltipMotorStatus = (key: React.Key) => {
        switch (key) {
            case Common.MOTOR_STATUS_RUNNING_STATUS_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 구동모터 작동상태
                        </p>
                        <p>
                            Remarks: 00-정지
                            <br />
                            01-운행, 11-고장
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_ROTATION_DIRECTION_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 모터 조향
                        </p>
                        <p>
                            Remarks:
                            <br />
                            01-정회전(전진)
                            <br />
                            10-반전(후퇴)
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_ERROR_LEVEL_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 드라이브 모터 고장 레벨
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상
                            <br />
                            01-레벨1 고장(경고, 알람)
                            <br />
                            10-2차 고장(경미한 고장)은 <br />
                            전력(정격 전력 이하)을 제한한다.
                            <br />
                            11-3단계 고장(심각한 고장)이 중단됩니다.
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_ERROR_CODE_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 드라이브 모터 고장 코드
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x00-정상
                            <br />
                            0x01-자가진단 이상
                            <br />
                            0x11-과압(고압)
                            <br />
                            0x12-저압(고압)
                            <br />
                            0x13-단락/절연 감지(고압)
                            <br />
                            0x14-차단/개방(고압)
                            <br />
                            0x15-과류(고압)
                            <br />
                            0x16-구동 모터 과부하
                            <br />
                            0x17-구동 모터 온도가 너무 높음
                            <br />
                            0x18-구동 모터 컨트롤러 온도가 너무 높음
                            <br />
                            0x19-드라이브 모터 컨트롤러 12V 부족 전압
                            <br />
                            0x1A-회전 장애
                            <br />
                            0x1B-구동 모터 출력 결상
                            <br />
                            0x1C-드라이브 모터 과속
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_COOLING_WATER_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 구동 모터 냉각수 온도
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_CONTROLLER_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 구동 모터 제어기 온도
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 모터 출력 백분율
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: 제동 회수 전력 백분율
                        </p>
                    </>
                );

            case Common.MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_KEY:
                return (
                    <>
                        <p>
                            Motor Status
                            <br />
                            CAN ID: 0x18E4D7D0
                            <br />
                            Name: brake recovery operating condition
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00:보류
                            <br />
                            01:차량 브레이크 없는 회수
                            <br />
                            (충전 또는 배터리 모터 및 기타 부속품의
                            <br />
                            문제로 인한 브레이크 없는 회수) 계기 점등
                            <br />
                            10:차량 제동 회수에너지 구비
                            <br />
                            11:보류
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertMotorStatusRunningStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정지";
                break;
            case 1:
                valueString = "운행";
                break;
            case 2:
                valueString = "고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertMotorStatusRotationDirectionRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 1:
                valueString = "전진";
                break;
            case 2:
                valueString = "후진";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertMotorStatusErrorCodeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0x00:
                valueString = "정상";
                break;

            case 0x01:
                valueString = "자가진단 이상";
                break;

            case 0x11:
                valueString = "과압(고압)";
                break;

            case 0x12:
                valueString = "저압(고압)";
                break;

            case 0x13:
                valueString = "단락/절연 감지(고압)";
                break;

            case 0x14:
                valueString = "차단/개방(고압)";
                break;

            case 0x15:
                valueString = "과류(고압)";
                break;

            case 0x16:
                valueString = "구동 모터 과부하";
                break;

            case 0x17:
                valueString = "모터온도가 너무 높음";
                break;

            case 0x18:
                valueString = "컨트롤러 온도가 너무 높음";
                break;

            case 0x19:
                valueString = "컨트롤러 12V 부족";
                break;

            case 0x1a:
                valueString = "회전 장애";
                break;

            case 0x1b:
                valueString = "구동 모터 출력 결상";
                break;

            case 0x1c:
                valueString = "드라이브 모터 과속";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertMotorStatusBrakeRecoveryWorkingStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "보류";
                break;
            case 1:
                valueString = "브레이크 없는 회수";
                break;
            case 2:
                valueString = "회생제동 구비";
                break;
            case 3:
                valueString = "보류";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getMotorStatusRunningStatusValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_RUNNING_STATUS_KEY) && currentCanData !== undefined) {
            return convertMotorStatusRunningStatusRemark(currentCanData.motorStatus?.status);
        }
        return "";
    };

    const getMotorStatusRotationDirectionValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_ROTATION_DIRECTION_KEY) && currentCanData !== undefined) {
            return convertMotorStatusRotationDirectionRemark(currentCanData.motorStatus?.rotationDirection);
        }
        return "";
    };

    const getMotorStatusErrorLevelValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_ERROR_LEVEL_KEY) && currentCanData !== undefined) {
            return convertErrorLevelRemark(currentCanData.motorStatus?.errLevel);
        }
        return "";
    };

    const getMotorStatusErrorCodeValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_ERROR_CODE_KEY) && currentCanData !== undefined) {
            return convertMotorStatusErrorCodeRemark(currentCanData.motorStatus?.errCode);
        }
        return "";
    };

    const getMotorStatusCoolingWaterTemperatureValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_COOLING_WATER_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.motorStatus?.coolingWaterTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getMotorStatusControllerTemperatureValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_CONTROLLER_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.motorStatus?.controllerTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getMotorStatusOutputPowerPercentageValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.motorStatus?.outputPercent, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getMotorStatusBrakeRecoveryPowerPercentageValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.motorStatus?.brakeRecoveryPowerPercent, Common.UNIT_PERCENT);
        }
        return "";
    };

    const getMotorStatusBrakeRecoveryWorkingStatusValue = () => {
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_KEY) && currentCanData !== undefined) {
            return convertMotorStatusBrakeRecoveryWorkingStatusRemark(currentCanData.motorStatus?.brakeRecoveryOpCondition);
        }
        return "";
    };

    const onChangeMotorStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedMotorStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasMotorStatus: Array<TypeUtils.ChartData> = [];

        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_RUNNING_STATUS_KEY)) chartDatasMotorStatus.push(chartMotorStatusRunningStatus);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_ROTATION_DIRECTION_KEY)) chartDatasMotorStatus.push(chartMotorStatusRotationDirection);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_ERROR_LEVEL_KEY)) chartDatasMotorStatus.push(chartMotorStatusErrorLevel);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_ERROR_CODE_KEY)) chartDatasMotorStatus.push(chartMotorStatusErrorCode);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_COOLING_WATER_TEMPERATURE_KEY))
            chartDatasMotorStatus.push(chartMotorStatusCoolingWaterTemperature);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_CONTROLLER_TEMPERATURE_KEY))
            chartDatasMotorStatus.push(chartMotorStatusControllerTemperature);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_KEY))
            chartDatasMotorStatus.push(chartMotorStatusOutputPowerPercentage);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_KEY))
            chartDatasMotorStatus.push(chartMotorStatusBrakeRecoveryPowerPercentage);
        if (selectedMotorStatus.includes(Common.MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_KEY))
            chartDatasMotorStatus.push(chartMotorStatusBrakeRecoveryWorkingStatus);

        setSeriesChartDatasMotorStatus(chartDatasMotorStatus);
    }, [
        selectedMotorStatus,
        chartMotorStatusRunningStatus,
        chartMotorStatusRotationDirection,
        chartMotorStatusErrorLevel,
        chartMotorStatusErrorCode,
        chartMotorStatusCoolingWaterTemperature,
        chartMotorStatusControllerTemperature,
        chartMotorStatusOutputPowerPercentage,
        chartMotorStatusBrakeRecoveryPowerPercentage,
        chartMotorStatusBrakeRecoveryWorkingStatus,
    ]);

    const tableDataMotorStatus: CanTableType[] = [
        {
            key: Common.MOTOR_STATUS_RUNNING_STATUS_KEY,
            name: Common.MOTOR_STATUS_RUNNING_STATUS_NAME,
            value: getMotorStatusRunningStatusValue(),
        },
        {
            key: Common.MOTOR_STATUS_ROTATION_DIRECTION_KEY,
            name: Common.MOTOR_STATUS_ROTATION_DIRECTION_NAME,
            value: getMotorStatusRotationDirectionValue(),
        },
        {
            key: Common.MOTOR_STATUS_ERROR_LEVEL_KEY,
            name: Common.MOTOR_STATUS_ERROR_LEVEL_NAME,
            value: getMotorStatusErrorLevelValue(),
        },
        {
            key: Common.MOTOR_STATUS_ERROR_CODE_KEY,
            name: Common.MOTOR_STATUS_ERROR_CODE_NAME,
            value: getMotorStatusErrorCodeValue(),
        },
        {
            key: Common.MOTOR_STATUS_COOLING_WATER_TEMPERATURE_KEY,
            name: Common.MOTOR_STATUS_COOLING_WATER_TEMPERATURE_NAME,
            value: getMotorStatusCoolingWaterTemperatureValue(),
        },
        {
            key: Common.MOTOR_STATUS_CONTROLLER_TEMPERATURE_KEY,
            name: Common.MOTOR_STATUS_CONTROLLER_TEMPERATURE_NAME,
            value: getMotorStatusControllerTemperatureValue(),
        },
        {
            key: Common.MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_KEY,
            name: Common.MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_NAME,
            value: getMotorStatusOutputPowerPercentageValue(),
        },
        {
            key: Common.MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_KEY,
            name: Common.MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_NAME,
            value: getMotorStatusBrakeRecoveryPowerPercentageValue(),
        },
        {
            key: Common.MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_KEY,
            name: Common.MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_NAME,
            value: getMotorStatusBrakeRecoveryWorkingStatusValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Vehicle Mileage
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [vehicleMileagePanelOpen, setVehicleMileagePanelOpen] = useState(false);
    const [vehicleMileageChartMounted, setVehicleMileageChartMounted] = useState(false);
    const [selectedVehicleMileage, setSelectedVehicleMileage] = useState<Array<React.Key>>([]);

    const [seriesChartDatasVehicleMileage, setSeriesChartDatasVehicleMileage] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartVehicleMileageAccumulatedMileage, setChartVehicleMileageAccumulatedMileage] = useState<TypeUtils.ChartData>({
        name: Common.VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_NAME,
        data: [],
    });

    const [chartVehicleMileageCurrentMileage, setChartVehicleMileageCurrentMileage] = useState<TypeUtils.ChartData>({
        name: Common.VEHICLE_MILEAGE_CURRENT_MILEAGE_NAME,
        data: [],
    });

    const getTabelTooltipVehicleMileage = (key: React.Key) => {
        switch (key) {
            case Common.VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_KEY:
                return (
                    <>
                        <p>
                            Vehicle Mileage
                            <br />
                            CAN ID: 0x18EAFFD7
                            <br />
                            Name: 누적 주행거리
                        </p>
                    </>
                );

            case Common.VEHICLE_MILEAGE_CURRENT_MILEAGE_KEY:
                return (
                    <>
                        <p>
                            Vehicle Mileage
                            <br />
                            CAN ID: 0x18EAFFD7
                            <br />
                            Name: 현재 주행거리
                        </p>
                    </>
                );
        }

        return null;
    };

    const getVehicleMileageAccumulatedMileageValue = () => {
        if (selectedVehicleMileage.includes(Common.VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleMileage?.totalMileage, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const getVehicleMileageCurrentMileageValue = () => {
        if (selectedVehicleMileage.includes(Common.VEHICLE_MILEAGE_CURRENT_MILEAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.vehicleMileage?.currMileage, Common.UNIT_DISTANCE);
        }
        return "";
    };

    const onChangeVehicleMileage = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedVehicleMileage(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasVehicleMileage: Array<TypeUtils.ChartData> = [];

        if (selectedVehicleMileage.includes(Common.VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_KEY))
            chartDatasVehicleMileage.push(chartVehicleMileageAccumulatedMileage);
        if (selectedVehicleMileage.includes(Common.VEHICLE_MILEAGE_CURRENT_MILEAGE_KEY))
            chartDatasVehicleMileage.push(chartVehicleMileageCurrentMileage);

        setSeriesChartDatasVehicleMileage(chartDatasVehicleMileage);
    }, [selectedVehicleMileage, chartVehicleMileageAccumulatedMileage, chartVehicleMileageCurrentMileage]);

    const tableDataVehicleMileage: CanTableType[] = [
        {
            key: Common.VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_KEY,
            name: Common.VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_NAME,
            value: getVehicleMileageAccumulatedMileageValue(),
        },
        {
            key: Common.VEHICLE_MILEAGE_CURRENT_MILEAGE_KEY,
            name: Common.VEHICLE_MILEAGE_CURRENT_MILEAGE_NAME,
            value: getVehicleMileageCurrentMileageValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region B2C Charger
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [b2CChargerPanelOpen, setB2CChargerPanelOpen] = useState(false);
    const [b2CChargerChartMounted, setV2CChargerChartMounted] = useState(false);
    const [selectedB2CCharger, setSelectedB2CCharger] = useState<Array<React.Key>>([]);

    const [seriesChartDatasB2CCharger, setSeriesChartDatasB2CCharger] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartB2CChargerVoltageSet, setChartB2CChargerVoltageSet] = useState<TypeUtils.ChartData>({
        name: Common.B2C_CHARGER_VOLTAGE_SET_NAME,
        data: [],
    });

    const [chartB2CChargerCurrentSet, setChartB2CChargerCurrentSet] = useState<TypeUtils.ChartData>({
        name: Common.B2C_CHARGER_CURRENT_SET_NAME,
        data: [],
    });

    const [chartB2CChargerControl, setChartB2CChargerControl] = useState<TypeUtils.ChartData>({
        name: Common.B2C_CHARGER_CONTROL_NAME,
        data: [],
    });

    const getTabelTooltipB2CCharger = (key: React.Key) => {
        switch (key) {
            case Common.B2C_CHARGER_VOLTAGE_SET_KEY:
                return (
                    <>
                        <p>
                            B2C Charger
                            <br />
                            CAN ID: 0x180156F3
                            <br />
                            Name: 교류 충전 전압 설정
                        </p>
                    </>
                );

            case Common.B2C_CHARGER_CURRENT_SET_KEY:
                return (
                    <>
                        <p>
                            B2C Charger
                            <br />
                            CAN ID: 0x180156F3
                            <br />
                            Name: 교류 충전 전류 설정
                        </p>
                    </>
                );

            case Common.B2C_CHARGER_CONTROL_KEY:
                return (
                    <>
                        <p>
                            B2C Charger
                            <br />
                            CAN ID: 0x180156F3
                            <br />
                            Name: 교류 충전기 제어
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0-Start to charge
                            <br />
                            1-Normal Stop to charge
                            <br />
                            2-Fault, Stop to charge
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertB2CChargerControlRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "Start to charge";
                break;
            case 1:
                valueString = "Normal Stop to charge";
                break;
            case 2:
                valueString = "Fault, Stop to charge";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getB2CChargerVoltageSetValue = () => {
        if (selectedB2CCharger.includes(Common.B2C_CHARGER_VOLTAGE_SET_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.b2CCharger?.voltSet, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getB2CChargerCurrentSetValue = () => {
        if (selectedB2CCharger.includes(Common.B2C_CHARGER_CURRENT_SET_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.b2CCharger?.currSet, Common.UNIT_CURRENT);
        }
        return "";
    };

    const getB2CChargerControlValue = () => {
        if (selectedB2CCharger.includes(Common.B2C_CHARGER_CURRENT_SET_KEY) && currentCanData !== undefined) {
            return convertB2CChargerControlRemark(currentCanData.b2CCharger?.chargeControl);
        }
        return "";
    };

    const onChangeB2CCharger = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedB2CCharger(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasB2CCharger: Array<TypeUtils.ChartData> = [];

        if (selectedB2CCharger.includes(Common.B2C_CHARGER_VOLTAGE_SET_KEY)) chartDatasB2CCharger.push(chartB2CChargerVoltageSet);
        if (selectedB2CCharger.includes(Common.B2C_CHARGER_CURRENT_SET_KEY)) chartDatasB2CCharger.push(chartB2CChargerCurrentSet);
        if (selectedB2CCharger.includes(Common.B2C_CHARGER_CONTROL_KEY)) chartDatasB2CCharger.push(chartB2CChargerControl);

        setSeriesChartDatasB2CCharger(chartDatasB2CCharger);
    }, [selectedB2CCharger, chartB2CChargerVoltageSet, chartB2CChargerCurrentSet, chartB2CChargerControl]);

    const tableDataB2CCharger: CanTableType[] = [
        {
            key: Common.B2C_CHARGER_VOLTAGE_SET_KEY,
            name: Common.B2C_CHARGER_VOLTAGE_SET_NAME,
            value: getB2CChargerVoltageSetValue(),
        },
        {
            key: Common.B2C_CHARGER_CURRENT_SET_KEY,
            name: Common.B2C_CHARGER_CURRENT_SET_NAME,
            value: getB2CChargerCurrentSetValue(),
        },
        {
            key: Common.B2C_CHARGER_CONTROL_KEY,
            name: Common.B2C_CHARGER_CONTROL_NAME,
            value: getB2CChargerControlValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Working Command
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [workingCommandPanelOpen, setWorkingCommandPanelOpen] = useState(false);
    const [workingCommandChartMounted, setWorkingCommandChartMounted] = useState(false);
    const [selectedWorkingCommand, setSelectedWorkingCommand] = useState<Array<React.Key>>([]);

    const [seriesChartDatasWorkingCommand, setSeriesChartDatasWorkingCommand] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartWorkingCommandRunningCommandDcac1, setChartWorkingCommandRunningCommandDcac1] = useState<TypeUtils.ChartData>({
        name: Common.WORKING_COMMAND_RUNNING_COMMAND_DCAC1_NAME,
        data: [],
    });

    const [chartWorkingCommandResetCommandEhps, setChartWorkingCommandResetCommandEhps] = useState<TypeUtils.ChartData>({
        name: Common.WORKING_COMMAND_RESET_COMMAND_EHPS_NAME,
        data: [],
    });

    const [chartWorkingCommandSpeed, setChartWorkingCommandSpeed] = useState<TypeUtils.ChartData>({
        name: Common.WORKING_COMMAND_SPEED_NAME,
        data: [],
    });

    const getTabelTooltipWorkingCommand = (key: React.Key) => {
        switch (key) {
            case Common.WORKING_COMMAND_RUNNING_COMMAND_DCAC1_KEY:
                return (
                    <>
                        <p>
                            Working Command
                            <br />
                            CAN ID: 0x18A1E1D0
                            <br />
                            Name: DCAC1 실행지시
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-EHPS 중지
                            <br />
                            01-EHPS 실행
                        </p>
                    </>
                );

            case Common.WORKING_COMMAND_RESET_COMMAND_EHPS_KEY:
                return (
                    <>
                        <p>
                            Working Command
                            <br />
                            CAN ID: 0x18A1E1D0
                            <br />
                            Name: EHPS 재설정 지시
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-EHPS 재설정 안함
                            <br />
                            01-EHPS 재설정
                        </p>
                    </>
                );

            case Common.WORKING_COMMAND_SPEED_KEY:
                return (
                    <>
                        <p>
                            Working Command
                            <br />
                            CAN ID: 0x18A1E1D0
                            <br />
                            Name: 속도
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertWorkingCommandRunningCommandDcac1Remark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "EHPS 중지";
                break;
            case 1:
                valueString = "EHPS 실행";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertWorkingCommandResetCommandEhpsRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "EHPS 재설정 안함";
                break;
            case 1:
                valueString = "EHPS 재설정";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getWorkingCommandRunningCommandDcac1Value = () => {
        if (selectedWorkingCommand.includes(Common.WORKING_COMMAND_RUNNING_COMMAND_DCAC1_KEY) && currentCanData !== undefined) {
            return convertWorkingCommandRunningCommandDcac1Remark(currentCanData.workingCmd?.dcac1RunningCmd);
        }
        return "";
    };

    const getWorkingCommandResetCommandEhpsValue = () => {
        if (selectedWorkingCommand.includes(Common.WORKING_COMMAND_RESET_COMMAND_EHPS_KEY) && currentCanData !== undefined) {
            return convertWorkingCommandResetCommandEhpsRemark(currentCanData.workingCmd?.ehpsResetCmd);
        }
        return "";
    };

    const getWorkingCommandSpeedValue = () => {
        if (selectedWorkingCommand.includes(Common.WORKING_COMMAND_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.workingCmd?.speed, Common.UNIT_SPEED);
        }
        return "";
    };

    const onChangeWorkingCommand = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedWorkingCommand(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasWorkingCommand: Array<TypeUtils.ChartData> = [];

        if (selectedWorkingCommand.includes(Common.WORKING_COMMAND_RUNNING_COMMAND_DCAC1_KEY))
            chartDatasWorkingCommand.push(chartWorkingCommandRunningCommandDcac1);
        if (selectedWorkingCommand.includes(Common.WORKING_COMMAND_RESET_COMMAND_EHPS_KEY))
            chartDatasWorkingCommand.push(chartWorkingCommandResetCommandEhps);
        if (selectedWorkingCommand.includes(Common.WORKING_COMMAND_SPEED_KEY)) chartDatasWorkingCommand.push(chartWorkingCommandSpeed);

        setSeriesChartDatasWorkingCommand(chartDatasWorkingCommand);
    }, [selectedWorkingCommand, chartWorkingCommandRunningCommandDcac1, chartWorkingCommandResetCommandEhps, chartWorkingCommandSpeed]);

    const tableDataWorkingCommand: CanTableType[] = [
        {
            key: Common.WORKING_COMMAND_RUNNING_COMMAND_DCAC1_KEY,
            name: Common.WORKING_COMMAND_RUNNING_COMMAND_DCAC1_NAME,
            value: getWorkingCommandRunningCommandDcac1Value(),
        },
        {
            key: Common.WORKING_COMMAND_RESET_COMMAND_EHPS_KEY,
            name: Common.WORKING_COMMAND_RESET_COMMAND_EHPS_NAME,
            value: getWorkingCommandResetCommandEhpsValue(),
        },
        {
            key: Common.WORKING_COMMAND_SPEED_KEY,
            name: Common.WORKING_COMMAND_SPEED_NAME,
            value: getWorkingCommandSpeedValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region EHPS Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [ehpsStatusPanelOpen, setEhpsStatusPanelOpen] = useState(false);
    const [ehpsStatusChartMounted, setEhpsStatusChartMounted] = useState(false);
    const [selectedEhpsStatus, setSelectedEhpsStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasEhpsStatus, setSeriesChartDatasEhpsStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartEhpsStatusActualSpeed, setChartEhpsStatusActualSpeed] = useState<TypeUtils.ChartData>({
        name: Common.EHPS_STATUS_ACTUAL_SPEED_NAME,
        data: [],
    });

    const [chartEhpsStatusControllerTemperature, setChartEhpsStatusControllerTemperature] = useState<TypeUtils.ChartData>({
        name: Common.EHPS_STATUS_CONTROLLER_TEMPERATURE_NAME,
        data: [],
    });

    const [chartEhpsStatusStatus, setChartEhpsStatusStatus] = useState<TypeUtils.ChartData>({
        name: Common.EHPS_STATUS_STATUS_NAME,
        data: [],
    });

    const [chartEhpsStatusErrorLevel, setChartEhpsStatusErrorLevel] = useState<TypeUtils.ChartData>({
        name: Common.EHPS_STATUS_ERROR_LEVEL_NAME,
        data: [],
    });

    const [chartEhpsStatusErrorCode, setChartEhpsStatusErrorCode] = useState<TypeUtils.ChartData>({
        name: Common.EHPS_STATUS_ERROR_CODE_NAME,
        data: [],
    });

    const [chartEhpsStatus2Voltage, setChartEhpsStatus2Voltage] = useState<TypeUtils.ChartData>({
        name: Common.EHPS_STATUS2_VOLTAGE_NAME,
        data: [],
    });

    const [chartEhpsStatus2Current, setChartEhpsStatus2Current] = useState<TypeUtils.ChartData>({
        name: Common.EHPS_STATUS2_CURRENT_NAME,
        data: [],
    });

    const getTabelTooltipEhpsStatus = (key: React.Key) => {
        switch (key) {
            case Common.EHPS_STATUS_ACTUAL_SPEED_KEY:
                return (
                    <>
                        <p>
                            EHPS status
                            <br />
                            CAN ID: 0x18A2D0E1
                            <br />
                            Name: EHPS 실회전수
                        </p>
                    </>
                );

            case Common.EHPS_STATUS_CONTROLLER_TEMPERATURE_KEY:
                return (
                    <>
                        <p>
                            EHPS status
                            <br />
                            CAN ID: 0x18A2D0E1
                            <br />
                            Name: EHPS 컨트롤러 온도
                        </p>
                    </>
                );

            case Common.EHPS_STATUS_STATUS_KEY:
                return (
                    <>
                        <p>
                            EHPS status
                            <br />
                            CAN ID: 0x18A2D0E1
                            <br />
                            Name: EHPS 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정지, 01-준비중
                            <br />
                            10-운행, 11-고장
                        </p>
                    </>
                );

            case Common.EHPS_STATUS_ERROR_LEVEL_KEY:
                return (
                    <>
                        <p>
                            EHPS status
                            <br />
                            CAN ID: 0x18A2D0E1
                            <br />
                            Name: EHPS 오류 수준
                        </p>
                        <p>
                            Remarks:
                            <br />
                            00-정상
                            <br />
                            01-레벨 1 고장(경고, 알람)
                            <br />
                            10-2차 고장(경미한 고장)
                            <br />
                            전력제한(정격전력 이하)
                            <br />
                            11-3단계 고장(심각한 고장, 정지)
                        </p>
                    </>
                );

            case Common.EHPS_STATUS_ERROR_CODE_KEY:
                return (
                    <>
                        <p>
                            EHPS status
                            <br />
                            CAN ID: 0x18A2D0E1
                            <br />
                            Name: DCA1 고장 코드
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x00-정상
                            <br />
                            0x01-자가진단 이상
                            <br />
                            0x11-과압(입력단)
                            <br />
                            0x12-부족 전압(입력단)
                            <br />
                            0x13-과전류/단락(출력단)
                            <br />
                            0x14-회전 속도가 너무 높음
                            <br />
                            0x15-개방 회로 감지 이상
                            <br />
                            0x16-컨트롤러 온도가 너무 높음
                        </p>
                    </>
                );

            case Common.EHPS_STATUS2_VOLTAGE_KEY:
                return (
                    <>
                        <p>
                            EHPS status2
                            <br />
                            CAN ID: 0x18A3D0E1
                            <br />
                            Name: EHPS 전압
                        </p>
                    </>
                );

            case Common.EHPS_STATUS2_CURRENT_KEY:
                return (
                    <>
                        <p>
                            EHPS status2
                            <br />
                            CAN ID: 0x18A3D0E1
                            <br />
                            Name: EHPS 전류
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertEhpsStatusStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정지";
                break;
            case 1:
                valueString = "준비중";
                break;
            case 2:
                valueString = "운행";
                break;
            case 3:
                valueString = "고장";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertEhpsStatusErrorCodeRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0x00:
                valueString = "정상";
                break;
            case 0x01:
                valueString = "자가진단 이상";
                break;
            case 0x11:
                valueString = "과압(입력단)";
                break;
            case 0x12:
                valueString = "부족 전압(입력단)";
                break;
            case 0x13:
                valueString = "과전류/단락(출력단)";
                break;
            case 0x14:
                valueString = "회전 속도가 너무 높음";
                break;
            case 0x15:
                valueString = "개방 회로 감지 이상";
                break;
            case 0x16:
                valueString = "EHPS 컨트롤러 온도가 너무 높음";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getEhpsStatusActualSpeedValue = () => {
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_ACTUAL_SPEED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ehpsStatus?.realSpeed, Common.UNIT_MOTOR_SPEED);
        }
        return "";
    };

    const getEhpsStatusControllerTemperatureValue = () => {
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_CONTROLLER_TEMPERATURE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ehpsStatus?.controllerTemp, Common.UNIT_CELSIUS);
        }
        return "";
    };

    const getEhpsStatusStatusValue = () => {
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_STATUS_KEY) && currentCanData !== undefined) {
            return convertEhpsStatusStatusRemark(currentCanData.ehpsStatus?.status);
        }
        return "";
    };

    const getEhpsStatusErrorLevelValue = () => {
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_ERROR_LEVEL_KEY) && currentCanData !== undefined) {
            return convertErrorLevelRemark(currentCanData.ehpsStatus?.errLevel);
        }
        return "";
    };

    const getEhpsStatusErrorCodeValue = () => {
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_ERROR_CODE_KEY) && currentCanData !== undefined) {
            return convertEhpsStatusErrorCodeRemark(currentCanData.ehpsStatus?.errCode);
        }
        return "";
    };

    const getEhpsStatus2VoltageValue = () => {
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS2_VOLTAGE_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ehpsStatus2?.volt, Common.UNIT_VOLTAGE);
        }
        return "";
    };

    const getEhpsStatus2CurrentValue = () => {
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS2_CURRENT_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.ehpsStatus2?.curr, Common.UNIT_CURRENT);
        }
        return "";
    };

    const onChangeEhpsStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedEhpsStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasEhpsStatus: Array<TypeUtils.ChartData> = [];

        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_ACTUAL_SPEED_KEY)) chartDatasEhpsStatus.push(chartEhpsStatusActualSpeed);
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_CONTROLLER_TEMPERATURE_KEY))
            chartDatasEhpsStatus.push(chartEhpsStatusControllerTemperature);
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_STATUS_KEY)) chartDatasEhpsStatus.push(chartEhpsStatusStatus);
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_ERROR_LEVEL_KEY)) chartDatasEhpsStatus.push(chartEhpsStatusErrorLevel);
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS_ERROR_CODE_KEY)) chartDatasEhpsStatus.push(chartEhpsStatusErrorCode);
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS2_VOLTAGE_KEY)) chartDatasEhpsStatus.push(chartEhpsStatus2Voltage);
        if (selectedEhpsStatus.includes(Common.EHPS_STATUS2_CURRENT_KEY)) chartDatasEhpsStatus.push(chartEhpsStatus2Current);

        setSeriesChartDatasEhpsStatus(chartDatasEhpsStatus);
    }, [
        selectedEhpsStatus,
        chartEhpsStatusActualSpeed,
        chartEhpsStatusControllerTemperature,
        chartEhpsStatusStatus,
        chartEhpsStatusErrorLevel,
        chartEhpsStatusErrorCode,
        chartEhpsStatus2Voltage,
        chartEhpsStatus2Current,
    ]);

    const tableDataEhpsStatus: CanTableType[] = [
        {
            key: Common.EHPS_STATUS_ACTUAL_SPEED_KEY,
            name: Common.EHPS_STATUS_ACTUAL_SPEED_NAME,
            value: getEhpsStatusActualSpeedValue(),
        },
        {
            key: Common.EHPS_STATUS_CONTROLLER_TEMPERATURE_KEY,
            name: Common.EHPS_STATUS_CONTROLLER_TEMPERATURE_NAME,
            value: getEhpsStatusControllerTemperatureValue(),
        },
        {
            key: Common.EHPS_STATUS_STATUS_KEY,
            name: Common.EHPS_STATUS_STATUS_NAME,
            value: getEhpsStatusStatusValue(),
        },
        {
            key: Common.EHPS_STATUS_ERROR_LEVEL_KEY,
            name: Common.EHPS_STATUS_ERROR_LEVEL_NAME,
            value: getEhpsStatusErrorLevelValue(),
        },
        {
            key: Common.EHPS_STATUS_ERROR_CODE_KEY,
            name: Common.EHPS_STATUS_ERROR_CODE_NAME,
            value: getEhpsStatusErrorCodeValue(),
        },
        {
            key: Common.EHPS_STATUS2_VOLTAGE_KEY,
            name: Common.EHPS_STATUS2_VOLTAGE_NAME,
            value: getEhpsStatus2VoltageValue(),
        },
        {
            key: Common.EHPS_STATUS2_CURRENT_KEY,
            name: Common.EHPS_STATUS2_CURRENT_NAME,
            value: getEhpsStatus2CurrentValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    //#region Angle Sensor Status
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const [angleSensorStatusPanelOpen, setAngleSensorStatusPanelOpen] = useState(false);
    const [angleSensorStatusChartMounted, setAngleSensorStatusChartMounted] = useState(false);
    const [selectedAngleSensorStatus, setSelectedAngleSensorStatus] = useState<Array<React.Key>>([]);

    const [seriesChartDatasAngleSensorStatus, setSeriesChartDatasAngleSensorStatus] = useState<Array<TypeUtils.ChartData>>([]);

    const [chartAngleSensorStatusSteeringWheelAngle, setChartAngleSensorStatusSteeringWheelAngle] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_NAME,
        data: [],
    });

    const [chartAngleSensorStatusSteeringWheelSpeed, setChartAngleSensorStatusSteeringWheelSpeed] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPEED_NAME,
        data: [],
    });

    const [chartAngleSensorStatusSensorFailureSignal, setChartAngleSensorStatusSensorFailureSignal] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_NAME,
        data: [],
    });

    const [chartAngleSensorStatusVoltageWarningSignal, setChartAngleSensorStatusVoltageWarningSignal] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_NAME,
        data: [],
    });

    const [chartAngleSensorStatusAngularVelocitySignBit, setChartAngleSensorStatusAngularVelocitySignBit] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_NAME,
        data: [],
    });

    const [chartAngleSensorStatusCalibrationStatus, setChartAngleSensorStatusCalibrationStatus] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_NAME,
        data: [],
    });

    const [chartAngleSensorStatusAngularVelocityValidSignal, setChartAngleSensorStatusAngularVelocityValidSignal] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_NAME,
        data: [],
    });

    const [chartAngleSensorStatusSteeringAngleValidSignal, setChartAngleSensorStatusSteeringAngleValidSignal] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_NAME,
        data: [],
    });

    const [chartAngleSensorStatusCountSignal, setChartAngleSensorStatusCountSignal] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_COUNT_SIGNAL_NAME,
        data: [],
    });

    const [chartAngleSensorStatusCheck, setChartAngleSensorStatusCheck] = useState<TypeUtils.ChartData>({
        name: Common.ANGLE_SENSOR_STATUS_CHECK_NAME,
        data: [],
    });

    const getTabelTooltipAngleSensorStatus = (key: React.Key) => {
        switch (key) {
            case Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 핸들 각도
                        </p>
                        <p>
                            Remarks:
                            <br />
                            Y=kX
                            <br />
                            Y:실제 회전 각도
                            <br />
                            X:CAN_Msg 값
                            <br />
                            Y=0.1*X&#40;0&#60;X&#60;=32767&#41;
                            <br />
                            Y=0.1*&#40;X-65535&#41;&#40;X&#62;32767&#41;
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPPED_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 핸들 속도
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 센서 고장 신호
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x0-정상, 0x1: 무효
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 전압경고신호
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x00-정상
                            <br />
                            0x01-9V 미만 경보
                            <br />
                            0x10-16V 이상 경보
                            <br />
                            0x11-예약
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 회전 속도 부호 비트
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x0-정상, 0x1: 무효
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 교정 상태
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x0-0으로 표시되지 않음
                            <br />
                            0x1-0으로 표시됨
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 회전속도유효신호
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x0-유효하지 않음
                            <br />
                            0x1-유효
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 스티어링 각도 유효 신호
                        </p>
                        <p>
                            Remarks:
                            <br />
                            0x0-유효하지 않음
                            <br />
                            0x1-유효
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_COUNT_SIGNAL_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 계수 신호
                        </p>
                    </>
                );

            case Common.ANGLE_SENSOR_STATUS_CHECK_KEY:
                return (
                    <>
                        <p>
                            Angle Sensor Status
                            <br />
                            CAN ID: 0X18F01D48
                            <br />
                            Name: 체크
                        </p>
                    </>
                );
        }

        return null;
    };

    const convertAngleSensorStatusSensorFailureSignalRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "무효";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertAngleSensorStatusVoltageWarningSignalRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "9V 미만 경보";
                break;
            case 2:
                valueString = "16V 이상 경보";
                break;
            case 3:
                valueString = "예약";
                break;
            default:
                break;
        }
        return valueString;
    };

    const convertAngleSensorStatusAngularVelocitySignBitRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "정상";
                break;
            case 1:
                valueString = "무효";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertAngleSensorStatusCalibrationStatusRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "0으로 표시되지 않음";
                break;
            case 1:
                valueString = "0으로 표시됨";
                break;

            default:
                break;
        }
        return valueString;
    };

    const convertAngleSensorStatusValidRemark = (value: number) => {
        let valueString = String(value);
        switch (value) {
            case 0:
                valueString = "유효하지 않음";
                break;
            case 1:
                valueString = "유효";
                break;

            default:
                break;
        }
        return valueString;
    };

    const getAngleSensorStatusSteeringWheelAngleValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.angleSensorStatus?.steeringAngle, Common.UNIT_DEGREE);
        }
        return "";
    };

    const getAngleSensorStatusSteeringWheelSpeedValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPPED_KEY) && currentCanData !== undefined) {
            return convertRemarkWithUnit(currentCanData.angleSensorStatus?.steeringAngleVelocity, Common.UNIT_DEGREE_SPEED);
        }
        return "";
    };

    const getAngleSensorStatusSensorFailureSignalValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertAngleSensorStatusSensorFailureSignalRemark(currentCanData.angleSensorStatus?.failure);
        }
        return "";
    };

    const getAngleSensorStatusVoltageWarningSignalValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertAngleSensorStatusVoltageWarningSignalRemark(currentCanData.angleSensorStatus?.batWarn);
        }
        return "";
    };

    const getAngleSensorStatusAngularVelocitySignBitValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_KEY) && currentCanData !== undefined) {
            return convertAngleSensorStatusAngularVelocitySignBitRemark(currentCanData.angleSensorStatus?.steeringAngleVelocitySign);
        }
        return "";
    };

    const getAngleSensorStatusCalibrationStatusValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_KEY) && currentCanData !== undefined) {
            return convertAngleSensorStatusCalibrationStatusRemark(currentCanData.angleSensorStatus?.calibrated);
        }
        return "";
    };

    const getAngleSensorStatusAngularVelocityValidSignalValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertAngleSensorStatusValidRemark(currentCanData.angleSensorStatus?.steeringAngleSpeedValid);
        }
        return "";
    };

    const getAngleSensorStatusSteeringAngleValidSignalValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_KEY) && currentCanData !== undefined) {
            return convertAngleSensorStatusValidRemark(currentCanData.angleSensorStatus?.steeringAngleValid);
        }
        return "";
    };

    const getAngleSensorStatusCountSignalValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_COUNT_SIGNAL_KEY) && currentCanData !== undefined) {
            return String(currentCanData.angleSensorStatus?.msgCounter);
        }
        return "";
    };

    const getAngleSensorStatusCheckValue = () => {
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_CHECK_KEY) && currentCanData !== undefined) {
            return String(currentCanData.angleSensorStatus?.checksum);
        }
        return "";
    };

    const onChangeAngleSensorStatus = (selectedRowKeys: React.Key[], selectedRows: CanTableType[]) => {
        setSelectedAngleSensorStatus(selectedRowKeys);
    };

    useEffect(() => {
        const chartDatasAngleSensorStatus: Array<TypeUtils.ChartData> = [];

        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusSteeringWheelAngle);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPPED_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusSteeringWheelSpeed);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusSensorFailureSignal);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusVoltageWarningSignal);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusAngularVelocitySignBit);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusCalibrationStatus);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusAngularVelocityValidSignal);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusSteeringAngleValidSignal);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_COUNT_SIGNAL_KEY))
            chartDatasAngleSensorStatus.push(chartAngleSensorStatusCountSignal);
        if (selectedAngleSensorStatus.includes(Common.ANGLE_SENSOR_STATUS_CHECK_KEY)) chartDatasAngleSensorStatus.push(chartAngleSensorStatusCheck);

        setSeriesChartDatasAngleSensorStatus(chartDatasAngleSensorStatus);
    }, [
        selectedAngleSensorStatus,
        chartAngleSensorStatusSteeringWheelAngle,
        chartAngleSensorStatusSteeringWheelSpeed,
        chartAngleSensorStatusSensorFailureSignal,
        chartAngleSensorStatusVoltageWarningSignal,
        chartAngleSensorStatusAngularVelocitySignBit,
        chartAngleSensorStatusCalibrationStatus,
        chartAngleSensorStatusAngularVelocityValidSignal,
        chartAngleSensorStatusSteeringAngleValidSignal,
        chartAngleSensorStatusCountSignal,
        chartAngleSensorStatusCheck,
    ]);

    const tableDataAngleSensorStatus: CanTableType[] = [
        {
            key: Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_KEY,
            name: Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_NAME,
            value: getAngleSensorStatusSteeringWheelAngleValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPPED_KEY,
            name: Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPEED_NAME,
            value: getAngleSensorStatusSteeringWheelSpeedValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_KEY,
            name: Common.ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_NAME,
            value: getAngleSensorStatusSensorFailureSignalValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_KEY,
            name: Common.ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_NAME,
            value: getAngleSensorStatusVoltageWarningSignalValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_KEY,
            name: Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_NAME,
            value: getAngleSensorStatusAngularVelocitySignBitValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_KEY,
            name: Common.ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_NAME,
            value: getAngleSensorStatusCalibrationStatusValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_KEY,
            name: Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_NAME,
            value: getAngleSensorStatusAngularVelocityValidSignalValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_KEY,
            name: Common.ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_NAME,
            value: getAngleSensorStatusSteeringAngleValidSignalValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_COUNT_SIGNAL_KEY,
            name: Common.ANGLE_SENSOR_STATUS_COUNT_SIGNAL_NAME,
            value: getAngleSensorStatusCountSignalValue(),
        },
        {
            key: Common.ANGLE_SENSOR_STATUS_CHECK_KEY,
            name: Common.ANGLE_SENSOR_STATUS_CHECK_NAME,
            value: getAngleSensorStatusCheckValue(),
        },
    ];
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    //#endregion

    useEffect(() => {
        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Yaxing) {
            //#region Datas
            const batteryInformationVoltageDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationCurrentDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationSocDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationSohDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationOutputStatusDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationChargingConnectStatusDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationChargingStatusDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationErrorLevelDatas: TypeUtils.ChartElement[] = [];
            const batteryInformationErrorCodeDatas: TypeUtils.ChartElement[] = [];

            const cellVoltageBoxNumberDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageMonomerStartingNumberDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCell1VoltageDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCell2VoltageDatas: TypeUtils.ChartElement[] = [];
            const cellVoltageCell3VoltageDatas: TypeUtils.ChartElement[] = [];

            const cellTemperatureBoxNumberDatas: TypeUtils.ChartElement[] = [];
            const cellTemperatureCellNumberDatas: TypeUtils.ChartElement[] = [];
            const cellTemperatureCell1TemperatureDatas: TypeUtils.ChartElement[] = [];
            const cellTemperatureCell2TemperatureDatas: TypeUtils.ChartElement[] = [];
            const cellTemperatureCell3TemperatureDatas: TypeUtils.ChartElement[] = [];
            const cellTemperatureCell4TemperatureDatas: TypeUtils.ChartElement[] = [];
            const cellTemperatureCell5TemperatureDatas: TypeUtils.ChartElement[] = [];
            const cellTemperatureCell6TemperatureDatas: TypeUtils.ChartElement[] = [];

            const highestLowestCellVoltageHighestValueDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellVoltageCellNumberWithHVDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellVoltageBoxNumberWithHVDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellVoltageLowestValueDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellVoltageCellNumberWithLVDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellVoltageBoxNumberWithLVDatas: TypeUtils.ChartElement[] = [];

            const highestLowestCellTemperatureHighestValueDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellTemperatureCellNumberWithHVDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellTemperatureBoxNumberWithHVDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellTemperatureLowestValueDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellTemperatureCellNumberWithLVDatas: TypeUtils.ChartElement[] = [];
            const highestLowestCellTemperatureBoxNumberWithLVDatas: TypeUtils.ChartElement[] = [];
            // const highestLowestCellTemperatureHighestPoleTemperatureDatas: TypeUtils.ChartElement[] = [];
            // const highestLowestCellTemperatureLocationDatas: TypeUtils.ChartElement[] = [];

            const batteryLimit1MaximumCellVoltageDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit1MinimumCellVoltageDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit1MaximumSocDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit1MinimumSocDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit1MaximumCellTemperatureDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit1MinimumCellTemperatureDatas: TypeUtils.ChartElement[] = [];

            const batteryLimit2MaximumChargingCurrentDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit2MaximumDischargingCurrentDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit2MaximumChargingVoltageDatas: TypeUtils.ChartElement[] = [];
            const batteryLimit2MinimumDischargingVoltageDatas: TypeUtils.ChartElement[] = [];

            const batteryRequestContactorShuttingRequestDatas: TypeUtils.ChartElement[] = [];
            const batteryRequestUltraCapacitorReferenceVoltageDatas: TypeUtils.ChartElement[] = [];

            const batterySubsystemsNumberDatas: TypeUtils.ChartElement[] = [];
            const batterySubsystemsCellNumberDatas: TypeUtils.ChartElement[] = [];
            const batterySubsystemsTempCheckNumberDatas: TypeUtils.ChartElement[] = [];
            const batterySubsystemsChargingPowerDatas: TypeUtils.ChartElement[] = [];
            const batterySubsystemsChargingTimeDatas: TypeUtils.ChartElement[] = [];

            const insulationStatusMonitorStatusDatas: TypeUtils.ChartElement[] = [];
            const insulationStatusResistanceDatas: TypeUtils.ChartElement[] = [];
            const insulationStatusErrorLevelDatas: TypeUtils.ChartElement[] = [];
            const insulationStatusErrorCodeDatas: TypeUtils.ChartElement[] = [];

            const accumulatedCapacityDischargePowerDatas: TypeUtils.ChartElement[] = [];
            const accumulatedCapacityChargePowerDatas: TypeUtils.ChartElement[] = [];

            const dcdcStatusUnderOutputVoltageDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusOverOutputVoltageDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusUnderInputVoltageDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusOverInputVoltageDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusHardwareFaultFlagDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusOperatingModeDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusDeratingDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusOverInputCurrentDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusOverTemperatureDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusOverOutputCurrentDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusRealOutputCurrentDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusRealityTemperatureDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusRealOutputVoltageDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusRealInputVoltageDatas: TypeUtils.ChartElement[] = [];
            const dcdcStatusVersionDatas: TypeUtils.ChartElement[] = [];

            const dcdcWorkCommandEnableDatas: TypeUtils.ChartElement[] = [];
            const dcdcWorkCommandVoltageRequestDatas: TypeUtils.ChartElement[] = [];

            const tmpsPressureStatusLeftFrontDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusRightFrontDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusLeftRearDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusRightRearDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusLeftFrontStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusRightFrontStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusLeftRearStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusRightRearStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusAcquisitionStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusTireMonitorSystemStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsPressureStatusSendCycleDatas: TypeUtils.ChartElement[] = [];

            const tmpsTemperatureStatusLeftFrontDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusRightFrontDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusLeftRearDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusRightRearDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusLeftFrontStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusRightFrontStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusLeftRearStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusRightRearStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusAcquisitionStatusDatas: TypeUtils.ChartElement[] = [];
            const tmpsTemperatureStatusSendCycleDatas: TypeUtils.ChartElement[] = [];

            const lampSignalHandbrakeStatusDatas: TypeUtils.ChartElement[] = [];
            const lampSignalDriverSeatBeltSwitchDatas: TypeUtils.ChartElement[] = [];
            const lampSignalLowBeamDatas: TypeUtils.ChartElement[] = [];
            const lampSignalHighBeamDatas: TypeUtils.ChartElement[] = [];
            const lampSignalFrontFogDatas: TypeUtils.ChartElement[] = [];
            const lampSignalRearFogDatas: TypeUtils.ChartElement[] = [];
            const lampSignalRightTurnDatas: TypeUtils.ChartElement[] = [];
            const lampSignalLeftTurnDatas: TypeUtils.ChartElement[] = [];
            const lampSignalPositionDatas: TypeUtils.ChartElement[] = [];
            const lampSignalHazardWarningDatas: TypeUtils.ChartElement[] = [];
            const lampSignalLeftFrontDoorDatas: TypeUtils.ChartElement[] = [];
            const lampSignalRightFrontDoorDatas: TypeUtils.ChartElement[] = [];
            const lampSignalLeftMiddleDoorDatas: TypeUtils.ChartElement[] = [];
            const lampSignalRightMiddleDoorDatas: TypeUtils.ChartElement[] = [];
            const lampSignalTailGateDatas: TypeUtils.ChartElement[] = [];

            const vehicleSpeedSpeedDatas: TypeUtils.ChartElement[] = [];
            const vehicleSpeedInstantaneousPowerConsumptionDatas: TypeUtils.ChartElement[] = [];
            const vehicleSpeedDriveMotorSpeedDatas: TypeUtils.ChartElement[] = [];
            const vehicleSpeedHighVoltagePrechargeDatas: TypeUtils.ChartElement[] = [];
            const vehicleSpeedRechargeMileageDatas: TypeUtils.ChartElement[] = [];

            const electronicMessageDrivingModeDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageGearPositionDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageHighVoltageControllerStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageHighVoltageControllerFaultCodeDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageVehicleControllerVcuStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageVehicleControllerDefrostStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageVehicleControllerAcceleratorPedalStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageVehicleControllerBrakePedalStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageVehicleElectronicSystemFailureDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageDcDcStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageDriveMotorPowerLimitDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageCoolingWaterPumpStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageSteeringOilPumpStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageAirPumpStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageAirConditionerStatusDatas: TypeUtils.ChartElement[] = [];
            const electronicMessageOperationReadyIndicatorDatas: TypeUtils.ChartElement[] = [];

            const motorStatusRunningStatusDatas: TypeUtils.ChartElement[] = [];
            const motorStatusRotationDirectionDatas: TypeUtils.ChartElement[] = [];
            const motorStatusErrorLevelDatas: TypeUtils.ChartElement[] = [];
            const motorStatusErrorCodeDatas: TypeUtils.ChartElement[] = [];
            const motorStatusCoolingWaterTemperatureDatas: TypeUtils.ChartElement[] = [];
            const motorStatusControllerTemperatureDatas: TypeUtils.ChartElement[] = [];
            const motorStatusOutputPowerPercentageDatas: TypeUtils.ChartElement[] = [];
            const motorStatusBrakeRecoveryPowerPercentageDatas: TypeUtils.ChartElement[] = [];
            const motorStatusBrakeRecoveryWorkingStatusDatas: TypeUtils.ChartElement[] = [];

            const vehicleMileageAccumulatedMileageDatas: TypeUtils.ChartElement[] = [];
            const vehicleMileageCurrentMileageDatas: TypeUtils.ChartElement[] = [];

            const b2CChargerVoltageSetDatas: TypeUtils.ChartElement[] = [];
            const b2CChargerCurrentSetDatas: TypeUtils.ChartElement[] = [];
            const b2CChargerControlDatas: TypeUtils.ChartElement[] = [];

            const workingCommandRunningCommandDcac1Datas: TypeUtils.ChartElement[] = [];
            const workingCommandResetCommandEhpsDatas: TypeUtils.ChartElement[] = [];
            const workingCommandSpeedDatas: TypeUtils.ChartElement[] = [];

            const ehpsStatusActualSpeedDatas: TypeUtils.ChartElement[] = [];
            const ehpsStatusControllerTemperatureDatas: TypeUtils.ChartElement[] = [];
            const ehpsStatusStatusDatas: TypeUtils.ChartElement[] = [];
            const ehpsStatusErrorLevelDatas: TypeUtils.ChartElement[] = [];
            const ehpsStatusErrorCodeDatas: TypeUtils.ChartElement[] = [];
            const ehpsStatus2VoltageDatas: TypeUtils.ChartElement[] = [];
            const ehpsStatus2CurrentDatas: TypeUtils.ChartElement[] = [];

            const angleSensorStatusSteeringWheelAngleDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusSteeringWheelSpeedDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusSensorFailureSignalDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusVoltageWarningSignalDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusAngularVelocitySignBitDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusCalibrationStatusDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusAngularVelocityValidSignalDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusSteeringAngleValidSignalDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusCountSignalDatas: TypeUtils.ChartElement[] = [];
            const angleSensorStatusCheckDatas: TypeUtils.ChartElement[] = [];
            //#endregion

            runningCanDataList?.forEach((data) => {
                const canData = data as CanDataYaxing.CanData;

                const time = moment(canData.dataTime, Common.FORMAT_DATE_TIME_MILLISECOND);

                //#region Battery Information
                batteryInformationVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.volt || 0,
                });

                batteryInformationCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.curr || 0,
                });

                batteryInformationSocDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.soc || 0,
                });

                batteryInformationSohDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.soh || 0,
                });

                batteryInformationOutputStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.outputStatus || 0,
                });

                batteryInformationChargingConnectStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.chargeConnectStatus || 0,
                });

                batteryInformationChargingStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.chargeStatus || 0,
                });

                batteryInformationErrorLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.errLevel || 0,
                });

                batteryInformationErrorCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.batInfo?.errCode || 0,
                });
                //#endregion

                //#region Cell Voltage
                cellVoltageBoxNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.boxNo || 0,
                });

                cellVoltageMonomerStartingNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.cellNo || 0,
                });

                cellVoltageCell1VoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.cell1 || 0,
                });

                cellVoltageCell2VoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.cell2 || 0,
                });

                cellVoltageCell3VoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.cellVolt?.cell3 || 0,
                });
                //#endregion

                //#region Cell Temperature
                cellTemperatureBoxNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.boxNo || 0,
                });
                cellTemperatureCellNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.cellNo || 0,
                });
                cellTemperatureCell1TemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.cell1 || 0,
                });

                cellTemperatureCell2TemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.cell2 || 0,
                });

                cellTemperatureCell3TemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.cell3 || 0,
                });

                cellTemperatureCell4TemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.cell4 || 0,
                });

                cellTemperatureCell5TemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.cell5 || 0,
                });

                cellTemperatureCell6TemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.cellTemp?.cell6 || 0,
                });
                //#endregion

                //#region Highest Lowest Cell Voltage
                highestLowestCellVoltageHighestValueDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellVolt?.highest || 0,
                });

                highestLowestCellVoltageCellNumberWithHVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellVolt?.highestCellNo || 0,
                });

                highestLowestCellVoltageBoxNumberWithHVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellVolt?.highestBoxNo || 0,
                });

                highestLowestCellVoltageLowestValueDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellVolt?.lowest || 0,
                });

                highestLowestCellVoltageCellNumberWithLVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellVolt?.lowestCellNo || 0,
                });

                highestLowestCellVoltageBoxNumberWithLVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellVolt?.lowestBoxNo || 0,
                });
                //#endregion

                //#region Highest Lowest Cell Temperature
                highestLowestCellTemperatureHighestValueDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellTemp?.highest || 0,
                });

                highestLowestCellTemperatureCellNumberWithHVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellTemp?.highestCellNo || 0,
                });

                highestLowestCellTemperatureBoxNumberWithHVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellTemp?.highestBoxNo || 0,
                });

                highestLowestCellTemperatureLowestValueDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellTemp?.lowest || 0,
                });

                highestLowestCellTemperatureCellNumberWithLVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellTemp?.lowestCellNo || 0,
                });

                highestLowestCellTemperatureBoxNumberWithLVDatas.push({
                    x: time.valueOf(),
                    y: canData.highLowCellTemp?.lowestBoxNo || 0,
                });

                // highestLowestCellTemperatureHighestPoleTemperatureDatas.push({
                //     x: time.valueOf(),
                //     y: canData.highLowCellTemp?.highestPoleTemperature,
                // });

                // highestLowestCellTemperatureLocationDatas.push({
                //     x: time.valueOf(),
                //     y: canData.highLowCellTemp?.location,
                // });
                //#endregion

                //#region Battery Limit 1
                batteryLimit1MaximumCellVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit1?.maxCellVolt || 0,
                });

                batteryLimit1MinimumCellVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit1?.minCellVolt || 0,
                });

                batteryLimit1MaximumSocDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit1?.maxSoc || 0,
                });
                batteryLimit1MinimumSocDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit1?.minSoc || 0,
                });
                batteryLimit1MaximumCellTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit1?.maxCellTemp || 0,
                });

                batteryLimit1MinimumCellTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit1?.minCellTemp || 0,
                });
                //#endregion

                //#region Battery Limit 2
                batteryLimit2MaximumChargingCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit2?.maxChargeCurr || 0,
                });

                batteryLimit2MaximumDischargingCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit2?.maxDischargeCurr || 0,
                });

                batteryLimit2MaximumChargingVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit2?.maxChargeVolt || 0,
                });

                batteryLimit2MinimumDischargingVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.batLimit2?.minDischargeVolt || 0,
                });
                //#endregion

                //#region Battery Request
                batteryRequestContactorShuttingRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.batReq?.contactorShutting || 0,
                });

                batteryRequestUltraCapacitorReferenceVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.batReq?.ultraCapaRefVolt || 0,
                });
                //#endregion

                //#region Battery Subsystems
                batterySubsystemsNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.batSubsys?.no || 0,
                });

                batterySubsystemsCellNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.batSubsys?.cellNo || 0,
                });

                batterySubsystemsTempCheckNumberDatas.push({
                    x: time.valueOf(),
                    y: canData.batSubsys?.tempCheckNo || 0,
                });

                batterySubsystemsChargingPowerDatas.push({
                    x: time.valueOf(),
                    y: canData.batSubsys?.chargePower || 0,
                });

                batterySubsystemsChargingTimeDatas.push({
                    x: time.valueOf(),
                    y: canData.batSubsys?.chargeTime || 0,
                });
                //#endregion

                //#region Insulation Status
                insulationStatusMonitorStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.insulStatus?.monitorStatus || 0,
                });

                insulationStatusResistanceDatas.push({
                    x: time.valueOf(),
                    y: canData.insulStatus?.resistance || 0,
                });

                insulationStatusErrorLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.insulStatus?.monitorErrLevel || 0,
                });

                insulationStatusErrorCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.insulStatus?.monitorErrCode || 0,
                });
                //#endregion

                //#region Accumulated Capacity
                accumulatedCapacityDischargePowerDatas.push({
                    x: time.valueOf(),
                    y: canData.accumulatedCapa?.discharge || 0,
                });

                accumulatedCapacityChargePowerDatas.push({
                    x: time.valueOf(),
                    y: canData.accumulatedCapa?.charge || 0,
                });
                //#endregion

                //#region DCDC Status
                dcdcStatusUnderOutputVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.underOutputVolt || 0,
                });

                dcdcStatusOverOutputVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.overOutputVolt || 0,
                });

                dcdcStatusUnderInputVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.underInputVolt || 0,
                });

                dcdcStatusOverInputVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.overInputVolt || 0,
                });

                dcdcStatusHardwareFaultFlagDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.hwFaultFlag || 0,
                });

                dcdcStatusOperatingModeDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.opMode || 0,
                });

                dcdcStatusDeratingDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.derating || 0,
                });

                dcdcStatusOverInputCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.overInputCurr || 0,
                });

                dcdcStatusOverTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.overTemp || 0,
                });

                dcdcStatusOverOutputCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.overOutputCurr || 0,
                });

                dcdcStatusRealOutputCurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.realOutputCurr || 0,
                });

                dcdcStatusRealityTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.realityTemp || 0,
                });

                dcdcStatusRealOutputVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.realOutputVolt || 0,
                });

                dcdcStatusRealInputVoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.realInputVolt || 0,
                });

                dcdcStatusVersionDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcStatus?.ver || 0,
                });
                //#endregion

                //#region DCDC Work Command
                dcdcWorkCommandEnableDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcWorkCmd?.enable || 0,
                });
                dcdcWorkCommandVoltageRequestDatas.push({
                    x: time.valueOf(),
                    y: canData.dcdcWorkCmd?.voltReq || 0,
                });
                //#endregion

                //#region TMPS Pressure Status
                tmpsPressureStatusLeftFrontDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.leftFront || 0,
                });

                tmpsPressureStatusRightFrontDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.rightFront || 0,
                });

                tmpsPressureStatusLeftRearDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.leftRear || 0,
                });

                tmpsPressureStatusRightRearDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.rightRear || 0,
                });

                tmpsPressureStatusLeftFrontStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.leftFrontStatus || 0,
                });

                tmpsPressureStatusRightFrontStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.rightFrontStatus || 0,
                });

                tmpsPressureStatusLeftRearStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.leftRearStatus || 0,
                });

                tmpsPressureStatusRightRearStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.rightRearStatus || 0,
                });

                tmpsPressureStatusAcquisitionStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.collectStatus || 0,
                });

                tmpsPressureStatusTireMonitorSystemStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.monitoringStatus || 0,
                });

                tmpsPressureStatusSendCycleDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsPressureStatus?.heartBeat || 0,
                });
                //#endregion

                //#region TMPS Temperature Status
                tmpsTemperatureStatusLeftFrontDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.leftFront || 0,
                });

                tmpsTemperatureStatusRightFrontDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.rightFront || 0,
                });

                tmpsTemperatureStatusLeftRearDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.leftRear || 0,
                });

                tmpsTemperatureStatusRightRearDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.rightRear || 0,
                });

                tmpsTemperatureStatusLeftFrontStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.leftFrontStatus || 0,
                });

                tmpsTemperatureStatusRightFrontStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.rightFrontStatus || 0,
                });

                tmpsTemperatureStatusLeftRearStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.leftRearStatus || 0,
                });

                tmpsTemperatureStatusRightRearStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.rightRearStatus || 0,
                });

                tmpsTemperatureStatusAcquisitionStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.collectStatus || 0,
                });

                tmpsTemperatureStatusSendCycleDatas.push({
                    x: time.valueOf(),
                    y: canData.tmpsTempStatus?.heartBeat || 0,
                });
                //#endregion

                //#region Lamp Signal
                lampSignalHandbrakeStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.handbrake || 0,
                });

                lampSignalDriverSeatBeltSwitchDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.seatbeltSwitch || 0,
                });

                lampSignalLowBeamDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.lowBeam || 0,
                });

                lampSignalHighBeamDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.highBeam || 0,
                });

                lampSignalFrontFogDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.frontFog || 0,
                });

                lampSignalRearFogDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.rearFog || 0,
                });

                lampSignalRightTurnDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.rightTurn || 0,
                });

                lampSignalLeftTurnDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.leftTurn || 0,
                });

                lampSignalPositionDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.position || 0,
                });

                lampSignalHazardWarningDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.hazardWarn || 0,
                });

                lampSignalLeftFrontDoorDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.leftDoor || 0,
                });
                lampSignalRightFrontDoorDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.rightDoor || 0,
                });

                lampSignalLeftMiddleDoorDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.middleDoor || 0,
                });

                lampSignalRightMiddleDoorDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.rightMiddleDoor || 0,
                });

                lampSignalTailGateDatas.push({
                    x: time.valueOf(),
                    y: canData.lampSignal?.backDoor || 0,
                });
                //#endregion

                //#region Vehicle Speed
                vehicleSpeedSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleSpeed?.speed || 0,
                });

                vehicleSpeedInstantaneousPowerConsumptionDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleSpeed?.powerUsage || 0,
                });

                vehicleSpeedDriveMotorSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleSpeed?.motorRpm || 0,
                });

                vehicleSpeedHighVoltagePrechargeDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleSpeed?.prechargeVolt || 0,
                });

                vehicleSpeedRechargeMileageDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleSpeed?.cruisingDistance || 0,
                });
                //#endregion

                //#region Electronic Message
                electronicMessageDrivingModeDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.drivingMode || 0,
                });

                electronicMessageGearPositionDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.gearPosition || 0,
                });

                electronicMessageHighVoltageControllerStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.hiacStatus || 0,
                });

                electronicMessageHighVoltageControllerFaultCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.hiacErrCode || 0,
                });

                electronicMessageVehicleControllerVcuStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.vcuStatus || 0,
                });

                electronicMessageVehicleControllerDefrostStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.defrostStatus || 0,
                });

                electronicMessageVehicleControllerAcceleratorPedalStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.acceleratorPedalStatus || 0,
                });

                electronicMessageVehicleControllerBrakePedalStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.brakePedalStatus || 0,
                });

                electronicMessageVehicleElectronicSystemFailureDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.electricSysFail || 0,
                });

                electronicMessageDcDcStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.dcDcStatus || 0,
                });

                electronicMessageDriveMotorPowerLimitDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.motorPowerLimit || 0,
                });

                electronicMessageCoolingWaterPumpStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.coolingWaterPumpStatus || 0,
                });

                electronicMessageSteeringOilPumpStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.steeringOilPumpStatus || 0,
                });

                electronicMessageAirPumpStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.airPumpStatus || 0,
                });

                electronicMessageAirConditionerStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.airConditionerStatus || 0,
                });

                electronicMessageOperationReadyIndicatorDatas.push({
                    x: time.valueOf(),
                    y: canData.electricalMsg?.readyIndicator || 0,
                });
                //#endregion

                //#region Motor Status
                motorStatusRunningStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.status || 0,
                });

                motorStatusRotationDirectionDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.rotationDirection || 0,
                });

                motorStatusErrorLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.errLevel || 0,
                });

                motorStatusErrorCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.errCode || 0,
                });

                motorStatusCoolingWaterTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.coolingWaterTemp || 0,
                });

                motorStatusControllerTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.controllerTemp || 0,
                });

                motorStatusOutputPowerPercentageDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.outputPercent || 0,
                });

                motorStatusBrakeRecoveryPowerPercentageDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.brakeRecoveryPowerPercent || 0,
                });

                motorStatusBrakeRecoveryWorkingStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.motorStatus?.brakeRecoveryOpCondition || 0,
                });
                //#endregion

                //#region Vehicle Mileage
                vehicleMileageAccumulatedMileageDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleMileage?.totalMileage || 0,
                });

                vehicleMileageCurrentMileageDatas.push({
                    x: time.valueOf(),
                    y: canData.vehicleMileage?.currMileage || 0,
                });
                //#endregion

                //#region B2C Charger
                b2CChargerVoltageSetDatas.push({
                    x: time.valueOf(),
                    y: canData.b2CCharger?.voltSet || 0,
                });

                b2CChargerCurrentSetDatas.push({
                    x: time.valueOf(),
                    y: canData.b2CCharger?.currSet || 0,
                });

                b2CChargerControlDatas.push({
                    x: time.valueOf(),
                    y: canData.b2CCharger?.chargeControl || 0,
                });
                //#endregion

                //#region Working Command
                workingCommandRunningCommandDcac1Datas.push({
                    x: time.valueOf(),
                    y: canData.workingCmd?.dcac1RunningCmd || 0,
                });

                workingCommandResetCommandEhpsDatas.push({
                    x: time.valueOf(),
                    y: canData.workingCmd?.ehpsResetCmd || 0,
                });

                workingCommandSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.workingCmd?.speed || 0,
                });
                //#endregion

                //#region EHPS Status
                ehpsStatusActualSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.ehpsStatus?.realSpeed || 0,
                });

                ehpsStatusControllerTemperatureDatas.push({
                    x: time.valueOf(),
                    y: canData.ehpsStatus?.controllerTemp || 0,
                });

                ehpsStatusStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.ehpsStatus?.status || 0,
                });

                ehpsStatusErrorLevelDatas.push({
                    x: time.valueOf(),
                    y: canData.ehpsStatus?.errLevel || 0,
                });

                ehpsStatusErrorCodeDatas.push({
                    x: time.valueOf(),
                    y: canData.ehpsStatus?.errCode || 0,
                });

                ehpsStatus2VoltageDatas.push({
                    x: time.valueOf(),
                    y: canData.ehpsStatus2?.volt || 0,
                });
                ehpsStatus2CurrentDatas.push({
                    x: time.valueOf(),
                    y: canData.ehpsStatus2?.curr || 0,
                });
                //#endregion

                //#region Angle Sensor Status
                angleSensorStatusSteeringWheelAngleDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.steeringAngle || 0,
                });

                angleSensorStatusSteeringWheelSpeedDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.steeringAngleVelocity || 0,
                });

                angleSensorStatusSensorFailureSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.failure || 0,
                });

                angleSensorStatusVoltageWarningSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.batWarn || 0,
                });

                angleSensorStatusAngularVelocitySignBitDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.steeringAngleVelocitySign || 0,
                });

                angleSensorStatusCalibrationStatusDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.calibrated || 0,
                });

                angleSensorStatusAngularVelocityValidSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.steeringAngleSpeedValid || 0,
                });

                angleSensorStatusSteeringAngleValidSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.steeringAngleValid || 0,
                });

                angleSensorStatusCountSignalDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.msgCounter || 0,
                });

                angleSensorStatusCheckDatas.push({
                    x: time.valueOf(),
                    y: canData.angleSensorStatus?.checksum || 0,
                });
                //#endregion
            });

            //#region Battery Information
            setChartBatteryInformationVoltage({
                ...chartBatteryInformationVoltage,
                data: batteryInformationVoltageDatas,
            });

            setChartBatteryInformationCurrent({
                ...chartBatteryInformationCurrent,
                data: batteryInformationCurrentDatas,
            });

            setChartBatteryInformationSoc({
                ...chartBatteryInformationSoc,
                data: batteryInformationSocDatas,
            });

            setChartBatteryInformationSoh({
                ...chartBatteryInformationSoh,
                data: batteryInformationSohDatas,
            });

            setChartBatteryInformationOutputStatus({
                ...chartBatteryInformationOutputStatus,
                data: batteryInformationOutputStatusDatas,
            });

            setChartBatteryInformationChargingConnectStatus({
                ...chartBatteryInformationChargingConnectStatus,
                data: batteryInformationChargingConnectStatusDatas,
            });

            setChartBatteryInformationChargingStatus({
                ...chartBatteryInformationChargingStatus,
                data: batteryInformationChargingStatusDatas,
            });

            setChartBatteryInformationErrorLevel({
                ...chartBatteryInformationErrorLevel,
                data: batteryInformationErrorLevelDatas,
            });

            setChartBatteryInformationErrorCode({
                ...chartBatteryInformationErrorCode,
                data: batteryInformationErrorCodeDatas,
            });
            //#endregion

            //#region Cell Voltage
            setChartCellVoltageBoxNumber({
                ...chartCellVoltageBoxNumber,
                data: cellVoltageBoxNumberDatas,
            });

            setChartCellVoltageMonomerStartingNumber({
                ...chartCellVoltageMonomerStartingNumber,
                data: cellVoltageMonomerStartingNumberDatas,
            });

            setChartCellVoltageCell1Voltage({
                ...chartCellVoltageCell1Voltage,
                data: cellVoltageCell1VoltageDatas,
            });

            setChartCellVoltageCell2Voltage({
                ...chartCellVoltageCell2Voltage,
                data: cellVoltageCell2VoltageDatas,
            });

            setChartCellVoltageCell3Voltage({
                ...chartCellVoltageCell3Voltage,
                data: cellVoltageCell3VoltageDatas,
            });
            //#endregion

            //#region Cell Temperature
            setChartCellTemperatureBoxNumber({
                ...chartCellTemperatureBoxNumber,
                data: cellTemperatureBoxNumberDatas,
            });

            setChartCellTemperatureCellNumber({
                ...chartCellTemperatureCellNumber,
                data: cellTemperatureCellNumberDatas,
            });

            setChartCellTemperatureCell1Temperature({
                ...chartCellTemperatureCell1Temperature,
                data: cellTemperatureCell1TemperatureDatas,
            });

            setChartCellTemperatureCell2Temperature({
                ...chartCellTemperatureCell2Temperature,
                data: cellTemperatureCell2TemperatureDatas,
            });

            setChartCellTemperatureCell3Temperature({
                ...chartCellTemperatureCell3Temperature,
                data: cellTemperatureCell3TemperatureDatas,
            });

            setChartCellTemperatureCell4Temperature({
                ...chartCellTemperatureCell4Temperature,
                data: cellTemperatureCell4TemperatureDatas,
            });

            setChartCellTemperatureCell5Temperature({
                ...chartCellTemperatureCell5Temperature,
                data: cellTemperatureCell5TemperatureDatas,
            });

            setChartCellTemperatureCell6Temperature({
                ...chartCellTemperatureCell6Temperature,
                data: cellTemperatureCell6TemperatureDatas,
            });
            //#endregion

            //#region Highest Lowest Cell Voltage
            setChartHighestLowestCellVoltageHighestValue({
                ...chartHighestLowestCellVoltageHighestValue,
                data: highestLowestCellVoltageHighestValueDatas,
            });

            setChartHighestLowestCellVoltageCellNumberWithHV({
                ...chartHighestLowestCellVoltageCellNumberWithHV,
                data: highestLowestCellVoltageCellNumberWithHVDatas,
            });

            setChartHighestLowestCellVoltageBoxNumberWithHV({
                ...chartHighestLowestCellVoltageBoxNumberWithHV,
                data: highestLowestCellVoltageBoxNumberWithHVDatas,
            });

            setChartHighestLowestCellVoltageLowestValue({
                ...chartHighestLowestCellVoltageLowestValue,
                data: highestLowestCellVoltageLowestValueDatas,
            });

            setChartHighestLowestCellVoltageCellNumberWithLV({
                ...chartHighestLowestCellVoltageCellNumberWithLV,
                data: highestLowestCellVoltageCellNumberWithLVDatas,
            });

            setChartHighestLowestCellVoltageBoxNumberWithLV({
                ...chartHighestLowestCellVoltageBoxNumberWithLV,
                data: highestLowestCellVoltageBoxNumberWithLVDatas,
            });
            //#endregion

            //#region Highest Lowest Cell Temperature
            setChartHighestLowestCellTemperatureHighestValue({
                ...chartHighestLowestCellTemperatureHighestValue,
                data: highestLowestCellTemperatureHighestValueDatas,
            });

            setChartHighestLowestCellTemperatureCellNumberWithHV({
                ...chartHighestLowestCellTemperatureCellNumberWithHV,
                data: highestLowestCellTemperatureCellNumberWithHVDatas,
            });

            setChartHighestLowestCellTemperatureBoxNumberWithHV({
                ...chartHighestLowestCellTemperatureBoxNumberWithHV,
                data: highestLowestCellTemperatureBoxNumberWithHVDatas,
            });

            setChartHighestLowestCellTemperatureLowestValue({
                ...chartHighestLowestCellTemperatureLowestValue,
                data: highestLowestCellTemperatureLowestValueDatas,
            });

            setChartHighestLowestCellTemperatureCellNumberWithLV({
                ...chartHighestLowestCellTemperatureCellNumberWithLV,
                data: highestLowestCellTemperatureCellNumberWithLVDatas,
            });

            setChartHighestLowestCellTemperatureBoxNumberWithLV({
                ...chartHighestLowestCellTemperatureBoxNumberWithLV,
                data: highestLowestCellTemperatureBoxNumberWithLVDatas,
            });

            // setChartHighestLowestCellTemperatureHighestPoleTemperature({
            //     ...chartHighestLowestCellTemperatureHighestPoleTemperature,
            //     data: highestLowestCellTemperatureHighestPoleTemperatureDatas,
            // });

            // setChartHighestLowestCellTemperatureLocation({
            //     ...chartHighestLowestCellTemperatureLocation,
            //     data: highestLowestCellTemperatureLocationDatas,
            // });
            //#endregion

            //#region Battery Limit 1
            setChartBatteryLimit1MaximumCellVoltage({
                ...chartBatteryLimit1MaximumCellVoltage,
                data: batteryLimit1MaximumCellVoltageDatas,
            });

            setChartBatteryLimit1MinimumCellVoltage({
                ...chartBatteryLimit1MinimumCellVoltage,
                data: batteryLimit1MinimumCellVoltageDatas,
            });

            setChartBatteryLimit1MaximumSoc({
                ...chartBatteryLimit1MaximumSoc,
                data: batteryLimit1MaximumSocDatas,
            });

            setChartBatteryLimit1MinimumSoc({
                ...chartBatteryLimit1MinimumSoc,
                data: batteryLimit1MinimumSocDatas,
            });

            setChartBatteryLimit1MaximumCellTemperature({
                ...chartBatteryLimit1MaximumCellTemperature,
                data: batteryLimit1MaximumCellTemperatureDatas,
            });

            setChartBatteryLimit1MinimumCellTemperature({
                ...chartBatteryLimit1MinimumCellTemperature,
                data: batteryLimit1MinimumCellTemperatureDatas,
            });
            //#endregion

            //#region Battery Limit 2
            setChartBatteryLimit2MaximumChargingCurrent({
                ...chartBatteryLimit2MaximumChargingCurrent,
                data: batteryLimit2MaximumChargingCurrentDatas,
            });

            setChartBatteryLimit2MaximumDischargingCurrent({
                ...chartBatteryLimit2MaximumDischargingCurrent,
                data: batteryLimit2MaximumDischargingCurrentDatas,
            });

            setChartBatteryLimit2MaximumChargingVoltage({
                ...chartBatteryLimit2MaximumChargingVoltage,
                data: batteryLimit2MaximumChargingVoltageDatas,
            });

            setChartBatteryLimit2MinimumDischargingVoltage({
                ...chartBatteryLimit2MinimumDischargingVoltage,
                data: batteryLimit2MinimumDischargingVoltageDatas,
            });
            //#endregion

            //#region Battery Request
            setChartBatteryRequestContactorShuttingRequest({
                ...chartBatteryRequestContactorShuttingRequest,
                data: batteryRequestContactorShuttingRequestDatas,
            });

            setChartBatteryRequestUltraCapacitorReferenceVoltage({
                ...chartBatteryRequestUltraCapacitorReferenceVoltage,
                data: batteryRequestUltraCapacitorReferenceVoltageDatas,
            });
            //#endregion

            //#region Battery Subsystems
            setChartBatterySubsystemsNumber({
                ...chartBatterySubsystemsNumber,
                data: batterySubsystemsNumberDatas,
            });

            setChartBatterySubsystemsCellNumber({
                ...chartBatterySubsystemsCellNumber,
                data: batterySubsystemsCellNumberDatas,
            });

            setChartBatterySubsystemsTempCheckNumber({
                ...chartBatterySubsystemsTempCheckNumber,
                data: batterySubsystemsTempCheckNumberDatas,
            });

            setChartBatterySubsystemsChargingPower({
                ...chartBatterySubsystemsChargingPower,
                data: batterySubsystemsChargingPowerDatas,
            });

            setChartBatterySubsystemsChargingTime({
                ...chartBatterySubsystemsChargingTime,
                data: batterySubsystemsChargingTimeDatas,
            });
            //#endregion

            //#region Insulation Status
            setChartInsulationStatusMonitorStatus({
                ...chartInsulationStatusMonitorStatus,
                data: insulationStatusMonitorStatusDatas,
            });

            setChartInsulationStatusResistance({
                ...chartInsulationStatusResistance,
                data: insulationStatusResistanceDatas,
            });

            setChartInsulationStatusErrorLevel({
                ...chartInsulationStatusErrorLevel,
                data: insulationStatusErrorLevelDatas,
            });

            setChartInsulationStatusErrorCode({
                ...chartInsulationStatusErrorCode,
                data: insulationStatusErrorCodeDatas,
            });
            //#endregion

            //#region Accumulated Capacity
            setChartAccumulatedCapacityDischargePower({
                ...chartAccumulatedCapacityDischargePower,
                data: accumulatedCapacityDischargePowerDatas,
            });

            setChartAccumulatedCapacityChargePower({
                ...chartAccumulatedCapacityChargePower,
                data: accumulatedCapacityChargePowerDatas,
            });
            //#endregion

            //#region DCDC Status
            setChartDcdcStatusUnderOutputVoltage({
                ...chartDcdcStatusUnderOutputVoltage,
                data: dcdcStatusUnderOutputVoltageDatas,
            });

            setChartDcdcStatusOverOutputVoltage({
                ...chartDcdcStatusOverOutputVoltage,
                data: dcdcStatusOverOutputVoltageDatas,
            });

            setChartDcdcStatusUnderInputVoltage({
                ...chartDcdcStatusUnderInputVoltage,
                data: dcdcStatusUnderInputVoltageDatas,
            });

            setChartDcdcStatusOverInputVoltage({
                ...chartDcdcStatusOverInputVoltage,
                data: dcdcStatusOverInputVoltageDatas,
            });

            setChartDcdcStatusHardwareFaultFlag({
                ...chartDcdcStatusHardwareFaultFlag,
                data: dcdcStatusHardwareFaultFlagDatas,
            });

            setChartDcdcStatusOperatingMode({
                ...chartDcdcStatusOperatingMode,
                data: dcdcStatusOperatingModeDatas,
            });

            setChartDcdcStatusDerating({
                ...chartDcdcStatusDerating,
                data: dcdcStatusDeratingDatas,
            });

            setChartDcdcStatusOverInputCurrent({
                ...chartDcdcStatusOverInputCurrent,
                data: dcdcStatusOverInputCurrentDatas,
            });

            setChartDcdcStatusOverTemperature({
                ...chartDcdcStatusOverTemperature,
                data: dcdcStatusOverTemperatureDatas,
            });

            setChartDcdcStatusOverOutputCurrent({
                ...chartDcdcStatusOverOutputCurrent,
                data: dcdcStatusOverOutputCurrentDatas,
            });

            setChartDcdcStatusRealOutputCurrent({
                ...chartDcdcStatusRealOutputCurrent,
                data: dcdcStatusRealOutputCurrentDatas,
            });

            setChartDcdcStatusRealityTemperature({
                ...chartDcdcStatusRealityTemperature,
                data: dcdcStatusRealityTemperatureDatas,
            });

            setChartDcdcStatusRealOutputVoltage({
                ...chartDcdcStatusRealOutputVoltage,
                data: dcdcStatusRealOutputVoltageDatas,
            });

            setChartDcdcStatusRealInputVoltage({
                ...chartDcdcStatusRealInputVoltage,
                data: dcdcStatusRealInputVoltageDatas,
            });

            setChartDcdcStatusVersion({
                ...chartDcdcStatusVersion,
                data: dcdcStatusVersionDatas,
            });
            //#endregion

            //#region DCDC Work Commande
            setChartDcdcWorkCommandEnable({
                ...chartDcdcWorkCommandEnable,
                data: dcdcWorkCommandEnableDatas,
            });

            setChartDcdcWorkCommandVoltageRequest({
                ...chartDcdcWorkCommandVoltageRequest,
                data: dcdcWorkCommandVoltageRequestDatas,
            });
            //#endregion

            //#region TMPS Pressure Status
            setChartTmpsPressureStatusLeftFront({
                ...chartTmpsPressureStatusLeftFront,
                data: tmpsPressureStatusLeftFrontDatas,
            });

            setChartTmpsPressureStatusRightFront({
                ...chartTmpsPressureStatusRightFront,
                data: tmpsPressureStatusRightFrontDatas,
            });

            setChartTmpsPressureStatusLeftRear({
                ...chartTmpsPressureStatusLeftRear,
                data: tmpsPressureStatusLeftRearDatas,
            });

            setChartTmpsPressureStatusRightRear({
                ...chartTmpsPressureStatusRightRear,
                data: tmpsPressureStatusRightRearDatas,
            });

            setChartTmpsPressureStatusLeftFrontStatus({
                ...chartTmpsPressureStatusLeftFrontStatus,
                data: tmpsPressureStatusLeftFrontStatusDatas,
            });

            setChartTmpsPressureStatusRightFrontStatus({
                ...chartTmpsPressureStatusRightFrontStatus,
                data: tmpsPressureStatusRightFrontStatusDatas,
            });

            setChartTmpsPressureStatusLeftRearStatus({
                ...chartTmpsPressureStatusLeftRearStatus,
                data: tmpsPressureStatusLeftRearStatusDatas,
            });

            setChartTmpsPressureStatusRightRearStatus({
                ...chartTmpsPressureStatusRightRearStatus,
                data: tmpsPressureStatusRightRearStatusDatas,
            });

            setChartTmpsPressureStatusAcquisitionStatus({
                ...chartTmpsPressureStatusAcquisitionStatus,
                data: tmpsPressureStatusAcquisitionStatusDatas,
            });

            setChartTmpsPressureStatusTireMonitorSystemStatus({
                ...chartTmpsPressureStatusTireMonitorSystemStatus,
                data: tmpsPressureStatusTireMonitorSystemStatusDatas,
            });

            setChartTmpsPressureStatusSendCycle({
                ...chartTmpsPressureStatusSendCycle,
                data: tmpsPressureStatusSendCycleDatas,
            });
            //#endregion

            //#region TMPS Temperature Status
            setChartTmpsTemperatureStatusLeftFront({
                ...chartTmpsTemperatureStatusLeftFront,
                data: tmpsTemperatureStatusLeftFrontDatas,
            });

            setChartTmpsTemperatureStatusRightFront({
                ...chartTmpsTemperatureStatusRightFront,
                data: tmpsTemperatureStatusRightFrontDatas,
            });

            setChartTmpsTemperatureStatusLeftRear({
                ...chartTmpsTemperatureStatusLeftRear,
                data: tmpsTemperatureStatusLeftRearDatas,
            });

            setChartTmpsTemperatureStatusRightRear({
                ...chartTmpsTemperatureStatusRightRear,
                data: tmpsTemperatureStatusRightRearDatas,
            });

            setChartTmpsTemperatureStatusLeftFrontStatus({
                ...chartTmpsTemperatureStatusLeftFrontStatus,
                data: tmpsTemperatureStatusLeftFrontStatusDatas,
            });

            setChartTmpsTemperatureStatusRightFrontStatus({
                ...chartTmpsTemperatureStatusRightFrontStatus,
                data: tmpsTemperatureStatusRightFrontStatusDatas,
            });

            setChartTmpsTemperatureStatusLeftRearStatus({
                ...chartTmpsTemperatureStatusLeftRearStatus,
                data: tmpsTemperatureStatusLeftRearStatusDatas,
            });

            setChartTmpsTemperatureStatusRightRearStatus({
                ...chartTmpsTemperatureStatusRightRearStatus,
                data: tmpsTemperatureStatusRightRearStatusDatas,
            });

            setChartTmpsTemperatureStatusAcquisitionStatus({
                ...chartTmpsTemperatureStatusAcquisitionStatus,
                data: tmpsTemperatureStatusAcquisitionStatusDatas,
            });

            setChartTmpsTemperatureStatusSendCycle({
                ...chartTmpsTemperatureStatusSendCycle,
                data: tmpsTemperatureStatusSendCycleDatas,
            });
            //#endregion

            //#region Lamp Signal
            setChartLampSignalHandbrakeStatus({
                ...chartLampSignalHandbrakeStatus,
                data: lampSignalHandbrakeStatusDatas,
            });

            setChartLampSignalDriverSeatBeltSwitch({
                ...chartLampSignalDriverSeatBeltSwitch,
                data: lampSignalDriverSeatBeltSwitchDatas,
            });

            setChartLampSignalLowBeam({
                ...chartLampSignalLowBeam,
                data: lampSignalLowBeamDatas,
            });

            setChartLampSignalHighBeam({
                ...chartLampSignalHighBeam,
                data: lampSignalHighBeamDatas,
            });

            setChartLampSignalFrontFog({
                ...chartLampSignalFrontFog,
                data: lampSignalFrontFogDatas,
            });

            setChartLampSignalRearFog({
                ...chartLampSignalRearFog,
                data: lampSignalRearFogDatas,
            });

            setChartLampSignalRightTurn({
                ...chartLampSignalRightTurn,
                data: lampSignalRightTurnDatas,
            });

            setChartLampSignalLeftTurn({
                ...chartLampSignalLeftTurn,
                data: lampSignalLeftTurnDatas,
            });

            setChartLampSignalPosition({
                ...chartLampSignalPosition,
                data: lampSignalPositionDatas,
            });

            setChartLampSignalHazardWarning({
                ...chartLampSignalHazardWarning,
                data: lampSignalHazardWarningDatas,
            });

            setChartLampSignalLeftFrontDoor({
                ...chartLampSignalLeftFrontDoor,
                data: lampSignalLeftFrontDoorDatas,
            });

            setChartLampSignalRightFrontDoor({
                ...chartLampSignalRightFrontDoor,
                data: lampSignalRightFrontDoorDatas,
            });

            setChartLampSignalLeftMiddleDoor({
                ...chartLampSignalLeftMiddleDoor,
                data: lampSignalLeftMiddleDoorDatas,
            });

            setChartLampSignalRightMiddleDoor({
                ...chartLampSignalRightMiddleDoor,
                data: lampSignalRightMiddleDoorDatas,
            });

            setChartLampSignalTailGate({
                ...chartLampSignalTailGate,
                data: lampSignalTailGateDatas,
            });
            //#endregion

            //#region Vehicle Speed
            setChartVehicleSpeedSpeed({
                ...chartVehicleSpeedSpeed,
                data: vehicleSpeedSpeedDatas,
            });

            setChartVehicleSpeedInstantaneousPowerConsumption({
                ...chartVehicleSpeedInstantaneousPowerConsumption,
                data: vehicleSpeedInstantaneousPowerConsumptionDatas,
            });

            setChartVehicleSpeedDriveMotorSpeed({
                ...chartVehicleSpeedDriveMotorSpeed,
                data: vehicleSpeedDriveMotorSpeedDatas,
            });

            setChartVehicleSpeedHighVoltagePrecharge({
                ...chartVehicleSpeedHighVoltagePrecharge,
                data: vehicleSpeedHighVoltagePrechargeDatas,
            });

            setChartVehicleSpeedRechargeMileage({
                ...chartVehicleSpeedRechargeMileage,
                data: vehicleSpeedRechargeMileageDatas,
            });
            //#endregion

            //#region Electronic Message
            setChartElectronicMessageDrivingMode({
                ...chartElectronicMessageDrivingMode,
                data: electronicMessageDrivingModeDatas,
            });

            setChartElectronicMessageGearPosition({
                ...chartElectronicMessageGearPosition,
                data: electronicMessageGearPositionDatas,
            });

            setChartElectronicMessageHighVoltageControllerStatus({
                ...chartElectronicMessageHighVoltageControllerStatus,
                data: electronicMessageHighVoltageControllerStatusDatas,
            });

            setChartElectronicMessageHighVoltageControllerFaultCode({
                ...chartElectronicMessageHighVoltageControllerFaultCode,
                data: electronicMessageHighVoltageControllerFaultCodeDatas,
            });

            setChartElectronicMessageVehicleControllerVcuStatus({
                ...chartElectronicMessageVehicleControllerVcuStatus,
                data: electronicMessageVehicleControllerVcuStatusDatas,
            });

            setChartElectronicMessageVehicleControllerDefrostStatus({
                ...chartElectronicMessageVehicleControllerDefrostStatus,
                data: electronicMessageVehicleControllerDefrostStatusDatas,
            });

            setChartElectronicMessageVehicleControllerAcceleratorPedalStatus({
                ...chartElectronicMessageVehicleControllerAcceleratorPedalStatus,
                data: electronicMessageVehicleControllerAcceleratorPedalStatusDatas,
            });

            setChartElectronicMessageVehicleControllerBrakePedalStatus({
                ...chartElectronicMessageVehicleControllerBrakePedalStatus,
                data: electronicMessageVehicleControllerBrakePedalStatusDatas,
            });

            setChartElectronicMessageVehicleElectronicSystemFailure({
                ...chartElectronicMessageVehicleElectronicSystemFailure,
                data: electronicMessageVehicleElectronicSystemFailureDatas,
            });

            setChartElectronicMessageDcDcStatus({
                ...chartElectronicMessageDcDcStatus,
                data: electronicMessageDcDcStatusDatas,
            });

            setChartElectronicMessageDriveMotorPowerLimit({
                ...chartElectronicMessageDriveMotorPowerLimit,
                data: electronicMessageDriveMotorPowerLimitDatas,
            });

            setChartElectronicMessageCoolingWaterPumpStatus({
                ...chartElectronicMessageCoolingWaterPumpStatus,
                data: electronicMessageCoolingWaterPumpStatusDatas,
            });

            setChartElectronicMessageSteeringOilPumpStatus({
                ...chartElectronicMessageSteeringOilPumpStatus,
                data: electronicMessageSteeringOilPumpStatusDatas,
            });

            setChartElectronicMessageAirPumpStatus({
                ...chartElectronicMessageAirPumpStatus,
                data: electronicMessageAirPumpStatusDatas,
            });

            setChartElectronicMessageAirConditionerStatus({
                ...chartElectronicMessageAirConditionerStatus,
                data: electronicMessageAirConditionerStatusDatas,
            });

            setChartElectronicMessageOperationReadyIndicator({
                ...chartElectronicMessageOperationReadyIndicator,
                data: electronicMessageOperationReadyIndicatorDatas,
            });
            //#endregion

            //#region Motor Status
            setChartMotorStatusRunningStatus({
                ...chartMotorStatusRunningStatus,
                data: motorStatusRunningStatusDatas,
            });

            setChartMotorStatusRotationDirection({
                ...chartMotorStatusRotationDirection,
                data: motorStatusRotationDirectionDatas,
            });

            setChartMotorStatusErrorLevel({
                ...chartMotorStatusErrorLevel,
                data: motorStatusErrorLevelDatas,
            });

            setChartMotorStatusErrorCode({
                ...chartMotorStatusErrorCode,
                data: motorStatusErrorCodeDatas,
            });

            setChartMotorStatusCoolingWaterTemperature({
                ...chartMotorStatusCoolingWaterTemperature,
                data: motorStatusCoolingWaterTemperatureDatas,
            });

            setChartMotorStatusControllerTemperature({
                ...chartMotorStatusControllerTemperature,
                data: motorStatusControllerTemperatureDatas,
            });

            setChartMotorStatusOutputPowerPercentage({
                ...chartMotorStatusOutputPowerPercentage,
                data: motorStatusOutputPowerPercentageDatas,
            });

            setChartMotorStatusBrakeRecoveryPowerPercentage({
                ...chartMotorStatusBrakeRecoveryPowerPercentage,
                data: motorStatusBrakeRecoveryPowerPercentageDatas,
            });

            setChartMotorStatusBrakeRecoveryWorkingStatus({
                ...chartMotorStatusBrakeRecoveryWorkingStatus,
                data: motorStatusBrakeRecoveryWorkingStatusDatas,
            });
            //#endregion

            //#region Vehicle Mileage
            setChartVehicleMileageAccumulatedMileage({
                ...chartVehicleMileageAccumulatedMileage,
                data: vehicleMileageAccumulatedMileageDatas,
            });

            setChartVehicleMileageCurrentMileage({
                ...chartVehicleMileageCurrentMileage,
                data: vehicleMileageCurrentMileageDatas,
            });

            //#endregion

            //#region B2C Charger
            setChartB2CChargerVoltageSet({
                ...chartB2CChargerVoltageSet,
                data: b2CChargerVoltageSetDatas,
            });

            setChartB2CChargerCurrentSet({
                ...chartB2CChargerCurrentSet,
                data: b2CChargerCurrentSetDatas,
            });

            setChartB2CChargerControl({
                ...chartB2CChargerControl,
                data: b2CChargerControlDatas,
            });
            //#endregion

            //#region Working Command
            setChartWorkingCommandRunningCommandDcac1({
                ...chartWorkingCommandRunningCommandDcac1,
                data: workingCommandRunningCommandDcac1Datas,
            });

            setChartWorkingCommandResetCommandEhps({
                ...chartWorkingCommandResetCommandEhps,
                data: workingCommandResetCommandEhpsDatas,
            });

            setChartWorkingCommandSpeed({
                ...chartWorkingCommandSpeed,
                data: workingCommandSpeedDatas,
            });
            //#endregion

            //#region EHPS Status
            setChartEhpsStatusActualSpeed({
                ...chartEhpsStatusActualSpeed,
                data: ehpsStatusActualSpeedDatas,
            });

            setChartEhpsStatusControllerTemperature({
                ...chartEhpsStatusControllerTemperature,
                data: ehpsStatusControllerTemperatureDatas,
            });

            setChartEhpsStatusStatus({
                ...chartEhpsStatusStatus,
                data: ehpsStatusStatusDatas,
            });

            setChartEhpsStatusErrorLevel({
                ...chartEhpsStatusErrorLevel,
                data: ehpsStatusErrorLevelDatas,
            });

            setChartEhpsStatusErrorCode({
                ...chartEhpsStatusErrorCode,
                data: ehpsStatusErrorCodeDatas,
            });

            setChartEhpsStatus2Voltage({
                ...chartEhpsStatus2Voltage,
                data: ehpsStatus2VoltageDatas,
            });

            setChartEhpsStatus2Current({
                ...chartEhpsStatus2Current,
                data: ehpsStatus2CurrentDatas,
            });
            //#endregion

            //#region Angle Sensor Status
            setChartAngleSensorStatusSteeringWheelAngle({
                ...chartAngleSensorStatusSteeringWheelAngle,
                data: angleSensorStatusSteeringWheelAngleDatas,
            });

            setChartAngleSensorStatusSteeringWheelSpeed({
                ...chartAngleSensorStatusSteeringWheelSpeed,
                data: angleSensorStatusSteeringWheelSpeedDatas,
            });

            setChartAngleSensorStatusSensorFailureSignal({
                ...chartAngleSensorStatusSensorFailureSignal,
                data: angleSensorStatusSensorFailureSignalDatas,
            });

            setChartAngleSensorStatusVoltageWarningSignal({
                ...chartAngleSensorStatusVoltageWarningSignal,
                data: angleSensorStatusVoltageWarningSignalDatas,
            });

            setChartAngleSensorStatusAngularVelocitySignBit({
                ...chartAngleSensorStatusAngularVelocitySignBit,
                data: angleSensorStatusAngularVelocitySignBitDatas,
            });

            setChartAngleSensorStatusCalibrationStatus({
                ...chartAngleSensorStatusCalibrationStatus,
                data: angleSensorStatusCalibrationStatusDatas,
            });

            setChartAngleSensorStatusAngularVelocityValidSignal({
                ...chartAngleSensorStatusAngularVelocityValidSignal,
                data: angleSensorStatusAngularVelocityValidSignalDatas,
            });

            setChartAngleSensorStatusSteeringAngleValidSignal({
                ...chartAngleSensorStatusSteeringAngleValidSignal,
                data: angleSensorStatusSteeringAngleValidSignalDatas,
            });

            setChartAngleSensorStatusCountSignal({
                ...chartAngleSensorStatusCountSignal,
                data: angleSensorStatusCountSignalDatas,
            });

            setChartAngleSensorStatusCheck({
                ...chartAngleSensorStatusCheck,
                data: angleSensorStatusCheckDatas,
            });
            //#endregion
        }
    }, [runningCanDataList]);

    useEffect(() => {
        if (runningCanDataList === undefined || selectedDataPointIndex === undefined) return;

        if (vehicleInfo?.makerModel?.maker?.makerName === Common.VehicleMaker.Yaxing) {
            const canData = runningCanDataList[selectedDataPointIndex] as CanDataYaxing.CanData;

            setCurrentCanData(canData);
        }
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (
            batteryInformationChartMounted === true ||
            cellVoltageChartMounted === true ||
            cellTemperatureChartMounted === true ||
            highestLowestCellVoltageChartMounted === true ||
            highestLowestCellTemperatureChartMounted === true ||
            batteryLimit1ChartMounted === true ||
            batteryLimit2ChartMounted === true ||
            batteryRequestChartMounted === true ||
            batterySubsystemsChartMounted === true ||
            insulationStatusChartMounted === true ||
            accumulatedCapacityChartMounted === true ||
            dcdcStatusChartMounted === true ||
            dcdcWorkCommandChartMounted === true ||
            tmpsPressureStatusChartMounted === true ||
            tmpsTemperatureStatusChartMounted === true ||
            lampSignalChartMounted === true ||
            vehicleSpeedChartMounted === true ||
            electronicMessageChartMounted === true ||
            motorStatusChartMounted === true ||
            vehicleMileageChartMounted === true ||
            b2CChargerChartMounted === true ||
            workingCommandChartMounted === true ||
            ehpsStatusChartMounted === true ||
            angleSensorStatusChartMounted === true
        ) {
            if (onMountedChart !== undefined) onMountedChart();
        }
    }, [
        batteryInformationChartMounted,
        cellVoltageChartMounted,
        cellTemperatureChartMounted,
        highestLowestCellVoltageChartMounted,
        highestLowestCellTemperatureChartMounted,
        batteryLimit1ChartMounted,
        batteryLimit2ChartMounted,
        batteryRequestChartMounted,
        batterySubsystemsChartMounted,
        insulationStatusChartMounted,
        accumulatedCapacityChartMounted,
        dcdcStatusChartMounted,
        dcdcWorkCommandChartMounted,
        tmpsPressureStatusChartMounted,
        tmpsTemperatureStatusChartMounted,
        lampSignalChartMounted,
        vehicleSpeedChartMounted,
        electronicMessageChartMounted,
        motorStatusChartMounted,
        vehicleMileageChartMounted,
        b2CChargerChartMounted,
        workingCommandChartMounted,
        ehpsStatusChartMounted,
        angleSensorStatusChartMounted,
    ]);

    const convertChartTooltip = (seriesName: string, value: number) => {
        let convertTooltipString = String(value);
        switch (seriesName) {
            case Common.BATTERY_INFORMATION_VOLTAGE_NAME:
            case Common.CELL_VOLTAGE_CELL_1_VOLTAGE_NAME:
            case Common.CELL_VOLTAGE_CELL_2_VOLTAGE_NAME:
            case Common.CELL_VOLTAGE_CELL_3_VOLTAGE_NAME:
            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_HIGHEST_VALUE_NAME:
            case Common.HIGHEST_LOWEST_CELL_VOLTAGE_LOWEST_VALUE_NAME:
            case Common.BATTERY_LIMIT_1_MAXIMUM_CELL_VOLTAGE_NAME:
            case Common.BATTERY_LIMIT_1_MINIMUM_CELL_VOLTAGE_NAME:
            case Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_VOLTAGE_NAME:
            case Common.BATTERY_LIMIT_2_MINIMUM_DISCHARGING_VOLTAGE_NAME:
            case Common.DCDC_STATUS_REAL_OUTPUT_VOLTAGE_NAME:
            case Common.DCDC_STATUS_REAL_INPUT_VOLTAGE_NAME:
            case Common.DCDC_WORK_COMMAND_VOLTAGE_REQUEST_NAME:
            case Common.VEHICLE_SPEED_HIGH_VOLTAGE_PRECHARGE_NAME:
            case Common.BATTERY_REQUEST_ULTRA_CAPACITOR_REFERENCE_VOLTAGE_NAME:
            case Common.B2C_CHARGER_VOLTAGE_SET_NAME:
            case Common.EHPS_STATUS2_VOLTAGE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_VOLTAGE);
                break;
            }

            case Common.BATTERY_INFORMATION_CURRENT_NAME:
            case Common.BATTERY_LIMIT_2_MAXIMUM_CHARGING_CURRENT_NAME:
            case Common.BATTERY_LIMIT_2_MAXIMUM_DISCHARGING_CURRENT_NAME:
            case Common.DCDC_STATUS_REAL_OUTPUT_CURRENT_NAME:
            case Common.B2C_CHARGER_CURRENT_SET_NAME:
            case Common.EHPS_STATUS2_CURRENT_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CURRENT);
                break;
            }

            case Common.BATTERY_INFORMATION_SOC_NAME:
            case Common.BATTERY_INFORMATION_SOH_NAME:
            case Common.BATTERY_LIMIT_1_MAXIMUM_SOC_NAME:
            case Common.BATTERY_LIMIT_1_MINIMUM_SOC_NAME:
            case Common.MOTOR_STATUS_OUTPUT_POWER_PERCENTAGE_NAME:
            case Common.MOTOR_STATUS_BRAKE_RECOVERY_POWER_PERCENTAGE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PERCENT);
                break;
            }

            case Common.CELL_TEMPERATURE_CELL_1_TEMPERATURE_NAME:
            case Common.CELL_TEMPERATURE_CELL_2_TEMPERATURE_NAME:
            case Common.CELL_TEMPERATURE_CELL_3_TEMPERATURE_NAME:
            case Common.CELL_TEMPERATURE_CELL_4_TEMPERATURE_NAME:
            case Common.CELL_TEMPERATURE_CELL_5_TEMPERATURE_NAME:
            case Common.CELL_TEMPERATURE_CELL_6_TEMPERATURE_NAME:
            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_HIGHEST_VALUE_NAME:
            case Common.HIGHEST_LOWEST_CELL_TEMPERATURE_LOWEST_VALUE_NAME:
            case Common.BATTERY_LIMIT_1_MAXIMUM_CELL_TEMPERATURE_NAME:
            case Common.BATTERY_LIMIT_1_MINIMUM_CELL_TEMPERATURE_NAME:
            case Common.DCDC_STATUS_REALITY_TEMPERATURE_NAME:
            case Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_NAME:
            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_NAME:
            case Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_NAME:
            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_NAME:
            case Common.MOTOR_STATUS_COOLING_WATER_TEMPERATURE_NAME:
            case Common.MOTOR_STATUS_CONTROLLER_TEMPERATURE_NAME:
            case Common.EHPS_STATUS_CONTROLLER_TEMPERATURE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_CELSIUS);
                break;
            }

            case Common.BATTERY_SUBSYSTEMS_CHARGING_POWER_NAME:
            case Common.ACCUMULATED_CAPACITY_DISCHARGE_POWER_NAME:
            case Common.ACCUMULATED_CAPACITY_CHARGE_POWER_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_POWER_H);
                break;
            }

            case Common.BATTERY_SUBSYSTEMS_CHARGING_TIME_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_MINUTE);
                break;
            }

            case Common.INSULATION_STATUS_RESISTANCE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_K_OHM);
                break;
            }

            case Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_NAME:
            case Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_NAME:
            case Common.TMPS_PRESSURE_STATUS_LEFT_REAR_NAME:
            case Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_PRESSURE);
                break;
            }

            case Common.VEHICLE_SPEED_SPEED_NAME:
            case Common.WORKING_COMMAND_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_SPEED);
                break;
            }

            case Common.VEHICLE_SPEED_INSTANTANEOUS_POWER_CONSUMPTION_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_POWER_DISTANCE);
                break;
            }

            case Common.VEHICLE_SPEED_DRIVE_MOTOR_SPEED_NAME:
            case Common.EHPS_STATUS_ACTUAL_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_MOTOR_SPEED);
                break;
            }

            case Common.VEHICLE_SPEED_RECHARE_MILEAGE_NAME:
            case Common.VEHICLE_MILEAGE_ACCUMULATED_MILEAGE_NAME:
            case Common.VEHICLE_MILEAGE_CURRENT_MILEAGE_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_DISTANCE);
                break;
            }

            case Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_ANGEL_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_DEGREE);
                break;
            }

            case Common.ANGLE_SENSOR_STATUS_STEERING_WHEEL_SPEED_NAME: {
                convertTooltipString = convertRemarkWithUnit(value, Common.UNIT_DEGREE_SPEED);
                break;
            }

            case Common.BATTERY_INFORMATION_OUTPUT_STATUS_NAME: {
                convertTooltipString = convertOutputStatusRemark(value);
                break;
            }

            case Common.BATTERY_INFORMATION_CHARGING_CONNECT_STATUS_NAME: {
                convertTooltipString = convertChargingConnectStatusRemark(value);
                break;
            }

            case Common.BATTERY_INFORMATION_CHARGING_STATUS_NAME: {
                convertTooltipString = convertChargingStatusRemark(value);
                break;
            }

            case Common.BATTERY_INFORMATION_ERROR_LEVEL_NAME:
            case Common.INSULATION_STATUS_ERROR_LEVEL_NAME:
            case Common.MOTOR_STATUS_ERROR_LEVEL_NAME:
            case Common.EHPS_STATUS_ERROR_LEVEL_NAME: {
                convertTooltipString = convertErrorLevelRemark(value);
                break;
            }

            case Common.BATTERY_INFORMATION_ERROR_CODE_NAME: {
                convertTooltipString = convertErrorCodeRemark(value);
                break;
            }

            case Common.INSULATION_STATUS_MONITOR_STATUS_NAME: {
                convertTooltipString = convertMonitorStatusRemark(value);
                break;
            }

            case Common.INSULATION_STATUS_ERROR_CODE_NAME: {
                convertTooltipString = convertInsulationStatusErrorCodeRemark(value);
                break;
            }

            case Common.DCDC_STATUS_UNDER_OUTPUT_VOLTAGE_NAME:
            case Common.DCDC_STATUS_OVER_OUTPUT_VOLTAGE_NAME:
            case Common.DCDC_STATUS_UNDER_INPUT_VOLTAGE_NAME:
            case Common.DCDC_STATUS_OVER_INPUT_VOLTAGE_NAME:
            case Common.DCDC_STATUS_HARDWARE_FAULT_FLAG_NAME:
            case Common.DCDC_STATUS_OVER_INPUT_CURRENT_NAME:
            case Common.DCDC_STATUS_OVER_TEMPERATURE_NAME:
            case Common.DCDC_STATUS_OVER_OUTPUT_CURRENT_NAME: {
                convertTooltipString = convertDcdcStatusRemark(value);
                break;
            }

            case Common.DCDC_STATUS_OPERATING_MODE_NAME: {
                convertTooltipString = convertDcdcStatusOperatingModeRemark(value);
                break;
            }

            case Common.DCDC_STATUS_DERATING_NAME: {
                convertTooltipString = convertDcdcStatusDeratingRemark(value);
                break;
            }

            case Common.DCDC_WORK_COMMAND_ENABLE_NAME: {
                convertTooltipString = convertDcdcWorkCommandEnableRemark(value);
                break;
            }

            case Common.TMPS_PRESSURE_STATUS_LEFT_FRONT_STATUS_NAME:
            case Common.TMPS_PRESSURE_STATUS_RIGHT_FRONT_STATUS_NAME:
            case Common.TMPS_PRESSURE_STATUS_LEFT_REAR_STATUS_NAME:
            case Common.TMPS_PRESSURE_STATUS_RIGHT_REAR_STATUS_NAME: {
                convertTooltipString = convertTmpsPressureStatusRemark(value);
                break;
            }

            case Common.TMPS_PRESSURE_STATUS_ACQUISITION_STATUS_NAME: {
                convertTooltipString = convertTmpsPressureStatusAcquisitionStatusRemark(value);
                break;
            }

            case Common.TMPS_PRESSURE_STATUS_TIRE_MONITOR_SYSTEM_STATUS_NAME: {
                convertTooltipString = convertTmpsPressureStatusTireMonitorSystemStatusRemark(value);
                break;
            }

            case Common.TMPS_TEMPERATURE_STATUS_LEFT_FRONT_STATUS_NAME:
            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_FRONT_STATUS_NAME:
            case Common.TMPS_TEMPERATURE_STATUS_LEFT_REAR_STATUS_NAME:
            case Common.TMPS_TEMPERATURE_STATUS_RIGHT_REAR_STATUS_NAME: {
                convertTooltipString = convertTmpsTemperatureStatusRemark(value);
                break;
            }

            case Common.TMPS_TEMPERATURE_STATUS_ACQUISITION_STATUS_NAME: {
                convertTooltipString = convertTmpsTemperatureStatusAcquisitionStatusRemark(value);
                break;
            }

            case Common.LAMP_SIGNAL_HANDBRAKE_STATUS_NAME:
            case Common.LAMP_SIGNAL_LOW_BEAM_NAME:
            case Common.LAMP_SIGNAL_HIGH_BEAM_NAME:
            case Common.LAMP_SIGNAL_FRONT_FOG_NAME:
            case Common.LAMP_SIGNAL_REAR_FOG_NAME:
            case Common.LAMP_SIGNAL_RIGHT_TURN_NAME:
            case Common.LAMP_SIGNAL_LEFT_TURN_NAME:
            case Common.LAMP_SIGNAL_POSITION_NAME:
            case Common.LAMP_SIGNAL_HAZARD_WARNING_NAME:
            case Common.LAMP_SIGNAL_LEFT_FRONT_DOOR_NAME:
            case Common.LAMP_SIGNAL_RIGHT_FRONT_DOOR_NAME:
            case Common.LAMP_SIGNAL_LEFT_MIDDLE_DOOR_NAME:
            case Common.LAMP_SIGNAL_RIGHT_MIDDLE_DOOR_NAME:
            case Common.LAMP_SIGNAL_TAIL_GATE_NAME: {
                convertTooltipString = convertLampSignalRemark(value);
                break;
            }

            case Common.LAMP_SIGNAL_DRIVER_SEAT_BELT_SWITCH_NAME: {
                convertTooltipString = convertLampSignalDriverSeatBeltSwitchRemark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_DRIVING_MODE_NAME: {
                convertTooltipString = convertElectronicMessageDrivingModeRemark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_GEAR_POSITION_NAME: {
                convertTooltipString = convertElectronicMessageGearPositionRemark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_STATUS_NAME:
            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_VCU_STATUS_NAME:
            case Common.ELECTRONIC_MESSAGE_VEHICLE_ELECTRONICS_SYSTEM_FAILURE_NAME:
            case Common.ELECTRONIC_MESSAGE_DCDC_STATUS_NAME:
            case Common.ELECTRONIC_MESSAGE_COOLING_WATER_PUMP_STATUS_NAME:
            case Common.ELECTRONIC_MESSAGE_STEERING_OIL_PUMP_STATUS_NAME:
            case Common.ELECTRONIC_MESSAGE_AIR_PUMP_STATUS_NAME: {
                convertTooltipString = convertElectronicMessageStatusRemark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_HIGH_VOLTAGE_CONTROLLER_FAULT_CODE_NAME: {
                convertTooltipString = convertElectronicMessageHighVoltageControllerFaultCodeRemark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_DEFROST_STATUS_NAME:
            case Common.ELECTRONIC_MESSAGE_AIR_CONDITIONER_STATUS_NAME: {
                convertTooltipString = convertElectronicMessageStatus2Remark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_ACCELERATOR_PEDAL_STATUS_NAME:
            case Common.ELECTRONIC_MESSAGE_VEHICLE_CONTROLLER_BRAKE_PEDAL_STATUS_NAME: {
                convertTooltipString = convertElectronicMessagePedalStatusRemark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_DRIVE_MOTOR_POWER_LIMIT_NAME: {
                convertTooltipString = convertElectronicMessageDriveMotorPowerLimitRemark(value);
                break;
            }

            case Common.ELECTRONIC_MESSAGE_OPERATION_READY_INDICATOR_NAME: {
                convertTooltipString = convertElectronicMessageOperationReadyIndicatorRemark(value);
                break;
            }

            case Common.MOTOR_STATUS_RUNNING_STATUS_NAME: {
                convertTooltipString = convertMotorStatusRunningStatusRemark(value);
                break;
            }

            case Common.MOTOR_STATUS_ROTATION_DIRECTION_NAME: {
                convertTooltipString = convertMotorStatusRotationDirectionRemark(value);
                break;
            }

            case Common.MOTOR_STATUS_ERROR_CODE_NAME: {
                convertTooltipString = convertMotorStatusErrorCodeRemark(value);
                break;
            }

            case Common.MOTOR_STATUS_BRAKE_RECOVERY_WORKING_STATUS_NAME: {
                convertTooltipString = convertMotorStatusBrakeRecoveryWorkingStatusRemark(value);
                break;
            }

            case Common.BATTERY_REQUEST_CONTACTOR_SHUTTING_REQUEST_NAME: {
                convertTooltipString = convertBatteryRequestContactorShuttingRequestRemark(value);
                break;
            }

            case Common.B2C_CHARGER_CONTROL_NAME: {
                convertTooltipString = convertB2CChargerControlRemark(value);
                break;
            }

            case Common.WORKING_COMMAND_RUNNING_COMMAND_DCAC1_NAME: {
                convertTooltipString = convertWorkingCommandRunningCommandDcac1Remark(value);
                break;
            }

            case Common.WORKING_COMMAND_RESET_COMMAND_EHPS_NAME: {
                convertTooltipString = convertWorkingCommandResetCommandEhpsRemark(value);
                break;
            }

            case Common.EHPS_STATUS_STATUS_NAME: {
                convertTooltipString = convertEhpsStatusStatusRemark(value);
                break;
            }

            case Common.EHPS_STATUS_ERROR_CODE_NAME: {
                convertTooltipString = convertEhpsStatusErrorCodeRemark(value);
                break;
            }

            case Common.ANGLE_SENSOR_STATUS_SENSOR_FAILURE_SIGNAL_NAME: {
                convertTooltipString = convertAngleSensorStatusSensorFailureSignalRemark(value);
                break;
            }

            case Common.ANGLE_SENSOR_STATUS_VOLTAGE_WARNING_SIGNAL_NAME: {
                convertTooltipString = convertAngleSensorStatusVoltageWarningSignalRemark(value);
                break;
            }

            case Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_SIGN_BIT_NAME: {
                convertTooltipString = convertAngleSensorStatusAngularVelocitySignBitRemark(value);
                break;
            }

            case Common.ANGLE_SENSOR_STATUS_CALIBRATION_STATUS_NAME: {
                convertTooltipString = convertAngleSensorStatusCalibrationStatusRemark(value);
                break;
            }

            case Common.ANGLE_SENSOR_STATUS_ANGULAR_VELOCITY_VALID_SIGNAL_NAME:
            case Common.ANGLE_SENSOR_STATUS_STEERING_ANGLE_VALID_SIGNAL_NAME: {
                convertTooltipString = convertAngleSensorStatusValidRemark(value);
                break;
            }

            default:
                break;
        }
        return convertTooltipString;
    };

    const list = [
        {
            title: "Battery Information",
            id: "batteryInformation",
            panelOpen: batteryInformationPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setBatteryInformationPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataBatteryInformation,
            tableSelectRowKeys: selectedBatteryInformation,
            onChangeTableSelect: onChangeBatteryInformation,
            tableGetTooltips: getTabelTooltipBatteryInformation,
            seriesChartDatas: seriesChartDatasBatteryInformation,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setBatteryInformationChartMounted(true),
        },
        {
            title: "Cell Voltage",
            id: "cellVoltage",
            panelOpen: cellVoltagePanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setCellVoltagePanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataCellVoltage,
            tableSelectRowKeys: selectedCellVoltage,
            onChangeTableSelect: onChangeCellVoltage,
            tableGetTooltips: getTabelTooltipCellVoltage,
            seriesChartDatas: seriesChartDatasCellVoltage,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setCellVoltageChartMounted(true),
        },
        {
            title: "Cell Temperature",
            id: "cellTemperature",
            panelOpen: cellTemperaturePanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setCellTemperaturePanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataCellTemperature,
            tableSelectRowKeys: selectedCellTemperature,
            onChangeTableSelect: onChangeCellTemperature,
            tableGetTooltips: getTabelTooltipCellTemperature,
            seriesChartDatas: seriesChartDatasCellTemperature,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setCellTemperatureChartMounted(true),
        },
        {
            title: "Highest and Lowest Cell Voltage",
            id: "highestLowestCellVoltage",
            panelOpen: highestLowestCellVoltagePanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setHighestLowestCellVoltagePanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataHighestLowestCellVoltage,
            tableSelectRowKeys: selectedHighestLowestCellVoltage,
            onChangeTableSelect: onChangeHighestLowestCellVoltage,
            tableGetTooltips: getTabelTooltipHighestLowestCellVoltage,
            seriesChartDatas: seriesChartDatasHighestLowestCellVoltage,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setHighestLowestCellVoltageChartMounted(true),
        },
        {
            title: "Highest and Lowest Cell Temperature",
            id: "highestLowestCellTemperature",
            panelOpen: highestLowestCellTemperaturePanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setHighestLowestCellTemperaturePanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataHighestLowestCellTemperature,
            tableSelectRowKeys: selectedHighestLowestCellTemperature,
            onChangeTableSelect: onChangeHighestLowestCellTemperature,
            tableGetTooltips: getTabelTooltipHighestLowestCellTemperature,
            seriesChartDatas: seriesChartDatasHighestLowestCellTemperature,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setHighestLowestCellTemperatureChartMounted(true),
        },
        {
            title: "Battery Limit 1",
            id: "batteryLimit1",
            panelOpen: batteryLimit1PanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setBatteryLimit1PanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataBatteryLimit1,
            tableSelectRowKeys: selectedBatteryLimit1,
            onChangeTableSelect: onChangeBatteryLimit1,
            tableGetTooltips: getTabelTooltipBatteryLimit1,
            seriesChartDatas: seriesChartDatasBatteryLimit1,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setBatteryLimit1ChartMounted(true),
        },
        {
            title: "Battery Limit 2",
            id: "batteryLimit2",
            panelOpen: batteryLimit2PanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setBatteryLimit2PanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataBatteryLimit2,
            tableSelectRowKeys: selectedBatteryLimit2,
            onChangeTableSelect: onChangeBatteryLimit2,
            tableGetTooltips: getTabelTooltipBatteryLimit2,
            seriesChartDatas: seriesChartDatasBatteryLimit2,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setBatteryLimit2ChartMounted(true),
        },
        {
            title: "Battery Request",
            id: "batteryRequest",
            panelOpen: batteryRequestPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setBatteryRequestPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataBatteryRequest,
            tableSelectRowKeys: selectedBatteryRequest,
            onChangeTableSelect: onChangeBatteryRequest,
            tableGetTooltips: getTabelTooltipBatteryRequest,
            seriesChartDatas: seriesChartDatasBatteryRequest,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setBatteryRequestChartMounted(true),
        },
        {
            title: "Battery Subsystems",
            id: "batterySubsystems",
            panelOpen: batterySubsystemsPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setBatterySubsystemsPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataBatterySubsystems,
            tableSelectRowKeys: selectedBatterySubsystems,
            onChangeTableSelect: onChangeBatterySubsystems,
            tableGetTooltips: getTabelTooltipBatterySubsystems,
            seriesChartDatas: seriesChartDatasBatterySubsystems,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setBatterySubsystemsChartMounted(true),
        },
        {
            title: "Insulation Status",
            id: "insulationStatus",
            panelOpen: insulationStatusPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setInsulationStatusPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataInsulationStatus,
            tableSelectRowKeys: selectedInsulationStatus,
            onChangeTableSelect: onChangeInsulationStatus,
            tableGetTooltips: getTabelTooltipInsulationStatus,
            seriesChartDatas: seriesChartDatasInsulationStatus,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setInsulationStatusChartMounted(true),
        },
        {
            title: "Accumulated Capacity",
            id: "accumulatedCapacity",
            panelOpen: accumulatedCapacityPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setAccumulatedCapacityPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataAccumulatedCapacity,
            tableSelectRowKeys: selectedAccumulatedCapacity,
            onChangeTableSelect: onChangeAccumulatedCapacity,
            tableGetTooltips: getTabelTooltipAccumulatedCapacity,
            seriesChartDatas: seriesChartDatasAccumulatedCapacity,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setAccumulatedCapacityChartMounted(true),
        },
        {
            title: "DC/DC Status",
            id: "dcdcStatus",
            panelOpen: dcdcStatusPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setDcdcStatusPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataDcdcStatus,
            tableSelectRowKeys: selectedDcdcStatus,
            onChangeTableSelect: onChangeDcdcStatus,
            tableGetTooltips: getTabelTooltipDcdcStatus,
            seriesChartDatas: seriesChartDatasDcdcStatus,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setDcdcStatusChartMounted(true),
        },
        {
            title: "DC/DC Work Command",
            id: "dcdcWorkCommand",
            panelOpen: dcdcWorkCommandPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setDcdcWorkCommandPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataDcdcWorkCommand,
            tableSelectRowKeys: selectedDcdcWorkCommand,
            onChangeTableSelect: onChangeDcdcWorkCommand,
            tableGetTooltips: getTabelTooltipDcdcWorkCommand,
            seriesChartDatas: seriesChartDatasDcdcWorkCommand,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setDcdcWorkCommandChartMounted(true),
        },
        {
            title: "TMPS Pressure Status",
            id: "tmpsPressureStatus",
            panelOpen: tmpsPressureStatusPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setTmpsPressureStatusPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataTmpsPressureStatus,
            tableSelectRowKeys: selectedTmpsPressureStatus,
            onChangeTableSelect: onChangeTmpsPressureStatus,
            tableGetTooltips: getTabelTooltipTmpsPressureStatus,
            seriesChartDatas: seriesChartDatasTmpsPressureStatus,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setTmpsPressureStatusChartMounted(true),
        },
        {
            title: "TMPS Temperature Status",
            id: "tmpsTemperatureStatus",
            panelOpen: tmpsTemperatureStatusPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setTmpsTemperatureStatusPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataTmpsTemperatureStatus,
            tableSelectRowKeys: selectedTmpsTemperatureStatus,
            onChangeTableSelect: onChangeTmpsTemperatureStatus,
            tableGetTooltips: getTabelTooltipTmpsTemperatureStatus,
            seriesChartDatas: seriesChartDatasTmpsTemperatureStatus,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setTmpsTemperatureStatusChartMounted(true),
        },
        {
            title: "Lamp Signal",
            id: "lampSignal",
            panelOpen: lampSignalPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setLampSignalPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataLampSignal,
            tableSelectRowKeys: selectedLampSignal,
            onChangeTableSelect: onChangeLampSignal,
            tableGetTooltips: getTabelTooltipLampSignal,
            seriesChartDatas: seriesChartDatasLampSignal,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setLampSignalChartMounted(true),
        },
        {
            title: "Vehicle Speed",
            id: "vehicleSpeed",
            panelOpen: vehicleSpeedPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setVehicleSpeedPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataVehicleSpeed,
            tableSelectRowKeys: selectedVehicleSpeed,
            onChangeTableSelect: onChangeVehicleSpeed,
            tableGetTooltips: getTabelTooltipVehicleSpeed,
            seriesChartDatas: seriesChartDatasVehicleSpeed,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setVehicleSpeedChartMounted(true),
        },
        {
            title: "Electronic Message",
            id: "electronicMessage",
            panelOpen: electronicMessagePanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setElectronicMessagePanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataElectronicMessage,
            tableSelectRowKeys: selectedElectronicMessage,
            onChangeTableSelect: onChangeElectronicMessage,
            tableGetTooltips: getTabelTooltipElectronicMessage,
            seriesChartDatas: seriesChartDatasElectronicMessage,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setElectronicMessageChartMounted(true),
        },
        {
            title: "Motor Status",
            id: "motorStatus",
            panelOpen: motorStatusPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setMotorStatusPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataMotorStatus,
            tableSelectRowKeys: selectedMotorStatus,
            onChangeTableSelect: onChangeMotorStatus,
            tableGetTooltips: getTabelTooltipMotorStatus,
            seriesChartDatas: seriesChartDatasMotorStatus,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setMotorStatusChartMounted(true),
        },
        {
            title: "Vehicle Mileage",
            id: "vehicleMileage",
            panelOpen: vehicleMileagePanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setVehicleMileagePanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataVehicleMileage,
            tableSelectRowKeys: selectedVehicleMileage,
            onChangeTableSelect: onChangeVehicleMileage,
            tableGetTooltips: getTabelTooltipVehicleMileage,
            seriesChartDatas: seriesChartDatasVehicleMileage,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setVehicleMileageChartMounted(true),
        },
        {
            title: "B2C Charger",
            id: "b2CCharger",
            panelOpen: b2CChargerPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setB2CChargerPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataB2CCharger,
            tableSelectRowKeys: selectedB2CCharger,
            onChangeTableSelect: onChangeB2CCharger,
            tableGetTooltips: getTabelTooltipB2CCharger,
            seriesChartDatas: seriesChartDatasB2CCharger,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setV2CChargerChartMounted(true),
        },
        {
            title: "Working Command",
            id: "workingCommand",
            panelOpen: workingCommandPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setWorkingCommandPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataWorkingCommand,
            tableSelectRowKeys: selectedWorkingCommand,
            onChangeTableSelect: onChangeWorkingCommand,
            tableGetTooltips: getTabelTooltipWorkingCommand,
            seriesChartDatas: seriesChartDatasWorkingCommand,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setWorkingCommandChartMounted(true),
        },
        {
            title: "EHPS Status",
            id: "ehpsStatus",
            panelOpen: ehpsStatusPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setEhpsStatusPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataEhpsStatus,
            tableSelectRowKeys: selectedEhpsStatus,
            onChangeTableSelect: onChangeEhpsStatus,
            tableGetTooltips: getTabelTooltipEhpsStatus,
            seriesChartDatas: seriesChartDatasEhpsStatus,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setEhpsStatusChartMounted(true),
        },
        {
            title: "Angle Sensor Status",
            id: "angleSensorStatus",
            panelOpen: angleSensorStatusPanelOpen,
            onChangePanelOpen: (panelOpened: boolean) => setAngleSensorStatusPanelOpen(panelOpened),
            dataLoading: loading,
            tableDataSource: tableDataAngleSensorStatus,
            tableSelectRowKeys: selectedAngleSensorStatus,
            onChangeTableSelect: onChangeAngleSensorStatus,
            tableGetTooltips: getTabelTooltipAngleSensorStatus,
            seriesChartDatas: seriesChartDatasAngleSensorStatus,
            convertChartTooltips: convertChartTooltip,
            onMountedChart: () => setAngleSensorStatusChartMounted(true),
        },
    ];

    const [changedList, setChangedList] = useState<any>();

    const onDragEnd = (result: DropResult) => {
        // 드래그 앤 드롭이 완료된 후의 결과를 확인합니다.
        const { source, destination } = result;

        // 만약 드래그된 요소가 드롭되지 않았거나, 원래 위치와 같은 위치로 드래그되었다면 아무것도 하지 않습니다.
        if (!destination || (source.index === destination.index && source.droppableId === destination.droppableId)) {
            return;
        }

        // 드래그된 요소의 새로운 위치를 반영하여 배열을 업데이트합니다.
        const newList = Array.from(list);
        const [removed] = newList.splice(source.index, 1);
        newList.splice(destination.index, 0, removed);

        // 업데이트된 배열을 사용하여 컴포넌트 상태를 업데이트합니다.
        setChangedList(newList);
        console.log("newList", newList);
    };

    return (
        <Space direction="vertical" size={20} style={{ width: "100%" }}>
            {isMobile === false ? (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {(changedList || list).map((item: any, index: number) => {
                                    return (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                            {(provided, snapshot) => (
                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <CanDataWrapper
                                                        title={item.title}
                                                        id={item.id}
                                                        panelOpen={item.panelOpen}
                                                        onChangePanelOpen={item.onChangePanelOpen}
                                                        dataLoading={item.dataLoading}
                                                        tableDataSource={item.tableDataSource}
                                                        tableSelectRowKeys={item.tableSelectRowKeys}
                                                        onChangeTableSelect={item.onChangeTableSelect}
                                                        tableGetTooltips={item.tableGetTooltips}
                                                        seriesChartDatas={item.seriesChartDatas}
                                                        convertChartTooltips={item.convertChartTooltips}
                                                        selectedChartDataPointIndex={selectedDataPointIndex}
                                                        onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                                                        onMountedChart={item.onMountedChart}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <div>
                    {list.map((item: any) => {
                        return (
                            <CanDataWrapper
                                title={item.title}
                                id={item.id}
                                panelOpen={item.panelOpen}
                                onChangePanelOpen={item.onChangePanelOpen}
                                dataLoading={item.dataLoading}
                                tableDataSource={item.tableDataSource}
                                tableSelectRowKeys={item.tableSelectRowKeys}
                                onChangeTableSelect={item.onChangeTableSelect}
                                tableGetTooltips={item.tableGetTooltips}
                                seriesChartDatas={item.seriesChartDatas}
                                convertChartTooltips={item.convertChartTooltips}
                                selectedChartDataPointIndex={selectedDataPointIndex}
                                onChangedSelectChartDataPointIndex={onChangedSelectDataPointIndex}
                                onMountedChart={item.onMountedChart}
                            />
                        );
                    })}
                </div>
            )}
        </Space>
    );
}

export default CanDataYaxingViewer;
