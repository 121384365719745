import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useAuthState } from "../../provider/AuthProvider";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import { TableProps } from "antd/es/table/InternalTable";
import { useSelector, useDispatch } from "react-redux";
import { actionSetRunnings } from "../../utils/action";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestRunning from "../../utils/requestApiRunning";

import Badge from "antd/es/badge/index";
import Space from "antd/es/space/index";
import Tag from "antd/es/tag/index";

import moment from "moment";
import DataTable from "../../components/common/DataTable";
import Search from "../../components/browser/Search";

type SearchType = {
    carNumber: string;
    makerName: string;
    categoryName: string;
    startDate: string;
    endDate: string;
    checkbox: string[];
};

const PageRunnings = () => {
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerRunnings = useSelector((state) => state.runnings);

    const [histories, setHistories] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [filteredHistories, setFilteredHistories] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.RunningHistoryDto>>({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchValue, setSearchValue] = useState<SearchType>();
    const [loadingHistories, setLoadingHistories] = useState(false);
    const [tempHistories, setTempHistories] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestHistoriesPage, setRequestHistoriesPage] = useState(0);
    const [pageSize, setPageSize] = useState<number>(20);

    const searchData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            span: 12,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
        {
            id: "makerName",
            span: 6,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            span: 6,
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "searchDate",
            span: 12,
            title: String.searchDate,
            type: "rangePicker",
            dateFormat: Common.FORMAT_DATE_TIME,
        },
        {
            id: "checkbox",
            span: 12,
            title: String.alarmStatus,
            type: "checkbox",
            options: [
                { value: "error", label: String.error },
                { value: "warning", label: String.warning },
                { value: "normal", label: String.normal },
            ],
            defaultValue: ["error", "warning", "normal"],
        },
    ];

    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.RunningHistoryDto> = [
        {
            title: String.runningDate,
            dataIndex: "startTime",
            key: "startTime",
            width: "10%",
            sorter: (a, b, sortOrder) => Utils.sortDate(a.startTime, b.startTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "time" ? sortedInfo.order : null,
            render: (_, { startTime }) => moment.utc(startTime, Common.FORMAT_DATE).format(Common.FORMAT_DATE),
        },
        {
            title: String.carNumber,
            dataIndex: ["car", "carNumber"],
            key: "carNumber",
            width: "10%",
            align: "center",
        },
        {
            title: String.error + "/" + String.warning,
            key: "alarm",
            width: "8%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a.errorCount, b.errorCount, sortOrder),
            sortOrder: sortedInfo.columnKey === "errorCount" ? sortedInfo.order : null,
            render: (_, row) =>
                row.errorCount || row.warningCount ? (
                    <Space size={0}>
                        {row.errorCount ? <Tag className="errorTag">{row.errorCount}</Tag> : ""}
                        {row.warningCount ? <Tag className="warningTag">{row.warningCount}</Tag> : ""}
                    </Space>
                ) : (
                    <Tag className="normalTag">{row.errorCount}</Tag>
                ),
        },
        {
            title: String.startTime,
            dataIndex: "startTime",
            key: "startTime",
            align: "center",
            width: "8%",
            render: (_, { startTime, rtcCheck }) => {
                return rtcCheck === true ? (
                    <Badge style={{ right: "-4px" }} dot color="var(--danger)">
                        <span className="fs-md">{startTime ? moment(startTime).format(Common.FORMAT_TIME) : String.dash}</span>
                    </Badge>
                ) : startTime ? (
                    <span className="fs-md">{moment(startTime).format(Common.FORMAT_TIME)}</span>
                ) : (
                    String.dash
                );
            },
        },
        {
            title: String.endTime,
            dataIndex: "endTime",
            align: "center",
            width: "8%",
            render: (_, { startTime, endTime, finish }) => {
                const checkDday = moment(endTime).startOf("day").diff(moment(startTime).startOf("day"), "days");
                const checkAbnormal = finish !== true && moment(endTime, Common.FORMAT_DATE_TIME).isBefore(moment().subtract(10, "m"));
                return (
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {checkDday > 0 && (
                            <Tag className="normalTag" style={{ marginRight: 4 }}>
                                D+{checkDday}
                            </Tag>
                        )}
                        {checkAbnormal ? (
                            Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? (
                                <Badge style={{ right: "-4px" }} dot color="var(--danger)">
                                    <span className={"fs-md fc-point"}>
                                        {endTime ? moment(endTime).format(Common.FORMAT_TIME) : String.dash}
                                    </span>
                                </Badge>
                            ) : (
                                <span className={"fs-md"}>{endTime ? moment(endTime).format(Common.FORMAT_TIME) : String.dash}</span>
                            )
                        ) : finish === true ? (
                            <span className={"fs-md"}>{endTime ? moment(endTime).format(Common.FORMAT_TIME) : String.dash}</span>
                        ) : (
                            <Tag className="pointTag">운행중</Tag>
                        )}
                    </div>
                );
            },
        },
        {
            title: String.runningTime,
            dataIndex: "drivingTime",
            key: "drivingTime",
            width: "8%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a.drivingTime, b.drivingTime, sortOrder),
            sortOrder: sortedInfo.columnKey === "drivingTime" ? sortedInfo.order : null,
            render: (_, { drivingTime }) => Utils.secToTime(Number(drivingTime)),
        },
        {
            title: (
                <>
                    {String.traveledDistance}
                    <span className="fs-xs"> {String.kmDistanceUnit}</span>
                </>
            ),
            dataIndex: "drivingDistance",
            key: "drivingDistance",
            width: "8%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a.drivingDistance, b.drivingDistance, sortOrder),
            sortOrder: sortedInfo.columnKey === "drivingDistance" ? sortedInfo.order : null,
            render: (_, { drivingDistance }) => Utils.checkNumberData(drivingDistance),
        },
        {
            title: (
                <>
                    {String.avgSpeed}
                    <span className="fs-xs"> {String.kmSpeedUnit}</span>
                </>
            ),
            dataIndex: "avgSpeed",
            key: "avgSpeed",
            width: "8%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a.avgSpeed, b.avgSpeed, sortOrder),
            sortOrder: sortedInfo.columnKey === "avgSpeed" ? sortedInfo.order : null,
            render: (_, { avgSpeed }) => Utils.checkNumberData(avgSpeed),
        },
        {
            title: (
                <>
                    {String.electricMileage}
                    <span className="fs-xs"> {String.kmElectricMileageUnit}</span>
                </>
            ),
            dataIndex: "electricMileage",
            key: "electricMileage",
            width: "8%",
            sorter: (a, b, sortOrder) => Utils.sortNumber(a.electricMileage, b.electricMileage, sortOrder),
            sortOrder: sortedInfo.columnKey === "electricMileage" ? sortedInfo.order : null,
            render: (_, { electricMileage }) => Utils.checkNumberData(electricMileage),
        },
    ];

    useEffect(() => {
        if (Object.keys(reducerRunnings).length > 0) {
            setCurrentPage(reducerRunnings?.currentPage);

            setSearch(
                searchData?.map((item) => {
                    const { id, defaultStartDate, defaultEndDate, defaultValue } = item;
                    const isSearchDate = id === "searchDate";
                    const isCheckbox = id === "checkbox";

                    if (isSearchDate) {
                        return {
                            ...item,
                            defaultStartDate: reducerRunnings["startDate"] ?? defaultStartDate,
                            defaultEndDate: reducerRunnings["endDate"] ?? defaultEndDate,
                        };
                    } else if (isCheckbox) {
                        const { error = true, warning = true, normal = true } = reducerRunnings;

                        return {
                            ...item,
                            defaultValue: Object.entries({ error, warning, normal })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else {
                        return {
                            ...item,
                            defaultValue: reducerRunnings[id] ?? defaultValue,
                        };
                    }
                })
            );
        } else {
            setSearch(searchData);
        }
    }, [reducerRunnings]);

    const dispatchRunnings = (selectedId: number) => {
        dispatch(
            actionSetRunnings({
                ...searchValue,
                currentPage: currentPage,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-layout-content").scrollTop,
                pageSize: pageSize,
            })
        );
    };

    const onTableChange: TableProps<TypeDTO.RunningHistoryDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.RunningHistoryDto>);
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const onSearchData = (value: any) => {
        const { checkbox, ...searchValue } = value;

        searchValue.starDate = moment(value?.startDate).format(Common.FORMAT_DATE_HOUR_TIME_START);
        searchValue.endDate = moment(value?.endDate).format(Common.FORMAT_DATE_HOUR_TIME_END);
        searchValue.error = value?.checkbox?.includes("error");
        searchValue.warning = value?.checkbox?.includes("warning");
        searchValue.normal = value?.checkbox?.includes("normal");

        setSearchValue(searchValue);
        setLoadingHistories(true);
        requestApiRunningHistoryList(searchValue, requestHistoriesPage);
    };

    const { requestApiRunningHistoryList, resultApiRunningHistoryList } = RequestRunning.useRequestApiRunningHistoryList();

    useEffect(() => {
        if (!resultApiRunningHistoryList) return;

        if (resultApiRunningHistoryList.runningHistories.totalPages > requestHistoriesPage + 1) {
            setTempHistories([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(requestHistoriesPage + 1);
        } else {
            setLoadingHistories(false);
            setHistories([...(tempHistories || []), ...resultApiRunningHistoryList.runningHistories.content]);
            setRequestHistoriesPage(0);
            setTempHistories([]);

            setTimeout(() => {
                document.querySelector(".ant-layout-content").scrollTo({ top: reducerRunnings.scrollPosition });
            }, 100);
        }
    }, [resultApiRunningHistoryList]);

    useEffect(() => {
        if (requestHistoriesPage === 0) return;

        requestApiRunningHistoryList(searchValue, requestHistoriesPage);
    }, [requestHistoriesPage]);

    useEffect(() => {
        if (histories === undefined) return;
        const array: Array<TypeDTO.RunningHistoryDto> = [];
        histories.map((history) => {
            history.rtcCheck !== true && array.push(history);
        });
        setFilteredHistories(array);
    }, [histories]);

    return (
        <div className="pageWrapper">
            <Search
                title={String.menu_running_history}
                dataLength={histories?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
            />
            <DataTable
                rowKey="endTime"
                disabledTitle
                loading={loadingHistories}
                columns={columns}
                dataSource={Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN]) ? histories : filteredHistories}
                currentPage={currentPage}
                rowClassName={(history: TypeDTO.RunningHistoryDto) => {
                    return history?.runningHistoryId === reducerRunnings?.selectedId ? "table-row-selected" : "";
                }}
                tableSize={reducerRunnings?.pageSize}
                onRow={(history: TypeDTO.RunningHistoryDto) => {
                    dispatchRunnings(history.runningHistoryId);
                    navigate(Common.PAGE_RUNNING_DETAILS + "/" + history.car.carId + "/" + history.bootTime);
                }}
                onChange={onTableChange}
            />
        </div>
    );
};

export default PageRunnings;
