import { useState, useEffect } from "react";
import type { ColumnsType, SorterResult } from "antd/es/table/interface";
import type { TableProps } from "antd/es/table/InternalTable";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestCar from "../../utils/requestApiCar";

import DataTable from "./DataTable";
import Search from "../browser/Search";

type SearchType = {
    makerName: string;
    categoryName: string;
    carNumber: string;
};

function GroupCars({
    inputType,
    groupCars,
    selectedRowKeys,
    onSelected,
}: {
    inputType?: boolean;
    groupCars?: Array<TypeDTO.CarDto>;
    selectedRowKeys?: Array<string>;
    onSelected?: (selectedCars?: Array<TypeDTO.CarDto>) => void;
}) {
    const [sortedInfo, setSortedInfo] = useState<SorterResult<TypeDTO.CarDto>>({});
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto>>();
    const [selectedCars, setSelectedCars] = useState<Array<TypeDTO.CarDto>>([]);

    useEffect(() => {
        if (inputType) {
            requestApiCarList();
        } else {
            setFilteredCars(undefined);
        }

        if (groupCars) {
            setCars(groupCars);
            setSelectedCars(groupCars);
        }
    }, [groupCars, inputType]);

    useEffect(() => {
        onSelected && onSelected(selectedCars);
    }, [selectedCars]);

    const columns: ColumnsType<TypeDTO.CarDto> = [
        {
            title: String.carNumber,
            dataIndex: "carNumber",
            key: "carNumber",
            width: "10%",
            sorter: (a: any, b: any, sortOrder: any) => Utils.sortString(a.carNumber, b.carNumber, sortOrder),
            sortOrder: sortedInfo.columnKey === "carNumber" ? sortedInfo.order : null,
        },
        {
            title: String.customer,
            dataIndex: ["customer", "customerName"],
            key: "customerName",
            width: "20%",
            sorter: (a: any, b: any, sortOrder: any) => Utils.sortString(a.customer?.customerName, b.customer?.customerName, sortOrder),
            sortOrder: sortedInfo.columnKey === "customer" ? sortedInfo.order : null,
            render: (_, { customer }) => customer?.customerName || String.dash,
        },
        {
            title: String.phone,
            dataIndex: ["customer", "customerPhone"],
            key: "customerPhone",
            width: "25%",
            align: "center",
            render: (_, { customer }) => Utils.convertPhone(customer?.customerPhone) || String.dash,
        },
        {
            title: String.maker,
            dataIndex: ["maker", "makerName"],
            key: "makerName",
            width: "10%",
            align: "center",
            render: (_, { makerModel }) => makerModel?.maker?.makerName || String.dash,
        },
        {
            title: String.type,
            dataIndex: ["category", "categoryName"],
            key: "categoryName",
            width: "10%",
            align: "center",
            render: (_, { category }) => category?.categoryName || String.dash,
        },
    ];

    const search: TypeUtils.SearchItemType[] = [
        {
            id: "makerName",
            span: 12,
            title: String.maker,
            type: "select",
            typeDetail: "maker",
        },
        {
            id: "categoryName",
            span: 12,
            title: String.category,
            type: "select",
            typeDetail: "category",
        },
        {
            id: "carNumber",
            span: 24,
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
    ];

    const { loadingApiCarList, requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    const onTableChange: TableProps<TypeDTO.CarDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.CarDto>);
    };

    const onSearchData = (value: SearchType) => {
        const searchFilter = Utils.searchFilter;

        setFilteredCars(
            cars?.filter(
                (car) =>
                    searchFilter(car?.makerModel?.maker, value?.makerName, "makerName") &&
                    searchFilter(car?.category, value?.categoryName, "categoryName") &&
                    searchFilter(car, value?.carNumber, "carNumber")
            )
        );
    };

    const onCarSelect = (values: Array<TypeDTO.CarDto>, selectedRowKeys: Array<React.Key>) => {
        if (!filteredCars) {
            setSelectedCars(values);
        } else {
            const checked = filteredCars?.filter((value) => selectedRowKeys.includes(value.carNumber));
            const unChecked = filteredCars?.filter((value) => !selectedRowKeys.includes(value.carNumber));
            const carNumbers = selectedCars.map((value) => value.carNumber);

            checked?.forEach((value) => {
                if (!carNumbers.includes(value.carNumber)) {
                    setSelectedCars((prevSelectedCars) => [...prevSelectedCars, value]);
                }
            });

            unChecked?.forEach((value) => {
                if (carNumbers.includes(value.carNumber)) {
                    setSelectedCars((prevSelectedCars) => prevSelectedCars.filter((car) => car.carNumber !== value.carNumber));
                }
            });
        }
    };

    return (
        <>
            <Search
                title={String.carInfo}
                dataLength={filteredCars?.length || cars?.length}
                values={search}
                onSearch={(value) => onSearchData(value)}
                onClear={(value) => onSearchData(value)}
            />
            <DataTable
                hasScroll={false}
                columns={columns}
                disabledTitle
                loading={loadingApiCarList}
                dataSource={filteredCars || cars}
                rowKey={(render: TypeDTO.CarDto) => render?.carNumber}
                onSelected={inputType ? onCarSelect : undefined}
                selectedRowKeys={selectedRowKeys}
                onChange={onTableChange}
            />
        </>
    );
}

export default GroupCars;
