import { useState, useEffect } from "react";
import { useAuthState } from "../../provider/AuthProvider";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { actionSetIssuesByCar } from "../../utils/action";
import { type TableProps } from "antd/es/table/InternalTable";
import Space from "antd/es/space/index";
import type { SorterResult } from "antd/es/table/interface";

import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as Common from "../../commons/common";
import * as String from "../../commons/string";
import * as RequestIssue from "../../utils/requestApiIssue";
import * as Utils from "../../utils/utils";

import moment from "moment";
import DataTable from "../common/DataTable";
import Filter from "./Filter";
import CustomRangePicker from "../../components/mobile/CustomRangePicker";
import IconRedExclamtion from "../../assets/images/icon/icon_red_exclamation.png";
import IconInAction from "../../assets/images/icon/icon_in_action.png";
import IconCheck from "../../assets/images/icon/icon_gray_check.png";

export type SearchType = {
    carNumber: string;
    issueManager: string;
    checkbox: string[];
    searchValue: string;
    startDate: string;
    endDate: string;
};

const Maintenances = ({ carNumber }: { carNumber?: string }) => {
    const navigate = useNavigate();
    const userDetails = useAuthState();
    const dispatch = useDispatch();
    const reducerIssuesByCar = useSelector((state) => state.issuesByCar);

    const [issues, setIssues] = useState<Array<TypeDTO.IssueDto>>([]);
    const [searchValue, setSearchValue] = useState<SearchType>({
        startDate: reducerIssuesByCar.startDate || moment().subtract(7, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
        endDate: reducerIssuesByCar.endDate || moment().format(Common.FORMAT_DATE_TIME),
    });
    const [tempIssues, setTempIssues] = useState<Array<TypeDTO.RunningHistoryDto>>();
    const [requestIssuesPage, setRequestIssuesPage] = useState(0);

    const filterData: TypeUtils.SearchItemType[] = [
        {
            id: "carNumber",
            title: String.carNumber,
            type: "select",
            typeDetail: "car",
        },
        {
            id: "checkbox",
            title: String.actionStatus,
            type: "checkbox",
            options: [
                { value: "waiting", label: String.waiting },
                { value: "inAction", label: String.action },
                { value: "completed", label: String.completion },
            ],
            defaultValue: ["waiting", "inAction", "completed"],
        },
        {
            id: "issueType",
            title: String.issueType,
            type: "select",
            typeDetail: "issueType",
        },
        {
            id: "searchValue",
            title: String.issueContent,
            type: "input",
            typeDetail: "issueTitle",
        },
        {
            id: "issueManagerName",
            title: String.chargingManager,
            type: "select",
            typeDetail: "name",
        },
    ];

    const [filter, setFilter] = useState<TypeUtils.SearchItemType[]>();

    const columns: ColumnsType<TypeDTO.IssueDto> = carNumber
        ? [
              {
                  title: String.status,
                  dataIndex: "actionStatus",
                  key: "actionStatus",
                  align: "center",
                  width: "8%",
                  render: (_, { actionStatus }) => {
                      return actionStatus === "WAITING" ? (
                          <img alt="icon" src={IconRedExclamtion} width={20} height={20} />
                      ) : actionStatus === "COMPLETED" ? (
                          <img alt="icon" src={IconCheck} width={20} height={20} />
                      ) : actionStatus === "IN_ACTION" ? (
                          <img alt="icon" src={IconInAction} width={20} height={20} />
                      ) : (
                          String.dash
                      );
                  },
              },
              {
                  title: String.title,
                  dataIndex: "issueTitle",
                  key: "issueTitle",
                  align: "center",
                  width: "15%",
              },
          ]
        : [
              {
                  title: String.status,
                  dataIndex: "actionStatus",
                  key: "actionStatus",
                  align: "center",
                  width: "8%",
                  render: (_, { actionStatus }) => {
                      return actionStatus === "WAITING" ? (
                          <img alt="icon" src={IconRedExclamtion} width={20} height={20} />
                      ) : actionStatus === "COMPLETED" ? (
                          <img alt="icon" src={IconCheck} width={20} height={20} />
                      ) : actionStatus === "IN_ACTION" ? (
                          <img alt="icon" src={IconInAction} width={20} height={20} />
                      ) : (
                          String.dash
                      );
                  },
              },
              {
                  title: String.carNumber,
                  dataIndex: ["car", "carNumber"],
                  key: "carNumber",
                  align: "center",
                  width: "20%",
              },
              {
                  title: String.title,
                  dataIndex: "issueTitle",
                  key: "issueTitle",
                  align: "center",
                  width: "20%",
              },
          ];

    const onTableChange: TableProps<TypeDTO.IssueDto>["onChange"] = (pagination, filters, sorter) => {
        setSortedInfo(sorter as SorterResult<TypeDTO.IssueDto>);
    };

    useEffect(() => {
        setTimeout(() => {
            document.querySelector(".ant-table-body").scrollTo({ top: reducerIssuesByCar.scrollPosition });
        }, 100);
    }, [issues]);

    useEffect(() => {
        if (Object.keys(reducerIssuesByCar).length > 2) {
            console.log("reducerIssuesByCar.length > 2", reducerIssuesByCar);

            setFilter(
                filterData?.map((item) => {
                    const { id, defaultValue } = item;
                    const isCheckbox = id === "checkbox";

                    if (isCheckbox) {
                        const { waiting = true, inAction = true, completed = true } = reducerIssuesByCar;

                        return {
                            ...item,
                            defaultValue: Object.entries({ waiting, inAction, completed })
                                .filter(([key, value]) => value)
                                .map(([key]) => key),
                        };
                    } else {
                        return {
                            ...item,
                            defaultValue: reducerIssuesByCar[id] ?? defaultValue,
                        };
                    }
                })
            );
            setSearchValue(reducerIssuesByCar);
        } else {
            setFilter(filterData);
            requestApiIssueList();
        }
    }, [reducerIssuesByCar]);

    const dispatchIssuesByCar = (selectedId: number) => {
        console.log("dispatchIssuesByCar", searchValue);
        dispatch(
            actionSetIssuesByCar({
                ...searchValue,
                selectedId: selectedId,
                scrollPosition: document.querySelector(".ant-table-body").scrollTop,
            })
        );
    };

    const onSearchData = (value: any) => {
        const { checkbox, ...searchValue } = value;

        searchValue.carNumber = carNumber || value.carNumber;
        searchValue.waiting = value?.waiting ?? value?.checkbox?.includes("waiting") ?? false;
        searchValue.inAction = value?.inAction ?? value?.checkbox?.includes("inAction") ?? false;
        searchValue.completed = value?.completed ?? value?.checkbox?.includes("completed") ?? false;
        searchValue.searchValue = value?.issueTitle;

        setSearchValue(searchValue);
        requestApiIssueList(searchValue, requestIssuesPage);
    };

    const { loadingApiIssueList, requestApiIssueList, resultApiIssueList } = RequestIssue.useRequestApiIssueList();

    useEffect(() => {
        if (!resultApiIssueList) return;

        if (resultApiIssueList.issues.totalPages > requestIssuesPage + 1) {
            setTempIssues([...(tempIssues || []), ...resultApiIssueList.issues.content]);
            setRequestIssuesPage(requestIssuesPage + 1);
        } else {
            setIssues([...(tempIssues || []), ...resultApiIssueList.issues.content]);
            setRequestIssuesPage(0);
            setTempIssues([]);
        }
    }, [resultApiIssueList]);

    useEffect(() => {
        if (requestIssuesPage === 0) return;

        requestApiIssueList(searchValue, requestIssuesPage);
    }, [requestIssuesPage]);

    return (
        <div className="pageWrapper">
            <CustomRangePicker
                todayMax={false}
                value={{ startDate: searchValue.startDate, endDate: searchValue.endDate }}
                onSearch={(value) => {
                    if (value) {
                        onSearchData({ ...searchValue, startDate: value.startDate, endDate: value.endDate });
                    } else {
                        onSearchData({
                            ...searchValue,
                            startDate: moment().subtract(7, "day").startOf("day").format(Common.FORMAT_DATE_TIME),
                            endDate: moment().endOf("day").format(Common.FORMAT_DATE_TIME),
                        });
                    }
                }}
            />
            <Space direction="vertical">
                <Filter
                    dataLength={issues?.length}
                    values={carNumber ? filter?.filter((item) => item.id !== "carNumber") : filter}
                    onSearch={(value) => {
                        onSearchData({ ...value, startDate: searchValue.startDate, endDate: searchValue.endDate });
                    }}
                />
                <DataTable
                    isMobile
                    rowKey={(row: TypeDTO.IssueDto) => row.issueId}
                    disabledTitle
                    disabledPagination
                    loading={loadingApiIssueList}
                    columns={
                        Utils.roleCheck(userDetails?.user?.authority, [
                            Utils.getAuthority.ROLE_ADMIN,
                            Utils.getAuthority.ROLE_MANAGER,
                            Utils.getAuthority.ROLE_CUSTOMER_MANAGER,
                            Utils.getAuthority.ROLE_CUSTOMER,
                        ])
                            ? columns
                            : columns.filter((column) => column.key !== "detail")
                    }
                    dataSource={issues}
                    rowClassName={(issue: TypeDTO.IssueDto) => {
                        return issue?.issueId === reducerIssuesByCar?.selectedId ? "table-row-selected" : "";
                    }}
                    onRow={(value: TypeDTO.IssueDto) => {
                        dispatchIssuesByCar(value.issueId);
                        navigate(Common.PAGE_MANAGE_MAINTENANCE_DETAIL + "/" + value.car.carId + "/" + value.issueId);
                    }}
                    onChange={onTableChange}
                    scroll="calc(var(--vh, 1vh) * 100 - 292px)"
                />
            </Space>
        </div>
    );
};

export default Maintenances;
