import { useEffect, useState } from "react";
import Button from "antd/es/button/button";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Drawer from "antd/es/drawer/index";

import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";

import styles from "../../pages/browser/Dashboard.module.css";
import IconGraph from "../../assets/images/icon/icon_graph_1.png";
import StatsRunningHistoryByPeriod from "./StatsRunningHistoryByPeriod";
import StatsErrorByPeriod from "./StatsErrorByPeriod";

const DashboardDrawer = ({ drivingCount }: { drivingCount?: number }) => {
    const [open, setOpen] = useState<boolean>(true);
    const [drawerHeight, setDrawerHeight] = useState<string>("clamp(300px, 30vh, 400px)");

    useEffect(() => {
        const isOpen = Utils.getLocalStorage(Common.CONTEXT_OPEN_DRAWER) === "true";
        setDrawerHeight(isOpen ? "clamp(300px, 30vh, 400px)" : "0px");
        setOpen(isOpen);
    }, []);

    const onClick = () => {
        const isOpen = !open;
        setDrawerHeight("clamp(300px, 30vh, 400px)");
        Utils.setLocalStorage(Common.CONTEXT_OPEN_DRAWER, String(isOpen));
        setOpen(isOpen);
    };

    return (
        <Drawer
            className={open ? `drawerOpened ${styles.drawerWrapper}` : `drawerClosed ${styles.drawerWrapper}`}
            height={drawerHeight}
            placement="bottom"
            closable={false}
            mask={false}
            getContainer={false}
            open={open}
        >
            <Button className={styles.drawerBtn} icon={<img alt="" src={IconGraph} width={24} />} onClick={onClick} />
            <Row className={styles.drawerStatsRow}>
                <Col span={12} className={styles.drawerStatsCol}>
                    <StatsRunningHistoryByPeriod disabledSearch drivingCount={drivingCount} />
                </Col>
                <Col span={12} className={styles.drawerStatsCol}>
                    <StatsErrorByPeriod disabledSearch />
                </Col>
            </Row>
        </Drawer>
    );
};

export default DashboardDrawer;
