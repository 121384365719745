import { ReactNode, useEffect, useState } from "react";
import DatePicker from "antd/es/date-picker/index";

import * as Common from "../../commons/common";

import styles from "../browser/Search.module.css";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/ko_KR";

function CustomDatePicker({
    open,
    value,
    hasTime,
    dateFormat,
    className,
    renderExtraFooter,
    maxDate,
    minDate,
    allowClear,
    disabled,
    onClick,
    onChange,
}: {
    open?: boolean;
    value?: string;
    hasTime: boolean;
    dateFormat: string;
    className?: string;
    renderExtraFooter?: (mode: any) => ReactNode;
    maxDate?: dayjs.Dayjs;
    minDate?: dayjs.Dayjs;
    allowClear?: boolean;
    disabled?: boolean;
    onClick?: () => void;
    onChange?: (value: string | undefined) => void;
}) {
    const [displayFormat, setDisplayFormat] = useState<string>();

    const onChangedDate = (date: dayjs.Dayjs, dateString: any) => {
        if (onChange) {
            if (date || date === "") {
                onChange(dateString);
            } else {
                onChange(undefined);
            }
        }
    };

    useEffect(() => {
        setDisplayFormat(hasTime ? dateFormat : Common.FORMAT_DATE);
    }, [dateFormat]);

    return (
        <DatePicker
            open={open}
            allowClear={allowClear}
            locale={locale}
            className={className ? `${className} ${styles.datePicker}` : styles.datePicker}
            showTime={hasTime}
            format={displayFormat}
            disabled={disabled}
            value={value ? dayjs(value) : null}
            renderExtraFooter={renderExtraFooter}
            onChange={onChangedDate}
            onClick={onClick}
            placeholder="날짜 선택"
            maxDate={maxDate}
            minDate={minDate}
            showToday={false}
        />
    );
}

export default CustomDatePicker;
