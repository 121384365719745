import * as React from "react";
import { useParams } from "react-router";

import Soc from "../../components/mobile/Soc";

const PageSocByCar = () => {
    const params = useParams();

    return (
        <div className="pageWrapper">
            <Soc carNumber={params.id} />
        </div>
    );
};

export default PageSocByCar;
