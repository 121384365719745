import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";
import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export type VehicleRunningHistorySearch = {
    carNumber?: string;
    makerName?: string;
    categoryName?: string;
    startDate?: string;
    endDate?: string;
    error?: boolean;
    warning?: boolean;
    startElectricRate?: number;
    endElectricRate?: number;
    startMileage?: number;
    endMileage?: number;
    startAvgSpeed?: number;
    endAvgSpeed?: number;
};

export const useRequestApiRunningHistoryList = () => {
    const PAGE_SIZE = 1000;

    const requestAxiosApiRunningHistoryList = async (searchType: VehicleRunningHistorySearch, page: number, size: number) => {
        const response = await utilAxiosWithAuth().get(Request.RUNNING_HISTORIES_URL, {
            params: { ...searchType, page: page, size: PAGE_SIZE },
        });

        return response.data;
    };

    const {
        loading: loadingApiRunningHistoryList,
        error: errorApiRunningHistoryList,
        data: resultApiRunningHistoryList,
        execute: requestApiRunningHistoryList,
    } = useAsyncAxios(requestAxiosApiRunningHistoryList);

    useEffect(() => {
        if (resultApiRunningHistoryList === null) return;
        DEBUG && console.log("resultApiRunningHistoryList", resultApiRunningHistoryList);
    }, [resultApiRunningHistoryList]);

    useEffect(() => {
        if (errorApiRunningHistoryList === null) return;
        DEBUG && console.log("errorApiRunningHistoryList", errorApiRunningHistoryList);
    }, [errorApiRunningHistoryList]);

    return {
        loadingApiRunningHistoryList,
        errorApiRunningHistoryList,
        resultApiRunningHistoryList,
        requestApiRunningHistoryList,
    };
};

export const useRequestApiRunningHistoryLast = () => {
    const requestAxiosApiRunningHistoryLast = async (searchType: VehicleRunningHistorySearch, page: number, size: number) => {
        const response = await utilAxiosWithAuth().get(Request.RUNNING_HISTORIES_LAST_URL);

        return response.data;
    };

    const {
        loading: loadingApiRunningHistoryLast,
        error: errorApiRunningHistoryLast,
        data: resultApiRunningHistoryLast,
        execute: requestApiRunningHistoryLast,
    } = useAsyncAxios(requestAxiosApiRunningHistoryLast);

    useEffect(() => {
        if (resultApiRunningHistoryLast === null) return;
        DEBUG && console.log("resultApiRunningHistoryLast", resultApiRunningHistoryLast);
    }, [resultApiRunningHistoryLast]);

    useEffect(() => {
        if (errorApiRunningHistoryLast === null) return;
        DEBUG && console.log("errorApiRunningHistoryLast", errorApiRunningHistoryLast);
    }, [errorApiRunningHistoryLast]);

    return {
        loadingApiRunningHistoryLast,
        errorApiRunningHistoryLast,
        resultApiRunningHistoryLast,
        requestApiRunningHistoryLast,
    };
};

export type RunningCanChartState = {
    carId: number;
    bootTime?: string;
    startDate?: string;
    endDate?: string;
};

export const useRequestApiRunningHistory = () => {
    const requestAxiosApiRunningHistory = async (searchType: RunningCanChartState) => {
        const response = await utilAxiosWithAuth().get(Request.RUNNING_HISTORY_URL, {
            params: searchType,
        });

        return response.data;
    };

    const {
        loading: loadingApiRunningHistory,
        error: errorApiRunningHistory,
        data: resultApiRunningHistory,
        execute: requestApiRunningHistory,
    } = useAsyncAxios(requestAxiosApiRunningHistory);

    useEffect(() => {
        if (resultApiRunningHistory === null) return;
        DEBUG && console.log("resultApiRunningHistory", resultApiRunningHistory);
    }, [resultApiRunningHistory]);

    useEffect(() => {
        if (errorApiRunningHistory === null) return;
        DEBUG && console.log("errorApiRunningHistory", errorApiRunningHistory);
    }, [errorApiRunningHistory]);

    return {
        loadingApiRunningHistory,
        errorApiRunningHistory,
        resultApiRunningHistory,
        requestApiRunningHistory,
    };
};

export const useRequestApiRunningData = () => {
    const PAGE_SIZE = 10000;

    const requestAxiosApiRunningData = async (searchType: RunningCanChartState, page: number, size: number) => {
        const response = await utilAxiosWithAuth().get(Request.RUNNING_DATA_URL, {
            params: { ...searchType, page: page, size: PAGE_SIZE },
        });

        return response.data;
    };

    const {
        loading: loadingApiRunningData,
        error: errorApiRunningData,
        data: resultApiRunningData,
        execute: requestApiRunningData,
    } = useAsyncAxios(requestAxiosApiRunningData);

    useEffect(() => {
        if (resultApiRunningData === null) return;
        DEBUG && console.log("resultApiRunningData", resultApiRunningData);
    }, [resultApiRunningData]);

    useEffect(() => {
        if (errorApiRunningData === null) return;
        DEBUG && console.log("errorApiRunningData", errorApiRunningData);
    }, [errorApiRunningData]);

    return {
        loadingApiRunningData,
        errorApiRunningData,
        resultApiRunningData,
        requestApiRunningData,
    };
};

export const useRequestApiRunningGpsData = () => {
    const requestAxiosApiRunningGpsData = async (searchType: RunningCanChartState, page: number, size: number) => {
        const response = await utilAxiosWithAuth().get(Request.RUNNING_GPS_DATA_URL, {
            params: { ...searchType },
        });

        return response.data;
    };

    const {
        loading: loadingApiRunningGpsData,
        error: errorApiRunningGpsData,
        data: resultApiRunningGpsData,
        execute: requestApiRunningGpsData,
    } = useAsyncAxios(requestAxiosApiRunningGpsData);

    useEffect(() => {
        if (resultApiRunningGpsData === null) return;
        DEBUG && console.log("resultApiRunningGpsData", resultApiRunningGpsData);
    }, [resultApiRunningGpsData]);

    useEffect(() => {
        if (errorApiRunningGpsData === null) return;
        DEBUG && console.log("errorApiRunningGpsData", errorApiRunningGpsData);
    }, [errorApiRunningGpsData]);

    return {
        loadingApiRunningGpsData,
        errorApiRunningGpsData,
        resultApiRunningGpsData,
        requestApiRunningGpsData,
    };
};

export const useRequestApiRunningDataDownload = () => {
    const requestAxiosApiRunningDataDownload = async (searchType: RunningCanChartState) => {
        const response = await utilAxiosWithAuth().get(Request.RUNNING_DATA_DOWNLOAD_URL, {
            params: searchType,
        });

        return response.data;
    };

    const {
        loading: loadingApiRunningDataDownload,
        error: errorApiRunningDataDownload,
        data: resultApiRunningDataDownload,
        execute: requestApiRunningDataDownload,
    } = useAsyncAxios(requestAxiosApiRunningDataDownload);

    useEffect(() => {
        if (resultApiRunningDataDownload === null) return;
        DEBUG && console.log("resultApiRunningDataDownload", resultApiRunningDataDownload);

        // const file = new Blob(["\ufeff" + resultApiRunningDataDownload.data], { type: "text/csv;charset=utf-8;" });
        // const element = document.createElement("a");
        // element.href = URL.createObjectURL(file);
        // element.download = decodeURIComponent(resultApiRunningDataDownload.headers["content-disposition"].split("filename=")[1].replace(/"/g, ""));
        // element.click();
    }, [resultApiRunningDataDownload]);

    useEffect(() => {
        if (errorApiRunningDataDownload === null) return;
        DEBUG && console.log("errorApiRunningDataDownload", errorApiRunningDataDownload);
    }, [errorApiRunningDataDownload]);

    return {
        loadingApiRunningDataDownload,
        errorApiRunningDataDownload,
        resultApiRunningDataDownload,
        requestApiRunningDataDownload,
    };
};

export type RunningHistoryStatsState = {
    startDate?: string;
    endDate?: string;
    period?: "YEAR" | "MONTH" | "DAY";
};

export const useRequestApiRunningHistoryStatsData = () => {
    const requestAxiosApiRunningHistoryStatsData = async (searchType: RunningHistoryStatsState) => {
        const response = await utilAxiosWithAuth().get(Request.RUNNING_HISTORY_STATS_URL, {
            params: searchType,
        });

        return response.data;
    };

    const {
        loading: loadingApiRunningHistoryStatsData,
        error: errorApiRunningHistoryStatsData,
        data: resultApiRunningHistoryStatsData,
        execute: requestApiRunningHistoryStatsData,
    } = useAsyncAxios(requestAxiosApiRunningHistoryStatsData);

    useEffect(() => {
        if (!resultApiRunningHistoryStatsData) return;
        DEBUG && console.log("resultApiRunningHistoryStatsData", resultApiRunningHistoryStatsData);
    }, [resultApiRunningHistoryStatsData]);

    useEffect(() => {
        if (!errorApiRunningHistoryStatsData) return;
        DEBUG && console.log("errorApiRunningHistoryStatsData", errorApiRunningHistoryStatsData);
    }, [errorApiRunningHistoryStatsData]);

    return {
        loadingApiRunningHistoryStatsData,
        errorApiRunningHistoryStatsData,
        resultApiRunningHistoryStatsData,
        requestApiRunningHistoryStatsData,
    };
};
