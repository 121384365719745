import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { mapMarker } from "../../components/common/KakaoMap";
import { Tabs } from "antd";

import * as TypeDTO from "../../commons/typeDTO";
import * as Utils from "../../utils/utils";
import * as RequestRunning from "../../utils/requestApiRunning";
import * as RequestCar from "../../utils/requestApiCar";

import Form from "antd/es/form/index";
import TabRunningInfo from "../../components/mobile/TabRunningInfo";
import TabRunningMap from "../../components/mobile/TabRunningMap";
import TabRunningData from "../../components/mobile/TabRunningData";

import MarkerLocation from "../../assets/images/marker/marker_car_location.png";

export type RunningCanChartState = {
    carId: number;
    bootTime?: string;
    startDate?: string;
    endDate?: string;
};

export type VehicleInfo = {
    startTime: string;
    endTime: string;
};

function PageRunningDetails() {
    const param = useParams();
    const location = useLocation();
    const [carForm] = Form.useForm();
    const [runningsForm] = Form.useForm();

    const [canChartState, setCanChartState] = useState<RunningCanChartState | undefined>(undefined);
    const [car, setCar] = useState<TypeDTO.CarDto | undefined>(undefined);
    const [runningCanDataList, setRunningCanDataList] = useState<Array<TypeDTO.CanData> | undefined>(undefined);
    const [runningCanGpsDataList, setRunningCanGpsDataList] = useState<Array<TypeDTO.DataGpsDto> | undefined>(undefined);

    const [runningHistory, setRunningHistory] = useState<TypeDTO.RunningHistoryDto>();
    const [isFinish, setIsFinish] = useState<boolean>(false);
    const [chartMarker, setChartMarker] = useState<mapMarker | undefined>(undefined);
    const [selectedDataPointIndex, setSelectedDataPointIndex] = useState(-1);

    const [requestCanDataPage, setRequestCanDataPage] = useState(0);
    const [tempRunningCanDataList, setTempRunningCanDataList] = useState<Array<TypeDTO.CanData> | undefined>(undefined);
    const [alarmInfo, setAlarmInfo] = useState<TypeDTO.AlarmDto>();
    const [loading, setLoading] = useState<boolean>(false);

    const [activeKey, setActiveKey] = useState<string>(localStorage.getItem("runningDetailTab") || "운행 정보");

    useEffect(() => {
        if (param.id1 && param.id2) {
            if (!param.id3) {
                setCanChartState({ carId: Number(param.id1), bootTime: param.id2 });
            } else {
                setCanChartState({ carId: Number(param.id1), startDate: param.id2, endDate: param.id3 });
            }
        }
    }, [param]);

    useEffect(() => {
        location.state && setAlarmInfo(location.state as TypeDTO.AlarmDto);
    }, [location.state]);

    useEffect(() => {
        canChartState && requestApiCarInfo(canChartState.carId);
    }, [canChartState]);

    const { loadingApiCarInfo, requestApiCarInfo, resultApiCarInfo } = RequestCar.useRequestApiCarInfo();

    useEffect(() => {
        if (!resultApiCarInfo) return;

        const car = resultApiCarInfo.car;
        setCar(car);
        carForm.setFieldsValue({
            ...car,
            customer: {
                ...car.customer,
                customerPhone: Utils.convertPhone(car?.customer?.customerPhone),
            },
        });
        setLoading(true);

        if (car) {
            getRunningHistory();
            requestApiRunningGpsData(canChartState);
            requestApiRunningData(canChartState, requestCanDataPage);
        }
    }, [resultApiCarInfo]);

    const { loadingApiRunningHistory, requestApiRunningHistory, resultApiRunningHistory } = RequestRunning.useRequestApiRunningHistory();

    useEffect(() => {
        setRunningHistory(resultApiRunningHistory?.runningHistory);
        setIsFinish(resultApiRunningHistory?.runningHistory?.finish);
    }, [resultApiRunningHistory]);

    const { loadingApiRunningGpsData, requestApiRunningGpsData, resultApiRunningGpsData } = RequestRunning.useRequestApiRunningGpsData();

    useEffect(() => {
        setRunningCanGpsDataList(resultApiRunningGpsData?.runningGps);
    }, [resultApiRunningGpsData]);

    const { loadingApiRunningData, requestApiRunningData, resultApiRunningData } = RequestRunning.useRequestApiRunningData();

    const getRunningHistory = () => {
        if (canChartState?.bootTime) {
            requestApiRunningHistory(canChartState);
        } else if (canChartState?.startDate && canChartState?.endDate) {
            runningsForm.setFieldsValue({
                startTime: canChartState.startDate,
                endTime: canChartState.endDate,
            });
        }
    };

    useEffect(() => {
        if (!resultApiRunningData) {
            return;
        }

        if (!resultApiRunningData.runningData || !resultApiRunningData.runningData) {
            setLoading(false);
            return;
        }

        if (resultApiRunningData.runningData.totalPages > requestCanDataPage + 1) {
            if (tempRunningCanDataList) {
                setTempRunningCanDataList([...tempRunningCanDataList, ...resultApiRunningData.runningData.content]);
            } else {
                setTempRunningCanDataList(resultApiRunningData.runningData.content);
            }
            setRequestCanDataPage(requestCanDataPage + 1);
        } else {
            setLoading(false);
            if (tempRunningCanDataList) {
                setRunningCanDataList([...tempRunningCanDataList, ...resultApiRunningData.runningData.content]);
            } else {
                setRunningCanDataList(resultApiRunningData.runningData.content);
            }

            setRequestCanDataPage(0);
            setTempRunningCanDataList([]);
        }
    }, [resultApiRunningData]);

    useEffect(() => {
        if (requestCanDataPage !== 0) {
            requestApiRunningData(canChartState, requestCanDataPage);
        }
    }, [requestCanDataPage]);

    useEffect(() => {
        if (!runningCanDataList) return;

        const canData = runningCanDataList[selectedDataPointIndex] as TypeDTO.BasicCanData;
        const vehicleImage = MarkerLocation;

        setChartMarker({
            key: "",
            position: {
                lat: canData?.latitude,
                lng: canData?.longitude,
            },
            imageSrc: vehicleImage,
        });
    }, [selectedDataPointIndex]);

    useEffect(() => {
        if (alarmInfo && runningCanDataList) {
            const searchIndex = runningCanDataList.findIndex((item) => {
                const itemInfo = item as TypeDTO.BasicCanData;
                return itemInfo.dataTime.includes(alarmInfo.alarmTime);
            });

            setSelectedDataPointIndex(searchIndex);
        }
    }, [runningCanDataList]);

    const items = [
        {
            label: "운행 정보",
            key: "운행 정보",
            children: <TabRunningInfo car={car} isFinish={isFinish} runningHistory={runningHistory} />,
        },
        {
            label: "운행 경로",
            key: "운행 경로",
            children: (
                <TabRunningMap
                    isFinish={isFinish}
                    loadingApiRunningGpsData={loadingApiRunningGpsData}
                    runningCanGpsDataList={runningCanGpsDataList}
                    chartMarker={chartMarker}
                />
            ),
        },
        {
            label: "운행 데이터",
            key: "운행 데이터",
            children: (
                <TabRunningData
                    car={car}
                    loading={loading}
                    runningCanDataList={runningCanDataList}
                    selectedDataPointIndex={selectedDataPointIndex}
                    onChangeSelectedDataPointIndex={(index) => setSelectedDataPointIndex(index)}
                />
            ),
        },
    ];

    const onChange = (tab: string) => {
        setActiveKey(tab);
        localStorage.setItem("runningDetailTab", tab);
    };

    return (
        <div className="pageWrapper">
            <Tabs
                className={activeKey === "운행 경로" ? "fullTabContentHolder" : ""}
                defaultActiveKey={localStorage.getItem("runningDetailTab") || "운행 정보"}
                type="card"
                items={items}
                onChange={(tab: string) => onChange(tab)}
            />
        </div>
    );
}

export default PageRunningDetails;
