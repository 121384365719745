import { useState, useEffect } from "react";

import moment from "moment";
import Spin from "antd/es/spin/index";

import * as Common from "../../commons/common";
import * as TypeUtils from "../../commons/typeUtils";
import CanDataChart from "./CanDataChart";

function CanDataChartJoinWrapper({
    loading,
    id,
    series,
    convertTooltip,
    selectedDataPointIndex,
    onChangedSelectDataPointIndex,
    onMountedChart,
}: {
    loading: boolean;
    id: string;
    series: TypeUtils.ChartData[];
    convertTooltip?: (seriesName: string, value: number) => string;
    selectedDataPointIndex?: number;
    onChangedSelectDataPointIndex?: (dataPointIndex: number) => void;
    onMountedChart?: () => void;
}) {
    const [commonChartOptions, setCommonChartOptions] = useState<Record<string, any>>({
        chart: {
            height: Common.CHART_HEIGHT,
            type: "line",
            group: "canData",
            id: `${id}`,
        },
        tooltip: {
            y: {
                // title: {
                //     formatter: (seriesName: any) => tooltipTitle,
                // },
                formatter: function (value: number, { series, seriesIndex, dataPointIndex, w }: any) {
                    if (convertTooltip !== undefined) return convertTooltip(w.config.series[seriesIndex].name, value);
                    return value;
                },
            },
        },
    });

    const handleClickChart = (dataPointIndex: number) => {
        if (onChangedSelectDataPointIndex !== undefined) {
            onChangedSelectDataPointIndex(dataPointIndex);
        }
    };

    useEffect(() => {
        if (selectedDataPointIndex === undefined || selectedDataPointIndex === -1) return;
        if (series.length === 0) return;
        if (series[0].data.length === 0) return;

        const xLabel = series[0].data[selectedDataPointIndex].x;
        const annotationLabel = moment(Number(xLabel)).format(Common.FORMAT_TIME);

        setCommonChartOptions({
            ...commonChartOptions,
            annotations: {
                ...commonChartOptions.annotations,
                xaxis: [
                    {
                        x: xLabel,
                        strokeDashArray: 0,
                        borderColor: "#F63B3B",
                        label: {
                            borderColor: "#F63B3B",
                            style: {
                                color: "#fff",
                                background: "#775DD0",
                            },
                            text: annotationLabel,
                        },
                    },
                ],
            },
        });
    }, [selectedDataPointIndex, series]);

    return (
        <>
            {loading ? (
                <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Spin size="large" />
                </div>
            ) : (
                <CanDataChart series={series} option={commonChartOptions} onClickChart={handleClickChart} onMountedChart={onMountedChart} />
            )}
        </>
    );
}

export default CanDataChartJoinWrapper;
