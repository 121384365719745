import { useState, useEffect } from "react";
import { AlertType, useAlert } from "../../provider/AlertProvider";
import { useLocation, useNavigate } from "react-router";

import Form from "antd/es/form/index";
import Space from "antd/es/space/index";

import { useAuthState } from "../../provider/AuthProvider";
import { useParams } from "react-router";
import CustomForm, { viewType } from "../../components/browser/CustomForm";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as Utils from "../../utils/utils";
import * as RequestAlarm from "../../utils/requestApiAlarm";

import GroupReceivers from "../../components/common/GroupReceivers";
import GroupCars from "../../components/common/GroupCars";

export type StateType = {
    editType: boolean;
    value: string;
};

function PageGroupDetail() {
    const alert = useAlert();
    const userDetails = useAuthState();
    const navigate = useNavigate();
    const params = useParams();
    const [form] = Form.useForm();
    const { pathname } = useLocation();

    const [type, setType] = useState<viewType>("view");
    const [inputs, setInputs] = useState<TypeDTO.AlarmReceivingGroupDto>();
    const [originalData, setOriginalData] = useState<TypeDTO.AlarmReceivingGroupDto>();

    useEffect(() => {
        if (!params) return;

        if (pathname.includes(Common.PAGE_MANAGE_GROUP_DETAIL)) {
            requestApiAlarmReceivingGroupInfo(params.id);
            setType("view");
        } else if (pathname.includes(Common.PAGE_MANAGE_GROUP_REGISTER)) {
            setType("register");
        } else {
            requestApiAlarmReceivingGroupInfo(params.id);
            setType("edit");
        }
    }, [params]);

    const contents: Array<TypeUtils.formType> = [
        {
            name: "groupName",
            label: String.groupName,
            span: 12,
            disabled: type === "view",
        },
        {
            name: "description",
            label: String.description,
            span: 12,
            disabled: type === "view",
        },
    ];

    const { requestApiAlarmReceivingGroupInfo, resultApiAlarmReceivingGroupInfo } = RequestAlarm.useRequestApiAlarmReceivingGroupInfo();

    useEffect(() => {
        if (!resultApiAlarmReceivingGroupInfo) return;

        const group = resultApiAlarmReceivingGroupInfo.alarmReceivingGroup;
        setInputs(group);
        setOriginalData(group);

        form.setFieldsValue(group);
    }, [resultApiAlarmReceivingGroupInfo]);

    const { requestApiAlarmReceivingGroupRegister, resultApiAlarmReceivingGroupRegister } = RequestAlarm.useRequestApiAlarmReceivingGroupRegister();

    useEffect(() => {
        if (!resultApiAlarmReceivingGroupRegister) return;

        onResult("register");
    }, [resultApiAlarmReceivingGroupRegister]);

    const { requestApiAlarmReceivingGroupUpdate, resultApiAlarmReceivingGroupUpdate } = RequestAlarm.useRequestApiAlarmReceivingGroupUpdate();

    useEffect(() => {
        if (!resultApiAlarmReceivingGroupUpdate) return;

        onResult("edit");
    }, [resultApiAlarmReceivingGroupUpdate]);

    const onReceiverSelected = (selectedUsers?: Array<TypeDTO.AlarmReceivingGroupUserDto>) => {
        setInputs((prevState: any) => ({
            ...prevState,
            alarmReceivingGroupUsers: selectedUsers?.filter((receiver) => receiver.receivingEmail || receiver.receivingSms),
        }));
    };

    const onCarSelected = (selectedCars?: Array<TypeDTO.CarDto>) => {
        setInputs((prevState: any) => ({
            ...prevState,
            cars: selectedCars,
        }));
    };

    const onResult = (type: "register" | "edit" | "delete") => {
        const typeText = Utils.getTypeText(type);

        alert.setAlert(AlertType.SUCCESS, `${String.group} ${typeText} 성공`, `${String.group} 정보를 ${typeText}하였습니다.`);
        navigate(Common.PAGE_MANAGE_GROUP);
    };

    const onFinish = (type: viewType, value: TypeDTO.AlarmReceivingGroupDto) => {
        const searchValue = { ...inputs, groupName: value.groupName, description: value.description };
        type === "register" ? requestApiAlarmReceivingGroupRegister(searchValue) : requestApiAlarmReceivingGroupUpdate(params.id, searchValue);
    };

    return (
        <Space direction="vertical" size={24} style={{ width: "100%" }}>
            <div className="pageWrapper">
                <Space direction="vertical" size={8} style={{ display: "flex" }}>
                    <CustomForm
                        form={form}
                        type={type}
                        contents={[{ name: String.alarmGroup, forms: contents }]}
                        initialValues={originalData}
                        onChangeType={(type) => {
                            setType(type);
                            type === "view" && setInputs(originalData);
                        }}
                        onFinish={onFinish}
                        disabledBtn={!Utils.roleCheck(userDetails?.user?.authority, [Utils.getAuthority.ROLE_ADMIN])}
                    />
                </Space>
            </div>
            <div style={{ width: "100%", display: "flex", gap: 24 }}>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <GroupReceivers
                        key="receiverForm"
                        inputType={type !== "view"}
                        groupReceivers={originalData?.alarmReceivingGroupUsers}
                        onSelected={onReceiverSelected}
                    />
                </div>
                <div className="pageWrapper" style={{ maxWidth: "calc(50% - 12px)" }}>
                    <GroupCars
                        key="carForm"
                        inputType={type !== "view"}
                        groupCars={type === "view" ? inputs?.cars : undefined}
                        onSelected={onCarSelected}
                        selectedRowKeys={inputs?.cars?.map((item: TypeDTO.CarDto) => item.carNumber) ?? []}
                    />
                </div>
            </div>
        </Space>
    );
}

export default PageGroupDetail;
