const getAuthUrl = () => {
    const hostname = window.location.hostname;

    if (hostname.includes(process.env.REACT_APP_DEV_URI)) {
        return process.env.REACT_APP_DEV_AUTH_URI;
    } else if (hostname.includes(process.env.REACT_APP_PROD_URI)) {
        return process.env.REACT_APP_PROD_AUTH_URI;
    } else return process.env.REACT_APP_DEV_AUTH_URI;
};

const getApiUrl = () => {
    const hostname = window.location.hostname;

    if (hostname.includes(process.env.REACT_APP_DEV_URI)) {
        return process.env.REACT_APP_DEV_API_URI;
    } else if (hostname.includes(process.env.REACT_APP_PROD_URI)) {
        return process.env.REACT_APP_PROD_API_URI;
    } else return process.env.REACT_APP_DEV_API_URI;
};

const DEFAULT_AUTH_URL = getAuthUrl() + process.env.REACT_APP_API_PREFIX;
const DEFAULT_API_URL = getApiUrl() + process.env.REACT_APP_API_PREFIX;

// Auth - 01. 인증
export const LOGIN_URL = DEFAULT_AUTH_URL + "login";

// Auth - 02. 회원관리
export const MEMBER_URL = DEFAULT_AUTH_URL + "member/";

// Auth - 03. 사용자관리
export const USER_ADD_URL = DEFAULT_AUTH_URL + "user";
export const USERS_URL = DEFAULT_AUTH_URL + "users";
export const USER_URL = DEFAULT_AUTH_URL + "user/";

export const CUSTOMER_MANAGER_ADD_URL = DEFAULT_AUTH_URL + "customerManager";
export const CUSTOMER_MANAGERS_URL = DEFAULT_AUTH_URL + "customerManagers";
export const CUSTOMER_MANAGER_URL = DEFAULT_AUTH_URL + "customerManager/";

export const CUSTOMER_ADD_URL = DEFAULT_AUTH_URL + "customer";
export const CUSTOMERS_URL = DEFAULT_AUTH_URL + "customers";
export const CUSTOMER_URL = DEFAULT_AUTH_URL + "customer/";

// Auth - 04. 어플리케이션관리
export const APPLICATION_ADD_URL = DEFAULT_AUTH_URL + "application";
export const APPLICATIONS_URL = DEFAULT_AUTH_URL + "applications";
export const APPLICATION_URL = DEFAULT_AUTH_URL + "application/";

// 01. 차량 관리
export const CAR_ADD_URL = DEFAULT_API_URL + "car";
export const CARS_URL = DEFAULT_API_URL + "cars";
export const CAR_URL = DEFAULT_API_URL + "car/";

// 02. 차량 분류 관리
export const CATEGORY_ADD_URL = DEFAULT_API_URL + "category";
export const CATEGORIES_URL = DEFAULT_API_URL + "categories";
export const CATEGORY_URL = DEFAULT_API_URL + "category/";

// 03. 제조사 관리
export const MAKER_ADD_URL = DEFAULT_API_URL + "maker";
export const MAKERS_URL = DEFAULT_API_URL + "makers";
export const MAKER_URL = DEFAULT_API_URL + "maker/";

// 04. 제조사 모델 관리
export const MAKER_MODEL_ADD_URL = MAKER_URL + "model";
export const MAKER_MODEL_URL = MAKER_MODEL_ADD_URL + "/";
export const MAKER_MODELS_URL = MAKER_URL + "models";

// 05. 차량 단말기 관리
export const TERMINAL_ADD_URL = DEFAULT_API_URL + "terminal";
export const TERMINALS_URL = DEFAULT_API_URL + "terminals";
export const TERMINAL_URL = DEFAULT_API_URL + "terminal/";

// 06. SW 버전 관리
export const SWVER_ADD_URL = DEFAULT_API_URL + "swVer";
export const SWVERS_URL = DEFAULT_API_URL + "swVers";
export const SWVER_URL = DEFAULT_API_URL + "swVer/";

// 07. 단말기 모뎀 관리
export const MODEM_ADD_URL = TERMINAL_URL + "modem";
export const MODEMS_URL = TERMINAL_URL + "modems";
export const MODEM_URL = TERMINAL_URL + "modem/";

// 08. 도어 모듈 관리
export const DOOR_MODULE_ADD_URL = DEFAULT_API_URL + "doorModule";
export const DOOR_MODULES_URL = DEFAULT_API_URL + "doorModules";
export const DOOR_MODULE_URL = DEFAULT_API_URL + "doorModule/";

// 09. 도어 모듈 작동
export const DOOR_MODULE_OPERATION_URL = DEFAULT_API_URL + "doorModules/operation/";
export const DOOR_MODULE_CLOSE_DOOR_URL = DOOR_MODULE_OPERATION_URL + "close/door/";
export const DOOR_MODULE_CLOSE_SWINGDOOR_URL = DOOR_MODULE_OPERATION_URL + "close/swing-door/";
export const DOOR_MODULE_OPEN_DOOR_URL = DOOR_MODULE_OPERATION_URL + "open/door/";
export const DOOR_MODULE_OPEN_SWINGDOOR_URL = DOOR_MODULE_OPERATION_URL + "open/swing-door/";
export const DOOR_MODULE_STATUS_URL = DOOR_MODULE_OPERATION_URL + "status/";

// 10. 도어 상태 이력 관리
export const DOOR_STATUS_HISTORIES_URL = DEFAULT_API_URL + "doorStatus/histories";

// 11. 보조 배터리 이력 관리
export const SUB_BATTERY_HISTORIES_URL = DEFAULT_API_URL + "subBattery/histories";

// 12. 이슈 관리
export const ISSUE_ADD_URL = DEFAULT_API_URL + "issue";
export const ISSUES_URL = DEFAULT_API_URL + "issues";
export const ISSUE_URL = DEFAULT_API_URL + "issue/";
export const ISSUE_FILE_DOWNLOAD_URL = ISSUE_URL + "file/";
export const ISSUE_BY_CAR_URL = ISSUES_URL + "/byCar";

// 13. 알람 수신 그룹 관리
export const ALARM_URL = DEFAULT_API_URL + "alarm/";
export const ALARM_RECEIVING_GROUP_ADD_URL = ALARM_URL + "receivingGroup";
export const ALARM_RECEIVING_GROUPS_URL = ALARM_URL + "receivingGroups";
export const ALARM_RECEIVING_GROUP_URL = ALARM_URL + "receivingGroup/";

// 14. 알람 관리
export const ALARMS_URL = DEFAULT_API_URL + "alarms";
export const ALARM_COUNT_URL = DEFAULT_API_URL + "alarm/count/today";
export const ALARMS_DOWNLOAD_URL = DEFAULT_API_URL + "alarms/file";

// 15. 알람 이력 관리
export const ALARM_HISTORIES_URL = ALARM_URL + "histories";

// 16. 운행 이력 관리
export const RUNNING_HISTORIES_URL = DEFAULT_API_URL + "running/histories";
export const RUNNING_HISTORIES_LAST_URL = DEFAULT_API_URL + "running/histories/last";
export const RUNNING_HISTORY_URL = DEFAULT_API_URL + "running/history";
export const RUNNING_HISTORY_STATS_URL = DEFAULT_API_URL + "running/history/stats";

// 17. 운행 데이터 관리
export const RUNNING_DATA_URL = DEFAULT_API_URL + "running/data";
export const RUNNING_DATA_DOWNLOAD_URL = DEFAULT_API_URL + "running/data/download";
export const RUNNING_GPS_DATA_URL = DEFAULT_API_URL + "running/data/gps";

// 18. 통계 정보
export const STATS_URL = DEFAULT_API_URL + "stats/";
export const STATS_ALARM_URL = STATS_URL + "alarm";
export const STATS_CAR_BY_CATEGORY_URL = STATS_URL + "car/byCategory";
export const STATS_CAR_BY_MAKER_URL = STATS_URL + "car/byMaker";
export const STATS_CELL_URL = STATS_URL + "cell/temp/";
export const STATS_DATA_URL = STATS_URL + "data";
export const STATS_DATA_ACCUMULATED_URL = STATS_DATA_URL + "/accumulated";
export const STATS_RUNNING_URL = STATS_URL + "running";
export const STATS_RUNNING_BY_CAR_URL = STATS_RUNNING_URL + "/";
export const STATS_RUNNING_ACCUMULATED_URL = STATS_RUNNING_BY_CAR_URL + "accumulated";

// 19. 배터리 soc 점검
export const BATTERIES_SOC_URL = DEFAULT_API_URL + "batteries/soc";

// 20. 날씨 관리
export const WEATHER_URL = DEFAULT_API_URL + "weather";

// 21. 정기점검 관리
export const MAINTENANCES_URL = DEFAULT_API_URL + "maintenances";
export const MAINTENANCE_URL = DEFAULT_API_URL + "maintenance/";

// 21. 정기점검 지침 관리
export const MAINTENANCE_GUIDES_URL = DEFAULT_API_URL + "maintenance/guides";
export const MAINTENANCE_GUIDE_ADD_URL = DEFAULT_API_URL + "maintenance/guide";
export const MAINTENANCE_GUIDE_URL = DEFAULT_API_URL + "maintenance/guide/";

// 22. 정기점검 지침 관리
export const MAINTENANCE_DETAILS_URL = DEFAULT_API_URL + "maintenance/details";
