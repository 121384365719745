import { useEffect, useState } from "react";
import Col from "antd/es/grid/col";
import Row from "antd/es/grid/row";
import Radio from "antd/es/radio/index";
import Card from "antd/es/card/index";
import Spin from "antd/es/spin/index";

import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as RequsetRunning from "../../utils/requestApiRunning";
import * as Common from "../../commons/common";

import moment from "moment";
import ReactApexChart from "react-apexcharts";
import styles from "./Stats.module.css";
import CustomDatePicker from "../common/CustomDatePicker";
import produce from "immer";

export type dataPeriodType = {
    startDate?: string;
    endDate?: string;
    period?: "YEAR" | "MONTH" | "DAY";
};

type runningHistoryStatsItemType = {
    year: number;
    month: number;
    day?: number;
    count: number;
};

type statsType = {
    baseCount?: number;
    period?: "YEAR" | "MONTH" | "DAY";
    runningHistoryStatsItems: Array<runningHistoryStatsItemType>;
};

const StatsRunningHistoryByPeriod = ({ drivingCount, disabledSearch }: { drivingCount?: number; disabledSearch: boolean }) => {
    const [requestStats, setRequestStats] = useState<dataPeriodType>({
        startDate: moment().add(-6, "days").format(Common.FORMAT_DATE),
        endDate: moment().add(1, "days").format(Common.FORMAT_DATE),
        period: "DAY",
    });
    const [stats, setStats] = useState<statsType>();
    const [option, setOption] = useState<Record<string, any> | undefined>();
    const [series, setSeries] = useState<TypeUtils.ChartData[]>([]);

    useEffect(() => {
        requestApiRunningHistoryStatsData(requestStats);
    }, []);

    const errorChartOptions = {
        colors: ["rgba(24, 116, 255, 0.30)", "var(--point)"],
        chart: {
            height: "100%",
            stacked: true,
            width: "1000px",
            toolbar: {
                show: false,
            },
        },
        title: {
            text: "일별 운행 차량 추이",
            style: {
                fontFamily: "Pretendard-Bold",
                fontSize: "16px",
            },
        },
        xaxis: {
            type: "category",
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    colors: "var(--font2)",
                },
                labels: {
                    formatter: function (val: number) {
                        return val;
                    },
                    style: { colors: "var(--font2)" },
                },
            },
        ],
        tooltip: {
            y: {
                formatter: function (val: number, { series, seriesIndex, dataPointIndex, w }: any) {
                    if (seriesIndex === 0) {
                        const drivingCount = series[1][dataPointIndex];
                        return (val + drivingCount)?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "대";
                    }
                    return val?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "대";
                },
            },
        },
        dataLabels: {
            formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex];
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "20%",
            },
        },
        stroke: {
            width: 1,
        },
        legend: {
            position: "bottom",
            horizontalAlign: "center",
            fontFamily: "Pretendard-Regular",
            fontWeight: "normal",
            onItemClick: {
                toggleDataSeries: false,
            },
            onItemHover: {
                highlightDataSeries: false,
            },
            showForSingleSeries: false,
            fontSize: "14px",
        },
    };

    useEffect(() => {
        const counts: TypeUtils.ChartElement[] = [];
        const drivingCounts: TypeUtils.ChartElement[] = [];

        let timeFormat = Common.FORMAT_DATE;
        switch (requestStats.period) {
            case "YEAR":
                timeFormat = Common.FORMAT_YEAR;
                break;
            case "MONTH":
                timeFormat = Common.FORMAT_MONTH;
                break;
            case "DAY":
                timeFormat = Common.FORMAT_DATE;
                break;
            default:
                break;
        }

        stats?.runningHistoryStatsItems?.forEach((value: runningHistoryStatsItemType, index: number) => {
            const formattedDate = moment(`${value.year}-${value.month}-${value.day}`, Common.FORMAT_DATE).format(Common.FORMAT_DATE);
            const time = moment(formattedDate, Common.FORMAT_DATE_TIME_MILLISECOND).format(timeFormat);
            const count = formattedDate === moment().format(Common.FORMAT_DATE) ? drivingCount || 0 : 0;

            drivingCounts.push({
                x: time,
                y: count,
            });

            counts.push({
                x: time,
                y: value.count - count,
            });
        });

        setSeries([
            {
                name: "누적 차량",
                type: "bar",
                data: counts || 0,
            },
            {
                name: "운행 차량",
                type: "bar",
                data: drivingCounts || 0,
            },
        ]);

        !option && setOption(errorChartOptions);
    }, [stats, drivingCount]);

    const { loadingApiRunningHistoryStatsData, requestApiRunningHistoryStatsData, resultApiRunningHistoryStatsData } =
        RequsetRunning.useRequestApiRunningHistoryStatsData();

    useEffect(() => {
        if (!resultApiRunningHistoryStatsData) return;

        setStats(resultApiRunningHistoryStatsData.runningHistoryStats);
    }, [resultApiRunningHistoryStatsData]);

    const onChangeEvent = (type: string, value: any) => {
        setRequestStats(
            produce((draft) => {
                draft[type as keyof dataPeriodType] = value;
            })
        );
    };

    return (
        <>
            {!disabledSearch && (
                <Row className="searchLastRow" style={{ marginBottom: "10px" }}>
                    <Col xs={{ span: 6 }} sm={{ span: 3 }} xl={{ span: 2 }} className="searchHead">
                        {String.searchDate}
                    </Col>
                    <Col xs={{ span: 18 }} sm={{ span: 21 }} xl={{ span: 10 }} className="searchBody" style={{ display: "inline-flex" }}>
                        <CustomDatePicker
                            value={requestStats.startDate}
                            hasTime
                            dateFormat={Common.FORMAT_DATE_TIME}
                            onChange={(value) => onChangeEvent("startDate", value)}
                        />
                        <span style={{ margin: "0 7px" }}>~</span>
                        <CustomDatePicker
                            value={requestStats.endDate}
                            hasTime
                            dateFormat={Common.FORMAT_DATE_TIME}
                            onChange={(value) => onChangeEvent("endDate", value)}
                        />
                    </Col>
                    <Col xs={{ span: 6 }} sm={{ span: 3 }} xl={{ span: 2 }} className="searchHead">
                        {String.unit}
                    </Col>
                    <Col xs={{ span: 18 }} sm={{ span: 21 }} xl={{ span: 10 }} className="searchBody" style={{ display: "inline-flex" }}>
                        <Radio.Group onChange={(e) => onChangeEvent("period", e.target.value)} defaultValue={requestStats.period}>
                            <Radio.Button value="YEAR">{String.periodYear}</Radio.Button>
                            <Radio.Button value="MONTH">{String.periodMonth}</Radio.Button>
                            <Radio.Button value="DAY">{String.periodDay}</Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>
            )}
            {option && (
                <Card className={styles.card}>
                    {loadingApiRunningHistoryStatsData ? (
                        <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Spin />
                        </div>
                    ) : (
                        <ReactApexChart
                            style={{ backgroundColor: "white" }}
                            options={option}
                            series={series}
                            width="100%"
                            height={option.chart.height}
                        />
                    )}
                </Card>
            )}
        </>
    );
};

export default StatsRunningHistoryByPeriod;
