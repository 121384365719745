import { useEffect, useState } from "react";
import Spin from "antd/es/spin/index";
import Card from "antd/es/card/index";

import * as RequestStats from "../../utils/requestApiStats";
import * as String from "../../commons/string";

import ReactApexChart from "react-apexcharts";
import styles from "./Stats.module.css";

type carStatsType = {
    maker: {
        makerId: number;
        makerName: string;
    };
    count: number;
};

function StatsCarByMaker() {
    const [stats, setStats] = useState<Array<carStatsType>>([]);
    const [series, setSeries] = useState<any>([]);
    const [labels, setLabels] = useState<string[]>([]);

    const options = {
        labels: labels,
        colors: ["#0A84FF", "#5E5CE6", "#BF5AF2", "#FF2D55", "#FF453A", "#FF9F0A", "#FFD60A", "#32D74B", "#64D2FF"],
        chart: {
            stacked: true,
            width: "1000px",
            toolbar: {
                show: false,
            },
        },
        dataLabels: {
            formatter: function (val: any, opts: any) {
                return opts.w.config.series[opts.seriesIndex];
            },
            dropShadow: {
                enabled: false,
            },
            style: {
                fontFamily: "Pretendard-Bold",
                colors: ["#fff"],
            },
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    size: "50%",
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            showAlways: true,
                            fontSize: "14px",
                            fontFamily: "Pretendard-Regular",
                            color: "#000000",
                        },
                    },
                },
            },
        },
        legend: {
            formatter: function (val: string, opts: Record<any, any>) {
                return val + " " + opts.w.globals.series[opts.seriesIndex];
            },
            fontFamily: "Pretendard-Regular",
            fontWeight: "normal",
            fontSize: "14px",
        },
        tooltip: {
            style: {
                fontFamily: "Pretendard-Regular",
            },
        },
    };

    useEffect(() => {
        requestApiStatsCarByMaker();
    }, []);

    useEffect(() => {
        if (!stats) return;

        const datas = stats.map((value) => Number(value.count));
        const labels = stats.map((value) => value?.maker?.makerName ?? String.etc);

        setSeries(datas);
        setLabels(labels);
    }, [stats]);

    const { loadingApiStatsCarByMaker, requestApiStatsCarByMaker, resultApiStatsCarByMaker } = RequestStats.useRequestApiStatsCarByMaker();

    useEffect(() => {
        if (!resultApiStatsCarByMaker) return;

        setStats(resultApiStatsCarByMaker.carStats);
    }, [resultApiStatsCarByMaker]);

    return (
        <Card className={styles.card}>
            <p className="fw-bd" style={{ fontSize: "16px" }}>
                {String.statsCarByMaker}
            </p>
            {loadingApiStatsCarByMaker ? (
                <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin />
                </div>
            ) : (
                <ReactApexChart options={options} series={series} type="donut" height={250} />
            )}
        </Card>
    );
}

export default StatsCarByMaker;
