import "./App.css";
import { useEffect } from "react";
import { ConfigProvider } from "antd";
import { isMobile } from "react-device-detect";

import locale from "antd/locale/ko_KR";
import MobileRouter from "./utils/MobileRouter";
import BrowserRouter from "./utils/BrowserRouters";

import "dayjs/locale/ko";

function App() {
    function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
    }
    useEffect(() => {
        setScreenSize();
    });

    return (
        <ConfigProvider
            locale={locale}
            theme={{
                token: {
                    fontFamily: "Pretendard-Regular",
                },
            }}
        >
            {isMobile ? <MobileRouter /> : <BrowserRouter />}
        </ConfigProvider>
    );
}

export default App;
