import * as Request from "../commons/request";
import * as TypeDTO from "../commons/typeDTO";

import { useEffect } from "react";

import { useAsyncAxios, utilAxiosWithAuth } from "./customAxios";

const DEBUG = true;

export const useRequestApiCategoryRegister = () => {
    const requestAxiosApiCategoryRegister = async (data: TypeDTO.CategoryDto) => {
        const response = await utilAxiosWithAuth().post(Request.CATEGORY_ADD_URL, data);

        return response.data;
    };

    const {
        loading: loadingApiCategoryRegister,
        error: errorApiCategoryRegister,
        data: resultApiCategoryRegister,
        execute: requestApiCategoryRegister,
    } = useAsyncAxios(requestAxiosApiCategoryRegister);

    useEffect(() => {
        if (resultApiCategoryRegister === null) return;
        DEBUG && console.log("resultApiCategoryRegister", resultApiCategoryRegister);
    }, [resultApiCategoryRegister]);

    useEffect(() => {
        if (errorApiCategoryRegister === null) return;
        DEBUG && console.log("errorApiCategoryRegister", errorApiCategoryRegister);
    }, [errorApiCategoryRegister]);

    return {
        loadingApiCategoryRegister,
        errorApiCategoryRegister,
        resultApiCategoryRegister,
        requestApiCategoryRegister,
    };
};

export const useRequestApiCategoryList = () => {
    const requestAxiosApiCategoryList = async () => {
        const response = await utilAxiosWithAuth().get(Request.CATEGORIES_URL);

        return response.data;
    };

    const {
        loading: loadingApiCategoryList,
        error: errorApiCategoryList,
        data: resultApiCategoryList,
        execute: requestApiCategoryList,
    } = useAsyncAxios(requestAxiosApiCategoryList);

    useEffect(() => {
        if (resultApiCategoryList === null) return;
        DEBUG && console.log("resultApiCategoryList", resultApiCategoryList);
    }, [resultApiCategoryList]);

    useEffect(() => {
        if (errorApiCategoryList === null) return;
        DEBUG && console.log("errorApiCategoryList", errorApiCategoryList);
    }, [errorApiCategoryList]);

    return {
        loadingApiCategoryList,
        errorApiCategoryList,
        resultApiCategoryList,
        requestApiCategoryList,
    };
};

export const useRequestApiCategoryInfo = () => {
    const requestAxiosApiCategoryInfo = async (categoryId: number) => {
        const response = await utilAxiosWithAuth().get(Request.CATEGORY_URL + categoryId);

        return response.data;
    };

    const {
        loading: loadingApiCategoryInfo,
        error: errorApiCategoryInfo,
        data: resultApiCategoryInfo,
        execute: requestApiCategoryInfo,
    } = useAsyncAxios(requestAxiosApiCategoryInfo);

    useEffect(() => {
        if (resultApiCategoryInfo === null) return;
        DEBUG && console.log("resultApiCategoryInfo", resultApiCategoryInfo);
    }, [resultApiCategoryInfo]);

    useEffect(() => {
        if (errorApiCategoryInfo === null) return;
        DEBUG && console.log("errorApiCategoryInfo", errorApiCategoryInfo);
    }, [errorApiCategoryInfo]);

    return {
        loadingApiCategoryInfo,
        errorApiCategoryInfo,
        resultApiCategoryInfo,
        requestApiCategoryInfo,
    };
};

export const useRequestApiCategoryUpdate = () => {
    const requestAxiosApiCategoryUpdate = async (categoryId: number, data: TypeDTO.CategoryDto) => {
        const response = await utilAxiosWithAuth().put(Request.CATEGORY_URL + categoryId, data);

        return response.data;
    };

    const {
        loading: loadingApiCategoryUpdate,
        error: errorApiCategoryUpdate,
        data: resultApiCategoryUpdate,
        execute: requestApiCategoryUpdate,
    } = useAsyncAxios(requestAxiosApiCategoryUpdate);

    useEffect(() => {
        if (resultApiCategoryUpdate === null) return;
        DEBUG && console.log("resultApiCategoryUpdate", resultApiCategoryUpdate);
    }, [resultApiCategoryUpdate]);

    useEffect(() => {
        if (errorApiCategoryUpdate === null) return;
        DEBUG && console.log("errorApiCategoryUpdate", errorApiCategoryUpdate);
    }, [errorApiCategoryUpdate]);

    return {
        loadingApiCategoryUpdate,
        errorApiCategoryUpdate,
        resultApiCategoryUpdate,
        requestApiCategoryUpdate,
    };
};

export const useRequestApiCategoryDelete = () => {
    const requestAxiosApiCategoryDelete = async (categoryId: number) => {
        const response = await utilAxiosWithAuth().delete(Request.CATEGORY_URL + categoryId);

        return response.data;
    };

    const {
        loading: loadingApiCategoryDelete,
        error: errorApiCategoryDelete,
        data: resultApiCategoryDelete,
        execute: requestApiCategoryDelete,
    } = useAsyncAxios(requestAxiosApiCategoryDelete);

    useEffect(() => {
        if (resultApiCategoryDelete === null) return;
        DEBUG && console.log("resultApiCategoryDelete", resultApiCategoryDelete);
    }, [resultApiCategoryDelete]);

    useEffect(() => {
        if (errorApiCategoryDelete === null) return;
        DEBUG && console.log("errorApiCategoryDelete", errorApiCategoryDelete);
    }, [errorApiCategoryDelete]);

    return {
        loadingApiCategoryDelete,
        errorApiCategoryDelete,
        resultApiCategoryDelete,
        requestApiCategoryDelete,
    };
};
