import { useEffect, useState } from "react";
import { Header } from "antd/es/layout/layout";
import { FloatingPanel } from "antd-mobile";
import { Input, List } from "antd";
import { CheckCircleFilled, LeftOutlined, SearchOutlined, CloseCircleFilled } from "@ant-design/icons";

import * as RequestCar from "../../utils/requestApiCar";
import * as TypeDTO from "../../commons/typeDTO";
import * as Common from "../../commons/common";
import * as Utils from "../../utils/utils";

import styles from "./Selector.module.css";

function CarSelector({
    isWhite,
    selectedCarId,
    selectedCarNumber,
    onOpen,
    onSelect,
    onSelectCarNumber,
}: {
    isWhite?: boolean;
    selectedCarId?: number;
    selectedCarNumber?: string;
    onOpen?: (isOpen: boolean) => void;
    onSelect: (id?: number) => void;
    onSelectCarNumber?: (carNumber?: string) => void;
}) {
    const [open, setOpen] = useState<boolean>(false);
    const [cars, setCars] = useState<Array<TypeDTO.CarDto>>();
    const [filteredCars, setFilteredCars] = useState<Array<TypeDTO.CarDto>>();
    const [selectedCar, setSelectedCar] = useState<TypeDTO.CarDto>();
    const [searchValue, setSearchValue] = useState<string>();
    const [otherCars, setOtherCars] = useState<Array<TypeDTO.CarDto>>();
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        requestApiCarList(true, false, true);
        Utils.onEvent(Common.EVENT_CLOSE_ALL_VIEW, handleCloseAllView);

        return () => {
            Utils.offEvent(Common.EVENT_CLOSE_ALL_VIEW, handleCloseAllView);
        };
    }, []);

    useEffect(() => {
        if (open) {
            onOpen && onOpen(true);
            if (selectedCar) {
                const array = cars?.filter((car) => {
                    return car.carNumber.includes(selectedCar.carNumber);
                });
                setFilteredCars(array);

                if (cars) {
                    const selectedIndex: Array<number> = [];
                    cars.find((car, index) => {
                        if (car.carId === selectedCar.carId) {
                            selectedIndex.push(index);
                        }
                    });

                    setOtherCars(cars.filter((car, index) => index !== selectedIndex[0]));
                }
            } else {
                setOtherCars(undefined);
            }
            Utils.addViewStack();
        } else {
            onOpen && onOpen(false);
            Utils.popViewStack();
            setSearchValue(undefined);
            setFilteredCars(undefined);
        }
    }, [open]);

    useEffect(() => {
        if (selectedCarId === undefined && selectedCarNumber === undefined) {
            setSelectedCar(undefined);
        }
        cars?.find((car: TypeDTO.CarDto) => {
            if (selectedCarId === car.carId) {
                setSelectedCar(car);
            } else if (selectedCarNumber === car.carNumber) {
                setSelectedCar(car);
            }
        });
    }, [selectedCarId, selectedCarNumber, cars]);

    const { requestApiCarList, resultApiCarList } = RequestCar.useRequestApiCarList();

    useEffect(() => {
        if (!resultApiCarList) return;

        setCars(resultApiCarList.cars);
    }, [resultApiCarList]);

    useEffect(() => {
        setOpen(false);
        if (selectedCar === undefined) {
            onSelect(undefined);
            if (isInitialized && onSelectCarNumber) {
                onSelectCarNumber(undefined);
            }
        } else {
            onSelect(selectedCar.carId);
            if (isInitialized && onSelectCarNumber) {
                onSelectCarNumber(selectedCar.carNumber);
            }
        }

        if (!isInitialized) {
            setIsInitialized(true);
        }
    }, [selectedCar]);

    const handleCloseAllView = () => {
        setOpen(false);
    };

    return (
        <>
            <div
                className={styles.selectorTrigger}
                style={!isWhite ? { background: "var(--background)", boxShadow: "none" } : {}}
                onClick={() => setOpen(true)}
            >
                <span className={`fw-rg fs-md ${selectedCar ? "fc-font1" : "fc-font3"}`}>
                    {selectedCar ? selectedCar.carNumber : "차량 번호를 입력해 주세요."}
                </span>
                {selectedCar ? (
                    <CloseCircleFilled
                        onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCar(undefined);
                        }}
                    />
                ) : (
                    <SearchOutlined />
                )}
            </div>
            {open && (
                <FloatingPanel anchors={[window.innerHeight]} className="fullPanel" handleDraggingOfContent={false}>
                    <div className={styles.selectorOverlay}>
                        <Header className={styles.overlayHeader}>
                            <LeftOutlined
                                className={styles.menuIcon}
                                onClick={() => {
                                    setOpen(false);
                                    setFilteredCars(undefined);
                                }}
                            />
                            <h4 className="fc-font1">차량 번호 검색</h4>
                            <LeftOutlined style={{ opacity: 0 }} />
                        </Header>
                        <div className={styles.overlayContent}>
                            <Input
                                className={styles.selector}
                                allowClear
                                placeholder="차량 번호를 입력해 주세요."
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    if (e.target.value || selectedCar) {
                                        const array = cars?.filter((car) => {
                                            if (e.target.value) {
                                                return car.carNumber.includes(e.target.value);
                                            } else if (selectedCar) {
                                                return car.carNumber.includes(selectedCar.carNumber);
                                            }
                                        });
                                        setFilteredCars(array);
                                    } else {
                                        setFilteredCars(undefined);
                                    }
                                }}
                                suffix={<SearchOutlined />}
                            />
                            <List className={styles.carList} size="large" dataSource={filteredCars ? filteredCars : cars}>
                                {(filteredCars ? filteredCars : cars)
                                    ?.slice()
                                    .sort((a, b) => a?.carNumber?.localeCompare(b?.carNumber))
                                    ?.map((car) => (
                                        <List.Item
                                            className="fw-rg fs-md"
                                            key={car.carId}
                                            onClick={() => {
                                                if (selectedCar?.carId === car.carId) {
                                                    setOpen(false);
                                                    onSelect(selectedCar.carId);
                                                } else {
                                                    setSelectedCar(car);
                                                }
                                            }}
                                        >
                                            {car.carNumber}
                                            {car.carId === selectedCar?.carId && <CheckCircleFilled />}
                                        </List.Item>
                                    ))}
                                {!searchValue &&
                                    otherCars &&
                                    otherCars
                                        ?.slice()
                                        .sort((a, b) => a?.carNumber?.localeCompare(b?.carNumber))
                                        ?.map((car) => (
                                            <List.Item
                                                className="fw-rg fs-md"
                                                key={car.carId}
                                                onClick={() => {
                                                    if (selectedCar?.carId === car.carId) {
                                                        setOpen(false);
                                                        onSelect(selectedCar.carId);
                                                    } else {
                                                        setSelectedCar(car);
                                                    }
                                                }}
                                            >
                                                {car.carNumber}
                                                {car.carId === selectedCar?.carId && <CheckCircleFilled />}
                                            </List.Item>
                                        ))}
                            </List>
                        </div>
                    </div>
                </FloatingPanel>
            )}
        </>
    );
}

export default CarSelector;
