import { useEffect } from "react";
import notification from "antd/es/notification";
import { AlertType, useAlert } from "../../provider/AlertProvider";

type NotificationType = "success" | "info" | "warning" | "error";

const MessageType: Record<string, string> = {
    "Alarm already assigned": "이미 등록된 알람입니다.",
    "Invalid alarm id": "등록되지 않은 알람 ID입니다.",
    "Invalid alarm receiving group id": "등록되지 않은 수신그룹 ID입니다.",
    "Application name already exists": "이미 등록된 서비스명입니다.",
    "Invalid application name": "등록되지 않은 서비스명입니다.",
    "Area name already exists": "이미 등록된 지역명입니다.",
    "Invalid area id": "등록되지 않은 지역 ID입니다.",
    "Can't delete car because running history exists": "등록된 운행이력이 있어, 차량을 삭제할 수 없습니다.",
    "Can't delete car because car number history exists": "등록된 차량 번호 변경 이력이 있어, 차량을 삭제할 수 없습니다.",
    "Can't delete car because issue exists": "등록된 차량 정비 내역이 있어, 차량을 삭제할 수 없습니다.",
    "Car number already exists": "이미 등록된 차량 번호입니다.",
    "Car id number already exists": "이미 등록된 차대번호입니다.",
    "Car id is already registered": "이미 등록된 차량 ID입니다.",
    "Invalid car id": "등록되지 않은 차량 ID입니다.",
    "Invalid car number": "등록되지 않은 차량번호입니다.",
    "Invalid terminal": "등록되지 않은 단말기입니다.",
    "Already exist history": "이미 등록된 이력입니다.",
    "Invalid car number history id": "등록되지 않은 차량 번호 이력 ID입니다.",
    "Category name already exists": "이미 등록된 차량분류명입니다.",
    "Invalid category id": "등록되지 않은 차량분류 ID입니다.",
    "Customer already exists": "이미 등록된 고객입니다.",
    "Customer manager already exists": "이미 등록된 고객관리자입니다.",
    "Invalid customer manager id": "등록되지 않은 고객관리자 ID입니다.",
    "Invalid customer manager name": "등록되지 않은 고객관리자 이름입니다.",
    "Customer manager already referenced in customer": "고객이 등록되어 있어 삭제할 수 없습니다.",
    "Invalid customer id": "등록되지 않은 고객 ID입니다.",
    "Invalid data bukgi id": "등록되지 않은 북기 ID입니다.",
    "Can't delete data because of other referenced data.": "다른 데이터가 참조되어 있어 삭제할 수 없습니다.",
    "File name is empty": "파일명이 없습니다.",
    "File is not supported": "지원되지 않는 파일입니다.",
    "File size is zero": "파일 크기가 0입니다.",
    "Invalid data yaxing id": "등록되지 않은 야싱 ID입니다.",
    "Serial number already exists": "이미 등록된 S/N입니다.",
    "Imei already exists": "이미 등록된 IMEI입니다.",
    "Door module id is already registered": "이미 등록된 도어모듈 ID입니다.",
    "Door module serial number already registered": "이미 등록된 도어모듈 S/N입니다.",
    "Car id already exists": "이미 등록된 차량입니다.",
    "Invalid door module id": "등록되지 않은 도어모듈 ID입니다.",
    "Invalid door module imei": "등록되지 않은 도어모듈 IMEI입니다.",
    "Invalid door status history id": "등록되지 않은 도어모듈 이력 ID입니다.",
    "Access denied": "권한이 없습니다.",
    "Invalid file": "등록되지 않은 파일입니다.",
    "Invalid data": "등록되지 않은 데이터입니다.",
    "Invalid issue action id": "등록되지 않은 조치 ID입니다.",
    "Invalid issue id": "등록되지 않은 정비 ID입니다.",
    "already exist maintenance guide": "이미 등록된 정기점검 지침입니다.",
    "Invalid maintenance guide id": "등록되지 않은 정기점검 지침 ID입니다. ",
    "Maker name already exists": "이미 등록된 제조사명입니다.",
    "Maker model is used in car": "이미 사용 중인 모델입니다.",
    "Maker model name already exists": "이미 등록된 모델명입니다.",
    "Invalid maker model id": "등록되지 않은 모델 ID입니다",
    "Invalid maker id": "등록되지 않은 제조사 ID입니다.",
    "Sub battery history already exists": "이미 등록된 보조배터리 이력입니다.",
    "Invalid sub battery history id": "등록되지 않은 보조배터리 이력 ID입니다.",
    "SW Ver is used for cars": "이미 사용중인 버전입니다.",
    "SW ver already exists": "이미 등록된 버전입니다.",
    "Invalid sw ver id": "등록되지 않은 버전 ID입니다.",
    "Terminal id is already registered": "이미 등록된 단말기 ID입니다.",
    "Terminal modem serial number already exists": "이미 등록된 모뎀 S/N입니다.",
    "Terminal modem id is already registered": "이미 등록된 모뎀 ID입니다.",
    "Invalid terminal modem id": "등록되지 않은 모뎀 ID입니다.",
    "Invalid terminal modem serial number": "등록되지 않은 모뎀 S/N입니다.",
    "Invalid terminal id": "등록되지 않은 단말기 ID입니다.",
    "Invalid terminal serial number": "등록되지 않은 단말기 S/N입니다.",
    "Unauthorized token": "인증 토큰이 만료되었습니다.",
    "Invalid password": "잘못된 비밀번호입니다.",
    "Disabled user": "계정이 숨김처리 되어있습니다.",
    "Account locked": "계정이 잠겨있습니다.",
    "Username already exists": "이미 등록된 아이디입니다.",
    "Phone already exists": "이미 등록된 전화번호입니다.",
    "Invalid username": "등록되지 않은 사용자입니다.",
    "Invalid phone": "등록되지 않은 휴대폰 번호입니다.",
    "Invalid name": "등록되지 않은 이름입니다.",

    ERR_NETWORK: "서버 접속이 지연되고 있습니다.\n잠시 후 다시 시도해 주세요",
    ECONNABORTED: "서버 접속이 지연되고 있습니다.\n잠시 후 다시 시도해 주세요",
};

function AlertPopup() {
    const { type, title, message, setAlert } = useAlert();
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        let description = "";
        switch (type) {
            case AlertType.NONE:
                break;

            case AlertType.SUCCESS:
                openNotificationWithIcon("success", title, message);
                break;

            case AlertType.NONEXISTENT_MEMBER:
            case AlertType.ALREADY_EXISTENT_MEMBER:
            case AlertType.ACCOUNT_DISABLED:
            case AlertType.ACCOUNT_LOCKED:
            case AlertType.ACCOUNT_REJECTED:
            case AlertType.ACCOUNT_WAITING:
            case AlertType.ALREADY_ASSIGNED_ALARM:
            case AlertType.NONEXISTENT_ALARM:
            case AlertType.NONEXISTENT_ALARM_RECEIVING_GROUP:
            case AlertType.ALREADY_EXISTENT_APPLICATION:
            case AlertType.NONEXISTENT_APPLICATION:
            case AlertType.ALREADY_EXISTENT_AREA:
            case AlertType.NONEXISTENT_AREA:
            case AlertType.CANNOT_DELETE_CAR:
            case AlertType.ALREADY_EXISTENT_CAR:
            case AlertType.NONEXISTENT_CAR:
            case AlertType.ALREADY_EXISTENT_CAR_NUMBER_HISTORY:
            case AlertType.NONEXISTENT_CAR_NUMBER_HISTORY:
            case AlertType.ALREADY_EXISTENT_CATEGORY:
            case AlertType.NONEXISTENT_CATEGORY:
            case AlertType.UNDELETABLE_CUSTOMER_MANAGER:
            case AlertType.ALREADY_EXISTENT_CUSTOMER:
            case AlertType.ALREADY_EXISTENT_CUSTOMER_MANAGER:
            case AlertType.NONEXISTENT_CUSTOMER_MANAGER:
            case AlertType.NONEXISTENT_CUSTOMER:
            case AlertType.NONEXISTENT_DATA_BUKGI:
            case AlertType.CANNOT_DELETE_DATA:
            case AlertType.UPLOAD_FAILURE:
            case AlertType.NONEXISTENT_DATA_YAXING:
            case AlertType.ALREADY_EXISTENT_DOOR_MODULE:
            case AlertType.NONEXISTENT_DOOR_MODULE:
            case AlertType.NONEXISTENT_DOOR_STATUS_HISTORY:
            case AlertType.NONEXISTENT_FILE:
            case AlertType.INACCESSIBLE_DATA:
            case AlertType.INVALID_FILE:
            case AlertType.INVALID_PARAM:
            case AlertType.NONEXISTENT_ISSUE_ACTION:
            case AlertType.NONEXISTENT_ISSUE:
            case AlertType.ALREADY_EXISTENT_MAINTENANCE_GUIDE:
            case AlertType.NONEXISTENT_MAINTENANCE_GUIDE:
            case AlertType.ALREADY_EXISTENT_MAKER:
            case AlertType.UNDELETABLE_MAKER_MODEL:
            case AlertType.ALREADY_EXISTENT_MAKER_MODEL:
            case AlertType.NONEXISTENT_MAKER_MODEL:
            case AlertType.NONEXISTENT_MAKER:
            case AlertType.OPEN_WEATHER_ERROR:
            case AlertType.ALREADY_EXISTENT_SUB_BATTERY_HISTORY:
            case AlertType.NONEXISTENT_SUB_BATTERY_HISTORY:
            case AlertType.CANT_DELETE_SW_VER:
            case AlertType.ALREADY_EXISTENT_SW_VER:
            case AlertType.NONEXISTENT_SW_VER:
            case AlertType.ALREADY_EXISTENT_TERMINAL:
            case AlertType.ALREADY_EXISTENT_TERMINAL_MODEM:
            case AlertType.NONEXISTENT_TERMINAL_MODEM:
            case AlertType.NONEXISTENT_TERMINAL:
            case AlertType.UNAUTHORIZED:
            case AlertType.ALREADY_EXISTENT_USER:
            case AlertType.NONEXISTENT_USER:
            case AlertType.FILE_NOT_FOUND:
            case AlertType.ERR_NETWORK:
            case AlertType.ECONNABORTED:
                description = MessageType[message];
                openNotificationWithIcon("error", title, description ? description : message);
                break;

            default:
                openNotificationWithIcon("error", title, message);
                break;
        }
    }, [type, message]);

    const openNotificationWithIcon = (type: NotificationType, title: string, message: string) => {
        api[type]({
            message: title === "ERROR" ? "오류" : title,
            description: message,
            onClose: () => {
                setAlert(AlertType.NONE, "", "");
            },
            duration: 3,
        });
    };
    return <>{contextHolder}</>;
}

export default AlertPopup;
