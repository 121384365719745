import { useState, useEffect } from "react";
import Spin from "antd/es/spin/index";
import Space from "antd/es/space/index";

import * as Common from "../../commons/common";
import * as TypeDTO from "../../commons/typeDTO";
import * as TypeUtils from "../../commons/typeUtils";
import * as String from "../../commons/string";
import * as RequestStats from "../../utils/requestApiStats";

import ReactApexChart from "react-apexcharts";
import moment from "moment";
import Search from "../browser/Search";
import styles from "./Stats.module.css";

type SearchType = {
    startDate: string;
    endDate: string;
};

type RunningStatsDto = {
    bootTime: string;
    electricMileage: number;
    avgSpeed: number;
    drivingDistance: number;
};

function ChartRunningsByCar({ carId }: { carId?: number }) {
    const [stats, setStats] = useState<Array<RunningStatsDto>>([]);
    const [seriesDatas, setSeriesDatas] = useState<Array<TypeUtils.ChartData>>([]);
    const [chartOption, setChartOption] = useState<Record<string, any> | undefined>();
    const [search, setSearch] = useState<TypeUtils.SearchItemType[]>([
        {
            id: "searchDate",
            span: 24,
            title: String.searchDate,
            type: "rangePicker",
            dateFormat: Common.FORMAT_DATE_TIME,
        },
    ]);

    useEffect(() => {
        const chartDatas: Array<TypeUtils.ChartData> = [];
        const chartElectricRate: TypeUtils.ChartData = {
            name: "전비",
            data: [],
        };
        const chartAvgSpeed: TypeUtils.ChartData = {
            name: "평균속도",
            data: [],
        };
        const chartTravelledDistance: TypeUtils.ChartData = {
            name: "운행거리",
            data: [],
        };

        const unit = ["km/kWh", "km/h", "km"];

        let prevTime: string;
        stats?.forEach((data) => {
            const time = moment(data.bootTime, Common.FORMAT_DATE_TIME_MILLISECOND).format(Common.FORMAT_DATE_TIME);

            if (prevTime !== time) {
                chartElectricRate.data.push({
                    x: time,
                    y: Number.isNaN(data.electricMileage) ? 0 : data.electricMileage,
                });
                chartAvgSpeed.data.push({
                    x: time,
                    y: Number.isNaN(data.avgSpeed) ? 0 : data.avgSpeed,
                });
                chartTravelledDistance.data.push({
                    x: time,
                    y: Number.isNaN(data.drivingDistance) ? 0 : data.drivingDistance,
                });
            }
            prevTime = time;
        });
        chartDatas.push(chartElectricRate);
        chartDatas.push(chartAvgSpeed);
        chartDatas.push(chartTravelledDistance);

        setSeriesDatas(chartDatas);

        const chart_option = {
            colors: ["#0A84FF", "#5E5CE6", "#BF5AF2", "#FF2D55", "#FF453A", "#FF9F0A", "#FFD60A", "#32D74B", "#64D2FF"],
            chart: {
                height: "350px",
                type: "line",
                toolbar: {
                    show: true,
                    tools: {
                        download: false,
                        selection: false,
                        pan: false,
                        zoomin: true,
                        zoomout: true,
                    },
                    offsetY: -4,
                },
                zoom: {
                    enabled: true,
                    type: "x",
                },
                animations: {
                    enabled: false,
                    easing: "linear",
                    dynamicAnimation: {
                        speed: 500,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },

            title: {
                align: "center",
            },
            xaxis: {
                type: "category",
            },
            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    labels: {
                        formatter: function (val: number) {
                            return val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                    },
                },
                {
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    labels: {
                        style: {
                            colors: "var(--point)",
                        },
                        formatter: function (val: number) {
                            return val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                    },
                },
                {
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                    },
                    labels: {
                        style: {
                            colors: "#002A6A",
                        },
                        formatter: function (val: number) {
                            return val.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        },
                    },
                },
            ],
            tooltip: {
                enable: true,
                shared: true,
                marker: {
                    show: false,
                },
                x: {
                    show: false,
                },
                fixed: {
                    enabled: true,
                    position: "topLeft",
                    offsetY: 30,
                    offsetX: 60,
                },

                y: {
                    formatter: function (value: number, { series, seriesIndex, dataPointIndex, w }: any) {
                        return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + unit[seriesIndex];
                    },
                },
            },
            legend: {
                showForSingleSeries: false,
                fontFamily: "Pretendard-Regular",
                fontSize: "14px",
            },
            noData: {
                text: "No Data",
                align: "center",
                verticalAlign: "middle",
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: "#8b0000",
                    fontSize: "32px",
                    fontFamily: "Pretendard-Bold",
                },
            },
            plotOptions: {
                line: {
                    curve: "smooth",
                },
                bar: {
                    horizontal: false,
                    columnWidth: "10%",
                },
            },
            stroke: {
                width: 2,
            },
            axisBorder: {
                show: true,
                color: "#a9abb3",
                height: 1,
                width: "100%",
            },
            markers: {
                size: 0,
            },
        };

        setChartOption(chart_option);
    }, [stats]);

    const onSearch = (value: SearchType) => {
        carId && requestApiStatsRunningCar(carId, value);
    };

    const { loadingApiStatsRunningCar, requestApiStatsRunningCar, resultApiStatsRunningCar } = RequestStats.useRequestApiStatsRunningCar();

    useEffect(() => {
        if (!resultApiStatsRunningCar) return;

        setStats(resultApiStatsRunningCar.runningStats);
    }, [resultApiStatsRunningCar]);

    return (
        <Space size={16} direction="vertical" style={{ width: "100%" }}>
            <Search title="운행 이력 조회" values={search} onSearch={(value) => onSearch(value)} onClear={(value) => onSearch(value)} />
            {loadingApiStatsRunningCar ? (
                <div style={{ height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Spin />
                </div>
            ) : (
                <>
                    {chartOption && (
                        <ReactApexChart
                            style={{ backgroundColor: "white", overflowX: "auto", overflowY: "hidden", position: "relative" }}
                            options={chartOption}
                            series={seriesDatas}
                            type={chartOption?.chart?.type}
                            width="100%"
                            height={chartOption?.chart?.height}
                        />
                    )}
                </>
            )}
        </Space>
    );
}

export default ChartRunningsByCar;
